/* RESPONSIBLE TEAM: team-proactive-support */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import ComponentConfiguration from 'embercom/objects/component-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/selection-set/stats/standard-stat-list-configuration';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default function generateSelectionSetConfig(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');
  return ViewModeConfig.create({
    headerComponentConfigs: [],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: intl.t('outbound.chats.chat'),
          icon: 'chat-bubble',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.sent'),
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.opened'),
          view: 'opens',
          isNotDraft: true,
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.clicked'),
          view: 'clicks',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.replied'),
          view: 'replies',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [new ComponentConfiguration({ component: 'selection-set/content/chat', container })],
      receipts: [
        new StandardStatListConfiguration({
          stat: 'receipt',
          objectType: objectTypes.chat,
          hideCreatedVia: true,
          emptyStateDescription: ' ', // We need a non-empty value as override in order to hide description }),
        }),
      ],
      opens: [
        new StandardStatListConfiguration({
          stat: 'open',
          objectType: objectTypes.chat,
        }),
      ],
      clicks: [
        new StandardStatListConfiguration({
          stat: 'click',
          objectType: objectTypes.chat,
        }),
      ],
      replies: [
        new StandardStatListConfiguration({
          stat: 'reply',
          objectType: objectTypes.chat,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });
}
