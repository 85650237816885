/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from '../component-configuration';
import { assert } from '@ember/debug';

export default ComponentConfiguration.extend({
  view: undefined,
  isVisible: true,

  init(inputs) {
    this._super(...arguments);
    assert('A view must be passed for a tab configuration', inputs.view);
    this.setProperties({
      view: inputs.view,
    });
  },
});
