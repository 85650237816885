/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { get } from '@ember/object';
export default Mixin.create({
  rollbackMessageAndRelationships() {
    let relationshipNames = get(this.constructor, 'relationshipNames');
    let hasManyNamesToRollback = this._filterRelationshipNames(relationshipNames.hasMany);
    let belongsToNamesToRollback = this._filterRelationshipNames(relationshipNames.belongsTo);

    this.rollbackAttributes();
    hasManyNamesToRollback.forEach((relationshipName) => {
      this.get(relationshipName).forEach((model) => this._rollbackModelIfDefined(model));
    });
    belongsToNamesToRollback.forEach((relationshipName) => {
      this._rollbackBelongsToRelationship(this.get(relationshipName));
    });
  },

  _filterRelationshipNames(relationshipNames) {
    return relationshipNames.filter((relationshipName) => {
      return this.rollbackRelationshipNamesTrustedlist.includes(relationshipName);
    });
  },

  _rollbackBelongsToRelationship(relationship) {
    if (relationship.isFulfilled) {
      this._rollbackModelIfDefined(relationship.content);
    }
  },

  _rollbackModelIfDefined(model) {
    if (model) {
      model.rollbackAttributes();
    }
  },
});
