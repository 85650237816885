/* RESPONSIBLE TEAM: team-proactive-support */

import Model, { attr } from '@ember-data/model';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';

export default class DkimSettingsModel extends Model {
  @service appService;

  @attr() appId;
  @attr() domain;
  @attr() recordName;
  @attr() domainForCustomerDnsRecord;
  @attr() domainForIntercomDnsRecord;
  @attr() validDmarcExists;
  @attr() validRecordExists;
  @attr() isUsedInAdminEmails;
  @attr() isUsedInCustomEmails;
  @attr() adminEmails;
  @attr() hasVerifiedAdminEmails;

  get app() {
    return this.appService.app;
  }

  get app_id() {
    return this.appId;
  }

  async verify() {
    let settings = await ajax({
      url: `/ember/dkim_settings/${this.id}/verify`,
      type: 'GET',
      data: {
        app_id: this.app_id,
      },
    });

    let dkim = settings?.dkim_settings;
    if (dkim) {
      this.validRecordExists = dkim.valid_record_exists;
      this.validDmarcExists = dkim.valid_dmarc_exists;
    }

    let customBounce = settings?.custom_bounce_settings;
    if (customBounce) {
      let customBounceModel = this.store.peekRecord('custom-bounce-settings', customBounce.id);
      customBounceModel.validRecordExists = customBounce.valid_record_exists;
    }

    if (
      !dkim?.valid_record_exists ||
      !customBounce?.valid_record_exists ||
      !dkim?.valid_dmarc_exists
    ) {
      throw new Error('Domain verification failed');
    }

    return true;
  }
}
