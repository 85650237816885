/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { fragment } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type SearchBar from './search-bar';
import type Footer from './footer';
import type Header from './header';
import { type HeaderAlignment, type HeaderJustify } from '../styles/header';

const DEFAULT_GLOBAL_SEARCH_BAR_STYLE = {
  width: '100%',
  borderRadius: 10,
};

const DEFAULT_GLOBAL_HEADER_STYLE = {
  height: '245px',
  align: 'start',
  justify: 'end',
};

export default class NamedComponents extends Fragment {
  @fragment('customization-options/global-options/search-bar') declare searchBar: SearchBar;
  @fragment('customization-options/global-options/header') declare header: Header;

  @fragment('customization-options/global-options/footer') declare footer: Footer;

  setDefaultValues() {
    this.setDefaultSearchBar();
    this.setDefaultHeader();
  }

  setDefaultSearchBar() {
    if (!this.searchBar) {
      this.searchBar = this.store.createFragment(
        'customization-options/global-options/search-bar',
        {
          style: DEFAULT_GLOBAL_SEARCH_BAR_STYLE,
        },
      );
    }

    let { width, borderRadius } = DEFAULT_GLOBAL_SEARCH_BAR_STYLE;
    if (!this.searchBar.style.width) {
      this.searchBar.style.width = width;
    }
    if (!this.searchBar.style.borderRadius) {
      this.searchBar.style.borderRadius = borderRadius;
    }
  }

  setDefaultHeader() {
    if (!this.header) {
      this.header = this.store.createFragment('customization-options/global-options/header', {
        style: DEFAULT_GLOBAL_HEADER_STYLE,
      });
    }

    let { height, align, justify } = DEFAULT_GLOBAL_HEADER_STYLE;
    if (!this.header.style.height) {
      this.header.style.height = height;
    }

    if (!this.header.style.align) {
      this.header.style.align = align as HeaderAlignment;
    }
    if (!this.header.style.justify) {
      this.header.style.justify = justify as HeaderJustify;
    }
    this.header.subheader?.setDefaultValues();

    if (!this.footer) {
      this.footer = this.store.createFragment('customization-options/global-options/footer', {
        type: 0,
      });
    }
  }
}
