/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';
import type IntlService from 'embercom/services/intl';

export default class PreHandoverHeaderConfiguration extends StandardPanelHeaderConfiguration {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;

  // @ts-expect-error StandardPanelHeaderConfiguration is pre-Octane so we have a type mismatch here
  get errors() {
    return [];
  }

  // @ts-expect-error same as above
  get isComplete() {
    return this.errors.length === 0;
  }

  // @ts-expect-error
  init(inputs) {
    inputs.component = 'content-editor/panels/custom/ai-agent-profile/pre-handover/header';
    inputs.title = this.intl.t('operator.fin.setup.setup-and-go-live.pre-handover.title');
    super.init(...arguments);
  }
}
