/* RESPONSIBLE TEAM: team-workflows */
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';

export default class StandardAudienceEditorConfiguration {
  matchBehaviorSwitcher = null;
  sendToUnsubscribedToggle = null;
  clientPredicatesEditor = null;
  audienceRulesEditor = null;
  audiencePreview = null;
  eventRulesEditor = null;
  audiencePreviewHidden = null;
  audiencePreviewHiddenText = null;
  unsupportedAttributesBanner = null;
  unsupportedAttributesBannerText = null;

  // default values
  defaults = {
    showMatchBehavior: false,
    showSendToUnsubscribedToggle: false,
    showClientPredicates: false,
    showAudiencePreview: true,
    ignoredAttributeTypesForPreview: [],
    useRolePredicateGroup: false,
    showEventRules: false,
    showDateEvents: false,
    additionalAttributes: [],
    includeTicketAttributeGroups: false,
    includeConversationAttributes: false,
    showAudiencePreviewHidden: false,
    audiencePreviewHiddenText: '',
    showUnsupportedAttributesBanner: false,
    unsupportedAttributesBannerText: '',
    useSupportedPredicateAttributes: false,
  };

  constructor(inputs) {
    this.inputs = { ...this.defaults, ...inputs };
    this.createDefaults();
  }

  createDefaults() {
    if (this.inputs.showMatchBehavior) {
      this.matchBehaviorSwitcher = this._createComponent(
        'content-editor/audience-wrappers/standard/match-behavior-switcher',
        this.inputs,
      );
    }

    if (this.inputs.showSendToUnsubscribedToggle) {
      this.sendToUnsubscribedToggle = this._createComponent(
        'content-editor/audience-wrappers/content/email/send-to-unsubscribed-toggle',
        this.inputs,
      );
    }

    if (this.inputs.showClientPredicates) {
      this.clientPredicatesEditor = this._createComponent(
        'content-editor/audience-wrappers/standard/client-predicates-editor',
        this.inputs,
      );
    }

    this.audienceRulesEditor = this._createComponent(
      'content-editor/audience-wrappers/standard/audience-rules-editor',
      this.inputs,
    );

    if (this.inputs.showEventRules) {
      this.eventRulesEditor = this._createComponent(
        'content-editor/audience-wrappers/standard/event-rules-editor',
        this.inputs,
      );
    }

    if (this.inputs.showAudiencePreview) {
      this.audiencePreview = this._createComponent(
        'content-editor/audience-wrappers/standard/audience-preview',
        this.inputs,
      );
    }

    if (this.inputs.showAudiencePreviewHidden) {
      this.audiencePreviewHidden = this.inputs.showAudiencePreviewHidden;
      this.audiencePreviewHiddenText = this.inputs.audiencePreviewHiddenText;
    }

    if (this.inputs.showUnsupportedAttributesBanner) {
      this.unsupportedAttributesBanner = this.inputs.showUnsupportedAttributesBanner;
      this.unsupportedAttributesBannerText = this.inputs.unsupportedAttributesBannerText;
    }
  }

  _createComponent(component, config) {
    return ComponentConfiguration.create({
      component,
      config,
    });
  }
}
