/* RESPONSIBLE TEAM: team-help-desk-experience */

import type ApplicationInstance from '@ember/application/instance';
import { registerDestructor } from '@ember/destroyable';
import { inject as service } from '@ember/service';
import Modifier from 'ember-modifier';
import type CommandKService from 'embercom/services/command-k';

export default class CommandKPin extends Modifier {
  @service declare commandK: CommandKService;

  private isPinned = false;

  constructor(owner: ApplicationInstance, args: never) {
    super(owner, args);
    registerDestructor(this, this.cleanup.bind(this, args));
  }

  modify(element: HTMLElement) {
    if (!this.isPinned) {
      this.commandK.pinTo(element);
      this.isPinned = true;
    }
  }

  private cleanup = () => {
    this.commandK.unpin();
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'command-k-pin': typeof CommandKPin;
  }
}
