/* RESPONSIBLE TEAM: team-purchase-experience */
import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type IntlService from 'ember-intl/services/intl';

interface StripeIntentPayload {
  object_id?: string;
  client_secret?: string;
  stripe_account_id?: string;
  amount?: number;
  existing_payment_method?: string;
}

export default class StripeIntent extends Model {
  @service declare intl: IntlService;

  @belongsTo('app', { async: false, inverse: null }) declare app: any; // App model is written in legacy Ember which is awkward with TypeScript, so typing as 'any' for now
  @attr('string') declare clientSecret: string;
  @attr('string') declare stripeAccountId: string;
  @attr('string') declare objectId: string;
  @attr('string') declare objectType: string;
  @attr('number') declare amount: number;
  @attr('string') declare existingPaymentMethod: string;

  updateFromPayload(payload: StripeIntentPayload) {
    let properties = {
      ...(payload.object_id && { objectId: payload.object_id }),
      ...(payload.client_secret && { clientSecret: payload.client_secret }),
      ...(payload.stripe_account_id && { stripeAccountId: payload.stripe_account_id }),
      ...(payload.amount && { amount: payload.amount }),
      ...(payload.existing_payment_method && {
        existingPaymentMethod: payload.existing_payment_method,
      }),
    } as { [K in keyof this]: any };
    this.setProperties(properties);
  }

  get hasExistingPaymentMethod() {
    return isPresent(this.existingPaymentMethod);
  }

  get isSetupIntent() {
    return this.objectType === 'setup_intent';
  }

  get isPaymentIntent() {
    return this.objectType === 'payment_intent';
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'billing/stripe-intent': StripeIntent;
  }
}
