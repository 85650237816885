/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';
import { type BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { inject as service } from '@ember/service';
import { dependentKeyCompat } from '@ember/object/compat';

import type PrivacyPolicyNoticeContent from './privacy-policy-notice-content';
import type IntlService from 'ember-intl/services/intl';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

export default class PrivacyPolicyNotice extends Model {
  @service declare intl: IntlService;
  @service declare messengerSettingsService: MessengerSettingsService;

  @hasMany('messenger-settings/privacy-policy-notice-content')
  contents?: SyncHasMany<PrivacyPolicyNoticeContent>;

  @attr('boolean') enabled?: boolean;

  updateContents(localeId: string, blocksDocument: BlocksDocument) {
    if (!this.contents) {
      return;
    }

    let existingEntryForLocale = this.contents.findBy('localeId', localeId);

    if (existingEntryForLocale) {
      existingEntryForLocale.notice =
        blocksDocument.blocks[0].type === 'paragraph' ? blocksDocument.blocks[0].text : '';
    } else {
      let newRecord = this.store.createRecord('messenger-settings/privacy-policy-notice-content', {
        id: localeId,
        localeId,
        notice: blocksDocument.blocks[0].type === 'paragraph' ? blocksDocument.blocks[0].text : '',
      });
      this.contents.pushObject(newRecord);
    }
  }

  validate() {
    if (!this.contents || this.enabled === undefined) {
      throw new Error(this.intl.t('app.models.role.notifications.error'));
    }
  }

  @dependentKeyCompat
  get defaultLocale() {
    return this.messengerSettingsService.defaultMessengerLocale;
  }

  @dependentKeyCompat
  get defaultPrivacyPolicyNoticeContent() {
    return this.contents?.findBy('localeId', this.defaultLocale)?.notice;
  }

  get hasUnsavedChanges() {
    if (!this.contents) {
      return false;
    }

    return (
      this.hasDirtyAttributes ||
      this.contents
        ?.toArray()
        .some((notice: PrivacyPolicyNoticeContent) => notice.hasDirtyAttributes)
    );
  }

  refresh() {
    this.rollbackAttributes();
  }
}
