/* RESPONSIBLE TEAM: team-help-desk-experience */
export type RowMap = number[];

// Emojis are rendered in a group layout, within multiple rows and columns.
// Given a group of emojis, this returns a map of the layout that we would have
// rendered. For example:

// { smileys: [Array<15>], animals: [Array<22>] }

// … may return something like [ 10, 5, 10, 10, 2 ]

// That means 10 emojis in the first row, 5 in the second one, 10 in the next
// two, and 2 in the last row.
export function buildRowMap(group: Record<string, unknown[]>, maxPerRow: number) {
  let rows: number[] = [];

  for (let [, items] of Object.entries(group)) {
    let count = items.length;

    let packedRows = Math.floor(count / maxPerRow);
    let remainingItems = count % maxPerRow;

    rows.push(...Array(packedRows).fill(maxPerRow));
    if (remainingItems > 0) {
      rows.push(remainingItems);
    }
  }

  return rows;
}

export function findRowAndColumnForIndex(rowMap: RowMap, index: number) {
  let seenRows = 0;
  let seenItems = 0;

  for (let [, count] of Object.entries(rowMap)) {
    if (seenItems + count > index) {
      return [seenRows, index - seenItems];
    }

    seenRows += 1;
    seenItems += count;
  }

  return [-1, -1];
}

export function findIndexForRowAndColumn(rowMap: RowMap, row: number, column: number) {
  let seenRows = 0;
  let seenItems = 0;

  for (let [, count] of Object.entries(rowMap)) {
    if (seenRows >= row) {
      break;
    }

    seenRows += 1;
    seenItems += count;
  }

  return seenItems + column;
}

// Find an equivalent index in the next row. Typically the same column number in
// the next row, but if the next row doesn't have as many columns, it'll choose
// the index for the last column.
export function getIndexInNextRow(rowMap: RowMap, index: number) {
  let [currentRow, currentColumn] = findRowAndColumnForIndex(rowMap, index);
  let itemsInNextRow = rowMap[currentRow + 1];

  return findIndexForRowAndColumn(
    rowMap,
    currentRow + 1,
    Math.min(currentColumn, itemsInNextRow - 1),
  );
}

// Find an equivalent index in the previous row. Typically the same column
// number in the previous row, but if the previous row doesn't have as many
// columns, it'll choose the index for the last column.
export function getIndexInPreviousRow(rowMap: RowMap, index: number) {
  let [currentRow, currentColumn] = findRowAndColumnForIndex(rowMap, index);
  let itemsInPreviousRow = rowMap[currentRow - 1];

  return findIndexForRowAndColumn(
    rowMap,
    currentRow - 1,
    Math.min(currentColumn, itemsInPreviousRow - 1),
  );
}
