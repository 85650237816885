/* RESPONSIBLE TEAM: team-app-security */
import Model, { attr, belongsTo } from '@ember-data/model';
import IpAllowListValidator from '../lib/ip-allowlist-validator';
import { inject as service } from '@ember/service';

export default class SecurityAppSettings extends Model {
  @service appService;
  @service intl;
  @service intercomEventService;

  @attr('boolean') force_2fa;
  @attr('array') authorizedSignInMethods;
  @attr('array') ssoDomainWhitelist;
  @belongsTo('saml-account', { async: false }) samlAccount;
  @belongsTo('scim-setting', { async: false }) scimSettings;
  @attr('boolean') canEnableEnforceSso;
  @attr('boolean') canEnforceSaml;
  @attr('boolean') endUserUploadsAccessDisabled;
  @attr('boolean') notificationEmailsWithContentEnabled;
  @attr('boolean') leadEmailIdentificationEnabled;
  @attr('string') securityContactEmail;
  @attr('string') samlBaseUrl;
  @attr('boolean') unauthenticatedLeadMergesEnabled;
  @attr('boolean') canToggleNotificationEmailsWithContent;
  @attr('boolean') conversationPanRedactionEnabled;
  @attr('boolean') ipAllowlistEnabled;
  @attr('array') ipAllowlist;
  @attr('boolean') customSessionLengthEnabled;
  @attr('number') customSessionLength;
  @attr('array') customSessionLengthValidExpiries;

  async save() {
    this.beforeSave();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: 'security_app_settings',
      ip_allowlist_enabled: this.ipAllowlistEnabled,
      allowlist_updated: this.changedAttributes().ipAllowlist !== undefined,
    });
    await super.save(...arguments);
  }

  beforeSave() {
    if (!this.isInvalid) {
      this.updateIpAllowlist();
    }
  }

  get isInvalid() {
    return !this.ipValidator.isValid;
  }

  get ipValidator() {
    let ipAllowlist = this.ipAllowlist;
    let ipString = this._isArray(ipAllowlist) ? ipAllowlist.join(',') : ipAllowlist;
    return new IpAllowListValidator(ipString, this.intl);
  }

  _isArray(list) {
    return Array.isArray(list);
  }

  updateIpAllowlist() {
    if (this._isArray(this.ipAllowlist)) {
      return;
    }

    let ipAllowlist = this.ipAllowlist;
    this.ipAllowlist = ipAllowlist.split(',').map((ip) => ip.trim());
  }
}
