/* RESPONSIBLE TEAM: team-product-exploration */
/* eslint-disable @intercom/intercom/no-bare-strings */

// This file powers search within Accounts area of the product.
import type SearchableRoute from './searchable-route';

const AccountRoutes: Array<SearchableRoute> = [
  {
    route: 'apps.app.account',
    name: 'Your account',
    children: [
      {
        route: 'apps.app.account',
        name: 'Details',
        synonyms: ['Profile', 'Account'],
        children: [
          {
            route: 'apps.app.account',
            name: 'Change email address',
            synonyms: ['Update email'],
          },
          {
            route: 'apps.app.account.password',
            name: 'Change password',
            synonyms: ['Update password'],
          },
          {
            route: 'apps.app.account',
            name: 'Change photo',
            synonyms: ['Avatar', 'Picture'],
          },
          {
            route: 'apps.app.account.two_factor_auth',
            name: 'Setup Two Factor Authentication',
            synonyms: ['2-Factor Authentication', '2FA'],
          },
        ],
      },
      {
        route: 'apps.app.account.notifications',
        name: 'Notifications',
        synonyms: ['Alerts', 'Notification preferences'],
        children: [
          {
            route: 'apps.app.account.notifications',
            name: 'Browser notifications',
            synonyms: ['Sound'],
          },
          {
            route: 'apps.app.account.notifications',
            name: 'Daily email',
          },
          {
            route: 'apps.app.account.notifications',
            name: 'App store updates',
          },
          {
            route: 'apps.app.account.notifications',
            name: 'Fin content suggestion emails',
          },
        ],
      },
      {
        route: 'apps.app.account.visibility.user-segments',
        name: 'Visibility',
        synonyms: ['Visible', 'Hidden'],
        children: [
          {
            route: 'apps.app.account.visibility.user-segments',
            name: 'User segments',
          },
          {
            route: 'apps.app.account.visibility.company-segments',
            name: 'Company segments',
          },
          {
            route: 'apps.app.account.visibility.tags',
            name: 'Tags',
          },
        ],
      },
      {
        route: 'apps.app.account.oauth-tokens',
        name: 'Authentication',
        children: [
          {
            route: 'apps.app.account.oauth-tokens',
            name: 'OAuth Tokens',
            synonyms: ['Access Tokens', 'API Tokens', 'Authentication Tokens'],
          },
        ],
      },
      {
        route: 'apps.app.account.account_access',
        name: 'Account Access',
        children: [
          {
            route: 'apps.app.account.account_access',
            name: 'Your activity',
            synonyms: ['Active Sessions', 'Login History', 'Active sessions'],
          },
        ],
      },
    ],
  },
];

export default AccountRoutes;
