/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
import { put } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { MAX_DELAY_IN_SECONDS, MIN_DELAY_IN_SECONDS } from 'embercom/lib/workflows/helpers/delays';

const Validations = buildValidations({
  actions: {
    description: 'Valid actions by trigger',
    validators: [
      validator('workflow-has-valid-actions-by-uniqueness'),
      validator('workflow-has-valid-actions-by-is-valid'),
    ],
  },
  predicateGroup: {
    description: 'Valid predicates by status',
    validators: [validator('workflow-has-valid-predicates-for-status')],
  },
  data: {
    description: 'Valid data for trigger',
    validators: [validator('workflow-has-valid-trigger-data')],
  },
});

export default class Workflow extends Model.extend(Validations) {
  @service appService;
  @service intl;

  @attr('string') name;
  @attr('string') description;
  @attr('string') status;
  @attr('number') order;
  @attr('date') lastAppliedAt;
  @attr('date') createdAt;
  @attr('array') migratedToRulesetIds;
  @attr() data;
  @attr() triggerTypes;
  @fragmentArray('workflows/workflow-action') actions;
  @fragment('predicates/predicate-group') predicateGroup;

  get app() {
    return this.appService.app;
  }

  get canBeSetLive() {
    return !this.isLive && this.canBeLive;
  }

  get canBeSaved() {
    if (!this.hasTrigger) {
      return false;
    }
    if (!this.passesValidations) {
      return false;
    }
    if (this.isLive && !this.canBeLive) {
      return false;
    }
    return true;
  }

  get awaitingFirstReplyTrigger() {
    return this.triggerTypes.includes('awaiting_first_reply');
  }

  get awaitingEndUserReplyTrigger() {
    return this.triggerTypes.includes('awaiting_end_user_reply');
  }

  get replyToOutboundTrigger() {
    return this.triggerTypes.includes('reply_to_outbound');
  }

  get isLive() {
    return this.status === 'live';
  }

  get canBeLive() {
    if (
      this.hasTrigger &&
      this.awaitingFirstReplyTrigger &&
      !this.app.canUseAwaitingFirstReplyTrigger
    ) {
      return false;
    }
    return (
      this.passesValidations && this.hasTrigger && this.hasPredicates && this.actions.length > 0
    );
  }

  get passesValidations() {
    return this.validations.isValid && this.predicateGroup.isValid;
  }

  get hasTrigger() {
    return this.triggerTypes.length > 0;
  }

  get hasPredicates() {
    // The re-used model allows empty predicates to be valid, we don't
    return this.predicateGroup.predicates && this.predicateGroup.predicates.length > 0;
  }

  get hasChanges() {
    return (
      this.hasDirtyAttributes ||
      this.actions.hasDirtyAttributes ||
      this.predicateGroup.hasDirtyAttributes
    );
  }

  get hasSaveWarning() {
    return this.triggerTypes.includes('any_user_comment') || this.showTrackerTicketWarning;
  }

  get saveWarningText() {
    if (this.showTrackerTicketWarning) {
      return this.intl.t('settings.inbox-rules.index.workflow.save-tracker-ticket-warning.text');
    }

    return this.intl.t('settings.inbox-rules.index.workflow.save-warning.text');
  }

  get showTrackerTicketWarning() {
    return (
      this.triggerTypes.includes('outbound_ticket_created') ||
      this.triggerTypes.includes('ticket_state_updated')
    );
  }

  get hasInvalidDelay() {
    return (
      this.data.delay_in_seconds > MAX_DELAY_IN_SECONDS ||
      this.data.delay_in_seconds < MIN_DELAY_IN_SECONDS
    );
  }

  reorder(newIndex) {
    return put(`/ember/workflows/${this.id}/reorder`, {
      order: newIndex,
      app_id: this.appService.app.id,
    });
  }
}
