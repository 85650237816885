/* RESPONSIBLE TEAM: team-workflows */
import { attr } from '@ember-data/model';
import { bool, readOnly, notEmpty, empty, or, and, not, equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray, fragment, fragmentOwner } from 'ember-data-model-fragments/attributes';
import generateUUID from 'embercom/lib/uuid-generator';
import Validations from 'embercom/validations/workflows/step';
import renderPreview from '@intercom/embercom-composer/lib/render-preview';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { CONTROL_TYPE_FALLBACK } from 'embercom/lib/operator/custom-bots/constants';

export default Fragment.extend(Validations, {
  appService: service(),
  attributeService: service(),
  customObjectsService: service(),

  path: fragmentOwner(),
  type: attr('string'),
  triggerableWorkflowId: attr('number'),
  triggerableWorkflowTitle: attr('string'),
  triggerableWorkflowCustomBotId: attr('number'),
  controls: fragmentArray('operator/workflows/control'),
  blocks: fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' }),
  serializedBlocks: computed('blocks.{@each.text,type}', function () {
    // We only need to serialize the blocks if they are a FragmentArray (in tests they are already serialized)
    if (this.blocks.type === 'common/blocks/block') {
      return this.blocks.serialize();
    } else {
      return this.blocks;
    }
  }),
  uuid: attr('string', {
    defaultValue() {
      return generateUUID();
    },
  }),
  stepData: fragment('operator/workflows/step-data'),
  simpleActionRule: fragment('rules/rule'),
  followUpRules: fragmentArray('rules/rule'),
  firstBlockHasEmptyText: computed('blocks.firstObject.text', function () {
    return this.blocks.firstObject && this.blocks.firstObject.text === '';
  }),

  justAdded: false,
  hasSimpleActionRule: notEmpty('simpleActionRule'),
  hasFollowUpRules: notEmpty('followUpRules'),
  hasActions: or('hasSimpleActionRule', 'hasFollowUpRules'),
  hasBlocks: notEmpty('blocks'),
  hasControls: notEmpty('controls'),
  hasNoActions: not('hasActions'),
  hasNoBlocks: not('hasBlocks'),
  hasNoControls: not('hasControls'),

  isAttributeCollector: equal('type', 'attribute_collector'),
  isReplyButtons: equal('type', 'reply_buttons'),
  isConditionalBranches: equal('type', 'conditional_branches'),
  isFreeInput: equal('type', 'free_input'),
  isChatMessage: equal('type', 'chat_message'),
  isMessengerAppMessage: equal('type', 'messenger_app'),
  isMidPathActions: equal('type', 'mid_path_actions'),
  isCustomObjectSelector: equal('type', 'custom_object_selector'),
  isSetExpectations: equal('type', 'set_expectations'),
  isTerminal: equal('type', 'terminal'),
  isFollowUpRules: equal('type', 'follow_up_rules'),
  isAnswerBot: equal('type', 'answer_bot'),
  isWorkflowConnector: equal('type', 'workflow_connector'),
  isTriggerWorkflow: equal('type', 'trigger_workflow'),
  isSendTicket: equal('type', 'send_ticket'),
  isSendTicketTerminal: computed('isTerminal', 'stepData.endingType', function () {
    return this.isTerminal && this.stepData.endingType === 'send_ticket';
  }),
  isSnooze: equal('type', 'snooze'),
  isWait: equal('type', 'wait'),
  isFin: equal('type', 'fin'),

  isNotSendTicket: not('isSendTicket'),
  isNotSendTicketTerminal: not('isSendTicketTerminal'),
  isStartOver: equal('type', 'start_over'),

  isEndStateStep: computed('type', 'appService.app.canUseMidPathReusableWorkflows', function () {
    let endStateStepTypes = [
      'follow_up_rules',
      'reply_buttons',
      'answer_bot',
      'terminal',
      'conditional_branches',
      'custom_object_selector',
      ...(this.appService.app?.canUseMidPathReusableWorkflows ? [] : ['trigger_workflow']),
      'send_ticket',
      'fin',
    ];
    return endStateStepTypes.find((endStateType) => endStateType === this.type) !== undefined;
  }),
  canBranch: notEmpty('branchingControls'),
  canOnlyBranch: and('canBranch', 'hasNoMidPathControls'),
  hasNoMidPathControls: empty('midPathControls'),
  midPathControls: computed('controls.[]', 'branchingControls', function () {
    return this.controls.filter((control) => !this.branchingControls.includes(control));
  }),
  attributeIdentifier: readOnly('controls.firstObject.attributeIdentifier'),
  firstSimpleAction: readOnly('simpleActionRule.actions.firstObject'),
  shouldCollectAttribute: bool('attributeIdentifier'),
  isUploading: attr('boolean', {
    defaultValue() {
      return false;
    },
  }),
  replyOptions: computed('controls.@each.buttonLabel', function () {
    return this.controls.mapBy('buttonLabel').reject((label) => !label);
  }),
  attributeIdentifiers: computed('attributeIdentifier', 'controls', function () {
    return this.controls.mapBy('attributeIdentifier').reject((identifier) => !identifier);
  }),
  branchingControls: computed('controls.[]', 'path.steps', 'type', function () {
    switch (this.type) {
      case 'reply_buttons':
      case 'custom_object_selector':
      case 'conditional_branches':
      case 'fin':
        return this.controls;
      case 'workflow_connector':
        return this.controls.filter((c) => c.type === CONTROL_TYPE_FALLBACK);
      default:
        return this.controls.filter(
          (c) =>
            c.nextStepUuid !== null &&
            c.nextStepUuid !== undefined &&
            !this.path.steps.mapBy('uuid').includes(c.nextStepUuid),
        );
    }
  }),
  oneLineSummary: computed('serializedBlocks.@each.text', function () {
    let serializedBlocks = this.serializedBlocks;
    let htmlString;

    if (serializedBlocks.length > 0) {
      htmlString = serializedBlocks
        .map((block) => {
          switch (block.type) {
            case 'app':
            case 'image':
            case 'button':
              return `[${capitalize(block.type)}]`;
            case 'messengerCard':
              return `[${block.text}]`;
            default:
              return this.replace(block.text, '<br>', ' ');
          }
        })
        .join(' ');
    } else {
      htmlString = '[Bot message]';
    }

    htmlString = renderPreview(htmlString, {});
    return this.stripTextFromHTMLString(this.removeConsecutiveSpaces(htmlString));
  }),
  selectedCustomObject: computed(
    'customObjectsService.customObjectTypes',
    'stepData.customObjectSelectorParams.object_type_for_button_generation',
    'store',
    'attributeService',
    function () {
      return this.customObjectsService.findCustomObjectTypeByIdentifier(
        this.stepData.customObjectSelectorParams.object_type_for_button_generation,
      );
    },
  ),
  replace(text, from, to) {
    return text ? text.replace(new RegExp(from, 'g'), to) : text;
  },
  removeConsecutiveSpaces(text) {
    return text ? text.replace(/  +/g, ' ') : text;
  },
  stripTextFromHTMLString(htmlString) {
    let fakeElement = document.createElement('div');
    fakeElement.innerHTML = sanitizeHtml(htmlString);
    return fakeElement.textContent;
  },
});
