/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { task } from 'ember-concurrency-decorators';

import ArticleContent from './article-content';

export default class ArticleContentForEditing extends ArticleContent {
  @task
  *saveAsDraft(): Generator<$TSFixMe> {
    return yield this.save({ isAutoSave: false, adapterOptions: { forceDraftState: true } });
  }

  @task
  *autoSaveAsDraft() {
    yield this.save({ isAutoSave: true, adapterOptions: { forceDraftState: true } });
  }
}
