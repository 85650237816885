/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';

export default Model.extend({
  appId: attr('string'),
  teamId: attr('string'),
  volumeControlEnabled: attr('boolean'),
  distributionMethod: attr('string'),
  order: attr(),
  officeHoursScheduleId: attr('string'),
  teamResponseDelayEnabled: attr('boolean', { defaultValue: false }),
  customResponseDelay: attr('number', { defaultValue: 3 }),
  customResponseDelayValue: attr(),
  customResponseTimeValues: attr(),
});
