/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class Css extends Fragment {
  @attr('string') bodyBackgroundColor;
  @attr('string') containerBackgroundColor;
  @attr('string') templateFontColor;
  @attr('string') templateFontFamily;
  @attr('string') templateLinkFontColor;
  @attr('string') templateLinkFontWeight;
  @attr('string') templateLinkFontStyle;
  @attr('number') h1_font_size;
  @attr('number') h2_font_size;
  @attr('number') paragraph_font_size;
  @attr('number') small_font_size;
}
