/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { isEqual, isPresent } from '@ember/utils';
import { computed } from '@ember/object';
import { equal, readOnly, or, not, and } from '@ember/object/computed';
import { fragment } from 'ember-data-model-fragments/attributes';
import { isAny } from '@intercom/pulse/lib/computed-properties';
import ajax from 'embercom/lib/ajax';
import Admin from 'embercom/models/admin';
import { dasherize } from '@ember/string';

export default Model.extend({
  appId: attr('string'),
  createdAt: attr('date'),
  name: attr('string'),
  order: attr('number', { defaultValue: 0 }),
  underlyingRule: fragment('rules/rule'),
  lastExecutedAt: attr('date'),
  status: attr('string'),
  type: attr('string'),
  deleted: attr('boolean'),
  createdByAdminId: attr(),
  updatedByAdminId: attr(),

  firstAction: readOnly('underlyingRule.actions.firstObject'),
  entityString: '',
  subRouteString: '',
  allowedRuleActions: null,

  hasValidName: computed('name', function () {
    let name = this.name;
    return isPresent(name) && name.length <= 100;
  }),
  underlyingRuleHasValidPredicates: and(
    'underlyingRule.hasPredicates',
    'underlyingRule.predicatesAreValid',
  ),
  underlyingRuleHasValidActions: and('underlyingRule.hasActions', 'underlyingRule.actionsAreValid'),
  isValid: and('hasValidName', 'underlyingRuleHasValidPredicates', 'underlyingRuleHasValidActions'),

  hasDirtyRuleActions: isAny('underlyingRule.actions', 'hasDirtyAttributes', true),
  hasDirtyPredicates: isAny('underlyingRule.predicates', 'hasDirtyAttributes', true),
  isDirtyOrHasDirtyChildObjects: or(
    'hasDirtyAttributes',
    'hasDirtyRuleActions',
    'hasDirtyPredicates',
  ),

  isInboxAssignmentRule: equal('type', 'inbox-assignment'),
  isDefaultInboxAssignmentRule: equal('type', 'default-inbox-assignment'),
  isOwnerAssignmentRule: equal('firstAction.type', 'assign-conversation-to-owner'),
  isAssignmentRule: or(
    'isInboxAssignmentRule',
    'isDefaultInboxAssignmentRule',
    'isOwnerAssignmentRule',
  ),
  isTeamAssignmentRule: and('isAssignmentRule', 'firstAction.assignee.isTeam'),
  isRoundRobinAssignmentRule: and(
    'isTeamAssignmentRule',
    'firstAction.data.actionData.round_robin',
  ),

  isConversationTagRule: equal('type', 'inbox-tag'),
  isPriorityRule: equal('type', 'inbox-priority'),
  isConversationSlaRule: equal('type', 'inbox-sla'),

  isOperatorLeadQualificationRule: equal('type', 'operator-lead-qualification'),
  isOperatorUpfrontCollectionRule: equal('type', 'operator-upfront-collection'),
  isCustomBotRule: or('isCustomBotSimpleActionRule', 'isCustomBotFollowUpRule'),
  isCustomBotSimpleActionRule: equal('type', 'operator-simple-action-rule'),
  isCustomBotFollowUpRule: equal('type', 'operator-follow-up'),

  createdByAdmin: computed('createdByAdminId', function () {
    return Admin.peekAndMaybeLoad(this.store, this.createdByAdminId);
  }),
  updatedByAdmin: computed('updatedByAdminId', function () {
    return Admin.peekAndMaybeLoad(this.store, this.createdByAdminId);
  }),

  isNotExpanded: not('isExpanded'),
  isLive: computed('status', {
    get() {
      return isEqual(this.status, 'live');
    },
    set(key, value) {
      let newStatus = value ? 'live' : 'paused';
      this.set('status', newStatus);
      return isEqual(this.status, 'live');
    },
  }),

  analyticsData: computed('id', function () {
    return {
      object: 'rule',
      rule_id: this.id,
    };
  }),

  createdSuccessEventText: computed('entityString', function () {
    return `${dasherize(this.entityString)}-created`;
  }),

  updatedSuccessEventText: computed('entityString', function () {
    return `${dasherize(this.entityString)}-updated`;
  }),

  updateOrder() {
    return ajax({
      url: `/ember/inbox/conversation_rules/${this.subRouteString}/${this.id}/reorder`,
      type: 'PUT',
      data: JSON.stringify({
        app_id: this.appId,
        order: this.order,
      }),
    });
  },
  buildMetaDataForAnalytics(isCreate) {
    return {
      predicates: JSON.stringify(this.predicates),
    };
  },
});
