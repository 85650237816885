/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import ArticleSummary, {
  type ArticleSummaryWireFormat,
} from 'embercom/objects/inbox/article-summary';
import { EntityType } from 'embercom/models/data/entity-types';

interface ArticleWireFormat {
  renderable_type: RenderableType;
  article_summary: ArticleSummaryWireFormat;
}

export default class Article implements RenderableData {
  readonly renderableType = RenderableType.Chat;
  readonly articleSummary: ArticleSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(articleSummary: ArticleSummary) {
    this.articleSummary = articleSummary;
  }

  static deserialize(json: ArticleWireFormat): Article {
    let articleSummary = ArticleSummary.deserialize(json.article_summary);
    return new Article(articleSummary);
  }
}
