/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

const TEMPLATE_INTERFACE_ICONS = {
  1: 'qual-identify',
  2: 'clock',
  3: 'cash',
  4: 'qualify',
  5: 'chat-bubble-line',
  6: 'message',
  7: 'wave',
  8: 'active',
  9: 'rules',
};

const TEMPLATE_ILLUSTRATIVE_ICONS = {
  1: 'people',
  2: 'delivery-window',
  3: 'briefcase',
  4: 'warning',
  5: 'message',
  6: 'schedule',
  7: 'wave',
  8: 'activity',
  9: 'rule',
};

export default Model.extend({
  title: attr('string'),
  description: attr('string'),
  interfaceIcon: computed('id', function () {
    return TEMPLATE_INTERFACE_ICONS[this.id];
  }),
  illustrativeIcon: computed('id', function () {
    return TEMPLATE_ILLUSTRATIVE_ICONS[this.id];
  }),
  rolePredicates: attr('array'),
});
