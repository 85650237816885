/* RESPONSIBLE TEAM: team-frontend-tech */
function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  let results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default {
  features(url) {
    let featureFlags = getParameterByName('features', url);
    if (!featureFlags) {
      return [];
    }
    return featureFlags.split(',');
  },
  includes(featureKey, url) {
    return this.features(url).includes(featureKey);
  },
};
