/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  name: attr('string'),
  description: attr('string'),
  iconUrl: attr('string'),
  installUrl: attr('string'),
  isInstalled: attr('boolean'),
  categories: hasMany('appstore/category'),
  capabilities: attr(),
  analyticsData: computed('id', function () {
    return {
      object: 'app_package',
      app_package_code: this.id,
    };
  }),
});
