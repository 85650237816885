/* RESPONSIBLE TEAM: team-channels */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { set } from '@ember/object';

export default class BusinessProfileSettings extends Fragment {
  @attr('string') about;
  @attr('string') address;
  @attr('string') description;
  @attr('string') email;
  @attr('string') vertical;
  @attr('array') websites;

  get websitesCommaSeparated() {
    if (this.websites) {
      return this.websites.join(',');
    } else {
      return '';
    }
  }

  set websitesCommaSeparated(value) {
    if (value) {
      set(this, 'websites', value.split(','));
    } else {
      set(this, 'websites', []);
    }

    return;
  }
}
