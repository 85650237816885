/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { getOwner } from '@ember/application';
import { computed } from '@ember/object';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import pushEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/push';
import PushValidations from 'embercom/validations/mobile-push';

export const hasDeviceTokenPredicate = {
  attribute: 'has_device_token',
  type: 'boolean',
  comparison: 'true',
  value: '',
};

export const hasiOSSessionsPredicate = {
  attribute: 'session_count_ios',
  type: 'integer',
  comparison: 'gt',
  value: '0',
};
export const hasAndroidSessionsPredicate = {
  attribute: 'session_count_android',
  type: 'integer',
  comparison: 'gt',
  value: '0',
};

const implicitPredicatesForPush = [hasDeviceTokenPredicate];
const implicitPredicatesForAndroidFirstPush = [
  hasDeviceTokenPredicate,
  hasAndroidSessionsPredicate,
];
const implicitPredicatesForiOSFirstPush = [hasDeviceTokenPredicate, hasiOSSessionsPredicate];

export default Model.extend(PushValidations, {
  localizedPushContents: hasMany('mobile-push/localized-push-content', { inverse: 'push' }),
  preferredDevices: attr('array'),

  contentHash: computed('localizedPushContents.@each.contentHash', function () {
    return this.localizedPushContents.map(
      (localizedPushContent) => localizedPushContent.contentHash,
    );
  }),

  hasUnsavedChanges: computed(
    'hasDirtyAttributes',
    'localizedPushContents.@each.hasUnsavedChanges',
    function () {
      return (
        this.hasDirtyAttributes ||
        this.localizedPushContents.any(
          (localizedPushContent) => localizedPushContent.hasUnsavedChanges,
        )
      );
    },
  ),

  rollbackAttributes() {
    this._super(...arguments);
    this.localizedPushContents.forEach((content) => content.rollbackAttributes());
  },

  editorConfig() {
    let container = getOwner(this);
    return pushEditConfigGenerator({ container, push: this });
  },

  implicitPredicates: computed('preferredDevices', function () {
    // Any changes to this function should be made to the backend version too.
    if (
      this.preferredDevices.includes(preferredDevices.ios) &&
      this.preferredDevices.includes(preferredDevices.android)
    ) {
      return implicitPredicatesForPush;
    } else if (this.preferredDevices.includes(preferredDevices.android)) {
      return implicitPredicatesForAndroidFirstPush;
    } else if (this.preferredDevices.includes(preferredDevices.ios)) {
      return implicitPredicatesForiOSFirstPush;
    }
  }),
});
