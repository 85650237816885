/* RESPONSIBLE TEAM: team-ai-agent */
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';

export default class PreHandoverSummaryConfiguration extends StandardSummaryConfiguration {
  // @ts-expect-error
  init(inputs) {
    super.init({
      title: inputs.title,
      component: 'content-editor/summaries/custom/resolution-bot-behavior/pre-handover/summary',
      isVisible: true,
      titleCSSClasses: 'mt-8',
    });
  }
}
