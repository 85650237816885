/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const IntercomEmailAttributeName = 'email';
export const SalesforceEmailFieldName = 'Email';

export const syncDirection = {
  IntercomToCRM: 0,
  CrmtoIntercom: 1,
};

export const typeMapping = {
  string: {
    label: 'Text',
    to: [
      'string',
      'textarea',
      'phone',
      'url',
      'email',
      'reference',
      'encryptedstring',
      'picklist',
      'multipicklist',
    ],
    from: [
      'string',
      'textarea',
      'phone',
      'url',
      'email',
      'reference',
      'id',
      'address',
      'location',
      'int',
      'double',
      'currency',
      'percent',
      'boolean',
      'date',
      'datetime',
      'time',
      'picklist',
      'multipicklist',
    ],
  },
  conversation_attribute_list: {
    label: 'List',
    to: [
      'string',
      'textarea',
      'phone',
      'url',
      'email',
      'reference',
      'encryptedstring',
      'picklist',
      'multipicklist',
    ],
    from: ['List'],
  },
  integer: {
    label: 'Number',
    to: ['int', 'double', 'currency', 'percent', 'string', 'textarea', 'encryptedstring'],
    from: ['int', 'double', 'currency', 'percent'],
  },
  float: {
    label: 'Decimal Number',
    to: ['int', 'double', 'currency', 'percent', 'string', 'textarea', 'encryptedstring'],
    from: ['int', 'double', 'currency', 'percent'],
  },
  boolean: {
    label: 'True or False',
    to: ['boolean', 'string', 'textarea', 'encryptedstring'],
    from: ['boolean'],
  },
  date: {
    label: 'Date',
    to: ['date', 'datetime', 'time', 'string', 'textarea', 'encryptedstring'],
    from: ['date', 'datetime'],
  },
  datetime: {
    label: 'Datetime',
    to: ['date', 'datetime', 'time', 'string', 'textarea', 'encryptedstring'],
    from: ['date', 'datetime'],
  },
};
