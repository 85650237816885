/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import { copy } from 'ember-copy';
import { resolve } from 'rsvp';
import { isPresent, isEmpty } from '@ember/utils';
import $ from 'jquery';
import EmberObject from '@ember/object';
import { timeout } from 'ember-concurrency';
import { getContainer } from 'embercom/lib/container-lookup';
import { postRequest } from 'embercom/lib/inbox/requests';
import ENV from 'embercom/config/environment';

const ROUTE_LOCATIONS = [
  { regex: /apps.app.users.index/, name: `user_list` },
  { regex: /(app.users.segments)/, name: `user_list` },
  { regex: /(app.users.user)/, name: `user_profile` },
  { regex: /app.companies.segments/, name: `company_list` },
  { regex: /(apps.app.index)|(app.inbox)|(app.respond.inbox)|(app.respond.index)/, name: `inbox` },
  { regex: /apps.app.conversation/, name: `single_conversation` },
  { regex: /outbound.messages.(show|edit|index|message)/, name: `message_editor` },
  { regex: /outbound.messages.(auto|manual|visitor-auto).message/, name: `message_editor` },
  {
    regex:
      /outbound.messages.(auto.|manual.|visitor-auto.)?(all|live|paused|scheduled|draft|yours)/,
    name: `message_list`,
  },
  { regex: /articles.list/, name: `article_list` },
  { regex: /articles.article/, name: `article_editor` },
];

const INTERACTION_LOCATIONS = [
  { selector: `.js__main-container`, name: `main_content` },
  { selector: `.left-nav__icon .product-icon`, name: `left_nav_product` },
  { selector: `.left-nav__link.o__logo`, name: `left_nav_logo` },
];

export default EmberObject.extend({}).reopenClass({
  capture(metrics, delayInSeconds = 0) {
    if (!Em.testing) {
      return timeout(delayInSeconds * 1000).then(() => this.post('/frontend_stats', metrics));
    }
  },

  getGeneralLocationForRoute(routeName) {
    let location = 'other';
    let generalLocation = ROUTE_LOCATIONS.find((route) => route.regex.test(routeName));
    if (generalLocation) {
      location = generalLocation.name;
    }
    return location;
  },

  getInteractionLocationForTarget(target) {
    let $target = $(target);
    let location = 'other';
    let generalLocation = INTERACTION_LOCATIONS.find((location) =>
      isPresent($target.closest(location.selector)),
    );
    if (generalLocation) {
      location = generalLocation.name;
    }
    return location;
  },

  sendInteractionMetrics(metrics) {
    if (isEmpty(metrics)) {
      return resolve();
    }
    let copiedMetrics = metrics.map((metric) => copy(metric));
    return this.post('/ember/interaction_metrics', { metrics: copiedMetrics });
  },

  post(url, additionalData = {}) {
    let adminId = this._fetchCurrentAdminId();
    if (adminId) {
      let data = Object.assign(additionalData, {
        admin_id: adminId,
        app_id: this._fetchCurrentAppId(),
      });
      this._postRequest(url, data, { priority: 'low' }).then((response) => {
        if (ENV.environment !== 'test' && !response.ok) {
          console.error(`Failed to send interaction metrics`);
        } else {
          return response.json();
        }
      });
    } else {
      return resolve();
    }
  },

  //Visible for testing
  _postRequest(url, data, init) {
    return postRequest(url, data, init);
  },

  _fetchCurrentAdminId() {
    let adminController = getContainer().lookup('controller:apps');
    if (adminController && adminController.get('model.id')) {
      return adminController.get('model.id');
    }

    let session = getContainer().lookup('service:session');

    if (session?.isTeammateLoaded) {
      return session.teammate.id;
    }
  },

  _fetchCurrentAppId() {
    let appController = getContainer().lookup('controller:apps.app');
    if (appController && appController.get('model.id')) {
      return appController.get('model.id');
    }

    let session = getContainer().lookup('service:session');

    if (session?.isWorkspaceLoaded) {
      return session.workspace.id;
    }
  },
});
