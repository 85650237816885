/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { reads } from '@ember/object/computed';

export default RulesetLink.extend({
  object: belongsTo('emails/email', { async: false }),
  objectType: objectTypes.email,

  sendToUnsubscribed: reads('object.sendToUnsubscribed'),
  isLegallyMandated: reads('object.isLegallyMandated'),
});
