/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import type InboxState from 'embercom/services/inbox-state';
import { SimpleAction } from './action';

export default class BulkEditAction extends SimpleAction {
  @service declare inboxState: InboxState;

  get label() {
    let count = this.inboxState.selectedConversations.count;
    let id = count > 0 ? 'bulk-edit' : 'bulk-edit-unknown-selected';

    return this.intl.t(`inbox.command-k.actions.${id}`, {
      count,
    });
  }
}
