/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { belongsTo, attr } from '@ember-data/model';
import { seriesEdgeTypes } from 'embercom/models/data/matching-system/matching-constants';
import type EdgeSplit from './edge-split';
import type Store from '@ember-data/store';

type EdgeCreateParams = {
  store: Store;
  series: $TSFixMe;
  predecessor: $TSFixMe;
  successor: $TSFixMe;
  edgeType: number;
  edgeSplit: EdgeSplit;
  data: EdgeCreateData;
};

type EdgeCreateData = {
  series_id: number;
  predecessor_id: number;
  successor_id: number;
  edge_type: number;
  edge_split_id: number;
};

export default class SeriesEdge extends Model {
  @belongsTo('series/series', { inverse: 'edges' }) declare series: $TSFixMe;
  @belongsTo('series/node', { async: false }) declare predecessor: $TSFixMe;
  @belongsTo('series/node', { async: false }) declare successor: $TSFixMe;
  @belongsTo('series/edge-split', { async: false }) declare edgeSplit: EdgeSplit;
  @attr('number') declare edgeType: number;

  get isPrimary() {
    return this.edgeType === seriesEdgeTypes.primary;
  }

  get isAlternative() {
    return this.edgeType === seriesEdgeTypes.alternative;
  }

  get isSplit() {
    return this.edgeType === seriesEdgeTypes.split;
  }

  static createEdge(params: EdgeCreateParams) {
    let { store, series, predecessor, successor, edgeType, edgeSplit } = params;

    return store.createRecord('series/edge', {
      series,
      predecessor,
      successor,
      edgeType,
      edgeSplit,
    });
  }

  static createEdgeFromSerializedData(params: EdgeCreateParams) {
    let { store, data } = params;

    let series = store.peekRecord('series/series', data.series_id);
    let predecessor = store.peekRecord('series/node', data.predecessor_id);
    let successor = store.peekRecord('series/node', data.successor_id);
    let edgeType = data.edge_type;
    let edgeSplit = data.edge_split_id
      ? store.peekRecord('series/edge_split', data.edge_split_id)
      : null;

    return store.createRecord('series/edge', {
      series,
      predecessor,
      successor,
      edgeType,
      edgeSplit,
    });
  }
}
