/* RESPONSIBLE TEAM: team-proactive-support */
import {
  KnowledgeBaseSearchResult,
  type KnowledgeBaseSearchResultWireFormat,
} from 'embercom/objects/inbox/knowledge-base/search-result';
import { type KnowledgeBaseContent } from './content';

export const FOLDER_FALLBACK_EMOJI = '📁';

export interface KnowledgeBaseFolderConstructorFormat {
  readonly id: number;
  readonly title: string;
  readonly description?: string;
  readonly emoji?: string;
  readonly parentFolderId?: number;
  readonly contents: KnowledgeBaseSearchResult[];
}

export interface KnowledgeBaseFolderWireFormat {
  readonly id: number;
  readonly title: string;
  readonly description?: string;
  readonly emoji?: string;
  readonly parent_folder_id?: number;
  readonly contents: KnowledgeBaseSearchResultWireFormat[];
}

export class KnowledgeBaseFolder {
  readonly id: number;
  readonly title: string;
  readonly description?: string;
  readonly emoji?: string;
  readonly parentFolderId?: number;
  readonly contents: KnowledgeBaseSearchResult[];

  constructor(inputs: KnowledgeBaseFolderConstructorFormat) {
    this.id = inputs.id;
    this.title = inputs.title;
    this.description = inputs.description;
    this.emoji = inputs.emoji || FOLDER_FALLBACK_EMOJI;
    this.parentFolderId = inputs.parentFolderId;
    this.contents = inputs.contents;
  }

  static deserialize(json: KnowledgeBaseFolderWireFormat) {
    return new KnowledgeBaseFolder({
      id: json.id,
      title: json.title,
      description: json.description,
      emoji: json.emoji,
      parentFolderId: json.parent_folder_id,
      contents: json.contents.map(KnowledgeBaseSearchResult.deserialize),
    });
  }

  get isRoot(): boolean {
    return !this.parentFolderId;
  }

  containsContent(selectedContent: KnowledgeBaseContent) {
    return this.contents.find(
      (content) =>
        content.entityId === selectedContent.entityId &&
        content.entityType === selectedContent.entityType,
    );
  }
}
