/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Block from './block';
import { fragment } from 'ember-data-model-fragments/attributes';

export default Block.extend({
  text: attr('string'),
  align: attr('string'),
  linkUrl: attr('string'),
  buttonStyle: attr('string'),
  style: fragment('common/blocks/style'),
  trackingLinkUrl: attr('string'),
});
