/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export const states = {
  inactive: 0,
  active: 1,
};

export const defaultGoalMatchingTime = 72;

export const timeConstraints = [
  { text: '1 Day', value: 24 },
  { text: '3 Days', value: 72 },
  { text: '7 Days', value: 168 },
  { text: '30 Days', value: 720 },
];

export const seriesGoalTimeConstraints = [
  ...timeConstraints,
  { text: '3 Months', value: 2160 },
  { text: '6 Months', value: 4320 },
];

export const timeConstraintsByEntity = {
  [objectTypes.ruleset]: timeConstraints,
  [objectTypes.series]: seriesGoalTimeConstraints,
  [objectTypes.splitter]: seriesGoalTimeConstraints,
};
