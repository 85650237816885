/* RESPONSIBLE TEAM: team-ai-agent */
import Model, { attr } from '@ember-data/model';

export default class ArticleSuggestion extends Model {
  @attr('boolean') declare enabledForUsers: boolean;
  @attr('boolean') declare enabledForLeads: boolean;

  get isNotDirty() {
    return !this.hasDirtyAttributes;
  }
}
