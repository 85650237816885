/* RESPONSIBLE TEAM: team-workflows */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class StepData extends Fragment {
  @attr('string') resolutionBotFollowUpMode;
  @attr() previewCanvas;
  @attr('string') previewMessengerCardUri;
  @attr() cardCreationParams;
  @attr() customObjectSelectorParams;
  @attr('string') endingType;
  @attr('string') actionId;
  @attr('boolean') attributeCollectionOverwritable;
  @attr('boolean') attributeCollectionDisabled;
  @attr('string') ticketTypeId;
  @attr('boolean') disableComposer;
}
