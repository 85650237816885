/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

const DEFAULT_BACKGROUND = 'background-1';

export default Model.extend({
  admin_messenger_background: attr('string'),
  away_mode_reassign_team_id: attr('string'),
  favoriteReports: attr('array'),
  adminMessengerBackground: computed('admin_messenger_background', {
    get() {
      return this.admin_messenger_background || DEFAULT_BACKGROUND;
    },
    set(key, value) {
      this.set('admin_messenger_background', value);
      return value || DEFAULT_BACKGROUND;
    },
  }),
});
