/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Model, { attr, belongsTo } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type Metric } from 'embercom/models/data/pricing/metric-types';
import type Price from 'embercom/models/price';

type WorkspaceUsage = {
  app_id: string;
  app_name: string;
  usage: Partial<Record<Metric, number>>;
};

export default class Contract extends Model {
  @service declare intl: IntlService;

  @attr() declare appId: string;
  @attr('boolean') declare active: boolean;
  @fragment('billing/subscription') declare primarySubscription: any; // Subscription fragment is written in legacy Ember which is awkward with TypeScript, so typing as 'any' for now
  @attr() declare annual: boolean;
  @attr() declare billingPeriodDurationInMonths: number;
  @attr() declare usageLimitAgreement: any;
  @attr() declare secondarySubscriptions: any;
  @attr() declare billedUsage: Partial<Record<Metric, number>>;
  @attr() declare totalUsage: Partial<Record<Metric, number>>;
  @attr('array') declare perWorkspaceUsage: WorkspaceUsage[];
  @attr() declare isPrimarySubscription: boolean;
  @attr() declare isSecondarySubscription: boolean;
  @attr() declare primaryWorkspaceAppIdCode: string;
  @attr() declare primaryWorkspaceAppName: string;
  @attr() declare contractUsageLimits: Partial<Record<Metric, number>>;
  @attr() declare numberOfSecondarySubscriptions: number;
  @attr() declare billingSnapshotLastUpdatedAt: Date;
  @attr('date') declare contractCancellationDate: Date;
  @attr('date') declare contractRenewalDate: Date;
  @attr('boolean') declare contractAutoRenew: boolean;
  @belongsTo('price', { async: false, inverse: null }) declare currentPrice: Price;

  get contractType() {
    if (this.isPrimarySubscription) {
      return this.intl.t('app.models.contract.type.primary');
    } else if (this.isSecondarySubscription) {
      return this.intl.t('app.models.contract.type.secondary');
    }

    return null;
  }

  get daysToRenewal() {
    let today = moment().startOf('day');
    let renewalDay = moment(this.contractRenewalDate).startOf('day');
    let diff = renewalDay.diff(today);
    return Math.round(moment.duration(diff).asDays());
  }

  get inRenewalPeriod() {
    return this.daysToRenewal <= 60 && this.daysToRenewal >= 0;
  }

  get inAutoRenewalPeriod() {
    return this.isPrimarySubscription && this.contractAutoRenew && this.inRenewalPeriod;
  }

  get hasAutoRenewed() {
    return this.inAutoRenewalPeriod && this.daysToRenewal < 30;
  }

  get autoRenewalDate() {
    if (this.contractAutoRenew) {
      return moment(this.contractRenewalDate).subtract(30, 'd');
    }
    return null;
  }

  get hasInvalidRenewalDate() {
    return (
      this.contractRenewalDate === null ||
      moment.utc(this.contractRenewalDate).startOf('day') < moment.utc().startOf('day')
    );
  }

  get cancellationScheduled() {
    return (
      this.contractCancellationDate &&
      moment.utc(this.contractCancellationDate).startOf('day') >= moment.utc().startOf('day')
    );
  }

  get billingCadence() {
    return this.primarySubscription.billingCadence;
  }

  get isBilledMonthly() {
    return this.primarySubscription.isBilledMonthly;
  }
}
