/* RESPONSIBLE TEAM: team-ai-agent */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { computed } from '@ember/object';

export default Fragment.extend({
  conversationCount: attr('number'),
  allDoneCount: attr('number'),
  abandonedCount: attr('number'),
  waitForTeamCount: attr('number'),
  effectivenessPercentage: computed('allDoneCount', 'conversationCount', function () {
    if (this.conversationCount === 0) {
      return null;
    }
    return (this.allDoneCount / this.conversationCount) * 100;
  }),
  abandonedPercentage: computed('abandonedCount', 'conversationCount', function () {
    if (this.conversationCount === 0) {
      return null;
    }
    return (this.abandonedCount / this.conversationCount) * 100;
  }),
  waitForTeamPercentage: computed('waitForTeamCount', 'conversationCount', function () {
    if (this.conversationCount === 0) {
      return null;
    }
    return (this.waitForTeamCount / this.conversationCount) * 100;
  }),
  upperConfidenceInterval: computed('conversationCount', 'allDoneCount', function () {
    if (this.conversationCount === 0 || this.allDoneCount === 0) {
      return 0;
    }

    let proportionResolved = this.allDoneCount / this.conversationCount;
    let upperConfidenceInterval =
      proportionResolved +
      1.96 * Math.sqrt((proportionResolved * (1 - proportionResolved)) / this.conversationCount);
    return upperConfidenceInterval;
  }),
  isUnderperforming: computed('conversationCount', 'upperConfidenceInterval', function () {
    if (this.conversationCount < 10) {
      return false;
    } else {
      return this.upperConfidenceInterval < 0.2;
    }
  }),
});
