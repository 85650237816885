/* RESPONSIBLE TEAM: team-help-desk-experience */

import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';

export default class AutoSummarizeLimitReached implements RenderableData {
  readonly renderableType = RenderableType.AutoSummarizeLimitReached;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  static deserialize(): AutoSummarizeLimitReached {
    return new AutoSummarizeLimitReached();
  }
}
