/* RESPONSIBLE TEAM: team-workflows */
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';

export default class Trigger {
  @service intl;
  @service appService;

  constructor(owner) {
    setOwner(this, owner);
  }

  get items() {
    let items = [
      {
        value: 'inbound_conversation_started',
        text: this.intl.t('settings.inbox-rules.triggers.inbound-conversation-started.text'),
        description: this.intl.t(
          'settings.inbox-rules.triggers.inbound-conversation-started.description',
        ),
        icon: 'download-conversation',
      },
      {
        value: 'reply_to_outbound',
        text: this.intl.t('settings.inbox-rules.triggers.reply-to-outbound.text'),
        description: this.intl.t('settings.inbox-rules.triggers.reply-to-outbound.description'),
        icon: 'reply',
      },
      {
        value: 'any_user_comment',
        text: this.intl.t('settings.inbox-rules.triggers.any-user-comment.text'),
        description: this.intl.t('settings.inbox-rules.triggers.any-user-comment.description'),
        icon: 'conversation',
      },
      {
        value: 'awaiting_first_reply',
        componentPath: 'awaiting-first-reply',
        text: this.intl.t('settings.inbox-rules.triggers.awaiting-first-reply.text'),
        description: this.intl.t('settings.inbox-rules.triggers.awaiting-first-reply.description'),
        icon: 'sla',
      },
      {
        value: 'awaiting_end_user_reply',
        componentPath: 'awaiting-end-user-reply',
        text: this.intl.t('settings.inbox-rules.triggers.awaiting-end-user-reply.text'),
        description: this.intl.t(
          'settings.inbox-rules.triggers.awaiting-end-user-reply.description',
        ),
        icon: 'sla',
      },
      {
        value: 'outbound_ticket_created',
        text: this.intl.t('settings.inbox-rules.triggers.outbound-ticket-created.text'),
        description: this.intl.t(
          'settings.inbox-rules.triggers.outbound-ticket-created.description',
        ),
        icon: 'download-conversation',
      },
      {
        value: 'ticket_state_updated',
        text: this.intl.t('settings.inbox-rules.triggers.ticket-state-updated.text'),
        description: this.intl.t('settings.inbox-rules.triggers.ticket-state-updated.description'),
        icon: 'ticket',
      },
    ];

    if (this.appService.app.hasSlackNotificationsIntegration) {
      items.push({
        value: 'conversation_status_changed',
        text: this.intl.t('settings.inbox-rules.triggers.conversation-status-changed.text'),
        description: this.intl.t(
          'settings.inbox-rules.triggers.conversation-status-changed.description',
        ),
        icon: 'chat-filled',
      });
      items.push({
        value: 'attribute_updated',
        text: this.intl.t('settings.inbox-rules.triggers.attribute-updated.text'),
        description: this.intl.t('settings.inbox-rules.triggers.attribute-updated.description'),
        icon: 'ticket',
      });
      items.push({
        value: 'admin_note_created',
        text: this.intl.t('settings.inbox-rules.triggers.admin-note-created.text'),
        description: this.intl.t('settings.inbox-rules.triggers.admin-note-created.description'),
        icon: 'inapp-note',
      });
      items.push({
        value: 'any_teammate_comment',
        text: this.intl.t('settings.inbox-rules.triggers.any-teammate-comment.text'),
        description: this.intl.t('settings.inbox-rules.triggers.any-teammate-comment.description'),
        icon: 'conversation',
      });

      if (this.appService.app.canUseSLAAdvanceWarningTrigger) {
        items.push({
          value: 'sla_breach_advance_warning',
          text: this.intl.t('settings.inbox-rules.triggers.sla-breach-advance-warning.text'),
          description: this.intl.t(
            'settings.inbox-rules.triggers.sla-breach-advance-warning.description',
          ),
          icon: 'sla',
        });
      }
    }

    return items;
  }
}

export const INACTIVITY_TRIGGERS_VALUES = ['awaiting_end_user_reply', 'awaiting_first_reply'];
