/* RESPONSIBLE TEAM: team-customer-data-platform */
import { attr } from '@ember-data/model';
import Import from './import';

export default Import.extend({
  accessToken: attr(),
  secureAccessToken: attr(),
  listId: attr(),
  adminId: attr(),
  userCount: attr(),

  lists: null,
});
