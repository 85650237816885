/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  intercomAppIdCode: attr('string'),
  intercomAdminId: attr('string'),
  accountEmail: attr('string'),
  hasIntegratedCalendar: attr('boolean'),
});
