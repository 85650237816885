/* RESPONSIBLE TEAM: team-product-setup */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const BIZZ_FIN_ICONS = [
  'currency-pound',
  'briefcase',
  'building-office-2',
  'currency-rupee',
  'currency-bangladeshi',
  'wallet',
  'scale',
  'currency-yen',
  'currency-euro',
  'banknotes',
  'credit-card',
  'circle-stack',
  'building-storefront',
  'building-library',
  'currency-dollar',
];

const bizzFinCommonKeywords = [
  'circle',
  'currency',
  'money',
  'finance',
  'payment',
  'business',
  'coin',
];

export const BIZZ_FIN_KEYWORDS = {
  [BIZZ_FIN_ICONS[0]]: [...bizzFinCommonKeywords, 'pound', '£'],
  [BIZZ_FIN_ICONS[1]]: [
    'briefcase',
    'business',
    'work',
    'office',
    'professional',
    'employment',
    'finance',
    'portfolio',
    'career',
    'employment',
    'travel',
  ],
  [BIZZ_FIN_ICONS[2]]: [
    'office',
    'skyscraper',
    'architecture',
    'commercial',
    'urban',
    'workspace',
    'business',
    'corporate',
    'tower',
    'cityscape',
    'structure',
    'realestate',
    'company',
  ],
  [BIZZ_FIN_ICONS[3]]: [...bizzFinCommonKeywords, 'rupee', '₹'],
  [BIZZ_FIN_ICONS[4]]: [...bizzFinCommonKeywords, 'bangladeshi', 'taka'],
  [BIZZ_FIN_ICONS[5]]: [
    'wallet',
    'money',
    'purse',
    'currency',
    'finance',
    'payment',
    'cash',
    'account',
    'personal',
    'holder',
    'bank',
    'financial',
    'storage',
    'commerce',
  ],
  [BIZZ_FIN_ICONS[6]]: [
    'scale',
    'balance',
    'measurement',
    'weight',
    'gauge',
    'precision',
    'metrics',
    'tools',
    'calibration',
    'equal',
    'justice',
    'trade-off',
    'common',
  ],
  [BIZZ_FIN_ICONS[7]]: [...bizzFinCommonKeywords, 'japan', 'yen', '¥'],
  [BIZZ_FIN_ICONS[8]]: [...bizzFinCommonKeywords, 'euro', '€'],
  [BIZZ_FIN_ICONS[9]]: [
    'currency',
    'money',
    'cash',
    'paper',
    'finance',
    'funds',
    'payment',
    'bill',
    'notes',
    'financial',
    'economy',
    'banking',
    'wealth',
  ],
  [BIZZ_FIN_ICONS[10]]: [
    'payment',
    'finance',
    'banking',
    'transaction',
    'credit',
    'debit',
    'commerce',
    'financial',
    'purchase',
    'account',
    'security',
    'card',
  ],
  [BIZZ_FIN_ICONS[11]]: [
    'coins',
    'currency',
    'money',
    'finance',
    'stack',
    'wealth',
    'economy',
    'payment',
    'cash',
    'financial',
    'savings',
    'investment',
    'funds',
    'savings',
  ],
  [BIZZ_FIN_ICONS[12]]: [
    'storefront',
    'retail',
    'shop',
    'business',
    'commercial',
    'building',
    'architecture',
    'shopping',
    'market',
    'store',
    'urban',
    'commerce',
  ],
  [BIZZ_FIN_ICONS[13]]: [
    'building',
    'library',
    'bookstore',
    'education',
    'academic',
    'study',
    'books',
    'public',
    'learn',
    'information',
    'institution',
    'knowledge',
    'reading',
  ],
  [BIZZ_FIN_ICONS[14]]: [...bizzFinCommonKeywords, 'dollar', 'american', '$'],
};
