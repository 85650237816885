/* RESPONSIBLE TEAM: team-customer-data-platform */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class Attribute extends Fragment {
  @attr('string') type;
  @attr('string') path;
  @attr('string') label;
  @attr('boolean') mappable;
  @attr('boolean') custom;
  @attr('array') mappableTypes;
  @attr('string') crmObjectType;
}
