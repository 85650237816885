/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  alignment: attr('string'),
  textColor: attr('string'),
  type: attr('string'),
  applyDefaultsForParagraph() {
    this.setProperties({
      type: 'text',
      alignment: 'left',
      textColor: '#000000',
    });
  },
});
