/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export enum CardStyleType {
  BORDERED = 'bordered',
  FLOATING = 'floating',
}

export default class CardStyle extends Fragment {
  @attr('string') declare type: CardStyleType;
  @attr('number') declare borderRadius: number;
}
