/* RESPONSIBLE TEAM: team-frontend-tech */
export default [
  {
    textTranslationKey: 'app.models.data.time-periods.everyday',
    value: 'everyday',
  },
  {
    textTranslationKey: 'app.models.data.time-periods.weekdays',
    value: 'weekdays',
  },
  {
    textTranslationKey: 'app.models.data.time-periods.weekends',
    value: 'weekends',
  },
  {
    textTranslationKey: 'app.models.data.time-periods.monday',
    value: 'monday',
  },
  {
    textTranslationKey: 'app.models.data.time-periods.tuesday',
    value: 'tuesday',
  },
  {
    textTranslationKey: 'app.models.data.time-periods.wednesday',
    value: 'wednesday',
  },
  {
    textTranslationKey: 'app.models.data.time-periods.thursday',
    value: 'thursday',
  },
  {
    textTranslationKey: 'app.models.data.time-periods.friday',
    value: 'friday',
  },
  {
    textTranslationKey: 'app.models.data.time-periods.saturday',
    value: 'saturday',
  },
  {
    textTranslationKey: 'app.models.data.time-periods.sunday',
    value: 'sunday',
  },
];
