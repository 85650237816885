/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { not } from '@ember/object/computed';

export default Model.extend({
  canvasCreationOptionsForLeads: attr(),
  canvasCreationOptionsForUsers: attr(),
  canvasForLeads: attr(),
  canvasForUsers: attr(),
  enabledForLeads: attr('boolean'),
  enabledForUsers: attr('boolean'),
  enabledForLeadsOsc: attr('boolean'),
  enabledForUsersOsc: attr('boolean'),
  enabledForLeadsOutboundCustomBots: attr('boolean'),
  enabledForUsersOutboundCustomBots: attr('boolean'),
  blockUpdateAfterForUsers: attr('number'),
  blockUpdateAfterForLeads: attr('number'),
  blockRatingAfterForUsers: attr('number'),
  blockRatingAfterForLeads: attr('number'),

  isValid: true,
  isNotDirty: not('hasDirtyAttributes'),

  refresh() {
    this.rollbackAttributes();
  },
});
