/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Tour from '@intercom/intersection-shared-models/models/tour';
import { getOwner } from '@ember/application';
import { fragment } from 'ember-data-model-fragments/attributes';
import EmbercomOnlyTourValidations from 'embercom/validations/tour';
import Admin from 'embercom/models/admin';
import { dependentKeyCompat } from '@ember/object/compat';
import productTourEditorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/product-tour';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class TourModel extends Tour.extend(EmbercomOnlyTourValidations) {
  @attr() assigneeId;
  @attr() fromId;
  @attr() buttonColor;
  @fragment('tours/client-data') clientData;
  @fragment('stats-system/tour-counts') stats;

  @service appService;

  get app() {
    return this.appService.app;
  }

  get assignTo() {
    return Admin.peekAndMaybeLoad(this.store, this.assigneeId);
  }

  get from() {
    return Admin.peekAndMaybeLoad(this.store, this.fromId);
  }

  get hasCompanyAttributes() {
    return this.steps.any((step) => step.hasCompanyAttributes);
  }

  @dependentKeyCompat
  get implicitPredicates() {
    return [];
  }

  @dependentKeyCompat
  get canStartInserting() {
    return this.clientData?.insertable && this.validations.isValid;
  }

  get isInsertableOnly() {
    return this.insertable && !this.clientData.showAutomatically;
  }

  get stepsUpdatedAt() {
    let updatedAts = this.steps.map((step) => step.updatedAt);
    return updatedAts.reduce((a, b) => (new Date(a) > new Date(b) ? a : b));
  }

  get copyableUrl() {
    let url = this.insertableUrl;

    if (isPresent(url)) {
      let hasParams = url.indexOf('?') > -1;
      let tourParam = `product_tour_id=${this.id}`;
      let tourParamPrefix = hasParams ? '&' : '?';
      url = `${url}${tourParamPrefix}${tourParam}`;
    }

    return url;
  }

  static getLocalModel(params) {
    let { store, tourId } = params;
    return store.peekRecord('tour', tourId);
  }

  showStat(statName) {
    return !(statName === 'completion' && this.get('steps.length') === 1);
  }

  editorConfig() {
    let container = getOwner(this);
    return productTourEditorConfigGenerator({ container, productTour: this });
  }

  beforeSave() {
    this.insertable = this.canStartInserting;
  }
}
