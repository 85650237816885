/* RESPONSIBLE TEAM: team-messenger */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { defaultTitle, defaultParagraph } from 'embercom/models/data/carousel/default-blocks';
import { actionToTypeMap, actionToTitleMap } from 'embercom/models/data/carousel/action-constants';
import intercomMessengerColors from '@intercom/intercom-messenger-colors';
import rgbaToHex from 'embercom/lib/rgba-to-hex';
import CarouselScreenValidations from 'embercom/validations/carousel-screen';
import { isNone } from '@ember/utils';

export default class CarouselScreen extends Model.extend(CarouselScreenValidations) {
  @service store;
  @hasMany('carousels/carousel-action', { inverse: 'carouselScreen', async: false })
  carouselActions;
  @belongsTo('carousels/localized-carousel-content', { inverse: 'carouselScreens', async: false })
  localizedCarouselContent;
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' }) blocks;
  @attr('string', { defaultValue: '#ffffff' }) backgroundColor;
  @attr('string', { defaultValue: '#222222' }) textColor;
  @attr('string', { defaultValue: 'top' }) verticalAlignment;
  @attr('number') order;
  @attr('string', { defaultValue: null }) templateId;

  deletedActions = [];

  init() {
    super.init(...arguments);
    this._setupDefaultScreen();
  }

  get isLastScreen() {
    return this.order === this.localizedCarouselContent.get('carouselScreens').length - 1;
  }

  get sortedCarouselActions() {
    return this.carouselActions.sortBy('order').reverseObjects();
  }

  get requestsPermission() {
    return this.primaryAction.type.includes('permission');
  }

  get primaryAction() {
    return this.carouselActions.find((action) => action.isPrimary);
  }

  _setupDefaultScreen() {
    if (isEmpty(this.blocks)) {
      let headingBlock = this.store.createFragment('common/blocks/heading', defaultTitle);
      let paragraphBlock = this.store.createFragment('common/blocks/paragraph', defaultParagraph);
      this.blocks = [headingBlock, paragraphBlock];
    }
    if (isEmpty(this.carouselActions)) {
      this.createPrimaryAction('dismiss');
    }
  }

  beforeSave() {
    this._clearEmptyImageTextBlocks();
  }

  afterSave() {
    this._clearUnsavedCarouselScreenActions();
  }

  rollbackAttributes() {
    this._clearUnsavedCarouselScreenActions();
    this.deletedActions.forEach((action) => this.carouselActions.pushObject(action));
    this.carouselActions.forEach((action) => action.rollbackAttributes());
    this.carouselActions = this.carouselActions.sortBy('order');
    super.rollbackAttributes();
    this.deletedActions = [];
  }

  createPrimaryAction(action) {
    return this._createAction(action, 0, this.generatePrimaryActionColors());
  }

  createSecondaryAction(action) {
    return this._createAction(action, 1, this.generateSecondaryActionColors());
  }

  generatePrimaryActionColors() {
    let messengerColors = this._messengerColors();
    if (messengerColors) {
      return {
        backgroundColor: rgbaToHex(messengerColors.button_background_color).substring(0, 7),
        textColor: rgbaToHex(messengerColors.button_text_color).substring(0, 7),
      };
    } else {
      return {};
    }
  }

  generateSecondaryActionColors() {
    let messengerColors = this._messengerColors();
    if (messengerColors) {
      return {
        backgroundColor: rgbaToHex(messengerColors.quick_reply_background_color).substring(0, 7),
        textColor: rgbaToHex(messengerColors.quick_reply_text_color).substring(0, 7),
      };
    } else {
      return {};
    }
  }

  clearActions() {
    this.carouselActions.forEach((action) => {
      if (action.id) {
        this.deletedActions.push(action);
      }
    });
    this.carouselActions.clear();
  }

  _messengerColors() {
    let primaryColor = this.get('localizedCarouselContent.carousel.actionsColor');
    if (primaryColor) {
      return intercomMessengerColors({
        primaryColor,
        darkenAmount: 10,
      });
    }
  }

  _createAction(action, order, colors) {
    let actionRecord = this.store.createRecord('carousels/carousel-action', {
      type: actionToTypeMap[action],
      title: actionToTitleMap[action],
      order,
      backgroundColor: colors.backgroundColor,
      textColor: colors.textColor,
    });
    this.carouselActions.pushObject(actionRecord);
    return actionRecord;
  }

  _clearUnsavedCarouselScreenActions() {
    this.carouselActions
      .filter((carouselAction) => isNone(carouselAction.id))
      .forEach((carouselAction) => {
        this.store.unloadRecord(carouselAction);
      });
  }

  _clearEmptyImageTextBlocks() {
    this.blocks
      .filter(
        (block) => block.type === 'imageText' && (isNone(block.text) || isNone(block.imageUrl)),
      )
      .forEach((block) => {
        this.blocks.removeObject(block);
      });
  }
}
