/* RESPONSIBLE TEAM: team-help-desk-experience */
import {
  type RenderableData,
  type LinkedTicketWireFormat,
  LinkedTicket,
} from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { EntityType } from 'embercom/models/data/entity-types';
import { Upload, type UploadWireFormat } from 'embercom/objects/inbox/renderable/upload';

interface AdminNoteWireFormat {
  renderable_type: RenderableType;
  blocks: Array<object>;
  admin_summary: AdminSummaryWireFormat;
  uploads?: Array<UploadWireFormat>;
  linked_ticket?: LinkedTicketWireFormat;
}

export default class AdminNote implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.AdminNote;
  readonly blocks: Array<object>;
  readonly adminSummary: AdminSummary;
  readonly uploads?: Array<Upload>;
  readonly linkedTicket?: LinkedTicket;

  get teammate() {
    return this.adminSummary;
  }

  get creatingEntity() {
    return { type: EntityType.Admin, id: this.adminSummary.id, object: this.teammate };
  }

  get styles() {
    return {
      backgroundColor: 'bg-yellow-light dark:bg-dm-yellow-dark',
      textColor: 'text-yellow-dark dark:text-dm-yellow-light',
      backgroundColorHover: 'inbox2__light-and-dark-yellow-hover-background-color',
    };
  }

  get ticketStyles() {
    return {
      backgroundColor: 'bg-white dark:bg-black',
      textColor: 'text-black dark:text-dm-white',
      backgroundColorHover: 'bg-gray-lightest dark:bg-dm-gray-darker',
    };
  }

  constructor(
    blocks: Array<object>,
    adminSummary: AdminSummary,
    uploads?: Array<Upload>,
    linkedTicket?: LinkedTicket,
  ) {
    this.blocks = blocks;
    this.adminSummary = adminSummary;
    this.uploads = uploads || [];
    this.linkedTicket = linkedTicket;
  }

  static deserialize(json: AdminNoteWireFormat): AdminNote {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    let linkedTicket = json.linked_ticket
      ? LinkedTicket.deserialize(json.linked_ticket)
      : undefined;
    return new AdminNote(
      json.blocks,
      adminSummary,
      json.uploads?.map(Upload.deserialize),
      linkedTicket,
    );
  }
}
