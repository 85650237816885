/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr } from '@ember-data/model';
import STRICTNESS from 'embercom/lib/identity-verification-strictness';
import { capitalize } from '@ember/string';

export default class PingDomainSettingsModel extends Model {
  @attr() pingDomainId;
  @attr() identityVerificationEnabled;
  @attr() identityVerificationStrictness;

  get identityVerificationStrictnessLabel() {
    return capitalize(STRICTNESS[this.identityVerificationStrictness].label);
  }
}
