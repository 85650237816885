/* RESPONSIBLE TEAM: team-help-desk-experience */
export interface GeoipDataWireFormat {
  city_name: string;
  country_code2: string;
  country_name: string;
  region_code: string;
  timezone: string;
}

export default class GeoipData {
  readonly cityName: string;
  readonly countryCode2: string;
  readonly countryName: string;
  readonly regionCode: string;
  readonly timezone: string;

  constructor(
    cityName: string,
    countryCode2: string,
    countryName: string,
    regionCode: string,
    timezone: string,
  ) {
    this.cityName = cityName;
    this.countryCode2 = countryCode2;
    this.countryName = countryName;
    this.regionCode = regionCode;
    this.timezone = timezone;
  }

  hasData() {
    return (
      this.cityName || this.countryCode2 || this.countryName || this.regionCode || this.timezone
    );
  }

  static deserialize(json: GeoipDataWireFormat): GeoipData {
    return new GeoipData(
      json.city_name,
      json.country_code2,
      json.country_name,
      json.region_code,
      json.timezone,
    );
  }
}
