/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default [
  { text: '12:00 am', value: 0 * 30 },
  { text: '12:30 am', value: 1 * 30 },
  { text: '1:00 am', value: 2 * 30 },
  { text: '1:30 am', value: 3 * 30 },
  { text: '2:00 am', value: 4 * 30 },
  { text: '2:30 am', value: 5 * 30 },
  { text: '3:00 am', value: 6 * 30 },
  { text: '3:30 am', value: 7 * 30 },
  { text: '4:00 am', value: 8 * 30 },
  { text: '4:30 am', value: 9 * 30 },
  { text: '5:00 am', value: 10 * 30 },
  { text: '5:30 am', value: 11 * 30 },
  { text: '6:00 am', value: 12 * 30 },
  { text: '6:30 am', value: 13 * 30 },
  { text: '7:00 am', value: 14 * 30 },
  { text: '7:30 am', value: 15 * 30 },
  { text: '8:00 am', value: 16 * 30 },
  { text: '8:30 am', value: 17 * 30 },
  { text: '9:00 am', value: 18 * 30 },
  { text: '9:30 am', value: 19 * 30 },
  { text: '10:00 am', value: 20 * 30 },
  { text: '10:30 am', value: 21 * 30 },
  { text: '11:00 am', value: 22 * 30 },
  { text: '11:30 am', value: 23 * 30 },
  { text: '12:00 pm', value: 24 * 30 },
  { text: '12:30 pm', value: 25 * 30 },
  { text: '1:00 pm', value: 26 * 30 },
  { text: '1:30 pm', value: 27 * 30 },
  { text: '2:00 pm', value: 28 * 30 },
  { text: '2:30 pm', value: 29 * 30 },
  { text: '3:00 pm', value: 30 * 30 },
  { text: '3:30 pm', value: 31 * 30 },
  { text: '4:00 pm', value: 32 * 30 },
  { text: '4:30 pm', value: 33 * 30 },
  { text: '5:00 pm', value: 34 * 30 },
  { text: '5:30 pm', value: 35 * 30 },
  { text: '6:00 pm', value: 36 * 30 },
  { text: '6:30 pm', value: 37 * 30 },
  { text: '7:00 pm', value: 38 * 30 },
  { text: '7:30 pm', value: 39 * 30 },
  { text: '8:00 pm', value: 40 * 30 },
  { text: '8:30 pm', value: 41 * 30 },
  { text: '9:00 pm', value: 42 * 30 },
  { text: '9:30 pm', value: 43 * 30 },
  { text: '10:00 pm', value: 44 * 30 },
  { text: '10:30 pm', value: 45 * 30 },
  { text: '11:00 pm', value: 46 * 30 },
  { text: '11:30 pm', value: 47 * 30 },
  { text: '11:59 pm', value: 48 * 30 - 1 },
];
