/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { assert } from '@ember/debug';
import { contentEditors } from 'embercom/models/data/matching-system/matching-constants';

export default EmberObject.extend({
  name: undefined,
  header: undefined,
  body: undefined,
  supportedEditors: [contentEditors.standalone],
  isDisabled: false,

  init(inputs) {
    this._super(...arguments);
    assert('A name must be provided for a panel configuration', inputs.name);
    assert('A header must be provided for a panel configuration', inputs.header);
    assert('A body must be provided for a panel configuration', inputs.body);

    assert(
      `The header component path used for this panel configuration must be within the 'content-editor/panels/${inputs.name}' directory. You passed ${inputs.header.component}`,
      inputs.header.component.startsWith(`content-editor/panels/${inputs.name}`),
    );
    inputs.header.parentConfiguration = this;

    assert(
      `The body component path used for this panel configuration must be within the 'content-editor/panels/${inputs.name}' directory. You passed ${inputs.body.component}`,
      inputs.body.component.startsWith(`content-editor/panels/${inputs.name}`),
    );
    inputs.body.parentConfiguration = this;

    this.setProperties(inputs);
  },
});
