/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export type SubscriptionType =
  | 'self-serve'
  | 'self-serve-annual-contract'
  | 'self-serve-annual-overage'
  | 'sales-overage'
  | 'sales-contract';

export default class SubscriptionDetails extends Fragment {
  @attr declare metadata: { type: SubscriptionType; createdByAdminId: string };
}
