/* RESPONSIBLE TEAM: team-proactive-support */
const IMPLICIT_IOS_SESSION_PREDICATE = {
  attribute: 'session_count_ios',
  type: 'integer',
  comparison: 'gt',
  value: '0',
};
const IMPLICIT_ANDROID_SESSION_PREDICATE = {
  attribute: 'session_count_android',
  type: 'integer',
  comparison: 'gt',
  value: '0',
};
const IMPLICIT_PUSH_ONLY_PREDICATE = {
  attribute: 'has_device_token',
  type: 'boolean',
  comparison: 'true',
};
const IMPLICIT_EMAIL_KNOWN_PREDICATE = { attribute: 'email', type: 'string', comparison: 'known' };

const IMPLICIT_EMAIL_NOT_UNSUBSCRIBED_PREDICATE = {
  attribute: 'unsubscribed_from_emails',
  type: 'boolean',
  comparison: 'false',
};
const IMPLICIT_EMAIL_UNSUBSCRIBED_PREDICATE = {
  attribute: 'unsubscribed_from_emails',
  type: 'boolean',
  comparison: 'true',
};

const IMPLICIT_EMAIL_NOT_BOUNCED_PREDICATE = {
  attribute: 'has_hard_bounced',
  type: 'string',
  comparison: 'ne',
  value: 'true',
};
const IMPLICIT_EMAIL_BOUNCED_PREDICATE = {
  attribute: 'has_hard_bounced',
  type: 'boolean',
  comparison: 'true',
};

const IMPLICIT_USER_NOT_COMPLAINED_PREDICATE = {
  attribute: 'marked_email_as_spam',
  type: 'string',
  comparison: 'ne',
  value: 'true',
};
const IMPLICIT_USER_COMPLAINED_PREDICATE = {
  attribute: 'marked_email_as_spam',
  type: 'boolean',
  comparison: 'true',
};
const IMPLICIT_EMAIL_UNKNOWN_PREDICATE = {
  attribute: 'email',
  type: 'string',
  comparison: 'unknown',
};

export default {
  emailBouncedPredicates: [IMPLICIT_EMAIL_BOUNCED_PREDICATE],
  emailUnsubscribedPredicates: [
    IMPLICIT_EMAIL_UNSUBSCRIBED_PREDICATE,
    IMPLICIT_USER_COMPLAINED_PREDICATE,
    IMPLICIT_EMAIL_BOUNCED_PREDICATE,
  ],
  emailPredicates: [
    IMPLICIT_EMAIL_NOT_UNSUBSCRIBED_PREDICATE,
    IMPLICIT_EMAIL_KNOWN_PREDICATE,
    IMPLICIT_USER_NOT_COMPLAINED_PREDICATE,
    IMPLICIT_EMAIL_NOT_BOUNCED_PREDICATE,
  ],
  iosPredicates: [IMPLICIT_IOS_SESSION_PREDICATE],
  androidPredicates: [IMPLICIT_ANDROID_SESSION_PREDICATE],
  pushOnlyPredicates: [IMPLICIT_PUSH_ONLY_PREDICATE],
  iosPushOnlyPredicates: [IMPLICIT_IOS_SESSION_PREDICATE, IMPLICIT_PUSH_ONLY_PREDICATE],
  androidPushOnlyPredicates: [IMPLICIT_ANDROID_SESSION_PREDICATE, IMPLICIT_PUSH_ONLY_PREDICATE],
  emailCollectorPredicates: [IMPLICIT_EMAIL_UNKNOWN_PREDICATE],
};
