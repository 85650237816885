/* RESPONSIBLE TEAM: team-knowledge-interop */
import Model, { attr } from '@ember-data/model';
import {
  type PENDING,
  type IN_PROGRESS,
  type FAILED,
  type COMPLETE,
} from 'embercom/lib/content-service/file-source/constants';

export default class FileSource extends Model {
  @attr('string') declare fileName: string;
  @attr('string') declare status:
    | typeof PENDING
    | typeof IN_PROGRESS
    | typeof FAILED
    | typeof COMPLETE;
  @attr('string') declare fileType: 'application/pdf';
  @attr('string') declare uploadId: number;
  @attr('number') declare lastEditedById: number;
  @attr('string') declare errorCode: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: string;
  @attr('string') declare locale: string;
  @attr('string') declare folderId: string;

  get lastUpdatedByTeammate() {
    return this.store.peekRecord('admin', this.lastEditedById);
  }
}
