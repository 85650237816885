/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ParentAction } from './action';
import { inject as service } from '@ember/service';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';

export default class EmojiAction extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.Emoji];
  }

  id = 'emoji';
  icon = 'lwr-happy' as const;
  paneComponent = 'inbox2/command-k/emoji/pane';
}
