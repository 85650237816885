/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type NotificationSound } from 'embercom/services/browser-alerts';
export interface AdminBrowserSettingsWireFormat {
  notification_sound: NotificationSound;
  audio_notifications_enabled: boolean;
  browser_alert_setting: number;
  team_ids: Array<number>;
  operator_bot_id: number;
}

export default class AdminBrowserSettings {
  readonly notificationSound: NotificationSound;
  readonly audioNotificationsEnabled: boolean;
  readonly browserAlertSetting: number;
  readonly teamIds: Array<number>;
  readonly operatorBotId: number;

  constructor(
    notificationSound: NotificationSound,
    audioNotificationsEnabled: boolean,
    browserAlertSetting: number,
    teamIds: Array<number>,
    operatorBotId: number,
  ) {
    this.notificationSound = notificationSound;
    this.audioNotificationsEnabled = audioNotificationsEnabled;
    this.browserAlertSetting = browserAlertSetting;
    this.teamIds = teamIds || [];
    this.operatorBotId = operatorBotId;
  }

  static deserialize(json: AdminBrowserSettingsWireFormat): AdminBrowserSettings {
    return new AdminBrowserSettings(
      json.notification_sound,
      json.audio_notifications_enabled,
      json.browser_alert_setting,
      json.team_ids,
      json.operator_bot_id,
    );
  }
}
