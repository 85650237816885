/* RESPONSIBLE TEAM: team-workflows */

import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { userTypeComputedProperty } from './utils/userTypeComputedProperty';

export default class ArticleAutoReaction extends Model {
  @service messengerSettingsTargetUserProvider;

  @attr('boolean') enabledForLeads;
  @attr('boolean') enabledForUsers;

  @userTypeComputedProperty(
    'messengerSettingsTargetUserProvider.targetUserType',
    'enabledForUsers',
    'enabledForLeads',
  )
  autoReactionEnabled;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
