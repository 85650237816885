/* RESPONSIBLE TEAM: team-messenger */
import Model, { hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { userTypeComputedProperty } from './utils/userTypeComputedProperty';

export default class Spaces extends Model {
  @hasMany('messenger-settings/space', { async: false }) spacesForUsers;
  @hasMany('messenger-settings/space', { async: false }) spacesForVisitors;
  @hasMany('messenger-settings/space', { async: false }) spacesForMobileUsers;
  @service messengerSettingsTargetUserProvider;

  @userTypeComputedProperty(
    'messengerSettingsTargetUserProvider.targetUserType',
    'spacesForUsers',
    'spacesForVisitors',
    'spacesForMobileUsers',
  )
  selectedSpaces;

  get spacesForPreview() {
    let hub = this.selectedSpaces.find((space) => space.isHub);
    let otherSpaces = this.selectedSpaces.filter(
      (space) => (space.enabled || space.isMessages) && !space.isHub,
    );
    return hub.enabled ? [hub, ...otherSpaces] : otherSpaces;
  }

  get showSpacesPreview() {
    return this.spacesForPreview.length > 1;
  }

  get activePreviewSpace() {
    let hub = this.selectedSpaces.find((space) => space.isHub);
    let messages = this.selectedSpaces.find((space) => space.isMessages);
    return hub.enabled ? hub : messages;
  }

  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.spacesForVisitors.any((space) => space.hasUnsavedChanges) ||
      this.spacesForUsers.any((space) => space.hasUnsavedChanges) ||
      this.spacesForMobileUsers.any((space) => space.hasUnsavedChanges)
    );
  }

  get userEnabledSpacesForCda() {
    return this.spacesForUsers
      .filter((space) => space.enabled)
      .map((space) => space.type)
      .join(',');
  }

  get visitorEnabledSpacesForCda() {
    return this.spacesForVisitors
      .filter((space) => space.enabled)
      .map((space) => space.type)
      .join(',');
  }

  get mobileUserEnabledSpacesForCda() {
    return this.spacesForMobileUsers
      .filter((space) => space.enabled)
      .map((space) => space.type)
      .join(',');
  }

  get userHelpSpace() {
    return this.spacesForUsers.find((space) => space.isHelp);
  }

  get visitorHelpSpace() {
    return this.spacesForVisitors.find((space) => space.isHelp);
  }

  get mobileUserHelpSpace() {
    return this.spacesForMobileUsers.find((space) => space.isHelp);
  }

  spaceEnabled(spaceProperty) {
    let space = this.selectedSpaces.find((space) => space[spaceProperty]);
    return space && space.enabled;
  }

  spaceEnabledForUsers(spaceProperty) {
    let space = this.spacesForUsers.find((space) => space[spaceProperty]);
    return space && space.enabled;
  }

  spaceEnabledForVisitors(spaceProperty) {
    let space = this.spacesForVisitors.find((space) => space[spaceProperty]);
    return space && space.enabled;
  }

  spaceEnabledForMobileUsers(spaceProperty) {
    let space = this.spacesForMobileUsers.find((space) => space[spaceProperty]);
    return space && space.enabled;
  }

  get hasNews() {
    return this.spaceEnabled('isNews');
  }

  get hasHelp() {
    return this.spaceEnabled('isHelp');
  }

  get hasHub() {
    return this.spaceEnabled('isHub');
  }

  get hasTasks() {
    return this.spaceEnabled('isTasks');
  }

  get hasTasksEnabledForAnyUserType() {
    return this.spaceEnabledForUsers('isTasks') || this.spaceEnabledForVisitors('isTasks');
  }

  enableSpace(spaceProperty) {
    let space = this.selectedSpaces.find((space) => space[spaceProperty]);
    if (!space) {
      return;
    }
    space.enabled = true;
  }

  enableNews() {
    this.enableSpace('isNews');
  }

  enableTasks() {
    this.enableSpace('isTasks');
  }

  enableHelp() {
    this.enableSpace('isHelp');
  }

  enableHub() {
    this.enableSpace('isHub');
  }

  refresh() {
    this.rollbackAttributes();
    this.spacesForUsers.forEach((space) => space.refresh());
    this.spacesForVisitors.forEach((space) => space.refresh());
    this.spacesForMobileUsers.forEach((space) => space.refresh());
  }
}
