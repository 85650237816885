/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/news-item/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/news-item/edit-mode-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import {
  objectTypes,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.newsItem;
  let humanReadableObjectName = humanReadableObjectNames[options.objectType];
  let notificationMessages = generateNotificationMessages(options);
  notificationMessages.activate = generateNewsItemNotificationMessages(
    options.container,
    humanReadableObjectName,
  );
  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    notificationMessages,
    requiredFeature: 'news',
  });
}

function generateNewsItemNotificationMessages(container, humanReadableObjectName) {
  return NotificationMessage.extend({
    contentEditorService: service(),
    intl: service(),
    humanReadableObjectName,
    async confirmation() {
      let ruleset = this.contentEditorService.ruleset;
      let newsItem = ruleset.rulesetLinks.firstObject.object;

      let title = this.intl.t('outbound.news-items.edit.set-live.confirmation.title');
      let confirmButtonText = this.intl.t(
        'outbound.news-items.edit.set-live.confirmation.button-text',
      );
      let body = this.intl.t('outbound.news-items.edit.set-live.confirmation.body', {
        newsfeedsCount: newsItem.newsfeedIds.length,
      });

      return { title, body, confirmButtonText };
    },
  }).create({
    success: `The ${humanReadableObjectName} was set live`,
    failure: `We couldn't set this ${humanReadableObjectName} live. Send us a message and we'll do our best to help out.`,
    container,
  });
}
