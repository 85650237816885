/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import ajax from 'embercom/lib/ajax';

let SegmentService = EmberObject.extend({
  appId: null,
  type: 'segment',
  dependentObjectsUrl: computed('segment.id', function () {
    return `/ember/segments/${this.segment.id}/dependent_objects`;
  }),
  getDependentObjects() {
    return ajax({
      url: this.dependentObjectsUrl,
      type: 'GET',
      data: {
        app_id: this.appId,
        is_company: this.get('segment.isCompany'),
      },
    }).then(function (response) {
      return response.table;
    });
  },
}).reopenClass({
  findAllSegmentFromList(segmentList, segmentToCheckForContext) {
    segmentToCheckForContext = segmentToCheckForContext || segmentList.get('firstObject');
    return this._allSegmentFromList(segmentList, segmentToCheckForContext);
  },
  _allSegmentFromList(segmentList, segment) {
    if (segment.get('isUserSegment')) {
      return segmentList.findBy('isAllUsersSegment');
    } else if (segment.get('isAnonymousSegment')) {
      return segmentList.findBy('isContactsSegment');
    } else if (segment.get('isCompany')) {
      return segmentList.findBy('isAllCompaniesSegment');
    }
  },
});

export default SegmentService;
