/* RESPONSIBLE TEAM: team-workflows */
import {
  BaseConfig,
  INPUT_RULE_EMOJI,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
} from '@intercom/embercom-prosemirror-composer';
import {
  INLINE_CONTROL_ALIGNMENT,
  type TypeaheadConfig,
  type TextDirection,
} from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { EmbercomCanvasNetworkClient } from 'embercom/objects/composer/embercom-canvas-network-client';
import type AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { MENTION_TYPEAHEAD } from 'embercom/objects/inbox/composer-config';

export default class ComposerConfig extends BaseConfig {
  autoFocus = true;
  allowedBlocks = [
    'paragraph',
    'heading',
    'subheading',
    'image',
    'video',
    'videoFile',
    'audioFile',
    'orderedList',
    'unorderedList',
    'messengerCard',
    'mention',
  ];
  allowedInline = ['bold', 'italic', 'anchor'];
  hideFromTextFormatter = [INLINE_CONTROL_ALIGNMENT];
  allowTextAlignment = true;
  emojiInserter = 'composer/emoji-inserter';
  canvasNetworkClient;
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [
    INPUT_RULE_EMOJI,
    INPUT_RULE_MARKDOWN_ANCHOR,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
  ];
  textDirection: TextDirection = 'auto';
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'image-editor', options: { supportAltAttributeEditing: false } },
    { name: 'video-editor' },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedVideoFileTypes: ['video/mp4'],
    allowedAttachmentTypes: ['*'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor({
    app,
    resolver,
    placeholder,
    allowMentions,
  }: {
    app: any;
    resolver: AttributeInfoResolver;
    placeholder: string;
    allowMentions?: boolean;
  }) {
    super();

    this.initializeMentions(!!allowMentions);

    this.canvasNetworkClient = new EmbercomCanvasNetworkClient(app.id);
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
    this.placeholder = placeholder;
  }

  removeAllowedBlock(block: string) {
    this.allowedBlocks = this.allowedBlocks.without(block);
  }

  insertTypeahead(typeahead: TypeaheadConfig) {
    this.typeaheads = [...this.typeaheads, typeahead];
  }

  private initializeMentions(allowMentions: boolean) {
    if (allowMentions) {
      this.insertTypeahead(MENTION_TYPEAHEAD);
    } else {
      this.removeAllowedBlock('mention');
    }
  }
}
