/* RESPONSIBLE TEAM: team-workflows */
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

export default class DisableComposer extends Step {
  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'bot_workflows';
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  static createNewStep(store: Store): DisableComposer {
    return store.createRecord('operator/visual-builder/step/disable-composer', {
      type: 'operator/visual-builder/step/disable-composer',
    });
  }
}
