/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';

export default Model.extend({
  bouncedAt: attr('date'),
  clickedAt: attr('date'),
  linksClickedCount: attr('number'),
  complainedAt: attr('date'),
  hitGoalAt: attr('date'),
  openedAt: attr('date'),
  repliedAt: attr('date'),
  respondedAt: attr('date'),
  sentAt: attr('date'),
  completedAt: attr('date'),
  unsubscribedAt: attr('date'),
  respondedWith: attr('string'),
  collectedAt: attr('date'),
  user: fragment('messages/recipient'),
});
