/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type ConversationSlaObject } from 'embercom/objects/inbox/interfaces/conversation_sla';
import { type WorkflowObject } from 'embercom/objects/inbox/interfaces/workflow';
interface ConversationSlaAppliedByWorkflowWireFormat {
  renderable_type: RenderableType;
  workflow: WorkflowObject;
  conversation_sla: ConversationSlaObject;
}

export default class ConversationSlaAppliedByWorkflow {
  readonly renderableType: RenderableType = RenderableType.ConversationSlaAppliedByWorkflow;
  readonly workflow: WorkflowObject;
  readonly conversationSla: ConversationSlaObject;

  constructor(workflow: WorkflowObject, conversationSla: ConversationSlaObject) {
    this.workflow = workflow;
    this.conversationSla = conversationSla;
  }

  get creatingEntity() {
    return { type: EntityType.Workflow, id: this.workflow.id || -1, object: this.workflow };
  }

  static deserialize(
    json: ConversationSlaAppliedByWorkflowWireFormat,
  ): ConversationSlaAppliedByWorkflow {
    return new ConversationSlaAppliedByWorkflow(json.workflow, json.conversation_sla);
  }
}
