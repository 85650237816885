/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import type Tag from 'embercom/objects/inbox/tag';
import Company, { type CompanyAttributes } from 'embercom/objects/inbox/company';
import GeoipData, { type GeoipDataWireFormat } from 'embercom/objects/inbox/geoip-data';
import type Attribute from 'embercom/objects/inbox/attribute';
import PageView, { type PageViewWireFormat } from 'embercom/objects/inbox/page-view';
// @ts-ignore
import { cached } from 'tracked-toolbox';

const isCustomDataKey = (key: string) => key.startsWith('custom_data.');

export interface UserAttributes {
  id: string;
  role: string;
  user_id: string;
  created_at: string;
  last_request_at: string;
  session_count: number;
  name: string;
  geoip_data?: GeoipDataWireFormat;
  user_time?: {
    local_time_12h: string;
  };
  note_ids?: string[];
  tag_ids?: number[];
  owner_id?: number;
  companies?: CompanyAttributes[];
  recent_page_views?: PageViewWireFormat[];
  custom_data?: Record<string, unknown>;
  email?: string;
  unsubscribed_from_emails: boolean;
  matched_on?: string[];
  [key: string]: any;
}

export default class User {
  @tracked attributes: UserAttributes;

  constructor(attributes: UserAttributes) {
    this.attributes = attributes ?? {};
  }

  get displayAs(): string {
    return this.name || this.email || this.userId || this.id;
  }

  get id() {
    return this.attributes.id;
  }

  get name() {
    return this.attributes.name;
  }

  get email() {
    return this.attributes.email;
  }

  get role() {
    return this.attributes.role;
  }

  get identifiedByEmail() {
    return this.hasUserRole && !this.userId;
  }

  get geoipData() {
    return this.attributes.geoip_data ? GeoipData.deserialize(this.attributes.geoip_data) : null;
  }

  @cached
  get companies() {
    return (this.attributes.companies ?? []).map(Company.deserialize);
  }

  get company() {
    return this.companies[0];
  }

  get userId() {
    return this.attributes.user_id;
  }

  get createdAt() {
    return this.attributes.created_at;
  }

  get lastRequestAt() {
    return this.attributes.last_request_at;
  }

  get sessionCount() {
    return this.attributes.session_count;
  }

  get unsubscribedFromEmails() {
    return this.attributes.unsubscribed_from_emails;
  }

  get matchedOn() {
    return this.attributes.matched_on;
  }

  get tagIds() {
    return this.attributes.tag_ids?.map(String) ?? [];
  }

  get noteIds() {
    return this.attributes.note_ids?.map(String) ?? [];
  }

  get recentPageViews() {
    return (
      this.attributes.recent_page_views?.map((pageViewWireFormat: PageViewWireFormat) =>
        PageView.deserialize(pageViewWireFormat),
      ) || []
    );
  }

  get hasLeadRole() {
    return this.role === 'contact_role';
  }

  get hasUserRole() {
    return this.role === 'user_role';
  }

  get whatsAppNumber() {
    return this.attributes.whatsapp_number;
  }

  get isWhatsAppLead() {
    return !!this.whatsAppNumber && this.hasLeadRole;
  }

  get isUnsubscribedFromEmails(): boolean {
    return this.attributes.unsubscribed_from_emails;
  }

  set isUnsubscribedFromEmails(val: boolean) {
    this.updateUserAttribute('unsubscribed_from_emails', val);
  }

  getAttributeValue(name: keyof UserAttributes) {
    return this.attributes[name];
  }

  updateAttributes(attributes: UserAttributes) {
    this.attributes = attributes;
  }

  updateAttribute(
    attribute: Attribute,
    value: any,
    { companyId }: { companyId?: string } = {},
  ): void {
    if (attribute.isCompany) {
      if (companyId === undefined || companyId === null) {
        throw new Error('companyId is required to update a company attribute');
      }

      this.updateCompanyAttribute(companyId, attribute.key, value);
    } else {
      this.updateUserAttribute(attribute.key, value);
    }
  }

  updateTags(tags: Tag[]) {
    this.attributes = {
      ...this.attributes,
      tag_ids: tags
        .map((tag) => tag.id)
        .uniq()
        .map(Number),
    };
  }

  updateNoteIds(updatedNoteIds: string[]) {
    this.attributes = {
      ...this.attributes,
      note_ids: [...updatedNoteIds],
    };
  }

  addNewCompany() {
    let existingCompanies = this.attributes.companies ?? [];

    this.attributes = {
      ...this.attributes,
      companies: [...existingCompanies, { id: '' }],
    };
  }

  get mostRecentCompany() {
    return this.companies[0];
  }

  get otherCompanies() {
    if (this.companies) {
      let mostRecentCompany = this.mostRecentCompany;

      return this.companies.filter(function (company) {
        return company && company !== mostRecentCompany;
      });
    }

    return [];
  }

  get qualificationCompany() {
    let company = this.companies.findBy('id', String(this.attributes.qualification_company_id));

    if (company) {
      return company;
    } else if (this.companies.length > 0) {
      return this.mostRecentCompany;
    } else {
      return;
    }
  }

  private updateUserAttribute(name: string & keyof UserAttributes, value: any) {
    if (isCustomDataKey(name)) {
      let key = name.replace('custom_data.', '');

      this.attributes = {
        ...this.attributes,
        custom_data: {
          ...this.attributes.custom_data,
          [key]: value,
        },
      };
    } else {
      this.attributes = { ...this.attributes, [name]: value };
    }
  }

  private updateCompanyAttribute(companyId: string, name: string, value: any) {
    let key = name.replace('company.', '');

    this.attributes = {
      ...this.attributes,
      companies: this.attributes.companies?.map((company) => {
        if (company.id !== companyId) {
          return company;
        }

        if (isCustomDataKey(key)) {
          return {
            ...company,
            custom_data: {
              ...company.custom_data,
              [key.replace('custom_data.', '')]: value,
            },
          };
        } else {
          return {
            ...company,
            [key]: value,
          };
        }
      }),
    };
  }
}
