/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import MF from 'ember-data-model-fragments';
import {
  timetableTypes,
  timezoneTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { equal } from '@ember/object/computed';

export default MF.Fragment.extend({
  timetableType: attr(),
  timezoneType: attr(),
  timeIntervals: MF.fragmentArray('matching-system/timetable/time-interval'),

  isInsideOfficeHoursType: equal('timetableType', timetableTypes.insideOfficeHours),
  isOutsideOfficeHoursType: equal('timetableType', timetableTypes.outsideOfficeHours),
  isCustomTimesType: equal('timetableType', timetableTypes.customTimes),

  isUserTimezoneBased: equal('timezoneType', timezoneTypes.basedOnUserTimezone),
  isAppTimezoneBased: equal('timezoneType', timezoneTypes.basedOnAppTimezone),

  timeIntervalsForDay(day) {
    //return intervals that start or end on the day, or which entirely encompass the day
    return this.timeIntervals.toArray().filter((timeInterval) => {
      return (
        timeInterval.startDay === day ||
        timeInterval.endDay === day ||
        (timeInterval.startDay < day && timeInterval.endDay > day)
      );
    });
  },
});
