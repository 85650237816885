/* RESPONSIBLE TEAM: team-help-desk-experience */

import { tracked } from '@glimmer/tracking';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { type ComposerPaneStyles } from 'embercom/objects/inbox/composer-pane';
import type ComposerPane from 'embercom/objects/inbox/composer-pane';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import type InboxState from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import type Macro from 'embercom/objects/inbox/macro';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class NotePane implements ComposerPane {
  @service declare inboxState: InboxState;

  readonly type: ComposerPaneType = ComposerPaneType.Note;
  readonly nameTranslationKey: string = 'inbox.composer.panes.note';
  readonly icon: InterfaceIconName = 'edit';
  readonly hotkeyId = HotkeyID.ComposeNote;
  readonly isTicketNotesComposer: boolean = false;

  @tracked blocksDoc: BlocksDocument;
  @tracked macroActions: Macro['actions'] = [];

  constructor(isTicketNotesComposer = false) {
    this.isTicketNotesComposer = isTicketNotesComposer;
    this.blocksDoc = new BlocksDocument([]);
  }

  get styles(): ComposerPaneStyles {
    if (this.isTicketNotesComposer) {
      return {
        container:
          'bg-white dark:bg-dm-black border border-gray-lightest dark:border-dm-gray-darker dark:shadow-dm-sm',
      };
    }

    return {
      container:
        'inbox2__note-pane bg-yellow-light border dark:bg-dm-yellow-dark dark:shadow-dm-sm text-yellow-dark dark:text-dm-white',
    };
  }

  clear() {
    this.blocksDoc = new BlocksDocument([]);
    this.macroActions = [];
  }
}
