/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const maximumStepsLimitForBranching = 12;
export const maximumQuestionsPerStepBannerFormat = 1;
export const maximumQuestionsPerStepPostFormat = 3;

export const surveyFormats = {
  banner: 0,
  post: 1,
};

export const stepTypes = {
  content: 0,
  question: 1,
  intro: 2,
  thankYou: 3,
};

export const responseTypes = {
  input: 0,
  text: 1,
  dropdown: 2,
  ratingScale: 3,
  multiSelect: 4,
};

export const scaleTypes = {
  numeric: 0,
  stars: 1,
  emoji: 2,
  nps: 3,
};

export const selectTypes = {
  single: 0,
  multiple: 1,
};

export const ratingScaleResponseTypes = {
  numeric: `${responseTypes.ratingScale}.${scaleTypes.numeric}`,
  emoji: `${responseTypes.ratingScale}.${scaleTypes.emoji}`,
  stars: `${responseTypes.ratingScale}.${scaleTypes.stars}`,
  nps: `${responseTypes.ratingScale}.${scaleTypes.nps}`,
};

const ratingScaleQuestions = [
  { text: 'Net Promoter Score', value: ratingScaleResponseTypes.nps, icon: 'nps' },
  { text: 'Numeric scale', value: ratingScaleResponseTypes.numeric, icon: 'numbers' },
  { text: 'Star rating scale', value: ratingScaleResponseTypes.stars, icon: 'star-outlined' },
  { text: 'Emoji scale', value: ratingScaleResponseTypes.emoji, icon: 'lwr-happy' },
];

const smallFormatQuestions = [
  { text: 'Dropdown / List', value: responseTypes.dropdown, icon: 'small-down-arrow' },
  { text: 'Short text entry', value: responseTypes.input, icon: 'short-text' },
  { text: 'Long text entry', value: responseTypes.text, icon: 'long-text' },
];

export const smallFormatQuestionTypeItems = [...ratingScaleQuestions, ...smallFormatQuestions];

export const largeFormatQuestionTypeItems = [
  ...ratingScaleQuestions,
  { text: 'Multiple choice', value: responseTypes.multiSelect, icon: 'selection' },
  ...smallFormatQuestions,
];

export const validationTypes = {
  text: 0,
  number: 1,
  email: 2,
  phone: 3,
};

export const responseTypeCompatibleAttributeTypes = {
  [responseTypes.input]: ['string', 'integer', 'name', 'email', 'phone'],
  [responseTypes.text]: ['string'],
  [responseTypes.dropdown]: ['string'],
  [responseTypes.ratingScale]: ['integer'],
  [responseTypes.multiSelect]: ['string'],
};

export const attributeTypeAsValidation = {
  string: validationTypes.text,
  integer: validationTypes.number,
  name: validationTypes.text,
  email: validationTypes.email,
  phone: validationTypes.phone,
};

export const emojiScaleOptions = {
  3: [
    { value: 1, unicodeEmoticon: '🙁' },
    { value: 2, unicodeEmoticon: '😑' },
    { value: 3, unicodeEmoticon: '😄' },
  ],
  5: [
    { value: 1, unicodeEmoticon: '😠' },
    { value: 2, unicodeEmoticon: '🙁' },
    { value: 3, unicodeEmoticon: '😑' },
    { value: 4, unicodeEmoticon: '😄' },
    { value: 5, unicodeEmoticon: '🤩' },
  ],
};

export const actionTypes = {
  openUrlCurrentTab: 0,
  openUrlNewTab: 1,
};

export const defaultPostThankYouBlocks = [
  {
    type: 'heading',
    text: 'All done 🎉',
    modelKey: 'common/blocks/heading',
  },
  {
    type: 'paragraph',
    text: '',
    modelKey: 'common/blocks/paragraph',
  },
  {
    type: 'paragraph',
    text: 'Thanks for sharing your feedback with us.',
    modelKey: 'common/blocks/paragraph',
  },
];

export const defaultBannerThankYouBlocks = [
  {
    type: 'paragraph',
    text: 'Thanks for taking the survey!',
    modelKey: 'common/blocks/paragraph',
  },
];

export const defaultIntroBlocks = [
  {
    type: 'heading',
    text: 'Sample survey heading',
    modelKey: 'common/blocks/heading',
  },
  {
    type: 'paragraph',
    text: '',
    modelKey: 'common/blocks/paragraph',
  },
  {
    type: 'paragraph',
    text: 'Hi {{first_name|fallback:"there"}},',
    modelKey: 'common/blocks/paragraph',
  },
];

export const thankYouStepStartOrder = -2;

export const branchingQuestionIdentifier = 'survey_response.question_';

export const defaultSurveyPredicates = {
  predicates: [
    {
      type: 'and',
      predicates: [
        {
          type: 'or',
          predicates: [
            {
              attribute: 'last_outbound_content_received_at_data.survey',
              type: 'date',
              comparison: 'lt',
              value: '14',
            },
            {
              attribute: 'last_outbound_content_received_at_data.survey',
              type: 'date',
              comparison: 'unknown',
              value: null,
            },
          ],
        },
      ],
    },
  ],
};

export const responseOrderTypes = {
  default: 0,
  alphabetical: 1,
  random: 2,
};
