/* RESPONSIBLE TEAM: team-workflows */
import Coordinates from 'graph-editor/models/graph-editor/coordinates';
import type Edge from 'graph-editor/models/graph-editor/edge';
import StandardEdgeGenerator from 'graph-editor/models/graph-editor/edge/generator';
import {
  INWARD_CONNECTION_POINT_NODE_X_OFFSET,
  SPACING_BETWEEN_NODES,
} from 'embercom/objects/workflows/graph-editor/graph-layout';

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

const SPACING_FOR_LOOPING_EDGE = 40;

export default class WorkflowsEdgeGenerator extends StandardEdgeGenerator {
  constructor(edge: Edge) {
    super(edge);
  }

  get pathClass() {
    return 'workflows__edge';
  }

  get hoverPathClass() {
    if (this.edge.dataObject.isStatic) {
      return '';
    }
    return 'workflows__edge__hover-path';
  }

  get arrowPathClass() {
    return 'workflows__edge__arrowhead';
  }

  // Replace this with @cached in Ember 3.19
  @computed(
    'edge.{dataObject.outwardConnectionPoint,predecessor.connectionPoints.@each.coordinates,dataObject.isStatic}',
  )
  get fromConnectionPoints() {
    if (this.edge.dataObject.isStatic) {
      return this.edge.predecessor.connectionPoints;
    }
    let connectionPoint = this.edge.dataObject.outwardConnectionPoint;
    return this.edge.predecessor.connectionPoints.filter((c) => {
      return c.direction === 'outwards' && c.dataObject === connectionPoint;
    });
  }

  // Replace this with @cached in Ember 3.19
  @computed('edge.{successor.connectionPoints.@each.coordinates,dataObject.isStatic}')
  get toConnectionPoints() {
    if (this.edge.dataObject.isStatic) {
      return this.edge.successor?.connectionPoints || [];
    }
    return (
      this.edge.successor?.connectionPoints.filter(
        (c) => c.direction === 'inwards' || c.direction === 'both',
      ) || []
    );
  }

  get isLoop() {
    return this.end.x < this.start.x;
  }

  get inflectionPoint() {
    // Overlapping Edges
    return new Coordinates(
      this.start.x + SPACING_BETWEEN_NODES / 2,
      this.isLoop ? this.end.y - SPACING_FOR_LOOPING_EDGE : this.midpoint.y,
    );
  }

  get shortestSegment() {
    return Math.min(Math.abs(this.start.x - this.end.x), Math.abs(this.start.y - this.end.y));
  }

  get path() {
    if (this.from === 'right' && this.to === 'left') {
      return this.rightToLeft();
    }

    return this.straightLine();
  }

  protected rightToLeft() {
    if (this.isLoop) {
      let end_spacing_x =
        SPACING_BETWEEN_NODES -
        INWARD_CONNECTION_POINT_NODE_X_OFFSET -
        Math.abs(this.inflectionPoint.x - this.start.x);

      return this.pathFromCoordinateList([
        [this.start.x, this.start.y],
        [this.inflectionPoint.x, this.start.y],
        [this.inflectionPoint.x, this.inflectionPoint.y],
        [this.end.x - end_spacing_x, this.inflectionPoint.y],
        [this.end.x - end_spacing_x, this.end.y],
        [this.end.x, this.end.y],
      ]);
    }

    if (this.shortestSegment < 2 * this.spacing) {
      return this.straightLine();
    } else {
      return this.pathFromCoordinateList([
        [this.start.x, this.start.y],
        [this.inflectionPoint.x, this.start.y],
        [this.inflectionPoint.x, this.end.y],
        [this.end.x, this.end.y],
      ]);
    }
  }
}
