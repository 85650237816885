/* RESPONSIBLE TEAM: team-reporting */
import { type MetricPropertyDefinition } from 'embercom/objects/reporting/unified/properties/types';

export const CONVERSATION_ATTRIBUTES = 'conversation_custom_fields';
export const SYSTEM_DEFINED_ATTRIBUTES = 'system.attribute';
export const CUSTOM_USER_ATTRIBUTE = 'user.custom_data';
export const CUSTOM_COMPANY_ATTRIBUTE = 'companies.custom_data';
export const TICKETS_ATTRIBUTES = 'ticket';

export const STANDARD_DATA = 'standard_data';

export const TEAMMATE_ATTRIBUTE = 'teammate';

export const TEAM_ATTRIBUTE = 'team';

export const USER_STANDARD_ATTRIBUTE = 'user';
export const COMPANY_STANDARD_ATTRIBUTE = 'company';

export const FILTERABLE_CONVERSATION_CUSTOM_ATTRIBUTE_TYPES = [
  'list',
  'boolean',
  'string',
  'integer',
  'decimal',
  'ticket',
];

export const FILTERABLE_USER_AND_COMPANY_ATTRIBUTE_TYPES = [
  'boolean',
  'string',
  'integer',
  'float',
];
export interface Attribute extends MetricPropertyDefinition {
  // TODO: delete alias_id from metricsProperties and Attributes
  group?: string | undefined;
  supportsBreakdown: boolean;
  supportsFiltering: boolean;
  disableMultiple: boolean;
  parentAttributeId: string | null;
  dataType: string;
}

export interface AttributeDefinition {
  id: string;
  name: string;
  description: string;
  type: string;
  filterTooltip: string;
  parentAttributeId: string | null;
  field: string;
  icon: string;
  group?: string | undefined;
  groupTooltip?: string | undefined;
  filterLabelPrefix?: string | undefined;
  filterSelectAllLabel?: string | undefined;
  headerName?: string | undefined;
  variantOf?: string | undefined;
  supportsBreakdown: boolean;
  supportsFiltering: boolean;
  disableMultiple: boolean;
  dataType: string;
}

export interface DatasetDefinition {
  id: string;
  attributeIds: string[];
}
export class Dataset {
  id: string;
  attributes: Attribute[];

  constructor(id: string, attributes: Attribute[]) {
    this.id = id;
    this.attributes = attributes;
  }

  hasAttribute(attributeId: string) {
    return this.attributes.some((attribute) => attribute.id === attributeId);
  }

  static fromDefinition(
    attributeDefinitions: AttributeDefinition[],
    datasetDefinitions: DatasetDefinition[],
  ): Dataset[] {
    let attributes: Attribute[] = attributeDefinitions.map((definition) =>
      this.makeDatasetAttribute(definition),
    );

    return datasetDefinitions.map((definition) => {
      return new Dataset(
        definition.id,
        attributes.filter((attribute) => definition.attributeIds.includes(attribute.id)),
      );
    });
  }

  private static makeDatasetAttribute(definition: AttributeDefinition) {
    return {
      id: definition.id,
      group: definition.group,
      name: definition.name,
      parentAttributeId: definition.parentAttributeId,
      groupTooltip: definition.groupTooltip,
      filterTooltip: definition.filterTooltip,
      filterLabelPrefix: definition.filterLabelPrefix,
      filterSelectAllLabel: definition.filterSelectAllLabel,
      headerName: definition.headerName,
      icon: definition.icon,
      type: definition.type,
      supportsBreakdown: definition.supportsBreakdown ?? true,
      supportsFiltering: definition.supportsFiltering ?? true,
      field: definition.field,
      variantOf: definition.variantOf,
      disableMultiple: definition.disableMultiple || false,
      dataType: definition.dataType,
    };
  }
}
