/* RESPONSIBLE TEAM: team-proactive-support */
import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';
import TabConfiguration from 'embercom/objects/content-editor/tabs/tab-configuration';

export default class SeriesOverviewTabConfiguration extends TabConfiguration {
  @service appService;
  @service seriesEditorService;

  label;
  subtitle;

  get isVisible() {
    return true;
  }

  init(inputs) {
    inputs.component = 'series/editor/tabs/reporting-tab';
    inputs.view = 'reporting';

    super.init(...arguments);

    assert('A label must be passed for the series reporting tab configuration', inputs.label);
    assert('A subtitle must be passed for the series reporting tab configuration', inputs.subtitle);

    this.setProperties({
      label: inputs.label,
      subtitle: inputs.subtitle,
    });
  }
}
