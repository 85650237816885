/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class EmailContinuedConversationsSettings extends Model {
  @service appService;

  @attr('string') appId;
  @attr('number') duration;
}
