/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { not, notEmpty } from '@ember/object/computed';
import EmberObject, { computed } from '@ember/object';
import avatarUtils from '@intercom/pulse/lib/avatar-utils';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import Admin from 'embercom/models/admin';

export default Model.extend({
  admin_id: attr(),
  admin: computed('admin_id', function () {
    return Admin.peekAndMaybeLoad(this.store, this.admin_id);
  }),
  conversation_id: attr('number'),
  user: attr(),
  rating_index: attr('number'),
  remark: attr('string'),
  seen_in_inbox_of_love: attr('boolean'),
  unseen: not('seen_in_inbox_of_love'),
  updated_at: attr('date'),

  hasRemark: notEmpty('remark'),
  userWithAvatar: computed('user', function () {
    let user = EmberObject.create(this.user);
    user.set('avatarData', {
      color: user.get('avatar.color'),
      url: user.get('avatar.image_urls.normal'),
      initials: avatarUtils.formatInitials(user.get('avatar.initials')),
      isCustomer: true,
      isTeammate: false,
      isCompany: false,
    });
    return user;
  }),

  markAsSeen(app_id) {
    this.markRatingAsSeen.perform(app_id);
  },

  markRatingAsSeen: task(function* (app_id) {
    this.set('seen_in_inbox_of_love', true);

    yield ajax({
      url: `/ember/conversation_ratings/mark_as_seen/${this.id}`,
      type: 'PUT',
      data: JSON.stringify({
        app_id,
      }),
    });
  }),
});
