/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import MF from 'ember-data-model-fragments';
import { fragment } from 'ember-data-model-fragments/attributes';
import { states, defaultGoalMatchingTime } from 'embercom/models/data/stats-system/goals-constants';
import { computed } from '@ember/object';
import { and, reads } from '@ember/object/computed';

export default MF.Fragment.extend({
  entityId: attr('string'),
  entityType: attr('string'),
  predicateGroup: fragment('predicates/predicate-group'),
  timeConstraint: attr('number', { defaultValue: defaultGoalMatchingTime }),
  state: attr('number', { defaultValue: states.active }),

  usesClientPredicate: reads('predicateGroup.predicates.firstObject.isClientPredicate'),
  usesCompanyPredicate: reads('predicateGroup.predicates.firstObject.isCompanyPredicate'),
  usesUserPredicate: computed('usesClientPredicate', 'usesCompanyPredicate', function () {
    return !this.usesClientPredicate && !this.usesCompanyPredicate;
  }),
  hasClientPredicate: and('usesClientPredicate', 'predicateGroup.hasPredicates'),
  hasCompanyPredicate: and('usesCompanyPredicate', 'predicateGroup.hasPredicates'),
  hasUserPredicate: and('usesUserPredicate', 'predicateGroup.hasPredicates'),
  timeConstraintInDays: computed('timeConstraint', function () {
    return this.timeConstraint / 24;
  }),
  modelName: computed('usesCompanyPredicate', function () {
    return this.usesCompanyPredicate ? 'company' : 'customer';
  }),
});
