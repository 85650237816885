/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { hasMany, attr } from '@ember-data/model';
import SmsValidations from 'embercom/validations/sms';
import { getOwner } from '@ember/application';
import smsEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/sms';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { consentType } from 'embercom/models/outbound-subscriptions/subscription-type';
import { COUNTRY_CODE } from '../../components/outbound/sms/onboarding/country-data';

export const hasPhoneNumberPredicate = {
  attribute: 'phone',
  type: 'string',
  comparison: 'known',
  value: null,
};

export const hardUnsubscribedFromSms = {
  attribute: 'hard_unsubscribed_from_sms',
  type: 'boolean',
  comparison: 'false',
  value: '',
};

const ALPHANUM_SENDER_ID_UNSUPPORTED_COUNTRIES = [COUNTRY_CODE.UNITED_STATES, COUNTRY_CODE.CANADA];

export default class SmsMessage extends Model.extend(SmsValidations) {
  @service appService;
  @service intl;

  @hasMany('sms/localized-sms-message-content', { inverse: 'sms' }) localizedSmsMessageContents;
  @attr('number') useCaseType;
  @attr('boolean') showCompanyInfo;
  @attr('boolean') showUnsubscribeInstructions;
  @attr('boolean') quietHoursEnabled;
  @attr('string') companyInfoText;
  @attr('string') unsubscribeInstructionsText;
  @attr('string') subscriptionTypeId;
  @attr('boolean') useAlphanumSenderId;
  @attr('string') subscriptionPreferenceUrl;
  @attr('boolean') hasAlphanumericSenderId;
  @attr('number') maxAudienceSize;
  @attr('number') usageLimitStatus;

  @dependentKeyCompat
  get contentHash() {
    return this.localizedSmsMessageContents.map(
      (localizedSmsContent) => localizedSmsContent.contentHash,
    );
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this.localizedSmsMessageContents.forEach((content) => content.rollbackAttributes());
  }

  editorConfig() {
    let container = getOwner(this);
    return smsEditConfigGenerator({ container, sms: this });
  }

  @dependentKeyCompat
  get implicitPredicates() {
    // keep only unique values in the country list and filter out unsupported countries for alphanum id
    let allowedCountries = [...new Set(this.allowedPhoneCountry)];
    if (this.appService.app.canUseAlphanumericSenderId && this.useAlphanumSenderId) {
      allowedCountries = _.difference(allowedCountries, ALPHANUM_SENDER_ID_UNSUPPORTED_COUNTRIES);
    }
    return [
      this.allowedPhoneCountryPredicate(allowedCountries),
      hardUnsubscribedFromSms,
      {
        attribute:
          this.subscriptionType?.consentType === consentType.optIn
            ? 'opted_in_subscription_type_ids'
            : 'opted_out_subscription_type_ids',
        type: 'subscription_type',
        comparison: this.subscriptionType?.consentType === consentType.optIn ? 'in' : 'nin',
        value: this.subscriptionTypeId,
      },
    ];
  }

  allowedPhoneCountryPredicate(allowedCountries) {
    return {
      attribute: 'phone_country',
      type: 'phone_country',
      comparison: 'in',
      value: allowedCountries,
    };
  }

  get allowedPhoneCountry() {
    return this.store
      .peekAll('sms/phone-number')
      .toArray()
      .map((number) => number.countryCode);
  }

  get subscriptionType() {
    return (
      this.subscriptionTypeId &&
      this.store.peekRecord('outbound-subscriptions/subscription-type', this.subscriptionTypeId)
    );
  }

  get optOutInstruction() {
    if (this.appService.app.canUseAlphanumericSenderId && this.useAlphanumSenderId) {
      return this.intl.t(
        'outbound.sms.editor.content-panel.unsubscribe-instructions-text.without-keyword',
        { subscriptionPreferenceUrl: this.subscriptionPreferenceUrl },
      );
    }
    return this.intl.t(
      'outbound.sms.editor.content-panel.unsubscribe-instructions-text.with-keyword',
      { subscriptionPreferenceUrl: this.subscriptionPreferenceUrl },
    );
  }

  get isSendingBlocked() {
    return this.usageLimitStatus !== 0;
  }

  get hasRemainingUsage() {
    if (this.maxAudienceSize === null) {
      return true;
    } else if (this.maxAudienceSize > 0) {
      return true;
    }
    return false;
  }
}
