/* RESPONSIBLE TEAM: team-proactive-support */
import SeriesModeConfig from 'embercom/objects/content-editor/series-mode-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';
import { computed } from '@ember/object';

export default function generateEditorConfig(options) {
  let container = options.container;
  let splitter = options.splitter;

  return new SeriesModeConfig({
    headerComponentConfig: StandardSeriesModeHeaderConfiguration.extend({
      splitter,
      isValid: computed('splitter.validations.isValid', function () {
        return splitter.validations.isValid;
      }),
      errorMessage: computed('splitter.validations.errors.[]', function () {
        return splitter.validations.errors?.firstObject?.message;
      }),
    }).create({ container }),
    editorComponentConfigs: [
      ComponentConfiguration.create({
        component: 'content-editor/panels/content/splitter/body',
      }),
    ],

    viewModeHeaderConfig: StandardSeriesViewModeHeaderConfiguration.create({ container }),
    viewModeSummaryConfig: ComponentConfiguration.create({
      component: 'content-editor/summaries/content/splitter/summary',
    }),
  });
}
