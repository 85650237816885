/* RESPONSIBLE TEAM: team-help-desk-experience */
import { modifier } from 'ember-modifier';

const AutoGrowTextarea = modifier((element: HTMLTextAreaElement) => {
  element.style.boxSizing = 'border-box';

  let offset = element.offsetHeight - element.clientHeight;
  let resize = () => {
    element.style.height = '0px';
    element.style.height = `${element.scrollHeight + offset}px`;
  };

  resize();
  element.addEventListener('input', resize);
  element.addEventListener('change', resize);

  return () => {
    element.removeEventListener('input', resize);
    element.removeEventListener('change', resize);
  };
});

export default AutoGrowTextarea;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'auto-grow-textarea': typeof AutoGrowTextarea;
  }
}
