/* RESPONSIBLE TEAM: team-channels */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';

interface MergedPrimaryConversationWireFormat {
  admin_id: string;
  user_id: string;
  user_display_name: string;
  merged_conversation_subject: string;
  merged_conversation_id: number;
}

export default class MergedPrimaryConversation implements RenderableData {
  renderableType: RenderableType = RenderableType.MergedPrimaryConversation;
  readonly adminId: string;
  readonly userId: string;
  readonly userDisplayName: string;
  readonly mergedConversationSubject: string;
  readonly mergedConversationId: number;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminId,
    };
  }

  constructor(
    adminId: string,
    userId: string,
    userDisplayName: string,
    mergedConversationSubject: string,
    mergedConversationId: number,
  ) {
    this.adminId = adminId;
    this.userId = userId;
    this.userDisplayName = userDisplayName;
    this.mergedConversationSubject = mergedConversationSubject;
    this.mergedConversationId = mergedConversationId;
  }

  static deserialize(json: MergedPrimaryConversationWireFormat): MergedPrimaryConversation {
    return new MergedPrimaryConversation(
      json.admin_id,
      json.user_id,
      json.user_display_name,
      json.merged_conversation_subject,
      json.merged_conversation_id,
    );
  }
}
