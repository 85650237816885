/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  name: attr('string'),
  slug: attr('string'),
  content: attr(),
  categoryType: attr('string'),
  publiclyVisible: attr('boolean'),
  analyticsData: computed('slug', function () {
    return {
      object: 'app_store_category',
      category_name: this.slug,
    };
  }),
});
