/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import CustomBotSummary, { type CustomBotSummaryWireFormat } from '../custom-bot-summary';

interface UnengagedInboundBotIntroductionWireFormat {
  renderable_type: RenderableType;
  custom_bot_summary: CustomBotSummaryWireFormat;
}

export default class UnengagedInboundBotIntroduction implements RenderableData {
  readonly renderableType = RenderableType.UnengagedInboundBotIntroduction;
  readonly customBotSummary: CustomBotSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(customBotSummary: CustomBotSummary) {
    this.customBotSummary = customBotSummary;
  }

  static deserialize(
    json: UnengagedInboundBotIntroductionWireFormat,
  ): UnengagedInboundBotIntroduction {
    let customBotSummary = CustomBotSummary.deserialize(json.custom_bot_summary);
    return new UnengagedInboundBotIntroduction(customBotSummary);
  }
}
