/* RESPONSIBLE TEAM: team-help-desk-experience */

import { type Icon, EmojiIcon, NamedIcon } from './command-k/icons';

export type ViewSystemType =
  | 'teammate_created'
  | 'fin_conversations'
  | 'fin_ai_interactions'
  | 'ai_chatbot_all_conversations'
  | 'ai_chatbot_resolved'
  | 'ai_chatbot_routed'
  | 'ai_chatbot_abandoned'
  | 'ai_agent_all_conversations'
  | 'ai_agent_resolved'
  | 'ai_agent_routed'
  | 'ai_agent_abandoned';

export interface ViewSummaryWireFormat {
  id: number;
  name: string;
  icon?: string;
  count?: number;
  predicates?: any;
  system_type?: ViewSystemType;
  has_dynamic_admin_assignee_predicates?: boolean | null;
}

export default class ViewSummary {
  readonly id: number;
  readonly name: string;
  readonly icon?: Icon;
  readonly count?: number;
  readonly predicates?: any;
  readonly systemType: ViewSystemType;
  readonly hasDynamicAdminAssigneePredicates: boolean | null;

  constructor(
    id: number,
    name: string,
    icon: string | EmojiIcon | undefined = undefined,
    count: number | undefined = undefined,
    predicates: any | undefined = undefined,
    systemType: ViewSystemType | undefined = 'teammate_created',
    hasDynamicAdminAssigneePredicates: boolean | null = null,
  ) {
    this.id = id;
    this.name = name;
    this.count = count;
    this.predicates = predicates;
    this.systemType = systemType;
    this.hasDynamicAdminAssigneePredicates = hasDynamicAdminAssigneePredicates;

    switch (systemType) {
      case 'teammate_created':
        if (icon instanceof EmojiIcon) {
          this.icon = icon;
        } else if (icon) {
          this.icon = new EmojiIcon(icon);
        }
        break;
      case 'fin_conversations':
      case 'fin_ai_interactions':
      case 'ai_chatbot_all_conversations':
        this.icon = new NamedIcon('fin', 'standard');
        break;
      case 'ai_chatbot_resolved':
        this.icon = new NamedIcon('lwr-thumbs-up', 'standard');
        break;
      case 'ai_chatbot_routed':
        this.icon = new NamedIcon('new-direct-message', 'standard');
        break;
      case 'ai_chatbot_abandoned':
        this.icon = new NamedIcon('tooltip', 'standard');
        break;
      case 'ai_agent_all_conversations':
        this.icon = new NamedIcon('fin', 'standard');
        break;
      case 'ai_agent_resolved':
        this.icon = new NamedIcon('lwr-thumbs-up', 'standard');
        break;
      case 'ai_agent_routed':
        this.icon = new NamedIcon('new-direct-message', 'standard');
        break;
      case 'ai_agent_abandoned':
        this.icon = new NamedIcon('tooltip', 'standard');
        break;
    }
  }

  static deserialize(json: ViewSummaryWireFormat): ViewSummary {
    return new ViewSummary(
      json.id,
      json.name,
      json.icon,
      json.count,
      json.predicates,
      json.system_type,
      json.has_dynamic_admin_assignee_predicates,
    );
  }

  get isSystemView() {
    return this.systemType !== 'teammate_created';
  }

  get isEditable() {
    return !this.isSystemView;
  }

  get isAiChatbotView() {
    return [
      'ai_chatbot_all_conversations',
      'ai_chatbot_resolved',
      'ai_chatbot_routed',
      'ai_chatbot_abandoned',
      'ai_agent_all_conversations',
      'ai_agent_resolved',
      'ai_agent_routed',
      'ai_agent_abandoned',
    ].includes(this.systemType);
  }

  get isAiAgentResolvedView() {
    return this.systemType === 'ai_agent_resolved' || this.systemType === 'ai_chatbot_resolved';
  }
}
