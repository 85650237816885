/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import ajax from 'embercom/lib/ajax';

let UserContentSeenState = {
  updateConversationForAdmin(conversation, realTimeEventService) {
    if (conversation.get('disableSeenStateUpdates')) {
      return;
    }
    let lastUserPart = conversation.get('lastUserPart');
    if (lastUserPart === undefined || lastUserPart.get('isSeenByCurrentAdmin')) {
      return;
    }
    ajax({
      url: '/ember/conversation_parts/update_admin_seen_state',
      type: 'POST',
      data: JSON.stringify({
        app_id: conversation.get('app_id'),
        id: lastUserPart.get('id'),
      }),
    }).then(function () {
      lastUserPart.setSeen();
      realTimeEventService.sendUserContentSeenByAdminEvent(conversation);
    });
  },
};

export default UserContentSeenState;
