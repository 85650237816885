/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';

const VIDEO_PROJECTS = [
  {
    identifier: 'js_install',
    keyMatchRegex: /^js_install\//,
    title: 'Capture more leads and close more deals',
    subtitle: 'It takes just a few minutes to setup',
    ctaText: 'Install the Messenger',
    ctaUrl: `${TEAMMATE_APP_URL}/a/apps/_/settings/web`,
  },
  {
    identifier: 'maya_messenger',
    keyMatchRegex: /^people\/maya\/messenger_intro\//,
    title: 'Grow your business with a Messenger',
    subtitle: '',
    ctaText: `Learn more about Intercom's Messenger`,
    ctaUrl:
      'https://www.intercom.com/live-chat?&utm_medium=emailutm_source=mkt-nur&utm_campaign=20181015_video&utm_content=custom-video-video-cta',
  },
];

export function getVideoProjectByKey(videoKey) {
  return VIDEO_PROJECTS.find((p) => p.keyMatchRegex.test(videoKey));
}
