/* RESPONSIBLE TEAM: team-messenger */
import { modifier } from 'ember-modifier';

function onElementScroll(element: HTMLElement, [handleScroll]: [Function]) {
  function fn() {
    handleScroll(element);
  }

  element.addEventListener('scroll', fn);
  fn();

  return function cleanupObserver() {
    element.removeEventListener('scroll', fn);
  };
}

export default modifier(onElementScroll);
