/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, attr, hasMany } from '@ember-data/model';
import { equal, readOnly, not, or } from '@ember/object/computed';
import { fragment } from 'ember-data-model-fragments/attributes';
import { isAny } from '@intercom/pulse/lib/computed-properties';

export default Model.extend({
  slotGroup: belongsTo('messenger-home/slot-group'),
  canvas: attr(),
  messengerApp: belongsTo('messenger-app'),
  messengerAppId: attr('number'),
  displayOrder: attr('number'),
  slotType: attr('string'),
  hasConfiguration: attr('boolean'),
  externalLinks: hasMany('messenger-home/external-link'),
  externalLinksHaveUnsavedChanges: isAny('externalLinks', 'hasUnsavedChanges'),
  ticketLinks: hasMany('messenger-home/ticket-link'),
  ticketLinksHaveUnsavedChanges: isAny('ticketLinks', 'hasUnsavedChanges'),
  hasUnsavedChanges: or(
    'hasDirtyAttributes',
    'externalLinksHaveUnsavedChanges',
    'externalLinksWereDeleted',
    'ticketLinksHaveUnsavedChanges',
    'ticketLinksWereDeleted',
  ),
  predicateGroup: fragment('predicates/predicate-group'),
  isHelpCenterSlot: equal('slotType', 'help_center'),
  isNewConversationCardSlot: equal('slotType', 'new_conversation_card'),
  isNewsSlot: equal('slotType', 'news_items_card'),
  isTasksSlot: equal('slotType', 'checklists_card'),
  isExternalLinksSlot: equal('slotType', 'external_links_card'),
  isTicketLinksSlot: equal('slotType', 'ticket_links_card'),
  isDraggingDisabledInSearchBrowseSettings: not('isHelpCenterSlot'),
  isDraggingDisabledInHomeSettings: readOnly('isHelpCenterSlot'),
  externalLinksWereDeleted: false,
  ticketLinksWereDeleted: false,

  addExternalLink({ label, href, displayOrder = this.externalLinks.length }) {
    let externalLink = this.store.createRecord('messenger-home/external-link', {
      slot: this,
      label,
      href,
      displayOrder,
    });
    this.externalLinks.pushObject(externalLink);
    return externalLink;
  },

  removeExternalLink(externalLink) {
    this.externalLinks.removeObject(externalLink);
    externalLink.deleteRecord();
    this.set('externalLinksWereDeleted', true);
    this.resetExternalLinkDisplayOrder(this.externalLinks);
  },

  resetExternalLinkDisplayOrder(externalLinks) {
    externalLinks.forEach((link, index) => link.set('displayOrder', index));
    this.set('externalLinks', externalLinks);
  },

  removeExternalLinksWithoutIds() {
    this.externalLinks.filterBy('id', null).forEach((externalLink) => externalLink.deleteRecord());
  },

  removeTicketLinksWithoutIds() {
    this.ticketLinks.filterBy('id', null).forEach((ticketLink) => ticketLink.deleteRecord());
  },

  addTicketLink({ ticketTypeId, icon, displayOrder = this.ticketLinks.length }) {
    let ticketLink = this.store.createRecord('messenger-home/ticket-link', {
      slot: this,
      ticketTypeId,
      icon,
      displayOrder,
    });
    this.ticketLinks.pushObject(ticketLink);
    return ticketLink;
  },

  resetTicketLinkDisplayOrder(ticketLinks) {
    ticketLinks.forEach((link, index) => link.set('displayOrder', index));
    this.set('ticketLinks', ticketLinks);
  },

  removeTicketLink(ticketLink) {
    this.ticketLinks.removeObject(ticketLink);
    ticketLink.deleteRecord();
    this.set('ticketLinksWereDeleted', true);
    this.resetTicketLinkDisplayOrder(this.ticketLinks);
  },
});
