/* RESPONSIBLE TEAM: team-help-desk-experience */

import AdminSummary from 'embercom/objects/inbox/admin-summary';
import Tag from 'embercom/objects/inbox/tag';

export interface TaggingWireFormat {
  id: number;
  created_at: Date;
  deleted: boolean;
  tag_summary: {
    id: number;
    name: string;
  };
  admin_summary?: {
    id: number;
    name: string;
    image_url?: string;
  };
}

export default class Tagging {
  static deserialize(tag: TaggingWireFormat) {
    let { created_at: createdAt, tag_summary: tagSummary, admin_summary } = tag;
    let adminSummary = admin_summary
      ? {
          ...admin_summary,
          image_url: admin_summary.image_url ?? '',
        }
      : undefined;

    let createdBy = adminSummary ? AdminSummary.deserialize(adminSummary) : undefined;

    return new Tag(tagSummary.id, tagSummary.name, createdBy, createdAt);
  }
}
