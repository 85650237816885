/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { fragment } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type Font from './global-options/font';
import type GlobalComponentStyle from './global-options/global-component-style';
import type NamedComponents from './global-options/named-components';
import { CardStyleType } from './styles/card';
import type Brand from './global-options/brand';

const DEFAULT_GLOBAL_CARD_STYLE = {
  type: CardStyleType.BORDERED,
  borderRadius: 8,
};

export default class GlobalOptions extends Fragment {
  @fragment('customization-options/global-options/font')
  declare font: Font;

  @fragment('customization-options/global-options/global-component-style')
  declare componentStyle: GlobalComponentStyle;

  @fragment('customization-options/global-options/named-components')
  declare namedComponents: NamedComponents;

  @fragment('customization-options/global-options/brand')
  declare brand: Brand;

  setDefaultValues() {
    if (!this.componentStyle) {
      this.componentStyle = this.store.createFragment(
        'customization-options/global-options/global-component-style',
        {
          card: DEFAULT_GLOBAL_CARD_STYLE,
        },
      );
    }

    if (this.componentStyle.card) {
      let { type, borderRadius } = DEFAULT_GLOBAL_CARD_STYLE;

      if (!this.componentStyle.card.type) {
        this.componentStyle.card.type = type;
      }

      if (!this.componentStyle.card.borderRadius) {
        this.componentStyle.card.borderRadius = borderRadius;
      }
    }

    if (!this.namedComponents) {
      this.namedComponents = this.store.createFragment(
        'customization-options/global-options/named-components',
      );
    }

    this.namedComponents.setDefaultValues();
  }
}
