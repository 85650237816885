/* RESPONSIBLE TEAM: team-help-desk-experience */
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import AdminWithPermissions, {
  type AdminWithPermissionsWireFormat,
} from './admin-with-permissions';
import Macro, { type MacroWireFormat } from './macro';
import Tag, { type TagWireFormat } from './tag';
import WorkflowConnectorAction, {
  type WorkflowConnectorActionWireFormat,
} from './workflow-connector-action';
import TeamSummary, { type TeamSummaryWireFormat } from './team-summary';
import ViewSummary, { type ViewSummaryWireFormat } from './view-summary';
import GithubIssueSummary, { type GithubIssueSummaryWireFormat } from './github-issue-summary';
import ConversationTopic, { type ConversationTopicWireFormat } from './conversation-topic';
import TriggerableCustomBot, {
  type TriggerableCustomBotWireFormat,
} from './triggerable-custom-bot';
import type fuzzysort from 'fuzzysort';

export type SearchableData =
  | AdminWithPermissions
  | Macro
  | Tag
  | TeamSummary
  | ViewSummary
  | GithubIssueSummary
  | WorkflowConnectorAction
  | ConversationTopic
  | TriggerableCustomBot;

const SearchableDataMap = {
  [SearchableType.Admin]: AdminWithPermissions,
  [SearchableType.Macro]: Macro,
  [SearchableType.Tag]: Tag,
  [SearchableType.Team]: TeamSummary,
  [SearchableType.View]: ViewSummary,
  [SearchableType.ConversationTopic]: ConversationTopic,
  [SearchableType.GithubIssue]: GithubIssueSummary,
  [SearchableType.TriggerableCustomBot]: TriggerableCustomBot,
  [SearchableType.WorkflowConnectorAction]: WorkflowConnectorAction,
} as const;

export type SearchableDataForEntityType<T extends SearchableType> = InstanceType<
  (typeof SearchableDataMap)[T]
>;

type SearchableDocumentBaseWireFormat = {
  entity_id: number;
  created_at: string;
  updated_at: string;
  restricted_contexts?: number[];
};

export type AdminWithPermissionsDocumentWireFormat = SearchableDocumentBaseWireFormat & {
  entity_type: SearchableType.Admin;
  data: AdminWithPermissionsWireFormat;
};

export type MacroDocumentWireFormat = SearchableDocumentBaseWireFormat & {
  entity_type: SearchableType.Macro;
  data: MacroWireFormat;
};

export type TagDocumentWireFormat = SearchableDocumentBaseWireFormat & {
  entity_type: SearchableType.Tag;
  data: TagWireFormat;
};

export type TeamSummaryDocumentWireFormat = SearchableDocumentBaseWireFormat & {
  entity_type: SearchableType.Team;
  data: TeamSummaryWireFormat;
};

export type ViewSummaryDocumentWireFormat = SearchableDocumentBaseWireFormat & {
  entity_type: SearchableType.View;
  data: ViewSummaryWireFormat;
};

export type GithubIssueSummaryDocumentWireFormat = SearchableDocumentBaseWireFormat & {
  entity_type: SearchableType.GithubIssue;
  data: GithubIssueSummaryWireFormat;
};

export type ConversationTopicDocumentWireFormat = SearchableDocumentBaseWireFormat & {
  entity_type: SearchableType.ConversationTopic;
  data: ConversationTopicWireFormat;
};

export type WorkflowConnectorActionDocumentWireFormat = SearchableDocumentBaseWireFormat & {
  entity_type: SearchableType.WorkflowConnectorAction;
  data: WorkflowConnectorActionWireFormat;
};

export type TriggerableCustomBotDocumentWireFormat = SearchableDocumentBaseWireFormat & {
  entity_type: SearchableType.TriggerableCustomBot;
  data: TriggerableCustomBotWireFormat;
};

export type SearchableDocumentWireFormat =
  | AdminWithPermissionsDocumentWireFormat
  | MacroDocumentWireFormat
  | TagDocumentWireFormat
  | TeamSummaryDocumentWireFormat
  | ViewSummaryDocumentWireFormat
  | GithubIssueSummaryDocumentWireFormat
  | ConversationTopicDocumentWireFormat
  | WorkflowConnectorActionDocumentWireFormat
  | TriggerableCustomBotDocumentWireFormat;

export default class SearchableDocument {
  entityId: number;
  entityType: SearchableType;
  createdAt: Date;
  updatedAt: Date;
  data: SearchableData;
  restrictedContexts: number[];
  highlightedLabel?: string;
  preparedFuzzySearchData?: ReturnType<typeof fuzzysort.prepare>;

  constructor(
    entityId: number,
    entityType: SearchableType,
    createdAt: Date,
    updatedAt: Date,
    data: SearchableData,
    restrictedContexts: number[] = [],
    highlightedLabel?: string,
    preparedFuzzySearchData?: ReturnType<typeof fuzzysort.prepare>,
  ) {
    this.entityId = entityId;
    this.entityType = entityType;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.data = data;
    this.restrictedContexts = restrictedContexts;
    this.highlightedLabel = highlightedLabel;
    this.preparedFuzzySearchData = preparedFuzzySearchData;
  }

  static deserialize(json: SearchableDocumentWireFormat): SearchableDocument {
    let data;

    switch (json.entity_type) {
      case SearchableType.Admin: {
        data = AdminWithPermissions.deserialize(json.data);
        break;
      }
      case SearchableType.Macro: {
        data = Macro.deserialize(json.data);
        break;
      }
      case SearchableType.Tag: {
        data = Tag.deserialize(json.data);
        break;
      }
      case SearchableType.Team: {
        data = TeamSummary.deserialize(json.data);
        break;
      }
      case SearchableType.View: {
        data = ViewSummary.deserialize(json.data);
        break;
      }
      case SearchableType.GithubIssue: {
        data = GithubIssueSummary.deserialize(json.data);
        break;
      }
      case SearchableType.ConversationTopic: {
        data = ConversationTopic.deserialize(json.data);
        break;
      }
      case SearchableType.WorkflowConnectorAction: {
        data = WorkflowConnectorAction.deserialize(json.data);
        break;
      }
      case SearchableType.TriggerableCustomBot: {
        data = TriggerableCustomBot.deserialize(json.data);
        break;
      }
      default: {
        let exhaustive: any = json;
        throw new Error(`Unsupported SearchableData type: [${exhaustive.entity_type}]`);
      }
    }

    return new SearchableDocument(
      json.entity_id,
      json.entity_type,
      new Date(json.created_at),
      new Date(json.updated_at),
      data,
      json.restricted_contexts,
    );
  }

  withHighlight(highlightedValue?: string): SearchableDocument {
    return new SearchableDocument(
      this.entityId,
      this.entityType,
      this.createdAt,
      this.updatedAt,
      this.data,
      this.restrictedContexts,
      highlightedValue,
      this.preparedFuzzySearchData,
    );
  }
}
