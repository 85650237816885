/* RESPONSIBLE TEAM: team-proactive-support */
import { type ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { EntityType } from 'embercom/models/data/entity-types';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type ContentStamp, contentStampStyleObj } from './content';

export interface HighlightedResult {
  type: 'plain' | 'highlight';
  text: string;
}

export interface KnowledgeBaseSearchResultWireFormat {
  readonly id: string;
  readonly title: string;
  readonly highlighted_title: HighlightedResult[];
  readonly highlighted_body: HighlightedResult[];
  readonly entity_id: number;
  readonly entity_type: EntityType;
  readonly content_wrapper_type: ContentWrapperType;
  readonly author_summary?: AdminSummaryWireFormat;
  readonly plain_text_summary?: string;
  readonly created_at: string;
  readonly updated_at: string;
}

export interface KnowledgeBaseSearchResultsWireFormat {
  results: KnowledgeBaseSearchResultWireFormat[];
}

export interface KnowledgeBaseSearchResultConstructorFormat {
  readonly id: string;
  readonly title: string;
  readonly highlightedTitle: HighlightedResult[];
  readonly highlightedBody: HighlightedResult[];
  readonly entityId: number;
  readonly entityType: EntityType;
  readonly contentWrapperType: ContentWrapperType;
  readonly author?: AdminSummary;
  readonly plainTextSummary?: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export class KnowledgeBaseSearchResult {
  readonly id: string;
  readonly title: string;
  readonly highlightedTitle: HighlightedResult[];
  readonly highlightedBody: HighlightedResult[];
  readonly entityId: number;
  readonly entityType: EntityType;
  readonly contentWrapperType: ContentWrapperType;
  readonly author?: AdminSummary;
  readonly plainTextSummary?: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(inputs: KnowledgeBaseSearchResultConstructorFormat) {
    this.id = inputs.id;
    this.title = inputs.title;
    this.highlightedTitle = inputs.highlightedTitle;
    this.highlightedBody = inputs.highlightedBody;
    this.entityId = inputs.entityId;
    this.entityType = inputs.entityType;
    this.contentWrapperType = inputs.contentWrapperType;
    this.author = inputs.author;
    this.plainTextSummary = inputs.plainTextSummary;
    this.createdAt = inputs.createdAt;
    this.updatedAt = inputs.updatedAt;
  }

  static deserialize(json: KnowledgeBaseSearchResultWireFormat) {
    return new KnowledgeBaseSearchResult({
      id: json.id,
      title: json.title,
      highlightedTitle: json.highlighted_title,
      highlightedBody: json.highlighted_body,
      entityId: json.entity_id,
      entityType: json.entity_type,
      contentWrapperType: json.content_wrapper_type,
      author: json.author_summary ? AdminSummary.deserialize(json.author_summary) : undefined,
      plainTextSummary: json.plain_text_summary,
      createdAt: new Date(json.created_at),
      updatedAt: new Date(json.updated_at),
    });
  }

  get isInternalArticle(): boolean {
    return this.entityType === EntityType.InternalArticle;
  }

  get icon(): InterfaceIconName | undefined {
    switch (this.entityType) {
      case EntityType.ArticleContent:
        return 'article';
      case EntityType.InternalArticle:
        return 'locked';
      default:
        return undefined;
    }
  }

  get stamp(): ContentStamp | undefined {
    return contentStampStyleObj(this.entityType);
  }

  get entityName(): string {
    return humanReadableObjectNames[this.entityType];
  }
}
