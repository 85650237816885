/* RESPONSIBLE TEAM: team-channels */
import { modifier } from 'ember-modifier';

export interface ClickOutsideSignature {
  Element: HTMLElement;
  Args: {
    Positional: [(event: MouseEvent) => void];
  };
}

const ClickOutsideModifier = modifier<ClickOutsideSignature>(
  (element, [callback]) => {
    if (!callback) {
      return;
    }

    function handleClick(event: MouseEvent) {
      if (!element.contains(event.target as Node)) {
        callback(event);
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  },
  { eager: false },
);

export default ClickOutsideModifier;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'click-outside': typeof ClickOutsideModifier;
  }
}
