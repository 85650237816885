/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { isAny } from '@intercom/pulse/lib/computed-properties';
import { readOnly, not } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Mixin.create({
  paths: fragmentArray('operator/workflows/path', {
    defaultValue: () => [],
  }),

  _selectedPath: null,
  firstStep: readOnly('paths.firstObject.steps.firstObject'),
  lastStep: readOnly('paths.firstObject.steps.lastObject'),
  isUploading: isAny('paths', 'isUploading'),
  isNotUploading: not('isUploading'),
  contentType: null,

  selectedPath: computed('_selectedPath', 'paths.firstObject', function () {
    return this._selectedPath ? this._selectedPath : this.paths.firstObject;
  }),

  beforeEdit() {
    this.initializeFragmentsAndSetInverseRelationships();
  },

  beforeSave() {
    this.paths.invoke('ensureStepsAreLinkedCorrectly');
  },

  save() {
    this.paths.invoke('ensureStepsAreLinkedCorrectly');
    return this._super(...arguments);
  },

  setSelectedPath(path) {
    this.set('_selectedPath', path);
  },

  initializeFragmentsAndSetInverseRelationships() {
    this.setInverseRelationships();
    this._createEmptySimpleActionRuleForEndStateSteps();
  },

  setInverseRelationships() {
    this.setIndexOnPaths();
    this._setBranchingStepNextPath();
  },

  setIndexOnPaths() {
    this.paths.forEach((path, index) => {
      path.set('index', index);
    });
  },

  resetInvalidActions() {
    this.paths.forEach((path) => {
      path.steps.forEach((step) => {
        this._resetInvalidActionsInStep(step);
      });
    });
  },

  _resetInvalidActionsInStep(step) {
    step.simpleActionRule?.actions?.forEach((action) => this._resetInvalidAction(action));

    step.followUpRules.forEach((followUpRule) => {
      followUpRule.actions.forEach((action) => this._resetInvalidAction(action));
    });
  },

  _resetInvalidAction(action) {
    if (action.type === 'tag-user') {
      if (!this.availableTags.find((tag) => action.actionData?.tag === tag.name)) {
        action.set('actionData', {});
      }
    } else if (action.type === 'add-tag-to-conversation') {
      if (!this.availableTags.find((tag) => action.actionData?.tag_id === tag.id)) {
        action.set('actionData', {});
      }
    }
  },

  _createEmptySimpleActionRuleForEndStateSteps() {
    this.paths.forEach((path) => {
      if (
        !path.get('endStateStep.hasSimpleActionRule') &&
        (path.get('endStateStep.hasFollowUpRules') ||
          (path.get('endStateStep.isTerminal') && this.allowsActionsInTerminalStep))
      ) {
        path.set('endStateStep.simpleActionRule', this.store.createFragment('rules/rule'));
      }
    });
  },

  _setBranchingStepNextPath() {
    let stepPathMap = this._getPathOfSteps();
    this.paths.forEach((path) => {
      path.get('steps').forEach((step) => {
        step.branchingControls.forEach((control) => {
          let nextPath = stepPathMap.get(control.get('nextStepUuid'));
          control.set('path', path);
          control.linkToPath(nextPath);
        });
      });
    });
  },

  _getPathOfSteps() {
    let pathOfSteps = new Map();
    this.paths.forEach((path) => {
      path.get('steps').forEach((step) => {
        pathOfSteps.set(step.get('uuid'), path);
      });
    });
    return pathOfSteps;
  },

  addPath(newPath, index) {
    if (!index) {
      index = this.paths.length;
    }
    this.paths.insertAt(index, newPath);
    this.setIndexOnPaths();
  },

  removePath(pathToRemove) {
    this.paths.removeObject(pathToRemove);
    this.setIndexOnPaths();
  },

  reorderPaths(reorderedPaths) {
    this.paths.clear();
    this.paths.addObjects(reorderedPaths);
    this.setIndexOnPaths();
  },
});
