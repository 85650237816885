/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const SUPPORT_SEAT_TYPE = 'support';
export const PROACTIVE_SUPPORT_SEAT_TYPE = 'proactive_support';
export const ENGAGE_SEAT_TYPE = 'engage';
// https://github.com/intercom/intercom/issues/231868
// We've renamed the VBP2.0 Core plans as part of a wider brand refresh. Before we can rename
// the Convert seat type from "marketing" to "convert", we need to ensure we're serialzing down the
// seat type as expected from Intercom / Billing
export const CONVERT_SEAT_TYPE = 'marketing';
export const COLLABORATOR_SEAT_TYPE = 'collaborator';
// Pricing 5.x Seat types
export const PRICING_5_X_LITE_SEAT_TYPE = 'light';
export const PRICING_5_X_FULL_SEAT_TYPE = 'core';
export const COPILOT_SEAT_TYPE = 'copilot';
export const TEAMMATE_USAGE = 'used';
export const INVITE_USAGE = 'pending';
export const NON_SPECIFIC_SEAT_TYPE_REQUEST = 'non-specific';

// Seat type feature keys
export const LIGHT_SEAT_TYPE_FEATURE_KEY = 'light_seat_type';
export const CORE_SEAT_TYPE_FEATURE_KEY = 'core_seat_type';

// seat billing period
export const MONTHLY_BILLING_PERIOD = '1';
export const ANNUAL_BILLING_PERIOD = '12';

export type SeatType =
  | typeof SUPPORT_SEAT_TYPE
  | typeof PROACTIVE_SUPPORT_SEAT_TYPE
  | typeof ENGAGE_SEAT_TYPE
  | typeof CONVERT_SEAT_TYPE
  | typeof COLLABORATOR_SEAT_TYPE
  | typeof PRICING_5_X_LITE_SEAT_TYPE
  | typeof PRICING_5_X_FULL_SEAT_TYPE
  | typeof COPILOT_SEAT_TYPE;

type SeatTypeLabels = { [key in SeatType]?: string };

export const VBP2_BASE_SEAT_TYPES: SeatType[] = [
  SUPPORT_SEAT_TYPE,
  ENGAGE_SEAT_TYPE,
  CONVERT_SEAT_TYPE,
  COLLABORATOR_SEAT_TYPE,
];

export const VBP2_SEAT_TYPES = VBP2_BASE_SEAT_TYPES.concat([
  PROACTIVE_SUPPORT_SEAT_TYPE,
  COPILOT_SEAT_TYPE,
]);

export const PRICING_5_X_BASE_SEAT_TYPES: SeatType[] = [
  PRICING_5_X_FULL_SEAT_TYPE,
  PRICING_5_X_LITE_SEAT_TYPE,
];

export const PRICING_5_X_SEAT_TYPES = PRICING_5_X_BASE_SEAT_TYPES.concat([COPILOT_SEAT_TYPE]);

export const VBP2_SEAT_TYPES_AVAILABLE_IN_UI: SeatType[] = [
  SUPPORT_SEAT_TYPE,
  PROACTIVE_SUPPORT_SEAT_TYPE,
  ENGAGE_SEAT_TYPE,
  CONVERT_SEAT_TYPE,
];

export const ALL_SEAT_TYPES = [...VBP2_SEAT_TYPES, ...PRICING_5_X_SEAT_TYPES];

export const VBP2_SEAT_LABELS_KEYS: SeatTypeLabels = {
  [SUPPORT_SEAT_TYPE]: 'settings.seats.support',
  [PROACTIVE_SUPPORT_SEAT_TYPE]: 'settings.seats.proactive-support',
  [ENGAGE_SEAT_TYPE]: 'settings.seats.engage',
  [CONVERT_SEAT_TYPE]: 'settings.seats.marketing',
  [COLLABORATOR_SEAT_TYPE]: 'settings.seats.collaborator',
  [COPILOT_SEAT_TYPE]: 'settings.seats.copilot',
};

export const VBP2_SEAT_LABELS: SeatTypeLabels = {
  [SUPPORT_SEAT_TYPE]: 'Support',
  [PROACTIVE_SUPPORT_SEAT_TYPE]: 'Proactive support',
  [ENGAGE_SEAT_TYPE]: 'Engage',
  [CONVERT_SEAT_TYPE]: 'Convert',
  [COLLABORATOR_SEAT_TYPE]: 'Collaborator',
  [COPILOT_SEAT_TYPE]: 'Copilot',
};

export const PRICING_5_X_SEAT_LABEL_KEYS: SeatTypeLabels = {
  [PRICING_5_X_LITE_SEAT_TYPE]: 'settings.seats.lite-seat',
  [PRICING_5_X_FULL_SEAT_TYPE]: 'settings.seats.core-seat',
  [COPILOT_SEAT_TYPE]: 'settings.seats.copilot',
};

export const PRICING_5_X_SEAT_LABELS: SeatTypeLabels = {
  [PRICING_5_X_LITE_SEAT_TYPE]: 'Lite',
  [PRICING_5_X_FULL_SEAT_TYPE]: 'Full',
  [COPILOT_SEAT_TYPE]: 'Copilot',
};

export const ALL_SEAT_LABELS: SeatTypeLabels = {
  ...VBP2_SEAT_LABELS,
  ...PRICING_5_X_SEAT_LABELS,
};

//Split out into VBP2 and Pricing 5.x
export function availableUiSeatTypes(isPricing5: boolean): SeatType[] {
  return isPricing5 ? PRICING_5_X_SEAT_TYPES : VBP2_SEAT_TYPES;
}

export const INVALID_SEAT_COMBINATIONS = {
  [ENGAGE_SEAT_TYPE]: [PROACTIVE_SUPPORT_SEAT_TYPE],
  [COLLABORATOR_SEAT_TYPE]: [
    SUPPORT_SEAT_TYPE,
    PROACTIVE_SUPPORT_SEAT_TYPE,
    ENGAGE_SEAT_TYPE,
    CONVERT_SEAT_TYPE,
  ],
  [PRICING_5_X_LITE_SEAT_TYPE]: [PRICING_5_X_FULL_SEAT_TYPE],
  [PRICING_5_X_FULL_SEAT_TYPE]: [PRICING_5_X_LITE_SEAT_TYPE],
};

//Permissions page redesign
export const CONVERSATION_SECTION_ID = 'conversations-section';
export const COPILOT_SECTION_ID = 'copilot-section';

export interface SeatUsage {
  contractedLimit: number;
  pending: number;
  price: number;
  used: number;
}
