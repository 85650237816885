/* RESPONSIBLE TEAM: team-workflows */
import { default as RuleAction } from 'embercom/models/rules/rule-action';

export default class WorkflowAction extends RuleAction {
  get indexComponent() {
    return `workflows/index/actions/${this.type}`;
  }

  get sortOrder() {
    return [
      'assign-conversation',
      'assign-conversation-to-owner',
      'change-conversation-priority',
      'apply-conversation-sla',
      'add-tag-to-conversation',
      'snooze-conversation',
      'close-conversation',
      'set-conversation-attribute-data',
    ].indexOf(this.type);
  }
}
