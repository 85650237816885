/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type ConversationRecord } from './types/conversation-record';
import ConversationAttributeSummary from './conversation-attribute-summary';
import type TeamSummary from './team-summary';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type Descriptor } from 'embercom/objects/inbox/types/descriptor';
import {
  type DescriptorCondition,
  type DescriptorConditionWireFormat,
} from 'embercom/objects/inbox/descriptor-condition';

export enum DataType {
  String = 'string',
  Integer = 'integer',
  List = 'list',
  Decimal = 'decimal',
  Boolean = 'boolean',
  DomainObjectRelationship = 'domain_object_relationship',
  UserRelationship = 'user_relationship',
  Datetime = 'datetime',
  Relationship = 'relationship',
  Files = 'files',
  AdminId = 'admin_id',
}

export interface ConversationAttributeDescriptorListOption {
  id: string;
  label: string;
  archived: boolean;
}

export interface ConversationAttributeDescriptorWireFormat {
  id: number | string;
  name: string;
  data_type: DataType;
  order?: number;
  archived?: boolean;
  list_options?: ConversationAttributeDescriptorListOption[];
  required?: boolean;
  visible_to_team_ids?: string[];
  multiline?: boolean;
  editable: boolean;
  hide_from_inbox_sidebar?: boolean;
  icon?: InterfaceIconName;
  category?: string | null;
  conditions?: DescriptorConditionWireFormat[];
  dependent_conditions?: DescriptorConditionWireFormat[];
}

export default class ConversationAttributeDescriptor {
  readonly id: number | string;
  readonly name: string;
  readonly type: DataType;
  readonly order?: number;
  readonly archived?: boolean;
  readonly listOptions?: ConversationAttributeDescriptorListOption[];
  readonly required: boolean | null;
  readonly visibleToTeamIds?: number[];
  readonly multiline?: boolean;
  readonly editable: boolean;
  readonly hideFromInboxSidebar: boolean;
  readonly icon?: InterfaceIconName;
  readonly category?: string | null;
  readonly conditions: DescriptorCondition[] = [];
  readonly dependentConditions: DescriptorCondition[] = [];

  constructor(
    id: number | string,
    name: string,
    type: DataType,
    editable?: boolean,
    order?: number,
    archived?: boolean,
    listOptions?: ConversationAttributeDescriptorListOption[],
    required?: boolean,
    visibleToTeamIds?: number[],
    multiline?: boolean,
    hideFromInboxSidebar?: boolean,
    icon?: InterfaceIconName,
    category?: string | null,
    conditions: DescriptorCondition[] = [],
    dependentConditions: DescriptorCondition[] = [],
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.editable = editable === undefined ? true : editable;
    this.order = order;
    this.archived = archived;
    this.listOptions = listOptions;
    this.required = !!required;
    this.visibleToTeamIds = visibleToTeamIds;
    this.multiline = multiline;
    this.hideFromInboxSidebar = hideFromInboxSidebar === undefined ? false : hideFromInboxSidebar;
    this.icon = icon;
    this.category = category;
    this.conditions = conditions;
    this.dependentConditions = dependentConditions;
  }

  isVisibleToTeamId(teamId?: number): boolean {
    if (this.visibleToTeamIds && this.visibleToTeamIds.length) {
      return !!teamId && this.visibleToTeamIds.includes(teamId);
    }

    return true;
  }

  get isRelationshipDataType() {
    return this.type === DataType.Relationship;
  }

  get isFilesType() {
    return this.type === DataType.Files;
  }

  get isDependentAttribute() {
    return this.conditions.length > 0;
  }

  get isControllingAttribute() {
    return this.dependentConditions.length > 0;
  }

  matchedDependents(value: string): DescriptorCondition[] {
    return this.dependentConditions.filter((condition) => {
      return condition.controllingListOptionId === value;
    });
  }

  shouldShowDependent(dependentAttribute: Descriptor, value: string): boolean {
    return this.dependentConditions.some((condition) => {
      return (
        condition.descriptorId === dependentAttribute.id &&
        condition.controllingListOptionId === value
      );
    });
  }

  dependentAttributeIds() {
    return this.dependentConditions.map((condition) => condition.descriptorId);
  }

  controllingAttributeIds() {
    return this.conditions.map((condition) => condition.controllingDescriptorId);
  }

  matchedControllersListOptions(values: Record<number, ConversationAttributeSummary>): string[] {
    return this.conditions
      .filter((condition) => {
        return (
          condition.controllingListOptionId === values[condition.controllingDescriptorId]?.value
        );
      })
      .flatMap((condition) => condition.descriptorListOptionIds)
      .filter(Boolean);
  }

  static deserialize(
    json: ConversationAttributeDescriptorWireFormat,
  ): ConversationAttributeDescriptor {
    let id: number | string = Number(json.id);
    if (isNaN(id)) {
      id = json.id;
    }

    return new ConversationAttributeDescriptor(
      id,
      json.name,
      json.data_type,
      json.editable,
      json.order,
      json.archived,
      json.list_options,
      json.required,
      json.visible_to_team_ids?.map((id) => parseInt(id, 10)),
      json.multiline,
      json.hide_from_inbox_sidebar,
      json.icon,
      json.category,
      json?.conditions?.map((condition) => ({
        id: condition.id,
        descriptorId: condition.descriptor_id,
        controllingDescriptorId: condition.controlling_descriptor_id,
        controllingListOptionId: condition.controlling_list_option_id,
        descriptorListOptionIds: condition.descriptor_list_option_ids,
      })),
      json?.dependent_conditions?.map((condition) => ({
        id: condition.id,
        descriptorId: condition.descriptor_id,
        controllingDescriptorId: condition.controlling_descriptor_id,
        controllingListOptionId: condition.controlling_list_option_id,
        descriptorListOptionIds: condition.descriptor_list_option_ids,
      })),
    );
  }
}

export function requiredAttributesForTeamAssignee(
  descriptors: Descriptor[],
  team?: TeamSummary,
): Descriptor[] {
  let isRequired = (descriptor: ConversationAttributeDescriptor) =>
    !descriptor.archived && descriptor.required;

  let isVisibleToTeam = (descriptor: ConversationAttributeDescriptor) =>
    descriptor.isVisibleToTeamId(team?.id);

  return descriptors.filter(isVisibleToTeam).filter(isRequired);
}

export function emptyRequiredAttributesForConversation(
  conversation: ConversationRecord,
  descriptors: Descriptor[],
): ConversationAttributeSummary[] {
  if (!conversation.isReplyable) {
    return [];
  }

  let matchingAttribute = (descriptor: ConversationAttributeDescriptor) =>
    (conversation.attributes || []).find(
      (attribute: ConversationAttributeSummary) => attribute.descriptor.id === descriptor.id,
    ) || new ConversationAttributeSummary(undefined, descriptor);

  let isValueEmpty = (attribute: ConversationAttributeSummary) => attribute.isValueEmpty;

  return requiredAttributesForTeamAssignee(descriptors, conversation.teamAssignee)
    .map(matchingAttribute)
    .filter(isValueEmpty);
}
