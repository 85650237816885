/* RESPONSIBLE TEAM: team-channels */

import { tracked } from '@glimmer/tracking';

export enum SlaStateTargetType {
  FirstReplyTime = 'first_reply_time',
  NextReplyTime = 'next_reply_time',
  TimeToClose = 'time_to_close',
  TimeToResolve = 'time_to_resolve',
}

export enum SlaStatus {
  Active = 'active',
  Hit = 'hit',
  Missed = 'missed',
  BreachFixed = 'breach_fixed',
  Paused = 'paused',
}

export interface ConversationSlaStateWireFormat {
  target_type: SlaStateTargetType;
  next_breach_at?: Date;
  paused_at?: Date;
  seconds_remaining_at_pause?: number;
  status?: SlaStatus;
}

export default class ConversationSlaState {
  @tracked targetType: SlaStateTargetType;
  @tracked nextBreachAt?: Date;
  @tracked pausedAt?: Date;
  @tracked remainingSeconds?: number;
  @tracked status?: SlaStatus;

  constructor(
    target_type: SlaStateTargetType,
    next_breach_at: Date | undefined,
    paused_at: Date | undefined,
    remaining_seconds?: number,
    status?: SlaStatus,
  ) {
    this.targetType = target_type;
    this.nextBreachAt = next_breach_at ? new Date(next_breach_at) : undefined;
    this.pausedAt = paused_at ? new Date(paused_at) : undefined;
    this.remainingSeconds = remaining_seconds;
    this.status = status;
  }

  get shortLabel() {
    if (this.nextBreachAt && this.nextBreachAt.getTime() < Date.now()) {
      return `inbox.conversation-details.slas.${this.targetType}.short.breached`;
    } else {
      return `inbox.conversation-details.slas.${this.targetType}.short.due`;
    }
  }

  get longLabel() {
    return `inbox.conversation-details.slas.${this.targetType}.long`;
  }

  static deserialize(json: ConversationSlaStateWireFormat): ConversationSlaState {
    return new ConversationSlaState(
      json.target_type,
      json.next_breach_at,
      json.paused_at,
      json.seconds_remaining_at_pause,
      json.status,
    );
  }
}
