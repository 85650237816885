/* RESPONSIBLE TEAM: team-proactive-support */
/* eslint-disable @intercom/intercom/no-bare-strings */

// @ts-ignore
import PredicateGroup from 'predicates/models/predicate-group';
// @ts-ignore
import { default as PredicateModel } from 'predicates/models/predicate';

import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';

import {
  type State,
  MatchBehavior,
  matchingLocations as MatchingLocation,
} from 'embercom/models/data/matching-system/matching-constants';
import type Store from '@ember-data/store';

interface CompanySummary {
  id: string;
  name: string;
}

interface Predicate {
  attribute: string;
  type: string;
  comparison: string;
  value: string;
}

interface PredicateDiffWireFormat {
  value_at_match: Array<Predicate>;
  value_now: Array<Predicate>;
  is_equal: boolean;
}

interface EntryRecordSummaryWireFormat {
  id: number;
  locked_company?: CompanySummary;
  created_at: string;
}

interface CheckpointSummaryWireFormat {
  id: number;
  company?: CompanySummary;
  created_at: string;
  status: number;
  matching_location_type: number;
  expires_at: string;
  foreground_processable_at: string;
  background_processable_at: string;
}

interface TriggerSummaryWireFormat {
  event_id: number;
  event_type: number;
  event_predicates: Array<Predicate>;
}

interface MatchRecordWireFormat {
  id: number;
  company: CompanySummary;
  created_at: string;
  last_matched_at: string;
  match_count: number;
}

interface UsedAttributesWireFormat {
  key: string;
  value_at_match: string;
  value_now: string;
  is_equal: boolean;
}

interface TriggerWireFormat {
  value_at_match: Array<TriggerSummaryWireFormat>;
  value_now: Array<TriggerSummaryWireFormat>;
  is_equal: boolean;
}

interface MatchSnapshotWireFormat {
  id: number;
  created_at: Date;
  user_attributes_used: Array<UsedAttributesWireFormat>;
  company_attributes_used: Array<UsedAttributesWireFormat>;
  predicates: PredicateDiffWireFormat;
  client_predicates: PredicateDiffWireFormat;
  series_exit_predicates?: PredicateDiffWireFormat;
  triggers: TriggerWireFormat;
  user: {
    at_match: Record<string, any>;
    now: Record<string, any>;
  };
  company: {
    at_match: Record<string, any>;
    now: Record<string, any>;
  };
}

interface MatchingResultWireFormat {
  predicate: Predicate & { predicates?: Array<MatchingResultWireFormat> };
  matched: boolean;
  current_value: any;
}

interface UserCompanyMatchingResultWireFormat {
  company?: CompanySummary;
  matching_results: Array<MatchingResultWireFormat>;
}

interface UserEventSummaryWireFormat {
  first: string;
  last: string;
  count: number;
  name?: string;
}

interface TriggerResultWireFormat {
  trigger_summary: TriggerSummaryWireFormat;
  user_event_summary: UserEventSummaryWireFormat | null;
  metadata_matched?: boolean;
  last_metadata?: any;
}

interface MatchingTimetableResultWireFormat {
  open_at: string;
  close_at: string;
  is_open: boolean;
}

interface WarningWireFormat {
  section: 'trigger';
  text: string;
}
interface MatchCheckWireFormat {
  state: State;
  match_behavior: MatchBehavior;
  is_series_ruleset: boolean;
  series_node_type?: number;
  match_records: Array<MatchRecordWireFormat>;
  match_snapshots: Array<MatchSnapshotWireFormat>;
  current_serialized_user: Record<string, any>;
  current_matching_results: Array<UserCompanyMatchingResultWireFormat>;
  current_matching_locations: Array<{
    ruleset_link_id: number;
    matching_locations: Array<MatchingLocation>;
  }>;
  current_trigger_results: Array<TriggerResultWireFormat> | null;
  current_matching_timetable_results: MatchingTimetableResultWireFormat | null;
  current_client_predicates: Array<Predicate>;
  serialized_checkpoints: Array<CheckpointSummaryWireFormat>;
  serialized_entry_records: Array<EntryRecordSummaryWireFormat>;
  warnings: Array<WarningWireFormat>;
}

class UsedAttribute {
  @service declare attributeService: any;

  key: string;
  valueAtMatch: string;
  valueNow: string;
  isEqual: boolean;

  get changed(): boolean {
    return !this.isEqual;
  }

  get attribute(): any {
    return this.attributeService.attributes.find((attribute: any) => attribute.key === this.key);
  }

  get name(): string {
    return this.attribute?.humanFriendlyName ?? this.key;
  }

  get icon(): InterfaceIconName {
    return this.attribute?.icon || 'transfer';
  }

  constructor(data: UsedAttributesWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.key = data.key;
    this.valueAtMatch = data.value_at_match;
    this.valueNow = data.value_now;
    this.isEqual = data.is_equal;
  }
}

class MatchingResult {
  @service declare store: Store;

  predicate: { type: string; predicates?: Array<MatchingResult> };
  matched: boolean;
  currentValue: any;

  predicateModel?: any;

  constructor(data: MatchingResultWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.predicate = { type: data.predicate.type };
    if (data.predicate.predicates) {
      this.predicate.predicates = data.predicate.predicates.map(
        (predicate) => new MatchingResult(predicate, owner),
      );
    }

    this.matched = data.matched;

    // Here we should actually format the value based on the type of the predicate
    // e.g. formatting dates, or if it's a list of Tags etc.
    if (data.current_value && data.current_value.toString().length < 75) {
      this.currentValue = data.current_value;
    }

    if (!data.predicate.predicates) {
      this.predicateModel = PredicateModel.createPredicateWithGenericType(this.store, {
        attribute: data.predicate.attribute,
        type: data.predicate.type,
        comparison: data.predicate.comparison,
        value: data.predicate.value,
      });
    }
  }
}

class UserCompanyMatchingResult {
  company?: CompanySummary;
  matchingResults: Array<MatchingResult>;
  matched: boolean;

  constructor(data: UserCompanyMatchingResultWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.company = data.company;
    this.matchingResults = data.matching_results.map((result) => new MatchingResult(result, owner));
    this.matched = this.matchingResults.every((result) => result.matched);
  }
}

class CheckpointSummary {
  id: number;
  company?: CompanySummary;
  createdAt: Date;
  status: number;
  matchingLocationType: number;
  expiresAt: Date;
  foregroundProcessableAt: Date;
  backgroundProcessableAt: Date;

  constructor(data: CheckpointSummaryWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.id = data.id;
    this.company = data.company;
    this.createdAt = new Date(data.created_at);
    this.status = data.status;
    this.matchingLocationType = data.matching_location_type;
    this.expiresAt = new Date(data.expires_at);
    this.foregroundProcessableAt = new Date(data.foreground_processable_at);
    this.backgroundProcessableAt = new Date(data.background_processable_at);
  }
}

class EntryRecordSummary {
  id: number;
  lockedCompany?: CompanySummary;
  createdAt: Date;

  constructor(data: EntryRecordSummaryWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.id = data.id;
    this.lockedCompany = data.locked_company;
    this.createdAt = new Date(data.created_at);
  }
}

class MatchRecord {
  id: number;
  company: CompanySummary;
  createdAt: Date;
  lastMatchedAt: Date;
  matchCount: number;

  constructor(data: MatchRecordWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.id = data.id;
    this.company = data.company;
    this.createdAt = new Date(data.created_at);
    this.lastMatchedAt = new Date(data.last_matched_at);
    this.matchCount = data.match_count;
  }
}

class TriggerSummary {
  @service declare store: Store;
  @service declare attributeService: any;

  eventId: number;
  eventType: number;
  eventPredicates: any;

  get name(): string | undefined {
    return this.attributeService.eventAttributes.find((attr: any) => attr.event_id === this.eventId)
      ?.humanFriendlyName;
  }

  constructor(data: TriggerSummaryWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.eventId = data.event_id;
    this.eventType = data.event_type;

    data.event_predicates = data.event_predicates ?? [];

    this.eventPredicates = this.store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(this.store, data.event_predicates),
    });
  }
}

class TriggerDiff {
  isEqual: boolean;
  valueAtMatch: Array<TriggerSummary>;
  valueNow: Array<TriggerSummary>;

  get changed(): boolean {
    return !this.isEqual;
  }

  constructor(data: TriggerWireFormat, owner: unknown) {
    setOwner(this, owner);

    data.value_at_match = data.value_at_match ?? [];
    data.value_now = data.value_now ?? [];

    this.valueAtMatch = data.value_at_match.map(
      (trigger: TriggerSummaryWireFormat) => new TriggerSummary(trigger, owner),
    );
    this.valueNow = data.value_now.map(
      (trigger: TriggerSummaryWireFormat) => new TriggerSummary(trigger, owner),
    );
    this.isEqual = data.is_equal;
  }
}

export class PredicateDiff {
  @service declare store: Store;

  predicateGroupAtMatch: any;
  predicateGroupNow: any;
  isEqual: boolean;

  get changed(): boolean {
    return !this.isEqual;
  }

  constructor(data: PredicateDiffWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.isEqual = data.is_equal;

    this.predicateGroupAtMatch = this.store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(this.store, data.value_at_match),
    });

    this.predicateGroupNow = this.store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(this.store, data.value_now),
    });
  }
}

class MatchingTimetableResult {
  openAt: Date;
  closeAt: Date;
  isOpen: boolean;

  constructor(data: MatchingTimetableResultWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.openAt = new Date(data.open_at);
    this.closeAt = new Date(data.close_at);
    this.isOpen = data.is_open;
  }
}

class UserEventSummary {
  first: Date;
  last: Date;
  count: number;
  name?: string;

  constructor(data: UserEventSummaryWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.first = new Date(data.first);
    this.last = new Date(data.last);
    this.count = data.count;
    this.name = data.name;
  }
}

class TriggerResult {
  @service declare store: Store;

  trigger: TriggerSummary;
  userEventSummary?: UserEventSummary;
  lastMetadata?: any;
  matched?: boolean;

  get predicates(): any {
    return this.trigger.eventPredicates.predicates;
  }

  constructor(data: TriggerResultWireFormat, owner: unknown) {
    setOwner(this, owner);

    this.trigger = new TriggerSummary(data.trigger_summary, owner);

    this.lastMetadata = data.last_metadata;
    this.matched = data.metadata_matched;

    if (data.user_event_summary) {
      this.userEventSummary = new UserEventSummary(data.user_event_summary, owner);
    }
  }
}

class MatchSnapshot {
  id: number;
  createdAt: Date;
  userAttributesUsed: Array<UsedAttribute>;
  companyAttributesUsed: Array<UsedAttribute>;
  predicates: PredicateDiff;
  clientPredicates: PredicateDiff;
  seriesExitPredicates?: PredicateDiff;
  triggers: TriggerDiff;
  user: {
    at_match: Record<string, any>;
    now: Record<string, any>;
  };
  company: {
    at_match: Record<string, any>;
    now: Record<string, any>;
  };

  get allAttributesUsed(): Array<UsedAttribute> {
    return [...this.userAttributesUsed, ...this.companyAttributesUsed];
  }

  constructor(data: MatchSnapshotWireFormat, owner: unknown) {
    setOwner(this, owner);

    data.triggers = data.triggers ?? [];
    data.user_attributes_used = data.user_attributes_used ?? [];
    data.company_attributes_used = data.company_attributes_used ?? [];

    this.id = data.id;
    this.createdAt = data.created_at;
    this.userAttributesUsed = data.user_attributes_used.map(
      (attr) => new UsedAttribute(attr, owner),
    );

    this.companyAttributesUsed = data.company_attributes_used.map(
      (attr) => new UsedAttribute(attr, owner),
    );

    this.predicates = new PredicateDiff(data.predicates, owner);
    this.clientPredicates = new PredicateDiff(data.client_predicates, owner);
    if (data.series_exit_predicates) {
      this.seriesExitPredicates = new PredicateDiff(data.series_exit_predicates, owner);
    }
    this.triggers = new TriggerDiff(data.triggers, owner);
    this.user = data.user;
    this.company = data.company;
  }
}

class MatchCheck {
  @service declare store: Store;
  @service declare appService: any;

  state: State;
  matchBehavior: MatchBehavior;
  isSeriesRuleset: boolean;
  seriesNodeType?: number;
  matchRecords: Array<MatchRecord>;
  matchSnapshots: Array<MatchSnapshot>;
  currentSerializedUser: any;
  currentMatchingResults: Array<UserCompanyMatchingResult>;
  checkpoints: Array<CheckpointSummary>;
  entryRecords: Array<EntryRecordSummary>;
  warnings: Array<WarningWireFormat>;

  triggerResults?: Array<TriggerResult>;
  matchingTimetableResults?: MatchingTimetableResult;
  clientPredicates?: any;

  matchingLocations: Array<{
    description: string;
    icon: InterfaceIconName;
    lastSeenDetails: string;
  }>;

  get isTransient(): boolean {
    return this.matchBehavior === MatchBehavior.Transient;
  }

  get triggerWarnings(): Array<WarningWireFormat> {
    return this.warnings.filter((warning) => warning.section === 'trigger');
  }

  constructor(data: MatchCheckWireFormat, owner: unknown) {
    setOwner(this, owner);
    data.match_records = data.match_records || [];
    data.match_snapshots = data.match_snapshots || [];
    data.serialized_checkpoints = data.serialized_checkpoints || [];
    data.serialized_entry_records = data.serialized_entry_records || [];

    this.state = data.state;

    this.matchBehavior = data.match_behavior;

    this.isSeriesRuleset = data.is_series_ruleset;

    this.matchRecords = data.match_records.map((record) => new MatchRecord(record, owner));

    this.matchSnapshots = data.match_snapshots.map(
      (snapshot) => new MatchSnapshot(snapshot, owner),
    );

    let existingUser = this.store.peekRecord('user', `${data.current_serialized_user.id}`);

    if (existingUser) {
      this.store.unloadRecord(existingUser);
    }

    this.currentSerializedUser = this.store.createRecord('user', {
      ...data.current_serialized_user,
      id: `${data.current_serialized_user.id}`,
    });

    this.currentMatchingResults = data.current_matching_results.map(
      (result) => new UserCompanyMatchingResult(result, owner),
    );

    this.checkpoints = data.serialized_checkpoints.map(
      (checkpoint) => new CheckpointSummary(checkpoint, owner),
    );

    this.entryRecords = data.serialized_entry_records.map(
      (entryRecord) => new EntryRecordSummary(entryRecord, owner),
    );

    if (data.current_trigger_results) {
      this.triggerResults = data.current_trigger_results.map(
        (trigger) => new TriggerResult(trigger, owner),
      );
    }

    if (data.current_matching_timetable_results) {
      this.matchingTimetableResults = new MatchingTimetableResult(
        data.current_matching_timetable_results,
        owner,
      );
    }

    if (data.current_client_predicates) {
      this.clientPredicates = this.store.createFragment('predicates/predicate-group', {
        predicates: PredicateGroup.convertRawPredicates(this.store, data.current_client_predicates),
      }).predicates;
    }

    this.seriesNodeType = data.series_node_type;

    this.warnings = data.warnings;

    this.matchingLocations = [];
    data.current_matching_locations
      .flatMap((locations) => locations.matching_locations)
      .uniq()
      .forEach((location) => {
        if ([MatchingLocation.WEB_PING_BOOT, MatchingLocation.WEB_PING_UPDATE].includes(location)) {
          this.matchingLocations.push({
            description: 'When the user is on your website',
            icon: 'globe',
            lastSeenDetails: `Last seen on the web: ${
              this.currentSerializedUser.last_request_at || 'Never'
            }`,
          });
        }
        if (
          [MatchingLocation.ANDROID_PING].includes(location) &&
          this.appService.app.hasAndroidSdk
        ) {
          this.matchingLocations.push({
            description: 'When the user is on your Android app',
            icon: 'android',
            lastSeenDetails: `Last seen on android: ${
              this.currentSerializedUser.last_android_request_at || 'Never'
            }`,
          });
        }
        if ([MatchingLocation.IOS_PING].includes(location) && this.appService.app.hasIOSSdk) {
          this.matchingLocations.push({
            description: 'When the user is on your iOS app',
            icon: 'apple',
            lastSeenDetails: `Last seen on iOS: ${
              this.currentSerializedUser.last_ios_request_at || 'Never'
            }`,
          });
        }
        if ([MatchingLocation.MATCHING_PIPELINE].includes(location)) {
          this.matchingLocations.push({
            description: 'Periodically in the background',
            icon: 'clock',
            lastSeenDetails: '',
          });
        }
        if ([MatchingLocation.EVENT_TRIGGER].includes(location) && this.triggerResults) {
          this.matchingLocations.push({
            description: 'When the user triggers the relevant event',
            icon: 'trigger',
            lastSeenDetails: '',
          });
        }
        if ([MatchingLocation.TRIGGER_FROM_CODE].includes(location)) {
          this.matchingLocations.push({
            description: 'When the content is triggered manually through our API',
            icon: 'code-block',
            lastSeenDetails: '',
          });
        }
      });
    this.matchingLocations = this.matchingLocations.uniqBy('description');
  }
}

export { MatchCheck, MatchCheckWireFormat, MatchingResult };
