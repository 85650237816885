/* RESPONSIBLE TEAM: team-workflows */
import type SendToMarketo from 'embercom/models/operator/visual-builder/step/send-to-marketo';
import StepConfig, { type StepConfigParams } from '../step';

export default class SendToMarketoConfiguration extends StepConfig {
  declare step: SendToMarketo;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/send-to-marketo',
      ...params,
    });
  }
}
