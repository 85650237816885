/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';
import DateFormatter from 'embercom/vendor/intercom/date-formatter';
import Fragment from 'ember-data-model-fragments/fragment';
import { getApp, getAttributeService } from 'embercom/lib/container-lookup';

export default Fragment.extend({
  name: attr(),
  first: attr(),
  last: attr(),
  count: attr(),
  visible_for_current_admin: attr(),
  description: attr(),
  identifier: computed('name', function () {
    return `user_event_summaries.${this.name}`;
  }),
  isDescriptionModifiable: true,
  isUserEvent: true,
  icon: 'event',
  settingsPath: 'apps.app.settings.events',
  humanFriendlyName: computed('name', function () {
    return this.name
      .toLowerCase()
      .replace(/(\-|\_)/g, ' ')
      .replace(/^(\w)/, (match, firstCharacter) => firstCharacter.toUpperCase());
  }),
  formattedFirstDate: computed('first', function () {
    return DateFormatter.fromNow(this.first);
  }),
  formattedLastDate: computed('last', function () {
    return DateFormatter.fromNow(this.last);
  }),
  expanded: false,
  app: computed(function () {
    return getApp();
  }),
  attributeService: computed(function () {
    return getAttributeService();
  }),
  attribute: findByProperty('attributeService.attributes', 'identifier', 'identifier'),
});
