/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import { readOnly, notEmpty } from '@ember/object/computed';

export default EmberObject.extend({
  type: 'user',
  value: null,
  user: null,
  userHasName: notEmpty('user.name'),
  suggestionText: readOnly('user.nameOrEmail'),
  suggestionSubtext: computed('userHasName', 'user.email', function () {
    if (this.userHasName) {
      return this.user.email;
    }
  }),
  suggestionAvatar: readOnly('user'),
  filterAvatar: readOnly('user'),
  filterText: computed('user.{nameOrEmail,displayAs}', function () {
    return this.user.nameOrEmail ? this.user.nameOrEmail : this.user.displayAs;
  }),
}).reopenClass({
  type: 'user',
  dropdownIcon: 'user',
});
