/* RESPONSIBLE TEAM: team-workflows */
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import type Store from '@ember-data/store';
import { ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  ticketTypeId: [validator('presence', true)],
});

export default class SendTicket extends Step.extend(Validations) {
  @attr('number') declare ticketTypeId?: number;
  @attr('boolean') declare disableComposer?: boolean;
  supportedChannels = ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL;

  static get supportedContexts() {
    return [TargetContext.Conversation];
  }

  static get requiredBillingFeature() {
    return 'inbox';
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  static createNewStep(store: Store): SendTicket {
    return store.createRecord('operator/visual-builder/step/send-ticket', {
      type: 'operator/visual-builder/step/send-ticket',
      justAdded: true,
    });
  }
}
