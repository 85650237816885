/* RESPONSIBLE TEAM: team-proactive-support */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/ai-content-segment/edit-mode-generator';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/ai-content-segment/view-mode-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import {
  objectTypes,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';

export default function generateEditorConfig(options) {
  let notificationMessages = generateNotificationMessages(options);
  let intl = options.container.lookup('service:intl');

  notificationMessages.save = NotificationMessage.create({
    success: intl.t('ai-content-library.segments.notifications.save.success', {
      objectName: humanReadableObjectNames[objectTypes.aiContentSegment],
    }),
    failure: intl.t('ai-content-library.segments.notifications.save.failure', {
      objectName: humanReadableObjectNames[objectTypes.aiContentSegment],
    }),
    container: options.container,
  });

  notificationMessages.delete = NotificationMessage.create({
    success: intl.t('ai-content-library.segments.notifications.delete.success', {
      objectName: humanReadableObjectNames[objectTypes.aiContentSegment],
    }),
    failure: intl.t('ai-content-library.segments.notifications.delete.failure', {
      objectName: humanReadableObjectNames[objectTypes.aiContentSegment],
    }),
    confirmation() {
      let title = intl.t('ai-content-library.segments.notifications.delete.confirmation.title', {
        objectName: humanReadableObjectNames[objectTypes.aiContentSegment],
      });
      let body = intl.t('ai-content-library.segments.notifications.delete.confirmation.body', {
        objectName: humanReadableObjectNames[objectTypes.aiContentSegment],
      });
      let confirmButtonText = intl.t(
        'ai-content-library.segments.notifications.delete.confirmation.button',
      );
      return { title, body, confirmButtonText };
    },
    container: options.container,
  });

  notificationMessages.activate;

  options.objectType = objectTypes.aiContentSegment;
  return EditorConfig.create({
    editMode: generateEditModeConfig(options),
    viewMode: generateViewModeConfig(options),
    notificationMessages,
  });
}
