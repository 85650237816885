/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';

export default Fragment.extend({
  marketoHostName: attr('string'),
  marketoAppHostName: attr('string'),
  marketoClientId: attr('string'),
  marketoClientSecret: attr('string'),
  leadEnrichmentEnabled: attr('boolean'),
  sendConversationActivityMetadata: attr('boolean'),
  autoCreateLeads: attr('boolean'),
  errorCode: attr('string'),
  // Commit hash of the current text used to describe cookie scraping enrichment
  // IMPORTANT: Please update this value if the description text in appstore/app-package/settings/marketo-enrichment/main.hbs changes
  leadEnrichmentVersion: attr('string', {
    defaultValue: 'a5d3c9fe26b725ae42da8d8846c9834631819b5b',
  }),
  attributeMappings: fragmentArray('crm/attribute-mapping'),
  attributeOptionsForType: fragment('crm/attribute-options'),
});
