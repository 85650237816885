/* RESPONSIBLE TEAM: team-messenger */

import Model, { attr } from '@ember-data/model';

export default class PrivacyPolicyNoticeContent extends Model {
  @attr('string') localeId?: string;
  @attr('string') notice?: string;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }
}
