/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { assert } from '@ember/debug';

export default EmberObject.extend({
  component: undefined,

  init(inputs) {
    this._super(...arguments);
    assert('A path must be provided for the component configuration', inputs.component);
    assert(
      `The component path must be within the content-editor or series directory. You passed '${inputs.component}'`,
      inputs.component.startsWith('content-editor/') || inputs.component.startsWith('series/'),
    );
    this.setProperties(inputs);
  },
});
