/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { notEmpty } from '@ember/object/computed';
import { computed } from '@ember/object';
import ReportsLabels from 'embercom/lib/integrations/slack-reports-labels';
import { findBy } from '@intercom/pulse/lib/computed-properties';

export default Model.extend({
  url: attr(),
  topics: hasMany('webhook-topic'),
  reports: attr(),
  reportsLabels: computed('reports', function () {
    if (this.reports === undefined) {
      return [];
    } else {
      return Object.keys(this.reports).map((report) => ({
        id: report,
        label: ReportsLabels[report],
      }));
    }
  }),

  _topicToNotificationTypes: {
    'conversation.user.created': 'conversation.new',
    'conversation.user.replied': 'conversation.all',
    'conversation.admin.opened': 'conversation.all',
    'conversation.admin.closed': 'conversation.all',
    'conversation.admin.replied': 'conversation.all',
    'conversation.admin.noted': 'conversation.all',
    'conversation.admin.assigned': 'conversation.all',
    'user.created': 'user.created',
    'company.created': 'company.created',
    'contact.signed_up': 'lead.signed_up',
  },

  notificationTypes: computed('topics', function () {
    let resultSet = [];
    this.topics.forEach((topic) => {
      let notificationTypeId = this._topicToNotificationTypes[topic.id];
      if (notificationTypeId) {
        let notificationType = this.store.peekRecord('slack-notification-type', notificationTypeId);
        if (notificationType) {
          resultSet.push(notificationType);
        }
      }
    });
    return resultSet;
  }),

  newConversationNotificationType: findBy('notificationTypes', 'id', 'conversation.new'),
  allConversationNotificationType: findBy('notificationTypes', 'id', 'conversation.all'),
  conversationNotificationType: computed(
    'newConversationNotificationType',
    'allConversationNotificationType',
    function () {
      let newConversationNotificationType = this.newConversationNotificationType;
      let allConversationNotificationType = this.allConversationNotificationType;

      if (allConversationNotificationType) {
        return allConversationNotificationType;
      } else if (newConversationNotificationType) {
        return newConversationNotificationType;
      }
      return null;
    },
  ),
  hasConversationNotificationType: notEmpty('conversationNotificationType'),
});
