/* RESPONSIBLE TEAM: team-customer-data-platform */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';

export default class IdentityMapping extends Fragment {
  @attr('string') declare _id: string;
  @attr('string') declare crmObjectId: string;
  @attr('string') declare crmObjectType: string;
  @attr('string') declare intercomObjectId: string;
  @attr('string') declare intercomObjectType: string;

  @attr('string') declare status: string; // not stored in the server
}

export type IdentityMappingFragmentInput = {
  status: 'skip' | 'done' | 'pending';
  crmObjectId: string;
  crmObjectType: string;
  intercomObjectId: string | undefined;
  intercomObjectType: string | undefined;
};
