/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import TabConfiguration from '../tab-configuration';
import { inject as service } from '@ember/service';
import { not, readOnly } from '@ember/object/computed';

export default TabConfiguration.extend({
  contentEditorService: service(),

  isNotDraft: not('contentEditorService.ruleset.isDraft'),
  isVisible: readOnly('isNotDraft'),

  label: 'Step',
  sublabel: 'Analysis',
  view: undefined,

  init(inputs) {
    inputs.component = 'content-editor/tabs/checklist/step-analysis-tab';
    inputs.view = 'stepAnalysis';

    this._super(...arguments);
    this.setProperties({
      view: inputs.view,
    });
  },
});
