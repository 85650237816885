/* RESPONSIBLE TEAM: team-tickets-1 */
export interface GithubIssueSummaryConstructorFormat {
  id: string;
  title: string | null;
  rawLink: string;
  issueId: string;
}

export interface GithubIssueSummaryWireFormat {
  id: string;
  title: string | null;
  raw_link: string;
  issue_id: string;
}

export default class GithubIssueSummary {
  readonly id: string;
  readonly title: string | null;
  readonly rawLink: string;
  readonly issueId: string;

  constructor(inputs: GithubIssueSummaryConstructorFormat) {
    let { id, title, rawLink, issueId } = inputs;
    this.id = id;
    this.title = title;
    this.rawLink = rawLink;
    this.issueId = issueId;
  }

  static deserialize(json: GithubIssueSummaryWireFormat) {
    return new GithubIssueSummary({
      id: json.id,
      title: json.title,
      rawLink: json.raw_link,
      issueId: json.issue_id,
    });
  }

  get name(): string {
    let fullIssueTitle = `${this.issueId}`;

    if (this.title) {
      fullIssueTitle += `: ${this.title}`;
    }

    return fullIssueTitle;
  }
}
