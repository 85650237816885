/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { not, and } from '@ember/object/computed';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';
import ReportContentTabConfiguration from 'embercom/objects/content-editor/tabs/report-content-tab-configuration';
import {
  StandardGoalSummaryConfiguration,
  StandardScheduleSummaryConfiguration,
} from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import SurveySharingSummaryConfiguration from 'embercom/objects/content-editor/summaries/custom/survey-sharing/summary-configuration';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/survey/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import SurveyAudienceSummaryConfiguration from 'embercom/objects/content-editor/summaries/audience/content/survey/audience-summary-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';

export default function generateViewModeConfig(options) {
  let container = options.container;
  let permissionsService = container.lookup('service:permissionsService');
  let intl = container.lookup('service:intl');

  let surveyReportPermission = permissionsService.currentAdminCan(
    'can_outbound__surveys_data__manage',
  );
  let reportPermission =
    permissionsService.currentAdminCan('can_access_reporting') && surveyReportPermission;

  let tabComponentConfigs = [
    StandardContentTabConfiguration.create({
      label: 'Survey',
      icon: 'inapp-post',
      view: 'content',
    }),
    StandardStatTabConfiguration.create({
      label: intl.t('outbound.stats-system.tabs.sent'),
      view: 'receipts',
      container,
    }),
    ...(surveyReportPermission
      ? [
          StandardStatTabConfiguration.create({
            label: 'Responded',
            view: 'answers',
            container,
          }),
        ]
      : []),
    StandardStatTabConfiguration.create({
      label: intl.t('outbound.stats-system.tabs.clicked'),
      view: 'clicks',
      container,
    }),
    ...(reportPermission
      ? [
          ReportContentTabConfiguration.create({
            label: intl.t('outbound.stats-system.tabs.responses'),
            container,
            view: 'reports',
          }),
        ]
      : []),
    StandardStatTabConfiguration.extend({
      _isNotDraft: not('contentEditorService.ruleset.isDraft'),
      isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
    }).create({
      label: intl.t('outbound.stats-system.tabs.goal'),
      view: 'goals',
      container,
    }),
  ];

  return ViewModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesViewModeHeaderConfiguration.create({
      moreDropdownConfig: MoreDropdownConfiguration.create({
        hideDuplicateOption: true,
        hideCsvExportOption: false,
        hideTagOption: true,
        hideDeleteOption: true,
        hideVersionsOption: true,
        container,
      }),
      container,
    }),
    headerComponentConfigs: [
      StandardViewModeHeaderConfiguration.create({
        container,
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/surveys/preview-button',
        }),
      }),
    ],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs,
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        ContentSummaryConfiguration.create({ container }),
        SurveyAudienceSummaryConfiguration.create({ container }),
        StandardScheduleSummaryConfiguration.create({
          title: 'Frequency and scheduling',
          container,
        }),
        SurveySharingSummaryConfiguration.create({ container }),
        StandardGoalSummaryConfiguration.create({ container }),
      ],
      reports: [
        StandardSummaryConfiguration.create({
          container,
          title: intl.t('content-editor.tabs.content-title'),
          component: 'content-editor/summaries/content/survey/report-tab-component',
          isVisible: true,
        }),
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: false,
          noun: 'send',
          verb: 'received',
          emptyStateTitle: 'No one has received your survey yet',
          emptyStateDescription: ' ', // We need a non-empty value as override in order to hide description
          container,
        }),
      ],
      answers: [
        StandardStatListConfiguration.create({
          stat: 'answer',
          component: 'content-editor/view-mode/survey/answer-stat-list',
          container,
        }),
      ],
      completions: [
        StandardStatListConfiguration.create({
          stat: 'completion',
          component: 'content-editor/view-mode/survey/completion-stat-list',
          container,
        }),
      ],
      clicks: [
        StandardStatListConfiguration.create({
          stat: 'click',
          container,
        }),
      ],
      goals: [
        StandardStatListConfiguration.create({
          stat: 'goalSuccess',
          container,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });
}
