/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
let States = {};

States.US = [
  {
    text: 'Alabama', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.alabama',
    value: 'AL',
  },
  {
    text: 'Alaska', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.alaska',
    value: 'AK',
  },
  {
    text: 'Arizona', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.arizona',
    value: 'AZ',
  },
  {
    text: 'Arkansas', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.arkansas',
    value: 'AR',
  },
  {
    text: 'California', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.california',
    value: 'CA',
  },
  {
    text: 'Colorado', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.colorado',
    value: 'CO',
  },
  {
    text: 'Connecticut', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.connecticut',
    value: 'CT',
  },
  {
    text: 'Delaware', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.delaware',
    value: 'DE',
  },
  {
    text: 'District of Columbia', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.district_of_columbia',
    value: 'DC',
  },
  {
    text: 'Florida', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.florida',
    value: 'FL',
  },
  {
    text: 'Georgia', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.georgia',
    value: 'GA',
  },
  {
    text: 'Hawaii', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.hawaii',
    value: 'HI',
  },
  {
    text: 'Idaho', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.idaho',
    value: 'ID',
  },
  {
    text: 'Illinois', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.illinois',
    value: 'IL',
  },
  {
    text: 'Indiana', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.indiana',
    value: 'IN',
  },
  {
    text: 'Iowa', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.iowa',
    value: 'IA',
  },
  {
    text: 'Kansas', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.kansas',
    value: 'KS',
  },
  {
    text: 'Kentucky', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.kentucky',
    value: 'KY',
  },
  {
    text: 'Louisiana', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.louisiana',
    value: 'LA',
  },
  {
    text: 'Maine', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.maine',
    value: 'ME',
  },
  {
    text: 'Maryland', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.maryland',
    value: 'MD',
  },
  {
    text: 'Massachusetts', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.massachusetts',
    value: 'MA',
  },
  {
    text: 'Michigan', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.michigan',
    value: 'MI',
  },
  {
    text: 'Minnesota', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.minnesota',
    value: 'MN',
  },
  {
    text: 'Mississippi', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.mississippi',
    value: 'MS',
  },
  {
    text: 'Missouri', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.missouri',
    value: 'MO',
  },
  {
    text: 'Montana', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.montana',
    value: 'MT',
  },
  {
    text: 'Nebraska', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.nebraska',
    value: 'NE',
  },
  {
    text: 'Nevada', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.nevada',
    value: 'NV',
  },
  {
    text: 'New Hampshire', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.new_hampshire',
    value: 'NH',
  },
  {
    text: 'New Jersey', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.new_jersey',
    value: 'NJ',
  },
  {
    text: 'New Mexico', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.new_mexico',
    value: 'NM',
  },
  {
    text: 'New York', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.new_york',
    value: 'NY',
  },
  {
    text: 'North Carolina', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.north_carolina',
    value: 'NC',
  },
  {
    text: 'North Dakota', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.north_dakota',
    value: 'ND',
  },
  {
    text: 'Ohio', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.ohio',
    value: 'OH',
  },
  {
    text: 'Oklahoma', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.oklahoma',
    value: 'OK',
  },
  {
    text: 'Oregon', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.oregon',
    value: 'OR',
  },
  {
    text: 'Pennsylvania', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.pennsylvania',
    value: 'PA',
  },
  {
    text: 'Rhode Island', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.rhode_island',
    value: 'RI',
  },
  {
    text: 'South Carolina', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.south_carolina',
    value: 'SC',
  },
  {
    text: 'South Dakota', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.south_dakota',
    value: 'SD',
  },
  {
    text: 'Tennessee', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.tennessee',
    value: 'TN',
  },
  {
    text: 'Texas', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.texas',
    value: 'TX',
  },
  {
    text: 'Utah', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.utah',
    value: 'UT',
  },
  {
    text: 'Vermont', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.vermont',
    value: 'VT',
  },
  {
    text: 'Virginia', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.virginia',
    value: 'VA',
  },
  {
    text: 'Washington', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.washington',
    value: 'WA',
  },
  {
    text: 'West Virginia', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.west_virginia',
    value: 'WV',
  },
  {
    text: 'Wisconsin', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.wisconsin',
    value: 'WI',
  },
  {
    text: 'Wyoming', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.wyoming',
    value: 'WY',
  },
];

States.CA = [
  {
    text: 'Alberta', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.alberta',
    value: 'AB',
  },
  {
    text: 'British Columbia', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.british_columbia',
    value: 'BC',
  },
  {
    text: 'Manitoba', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.manitoba',
    value: 'MB',
  },
  {
    text: 'New Brunswick', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.new_brunswick',
    value: 'NB',
  },
  {
    text: 'Newfoundland and Labrador', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.newfoundland_and_labrador',
    value: 'NL',
  },
  {
    text: 'Nova Scotia', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.nova_scotia',
    value: 'NS',
  },
  {
    text: 'Northwest Territories', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.northwest_territories',
    value: 'NT',
  },
  {
    text: 'Nunavut', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.nunavut',
    value: 'NU',
  },
  {
    text: 'Ontario', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.ontario',
    value: 'ON',
  },
  {
    text: 'Prince Edward Island', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.prince_edward_island',
    value: 'PE',
  },
  {
    text: 'Quebec', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.quebec',
    value: 'QC',
  },
  {
    text: 'Saskatchewan', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.saskatchewan',
    value: 'SK',
  },
  {
    text: 'Yukon', // deprecated bare string in favour of below TranslationKey field
    textTranslationKey: 'app.lib.states.yukon',
    value: 'YT',
  },
];

export default States;
