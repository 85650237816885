/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';
import { inject as service } from '@ember/service';
import { readOnly, empty } from '@ember/object/computed';

export default StandardPanelHeaderConfiguration.extend({
  contentEditorService: service(),

  errors: readOnly('contentEditorService.ruleset.validations.attrs.rulesetLinks.errors'),
  isComplete: empty('errors'),

  init(inputs) {
    inputs.component = 'content-editor/panels/content/news-item/header';
    inputs.title = inputs.title || 'Content';
    this._super(...arguments);
  },
});
