/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import GraphConfiguration from 'embercom/objects/series/node/graph-configuration';
import StandardNodeComponentConfiguration from 'embercom/objects/series/node/standard-node-component-configuration';
import StandardConnectorConfiguration from 'embercom/objects/series/node/standard-connector-configuration';
import StatsConfiguration from 'embercom/objects/series/node/stats-configuration';
import {
  objectTypes,
  seriesEdgeTypes,
  seriesWaitTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

export default class WaitGraphConfiguration extends GraphConfiguration {
  objectType = objectTypes['wait'];

  constructor(seriesNode) {
    super(...arguments);
    this.componentConfig = new StandardNodeComponentConfiguration({
      icon: 'series/content/wait',
      objectType: this.objectType,
      statsSummaryConfigurationComponent: new StatsConfiguration({
        verb: 'waited',
        component: 'series/node/standard-stats-summary',
      }),
    });
  }

  get connectors() {
    return [
      new StandardConnectorConfiguration({
        label: 'WHEN PASSED',
        edgeType: seriesEdgeTypes.primary,
      }),
    ];
  }

  get inferredTitle() {
    let previewData = this.node.contentPreviews.firstObject?.contentPreviewData;
    let timeDelay = previewData?.time_delay;
    let waitType = previewData?.wait_type;

    if (waitType === seriesWaitTypes.relative) {
      return `Wait for ${this._formatRelativeTimeDelay(timeDelay)}`;
    } else if (waitType === seriesWaitTypes.absolute) {
      return `Wait until ${this._formatAbsoluteTimeDelay(timeDelay, previewData?.timezone)}`;
    } else {
      return 'Wait';
    }
  }

  get defaultTitle() {
    return this.inferredTitle;
  }

  _formatRelativeTimeDelay(timeDelay) {
    let delayDuration = moment.duration(timeDelay, 'seconds');
    return delayDuration.format('d [days], h [hours], m [minutes]', { trim: 'all', minValue: 1 });
  }

  _formatAbsoluteTimeDelay(timeDelay, timezone) {
    let timeDelayAsMoment = moment.unix(timeDelay);
    if (timezone) {
      timeDelayAsMoment = timeDelayAsMoment.tz(timezone);
    }
    return timeDelayAsMoment.format('MMM D, YYYY, HH:mm');
  }

  get primaryPredecessorLabel() {
    return `Completed '${this.title}'`;
  }

  get checkpointStats() {
    return [
      { label: 'finished waiting', value: this.node.completedCheckpointCount },
      { label: 'still waiting', value: this.node.activeCheckpointCount },
      { label: 'left path', value: this.node.exitedCheckpointCount },
    ];
  }
}
