/* RESPONSIBLE TEAM: team-frontend-tech */
const generateUUID = function () {
  let d = new Date().getTime();
  /* eslint-disable no-bitwise */
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-mixed-operators
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  /* eslint-enable no-bitwise */
  return uuid;
};
export default generateUUID;
