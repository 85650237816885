/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { notEmpty, empty, and } from '@ember/object/computed';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import avatarUtils from '@intercom/pulse/lib/avatar-utils';
import Admin from 'embercom/models/admin';

export default Model.extend({
  app_id: attr(),
  name: attr(),
  email: attr(),
  conversation_id: attr(),
  blockedAt: attr(),
  blockedByAutomation: attr(),

  blocked_by_id: attr(),
  blockedBy: computed('blocked_by_id', function () {
    return Admin.peekAndMaybeLoad(this.store, this.blocked_by_id);
  }),

  deletedAvatar: attr(),
  nameOrEmail: ternaryToProperty('hasEmailOnly', 'email', 'name'),
  hasEmail: notEmpty('email'),
  hasNoName: empty('name'),
  hasEmailOnly: and('hasNoName', 'hasEmail'),

  avatarData: computed('deletedAvatar.{color,image_urls.normal,initials}', function () {
    let data = this.getProperties(
      'deletedAvatar.color',
      'deletedAvatar.image_urls.normal',
      'deletedAvatar.initials',
    );
    return {
      color: data['deletedAvatar.color'],
      url: data['deletedAvatar.image_urls.normal'],
      showAsActive: false,
      showAsAway: false,
      initials: avatarUtils.formatInitials(data['deletedAvatar.initials']),
      isCustomer: true,
      isTeammate: false,
      isCompany: false,
      deleted: false,
      shouldAnimateUpdate: false,
    };
  }),
});
