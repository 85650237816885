/* RESPONSIBLE TEAM: team-help-desk-experience */

import { isEmpty } from '@ember/utils';

export interface SidebarSectionConstructorFormat {
  id: number | null;
  iconUrl?: string;
  inboxAppId?: number;
  intercomModuleIdentifier?: string;
  cardIdentifier?: string;
  name: string;
}

export interface SidebarSectionWireFormat {
  id: number | null;
  icon_url?: string;
  inbox_app_id?: number;
  intercom_module_identifier?: string;
  card_identifier?: string;
  name: string;
}

function isSameSection(a?: SidebarSectionConstructorFormat, b?: SidebarSectionConstructorFormat) {
  if (!a || !b) {
    return false;
  }

  if (a.id && b.id) {
    return a.id === b.id;
  }

  if (a.intercomModuleIdentifier && b.intercomModuleIdentifier) {
    return a.intercomModuleIdentifier === b.intercomModuleIdentifier;
  }

  if (a.inboxAppId && b.inboxAppId) {
    return a.inboxAppId === b.inboxAppId;
  }

  return false;
}

const USER_MODULES = [
  'series',
  'events',
  'external-profiles',
  'latest-conversations',
  'notes',
  'qualification',
  'segments',
  'tags',
  'user-details',
  'recent-events',
  'recent-page-views',
  'subscription-types',
  'domain-object',
];

const COMPANY_MODULES = ['company-details', 'company-notes', 'company-tags', 'company-segments'];

const CONVERSATION_MODULES = ['similar-conversations', 'conversation-details'];

const TICKET_MODULES = ['linked-tickets'];

const TRACKER_TICKET_MODULES = ['inbox_app_jira-for-tickets', 'linked-github-issues'];

export default class SidebarSection {
  readonly id: number | null;
  readonly iconUrl?: string;
  readonly inboxAppId?: number;
  readonly intercomModuleIdentifier?: string;
  readonly cardIdentifier?: string;
  readonly name: string;

  constructor(inputs: SidebarSectionConstructorFormat) {
    let { id, iconUrl, inboxAppId, intercomModuleIdentifier, cardIdentifier, name } = inputs;
    this.id = id;
    this.iconUrl = iconUrl;
    this.inboxAppId = inboxAppId;
    this.intercomModuleIdentifier = intercomModuleIdentifier;
    this.cardIdentifier = cardIdentifier;
    this.name = name;
  }

  static deserialize(json: SidebarSectionWireFormat) {
    return new SidebarSection({
      id: json.id,
      iconUrl: json.icon_url,
      inboxAppId: json.inbox_app_id,
      intercomModuleIdentifier: json.intercom_module_identifier,
      cardIdentifier: json.card_identifier,
      name: json.name,
    });
  }

  serialize() {
    return {
      id: this.id,
      icon_url: this.iconUrl,
      inbox_app_id: this.inboxAppId,
      intercom_module_identifier: this.intercomModuleIdentifier,
      card_identifier: this.cardIdentifier,
      name: this.name,
    };
  }

  isSameSection(section?: SidebarSection) {
    return isSameSection(section, this);
  }

  get componentIdentifier() {
    return this.intercomModuleIdentifier || 'app-card';
  }

  get isApp() {
    return !isEmpty(this.inboxAppId);
  }

  get isUserModule() {
    return this.intercomModuleIdentifier && USER_MODULES.includes(this.intercomModuleIdentifier);
  }

  get isTrackerTicketModule() {
    return (
      TRACKER_TICKET_MODULES.includes(this.cardIdentifier || '') ||
      TRACKER_TICKET_MODULES.includes(this.intercomModuleIdentifier || '')
    );
  }

  get isCompanyModule() {
    return this.intercomModuleIdentifier && COMPANY_MODULES.includes(this.intercomModuleIdentifier);
  }

  get isGithubModule() {
    return (
      this.intercomModuleIdentifier && 'linked-github-issues' === this.intercomModuleIdentifier
    );
  }

  get isConversationModule() {
    return (
      this.intercomModuleIdentifier && CONVERSATION_MODULES.includes(this.intercomModuleIdentifier)
    );
  }

  get isTicketModule() {
    return this.intercomModuleIdentifier && TICKET_MODULES.includes(this.intercomModuleIdentifier);
  }

  get isChecklistModule() {
    return this.intercomModuleIdentifier === 'in-app-checklists';
  }

  get icon() {
    if (this.isApp) {
      return 'app-store';
    }

    if (this.isUserModule) {
      return 'person';
    }

    if (this.isCompanyModule) {
      return 'company';
    }

    if (this.isConversationModule) {
      return 'chat-bubble';
    }

    if (this.isTicketModule) {
      return 'ticket';
    }

    if (this.isGithubModule) {
      return 'github';
    }

    if (this.isChecklistModule) {
      return 'checklist';
    }

    return 'intercom';
  }
}
