/* RESPONSIBLE TEAM: team-workflows */
import { assert } from '@ember/debug';
import type Registry from '@glint/environment-ember-loose/registry';

type ComponentConfigurationParams = {
  component: keyof Registry;
};

export default class ComponentConfiguration {
  component: keyof Registry | undefined;

  constructor(params?: ComponentConfigurationParams) {
    this.component = params?.component;

    assert('You must specify the path to the component file', this.component);
  }
}
