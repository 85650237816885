/* RESPONSIBLE TEAM: team-proactive-support */
// the equivalent of app/lib/matching_system/commands/series/validations/incoming_edges_loop_validation.rb
// for the frontend

export default class LoopDetector {
  visitedNodes = [];
  currentlyVisitingNodes = [];

  constructor(edges) {
    this.edges = edges;
    this.nodes = this.getUniqueNodes(edges);
  }

  getUniqueNodes(edges) {
    // get successors
    let successorNodes = edges.map((edge) => edge.successor);
    // get predecessors
    let predecessorNodes = edges.map((edge) => edge.predecessor);
    // intersect the two arrays
    return successorNodes.filter((node) => predecessorNodes.includes(node)).uniq();
  }

  detectCycleFromNode(node) {
    this.visitedNodes.push(node);
    this.currentlyVisitingNodes.push(node);

    let successors = this.getNodeSuccessors(node);

    let result = successors.some((successor) => {
      // We recursively explore all the successor nodes to the current one
      // If we find a node that we already explored, we found a loop
      if (!this.visitedNodes.includes(successor)) {
        if (this.detectCycleFromNode(successor)) {
          return true;
        }
      } else if (this.currentlyVisitingNodes.includes(successor)) {
        return true;
      }
    });
    this.currentlyVisitingNodes.removeObject(node);
    return result;
  }

  getNodeSuccessors(node) {
    let successorEdges = this.edges.filter((edge) => edge.predecessor === node);
    return successorEdges.map((edge) => edge.successor);
  }

  detectLoop() {
    return this.nodes.some((node) => {
      if (!this.visitedNodes.includes(node)) {
        if (this.detectCycleFromNode(node)) {
          return true;
        }
      }
    });
  }
}
