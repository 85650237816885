/* RESPONSIBLE TEAM: team-workflows */
import {
  OUTBOUND_BOT_TYPE,
  OUTBOUND_TARGET,
  AT_CONVERSATION_START,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import OutboundBotEditorConfig from 'embercom/objects/visual-builder/configuration/editor/outbound-bot';

import CustomBotConfig from '../custom-bot';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class OutboundCustomBotConfig extends CustomBotConfig {
  get target(): BotConfigTarget {
    return OUTBOUND_TARGET;
  }

  get botType() {
    return OUTBOUND_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.customBot;
  }

  get matchBehavior() {
    return matchBehaviors.single;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.outbound.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.outbound.description';
  }

  get icon(): InterfaceIconName {
    return 'outbound-filled';
  }

  get EditorConfigClass() {
    return OutboundBotEditorConfig;
  }

  get requiredBillingFeature() {
    return 'workflows_outbound_billing_feature';
  }

  get triggerContextType() {
    return AT_CONVERSATION_START;
  }
}
