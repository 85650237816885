/* RESPONSIBLE TEAM: team-workflows */
import {
  TRIGGERABLE_BOT_TYPE,
  USER_INACTIVITY_TARGET,
  USER_INACTIVITY_TRIGGER,
  DURING_CONVERSATION,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import UserInactivityEditorConfig from 'embercom/objects/visual-builder/configuration/editor/user-inactivity-target';
import CustomBotConfig, { type ColumnConfigs } from '../custom-bot';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class UserInactivityCustomBotConfig extends CustomBotConfig {
  constructor(public customBot?: any) {
    super();
  }

  get target(): BotConfigTarget {
    return USER_INACTIVITY_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return USER_INACTIVITY_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.user-inactivity.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.user-inactivity.description';
  }

  get icon(): InterfaceIconName {
    return 'snooze';
  }

  get editorConfig() {
    return new this.EditorConfigClass({
      targetChannels: this.customBot?.targetChannels,
      areStepPaywallsActive: this.areStepPaywallsActive,
    });
  }

  get EditorConfigClass() {
    return UserInactivityEditorConfig;
  }

  get columnKeys(): (keyof ColumnConfigs)[] {
    let baseColumns: (keyof ColumnConfigs)[] = super.columnKeys;

    return [...baseColumns.slice(0, 2), 'channels', ...baseColumns.slice(2)];
  }

  get requiredBillingFeature() {
    return 'inbox_rules';
  }

  get triggerContextType() {
    return DURING_CONVERSATION;
  }
}
