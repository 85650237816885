/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, attr, hasMany } from '@ember-data/model';
import { notEmpty, equal, reads, and, gt, not, readOnly, or } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import MessageVariationValidations from 'embercom/validations/message-variation';
import {
  applyFunction,
  equalToProperty,
  objectAtProperty,
  ternary,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import StateProperties from 'embercom/models/mixins/common/states/state-properties';
import VariationUIConcern from 'embercom/models/mixins/messages/variation-ui-concern';
import generatePreviewBlocks from 'embercom/lib/blocks/generate-preview-blocks';
import nonconcurrentAjax from 'embercom/lib/nonconcurrent-ajax';
import MESSAGE_CONSTANTS from 'embercom/models/data/messages/message-constants';
import composerPresetForOptions from 'embercom/models/data/messages/composer-presets';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';
import Admin from 'embercom/models/admin';

export default Model.extend(MessageVariationValidations, StateProperties, VariationUIConcern, {
  appService: service(),
  app: readOnly('appService.app'),

  message: belongsTo('message'),
  state: attr('string'),

  isVariationA: equalToProperty('message.variationA.id', 'id'),
  label: ternary('isVariationA', 'A', 'B'),
  isControlGroup: false,
  nameOfMessageRelationship: computed('label', function () {
    return `variation${this.label}`;
  }),

  analyticsData: computed('messageStyle', function () {
    return {
      id: this.id,
      object: 'message-variation',
      message_style: this.messageStyle,
      assignment_strategy: this.assignmentStrategy,
      links_count: this.get('clickTrackingLinks.length'),
    };
  }),

  assignToId: attr(),
  assignTo: computed('assignToId', function () {
    return Admin.peekAndMaybeLoad(this.store, this.assignToId);
  }),
  fromId: attr(),
  from: computed('fromId', function () {
    if (this.fromId) {
      return Admin.peekAndMaybeLoad(this.store, this.fromId);
    }
  }),

  emailAddressForApp: readOnly('from.email_address_for_app'),
  templatedEmailAddressForApp: computed('emailAddressForApp', function () {
    return `<${this.emailAddressForApp}>`;
  }),

  dynamicSender: attr(),
  dynamicAssignee: attr(),

  pushOnlyURIs: fragment('messages/push-only-uri'),

  customFromAddress: belongsTo('custom-email-address', { async: true }),
  hasCustomFromAddress: notEmpty('customFromAddress.content'),
  customReplyToAddress: belongsTo('custom-email-address', { async: true }),
  hasCustomReplyToAddress: notEmpty('customReplyToAddress.content'),

  fromDisplayAs: ternaryToProperty(
    'hasCustomFromAddress',
    'customFromAddress.displayAs',
    'from.localized_sender_name',
  ),

  wentLiveAt: attr('date'),
  stoppedAt: attr('date'),
  createdAt: attr('date'),
  updatedAt: attr('date'),

  blocks: fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' }),
  json_blocks: [],
  clickTrackingLinks: hasMany('message-variation/click-tracking-link'),

  resetLegacyJsonBlocks() {
    // Legacy conversion to allow us to work with old components. Should be killed when Beckett is fully released.
    this.set(
      'json_blocks',
      this.blocks.map((block) => block.serialize()),
    );
  },

  didUpdate() {
    this._super(...arguments);
    this.resetLegacyJsonBlocks();
  },

  didLoad() {
    this._super(...arguments);
    this.resetLegacyJsonBlocks();
  },

  messageStyle: attr('number'),
  deliveryOption: attr('string'),
  subject: attr('string'),
  replyType: attr('string'),
  assignmentStrategy: attr('string'),
  pointerSelector: attr('string'),

  reactions: fragmentArray('messages/reaction', { defaultValue: () => [] }),
  isReactionsReply: equal('replyType', MESSAGE_CONSTANTS.reactionsReply),
  isTextReply: equal('replyType', MESSAGE_CONSTANTS.textReply),
  isAttributeCollectorReply: equal('replyType', MESSAGE_CONSTANTS.attributeCollectorReply),
  hasRepliesDisabled: equal('replyType', MESSAGE_CONSTANTS.repliesDisabled),

  messageStyleNames: ['chat', 'post', 'note', 'video', 'pointer'],
  messageStyleName: objectAtProperty('messageStyleNames', 'messageStyle'),

  isPost: equal('messageStyle', 1),
  isNote: equal('messageStyle', 2),
  isChat: equal('messageStyle', 0),
  isVideo: equal('messageStyle', 3),
  isPointer: equal('messageStyle', 4),

  isEmail: reads('message.isEmail'),
  isMobilePush: reads('message.isMobilePush'),
  isInApp: reads('message.isInApp'),

  isVisitorAutoMessage: reads('message.isVisitorAutoMessage'),
  isUAMEmailCollectorEnabled: reads('message.isAutoMessage'),
  canUseAttributeCollector: or('isVisitorAutoMessage', 'isUAMEmailCollectorEnabled'),
  supportsAttributeCollectorReplyType: and('isChat', 'canUseAttributeCollector'),

  isFull: equal('deliveryOption', 'full'),
  isSummary: equal('deliveryOption', 'summary'),
  isBadge: equal('deliveryOption', 'badge'),

  hasBeenSent: gt('stats.sentCount', 0),
  hasNotBeenSent: not('hasBeenSent'),

  canSetRoundRobin: and('message.app.inboxIsActive', 'assignTo.isTeam'),
  assignWithRoundRobin: equal('assignmentStrategy', MESSAGE_CONSTANTS.roundRobin),

  stats: belongsTo('messages/variation-stats'),
  previewBlocks: applyFunction(generatePreviewBlocks, 'blocks'),

  emailTemplate: belongsTo('email-template', { async: true }),

  isHtmlMode: readOnly('emailTemplate.isHtmlMode'),

  styleLabel: computed(
    'isEmail',
    'isMobilePush',
    'isPost',
    'isNote',
    'isChat',
    'isPointer',
    function () {
      let label = 'Email'; // default to email
      if (this.isMobilePush) {
        label = 'Push Message';
      } else if (this.isPost) {
        label = 'Post';
      } else if (this.isNote) {
        label = 'Note';
      } else if (this.isChat) {
        label = 'Chat';
      } else if (this.isPointer) {
        label = 'Pointer';
      }
      return label;
    },
  ),

  styleIcon: computed(
    'isEmail',
    'isMobilePush',
    'isPost',
    'isNote',
    'isChat',
    'isPointer',
    function () {
      let icon = 'sent-message'; // default to email
      if (this.isMobilePush) {
        icon = 'push-notification';
      } else if (this.isPost) {
        icon = 'inapp-post';
      } else if (this.isNote) {
        icon = 'inapp-note';
      } else if (this.isPointer) {
        icon = 'inapp-pointer';
      } else if (this.isChat) {
        icon = 'chat-bubble';
      }
      return icon;
    },
  ),

  humanReadableCreationDate: computed('createdAt', function () {
    return moment(this.createdAt).fromNow();
  }),

  localeFormattedCreatedAtDate: computed('createdAt', function () {
    return moment(this.createdAt).format(dateAndTimeFormats.fullDateAndTimeWithoutTimezone);
  }),

  //Temporary while we support both old and new variation models
  isReactionOrLWRReply: reads('isReactionsReply'),

  //Exposed for factories
  rawBlocks: attr({ defaultValue: undefined }),

  htmlPreview: '',

  composerPreset(isReadOnly, isMobile) {
    return composerPresetForOptions(this, {
      isReadOnly,
      isMobile,
    });
  },

  activate() {
    return this._post('/ember/message_variations/activate', {
      message_id: this.get('message.id'),
    }).then((response) =>
      this.store.pushPayload({
        'message-variations': [response],
      }),
    );
  },

  deactivate() {
    return this._post('/ember/message_variations/deactivate', {
      message_id: this.get('message.id'),
    }).then((response) =>
      this.store.pushPayload({
        'message-variations': [response],
      }),
    );
  },

  generateHtmlPreview() {
    return this._post(
      '/ember/message_variations/generate_html_preview',
      Object.assign(this.serialize(), {
        selection_state: this.get('message.selectionState').serialize(),
        show_unsubscribe_link: this.get('message.showUnsubscribeLink'),
        preview_user_id: this.get('message.sampleUser.user_id'),
      }),
    ).then((response) => this.set('htmlPreview', response));
  },

  _post(url, additionalData = {}) {
    return this._request(url, 'POST', additionalData);
  },

  _request(url, requestType = 'POST', additionalData = {}) {
    return nonconcurrentAjax(this, {
      url,
      type: requestType,
      data: JSON.stringify(
        Object.assign(additionalData, {
          id: this.id,
          app_id: this.get('message.appId'),
          admin_id: this.get('message.app.currentAdmin.id'),
        }),
      ),
    });
  },
});
