/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';
import StandardInserterComponentConfiguration from 'embercom/objects/series/node/standard-inserter-component-configuration';
import StandardLoadingComponentConfiguration from 'embercom/objects/series/node/standard-loading-component-configuration';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class CarouselInserterConfiguration extends InserterConfiguration {
  objectType = objectTypes['carousel'];
  objectData = {};
  componentConfig = new StandardInserterComponentConfiguration({
    icon: 'series/content/carousel',
    title: 'Carousel',
  });
  loadingComponentConfig = new StandardLoadingComponentConfiguration({
    icon: 'series/content/carousel',
    title: 'Empty carousel',
    objectType: this.objectType,
    objectName: 'Carousel',
  });

  canInsertNode() {
    return super.canInsertNode(...arguments);
  }
}
