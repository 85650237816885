/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import PredicateFilterMap from 'embercom/models/data/predicate-filter-map';

const numericComparisons = {
  comparison: [
    { label: 'is greater than', value: 'gt', selected: false },
    { label: 'is less than', value: 'lt', selected: false },
    { label: 'is', value: 'eq', selected: false },
    { label: 'is not', value: 'ne', selected: false },
    { label: 'is increased', value: 'increase', selected: false, hideInput: true },
    { label: 'is decreased', value: 'decrease', selected: false, hideInput: true },
    {
      label: 'has any value',
      value: 'known',
      selected: false,
      hideInput: true,
      comparison: 'known',
    },
  ],
};

const optionalTagComparisons = {
  comparison: [
    { label: 'is', value: 'eq', selected: false },
    { label: 'is not', value: 'ne', selected: false },
    { label: 'has any value', value: 'known', selected: false, hideInput: true },
  ],
};

let CustomPredicateFilterMap = {
  numeric: numericComparisons,
  integer: numericComparisons,
  float: numericComparisons,
  string: {
    comparison: [
      { label: 'is', value: 'eq', selected: false },
      { label: 'is not', value: 'ne', selected: false },
      { label: 'starts with', value: 'starts_with', selected: false },
      { label: 'ends with', value: 'ends_with', selected: false },
      { label: 'contains', value: 'contains', selected: false },
      { label: 'does not contain', value: 'not_contains', selected: false },
      {
        label: 'has any value',
        value: 'known',
        selected: false,
        hideInput: true,
        comparison: 'known',
      },
    ],
  },
  date: {
    comparison: [
      {
        label: 'has any value',
        value: 'known',
        selected: false,
        hideInput: true,
        comparison: 'known',
      },
    ],
  },
  plan: optionalTagComparisons,
  optionalTag: optionalTagComparisons,
  user_event: {
    comparison: [
      {
        label: 'is triggered',
        value: 'triggered',
        selected: false,
        hideInput: true,
        comparison: 'triggered',
      },
      { label: 'count is greater than', value: 'gt', selected: false },
      { label: 'count is less than', value: 'lt', selected: false },
      { label: 'count is', value: 'eq', selected: false },
      { label: 'count is not', value: 'ne', selected: false },
    ],
  },
};
let PredicateFilterMapCopy = $.extend(true, {}, PredicateFilterMap);
let GoalsPredicateFilterMap = $.extend(false, PredicateFilterMapCopy, CustomPredicateFilterMap);

export default GoalsPredicateFilterMap;
