/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import type Inbox from './inbox';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { NamedIcon } from '../command-k/icons';

export type UnassignedInboxWireFormat = {
  inbox_type: InboxType.Unassigned;
  count: number;
};

export default class Unassigned implements Inbox {
  readonly id = 'unassigned';
  readonly category = InboxCategory.Shared;
  readonly type = InboxType.Unassigned;
  readonly count?: number;

  name = 'Unassigned';
  icon = new NamedIcon('unassigned');

  readonly translationKey = 'inbox.inbox-list.unassigned';

  constructor(count: number) {
    this.count = count;
  }

  static deserialize(json: UnassignedInboxWireFormat): Unassigned {
    return new Unassigned(json.count);
  }
}
