/* RESPONSIBLE TEAM: team-proactive-support */

/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import Model, { attr, hasMany } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import { dependentKeyCompat } from '@ember/object/compat';
import surveyEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/survey';
import Admin from 'embercom/models/admin';
import SurveyValidations from 'embercom/validations/survey';
import { isNone, isPresent } from '@ember/utils';
import {
  maximumStepsLimitForBranching,
  maximumQuestionsPerStepBannerFormat,
  maximumQuestionsPerStepPostFormat,
  surveyFormats,
  thankYouStepStartOrder,
} from 'embercom/models/data/survey/constants';
import { deliveryChannels, senderTypes } from 'embercom/models/data/outbound/constants';
import { pathBasedUniqueStepsIds } from 'embercom/lib/survey-branching-helper';

const UNASSIGNED_ID = '0';
export default class SurveyModel extends Model.extend(SurveyValidations) {
  @service store;
  @service appService;
  @attr('number', { defaultValue: () => surveyFormats.banner }) format;
  @attr('number') senderId;
  @attr('number', { defaultValue: () => senderTypes.admin }) senderType;
  @attr('array') preferredDevices;
  @attr('boolean') dismissible;
  @attr('boolean') showProgressBar;
  @hasMany('surveys/step', { inverse: 'survey' }) steps;
  @fragment('customization-options/outbound-content') customizationOptions;
  @hasMany('content-service/path') paths;
  @hasMany('surveys/question', { inverse: 'survey' }) deletedQuestions;

  get sender() {
    if (this.senderId && this.senderId !== UNASSIGNED_ID) {
      return Admin.peekAndMaybeLoad(this.store, this.senderId);
    }
  }

  get senderName() {
    return this.sender.firstNameOrNameOrEmail || 'Unknown';
  }

  get showSender() {
    return this.senderType !== senderTypes.noSender;
  }

  get isBannerFormat() {
    return this.format === surveyFormats.banner;
  }

  get isPostFormat() {
    return this.format === surveyFormats.post;
  }

  get maximumStepsLimit() {
    return maximumStepsLimitForBranching;
  }

  get maximumQuestionsLimit() {
    if (this.isBannerFormat) {
      return maximumQuestionsPerStepBannerFormat;
    } else if (this.isPostFormat) {
      return maximumQuestionsPerStepPostFormat;
    }
  }

  get maximumThankYouStepsLimit() {
    return 3;
  }

  get hasReachedMaximumStepsLimit() {
    return this.orderedSteps.length >= this.maximumStepsLimit;
  }

  get hasReachedMaximumThankYouStepsLimit() {
    return this.thankYouSteps.length >= this.maximumThankYouStepsLimit;
  }

  get orderedSteps() {
    return this.steps.filter((step) => step.isOrdered).sortBy('order');
  }

  get hasThankYouStep() {
    return this.steps.any((step) => step.isThankYouType);
  }

  get defaultThankYouStep() {
    return this.thankYouSteps.find((step) => step.order === thankYouStepStartOrder);
  }

  get thankYouSteps() {
    return this.steps
      .filter((step) => step.isThankYouType)
      .sortBy('order')
      .reverse();
  }

  get nextThankYouStepOrder() {
    return thankYouStepStartOrder - this.thankYouSteps.length;
  }

  get hasIntroStep() {
    return this.steps.any((step) => step.isIntroType);
  }

  get hasAnySteps() {
    return Boolean(this.orderedSteps.length);
  }

  get firstStep() {
    return this.orderedSteps.firstObject;
  }

  get introStep() {
    return this.steps.filter((step) => step.isIntroType).firstObject;
  }

  get preferredDevicesHasMobile() {
    return this.preferredDevices.some((device) => deliveryChannels.mobile.includes(device));
  }

  get userDefinedBranchingPaths() {
    return this.paths.filter((path) => path.order > 0);
  }

  get stepsIdsWithAttachedBranchingLogic() {
    return pathBasedUniqueStepsIds(this.userDefinedBranchingPaths);
  }

  get questions() {
    return this.steps.toArray().flatMap((step) => step.orderedQuestions.toArray());
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.steps.any((step) => step.hasUnsavedChanges);
  }

  @dependentKeyCompat
  get contentHash() {
    return {
      format: this.format,
      senderId: this.senderId,
      senderType: this.senderType,
      steps: this.steps.map((step) => step.contentHash),
      preferredDevices: this.preferredDevices,
      dismissible: this.dismissible,
      showProgressBar: this.showProgressBar,
    };
  }

  editorConfig() {
    let container = getOwner(this);
    return surveyEditConfigGenerator({ container });
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this._clearLocallyCreatedSteps();
    this.steps.forEach((step) => step.rollbackAttributes());
    this._rehydratePathRecords();
  }

  afterSave() {
    this._clearLocallyCreatedSteps();
    this.steps.forEach((step) => step._clearLocallyCreatedQuestions());
    this.steps.forEach((step) => step._clearLocallyCreatedActions());
    this._rehydratePathRecords();
  }

  _clearLocallyCreatedSteps() {
    this.steps
      .filter((step) => isNone(step.id))
      .forEach((step) => {
        this.store.unloadRecord(step);
      });
  }

  _rehydratePathRecords() {
    // Clear locally created paths that have now been persisted
    this.paths
      .filter((path) => isNone(path.id))
      .forEach((path) => {
        this.store.unloadRecord(path);
      });

    // Since we don't call `path.save()` directly we need to manually rollback the attributes
    // The changes have actually been saved on the backend.
    this.paths
      .filter((path) => path.hasDirtyAttributes)
      .forEach((path) => {
        path.rollbackAttributes();
      });
  }

  selectedQuestionTypeDetails(selctedQuestionType) {
    let [questionType, scaleQuestionType] = selctedQuestionType
      .toString()
      .split('.')
      .map((el) => Number(el));

    return {
      questionType,
      scaleQuestionType,
    };
  }

  handleMultiSelectMax(currentQuestion, isMultiSelectSelected) {
    let { isMultiSelect: isMultiSelectFromResponse, includeOther, options } = currentQuestion.data;
    let otherOptionCount = includeOther ? 1 : 0;
    let isMultiSelect =
      isMultiSelectSelected !== undefined ? isMultiSelectSelected : isMultiSelectFromResponse;

    if (isMultiSelect) {
      this.question.data.maximumSelection = options.length + otherOptionCount;
    } else {
      this.question.data.maximumSelection = 1;
    }
  }

  get isValid() {
    return this.validations.isValid && this.steps.every((step) => step.validations.isValid);
  }
}

export function getSurveyUrlParam(hasOtherParams, rulesetId) {
  return `${hasOtherParams ? '&' : '?'}intercom_survey_id=${rulesetId}`;
}

export function generateShareableUrl(url, rulesetId) {
  if (isPresent(url)) {
    let hasParams = url.indexOf('?') > -1;
    let surveyParam = getSurveyUrlParam(hasParams, rulesetId);
    url = `${url}${surveyParam}`;
  }
  return url;
}
