/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import { readOnly, not } from '@ember/object/computed';

const Validations = buildValidations({
  redirectEnabled: [
    validator('task-bots/conversation-routing-has-valid-redirect', {
      dependentKeys: [
        'model.redirectEnabled',
        'model.redirectEmail',
        'model.redirectPhone',
        'model.redirectUrl',
      ],
    }),
  ],
  redirectEmail: [
    validator('format', {
      allowBlank: true,
      type: 'email',
      disabled: not('model.redirectEnabled'),
    }),
  ],
  redirectPhone: [
    validator('format', {
      allowBlank: true,
      type: 'phone',
      disabled: not('model.redirectEnabled'),
    }),
  ],
  redirectUrl: [
    validator('format', {
      allowBlank: true,
      type: 'url',
      disabled: not('model.redirectEnabled'),
    }),
  ],
});

export default Model.extend(Validations, {
  enabled: attr('boolean'),
  assigneeId: attr('string'),
  redirectEnabled: attr('boolean'),

  redirectEmail: attr('string'),
  redirectUrl: attr('string'),
  redirectPhone: attr('string'),

  isValid: readOnly('validations.isValid'),
  isNotDirty: not('hasDirtyAttributes'),

  refresh() {
    this.rollbackAttributes();
  },
});
