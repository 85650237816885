/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { RenderableTypeClassMapping } from 'embercom/models/data/inbox/renderable-classes';
import { type EntityType } from 'embercom/models/data/entity-types';
import { type AssigningEntity } from 'embercom/models/data/inbox/assignment-enums';
import { type EmailMetadata } from './renderable/email-metadata';

export interface CreatingEntity {
  type: EntityType | AssigningEntity | undefined;
  id: string | number;
  object?: any;
}

export interface LinkedTicketWireFormat {
  id: number;
}

export class LinkedTicket {
  readonly id: number;

  constructor(id: number) {
    this.id = id;
  }

  static deserialize(json: LinkedTicketWireFormat): LinkedTicket {
    return new LinkedTicket(json.id);
  }

  static serialize(linkedTicket: LinkedTicket): LinkedTicketWireFormat {
    return {
      id: linkedTicket.id,
    };
  }
}

export interface RenderableData {
  readonly renderableType: RenderableType;
  creatingEntity: CreatingEntity;
  adminOnly?: boolean;
  styles?: {
    backgroundColor: string;
    textColor: string;
    backgroundColorHover?: string;
  };
  ticketStyles?: {
    backgroundColor: string;
    textColor: string;
    backgroundColorHover?: string;
  };
  ticketId?: number;
  rulesetId?: number;
  linkedTicket?: LinkedTicket;
  issueSummary?: string;
  finAnswerType?: FinAnswerType;
  emailMetadata?: EmailMetadata;
}

export interface DeserializableRenderableData {
  deserialize(json: object): RenderableData;
}

export interface RenderableDataWireFormat {
  renderable_type: RenderableType;
  [x: string]: any;
}

export function deserialize(
  partType: RenderableType,
  json: RenderableDataWireFormat,
): RenderableData {
  // json.renderable_type should always be present, but fall-back to the parent type
  return RenderableTypeClassMapping[json.renderable_type ?? partType].deserialize(json);
}

export enum FinAnswerType {
  InlineAnswer = 'inline_answer',
  OutOfDomain = 'out_of_domain',
  RelevantContent = 'relevant_content',
}
