/* RESPONSIBLE TEAM: team-proactive-support */
export default (array, conjunction = 'and', showAllConjunctions = false) => {
  let length = array.length;

  if (length === 0) {
    return '';
  } else if (length === 1) {
    return array[0];
  } else if (length === 2 || showAllConjunctions) {
    return array.join(` ${conjunction} `);
  } else {
    let beforeAnd = array.slice(0, -1).join(', ');
    let afterAnd = array.slice(-1);
    return `${beforeAnd}, ${conjunction} ${afterAnd}`;
  }
};
