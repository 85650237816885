/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  label: {
    descriptionKey: 'messenger.add-apps.external-links.side-drawer-link-label',
    validators: [
      validator('presence', {
        presence: true,
        messageKey: 'validations.messenger-settings.errors.required',
      }),
    ],
  },
  href: {
    descriptionKey: 'messenger.add-apps.external-links.side-drawer-link-href',
    validators: [
      validator('presence', {
        presence: true,
        messageKey: 'validations.messenger-settings.errors.required',
      }),
      validator('format', {
        type: 'url',
        descriptionKey: 'messenger.add-apps.external-links.side-drawer-link-href',
        messageKey: 'validations.messenger-settings.errors.url',
      }),
    ],
  },
});

export default class ExternalLink extends Model.extend(Validations) {
  @belongsTo('messenger-home/slot') slot;

  @attr('string') label;
  @attr('string') href;
  @attr('number') displayOrder;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }
}
