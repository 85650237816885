/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import { equal, not } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import PageTargetValidations from 'embercom/validations/messages/page-target';
import { selectFromObject } from '@intercom/pulse/lib/computed-properties';

export default Fragment.extend(PageTargetValidations, {
  pageTargetString: attr('string'),
  pageTargetType: attr('number'),
  empty: equal('pageTargetType', 0),
  present: not('empty'),

  _comparisons: {
    0: '',
    1: 'the page with the URL',
    2: 'pages with a URL that contains',
    3: 'pages with a URL that match the regular expression',
  },
  comparison: selectFromObject('_comparisons', 'pageTargetType'),
});
