/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { assert } from '@ember/debug';
import { isArray } from '@ember/array';

// The ActiveViewConfiguration is a simple
// object that maps view keys to an array of component configurations.

export default EmberObject.extend({
  init() {
    this._super(...arguments);
    Object.keys(this)
      .reject((property) => property === '_super')
      .forEach((property) => {
        let value = this.get(property);
        assert('An array must be passed for the view component configs', isArray(value));
        value.forEach((element) => {
          assert(
            `Expected a component for the active view configuration [${property}]`,
            element.component,
          );

          element.parentConfiguration = this;
        });
      });
  },
});
