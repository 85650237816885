/* RESPONSIBLE TEAM: team-help-desk-experience */
import Model, { attr, belongsTo } from '@ember-data/model';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import type PingDomainSettings from './ping-domain-settings';

interface PingDomainError {
  short: string;
  long?: string;
  article?: string;
}

export default class PingDomain extends Model {
  @attr('string') declare appId: string;
  @attr('string') declare domain: string;
  @attr('boolean') declare validUserHash: boolean;
  @attr('boolean') declare identityVerificationEnabled: boolean;
  @attr('boolean') declare ignored: boolean;
  @attr('boolean') declare isTrustedDomain: boolean;
  @attr('string') declare errorCode: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  @belongsTo('ping-domain-settings', { async: false }) declare settings: PingDomainSettings;

  @service declare intl: IntlService;
  @service declare router: Router;

  get hasError(): boolean {
    return !this.validUserHash || this.isUntrustedDomain;
  }

  get missingHashes(): boolean {
    return this.errorCode === 'user_hash_is_missing';
  }

  get isUntrustedDomain(): boolean {
    return !this.isTrustedDomain;
  }

  get lastModified(): string {
    let last24Hours = moment().subtract(24, 'hours');
    let lastUpdated = moment(this.updatedAt);

    if (lastUpdated.isAfter(last24Hours)) {
      return this.intl.t(
        'components.settings.identity-verification.ping-domain.last-modified.less-than-day-ago',
      );
    }

    return lastUpdated.fromNow();
  }

  get errorMessage(): PingDomainError {
    if (this.errorCode) {
      return this.errorsMapping(this.errorCode);
    } else {
      return this.errorsMapping('default');
    }
  }

  get errorMessages(): PingDomainError[] {
    let errors = [];
    if (this.isUntrustedDomain) {
      errors.push(this.errorsMapping('untrusted_domain'));
    }
    if (this.errorCode) {
      errors.push(this.errorsMapping(this.errorCode));
    }

    return errors;
  }

  get noErrorsText(): string {
    return this.errorsMapping('default').short;
  }

  errorsMapping(errorCode: string): PingDomainError {
    let prefix = 'components.settings.identity-verification.ping-domain.errors';

    switch (errorCode) {
      case 'user_hash_is_missing':
        return {
          short: this.intl.t(`${prefix}.user-hash-is-missing.short`),
          long: this.intl.t(`${prefix}.user-hash-is-missing.long`),
          article:
            'https://www.intercom.com/help/en/articles/7946881-troubleshooting-identity-verification',
        };
      case 'user_hash_is_unrecognized':
        return {
          short: this.intl.t(`${prefix}.user-hash-is-unrecognized.short`),
          long: this.intl.t(`${prefix}.user-hash-is-unrecognized.long`),
        };
      case 'untrusted_domain':
        return {
          short: this.intl.t(`${prefix}.untrusted-domain.short`),
          long: this.intl.t(`${prefix}.untrusted-domain.long`, {
            url: this.router.urlFor('apps.app.messenger.settings', {
              queryParams: { tab: 'general', section: 'security' },
            }),
            htmlSafe: true,
          }),
          article:
            'https://www.intercom.com/help/en/articles/4418-list-trusted-domains-you-use-with-your-messenger',
        };
      default:
        return {
          short: this.intl.t(`${prefix}.default.short`),
        };
    }
  }
}
