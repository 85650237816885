/* RESPONSIBLE TEAM: team-proactive-support */
import SeriesModeConfig from 'embercom/objects/content-editor/series-mode-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import SeriesAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/series-audience-editor-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';

export default function generateEditorConfig(options) {
  let container = options.container;
  let audienceEditorConfiguration = new SeriesAudienceEditorConfiguration({
    showMatchBehavior: false,
    showClientPredicates: true,
    showEventRules: true,
    showDateEvents: true,
    container,
  });

  let ruleset = audienceEditorConfiguration.ruleset;

  if (!shouldShowAudiencePreview(ruleset)) {
    audienceEditorConfiguration.audiencePreview = ComponentConfiguration.create({
      component:
        'content-editor/audience-wrappers/content/series/audience-preview-non-starting-node',
      config: audienceEditorConfiguration.inputs,
    });
  }

  return new SeriesModeConfig({
    headerComponentConfig: StandardSeriesModeHeaderConfiguration.create({ container }),
    editorComponentConfigs: [
      ComponentConfiguration.create({
        component: 'content-editor/panels/audience/series-body',
        audienceEditorConfiguration,
      }),
    ],

    viewModeHeaderConfig: StandardSeriesViewModeHeaderConfiguration.create({ container }),
    viewModeSummaryConfig: ComponentConfiguration.create({
      component: 'content-editor/panels/audience/body',
      audienceEditorConfiguration: new SeriesAudienceEditorConfiguration({
        showClientPredicates: ruleset.clientPredicateGroup.predicates.length,
        showAudiencePreview: false,
        showEventRules: ruleset.rulesetTriggers.length,
        showDateEvents: ruleset.rulesetTriggers.length,
        showMatchBehavior: false,
        hideAddFilterGroupButton: true,
        hideAddRule: true,
        isDisabled: true,
        container,
      }),
    }),
  });
}

function shouldShowAudiencePreview(ruleset) {
  if (!ruleset.get('node')) {
    return true;
  }
  return ruleset.get('node').isStarting;
}
