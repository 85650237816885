/* RESPONSIBLE TEAM: team-proactive-support */
import PanelConfiguration from 'embercom/objects/content-editor/panel-configuration';
import GoalHeaderConfiguration from 'embercom/objects/content-editor/panels/goal/standard-goal-header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import { contentEditors } from 'embercom/models/data/matching-system/matching-constants';
import { assert } from '@ember/debug';

export default PanelConfiguration.extend({
  init(inputs) {
    // The container gives us access to Services in these objects. Pass `container: getOwner(this)` as an
    // input when generating these configuration objects.
    assert('You must provide the application container in your panel inputs', inputs.container);

    let header = GoalHeaderConfiguration.create(
      {
        title: inputs.title,
      },
      { container: inputs.container },
    );

    assert('You must provide the introduction text in your panel inputs', inputs.introText);
    let body = ComponentConfiguration.create({
      component: 'content-editor/panels/goal/body',
      introText: inputs.introText,
      showUtmTracking: inputs.showUtmTracking,
    });
    this._super({
      name: 'goal',
      header,
      body,
      supportedEditors: [contentEditors.standalone, contentEditors.series],
    });
  },
});
