/* RESPONSIBLE TEAM: team-workflows */
import { Channel } from 'embercom/models/data/inbox/channels';

export interface WorkflowDetailsWireFormat {
  workflow_instance_id: number;
  compatible_channels: number[];
}

export default class WorkflowDetails {
  workflowInstanceId: number;
  compatibleChannels: Channel[];

  constructor(worflowInstanceId: number, compatibleChannels: number[]) {
    this.workflowInstanceId = worflowInstanceId;
    /**
     * Transform an array of numeric values into an array of valid enum values of type Channel
     * 1. For each numeric value in the compatibleChannels array, it uses the numeric value as a key
     *    to find the corresponding string key name in the Channel enum (Channel[Channel[channel]]).
     * 2. It then uses this string key name to get the actual Channel enum value, ensuring that only
     *    valid enum values are included in the compatibleChannels array.
     * 3. The filter operation removes any undefined entries, ensuring that the array only contains valid
     *    Channel enum values.
     * */
    this.compatibleChannels = compatibleChannels
      .map((channel) => Channel[Channel[channel] as keyof typeof Channel])
      .filter((channel) => channel !== undefined) as Channel[];
  }

  static deserialize(json: WorkflowDetailsWireFormat): WorkflowDetails {
    return new WorkflowDetails(json.workflow_instance_id, json.compatible_channels);
  }
}
