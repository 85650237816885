/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

let Validations = buildValidations({
  descriptorId: [validator('presence', true)],
  controllingDescriptor: [validator('presence', true)],
  controllingListOptionId: [validator('presence', true)],
});

export default class Condition extends Model.extend(Validations) {
  @attr('string') declare descriptorId: number;
  @attr('string') declare controllingDescriptorId: number;
  @attr('string') declare controllingListOptionId: string;
  @attr('array') declare descriptorListOptionIds: string[];

  @belongsTo('conversation-attributes/descriptor', { inverse: 'conditions', async: false })
  descriptor: any;
  @belongsTo('conversation-attributes/descriptor', { inverse: 'dependentConditions', async: false })
  controllingDescriptor: any;
}
