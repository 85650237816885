/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import { type BlockList } from '@intercom/interblocks.ts';
import { request } from 'embercom/lib/inbox/requests';
import latinize from 'latinize';
import type AdminSummary from './admin-summary';

export type MacroActionType =
  | 'reply-to-conversation'
  | 'add-note-to-conversation'
  | 'close-conversation'
  | 'assign-conversation' // assign to team/teammate are saved with this action type in the backend
  | 'assign-conversation-to-teammate' // This type only exists in the front end
  | 'assign-conversation-to-team' // This type only exists in the front end
  | 'snooze-conversation'
  | 'assign-conversation-to-owner'
  | 'change-conversation-priority'
  | 'add-tag-to-conversation'
  | 'set-conversation-data-attribute'
  | 'workflow-connector-action'
  | 'add-conversation-topic';

export type MacroActionEditorContext = 'bulk-edit' | 'create-macro' | 'conversation';

export enum MacroType {
  Opener = 0,
  Reply = 1,
  Note = 2,
}

export enum AdminSearchContexts {
  Mentions = 100,
  Assignments = 101,
}

export type QuickSearchContexts = MacroType | AdminSearchContexts;

export enum MacroVisibility {
  Everyone = 'everyone',
  Teams = 'teams',
  Myself = 'owner',
}

export enum MacroActionTypeValues {
  ReplyToConversation = 'reply-to-conversation',
  AddNoteToConversation = 'add-note-to-conversation',
}

const ASSIGNMENT_IDENTIFIERS = ['assign-conversation-to-teammate', 'assign-conversation-to-team'];
export interface MacroActionWireFormat {
  type: MacroActionType;
  action_data: Record<string, any>;
}

export class MacroAction {
  type: MacroActionType;
  @tracked data?: Record<string, any>;
  @tracked justAdded?: boolean = false;
  @tracked applyable?: boolean = true;
  @tracked failReasonTooltipContent?: string;

  constructor(
    type: MacroActionType,
    data?: Record<string, any>,
    justAdded?: boolean,
    applyable = true,
  ) {
    this.type = type;
    this.data = data;
    this.justAdded = justAdded;
    this.applyable = applyable;
  }

  updateData(data: Record<string, any>) {
    this.data = data;
  }

  get uniquenessKey() {
    if (this.type === 'close-conversation' || this.type === 'snooze-conversation') {
      return 'close-or-snooze-conversation';
    } else if (this.type.startsWith('assign-conversation')) {
      return 'assign-conversation';
    } else if (this.type === 'add-tag-to-conversation') {
      return `${this.type}-${this.data?.tag_summary?.id}`;
    } else if (this.type === 'set-conversation-data-attribute') {
      return `${this.type}-${this.data?.attribute_summary?.identifier}`;
    } else if (this.type === 'workflow-connector-action') {
      return `${this.type}-${this.data?.workflow_connector_action_summary?.id}`;
    } else if (this.type === 'add-conversation-topic') {
      return `${this.type}-${this.data?.topic?.id}`;
    } else {
      return `${this.type}`;
    }
  }

  get isInvalid() {
    if (!this.justAdded && this.data) {
      if (this.type === 'snooze-conversation') {
        return !('snoozed_until' in this.data);
      } else if (this.type.startsWith('assign-conversation')) {
        return !('assignee_summary' in this.data);
      } else if (this.type === 'workflow-connector-action') {
        return !('workflow_connector_action_summary' in this.data);
      } else if (this.type === 'add-tag-to-conversation') {
        return !('tag_summary' in this.data);
      } else if (this.type === 'add-conversation-topic') {
        return !('topic' in this.data);
      }
    }

    return false;
  }

  static deserialize(json: MacroActionWireFormat): MacroAction {
    let type = json.type;
    if ('assign-conversation' === json.type) {
      if (json.action_data?.assignee_summary.type === 'admin') {
        type = 'assign-conversation-to-teammate';
      } else {
        type = 'assign-conversation-to-team';
      }
    }

    return new MacroAction(type, json.action_data);
  }
}

export function transformMacroActions(macroActions: MacroAction[]): MacroActionWireFormat[] {
  return macroActions.map((action) => ({
    action_data: action.data ? transformMacroActionData(action.type, action.data) : {},
    type: transformMacroActionType(action.type),
  }));
}

export function transformMacroTypes(types: MacroType[]) {
  return types.map((type) => {
    switch (type) {
      case MacroType.Opener:
        return 'opener';
      case MacroType.Reply:
        return 'reply';
      case MacroType.Note:
        return 'note';
      default:
        throw new Error(`unknown macro type: ${type}`);
    }
  });
}

function transformMacroActionData(
  type: MacroAction['type'],
  data: MacroAction['data'],
): MacroActionWireFormat['action_data'] {
  if (type === 'add-tag-to-conversation') {
    return { tag_id: data?.tag_summary.id };
  } else if (ASSIGNMENT_IDENTIFIERS.includes(type)) {
    return { assignee_id: data?.assignee_summary.id };
  } else if (type === 'set-conversation-data-attribute') {
    return {
      attribute_identifier: data?.attribute_summary.identifier,
      value: data?.attribute_summary.value,
    };
  } else if (type === 'workflow-connector-action') {
    return { workflow_connector_action_id: data?.workflow_connector_action_summary.id };
  } else if (type === 'add-conversation-topic') {
    return { topic_id: data?.topic.id };
  } else {
    return data || {};
  }
}

function transformMacroActionType(type: MacroAction['type']) {
  if (ASSIGNMENT_IDENTIFIERS.includes(type)) {
    return 'assign-conversation';
  }
  return type;
}

export interface MacroWireFormat {
  id: number;
  name: string;
  blocks: BlockList;
  actions: Array<MacroActionWireFormat>;
  updated_at: Date;
  author: AdminSummary;
  restricted_contexts?: Array<MacroType>;
}

export default class Macro {
  readonly id: number;
  readonly name: string;
  readonly blocks: BlockList;
  readonly updatedAt: Date;
  readonly author: AdminSummary;
  readonly actions: Array<MacroAction>;

  constructor(
    id: number,
    name: string,
    blocks: BlockList,
    updatedAt: Date,
    author: AdminSummary,
    actions?: Array<MacroActionWireFormat | MacroAction>,
  ) {
    this.id = id;
    this.name = name;
    this.blocks = blocks;
    this.updatedAt = updatedAt;
    this.author = author;
    this.actions =
      actions?.map((action) =>
        action instanceof MacroAction ? action : new MacroAction(action.type, action.action_data),
      ) ?? [];
  }

  get latinizedLocaleLowerCaseName() {
    return latinize(this.name).toLocaleLowerCase();
  }

  static deserialize(json: MacroWireFormat): Macro {
    let actions = json.actions.map(MacroAction.deserialize);
    return new Macro(json.id, json.name, json.blocks, json.updated_at, json.author, actions);
  }

  async render(
    appId: string,
    isGroupConversation = false,
    conversationId?: string,
    userId?: string,
  ): Promise<Macro> {
    let response = await request(
      this.buildRenderUrl(appId, isGroupConversation, conversationId, userId),
    );
    let json = (await response.json()).saved_reply as MacroWireFormat;
    return Macro.deserialize(json);
  }

  private buildRenderUrl(
    appId: string,
    isGroupConversation = false,
    conversationId?: string,
    userId?: string,
  ) {
    let url = `/ember/inbox/saved_replies/${this.id}.json?app_id=${appId}&group_conversation=${isGroupConversation}`;
    if (conversationId) {
      url += `&conversation_id=${conversationId}`;
    }
    if (userId) {
      url += `&user_id=${userId}`;
    }

    return url;
  }
}
