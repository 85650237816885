/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import CarouselContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/carousel/panel-configuration';
import CarouselTriggerProgrammaticallyPanelConfiguration from 'embercom/objects/content-editor/panels/trigger-programmatically/carousel/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import SeriesSchedulePanelConfiguration from 'embercom/objects/content-editor/panels/schedule/series-schedule-panel-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import CarouselAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/content/carousel/audience-editor-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import CarouselAudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/content/carousel/audience-header-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';

export default function generateEditModeConfig(options) {
  let container = options.container;

  let audienceEditorConfiguration = new CarouselAudienceEditorConfiguration({
    allowedUserRoles: ['user'],
    showClientPredicates: false,
    showEventRules: true,
    showDateEvents: true,
    container,
  });
  audienceEditorConfiguration.audienceRulesEditor = ComponentConfiguration.create({
    component: 'content-editor/audience-wrappers/content/carousel/audience-rules-editor',
    config: audienceEditorConfiguration.inputs,
  });
  audienceEditorConfiguration.audiencePreview = ComponentConfiguration.create({
    component: 'content-editor/audience-wrappers/content/carousel/audience-preview',
    config: audienceEditorConfiguration.inputs,
  });

  let editorPanelConfigs = [
    CarouselContentPanelConfiguration.create({ container }),
    StandardAudiencePanelConfiguration.create({
      audienceHeaderConfiguration: CarouselAudienceHeaderConfiguration.create({ container }),
      audienceEditorConfiguration,
      container,
    }),
    CarouselTriggerProgrammaticallyPanelConfiguration.create({ container }),
    StandardSchedulePanelConfiguration.create({
      showFrequencyEditor: true,
      title: 'Frequency and scheduling',
      container,
    }),
    SeriesSchedulePanelConfiguration.create({ container }),
    StandardGoalPanelConfiguration.create({
      title: 'Set your goal',
      introText: 'Understand how your carousel drives people to take action in your product.',
      container,
    }),
  ];

  audienceEditorConfiguration.footer = ComponentConfiguration.create({
    component: 'content-editor/panels/audience/content/carousel/footer',
    config: audienceEditorConfiguration,
  });

  return EditModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesModeHeaderConfiguration.create({ container }),
    headerComponentConfigs: [StandardEditModeHeaderConfiguration.create({ container })],
    footerComponentConfigs: [],
    editorPanelConfigs,
  });
}
