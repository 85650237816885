/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';

export default EmberObject.extend({
  duplicateModalComponent: undefined,
  hideDuplicateOption: false,
  hideCsvExportOption: false,
  hideTagOption: false,
  hideVersionsOption: false,
  hideDeleteOption: false,

  init(inputs) {
    this._super(...arguments);
    this.setProperties(inputs);
  },
});
