/* RESPONSIBLE TEAM: team-channels */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { LANGUAGES_RELEASED_TO_EVERYBODY } from 'embercom/lib/resolution-bot-languages';

export default class SmsLanguage extends Fragment {
  @attr('string', { readOnly: true }) _id;
  @attr('string', { readOnly: true }) language;
  @attr('string') smsContent;

  // These aren't part of the core model
  @attr('boolean', { readOnly: true }) default;
  @attr('string', { readOnly: true }) defaultSmsContent;

  get languageName() {
    let languages = LANGUAGES_RELEASED_TO_EVERYBODY;
    let match = languages.find(({ value }) => value === this.language);
    return (match && match.text) || this.language;
  }
}
