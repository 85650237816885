/* RESPONSIBLE TEAM: team-proactive-support */
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import Model, { attr, hasMany } from '@ember-data/model';
import PostValidations from 'embercom/validations/post';
import postEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/post';
import { dependentKeyCompat } from '@ember/object/compat';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import {
  hasiOSSessionsPredicate,
  hasAndroidSessionsPredicate,
} from 'embercom/models/mobile-push/push';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export const formatOptions = {
  small: 0,
  large: 1,
};

export const replyTypeOptions = {
  text: 0,
  reaction: 1,
  disabled: 2,
};

export const defaultReactions = [
  {
    index: 0,
    unicodeEmoticon: '💜',
  },
  {
    index: 1,
    unicodeEmoticon: '😴',
  },
  {
    index: 2,
    unicodeEmoticon: '👎',
  },
  {
    index: 3,
    unicodeEmoticon: '🎉',
  },
];

export default class PostModel extends Model.extend(PostValidations) {
  @service store;

  @attr('number') format;
  @attr('number') replyType;
  @attr('number') sentAs;
  @attr() sendPushNotification;
  @attr('array') preferredDevices;
  @fragmentArray('messages/reaction') reactionSet;
  @hasMany('posts/localized-post-content', { inverse: 'post' }) localizedPostContents;

  @dependentKeyCompat
  get contentHash() {
    return this.localizedPostContents.map(
      (localizedPostContent) => localizedPostContent.contentHash,
    );
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.localizedPostContents.any((localizedContent) => localizedContent.hasUnsavedChanges)
    );
  }

  get localizedContents() {
    return this.localizedPostContents;
  }

  @dependentKeyCompat
  get implicitPredicates() {
    // Any changes to this function should be made to the backend version too.
    let predicates = [];

    if (
      this.preferredDevices.length === 2 &&
      this.preferredDevices.includes(preferredDevices.ios) &&
      this.preferredDevices.includes(preferredDevices.android)
    ) {
      predicates.push({
        type: 'or',
        predicates: [hasAndroidSessionsPredicate, hasiOSSessionsPredicate],
      });
    } else if (
      this.preferredDevices.length === 1 &&
      this.preferredDevices[0] === preferredDevices.android
    ) {
      predicates.push(hasAndroidSessionsPredicate);
    } else if (
      this.preferredDevices.length === 1 &&
      this.preferredDevices[0] === preferredDevices.ios
    ) {
      predicates.push(hasiOSSessionsPredicate);
    }
    return predicates;
  }

  editorConfig() {
    let container = getOwner(this);
    return postEditConfigGenerator({ container });
  }

  get isTextReply() {
    return this.replyType === replyTypeOptions.text;
  }

  get isReactionsReply() {
    return this.replyType === replyTypeOptions.reaction;
  }

  get isDisabledReply() {
    return this.replyType === replyTypeOptions.disabled;
  }

  get isSmall() {
    return this.format === formatOptions.small;
  }

  get readableFormat() {
    return this.isSmall ? 'small' : 'large';
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this.localizedPostContents.forEach((content) => content.rollbackAttributes());
  }
}
