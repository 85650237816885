/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import SenderEmailAddressSummary, {
  type SenderEmailAddressSummaryWireFormat,
} from 'embercom/objects/inbox/sender-email-address-summary';
import { EntityType } from 'embercom/models/data/entity-types';
import { OutboundInitialPartSenderType } from 'embercom/objects/inbox/outbound-initial-part-sender-types';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';
import { tracked } from '@glimmer/tracking';
import { type Block } from 'embercom/models/common/blocks/block';
import { type Attachment } from '@intercom/interblocks.ts';

export type SenderSummary = AdminSummary | SenderEmailAddressSummary;
type SenderSummaryWireFormat = AdminSummaryWireFormat | SenderEmailAddressSummaryWireFormat;
interface EmailWireFormat {
  renderable_type: RenderableType;
  sender_summary: SenderSummaryWireFormat;
  sender_type: OutboundInitialPartSenderType;
  blocks: Array<Block>;
  tags: Array<TagWireFormat>;
  subject?: string;
  ruleset_id?: number;
  selection_set_id?: number;
  creator_summary?: AdminSummaryWireFormat;
  admin_summary?: AdminSummaryWireFormat;
  deleted_at?: Date;
}

interface Content {
  type: string;
  attachments: Attachment[];
}

interface Cell {
  content: Content[];
}

interface Row {
  cells: Cell[];
}

export default class Email implements RenderableData {
  readonly renderableType = RenderableType.Email;
  readonly senderSummary: SenderSummary;
  readonly senderType: OutboundInitialPartSenderType;
  readonly blocks: Array<Block>;
  @tracked tags: Array<Tag>;
  readonly subject?: string;
  readonly rulesetId?: number;
  readonly selectionSetId?: number;
  readonly creatorSummary?: AdminSummary;
  readonly adminSummary?: AdminSummary;
  readonly deletedAt?: Date;

  get teammate() {
    if (this.senderType === OutboundInitialPartSenderType.Admin) {
      return this.senderSummary;
    } else {
      return;
    }
  }

  get senderEmailAddress() {
    if (this.senderType === OutboundInitialPartSenderType.CustomEmailAddress) {
      return this.senderSummary;
    } else {
      return;
    }
  }

  get styles() {
    return {
      backgroundColor: 'bg-sky dark:bg-dm-blue-dark',
      textColor: 'text-blue-darkest dark:text-dm-white',
      backgroundColorHover: 'bg-sky-dark dark:bg-blue-darkest',
    };
  }

  get creatingEntity() {
    if (this.senderEmailAddress) {
      return {
        type: EntityType.SenderEmailAddress,
        id: this.senderSummary.id,
        object: this.senderEmailAddress,
      };
    } else {
      return { type: EntityType.Admin, id: this.senderSummary.id, object: this.teammate };
    }
  }

  constructor(
    blocks: Array<Block>,
    senderSummary: SenderSummary,
    senderType: OutboundInitialPartSenderType,
    subject?: string,
    tags?: Array<Tag>,
    rulesetId?: number,
    selectionSetId?: number,
    creatorSummary?: AdminSummary,
    adminSummary?: AdminSummary,
    deletedAt?: Date,
  ) {
    this.blocks = blocks;
    this.subject = subject;
    this.senderSummary = senderSummary;
    this.senderType = senderType;
    this.rulesetId = rulesetId;
    this.selectionSetId = selectionSetId;
    this.creatorSummary = creatorSummary;
    this.tags = tags || [];
    this.adminSummary = adminSummary;
    this.deletedAt = deletedAt;
  }

  static deserialize(json: EmailWireFormat): Email {
    let senderSummary;
    if (json.sender_type === OutboundInitialPartSenderType.CustomEmailAddress) {
      senderSummary = SenderEmailAddressSummary.deserialize(
        json.sender_summary as SenderEmailAddressSummaryWireFormat,
      );
    } else {
      senderSummary = AdminSummary.deserialize(json.sender_summary as AdminSummaryWireFormat);
    }

    let creatorSummary;
    if (json.creator_summary) {
      creatorSummary = AdminSummary.deserialize(json.creator_summary);
    }
    let adminSummary;
    if (json.admin_summary) {
      adminSummary = AdminSummary.deserialize(json.admin_summary);
    }

    return new Email(
      Email.blocksWithAttachments(json.blocks),
      senderSummary,
      json.sender_type,
      json.subject,
      json.tags && json.tags.map(Tag.deserialize),
      json.ruleset_id,
      json.selection_set_id,
      creatorSummary,
      adminSummary,
      json.deleted_at,
    );
  }

  // Some older outbound support emails are wrapped in a table, with the attachmentList nested inside
  // We need to extract the attachmentList and add it as a top level block
  // https://github.com/intercom/intercom/issues/263926
  static blocksWithAttachments(blocks: Array<Block>): Array<Block> {
    let attachments: Attachment[] = [];
    let tables = blocks.filter((block) => block.type === 'table');

    if (tables.length) {
      try {
        tables.forEach((table: Block) => {
          table.rows.forEach((row: Row) => {
            row.cells.forEach((cell: Cell) => {
              cell.content.forEach((content: Content) => {
                if (content.type === 'attachmentList') {
                  attachments = attachments.concat(content.attachments);
                }
              });
            });
          });
        });
      } catch (e) {
        // no-op
      }
    }

    if (attachments.length) {
      return [...blocks, { type: 'attachmentList', attachments }];
    } else {
      return blocks;
    }
  }
}
