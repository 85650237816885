/* RESPONSIBLE TEAM: team-purchase-experience */
export enum IntershopAddOnMediaAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IntershopAddOnSectionHorizontalMedia {
  url: string;
  side: IntershopAddOnMediaAlignment;
}

export interface IntershopAddOnHeadline {
  heading?: Array<string>;
  description?: Array<string>;
  backgroundColour: string;
}

export interface IntershopAddOnSectionHorizontal {
  heading?: string;
  description: {
    text?: string;
    list?: Array<string>;
  };
  subHeading?: string;
  subDescription?: string;
  image?: IntershopAddOnSectionHorizontalMedia;
  video?: IntershopAddOnSectionHorizontalMedia;
}

export interface IntershopAddOnSectionVertical {
  heading?: string;
  description?: string;
  image: {
    url: string;
  };
}

export interface IntershopAddOnFooter {
  heading: string;
  description?: string;
  backgroundColour: string;
}

interface BaseIntershopAddOn {
  headline?: IntershopAddOnHeadline;
  horizontal?: Array<IntershopAddOnSectionHorizontal>;
  vertical?: Array<IntershopAddOnSectionVertical>;
  footer?: IntershopAddOnFooter;
}

export interface IntershopAddOnData extends BaseIntershopAddOn {
  example?: {
    id: string;
    action: 'startSurvey' | 'startTour'; // TODO(smg): this could be an enum
  };
  allowedPricingMetricsCharge?: Array<string>;
  customTooltipContent?: {
    text: string;
    subtext: string;
    link: {
      name: string;
      path: string;
    };
  };
}

export interface IntershopAddonCardData {
  landingPageDescription: string;
  addonProductPageDescription: string;
  addonProductPageSubDescription?: string;
  icon: string | null;
}
