/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';

export default Model.extend({
  auto_seat_assignment_done: attr('boolean', { defaultValue: false }),
  manually_assigned_seat: attr('boolean', { defaultValue: false }),
  billing_activated: attr('boolean', { defaultValue: false }),
  fallback_admins_assigned: attr('boolean', { defaultValue: false }),
  date_migration_complete: attr('date'),
  grandfathered: attr('boolean', { defaultValue: false }),
});
