/* RESPONSIBLE TEAM: team-phone */
import Model, { attr } from '@ember-data/model';
import {
  phoneTypes,
  phoneTypesNames,
  VERIFICATION_STATUS,
} from 'embercom/models/data/sms/constants';
import {
  TOLL_FREE_VERIFICATION_COUNTRY_LIST,
  COUNTRIES_REQUIRING_REGULATORY_BUNDLES,
} from 'embercom/components/outbound/sms/onboarding/country-data';

export default class PhoneNumber extends Model {
  @attr('string') name;
  @attr('string') number;
  @attr('string') countryCode;
  @attr('number') phoneType;
  @attr('string') verificationStatus;
  @attr('string') verificationFormSubmittedOn;
  @attr('boolean') verificationCreatedManually;
  @attr('number') verificationRejectionCode;
  @attr('string') regulatoryBundleSid;

  get phoneTypeName() {
    return phoneTypesNames[this.phoneType];
  }

  get tollFreeVerificationRequired() {
    return (
      (!this.verificationStatus || this.verificationStatus === VERIFICATION_STATUS.DRAFT) &&
      this.phoneType === phoneTypes.tollFree &&
      TOLL_FREE_VERIFICATION_COUNTRY_LIST.includes(this.countryCode)
    );
  }

  get regulatoryBundleVerificationRequired() {
    return (
      this.phoneType === phoneTypes.mobile &&
      COUNTRIES_REQUIRING_REGULATORY_BUNDLES.includes(this.countryCode) &&
      !this.regulatoryBundleSid
    );
  }
}
