/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import ChecklistContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/checklist/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import SeriesSchedulePanelConfiguration from 'embercom/objects/content-editor/panels/schedule/series-schedule-panel-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';
import ChecklistSharingPanelConfiguration from 'embercom/objects/content-editor/panels/custom/checklist-sharing/panel-configuration';
import ChecklistAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/content/checklist/audience-editor-configuration';
import ChecklistAudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/content/checklist/audience-header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';

export default function generateEditModeConfig(options) {
  let container = options.container;

  let audienceEditorConfiguration = new ChecklistAudienceEditorConfiguration({
    container,
  });

  audienceEditorConfiguration.footer = ComponentConfiguration.create({
    component: 'content-editor/panels/audience/content/checklist/footer',
    config: audienceEditorConfiguration,
  });

  let editorPanelConfigs = [
    ChecklistContentPanelConfiguration.create({ container }),
    StandardAudiencePanelConfiguration.create({
      audienceHeaderConfiguration: ChecklistAudienceHeaderConfiguration.create({ container }),
      audienceEditorConfiguration,
      container,
    }),
    ChecklistSharingPanelConfiguration.create({ container }),
    StandardSchedulePanelConfiguration.create({
      container,
    }),
    StandardGoalPanelConfiguration.create({
      introText: 'Understand how your checklists drive people to take action in your product.',
      container,
    }),
    SeriesSchedulePanelConfiguration.create({ container }),
  ];

  return EditModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesModeHeaderConfiguration.create({ container }),
    headerComponentConfigs: [
      StandardEditModeHeaderConfiguration.create({
        container,
      }),
    ],
    editorPanelConfigs,
    footerComponentConfigs: [],
  });
}
