/* RESPONSIBLE TEAM: team-tickets-1 */
import { tracked } from '@glimmer/tracking';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { type ComposerPaneStyles, type ReplyChannel } from 'embercom/objects/inbox/composer-pane';
import type ComposerPane from 'embercom/objects/inbox/composer-pane';
import { ChatChannel, ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import type InboxState from 'embercom/services/inbox-state';
import { inject as service } from '@ember/service';
import type Macro from 'embercom/objects/inbox/macro';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';

export default class BulkReplyPane implements ComposerPane {
  @service declare inboxState: InboxState;

  readonly type: ComposerPaneType = ComposerPaneType.Reply;
  readonly hotkeyId = HotkeyID.ComposeReply;

  @tracked channel: ReplyChannel = ChatChannel;
  @tracked blocksDoc: BlocksDocument;
  @tracked macroActions: Macro['actions'] = [];

  constructor() {
    this.blocksDoc = new BlocksDocument([]);
  }

  get styles(): ComposerPaneStyles {
    return {
      container:
        'bg-white dark:bg-dm-black border border-gray-lightest dark:border-dm-gray-darker dark:shadow-dm-sm',
    };
  }

  get icon() {
    return 'chat-filled' as const;
  }

  get nameTranslationKey(): string {
    return 'inbox.composer.panes.bulk_reply';
  }

  clear() {
    this.blocksDoc = new BlocksDocument([]);
    this.macroActions = [];
  }
}
