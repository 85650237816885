/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { assert } from '@ember/debug';
import { isArray } from '@ember/array';

export default EmberObject.extend({
  tabComponentConfigs: undefined,
  fallbackComponentConfig: undefined, //Optional

  init(inputs) {
    this._super(...arguments);
    assert(
      'An array must be passed for the tab component configs',
      isArray(inputs.tabComponentConfigs),
    );

    inputs.tabComponentConfigs.setEach('parentConfiguration', this);

    if (inputs.fallbackComponentConfig) {
      inputs.fallbackComponentConfig.parentConfiguration = this;
    }

    this.setProperties({
      tabComponentConfigs: inputs.tabComponentConfigs,
      fallbackComponentConfig: inputs.fallbackComponentConfig,
    });
  },
});
