/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */

class AvatarSummaryWireFormat {
  readonly initials?: string;
}

class AvatarSummary {
  readonly initials?: string;

  constructor(inputs: { initials?: string }) {
    this.initials = inputs.initials;
  }

  static deserialize(json: AvatarSummaryWireFormat) {
    return new AvatarSummary({ initials: json.initials });
  }

  static serialize(avatar: AvatarSummary): AvatarSummaryWireFormat {
    return {
      initials: avatar?.initials,
    };
  }
}

const REJECTION_REASON_VALUES: any = {
  spam: 'Junk',
  auto_responder: 'Auto-responder',
};

export interface SpamMessageWireFormat {
  readonly id: number;
  readonly name: string | null;
  readonly email: string;
  readonly email_subject: string | null;
  readonly created_at: Date;
  readonly avatar: AvatarSummaryWireFormat;
  readonly rejected_reason: string | null;
}

export class SpamMessage {
  readonly id: number;
  readonly name: string | null;
  readonly email: string;
  readonly emailSubject: string | null;
  readonly createdAt: Date;
  readonly avatar?: AvatarSummary;
  readonly rejectedReason: string | null;

  constructor(inputs: {
    id: number;
    name: string | null;
    email: string;
    emailSubject: string | null;
    createdAt: Date;
    avatar: AvatarSummary;
    rejectedReason: string | null;
  }) {
    this.id = inputs.id;
    this.name = inputs.name;
    this.email = inputs.email;
    this.emailSubject = inputs.emailSubject;
    this.createdAt = inputs.createdAt;
    this.avatar = inputs.avatar;
    this.rejectedReason = inputs.rejectedReason;
  }

  get rejectionReasonLabel() {
    if (!this.rejectedReason) {
      return '-';
    }
    return REJECTION_REASON_VALUES[this.rejectedReason] || '-';
  }

  static deserialize(json: SpamMessageWireFormat) {
    return new SpamMessage({
      id: json.id,
      name: json.name,
      email: json.email,
      emailSubject: json.email_subject,
      createdAt: json.created_at,
      avatar: json.avatar,
      rejectedReason: json.rejected_reason,
    });
  }

  static serialize(spamMessage: SpamMessage): SpamMessageWireFormat {
    let avatar = spamMessage.avatar
      ? AvatarSummary.serialize(spamMessage.avatar)
      : new AvatarSummary({});
    return {
      id: spamMessage.id,
      name: spamMessage.name,
      email: spamMessage.email,
      email_subject: spamMessage.emailSubject,
      created_at: spamMessage.createdAt,
      avatar,
      rejected_reason: spamMessage.rejectedReason,
    };
  }
}

export interface SpamMetaWireFormat {
  readonly page: number;
  readonly total_pages: number;
}

export class SpamMeta {
  readonly page: number;
  readonly totalPages: number;

  constructor(inputs: { page: number; totalPages: number }) {
    this.page = inputs.page;
    this.totalPages = inputs.totalPages;
  }

  static deserialize(json: SpamMetaWireFormat) {
    return new SpamMeta({ page: json.page, totalPages: json.total_pages });
  }
}

export interface InboundEmailWireFormat {
  readonly id: number;
  readonly to: string;
  readonly reply_to: string;
  readonly from: string;
  readonly cc: string;
  readonly bcc: string;
  readonly subject: string;
  readonly date: Date;
  readonly plain_text: string;
  readonly html: string;
}

export class InboundEmail {
  readonly id: number;
  readonly to: string;
  readonly replyTo: string;
  readonly from: string;
  readonly cc: string;
  readonly bcc: string;
  readonly subject: string;
  readonly date: Date;
  readonly plainText: string;
  readonly html: string;

  constructor(inputs: {
    id: number;
    to: string;
    replyTo: string;
    from: string;
    cc: string;
    bcc: string;
    subject: string;
    date: Date;
    plainText: string;
    html: string;
  }) {
    this.id = inputs.id;
    this.to = inputs.to;
    this.replyTo = inputs.replyTo;
    this.from = inputs.from;
    this.cc = inputs.cc;
    this.bcc = inputs.bcc;
    this.subject = inputs.subject;
    this.date = inputs.date;
    this.plainText = inputs.plainText;
    this.html = inputs.html;
  }

  static deserialize(json: InboundEmailWireFormat) {
    return new InboundEmail({
      id: json.id,
      to: json.to,
      replyTo: json.reply_to,
      from: json.from,
      cc: json.cc,
      bcc: json.bcc,
      subject: json.subject,
      date: json.date,
      plainText: json.plain_text,
      html: json.html,
    });
  }
}
