/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import { EntityType } from 'embercom/models/data/entity-types';

export type AddingEntity = AdminSummary | UserSummary;
export type AddingEntityType = EntityType.Admin | EntityType.User;
type AddingEntityWireFormat = AdminSummaryWireFormat | UserSummaryWireFormat;
interface ParticipantAddedWireFormat {
  renderable_type: RenderableType;
  participant_summary: UserSummaryWireFormat;
  adding_entity: AddingEntityWireFormat;
  adding_entity_type: AddingEntityType;
}

export default class ParticipantAdded implements RenderableData {
  readonly renderableType = RenderableType.ParticipantAdded;
  readonly participantSummary: UserSummary;
  readonly addingEntity: AddingEntity;
  readonly addingEntityType: AddingEntityType;

  get creatingEntity() {
    return { type: this.addingEntityType, id: this.addingEntity.id, object: this.addingEntity };
  }

  constructor(
    participantSummary: UserSummary,
    addingEntity: AddingEntity,
    addingEntityType: AddingEntityType,
  ) {
    this.participantSummary = participantSummary;
    this.addingEntity = addingEntity;
    this.addingEntityType = addingEntityType;
  }

  static deserialize(json: ParticipantAddedWireFormat): ParticipantAdded {
    let addingEntity;
    if (json.adding_entity_type === EntityType.User) {
      addingEntity = UserSummary.deserialize(json.adding_entity as UserSummaryWireFormat);
    } else {
      addingEntity = AdminSummary.deserialize(json.adding_entity as AdminSummaryWireFormat);
    }

    let participantSummary = UserSummary.deserialize(json.participant_summary);

    return new ParticipantAdded(participantSummary, addingEntity, json.adding_entity_type);
  }
}
