/* RESPONSIBLE TEAM: team-help-desk-experience */

import { TrackedArray, tracked } from 'tracked-built-ins';
import { type BlockList } from '@intercom/interblocks.ts';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { EntityType } from 'embercom/models/data/entity-types';
import { type ArticleContentWireFormat } from './article';
import { type ConversationSummaryWireFormat } from './conversation-summary';
import { type KnowledgeBaseContentWireFormat } from './knowledge-base/content';
import { MacroAction, type MacroActionWireFormat } from './macro';

export enum InboxAssistantConversationPartStatus {
  success = 'success',
  could_not_find_answer = 'could_not_find_answer',
  error = 'error',
}

export type InboxAssistantConversationPartWireFormat = {
  id: number;
  inbox_conversation_id?: number;
  author_type: 'assistant' | 'teammate';
  admin_summary?: AdminSummaryWireFormat;
  content: BlockList;
  metadata: Record<string, unknown> | null;
  sources: FinSource[] | null;
  status: InboxAssistantConversationPartStatus;
  related_content: FinSource[] | null;

  // relevant only for no answers
  fallback_content: BlockList | null;
  fallback_related_content: FinSource[] | null;
  fallback_question: string | null;

  macro_actions?: MacroActionWireFormat[];

  created_at?: Date | null;
};

export type InboxAssistantConversationWireFormat = {
  id: number;
  inbox_conversation_id?: number;
  parts: InboxAssistantConversationPartWireFormat[];
};

export type BaseSource = {
  entity_type: EntityType;
  entity_id: number;
};

export type ArticleSource = BaseSource & {
  entity_type: EntityType.ArticleContent;
  preview?: {
    blocks?: BlockList[];
    blocks_indices?: [number, number][];
    passage_ids?: string[];
  };
  data: NonNullable<
    Pick<
      ArticleContentWireFormat,
      | 'id'
      | 'title'
      | 'last_edited_at'
      | 'article_id'
      | 'summary'
      | 'public_url'
      | 'article_preview'
    >
  > & {
    author: Pick<ArticleContentWireFormat['author'], 'id' | 'name'>;
  };
};

export type PastConversationSnippetSource = BaseSource & {
  entity_type: EntityType.PastConversationSnippet;
  data: Pick<ConversationSummaryWireFormat, 'id' | 'last_updated' | 'title'> & {
    admin_assignee: Pick<
      NonNullable<ConversationSummaryWireFormat['admin_assignee']>,
      'id' | 'name' | 'image_url' | 'is_operator'
    >;
    passage: string;
  };
};

export type ConversationExcerptSource = BaseSource & {
  entity_type: EntityType.ConversationExcerpt;
  data: Pick<ConversationSummaryWireFormat, 'id' | 'last_updated' | 'title'> & {
    conversation_part_selectors: string[];
    admin_assignee: Pick<
      NonNullable<ConversationSummaryWireFormat['admin_assignee']>,
      'id' | 'name' | 'image_url' | 'is_operator'
    >;
    source_preview: BlockList;
  };
};

export type ContentSnippetSource = BaseSource & {
  entity_type: EntityType.ContentSnippet;
  data: {
    author: Pick<AdminSummaryWireFormat, 'id' | 'name'>;
    updated_at: string;
    title: string;
    content: BlockList;
  };
};

export type InternalArticleSource = BaseSource & {
  entity_type: EntityType.InternalArticle;
  preview?: {
    blocks?: BlockList[];
  };
  data: NonNullable<
    Pick<KnowledgeBaseContentWireFormat, 'id' | 'title' | 'author_summary' | 'updated_at'> & {
      article_preview: KnowledgeBaseContentWireFormat['blocks'];
    }
  >;
};

export type SlackThreadSource = BaseSource & {
  entity_type: EntityType.SlackThread;
  data: {
    id: number;
    updated_at: string;
    title: string;
    source_preview: BlockList;
    source_url: string;
  };
};

export type FileSourceContentSource = BaseSource & {
  entity_type: EntityType.FileSourceContent;
  data: {
    id: number;
    title: string;
    content: string;
    source_preview: BlockList;
    updated_at: string;
    author_summary: Pick<AdminSummaryWireFormat, 'id' | 'name' | 'image_url' | 'is_operator'>;
  };
};

export type ExternalContentSource = BaseSource & {
  entity_type: EntityType.ExternalContent;
  data: {
    id: number;
    title: string;
    content: string;
    source_preview: BlockList;
    updated_at: string;
    author_summary: Pick<AdminSummaryWireFormat, 'id' | 'name' | 'image_url' | 'is_operator'>;
    source_url: string;
  };
};

export type SavedReplySource = BaseSource & {
  entity_type: EntityType.SavedReply;
  preview?: {
    blocks?: BlockList[];
  };
  data: {
    id: number;
    title: string;
    updated_at: string;
    author: Pick<AdminSummaryWireFormat, 'id' | 'name' | 'image_url'>;
    content: BlockList;
  };
};

// Slack is deprecated as a filterable source (issue #345438)
// but we still need to support it for existing answers
export type FinSource =
  | ArticleSource
  | PastConversationSnippetSource
  | ConversationExcerptSource
  | ContentSnippetSource
  | InternalArticleSource
  | SlackThreadSource
  | FileSourceContentSource
  | ExternalContentSource
  | SavedReplySource;

export function sourcesType(
  sources?: { entity_type: EntityType }[],
): 'some_internal_sources' | 'all_public_sources' | 'unknown' {
  if (!sources || sources.length === 0) {
    return 'unknown';
  }

  if (
    sources?.some(
      (source) =>
        source.entity_type === EntityType.InternalArticle ||
        source.entity_type === EntityType.SlackThread,
    )
  ) {
    return 'some_internal_sources';
  }

  return 'all_public_sources';
}

type InboxAssistantConversationPartMetadata = {
  abtest_assignments?: Record<string, string>;
  client_assigned_uuid?: string;
  [key: string]: unknown;
};

export class InboxAssistantConversationPart {
  id: number | string;
  inboxConversationId?: number;

  @tracked authorType: 'assistant' | 'teammate';
  @tracked adminSummary?: AdminSummary;
  @tracked content: BlockList;
  @tracked metadata?: InboxAssistantConversationPartMetadata;
  @tracked sources?: FinSource[];
  @tracked sourcesType: ReturnType<typeof sourcesType> = 'unknown';
  @tracked status: InboxAssistantConversationPartStatus;
  @tracked relatedContent?: FinSource[];
  @tracked fallbackContent?: BlockList;
  @tracked fallbackRelatedContent?: FinSource[];
  @tracked fallbackQuestion?: string;
  @tracked createdAt?: Date;

  @tracked macroActions?: MacroAction[];

  constructor(
    id: number | string,
    authorType: 'assistant' | 'teammate',
    content: BlockList,
    inboxConversationId?: number,
    metadata?: Record<string, unknown>,
    sources?: FinSource[],
    adminSummary?: AdminSummary,
    status?: InboxAssistantConversationPartStatus,
    relatedContent?: FinSource[],
    fallbackContent?: BlockList,
    fallbackRelatedContent?: FinSource[],
    fallbackQuestion?: string,
    createdAt?: Date,
    macroActions?: MacroAction[],
  ) {
    this.id = id;
    this.inboxConversationId = inboxConversationId;
    this.authorType = authorType;
    this.metadata = metadata;
    this.sources = sources;
    this.sourcesType = sourcesType(sources);
    this.content = content;
    this.adminSummary = adminSummary;
    this.status = status ?? InboxAssistantConversationPartStatus.success;
    this.relatedContent = relatedContent;
    this.createdAt = createdAt;
    this.fallbackContent = fallbackContent;
    this.fallbackRelatedContent = fallbackRelatedContent;
    this.fallbackQuestion = fallbackQuestion;
    this.macroActions = macroActions;
  }

  get persistedId() {
    // While a record is in flight, we store a UUID for its ID.
    return typeof this.id === 'number' ? this.id : undefined;
  }

  get isPersisted() {
    return !!this.persistedId;
  }

  get transactionId() {
    return this.metadata?.answer_bot_transaction_id as string;
  }

  get isFromAssistant() {
    return this.authorType === 'assistant';
  }

  get isSuccess() {
    return this.status === InboxAssistantConversationPartStatus.success;
  }

  get isCouldNotFindAnswer() {
    return this.status === InboxAssistantConversationPartStatus.could_not_find_answer;
  }

  get isFallbackAnswer() {
    return this.isCouldNotFindAnswer && !!this.fallbackContent;
  }

  get isMacroAnswer() {
    return Boolean(this.metadata?.macro_id);
  }

  get macroData() {
    if (!this.isMacroAnswer) {
      return undefined;
    }

    let macroAnswerSource =
      this.sources &&
      this.sources.filter(
        (source) =>
          source.entity_type === EntityType.SavedReply &&
          source.entity_id === this.metadata?.macro_id,
      )[0];

    return macroAnswerSource?.data;
  }

  get isError() {
    return this.status === InboxAssistantConversationPartStatus.error;
  }

  get hasSources() {
    return this.sources && this.sources.length > 0;
  }

  get hasRelatedContent() {
    return this.relatedContent && this.relatedContent.length > 0;
  }

  get hasCopilotIngestedAnyContent() {
    return this.metadata?.has_app_ingested_content !== false;
  }

  get canSend() {
    return this.isSuccess || this.isFallbackAnswer;
  }

  get flattenedAbTestAssignments() {
    let abTestAssignments = this.metadata?.abtest_assignments ?? {};

    return Object.keys(abTestAssignments).reduce(
      (acc, key) => {
        acc[`abtest_assignments.${key}`] = abTestAssignments[key];
        return acc;
      },
      {} as Record<string, string>,
    );
  }

  static deserialize(json: InboxAssistantConversationPartWireFormat) {
    return new InboxAssistantConversationPart(
      json.id,
      json.author_type,
      json.content,
      json.inbox_conversation_id,
      json.metadata ?? undefined,
      json.sources ?? undefined,
      json.admin_summary ? AdminSummary.deserialize(json.admin_summary) : undefined,
      json.status,
      json.related_content ?? undefined,
      json.fallback_content ?? undefined,
      json.fallback_related_content ?? undefined,
      json.fallback_question ?? undefined,
      json.created_at ? new Date(json.created_at) : undefined,
      json.macro_actions?.map(MacroAction.deserialize),
    );
  }

  static missingResponsePartPlaceholder() {
    return new InboxAssistantConversationPart(
      -1,
      'assistant',
      [],
      -1,
      undefined,
      undefined,
      undefined,
      InboxAssistantConversationPartStatus.error,
    );
  }
}

export class InboxAssistantConversation {
  id: number;
  inboxConversationId?: number;

  parts = new TrackedArray<InboxAssistantConversationPart>();

  constructor(id: number, parts: InboxAssistantConversationPart[], inbox_conversation_id?: number) {
    this.id = id;
    this.parts = new TrackedArray(parts);
    this.inboxConversationId = inbox_conversation_id;
  }

  get lastPart() {
    return this.parts[this.parts.length - 1];
  }

  get successfulAnswers(): InboxAssistantConversationPart[] {
    return this.parts.filter((part) => part.isSuccess && part.isFromAssistant);
  }

  get firstSuccessfulAnswer(): InboxAssistantConversationPart | undefined {
    return this.parts.find((part) => part.isSuccess && part.isFromAssistant);
  }

  addTeammatePart(admin: AdminSummary, content: BlockList) {
    this.parts.pushObject(
      new InboxAssistantConversationPart(
        0,
        'teammate',
        content,
        this.inboxConversationId,
        undefined,
        undefined,
        admin,
      ),
    );
  }

  /**
   * @deprecated This method is deprecated. Please use findOrCreateAssistantPart instead.
   */
  addOrUpdateAssistantPart(
    id: string,
    content: BlockList,
    status?: InboxAssistantConversationPartStatus,
    relatedContent?: FinSource[],
  ) {
    let existingPart = this.parts.find((part) => part.id === id);
    if (existingPart) {
      existingPart.content = content;
      return;
    }

    this.parts.pushObject(
      new InboxAssistantConversationPart(
        id,
        'assistant',
        content,
        this.inboxConversationId,
        undefined,
        undefined,
        undefined,
        status,
        relatedContent,
      ),
    );
  }

  findOrCreateAssistantPart(id: string) {
    let existingPart = this.parts.find((part) => part.id === id);
    if (existingPart) {
      return existingPart;
    }

    let part = new InboxAssistantConversationPart(id, 'assistant', [], this.inboxConversationId);
    this.parts.pushObject(part);
    return part;
  }

  static deserialize(json: InboxAssistantConversationWireFormat) {
    return new InboxAssistantConversation(
      json.id,
      json.parts.map(InboxAssistantConversationPart.deserialize),
      json.inbox_conversation_id,
    );
  }
}
