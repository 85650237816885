/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import { getEmberDataStore, getAttributeService } from 'embercom/lib/container-lookup';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import rawValueToFilterValue from 'embercom/lib/users/user-company-list/raw-value-to-filter-value';
import safeWindowOpen from 'embercom/lib/safe-window-open';

let FilterLinkBuilder = EmberObject.extend({
  attributeIdentifier: undefined,
  isCompany: undefined,
  isUrl: undefined,
  id: undefined,

  userOrCompany: computed('isCompany', 'id', function () {
    let modelName = this.isCompany ? 'company' : 'user';
    return getEmberDataStore().peekRecord(modelName, this.id);
  }),
  app: computed(function () {
    return getEmberDataStore().peekRecord('app', this.get('userOrCompany.app_id'));
  }),
  attributeService: computed(function () {
    return getAttributeService();
  }),

  attribute: computed('attributeIdentifier', function () {
    return this.get('attributeService.attributes').findBy('identifier', this.attributeIdentifier);
  }),

  rawValue: computed('userOrCompany', function () {
    let lookupKey = this.lookupKey;
    if (this.isCompany && lookupKey === 'tag_ids') {
      lookupKey = 'segment_ids';
    }
    let rawValue = this.get(`userOrCompany.${lookupKey}`);
    return rawValueToFilterValue(this.attributeIdentifier, rawValue);
  }),

  redirectUrl: computed('userOrCompany', 'attribute', 'rawValue', function () {
    return B64FilterParamEncoder.filterUrl(this.userOrCompany, this.attribute, this.rawValue);
  }),

  redirectToFilterUrl(inNewWindow) {
    if (this.isUrl) {
      safeWindowOpen(this.rawValue);
    } else if (inNewWindow) {
      safeWindowOpen(this.redirectUrl);
    } else {
      FilterLinkBuilder.redirectTo(this.redirectUrl);
    }
  },
}).reopenClass({
  isCompanyKey: 'is-company',
  attributeIdentifierKey: 'attribute-identifier',
  userOrCompanylookupKey: 'lookup-key',
  anonymousKey: 'anonymous',
  idKey: 'id',
  urlKey: 'is-url',
  redirectToUrl($dataElement, metaKeyPressed) {
    FilterLinkBuilder.create({
      isUrl: $dataElement.data(FilterLinkBuilder.urlKey),
      isCompany: $dataElement.data(FilterLinkBuilder.isCompanyKey),
      attributeIdentifier: $dataElement.data(FilterLinkBuilder.attributeIdentifierKey),
      lookupKey: $dataElement.data(FilterLinkBuilder.userOrCompanylookupKey),
      anonymous: $dataElement.data(FilterLinkBuilder.anonymousKey),
      id: $dataElement.data(FilterLinkBuilder.idKey),
    }).redirectToFilterUrl(metaKeyPressed);
  },
  redirectTo(url) {
    window.location = url;
  },
});

export default FilterLinkBuilder;
