/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  address: attr('string'),
  appId: attr('string'),
  available: attr('number'),
  capacity: attr('number'),
});
