/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { selectFromObject, ternary, valueOrDefault } from '@intercom/pulse/lib/computed-properties';
import { computed } from '@ember/object';

export default Mixin.create({
  _channelIcons: { Email: 'email', 'In-App': 'conversation', Push: 'push-notification' },
  channelIcon: selectFromObject('_channelIcons', 'type'),

  //Should deprecate in favour of using `selectionState.userTypes` everywhere
  userTypeLabel: ternary('isTargetingLeads', 'lead', 'user'),
  pluralUserTypeLabel: computed('userTypeLabel', function () {
    return `${this.userTypeLabel}s`;
  }),

  uiTitle: valueOrDefault('title', 'Untitled Message'),

  displayableClassType: computed('isVisitorAutoMessage', 'classType', function () {
    if (this.isVisitorAutoMessage) {
      return 'visitor auto';
    } else {
      return this.classType;
    }
  }),
});
