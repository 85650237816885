/* RESPONSIBLE TEAM: team-workflows */
import type Model from '@ember-data/model';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { inject as service } from '@ember/service';
import { type TaskGenerator } from 'ember-concurrency';
import { setOwner } from '@ember/application';
import type { BotConfigTarget } from '../configuration';
import { CONVERSATION_STARTED_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { underscore } from '@ember/string';

const SORT_BY_PRIORITY = 'priority';
const SORT_DIRECTION_ASC = 'asc';
const MAX_OPEN_COUNT_FOR_AUTOMATIC_OPEN = 5;

export const PAGE_SIZE = 25;

export default class CustomBotListData {
  @service outboundHomeService: any;

  appId;
  target;
  objectType;
  currentPage = 0;

  @tracked bots: any[] = [];
  @tracked totalCount = 0;
  @tracked isOpen = window.sessionStorage.getItem(`${this._uniqueIdentifier}-isOpen`) === 'true';
  @tracked title: string | null = null;

  constructor(owner: string, appId: string, target: BotConfigTarget, objectType: number) {
    setOwner(this, owner);
    this.appId = appId;
    this.target = target;
    this.objectType = objectType;
  }

  async contentSearch(pageFrom: number): Promise<object> {
    // If target channel filter is applied we only list bots that support Omnichannel
    if (
      this.outboundHomeService.additionalSearchableData?.targetChannels &&
      this.outboundHomeService.additionalSearchableData.targetChannels.length > 0 &&
      this.target !== CONVERSATION_STARTED_TARGET
    ) {
      return {
        totalCount: 0,
        contentWrappers: [],
      };
    }

    let response = await this.outboundHomeService.contentSearchWithFilters(
      this._getSearchParams(pageFrom),
    );
    let serializedResponse = JSON.stringify({
      ...response,
      contentWrappers: response.contentWrappers.map((wrapper: Model) => wrapper.serialize()),
    });

    // Write the repsonse to the cache
    window.sessionStorage.setItem(this._responseKey(pageFrom), serializedResponse);

    return response;
  }

  @task({ restartable: true })
  *setupInitialState() {
    // Reconstruct the overview page state from the cache
    // Tries to load the data for all previously loaded pages
    let bots: any[] = [];
    let totalCount = 0;
    let currentPage = 0;
    let cachedResponse = JSON.parse(
      `${window.sessionStorage.getItem(this._responseKey(currentPage))}`,
    );
    while (cachedResponse) {
      bots = [...bots, ...cachedResponse.contentWrappers];
      totalCount = cachedResponse.totalCount;
      currentPage++;
      cachedResponse = JSON.parse(
        `${window.sessionStorage.getItem(this._responseKey(currentPage))}`,
      );
    }
    this.bots = this.outboundHomeService._contentWrapperModels(bots);
    this.totalCount = totalCount;
    this.currentPage = Math.max(0, currentPage - 1);
    // Only want to try and open the section if we have data
    if (this._hasCachedData) {
      this._maybeOpenSection();
    }

    // Perform a background reload that ensures we're looking at the most recent data
    yield taskFor(this.reload).perform();
  }

  @task({ drop: true })
  *fetchNextPage(): TaskGenerator<void> {
    this.currentPage++;
    let response = yield this.contentSearch(this.currentPage);
    this.bots = [...this.bots, ...response.contentWrappers];
    this.totalCount = response.totalCount;
  }

  @task({ restartable: true })
  *reload(): TaskGenerator<void> {
    let bots: any[] = [];
    let totalCount = 0;
    for (let page = 0; page <= this.currentPage; page++) {
      let response = yield this.contentSearch(page);
      bots = [...bots, ...response.contentWrappers];
      totalCount = response.totalCount;
    }
    this.bots = bots;
    this.totalCount = totalCount;
    this._maybeOpenSection();
  }

  setOpenState(isOpen: boolean) {
    this.isOpen = isOpen;
    window.sessionStorage.setItem(`${this._uniqueIdentifier}-isOpen`, `${isOpen}`);
  }

  _getSearchParams(pageFrom: number) {
    return {
      object_types: [this.objectType],
      app_id: this.appId,
      per_page: PAGE_SIZE,
      page_from: pageFrom,
      statistics: [
        statisticKeys.receipts,
        statisticKeys.goals,
        statisticKeys.replies,
        statisticKeys.completions,
      ],
      title: this.title,
      additionalSearchableData: {
        // Only send non-null values from additionalSearchableData
        ...this._prepareAdditionalSearchableData(),
        target: this.target,
      },
      sort_by: SORT_BY_PRIORITY,
      sort_direction: SORT_DIRECTION_ASC,
    };
  }

  _hashParams(str: string) {
    let hash = 0;
    for (let character of str) {
      let charCode = character.charCodeAt(0);
      /* eslint-disable no-bitwise */
      hash = (hash << 5) - hash + charCode;
      /* eslint-disable no-bitwise */
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  _maybeOpenSection() {
    if (
      // We don't want to override the user's choice if they've already opened/closed the panel
      !window.sessionStorage.getItem(`${this._uniqueIdentifier}-isOpen`) &&
      this.totalCount <= MAX_OPEN_COUNT_FOR_AUTOMATIC_OPEN
    ) {
      this.setOpenState(true);
    }
  }

  _responseKey(page: number) {
    return `${this._hashParams(JSON.stringify(this._getSearchParams(page)))}`;
  }

  _prepareAdditionalSearchableData() {
    let additionalSearchableData = {} as {
      target_channels?: string[];
      workflow_type?: string;
      parallel?: boolean;
      has_fin?: boolean;
    };

    // Only send non-null values from additionalSearchableData
    if (this.outboundHomeService.additionalSearchableData) {
      Object.keys(this.outboundHomeService.additionalSearchableData).map((key) => {
        let snakeCasedKey = underscore(key) as keyof typeof additionalSearchableData;
        let value = this.outboundHomeService.additionalSearchableData[key];
        if (value !== null) {
          additionalSearchableData[snakeCasedKey] = value;
        }
      });
    }

    return additionalSearchableData;
  }

  get _hasCachedData() {
    return !!window.sessionStorage.getItem(this._responseKey(0));
  }

  get _uniqueIdentifier() {
    return `${this.appId}-${this.target}-${this.objectType}`;
  }

  get loading() {
    // We define loading here as when we are fetching data and have no cached data to show
    return taskFor(this.reload).isRunning && !this._hasCachedData;
  }
}
