/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ParentAction } from './action';
import type Session from 'embercom/services/session';

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'embercom/config/environment';
import { Resource, type Named } from 'ember-resources';
import { registerDestructor } from '@ember/destroyable';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { timeout, type TaskGenerator } from 'ember-concurrency';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { request } from 'embercom/lib/inbox/requests';

export interface GifSearchEntry {
  url: string;
  preview_url: string;
  width: number;
  height: number;
  title: string;
}

interface GifSearchWireFormat {
  next_cursor: string;
  results: Array<GifSearchEntry>;
}

interface GifSearchDataArgs {
  query: string;
  onSearchReturn?: Function;
}

export class GifSearchData extends Resource<Named<GifSearchDataArgs>> {
  @service declare session: Session;
  @tracked results: Array<GifSearchEntry> = [];

  constructor(owner: any, args: Named<GifSearchDataArgs>, previous: GifSearchData) {
    super(owner, args, previous);
    taskFor(this.search).perform();

    if (previous?.results) {
      this.results = previous.results;
    }

    registerDestructor(this, () => {
      taskFor(this.search).cancelAll();
    });
  }

  @task *search(): TaskGenerator<void> {
    let query = this.args.named.query;

    yield timeout(ENV.APP._250MS);

    let params = new URLSearchParams({ app_id: this.session.workspace.id, query });
    let response = (yield request(`/ember/gifs?${params.toString()}`)) as Response;
    let json = (yield response.json()) as GifSearchWireFormat;

    this.results = json.results;
    this.args.named.onSearchReturn?.();
  }
}

export default class GifsAction extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.InsertGif];
  }

  id = 'gifs';
  icon = 'gif' as const;
  paneComponent = 'inbox2/command-k/gifs/pane';
}
