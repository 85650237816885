/* RESPONSIBLE TEAM: team-data-interop */
import Model, { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type AttributeDescriptor from './attribute-descriptor';

export default class InboundTrigger extends Model {
  @service declare appService: any;

  @attr('string') declare name: string;
  @attr('string') declare uuid: string;
  @hasMany('workflow-connector/attribute-descriptor', { async: false })
  declare attributeDescriptors: AttributeDescriptor[];
}
