/* RESPONSIBLE TEAM: team-tickets-1 */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  username: attr('string'),
  apiToken: attr('string'),
  zendeskAppName: attr('string'),
  connected: attr('boolean'),
});
