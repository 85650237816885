/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardAudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import { and, bool, readOnly, union, not, or, filter } from '@ember/object/computed';

export default StandardAudienceHeaderConfiguration.extend({
  _recurringScheduleErrors: filter(
    '_validations.attrs.recurringSchedule.errors',
    (error) => error.type === 'attribute-based-schedule-day-offset',
  ),
  _tour: readOnly('ruleset.rulesetLinks.firstObject.object'),
  _showAutomaticallyEnabled: bool('_tour.clientData.showAutomatically'),
  showAutomaticallyDisabled: not('_showAutomaticallyEnabled'),
  _audienceCompleted: or('showAutomaticallyDisabled', 'ruleset.audienceValid'),

  errors: union(
    '_predicateGroupErrors',
    '_userRolePredicateErrors',
    '_clientPredicateGroupErrors',
    '_recurringScheduleErrors',
  ),
  isComplete: and(
    '_validations.attrs.predicateGroup.isValid',
    '_validations.attrs.userRolePredicate.isValid',
    '_validations.attrs.clientPredicateGroup.isValid',
    '_validations.attrs.recurringSchedule.isValid',
    '_showAutomaticallyEnabled',
    '_audienceCompleted',
  ),

  init(inputs) {
    this._super(...arguments);
    this.title = 'Show your tour automatically';
    this.component = 'content-editor/panels/audience/content/product-tour/header';
  },
});
