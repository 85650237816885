/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';
import { LANGUAGES_RELEASED_TO_EVERYBODY } from 'embercom/lib/resolution-bot-languages';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class AutoReplyLocalizedContent extends Model {
  @attr('string') language;
  @attr('boolean', { readOnly: true }) default;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [
      {
        type: 'paragraph',
        text: '',
        modelKey: 'common/blocks/paragraph',
      },
    ],
  })
  blocks;

  // These aren't part of the core model
  // @attr('boolean', { readOnly: true }) default;
  // @attr('string', { readOnly: true }) defaultSmsContent;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.blocks.any((block) => block.hasDirtyAttributes);
  }

  get languageName() {
    let languages = LANGUAGES_RELEASED_TO_EVERYBODY;
    let match = languages.find(({ value }) => value === this.language);
    return (match && match.text) || this.language;
  }
}
