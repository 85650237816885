/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardPanelHeaderConfiguration from '../standard-panel-header-configuration';
import { inject as service } from '@ember/service';

export default class SeriesScheduleHeaderConfiguration extends StandardPanelHeaderConfiguration {
  @service contentEditorService;

  get errors() {
    return [];
  }

  get isComplete() {
    return true;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get humanReadableObjectName() {
    return this.ruleset.rulesetLinks.firstObject.humanReadableObjectName;
  }

  init(inputs) {
    inputs.component = 'content-editor/panels/schedule/series-header';
    inputs.title = inputs.title || `Scheduling`;
    super.init(...arguments);
  }
}
