/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';

export default class InstagramIntegration extends Model {
  @attr('string') pageId;
  @attr('string') instagramId;
  @attr('string') instagramUsername;

  get analyticsData() {
    return {
      object: 'instagram_integration',
      instagram_integration_id: this.id,
    };
  }

  get displayString() {
    if (isPresent(this.instagramUsername)) {
      return this.instagramUsername;
    } else {
      return `${this.instagramId}`;
    }
  }
}
