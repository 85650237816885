/* RESPONSIBLE TEAM: team-workflows */

import type ConversationRatings from 'embercom/models/operator/visual-builder/step/conversation-ratings';
import StepConfig, { type StepConfigParams } from '../step';

export default class ConversationRatingsConfiguration extends StepConfig {
  declare step: ConversationRatings;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/conversation-ratings',
      ...params,
    });
  }
}
