/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import EmberObject, { computed } from '@ember/object';
import { equal, readOnly } from '@ember/object/computed';
import Admin from 'embercom/models/admin';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import {
  humanReadableObjectNames,
  objectIcons,
  states,
} from 'embercom/models/data/matching-system/matching-constants';
import { selectFromObject } from '@intercom/pulse/lib/computed-properties';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Model.extend({
  appService: service(),
  app: readOnly('appService.app'),
  appId: attr('string'),
  senderId: attr('number'),
  senderType: attr('number'),
  contentId: attr('number'),
  contentType: attr('number'),
  rulesetLinkId: attr('number'),
  insertable: attr('boolean'),
  showAutomatically: attr('boolean'),
  triggerable: attr('boolean'),
  stats: hasMany('outbound/content-statistic'),
  state: attr('number'),
  createdAt: attr('date'),
  wentLiveAt: attr('date'),
  lastUpdatedById: attr('number'),
  updatedAt: attr('date'),
  subscriptionTypeId: attr('number'),
  subtitle: attr('string'),
  contentData: attr({
    defaultValue() {
      return {};
    },
  }),

  hasCustomEmailAddress: equal('senderType', senderTypes.customEmailAddress),
  hasDynamicSender: equal('senderType', senderTypes.owner),

  owner: EmberObject.create({ isDynamicSender: true, displayAs: 'Owner', id: 'owner' }),

  isLive: equal('state', states.live),

  lastUpdatedBy: computed('lastUpdatedById', function () {
    return isPresent(this.lastUpdatedById)
      ? Admin.peekAndMaybeLoad(this.store, this.lastUpdatedById)
      : null;
  }),

  subscriptionType: computed('subscriptionTypeId', function () {
    return isPresent(this.subscriptionTypeId)
      ? this.store.peekRecord('outbound-subscriptions/subscription-type', this.subscriptionTypeId)
      : null;
  }),

  sender: computed('senderType', 'senderId', function () {
    switch (this.senderType) {
      case senderTypes.customEmailAddress:
        return this.store.findRecord('custom-email-address', this.senderId);
      case senderTypes.owner:
        return this.owner;
      default:
        return isPresent(this.senderId) ? Admin.peekAndMaybeLoad(this.store, this.senderId) : null;
    }
  }),

  contentTypeIcons: objectIcons,

  contentTypeIcon: selectFromObject('contentTypeIcons', 'contentType'),

  humanReadableContentName: computed('objectType', function () {
    return humanReadableObjectNames[this.contentType];
  }),
});
