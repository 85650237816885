/* RESPONSIBLE TEAM: team-workflows */
import { attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { fragment, fragmentOwner } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import Validations from 'embercom/validations/workflows/control';

export default Fragment.extend(Validations, {
  uuid: attr('number'),
  step: fragmentOwner(),
  buttonLabel: attr('string'),
  nextStepUuid: attr('string'),
  type: attr('string'),
  attributeIdentifier: attr('string'),
  nextPath: undefined, //this attribute is used for a quick-reply to keep track of the next path so before submitting it we can get the first step of the next path in order to set nextStepUuid
  path: undefined,
  predicateGroup: fragment('predicates/predicate-group'),

  isFollowUp: equal('type', 'follow_up'),
  isFallback: equal('type', 'fallback'),
  isStartOver: attr('boolean'),

  linkToPath(path) {
    if (!path) {
      return;
    }
    let oldPath = this.nextPath;
    if (oldPath) {
      oldPath.get('incomingControls').removeObject(this);
    }
    path.get('incomingControls').pushObject(this);
    this.set('nextPath', path);
  },
  unlinkToPath() {
    this.set('nextPath', undefined);
  },
});
