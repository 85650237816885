/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import EmberObject, { computed } from '@ember/object';
import { readOnly, equal } from '@ember/object/computed';
import { inject } from '@ember/service';
import { put } from 'embercom/lib/ajax';
import Admin from 'embercom/models/admin';

export const USER_TARGET = 'user';
export const NON_USER_TARGET = 'non-user';

export default Model.extend({
  appService: inject(),
  app: readOnly('appService.app'),
  target: attr('string'),
  state: attr('string'),
  title: attr('string'),
  order: attr('number'),
  rulesetId: attr('number'),
  hasControlGroup: attr('boolean'),
  hasAbTest: attr('boolean'),
  variantIds: attr('array'),
  variantStats: attr('array'),
  variantStates: attr('array'),

  lastUpdatedById: attr('number'),
  lastUpdatedBy: computed('lastUpdatedById', function () {
    return Admin.peekAndMaybeLoad(this.store, this.lastUpdatedById);
  }),

  targetUsers: equal('target', USER_TARGET),
  targetNonUsers: equal('target', NON_USER_TARGET),

  analyticsData: computed(function () {
    return {
      object: 'bot-auto-message',
    };
  }),

  updateOrder(previousBot = EmberObject.create()) {
    let url = `/ember/operator_custom_bot_summaries/${this.id}/reorder`;
    let data = {
      app_id: this.get('app.id'),
      order: this.order,
      target: this.target,
      previous_bot_id: previousBot.get('id'),
    };
    return put(url, data);
  },
});
