/* RESPONSIBLE TEAM: team-frontend-tech */
import Subview from './subview';
import { fragment } from 'ember-data-model-fragments/attributes';
import { attr } from '@ember-data/model';

export default Subview.extend({
  appearance: fragment('common/subviews/appearance'),
  block: fragment('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
  }),
  type: attr('string', { defaultValue: 'content_block' }),
}).reopenClass({
  createDefaultParagraph(store) {
    let contentBlock = store.createFragment('common/subviews/content-block');
    let defaultBlock = { type: 'paragraph', text: 'Hi {{first_name|fallback:"there"}},' };
    let block = store.createFragment('common/blocks/paragraph', defaultBlock);
    let appearance = store.createFragment('common/subviews/appearance');
    appearance.applyDefaultsForParagraph();
    contentBlock.setProperties({
      appearance,
      block,
    });
    return contentBlock;
  },
});
