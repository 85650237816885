/* RESPONSIBLE TEAM: team-help-desk-experience */
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

export interface TagWireFormat {
  id: string | number;
  name: string;
  created_at?: string;
  created_by?: AdminSummaryWireFormat;
}

export type TagMenuSource = 'actions_button_click' | 'tag_button_click' | null;
export type TagActionType = 'added' | 'removed' | null;

export default class Tag {
  readonly id: string;
  readonly name: string;
  readonly createdAt: Date;
  createdBy?: AdminSummary;

  constructor(
    id: string | number,
    name: string,
    createdBy: AdminSummary | undefined,
    createdAt: Date | undefined = new Date(),
  ) {
    this.id = id.toString();
    this.name = name;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
  }

  get isNew() {
    return this.id === '';
  }

  static deserialize(json: TagWireFormat): Tag {
    let createdBy = json.created_by ? AdminSummary.deserialize(json.created_by) : undefined;
    let createdAt = json.created_at ? new Date(json.created_at) : undefined;
    return new Tag(json.id, json.name, createdBy, createdAt);
  }

  static serialize(tag: Tag): Pick<TagWireFormat, 'id' | 'name'> {
    return { id: tag.id, name: tag.name };
  }
}
