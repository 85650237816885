/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr } from '@ember-data/model';

export default class SuggestedConversationTopicModel extends Model {
  @attr('array') keyphrases;
  @attr('string') name;

  get keyphrasesText() {
    return this.joinKeyphrases(this.keyphrases);
  }

  setKeyphrasesFromText(keyphraseText) {
    this.keyphrases = this.splitKeyphrases(keyphraseText);
  }

  splitKeyphrases(keyphraseText) {
    return keyphraseText
      .split(',')
      .map((keyphrase) => keyphrase.trim())
      .filter((t) => t.length > 0);
  }

  joinKeyphrases(keyphrases) {
    return (keyphrases || []).join(', ');
  }
}
