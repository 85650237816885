/* RESPONSIBLE TEAM: team-channels */

import { tracked } from '@glimmer/tracking';
import { type Block } from '@intercom/interblocks.ts';
import Em from 'ember';

export interface WhatsappTemplateComponent {
  type?: string;
  format?: string;
  text?: string;
  buttons?: Array<any>;
  parameters?: Array<any>;
}

export interface WhatsappTemplateConstructorFormat {
  id: string;
  name: string;
  language: string;
  status: string;
  category: string;
  components: Array<WhatsappTemplateComponent>;
}

export interface WhatsappTemplateWireFormat {
  id: string;
  name: string;
  language: string;
  status: string;
  category: string;
  components: Array<WhatsappTemplateComponent>;
}

const UNSUPPORTED_HEADER_FORMAT: Array<string | undefined> = ['image', 'document', 'video'];

export default class WhatsappTemplate {
  readonly id: string;
  readonly name: string;
  readonly language: string;
  readonly status: string;
  readonly category: string;
  @tracked components: Array<WhatsappTemplateComponent>;

  constructor({
    id,
    name,
    language,
    status,
    category,
    components,
  }: WhatsappTemplateConstructorFormat) {
    this.id = id;
    this.name = name;
    this.language = language;
    this.status = status;
    this.category = category;
    this.components = components;
  }

  static deserialize(json: WhatsappTemplateWireFormat) {
    return new WhatsappTemplate({
      id: json.id,
      name: json.name,
      language: json.language,
      status: json.status,
      category: json.category,
      components: json.components,
    });
  }

  get locale() {
    return this.language?.replace('_', '-');
  }

  get body() {
    return this.components.find((component) => component.type?.toLowerCase() === 'body');
  }

  get body_text() {
    return this.body?.text;
  }

  get bodyTextWithParameters() {
    return this.textWithParameters(this.body_text, this.body);
  }

  get header() {
    return this.components.find((component) => component.type?.toLowerCase() === 'header');
  }

  get header_text() {
    return this.header?.text;
  }

  get headerTextWithParameters() {
    return this.textWithParameters(this.header_text, this.header);
  }

  get footer() {
    return this.components.find((component) => component.type?.toLowerCase() === 'footer');
  }

  get footer_text() {
    return this.footer?.text;
  }

  get footerTextWithParameters() {
    return this.textWithParameters(this.footer_text, this.footer);
  }

  get buttons() {
    return this.components.find((component) => component.type?.toLowerCase() === 'buttons')
      ?.buttons;
  }

  get buttons_text() {
    return this.buttons?.map((button) => `[ ${button.text} ]`)?.join(' ');
  }

  get blocks() {
    let blocks = [];
    if (this.header_text) {
      blocks.push({
        type: 'subheading',
        text: this.escapeParameters(this.textToHtml(this.header_text)),
      });
    }
    if (this.body_text) {
      // noinspection TypeScriptValidateTypes
      blocks.push({
        type: 'paragraph',
        text: this.escapeParameters(this.textToHtml(this.body_text)),
        class: 'no-margin',
      });
    }
    if (this.footer_text) {
      blocks.push({
        type: 'paragraph',
        text: this.escapeParameters(this.textToHtml(this.footer_text)),
        class: 'no-margin',
      });
    }
    if (this.buttons_text) {
      blocks.push({
        type: 'paragraph',
        text: this.escapeParameters(this.textToHtml(this.buttons_text)),
        class: 'no-margin',
      });
    }
    return blocks;
  }

  get previewBlocks() {
    // TODO: extract repeated code from this and blocks
    let blocks = [];
    let handlebarsUtil = Em.Handlebars as any;
    if (this.header_text) {
      blocks.push({
        type: 'paragraph',
        text: this.textToHtml(handlebarsUtil.Utils.escapeExpression(this.header_text)),
        class: 'font-bold header',
      });
    }
    if (this.body_text) {
      blocks.push({
        type: 'paragraph',
        text: this.textToHtml(handlebarsUtil.Utils.escapeExpression(this.body_text)),
        class: 'no-margin body',
      });
    }
    if (this.footer_text) {
      blocks.push({
        type: 'paragraph',
        text: this.textToHtml(handlebarsUtil.Utils.escapeExpression(this.footer_text)),
        class: 'no-margin footer',
      });
    }
    if (this.buttons_text) {
      blocks.push({
        type: 'paragraph',
        text: this.textToHtml(handlebarsUtil.Utils.escapeExpression(this.buttons_text)),
        class: 'no-margin buttons',
      });
    }
    return blocks;
  }

  get truncatedBlocks() {
    let blocks = [];
    if (this.header_text) {
      blocks.push({ type: 'subheading', text: this.headerTextWithParameters });
    }
    if (this.body_text) {
      blocks.push({
        type: 'paragraph',
        text: this.truncateString(this.bodyTextWithParameters, 92) ?? '',
        class: 'no-margin',
      });
    }
    return blocks as Block[];
  }

  createBlock(type: string, text: string | undefined, className = '') {
    if (text) {
      return {
        type,
        text: this.textToHtml(text),
        ...(className && { class: className }), // Spread operator will add the class property only if className is truthy
      };
    }
    return null;
  }

  get blocksWithParameters(): any {
    return [
      this.createBlock('subheading', this.headerTextWithParameters),
      this.createBlock('paragraph', this.bodyTextWithParameters, 'no-margin'),
      this.createBlock('paragraph', this.footerTextWithParameters, 'no-margin'),
      this.createBlock('paragraph', this.buttons_text, 'no-margin'),
    ].filter(Boolean); // remove null entries
  }

  textWithParameters(text: string | undefined, component: WhatsappTemplateComponent | undefined) {
    if (!text || !component) {
      return;
    }

    let parameters = component.parameters;
    if (parameters === undefined) {
      return text;
    }

    return this.replacePlaceholders(text, parameters);
  }

  replacePlaceholders(text: string, parameters: any) {
    return text.replace(/{{(\d+)}}/g, (_: string, number: any) => {
      let index = parseInt(number, 10) - 1;
      if (index < 0 || index >= parameters.length) {
        console.warn(`No replacement found for placeholder {{${number}}}`);
        return `{{${number}}}`;
      }
      if (!parameters[index].hasOwnProperty('text')) {
        console.warn(`Object at index ${index} does not have a 'text' property`);
        return `{{${number}}}`;
      }
      return parameters[index].text;
    });
  }

  textToHtml(text: string) {
    return text.replace(/\n/g, '<br />');
  }

  escapeParameters(text: string) {
    return text.replace(/{{/g, '[[').replace(/}}/g, ']]');
  }

  truncateString(str: string | undefined, num: number) {
    if (str === undefined) {
      return;
    }
    if (str.length <= num) {
      return str;
    }
    return `${str.slice(0, num)}…`;
  }

  updateParameters(componentsWithParameters: Array<WhatsappTemplateComponent>) {
    this.components.forEach((component) => {
      let updatedComponent = componentsWithParameters.find(
        (c) => c.type?.toLowerCase() === component.type?.toLowerCase(),
      );
      if (updatedComponent) {
        component.parameters = updatedComponent.parameters;
      }
    });
  }

  get isInvalid() {
    return Boolean(this.unsupportedHeader) || Boolean(this.hasParameters());
  }

  hasParameters() {
    let parameterRegex = /{{.*?}}/g;
    return (
      Boolean(this.header?.text?.match(parameterRegex)) ||
      Boolean(this.body?.text?.match(parameterRegex)) ||
      Boolean(this.footer?.text?.match(parameterRegex)) ||
      Boolean(this.buttons_text?.match(parameterRegex))
    );
  }

  get unsupportedHeader() {
    return this.components.find((component) => {
      return (
        component.type?.toLowerCase() === 'header' &&
        UNSUPPORTED_HEADER_FORMAT.includes(component?.format?.toLowerCase())
      );
    });
  }
}
