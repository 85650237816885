/* RESPONSIBLE TEAM: team-proactive-support */

import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import ENV from 'embercom/config/environment';

import { post, get } from 'embercom/lib/ajax';
import {
  objectTypes,
  objectNames,
  nodeDelayBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { nodeGraphConfigurationClasses } from 'embercom/objects/series/configuration-list';
import { dependentKeyCompat } from '@ember/object/compat';
import { isNone, isPresent } from '@ember/utils';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import { cached } from 'tracked-toolbox';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

export const NODE_STATISTIC_KEYS = [
  'completedCheckpointCount',
  'completedCount',
  'disengagedCount',
  'activeCheckpointCount',
  'completedTraversedPrimaryEdgesCheckpointCount',
  'completedTraversedAlternativeEdgesCheckpointCount',
  'completedTraversedSplitEdgesCheckpointCount',
  'completedEjectedCheckpointCount',
  'completedFinishedCheckpointCount',
  'expiredCheckpointCount',
  'exitedCheckpointCount',
];

export default class SeriesNode extends Model {
  @service appService;
  @belongsTo('series/series', { inverse: 'nodes', async: false }) series;
  @belongsTo('matching-system/client-data', { async: false }) rulesetClientData;
  @belongsTo('matching-system/ruleset-errors', { async: false }) rulesetErrors;
  @hasMany('series/edge', { inverse: 'predecessor', async: false }) outwardEdges;
  @hasMany('series/edge', { inverse: 'successor', async: false }) inwardEdges;
  @hasMany('matching-system/content-preview', { async: false }) contentPreviews;
  @hasMany('series/edge-split', { async: false }) edgeSplits;

  @attr('number') xPosition;
  @attr('number') yPosition;
  @attr('number') nodeType;
  @attr('number') delayBehavior;
  @attr('number') maxEvaluationTime;
  @attr('number') completedCheckpointCount; // how many completed the Node
  @attr('number') completedCount; // how many completed the Series
  @attr('number') disengagedCount;
  @attr('number') activeCheckpointCount;
  @attr('number') completedTraversedPrimaryEdgesCheckpointCount;
  @attr('number') completedTraversedAlternativeEdgesCheckpointCount;
  @attr('number') completedTraversedSplitEdgesCheckpointCount;
  @attr('number') completedEjectedCheckpointCount;
  @attr('number') completedFinishedCheckpointCount;
  @attr('number') expiredCheckpointCount;
  @attr('number') exitedCheckpointCount;
  @attr('boolean') rulesetHasGoal;
  @attr() objectTypes;
  @attr('boolean') hasRulesetWithEventTriggerAndMetadata;
  @attr('boolean') hasRulesetWithChecklist;
  @attr('boolean') rulesetTargetsUsers;
  @attr('boolean') rulesetTargetsVisitorsOrLeads;

  @attr('number') rulesetId;
  @tracked _ruleset = undefined;

  duplicatedFromNode = undefined;

  async fetchRuleset() {
    this._ruleset = await this.store.findRecord('matching-system/ruleset', this.rulesetId);
    if (isNone(this._ruleset)) {
      throw new Error(`Node ${this.id} is missing its Ruleset (${this.rulesetId})`);
    }
    return this._ruleset;
  }

  get ruleset() {
    if (isNone(this._ruleset)) {
      throw new Error(
        `Attempted to access the Ruleset for Node ${this.id} before calling fetchRuleset`,
      );
    }
    return this._ruleset;
  }

  ready() {
    this.setEdgeSplitIndex();
  }

  setEdgeSplitIndex() {
    if (isPresent(this.edgeSplits)) {
      this.edgeSplits.forEach((edgeSplit, index) => {
        edgeSplit.index = index;
      });
    }
  }

  get hasFetchedRuleset() {
    return !isNone(this._ruleset);
  }

  set ruleset(ruleset) {
    if (ENV.environment === 'test') {
      this.rulesetId = ruleset.id;
      this._ruleset = ruleset;
    } else {
      throw new Error('Do not attempt to directly set the Ruleset outside of tests');
    }
  }

  @dependentKeyCompat
  get isDelayable() {
    return this.delayBehavior === nodeDelayBehaviors.delayable;
  }

  @dependentKeyCompat
  get evaluationTimeDurations() {
    let duration = moment.duration(this.maxEvaluationTime * 1000);
    return {
      days: Math.floor(duration.asDays()),
      hours: Math.floor(duration.asHours()) % 24,
      minutes: duration.asMinutes() % 60,
    };
  }

  get delayDescription() {
    if (this.isDelayable) {
      let duration = this.evaluationTimeDurations;
      let descriptions = [];
      if (duration.days) {
        descriptions.push(`${duration.days}d`);
      }
      if (duration.hours) {
        descriptions.push(`${duration.hours}h`);
      }
      if (duration.minutes) {
        descriptions.push(`${duration.minutes}m`);
      }
      return descriptions.join(' ');
    }
  }

  get objectNames() {
    return this.objectTypes.map((objectType) => objectNames[objectType]);
  }

  get hasErrors() {
    return this.rulesetErrors.get('messages').length > 0;
  }

  get title() {
    return this.graphConfiguration.title;
  }

  @cached
  get graphConfiguration() {
    let firstObjectType = this.objectTypes.firstObject;
    let GraphConfigurationClass = nodeGraphConfigurationClasses[firstObjectType];
    return new GraphConfigurationClass(this);
  }

  async duplicate(params) {
    let duplicate_nodes = await this.constructor.duplicateCollection({ nodes: [this], ...params });
    return duplicate_nodes.firstObject;
  }

  async checkpoints(statuses = [], page = 1, perPage = 25) {
    let checkpoints = await get(`/ember/series/nodes/${this.id}/checkpoints`, {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      series_id: this.series.id,
      checkpoint_statuses: statuses,
      page_from: page,
      per_page: perPage,
    });

    if (isPresent(checkpoints)) {
      this.store.pushPayload('series/checkpoint', { 'series/checkpoint': checkpoints });
      return checkpoints.map((checkpoint) =>
        this.store.peekRecord('series/checkpoint', checkpoint.id),
      );
    } else {
      return [];
    }
  }

  static async duplicateCollection(params) {
    let { nodes, store, app } = params;
    let node_ids = nodes.map((node) => node.id);
    let requestParams = {
      node_ids,
      app_id: app.id,
      admin_id: app.currentAdmin.id,
    };

    let response = await post(`/ember/series/nodes/duplicate`, requestParams);

    store.pushPayload({ 'series/node': response });
    let duplicatedNodes = response.map((newNode) => store.peekRecord('series/node', newNode.id));

    // The server returns the duplicated nodes in the order which we send them up
    // in the params. Here we can add a reference to the newly duplicated node to
    // the original node it was based on.
    if (duplicatedNodes.length === nodes.length) {
      for (let i = 0; i < duplicatedNodes.length; ++i) {
        duplicatedNodes[i].duplicatedFromNode = nodes[i];
      }
    }

    return duplicatedNodes;
  }

  static async createNode(params) {
    let { store, app, series, objectType, objectData, defaultParams } = params;
    defaultParams = defaultParams || {};

    let requestParams = {
      app_id: app.id,
      series_id: series.id,
      object_type: objectType,
      object_data: objectData,
      ...defaultParams,
    };
    let response = await post('/ember/series/nodes', requestParams);

    store.pushPayload({ 'series/node': [response] });
    return store.peekRecord('series/node', response.id);
  }

  get isStarting() {
    return this.inwardEdges.length === 0;
  }

  get isEnding() {
    return this.outwardEdges.length === 0;
  }

  get isInternal() {
    return this.inwardEdges.length > 0;
  }

  get isCondition() {
    return this.objectTypes.firstObject === objectTypes.condition;
  }

  isObjectType(objectType) {
    return this.objectTypes.firstObject === objectType;
  }

  get parentNodes() {
    return this.inwardEdges.map((edge) => edge.predecessor);
  }

  get ancestorNodes() {
    let parentNodes = this.parentNodes;
    let allAncestorNodes = parentNodes;
    while (parentNodes.length) {
      parentNodes = parentNodes.flatMap((node) => node.parentNodes);
      allAncestorNodes.addObjects(parentNodes);
    }
    return allAncestorNodes;
  }

  // We only want to set the statistical properties here rather than update all properties of the node in
  // case they have changed locally. Therefore, we extract just the count keys and push them into
  // the store – this prevents us from affecting the dirty state of the model.
  updateCounts(nodeJson) {
    let statsPayload = NODE_STATISTIC_KEYS.reduce((stats, key) => {
      let underscoredKey = underscore(key);
      stats[underscoredKey] = nodeJson[underscoredKey];
      return stats;
    }, {});
    statsPayload['id'] = this.id;
    this.store.push(this.store.normalize('series/node', statsPayload));
  }
}
