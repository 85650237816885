/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import {
  TICKET_STATE_UPDATED_TARGET,
  TRIGGERABLE_BOT_TYPE,
  TICKET_STATE_UPDATED_TRIGGER,
  TICKET_ONLY,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';

import CustomBotConfig from '../custom-bot';
import type { TargetContext } from '../custom-bot';
import { getApp } from 'embercom/lib/container-lookup';
import { TICKET_TRIGGER_PREDICATES, computeSelectedTargetContexts } from '../target-context-config';
import TicketTriggerEditorConfig from 'embercom/objects/visual-builder/configuration/editor/ticket-trigger';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class TicketStateUpdatedCustomBotConfig extends CustomBotConfig {
  constructor(public customBot?: any) {
    super();
  }

  get target(): BotConfigTarget {
    return TICKET_STATE_UPDATED_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return TICKET_STATE_UPDATED_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.ticket-state-updated.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.ticket-state-updated.description';
  }

  get triggerPredicatesDescriptionTranslationKey() {
    return 'operator.custom-bot.trigger.ticket-state-updated.trigger-predicates-description';
  }

  get icon(): InterfaceIconName {
    return 'ticket';
  }

  get editorConfig() {
    return new TicketTriggerEditorConfig({
      targetChannels: this.customBot?.targetChannels,
      areStepPaywallsActive: this.areStepPaywallsActive,
      targetContexts: this.selectedTargetContexts,
    });
  }

  get EditorConfigClass() {
    return TicketTriggerEditorConfig;
  }

  get triggerPredicates() {
    let app = getApp();
    if (!app.canUseWorkflowsTicketsV3) {
      return null;
    } else {
      return TICKET_TRIGGER_PREDICATES;
    }
  }

  get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get triggerContextType() {
    return TICKET_ONLY;
  }

  get selectedTargetContexts(): TargetContext[] | null {
    let app = getApp();

    if (app.canUseWorkflowsTicketsV3 && this.customBot?.triggerPredicateGroup) {
      return computeSelectedTargetContexts(this.customBot.triggerPredicateGroup);
    } else {
      return null;
    }
  }
}
