/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { cancel, later } from '@ember/runloop';
import { isBlank, isEqual, isEmpty } from '@ember/utils';
import {
  and,
  alias,
  gte,
  not,
  equal,
  reads,
  or,
  notEmpty,
  filterBy,
  gt,
  empty,
  readOnly,
} from '@ember/object/computed';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import { addTo, isAny, subtractFrom } from '@intercom/pulse/lib/computed-properties';
import ajax from 'embercom/lib/ajax';
import { task, taskGroup } from 'ember-concurrency';
import moment from 'moment-timezone';
import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import { CONVERSATION_PRIORITIES, CONVERSATION_STATES } from 'embercom/lib/inbox/constants';
import ConversationStreamSyncedList from 'embercom/lib/inbox/conversation-stream-synced-list';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { cancelTask, runTask, runDisposables } from 'ember-lifeline';

// When a conversation has no SLA attached, its next_breach_time is set to now + 2000 years in the future for sorting purposes.
// This timestamp describes a point ~2000 years into the future which we use to determine if a conversation has
// an SLA attached. If next_breach_time < SLA_PIVOT_TIMESTAMP, we have an SLA. If next_breach_time > SLA_PIVOT_TIMESTAMP
// we do not have an SLA. See https://docs.google.com/document/d/19HGu1of-pvmml2CzCu0BzyFx6xcg1oMY7CUj4ygcTyQ
const SLA_PIVOT_TIMESTAMP = new Date(4000, 1, 1);
const CARD_ANIMATION_TIME = 300;

export default Model.extend(TaggingMixin, {
  store: service(),
  appService: service(),
  intl: service(),

  app_id: attr(),
  read_at: attr(),
  assignee_id: attr(),
  admin_assignee_id: attr(),
  team_assignee_id: attr(),
  brand_name: attr(),
  brandName: alias('brand_name'),
  created_at: attr(),
  latest_admin_visible_comment_at: attr(),
  reply_count: attr(),
  state: attr(),
  initial_channel: attr(),
  current_channel: attr(),
  created_via_api: attr(),
  started_from_article: attr(),
  started_by_custom_bot: attr(),
  priority: attr(),
  title: attr(),
  has_uploads: attr(),
  bounced: attr(),
  can_use_salesforce: attr(),
  original_message_url: attr(),
  lead_shares_email_with_user: attr(),
  lead_identified_via_email: attr(),
  has_unread_mentions: attr(),
  is_one_to_one_conversation: attr(),
  isOneToOneConversation: alias('is_one_to_one_conversation'),
  waiting_since: attr(),
  last_snoozed_at: attr(),
  admin_has_read: attr('boolean'),
  updated_at: attr(),
  sorting_updated_at: attr(),
  latest_update_at: attr(),
  rating_index: attr(),
  conversation_sla_id: attr(),
  next_breach_time: attr(),
  nextBreachTime: alias('next_breach_time'),
  inbox_view_ids: attr(),
  customBotId: attr(),
  customBotTitle: attr(),
  redacted: attr('boolean'),
  social_latest_conversation: attr(),
  isInboundConversation: attr(),
  siblingConversationIds: attr(),
  messageThreadId: attr(),
  isRequest: attr('boolean', { defaultValue: false }),
  canReply: attr('boolean', { defaultValue: true }),
  ticket_state: attr('string', { shouldSerialize: false }),

  conversationAttributes: hasMany('conversation-attributes/conversation-attribute-value', {
    async: false,
  }),

  requestType: belongsTo('inbox/ticket-type-summary', { async: false }),

  participants: hasMany('participant', { async: false }),

  main_participant: computed('participants.@each.{firstName,display_as,email}', function () {
    let participants = this.participants;
    return participants.objectAt(0);
  }).readOnly(),
  mainParticipant: readOnly('main_participant'),

  has50ParticipantsOrMore: gte('participants.length', 50),

  drafts: hasMany('conversation-draft', { async: false }),
  draft: readOnly('drafts.firstObject'),
  hasDraft: notEmpty('draft'),
  hasNoDrafts: not('hasDraft'),
  isFacebook: equal('initial_channel', 'facebook'),
  isNotFacebook: not('isFacebook'),
  isTwitter: equal('initial_channel', 'twitter'),
  isNotTwitter: not('isTwitter'),
  isWhatsapp: equal('current_channel', 'whatsapp'),
  isNotWhatsapp: not('isWhatsapp'),
  isInstagram: equal('initial_channel', 'instagram'),
  isSms: equal('initial_channel', 'sms'),
  isPending: reads('lastPart.isPending'),
  isInitialChannelPush: equal('initial_channel', 'push'),
  isInitialChannelEmail: equal('initial_channel', 'email'),
  isChannelMessenger: computed('initial_channel', 'current_channel', function () {
    if (this.current_channel && this.current_channel !== 'unknown') {
      return this.initial_channel === 'messenger' && this.current_channel !== 'whatsapp';
    }
    return this.initial_channel === 'messenger';
  }),
  isChannelMessengerOrEmail: or('isChannelMessenger', 'isInitialChannelEmail'),
  isSocial: or('isFacebook', 'isTwitter', 'isWhatsapp', 'isInstagram', 'isSms'),
  isNotSocial: not('isSocial'),
  adminHasNotRead: not('admin_has_read'),
  syncedPartList: null,
  sortedParts: readOnly('syncedPartList.sortedParts'),
  parts: readOnly('syncedPartList.parts'),
  sortedNonPassiveParts: filterBy('sortedParts', 'nonPassive', true),
  userIsTyping: false,
  hasFetchedParts: gt('syncedPartList.items.length', 0),
  articleSuggestionCount: 0,
  isExpanded: false,
  disableFetchParts: false,
  disableSeenStateUpdates: false,
  taggings: hasMany('taggings'),
  updateTaggingUrl: '/ember/conversations/update_tags.json',
  hasSla: notEmpty('conversation_sla_id'),
  hasActiveTimeTarget: computed('next_breach_time', function () {
    let nextBreachTime = this.next_breach_time;
    return nextBreachTime && new Date(nextBreachTime) < SLA_PIVOT_TIMESTAMP;
  }),
  hasNoActiveTimeTarget: not('hasActiveTimeTarget'),
  initiator: belongsTo('conversations-service/initiator', { inverse: null }),
  initialPart: findBy('parts', 'is_initial_part', true),
  hasSiblingConversations: notEmpty('siblingConversationIds'),
  ticketState: computed('intl.locale', 'ticket_state', function () {
    let ticketStates = {
      submitted: this.intl.t('inbox.ticket-state.submitted'),
      in_progress: this.intl.t('inbox.ticket-state.in_progress'),
      waiting_on_customer: this.intl.t('inbox.ticket-state.waiting_on_customer'),
      resolved: this.intl.t('inbox.ticket-state.resolved'),
    };

    return ticketStates[this.ticket_state];
  }),

  init() {
    this._super(...arguments);

    // Creates a ConversationStreamSyncedList whose ember concurrency tasks
    // are tied to this conversation's. Cancelling any tasks in the list
    // will cancel this conversation's tasks (and vice versa)
    // See: https://github.com/intercom/intercom/issues/150738
    this.set(
      'syncedPartList',
      ConversationStreamSyncedList.create({
        conversation: this,
        syncingTaskGroup: this.partTasks,
      }),
    );
  },

  feedFormattedDate: computed('intl.locale', 'timestamp', function () {
    return this.intl.formatDate(this.timestamp, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }),
  feedDaysAgo: computed('timestamp', function () {
    let latestUpdateAtTime = moment(this.timestamp);
    let currentTime = moment();

    // Moment#diff rounds down towards zero, but we want to round to nearest integer
    // https://momentjs.com/docs/#/displaying/difference/
    return Math.round(currentTime.diff(latestUpdateAtTime, 'days', true));
  }),

  canBeImplicitlyAssigned: computed(
    'unassigned',
    'assignee.isTeam',
    'appService.app.inboxIsNotActive',
    'assignedToOperator',
    function () {
      if (this.get('appService.app.inboxIsNotActive')) {
        return false;
      }
      return this.unassigned || this.get('assignee.isTeam') || this.assignedToOperator;
    },
  ),
  assignee: computed('assignee_id', 'appService.app.assignableAdminsAndOperator.[]', function () {
    if (
      isBlank(this.assignee_id) ||
      isBlank(this.get('appService.app.assignableAdminsAndOperator'))
    ) {
      return null;
    }
    return this.get('appService.app.assignableAdminsAndOperator').findBy('id', this.assignee_id);
  }),
  assignedToOperator: computed('assignee_id', 'appService.app.operatorBot.id', function () {
    return this.assignee_id === this.appService.app.operatorBot.id;
  }),

  adminAssignee: computed(
    'admin_assignee_id',
    'appService.app.assignableAdminsAndOperator.[]',
    function () {
      if (
        isBlank(this.admin_assignee_id) ||
        isBlank(this.get('appService.app.assignableAdminsAndOperator'))
      ) {
        return null;
      }
      return this.get('appService.app.assignableAdminsAndOperator').findBy(
        'id',
        this.admin_assignee_id,
      );
    },
  ),
  hasAdminAssignee: computed('admin_assignee_id', function () {
    return !isBlank(this.admin_assignee_id) && !isEqual('0', this.admin_assignee_id);
  }),
  teamAssignee: computed(
    'team_assignee_id',
    'appService.app.assignableAdminsAndOperator.[]',
    function () {
      if (
        isBlank(this.team_assignee_id) ||
        isBlank(this.get('appService.app.assignableAdminsAndOperator'))
      ) {
        return null;
      }
      return this.get('appService.app.assignableAdminsAndOperator').findBy(
        'id',
        this.team_assignee_id,
      );
    },
  ),
  hasTeamAssignee: computed('team_assignee_id', function () {
    return !isBlank(this.team_assignee_id) && !isEqual('0', this.team_assignee_id);
  }),

  unassigned: computed('assignee_id', function () {
    return isBlank(this.assignee_id) || isEqual('0', this.assignee_id);
  }),
  hasAssignee: not('unassigned'),

  first_user_part: belongsTo('conversation-part', { async: false }),
  firstUserPart: alias('first_user_part'),

  last_part: belongsTo('conversation-part', { async: false }),
  lastPart: alias('last_part'),

  last_action_part: belongsTo('conversation-part', { async: false }),
  lastActionPart: alias('last_action_part'),

  last_part_where_current_admin_is_mentioned: belongsTo('conversation-part', { async: false }),
  lastPartWhereCurrentAdminIsMentioned: alias('last_part_where_current_admin_is_mentioned'),

  last_part_with_content_excluding_operator_v2: belongsTo('conversation-part', { async: false }),
  lastPartWithContentExcludingOperator: alias('last_part_with_content_excluding_operator_v2'),

  lastPartExcludingOperator: computed('last_part', 'sortedParts.[]', function () {
    let localLastPartExcludingOperator = this.sortedParts
      .slice()
      .reverse()
      .find((part) => part.isNotOperatorPart);

    if (localLastPartExcludingOperator) {
      return localLastPartExcludingOperator;
    }

    return this.last_part;
  }),

  analyticsData: computed('id', function () {
    return {
      object: 'conversation',
      conversation_id: this.id,
    };
  }),

  someoneIsTyping: or('userIsTyping', 'anotherAdminIsTypingANote', 'anotherAdminIsTypingAComment'),

  conversationSeenStatus: computed(
    'bounced',
    'lastPart.isInitialPartByHumanAdmin',
    'lastPart.hasBeenSeen',
    function () {
      if (this.bounced && this.get('lastPart.isInitialPartByHumanAdmin')) {
        return 'Bounced';
      }
      if (this.get('lastPart.hasBeenSeen')) {
        return 'Seen';
      }
      return 'Not seen yet';
    },
  ),

  partsExcludingBots: filterBy('sortedParts', 'isBotPart', false),
  hasAdminCommentOrMessage: isAny('partsExcludingBots', 'isAdminCommentOrMessage', true),
  userParts: filterBy('sortedParts', 'isUserPart', true),
  userPartsWithBody: filterBy('userParts', 'hasBody', true),
  lastUserPart: readOnly('userParts.lastObject'),
  lastUserPartWithBody: readOnly('userPartsWithBody.lastObject'),
  lastUserPartCreated: readOnly('lastUserPart.created_at'),
  lastUserPartTooOldToFacebookReply: computed('lastUserPartCreated', function () {
    let expiresAfterDays = 7;
    return this._lastUserPartLongerThan(expiresAfterDays);
  }),
  showFacebookWarningBanner: and('isFacebook', 'lastUserPartTooOldToFacebookReply'),

  whatsappReplyLimit: computed(function () {
    if (this.appService.app.canUseFeature('whatsapp-test-message-templates')) {
      return 1000 * 60 * 3;
    } else {
      return 1000 * 3600 * 24;
    }
  }),
  lastUserPartTooOldToWhatsappReply: computed('lastUserPartCreated', function () {
    return this._lastUserPartLongerThan(this.whatsappReplyLimit, 'ms');
  }),

  showWhatsappWarningBanner: computed('lastUserPartCreated', function () {
    this.cancelTaskForWhatsappWarningBanner();

    if (this.isNotWhatsapp) {
      return false;
    }

    if (this.lastUserPartTooOldToWhatsappReply) {
      return true;
    }

    // if last user part hasn't been fetched, check the oldest fetched part
    if (
      !this.lastUserPartCreated &&
      this._oldestFetchedPartLongerThan(this.whatsappReplyLimit, 'ms')
    ) {
      return true;
    }

    this.runTaskForWhatsappWarningBanner();
    return false;
  }),

  runTaskForWhatsappWarningBanner() {
    let timeFromLastUserReply = moment().diff(moment(this.lastUserPartCreated));
    let timeToReplyLimit = this.whatsappReplyLimit - timeFromLastUserReply;

    this._whatsappWarningBannerTaskId = runTask(
      this,
      () => {
        this.notifyPropertyChange('lastUserPart');
      },
      timeToReplyLimit,
    );
  },

  cancelTaskForWhatsappWarningBanner() {
    if (this._whatsappWarningBannerTaskId) {
      cancelTask(this, this._whatsappWarningBannerTaskId);
      this._whatsappWarningBannerTaskId = null;
    }
  },
  showSocialInactiveConversationBanner: computed(
    'isSocial',
    'social_latest_conversation',
    function () {
      return (
        this.isSocial &&
        this.social_latest_conversation &&
        this.social_latest_conversation.toString() !== this.id.toString()
      );
    },
  ),
  showSocialComposerBanner: or('showWhatsappWarningBanner', 'showSocialInactiveConversationBanner'),
  preventAdminReply: readOnly('showFacebookWarningBanner'),
  ordinal: alias('timestamp'),

  waitingSinceOrdinal: computed('computedWaitingSince', function () {
    return this._getTimeForDate(this.computedWaitingSince);
  }),

  computedWaitingSince: readOnly('waiting_since'),

  _lastUserPartLongerThan(n, period = 'days') {
    return moment(this.lastUserPartCreated).isBefore(moment().subtract(n, period));
  },

  _oldestFetchedPartLongerThan(n, period = 'days') {
    let oldestPartCreatedAt = this.sortedParts?.firstObject?.created_at;
    return moment(oldestPartCreatedAt).isBefore(moment().subtract(n, period));
  },

  _getFutureTimeForWaitingSince() {
    let currentWaitingSince = moment(this.waiting_since);
    let now = moment();
    return (currentWaitingSince.isAfter(now) ? currentWaitingSince : now.add(2000, 'y'))
      .utc()
      .format('YYYY-MM-DD[T]HH:mm:ss.000[Z]');
  },

  _waitingSinceInfluencingPartsDesc: computed(
    'sortedParts.@each.isWaitingSinceInfluencingPart',
    function () {
      return this.sortedParts.filter((part) => part.get('isWaitingSinceInfluencingPart')).reverse();
    },
  ),

  willDestroy() {
    if (this.syncedPartList) {
      this.syncedPartList.unload();
      this.syncedPartList.destroy();
    }

    this.cancelTaskForWhatsappWarningBanner();
    runDisposables();

    return this._super();
  },

  unloadRecord() {
    if (this.syncedPartList) {
      this.syncedPartList.unload();
      this.syncedPartList.destroy();
    }
    let belongsToParts = [
      'first_user_part',
      'last_part',
      'last_action_part',
      'last_part_where_current_admin_is_mentioned',
      'last_part_with_content_excluding_operator_v2',
    ];
    belongsToParts.forEach((part) => {
      this.get(part) && this.get(part).unloadRecord();
    });
    this._super();
  },

  _calculateWaitingSinceForAdminPart(latestPart) {
    // https://github.com/intercom/intercom/issues/108544
    // Set waiting into the future for admin comments
    let futureTime = this._getFutureTimeForWaitingSince();
    if (latestPart.get('hasBodyOrUploads')) {
      return futureTime;
    }
    let influencingParts = this._waitingSinceInfluencingPartsDesc;
    let previousPart = influencingParts.get('firstObject');

    // if we run into a reopener look at the previous part, if it's a closer or has a body,
    // set the waiting since into the future
    if (
      !latestPart.get('isReopener') ||
      !previousPart.get('isCloser') ||
      previousPart.get('hasBodyOrUploads')
    ) {
      return futureTime;
    }

    // If the closer has no body, look back until we find the next admin comment and set the waiting
    // since to the user comment immediately after this. (i.e. reset the waiting since to what it was before the close)
    let indexOfLastAdminComment = influencingParts.findIndex(
      (part) => part.get('isHumanAdminPart') && part.get('hasBody'),
    );
    let endpoint =
      indexOfLastAdminComment === -1 ? influencingParts.length : indexOfLastAdminComment;
    let partsAfterLastAdminComment = influencingParts.slice(2, endpoint);
    let nextUserComment = partsAfterLastAdminComment
      .reverse()
      .find((part) => part.get('isUserPart'));
    let timeToReturn = nextUserComment ? moment(nextUserComment.get('created_at')) : moment();
    return timeToReturn.utc().format('YYYY-MM-DD[T]HH:mm:ss.000[Z]');
  },

  isNotWaiting: computed('computedWaitingSince', function () {
    let datetime = new Date(this.computedWaitingSince);
    let now = new Date();
    let diff = datetime.getFullYear() - now.getFullYear();
    // A waiting since date in the far future indicates not waiting
    // we check for the very far future because the local clock is not guaranteed to be accurate
    return diff > 1000;
  }),

  inverseOrdinal: computed('ordinal', function () {
    return this.ordinal * -1;
  }),

  timestamp: computed('sorting_updated_at', function () {
    return this._getTimeForDate(this.sorting_updated_at);
  }),

  secondLastNonPassivePart: computed('sortedNonPassiveParts.[]', function () {
    let parts = this.sortedNonPassiveParts;
    let secondLastPart = Math.max(parts.get('length') - 2, 0);
    return parts.objectAt(secondLastPart);
  }),

  isSingleMessage: equal('sortedParts.length', 1),
  hasInitialMessagePart: isAny('sortedParts', 'isInitialMessage'),

  isLastAnOpeningAdminMessage: reads('lastPart.isInitialPartByHumanAdmin'),
  shouldDisplayMessageOnHeader: and(
    'isLastAnOpeningAdminMessage',
    'isSingleMessage',
    'initiator.showPageId',
  ),
  message_and_replies_count: addTo('reply_count', 1),
  isGroupConversation: gt('participants.length', 1),
  isNotGroupConversation: not('isGroupConversation'),
  fromDeletedUser: empty('participants'),
  display_item_reply_count: gt('reply_count', 0),
  other_participant_count: subtractFrom('participants.length', 1),

  otherParticipants: computed('participants.[]', function () {
    return this.participants.rejectBy('id', this.get('main_participant.id'));
  }),

  isPrioritised: equal('priority', CONVERSATION_PRIORITIES.priority),
  isOpen: equal('state', CONVERSATION_STATES.open),
  isSnoozed: equal('state', CONVERSATION_STATES.snoozed),
  isNotClosed: or('isOpen', 'isSnoozed'),
  isClosed: computed('state', function () {
    return isEmpty(this.state) || this.state === CONVERSATION_STATES.closed;
  }),

  requiredAttributesWithEmptyValue: computed(
    'conversationAttributes.[]',
    'conversationAttributes.@each.value',
    function () {
      return ConversationAttributeDescriptor.peekAllRequired(this.team_assignee_id)
        .map((descriptor) => ({
          descriptor,
          value: this.conversationAttributes.find(({ id }) => id === `${this.id}-${descriptor.id}`),
        }))
        .filter(({ value, descriptor }) => !!value?.isEmpty(descriptor));
    },
  ),
  hasRequiredAttributesWithEmptyValue: gt('requiredAttributesWithEmptyValue.length', 0),

  stateLabel: computed('state', function () {
    let state = this.state;
    switch (state) {
      case CONVERSATION_STATES.open:
        return 'Open';
      case CONVERSATION_STATES.closed:
        return 'Closed';
      case CONVERSATION_STATES.snoozed:
        return 'Snoozed';
    }
  }),

  assigneeIdentifier: computed('unassigned', 'assignee_id', function () {
    if (this.unassigned) {
      return 'unassigned';
    }
    return this.assignee_id;
  }),

  isLastPartARuleAssignment: reads('lastPart.isRuleAssignment'),
  isLastPartAdminAction: computed('lastPart', function () {
    let subtype = this.get('lastPart.sub_type');
    let body = this.get('lastPart.body');
    return (
      (subtype === 'assignment' && isBlank(body)) ||
      subtype === 'close' ||
      subtype === 'open' ||
      subtype === 'priority_changed'
    );
  }),

  isAssignedTo(admin) {
    return this.hasAssignee && parseInt(this.assignee_id, 10) === parseInt(admin.get('id'), 10);
  },

  _getTimeForDate(timestamp) {
    // the property used to get `timestamp` may be undefined, eg when loading conversations from sync
    if (!timestamp) {
      return;
    }
    return new Date(timestamp).getTime();
  },

  addParts(parts) {
    return this.syncedPartList.addParts(parts);
  },

  removePart(part) {
    return this.syncedPartList.removePart(part);
  },

  _setUpdatedAt() {
    let lastPartCreatedAt = this.get('lastPart.created_at');
    let convoUpdatedAt = this.updated_at;
    // only ever go forward in time.
    if (!convoUpdatedAt || new Date(lastPartCreatedAt) > new Date(convoUpdatedAt)) {
      this.set('updated_at', lastPartCreatedAt);
    }
  },

  _setSortingUpdatedAt() {
    let lastNonPassivePart = this.sortedParts
      .slice()
      .reverse()
      .find((p) => p.get('nonPassive'));
    let convoSortingUpdatedAt = this.sorting_updated_at;
    if (
      !!lastNonPassivePart &&
      (!convoSortingUpdatedAt ||
        new Date(lastNonPassivePart.get('created_at')) > new Date(this.sorting_updated_at))
    ) {
      this.set('sorting_updated_at', lastNonPassivePart.get('created_at'));
    }
  },

  async handleRealtimeEventIfShould(event) {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    if (!this.shouldHandleRealtimeEvent(event)) {
      return;
    }

    await this.syncedPartList.sync();
    this.updatedByRealtimeEvent(event);
  },

  shouldHandleRealtimeEvent(event) {
    if (event.conversationId !== this.id) {
      // The event is for a different conversation
      return false;
    }

    let eventClientSideUUID = event.clientAssignedUuid;
    return eventClientSideUUID
      ? !this.sortedParts.mapBy('client_assigned_uuid').includes(eventClientSideUUID)
      : // The event corresponds to a client that is not Embercom/Messenger. Handle it!
        true; // eslint-disable-line operator-linebreak
  },

  updatedByRealtimeEvent(event) {
    try {
      switch (event.eventName) {
        case 'ConversationAttributesUpdated':
          return this.updateConversationAttribute(event.updatedAttributes);
        default:
          return this.genericRealtimeUpdate(event);
      }
    } catch (e) {
      // no-op
    }
  },

  updateConversationAttribute(updatedAttributes) {
    updatedAttributes.map(({ id, value }) => {
      let exisitingRecord = this.store.peekRecord(
        'conversation-attributes/conversation-attribute-value',
        id,
      );
      if (exisitingRecord) {
        exisitingRecord.value = value;
      } else {
        let createdRecord = this.store.push(
          this.store.normalize('conversation-attributes/conversation-attribute-value', {
            id,
            value,
          }),
        );
        this.conversationAttributes.pushObject(createdRecord);
      }
    });
  },

  genericRealtimeUpdate(event) {
    try {
      if (event.assigneeId !== undefined) {
        this.set('assignee_id', event.assigneeId === null ? '' : event.assigneeId.toString());
      }

      if (this.appService.app?.canAssignToTeamAndTeammate) {
        if (event.teamAssigneeId !== undefined) {
          this.set(
            'team_assignee_id',
            event.teamAssigneeId === null ? '' : event.teamAssigneeId.toString(),
          );
        }

        if (event.adminAssigneeId !== undefined) {
          this.set(
            'admin_assignee_id',
            event.adminAssigneeId === null ? '' : event.adminAssigneeId.toString(),
          );
        }
      }

      if (event.title !== undefined) {
        this.set('title', event.title);
      }

      if (event.current_channel !== undefined && event.current_channel !== null) {
        this.set('current_channel', event.current_channel);
      }

      if (event.state !== undefined && this.state !== event.state) {
        this.set('state', event.state);
      }

      if (event.priority !== undefined && this.priority !== event.priority) {
        this.set('priority', event.priority);
      }

      if (event.next_breach_time !== undefined) {
        this.set('next_breach_time', event.next_breach_time);
      }

      if (event.conversation_sla_id !== undefined && event.conversation_sla_id !== null) {
        this.set('conversation_sla_id', event.conversation_sla_id);
      }

      if (event.subType === 'conversation_tags_updated') {
        this.taggings.reload();
      }

      if (event.lastActivityOwnerId !== undefined) {
        let isEventFromUser = event.lastActivityOwnerId === null;
        if (isEventFromUser) {
          this.set('userIsTyping', false);
        } else {
          this.clearAdminTypingState();
        }
      }
    } catch (e) {
      // no-op
    }
  },

  handleExternalReplySendStateRealtimeEvent(event) {
    let conversationPartId = event.commentId;
    let conversationPart = this.sortedParts.findBy('partId', conversationPartId);

    if (conversationPart) {
      conversationPart.set('external_reply_send_state', {
        send_state: event.sendState,
        info_message: event.infoMessage,
      });
    }
  },

  updateConversationReadAt() {
    let readAt = new Date().toISOString();
    this.set('read_at', readAt);
  },

  updateUserIsTyping() {
    this.set('userIsTyping', true);

    if (this.userIsTypingTimer) {
      cancel(this.userIsTypingTimer);
    }
    this.set(
      'userIsTypingTimer',
      later(
        this,
        function () {
          if (!(this.isDestroyed || this.isDestroying)) {
            this.set('userIsTyping', false);
          }
        },
        ENV.APP.cancel_user_is_typing_timeout,
      ),
    );
  },

  typingAdmin: null,
  typingAdminPartType: null, // can be 'note' or 'comment'
  anotherAdminIsTypingANote: equal('typingAdminPartType', 'note'),
  anotherAdminIsTypingAComment: equal('typingAdminPartType', 'comment'),

  updateAdminIsTyping(admin, action) {
    if (!this.typingAdmin || this.typingAdmin === admin) {
      this.setProperties({
        typingAdminPartType: action,
        typingAdmin: admin,
      });
    }
    if (this.adminIsTypingTimer) {
      cancel(this.adminIsTypingTimer);
    }
    this.set(
      'adminIsTypingTimer',
      later(this, this.clearAdminTypingState, ENV.APP.isTypingTimeout),
    );
  },

  clearAdminTypingState() {
    if (!this.isDestroying) {
      this.setProperties({
        typingAdminPartType: null,
        typingAdmin: null,
      });
    }
  },

  createDraftForAdmin(admin) {
    let draft = this.store.createRecord('conversation-draft', {
      app_id: this.app_id,
      conversation_id: this.id,
      subclass: null,
      blocks: [],
    });

    this.drafts.pushObject(draft);
    return draft;
  },

  partTasks: taskGroup().enqueue(),

  updateDraft: task(function* (draft) {
    yield draft.save();
  }).group('partTasks'),

  deleteDraft: task(function* () {
    let draft = this.draft;
    this.set('drafts', []);
    if (draft && !draft.isDeleted) {
      yield draft.destroyRecord();
    }
  }).group('partTasks'),

  savePart: task(function* (part) {
    if (part.get('isWaitingSinceInfluencingPart')) {
      this.set('waiting_since', this._calculateWaitingSinceForAdminPart(part));
    }
    try {
      part.set('hasErrored', false);
      yield part.save();
      if (part.get('isReply')) {
        this.incrementProperty('reply_count');
      }
    } catch (error) {
      part.updateError(error);
      throw error;
    }
  }).group('partTasks'),

  saveParts(parts) {
    return ajax({
      url: '/ember/conversation_parts/multi_create',
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.app_id,
        conversation_id: this.id,
        parts,
      }),
    });
  },

  saveMultipleParts: task(function* (parts) {
    try {
      parts.setEach('hasErrored', false);
      yield this.saveParts(parts);
      this.incrementProperty('reply_count');
      parts.forEach((part) => {
        part._internalModel.adapterWillCommit();
        part._internalModel.adapterDidCommit();
      });
    } catch (error) {
      parts.forEach((part) => {
        part.updateError(error);
      });
      throw error;
    }
  }).group('partTasks'),

  async markMentionsAsRead() {
    if (!this.has_unread_mentions) {
      return;
    }
    await ajax({
      url: '/ember/mentions/mark_conversation_as_read.json',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.app_id,
        conversation_id: this.id,
      }),
    });
    this.set('has_unread_mentions', false);
  },

  markAsReadForAdmin() {
    if (!this.adminHasNotRead) {
      return;
    }
    this.set('admin_has_read', true);
    return ajax({
      url: '/ember/conversations/mark_as_read.json',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.app_id,
        conversation_ids: [this.id],
      }),
    });
  },
  addParticipant(email) {
    return ajax({
      url: '/ember/conversation_participants.json',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.app_id,
        conversation_id: this.id,
        email_address: email,
      }),
    }).then((response) => {
      this.store.pushPayload({ participants: response.users });
      let added_participants = response.users.map((user) => {
        return this.store.peekRecord('participant', user.id);
      });
      added_participants.forEach((participant) => {
        if (!this.participants.findBy('id', participant.get('id'))) {
          this.participants.pushObject(participant);
        }
      });
      return added_participants[0];
    });
  },
  removeParticipant(user) {
    let participant = this.participants.findBy('id', user.get('id'));
    this.participants.removeObject(participant);

    return ajax({
      url: `/ember/conversation_participants.json?app_id=${this.app_id}&conversation_id=${this.id}&user_id=${user.id}`,
      type: 'DELETE',
      dataType: 'text',
    }).catch((error) => {
      this.participants.pushObject(participant);
      throw error;
    });
  },
  fetchSuggestions() {
    return ajax({
      url: `/ember/conversations/${this.id}/article_suggestions.json`,
      type: 'GET',
      data: {
        app_id: this.app_id,
      },
    }).catch(() => {
      return { suggestions: [] };
    });
  },
}).reopenClass({
  CARD_ANIMATION_TIME,

  newSearch(params) {
    return ajax({
      url: this.searchUrl(params),
      type: 'GET',
      data: params,
    });
  },

  searchUrl(params) {
    if (params._data_source === 'elasticsearch') {
      return '/ember/conversations/search.json';
    }
    if (params.user_id) {
      return '/ember/conversations/for_user.json';
    }
    if (params.company_id) {
      return '/ember/conversations/for_company.json';
    }
    if (params.assignee_identifier === 'mentions') {
      return '/ember/conversations/list_mentions.json';
    }
    if (params.assignee_identifier?.startsWith('view:')) {
      return '/ember/conversations/inbox_view.json';
    }
    return '/ember/conversations.json';
  },

  bulkAction(app, conversations, actionType, assignee, snoozeKey) {
    let conversationIds = conversations.mapBy('id');
    let params = {
      app_id: app.get('id'),
      conversation_ids: conversationIds,
      operation: actionType,
    };
    if (actionType === 'assignment') {
      params.assignee_id = assignee.get('id');
    }
    if (actionType === 'snoozed') {
      params.snoozed_until = snoozeKey;
      params.author_timezone = moment.tz.guess();
    }
    if (actionType === 'changed_priority') {
      params.priority = CONVERSATION_PRIORITIES.priority;
    }
    return ajax({
      url: '/ember/conversations/bulk.json',
      type: 'POST',
      data: JSON.stringify(params),
    });
  },
});
