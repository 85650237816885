/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { getOwner } from '@ember/application';
import waitEditorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/series/wait';
import { seriesWaitTypes } from 'embercom/models/data/matching-system/matching-constants';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import WaitValidations from 'embercom/validations/wait';

export default class SeriesWait extends Model.extend(WaitValidations) {
  @attr('number') declare type: number;
  @attr('number') declare timeDelay: number;
  @service declare appService: any;
  @service declare seriesEditorService: any;

  get series() {
    return this.seriesEditorService.series;
  }

  get isRelative() {
    return this.type === seriesWaitTypes.relative;
  }

  get isAbsolute() {
    return this.type === seriesWaitTypes.absolute;
  }

  get timeDelayAsMoment() {
    if (this.appService?.app) {
      return moment.unix(this.timeDelay).tz(this.appService?.app?.timezone);
    } else {
      return moment.unix(this.timeDelay);
    }
  }

  editorConfig() {
    let container = getOwner(this);
    return waitEditorConfigGenerator({ container, wait: this });
  }
}
