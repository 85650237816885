/* RESPONSIBLE TEAM: team-ml */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { ALL_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';
import { attr } from '@ember-data/model';
import type { Block } from 'embercom/models/common/blocks/block';

export default class FinCustom extends Step {
  @attr() declare blocks: Array<Block>;

  supportedChannels = ALL_CHANNELS;

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store): FinCustom {
    let blocks = [
      store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: '', // let the component decide what the default or placeholder text should be
      }),
    ];

    return store.createRecord('operator/visual-builder/step/fin-custom', {
      type: 'operator/visual-builder/step/fin-custom',
      blocks,
    });
  }
}
