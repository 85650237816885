/* RESPONSIBLE TEAM: team-proactive-support */
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import Model, { attr, hasMany } from '@ember-data/model';
import ChatValidations from 'embercom/validations/chat';
import chatEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/chat';
import chatSelectionSetConfigGenerator from 'embercom/objects/selection-set/configuration-generators/chat';
import { dependentKeyCompat } from '@ember/object/compat';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import {
  hasiOSSessionsPredicate,
  hasAndroidSessionsPredicate,
} from 'embercom/models/mobile-push/push';

export const replyTypeOptions = {
  text: 0,
  emailCollector: 1,
};

export const hasUnknownEmailPredicate = {
  attribute: 'email',
  type: 'string',
  comparison: 'unknown',
  value: '',
};

export default class Chat extends Model.extend(ChatValidations) {
  @service store;

  @attr('number') replyType;
  @attr('number') sentAs;
  @attr() sendPushNotification;
  @attr('array') preferredDevices;
  @attr('string') showUntil;
  @hasMany('chats/localized-chat-content', { inverse: 'chat' }) localizedChatContents;

  @dependentKeyCompat
  get contentHash() {
    return this.localizedChatContents.map(
      (localizedChatContent) => localizedChatContent.contentHash,
    );
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.localizedChatContents.any((localizedContent) => localizedContent.hasUnsavedChanges)
    );
  }

  get localizedContents() {
    return this.localizedChatContents;
  }

  @dependentKeyCompat
  get hasEmailCollector() {
    return this.replyType === replyTypeOptions.emailCollector;
  }

  @dependentKeyCompat
  get implicitPredicates() {
    // Any changes to this function should be made to the backend version too.
    let predicates = [];

    if (
      this.preferredDevices.length === 2 &&
      this.preferredDevices.includes(preferredDevices.ios) &&
      this.preferredDevices.includes(preferredDevices.android)
    ) {
      predicates.push({
        type: 'or',
        predicates: [hasAndroidSessionsPredicate, hasiOSSessionsPredicate],
      });
    } else if (
      this.preferredDevices.length === 1 &&
      this.preferredDevices[0] === preferredDevices.android
    ) {
      predicates.push(hasAndroidSessionsPredicate);
    } else if (
      this.preferredDevices.length === 1 &&
      this.preferredDevices[0] === preferredDevices.ios
    ) {
      predicates.push(hasiOSSessionsPredicate);
    }

    if (this.isEmailCollectorReply) {
      predicates.push(hasUnknownEmailPredicate);
    }
    return predicates;
  }

  editorConfig() {
    let container = getOwner(this);
    return chatEditConfigGenerator({ container });
  }

  selectionSetConfig() {
    let container = getOwner(this);
    return chatSelectionSetConfigGenerator({ container });
  }

  get isTextReply() {
    return this.replyType === replyTypeOptions.text;
  }

  get isEmailCollectorReply() {
    return this.replyType === replyTypeOptions.emailCollector;
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this.localizedChatContents.forEach((content) => content.rollbackAttributes());
  }
}
