/* RESPONSIBLE TEAM: team-product-exploration */

import { tracked } from '@glimmer/tracking';
import { setOwner } from '@ember/application';
import type ApplicationInstance from '@ember/application/instance';

export default abstract class Action {
  abstract id: string;
  abstract component: string;
  abstract get matches(): boolean;
  abstract get priority(): number;
  abstract get isEnabled(): boolean;

  abstract onSelect(): Promise<void>;
  abstract onQueryChange(query: string): void;
  abstract didBecomeActive(): void;

  @tracked parent?: Action;
  @tracked children: Array<Action> = [];
  @tracked currentQuery = '';

  depth: number;

  constructor(owner: ApplicationInstance, parent?: Action) {
    setOwner(this, owner);
    this.parent = parent;
    this.depth = parent ? parent.depth + 1 : 0;
    parent?.children.push(this);
  }

  get interfaceId(): string {
    return `global-search-item-${this.id}`;
  }

  setQuery(query: string) {
    this.currentQuery = query;
    this.onQueryChange(query);
  }

  flattened(): Array<Action> {
    let children = this.children?.flatMap((child) => child.flattened());
    return [this, ...(children ?? [])];
  }

  get isRoot(): boolean {
    return !this.parent;
  }

  get isChild(): boolean {
    return !!this.parent;
  }
}
