/* RESPONSIBLE TEAM: team-reporting */
import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
interface Args {
  named: {};
  positional: never[];
}

export default class AutoCollapseNav extends Modifier<Args> {
  @service declare navbarCollapsingService: any;

  modify(_element: HTMLElement, _positional: Args['positional'], _config: Args['named']) {
    if (this.navIsExpanded) {
      scheduleOnce('afterRender', this, this.navbarCollapsingService.toggleNavbarCollapsed);
    }
  }

  private get navIsExpanded() {
    return !this.navbarCollapsingService.collapsed;
  }
}
