/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';
import StandardInserterComponentConfiguration from 'embercom/objects/series/node/standard-inserter-component-configuration';
import StandardLoadingComponentConfiguration from 'embercom/objects/series/node/standard-loading-component-configuration';
import {
  objectTypes,
  humanReadableObjectNames,
  objectFeatures,
} from 'embercom/models/data/matching-system/matching-constants';
import { OUTBOUND_BOT_TYPE } from 'embercom/lib/operator/custom-bots/constants';
import { capitalize } from '@ember/string';

export default class BotInserterConfiguration extends InserterConfiguration {
  objectType = objectTypes.customBot;
  requiredFeatures = [objectFeatures[objectTypes.customBot]];

  get objectData() {
    return {
      type: OUTBOUND_BOT_TYPE,
    };
  }

  componentConfig = new StandardInserterComponentConfiguration({
    icon: 'series/content/workflow',
    paywallIcon: 'series/content/paywalled-workflow',
    featureFlagsWithAccess: [objectFeatures[objectTypes.customBot]],
    title: capitalize(humanReadableObjectNames[this.objectType]),
  });
  loadingComponentConfig = new StandardLoadingComponentConfiguration({
    icon: 'series/content/workflow',
    title: `Empty ${humanReadableObjectNames[this.objectType]}`,
    objectType: this.objectType,
  });

  canInsertNode({ app, _dataObject }) {
    return true;
  }
}
