/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { readOnly, notEmpty, and, or, empty } from '@ember/object/computed';

export default Model.extend({
  billAhead: attr(),
  description: attr(),
  label: attr(),
  metadata: attr(),
  mrrAmount: attr(),
  periodEnd: attr(),
  periodStart: attr(),
  postSubtotalType: attr(),
  priceInCents: attr(),

  // Metadata properties
  userCount: readOnly('metadata.user_count'),
  peopleCount: readOnly('metadata.people_count'),
  agentCount: readOnly('metadata.agent_count'),
  educateActive: readOnly('metadata.educate_active'),
  perProductLineItem: readOnly('metadata.per_product_line_item'),
  preDiscountCost: readOnly('metadata.pre_discount_cost'),
  discountAmount: readOnly('metadata.discount_amount'),
  hasPreDiscountCost: notEmpty('preDiscountCost'),

  canDisplayMrrAmount: and('mrrAmount', 'metadata.product_and_plan_names'),

  hasUserCount: notEmpty('userCount'),
  hasPeopleCount: notEmpty('peopleCount'),
  hasUserOrPeopleCount: or('hasUserCount', 'hasPeopleCount'),

  productAndPlanNames: computed('metadata.product_and_plan_names', function () {
    let productAndPlanNames = this.get('metadata.product_and_plan_names');
    if (!productAndPlanNames) {
      return '';
    }
    return productAndPlanNames.sort().join(', ');
  }),

  trueUpOveragePrice: computed(
    'metadata.price_per_block',
    'metadata.additional_blocks',
    function () {
      let pricePerBlock = this.get('metadata.price_per_block');
      let additionalBlocks = this.get('metadata.additional_blocks');
      return pricePerBlock * additionalBlocks;
    },
  ),

  hasNoMetadata: empty('metadata'),
});
