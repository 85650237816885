/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import {
  ENGAGE_SEAT_TYPE as ENGAGE,
  CONVERT_SEAT_TYPE as CONVERT,
  SUPPORT_SEAT_TYPE as SUPPORT,
  COLLABORATOR_SEAT_TYPE as COLLABORATOR,
  PRICING_5_X_FULL_SEAT_TYPE as CORE,
  PRICING_5_X_LITE_SEAT_TYPE as LITE,
} from 'embercom/lib/settings/seats/constants';

const DEPENDANT_SEAT_TYPES_VBP2 = [SUPPORT, ENGAGE, CONVERT, COLLABORATOR];

const DEPENDANT_SEAT_TYPES_PRICING_5_X = [CORE, LITE];

export function getUnallowedPermissionsForSeats(seatTypes, seatPermissionMap) {
  let effectiveSeatTypes = seatsWithTiers(seatTypes);

  return Object.keys(seatPermissionMap).filter((permissionKey) => {
    let allowedSeatTypes = seatPermissionMap[permissionKey];

    return !effectiveSeatTypes.some((seatType) => allowedSeatTypes.includes(seatType));
  });
}

export function getSeatDependentPermissions(seatPermissionMap, isPricing5) {
  let dependentSeatTypes = isPricing5
    ? DEPENDANT_SEAT_TYPES_PRICING_5_X
    : DEPENDANT_SEAT_TYPES_VBP2;

  return Object.keys(seatPermissionMap).filter((permissionKey) => {
    return dependentSeatTypes.some(
      (seatType) => !seatPermissionMap[permissionKey].includes(seatType),
    );
  });
}

export function getRequiredSeatTypesForPermission(permissionKey, seatPermissionMap) {
  return seatPermissionMap[permissionKey];
}

function seatsWithTiers(seatTypes) {
  // TODO: implement tier logic here
  return seatTypes;
}
