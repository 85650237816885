/* RESPONSIBLE TEAM: team-growth-opportunities */
import Model, { attr } from '@ember-data/model';

export default class Signup extends Model {
  @attr('boolean') authenticatedAdmin;
  @attr('boolean') viaSingleSignOn;

  async state() {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);
    let response = await adapter.state();
    this.setProperties({
      authenticatedAdmin: response.authenticated_admin,
      viaSingleSignOn: response.via_single_sign_on,
    });
    return this;
  }
}
