/* RESPONSIBLE TEAM: team-workflows */
import type ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';
import StepConfig, { type StepConfigParams } from '../step';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
// eslint-disable-next-line no-restricted-imports
import containerLookup from 'embercom/lib/container-lookup';
import type AttributeDescriptor from 'embercom/models/workflow-connector/attribute-descriptor';

const DEFAULT_CHAT_MESSAGE_COMPONENT = 'workflows/graph-editor/node-items/steps/chat-message';

export default class ChatMessageConfiguration extends StepConfig {
  declare step: ChatMessage;
  protected attributeService: any;

  constructor(
    params: Omit<StepConfigParams, 'component'> & { component?: string; attributeService: any },
  ) {
    super({
      component: DEFAULT_CHAT_MESSAGE_COMPONENT,
      ...params,
    });

    this.attributeService = params.attributeService;
  }

  get attributeInfoResolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: true,
      includeCustomObjectAttributes: true,
      localAttributes: this.step.editorConfig?.localAttributeGroupList,
    });
  }
}

export class OutboundBotChatMessageConfiguration extends ChatMessageConfiguration {
  contentEditorService: any;

  constructor(args: any) {
    super(args);

    this.contentEditorService = containerLookup('service:content-editor-service');
  }

  get deleteTooltipText() {
    return !this.canDeleteStep
      ? this.intl.t(
          'operator.workflows.visual-builder.step-option-menu.delete-disabled-tooltip-chat-message',
        )
      : undefined;
  }

  get attributeInfoResolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: true,
      includeCustomObjectAttributes: true,
      selectedEvents: this.selectedEvents,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }
}

export class ButtonBotChatMessageConfiguration extends ChatMessageConfiguration {
  constructor(args: any) {
    super(args);
  }

  get deleteTooltipText() {
    return !this.canDeleteStep
      ? this.intl.t(
          'operator.workflows.visual-builder.step-option-menu.delete-disabled-tooltip-chat-message',
        )
      : undefined;
  }
}
export class NewPhoneCallChatMessageConfiguration extends ChatMessageConfiguration {
  constructor(
    params: Omit<StepConfigParams, 'component'> & { component?: string; attributeService: any },
  ) {
    super({
      ...params,
      component: 'workflows/graph-editor/node-items/steps/phone-message',
    });
  }
}

export class WebhookReceivedChatMessageConfiguration extends ChatMessageConfiguration {
  protected webhookReceivedAttributes: Array<AttributeDescriptor>;

  constructor(
    params: Omit<StepConfigParams, 'component'> & {
      component?: string;
      attributeService: any;
      webhookReceivedAttributes: Array<AttributeDescriptor>;
    },
  ) {
    super({
      component: 'workflows/graph-editor/node-items/steps/chat-message',
      ...params,
    });
    this.webhookReceivedAttributes = params.webhookReceivedAttributes;
  }

  get attributeInfoResolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: true,
      includeCustomObjectAttributes: true,
      webhookReceivedAttributes: this.webhookReceivedAttributes,
    });
  }
}
