/* RESPONSIBLE TEAM: team-proactive-support */
import PanelConfiguration from 'embercom/objects/content-editor/panel-configuration';
import PushHeaderConfiguration from './header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import { assert } from '@ember/debug';
import { contentEditors } from 'embercom/models/data/matching-system/matching-constants';

export default PanelConfiguration.extend({
  init(inputs) {
    // The container gives us access to Services in these objects. Pass `container: getOwner(this)` as an
    // input when generating these configuration objects.
    assert('You must provide the application container in your panel inputs', inputs.container);

    let header = PushHeaderConfiguration.create(
      {
        title: inputs.title,
      },
      { container: inputs.container },
    );
    let body = ComponentConfiguration.create({
      component: 'content-editor/panels/content/push/body',
    });
    this._super({
      name: 'content',
      header,
      body,
      supportedEditors: [contentEditors.standalone, contentEditors.series],
    });
  },
});
