/* RESPONSIBLE TEAM: team-channels */
export interface PageViewWireFormat {
  id: string;
  created_at: string;
  url: string;
  page_title: string;
}

export default class PageView {
  readonly id: string;
  readonly createdAt: string;
  readonly url: string;
  readonly pageTitle: string;

  constructor(id: string, createdAt: string, url: string, pageTitle: string) {
    this.id = id;
    this.createdAt = createdAt;
    this.url = url;
    this.pageTitle = pageTitle;
  }

  static deserialize(json: PageViewWireFormat) {
    return new PageView(json.id, json.created_at, json.url, json.page_title);
  }
}
