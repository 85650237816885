/* RESPONSIBLE TEAM: team-proactive-support */
import TabConfiguration from './tab-configuration';
import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';
import { not, readOnly } from '@ember/object/computed';

export default TabConfiguration.extend({
  contentEditorService: service(),
  label: undefined,
  isNotDraft: not('contentEditorService.ruleset.isDraft'),
  isVisible: readOnly('isNotDraft'),

  init(inputs) {
    inputs.component = 'content-editor/tabs/reporting-content-tab';
    inputs.view = 'reports';

    this._super(...arguments);

    assert('A label must be passed for the standard content tab configuration', inputs.label);

    this.setProperties({
      label: inputs.label,
    });
  },
});
