/* RESPONSIBLE TEAM: team-workflows */

import Model, { attr, belongsTo } from '@ember-data/model';
import type ListOption from 'embercom/models/conversation-attributes/list-option';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import type { EntityType } from 'embercom/models/data/entity-types';

export enum Type {
  string = 'string',
  integer = 'integer',
  float = 'float',
  date = 'date',
  boolean = 'boolean',
  conversation_attribute_list = 'conversation_attribute_list',
  object = 'object',
  array = 'array',
}

export type SourceObject = {
  type: EntityType;
  id: string;
  path: string;
};

export type ChildAttributeDescriptor = {
  id: string;
  source_path: string;
};

export default class AttributeDescriptor extends Model {
  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('string') declare type: Type;
  @attr('array') declare listInputOptions: ListOption[];
  @attr('boolean') declare referenceOnly: boolean;
  @attr() declare sourceObject: SourceObject;
  @attr('array') declare children: ChildAttributeDescriptor[];

  @belongsTo('operator/visual-builder/workflow', {
    async: false,
    inverse: 'attributeDescriptors',
  })
  declare workflow: Workflow;

  get identifier(): string {
    return `workflow_local_variables.${this.id}`;
  }

  get templatable_identifier(): string {
    return this.identifier;
  }

  get icon() {
    switch (this.type) {
      case 'string':
        return 'recipient-data';
      case 'integer':
        return 'count';
      case 'float':
        return 'decimal';
      case 'boolean':
        return 'boolean';
      case 'date':
        return 'calendar';
      case 'conversation_attribute_list':
        return 'list';
      default:
        return 'insert-data';
    }
  }
}
