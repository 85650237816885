/* RESPONSIBLE TEAM: team-proactive-support */
import EmberObject from '@ember/object';
import { isPresent } from '@ember/utils';

export default class TemplateInfo {
  store = undefined;
  emailTemplate = undefined;
  senderName = undefined;
  avatar = undefined;
  showUnsubscribeLink = true;

  constructor(inputs) {
    let { emailTemplate, store, senderName, avatar, showUnsubscribeLink, isGranularUnsubscribe } =
      inputs;
    this.emailTemplate = emailTemplate;
    this.store = store;
    this.senderName = senderName;
    this.avatar = avatar;
    this.showUnsubscribeLink = showUnsubscribeLink;
    this.isGranularUnsubscribe = isGranularUnsubscribe;
  }

  // The composer itself mutates the data that we pass to it. This method
  // converts the object to the POJO that the composer expects.
  get composerHash() {
    return {
      name: this.name,
      html: this.html,
      data: this.data,
      emailTemplateData: this.emailTemplateData,
    };
  }

  get data() {
    return {
      unsubscribe_link: this.unsubscribeLink,
      unsubscribe_url: '',
      footerVisibilityClass: this.footerVisibilityClass,
      unsubscribeLinkClass: this.unsubscribeLinkClass,
      admin_avatar: this.avatarURL,
      hide_avatar: isPresent(this.avatarURL) ? '' : 'hidden',
      admin_name_and_app: this.senderName,
    };
  }

  get avatarURL() {
    let url = this.avatar?.image_urls?.square_50;
    if (isPresent(url)) {
      return `<img src="${url}" height="40" width="40" class="avatar" alt="intercomavatar">`;
    } else {
      return ``;
    }
  }

  get name() {
    return `${this.emailTemplate.id}-desktop`;
  }

  get html() {
    return this.emailTemplate.template;
  }

  get unsubscribeLink() {
    if (this.showUnsubscribeLink) {
      if (this.isGranularUnsubscribe) {
        return `<a href="#" class="ic-unsubscribe-link">Unsubscribe from this list</a>`;
      }
      return `<a href="#" class="ic-unsubscribe-link">Unsubscribe from our emails</a>`;
    }
    return '';
  }

  get unsubscribeLinkClass() {
    return this.showUnsubscribeLink ? `` : 'hidden';
  }

  get unsubscribeUrl() {
    return ``;
  }

  get footerVisibilityClass() {
    return ``;
  }

  get emailTemplateData() {
    return this.store.peekAll('email-template-data').map((model) =>
      EmberObject.create({
        identifier: model.identifier,
        value: model.value,
      }),
    );
  }
}
