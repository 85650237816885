/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import MF from 'ember-data-model-fragments';

export default MF.Fragment.extend({
  attachmentId: attr(),
  name: attr(),
  size: attr(),
  contentType: attr(),
  url: attr(),
});
