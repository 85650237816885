/* RESPONSIBLE TEAM: team-proactive-support */
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class ChecklistSharingHeaderConfiguration extends StandardPanelHeaderConfiguration {
  @service contentEditorService;
  @service intl;

  get errors() {
    return [];
  }

  get isComplete() {
    return isEmpty(this.errors);
  }

  init(inputs) {
    inputs.component = 'content-editor/panels/custom/checklist-sharing/header';
    inputs.title = this.intl.t(
      'outbound.content-editor.panels.checklist-sharing.shareable-panel-header',
    );
    super.init(...arguments);
  }
}
