/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment } from 'ember-data-model-fragments/attributes';
import {
  emojiScaleOptions,
  responseOrderTypes,
  scaleTypes,
  validationTypes,
} from 'embercom/models/data/survey/constants';
import { A } from '@ember/array';
import { isBlank } from '@ember/utils';
import { dependentKeyCompat } from '@ember/object/compat';

const DEFAULT_VALIDATION_VALUE = {
  type: validationTypes.text,
};

let scaleDefaults = {
  scaleStart: 1,
  scaleEnd: 5,
  lowerLabel: 'Not satisfied',
  upperLabel: 'Very satisfied',
};

export default class QuestionData extends Fragment {
  @attr('number') characterLimit;
  @attr('boolean', { defaultValue: () => false }) includeOther;
  @attr('string') lowerLabel;
  @attr('number') minimumSelection;
  @attr('number') maximumSelection;
  @attr('array', { defaultValue: () => A([]) }) options;
  @attr('boolean', { defaultValue: () => true }) required;
  @attr('number') scaleStart;
  @attr('number') scaleEnd;
  @attr('number') type;
  @attr('string') upperLabel;
  @attr('string') placeholder;
  @attr('number', { defaultValue: () => responseOrderTypes.default }) responseOrder;

  @fragment('surveys/question-validation', {
    defaultValue: DEFAULT_VALIDATION_VALUE,
  })
  validation;

  _resetData() {
    this.characterLimit = undefined;
    this.lowerLabel = undefined;
    this.options = A([]);
    this.required = true;
    this.scaleStart = undefined;
    this.scaleEnd = undefined;
    this.upperLabel = undefined;
    this.placeholder = undefined;
    this.type = undefined;
    this.responseOrder = responseOrderTypes.default;
    this.includeOther = false;
    this.minimumSelection = 0;
    this.maximumSelection = 1;
    this.validation = DEFAULT_VALIDATION_VALUE;
  }

  _setDefaultNPSData() {
    this.type = scaleTypes.nps;
    this.scaleStart = 0;
    this.scaleEnd = 10;
    this.lowerLabel = 'Not likely';
    this.upperLabel = 'Very likely';
  }

  _setDefaultNumericData() {
    this.type = scaleTypes.numeric;
    this.scaleStart = scaleDefaults.scaleStart;
    this.scaleEnd = scaleDefaults.scaleEnd;
    this.lowerLabel = scaleDefaults.lowerLabel;
    this.upperLabel = scaleDefaults.upperLabel;
  }

  _setDefaultStarsData() {
    this.type = scaleTypes.stars;
    this.scaleStart = scaleDefaults.scaleStart;
    this.scaleEnd = scaleDefaults.scaleEnd;
    this.lowerLabel = '';
    this.upperLabel = '';
  }

  _setDefaultEmojiData() {
    this.type = scaleTypes.emoji;
    this.scaleStart = scaleDefaults.scaleStart;
    this.scaleEnd = scaleDefaults.scaleEnd;
    this.lowerLabel = '';
    this.upperLabel = '';
    this.options = emojiScaleOptions[scaleDefaults.scaleEnd];
  }

  _setValidationType(validationType) {
    if (Object.values(validationTypes).indexOf(validationType) < 0) {
      return;
    }

    this.validation = { type: validationType };
  }

  get isMultiSelect() {
    return this.maximumSelection > 1;
  }

  get isNumericScale() {
    return this.type === scaleTypes.numeric;
  }

  get isStarsScale() {
    return this.type === scaleTypes.stars;
  }

  get isEmojiScale() {
    return this.type === scaleTypes.emoji;
  }

  get ratingScaleRange() {
    let scaleStart = Number(this.scaleStart);
    let scaleEnd = Number(this.scaleEnd);
    return Array.from({ length: scaleEnd + 1 - scaleStart }, (_, index) => index + scaleStart);
  }

  get isLowerLabelBlank() {
    return isBlank(this.lowerLabel);
  }

  get isUpperLabelBlank() {
    return isBlank(this.upperLabel);
  }

  get isScaleSmall() {
    return Number(this.scaleEnd) <= 5;
  }

  get isScaleStartInvalid() {
    return (
      this.type === scaleTypes.numeric &&
      (isBlank(this.scaleStart) || this.scaleStart < 0 || this.scaleStart >= this.scaleEnd)
    );
  }

  get isScaleEndInvalid() {
    return (
      this.type === scaleTypes.numeric &&
      (isBlank(this.scaleEnd) || this.scaleEnd > 10 || this.scaleStart >= this.scaleEnd)
    );
  }

  get hasInvalidScaleRange() {
    return this.isScaleStartInvalid || this.isScaleEndInvalid;
  }

  get hasInvalidNumberOfOptions() {
    let numValidOptions = 0;
    this.options.forEach((option) => {
      if (option) {
        numValidOptions++;
      }
    });
    return numValidOptions < 1;
  }

  @dependentKeyCompat
  get hasDuplicatedOptions() {
    return this.options
      .filter((option) => option.length > 0)
      .some((option, index, arr) => arr.indexOf(option) !== index);
  }

  @dependentKeyCompat
  get hasEmptyOptions() {
    return this.options.some((option) => option.length === 0);
  }
}
