/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';
import StandardInserterComponentConfiguration from 'embercom/objects/series/node/standard-inserter-component-configuration';
import StandardLoadingComponentConfiguration from 'embercom/objects/series/node/standard-loading-component-configuration';
import {
  objectTypes,
  objectNames,
  objectFeatures,
} from 'embercom/models/data/matching-system/matching-constants';

export default class SMSInserterConfiguration extends InserterConfiguration {
  objectType = objectTypes['sms'];
  objectData = {};
  requiredFeatures = [objectFeatures[objectTypes.sms]];

  componentConfig = new StandardInserterComponentConfiguration({
    icon: 'series/content/sms',
    paywallIcon: 'series/content/paywalled-sms',
    featureFlagsWithAccess: [objectFeatures[objectTypes.sms], 'messages-sms-beta'],
    title: objectNames[this.objectType].toUpperCase(),
  });

  loadingComponentConfig = new StandardLoadingComponentConfiguration({
    icon: 'series/content/sms',
    paywallIcon: 'series/content/paywalled-sms',
    title: `Empty ${objectNames[this.objectType].toUpperCase()}`,
    objectType: this.objectType,
  });

  isTooltipDisabled(app) {
    return this._appHasSmsFeature(app);
  }

  disabledTooltipContent() {
    return 'You need to purchase the SMS addon to use SMS in Series';
  }

  canInsertNode({ app, _dataObject }) {
    return this._appHasSmsFeature(app);
  }

  _appHasSmsFeature(app) {
    return app.canUseFeature('sms') || app.canUseFeature('messages-sms-beta');
  }
}
