/* RESPONSIBLE TEAM: team-frontend-tech */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';

export const emptyBlock = {
  type: 'paragraph',
  text: '',
  modelKey: 'common/blocks/paragraph',
};
const emptyPredicateGroup = { predicates: [] };

export default class PredicatesBlocksPair extends Fragment {
  @attr({ defaultValue: () => [emptyBlock] }) blocks;
  @fragment('predicates/predicate-group', {
    defaultValue: () => emptyPredicateGroup,
  })
  predicateGroup;
}
