/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardSummaryConfiguration from '../../standard-summary-configuration';
import { and, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default StandardSummaryConfiguration.extend({
  init(_inputs) {
    let title = 'Trigger your carousel from code';
    this._super({
      title,
      component: 'content-editor/summaries/trigger-programmatically/carousel/summary',
    });
  },
  contentEditorService: service(),
  isNotSeries: not('contentEditorService.isSeriesMode'),
  hasNoControlGroup: not('contentEditorService.ruleset.hasControlGroup'),
  isVisible: and('isNotSeries', 'hasNoControlGroup'),
});
