/* RESPONSIBLE TEAM: team-channels */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';

interface MergedSecondaryConversationWireFormat {
  admin_id: string;
  user_id: string;
  user_display_name: string;
  merge_into_conversation_subject: string;
  merge_into_conversation_id: number;
}

export default class MergedSecondaryConversation implements RenderableData {
  renderableType: RenderableType = RenderableType.MergedSecondaryConversation;
  readonly adminId: string;
  readonly userId: string;
  readonly userDisplayName: string;
  readonly mergeIntoConversationSubject: string;
  readonly mergeIntoConversationId: number;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminId,
    };
  }

  constructor(
    adminId: string,
    userId: string,
    userDisplayName: string,
    mergeIntoConversationSubject: string,
    mergeIntoConversationId: number,
  ) {
    this.adminId = adminId;
    this.userId = userId;
    this.userDisplayName = userDisplayName;
    this.mergeIntoConversationSubject = mergeIntoConversationSubject;
    this.mergeIntoConversationId = mergeIntoConversationId;
  }

  static deserialize(json: MergedSecondaryConversationWireFormat): MergedSecondaryConversation {
    return new MergedSecondaryConversation(
      json.admin_id,
      json.user_id,
      json.user_display_name,
      json.merge_into_conversation_subject,
      json.merge_into_conversation_id,
    );
  }
}
