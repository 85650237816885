/* RESPONSIBLE TEAM: team-workflows */
import Model, { attr, belongsTo } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import type ArticleSuggestion from './article-suggestions';

export default class TaskBots extends Model {
  @attr('string') declare appId: string;
  @attr('boolean') declare leadDelayEnabled: boolean;
  @attr('boolean') declare userDelayEnabled: boolean;

  @belongsTo('operator-settings/team-availability', { async: false }) teamAvailability: any;
  @belongsTo('operator-settings/conversation-routing', { async: false }) conversationRouting: any;
  @belongsTo('operator-settings/conversation-rating', { async: false }) conversationRating: any;
  @belongsTo('operator-settings/article-suggestions', { async: false })
  declare articleSuggestions: ArticleSuggestion;
  @belongsTo('operator-settings/article-auto-reaction', { async: false }) articleAutoReaction: any;
  @belongsTo('operator-settings/contact-collection', { async: false }) contactCollection: any;
  @belongsTo('operator-settings/qualify-leads', { async: false }) qualifyLeads: any;
  @belongsTo('operator-settings/duplicate-conversation-detection', { async: false })
  duplicateConversationDetection: any;
  @belongsTo('operator-settings/upfront-collection', { async: false }) upfrontCollection: any;

  @computed(
    'teamAvailability.isNotDirty',
    'conversationRouting.isNotDirty',
    'conversationRating.isNotDirty',
    'articleSuggestions.isNotDirty',
    'contactCollection.isNotDirty',
    'qualifyLeads.isNotDirty',
    'upfrontCollection.isNotDirty',
  )
  get hasUnsavedChanges() {
    return !(
      this.teamAvailability?.isNotDirty &&
      this.conversationRouting?.isNotDirty &&
      this.conversationRating?.isNotDirty &&
      this.articleSuggestions?.isNotDirty &&
      this.contactCollection?.isNotDirty &&
      this.qualifyLeads?.isNotDirty &&
      this.upfrontCollection?.isNotDirty
    );
  }

  refresh() {
    this.teamAvailability?.rollbackAttributes();
    this.conversationRouting?.rollbackAttributes();
    this.conversationRating?.rollbackAttributes();
    this.articleSuggestions?.rollbackAttributes();
    this.contactCollection?.rollbackAttributes();
    this.qualifyLeads?.rollbackAttributes();
    this.upfrontCollection?.rollbackAttributes();
  }
}
