/* RESPONSIBLE TEAM: team-phone */
import type Voicemail from 'embercom/models/operator/visual-builder/step/voicemail';
import StepConfig, { type StepConfigParams } from '../step';

export default class VoicemailConfiguration extends StepConfig {
  declare step: Voicemail;

  constructor(params: Omit<StepConfigParams, 'component'> & { component?: string }) {
    super({
      component: 'workflows/graph-editor/node-items/steps/voicemail',
      ...params,
    });
  }

  get alignment() {
    return 'left' as const;
  }
}
