/* RESPONSIBLE TEAM: team-help-desk-experience */
interface MessengerAppWireFormat {
  id: string;
  name: string;
  description: string | null;
  icon_url: string | null;
  configure_url: string | null;
  installed: boolean;
  params: object[] | null;
  app_package_id: string;
  installed_at: string | null;
  available_in_operator: boolean;
  available_in_messenger_home: boolean;
  available_in_conversation: boolean;
  available_in_messages: boolean;
  notifiable: boolean;
  paywalled_in_operator: boolean;
  show_in_inserter: boolean;
}

export interface MessengerAppConstructorFormat {
  id: string;
  name: string;
  description?: string;
  iconUrl?: string;
  configureUrl?: string;
  params?: object[];
  installed: boolean;
  appPackageId: string;
  installedAt?: Date;
  availableInOperator: boolean;
  availableInMessengerHome: boolean;
  availableInConversation: boolean;
  availableInMessages: boolean;
  notifiable: boolean;
  paywalledInOperator: boolean;
  showInInserter: boolean;
}

export default class MessengerApp {
  readonly id: string;
  readonly name: string;
  readonly description?: string;
  readonly iconUrl?: string;
  readonly configureUrl?: string;
  readonly params?: Record<string, any>[];
  readonly installed: boolean;
  readonly appPackageId: string;
  readonly installedAt?: Date;
  readonly availableInOperator: boolean;
  readonly availableInMessengerHome: boolean;
  readonly availableInConversation: boolean;
  readonly availableInMessages: boolean;
  readonly notifiable: boolean;
  readonly paywalledInOperator: boolean;
  readonly showInInserter: boolean;

  constructor(inputs: MessengerAppConstructorFormat) {
    let {
      id,
      name,
      description,
      iconUrl,
      configureUrl,
      params,
      installed,
      appPackageId,
      installedAt,
      availableInOperator,
      availableInMessengerHome,
      availableInConversation,
      availableInMessages,
      notifiable,
      paywalledInOperator,
      showInInserter,
    } = inputs;
    this.id = id;
    this.name = name;
    this.description = description;
    this.iconUrl = iconUrl;
    this.configureUrl = configureUrl;
    this.params = params;
    this.installed = installed;
    this.appPackageId = appPackageId;
    this.installedAt = installedAt;
    this.availableInOperator = availableInOperator;
    this.availableInMessengerHome = availableInMessengerHome;
    this.availableInConversation = availableInConversation;
    this.availableInMessages = availableInMessages;
    this.notifiable = notifiable;
    this.paywalledInOperator = paywalledInOperator;
    this.showInInserter = showInInserter;
  }

  static intercomToursPackageId = 'intercom-tours';

  get hasConfiguration(): boolean {
    return Boolean((this.params && this.params.length) || this.configureUrl);
  }

  get commandkId(): string {
    return `app-${this.appPackageId}`;
  }

  get isIntercomTours(): boolean {
    return this.appPackageId === MessengerApp.intercomToursPackageId;
  }

  static deserialize(json: MessengerAppWireFormat): MessengerApp {
    let installedAt = undefined;
    if (json.installed_at !== null) {
      installedAt = new Date(json.installed_at);
    }
    return new MessengerApp({
      id: json.id,
      name: json.name,
      description: json.description === null ? undefined : json.description,
      iconUrl: json.icon_url === null ? undefined : json.icon_url,
      configureUrl: json.configure_url === null ? undefined : json.configure_url,
      params: json.params === null ? undefined : json.params,
      installed: json.installed,
      appPackageId: json.app_package_id,
      installedAt,
      availableInOperator: json.available_in_operator,
      availableInMessengerHome: json.available_in_messenger_home,
      availableInConversation: json.available_in_conversation,
      availableInMessages: json.available_in_messages,
      notifiable: json.notifiable,
      paywalledInOperator: json.paywalled_in_operator,
      showInInserter: json.show_in_inserter,
    });
  }
}
