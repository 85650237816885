/* RESPONSIBLE TEAM: team-data-interop */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type Action from 'embercom/models/workflow-connector/action';

export default class extends RulesetLink {
  @belongsTo('workflow-connector/action', { async: false }) declare object: Action;

  get objectType() {
    return objectTypes.workflowConnectorAction;
  }
}
