/* RESPONSIBLE TEAM: team-customer-data-platform */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class SalesforceRecordType extends Fragment {
  @attr('boolean') available;
  @attr('boolean') defaultRecordTypeMapping;
  @attr('boolean') master;
  @attr('string') name;
  @attr('string') recordTypeId;
}
