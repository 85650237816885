/* RESPONSIBLE TEAM: team-frontend-tech */
export function responsibleTeamFromTransition(transition) {
  let to = transition.to;
  let responsibleTeam;

  while (to) {
    responsibleTeam = to.metadata && to.metadata.responsibleTeam;
    if (responsibleTeam) {
      break;
    }
    to = to.parent;
  }

  return responsibleTeam;
}
