/* RESPONSIBLE TEAM: team-proactive-support */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';

export const NODE_CATEGORIES = {
  content: 0,
  condition: 1,
  action: 2,
};
export default class NodeConfiguration {
  inserterConfiguration = new InserterConfiguration();
  category = null;
}
