/* RESPONSIBLE TEAM: team-knowledge-interop */
import ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type Folder from './folder';
import { tracked } from 'tracked-built-ins';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type FolderMembership from './folder-membership';
import { EntityType } from '../data/entity-types';
import { FinAvailability } from 'embercom/lib/ai-content-library/constants';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import { cached } from 'tracked-toolbox';
import { State } from '../data/matching-system/matching-constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import _ from 'underscore';
import { KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES } from 'embercom/lib/knowledge-hub/constants';
import type Model from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import type RouterService from '@ember/routing/router-service';
import KnowledgeHubItemMixin, { type KnowledgeHubItem } from './knowledge-hub-item';
import {
  moveToFolderAndSave,
  type MembershipChild,
} from 'embercom/objects/content-service/folders/membership-child';

export type KnowledgeHubTableRow = KnowledgeHubContentWrapper | ContentFragment;

export default class KnowledgeHubContentWrapper
  extends KnowledgeHubItemMixin(ContentWrapper)
  implements KnowledgeHubItem, MembershipChild
{
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @belongsTo('content-service/folder-membership', { async: false })
  declare folderMembership?: FolderMembership;

  @tracked
  private parentFolder?: Folder;

  @tracked defaultLocale?: string;

  private get parentFolderId(): number | undefined {
    return this.parentFolder?.id ?? this.relations?.folder?.folder_id;
  }

  get displayName(): string {
    return this.title;
  }

  get parentIdFromRelationship(): string | undefined {
    return this.parentFolderId?.toString();
  }

  // Note that this will always peek at the first content object.
  get peekContentObject(): (KnowledgeHubItem & Model) | undefined {
    if (this.entityId && KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES[this.entityType]) {
      return this.store.peekRecord(
        KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES[this.entityType],
        this.entityId,
      );
    }
    return undefined;
  }

  get isEditable(): boolean {
    return this.peekContentObject?.isEditable ?? this.parent?.isEditable ?? true;
  }

  async moveToFolderAndSave({ folder }: { folder?: Folder }) {
    if (this.entityType === EntityType.ContentLibraryFolder) {
      let thisFolder = this.peekContentObject as Folder;
      await thisFolder.moveToFolderAndSave({ folder });
    } else {
      await moveToFolderAndSave({ contentItem: this, destinationFolder: folder });
    }
  }

  get entityTypeForMembership(): EntityType {
    if (this.entityType === EntityType.ArticleContent) {
      return EntityType.Article;
    }
    return this.entityType;
  }

  get entityIdForMembership(): number {
    if (this.entityType === EntityType.ArticleContent) {
      return Number(this.contents.firstObject!.contentData.article_id);
    }
    if (!this.entityId) {
      throw new Error('Entity ID is not defined.');
    }
    return this.entityId;
  }

  get isMultilingual(): boolean {
    return (this.contents.length as number) > 1;
  }

  get parent(): Folder | undefined {
    if (!this.parentFolder && this.parentFolderId) {
      return this.store.peekRecord('content-service/folder', this.parentFolderId);
    }
    return this.parentFolder;
  }

  set parent(parent: Folder | undefined) {
    this.parentFolder = parent;
    if (this.relations?.folder) {
      this.relations.folder.folder_id = parent?.id;
    }
    this.onParentChange(parent);
  }

  get copilotAvailableValue(): number {
    if (this.entityType === EntityType.ArticleContent) {
      return FinAvailability.DERIVED;
    }
    return FinAvailability.ON;
  }

  get contentType(): EntityType {
    return this.defaultContent.contentType;
  }

  get copilotState(): FinAvailability {
    return this.defaultContent.copilotState;
  }

  get allUniqueCopilotStates(): FinAvailability[] {
    return Array.from(new Set(this.contents.map((fragment) => fragment.copilotState)));
  }

  get chatbotState(): FinAvailability {
    return this.defaultContent.chatbotState;
  }

  get allUniqueChatbotStates(): FinAvailability[] {
    return Array.from(new Set(this.contents.map((fragment) => fragment.chatbotState)));
  }

  get isFolder(): boolean {
    return this.contentType === EntityType.ContentLibraryFolder;
  }

  get copilotStates(): FinAvailability[] | undefined {
    if (this.isFolder) {
      return undefined;
    }

    return this.isMultilingual ? this.allUniqueCopilotStates : [this.copilotState];
  }

  get chatbotStates(): FinAvailability[] | undefined {
    if (this.isFolder) {
      return undefined;
    }

    return this.isMultilingual ? this.allUniqueChatbotStates : [this.chatbotState];
  }

  get state(): State | undefined {
    if (this.contentType !== objectTypes.articleContent) {
      return undefined; // only public articles should display help center status
    }
    if (this.isMultilingual) {
      // this is the case where the article has multiple locales
      return this.allUniqueStates.any((state) => state === State.Live) ? State.Live : State.Draft;
    }
    return this.defaultContent.state;
  }

  get allUniqueStates(): State[] {
    return Array.from(new Set(this.contents.map((fragment) => fragment.state)));
  }

  get locale(): string | undefined {
    return this.defaultContent.locale;
  }

  get allUniqueContentLocales(): string[] {
    return Array.from(new Set(this.contents.map((fragment) => fragment.locale!)));
  }

  get updatedAt(): Date {
    return this.contents.sortBy('updatedAt').lastObject!.updatedAt;
  }

  get lastUpdatedById(): number {
    return this.contents.sortBy('updatedAt').lastObject!.lastUpdatedById;
  }

  get createdById(): number {
    return this.defaultContent.createdById;
  }

  get allUniqueContentCreatedByIds(): number[] {
    return Array.from(new Set(this.contents.map((fragment) => fragment.createdById)));
  }

  @cached
  get defaultContent(): ContentFragment {
    let defaultLocale = this.defaultLocale || this.appService.app.locale || 'en';
    let defaultContent = this.contents.find((content) => content.locale === defaultLocale);
    return defaultContent || this.contents.firstObject!;
  }

  get aiContentSegmentIds(): number[] {
    let uniqueSegmentIds = new Set(
      this.contents.map((fragment) => fragment.aiContentSegmentIds).flat(),
    );
    return Array.from(uniqueSegmentIds);
  }

  get isMixedAiContentSegmentIds(): boolean {
    if (this.entityType === EntityType.ArticleContent && this.contents.firstObject) {
      let aiContentSegmentIds = this.contents.firstObject.aiContentSegmentIds;
      return this.contents.any((content: { aiContentSegmentIds: number[] }) => {
        return !_.isEqual(content.aiContentSegmentIds, aiContentSegmentIds);
      });
    }
    return false;
  }
}
