/* RESPONSIBLE TEAM: team-ai-agent */
/* eslint-disable no-restricted-imports */
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { chatMessageStepBlockValidators } from 'embercom/models/operator/visual-builder/step/chat-message';
import { type SyncHasMany } from '@ember-data/model';
import { type Block } from 'embercom/models/common/blocks/block';
import type Store from '@ember-data/store';
import { ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';
import { getApp } from 'embercom/lib/container-lookup';
import { resolutionBotBillingFeature } from 'embercom/components/operator/answer-bot/billing-helper';

const Validations = buildValidations({
  followUpRules: [
    validator('follow-up-rule-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
    validator('follow-up-rule-is-complete', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
  simpleActionRule: [
    validator('simple-action-rule-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
  ...chatMessageStepBlockValidators,
});

export default class AnswerBot extends Step.extend(Validations) {
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;
  @fragmentArray('rules/rule') declare followUpRules: SyncHasMany<any>;
  @fragment('rules/rule') declare simpleActionRule?: any;
  supportedChannels = ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL;

  get serializedBlocks() {
    // We only need to serialize the blocks if they are a FragmentArray (in tests they are already serialized)
    if (this.blocks.type === 'common/blocks/block') {
      return this.blocks.serialize();
    } else {
      return this.blocks;
    }
  }

  get hasSimpleActionRule() {
    return !!this.simpleActionRule;
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  static get requiredBillingFeature() {
    let app = getApp();

    return resolutionBotBillingFeature(app);
  }

  static createNewStep(store: Store): AnswerBot {
    let blocks = [
      store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: '', // let the component decide what the default or placeholder text should be
      }),
    ];

    return store.createRecord('operator/visual-builder/step/answer-bot', {
      type: 'operator/visual-builder/step/answer-bot',
      blocks,
    });
  }
}
