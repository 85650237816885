/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { on } from '@ember/object/evented';
import EmberObject from '@ember/object';
import { w } from '@ember/string';
export default EmberObject.extend({
  realTimeEventService: null,
  query: null,
  conversationUpdatedEvents: w(
    'ThreadAssigned ThreadClosed ThreadReopened ThreadUpdated ConversationAttributesUpdated',
  ),
  subscribe: on('init', function () {
    let self = this;
    self.get('conversationUpdatedEvents').forEach(function (eventName) {
      self.get('realTimeEventService').on(eventName, self, 'conversationUpdatedHandler');
    });
  }),
  unsubscribe() {
    let self = this;
    self.get('conversationUpdatedEvents').forEach(function (eventName) {
      self.get('realTimeEventService').off(eventName, self, 'conversationUpdatedHandler');
    });
  },
  willDestroy() {
    this.unsubscribe();
  },
  conversationUpdatedHandler(event) {
    let conversations = this.get('query.content').mapBy('conversation');
    let conversation = conversations.findBy('id', event.conversationId);
    if (
      conversation &&
      conversation.get('isCurrentlyActive') &&
      conversation.shouldHandleRealtimeEvent(event)
    ) {
      conversation.syncedPartList.sync().then(function () {
        conversation.updatedByRealtimeEvent(event);
      });
    }
  },
});
