/* RESPONSIBLE TEAM: team-proactive-support */
export const outboundWebhookHttpMethods = {
  post: 0,
  put: 1,
};

export const outboundWebhookHttpMethodLabels = {
  [outboundWebhookHttpMethods.post]: 'POST',
  [outboundWebhookHttpMethods.put]: 'PUT',
};

export const outboundWebhookContentTypes = {
  json: 0,
  urlEncoded: 1,
};

export const outboundWebhookContentTypeLabels = {
  [outboundWebhookContentTypes.json]: 'application/json',
  [outboundWebhookContentTypes.urlEncoded]: 'application/x-www-form-urlencoded',
};
