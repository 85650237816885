/* RESPONSIBLE TEAM: team-tickets-1 */
import moment from 'moment-timezone';

export default class TeammatesCsvExporter {
  constructor(app, admins, { workspaceAssignmentLimit, teamInboxes, teamSettings, intl }) {
    this.app = app;
    this.admins = admins;
    this.workspaceAssignmentLimit = workspaceAssignmentLimit;
    this.teamInboxes = teamInboxes;
    this.teamSettings = teamSettings;
    this.intl = intl;
  }

  get filename() {
    return this.intl.t('settings.teammate-workload.export-csv.filename', {
      date: moment.tz(this.app.timezone).format('YYYY-MM-DD'),
    });
  }

  get data() {
    let data = this.admins.map((admin) => this.row(admin));
    data.unshift(this.headerColumn);

    return data;
  }

  row(admin) {
    return [
      this.name(admin),
      this.email(admin),
      this.role(admin),
      this.assignmentLimit(admin),
      this.primaryInboxes(admin),
      this.secondaryInboxes(admin),
    ];
  }

  name(admin) {
    return admin.name;
  }

  email(admin) {
    return admin.email;
  }

  role(admin) {
    return (
      admin.currentAppPermissions?.role?.name ||
      this.intl.t('settings.teammate-workload.export-csv.no-role')
    );
  }

  assignmentLimit(admin) {
    return (
      admin.workload_management_settings?.assignment_limit ||
      `${this.workspaceAssignmentLimit} (${this.intl.t(
        'settings.teammate-workload.export-csv.workspace-limit',
      )})` ||
      this.intl.t('settings.teammates-workload.export-csv.no-limit')
    );
  }

  primaryInboxes(admin) {
    return this.teamInboxes
      .filter((inbox) => inbox.primaryMembers.includes(parseInt(admin.id, 10)))
      .map((inbox) => inbox.name)
      .join(', ');
  }

  secondaryInboxes(admin) {
    return this.teamInboxes
      .filter((inbox) => this.teamSettingByTeamId(inbox.id)?.distributionMethod === 'load_balanced')
      .filter((inbox) => inbox.secondaryMembers.includes(parseInt(admin.id, 10)))
      .map((inbox) => inbox.name)
      .join(', ');
  }

  teamSettingByTeamId(teamId) {
    return this.teamSettings.find((teamSetting) => teamSetting.teamId === teamId);
  }

  get headerColumn() {
    return [
      this.intl.t('settings.teammate-workload.export-csv.name'),
      this.intl.t('settings.teammate-workload.export-csv.email'),
      this.intl.t('settings.teammate-workload.export-csv.role'),
      this.intl.t('settings.teammate-workload.export-csv.assignment-limit'),
      this.intl.t('settings.teammate-workload.export-csv.primary-inboxes'),
      this.intl.t('settings.teammate-workload.export-csv.secondary-inboxes'),
    ];
  }
}
