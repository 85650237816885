/* RESPONSIBLE TEAM: team-phone */

import AdminSummary from '../admin-summary';
import UserSummary from '../user-summary';

export default class CallParticipantSummary {
  private participant: UserSummary | AdminSummary;

  get id() {
    return this.participant.id;
  }

  get displayLabel() {
    if (this.isAdmin) {
      return this.participant.name;
    }

    return this.participant.name || (this.participant as UserSummary).phone;
  }

  get phone() {
    return (this.participant as UserSummary).phone;
  }

  get isAdmin() {
    return this.participant instanceof AdminSummary;
  }

  get isUser() {
    return this.participant instanceof UserSummary;
  }

  get originalParticipant(): UserSummary | AdminSummary {
    return this.participant;
  }

  get adminParticipant() {
    return this.participant as AdminSummary;
  }

  get userParticipant() {
    return this.participant as UserSummary;
  }

  constructor(participant: UserSummary | AdminSummary) {
    this.participant = participant;
  }
}
