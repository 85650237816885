/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { type RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { RenderableStateChangeMapping } from 'embercom/models/data/inbox/state-change-classes';
import {
  type StateChangeReason,
  type StateChangeType,
  type StateChangingEntity,
} from 'embercom/models/data/inbox/state-change-enums';
import { type CustomBotSummaryWireFormat } from '../custom-bot-summary';
import type CustomBotSummary from '../custom-bot-summary';

export interface StateChangeWireFormat {
  renderable_type: RenderableType;
  changed_by_entity_type: StateChangingEntity;
  change: StateChangeType;
  reason: StateChangeReason;
  changed_by_entity?: any;
  change_context?: object;
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

export interface StateChangePart extends RenderableData {
  readonly renderableType: RenderableType;
  readonly changedByEntityType: StateChangingEntity;
  readonly change: StateChangeType;
  readonly reason: StateChangeReason;
  readonly eventDescription: string;
  readonly changeContext?: object;
  readonly customBotSummary?: CustomBotSummary;
}
export default class StateChange {
  static deserialize(json: StateChangeWireFormat): RenderableData {
    return RenderableStateChangeMapping[json.changed_by_entity_type].deserialize(json);
  }
}
