/* RESPONSIBLE TEAM: team-tickets-1 */
import { ParentAction, ChildAction } from './action';
import type Conversation from 'embercom/objects/inbox/conversation';
import {
  type ConversationState,
  type TicketSystemState,
} from 'embercom/objects/inbox/conversation';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { inject as service } from '@ember/service';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { type Icon } from 'embercom/objects/inbox/command-k/icons';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';

/*
  states: a list of states that we display in the command-k pane to search and select from.
 */
export type UpdateTicketStateCommandKContext = {
  states: TicketCustomStateRepresentation[];
  conversation?: Partial<Conversation>;
};

export type TicketCustomStateRepresentation = {
  state: ConversationState | TicketCustomState['id'];
  systemState?: TicketSystemState;
  label: string;
  icon?: Icon;
  iconComponent?: 'inbox2/ticket-state-dot-icon';
  shortcutId?: HotkeyID;
};

export default class UpdateTicketStateAction extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;

  id = 'update-ticket-state';
  icon = 'ticket' as const;
  paneComponent = 'inbox2/command-k/update-ticket-state/pane';

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.UpdateTicketState];
  }

  buildTopLevelItems(query: string) {
    return this.buildResults(query);
  }

  buildResults(query: string): Array<ChildAction> {
    let context = this.context as UpdateTicketStateCommandKContext;
    let results = context.states.map(
      (item) =>
        new ChildAction({
          parent: this,
          id: `${item.state}`,
          label: item.label,
          value: item.state,
          icon: item.icon,
          iconComponent: item.iconComponent,
          shortcutId: item.shortcutId,
        }),
    );

    let upperQuery = query.trim().toLocaleUpperCase();

    return results.filter((action) => {
      return action.label.toLocaleUpperCase().includes(upperQuery);
    });
  }
}
