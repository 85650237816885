/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr } from '@ember-data/model';

export default class IdentityVerificationSettingsModel extends Model {
  @attr() enabledByDefault;
  @attr() defaultStrictness;
  @attr('boolean') reminderEnabled;
  @attr('number') reminderOptedOutAdminId;
  @attr('date') reminderOptedOutAt;
}
