import Model, { attr } from '@ember-data/model';

/* RESPONSIBLE TEAM: team-app-security */

export default class SessionDeviceDetail extends Model {
  @attr('number') declare adminId: number;
  @attr('date') declare createdAt: Date;
  @attr('string') declare ipAddress: string;
  @attr('string') declare browser: string;
  @attr('string') declare operatingSystem: string;
  @attr('string') declare location: string;
}
