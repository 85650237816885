/* RESPONSIBLE TEAM: team-help-desk-experience */
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';

export interface NoteWireFormat {
  id: string;
  body: string;
  body_html: string;
  created_at?: string;
  created_by?: AdminSummaryWireFormat;
  updated_at?: string;
  updated_by?: AdminSummaryWireFormat;
}

export default class Note {
  readonly id: string;
  readonly createdAt?: Date;
  readonly createdBy?: AdminSummary;
  readonly updatedAt?: Date;
  readonly updatedBy?: AdminSummary;
  readonly body: string;
  readonly bodyHTML: string;

  constructor(
    id: string,
    body: string,
    bodyHTML: string,
    createdAt?: Date,
    createdBy?: AdminSummary,
    updatedAt?: Date,
    updatedBy?: AdminSummary,
  ) {
    this.id = id;
    this.body = body;
    this.bodyHTML = sanitizeHtml(bodyHTML);
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
  }

  static deserialize(json: NoteWireFormat): Note {
    let createdBy = json.created_by ? AdminSummary.deserialize(json.created_by) : undefined;
    let createdAt = json.created_at ? new Date(json.created_at) : undefined;
    let updatedBy = json.updated_by ? AdminSummary.deserialize(json.updated_by) : undefined;
    let updatedAt = json.updated_at ? new Date(json.updated_at) : undefined;

    return new Note(
      json.id.toString(),
      json.body,
      json.body_html,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
    );
  }
}
