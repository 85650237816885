/* RESPONSIBLE TEAM: team-workflows */
import type RemoveTagFromUser from 'embercom/models/operator/visual-builder/step/remove-tag-from-user';
import StepConfig, { type StepConfigParams } from '../step';

export default class RemoveTagFromUserConfiguration extends StepConfig {
  declare step: RemoveTagFromUser;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/remove-tag-from-user',
      ...params,
    });
  }
}
