/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, gt, not, or, bool, and, equal, empty } from '@ember/object/computed';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import UserPresence from 'embercom/models/types/user-presence-model';
import UserAvatarData from 'embercom/models/mixins/user-avatar-data';

let Participant = Model.extend(UserPresence, UserAvatarData, {
  user_id: attr(),
  display_as: attr(),
  displayAs: alias('display_as'),
  email: attr(),
  user_phone_number: attr(),
  whatsapp_number: attr(),
  phone: attr(),
  avatar: attr(),
  last_request_at: attr(),
  last_request_at_ios: attr(),
  last_request_at_android: attr(),
  is_anonymous: attr(),
  is_mobile_unidentified: attr(),
  role: attr(),
  pseudonym: attr(),
  isUnknown: attr(),
  displayAsDidUpdate: false,

  companies: attr('company-summaries', { defaultValue: () => [] }),

  custom_data: attr({ defaultValue: () => ({}) }),
  mostRecentCompany: alias('companies.firstObject'),
  hasCompanies: gt('companies.length', 0),
  hasNoCompanies: not('hasCompanies'),
  hasDisplayableCompany: or('mostRecentCompany.notManuallyCreated', 'mostRecentCompany.name'),
  hasEmail: bool('email'),
  hasNoEmail: not('hasEmail'),
  leadWithoutEmail: and('is_anonymous', 'hasNoEmail'),
  isUserRole: equal('role', 'user_role'),
  isLeadRole: equal('role', 'contact_role'),
  typeLabel: ternary('is_anonymous', 'Lead', 'User'),
  userPhoneNumberDidUpdate: false,

  firstName: computed('display_as', function () {
    return this.display_as.split(' ')[0];
  }),
  nameOrEmail: or('display_as', 'email'),

  hasNeverBeenSeen: empty('last_request_at'),

  pseudonymWithoutLocation: computed('display_as', 'is_anonymous', function () {
    if (this.is_anonymous && this.pseudonym) {
      return this.pseudonym.split(' ', 2).join(' ');
    }
    return '';
  }),

  analyticsData: computed('id', 'role', function () {
    return {
      object: 'user',
      user_id: this.id,
      user_role: this.role,
    };
  }),
  _setAndNotifyChange(keyName, value) {
    this.store.pushPayload({ participant: { id: this.id, [keyName]: value } });
  },
}).reopenClass({
  peekAndMaybeLoad(store, id) {
    if (id === null || id === undefined) {
      id = -1;
    }
    let participant = store.peekRecord('participant', id);
    if (participant) {
      return participant;
    }
    store.pushPayload({
      participants: [
        {
          id,
          user_id: id,
          display_as: 'This user has been deleted',
          is_unknown: true,
        },
      ],
    });
    return store.peekRecord('participant', id);
  },
});

export default Participant;
