/* RESPONSIBLE TEAM: team-frontend-tech */
import Model, { attr, belongsTo } from '@ember-data/model';
import type Admin from 'embercom/models/admin';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class Note extends Model {
  @attr() declare app_id: string;
  @attr() declare body: string;
  @attr() declare body_html_safe: string;
  @attr() declare created_at: string;
  @attr() declare model_id: string;
  @attr() declare is_company: boolean;
  @attr() declare last_edited_at: string;
  @attr() declare created_by: string;

  @belongsTo('admin') declare admin: Admin;
  @belongsTo('admin') declare updated_by: Admin;

  get bodyHtmlSafe(): string {
    return this.body_html_safe ? sanitizeHtml(this.body_html_safe) : '';
  }
}
