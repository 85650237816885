/* RESPONSIBLE TEAM: team-tickets-1 */
import { ChildAction, ParentAction } from './action';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { inject as service } from '@ember/service';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { NamedIcon } from 'embercom/objects/inbox/command-k/icons';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type InboxApi from 'embercom/services/inbox-api';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { localisedCustomStateAdminLabel } from 'embercom/lib/inbox2/ticket-custom-states';
import type IntlService from 'embercom/services/intl';

/*
  states: a list of states that we display in the command-k pane to search and select from.
 */
export type ResolveAndCloseTicketStateCommandKContext = {
  states: TicketCustomState[];
  conversation?: Partial<Conversation>;
};

export default class ResolveAndCloseTicketStateAction extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare ticketStateService: TicketStateService;
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;

  id = 'resolve-and-close-ticket-state';
  icon = 'ticket' as const;
  paneComponent = 'inbox2/command-k/resolve-and-close-ticket-state/pane';
  // @ts-ignore
  label = this.getCustomisedLabel();

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.ResolveAndCloseTicketState];
  }

  buildTopLevelItems(query: string) {
    return this.buildResults(query);
  }

  getCustomisedLabel() {
    return (
      this.context?.label ||
      this.intl.t('inbox.command-k.placeholders.resolve-and-close-ticket-state', {
        count: this.context?.isMultiple ? 2 : 1,
      })
    );
  }

  buildResults(query: string): Array<ChildAction> {
    let validTicketStates;

    let customStates = this.getResolvedCustomStatesForConversationTicketType();

    validTicketStates = customStates.map((customState: TicketCustomState) => {
      return {
        state: customState.id,
        label: localisedCustomStateAdminLabel(customState.adminLabel, this.intl),
        iconComponent: 'inbox2/ticket-state-dot-icon' as const,
      };
    });

    let closedWithoutResolveConversationState = {
      state: ConversationState.Closed,
      label: this.context?.isMultiple
        ? this.intl.t('inbox.conversation-states.close-without-resolve-multiple')
        : this.intl.t('inbox.conversation-states.close-without-resolve', {
            ticketState: localisedCustomStateAdminLabel(
              this.context?.currentTicketState?.adminLabel,
              this.intl,
            ),
          }),
      icon: new NamedIcon('close-conversation'),
      shortcutId: HotkeyID.Close,
    };

    let states = [...validTicketStates, closedWithoutResolveConversationState];

    let results = states.map(
      (item: any) =>
        new ChildAction({
          parent: this,
          id: `${item.state}`,
          label: item.label,
          value: item.state,
          icon: item.icon,
          iconComponent: item.iconComponent,
          shortcutId: item.shortcutId,
        }),
    );

    let upperQuery = query.trim().toLocaleUpperCase();

    return results.filter((action) => {
      return action.label.toLocaleUpperCase().includes(upperQuery);
    });
  }

  getResolvedCustomStatesForConversationTicketType() {
    if (this.context && this.context.ticketType) {
      return this.ticketStateService
        .getLiveCustomStatesForTicketType(this.context?.ticketType)
        .filter((customState) => customState.systemState === TicketSystemState.Resolved);
    } else {
      return this.ticketStateService.getLiveTicketCustomStatesForSystemState(
        TicketSystemState.Resolved,
      );
    }
  }
}
