/* RESPONSIBLE TEAM: team-proactive-support */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/outbound-webhook/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/outbound-webhook/edit-mode-generator';
import generateSeriesModeConfig from 'embercom/objects/series/ruleset-editor/outbound-webhook/editor-configuration-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.outboundWebhook;
  return EditorConfig.create({
    editMode: generateEditModeConfig(options),
    viewMode: generateViewModeConfig(options),
    seriesMode: generateSeriesModeConfig(options),
    notificationMessages: generateNotificationMessages(options),
  });
}
