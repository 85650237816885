/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  all: attr('number'),
  live: attr('number'),
  stopped: attr('number'),
  draft: attr('number'),
  scheduled: attr('number'),
});
