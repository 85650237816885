/* RESPONSIBLE TEAM: team-help-desk-experience */
import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';
import { ConversationState, type TicketSystemState } from './conversation';
import RenderablePart, { type RenderablePartWireFormat } from './renderable-part';
import {
  TicketCategory,
  TicketType,
  type TicketTypeWireFormat,
} from 'embercom/objects/inbox/ticket';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import ConversationAttributeSummary, {
  type ConversationAttributeSummaryWireFormat,
} from './conversation-attribute-summary';
import { DEFAULT_TICKET_TYPE_ATTRIBUTES } from 'embercom/lib/inbox/constants';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import ParticipantUserSummary, { firstConversationParticipant } from './participant-user-summary';

export interface LinkedTicketSummaryConstructorFormat {
  id: number;
  attributes?: ConversationAttributeSummary[];
  title: string;
  lastUpdated: Date;
  createdAt: Date;
  visibleToUser: boolean;
  ticketId?: string;
  state: ConversationState;
  ticketState: TicketSystemState;
  ticketCustomStateId?: number;
  adminAssignee: AdminSummary;
  ticketType?: TicketType;
  lastRenderableSummaryPart?: RenderablePart;
  firstUserPart?: RenderablePart;
  userSummary?: UserSummary;
  category?: TicketCategory;
  linkedCustomerTicketIds: number[] | null;
  linkedConversationIds: number[] | null;
  participantSummaries: ParticipantUserSummary[];
}

export interface LinkedTicketSummaryWireFormat {
  id: number;
  attributes?: ConversationAttributeSummaryWireFormat[];
  title: string;
  last_updated: string;
  created_at: string;
  visible_to_user: boolean;
  ticket_id?: string;
  state: ConversationState;
  ticket_state: TicketSystemState;
  ticket_custom_state_id?: number;
  admin_assignee: AdminSummaryWireFormat;
  ticket_type?: TicketTypeWireFormat;
  last_renderable_part?: RenderablePartWireFormat;
  first_user_part?: RenderablePartWireFormat;
  user_summary?: UserSummaryWireFormat;
  ticket_category?: TicketCategory;
  linked_conversation_ids: number[] | null;
  linked_customer_ticket_ids: number[] | null;
  participant_summaries?: UserSummaryWireFormat[];
}

export default class LinkedTicketSummary {
  readonly id: number;
  readonly title: string;
  readonly attributes?: ConversationAttributeSummary[];
  readonly visibleToUser: boolean;
  readonly ticketId?: string;
  readonly lastUpdated: Date;
  readonly createdAt: Date;
  readonly state: ConversationState;
  readonly ticketState: TicketSystemState;
  readonly ticketCustomStateId?: number;
  readonly adminAssignee: AdminSummary;
  readonly ticketType?: TicketType;
  readonly lastRenderableSummaryPart?: RenderablePart;
  readonly firstUserPart?: RenderablePart;
  readonly userSummary?: UserSummary;
  readonly category?: TicketCategory;
  readonly linkedConversationIds: number[] | null;
  readonly linkedCustomerTicketIds: number[] | null;
  readonly participantSummaries: ParticipantUserSummary[];

  constructor(inputs: LinkedTicketSummaryConstructorFormat) {
    let {
      id,
      title,
      attributes,
      lastUpdated,
      createdAt,
      visibleToUser,
      ticketId,
      state,
      ticketState,
      ticketCustomStateId,
      adminAssignee,
      ticketType,
      lastRenderableSummaryPart,
      firstUserPart,
      userSummary,
      category,
      linkedConversationIds,
      linkedCustomerTicketIds,
      participantSummaries,
    } = inputs;
    this.id = id;
    this.title = title;
    this.attributes = attributes;
    this.lastUpdated = lastUpdated;
    this.createdAt = createdAt;
    this.visibleToUser = visibleToUser;
    this.ticketId = ticketId;
    this.state = state;
    this.ticketState = ticketState;
    this.ticketCustomStateId = ticketCustomStateId;
    this.adminAssignee = adminAssignee;
    this.ticketType = ticketType;
    this.lastRenderableSummaryPart = lastRenderableSummaryPart;
    this.firstUserPart = firstUserPart;
    this.userSummary = userSummary;
    this.category = category;
    this.linkedConversationIds = linkedConversationIds;
    this.linkedCustomerTicketIds = linkedCustomerTicketIds;
    this.participantSummaries = participantSummaries || [];
  }

  get isClosed() {
    return this.state === ConversationState.Closed;
  }

  get ticketTitle() {
    let titleDescriptor = this.attributes?.find(
      (attribute: ConversationAttributeSummary) =>
        attribute.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE,
    );

    return (titleDescriptor?.value as string) ?? this.ticketType?.name;
  }

  get ticketDescription() {
    let descriptionDescriptor = this.attributes?.find(
      (attribute: ConversationAttributeSummary) =>
        attribute.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION,
    );

    return descriptionDescriptor?.value as string;
  }

  get firstParticipant() {
    return firstConversationParticipant(this.participantSummaries);
  }

  get ticketDescriptionAsBlocks() {
    let description = this.ticketDescription;

    if (typeof description !== 'string' || description === '') {
      return [];
    }

    try {
      return JSON.parse(description);
    } catch {
      return [];
    }
  }

  get emojiUri() {
    if (!this.ticketType?.emoji) {
      return undefined;
    }

    return intermoji.twemojiSVGUri(this.ticketType.emoji);
  }

  get isTrackerTicket() {
    return this.category === TicketCategory.Tracker;
  }

  get linkedCustomerReportIds() {
    return (this.linkedConversationIds || []).concat(this.linkedCustomerTicketIds || []);
  }

  static deserialize(json: LinkedTicketSummaryWireFormat) {
    let ticketType = undefined;
    if (json.ticket_type) {
      ticketType = TicketType.deserialize(json.ticket_type);
    }
    let lastRenderableSummaryPart = undefined;
    if (json.last_renderable_part) {
      lastRenderableSummaryPart = RenderablePart.deserialize(json.last_renderable_part);
    }
    let firstUserPart = undefined;
    if (json.first_user_part) {
      firstUserPart = RenderablePart.deserialize(json.first_user_part);
    }
    let userSummary = undefined;
    if (json.user_summary) {
      userSummary = UserSummary.deserialize(json.user_summary);
    }
    let category = json.ticket_category;
    let linkedConversationIds = json.linked_conversation_ids;
    let linkedCustomerTicketIds = json.linked_customer_ticket_ids;

    let participantSummaries = json.participant_summaries
      ? json.participant_summaries.compact().map(ParticipantUserSummary.deserialize)
      : undefined;

    return new LinkedTicketSummary({
      id: json.id,
      title: json.title,
      attributes: !json.attributes
        ? []
        : json.attributes.map((attribute: ConversationAttributeSummaryWireFormat) =>
            ConversationAttributeSummary.deserialize(attribute),
          ),
      lastUpdated: new Date(json.last_updated),
      createdAt: new Date(json.created_at),
      visibleToUser: json.visible_to_user,
      ticketId: json.ticket_id,
      state: json.state,
      ticketState: json.ticket_state,
      ticketCustomStateId: json.ticket_custom_state_id,
      adminAssignee: AdminSummary.deserialize(json.admin_assignee),
      ticketType,
      lastRenderableSummaryPart,
      firstUserPart,
      userSummary,
      category,
      linkedConversationIds,
      linkedCustomerTicketIds,
      participantSummaries: participantSummaries ?? [],
    });
  }
}
