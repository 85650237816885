/* RESPONSIBLE TEAM: team-help-desk-experience */
import RenderablePart from './renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type AdminSummary from './admin-summary';
import type UserSummary from './user-summary';
import Deleted from './renderable/deleted';
import { EntityType } from 'embercom/models/data/entity-types';
import type Chat from './renderable/chat';
import type Email from './renderable/email';

export function canDeletePart(part: RenderablePart) {
  return [
    RenderableType.AdminComment,
    RenderableType.AdminNote,
    RenderableType.BotNote,
    RenderableType.ConversationSummary,
    RenderableType.QuickReplyResponse,
    RenderableType.UserComment,
    RenderableType.UserEmailComment,
  ].includes(part.renderableType);
}

export function canDeleteInitialPart(part: RenderablePart) {
  return [RenderableType.Chat, RenderableType.Email].includes(part.renderableType);
}

export function isInitialPart(part: RenderablePart) {
  return part.entityType !== EntityType.ConversationPart;
}

export function isInitialOutboundMessage(part: RenderablePart) {
  if (part.renderableType === RenderableType.Chat) {
    let chatPart = part.renderableData as Chat;
    return !!chatPart.rulesetId;
  }

  if (part.renderableType === RenderableType.Email) {
    let emailPart = part.renderableData as Email;
    return !!emailPart.rulesetId;
  }

  return false;
}

export function isDeletedPart(part: RenderablePart) {
  return part.renderableData.renderableType === RenderableType.Deleted;
}

export function createDeletedPart(part: RenderablePart, deletedBy: AdminSummary): RenderablePart {
  let { adminSummary, userSummary } = part.renderableData as {
    adminSummary?: AdminSummary;
    userSummary?: UserSummary;
  };

  if (!canDeletePart(part) || !(adminSummary || userSummary)) {
    throw new Error('Part is not deletable');
  }

  return new RenderablePart(
    part.id,
    part.renderableType,
    new Deleted(deletedBy, new Date(), adminSummary, userSummary),
    part.createdAt,
    part.entityId,
    part.entityType,
  );
}

export function createDeletedInitialPart(
  part: RenderablePart,
  deletedBy: AdminSummary,
): RenderablePart {
  let { senderSummary } = part.renderableData as { senderSummary?: AdminSummary };

  if (!senderSummary) {
    throw new Error('Part is not deletable');
  }

  return new RenderablePart(
    part.id,
    part.renderableType,
    new Deleted(deletedBy, new Date(), senderSummary),
    part.createdAt,
    part.entityId,
    part.entityType,
  );
}
