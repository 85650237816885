/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';

export default ComponentConfiguration.extend({
  init(inputs) {
    this._super({
      component: 'content-editor/footers/custom/resolution-bot-behavior/new-behavior-body',
    });
  },
});
