/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { ALL_CHANNELS_EXCEPT_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  durationType: [
    validator('inclusion', {
      in: ['later_today', 'tomorrow', 'monday', 'custom'],
      messageKey: 'operator.workflows.visual-builder.validations.wait.duration-type',
    }),
  ],
  customDuration: [
    validator('number', {
      allowNone: true,
      integer: true,
      gte: 2,
      lte: 28 * 24 * 60 * 60, // 28 days in seconds
      messageKey: 'operator.workflows.visual-builder.validations.wait.custom-duration',
    }),
  ],
});

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export default class Wait extends Step.extend(Validations) {
  @attr('number') declare customDuration?: number;
  @attr('string') declare durationType: string;
  // interruptMode was introduced after the wait step was released, this means that there are wait
  // steps without one, for those cases we set a default here
  @attr('string', { defaultValue: 'admin_comments' }) declare interruptMode: string;
  @attr('boolean') declare disableComposer?: boolean;

  supportedChannels = ALL_CHANNELS_EXCEPT_PHONE_CALL;

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static get isStepGroupEnding() {
    return false;
  }

  static createNewStep(store: Store): Wait {
    return store.createRecord('operator/visual-builder/step/wait', {
      type: 'operator/visual-builder/step/wait',
      durationType: 'custom',
      customDuration: 300,
      interruptMode: 'admin_comments',
      disableComposer: false,
    });
  }

  get days() {
    return Math.floor((this.customDuration || 0) / DAY);
  }

  get hours() {
    return Math.floor(((this.customDuration || 0) % DAY) / HOUR);
  }

  get minutes() {
    return Math.floor(((this.customDuration || 0) % HOUR) / MINUTE);
  }

  get seconds() {
    return Math.floor((this.customDuration || 0) % MINUTE);
  }

  get minDuration() {
    return 2;
  }

  get maxDuration() {
    return 28 * 24 * 60 * 60; // 28 days in seconds
  }
}
