/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/sms/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/sms/edit-mode-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import {
  objectTypes,
  humanReadableObjectNames,
  objectFeatures,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import { inject as service } from '@ember/service';
import numberFormatter from 'embercom/lib/number-formatter';
import pluralize from 'embercom/lib/inflector';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.sms;
  let humanReadableObjectName = humanReadableObjectNames[options.objectType];
  let notificationMessages = generateNotificationMessages(options);
  notificationMessages.activate = generateSmsNotificationMessages(
    options.container,
    humanReadableObjectName,
    options.objectType,
  );

  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    notificationMessages,
    requiredFeature: objectFeatures[objectTypes.sms],
  });
}

function generateSmsNotificationMessages(container, humanReadableObjectName, objectType) {
  return NotificationMessage.extend({
    contentEditorService: service(),
    humanReadableObjectName,
    async confirmation() {
      await this.contentEditorService.ruleset.fetchAudiencePreview();

      let ruleset = this.contentEditorService.ruleset;
      let sms = ruleset.rulesetLinks.firstObject.object;
      let smsContent = sms.localizedSmsMessageContents.firstObject;

      let audienceCount = ruleset.audiencePreview.count;
      let audience = `${numberFormatter(audienceCount)} ${pluralize('person', audienceCount)}`;

      let title = `Ready to set your ${this.humanReadableObjectName} live?`;
      let confirmButtonText = `Set live`;

      let segments = `${numberFormatter(smsContent.segmentsCount)} ${pluralize(
        'segment',
        smsContent.segmentsCount,
      )}`;
      let totalMessageCount = audienceCount * smsContent.segmentsCount;

      if (ruleset.hasControlGroup) {
        totalMessageCount = Math.ceil(totalMessageCount / 2);
      }

      let totalMessages = `${numberFormatter(totalMessageCount)} ${pluralize(
        'message',
        totalMessageCount,
      )}`;

      let body;
      if (ruleset.hasControlGroup) {
        body = `Part of your audience of ${audience} will be sent this ${this.humanReadableObjectName} and the rest will join the control group.`;
        if (!ruleset.isMatchBehaviorStatic) {
          body +=
            ' Going forward, anyone who matches your rules in the future will be split similarly.';
        }
      } else if (ruleset.rulesetTriggers.length > 0) {
        body = `Your ${this.humanReadableObjectName} will be sent to people who trigger the specified event and match the audience rules. ${audience} currently match your audience rules.`;
      } else {
        body = `Your ${this.humanReadableObjectName} will be sent to ${audience}`;
        if (ruleset.isMatchBehaviorStatic) {
          body += '.';
        } else {
          body += ' now and anyone who matches your rules in the future.';
        }
        if (!ruleset.isDraft) {
          body += ` Anyone who has already received this ${this.humanReadableObjectName} will not receive it again.`;
        }
      }

      if (smsContent.segmentsCount > 1) {
        body += ` Due to the number of characters used, each SMS would be divided in ${segments}, resulting in`;

        if (smsContent.hasAttributes) {
          body += ` potentially more than`;
        }

        body += ` ${totalMessages}`;

        if (!ruleset.isMatchBehaviorStatic) {
          body += ` now, and ${numberFormatter(smsContent.segmentsCount)} ${pluralize(
            'message',
            smsContent.segmentsCount,
          )} for every person that matches in the future`;
        }

        body += '.';
      } else if (smsContent.hasAttributes) {
        body += ` Depending on the length of attributes, each SMS may be segmented, resulting in potentially more than ${totalMessages}`;

        if (!ruleset.isMatchBehaviorStatic) {
          body += ` now, and ${numberFormatter(smsContent.segmentsCount)} ${pluralize(
            'message',
            smsContent.segmentsCount,
          )} for every person that matches in the future`;
        }

        body += '.';
      }

      return {
        title,
        confirmButtonText,
        confirmContext: {
          body,
          objectType,
          isDynamicMessage: !ruleset.isMatchBehaviorStatic,
        },
        bodyComponentName: 'content-editor/set-live-confirmation-body',
        shouldTrackAnalyticsEvents: true,
        analyticsEventChannelName: objectNames[objectType],
      };
    },
  }).create({
    success: `The ${humanReadableObjectName} was set live`,
    failure: `We couldn't set this ${humanReadableObjectName} live. Send us a message and we'll do our best to help out.`,
    container,
  });
}
