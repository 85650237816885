/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';

export default class RulesetTrigger extends Model {
  @attr('number') eventId;
  @attr('string') eventName;
  @attr('number') eventType;
  @attr('number') rulesetId;
  @fragment('predicates/predicate-group') eventPredicateGroup;
}
