/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import CustomBotSummary, { type CustomBotSummaryWireFormat } from '../custom-bot-summary';

interface AiAgentFoundNoAnswerWireFormat {
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

export default class AiAgentFoundNoAnswer implements RenderableData {
  readonly renderableType = RenderableType.AiAgentFoundNoAnswer;
  readonly customBotSummary?: CustomBotSummary;

  constructor(customBotSummary?: CustomBotSummary) {
    this.customBotSummary = customBotSummary;
  }

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  static deserialize(json: AiAgentFoundNoAnswerWireFormat): AiAgentFoundNoAnswer {
    return new AiAgentFoundNoAnswer(
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
