/* RESPONSIBLE TEAM: team-customer-data-platform */
import Model, { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';

export default class ApiUsageData extends Model {
  @attr('string') sectionId;
  @attr('number', { defaultValue: 0 }) intercomApiConsumption;
  @attr('number') organizationApiConsumption;
  @attr('number') organizationApiLimit;
  @fragment('integrations/salesforce/settings') settings;
}
