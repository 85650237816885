/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';

export default class ContentStatistic extends Model {
  @attr('number') declare value?: number;
  @attr('number') declare denominator?: number;
  @attr('number') declare key: number;
  @attr('number') declare type: number;
  @attr declare subaggregations: unknown;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'outbound/content-statistic': ContentStatistic;
  }
}
