/* RESPONSIBLE TEAM: team-proactive-support */
import SeriesModeConfig from 'embercom/objects/content-editor/series-mode-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';

export default function generateEditorConfig(options) {
  let container = options.container;

  return new SeriesModeConfig({
    headerComponentConfig: StandardSeriesModeHeaderConfiguration.create({ container }),
    editorComponentConfigs: [
      ComponentConfiguration.create({
        component: 'content-editor/panels/content/tagger/body',
      }),
    ],

    viewModeHeaderConfig: StandardSeriesViewModeHeaderConfiguration.create({ container }),
    viewModeSummaryConfig: ComponentConfiguration.create({
      component: 'content-editor/summaries/content/tagger/summary',
    }),
  });
}
