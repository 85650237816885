/* RESPONSIBLE TEAM: team-ai-agent */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { dependentKeyCompat } from '@ember/object/compat';

export default class ExampleQuestion extends Fragment {
  @attr('string') text;
  @attr('string') identifier;
  @attr('string') addedFrom;

  @tracked isAdded = false;

  get hasContent() {
    return this.text?.length > 0;
  }

  @dependentKeyCompat
  get sourceConversationId() {
    // we'll likely move this to the server in the near future.
    // for now, this uses the identifier to determine if there is a source conversation id
    // identifier can take these forms:
    //  6-123456789 - where 123456789 is the conversation id
    //  zendesk-* - imported from zendesk
    //  <null> - manually added
    let parts = this.identifier?.split('-');
    if (parts?.length === 2) {
      if (Number.isInteger(parseInt(parts[0], 10))) {
        return parts[1];
      }
    }
  }
}
