/* RESPONSIBLE TEAM: team-workflows */
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import type Store from '@ember-data/store';
import type ConnectionPoint from '../connection-point';
import { ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  outwardConnectionPoints: [validator('has-many')],
  objectTypeDisplayAttributeId: [validator('presence', true)],
  relationshipAttributeForButtonGenerationId: [validator('presence', true)],
  attributeObjectTypeForButtonGeneration: [validator('presence', true)],
  relationshipAttributeForUserSelectionId: [validator('presence', true)],
  attributeObjectTypeForUserSelection: [validator('presence', true)],
});
export default class CustomObjectSelector extends Step.extend(Validations) {
  @attr('string') declare objectTypeDisplayAttributeId?: string;
  @attr('string') declare relationshipAttributeForButtonGenerationId?: string;
  @attr('string') declare objectTypeForButtonGeneration?: string;
  @attr('string') declare attributeObjectTypeForButtonGeneration?: string;
  @attr('string') declare relationshipAttributeForUserSelectionId?: string;
  @attr('string') declare attributeObjectTypeForUserSelection?: string;
  @attr('number', { defaultValue: 5 }) declare replyLimit?: number;

  supportedChannels = ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL;

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'conversation_attributes';
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  static createNewStep(store: Store): CustomObjectSelector {
    let outwardConnectionPoints = [
      store.createRecord('operator/visual-builder/connection-point', {
        type: 'follow_up',
      }),
      store.createRecord('operator/visual-builder/connection-point', {
        type: 'fallback',
      }),
    ] as ConnectionPoint[];
    return store.createRecord('operator/visual-builder/step/custom-object-selector', {
      outwardConnectionPoints,
      type: 'operator/visual-builder/step/custom-object-selector',
    });
  }
}
