/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import { gt, empty, and } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
const CHAR_LIMIT = 160;

export default Fragment.extend({
  localeId: attr('string'),
  name: attr('string'),
  title: attr('string'),
  headline: attr('string'),
  isSelected: attr('boolean'),

  invalidTitle: and('isSelected', 'missingTitle'),
  missingTitle: empty('title'),

  invalidHeadline: and('isSelected', 'headlineTooLong'),
  headlineTooLong: gt('headline.length', CHAR_LIMIT),
});
