/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';
import StandardInserterComponentConfiguration from 'embercom/objects/series/node/standard-inserter-component-configuration';
import StandardLoadingComponentConfiguration from 'embercom/objects/series/node/standard-loading-component-configuration';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class PushInserterConfiguration extends InserterConfiguration {
  objectType = objectTypes['push'];
  objectData = {};

  componentConfig = new StandardInserterComponentConfiguration({
    icon: 'series/content/push',
    title: capitalize(objectNames[this.objectType]),
  });

  loadingComponentConfig = new StandardLoadingComponentConfiguration({
    icon: 'series/content/push',
    title: `Empty ${objectNames[this.objectType]}`,
    objectType: this.objectType,
  });

  isTooltipDisabled(app) {
    return app.hasIOSSdk || app.hasAndroidSdk;
  }

  disabledTooltipContent() {
    return 'You need to upload a push certificate for iOS or a FCM key for Android to use this template';
  }

  canInsertNode({ app, _dataObject }) {
    if (app.hasIOSSdk || app.hasAndroidSdk) {
      return true;
    }

    return false;
  }
}
