/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { SenderType } from './types';

export const senderTypes = SenderType;

export const contentWrapperTypes = {
  message: 0,
  ruleset: 1,
  series: 2,
  article: 3,
  answer: 4,
  selectionSet: 5,
  externalContent: 6,
  aiContent: 7,
  internalNote: 8,
};

export const statisticKeys = {
  receipts: 0,
  replies: 1,
  opens: 2,
  clicks: 3,
  lastSent: 4,
  goals: 5,
  failures: 6,
  emailFailures: 6,
  tourStepFailures: 7,
  completions: 8,
  tourStepViews: 9,
  reactions: 10,
  dismissals: 11,
  collectedEmails: 12,
  seriesContentReceipts: 13,
  seriesCompletions: 14,
  seriesExits: 15,
  seriesDisengagements: 16,
  webhookFailures: 17,
  answers: 18,
  keywordReplies: 19,
  finHelpfulAnswers: 20,
  articleContentView: 21,
};

export const editorViews = {
  reporting: 'reporting',
};

export const statisticTypes = {
  integer: 0,
  date: 1,
  percentage: 2,
};

export const preferredDevices = {
  web: 0,
  ios: 1,
  android: 2,
};

export const deliveryChannels = {
  mobileAndWeb: [preferredDevices.web, preferredDevices.ios, preferredDevices.android],
  mobile: [preferredDevices.ios, preferredDevices.android],
  web: [preferredDevices.web],
  ios: [preferredDevices.ios],
  android: [preferredDevices.android],
};

export const sentAsOptions = {
  snippet: 0,
  full: 1,
  badge: 2,
  silent: 3,
};

export const defaultBlock = { type: 'paragraph', text: 'Hi {{first_name|fallback:"there"}},' };
export const defaultBlockWithModelKey = {
  type: 'paragraph',
  text: 'Hi {{first_name|fallback:"there"}},',
  modelKey: 'common/blocks/paragraph',
};

export const defaultEmptyTextBlockWithModelKey = {
  type: 'paragraph',
  text: '',
  modelKey: 'common/blocks/paragraph',
};

export const defaultNPSQuestionBlockWithModelKey = {
  type: 'paragraph',
  text: 'How likely are you to recommend us to family and friends?',
  modelKey: 'common/blocks/paragraph',
};

export const defaultNumericQuestionBlockWithModelKey = {
  type: 'paragraph',
  text: 'How satisfied are you with our product?',
  modelKey: 'common/blocks/paragraph',
};

export const PAGE_SIZE = 25;
export const WILDCARD_TITLE_SEARCH = false;
export const CB_PAGE_SIZE = 100; // see: https://github.com/intercom/intercom/issues/215023. TLDR: re-ordering objects is broken when customers have more than one page. This is a mitigation until the issue is properly fixed.

export const emailTemplateTypes = {
  premade: 0,
  custom: 1,
  htmlMode: 2,
};

export const premadeTemplates = {
  personal: 0,
  plain: 1,
  company: 2,
  announcement: 3,
  official: 4,
  plain_responsive: 5,
  personal_block: 6,
  company_block: 7,
};

export const premadeBlockTemplates = [
  premadeTemplates.personal_block,
  premadeTemplates.company_block,
];

export const premadeTemplateIDMapping = Object.keys(premadeTemplates).reduce(
  (map, templateName) => {
    map[premadeTemplates[templateName]] = templateName;
    return map;
  },
  {},
);

export const outboundCustomReportTests = {
  testA: 0,
  testB: 1,
  noTest: 2,
};

export const templateSwitcherOptions = {
  emailContent: 'emailContent',
  templateContent: 'templateContent',
};
