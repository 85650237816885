/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type HelpCenterSiteLink from './help-center-site-link';

export default class HelpCenterSiteLinkGroup extends Fragment {
  @attr('string') declare title: string;
  @attr('number') declare siteLinkGroupId: number;
  @attr('number') declare sortOrder: number;
  @attr('string') declare locale: string;

  @fragmentArray('help-center-site-link') declare links: HelpCenterSiteLink[];

  addLink() {
    let newLink: HelpCenterSiteLink = this.store.createFragment('help-center-site-link');
    newLink.sortOrder = this.links.length + 1; // Sort order is 1-indexed for some reason
    this.links.pushObject(newLink);
  }

  preview() {
    return {
      title: this.title,
      links: this.links.map((link: any) => {
        return link.preview();
      }),
    };
  }
}
