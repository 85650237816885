/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import GraphConfiguration from 'embercom/objects/series/node/graph-configuration';
import StandardNodeComponentConfiguration from 'embercom/objects/series/node/standard-node-component-configuration';
import SplitTestConnectorConfiguration from 'embercom/objects/series/node/split-test-connector-configuration';
import StatsConfiguration from 'embercom/objects/series/node/stats-configuration';
import {
  objectTypes,
  objectNames,
  objectFeatures,
} from 'embercom/models/data/matching-system/matching-constants';
import { seriesEdgeTypes } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class TestGraphConfiguration extends GraphConfiguration {
  objectType = objectTypes.splitter;

  constructor(seriesNode) {
    super(...arguments);

    this.componentConfig = new StandardNodeComponentConfiguration({
      icon: 'series/content/splitter',
      paywallIcon: 'series/content/paywalled-splitter',
      objectType: this.objectType,
      featureFlagsWithAccess: [objectFeatures[this.objectType]],
      title: seriesNode.get('rulesetClientData.title') || capitalize(objectNames[this.objectType]),
      statsSummaryConfigurationComponent: new StatsConfiguration({
        verb: 'split',
        component: 'series/node/standard-stats-summary',
      }),
    });
  }

  get connectors() {
    return this.node.edgeSplits.map((edgeSplit) => {
      return new SplitTestConnectorConfiguration({
        edgeSplit,
        edgeType: seriesEdgeTypes.split,
      });
    });
  }

  get inferredTitle() {
    return '';
  }

  get defaultTitle() {
    return 'Split Test';
  }

  get primaryPredecessorLabel() {
    return '';
  }

  get disableStatsPopover() {
    return true;
  }

  get checkpointStats() {
    return [
      { label: 'split', value: this.node.completedTraversedSplitEdgesCheckpointCount },
      { label: 'waiting to be split', value: this.node.activeCheckpointCount },
    ];
  }
}
