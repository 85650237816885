/* RESPONSIBLE TEAM: team-app-security */

import Model, { attr } from '@ember-data/model';

export default class IdentityVerificationReminder extends Model {
  @attr('number') app_id;
  @attr('date') send_at;
  @attr('date') sent_at;
  @attr('date') created_at;
  @attr('date') updated_at;
}
