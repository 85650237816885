/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { COPILOT_SEAT_TYPE as COPILOT } from 'embercom/lib/settings/seats/constants';
import {
  COPILOT_PERMISSION,
  NO_ACCESS,
  UNLIMITED_USAGE,
} from 'embercom/lib/settings/copilot-access-editor/constants';

export default function mapSelectionToSeatAndPermission(
  selection,
  onSeatToggle,
  permissionsObject,
  accessType,
) {
  if (isSwitchingToOrFromUnlimited(selection, accessType)) {
    onSeatToggle(COPILOT);
  }

  if (isSwitchingToNoAccess(selection, accessType)) {
    permissionsObject[COPILOT_PERMISSION] = false;
  }

  if (isSwitchingFromNoAccess(selection, accessType)) {
    permissionsObject[COPILOT_PERMISSION] = true;
  }
}

function isSwitchingToOrFromUnlimited(selection, accessType) {
  return accessType === UNLIMITED_USAGE || selection === UNLIMITED_USAGE;
}

function isSwitchingToNoAccess(selection, accessType) {
  return selection === NO_ACCESS && accessType !== NO_ACCESS;
}

function isSwitchingFromNoAccess(selection, accessType) {
  return accessType === NO_ACCESS && selection !== NO_ACCESS;
}
