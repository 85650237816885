/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type ParentActionConfig, ParentAction, ChildAction, type Action } from './action';
import { ConversationState } from 'embercom/objects/inbox/conversation';
import SnoozeAction from './snooze';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';

enum ChangeStateActionID {
  Open = 'open',
  Close = 'close',
  Snooze = 'snooze',
}

export default class ChangeStateAction extends ParentAction {
  id = 'change-state';
  hidden = true;
  paneComponent = 'inbox2/command-k/change-state/pane';

  actionOwner: unknown;
  closeAction: ChildAction;
  openAction: ChildAction;
  snoozeAction: SnoozeAction;

  constructor(args: ParentActionConfig) {
    super(args);

    this.actionOwner = args.owner;

    this.closeAction = new ChildAction({
      parent: this,
      id: ChangeStateActionID.Close,
      label: this.intl.t('inbox.command-k.actions.close'),
      icon: 'close-conversation',
      shortcutId: HotkeyID.Close,
      optionComponent: 'inbox2/command-k/change-state/option',
    });

    this.openAction = new ChildAction({
      parent: this,
      id: ChangeStateActionID.Open,
      label: this.intl.t('inbox.command-k.actions.open'),
      icon: 'reopen-conversation',
      shortcutId: HotkeyID.Reopen,
      optionComponent: 'inbox2/command-k/change-state/option',
    });

    this.snoozeAction = new SnoozeAction({
      parent: this,
      owner: this.actionOwner,
      optionComponent: 'inbox2/command-k/change-state/option',
    });
  }

  buildTopLevelItems(query: string): Array<Action> {
    return this.buildResults(query);
  }

  buildResults(query: string) {
    let c = [];
    switch (this.context?.currentState) {
      case ConversationState.Open:
        c.push(this.closeAction, this.snoozeAction);
        break;
      case ConversationState.Closed:
        c.push(this.openAction, this.snoozeAction);
        break;
      case ConversationState.Snoozed:
        c.push(this.openAction, this.closeAction);
        break;
    }

    let upperQuery = query.trim().toLocaleUpperCase();
    return c.filter((action) => action.label.toLocaleUpperCase().includes(upperQuery));
  }
}
