/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { type SyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import {
  edgeSplitTypes,
  edgeSplitIndexName,
} from 'embercom/models/data/matching-system/matching-constants';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { isPresent } from '@ember/utils';
import type SeriesEdge from './edge';
import type SeriesSplitter from './splitter';
import type ContentStatistic from '../outbound/content-statistic';

export default class EdgeSplit extends Model {
  @belongsTo('series/splitter') declare splitter: SeriesSplitter;
  @belongsTo('series/node') node: $TSFixMe;
  @belongsTo('series/edge', { async: false }) declare edge: SeriesEdge;
  @hasMany('outbound/content-statistic', { async: true })
  declare stats: SyncHasMany<ContentStatistic>;
  @attr('number') declare splitType: number;
  @attr('number') declare percentage: number;
  @attr('number') declare index: keyof typeof edgeSplitIndexName;

  get hasEdge() {
    return isPresent(this.edge);
  }

  get name() {
    if (this.isTest) {
      return `Test ${edgeSplitIndexName[this.index]}`;
    } else {
      return 'Control';
    }
  }

  get isTest() {
    return this.splitType === edgeSplitTypes.test;
  }

  get isControl() {
    return this.splitType === edgeSplitTypes.control;
  }

  get goalStats() {
    return this.stats.find((stat) => stat.key === statisticKeys.goals);
  }
}
