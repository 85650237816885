/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';

export default Fragment.extend({
  contactAutoCreationEnabled: attr('boolean'),
  leadAttributionEnabled: attr('boolean'),
  shouldReauthenticate: attr('boolean'),
  attributeMappings: fragmentArray('crm/attribute-mapping'),
  attributeOptionsForType: fragment('crm/attribute-options'),
  webhookFilters: fragment('crm/webhook-filter'),
});
