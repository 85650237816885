/* RESPONSIBLE TEAM: team-frontend-tech */
import Model, { attr, belongsTo } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Admin from 'embercom/models/admin';
import LocalizedBannerContentValidations from 'embercom/validations/localized-banner-content';

const UNASSIGNED_SENDER = -1;

export default class LocalizedBannerContent extends Model.extend(
  LocalizedBannerContentValidations,
) {
  @service store;

  @attr('number') actionContentId;
  @attr('number') actionContentType;
  @attr('string') actionHref;
  @attr('string') actionTitle;
  @belongsTo('banners/banner', { inverse: 'localizedBannerContents' }) banner;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
  })
  blocks;
  @attr('number') fromId;
  @attr('string') locale;
  @attr('number') senderType;
  @attr('string') actionContentTitle;

  get contentHash() {
    return {
      action: this.banner.get('action'),
      actionTitle: this.actionTitle,
      actionHref: this.actionHref,
      blocks: this.blocks.serialize(),
      position: this.banner.get('position'),
      reactionSet: this._reactionSet(),
      style: this.banner.get('style'),
    };
  }

  get from() {
    if (this.fromId && this.fromId !== UNASSIGNED_SENDER) {
      return Admin.peekAndMaybeLoad(this.store, this.fromId);
    }
  }

  _reactionSet() {
    return this.banner.get('reactionSet').map((reaction) => reaction.unicode_emoticon);
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.blocks.any((block) => block.hasDirtyAttributes);
  }
}
