/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr } from '@ember-data/model';

export default class ContentObject extends Model {
  @attr('string') title;
  @attr('string') app_id;
  @attr('string') content_object_id;
  @attr('string') series_id;
  @attr('boolean') has_goal;

  get displayedTitle() {
    if (this.title) {
      return this.title;
    } else {
      return 'Untitled';
    }
  }
}
