/* RESPONSIBLE TEAM: team-workflows */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/inbound-custom-bot/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/inbound-custom-bot/edit-mode-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import generateBotNotificationMessages from 'embercom/objects/content-editor/configuration-generators/bot-notification-messages-generator';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.inboundCustomBot;
  let container = options.container;
  let customBot = options.customBot;
  let intl = options.container.lookup('service:intl');
  let humanReadableObjectName = humanReadableObjectNames[options.objectType];
  let notificationMessages = generateNotificationMessages(options);
  notificationMessages.activate = generateBotNotificationMessages(
    container,
    humanReadableObjectName,
  );
  let paywallUpgradeHoverCopy = 'operator.custom-bot.header.set-live-paywall-text-with-workflows';

  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    notificationMessages,
    requiredFeature: customBot.config.requiredBillingFeature,
    paywallHoverParams: {
      customFeatureText: intl.t(paywallUpgradeHoverCopy),
    },
  });
}
