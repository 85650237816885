/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type RenderableData } from '../renderable-data';

interface PhoneCallWireFormat {
  renderable_type: RenderableType;
  call_type: string;
  action: string;
}

export default class PhoneCall implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.PhoneCall;
  readonly callType: string;
  readonly action: string;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(call_type: string, action: string) {
    this.callType = call_type;
    this.action = action;
  }

  static deserialize(json: PhoneCallWireFormat): PhoneCall {
    return new PhoneCall(json.call_type, json.action);
  }
}
