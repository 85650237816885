/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import SurveyContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/survey/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import SeriesSchedulePanelConfiguration from 'embercom/objects/content-editor/panels/schedule/series-schedule-panel-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';
import SurveySharingPanelConfiguration from 'embercom/objects/content-editor/panels/custom/survey-sharing/panel-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import SurveyAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/content/survey/audience-editor-configuration';
import SurveyAudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/content/survey/audience-header-configuration';

export default function generateEditModeConfig(options) {
  let container = options.container;

  let audienceEditorConfiguration = new SurveyAudienceEditorConfiguration({
    showEventRules: true,
    showDateEvents: true,
    container,
  });

  audienceEditorConfiguration.footer = ComponentConfiguration.create({
    component: 'content-editor/panels/audience/content/survey/footer',
    config: audienceEditorConfiguration,
  });

  return EditModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesModeHeaderConfiguration.create({
      container,
      previewConfig: ComponentConfiguration.create({
        container,
        component: 'content-editor/headers/surveys/preview-button',
      }),
    }),
    headerComponentConfigs: [
      StandardEditModeHeaderConfiguration.create({
        container,
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/surveys/preview-button',
        }),
      }),
    ],
    editorPanelConfigs: [
      SurveyContentPanelConfiguration.create({ container }),
      StandardAudiencePanelConfiguration.create({
        audienceHeaderConfiguration: SurveyAudienceHeaderConfiguration.create({ container }),
        audienceEditorConfiguration,
        container,
      }),
      StandardSchedulePanelConfiguration.create({
        showFrequencyEditor: true,
        container,
      }),
      SurveySharingPanelConfiguration.create({ container }),
      SeriesSchedulePanelConfiguration.create({ container }),
      StandardGoalPanelConfiguration.create({
        introText: 'Send surveys to your customers to learn more about them',
        container,
      }),
    ],
    footerComponentConfigs: [],
  });
}
