/* RESPONSIBLE TEAM: team-help-desk-experience */
import Tag, { type TagWireFormat } from './tag';

export interface PartTagsWireFormat {
  [partId: string]: Array<TagWireFormat>;
}

export interface PartTags {
  [partId: string]: Array<Tag>;
}

export interface ConversationTaggingsWireFormat {
  conversation_part_tags: PartTagsWireFormat;
  initial_part_tags: PartTagsWireFormat;
}

export default class ConversationTaggings {
  readonly conversationPartTags: PartTags;
  readonly initialPartTags: PartTags;

  constructor(conversationPartTags: PartTags, initialPartTags: PartTags) {
    this.conversationPartTags = conversationPartTags;
    this.initialPartTags = initialPartTags;
  }

  static deserialize(json: ConversationTaggingsWireFormat): ConversationTaggings {
    let deserializedConversationPartTags = this.deserializePartTags(json.conversation_part_tags);
    let deserializedInitialPartTags = this.deserializePartTags(json.initial_part_tags);

    return new ConversationTaggings(deserializedConversationPartTags, deserializedInitialPartTags);
  }

  static deserializePartTags(partTags: PartTagsWireFormat): PartTags {
    let deserializedPartTags = {} as PartTags;
    for (let partId in partTags) {
      if ({}.hasOwnProperty.call(partTags, partId)) {
        let tags = partTags[partId];
        let deserializedTags = tags.map((tagJson) => Tag.deserialize(tagJson));
        deserializedPartTags[partId] = deserializedTags;
      }
    }
    return deserializedPartTags;
  }
}
