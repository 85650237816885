/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import GraphConfiguration from 'embercom/objects/series/node/graph-configuration';
import StandardNodeComponentConfiguration from 'embercom/objects/series/node/standard-node-component-configuration';
import ConnectorConfiguration from 'embercom/objects/series/node/connector-configuration';
import StatsConfiguration from 'embercom/objects/series/node/stats-configuration';
import {
  objectTypes,
  seriesEdgeTypes,
  objectFeatures,
} from 'embercom/models/data/matching-system/matching-constants';
import renderPreview from '@intercom/embercom-composer/lib/render-preview';
import { htmlToTextContent } from 'embercom/lib/html-unescape';

export default class WhatsappGraphConfiguration extends GraphConfiguration {
  objectType = objectTypes.whatsapp;

  constructor(seriesNode) {
    super(...arguments);
    this.componentConfig = new StandardNodeComponentConfiguration({
      icon: 'series/content/whatsapp',
      paywallIcon: 'series/content/paywalled-whatsapp',
      featureFlagsWithAccess: [
        objectFeatures[objectTypes.whatsapp],
        'whatsapp_outbound_billing_feature',
      ],
      objectType: this.objectType,
      statsSummaryConfigurationComponent: new StatsConfiguration({
        verb: 'sent',
        component: 'series/node/standard-stats-summary',
      }),
    });
  }

  get defaultTitle() {
    return 'Empty WhatsApp message';
  }

  get connectors() {
    return [
      new ConnectorConfiguration({
        label: 'WHEN SENT',
        edgeType: seriesEdgeTypes.primary,
        component: 'series/node/connectors/content-shortcuts-connector',
      }),
    ];
  }

  get inferredTitle() {
    let contentPreview = this.node.contentPreviews.firstObject?.contentPreviewData?.content;
    if (contentPreview) {
      let previewHtml = renderPreview(contentPreview, {});
      return htmlToTextContent(previewHtml);
    }
    return '';
  }

  get primaryPredecessorLabel() {
    return `Received '${this.title}'`;
  }

  get checkpointStats() {
    return [
      { label: 'successfully sent', value: this.node.completedCheckpointCount },
      {
        label: 'ineligible to send',
        value:
          this.node.completedTraversedAlternativeEdgesCheckpointCount +
          this.node.completedEjectedCheckpointCount,
      },
    ];
  }
}
