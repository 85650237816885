/* RESPONSIBLE TEAM: team-help-desk-experience */

import Model, { type SyncHasMany, attr, hasMany } from '@ember-data/model';
import type CustomEmailAddress from './custom-email-address';

export default class SenderEmailAddressSettings extends Model {
  @attr('string') declare email: string;
  @attr('string') declare brandId: string | null;
  @attr('string') declare brandName: string | null;
  @attr('boolean') declare verified: boolean;
  @attr('boolean') declare forwardingEnabled: boolean;
  @attr('date') declare forwardedEmailLastReceivedAt: Date;

  @hasMany('custom-email-address') declare senderEmailAddresses: SyncHasMany<CustomEmailAddress>;
}
