/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { attr } from '@ember-data/model';
import { type Block } from '@intercom/interblocks.ts';
import Fragment from 'ember-data-model-fragments/fragment';

export default class HelpCenterFooterTextContent extends Fragment {
  @attr('string') declare locale: string;
  @attr('array') declare jsonBlocks: Array<Block>;
}
