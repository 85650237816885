/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';

export default StandardSummaryConfiguration.extend({
  init(inputs) {
    let title = inputs.title || 'Content';
    this._super({
      title,
      component: 'content-editor/summaries/content/custom-bot/summary',
      isVisible: true,
    });
  },
});
