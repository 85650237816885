/* RESPONSIBLE TEAM: team-frontend-tech */
import HtmlCleaner from '@intercom/embercom-composer/lib/html-cleaner';

export default function (url) {
  let cleaner = new HtmlCleaner();

  if (cleaner.isJavascriptOrDataUri(url)) {
    console.error('Cannot open new window, URL is to a disallowed protocol.');
    return;
  }
  return window.open(...arguments); //eslint-disable-line @intercom/intercom/no-window-open
}
