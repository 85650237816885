/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';

export default Model.extend({
  appId: attr('string'),
  appName: attr('string'),
  teammate: fragment('meeting-scheduler/teammate'),
  slots: hasMany('meeting-scheduler/slot'),
  emailFromConversation: attr('string'),
  userIdFromConversation: attr('string'),
  timezone: attr('string'),
});
