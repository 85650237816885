/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { not } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { computed } from '@ember/object';

export default Model.extend({
  app_id: attr(),
  admin_id: attr(),
  conversation_id: attr(),
  subclass: attr(),
  blocks: attr(),
  actions: attr(),
  isEmpty: computed('blocks.[]', 'subclass', function () {
    return !this.subclass || isEmpty(this.blocks);
  }),
  isNotEmpty: not('isEmpty'),
});
