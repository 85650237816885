/* RESPONSIBLE TEAM: team-knowledge-interop */
import Model, { type SyncHasMany, attr, hasMany } from '@ember-data/model';
import {
  type MatchBehavior,
  type RoleType,
} from 'embercom/models/data/matching-system/matching-constants';
import type ContentFragment from './content-fragment';
import { type EntityType } from '../data/entity-types';
import { type HighlightedResult } from 'embercom/objects/inbox/knowledge-base/search-result';

export enum ContentWrapperType {
  MESSAGE = 0,
  RULESET = 1,
  SERIES = 2,
  ARTICLES = 3,
  ANSWERS = 4,
  SELECTION_SET = 5,
  EXTERNAL_CONTENT = 6,
  AI_CONTENT = 7,
  SUPPORT_CONTENT = 8,
}

export default class ContentWrapper extends Model {
  @attr('number') declare contentWrapperType: ContentWrapperType;
  @attr() declare contentWrapperId: number | string;
  @attr('string') declare appId: string;
  @attr('string') declare title: string;
  @attr() declare audienceTypes: Array<RoleType>;
  @attr('number') declare matchBehavior: MatchBehavior;
  @hasMany('content-service/content-fragment', { async: false, inverse: 'contentWrapper' })
  declare contents: SyncHasMany<ContentFragment>;
  @attr() declare relations: any;
  @hasMany('tag') declare tags: SyncHasMany<any>;
  @attr('number') declare priority: number;
  @attr('array') declare highlightedTitle: HighlightedResult[];
  @attr('array') declare highlightedBody: HighlightedResult[];

  get entityType(): EntityType {
    return this.contents.firstObject!.contentType;
  }

  get entityId(): number | undefined {
    return this.contents.firstObject?.contentId;
  }

  allContentIncludedIn(fragments: Set<ContentFragment>): boolean {
    return this.contents.every((contentFragment: ContentFragment) =>
      contentFragment.allContentIncludedIn(fragments),
    );
  }

  anyContentIncludedIn(fragments: Set<ContentFragment>): boolean {
    return this.contents.any((contentFragment: ContentFragment) =>
      contentFragment.allContentIncludedIn(fragments),
    );
  }
}
