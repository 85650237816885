/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';

export interface CompanyAttributes {
  id: string;
  name?: string;
  last_request_at?: string;
  created_at?: string;
  user_count?: number;
  session_count?: number;
  plan_id?: number;
  monthly_spend?: number;
  size?: number;
  website?: string;
  tag_ids?: number[];
  note_ids?: number[];
  custom_data?: Record<string, unknown>;
  remote_company_id?: string;
  [key: string]: unknown;
}

export default class Company {
  readonly attributes: CompanyAttributes;

  @tracked noteIds?: string[];
  @tracked tagIds?: string[];

  constructor(summary: CompanyAttributes) {
    this.attributes = summary;

    this.tagIds = summary.tag_ids?.map(String) ?? [];
    this.noteIds = summary.note_ids?.map(String) ?? [];
  }

  static deserialize(json: CompanyAttributes): Company {
    return new Company(json);
  }

  get id() {
    return this.attributes.id;
  }

  get name() {
    return this.attributes.name;
  }

  get lastRequestAt() {
    return this.attributes.last_request_at;
  }

  get createdAt() {
    return this.attributes.created_at;
  }

  get userCount() {
    return this.attributes.user_count;
  }

  get sessionCount() {
    return this.attributes.session_count;
  }

  get planId() {
    return this.attributes.plan_id;
  }

  get monthlySpend() {
    return this.attributes.monthly_spend;
  }

  get size() {
    return this.attributes.size;
  }

  get website() {
    return this.attributes.website;
  }

  get displayAs(): string {
    return this.name || this.id;
  }

  get remoteCompanyId() {
    return this.attributes.remote_company_id;
  }

  setTagIds(updatedTagIds: string[]) {
    this.tagIds = updatedTagIds ?? [];
  }

  setNoteIds(updatedNoteIds: string[] | undefined) {
    this.noteIds = updatedNoteIds ?? [];
  }
}
