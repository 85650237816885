/* RESPONSIBLE TEAM: team-ai-agent */
import type SetLanguageOverride from 'embercom/models/operator/visual-builder/step/set-language-override';
import StepConfig, { type StepConfigParams } from '../step';

export default class RemoveTagFromConversationConfiguration extends StepConfig {
  declare step: SetLanguageOverride;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/set-language-override',
      ...params,
    });
  }
}
