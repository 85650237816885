/* RESPONSIBLE TEAM: team-proactive-support */
import { assert } from '@ember/debug';

export default class SeriesEditorConfiguration {
  seriesMode = null;
  notificationMessages = null;

  constructor({ seriesMode, notificationMessages }) {
    assert('A series mode configuration must be passed', seriesMode);
    assert('Notification messages must be passed', notificationMessages);

    seriesMode.parentConfiguration = this;
    Object.assign(this, { seriesMode, notificationMessages });
  }
}
