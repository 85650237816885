/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { readOnly, and, not, or, bool } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Workflowable from 'embercom/models/mixins/operator/workflowable';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  firstPathConstraints: {
    description: 'First Path',
    validators: [validator('custom-bot-first-path-constraints')],
  },
  paths: {
    description: 'Paths',
    validators: [validator('presence', true), validator('has-many')],
    disabled: bool('model.canUseVisualBuilderEditor'),
  },
  visualBuilderObject: validator('belongs-to', {
    description: 'Workflow',
    disabled: not('model.canUseVisualBuilderEditor'),
  }),
});

export default Model.extend(Workflowable, Validations, {
  appService: service(),
  app: readOnly('appService.app'),

  locale: attr('string'),
  name: attr('string'),
  enabled: attr('boolean'),
  default: attr('boolean'),
  visualBuilderObject: belongsTo('operator/visual-builder/workflow', { async: false }),

  isNotEnabled: not('enabled'),
  pathsAreValid: and('validations.isValid', 'validations.attrs.paths.isValid'),
  isValid: or('isNotEnabled', 'pathsAreValid'),
  canUseVisualBuilderEditor: computed('paths.length', 'visualBuilderObject', function () {
    return !this.paths.length && this.visualBuilderObject;
  }),

  contentType: 'generic-triage',
  allowsActionsInTerminalStep: true,
  analyticsData: computed('id', 'locale', function () {
    return {
      object: 'generic-triage',
      answer_id: this.id,
      language_code: this.locale,
    };
  }),

  hasUnsavedChanges: or('hasDirtyAttributes', 'visualBuilderObject.hasUnsavedChanges'),

  rollbackAttributes() {
    this._super(...arguments);
    this.visualBuilderObject?.rollbackAttributes();
  },
});
