/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { belongsTo } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import LocalizedSmsContentValidations from 'embercom/validations/localized-sms-message-content';
import { SegmentedMessage } from 'sms-segments-calculator';
import { attributes_regex } from 'embercom/models/data/matching-system/matching-constants';

export default class localizedSmsMessageContents extends Model.extend(
  LocalizedSmsContentValidations,
) {
  @belongsTo('sms/sms', { inverse: 'localizedSmsMessageContents' }) sms;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [{ text: '', type: 'paragraph', modelKey: 'common/blocks/paragraph' }],
  })
  bodyBlocks;

  get isValid() {
    return this.bodyBlocks.firstObject.text.length > 0;
  }

  get text() {
    return this.bodyBlocks
      .map((block, index) => {
        if (block.type === 'paragraph' && index !== this.bodyBlocks.length - 1) {
          return `${block.text}\n`;
        } else {
          return block.text;
        }
      })
      .join('');
  }

  get textExcludingAttributes() {
    return this.text.replace(attributes_regex, '');
  }

  get smsBody() {
    let body = '';

    if (this.get('sms.showCompanyInfo')) {
      body = body.concat(this.get('sms.companyInfoText'), '\n');
    }

    body = body.concat(this.textExcludingAttributes);

    if (this.get('sms.showUnsubscribeInstructions')) {
      body = body.concat('\n\n', this.get('sms.unsubscribeInstructionsText'));
    }

    return body;
  }

  get segmentsCount() {
    return new SegmentedMessage(this.smsBody).segmentsCount;
  }

  get messageLength() {
    return new SegmentedMessage(this.smsBody).numberOfCharacters;
  }

  get hasAttributes() {
    return attributes_regex.test(this.text);
  }

  get contentHash() {
    return this.bodyBlocks.serialize();
  }
}
