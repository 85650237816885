/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default {
  content: [
    { validationKey: 'validations.attrs.isUploading', type: 'validation' },
    { validationKey: 'validations.attrs.variations', type: 'validation' },
  ],
  audience: [
    { validationKey: 'validations.attrs.selectionState', type: 'validation' },
    { validationKey: 'validations.attrs.audiencePreview', type: 'validation' },
    // @angelo @pat Workaround for time being until https://github.com/intercom/intercom/issues/59886 is resolved.
    {
      validationKey: 'selectionStateValuesAreValid',
      type: 'custom',
      message: 'Your audience rules are invalid',
    },
  ],
  'delivery-conditions': [
    { validationKey: 'validations.attrs.clientPredicates', type: 'validation' },
  ],
  'follow-up': [{ validationKey: 'validations.attrs.followUpRules', type: 'validation' }],
  goal: [
    { validationKey: 'validations.attrs.goal', type: 'validation' },
    {
      validationKey: 'goalPredicatesAreValid',
      type: 'custom',
      message: 'Your message goal needs to be completed',
    },
  ],
  scheduling: [
    { validationKey: 'validations.attrs.dueToStopAt', type: 'validation' },
    { validationKey: 'validations.attrs.scheduledToGoLiveAt', type: 'validation' },
    { validationKey: 'validations.attrs.deliveryWindow', type: 'validation' },
  ],
  schedule: [],
  review: [],
};
