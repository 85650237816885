/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from 'embercom/objects/component-configuration';
import { assert } from '@ember/debug';

export default class StandardInserterComponentConfiguration extends ComponentConfiguration {
  icon = undefined;
  title = undefined;

  constructor(params = {}) {
    super({ component: 'series/node/inserters/standard' });
    this.icon = params.icon;
    this.paywallIcon = params.paywallIcon;
    this.featureFlagsWithAccess = params.featureFlagsWithAccess;
    this.title = params.title;

    assert('You must specify the icon', this.icon);
    assert('You must specify the title', this.title);
  }

  hasAccessToRequiredFeature(app) {
    if (this.featureFlagsWithAccess) {
      return this.featureFlagsWithAccess.any((feature) => app.canUseFeature(feature));
    } else {
      return true;
    }
  }
}
