/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { equal } from '@ember/object/computed';

export default Model.extend({
  name: attr('string'),
  date: attr('date'),
  latest_public: attr('boolean'),
  experimental: attr('boolean'),
  isUnstable: equal('name', 'Unstable'),
  webhookTopics: hasMany('developer-hub/webhook-topic'),
});
