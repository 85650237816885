/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { filterBy } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';

export default Model.extend({
  intercomEventService: service(),

  topics: hasMany('webhook-topic'),
  subscriptions: hasMany('slack-subscription'),

  selectedTopics: filterBy('topics', 'selected', true),

  trackEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'slack_legacy_integration',
      place: 'slack_integration',
    });
  },
}).reopenClass({
  getRedirectUrl(appId, topicIds, oauthRedirectSubpath) {
    return ajax({
      url: '/ember/slack_legacy_integrations/get_redirect_url',
      type: 'GET',
      data: {
        app_id: appId,
        topics: topicIds,
        dest: oauthRedirectSubpath,
      },
    });
  },
});
