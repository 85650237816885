/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import ajax from 'embercom/lib/ajax';

export default EmberObject.extend({
  appName: undefined,
  addOn: null,
  companySize: 'Unknown',
  department: 'Unknown',
  email: undefined,
  name: undefined,
  password: undefined,
  welcomeMessageBody: undefined,
  baseColor: undefined,
  avatar: undefined,
  termsOfServiceAccepted: false,
  userId: null,

  emailSubmissionId: undefined,
  pageviewId: undefined,
  locale: undefined,
  adminLocale: undefined,
  operatorPage: null,
  securityResearcher: false,
  viaSingleSignOn: null,
  solutionId: null,
  purchaseExperience: null,
  mobile: false,
  tablet: false,
  partnerCode: null,

  newAppFeatureFlags: null,

  signUpWithSubscription: false,
  pricingModel: null,
  planIds: null,
  couponCode: null,
  creditCardToken: null,
  countryCode: null,
  stateCode: null,
  perProductPricingFlow: null,
  existingAdmin: null,
  requireVerification: false,
  talon: '{"version": 6, "status": -1}',
  consentProvided: false,

  addOnPlanIds: null,
  billingPeriodDurationInMonths: null,
  seats: null,

  createAppWithAdminAndSignup() {
    let data = {
      admin: {
        name: this.name,
        email: this.email,
        password: this.password,
        terms_of_service: this.termsOfServiceAccepted,
        department: this.department,
        consent_provided: this.consentProvided,
        locale: this.adminLocale,
      },
      email_submission: {
        id: this.emailSubmissionId,
      },
      pageview: {
        id: this.pageviewId,
      },
      app: {
        name: this.appName,
        company_size: this.companySize,
        base_color: this.baseColor,
        security_researcher: this.securityResearcher,
        solution_id: this.solutionId,
        require_verification: this.requireVerification,
        talon: this.talon,
        billing_period_duration_in_months: this.billingPeriodDurationInMonths,
        seats: this.seats,
        add_on_plan_ids: this.addOnPlanIds,
      },
      sign_up_with_subscription: this.signUpWithSubscription,
      subscription: {
        pricing_model: this.pricingModel,
        plan_ids: this.planIds,
        coupon_code: this.couponCode,
        credit_card_token: this.creditCardToken,
        country_code: this.countryCode,
        input_country_code: this.countryCode,
        state_code: this.stateCode,
      },
      user_id: this.userId,
      sign_up_locale: this.locale,
      new_app_feature_flags: this.newAppFeatureFlags,
      welcome_message_body: this.welcomeMessageBody,
      purchase_experience: this.purchaseExperience,
      mobile: this.mobile,
      tablet: this.tablet,
      early_stage: this.earlyStage,
      product_tours: this.productTours,
      add_on: this.addOn,
      partner_code: this.partnerCode,
    };
    let params = {
      url: '/ember/signups',
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify(data),
    };
    return ajax(params);
  },
});
