/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { hasMany, attr } from '@ember-data/model';

export default Model.extend({
  sections: hasMany('inbox/admin-app-sidebar-section', { async: false }),
  notification: attr(),

  addSection(sectionToAdd) {
    this.sections.unshiftObject(sectionToAdd);
  },

  removeSection(sectionToRemove) {
    this.sections.removeObject(sectionToRemove);
  },
});
