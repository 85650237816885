/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { not, and } from '@ember/object/computed';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/control-group/summary-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';

export default function generateViewModeConfig(options) {
  // baseConfig here should be the editor configuration of the object we are creating a control
  // group for. i.e. contentEditorService.editorConfiguration
  let baseViewModeConfig = options.baseConfig.viewMode;
  let container = options.container;
  let intl = container.lookup('service:intl');
  return ViewModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesViewModeHeaderConfiguration.create({ container }),
    headerComponentConfigs: baseViewModeConfig.headerComponentConfigs,
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: intl.t('content-editor.tabs.control-group'),
          icon: 'multiple-people',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.members'),
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.extend({
          _isNotDraft: not('contentEditorService.ruleset.isDraft'),
          isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.goal'),
          view: 'goals',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        ContentSummaryConfiguration.create({ container }),
        ...baseViewModeConfig.activeViewConfiguration.content.slice(1), // drop the content summary
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          noun: 'member',
          verb: 'joined',
          emptyStateTitle: "No one's joined your control group yet",
          emptyStateDescription: ' ', // We need a non-empty value as override in order to hide description
          container,
        }),
      ],
      opens: [
        StandardStatListConfiguration.create({ stat: 'open', hideCreatedVia: true, container }),
      ],
      goals: [
        StandardStatListConfiguration.create({
          stat: 'goalSuccess',
          container,
        }),
      ],
    }),
    footerComponentConfigs: baseViewModeConfig.footerComponentConfigs,
  });
}
