/* RESPONSIBLE TEAM: team-growth-opportunities */
import Model, { attr } from '@ember-data/model';

export const STATUS_RETRY_LATER = 0;
export const STATUS_DENIED = 1;
export const STATUS_AUTHORIZED = 2;

export default class Checkout extends Model {
  @attr('number') status;

  get isNotReady() {
    return this.status === STATUS_RETRY_LATER;
  }

  get isAuthorized() {
    return this.status === STATUS_AUTHORIZED;
  }

  get isDenied() {
    return this.status === STATUS_DENIED;
  }
}
