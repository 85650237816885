/* RESPONSIBLE TEAM: team-proactive-support */
import { computed } from '@ember/object';
import { bool, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';

export default StandardPanelHeaderConfiguration.extend({
  intl: service(),
  contentEditorService: service(),
  isValid: bool('contentEditorService.activeObject.botData.selector'),
  errors: computed('isValid', 'intl.locale', function () {
    if (!this.isValid) {
      return [
        {
          message: this.intl.t(
            'operator.custom-bot.editor.choose-where-to-launch-your-bot-panel.error-message',
          ),
        },
      ];
    }
    return [];
  }),
  isComplete: readOnly('isValid'),
  init(inputs) {
    inputs.component = 'content-editor/panels/element-selector/custom-bot/header';
    inputs.title =
      inputs.title ||
      this.intl.t('operator.custom-bot.editor.choose-where-to-launch-your-bot-panel.title');
    this._super(...arguments);
  },
});
