/* RESPONSIBLE TEAM: team-pricing-and-packaging */
// eslint-disable-next-line @intercom/intercom/max-file-length
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { CORE_SEAT_METRIC, getProductInfo } from 'embercom/lib/billing';
import usageFormatter from 'embercom/lib/usage-formatter';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import numericFormatter from 'embercom/lib/numeric-formatter';

export default class Price extends Model {
  @service intl;
  @service customerService;

  @attr('string') app_id;
  @attr('number') amount;
  @attr('number') baseCost;
  @attr('number') preDiscountBaseCost;
  @fragmentArray('billing/price/breakdown') breakdown;
  @attr() combined_metric_prices_by_billing_period;
  @attr() discounts;
  @attr() planIds;
  @attr('number') peopleCount;
  @attr() perProductData;
  @attr() plan_combination_valid;
  @attr() preDiscountAmount;
  @attr() pre_discount_pricing_by_billing_period;
  @attr() priceSet;
  @attr() pricingModelIdentifier;
  @attr() pricing_by_billing_period;
  @attr() dailyAppStatUpdatedAt;

  @tracked breakdownRoot = this.breakdown?.firstObject;
  @tracked breakdownFirstCharge = this.breakdownRoot?.charges?.firstObject;
  @tracked monthly_active_people = this.perProductData?.maximum_monthly_active_identified_people;
  @tracked active_people = this.perProductData?.maximum_quarterly_active_people;
  @tracked currentTier = this.breakdownFirstCharge?.current_tier;
  @tracked currentTierPriceFormat = this.currentTier?.price_format;
  @tracked currentTierStartingUnit = this.currentTier?.starting_unit;
  @tracked actualUsage = this.breakdownFirstCharge?.actual_usage;
  @tracked billedUsage = this.breakdownFirstCharge?.billed_usage;
  @tracked quarterly_active_people = this.active_people;
  @tracked inbox_seats = this.perProductData?.agent_count;
  @tracked people_count = this.peopleCount;
  @tracked tiers = this.breakdownFirstCharge?.tiers;
  @tracked pricingMetric = this.breakdownFirstCharge?.pricing_metric;
  @tracked perUnitPrice = this.breakdownFirstCharge?.per_unit_price;
  @tracked planStartingPrice = this.breakdownRoot?.plan_starting_price;
  @tracked blockSize = this.breakdownFirstCharge?.block_size;
  @tracked pricingByBillingPeriod = this.pricing_by_billing_period;
  @tracked preDiscountPricingByBillingPeriod = this.pre_discount_pricing_by_billing_period;
  @tracked combinedMetricPricesByBillingPeriod = this.combined_metric_prices_by_billing_period;
  @tracked hasCouponApplied = !!this.appliedCoupon;
  @tracked hasPercentOffCoupon = !!this.appliedCoupon?.percent_off;
  @tracked hasFixedPriceCoupon = !!this.appliedCoupon?.amount_off_in_cents;
  @tracked couponPercentOff = this.appliedCoupon?.percent_off;
  @tracked couponFixedAmountOff = this.appliedCoupon?.amount_off_in_cents;

  get pricingMetricDisplayName() {
    return usageFormatter.getDisplayName(this.pricingMetric);
  }

  get formattedStartingPrice() {
    return this.formatPrice({ price: this.planStartingPrice, minPrecision: 2 });
  }

  get formattedPerUnitPrice() {
    return this.formatPrice({ price: this.perUnitPrice, minPrecision: 2 });
  }

  get currentTierInfo() {
    let tierStart =
      this.currentTierPriceFormat === 'FlatFee'
        ? this.currentTierStartingUnit
        : Math.max(0, this.billedUsage - this.blockSize + 1); // Adding 1 so the tiers don't overlap
    return {
      tierStart,
      tierEnd: this.billedUsage,
    };
  }

  get formattedUsageDisplay() {
    if (this.pricingMetric === 'fixed') {
      return '';
    } else if (this.currentTier) {
      let { tierStart, tierEnd } = this.currentTierInfo;

      return `${this.intl.formatNumber(tierStart)} - ${this.intl.formatNumber(
        tierEnd,
      )} (${this.intl.formatNumber(this.actualUsage)} used)`;
    } else {
      return this.intl.t('pricing-and-packaging.price.breakdown.formatted_usage_display', {
        purchasedUsage: this.intl.formatNumber(this.billedUsage),
        actualUsage: this.actualUsage,
      });
    }
  }

  get nonFixedMetric() {
    if (this.currentTier) {
      return this.intl.formatNumber(this.currentTier.ending_unit);
    } else {
      return this.intl.formatNumber(this.billedUsage);
    }
  }

  get formattedAmount() {
    return this.formatPrice({ price: this.amount });
  }

  get formattedAmountToTwoDecimals() {
    return this.formatPrice({ price: this.amount, minPrecision: 2 });
  }

  get formattedPreDiscountAmount() {
    return this.formatPrice({ price: this.preDiscountAmount, minPrecision: 2 });
  }

  get amountToTwoDecimals() {
    return this.intl.formatNumber(this.amount / 100, { minimumFractionDigits: 2 });
  }

  get preDiscountAmountToTwoDecimals() {
    return this.formatPrice({ price: this.preDiscountAmount });
  }

  get couponDiscountAmountFormatted() {
    return this.formatPrice({ price: this.couponDiscountAmount });
  }

  get couponDiscountAmount() {
    let coupon = this.appliedCoupon;
    return coupon ? coupon.amount : 0;
  }

  get appliedCoupon() {
    return this.discounts.find((discount) => discount.type === 'applied_coupon');
  }

  hasSamePlansAndSeats(planIds, seats) {
    return this.hasSamePlans(planIds) && this.hasSameSeats(seats);
  }

  hasSamePlans(yourPlanIds) {
    let myPlanIds = this.planIds;

    let youMatch = yourPlanIds.every((planId) => myPlanIds.includes(planId));
    let weMatch = myPlanIds.every((planId) => yourPlanIds.includes(planId));

    return youMatch && weMatch;
  }

  hasSameSeats(seats) {
    return this.seatsAreNotConsidered(seats) || seats === this.billedSeatsUsage();
  }

  billedSeatsUsage() {
    return this.annualPerSeatPricesPerPlan()?.[0]?.billed_usage;
  }

  seatsAreNotConsidered(seats) {
    return seats === 0 || this.billedSeatsUsage() === undefined;
  }

  getMonthlyTotalAfterTrial(billingPeriodDurationInMonths, includeAccountCoupons = true) {
    let billingPeriodPricing = this.getBillingPeriodPricing(
      billingPeriodDurationInMonths,
      includeAccountCoupons,
    );

    if (!billingPeriodPricing) {
      return this.getMonthlyPrice(includeAccountCoupons);
    }

    return this.getMonthlyPriceFromPricingByBillingPeriod(billingPeriodPricing);
  }

  getMonthlyPerSeatPriceAfterTrial(billingPeriodDurationInMonths) {
    let dailyAdminCountUnitPricing = this.seatCountPerUnitPrice()[billingPeriodDurationInMonths];
    if (dailyAdminCountUnitPricing.monthly_equivalent_when_billed_by_billing_period) {
      return dailyAdminCountUnitPricing.monthly_equivalent_when_billed_by_billing_period;
    }

    return dailyAdminCountUnitPricing.monthly_price_in_cents;
  }

  seatCountPerUnitPrice() {
    return (
      this.combinedMetricPricesByBillingPeriod.per_unit_price.latest_daily_admin_count ||
      this.combinedMetricPricesByBillingPeriod.per_unit_price.support_seat_count
    );
  }

  getBilledAsAmount(billingPeriodDurationInMonths, includeAccountCoupons = true) {
    let billingPeriodPricing = this.getBillingPeriodPricing(
      billingPeriodDurationInMonths,
      includeAccountCoupons,
    );

    if (!billingPeriodPricing) {
      return this.getMonthlyPrice(billingPeriodDurationInMonths, includeAccountCoupons);
    }

    let billingPeriodPrice =
      billingPeriodPricing.billing_period_price_after_discount ||
      billingPeriodPricing.billing_period_price_before_discount;

    return (
      billingPeriodPrice ||
      this.getMonthlyTotalAfterTrial(billingPeriodDurationInMonths, includeAccountCoupons)
    );
  }

  getMonthlyDifferenceBetweenBillingPeriods(
    firstBillingPeriodDurationInMonths,
    secondBillingPeriodDurationInMonths,
    includeAccountCoupons = true,
  ) {
    let amountInCentsForFirstBillingPeriodDurationInMonths = this.getMonthlyTotalAfterTrial(
      firstBillingPeriodDurationInMonths,
      includeAccountCoupons,
    );
    let amountInCentsForSecondBillingPeriodDurationInMonths = this.getMonthlyTotalAfterTrial(
      secondBillingPeriodDurationInMonths,
      includeAccountCoupons,
    );
    return Math.abs(
      amountInCentsForFirstBillingPeriodDurationInMonths -
        amountInCentsForSecondBillingPeriodDurationInMonths,
    );
  }

  getDiscountPercentageForBillingPeriod(billingPeriodDurationInMonths) {
    let pricing = this.getBillingPeriodPricing(billingPeriodDurationInMonths, true);
    return pricing?.billing_period_discount_percentage || 0;
  }

  getAnnualDiscountPercentage() {
    let discount = this.getDiscountPercentageForBillingPeriod(12);
    return discount * 100;
  }
  // Might be good for .diff() to return some sort of Price class to expose some functions like
  // formatPrice
  diff(secondPrice, billingPeriodDurationInMonths, includeAccountCoupons) {
    let firstAmount = this.getMonthlyTotalAfterTrial(
      billingPeriodDurationInMonths,
      includeAccountCoupons,
    );
    let secondAmount =
      secondPrice?.getMonthlyTotalAfterTrial(
        billingPeriodDurationInMonths,
        includeAccountCoupons,
      ) || 0;

    return firstAmount - secondAmount;
  }

  getBreakdownInfo(billingPeriodDurationInMonths) {
    return this.breakdown
      ?.map(({ plan_id, charges, name, amount, pricing_by_billing_period }) => {
        let price;
        let isDiscount = name === 'applied_coupon' || name === 'coupon';

        // Coupon matching is non-exact so double-checking `pricing_by_billing_period` exists too. There are also
        // a lot of test fixtures that need to be migrated to billing period pricing.
        if (
          isDiscount ||
          !pricing_by_billing_period ||
          !pricing_by_billing_period[billingPeriodDurationInMonths]
        ) {
          price = amount;
        } else {
          price = this.getMonthlyPriceFromPricingByBillingPeriod(
            pricing_by_billing_period[billingPeriodDurationInMonths],
          );
        }

        let breakdown = this.getBreakdownToDisplay(charges);
        return {
          ...getProductInfo(plan_id),
          pricing: { monthlyPrice: `${this.formatPrice({ price, isDiscount })}` },
          breakdown,
          hasSeatMetric: this.hasSeatMetric(breakdown),
        };
      })
      .filter((breakdown) => breakdown.name);
  }

  getBreakdownToDisplay(charges) {
    let monthlyMetrics = [
      Metric.thirty_day_messaged_contacts_product_tours,
      Metric.thirty_day_messaged_contacts,
      Metric.monthly_surveys_sent,
    ];

    let seatBasedMetrics = [
      Metric.support_seat_count,
      Metric.engage_seat_count,
      Metric.marketing_seat_count,
      Metric.proactive_support_seat_count,
      Metric.latest_daily_admin_count,
      Metric.inbox_seats,
      Metric.core_seat_count,
    ];

    return charges?.map(
      ({ pricing_metric, billed_usage, price, block_size, plan_limit, per_block_price }) => ({
        usage: pricing_metric === 'fixed' ? '' : this.intl.formatNumber(billed_usage),
        metric: this.pricingMetricTranslationWithUsage(pricing_metric, billed_usage),
        metricName: pricing_metric,
        monthlyUsage: monthlyMetrics.includes(pricing_metric),
        seatBased: seatBasedMetrics.includes(pricing_metric),
        price: this.formatPrice({ price }),
        blockSize: this.intl.formatNumber(block_size),
        planMax: plan_limit ? plan_limit.maximum : undefined,
        additionaCostPerExtraBlock: this.formatPrice({
          price: per_block_price,
          maxPrecision: 0,
        }),
      }),
    );
  }

  getPlanStartingPrice() {
    return this.breakdown?.firstObject.plan_starting_price;
  }

  getPlanCoreSeatUsageAndPrice(billingPeriodDurationInMonths) {
    let planBreakdown = this.getBreakdownInfo(billingPeriodDurationInMonths);
    return planBreakdown.firstObject.breakdown.find(
      (breakdown) => breakdown.metricName === 'core_seat_count',
    );
  }

  getMetricsWithBasePricing() {
    return this.breakdown?.firstObject.charges.map(
      ({ pricing_metric, charge_model, tiers, per_unit_price }) => {
        if (charge_model === 'PerUnit') {
          return this.getPerUnitMetricsWithBasePricing(
            pricing_metric,
            charge_model,
            per_unit_price,
          );
        }

        if (charge_model === 'Tiered') {
          return this.getTieredMetricsWithBasePricing(pricing_metric, charge_model, tiers);
        }
      },
    );
  }

  getChargeForMetric(metric) {
    let charges = this.breakdown.map((breakdown) => breakdown.charges.toArray()).flat();

    return charges.find((charge) => charge.pricing_metric === metric);
  }

  getPerUnitMetricsWithBasePricing(pricingMetric, chargeModel, perUnitPrice) {
    return {
      name: pricingMetric,
      chargeModel,
      price: perUnitPrice,
    };
  }

  getTieredMetricsWithBasePricing(pricingMetric, chargeModel, tiers) {
    let tiersExcludingFlatFee = tiers.filter((tier) => tier.price_format !== 'FlatFee');
    let firstTierPerUnitPrice = tiersExcludingFlatFee[0].price;
    let lastTierPerUnitPrice = tiersExcludingFlatFee[tiersExcludingFlatFee.length - 1].price;
    return {
      name: pricingMetric,
      chargeModel,
      startingPrice: lastTierPerUnitPrice,
      endingPrice: firstTierPerUnitPrice,
      firstTiersEndingUnit: tiers.find((tier) => tier.id === 1)?.ending_unit || 0,
    };
  }

  pricingMetricTranslationWithUsage(metric, usage) {
    let metricTranslation;
    switch (metric) {
      case Metric.thirty_day_messaged_contacts:
        metricTranslation = 'people_reached';
        break;
      case Metric.latest_daily_admin_count:
      case Metric.inbox_seats:
      case Metric.support_seat_count:
      case Metric.engage_seat_count:
      case Metric.marketing_seat_count:
      case Metric.proactive_support_seat_count:
        metricTranslation = usage > 1 ? 'seats' : 'seats_singular';
        break;
      case Metric.thirty_day_messaged_contacts_product_tours:
        metricTranslation = usage > 1 ? 'guided_users' : 'guided_users_singular';
        break;
      case Metric.quarterly_active_people:
        metricTranslation = 'active_people';
        break;
      case Metric.fixed:
        metricTranslation = usage > 1 ? 'unlimited_articles' : 'unlimited_articles_singular';
        break;
      case Metric.monthly_surveys_sent:
        metricTranslation = usage > 1 ? 'surveys_sent' : 'surveys_sent_singular';
        break;
      case Metric.core_seat_count:
        metricTranslation = usage > 1 ? 'core_seat_count' : 'core_seat_count_singular';
    }
    return metricTranslation
      ? this.intl.t(`pricing-and-packaging.price.breakdown.display.${metricTranslation}`)
      : '';
  }

  hasSeatMetric(charges) {
    // temp fix before refactoring all of this seats stuff
    if (!charges) {
      return false;
    }
    return charges.some((charge) => charge.seatBased);
  }

  getBillingPeriodPricing(billingPeriodDurationInMonths, includeAccountCoupons) {
    if (includeAccountCoupons) {
      return (
        this.pricingByBillingPeriod && this.pricingByBillingPeriod[billingPeriodDurationInMonths]
      );
    } else {
      return (
        this.preDiscountPricingByBillingPeriod &&
        this.preDiscountPricingByBillingPeriod[billingPeriodDurationInMonths]
      );
    }
  }

  getMonthlyPrice(includeAccountCoupons) {
    return includeAccountCoupons ? this.amount : this.preDiscountAmount;
  }

  getMonthlyPriceFromPricingByBillingPeriod(pricingByBillingPeriod) {
    return (
      pricingByBillingPeriod.monthly_equivalent_when_billed_by_billing_period ||
      pricingByBillingPeriod.monthly_price_in_cents
    );
  }

  annualPerSeatPricesPerPlan() {
    let seatPrices = [];
    this.breakdown.forEach((planPrices) => {
      if (planPrices.charges) {
        let plancharges = planPrices.charges.filter(
          (charges) => charges.pricing_metric === CORE_SEAT_METRIC,
        );
        if (plancharges.length) {
          let planPrice = {};
          planPrice.plan_id = planPrices['plan_id'];
          planPrice.name = planPrices['name'];
          planPrice.billed_usage = plancharges.firstObject['billed_usage'];
          let pricesByBillingPeriod = plancharges.firstObject.per_unit_pricing_by_billing_period;
          seatPrices.push({
            ...planPrice,
            ...pricesByBillingPeriod[12],
          });
        }
      }
    });
    return seatPrices;
  }

  annualPerSeatCombinedPlanPrices() {
    return this.combinedMetricPricesByBillingPeriod?.per_unit_price?.[CORE_SEAT_METRIC]?.[12];
  }

  formatPrice({
    price,
    isDiscount = false,
    minPrecision = 0,
    maxPrecision = 2,
    priceInCents = true,
  }) {
    let signedPrice = isDiscount ? -price : price;
    if (priceInCents) {
      signedPrice /= 100;
    }
    return this.intl.formatNumber(signedPrice, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: minPrecision,
      maximumFractionDigits: maxPrecision,
    });
  }

  getDiscountInfo(billingPeriodDurationInMonths) {
    if (!this.appliedCoupon && !this.customerService.isSelfServeAnnualCustomer) {
      return {};
    }

    let discountText = '';
    let discountAmount = 0;

    if (this.customerService.isSelfServeAnnualCustomer) {
      let percentOff = this.getDiscountPercentageForBillingPeriod(billingPeriodDurationInMonths);
      discountText = this.intl.t('billing.change-plan-modal.pricing-tooltip-core.percent-off', {
        percent: percentOff * 100,
      });
      discountAmount = this.getMonthlyDifferenceBetweenBillingPeriods(
        1,
        billingPeriodDurationInMonths,
        false,
      );
    } else if (this.hasPercentOffCoupon) {
      discountText = this.intl.t('billing.change-plan-modal.pricing-tooltip-core.percent-off', {
        percent: this.couponPercentOff,
      });
      discountAmount = this.couponDiscountAmount;
    } else {
      discountText = this.intl.t(
        'billing.change-plan-modal.pricing-tooltip-core.fixed-amount-off',
        {
          fixedAmount: numericFormatter(Math.abs(this.couponFixedAmountOff) / 100, 2, true),
        },
      );

      discountAmount = this.couponDiscountAmount;
    }

    return {
      label: this.intl.t('billing.change-plan-modal.pricing-tooltip-core.discount-shown', {
        amount: discountText,
      }),
      value: `-$${numericFormatter(discountAmount / 100, 2, true)}`,
    };
  }
}
