/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
import { capitalize } from '@ember/string';

export default Mixin.create({
  frontendStatsService: service('frontendStatsService'),
  recordPageRenderTime() {
    this.frontendStatsService.timeUntilAfterRender(`ember_ux_${this.type}MessageListRenderTime`, {
      bufferSize: 1,
    });
  },
  recordProductListItemRenderTime(job, state) {
    let metricName = `ember_ux_${job}${capitalize(state)}MessageListItemRenderTime`;
    this.frontendStatsService.timeUntilAfterRender(metricName, { bufferSize: 5 });
  },
});
