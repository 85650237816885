/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const actionToTypeMap = {
  continue: 'continue',
  skip: 'skip',
  startChat: 'start_chat',
  dismiss: 'dismiss',
  link: 'link',
  deepLink: 'deep_link',
  locationPermission: 'location_permission',
  pushNotificationPermission: 'push_notifications_permission',
  cameraPermission: 'camera_permission',
  microphonePermission: 'microphone_permission',
  photosPermission: 'photos_permission',
};

export const typeToActionMap = Object.keys(actionToTypeMap).reduce((ret, key) => {
  ret[actionToTypeMap[key]] = key;
  return ret;
}, {});

export const actionToTitleMap = {
  continue: 'Continue',
  skip: 'Not now',
  startChat: 'Start a conversation',
  dismiss: 'Done',
  link: 'Done',
  deepLink: 'Done',
  locationPermission: 'Continue',
  pushNotificationPermission: 'Continue',
  cameraPermission: 'Continue',
  microphonePermission: 'Continue',
  photosPermission: 'Continue',
};

export const nonPermissionTypes = [
  actionToTypeMap.dismiss,
  actionToTypeMap.link,
  actionToTypeMap.deepLink,
  actionToTypeMap.startChat,
  actionToTypeMap.continue,
];

export const lastScreenOptions = [
  {
    value: actionToTypeMap.dismiss,
    label: 'Close the carousel',
    description: 'Default action',
  },
  {
    value: -1,
    label: 'Request Permissions',
    description: 'Notification, location etc',
  },
  {
    value: actionToTypeMap.link,
    label: 'Open an external link',
    description: 'External URL page',
  },
  {
    value: actionToTypeMap.deepLink,
    label: 'Open a deep link',
    description: 'URI link in your app',
  },
  {
    value: actionToTypeMap.startChat,
    label: 'Start a conversation',
    description: 'Open Intercom messenger',
  },
];

export const lastScreenOptionsValues = lastScreenOptions.map((o) => o.value);

export const permissionsLabelIconMap = [
  {
    value: actionToTypeMap.locationPermission,
    label: 'Location',
    icon: 'location',
  },
  {
    value: actionToTypeMap.pushNotificationPermission,
    label: 'Push notifications',
    icon: 'push-notification',
  },
  {
    value: actionToTypeMap.microphonePermission,
    label: 'Microphone',
    icon: 'microphone',
  },
  {
    value: actionToTypeMap.cameraPermission,
    label: 'Camera',
    icon: 'camera',
  },
  {
    value: actionToTypeMap.photosPermission,
    label: 'Photo gallery',
    icon: 'picture',
  },
].sort((a, b) => (a.value > b.value ? 1 : -1));

export const permissionTypeValues = permissionsLabelIconMap.map((o) => o.value);

export const actionTypes = [
  {
    value: actionToTypeMap.continue,
    label: 'Navigate to the next screen',
    description: 'Default action',
  },
  {
    value: -1,
    label: 'Request permissions',
    description: 'Notifications, location etc',
  },
];
