/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr } from '@ember-data/model';
import { capitalize } from '@ember/string';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import moment from 'moment-timezone';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { emailTemplateTypes, premadeTemplates } from 'embercom/models/data/outbound/constants';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import emailTemplatesLogoTemplate from 'embercom/templates-raw/email-templates/partials/logo';
import emailTemplatesSocialTemplate from 'embercom/templates-raw/email-templates/partials/social';
import emailTemplatesAddressTemplate from 'embercom/templates-raw/email-templates/partials/address';
import announcementTemplate from 'embercom/templates-raw/email-templates/email-templates-announcement';
import companyTemplate from 'embercom/templates-raw/email-templates/email-templates-company';
import htmlBlockTemplate from 'embercom/templates-raw/email-templates/email-templates-html-block-template';
import officialTemplate from 'embercom/templates-raw/email-templates/email-templates-official';
import personalTemplate from 'embercom/templates-raw/email-templates/email-templates-personal';
import plainResponsiveTemplate from 'embercom/templates-raw/email-templates/email-templates-plain_responsive';
import plainTemplate from 'embercom/templates-raw/email-templates/email-templates-plain';
import personalBlockTemplate from 'embercom/lib/outbound/email_templates/personal_block_template';
import companyBlockTemplate from 'embercom/lib/outbound/email_templates/company_block_template';
import { isPresent } from '@ember/utils';
import baseTemplate from 'embercom/templates-raw/email-templates/email-templates-base';
import { renderBlocksV1 } from 'embercom/helpers/render-blocks';
import { substituteValues } from 'embercom/lib/outbound/email-template-helper';
import { fragment } from 'ember-data-model-fragments/attributes';
import { SECTION_TYPE } from '@intercom/interblocks.ts';

const LEGACY_TEMPLATE_NAMES = ['official'];
const NON_RESPONSIVE_LEGACY = ['plain'];
const INTERCOM_BUILT_BLOCK_TEMPLATES = ['personal_block', 'company_block'];
const INTERCOM_BUILT_TEMPLATE_NAMES = [
  'personal',
  'company',
  'announcement',
  'plain_responsive',
  'html-block-template',
]
  .concat(LEGACY_TEMPLATE_NAMES)
  .concat(INTERCOM_BUILT_BLOCK_TEMPLATES)
  .concat(NON_RESPONSIVE_LEGACY);

const EMAIL_TEMPLATES = {
  announcement: announcementTemplate,
  company: companyTemplate,
  'html-block-template': htmlBlockTemplate,
  official: officialTemplate,
  personal: personalTemplate,
  personal_block: personalBlockTemplate,
  company_block: companyBlockTemplate,
  plain_responsive: plainResponsiveTemplate,
  plain: plainTemplate,
};

const WIDGETS = [
  {
    name: 'logo',
    template: emailTemplatesLogoTemplate,
    label: 'logo',
    requiredFields: 'logo',
    editor: 'logo',
  },
  {
    name: 'social',
    template: emailTemplatesSocialTemplate,
    label: 'social accounts',
    placeholderText: 'Add your social accounts',
    requiredFields: 'facebook|twitter',
    editor: 'social',
  },
  {
    name: 'address',
    template: emailTemplatesAddressTemplate,
    label: 'physical address',
    requiredFields: 'address',
    editor: 'text',
  },
];

export { WIDGETS };

const RESPONSIVE = '_responsive';
const LEGACY_SUFFIX = ' legacy';

export default class EmailTemplate extends Model {
  @service appService;
  @attr('string') name;
  @attr('string') appId;
  @attr('string') template;
  @attr('array') blocks;
  @attr('array') contentBlocks;
  @fragment('email-template/css') css;

  get isAnnouncement() {
    return this.id === 'announcement';
  }

  get isPersonal() {
    return this.id === 'personal';
  }

  get isPersonalBlock() {
    return this.id === 'personal_block';
  }

  get isCompany() {
    return this.id === 'company';
  }

  get isCompanyBlock() {
    return this.id === 'company_block';
  }

  get isHtmlMode() {
    return this.id === 'html-block-template';
  }

  get isPlain() {
    return this.id === 'plain';
  }

  get isOfficial() {
    return this.id === 'official';
  }

  get isPlainResponsive() {
    return this.id === 'plain_responsive';
  }

  get isCustom() {
    return /^\d+$/.test(this.id);
  }

  get isBlockTemplate() {
    return !isPresent(this.template);
  }

  get isBlockTemplateWithContainer() {
    if (!this.isBlockTemplate) {
      return false;
    }
    return isPresent(
      this.blocks?.find((block) => {
        return block.type === 'table' && block.container;
      }),
    );
  }

  getBlocksHtml(messageLevelStyles) {
    if (this.isBlockTemplate) {
      let headers = this.getSectionsByType(this.blocks, SECTION_TYPE.header);
      let footers = this.getSectionsByType(this.blocks, SECTION_TYPE.footer);
      if (this.isPremade) {
        footers = this.applySectionStyles(footers, messageLevelStyles);
        headers = this.applySectionStyles(headers, messageLevelStyles);
      }
      let headersHTML = this._blockHTML(headers);
      let footersHTML = this._blockHTML(footers);

      return substituteValues(baseTemplate({}), {
        content: `${headersHTML}{{content}}${footersHTML}`,
      });
    }
  }

  applySectionStyles(sectionArray, messageLevelStyles) {
    if (!messageLevelStyles?.bodyBackgroundColor) {
      return sectionArray;
    }
    return sectionArray.map((section) => {
      if (
        section.sectionType === SECTION_TYPE.footer ||
        (section.sectionType === SECTION_TYPE.header && this.isPersonalBlock)
      ) {
        section.style.backgroundColor = messageLevelStyles.bodyBackgroundColor;
      }
      return section;
    });
  }

  getSectionsByType(blocks, sectionType) {
    return blocks.filter((block) => {
      return block.sectionType === sectionType.toString();
    });
  }

  get isPlainOrCompanyOrAnnouncement() {
    return this.isPlain || this.isCompany || this.isAnnouncement;
  }

  get isPremade() {
    return (
      this.isPlain ||
      this.isCompany ||
      this.isAnnouncement ||
      this.isPersonal ||
      this.isOfficial ||
      this.isPlainResponsive ||
      this.isPersonalBlock ||
      this.isCompanyBlock
    );
  }

  get isLegacy() {
    return this.isPlain;
  }

  get isResponsive() {
    return this.isPlainResponsive;
  }

  get outboundTemplateType() {
    if (this.isCustom) {
      return emailTemplateTypes.custom;
    }
    if (this.isPremade) {
      return emailTemplateTypes.premade;
    }
    if (this.isHtmlMode) {
      return emailTemplateTypes.htmlMode;
    }
  }

  get outboundTemplateId() {
    if (this.isCustom) {
      return Number(this.id);
    }
    if (this.isPremade) {
      return premadeTemplates[this.id];
    }
    if (this.isHtmlMode) {
      return -1;
    }
  }

  getAssociatedMessages(app) {
    return ajax({
      type: 'GET',
      url: '/ember/email_templates/messages_using_template',
      data: {
        id: this.id,
        app_id: app.get('id'),
      },
    });
  }

  _notEqualWithStandarizedWhitespace(string1, string2) {
    return string1.replace(/\s+/g, ' ') !== string2.replace(/\s+/g, ' ');
  }

  get isDirtyIgnoringWhitespace() {
    if (this.hasDirtyAttributes) {
      return (
        this.changedAttributes.name ||
        this._notEqualWithStandarizedWhitespace(this.template, this.data.template)
      );
    } else {
      return false;
    }
  }

  _substituteValues(html, options = {}) {
    Object.keys(options).forEach(function (key) {
      // Allow optional whitespace before or after the key.
      let regex = `\{\{\\s?${key}\\s?\}\}`;
      // The 'i' flag here means that the regex should be matched without
      // case sensitivity.
      html = html.replace(new RegExp(regex, 'i'), options[key]);
    });
    return html;
  }

  _blockHTML(blocks) {
    return renderBlocksV1(blocks, false, undefined, false, true).outerHTML;
  }

  static _pushPremadeTemplate(templateId, templateName, template, store, app_id) {
    if (isPresent(template.blocks)) {
      this._pushBlockPremadeTemplate(templateId, templateName, template, store, app_id);
    } else {
      this._pushLegacyPremadeTemplate(templateId, templateName, template, store, app_id);
    }
  }

  static _pushLegacyPremadeTemplate(templateId, templateName, template, store, app_id) {
    store.pushPayload('email-template', {
      'email-templates': [
        {
          id: templateId,
          app_id,
          name: templateName,
          template: template.replace(/\[\[/g, '{{').replace(/\]\]/g, '}}'),
        },
      ],
    });
  }

  static _pushBlockPremadeTemplate(templateId, templateName, template, store, app_id) {
    templateName = templateName.replace('_blocks', ' (new)');
    store.pushPayload('email-template', {
      'email-templates': [
        {
          id: templateId,
          app_id,
          name: templateName,
          blocks: template.blocks,
          content_blocks: template.contentBlocks,
          css: store.createFragment('email-template/css', template.css),
        },
      ],
    });
  }

  static widgets() {
    return WIDGETS;
  }

  static populateDefaultTemplates(app, translations) {
    let templateData = {
      app_name: app.get('name'),
      date: moment().format(dateAndTimeFormats.emailTemplateRenderDateFormat),
      app_base_color: app.get('base_color'),
      show_powered_by: app.get('show_powered_by'),
      powered_by_link: sanitizeHtml(translations.get('poweredByLink')),
      dont_want_to_get_emails_like_these: translations.get('dontWantToGetEmailsLikeThese'),
    };
    let store = getEmberDataStore();
    INTERCOM_BUILT_TEMPLATE_NAMES.forEach((templateName) => {
      if (!store.hasRecordForId('email-template', templateName)) {
        let name = templateName;
        if (NON_RESPONSIVE_LEGACY.includes(name)) {
          name += LEGACY_SUFFIX;
        }
        name = capitalize(name).replace(RESPONSIVE, '');

        this._pushPremadeTemplate(
          templateName,
          name,
          EMAIL_TEMPLATES[templateName](templateData),
          store,
          app.id,
        );
      }
    });
  }

  static isIntercomBuiltTemplate(id) {
    return INTERCOM_BUILT_TEMPLATE_NAMES.includes(id);
  }

  duplicate() {
    return ajax({
      url: `/ember/email_templates/${this.id}/duplicate`,
      method: 'POST',
      data: JSON.stringify({ app_id: this.appId }),
      contentType: 'application/json',
    });
  }
}
