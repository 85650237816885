/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default [
  {
    key: 'b2b',
    displayName: 'B2B SasS',
    samples: [
      {
        name: 'job_title',
        description: 'Job title of the user',
        type: 'String',
      },
      {
        name: 'utm_source',
        description: 'Traffic source of the user',
        type: 'String',
      },
      {
        name: 'nps_score',
        description: 'Most recent NPS score value',
        type: 'Number',
      },
      {
        name: 'onboarding_completed',
        description: 'True if the user has completed all onboarding steps',
        type: 'Boolean',
      },
      {
        name: 'draft_count',
        description: 'Number of active draft resources created by the user',
        type: 'Number',
      },
      {
        name: 'clicked_cancel',
        description: 'True if the user has attempted cancellation',
        type: 'Boolean',
      },
    ],
  },
  {
    key: 'b2c',
    displayName: 'B2C SaaS',
    samples: [
      {
        name: 'plan',
        description: 'Name of the user’s plan',
        type: 'String',
      },
      {
        name: 'utm_source',
        description: 'Traffic source of the user',
        type: 'String',
      },
      {
        name: 'confirmed_email_at',
        description: 'Timestamp of when the user’s email was confirmed',
        type: 'Date',
      },
      {
        name: 'invited_user_count',
        description: 'Number of invites sent by the user',
        type: 'Number',
      },
      {
        name: 'blocked_at',
        description: 'Timestamp of when the user was blocked',
        type: 'Date',
      },
      {
        name: 'cancel_reason',
        description: 'User-submitted cancellation reason',
        type: 'String',
      },
      {
        name: 'payment_status',
        description: 'Current status of the user’s account',
        type: 'String',
      },
    ],
  },
  {
    key: 'education',
    displayName: 'Education',
    samples: [
      {
        name: 'school_name',
        description: 'Name of the user’s school',
        type: 'String',
      },
      {
        name: 'role',
        description: 'Educational role of the user',
        type: 'String',
      },
      {
        name: 'district_name',
        description: 'Name of the user’s school district',
        type: 'String',
      },
      {
        name: 'courses_completed',
        description: 'Number of courses completed by the user',
        type: 'Number',
      },
      {
        name: 'last_course_taken',
        description: 'Name of the last class taken by the user',
        type: 'String',
      },
      {
        name: 'paid_courses_taken_count',
        description: 'Number of paid courses taken by the user',
        type: 'Number',
      },
      {
        name: 'unfinished_courses_count',
        description: 'Number of incomplete courses enrolled by the user',
        type: 'Number',
      },
    ],
  },
  {
    key: 'onDemand',
    displayName: 'On-Demand Commerce',
    samples: [
      {
        name: 'total_orders',
        description: 'Number of orders made by the user',
        type: 'Number',
      },
      {
        name: 'total_promocodes_sent',
        description: 'Number of promo codes sent to the user',
        type: 'Number',
      },
      {
        name: 'total_spent',
        description: 'Total value of sales to the user',
        type: 'Number',
      },
      {
        name: 'last_restaurant_ordered',
        description: 'Name of the last restaurant the user ordered from',
        type: 'String',
      },
      {
        name: 'phone_number',
        description: 'International phone number of the user',
        type: 'String',
      },
      {
        name: 'current_order_live',
        description: 'True if the user has a live order',
        type: 'String',
      },
      {
        name: 'current_order_status',
        description: 'Status of the current order',
        type: 'String',
      },
    ],
  },
  {
    key: 'marketplace',
    displayName: 'Marketplace',
    samples: [
      {
        name: 'buyer_account',
        description: 'True if the user has an active buyer account',
        type: 'Boolean',
      },
      {
        name: 'seller_account',
        description: 'True if the user has an active seller account',
        type: 'Boolean',
      },
      {
        name: 'store_launched_at',
        description: 'Timestamp of when the store was made live',
        type: 'Date',
      },
      {
        name: 'total_sales',
        description: 'Number of sales by the user’s store',
        type: 'Number',
      },
      {
        name: 'total_purchases',
        description: 'Number of purchases completed by the user',
        type: 'Number',
      },
      {
        name: 'favorite_category',
        description: 'Name of the user’s favorite category',
        type: 'String',
      },
    ],
  },
  {
    key: 'mobile',
    displayName: 'Mobile',
    samples: [
      {
        name: 'facebook_connected',
        description: 'True if Facebook is connected',
        type: 'Boolean',
      },
      {
        name: 'twitter_connected',
        description: 'True if Twitter is connected',
        type: 'Boolean',
      },
      {
        name: 'linkedin_connected',
        description: 'True if LinkedIn is connected',
        type: 'Boolean',
      },
      {
        name: 'app_version',
        description: 'Version number of the app most recently accessed',
        type: 'Number',
      },
      {
        name: 'last_accessed_at',
        description: 'Timestamp of the user’s last app usage',
        type: 'Date',
      },
      {
        name: 'allows_notifications',
        description: 'True if the user has consented to receive notifications',
        type: 'Boolean',
      },
    ],
  },
  {
    key: 'financial',
    displayName: 'Financial',
    samples: [
      {
        name: 'locked_bank_account_at',
        description: 'Timestamp of when the user’s account was locked',
        type: 'Date',
      },
      {
        name: 'number_of_savings_goals',
        description: 'Number of savings goals set by the customer',
        type: 'Number',
      },
      {
        name: 'number_of_spending_rules',
        description: 'Number of spending rules set by the customer',
        type: 'Number',
      },
      {
        name: 'linked_funding_account',
        description: 'True if the user has linked an account for funding',
        type: 'Boolean',
      },
      {
        name: 'verification_status',
        description: 'Status of the user’s account verification',
        type: 'String',
      },
    ],
  },
  {
    key: 'ecommerce',
    displayName: 'eCommerce',
    samples: [
      {
        name: 'last_purchase_at',
        description: 'Timestamp of the user’s last purchase',
        type: 'Date',
      },
      {
        name: 'items_in_cart',
        description: 'True if the user has items in their cart',
        type: 'Boolean',
      },
      {
        name: 'total_spent',
        description: 'Total value of sales to the user',
        type: 'Number',
      },
      {
        name: 'last_searched',
        description: 'Text of the user’s last search query',
        type: 'String',
      },
      {
        name: 'last_item_viewed',
        description: 'Unique identifier of the last item viewed',
        type: 'String',
      },
      {
        name: 'current_order_status',
        description: 'Status of the current order',
        type: 'String',
      },
      {
        name: 'current_order_number',
        description: 'Unique identifier for the user’s current order',
        type: 'Number',
      },
    ],
  },
];
