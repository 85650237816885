/* RESPONSIBLE TEAM: team-workflows */
import type AssignConversation from 'embercom/models/operator/visual-builder/step/assign-conversation';
import StepConfig, { type StepConfigParams } from '../step';

export default class AssignConversationConfiguration extends StepConfig {
  declare step: AssignConversation;
  allowTeammates = true;
  allowOwnerAssignee = true;
  allowUnassignedAdminForAssignee = true;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/assign-conversation',
      ...params,
    });
  }
}

export class PhoneTriggerAssignConversationConfiguration extends AssignConversationConfiguration {
  constructor(params: Omit<StepConfigParams, 'component'>) {
    super(params);
    this.allowTeammates = false;
    this.allowOwnerAssignee = false;
    this.allowUnassignedAdminForAssignee = false;
  }
}
