/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default Model.extend({
  eventQuota: alias('event_quota'),
  archivedEvents: alias('archived_events'),
  event_quota: attr(),
  archived_events: hasMany('attribute'),
});
