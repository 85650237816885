/* RESPONSIBLE TEAM: team-workflows */
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import NotificationMessages from 'embercom/objects/content-editor/notification-messages';
import { inject as service } from '@ember/service';
import { intersection } from 'underscore';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';

function getIncompatibleSteps(incompatibleRootWorkflows, customBot) {
  let incompatibleChannels = incompatibleRootWorkflows
    .map((rootWorkflow) =>
      rootWorkflow.targetChannels.filter(
        (channel) => !customBot.compatibleChannels.includes(channel),
      ),
    )
    .flat();

  let incompatibleSteps = new Set();
  customBot.visualBuilderObject.groups.forEach((group) =>
    group.steps.forEach((step) => {
      if (intersection(incompatibleChannels, step.supportedChannels).length === 0) {
        incompatibleSteps.add(step.type);
      }
    }),
  );

  return Array.from(incompatibleSteps);
}

export default function generateBotNotificationMessages(container, humanReadableObjectName) {
  let intl = container.lookup('service:intl');
  return NotificationMessages.create({
    activate: NotificationMessage.extend({
      humanReadableObjectName,
    }).create({
      success: intl.t('operator.custom-bot.notifications.activate.success', {
        object: humanReadableObjectName,
      }),
      failure: intl.t('operator.custom-bot.notifications.activate.failure', {
        object: humanReadableObjectName,
      }),
      container,
    }),

    deactivate: NotificationMessage.extend({
      appService: service(),
      contentEditorService: service(),
      async confirmation() {
        let obj = this.appService.app.canUseReusableWorkflowContentDependency
          ? this.contentEditorService.ruleset
          : this.contentEditorService.activeObject;
        let dependencies = await obj.loadDependentObjects();
        if (dependencies.length) {
          return {
            title: intl.t('operator.custom-bot.dependencies-modal.title', {
              action: intl.t('operator.custom-bot.dependencies-modal.deactivate-action'),
              object: humanReadableObjectName,
            }),
            bodyComponentName: 'operator-flows/dependencies-modal-body',
            confirmContext: {
              dependencies,
              action: intl
                .t('operator.custom-bot.dependencies-modal.deactivate-action')
                .toLocaleLowerCase(),
              object: humanReadableObjectName,
            },
            invertButtonActions: true,
            confirmButtonText: intl.t('operator.custom-bot.dependencies-modal.okay'),
            hideCancelButton: true,
          };
        }
        // don't show a confirmation modal when pausing no-trigger bots
        return null;
      },
    }).create({
      success: intl.t('operator.custom-bot.notifications.deactivate.success', {
        object: humanReadableObjectName,
      }),
      failure: intl.t('operator.custom-bot.notifications.deactivate.failure', {
        object: humanReadableObjectName,
      }),
      container,
    }),

    save: NotificationMessage.extend({
      contentEditorService: service(),
      appService: service(),
      async confirmation() {
        let customBot = this.contentEditorService.activeObject;
        let rootWorkflows = await customBot.loadRootWorkflows();

        // Those validations should only run if the customBot we are saving is on VBB.
        // For legacy editor: the backend validations will catch the errors.
        if (rootWorkflows.length > 0 && customBot.canUseVisualBuilderEditor) {
          let incompatibleRootWorkflows = rootWorkflows.filter(
            (rootWorkflow) =>
              rootWorkflow.targetChannels.filter(
                (channel) => !customBot.compatibleChannels.includes(channel),
              ).length > 0,
          );

          if (incompatibleRootWorkflows.length > 0) {
            let incompatibleSteps = getIncompatibleSteps(incompatibleRootWorkflows, customBot).map(
              (step) => {
                let stepName = step.split('/').lastObject;
                return intl.t(`operator.workflows.visual-builder.step-menu.${stepName}`);
              },
            );

            return {
              title: intl.t('operator.custom-bot.save-modal.warning-title'),
              bodyComponentName: 'operator-flows/save-modal-body',
              confirmContext: {
                incompatibleRootWorkflows: incompatibleRootWorkflows.mapBy('dependency'),
                incompatibleSteps,
              },
              invertButtonActions: true,
              confirmButtonText: intl.t('operator.custom-bot.save-modal.okay'),
              hideCancelButton: true,
            };
          }
        }

        let changingBots = await customBot.loadUserVisibilityChangingBots();
        let isCustomerFacing = !!customBot.visualBuilderObject?.isCustomerFacing;

        let changingBackgroundOnlyBots = changingBots.filter(
          (bot) => CUSTOM_BOT_CONFIG[bot.target].editorConfig.isBackgroundOnly,
        );

        if (isCustomerFacing && changingBackgroundOnlyBots.length > 0) {
          return {
            title: intl.t('operator.custom-bot.customer-facing-modal.workflow-must-run-background'),
            bodyComponentName: 'operator-flows/user-visibility-bot-changes-body',
            confirmContext: {
              introText: intl.t('operator.custom-bot.customer-facing-modal.workflow-used-for', {
                count: changingBackgroundOnlyBots.length,
              }),
              explanationText: intl.t(
                'operator.custom-bot.customer-facing-modal.background-only-workflow-explanation',
              ),
              changingBots: changingBackgroundOnlyBots,
            },
            invertButtonActions: true,
            confirmButtonText: intl.t('operator.custom-bot.save-modal.okay'),
            hideCancelButton: true,
          };
        }

        if (changingBots.length > 0) {
          return {
            title: isCustomerFacing
              ? intl.t('operator.custom-bot.customer-facing-modal.customer-facing-title')
              : intl.t('operator.custom-bot.customer-facing-modal.not-customer-facing-title'),
            bodyComponentName: 'operator-flows/user-visibility-bot-changes-body',
            confirmContext: {
              introText: isCustomerFacing
                ? intl.t('operator.custom-bot.customer-facing-modal.customer-facing-body')
                : intl.t('operator.custom-bot.customer-facing-modal.non-customer-facing-body'),
              explanationText: intl.t('operator.custom-bot.customer-facing-modal.bot-order-impact'),
              changingBots,
            },
            confirmButtonText: intl.t('operator.custom-bot.customer-facing-modal.save-anyway'),
          };
        }

        if (!this.contentEditorService.ruleset.isLive) {
          return null;
        }

        return {
          title: intl.t('operator.custom-bot.save-modal.title'),
          body: intl.t('operator.custom-bot.save-modal.body'),
          confirmButtonText: intl.t('operator.custom-bot.save-modal.confirm'),
        };
      },
    }).create({
      success: intl.t('operator.custom-bot.notifications.save.success', {
        object: humanReadableObjectName,
      }),
      failure: intl.t('operator.custom-bot.notifications.save.failure', {
        object: humanReadableObjectName,
      }),
      container,
    }),

    delete: NotificationMessage.extend({
      appService: service(),
      contentEditorService: service(),
      async confirmation() {
        let obj = this.appService.app.canUseReusableWorkflowContentDependency
          ? this.contentEditorService.ruleset
          : this.contentEditorService.activeObject;
        let dependencies = await obj.loadDependentObjects();
        if (dependencies.length) {
          return {
            title: intl.t('operator.custom-bot.dependencies-modal.title', {
              action: intl.t('operator.custom-bot.dependencies-modal.delete-action'),
              object: humanReadableObjectName,
            }),
            bodyComponentName: 'operator-flows/dependencies-modal-body',
            confirmContext: {
              dependencies,
              action: intl
                .t('operator.custom-bot.dependencies-modal.delete-action')
                .toLocaleLowerCase(),
              object: humanReadableObjectName,
            },
            invertButtonActions: true,
            confirmButtonText: intl.t('operator.custom-bot.dependencies-modal.okay'),
            hideCancelButton: true,
          };
        }
        let title = intl.t('operator.custom-bot.delete-modal.title', {
          object: humanReadableObjectName,
        });
        let confirmButtonText = intl.t('operator.custom-bot.delete-modal.delete-button');
        let primaryButtonType = 'primary-destructive';
        let liveContentWarning = '';
        if (
          this.contentEditorService.ruleset.isLive ||
          this.contentEditorService.ruleset.isPaused
        ) {
          liveContentWarning = intl.t('operator.custom-bot.delete-modal.live-content-warning', {
            object: humanReadableObjectName,
          });
        }
        let body = intl.t('operator.custom-bot.delete-modal.body', {
          object: humanReadableObjectName,
          liveContentWarning,
        });
        return { title, body, confirmButtonText, primaryButtonType };
      },
    }).create({
      success: intl.t('operator.custom-bot.notifications.delete.success', {
        object: humanReadableObjectName,
      }),
      failure: intl.t('operator.custom-bot.notifications.delete.failure', {
        object: humanReadableObjectName,
      }),
      container,
    }),

    schedule: NotificationMessage.extend({
      contentEditorService: service(),
      confirmation() {
        let ruleset = this.contentEditorService.ruleset;
        let title = intl.t('operator.custom-bot.schedule-modal.title');
        let scheduledActivationDate = ruleset.scheduledActivation.humanReadableChangeAt;
        let body = intl.t('operator.custom-bot.schedule-modal.body', {
          scheduledActivationDate,
        });
        let confirmButtonText = intl.t('operator.custom-bot.schedule-modal.schedule-button');
        return { title, body, confirmButtonText };
      },
    }).create({
      success: intl.t('operator.custom-bot.notifications.schedule.success'),
      failure: intl.t('operator.custom-bot.notifications.schedule.failure'),
      container,
    }),
  });
}
