/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';

export default Model.extend({
  accessToTeammateEnabled: attr(),
  messengerAutomaticResponseTimeTranslationKey: attr(),
  customResponseDelayEnabled: attr(),
  customResponseDelay: attr(),
  customResponseDelayValue: attr(),
  customResponseTimeValues: attr(),
  customResponseDelayOptions: attr(),
  hasDefaultOfficeHoursIntervals: attr(),
  reportingExcludeOooHours: attr(),
  hasUnsavedChanges: readOnly('hasDirtyAttributes'),
  messengerResponseTimeKeys: [
    'a_few_minutes',
    'a_few_hours',
    'a_day',
    'conversation_card_unknown_response_time',
    'conversation_card_unknown_response_time',
  ],

  messengerResponseTimeKey: computed(
    'messengerAutomaticResponseTimeTranslationKey',
    'customResponseDelayEnabled',
    'customResponseDelay',
    'customResponseDelayValue',
    function () {
      let responseTimeKey = this.messengerAutomaticResponseTimeTranslationKey;

      if (this.customResponseDelayEnabled) {
        let customResponseDelayCustom = 4;
        let responseTimeIndex = this.customResponseDelay;
        if (
          responseTimeIndex === customResponseDelayCustom &&
          this.customResponseDelayValue !== 0
        ) {
          responseTimeKey = `expected_response_delay_${this.customResponseDelayValue}`;
        } else {
          responseTimeKey = this.messengerResponseTimeKeys[responseTimeIndex];
        }
      }

      return responseTimeKey;
    },
  ),

  refresh() {
    this.rollbackAttributes();
  },
});
