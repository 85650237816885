/* RESPONSIBLE TEAM: team-channels */
export interface CustomBotSummaryWireFormat {
  id: string;
  title: string;
}

export default class CustomBotSummary {
  readonly id: string;
  readonly title: string;

  constructor(id: string, title: string) {
    this.id = id;
    this.title = title;
  }

  static deserialize(json: CustomBotSummaryWireFormat): CustomBotSummary {
    return new CustomBotSummary(json.id, json.title);
  }
}
