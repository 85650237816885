/* RESPONSIBLE TEAM: team-customer-data-platform */
import { A } from '@ember/array';
import { get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import escapeRegExp from 'embercom/lib/escape-regular-expression';

export default class TagSettingsList {
  @tracked sortState = {
    valuePath: 'tag.name',
    direction: 'asc',
  };

  @tracked rowsToDisplay = 20;
  @tracked rowsPerPage = 20;
  @tracked rows;
  @tracked tagSearchString;

  constructor(rows) {
    if (rows) {
      this.populate(rows);
    }
  }

  populate(rows) {
    this.rows = A(rows);
    this._sort();
  }

  get filteredRows() {
    let matchingRows = this.rows;

    if (this.tagSearchString) {
      let filterRegex = new RegExp(escapeRegExp(this.tagSearchString), 'i');
      matchingRows = this.rows.filter(
        (row) => row.editing || filterRegex.test(row.tag.get('name')),
      );
    }

    return matchingRows;
  }

  get displayableRows() {
    return this.filteredRows.slice(0, this.rowsToDisplay);
  }

  get hasMoreRows() {
    return this.rowsToDisplay < this.filteredRows.length;
  }

  updateSort(valuePath) {
    let direction = 'asc';

    if (this.sortState.valuePath === valuePath && this.sortState.direction === 'asc') {
      direction = 'desc';
    }

    this.sortState = { valuePath, direction };
    this.rowsToDisplay = this.rowsPerPage;
    this._sort();
  }

  _sort() {
    let { direction, valuePath } = this.sortState;

    let newRows = this.rows.slice();
    newRows.sort(function (a, b) {
      let valA = get(a, valuePath);
      let valB = get(b, valuePath);

      switch (typeof valA) {
        case 'string':
          return valA.localeCompare(valB);
        default:
          return valA - valB;
      }
    });

    if (direction === 'desc') {
      newRows = newRows.reverse();
    }

    this.rows.clear();
    this.rows.addObjects(newRows);
  }

  filterBy(query) {
    this.tagSearchString = query;
  }

  addToTop(row) {
    this.rows.insertAt(0, row);
    this.rowsToDisplay += 1;
  }

  add(row) {
    this.rows.addObject(row);
    this._sort();
  }

  remove(row) {
    this.rows.removeObject(row);
  }

  loadMoreRows() {
    this.rowsToDisplay += this.rowsPerPage;
  }
}
