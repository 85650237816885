/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  _id: attr('string'),
  identifier: attr('string'),

  justAdded: false,
  mandatoryAttribute: attr('boolean', { defaultValue: false }),
}).reopenClass({
  removeInvalidFragments(qualificationAttributes) {
    //remove undefined and duplicate identifiers
    let identifiers = qualificationAttributes
      .uniqBy('identifier')
      .map((attr) => attr.get('identifier'));
    identifiers = identifiers.filter((identifier) => !!identifier);

    return identifiers.map((identifier) => {
      return qualificationAttributes.find((attr) => attr.get('identifier') === identifier);
    });
  },
});
