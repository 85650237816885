/* RESPONSIBLE TEAM: team-proactive-support */
import ContentEditorStandardAudienceEditorConfiguration from './standard-audience-editor-configuration';

export default class SeriesAudienceEditorConfiguration extends ContentEditorStandardAudienceEditorConfiguration {
  constructor(inputs) {
    super(inputs);

    this.audienceRulesEditor = this._createComponent(
      'content-editor/audience-wrappers/content/series/audience-rules-editor',
      this.inputs,
    );
  }
}
