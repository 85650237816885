/* RESPONSIBLE TEAM: team-frontend-tech */
export function memoryHeapInfo() {
  try {
    if (!performance.memory) {
      return {};
    }

    return {
      'device.memory.total_js_heap_size': performance.memory.totalJSHeapSize,
      'device.memory.used_js_heap_size': performance.memory.usedJSHeapSize,
      'device.memory.used_js_heap_size_limit': performance.memory.jsHeapSizeLimit,
    };
  } catch {
    // Just in case something is not available
  }
}
