/* RESPONSIBLE TEAM: team-purchase-experience */
import { type Metric } from 'embercom/models/data/pricing/metric-types';

export enum PricingTooltipItemType {
  BaseFee,
  IncludedUsage,
}

export interface PricingTooltipBaseIncludes {
  metric: Metric;
  type: PricingTooltipItemType;
  usage?: number;
  price: string;
}
