/* RESPONSIBLE TEAM: team-tickets-1 */
import { tracked } from '@glimmer/tracking';
import { aggregationText } from 'embercom/helpers/aggregation-text';
import Formatters from 'embercom/lib/reporting/flexible/formatters';

export interface DashboardSettingsContructorFormat {
  id: string;
  inboxIds: string[];
  selectedInboxOverviewColumns: string[];
  selectedInboxOverviewTimeframe: number;

  teamIds: string[];
  selectedTeammateActivityColumns: string[];
  selectedTeammateActivityTimeframe: number;
  selectedTeammateStatuses: string[];

  aggregationType: string;
  idleThreshold: number;
  excludeBotTime: boolean;
}

export interface DashboardSettingsWireFormat {
  id: string;
  inbox_ids: string[];
  selected_inbox_overview_columns: string[];
  selected_inbox_overview_timeframe: number;

  team_ids: string[];
  selected_teammate_activity_timeframe: number;
  selected_teammate_activity_columns: string[];
  selected_teammate_statuses: string[];

  aggregation_type: string;
  idle_threshold: number;
  exclude_bot_time: boolean;
}

export default class DashboardSettings {
  readonly id: string;

  @tracked inboxIds: string[];
  @tracked selectedInboxOverviewColumns: string[];
  @tracked selectedInboxOverviewTimeframe: number;

  @tracked teamIds: string[];
  @tracked selectedTeammateActivityColumns: string[];
  @tracked selectedTeammateActivityTimeframe: number;
  @tracked selectedTeammateStatuses: string[];

  @tracked aggregationType: string;
  @tracked aggregationTypeText: string;
  @tracked idleThreshold: number;
  @tracked idleThresholdText: string;
  @tracked excludeBotTime: boolean;

  constructor(inputs: DashboardSettingsContructorFormat) {
    let {
      id,
      inboxIds,
      selectedInboxOverviewColumns,
      selectedInboxOverviewTimeframe,

      teamIds,
      selectedTeammateActivityColumns,
      selectedTeammateActivityTimeframe,
      selectedTeammateStatuses,

      aggregationType,
      idleThreshold,
      excludeBotTime,
    } = inputs;
    this.id = id;
    this.inboxIds = inboxIds;
    this.selectedInboxOverviewColumns = selectedInboxOverviewColumns;
    this.selectedInboxOverviewTimeframe = selectedInboxOverviewTimeframe;
    this.teamIds = teamIds;
    this.selectedTeammateActivityColumns = selectedTeammateActivityColumns;
    this.selectedTeammateActivityTimeframe = selectedTeammateActivityTimeframe;
    this.selectedTeammateStatuses = selectedTeammateStatuses;
    this.aggregationType = aggregationType;
    this.idleThreshold = idleThreshold;
    this.excludeBotTime = excludeBotTime;
    this.idleThresholdText = this.formatTime(this.idleThreshold);
    this.aggregationTypeText = aggregationText(this.aggregationType);
  }

  formatTime(value: number) {
    let timeFormatter = new Formatters.seconds(); // eslint-disable-line new-cap
    return value ? timeFormatter.formatCounter(value) : null;
  }

  static deserialize(json: DashboardSettingsWireFormat) {
    return new DashboardSettings({
      id: json.id,
      inboxIds: json.inbox_ids,
      selectedInboxOverviewColumns: json.selected_inbox_overview_columns,
      selectedInboxOverviewTimeframe: json.selected_inbox_overview_timeframe,
      teamIds: json.team_ids,
      selectedTeammateActivityColumns: json.selected_teammate_activity_columns,
      selectedTeammateActivityTimeframe: json.selected_teammate_activity_timeframe,
      selectedTeammateStatuses: json.selected_teammate_statuses,
      aggregationType: json.aggregation_type,
      idleThreshold: json.idle_threshold,
      excludeBotTime: json.exclude_bot_time,
    });
  }
}
