/* RESPONSIBLE TEAM: team-frontend-tech */
// A tempory replacement for the built-in `capitalize` helper
// while we await resolution of https://github.com/emberjs/ember.js/issues/17864

import { typeOf } from '@ember/utils';

export default function simpleCapitalize(text) {
  if (typeOf(text) === 'string') {
    return text.charAt(0).toUpperCase() + text.slice(1);
  } else {
    return '';
  }
}
