/* RESPONSIBLE TEAM: team-help-desk-experience */

type CrmProfileType = 'pipedrive';

export interface CrmProfileWireFormat {
  id: string;
  name: string;
  stage_name: string;
  url: string;
  deal_status: string;
  deal_title: string;
  status: string;
  profile_type: CrmProfileType;
}

export default class CrmProfile {
  id: string;
  name: string;
  stageName: string;
  url: string;
  dealStatus: string;
  dealTitle: string;
  status: string;
  profileType: CrmProfileType;

  constructor(
    id: string,
    name: string,
    stageName: string,
    url: string,
    dealStatus: string,
    dealTitle: string,
    status: string,
    profileType: CrmProfileType,
  ) {
    this.id = id;
    this.name = name;
    this.stageName = stageName;
    this.url = url;
    this.dealStatus = dealStatus;
    this.dealTitle = dealTitle;
    this.status = status;
    this.profileType = profileType;
  }

  static deserialize(json: CrmProfileWireFormat): CrmProfile {
    return new CrmProfile(
      json.id,
      json.name,
      json.stage_name,
      json.url,
      json.deal_status,
      json.deal_title,
      json.status,
      json.profile_type,
    );
  }
}
