/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { lte, and, readOnly, gt, notEmpty } from '@ember/object/computed';
import { Promise as EmberPromise } from 'rsvp';
import { isPresent } from '@ember/utils';
import { computed } from '@ember/object';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import ajax from 'embercom/lib/ajax';
import Admin from 'embercom/models/admin';
import { inject as service } from '@ember/service';

export default Model.extend({
  intl: service(),
  admin_id: attr(),
  admin: computed('admin_id', function () {
    if (this.admin_id) {
      return Admin.peekAndMaybeLoad(this.store, this.admin_id);
    }
  }),
  app_id: attr(),
  created_at: attr(),
  blocks: attr(),
  summary: attr(),
  name: attr(),
  title: readOnly('name'),
  updated_at: attr(),
  types: attr('array', { defaultValue: () => [] }),
  visibleToTeamIds: attr(),
  visibility: attr('string', { defaultValue: 'everyone' }),
  active: false,
  actions: fragmentArray('macro/macro-action'),
  hasUpdates: attr('boolean', { defaultValue: false }),
  serializedBlocks: readOnly('blocks'),

  isValid: and('nameAndBlocksValid', 'isNameLengthValid', 'actionsValid', 'teamVisibilityValid'),
  availabilityValid: gt('types.length', 0),
  nameAndBlocksValid: computed('name', 'blocks', 'actions.length', function () {
    if (this.actions.length > 0) {
      return isPresent(this.name);
    }
    return isPresent(this.name) && isPresent(this.blocks);
  }),
  actionsValid: computed('actions.@each.isValid', function () {
    return this.actions.every((action) => action.isValid);
  }),
  hasActions: notEmpty('actions'),
  teamVisibilityValid: computed('visibility', 'visibileToTeamIds.[]', function () {
    if (this.visibility === 'teams') {
      return this.visibleToTeamIds.length > 0;
    } else {
      return true;
    }
  }),
  validationMessage: computed(
    'intl.locale',
    'nameAndBlocksValid',
    'actionsValid',
    'isNameLengthValid',
    'teamVisibilityValid',
    function () {
      if (!this.nameAndBlocksValid) {
        return this.intl.t('inbox.manage-macros.validation.name-and-body');
      } else if (!this.actionsValid) {
        return this.intl.t('inbox.manage-macros.validation.actions');
      } else if (!this.isNameLengthValid) {
        return this.intl.t('inbox.manage-macros.validation.name-length');
      } else if (!this.teamVisibilityValid) {
        return this.intl.t('inbox.manage-macros.validation.team-visibility');
      }
      return;
    },
  ),

  isNameLengthValid: lte('name.length', 140),
}).reopenClass({
  getRendered(id, appId, conversationId) {
    return new EmberPromise(
      function (resolve) {
        ajax({
          url: `/ember/saved_replies/${id}.json`,
          type: 'GET',
          data: {
            conversation_id: conversationId,
            app_id: appId,
          },
        }).then(function (data) {
          resolve(data);
        });
      }.bind(this),
    );
  },
});
