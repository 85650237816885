/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import { lte } from '@ember/object/computed';
import Admin from 'embercom/models/admin';

export default Model.extend({
  adminId: attr(),
  admin: computed('adminId', function () {
    if (this.adminId) {
      return Admin.peekAndMaybeLoad(this.store, this.adminId);
    }
  }),
  appId: attr(),
  blocks: fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' }),
  name: attr('string'),
  summary: attr('string'),
  createdAt: attr(),

  serializedBlocks: computed('blocks.[]', function () {
    return this.blocks.serialize();
  }),

  isValid: computed('name', 'blocks', function () {
    return isPresent(this.name) && isPresent(this.blocks);
  }),

  isNameLengthValid: lte('name.length', 140),
});
