/* RESPONSIBLE TEAM: team-tickets-1 */
import type Session from 'embercom/services/session';

let exportConversationTranscriptsPermissionFF =
  'inbox__conversation_transcripts__export_permission';

export function canExportConversationTranscriptsInbox2(session: Session) {
  return (
    !session.workspace.isFeatureEnabled(exportConversationTranscriptsPermissionFF) ||
    session.teammate.permissions.canInboxConversationTranscriptsExport
  );
}

export function canExportConversationTranscriptsInbox1(appService: any) {
  return (
    !appService.app.canSeeInboxConversationTranscriptsExport ||
    appService.app.currentAdmin.currentAppPermissions.can_inbox__conversation_transcripts__export
  );
}

export let exportConversationTranscriptsPermission = 'can_inbox__conversation_transcripts__export';
