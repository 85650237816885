/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';
import StandardInserterComponentConfiguration from 'embercom/objects/series/node/standard-inserter-component-configuration';
import StandardLoadingComponentConfiguration from 'embercom/objects/series/node/standard-loading-component-configuration';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class ConditionInserterConfiguration extends InserterConfiguration {
  objectType = objectTypes['condition'];
  objectData = {};
  componentConfig = new StandardInserterComponentConfiguration({
    icon: 'series/content/condition',
    title: capitalize(objectNames[this.objectType]),
  });
  loadingComponentConfig = new StandardLoadingComponentConfiguration({
    icon: 'series/content/condition',
    title: `Empty rule`,
    objectType: this.objectType,
  });

  canInsertNode() {
    return super.canInsertNode(...arguments);
  }
}
