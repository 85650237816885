/* RESPONSIBLE TEAM: team-proactive-support */
import { type Scope } from './constants/scopes';
import { post } from 'embercom/lib/ajax';
import type SavedViewData from './saved-view-data';

interface Args {
  id?: number;
  name: string;
  scope: Scope;
  avatarEmoji: string;
  savedViewData: SavedViewData;
}

export default class SavedView implements Args {
  constructor(args: Args) {
    this.id = args.id;
    this.name = args.name;
    this.scope = args.scope;
    this.avatarEmoji = args.avatarEmoji;
    this.savedViewData = args.savedViewData;
  }
  public name;
  public scope;
  public avatarEmoji;
  public savedViewData;
  public id?: number;

  async saveView(app: any) {
    let results = await post(`/ember/content_service/saved_views`, {
      app_id: app.id,
      admin_id: app.currentAdmin.id,
      name: this.name,
      scope: this.scope,
      saved_view_data: this.savedViewData.data,
      avatar_emoji: this.avatarEmoji,
    });
    this._updateValuesFromAPIResult(results);
    return this;
  }

  _updateValuesFromAPIResult(result: any) {
    this.id = result.id;
  }
}
