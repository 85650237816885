/* RESPONSIBLE TEAM: team-purchase-experience */
import Model, { attr } from '@ember-data/model';
import moment from 'moment-timezone';

// We have given OpenAI an extended free usage window and don't want to show the banner
// if the length of the window is greater than 6 months.
export const HIDE_FIN_FREE_USAGE_BANNER_DAY_CUT_OFF = 180;

export default class FinFreeUsageWindow extends Model {
  @attr('date') declare startDate: Date;
  @attr('date') declare endDate: Date;
  @attr('number') declare state: number;

  get hasExtendedFinFreeUsageWindow(): boolean {
    return this.daysRemaining > HIDE_FIN_FREE_USAGE_BANNER_DAY_CUT_OFF;
  }

  get daysRemaining(): number {
    let endDate = moment(this.endDate);
    return endDate.diff(moment(), 'days');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'fin-free-usage-window': FinFreeUsageWindow;
  }
}
