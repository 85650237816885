/* RESPONSIBLE TEAM: team-channels */
import { tracked } from '@glimmer/tracking';

export default class NewUser {
  constructor({ id, email, name, phone }) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.phone = phone;
  }

  isNewUser = true;

  @tracked email;
  @tracked name;
  @tracked phone;

  get displayAs() {
    return this.name || this.email;
  }
}
