/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import ProductTourContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/product-tour/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import SeriesSchedulePanelConfiguration from 'embercom/objects/content-editor/panels/schedule/series-schedule-panel-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';
import UseTourAnywherePanelConfiguration from 'embercom/objects/content-editor/panels/custom/use-tour-anywhere/panel-configuration';
import ProductTourAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/content/product-tour/audience-editor-configuration';
import ProductTourAudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/content/product-tour/audience-header-configuration';

export default function generateEditModeConfig(options) {
  let container = options.container;

  let audienceEditorConfiguration = new ProductTourAudienceEditorConfiguration({
    showClientPredicates: true,
    showEventRules: true,
    showDateEvents: true,
    hideMatchBehavior: true,
    showMatchBehavior: false,
    container,
  });

  audienceEditorConfiguration.footer = ComponentConfiguration.create({
    component: 'content-editor/panels/audience/content/product-tour/footer',
    config: audienceEditorConfiguration,
  });

  return EditModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesModeHeaderConfiguration.create({
      container,
      previewConfig: ComponentConfiguration.create({
        container,
        component: 'content-editor/headers/tours/preview-button',
      }),
    }),
    headerComponentConfigs: [
      StandardEditModeHeaderConfiguration.create({
        container,
        titleInputConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/tours/title-input',
        }),
        descriptionInputConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/tours/description-input',
        }),
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/tours/preview-button',
        }),
      }),
    ],
    editorPanelConfigs: [
      ProductTourContentPanelConfiguration.create({ container }),
      StandardAudiencePanelConfiguration.create({
        hideMatchBehavior: true,
        audienceHeaderConfiguration: ProductTourAudienceHeaderConfiguration.create({
          hideMatchBehavior: true,
          container,
        }),
        audienceEditorConfiguration,
        container,
      }),
      UseTourAnywherePanelConfiguration.create({ container }),
      StandardSchedulePanelConfiguration.create({
        showFrequencyEditor: true,
        container,
      }),
      SeriesSchedulePanelConfiguration.create({ container }),
      StandardGoalPanelConfiguration.create({
        introText: 'Understand how your tour drives people to take action in your product.',
        container,
      }),
    ],
    footerComponentConfigs: [],
  });
}
