/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardNodeComponentConfiguration from './standard-node-component-configuration';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ConditionNodeComponentConfiguration extends StandardNodeComponentConfiguration {
  @tracked node = undefined;

  get objectLabel() {
    if (this.node.isStarting) {
      return `Entry rules`;
    } else {
      return this.objectName;
    }
  }

  @computed(
    // eslint-disable-next-line ember/use-brace-expansion
    'node.series.nodes.[]',
    'node.isStarting',
    'node.series.isMultipleEntry',
    'node.outwardEdges.[]',
    'node.inwardEdges.[]',
    'node.series.startingNodes.[]',
  )
  get customErrorMessage() {
    if (
      this.node.isStarting &&
      this.node.series?.isMultipleEntry &&
      this.node.outwardEdges.length &&
      this.node.series?.startingNodes.filter((node) => node.isCondition).length > 1
    ) {
      return `A multi entry series must have a single starting point.`;
    }
  }

  constructor(params = {}) {
    super(params);
    this.node = params.node;
  }
}
