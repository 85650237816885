/* RESPONSIBLE TEAM: team-proactive-support */
import Model from '@ember-data/model';
import { getOwner } from '@ember/application';
import ControlGroupValidations from 'embercom/validations/control-group';
import controlGroupConfigGenerator from 'embercom/objects/content-editor/configuration-generators/control-group';

export default class ControlGroup extends Model.extend(ControlGroupValidations) {
  editorConfig(baseConfig) {
    // baseConfig here should be the editor configuration of the object we are creating a control
    // group for. i.e. contentEditorService.editorConfiguration
    let container = getOwner(this);
    return controlGroupConfigGenerator({ baseConfig, container });
  }
}
