/* RESPONSIBLE TEAM: team-help-desk-experience */
export interface UploadWireFormat {
  id: number;
  name: string;
  size: number;
  url: string;
  content_type: string;
  scan_state?: UploadScanState;
}

export enum UploadScanState {
  Clean = 'clean',
  Inconclusive = 'inconclusive',
  ExtensionMismatch = 'extension_mismatch',
  Infected = 'infected',
  Error = 'error',
}

export class Upload {
  readonly id: number;
  readonly name: string;
  readonly size: number;
  readonly url: string;
  readonly contentType: string;
  readonly scanState?: UploadScanState;

  constructor(
    id: number,
    name: string,
    size: number,
    url: string,
    contentType: string,
    scanState?: UploadScanState,
  ) {
    this.id = id;
    this.name = name;
    this.size = size;
    this.url = url;
    this.contentType = contentType;
    this.scanState = scanState;
  }

  static deserialize(json: UploadWireFormat): Upload {
    return new Upload(json.id, json.name, json.size, json.url, json.content_type, json.scan_state);
  }

  static serialize(upload: Upload): UploadWireFormat {
    return {
      id: upload.id,
      name: upload.name,
      size: upload.size,
      url: upload.url,
      content_type: upload.contentType,
      scan_state: upload.scanState,
    };
  }
}
