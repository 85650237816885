/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';

export enum StateChangingEntity {
  User = EntityType.User,
  Teammate = EntityType.Admin,
  Workflow = EntityType.Workflow,
  Bot = EntityType.Bot,
}

export enum StateChangeType {
  Opened = 0,
  Closed = 1,
  Snoozed = 2,
  Unsnoozed = 3,
  Priority = 4,
  NotPriority = 5,
}

export enum StateChangeReason {
  StandardStateChange = 0,
  ChangedDueToReply = 1,
  ChangedDueToUserArchival = 2,
  TimerFinished = 3,
}
