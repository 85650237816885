/* RESPONSIBLE TEAM: team-workflows */
import SortableGroup from 'embercom/modifiers/sortable-group';

export default class IntercomSortableGroup extends SortableGroup {
  willRemove() {
    this.announcer.remove();
    this.removeEventListener();
    this.sortableService.deregisterGroup(this.groupName, this);
  }
}
