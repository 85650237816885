/* RESPONSIBLE TEAM: team-frontend-tech */
import Tooltip from '@intercom/pulse/modifiers/tooltip';

// TODO(@22a): port this logic back to the base tooltip modifier

export default class TooltipWithLineClampTruncationDetection extends Tooltip {
  get isTruncated() {
    let truncationDetectionElement =
      this.element.querySelector('[data-tooltip-truncation-marker]') || this.element;
    let isTruncatedHorizontally =
      truncationDetectionElement.offsetWidth < truncationDetectionElement.scrollWidth;
    let isTruncatedVertically =
      truncationDetectionElement.clientHeight < truncationDetectionElement.scrollHeight;
    return isTruncatedHorizontally || isTruncatedVertically;
  }
}
