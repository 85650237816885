/* RESPONSIBLE TEAM: team-workflows */
import Model, { attr } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import type ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';

export default class RoutingSettings extends Model {
  @attr('boolean') declare enabled: boolean;
  @belongsTo('conversation-attributes/descriptor', { async: false })
  declare classificationDescriptor: ConversationAttributeDescriptor;
}
