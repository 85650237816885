/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import parseUrl from 'embercom/lib/url-parser';

let charSpaceRatio = 7;
let ellipsis = '\u2026';
let fileExtensions = ['.asp', '.aspx', '.php', '.html', '.jsp', '.xhtml', '.jsp', '.jspx'];

let UrlTruncator = EmberObject.extend({
  truncate(url, availableWidth) {
    let urlParts = parseUrl(url);
    let pathname = urlParts.pathname;

    if (pathname.length === 0 || pathname === '/') {
      return urlParts.hostname;
    } else {
      return this._truncateUriPath(this._removeFileExtension(pathname), availableWidth);
    }
  },
  _truncateUriPath(url, availableWidth) {
    let maxNumCharacters = availableWidth / charSpaceRatio;

    if (url.length > maxNumCharacters + ellipsis.length) {
      let truncationIndex = url.length - maxNumCharacters;
      return ellipsis + url.substring(truncationIndex, url.length);
    }

    return url;
  },
  _removeFileExtension(path) {
    let self = this;
    fileExtensions.some(function (item) {
      if (self._endsWith(path, item)) {
        path = path.substring(0, path.length - item.length);
        return true;
      }
    });
    return path;
  },
  _endsWith(subjectString, searchString) {
    let position = subjectString.length - searchString.length;
    let lastIndex = subjectString.indexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  },
});

let urlTruncator = UrlTruncator.create();

// Truncates from the left of a url as there is more context on the right
export default function (url, availableWidth) {
  return urlTruncator.truncate(url, availableWidth);
}
