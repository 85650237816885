/* RESPONSIBLE TEAM: team-tickets-1 */
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import ConversationAttributeDescriptorSummary, {
  type ConversationAttributeDescriptorSummaryWireFormat,
} from 'embercom/objects/inbox/conversation-attribute-descriptor-summary';
import { Upload, type UploadWireFormat } from 'embercom/objects/inbox/renderable/upload';

export interface ConversationAttributeSummaryWireFormat {
  value: string | number | boolean | UploadWireFormat[] | Array<object>;
  descriptor: ConversationAttributeDescriptorSummaryWireFormat;
}

export default class ConversationAttributeSummary {
  @tracked value: string | number | boolean | undefined | Upload[] | Array<object>;
  @tracked private originalValue: ConversationAttributeSummary['value'];

  readonly descriptor: ConversationAttributeDescriptorSummary;

  constructor(
    value: ConversationAttributeSummary['value'],
    descriptor: ConversationAttributeDescriptorSummary,
  ) {
    this.value = value;
    this.originalValue = value;
    this.descriptor = descriptor;
  }

  static deserialize(json: ConversationAttributeSummaryWireFormat): ConversationAttributeSummary {
    let descriptor = ConversationAttributeDescriptorSummary.deserialize(json.descriptor);
    let val = this.isArrayOfUploadWireFormat(json.value)
      ? json.value.map((upload) => Upload.deserialize(upload))
      : json.value;
    return new ConversationAttributeSummary(val, descriptor);
  }

  static isArrayOfUploadWireFormat(value: any): value is UploadWireFormat[] {
    return Array.isArray(value) && value.length > 0 && 'content_type' in value[0];
  }

  get isUpdated() {
    let value = this.value;

    if (isEmpty(value) && isEmpty(this.originalValue)) {
      return false;
    }

    if (['integer', 'decimal'].includes(this.descriptor.type)) {
      value = Number(value);
    }

    return value !== this.originalValue;
  }

  rollback() {
    this.value = this.originalValue;
  }

  update(value: ConversationAttributeSummary['value']) {
    this.value = value;
    this.originalValue = value;
  }

  normalizeValue() {
    switch (this.descriptor.type) {
      case 'integer':
        return this.normalizeNumber(true);
      case 'decimal':
        return this.normalizeNumber(false);
      case 'string':
        return this.normalizeString();
    }
  }

  isAttributeIntegerAndLargerThanMaxSafeInteger(): boolean {
    return (
      this.descriptor.type === 'integer' &&
      typeof this.value === 'string' &&
      !Number.isSafeInteger(parseInt(this.value, 10))
    );
  }

  get isValueValid(): boolean {
    if (this.value === '') {
      return true;
    }

    switch (this.descriptor.type) {
      case 'integer':
        return !this.isAttributeIntegerAndLargerThanMaxSafeInteger();
      case 'decimal':
        return !isNaN(parseFloat(String(this.value)));
      default:
        return true;
    }
  }

  normalizeString() {
    this.value = (this.value as string).trim();
  }

  normalizeNumber(isInteger: boolean) {
    let cast = Number(this.value);
    if (this.value === '' || isNaN(cast)) {
      return;
    }

    this.value = isInteger ? Math.round(cast) : cast;
  }

  get isValueEmpty() {
    return isEmpty(this.value);
  }
}
