/* RESPONSIBLE TEAM: team-frontend-tech */

// This is a linked list data structure to store hierarchy of root spans.
// "root" here means a span that can have child spans.
// For now, only page load span and Ember route transition spans could be root spans.
// Entry looks like this:
import { hrTime, hrTimeDuration, hrTimeToMilliseconds } from '@opentelemetry/core';
import platform from '../browser-platform';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import { navigatorInfo } from 'embercom/lib/tracing/navigator-info';

const EmptyRootSpanTimeout = 1000;
const TraceTimeOut = 5 * 60 * 1000; // 5 minutes in milliseconds
const InactivityTimeout = 1000;

export default class SpanData {
  span; // OTEL Span object
  parent; // reference to the parent SpanData item of the linked list
  startTime;
  // time of the latest ended child span, we have to keep it in milliseconds as that's what span.endTime returns
  currentEndTime;
  activeChildSpanIDs = new Set();
  childrenSeen = 0;
  lastActivityTime;
  active = true;
  rollupFields = {};
  globalTags = {
    'device.platform': platform.name,
    'device.browser': UserAgentDetector.browserName(),
    ...navigatorInfo(),
  };

  constructor(span, parentSpanData) {
    this.span = span;
    this.startTime = span.startTime;
    this.parent = parentSpanData;
    if (parentSpanData) {
      this.globalTags = parentSpanData.globalTags;
    }
    span.setAttributes(this.globalTags);
  }

  setGlobalTags(tags) {
    this.globalTags = Object.assign(this.globalTags, tags);
    this.span.setAttributes(this.globalTags);
  }

  get endTime() {
    return this.currentEndTime ?? hrTimeToMilliseconds(hrTime());
  }

  set endTime(endTime) {
    let endTimeMs = hrTimeToMilliseconds(endTime);
    if (!this.currentEndTime) {
      this.currentEndTime = endTimeMs;
    } else {
      this.currentEndTime = Math.max(this.currentEndTime, endTimeMs);
    }
  }

  addActiveChild(spanId) {
    this.bumpActivity();
    this.activeChildSpanIDs.add(spanId);
    this.childrenSeen += 1;
  }

  removeActiveChild(spanId) {
    this.bumpActivity();
    this.activeChildSpanIDs.delete(spanId);
  }

  isReadyForCompletion() {
    // Only end root spans with at least one registered child in the past and no active children at the moment.
    // We don't want to end a root span that has just started - hence the childrenSeen condition.
    // No active child spans means all the underlying XHRs are finished and it is safe to end the span.
    // Or, alternatively finish the span when it had no children for more than 1 second.
    // Chances are this span will never have any XHR/fetch requests and hence no children – there's no point in waiting.
    return (
      (this.activeChildSpanIDs.size === 0 && this.childrenSeen > 0) ||
      (this.childrenSeen === 0 && this.duration >= EmptyRootSpanTimeout)
    );
  }

  isTraceTimedOut() {
    let topLevelRoot = this.topLevelRoot();
    return topLevelRoot && topLevelRoot.duration >= TraceTimeOut;
  }

  topLevelRoot() {
    let topLevelRoot = this;

    while (topLevelRoot?.parent) {
      topLevelRoot = topLevelRoot.parent;
    }

    return topLevelRoot;
  }

  bestParentForStartTime(startTime) {
    // We'd like to get rid of the long tasks that are started after the current root endTime
    if (
      this.activeChildSpanIDs.size === 0 &&
      this.childrenSeen > 0 &&
      this.endTime < hrTimeToMilliseconds(startTime)
    ) {
      return;
    }

    let newParent = this;

    while (newParent && hrTimeToMilliseconds(hrTimeDuration(newParent.startTime, startTime)) < 0) {
      // We've attempted to start a span with a start time before the start of
      // the current active parent span. This doesn't really make sense, the
      // parent wasn't initialized yet so couldn't have initiated this child, so
      // we should attempt to attribute it to another parent further up the
      // spanHierarchy tree
      newParent = newParent.parent;
    }

    return newParent;
  }

  addRollupField(name, value) {
    if (isNaN(value)) {
      return;
    }
    let topLevelRoot = this.topLevelRoot();
    if (topLevelRoot.rollupFields[name]) {
      topLevelRoot.rollupFields[name] += value;
    } else {
      topLevelRoot.rollupFields[name] = value;
    }
  }

  get duration() {
    return hrTimeToMilliseconds(hrTimeDuration(this.startTime, hrTime()));
  }

  bumpActivity() {
    this.lastActivityTime = hrTime();
  }

  maybeDeactivate(newSpan) {
    if (
      newSpan?.startTime &&
      this.active &&
      this.lastActivityTime &&
      this.childrenSeen &&
      this.activeChildSpanIDs.size === 0 &&
      hrTimeToMilliseconds(hrTimeDuration(this.lastActivityTime, newSpan.startTime)) >
        InactivityTimeout
    ) {
      this.active = false;
      this.topLevelRoot()?.span?.setAttribute('inactive', true);
      this.span?.addEvent('inactive_trace');
    }
  }
}
