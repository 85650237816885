/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import moment from 'moment-timezone';
import { ALL_SEAT_LABELS } from 'embercom/lib/settings/seats/constants';
import { isEmpty } from '@ember/utils';

const DATE_WITHOUT_TIME = 'YYYY-MM-DD';
const DATE_WITH_TIME = 'YYYY-MM-DD HH:mm';
const ROLE_COLUMN_INDEX = 3;

export default class TeammatesCsvExporter {
  constructor(app, admins, { showInboxSeatColumn, showMultipleSeats }) {
    this.app = app;
    this.admins = admins;
    this.showInboxSeatColumn = showInboxSeatColumn;
    this.showMultipleSeats = showMultipleSeats;
    this.dataBuilder = this._constructDataBuilder();
  }

  get filename() {
    return `Teammate List ${moment.tz(this.app.timezone).format(DATE_WITHOUT_TIME)}.csv`;
  }

  get data() {
    let data = this.admins.map((admin) => this.dataBuilder(admin));
    data.unshift(this._headerColumn);

    return data;
  }

  _constructDataBuilder() {
    if (this.showInboxSeatColumn) {
      return this._prepareExportDataForPPP;
    } else if (this.showMultipleSeats) {
      return this._prepareExportDataForVBP2_Pricing5x;
    } else {
      return this._prepareExportDataForVBP1x;
    }
  }

  _prepareExportDataForPPP(admin) {
    let inboxAccessStatus = admin.hasInboxAccess ? '1' : '0';

    return this.withRoleIfApplicable(
      [
        admin.name,
        admin.email,
        inboxAccessStatus,
        this._lastActiveDateBy(admin),
        this._2faStatusBy(admin),
        this._teammateUrl(admin),
      ],
      admin,
    );
  }

  _prepareExportDataForVBP2_Pricing5x(adminData) {
    let admin = adminData.teammate;

    return this.withRoleIfApplicable(
      [
        admin.name,
        admin.email,
        this._seats(admin),
        this._lastActiveDateBy(admin),
        this._2faStatusBy(admin),
        this._teammateUrl(admin),
      ],
      admin,
    );
  }

  _prepareExportDataForVBP1x(admin) {
    return this.withRoleIfApplicable(
      [
        admin.name,
        admin.email,
        this._lastActiveDateBy(admin),
        this._2faStatusBy(admin),
        this._teammateUrl(admin),
      ],
      admin,
    );
  }

  _lastActiveDateBy(admin) {
    return admin.last_active && moment(admin.last_active * 1000).format(DATE_WITH_TIME);
  }

  _2faStatusBy(admin) {
    return admin.has_enabled_2fa ? 'Enabled' : 'Disabled';
  }

  _teammateUrl(admin) {
    return `https://app.intercom.com/a/apps/${this.app.id}/settings/teammates/${admin.id}/permissions`;
  }

  _seats(admin) {
    if (isEmpty(admin.seats)) {
      return '-';
    }

    return admin.seats.map((seatType) => ALL_SEAT_LABELS[seatType]).join(', ');
  }

  get _headerColumn() {
    let baseHeaderColumn;
    if (this.showInboxSeatColumn) {
      baseHeaderColumn = [
        'Name',
        'Email',
        'Has Inbox Access',
        'Last Active',
        '2fa',
        'Teammate URL',
      ];
    } else if (this.showMultipleSeats) {
      baseHeaderColumn = ['Name', 'Email', 'Seats', 'Last Active', '2fa', 'Teammate URL'];
    } else {
      baseHeaderColumn = ['Name', 'Email', 'Last Active', '2fa', 'Teammate URL'];
    }

    if (this.app.hasRoles) {
      baseHeaderColumn.splice(ROLE_COLUMN_INDEX, 0, 'Role');
    }

    return baseHeaderColumn;
  }

  withRoleIfApplicable(row, admin) {
    if (this.app.hasRoles) {
      row.splice(ROLE_COLUMN_INDEX, 0, admin.currentAppPermissions.role?.name || '-');
    }

    return row;
  }
}
