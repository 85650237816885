/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
// @ts-ignore
import PredicateGroup from 'predicates/models/predicate-group';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
export default class ContentPreview extends Model {
  @attr({ defaultValue: () => {} }) declare contentPreviewData: any;
  @attr() declare generatedAt: number;

  @tracked predicateGroup = undefined;

  // Due to a crashing bug with creating a predicate-group (a model fragment) as part of setting up a component
  // whether inside a getter or not, we'll set it up directly on the model for use later.
  // This is admittedly gross as we're not guaranteed that this content content preview will even have predicates.
  ready() {
    this.setupPredicateGroup();
  }

  @action onLoad() {
    scheduleOnce('afterRender', this, this.setupPredicateGroup);
  }

  setupPredicateGroup() {
    if (this.contentPreviewData?.predicates) {
      this.predicateGroup = this.store.createFragment('predicates/predicate-group', {
        predicates: PredicateGroup.convertRawPredicates(
          this.store,
          this.contentPreviewData.predicates,
        ),
      });
    }
  }
}
