/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type SeriesWait from 'embercom/models/series/wait';

export default class extends RulesetLink {
  @belongsTo('series/wait', { async: false }) declare object: SeriesWait;
  get objectType() {
    return objectTypes.wait;
  }
}
