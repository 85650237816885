/* RESPONSIBLE TEAM: team-proactive-support */
import { type DataType } from './constants/data-types';

interface SavedViewDataArgs {
  savedViewDataType: DataType;
}

export default abstract class SavedViewData implements SavedViewDataArgs {
  constructor(args: SavedViewDataArgs) {
    this.savedViewDataType = args.savedViewDataType;
  }
  public savedViewDataType;

  get data(): any {
    return {
      saved_view_data_type: this.savedViewDataType,
    };
  }
}
