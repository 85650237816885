/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Block from './block';
import { notEmpty } from '@ember/object/computed';
import { fragment } from 'ember-data-model-fragments/attributes';

export default Block.extend({
  items: attr('array'),
  style: fragment('common/blocks/style'),
  hasContent: notEmpty('items'),
});
