/* RESPONSIBLE TEAM: team-help-desk-experience */
import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';
import { type ConversationState, type TicketSystemState } from './conversation';
import RenderablePart, { type RenderablePartWireFormat } from './renderable-part';
import {
  TicketCategory,
  TicketType,
  type TicketTypeWireFormat,
} from 'embercom/objects/inbox/ticket';
import { tracked } from '@glimmer/tracking';
import type TeamSummary from './team-summary';
import ConversationAttributeSummary, {
  type ConversationAttributeSummaryWireFormat,
} from './conversation-attribute-summary';
import ParticipantUserSummary from 'embercom/objects/inbox/participant-user-summary';
import { type UserSummaryWireFormat } from './user-summary';
import { DEFAULT_TICKET_TYPE_ATTRIBUTES } from 'embercom/lib/inbox/constants';

export interface LatestConversationSummaryConstructorFormat {
  id: number;
  redacted: boolean;
  title: string;
  lastUpdated: Date;
  createdAt: Date;
  state: ConversationState;
  adminAssignee: AdminSummary;
  lastRenderableSummaryPart?: RenderablePart;
  firstUserPart?: RenderablePart;
  ticketType?: TicketType;
  ticketState?: TicketSystemState;
  ticketCustomStateId?: number;
  ticketCategory?: TicketCategory;
  ticketId?: string;
  participantSummaries: ParticipantUserSummary[];
  attributes?: ConversationAttributeSummary[];
}
export interface LatestConversationSummaryWireFormat {
  id: number;
  redacted: boolean;
  title: string;
  last_updated: string;
  created_at: string;
  state: ConversationState;
  admin_assignee: AdminSummaryWireFormat;
  last_renderable_part?: RenderablePartWireFormat;
  first_user_part?: RenderablePartWireFormat;
  ticket_type?: TicketTypeWireFormat;
  ticket_state?: TicketSystemState;
  ticket_custom_state_id?: number;
  ticket_category?: TicketCategory;
  ticket_id?: string;
  participant_summaries?: UserSummaryWireFormat[];
  attributes?: ConversationAttributeSummaryWireFormat[];
}

export default class LatestConversationSummary {
  readonly id: number;
  readonly title: string;
  readonly lastUpdated: Date;
  readonly createdAt: Date;
  readonly lastRenderableSummaryPart?: RenderablePart;
  readonly firstUserPart?: RenderablePart;
  readonly ticketType?: TicketType;
  readonly isTicket: boolean = false;
  readonly ticketCategory?: TicketCategory;
  readonly ticketId?: string;

  @tracked redacted: boolean;
  @tracked state: ConversationState;
  @tracked ticketState?: TicketSystemState;
  @tracked ticketCustomStateId?: number;
  @tracked priority?: boolean;
  @tracked adminAssignee: AdminSummary;
  @tracked teamAssignee?: TeamSummary;
  @tracked attributes?: ConversationAttributeSummary[];
  @tracked participantSummaries: ParticipantUserSummary[];

  constructor(inputs: LatestConversationSummaryConstructorFormat) {
    let {
      id,
      redacted,
      title,
      lastUpdated,
      createdAt,
      state,
      adminAssignee,
      lastRenderableSummaryPart,
      firstUserPart,
      ticketType,
      ticketState,
      ticketCustomStateId,
      ticketCategory,
      ticketId,
      participantSummaries,
      attributes,
    } = inputs;
    this.id = id;
    this.redacted = redacted;
    this.title = title;
    this.lastUpdated = lastUpdated;
    this.createdAt = createdAt;
    this.state = state;
    this.adminAssignee = adminAssignee;
    this.lastRenderableSummaryPart = lastRenderableSummaryPart;
    this.firstUserPart = firstUserPart;
    this.ticketType = ticketType;
    this.isTicket = !!ticketType;
    this.ticketState = ticketState;
    this.ticketCustomStateId = ticketCustomStateId;
    this.ticketCategory = ticketCategory;
    this.ticketId = ticketId;
    this.participantSummaries = participantSummaries;
    this.attributes = attributes;
  }

  static deserialize(json: LatestConversationSummaryWireFormat) {
    let lastRenderableSummaryPart = undefined;
    if (json.last_renderable_part) {
      lastRenderableSummaryPart = RenderablePart.deserialize(json.last_renderable_part);
    }
    let firstUserPart = undefined;
    if (json.first_user_part) {
      firstUserPart = RenderablePart.deserialize(json.first_user_part);
    }
    let ticketType = undefined;
    if (json.ticket_type) {
      ticketType = TicketType.deserialize(json.ticket_type);
    }
    let participantSummaries = json.participant_summaries
      ? json.participant_summaries.compact().map(ParticipantUserSummary.deserialize)
      : undefined;
    let attributes = undefined;
    if (json.attributes) {
      attributes = json.attributes.map((attribute) =>
        ConversationAttributeSummary.deserialize(attribute),
      );
    }
    return new LatestConversationSummary({
      id: json.id,
      redacted: json.redacted,
      title: json.title,
      lastUpdated: new Date(json.last_updated),
      createdAt: new Date(json.created_at),
      state: json.state,
      adminAssignee: AdminSummary.deserialize(json.admin_assignee),
      lastRenderableSummaryPart,
      firstUserPart,
      ticketType,
      ticketState: json.ticket_state,
      ticketCustomStateId: json.ticket_custom_state_id,
      ticketCategory: json.ticket_category,
      ticketId: json.ticket_id,
      participantSummaries: participantSummaries ?? [],
      attributes,
    });
  }

  hasAttributeForDescriptor(id: number | string) {
    return this.attributes?.any((attribute) => attribute.descriptor.id === id);
  }

  addAttribute(attribute: ConversationAttributeSummary) {
    if (!this.attributes) {
      return;
    }

    if (this.hasAttributeForDescriptor(attribute.descriptor.id)) {
      return;
    }

    this.attributes = [...this.attributes, attribute];
  }

  get isReplyable(): boolean {
    return !this.isTicket;
  }

  get isTrackerTicket(): boolean {
    return this.ticketCategory === TicketCategory.Tracker;
  }

  get isCustomerTicket(): boolean {
    return this.ticketCategory === TicketCategory.Request;
  }

  get isBackOfficeTicket(): boolean {
    return this.ticketCategory === TicketCategory.Task;
  }

  get ticketTitle() {
    if (!this.isTicket) {
      return '';
    }

    let titleAttribute = this.attributes?.find(
      (attr) => attr.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.TITLE,
    );

    if (titleAttribute) {
      return titleAttribute.value as string;
    }
    return this.ticketType?.name;
  }

  get ticketDescription() {
    if (!this.isTicket) {
      return '';
    }

    let descriptionAttribute = this.attributes?.find(
      (attr) => attr.descriptor.name === DEFAULT_TICKET_TYPE_ATTRIBUTES.DESCRIPTION,
    );

    if (descriptionAttribute) {
      return descriptionAttribute.value;
    }

    return '';
  }
}
