/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { stateChangeDescriptions } from 'embercom/models/data/inbox/state-change-descriptions';
import {
  type StateChangeReason,
  type StateChangeType,
  StateChangingEntity,
} from 'embercom/models/data/inbox/state-change-enums';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import CustomBotSummary from '../../custom-bot-summary';

import { type StateChangePart, type StateChangeWireFormat } from '../state-change';

export default class BotStateChange implements StateChangePart {
  readonly renderableType: RenderableType = RenderableType.StateChange;
  readonly changedByEntityType: StateChangingEntity = StateChangingEntity.Bot;
  readonly changedByEntity: AdminSummary;
  readonly change: StateChangeType;
  readonly reason: StateChangeReason;
  readonly customBotSummary?: CustomBotSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: this.changedByEntity.id, object: this.changedByEntity };
  }

  get eventDescription(): string {
    return stateChangeDescriptions[this.change][this.reason];
  }

  constructor(
    change: StateChangeType,
    reason: StateChangeReason,
    changedByEntity: AdminSummary,
    customBotSummary?: CustomBotSummary,
  ) {
    this.change = change;
    this.reason = reason;
    this.changedByEntity = changedByEntity;
    this.customBotSummary = customBotSummary;
  }

  static deserialize(json: StateChangeWireFormat): BotStateChange {
    if (json.changed_by_entity_type !== StateChangingEntity.Bot) {
      throw new Error(
        `Attempted to create a BotStateChange from a response with changed entity type of '${json.changed_by_entity_type}'`,
      );
    }

    return new BotStateChange(
      json.change,
      json.reason,
      AdminSummary.deserialize(json.changed_by_entity),
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
