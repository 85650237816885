/* RESPONSIBLE TEAM: team-data-interop */

import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';
import type Field from './field';

export default class Form extends Model {
  @attr('string') declare name: string;
  @attr('string') declare displayName: string;
  @attr('boolean') declare endUserVisible: boolean;
  @attr('boolean') declare active: boolean;
  @attr('boolean') declare default: boolean;
  @hasMany('side-by-side/handover/zendesk/field', { async: false })
  declare ticketFields: SyncHasMany<Field>;
}
