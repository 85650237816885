/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';
import StandardInserterComponentConfiguration from 'embercom/objects/series/node/standard-inserter-component-configuration';
import StandardLoadingComponentConfiguration from 'embercom/objects/series/node/standard-loading-component-configuration';
import {
  objectFeatures,
  objectTypes,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class OutboundWebhookInserterConfiguration extends InserterConfiguration {
  objectType = objectTypes['outboundWebhook'];
  objectData = {};
  componentConfig = new StandardInserterComponentConfiguration({
    icon: 'series/content/webhook',
    paywallIcon: 'series/content/paywalled-webhook',
    featureFlagsWithAccess: [objectFeatures[this.objectType]],
    title: capitalize(objectNames[this.objectType]),
  });
  loadingComponentConfig = new StandardLoadingComponentConfiguration({
    icon: 'series/content/webhook',
    title: `Empty webhook`,
    objectType: this.objectType,
  });

  canInsertNode() {
    return super.canInsertNode(...arguments);
  }
}
