/* RESPONSIBLE TEAM: team-help-desk-experience */

import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';

// The filter dropdown can be in one of the following states:
// - ConversationState: open, closed, snoozed
// - TicketSystemState: submitted, in_progress, waiting_on_customer, resolved
export type InboxStateOption = ConversationState | TicketSystemState;

export const INBOX_STATE_ICON_MAPPING = {
  [ConversationState.Open]: 'reopen-conversation',
  [TicketSystemState.Submitted]: 'ticket',
  [TicketSystemState.InProgress]: 'ticket',
  [TicketSystemState.WaitingOnCustomer]: 'ticket',
  [TicketSystemState.Resolved]: 'ticket',
  [ConversationState.Closed]: 'close-conversation',
  [ConversationState.Snoozed]: 'snooze',
};

export enum InboxConversationTypeOption {
  All = 'all',
  Conversation = 'conversation',
  Ticket = 'ticket',
}

export enum InboxSortOption {
  Oldest = 'oldest',
  Newest = 'newest',
  StartedFirst = 'started-first',
  StartedLast = 'started-last',
  WaitingLongest = 'waiting-longest',
  NextSlaTarget = 'next-sla-target',
  PriorityNewest = 'priority-newest',
  Relevance = 'relevance',
}

export enum InboxMentionsStatus {
  All = 'all',
  Unread = 'unread',
}

export enum InboxSearchSort {
  Oldest = 'oldest',
  Newest = 'newest',
  Relevance = 'relevance',
}

export function isConversationStateEqual(
  inboxState: InboxStateOption,
  state: ConversationState,
  ticketState?: TicketSystemState,
): boolean {
  return {
    [ConversationState.Open]: state === ConversationState.Open,
    [ConversationState.Closed]: state === ConversationState.Closed,
    [ConversationState.Snoozed]: state === ConversationState.Snoozed,
    [TicketSystemState.WaitingOnCustomer]: ticketState === TicketSystemState.WaitingOnCustomer,
    [TicketSystemState.InProgress]: ticketState === TicketSystemState.InProgress,
    [TicketSystemState.Submitted]: ticketState === TicketSystemState.Submitted,
    [TicketSystemState.Resolved]: ticketState === TicketSystemState.Resolved,
  }[inboxState];
}

export function isTicketState(state: unknown): boolean {
  return Object.values(TicketSystemState).includes(state as TicketSystemState);
}

export function isConversationInInbox(
  inbox: Inbox | undefined,
  conversation: ConversationSummary | ConversationTableEntry,
) {
  if (!inbox) {
    return false;
  }

  // In the All, Mentions, Created By You, or views inbox, conversations are always in context.
  if (
    inbox.id === 'all' ||
    inbox.id === 'mentions' ||
    inbox.id === 'created_by_you' ||
    inbox.type === 'view'
  ) {
    return true;
  }

  if (inbox.id === 'unassigned') {
    return conversation.isNotAssigned;
  }

  return conversation.isAssignedTo(Number(inbox.id));
}

export function stateToInt(state: InboxStateOption): number | undefined {
  if (state === ConversationState.Open) {
    return 0;
  }

  if (state === ConversationState.Closed) {
    return 1;
  }

  if (state === ConversationState.Snoozed) {
    return 2;
  }

  return undefined;
}

export function intToState(n?: unknown): InboxStateOption | undefined {
  if (n === 0) {
    return ConversationState.Open;
  }

  if (n === 1) {
    return ConversationState.Closed;
  }

  if (n === 2) {
    return ConversationState.Snoozed;
  }

  return undefined;
}
