/* RESPONSIBLE TEAM: team-growth-opportunities */
import Modifier, { type modifier } from 'ember-modifier';
import { inject as service } from '@ember/service';
import type MailForwardingValidationService from 'embercom/services/mail-forwarding-validation-service';

interface Signature {
  Args: {
    Named: Record<string, unknown>;
    positional: unknown[];
    Element: HTMLElement;
  };
}

export default class UnsetMailForwardingValidationServiceModifier extends Modifier<Signature> {
  @service declare mailForwardingValidationService: MailForwardingValidationService;

  modify(_element: HTMLElement, _positional: unknown[], _named: Record<string, unknown>): void {
    this.mailForwardingValidationService.unset();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'unset-mail-forwarding-validation-service-modifier': typeof modifier;
  }
}
