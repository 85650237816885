/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { assert } from '@ember/debug';

export default EmberObject.extend({
  editMode: undefined,
  viewMode: undefined,
  notificationMessages: undefined,

  init(inputs) {
    this._super(...arguments);
    assert('An edit mode configuration must be passed', inputs.editMode);
    assert('A view mode configuration must be passed', inputs.viewMode);
    assert('Notification messages must be passed', inputs.notificationMessages);

    inputs.editMode.parentConfiguration = this;
    inputs.viewMode.parentConfiguration = this;

    this.setProperties({
      editMode: inputs.editMode,
      viewMode: inputs.viewMode,
      notificationMessages: inputs.notificationMessages,
    });
  },
});
