/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, attr } from '@ember-data/model';
import { applyFunction } from '@intercom/pulse/lib/computed-properties';
import countFormatter from 'embercom/lib/count-formatter';
import { toPercentString } from 'embercom/lib/percentage-formatter';

export default Model.extend({
  controlGroup: belongsTo('messages/control-group'),

  sentCount: attr('number'),
  displaySentCount: applyFunction(countFormatter, 'sentCount'),
  goalCount: attr('number'),
  goalSuccessPercentage: attr('number'),
  displayGoalSuccessPercentage: applyFunction(toPercentString, 'goalSuccessPercentage'),
  lastLoadedAt: attr('date'),
}).reopenClass({
  translateFromRealTimeNotification(data) {
    data.last_loaded_at = new Date().toISOString();
    return data;
  },
});
