/* RESPONSIBLE TEAM: team-product-exploration */

import { type Router } from '@ember/routing';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

export type RedirectTo = {
  route: string;
  queryParams?: Record<string, any>;
  id?: string | number;
};

export type RedirectMapping = {
  [key: string]:
    | RedirectTo
    | {
        resolveTransition: (transition: Transition) => RedirectTo;
      };
};

function resolveMessengerInstallationRedirect(transition: Transition): RedirectTo {
  let originalQueryParams = transition.to.queryParams;
  return {
    route: 'apps.app.settings.channels.messenger.install',
    queryParams: {
      tab: originalQueryParams.selectedTab || 'web',
      section: originalQueryParams.selectedAccordion || 'messenger-setup',
    },
  };
}

export function isOnMigratedRoute(currentRoute: string): boolean {
  // Migrated routes are route names that have been moved from new-settings route to settings route completely.
  let migratedRoutes = [
    'apps.app.settings.ai-automation',
    'apps.app.settings.app-settings',
    'apps.app.settings.proactive-support',
    'apps.app.settings.channels',
    'apps.app.settings.helpcenter',
    'apps.app.settings.helpdesk',
    'apps.app.settings.data',
    'apps.app.settings.workspace',
    'apps.app.settings.index',
    'apps.app.settings.personal',
  ];

  return migratedRoutes.some((route) => currentRoute.startsWith(route));
}

function resolveEmailSenderTransition(transition: Transition): RedirectTo {
  let queryParams = { tab: 'sender-email-addresses' } as any;

  if (transition.to.queryParams.verifiedCustomEmail) {
    queryParams.verifiedCustomEmail = transition.to.queryParams.verifiedCustomEmail;
  }

  return {
    route: 'apps.app.settings.channels.email',
    queryParams,
  };
}

function resolvePhoneRegulatoryBundleTransitionWithID(transition: Transition): RedirectTo {
  let queryParams = transition.to.queryParams as any;
  let id = transition.to.params.id;
  let page = transition.to.name.includes('edit') ? 'edit' : 'show';

  return {
    route: `apps.app.settings.channels.phone.phone-regulatory-bundles.${page}`,
    queryParams,
    id,
  };
}

function resolveCustomActionTransitionWithID(transition: Transition): RedirectTo {
  let id = transition.to.params.id;
  let queryParams = transition.to.queryParams as any;

  return {
    route: 'apps.app.settings.app-settings.custom-actions.custom-action',
    queryParams,
    id,
  };
}

export const SETTINGS_REDIRECT_MAPPING = {
  'apps.app.channels': {
    route: 'apps.app.settings.channels.social-channels',
  },
  'apps.app.settings.general': { route: 'apps.app.settings.workspace.general' },
  'apps.app.settings.events': {
    route: 'apps.app.settings.data.people',
    queryParams: { tab: 'events' },
  },
  'apps.app.settings.teammates': {
    route: 'apps.app.settings.workspace.teammates',
    queryParams: { tab: 'teammates' },
  },
  'apps.app.settings.roles': {
    route: 'apps.app.settings.workspace.teammates',
    queryParams: { tab: 'roles' },
  },
  'apps.app.settings.teams': { route: 'apps.app.settings.helpdesk.teams' },
  'apps.app.settings.logs': {
    route: 'apps.app.settings.workspace.teammates',
    queryParams: { tab: 'activity-logs' },
  },
  'apps.app.settings.office-hours': { route: 'apps.app.settings.workspace.office-hours' },
  'apps.app.settings.brands': { route: 'apps.app.settings.workspace.brands' },
  'apps.app.settings.blocked-users': {
    route: 'apps.app.settings.data.people',
    queryParams: { tab: 'blocked' },
  },
  'apps.app.settings.ai-assist': { route: 'apps.app.settings.ai-automation.helpdesk-ai' },
  'apps.app.settings.teammates.invite': {
    route: 'apps.app.settings.workspace.teammates.invite.new',
  },
  'apps.app.settings.tags-new': { route: 'apps.app.settings.data.tags' },
  'apps.app.settings.people-segments': {
    route: 'apps.app.settings.data.people',
    queryParams: { tab: 'segments' },
  },
  'apps.app.settings.company-segments': {
    route: 'apps.app.settings.data.companies',
    queryParams: { tab: 'segments' },
  },
  'apps.app.settings.people-data': { route: 'apps.app.settings.data.people' },
  'apps.app.settings.company-data': { route: 'apps.app.settings.data.companies' },
  'apps.app.settings.qualification-settings': {
    route: 'apps.app.settings.data.people',
    queryParams: { tab: 'lead-qualification' },
  },
  'apps.app.settings.conversation-data': {
    route: 'apps.app.settings.data.conversation-tickets',
  },
  'apps.app.settings.custom-object-data': { route: 'apps.app.settings.data.custom-objects' },
  'apps.app.settings.ticket-data': {
    route: 'apps.app.settings.helpdesk.tickets',
    queryParams: { tab: 'ticket-types' },
  },
  'apps.app.settings.ticket-data.detail': {
    resolveTransition: resolveTicketTypeDetailTransition,
  },
  'apps.app.settings.ticket-states': {
    route: 'apps.app.settings.helpdesk.tickets',
    queryParams: { tab: 'ticket-states' },
  },
  'apps.app.settings.macros': { route: 'apps.app.settings.helpdesk.macros' },
  'apps.app.settings.audiences': { route: 'apps.app.settings.data.audiences' },
  'apps.app.settings.slas': { route: 'apps.app.settings.helpdesk.sla' },
  'apps.app.settings.imports': { route: 'apps.app.settings.data.imports-exports' },
  'apps.app.settings.delete-user': {
    route: 'apps.app.settings.data.people',
    queryParams: { tab: 'delete-data' },
  },
  'apps.app.settings.data-export': {
    route: 'apps.app.settings.data.imports-exports',
    queryParams: { tab: 'export-data' },
  },
  'apps.app.settings.migrate-from-zendesk': {
    route: 'apps.app.settings.data.imports-exports',
    queryParams: { tab: 'import-zendesk' },
  },
  'apps.app.settings.workload-management': {
    route: 'apps.app.settings.helpdesk.assignments',
    tab: 'workspace',
  },
  'apps.app.settings.inbox-rules': { route: 'apps.app.settings.helpdesk.rules' },
  'apps.app.settings.automatic-away-mode': {
    route: 'apps.app.settings.helpdesk.assignments',
    queryParams: { tab: 'other' },
  },
  'apps.app.settings.assignment-preferences': {
    route: 'apps.app.settings.helpdesk.assignments',
    queryParams: { tab: 'other' },
  },
  'apps.app.settings.teammate-presence': {
    route: 'apps.app.settings.helpdesk.assignments',
    queryParams: { tab: 'other' },
  },
  'apps.app.settings.domains': {
    route: 'apps.app.settings.channels.email',
    queryParams: { tab: 'domains' },
  },
  'apps.app.settings.senders': {
    resolveTransition: resolveEmailSenderTransition,
  },
  'apps.app.settings.email-dedicated-ips': {
    route: 'apps.app.settings.channels.email',
    queryParams: { tab: 'advanced' },
  },
  'apps.app.settings.email-forwarding': {
    route: 'apps.app.settings.channels.email',
    queryParams: { tab: 'email-forwarding' },
  },
  'apps.app.settings.email-notifications': {
    route: 'apps.app.settings.channels.email',
    queryParams: { tab: 'customisation' },
  },
  'apps.app.settings.email-auto-reply': {
    route: 'apps.app.settings.channels.email',
    queryParams: { tab: 'customisation' },
  },
  'apps.app.settings.email-custom-assets-domains': {
    route: 'apps.app.settings.channels.email',
    queryParams: { tab: 'advanced' },
  },
  'apps.app.settings.email-signature': {
    route: 'apps.app.settings.channels.email',
    queryParams: { tab: 'customisation' },
  },
  'apps.app.settings.email-other-settings': {
    route: 'apps.app.settings.channels.email',
    queryParams: { tab: 'advanced' },
  },
  'apps.app.settings.phone': { route: 'apps.app.settings.channels.phone' },
  'apps.app.settings.switch': { route: 'apps.app.settings.channels.switch' },
  'apps.app.settings.installation': {
    resolveTransition: resolveMessengerInstallationRedirect,
  },
  'apps.app.settings.installation-new': {
    resolveTransition: resolveMessengerInstallationRedirect,
  },
  'apps.app.settings.web': {
    resolveTransition: resolveMessengerInstallationRedirect,
  },
  'apps.app.settings.security': { route: 'apps.app.settings.workspace.security' },
  'apps.app.settings.compliance-documents': { route: 'apps.app.settings.workspace.security' },
  'apps.app.settings.attachment-settings': { route: 'apps.app.settings.workspace.security' },
  'apps.app.settings.identity-verification': {
    route: 'apps.app.settings.workspace.security',
    queryParams: { tab: 'enforce-identity' },
  },
  'apps.app.settings.custom-authentication-tokens': {
    route: 'apps.app.settings.app-settings.authentication',
  },
  'apps.app.settings.workflow-connector-actions': {
    route: 'apps.app.settings.app-settings.custom-actions.index',
  },
  'apps.app.settings.workflow-connector-actions.custom-action': {
    resolveTransition: resolveCustomActionTransitionWithID,
  },
  'apps.app.settings.webhooks': { route: 'apps.app.settings.app-settings.legacy-webhooks' },
  'apps.app.messenger.web': {
    route: 'apps.app.settings.channels.messenger.web',
    queryParams: { tab: 'content', section: 'layout-and-spaces' },
  },
  'apps.app.messenger.mobile-sdk': {
    route: 'apps.app.settings.channels.messenger.mobile-sdk',
    queryParams: { tab: 'content', section: 'layout-and-spaces' },
  },
  'apps.app.messenger.conversations': {
    route: 'apps.app.settings.channels.messenger.conversations',
    queryParams: { tab: 'teammates', section: 'start-conversation-button' },
  },
  'apps.app.messenger.settings': {
    route: 'apps.app.settings.channels.messenger.general',
    queryParams: { section: 'inbound-volume' },
  },
  'apps.app.messenger.conversational': { route: 'apps.app.settings.channels.messenger.opt-in' },
  'apps.app.settings.tickets-onboarding': {
    route: 'apps.app.settings.helpdesk.tickets.tickets-onboarding',
  },
  'apps.app.settings.ios': {
    route: 'apps.app.settings.channels.messenger.install',
    queryParams: { tab: 'ios' },
  },
  'apps.app.settings.android': {
    route: 'apps.app.settings.channels.messenger.install',
    queryParams: { tab: 'android' },
  },

  'apps.app.settings.calling': {
    route: 'apps.app.settings.channels.phone',
  },
  'apps.app.settings.calling.phone-regulatory-bundles.index': {
    route: 'apps.app.settings.channels.phone.phone-regulatory-bundles.index',
  },
  'apps.app.settings.calling.phone-regulatory-bundles.new': {
    route: 'apps.app.settings.channels.phone.phone-regulatory-bundles.new',
  },
  'apps.app.settings.calling.phone-regulatory-bundles.show': {
    resolveTransition: resolvePhoneRegulatoryBundleTransitionWithID,
  },
  'apps.app.settings.calling.phone-regulatory-bundles.edit': {
    resolveTransition: resolvePhoneRegulatoryBundleTransitionWithID,
  },
} as RedirectMapping;

export const AUTOMATION_REDIRECT_MAPPING = {
  'apps.app.fin-ai-agent.setup': { resolveTransition: resolveFinAIAgentSetupTransition },
  'apps.app.fin-ai-agent.content-suggestions': {
    resolveTransition: resolveFinAIAgentContentSuggestionsTransition,
  },
  'apps.app.fin-ai-agent.profiles.index': { route: 'apps.app.automation.fin-ai-agent.profiles' },
  'apps.app.fin-ai-agent.profiles.edit': {
    resolveTransition: resolveFinAIAgentProfilesEditTransition,
  },
  'apps.app.fin-ai-agent.custom-answers': {
    resolveTransition: resolveFinAIAgentCustomAnswersTransition,
  },
  'apps.app.fin-ai-agent.custom-answers.new': {
    resolveTransition: resolveFinAIAgentCustomAnswersNewTransition,
  },
  'apps.app.fin-ai-agent.custom-answers.answer': {
    resolveTransition: resolveFinAIAgentCustomAnswersAnswerTransition,
  },
  'apps.app.fin-ai-agent.custom-answers.answer.edit': {
    resolveTransition: resolveFinAIAgentCustomAnswersAnswerTransitionWithID,
  },
  'apps.app.fin-ai-agent.custom-answers.answer.show': {
    resolveTransition: resolveFinAIAgentCustomAnswersAnswerTransitionWithID,
  },
  'apps.app.fin-ai-agent.custom-answers.unanswered-questions': {
    route: 'apps.app.automation.fin-ai-agent.custom-answers.unanswered-questions',
  },
} as RedirectMapping;

function resolveFinAIAgentSetupTransition(transition: Transition): RedirectTo {
  let queryParams = transition.to.queryParams as any;

  return {
    route: 'apps.app.automation.fin-ai-agent.setup',
    queryParams,
  };
}

function resolveFinAIAgentContentSuggestionsTransition(transition: Transition): RedirectTo {
  let queryParams = transition.to.queryParams as any;

  return {
    route: 'apps.app.automation.fin-ai-agent.content-suggestions',
    queryParams,
  };
}

function resolveFinAIAgentProfilesEditTransition(transition: Transition): RedirectTo {
  let id = transition.to.params.id;
  let queryParams = transition.to.queryParams as any;

  return {
    route: 'apps.app.automation.fin-ai-agent.profiles.edit',
    id,
    queryParams,
  };
}

function resolveFinAIAgentCustomAnswersTransition(transition: Transition): RedirectTo {
  let queryParams = transition.to.queryParams as any;

  return {
    route: 'apps.app.automation.fin-ai-agent.custom-answers',
    queryParams,
  };
}

function resolveFinAIAgentCustomAnswersNewTransition(transition: Transition): RedirectTo {
  let queryParams = transition.to.queryParams as any;

  return {
    route: 'apps.app.automation.fin-ai-agent.custom-answers.new',
    queryParams,
  };
}

function resolveFinAIAgentCustomAnswersAnswerTransition(transition: Transition): RedirectTo {
  let id = transition.to.params.id;
  let queryParams = transition.to.queryParams as any;

  return {
    route: 'apps.app.automation.fin-ai-agent.custom-answers.answer',
    id,
    queryParams,
  };
}

function resolveFinAIAgentCustomAnswersAnswerTransitionWithID(transition: Transition): RedirectTo {
  let parentRoute = transition.to.find(
    (route) => route.name === 'apps.app.fin-ai-agent.custom-answers.answer',
  );
  let id = parentRoute ? parentRoute.params.answer_id : undefined;
  let queryParams = transition.to.queryParams as any;
  let page = transition.to.name.includes('edit') ? 'edit' : 'show';

  return {
    route: `apps.app.automation.fin-ai-agent.custom-answers.answer.${page}`,
    id,
    queryParams,
  };
}

export const ACCOUNT_REDIRECT_MAPPING = {
  'apps.app.account.general': { route: 'apps.app.settings.personal.preferences' },
  'apps.app.account.notifications': { route: 'apps.app.settings.personal.notifications' },
  'apps.app.account.oauth-tokens': { route: 'apps.app.settings.personal.authentication' },
  'apps.app.account.account_access': { route: 'apps.app.settings.personal.account-access' },
  'apps.app.account.visibility': {
    route: 'apps.app.settings.personal.visible-to-you.company-segments',
  },
} as RedirectMapping;

function resolveManageSubscription(transition: Transition): RedirectTo {
  let queryParams = transition.to.queryParams as any;

  return {
    route: 'apps.app.settings.workspace.billing.manage-subscription',
    queryParams,
  };
}

function resolveTicketTypeDetailTransition(transition: Transition): RedirectTo {
  let id = transition.to.params.ticket_type_id;

  return {
    route: 'apps.app.settings.helpdesk.tickets.ticket-types.detail',
    id,
  };
}

function resolveInvoicesTransition(transition: Transition): RedirectTo {
  let id = transition.to.params.id;
  let queryParams = transition.to.queryParams as any;

  return {
    route: 'apps.app.settings.workspace.billing.invoices.invoice',
    queryParams,
    id,
  };
}

export const BILLING_REDIRECT_MAPPING = {
  'apps.app.billing.cancel': { route: 'apps.app.settings.workspace.billing.cancel' },
  'apps.app.billing.cancel.confirm': {
    route: 'apps.app.settings.workspace.billing.cancel.confirm',
  },
  'apps.app.billing.cancel.complete': {
    route: 'apps.app.settings.workspace.billing.cancel.complete',
  },
  'apps.app.billing.details': { route: 'apps.app.settings.workspace.billing.index' },
  'apps.app.billing.index': { route: 'apps.app.settings.workspace.billing.index' },
  'apps.app.billing.invoices': { route: 'apps.app.settings.workspace.billing.invoices.index' },
  'apps.app.billing.invoices.invoice': { resolveTransition: resolveInvoicesTransition },
  'apps.app.billing.manage-subscription': { resolveTransition: resolveManageSubscription },
  'apps.app.billing.manage-usage': {
    route: 'apps.app.settings.workspace.billing.manage-usage',
  },
  'apps.app.billing.migration': { route: 'apps.app.settings.workspace.migration' },
  'apps.app.billing.settings': { route: 'apps.app.settings.workspace.billing.settings' },
  'apps.app.billing.summary': { route: 'apps.app.settings.workspace.billing.index' },
  'apps.app.billing.usage': { route: 'apps.app.settings.workspace.billing.usage' },
} as RedirectMapping;

function resolveHelpCenterSettingsTransition(transition: Transition): RedirectTo {
  let id = transition.to.params.id;

  return {
    route: 'apps.app.settings.helpcenter.workspace-helpcenter.settings',
    id,
  };
}

function resolveHelpCenterCollectionsTransition(transition: Transition): RedirectTo {
  let id = transition.to.params.id;

  return {
    route: 'apps.app.settings.helpcenter.workspace-helpcenter.collections',
    id,
  };
}

const DIRECT_ROUTES_LIST = [
  'apps.app.outbound.settings.sms-keyword-responses.edit',
  'apps.app.outbound.settings.sms-keyword-responses.new',
];

/**
 * PERSONAL_BACKTRACK_REDIRECT_MAPPING is used to redirect the user
 * back to accounts routes when they opt out of the product IA
 * and are on the settings > personal page
 */
export const PERSONAL_BACKTRACK_REDIRECT_MAPPING = {
  'apps.app.settings.personal.details': {
    route: 'apps.app.account.general',
  },
  'apps.app.settings.personal.preferences': {
    route: 'apps.app.account.general',
  },
  'apps.app.settings.personal.notifications': {
    route: 'apps.app.account.notifications',
  },
  'apps.app.settings.personal.visible-to-you': {
    route: 'apps.app.account.visibility',
  },
  'apps.app.settings.personal.visible-to-you.company-segments': {
    route: 'apps.app.account.visibility.company-segments',
  },
  'apps.app.settings.personal.visible-to-you.user-segments': {
    route: 'apps.app.account.visibility.user-segments',
  },
  'apps.app.settings.personal.visible-to-you.tags': {
    route: 'apps.app.account.visibility.tags',
  },
  'apps.app.settings.personal.authentication': {
    route: 'apps.app.account.oauth-tokens',
  },
  'apps.app.settings.personal.account-access': {
    route: 'apps.app.account.account_access',
  },
} as RedirectMapping;

export const PROACTIVE_REDIRECT_MAPPING = {
  'apps.app.outbound.settings.messaging': {
    route: 'apps.app.settings.proactive-support.customization',
  },
  'apps.app.outbound.settings.universal-linking': {
    route: 'apps.app.settings.proactive-support.customization',
  },
  'apps.app.outbound.settings.subscription-types': {
    route: 'apps.app.settings.proactive-support.subscriptions',
  },
  'apps.app.outbound.settings.sms': {
    route: 'apps.app.settings.channels.sms',
  },
  'apps.app.outbound.settings.email-templates': {
    route: 'apps.app.settings.channels.email',
    queryParams: {
      highlight: 'customization-templates',
      tab: 'customisation',
    },
  },
  'apps.app.outbound.news.labels': {
    route: 'apps.app.settings.proactive-support.news-labels',
  },
  'apps.app.outbound.news.newsfeeds': {
    route: 'apps.app.settings.proactive-support.newsfeeds',
  },
  'apps.app.articles.site.settings': {
    resolveTransition: resolveHelpCenterSettingsTransition,
  },
  'apps.app.articles.site.collections.site-collection': {
    resolveTransition: resolveHelpCenterCollectionsTransition,
  },
} as RedirectMapping;

export function routeRedirector(
  transition: Transition,
  router: Router | RouterService,
  redirectMapping: RedirectMapping,
  defaultRoute: string | RedirectTo,
): void {
  let [routeName, queryParams, id] =
    typeof defaultRoute === 'string'
      ? [defaultRoute, undefined, undefined]
      : [defaultRoute.route, defaultRoute.queryParams, defaultRoute.id];

  if (DIRECT_ROUTES_LIST.includes(transition.to.name)) {
    return;
  }

  Object.keys(redirectMapping).forEach((key) => {
    if (transition.to.name.startsWith(key)) {
      let redirectObj: any = redirectMapping[key];
      if (redirectObj.resolveTransition) {
        let resolvedTransition = redirectObj.resolveTransition(transition);
        routeName = resolvedTransition.route;
        queryParams = resolvedTransition.queryParams;
        if (resolvedTransition.id) {
          id = resolvedTransition.id;
        }
      } else {
        routeName = redirectObj.route;
        queryParams = redirectObj.queryParams;
      }
    }
  });

  if (id) {
    router.transitionTo(routeName, id, { queryParams: queryParams || {} });
    return;
  }

  router.transitionTo(routeName, { queryParams: queryParams || {} });
}
