/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import moment from 'moment-timezone';

export default Model.extend({
  name: attr('string'),
  time_zone_name: attr('string', {
    defaultValue: () => moment.tz.guess(),
  }),
  force_2fa: attr('boolean'),
  should_gather_social_data: attr('boolean'),
  companies_enabled: attr('boolean'),
  uses_test_app: attr('boolean'),
  customer_has_active_subscription: attr('boolean'),
  authorized_sign_in_methods: attr('array'),
  sso_domain_whitelist: attr('array'),
  can_enable_enforce_sso: attr('boolean'),
  parsed_allowed_domains: attr('array'),
  teammateConversationAttachmentsEnabled: attr('boolean'),
  timezones: attr('array'),
});
