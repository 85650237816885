/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* eslint-disable @intercom/intercom/no-bare-strings */

export enum Metric {
  thirty_day_messaged_contacts = 'thirty_day_messaged_contacts',
  thirty_day_messaged_contacts_product_tours = 'thirty_day_messaged_contacts_product_tours',
  latest_daily_admin_count = 'latest_daily_admin_count',
  support_seat_count = 'support_seat_count',
  engage_seat_count = 'engage_seat_count',
  marketing_seat_count = 'marketing_seat_count',
  proactive_support_seat_count = 'proactive_support_seat_count',
  contracted_solution_seat_count = 'contracted_solution_seat_count',
  contracted_agent_count = 'contracted_agent_count',
  support_seat_count_or_latest_daily_admin_count = 'support_seat_count_or_latest_daily_admin_count',
  inbox_seats = 'inbox_seats',
  active_people = 'active_people',
  monthly_active_people = 'monthly_active_people',
  monthly_active_people_beta = 'monthly_active_people_beta',
  quarterly_active_people = 'quarterly_active_people',
  people_count = 'people_count',
  monthly_surveys_sent = 'monthly_surveys_sent',
  monthly_calls_deflected = 'monthly_calls_deflected',
  fixed = 'fixed',
  active_phone_numbers_count_us = 'active_phone_numbers_count_us',
  active_phone_numbers_count_uk = 'active_phone_numbers_count_uk',
  active_phone_numbers_count_ca = 'active_phone_numbers_count_ca',
  active_phone_numbers_count_au = 'active_phone_numbers_count_au',
  sms_segments_sent_received_monthly_us = 'sms_segments_sent_received_monthly_us',
  sms_segments_sent_received_monthly_uk = 'sms_segments_sent_received_monthly_uk',
  sms_segments_sent_received_monthly_ca = 'sms_segments_sent_received_monthly_ca',
  sms_segments_sent_received_monthly_au = 'sms_segments_sent_received_monthly_au',
  sms_segments_sent_received_in_shifted_billing_cycle_us = 'sms_segments_sent_received_in_shifted_billing_cycle_us',
  sms_segments_sent_received_in_shifted_billing_cycle_uk = 'sms_segments_sent_received_in_shifted_billing_cycle_uk',
  sms_segments_sent_received_in_shifted_billing_cycle_ca = 'sms_segments_sent_received_in_shifted_billing_cycle_ca',
  sms_segments_sent_received_in_shifted_billing_cycle_au = 'sms_segments_sent_received_in_shifted_billing_cycle_au',
  resolutions = 'resolutions', // Resolutions with Fin Ai Answers
  resolutions_with_custom_answers = 'resolutions_with_custom_answers', // Resolutions with custom answers or Fin Ai Answers
  core_seat_count = 'core_seat_count',
  light_seat_count = 'light_seat_count',
  whatsapp_inbound = 'whatsapp_inbound',
  whatsapp_outbound = 'whatsapp_outbound',
  emails_sent = 'emails_sent',
  calling = 'calling',
  messages_sent = 'messages_sent',
  copilot_seat_count = 'copilot_seat_count',
  mapped_sms_segments_sent_received_in_shifted_billing_cycle_au = 'sms_segments_sent_received_au',
  mapped_sms_segments_sent_received_in_shifted_billing_cycle_ca = 'sms_segments_sent_received_ca',
  mapped_sms_segments_sent_received_in_shifted_billing_cycle_uk = 'sms_segments_sent_received_uk',
  mapped_sms_segments_sent_received_in_shifted_billing_cycle_us = 'sms_segments_sent_received_us',
  mapped_resolutions_with_custom_answers = 'resolutions',
}
