/* RESPONSIBLE TEAM: team-frontend-tech */
import { modifier } from 'ember-modifier';
import { next, later } from '@ember/runloop';

export interface AutoFocusElementSignature {
  Element: HTMLElement;
  Args: {
    Positional: [boolean?];
    Named: { delay?: number };
  };
}

const AutoFocusModifier = modifier<AutoFocusElementSignature>(
  (element, [active], { delay }) => {
    if (active === false) {
      return;
    }

    if (delay) {
      later(
        this,
        () => {
          element.focus();
        },
        delay,
      );
    } else {
      // running this in a `next` give enough time for elements with simple animations to become visible
      // eg. in our editor panels
      next(this, () => {
        element.focus();
      });
    }
  },
  { eager: false },
);

export default AutoFocusModifier;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'auto-focus-element': typeof AutoFocusModifier;
  }
}
