/* RESPONSIBLE TEAM: team-help-desk-experience */

import { type IDBPDatabase } from 'idb';
import { type Span } from '@opentelemetry/api';
import { WeakIdentityMap } from 'weak-identity-map';

import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';

import { ResponseError, buildParams, request } from 'embercom/lib/inbox/requests';
import { type DB } from 'embercom/services/inbox2-core-data';
import type Session from 'embercom/services/session';
import type Tracing from 'embercom/services/tracing';

export default abstract class ObjectStore<T extends { id: unknown }> {
  protected db: IDBPDatabase<DB>;
  protected identityMap = new WeakIdentityMap<string, T>();

  @service declare session: Session;
  @service declare tracing: Tracing;

  constructor(owner: unknown, db: IDBPDatabase<DB>) {
    setOwner(this, owner);
    this.db = db;
  }

  abstract boot(): Promise<void>;

  abstract fetchAll(): Promise<T[]>;

  abstract fetchOne(id: T['id']): Promise<T | null>;

  static upgradeDB(_db: IDBPDatabase<DB>, _oldVersion: number, _newVersion: number | null) {}

  async shutdown() {
    this.identityMap.clear();
  }

  protected async fetchData<T>(path: string, version?: number): Promise<T | null> {
    return await this.tracing.inSpan(
      { name: 'fetchData', resource: 'inbox2-core-data', attributes: { path, version } },
      async (span?: Span) => {
        try {
          let params = buildParams(this.session.workspace.id, { version });
          let response = await request(`${path}?${params}`);
          let json = (await response.json()) as unknown as T;
          span?.setAttributes({ cached: false });
          return json;
        } catch (e: unknown) {
          if (e instanceof ResponseError && e.response.status === 304) {
            // nothing to do, we have the latest version already
            span?.setAttributes({ cached: true });
            return null;
          } else {
            throw e;
          }
        }
      },
    );
  }
}
