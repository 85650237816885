/* RESPONSIBLE TEAM: team-help-desk-experience */
export interface TeamSettingsWireFormat {
  id: number;
  assignment_distribution: string;
  volume_control_enabled: boolean;
}

export default class TeamSettings {
  readonly id: number;
  readonly assignmentDistribution: string;
  readonly volumeControlEnabled: boolean;

  constructor(id: number, assignmentDistribution: string, volumeControlEnabled: boolean) {
    this.id = id;
    this.assignmentDistribution = assignmentDistribution;
    this.volumeControlEnabled = volumeControlEnabled;
  }

  static deserialize(json: TeamSettingsWireFormat): TeamSettings {
    return new TeamSettings(json.id, json.assignment_distribution, json.volume_control_enabled);
  }
}
