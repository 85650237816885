/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { type Metric } from 'embercom/models/data/pricing/metric-types';

type Tier = {
  flatAmount: number | null;
  flatAmountDecimal: number | null;
  unitAmount: number | null;
  unitAmountDecimal: number | null;
  upTo: number | null;
};

export default class Price extends Fragment {
  @attr declare object: 'price';
  @attr declare metadata: { metric: Metric };
  @attr declare tiers: Array<Tier>;
}
