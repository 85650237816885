/* RESPONSIBLE TEAM: team-workflows */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import CustomBotSummary, { type CustomBotSummaryWireFormat } from '../custom-bot-summary';

interface RatingRequestNotSentWireFormat {
  reason: string;
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

export default class RatingRequestNotSent implements RenderableData {
  readonly renderableType = RenderableType.RatingRequestNotSent;
  readonly reason: string;
  readonly customBotSummary?: CustomBotSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(reason: string, customBotSummary?: CustomBotSummary) {
    this.reason = reason;
    this.customBotSummary = customBotSummary;
  }

  static deserialize(json: RatingRequestNotSentWireFormat): RatingRequestNotSent {
    return new RatingRequestNotSent(
      json.reason,
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
