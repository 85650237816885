/* RESPONSIBLE TEAM: team-channels */
import { isPresent } from '@ember/utils';

export interface WhatsappIntegrationSenderWireFormat {
  id: number;
  phone_number: string;
  whatsapp_account_id: string;
  display_name: string;
}

export default class WhatsappIntegrationSender {
  readonly id: number;
  readonly phoneNumber: string;
  readonly whatsappAccountId: string;
  readonly displayName: string;

  constructor(id: number, phoneNumber: string, whatsappAccountId: string, displayName: string) {
    this.id = id;
    this.phoneNumber = phoneNumber;
    this.whatsappAccountId = whatsappAccountId;
    this.displayName = displayName;
  }

  get displayString() {
    if (isPresent(this.displayName)) {
      return `${this.displayName} (${this.phoneNumber})`;
    } else {
      return this.phoneNumber;
    }
  }

  static deserialize(json: WhatsappIntegrationSenderWireFormat): WhatsappIntegrationSender {
    return new WhatsappIntegrationSender(
      json.id,
      json.phone_number,
      json.whatsapp_account_id,
      json.display_name,
    );
  }

  static serialize(sender: WhatsappIntegrationSender): WhatsappIntegrationSenderWireFormat {
    return {
      id: sender.id,
      phone_number: sender.phoneNumber,
      whatsapp_account_id: sender.whatsappAccountId,
      display_name: sender.displayName,
    };
  }
}
