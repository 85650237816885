/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';

export default class DisplayAttributeSetting extends Model {
  @attr('string') declare app_id: string;
  @attr('string') declare attribute: string;
  @attr('string') declare display_on: string;
  @attr('string') declare sort_direction: string;
  @attr('string') declare sort_by: string | undefined;

  get displayOnUsers() {
    return this.display_on === 'users';
  }

  get displayOnCompanies() {
    return this.display_on === 'companies';
  }

  get isUser() {
    return !this.isCompany;
  }

  get isCompany() {
    return this.attribute.match(/^company\./);
  }

  get isSorted() {
    return isPresent(this.sort_by);
  }

  get isSortedDescending() {
    return this.sort_by === 'desc';
  }

  static sortBy(displayedAttribute: any, app: any, display_on: string): Promise<any> {
    let attributeSetting = displayedAttribute.getAttributeSettingByDisplayOn(display_on);
    let isSortedDescending = attributeSetting.get('isSortedDescending');
    app.get('attribute_settings').setEach('sort_by', '');

    if (isSortedDescending) {
      attributeSetting.set('sort_by', 'asc');
    } else {
      attributeSetting.set('sort_by', 'desc');
    }

    return attributeSetting.save();
  }
}
