/* RESPONSIBLE TEAM: team-proactive-support */
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';
import { not } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default StandardSummaryConfiguration.extend({
  init(_inputs) {
    this._super({
      title: this.intl.t('outbound.content-editor.panels.checklist-sharing.rules'),
      component: 'content-editor/summaries/audience/content/audience-summary/summary',
    });
  },
  intl: service(),
  contentEditorService: service(),
  isVisible: not('contentEditorService.isSeriesMode').readOnly(),
});
