/* RESPONSIBLE TEAM: team-tickets-1 */

import { ChildAction, ParentAction } from './action';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { inject as service } from '@ember/service';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type InboxState from 'embercom/services/inbox-state';

export default class SearchTicketAction extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare inboxState: InboxState;

  id = 'search-ticket';
  icon = 'tracker' as const;
  paneComponent = 'inbox2/command-k/search-ticket/pane';

  get label() {
    let count = this.inboxState.selectedConversations.count;

    return this.inboxState.hasSelectedConversations
      ? this.intl.t('inbox.command-k.actions.bulk-link-reports', { count })
      : this.intl.t('inbox.command-k.actions.search-ticket');
  }

  buildResults(conversations: ConversationTableEntry[]): Array<ChildAction> {
    return conversations.map((conversation) => {
      return new ChildAction({
        parent: this,
        id: conversation.id.toString(),
        label: conversation.ticketTitle || conversation.ticketType?.name || '',
        value: conversation,
        optionComponent: 'inbox2/command-k/search-ticket/option',
      });
    });
  }
}
