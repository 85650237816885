/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, type SyncHasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import OutboundWebhookValidations from 'embercom/validations/outbound-webhook';
import webhookEditorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/outbound-webhook';
import { getOwner } from '@ember/application';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import type OutboundWebhookKeyValue from './outbound-webhook-key-value';

export default class OutboundWebhook extends Model.extend(OutboundWebhookValidations) {
  @service declare appService: any;

  @attr('string') declare url: string;
  @attr('number', { defaultValue: 0 }) declare httpMethod: number;
  @attr('number', { defaultValue: 0 }) declare contentType: number;
  @fragmentArray('outbound-webhook-key-value')
  declare headers: SyncHasMany<OutboundWebhookKeyValue>;
  @fragmentArray('outbound-webhook-key-value') declare body: SyncHasMany<OutboundWebhookKeyValue>;

  editorConfig() {
    let container = getOwner(this);
    return webhookEditorConfigGenerator({ container, webhook: this });
  }

  serialize() {
    let data = super.serialize(...arguments) as Record<string, any>;

    ['body', 'headers'].forEach((key) => {
      data[key] = data[key].reduce((acc: any, item: any) => {
        acc[item.key] = item.value;
        return acc;
      }, {});
    });

    return data;
  }
}
