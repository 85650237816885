/* RESPONSIBLE TEAM: team-proactive-support */
import StandardSummaryConfiguration from '../../standard-summary-configuration';
import { inject as service } from '@ember/service';

export default StandardSummaryConfiguration.extend({
  intl: service(),
  init(inputs) {
    let title = inputs.title || this.intl.t('app.objects.content-editor.whatsapp.content');
    this._super({
      title,
      component: 'content-editor/summaries/content/whatsapp/summary',
      isVisible: true,
    });
  },
});
