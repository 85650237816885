/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { dependentKeyCompat } from '@ember/object/compat';
import { isNone } from '@ember/utils';
import { type Paragraph } from 'embercom/models/common/blocks/paragraph';
import type Checklist from './checklist';
import type Task from './task';

export default class TaskGroup extends Model {
  @attr('number') declare order: number;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
  })
  declare titleBlocks: SyncHasMany<Paragraph>;
  @belongsTo('checklists/checklist', { inverse: 'taskGroups' }) declare checklist: Checklist;
  @hasMany('checklists/task', { inverse: 'taskGroup' }) declare tasks: SyncHasMany<Task>;

  get serializedTitleBlocks() {
    return this.titleBlocks.serialize();
  }

  get orderedTasks() {
    return this.tasks.sortBy('order');
  }

  get nonDeletedTasks() {
    return this.orderedTasks.filter((task) => !task.isDeleted);
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.titleBlocks.any((block) => block.hasDirtyAttributes) ||
      this.tasks.any((task) => task.get('hasUnsavedChanges'))
    );
  }

  @dependentKeyCompat
  get contentHash() {
    return {
      titleBlocks: this.serializedTitleBlocks,
      tasks: this.tasks.map((task) => task.contentHash),
    };
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this._clearLocallyCreatedTasks();
    this.tasks.forEach((task) => task.rollbackAttributes());
  }

  afterSave() {
    this._clearLocallyCreatedTasks();
  }

  _clearLocallyCreatedTasks() {
    this.tasks
      .filter((task) => isNone(task.id))
      .forEach((task) => {
        this.store.unloadRecord(task);
      });
  }
}
