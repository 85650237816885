/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { stepTypes } from 'embercom/models/data/survey/constants';
import { isNone } from '@ember/utils';
import StepValidations from 'embercom/validations/step';
import generateUUID from 'embercom/lib/uuid-generator';
import { inject as service } from '@ember/service';

export default class StepModel extends Model.extend(StepValidations) {
  @service intl;
  @service appService;
  @belongsTo('surveys/survey', { inverse: 'steps' }) survey;
  @hasMany('surveys/question', { inverse: 'step' }) questions;
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' }) blocks;
  @attr('number', { defaultValue: () => stepTypes.question }) stepType;
  @attr('number') order;
  @hasMany('surveys/action', { inverse: 'step' }) actions;
  @attr('string', { defaultValue: () => generateUUID() }) uuid;
  @attr('string') customButtonText;

  get app() {
    return this.appService.app;
  }

  get orderedQuestions() {
    return this.questions.sortBy('order');
  }

  get nonDeletedQuestions() {
    return this.orderedQuestions.filterBy('isDeleted', false);
  }

  get isThankYouType() {
    return this.stepType === stepTypes.thankYou;
  }

  get isIntroType() {
    return this.stepType === stepTypes.intro;
  }

  get isOrdered() {
    return !this.isThankYouType && !this.isIntroType;
  }

  get printOrder() {
    if (this.isThankYouType) {
      // Thank you steps are ordered [-2,-3,-4]
      // This turns that order into [1,2,3]
      return -this.order - 1;
    } else if (this.isIntroType) {
      return '';
    } else {
      return this.order + 1;
    }
  }

  get readableThankYouStepName() {
    let thankYouSteps = this.survey.get('thankYouSteps');
    if (!thankYouSteps || thankYouSteps.length <= 1) {
      return this.intl.t('outbound.surveys.readable-step-name.thank-you');
    } else {
      return this.intl.t('outbound.surveys.readable-step-name.numbered-thank-you', {
        number: this.printOrder,
      });
    }
  }

  get readableName() {
    if (this.isThankYouType) {
      return this.readableThankYouStepName;
    } else if (this.isIntroType) {
      return this.intl.t('outbound.surveys.readable-step-name.intro');
    } else {
      return this.intl.t('outbound.surveys.readable-step-name.numbered-step', {
        printOrder: this.printOrder,
      });
    }
  }

  @dependentKeyCompat
  get invalidButtonText() {
    return this.customButtonText?.length > 0 && this.customButtonText?.trim().length === 0;
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.blocks.any((block) => block.hasDirtyAttributes) ||
      this.questions.any((question) => question.hasUnsavedChanges) ||
      this.actions.any((action) => action.hasUnsavedChanges)
    );
  }

  get serializedBlocks() {
    return this.blocks.serialize();
  }

  get plainTextBlocks() {
    let textBlocks = this.serializedBlocks.filter((block) => block.type !== 'image');
    let paragraphTextBlocks = textBlocks.map((textBlock) => {
      let isHeadingBlock = textBlock.type === 'heading' || textBlock.type === 'subheading';

      if (isHeadingBlock) {
        textBlock.type = 'paragraph';
      }

      return textBlock;
    });

    return paragraphTextBlocks;
  }

  get textLength() {
    return this.blocks.reduce((total, { text }) => {
      if (!text) {
        return total;
      }
      return total + text.trim().length;
    }, 0);
  }

  get paths() {
    return this.survey.get('paths').filter((path) => {
      if (path.stepId) {
        return path.stepId === this.id;
      } else {
        return path.stepUuid === this.uuid;
      }
    });
  }

  get userDefinedBranchingPaths() {
    return this.paths.filter((path) => path.order > 0);
  }

  @dependentKeyCompat
  get inwardPaths() {
    return this.survey.get('paths')?.filter((path) => {
      if (path.nextStepId) {
        return path.nextStepId === this.id;
      } else {
        return path.nextStepUuid === this.uuid;
      }
    });
  }

  get defaultPath() {
    return this.paths.filter((path) => path.predicateGroup.isEmpty).firstObject;
  }

  get defaultNextStep() {
    if (!this.defaultPath) {
      return;
    }

    let defaultPathNextStep = this.survey.get('steps').find((step) => {
      if (this.defaultPath.nextStepId) {
        return this.defaultPath.nextStepId === step.id;
      } else {
        return this.defaultPath.nextStepUuid === step.uuid;
      }
    });
    return defaultPathNextStep;
  }

  get hasNonDefaultPath() {
    let defaultPathOrder = 0;
    return this.paths.some((path) => path.order !== defaultPathOrder);
  }

  get hasPaths() {
    return this.paths.length > 0;
  }

  get hasQuestions() {
    return this.nonDeletedQuestions.length > 0;
  }

  get hasActions() {
    return this.actions.length > 0;
  }

  get contentHash() {
    return {
      stepType: this.stepType,
      blocks: this.serializedBlocks,
      questions: this.questions.map((question) => question.contentHash),
    };
  }

  get isValid() {
    return (
      this.validations.isValid &&
      this.questions.every((question) => question.validations.isValid) &&
      this.actions.every((action) => action.validations.isValid)
    );
  }
  @dependentKeyCompat
  get hasValidInwardPaths() {
    let isFirstOrderedStep = this.order === 0;
    let doesNotRequireInwardsPath = this.isIntroType || isFirstOrderedStep;

    if (doesNotRequireInwardsPath) {
      return true;
    } else {
      return Boolean(this.inwardPaths.length);
    }
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this._clearLocallyCreatedQuestions();
    this._clearLocallyCreatedActions();
    this.questions.forEach((question) => question.rollbackAttributes());
    this.actions.length && this.actions.forEach((action) => action.rollbackAttributes());
  }

  afterSave() {
    this._clearLocallyCreatedQuestions();
  }

  _clearLocallyCreatedQuestions() {
    this.questions
      .filter((question) => isNone(question.id))
      .forEach((question) => {
        this.store.unloadRecord(question);
      });
  }

  _clearLocallyCreatedActions() {
    if (!this.actions.length) {
      return;
    }

    this.actions
      .filter((action) => isNone(action.id))
      .forEach((action) => {
        this.store.unloadRecord(action);
      });
  }
}
