/* RESPONSIBLE TEAM: team-tickets-1 */
import { and, or, equal } from '@ember/object/computed';
import Rule from 'embercom/models/inbox/conversation-rules/rule';

function roundRobinChanged(changedAttributes) {
  return (
    typeof changedAttributes['actionData'] === 'object' &&
    (changedAttributes['actionData'][0]['round_robin'] !== undefined ||
      changedAttributes['actionData'][1]['round_robin'] !== undefined)
  );
}

export default Rule.extend({
  entityString: 'assignment_rule',
  subRouteString: 'assignments',
  allowedRuleActions: ['assign-conversation'],

  isInboxAssignmentRule: equal('type', 'inbox-assignment'),
  isDefaultInboxAssignmentRule: equal('type', 'default-inbox-assignment'),
  isOwnerAssignmentRule: equal('firstAction.type', 'assign-conversation-to-owner'),
  isAssignmentRule: or(
    'isInboxAssignmentRule',
    'isDefaultInboxAssignmentRule',
    'isOwnerAssignmentRule',
  ),
  isTeamAssignmentRule: and('isAssignmentRule', 'firstAction.assignee.isTeam'),
  isRoundRobinAssignmentRule: and(
    'isTeamAssignmentRule',
    'firstAction.data.actionData.round_robin',
  ),
  _buildRoundRobinMetaData(changedAttributes) {
    if (roundRobinChanged(changedAttributes)) {
      return {
        round_robin_from: changedAttributes['actionData'][0]['round_robin'],
        round_robin_to: changedAttributes['actionData'][1]['round_robin'],
      };
    } else {
      let assignByRoundRobinRawData = this.get('firstAction.actionData.round_robin');
      return {
        round_robin_from: assignByRoundRobinRawData,
        round_robin_to: assignByRoundRobinRawData,
      };
    }
  },

  buildMetaDataForAnalytics(isCreate) {
    if (isCreate) {
      return {
        predicates: JSON.stringify(this.predicates),
        assignee_id: this.get('firstAction.actionData.assignee_id'),
        round_robin: this.get('firstAction.assignByRoundRobin'),
      };
    } else {
      return this._buildRoundRobinMetaData(this.get('firstAction.changedAttributes'));
    }
  },
});
