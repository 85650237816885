/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardPanelHeaderConfiguration from '../standard-panel-header-configuration';
import { readOnly, bool } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default StandardPanelHeaderConfiguration.extend({
  contentEditorService: service(),
  errors: readOnly('contentEditorService.ruleset.validations.attrs.goal.errors'),
  isComplete: bool('contentEditorService.ruleset.validations.attrs.goal.isValid').readOnly(),

  init(inputs) {
    inputs.component = 'content-editor/panels/goal/header';
    inputs.title = inputs.title || 'Goal';
    this._super(...arguments);
  },
});
