/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ParentAction, ChildAction } from './action';
import { ComposerPaneType } from 'embercom/objects/inbox/composer-pane';
import type Macro from 'embercom/objects/inbox/macro';
import { MacroType } from 'embercom/objects/inbox/macro';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { type SearchByTypeResponse } from 'embercom/services/quick-search';
import { inject as service } from '@ember/service';
import type Inbox2MacrosSearch from 'embercom/services/inbox2-macros-search';
import { SEARCH_RESULTS_SIZE } from 'embercom/components/inbox2/command-k/home';

const PANE_TO_MACRO_TYPE = {
  [ComposerPaneType.Reply]: MacroType.Reply,
  [ComposerPaneType.Note]: MacroType.Note,
};

interface UseMacroActionContext {
  pane?: ComposerPaneType;
  isStartingConversation?: boolean;
}
export default class UseMacroAction extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare inbox2MacrosSearch: Inbox2MacrosSearch;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.UseMacro];
  }

  id = 'use-macro';
  icon = 'saved-reply' as const;
  paneComponent = 'inbox2/command-k/use-macro/pane';

  async buildTopLevelItems(query: string): Promise<Array<ChildAction>> {
    let results = await this.inbox2MacrosSearch.filterMacros(
      query,
      undefined,
      this.searchableContext,
      SEARCH_RESULTS_SIZE,
    );
    return this.buildResults(results);
  }

  buildResults(searchResults: SearchByTypeResponse, trackingData?: any): Array<ChildAction> {
    let results = searchResults?.results;
    if (!results) {
      return [];
    }

    // Filters out macros with actions for side conversations
    let context = this.context as UseMacroActionContext | undefined;

    if (context && this.commandK.currentActivationContext === 'side-conversation') {
      results = results.filter((result) => {
        let macro = result.data as Macro;
        return macro.actions.length === 0;
      });
    }

    return results.map((result) => {
      return new ChildAction({
        parent: this,
        id: result.data.id.toString(),
        label: result.data.name,
        highlightedLabel: result.highlightedLabel,
        value: result.data,
        optionComponent: 'inbox2/command-k/use-macro/option',
        trackingData,
      });
    });
  }

  get searchableContext(): Array<MacroType> {
    let context = this.context as UseMacroActionContext | undefined;
    if (context) {
      if (context.isStartingConversation) {
        return [MacroType.Opener];
      }
      if (context.pane) {
        return [PANE_TO_MACRO_TYPE[context.pane]];
      }
    }
    return [];
  }
}
