/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { hasMany, attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { inject as service } from '@ember/service';
import KeywordResponseValidations from 'embercom/validations/sms/keyword-response';
import { keywordResponseTypes } from 'embercom/models/data/sms/constants';

export default class KeywordResponse extends Model.extend(KeywordResponseValidations) {
  @service intl;

  @attr('number') keywordType;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [{ text: '', type: 'paragraph', modelKey: 'common/blocks/paragraph' }],
  })
  jsonBlocks;
  @hasMany('sms/keyword', { inverse: 'keywordResponse' }) keywords;

  get isValid() {
    return this.text.length > 0;
  }

  get keywordTypeName() {
    return Object.keys(keywordResponseTypes).find(
      (key) => keywordResponseTypes[key] === this.keywordType,
    );
  }

  get text() {
    return this.jsonBlocks.map((block) => block.text).join('');
  }

  get keywordText() {
    return this.keywords.map((keyword) => keyword.name).join(', ');
  }

  get isDefaultResponse() {
    return this.keywordType === keywordResponseTypes.default;
  }

  get isCustomResponse() {
    return this.keywordType === keywordResponseTypes.custom;
  }

  get isCarrierStopResponse() {
    return this.keywordType === keywordResponseTypes.carrierStop;
  }

  get tooltip() {
    if (this.isDefaultResponse || this.isCarrierStopResponse) {
      return this.intl.t(
        `outbound.settings.sms.response-settings.keyword-response-row-tooltips.${this.keywordTypeName}`,
      );
    } else {
      return null;
    }
  }
}
