/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Model, { attr, belongsTo } from '@ember-data/model';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type ArticleContentReview from './article-content-review';

export default class ArticleContentReviewWrapper extends Model {
  @attr declare articleId: number;
  @attr('string') declare title: string;
  @attr declare liveVersionId: number;
  @attr declare lastVersionId: number;

  @belongsTo('articles/article-content-review')
  declare liveReview?: ArticleContentReview;
  @belongsTo('articles/article-content-review')
  declare lastReview?: ArticleContentReview;

  @tracked selected = false;
  @tracked currentReviewState: 'published' | 'latest-draft' = 'published';

  @action setSelected(isSelected: boolean) {
    this.selected = isSelected;
  }

  get reviewIdForCurrentState() {
    if (this.currentReviewState === 'published') {
      return this.liveVersionId;
    } else {
      return this.lastVersionId;
    }
  }

  get reviewForCurrentState() {
    if (this.currentReviewState === 'published') {
      return this.liveReview;
    } else if (this.liveVersionId !== this.lastVersionId) {
      return this.lastReview;
    } else {
      return undefined;
    }
  }
}
