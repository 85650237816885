/* RESPONSIBLE TEAM: team-customer-data-platform */
import Model, { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';

export default class SalesforceIntegration extends Model {
  @attr('boolean', { defaultValue: false }) isSandbox;
  @attr('string') importStatus;
  @attr('number', { defaultValue: 0 }) salesforceRateLimit;
  @attr('number', { defaultValue: 5000 }) salesforceIntercomMaxRateLimit;
  @attr('number', { defaultValue: 0 }) intercomApiConsumption;
  @attr('number') organizationApiConsumption;
  @attr('number') organizationApiLimit;
  @attr('array') opportunityFields;
  @attr('array') caseFields;
  @attr('array') requiredCaseFields;
  @attr('array') salesforceAccountLists;
  @attr() features;
  @fragmentArray('integrations/salesforce/account-list') accountLists;
  @fragment('integrations/salesforce/settings') settings;
  @attr('array') salesforceUsers;
  @fragmentArray('crm/attribute-mapping') attributeMappings;
  @fragment('crm/attribute-options') attributeOptions;
  @fragmentArray('salesforce/salesforce-fields') salesforceLeadFields;
  @fragmentArray('salesforce/salesforce-fields') salesforceContactFields;
  @fragmentArray('salesforce/salesforce-fields') salesforceAccountFields;
  @fragmentArray('salesforce/salesforce-record-type') salesforceCaseRecordTypes;
  @attr('boolean') shouldMigrate;

  @fragmentArray('integrations/salesforce/identity-mapping-field')
  identityMappingSupportedFields;
  @fragmentArray('integrations/salesforce/identity-mapping-field')
  accountMappingSupportedFields;
  @attr('number') lastAccountMatchDate;
  @attr('number') lastPeopleMatchDate;
}
