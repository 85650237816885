/* RESPONSIBLE TEAM: team-proactive-support */
import { timezoneTypes } from 'embercom/models/data/matching-system/matching-constants';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';
import InboundCustomBotContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/inbound-custom-bot/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';

export default function generateEditModeConfig({ container, customBot }) {
  let intl = container.lookup('service:intl');
  let panelConfiguration = [
    InboundCustomBotContentPanelConfiguration.create({
      container,
      customBot,
    }),
    StandardAudiencePanelConfiguration.create({
      title: intl.t('operator.custom-bot.editor.rules-panel.title'),
      hideMatchBehavior: true,
      audienceEditorConfiguration: new StandardAudienceEditorConfiguration({
        showMatchBehavior: false,
        allowedUserRoles: customBot.allowedUserRoles,
        container,
      }),
      container,
    }),
    StandardSchedulePanelConfiguration.create({
      title: intl.t('operator.custom-bot.editor.schedule-panel.title'),
      timezoneType: timezoneTypes.basedOnAppTimezone,
      container,
    }),
    StandardGoalPanelConfiguration.create({
      title: intl.t('operator.custom-bot.editor.goal-panel.title'),
      introText: intl.t('operator.custom-bot.editor.goal-panel.description'),
      container,
    }),
  ];

  return EditModeConfig.create({
    headerComponentConfigs: [
      StandardEditModeHeaderConfiguration.create({
        container,
        moreDropdownConfig: MoreDropdownConfiguration.create({
          duplicateModalComponent: 'custom-bots/duplicate-modal',
          hideCsvExportOption: true,
          hideTagOption: false,
          container,
        }),
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/workflows/preview-button',
          analyticEvent: {
            place: 'custom_bots',
          },
        }),
        afterSaveRedirectRoute: 'apps.app.automation.workflows-overview',
      }),
    ],
    editorPanelConfigs: panelConfiguration,
    footerComponentConfigs: [],
  });
}
