/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import type Inbox from './inbox';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { NamedIcon } from '../command-k/icons';

export type SpamInboxWireFormat = {
  inbox_type: InboxType.Spam;
  count: number;
};

export default class Spam implements Inbox {
  readonly id = 'spam';
  readonly category = InboxCategory.Shared;
  readonly type = InboxType.Spam;
  readonly count?: number;

  name = 'Spam';

  readonly translationKey = 'inbox.inbox-list.spam';

  icon = new NamedIcon('spam');

  constructor(count: number) {
    this.count = count;
  }

  static deserialize(json: SpamInboxWireFormat): Spam {
    return new Spam(json.count);
  }
}
