/* RESPONSIBLE TEAM: team-frontend-tech */
import Model, { attr } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import Admin from 'embercom/models/admin';

export default class Account extends Model {
  @service declare intl: IntlService;
  @attr('string') declare name: string;
  @attr('string') declare domain: string;
  @attr('string') declare source: string;
  @attr('number') declare ownerId: number;
  @attr('boolean', { defaultValue: false }) declare deleted: boolean;
  @attr('date') declare lastSeenAt: Date;

  get relativeLastSeenAt() {
    let relativeTime = this.intl.formatRelative(this.lastSeenAt);
    return relativeTime === 'a few seconds ago'
      ? this.intl.t('components.accounts.accounts-table.just-now')
      : relativeTime;
  }

  @attr('number') declare matchCount: number;

  @computed('ownerId', 'store')
  get owner() {
    return Admin.peekAndMaybeLoad(this.store, this.ownerId.toString());
  }
}
