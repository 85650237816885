/* RESPONSIBLE TEAM: team-workflows */
import {
  CONVERSATION_STARTED_TARGET,
  CONVERSATION_STARTED_TRIGGER,
  TRIGGERABLE_BOT_TYPE,
  AT_CONVERSATION_START,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import ConversationStartedTargetEditorConfig from 'embercom/objects/visual-builder/configuration/editor/conversation-started-target';
import CustomBotConfig, { type ColumnConfigs } from '../custom-bot';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class ConversationStartedCustomBotConfig extends CustomBotConfig {
  constructor(public customBot?: any) {
    super();
  }

  get target(): BotConfigTarget {
    return CONVERSATION_STARTED_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return CONVERSATION_STARTED_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.conversation-started.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.conversation-started.description';
  }

  get icon(): InterfaceIconName {
    return 'manual-message';
  }

  get editorConfig() {
    return new this.EditorConfigClass({
      targetChannels: this.customBot?.targetChannels,
      areStepPaywallsActive: this.areStepPaywallsActive,
    });
  }

  get EditorConfigClass() {
    return ConversationStartedTargetEditorConfig;
  }

  get columnKeys(): (keyof ColumnConfigs)[] {
    let baseColumns: (keyof ColumnConfigs)[] = super.columnKeys;

    return [...baseColumns.slice(0, 2), 'channels', ...baseColumns.slice(2)];
  }

  get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get triggerContextType() {
    return AT_CONVERSATION_START;
  }
}
