/* RESPONSIBLE TEAM: team-data-interop */

export const AVAILABLE_ACTION_COLUMNS = [
  {
    valuePath: 'name',
    isSortable: true,
  },
  {
    valuePath: 'usage',
    isSortable: true,
  },
  {
    valuePath: 'sentCount',
    isSortable: true,
    type: 'number',
    hasTooltip: true,
  },
  {
    valuePath: 'resolvedCount',
    isSortable: true,
    type: 'number',
    hasTooltip: true,
  },
  {
    valuePath: 'state',
    isSortable: true,
    type: 'stamp',
  },
  {
    valuePath: 'createdBy',
    type: 'avatar-with-text',
  },
  {
    valuePath: 'updatedBy',
    type: 'avatar-with-text',
  },
  {
    valuePath: 'updatedAt',
    isSortable: true,
  },
];

export const DEFAULT_ACTION_COLUMN_NAMES = ['usage', 'sentCount', 'resolvedCount', 'state'];

export const DEFAULT_ACTION_COLUMNS = AVAILABLE_ACTION_COLUMNS.filter(
  (column) => DEFAULT_ACTION_COLUMN_NAMES.includes(column.valuePath) || column.valuePath === 'name',
);
