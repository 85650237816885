/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';

interface ParticipantBlockedWireFormat {
  renderable_type: RenderableType;
}

export default class ParticipantBlocked implements RenderableData {
  readonly renderableType = RenderableType.ParticipantBlocked;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  static deserialize(_: ParticipantBlockedWireFormat): ParticipantBlocked {
    return new ParticipantBlocked();
  }
}
