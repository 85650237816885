/* RESPONSIBLE TEAM: team-proactive-support */
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';
import ComponentConfiguration from 'embercom/objects/component-configuration';

export default class StatsConfiguration extends ComponentConfiguration {
  verb = undefined;

  constructor(inputs) {
    super(inputs);
    assert('You must provide a verb for displaying stats', isPresent(inputs.verb));

    this.verb = inputs.verb;
  }
}
