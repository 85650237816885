/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import { empty, and, or } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import { validator, buildValidations } from 'ember-cp-validations';
import { computed } from '@ember/object';

const Validations = buildValidations({
  title: validator('presence', {
    presence: true,
    messageKey: 'articles.settings.redesign.validations.link-label-empty',
  }),
  url: [
    validator('presence', {
      presence: true,
      messageKey: 'articles.settings.redesign.validations.link-url-empty',
    }),
    validator('format', {
      regex: /^(https?:\/\/|mailto:)/,
      messageKey: 'articles.settings.redesign.validations.link-url-format',
    }),
  ],
});

export default Fragment.extend(Validations, {
  linkId: attr('number'),
  title: attr('string'),
  url: attr('string'),
  sortOrder: attr('number'),
  siteLinkGroupId: attr('number'),

  dirtyAttributes: computed('hasDirtyAttributes', 'title', function () {
    if (!this.hasDirtyAttributes) {
      return [];
    }

    return Object.keys(this.changedAttributes());
  }),

  urlForEditing: computed('url', {
    get() {
      return this.url;
    },
    set(_, value) {
      //fill the prefix https:// with there is no schema
      //check value.length > 6 is a workaround for users who are typing "http://"
      if (
        value &&
        value.length > 6 &&
        !value.trim().startsWith('http') &&
        !value.trim().startsWith('mailto')
      ) {
        value = `https://${value.trim()}`;
      }
      this.set('url', value);
      return value;
    },
  }),

  preview() {
    return {
      title: this.title,
      url: this.url,
    };
  },

  hasNoTitle: empty('title'),
  hasNoUrl: empty('url'),
  hasNoContent: and('hasNoTitle', 'hasNoUrl'),
  isInvalid: or('hasNoTitle', 'hasNoUrl'),
});
