/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class ActionResponse extends Fragment {
  @attr() declare body: any;
  @attr() declare rawBody: any;
  @attr('number') declare status: number;
  @attr('string') declare returnCode: string;
  @attr('string') declare humanReadableError: string;
  @attr('boolean') declare success: boolean;
}
