/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class SurveySharingHeaderConfiguration extends StandardPanelHeaderConfiguration {
  @service contentEditorService;

  get errors() {
    return [];
  }

  get isComplete() {
    return isEmpty(this.errors);
  }

  init(inputs) {
    inputs.component = 'content-editor/panels/custom/survey-sharing/header';
    inputs.title = 'Additional ways to share your survey';
    super.init(...arguments);
  }
}
