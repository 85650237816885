/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { assert } from '@ember/debug';
import { isArray } from '@ember/array';

export default EmberObject.extend({
  headerComponentConfigs: undefined,
  tabsConfiguration: undefined,
  activeViewConfiguration: undefined,
  footerComponentConfigs: undefined,

  init(inputs) {
    this._super(...arguments);
    assert(
      'An array must be passed for the header component configs',
      isArray(inputs.headerComponentConfigs),
    );
    assert('A tabs configuration must be provided', this.tabsConfiguration);
    assert('An active view configuration must be provided', this.activeViewConfiguration);
    assert(
      'An array must be passed for the footer component configs',
      isArray(inputs.footerComponentConfigs),
    );

    inputs.headerComponentConfigs.setEach('parentConfiguration', this);
    inputs.tabsConfiguration.parentConfiguration = this;
    inputs.activeViewConfiguration.parentConfiguration = this;
    inputs.footerComponentConfigs.setEach('parentConfiguration', this);

    this.setProperties({
      headerComponentConfigs: inputs.headerComponentConfigs,
      footerComponentConfigs: inputs.footerComponentConfigs,
    });
  },
});
