/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from 'embercom/objects/component-configuration';

export default class InserterConfiguration {
  objectType = undefined;
  requiredFeatures = [];
  get objectData() {
    return {};
  }
  componentConfig = new ComponentConfiguration({ component: 'path/to/component/file' });
  loadingComponentConfig = new ComponentConfiguration({ component: 'path/to/component/file' });

  isTooltipDisabled(app) {
    return true;
  }

  disabledTooltipContent() {
    return '';
  }

  get placeholderGraphNodeDataObject() {
    return {
      isNewlyInserted: true,
      inserterConfig: this,
    };
  }

  hasAccessToRequiredFeatures(app) {
    return this.requiredFeatures.every((requiredFeature) => {
      return app.canUseFeature(requiredFeature);
    });
  }

  canInsertNode({ app, _dataObject }) {
    return this.hasAccessToRequiredFeatures(app);
  }
}
