/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';
import { post, get } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SunshineConfiguration extends Model {
  @service declare appService: any;

  @attr('string') declare sunshineAppId: string;
  @attr('string') declare apiKeyId: string;
  @attr('string') declare apiSecret: string;
  @attr('number') declare workflowRulesetId: number;

  @attr declare supportedIntegrations: Array<any>;

  get supportedChannelIntegrationIds() {
    return this.supportedIntegrations.map((integration) => integration.channel_integration_id);
  }

  async addFinToChannel(channelId: string, brandId: string) {
    let response = await post(
      `/ember/standalone/zendesk_configurations/${this.id}/add_fin_to_sunshine_channel`,
      {
        channel_integration_id: channelId,
        brand_id: brandId,
      },
    );

    this.supportedIntegrations.pushObject(response);
  }

  async removeFinFromChannel(channelId: string) {
    await post(
      `/ember/standalone/zendesk_configurations/${this.id}/remove_fin_from_sunshine_channel`,
      {
        channel_integration_id: channelId,
      },
    );

    this.supportedIntegrations = this.supportedIntegrations.filter((integration) => {
      return integration.channel_integration_id !== channelId;
    });
  }

  @action async getUsageCounts(): Promise<{
    involvement_count: number;
    last_active?: string;
  }> {
    return get(`/ember/standalone/zendesk_configurations/${this.id}/sunshine_usage_counts`, {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
    });
  }

  revoke() {
    this.sunshineAppId = '';
    this.apiKeyId = '';
    this.apiSecret = '';
    this.workflowRulesetId = -1;
    this.supportedIntegrations = [];
  }
}
