/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';

export default class BotIntroHeaderConfiguration extends StandardPanelHeaderConfiguration {
  @service contentEditorService;
  @service intl;
  @service appService;

  get botIntroErrors() {
    return this.contentEditorService.activeObject.validations.attrs.botIntroLocalizations.errors;
  }

  get errors() {
    if (this.botIntroErrors.length > 1) {
      return [{ message: this.intl.t('ai-agent.profiles.bot-intro.finish') }];
    } else {
      return this.botIntroErrors;
    }
  }

  get isComplete() {
    return isEmpty(this.errors);
  }

  init(inputs) {
    inputs.component = 'content-editor/panels/custom/ai-agent-profile/bot-intro/header';
    inputs.title = this.intl.t('ai-agent.profiles.config-generator.bot-intro');
    super.init(...arguments);
  }
}
