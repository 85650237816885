/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { dependentKeyCompat } from '@ember/object/compat';
import { getOwner } from '@ember/application';
import checklistEditorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/checklist';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import Admin from 'embercom/models/admin';
import { isNone, isPresent } from '@ember/utils';

const UNASSIGNED_ID = 0;

import { type Block } from 'embercom/models/common/blocks/block';
import type TaskGroup from './task-group';
import { type EmberCPValidationsCompat } from 'embercom/validations/typescript/ember-cp-validations-compat';
import { validateChecklist } from 'embercom/validations/typescript/checklists/checklist';
import { defaultCompletionScreenBlocks } from '../data/checklists/constants';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';

type SenderType = ValueOf<typeof senderTypes>;

export enum DeliveryOption {
  FULL,
  SNIPPET,
  BADGE,
}

export enum CompletionActionTypes {
  CLOSE_COMPLETION_SCREEN,
}

export default class Checklist extends Model implements EmberCPValidationsCompat {
  @attr('number') declare senderId: number;
  @attr('number', { defaultValue: () => senderTypes.admin }) declare senderType: SenderType;
  @attr('number', { defaultValue: () => DeliveryOption.FULL })
  declare deliveryOption: DeliveryOption;
  @attr('string') declare completionScreenButtonText: string | null;
  @attr('boolean') declare showCompletionScreen: boolean;
  @attr('number', { defaultValue: () => CompletionActionTypes.CLOSE_COMPLETION_SCREEN })
  declare completionScreenActionType: CompletionActionTypes;
  @attr('boolean') declare showConfetti: boolean;

  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare titleBlocks: SyncHasMany<Block>;
  @attr('number') declare reminderSpacingPeriod: number;
  @attr('number') declare maxReminderCount: number;
  @attr('number') declare stopReminderAfterPeriod: number;

  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
  })
  declare subtitleBlocks: SyncHasMany<Block>;

  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare completionScreenBlocks: SyncHasMany<Block>;

  @hasMany('checklists/task-group', { inverse: 'checklist' })
  declare taskGroups: SyncHasMany<TaskGroup>;

  @dependentKeyCompat
  get validations() {
    return validateChecklist(this);
  }

  get hasTitle() {
    return isPresent(this.serializedTitleBlocks.firstObject?.text);
  }

  get hasSubtitle() {
    return isPresent(this.serializedSubtitleBlocks.firstObject?.text);
  }

  get sender(): Admin | undefined {
    if (this.senderId && this.senderId !== UNASSIGNED_ID) {
      return Admin.peekAndMaybeLoad(this.store, String(this.senderId));
    }
    return undefined;
  }

  get senderName() {
    return this.sender?.firstNameOrNameOrEmail ?? 'Unknown';
  }

  get showSender() {
    return this.senderId !== UNASSIGNED_ID;
  }

  get serializedTitleBlocks() {
    return this.titleBlocks.serialize();
  }

  get serializedSubtitleBlocks() {
    return this.subtitleBlocks.serialize();
  }

  get serializedCompletionScreenBlocks() {
    return this.completionScreenBlocks.serialize();
  }

  get firstTaskGroup() {
    return this.taskGroups.firstObject as TaskGroup;
  }

  get tasks() {
    return this.taskGroups
      .toArray()
      .flatMap((taskGroup: TaskGroup) => taskGroup.nonDeletedTasks.toArray());
  }

  get totalEstimatedTime() {
    let times = this.tasks.map((task) => task.estimatedMinutes);
    return times.reduce((sum, val) => sum + val, 0);
  }

  get hasCompletionScreenContent() {
    return isPresent(this.serializedCompletionScreenBlocks.firstObject?.text);
  }

  get hasCompletionScreenButtonText() {
    return isPresent(this.completionScreenButtonText);
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.titleBlocks.any((block) => block.hasDirtyAttributes) ||
      this.taskGroups.any((taskGroup) => taskGroup.get('hasUnsavedChanges'))
    );
  }

  @dependentKeyCompat
  get contentHash() {
    return {
      titleBlocks: this.serializedTitleBlocks,
      taskGroups: this.taskGroups.map((taskGroup) => taskGroup.contentHash),
    };
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this._clearLocallyCreatedTaskGroups();
    this.taskGroups.forEach((taskGroup) => taskGroup.rollbackAttributes());
  }

  editorConfig() {
    let container = getOwner(this);
    return checklistEditorConfigGenerator({ container, checklist: this });
  }

  afterSave() {
    this._clearLocallyCreatedTaskGroups();
    this.taskGroups.forEach((taskGroup) => taskGroup._clearLocallyCreatedTasks());
  }

  _clearLocallyCreatedTaskGroups() {
    this.taskGroups
      .filter((taskGroup) => isNone(taskGroup.id))
      .forEach((taskGroup) => {
        this.store.unloadRecord(taskGroup);
      });
  }

  initCompletionScreen() {
    let blockFragments: Array<Block> = defaultCompletionScreenBlocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );

    this.set('completionScreenBlocks', blockFragments);
  }

  resetCompletionScreen() {
    this.completionScreenBlocks.clear();
    this.completionScreenButtonText = null;
  }
}

export function getChecklistUrlParam(hasOtherParams: boolean, rulesetId: any) {
  return `${hasOtherParams ? '&' : '?'}intercom_checklist_id=${rulesetId}`;
}

export function generateShareableUrl(url: string, rulesetId: any) {
  if (isPresent(url)) {
    let hasParams = url.indexOf('?') > -1;
    let checklistParam = getChecklistUrlParam(hasParams, rulesetId);
    url = `${url}${checklistParam}`;
  }
  return url;
}
