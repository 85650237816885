/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type NewsItemModel from 'embercom/models/news/item';

export default class extends RulesetLink {
  @belongsTo('news/item', { async: false }) declare object: NewsItemModel;
  objectType = objectTypes.newsItem;
}
