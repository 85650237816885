/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { and } from '@ember/object/computed';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import {
  StandardAudienceSummaryConfiguration,
  StandardGoalSummaryConfiguration,
  StandardScheduleSummaryConfiguration,
} from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/email/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import emailLowEngagementData from 'embercom/lib/outbound/email-low-engagement-data';

export default function generateViewModeConfig(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');
  let engagementWarning = () => {
    let contentEditorService = container.lookup('service:content-editor-service');
    let getStatsByKey = (key) =>
      contentEditorService.activeRulesetLink.objectStats.findBy('key', key);

    let openedEmailsCount = getStatsByKey(statisticKeys.opens)?.value;
    let sentCount = getStatsByKey(statisticKeys.receipts)?.value;
    let subaggregations = getStatsByKey(statisticKeys.emailFailures)?.subaggregations;
    let failureCalculations = emailLowEngagementData(
      contentEditorService.activeRulesetLink.wentLiveAt,
      sentCount,
      openedEmailsCount,
      subaggregations,
    );
    let hasLowEngagement = Object.keys(failureCalculations).length;
    if (hasLowEngagement) {
      let intercomEventService = container.lookup('service:intercom-event-service');
      intercomEventService.trackAnalyticsEvent({
        action: 'flagged',
        object: 'email',
        place: 'email_issues_tab',
        flagged_property: 'low_engagement_stats',
        content_id: contentEditorService.activeRulesetLink.id,
        low_engagement: hasLowEngagement,
        low_engagement_types: Object.keys(failureCalculations),
      });
    }
    if (hasLowEngagement) {
      return true;
    }
    return false;
  };

  return ViewModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesViewModeHeaderConfiguration.create({
      container,
      moreDropdownConfig: MoreDropdownConfiguration.create({
        hideDuplicateOption: true,
        hideCsvExportOption: false,
        hideTagOption: true,
        hideDeleteOption: true,
        hideVersionsOption: true,
        container,
      }),
    }),
    headerComponentConfigs: [StandardViewModeHeaderConfiguration.create({ container })],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: intl.t('outbound.email.email'),
          icon: 'email',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.sent'),
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.opened'),
          view: 'opens',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.clicked'),
          view: 'clicks',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.replied'),
          view: 'replies',
          container,
        }),
        StandardStatTabConfiguration.extend({
          isVisible: and('contentEditorService.ruleset.hasGoal', 'isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.goal'),
          view: 'goals',
          container,
        }),
        StandardStatTabConfiguration.extend({
          showEngagementWarning: engagementWarning(),
        }).create({
          label: intl.t('outbound.stats-system.tabs.issues'),
          view: 'failures',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        ContentSummaryConfiguration.create({ container }),
        StandardAudienceSummaryConfiguration.create({
          container,
          showImplicitPredicates: true,
        }),
        StandardScheduleSummaryConfiguration.create({
          title: 'Frequency and scheduling',
          container,
        }),
        StandardGoalSummaryConfiguration.create({ container }),
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          noun: 'send',
          verb: 'received',
          emptyStateTitle: "No one's been sent your email yet",
          emptyStateDescription: ' ',
          container,
        }),
      ],
      opens: [
        StandardStatListConfiguration.create({ stat: 'open', hideCreatedVia: true, container }),
      ],
      clicks: [StandardStatListConfiguration.create({ stat: 'click', container })],
      replies: [StandardStatListConfiguration.create({ stat: 'reply', container })],
      goals: [
        StandardStatListConfiguration.create({
          filters: [
            { text: 'Hit the goal after opening', value: stats.open },
            { text: 'Hit the goal after clicking', value: stats.click },
            { text: 'Hit the goal after replying', value: stats.reply },
          ],
          stat: 'goalSuccess',
          container,
        }),
      ],
      failures: [StandardStatListConfiguration.create({ stat: 'failure', container })],
    }),
    footerComponentConfigs: [],
  });
}
