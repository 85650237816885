/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { type Block } from '@intercom/interblocks.ts';

interface SideConversationReplyWireFormat {
  blocks: Array<object>;
  admin_summary: AdminSummaryWireFormat;
  from_email: string;
  participants_count: number;
  side_conversation_id: number;
}

export default class SideConversationReply implements RenderableData {
  renderableType: RenderableType = RenderableType.SideConversationReply;
  readonly blocks: Array<Block>;
  readonly adminSummary: AdminSummary;
  readonly fromEmail: string;
  readonly participantsCount: number;
  readonly sideConversationId: number;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(
    blocks: Array<object>,
    adminSummary: AdminSummary,
    fromEmail: string,
    participantsCount: number,
    sideConversationId: number,
  ) {
    this.blocks = blocks.map((block) => block as Block);
    this.adminSummary = adminSummary;
    this.fromEmail = fromEmail;
    this.participantsCount = participantsCount;
    this.sideConversationId = sideConversationId;
  }

  static deserialize(json: SideConversationReplyWireFormat): SideConversationReply {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);

    return new SideConversationReply(
      json.blocks,
      adminSummary,
      json.from_email,
      json.participants_count,
      json.side_conversation_id,
    );
  }
}
