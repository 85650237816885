/* RESPONSIBLE TEAM: team-knowledge-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';

export default Model.extend({
  siteProvider: attr('string'),
  jobStatus: attr('string'),
  siteUrl: attr('string'),
  job_finished_at: attr('date'),
});
