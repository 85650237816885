/* RESPONSIBLE TEAM: team-proactive-support */
import StandardAudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import { and, union, filter } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default StandardAudienceHeaderConfiguration.extend({
  _recurringScheduleErrors: filter(
    '_validations.attrs.recurringSchedule.errors',
    (error) => error.type === 'attribute-based-schedule-day-offset',
  ),
  errors: union(
    '_predicateGroupErrors',
    '_userRolePredicateErrors',
    '_clientPredicateGroupErrors',
    '_validations.attrs.isSendable.errors',
    '_recurringScheduleErrors',
  ),
  isComplete: and(
    '_validations.attrs.predicateGroup.isValid',
    '_validations.attrs.userRolePredicate.isValid',
    '_validations.attrs.clientPredicateGroup.isValid',
    '_validations.attrs.isSendable.isValid',
    '_validations.attrs.recurringSchedule.isValid',
  ),
  intl: service(),
  init(inputs) {
    this._super(...arguments);
    this.component = 'content-editor/panels/audience/content/shareable-panel/header';
    this.title = this.intl.t('outbound.content-editor.panels.checklist-sharing.rules');
  },
});
