/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { fragment } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type HeaderStyle from '../styles/header';
import type Subheader from './subheader';

export default class Header extends Fragment {
  @fragment('customization-options/global-options/subheader') declare subheader: Subheader;
  @fragment('customization-options/styles/header') declare style: HeaderStyle;
}
