/* RESPONSIBLE TEAM: team-frontend-tech */
export function navigatorInfo() {
  try {
    return {
      'device.cpu_cores': navigator.hardwareConcurrency,
      'device.connection_type': navigator.connection?.effectiveType,
      'device.downlink': navigator.connection?.downlink,
      'device.memory': navigator.deviceMemory,
      'device.rtt': navigator.connection?.rtt,
      'device.online': navigator.onLine,
      'device.language': navigator.language,
      'device.prefers_reduced_data': navigator.connection?.saveData,
      'device.prefers_color_scheme_dark': window.matchMedia('(prefers-color-scheme: dark)').matches,
      'device.prefers_color_scheme_light': window.matchMedia('(prefers-color-scheme: light)')
        .matches,
      'device.prefers_contrast_more': window.matchMedia('(prefers-contrast: more)').matches,
      'device.prefers_contrast_less': window.matchMedia('(prefers-contrast: less)').matches,
      'device.prefers_contrast_no_preference': window.matchMedia(
        '(prefers-contrast: no-preference)',
      ).matches,
      'device.prefers_reduced_motion': window.matchMedia('(prefers-reduced-motion: reduce)')
        .matches,
      'device.prefers_reduced_transparency': window.matchMedia(
        '(prefers-reduced-transparency: reduce)',
      ).matches,
      'device.pointer_fine': window.matchMedia('(pointer: fine)').matches,
      'device.pointer_coarse': window.matchMedia('(pointer: coarse)').matches,
      'device.pointer_none': window.matchMedia('(pointer: none)').matches,
      'device.viewport_height': window.innerHeight,
      'device.viewport_width': window.innerWidth,
      'device.screen_height': window.screen.height,
      'device.screen_width': window.screen.width,
      'device.screen_color_depth': window.screen.colorDepth,
      'device.screen_pixel_depth': window.screen.pixelDepth,
    };
  } catch {
    // Do nothing, navigator could be a dead object
  }
}
