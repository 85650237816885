/* RESPONSIBLE TEAM: team-help-desk-experience */
export enum AddressSource {
  MANUALLY_ADDED = 'manually_added',
  FORWARDING_ADDRESS = 'forwarding_address',
  AUTOMATICALLY_ADDED = 'automatically_added',
  FORWARDING_EMAIL_PROCESSOR = 'forwarding_email_processor',
}

export interface CompanyEmailAddressWireFormat {
  id: number;
  email_address: string;
  source: AddressSource;
  deleted: boolean;
  created_at: string;
  updated_at: string;
}

export default class CompanyEmailAddress {
  readonly id: number;
  readonly emailAddress: string;
  readonly source: AddressSource;
  readonly deleted: boolean;
  readonly createdAt: string;
  readonly updatedAt: string;

  constructor(
    id: number,
    emailAddress: string,
    source: AddressSource,
    deleted: boolean,
    createdAt: string,
    updatedAt: string,
  ) {
    this.id = id;
    this.emailAddress = emailAddress;
    this.source = source;
    this.deleted = deleted;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static deserialize(json: CompanyEmailAddressWireFormat): CompanyEmailAddress {
    return new CompanyEmailAddress(
      json.id,
      json.email_address,
      json.source,
      json.deleted,
      json.created_at,
      json.updated_at,
    );
  }
}
