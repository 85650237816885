/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, readOnly, sort, match, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { changeGate } from '@intercom/pulse/lib/computed-properties';
import intercomMessengerColors from '@intercom/intercom-messenger-colors';
import Fragment from 'ember-data-model-fragments/fragment';
import { isRtlLocale } from 'embercom/lib/locale';
import RouteRegexes from 'embercom/lib/route-regexes';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

function renderEmojiWithLocaleText(emoji, text) {
  return isRtlLocale() ? `${text} ${emoji}` : `${emoji} ${text}`;
}

export default Fragment.extend({
  appService: service(),
  intl: service(),
  router: service(),
  messengerSettingsTargetUserProvider: service(),
  messengerSettingsService: service(),

  appPrimaryColor: attr(),
  appSecondaryColor: attr(),
  logoURL: attr(),
  locale: attr(),
  backgroundPatternNumber: attr(),
  greetingText: attr(),
  aboutUsText: attr(),
  messengerResponseTimeKey: attr(),
  home: attr(),
  slotGroups: attr(),
  showWeRunOnIntercom: attr(),
  inboundConversationsEnabledForUsers: attr(),
  inboundConversationsEnabledForVisitors: attr(),
  preventMultipleInboundConversationEnabledForUsers: attr(),
  preventMultipleInboundConversationEnabledForVisitors: attr(),
  requireSearchBrowseForUsers: attr(),
  requireSearchBrowseForVisitors: attr(),
  activeTeammates: attr(),
  launcherVisibleForUsers: attr(),
  launcherVisibleForVisitors: attr(),
  launcherVisibleForMobileUsers: attr(),
  alignment: attr(),
  horizontalPadding: attr(),
  verticalPadding: attr(),
  frequency: attr(),
  accessToTeammateEnabled: attr(),
  spaces: attr(),
  showAvatars: attr(),
  fadeToWhite: attr(),
  gradientFirstColor: attr(),
  gradientSecondColor: attr(),
  gradientThirdColor: attr(),
  textColor: attr(),
  backgroundType: attr(),
  backgroundImageUrl: attr(),
  isEditingMessenger: match('router.currentRouteName', RouteRegexes.newSettings.messenger.root),
  isMobilePreview: alias('messengerSettingsService.isMobilePreview'),
  appColors: computed(
    'appPrimaryColor',
    'appSecondaryColor',
    'isEditingMessenger',
    'isMobilePreview',
    function () {
      return intercomMessengerColors({
        primaryColor: this.appPrimaryColor,
        secondaryColor: this.appSecondaryColor,
        darkenAmount: 20,
        contrastCheckMethod: this.isMobilePreview ? 'contrast-ratio-4.5' : 'luminosity',
        pureBlackActionColors: this.isEditingMessenger,
      });
    },
  ),
  teammateStartConversationButtonText: attr(),
  finStartConversationButtonText: attr(),
  setExpectations: attr(),
  showConversationBackground: attr(),
  conversationalBackgroundType: attr(),
  backgroundSolidColor: attr(),
  brandIdentityLogoId: attr(),
  brandIdentityLogoUrl: attr(),
  conversationalMessengerIdentity: attr(),
  privacyPolicyNoticeEnabled: attr('boolean'),
  defaultPrivacyPolicyNoticeContent: attr(),

  isFinPreview: computed(
    'messengerSettingsService',
    'messengerSettingsService.navigation.selectedTab',
    'setExpectations',
    function () {
      return this.messengerSettingsService.selectedTab === 'fin' && this.setExpectations === 'bot';
    },
  ),

  startConversationButtonText: computed(
    'messengerSettingsService',
    'messengerSettingsService.navigation.selectedTab',
    'teammateStartConversationButtonText',
    'finStartConversationButtonText',
    'setExpectations',
    function () {
      if (this.messengerSettingsService.selectedTab === 'fin' && this.setExpectations === 'bot') {
        return this.finStartConversationButtonText;
      }

      return this.teammateStartConversationButtonText;
    },
  ),

  operatorResponseForEmail: computed('intl.locale', 'locale', function () {
    return renderEmojiWithLocaleText(
      '✉️',
      this.intl.t('components.messenger-preview.customer-email'),
    );
  }),

  operatorResponseForExpectation: computed('intl.locale', 'locale', function () {
    let text = this.intl.t(this.messengerResponseTimeKey, { locale: this.locale });
    return renderEmojiWithLocaleText('🕒', text);
  }),

  cardAssets: changeGate(
    'appColors.primary_type',
    function () {
      switch (this.get('appColors.primary_type')) {
        case 'light':
          return {
            nextIconUrl: assetUrl('/assets/images/messenger-cards/next-icon-black.png'),
            nextIconUrl2x: assetUrl('/assets/images/messenger-cards/next-icon-black@2x.png'),
          };
        default:
          return {
            nextIconUrl: assetUrl('/assets/images/messenger-cards/next-icon.png'),
            nextIconUrl2x: assetUrl('/assets/images/messenger-cards/next-icon@2x.png'),
          };
      }
    },
    { sync: true },
  ),
  isPrimaryColorLight: equal('appColors.primary_type', 'light'),
  isUpfrontEmailCollectionEnabled: computed('frequency', function () {
    return this.frequency !== 'never';
  }),
  hasUnknownResponseTime: equal(
    'messengerResponseTimeKey',
    'conversation_card_unknown_response_time',
  ),

  operator: readOnly('appService.app.operatorBot'),
  appName: readOnly('appService.app.name'),
  brandName: attr(),
  allTeammates: readOnly('appService.app.humanAdmins'),
  teammatesSorting: ['has_photo_avatar:desc'],
  sortedTeammates: sort('allTeammates', 'teammatesSorting'),
  sampleTeammates: computed('activeTeammates', 'teammates.[]', function () {
    return isPresent(this.activeTeammates)
      ? this.activeTeammates
      : this.sortedTeammates.slice(0, 3);
  }),
  m5sampleTeammates: computed('teammates.[]', function () {
    return isPresent(this.activeTeammates) && this.activeTeammates.length >= 3
      ? this.activeTeammates.slice(0, 3)
      : this.sortedTeammates.slice(0, 3);
  }),
  hasGradient: computed('gradientFirstColor', 'gradientSecondColor', function () {
    return (
      this.backgroundType === 'colors' && !!this?.gradientFirstColor && !!this?.gradientSecondColor
    );
  }),
  changeHeaderColors: computed(
    'textColor',
    'showBackgroundImage',
    'hasGradient',
    'isEditingMessenger',
    function () {
      return this.isEditingMessenger && (this.showBackgroundImage || this.hasGradient);
    },
  ),
  greetingTextColor: computed(
    'textColor',
    'appColors.header_text_color',
    'isEditingMessenger',
    function () {
      if (this.changeHeaderColors) {
        switch (this.textColor) {
          case 'dark':
            return '#00000066;';
          case 'light':
            return '#ffffff99';
          default:
            return this.appColors.greeting_text_color;
        }
      }
      return this.appColors.greeting_text_color;
    },
  ),
  introductionTextColor: computed(
    'textColor',
    'appColors.introduction_text_color',
    'isEditingMessenger',
    function () {
      if (this.changeHeaderColors) {
        switch (this.textColor) {
          case 'dark':
            return '#000000cc';
          case 'light':
            return '#ffffff';
          default:
            return this.appColors.introduction_text_color;
        }
      }
      return this.appColors.introduction_text_color;
    },
  ),
  primaryColorLabelBackground: computed('appPrimaryColor', function () {
    return intercomMessengerColors.tinycolor(this.appPrimaryColor).setAlpha(0.1).toRgbString();
  }),
  showBackgroundImage: computed('backgroundType', 'backgroundImageUrl', function () {
    return this.backgroundType === 'image' && !!this.backgroundImageUrl;
  }),
  showTwoColorsGradient: computed(
    'backgroundType',
    'gradientFirstColor',
    'gradientSecondColor',
    function () {
      return (
        this.backgroundType === 'colors' && !!this.gradientFirstColor && !!this.gradientSecondColor
      );
    },
  ),
  showTreeColorsGradient: computed(
    'showTwoColorsGradient',
    'gradientFirstColor',
    'gradientSecondColor',
    'gradientThirdColor',
    function () {
      return (
        this.backgroundType === 'colors' &&
        !!this.gradientFirstColor &&
        !!this.gradientSecondColor &&
        !!this.gradientThirdColor
      );
    },
  ),
});
