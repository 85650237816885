/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import ajax from 'embercom/lib/ajax';

const ConversationRatingsSettings = EmberObject.extend({
  canCreateConversationRatings: false,
  excludeLeadsFromConversationRatings: false,
});

ConversationRatingsSettings.reopenClass({
  async fetch(appId) {
    let res = await ajax({
      url: `/ember/conversation_ratings_settings/${appId}.json`,
      type: 'GET',
    });

    return ConversationRatingsSettings.create({
      canCreateConversationRatings: res.can_create_conversation_ratings,
      excludeLeadsFromConversationRatings: res.exclude_leads_from_conversation_ratings,
    });
  },
});

export default ConversationRatingsSettings;
