/* RESPONSIBLE TEAM: team-help-desk-experience */
import UserStateChange from 'embercom/objects/inbox/renderable/state-changes/user-state-change';
import TeammateStateChange from 'embercom/objects/inbox/renderable/state-changes/teammate-state-change';
import WorkflowStateChange from 'embercom/objects/inbox/renderable/state-changes/workflow-state-change';
import { StateChangingEntity } from './state-change-enums';
import { type DeserializableRenderableData } from 'embercom/objects/inbox/renderable-data';
import BotStateChange from 'embercom/objects/inbox/renderable/state-changes/bot-state-change';

export const RenderableStateChangeMapping: Readonly<
  Record<StateChangingEntity, DeserializableRenderableData>
> = Object.freeze({
  [StateChangingEntity.User]: UserStateChange,
  [StateChangingEntity.Teammate]: TeammateStateChange,
  [StateChangingEntity.Workflow]: WorkflowStateChange,
  [StateChangingEntity.Bot]: BotStateChange,
});

export type StateChange =
  | UserStateChange
  | TeammateStateChange
  | WorkflowStateChange
  | BotStateChange;
