/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from 'embercom/objects/component-configuration';
import { assert } from '@ember/debug';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';
// eslint-disable-next-line no-restricted-imports
export default class StandardLoadingComponentConfiguration extends ComponentConfiguration {
  icon = undefined;
  title = undefined;
  objectType = undefined;
  objectName = undefined;
  readableObjectNames = undefined;

  get objectLabel() {
    return this.objectName;
  }

  constructor(params = {}) {
    super({ component: 'series/node/graph/loading' });
    this.icon = params.icon;
    this.title = params.title;
    this.objectType = params.objectType;

    this.readableObjectNames = humanReadableObjectNames[this.objectType];

    this.objectName = params.objectName || this.readableObjectNames;

    assert('You must specify the icon', this.icon);
    assert('You must specify the title', this.title);
  }
}
