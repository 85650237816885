/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Block from './block';

export default Block.extend({
  type: attr('string'),
  text: attr('string'),
  url: attr('string'),
  title: attr('string'),
  description: attr('string'),
  linkType: attr('string'),
  siteName: attr('string'),
  author: attr(),
  image: attr(),
  createdAt: attr('string'),
  updatedAt: attr('string'),
  articleId: attr('string'),
});
