/* RESPONSIBLE TEAM: team-proactive-support */
import SeriesEditorConfig from 'embercom/objects/content-editor/series-editor-configuration';
import generateSeriesModeConfig from 'embercom/objects/series/ruleset-editor/wait/editor-configuration-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.wait;
  return new SeriesEditorConfig({
    seriesMode: generateSeriesModeConfig(options),
    notificationMessages: generateNotificationMessages(options),
  });
}
