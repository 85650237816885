/* RESPONSIBLE TEAM: team-messenger */
import Model, { hasMany, attr } from '@ember-data/model';
import CarouselValidations from 'embercom/validations/carousel';
import { getOwner } from '@ember/application';
import carouselEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/carousel';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import { dependentKeyCompat } from '@ember/object/compat';

export default class Carousel extends Model.extend(CarouselValidations) {
  @hasMany('carousels/localized-carousel-content', { inverse: 'carousel', async: false })
  localizedCarouselContents;
  @attr('string') dismissible;
  @attr('array') preferredDevices;

  @attr('string') textColor;
  @attr('string') backgroundColor;
  @attr('string') actionsColor;
  @attr('string', { defaultValue: 'top' }) verticalAlignment;

  @attr('number') targetUserGroup;
  @attr('date') updatedAt;

  @dependentKeyCompat
  get contentHash() {
    return {
      dismissible: this.dismissible,
      verticalAlignment: this.verticalAlignment,
      preferredDevices: this.preferredDevices,
      localizedCarouselContents: this.localizedCarouselContents.map(
        (localizedCarouselContent) => localizedCarouselContent.contentHash,
      ),
    };
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.localizedCarouselContents.any((localizedContent) => localizedContent.hasUnsavedChanges)
    );
  }

  editorConfig() {
    let container = getOwner(this);
    return carouselEditConfigGenerator({ container });
  }

  get hasAndroidAsPreferredDevice() {
    return this.preferredDevices.includes(preferredDevices.android);
  }

  get hasIosAsPreferredDevice() {
    return this.preferredDevices.includes(preferredDevices.ios);
  }

  rollbackAttributes() {
    this.localizedCarouselContents.forEach((localizedCarouselContent) =>
      localizedCarouselContent.rollbackAttributes(),
    );
    super.rollbackAttributes();
  }

  beforeSave() {
    this.localizedCarouselContents.forEach((localizedCarouselContent) =>
      localizedCarouselContent.beforeSave(),
    );
  }

  afterSave() {
    this.localizedCarouselContents.forEach((localizedCarouselContent) =>
      localizedCarouselContent.afterSave(),
    );
  }
}
