/* RESPONSIBLE TEAM: team-help-desk-experience */

import { type IDBPDatabase } from 'idb';
import { type Span } from '@opentelemetry/api';

import { prepareStringForFuzzySearch } from 'embercom/services/fuzzy-search';
import { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { type DB } from 'embercom/services/inbox2-core-data';
import { type TeamSummaryWireFormat } from 'embercom/objects/inbox/team-summary';
import ObjectStore from 'embercom/objects/inbox/core-data/store';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import TeamSummary from 'embercom/objects/inbox/team-summary';

type AssigneesResponse = {
  version: number;
  admins: {
    total: number;
    results: AdminSummaryWireFormat[];
  };
  teams: {
    total: number;
    results: TeamSummaryWireFormat[];
  };
};

export type AdminSchema = AdminSummaryWireFormat & { fuzzy: Fuzzysort.Prepared };
export type TeamSchema = TeamSummaryWireFormat & { fuzzy: Fuzzysort.Prepared };
export type AssigneeSchema =
  | { type: 'admin'; obj: AdminSchema }
  | { type: 'team'; obj: TeamSchema };

export default class AssigneesStore extends ObjectStore<AdminSummary | TeamSummary> {
  static upgradeDB(db: IDBPDatabase<DB>, _oldVersion: number, _newVersion: number | null) {
    // for now nuke all data on update, in future we can do something nicer
    if (db.objectStoreNames.contains('assignees')) {
      db.deleteObjectStore('assignees');
    }

    if (!db.objectStoreNames.contains('assignees')) {
      let assignees = db.createObjectStore('assignees', {
        keyPath: 'obj.id',
        autoIncrement: false,
      });

      // index to allow searching by type
      assignees.createIndex('type', 'type');

      // index to allow searching for admin by their email
      assignees.createIndex('email', 'obj.email', { unique: true });
    }
  }

  async fetchOne(
    id: AdminSummary['id'] | TeamSummary['id'],
  ): Promise<AdminSummary | TeamSummary | null> {
    let item = await this.db.get('assignees', id);
    if (!item) {
      // TODO: fetch from server
      return null;
    }

    return this.getOrCreateFromIdentityMap(item);
  }

  async fetchOneAdminByEmail(email: string): Promise<AdminSummary | null> {
    let item = await this.db.getFromIndex('assignees', 'email', email);
    if (!item) {
      // TODO: fetch from server
      return null;
    }

    let assignee = this.getOrCreateFromIdentityMap(item);
    if (assignee && !(assignee instanceof AdminSummary)) {
      throw new Error(`Expected AdminSummary, got ${assignee}`);
    }

    return assignee;
  }

  async fetchOneAdmin(id: AdminSummary['id']): Promise<AdminSummary | null> {
    let item = await this.fetchOne(id);

    if (item && !(item instanceof AdminSummary)) {
      throw new Error(`Expected AdminSummary, got ${item}`);
    }

    return item;
  }

  async fetchOneTeam(id: TeamSummary['id']): Promise<TeamSummary | null> {
    let item = await this.fetchOne(id);

    if (item && !(item instanceof TeamSummary)) {
      throw new Error(`Expected TeamSummary, got ${item}`);
    }

    return item;
  }

  async fetchAll(): Promise<AdminSummary[] | TeamSummary[]> {
    return await this.tracing.inSpan(
      { name: 'fetchAll', resource: 'inbox2-core-data.assignees' },
      async () => {
        let items = await this.db.getAll('assignees');

        return items.map((item) => this.getOrCreateFromIdentityMap(item));
      },
    );
  }

  private getOrCreateFromIdentityMap(item: AssigneeSchema) {
    let assignee = this.identityMap.get(item.obj.id.toString());
    if (!assignee) {
      switch (item.type) {
        case 'admin':
          assignee = AdminSummary.deserialize(item.obj);
          this.identityMap.set(item.obj.id.toString(), assignee);
          break;
        case 'team': {
          assignee = TeamSummary.deserialize(item.obj);
          this.identityMap.set(item.obj.id.toString(), assignee);
          break;
        }
      }
    }
    return assignee;
  }

  async boot() {
    let versionData = await this.db.get('versions', 'assignees');
    let json = await this.fetchData<AssigneesResponse>(
      '/ember/inbox/assignees',
      versionData?.version,
    );
    if (!json) {
      return;
    }

    let admins = json.admins.results;
    let teams = json.teams.results;
    let version = json.version;

    return await this.tracing.inSpan(
      { name: 'boot', resource: 'inbox2-core-data.assignees' },
      async (span?: Span) => {
        let existing = await this.db.getAll('assignees');
        let existingById = Object.fromEntries(
          existing.map((assignee) => [assignee.obj.id, assignee]),
        );
        let fetchedIds: Set<DB['assignees']['key']> = new Set();
        let toUpdate: AssigneeSchema[] = [];
        let toDelete: DB['assignees']['key'][] = [];

        // figure out which are new / changed
        admins.forEach((admin) => {
          fetchedIds.add(admin.id);
          let existingItem = existingById[admin.id];
          if (!existingItem || (existingItem && existingItem.obj.name !== admin.name)) {
            toUpdate.push({
              type: 'admin',
              obj: {
                ...admin,
                fuzzy: prepareStringForFuzzySearch(admin.name),
              },
            });
          }
        });

        teams.forEach((team) => {
          fetchedIds.add(team.id);
          let existingItem = existingById[team.id];
          if (!existingItem || (existingItem && existingItem.obj.name !== team.name)) {
            toUpdate.push({
              type: 'team',
              obj: {
                ...team,
                fuzzy: prepareStringForFuzzySearch(team.name),
              },
            });
          }
        });

        // figure out which have been deleted
        Object.keys(existingById).forEach((id) => {
          if (!fetchedIds.has(Number(id))) {
            toDelete.push(Number(id));
          }
        });

        span?.setAttributes({
          'items.fetched': fetchedIds.size,
          'items.existing': existing.length,
          'items.updated': toUpdate.length,
          'items.deleted': toDelete.length,
        });

        // stick the data in indexeddb
        let tx = this.db.transaction(['assignees', 'versions'], 'readwrite');
        let itemStore = tx.objectStore('assignees');
        let versionStore = tx.objectStore('versions');
        await Promise.all([
          ...toUpdate.map((item) => itemStore.put(item)),
          ...toDelete.map((id) => itemStore.delete(id)),
          versionStore.put({ id: 'assignees', version }),
          tx.done,
        ]);
      },
    );
  }
}
