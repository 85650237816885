/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { not, and } from '@ember/object/computed';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import {
  StandardGoalSummaryConfiguration,
  StandardScheduleSummaryConfiguration,
} from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import ProductTourAudienceSummaryConfiguration from 'embercom/objects/content-editor/summaries/audience/content/product-tour/audience-summary-configuration';
import UseTourAnywhereSummaryConfiguration from 'embercom/objects/content-editor/summaries/custom/product-tour/use-tour-anywhere-summary-configuration';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/tour/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';

export default function generateViewModeConfig(options) {
  let container = options.container;

  let intl = container.lookup('service:intl');
  return ViewModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesViewModeHeaderConfiguration.create({
      container,
      moreDropdownConfig: MoreDropdownConfiguration.create({
        hideDuplicateOption: true,
        hideCsvExportOption: false,
        hideTagOption: true,
        hideDeleteOption: true,
        hideVersionsOption: true,
        container,
      }),
    }),
    headerComponentConfigs: [
      StandardViewModeHeaderConfiguration.create({
        container,
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/tours/preview-button',
        }),
      }),
    ],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: 'Product Tour',
          icon: 'product-tours',
          view: 'content',
        }),
        StandardStatTabConfiguration.extend({ isVisible: true }).create({
          label: intl.t('outbound.stats-system.tabs.views'),
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.extend({
          isVisible: options.productTour.steps.length > 1,
        }).create({
          label: intl.t('outbound.stats-system.tabs.completed'),
          view: 'completions',
          container,
        }),
        StandardStatTabConfiguration.extend({ isVisible: true }).create({
          label: 'Issues',
          view: 'tourStepFailures',
          container,
        }),
        StandardStatTabConfiguration.extend({
          _isNotDraft: not('contentEditorService.ruleset.isDraft'),
          isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.goal'),
          view: 'goals',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        ContentSummaryConfiguration.create({ container }),
        ProductTourAudienceSummaryConfiguration.create({ container }),
        UseTourAnywhereSummaryConfiguration.create({ container }),
        StandardGoalSummaryConfiguration.create({ container }),
        StandardScheduleSummaryConfiguration.create({
          title: 'Frequency and scheduling',
          container,
        }),
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          emptyStateDescription: "As people view your tour, we'll show you when it was seen",
          container,
        }),
      ],
      completions: [StandardStatListConfiguration.create({ stat: 'completion', container })],
      tourStepFailures: [
        StandardStatListConfiguration.create({ stat: 'tourStepFailure', container }),
      ],
      goals: [
        StandardStatListConfiguration.create({
          stat: 'goalSuccess',
          container,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });
}
