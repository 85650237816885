/* RESPONSIBLE TEAM: team-workflows */
import type Snooze from 'embercom/models/operator/visual-builder/step/snooze';
import StepConfig, { type StepConfigParams } from '../step';

export default class SnoozeConfiguration extends StepConfig {
  declare step: Snooze;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/snooze',
      ...params,
    });
  }
}
