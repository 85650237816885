/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-side-effects */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { and, empty, equal, not, notEmpty, or, readOnly, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { decamelize, w } from '@ember/string';
import { isEmpty } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import {
  equalToProperty,
  isAny,
  ternary,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import ENV from 'embercom/config/environment';
import ajax from 'embercom/lib/ajax';
import ConversationList from 'embercom/lib/inbox/conversation-list';
import findConversationDiff from 'embercom/lib/inbox/find-conversation-diff';
import Admin from 'embercom/models/admin';
import { CONVERSATION_STATES } from 'embercom/lib/inbox/constants';
import { getOwner } from '@ember/application';
import { captureException } from 'embercom/lib/sentry';
import storage from 'embercom/vendor/intercom/storage';
import { cancel, later, scheduleOnce } from '@ember/runloop';
import Metrics from 'embercom/models/metrics';

export default Model.extend({
  appService: service(),
  app: readOnly('appService.app'),

  inboxService: service('inboxService'),
  notificationsService: service(),
  store: service(),

  identifier: attr(),
  name: attr(),
  app_id: attr(),
  open_count: attr(),
  snoozed_count: attr(),
  closed_count: attr(),
  unread_count: attr(),
  admin_id: attr(),
  admin: computed('admin_id', function () {
    if (this.admin_id) {
      return Admin.peekAndMaybeLoad(this.store, this.admin_id);
    }
    return null;
  }),

  conversationList: null,
  sortDirection: computed('_status', {
    get() {
      if (this._status === 'opened') {
        this._sortDirection = storage.get(this._sortLocalStorageKey('sortDirection'));
      }
      if (!this._sortDirection) {
        this._sortDirection = 'desc';
      }
      if (this.conversationList) {
        this.conversationList.sortDirection = this._sortDirection;
      }
      return this._sortDirection;
    },
    set(key, value) {
      this._sortDirection = value;
      this.conversationList.sortDirection = value;
      if (this._status === 'opened') {
        storage.set(this._sortLocalStorageKey('sortDirection'), value);
      }
      return value;
    },
  }),
  defaultSortKey: ternary(
    'isMentionsInbox',
    'last_part_where_current_admin_is_mentioned.created_at',
    'sortingUpdatedAt',
  ),
  sortKey: computed('_status', {
    get() {
      if (this._status === 'opened') {
        this._sortKey = storage.get(this._sortLocalStorageKey('sortKey'));
      }
      if (!this._sortKey) {
        this._sortKey = this.defaultSortKey;
      }
      if (this.conversationList) {
        this.conversationList.sortKey = this._sortKey;
      }
      return this._sortKey;
    },
    set(key, value) {
      this._sortKey = value;
      this.conversationList.sortKey = value;
      if (this._status === 'opened') {
        storage.set(this._sortLocalStorageKey('sortKey'), value);
      }
      return value;
    },
  }),
  _sortLocalStorageKey(property) {
    return `${this.app.get('currentAdmin.id')}-${this.id}-opened-${property}`;
  },

  conversations: readOnly('conversationList.items'),
  hasNoConversations: empty('conversations'),
  hasLoadedAll: equalToProperty('conversations.length', 'count'),
  hasPrioritisedConversations: isAny('conversations', 'isPrioritised', true),
  hasNoPrioritisedConversations: not('hasPrioritisedConversations'),
  hasActiveTimeTarget: isAny('conversations', 'hasActiveTimeTarget', true),
  hasNoActiveTimeTarget: not('hasActiveTimeTarget'),

  isEmpty: and('hasNoConversations', 'hasNotErrored', 'fetchPageTask.isIdle'),
  hasNotErrored: not('fetchPageTask.last.error'),

  assignee_identifier: or('admin.id', 'id'),
  avatar: reads('admin.avatar'),
  avatarData: reads('admin.avatarData'),

  isTeamInbox: reads('admin.is_team'),
  isBot: reads('admin.is_bot'),
  isMeInbox: equalToProperty('id', 'app.currentAdmin.id'),
  isUnassignedInbox: equal('identifier', 'unassigned'),
  isMentionsInbox: equal('id', 'mentions'),
  isAllInbox: equal('id', 'all'),
  isInboxView: computed('id', function () {
    return this.id.startsWith('view:');
  }),
  inboxViewId: computed('id', function () {
    return this.isInboxView ? this.id.slice(5) : null;
  }),
  isPredefinedInbox: or('isUnassignedInbox', 'isAllInbox', 'isMentionsInbox'),
  isNotAdminInbox: or('isTeamInbox', 'isPredefinedInbox', 'isBot', 'admin.isBot', 'isInboxView'),
  isAdminInbox: not('isNotAdminInbox'),
  hasTeamMembers: notEmpty('admin.member_ids'),
  canBeBackgrounded: not('isAllInbox'),

  countIsUndefined: equal('count', undefined),
  determinantCount: ternaryToProperty('isMentionsInbox', 'unread_count', 'open_count'),
  determinantCountIsUndefined: equal('determinantCount', undefined),
  determinantCountIsZero: equal('determinantCount', 0),
  hideOpenCountInNavs: or('determinantCountIsUndefined', 'app.inboxIsNotActive'),
  queryParams: {},

  analyticsData: computed('id', function () {
    return {
      object: 'inbox',
      id: this.id,
    };
  }),

  _status: 'opened',
  status: computed({
    get() {
      return this._status;
    },
    set(key, value) {
      this.set('_status', value);
      // waiting since sort order is only available on open inbox. Next breach time is available in all but closed inbox.
      if (
        (this.isSortingByWaitTime && value !== 'opened') ||
        (this.isSortingByNextBreachTime && value === 'closed')
      ) {
        this.sortBy(this.defaultSortKey, 'desc');
      }

      return value;
    },
  }),

  state: computed('status', function () {
    let status = this.status;
    if (status === 'opened') {
      return 'open';
    }
    return status;
  }),

  isSortingByWaitTime: equal('sortKey', 'waitingSince'),
  isSortingByNextBreachTime: equal('sortKey', 'nextBreachTime'),
  isSortedByPriorityNewest: equal('sortKey', 'priorityNewest'),

  countKey: computed('status', function () {
    switch (this.status) {
      case 'unread':
        return 'unread_count';
      case 'opened':
        return 'open_count';
      case 'snoozed':
        return 'snoozed_count';

      case 'closed':
        return 'closed_count';

      case 'read':
        return 'read_count';

      default:
        return 'open_count';
    }
  }),

  init() {
    this._super(...arguments);
    if (this.isMentionsInbox) {
      this.set('status', undefined);
    }
    this.conversationLoader = getOwner(this)
      .factoryFor('customClass:inbox/conversationLoader')
      .create({ inbox: this });

    this.realtimeUpdater = getOwner(this)
      .factoryFor('customClass:inbox/realtimeinboxupdater')
      .create({ inbox: this });

    this.set('conversationList', this._createConversationListModel());
    if (!this.conversationList.isValidSort()) {
      this.sortBy(this.defaultSortKey, 'desc');
    }
  },

  enableRealtime() {
    this.realtimeUpdater.enable();
  },

  willDestroy() {
    this.realtimeUpdater.destroy();
    this._cancelScheduledSync();
  },

  disableRealtime() {
    this.realtimeUpdater.disable();
  },

  sortBy(sortKey, sortDirection) {
    this.setProperties({ sortKey, sortDirection });
    if (!this.hasLoadedAll || this.isMentionsInbox) {
      this.reload();
    } else {
      this.conversationList.sort();
    }
  },

  reload() {
    this.cancelAllTasks();
    this.clearConversations();

    if (this.inboxService.inbox === this) {
      this.inboxService.transitionToRoot();
    }
    return this.fetchPageTask.perform(true);
  },

  setStatus(newStatus) {
    let oldStatus = this.status;
    if (oldStatus !== newStatus) {
      // Set count to undefined to ensure we attempt to fetch a page
      this.setProperties({
        status: newStatus,
        count: undefined,
      });
      this.reload();
    }
  },

  _createConversationListModel() {
    return new ConversationList({
      applySorting: true,
      sortKey: this.sortKey,
      sortDirection: this.sortDirection,
    });
  },

  _trackSyncMetric({ diff, fromUserInteraction }) {
    if (!diff) {
      return;
    }

    let inboxType;
    if (this.isInboxView) {
      inboxType = 'view';
    } else if (this.isTeamInbox || this.isAdminInbox) {
      inboxType = 'assignee';
    } else if (this.isBot) {
      inboxType = 'bot';
    } else {
      inboxType = this.identifier;
    }

    Metrics.capture({
      increment: ['inbox.sync'],
      tags: {
        inbox: inboxType,
        differs: diff.allDifferingIds.length > 0,
        interactive: !!fromUserInteraction,
      },
    });
  },

  sync: task(function* (additionalConversationsCount, fromUserInteraction) {
    let convosDiff = null;
    let remoteConvos = yield this._fetchConvoList(
      additionalConversationsCount,
      fromUserInteraction,
    );

    // This can happen under normal operation if an intercom admin is impersonating another user but has `restrict_data_access_enabled`
    if (remoteConvos?.sync_data) {
      convosDiff = findConversationDiff(
        this.get('conversationList.unTransitionedConversations'),
        remoteConvos.sync_data,
      );
    }

    if (ENV.environment !== 'test') {
      this.realtimeUpdater.schedulePoll();
    }

    this._setScheduledSync(remoteConvos?.valid_for);
    this._trackSyncMetric({ diff: convosDiff, fromUserInteraction });

    if (convosDiff?.allDifferingIds.length > 0) {
      try {
        yield this.conversationLoader.loadConversations({
          app_id: this.app_id,
          from_user_interaction: fromUserInteraction,
          ids: convosDiff.allDifferingIds,
          include_total_count: false,
        });

        if (!this.isInboxView) {
          convosDiff.allDifferingIds.forEach((id) => {
            let convo = this.store.peekRecord('conversation', id);
            this.addConversations([convo]);
            this.conversationWasUpdated(convo);
          });
        }

        if (this.isInboxView) {
          convosDiff.createdOrUpdatedIds.forEach((id) => {
            let convo = this.store.peekRecord('conversation', id);
            this.conversationList.markConversationAsNotTransitioned(convo);
            this.addConversations([convo]);
            this.conversationWasUpdated(convo);
          });

          convosDiff.removedIds.forEach((id) => {
            let convo = this.store.peekRecord('conversation', id);
            this.conversationList.markConversationAsTransitioned(convo);

            let isCurrentConversation = convo === this.inboxService.currentConversation;
            if (!isCurrentConversation) {
              this.removeConversationTask.perform(convo);
            }
          });
        }
      } catch (e) {
        if (this.inboxService.isActive) {
          this.reload();
        }
        captureException(e, {
          fingerprint: ['inbox-model', 'sync'],
        });
      }
    }

    this._updateInboxCount(remoteConvos.count);
  }).enqueue(),

  count: computed('status', 'open_count', 'closed_count', 'snoozed_count', {
    get() {
      return this.get(this.countKey);
    },
    set(key, value) {
      this.set(this.countKey, value);
      return value;
    },
  }),

  firstName: computed('name', function () {
    if (this.isTeamInbox) {
      return this.name;
    }
    return w(this.name)[0];
  }),

  adminBelongsToTeam(admin) {
    if (this.hasTeamMembers) {
      return this.get('admin.member_ids').includes(parseInt(admin.get('id'), 10));
    }
    return false;
  },

  currentAdminBelongsToTeam() {
    return this.adminBelongsToTeam(this.app.currentAdmin);
  },

  assigneeId: computed('assignee_identifier', function () {
    let currentAssignee = this.assignee_identifier;
    if (this.isMentionsInbox) {
      return 'mentions';
    } else if (currentAssignee === 'all') {
      return null;
    } else if (currentAssignee === 'unassigned') {
      return 0;
    } else if (this.isInboxView) {
      return currentAssignee;
    } else {
      return parseInt(currentAssignee, 10);
    }
  }),

  _fetchConvoList(additionalConversationsCount = 0, fromUserInteraction = false) {
    let params = {
      app_id: this.app_id,
      from_user_interaction: fromUserInteraction,
      state: this.state,
      sort_key: decamelize(this.sortKey),
      sort_direction: decamelize(this.sortDirection),
      count:
        Math.max(this.get('conversationList.length'), ENV.APP.conversations_per_page) +
        additionalConversationsCount,
    };
    let assigneeId = this.assigneeId;
    if (assigneeId !== null) {
      params.assignee_id = assigneeId;
    }

    if (this.isInboxView) {
      return ajax({
        url: '/ember/conversations/for_inbox_view',
        type: 'GET',
        data: params,
      });
    } else {
      return ajax({
        url: '/ember/conversations/for_inbox',
        type: 'GET',
        data: params,
      });
    }
  },

  removeConversationTask: task(function* (conversation) {
    let isConversationLeavingThisInbox =
      this.conversationList.includes(conversation) && !this.isConversationInContext(conversation);

    if (!isConversationLeavingThisInbox) {
      return;
    }

    yield timeout(ENV.APP.auto_transition_timeout);
    // Since we scheduled this task something else might have updated and untransitioned this conversation
    if (!this.conversationList.transitionedConversations.includes(conversation)) {
      return;
    }

    let isCurrentConversation = conversation === this.inboxService.currentConversation;
    let isCurrentInbox = this === this.inboxService.inbox;
    let transitionedToNextUntransitioned;
    if (isCurrentConversation && isCurrentInbox) {
      transitionedToNextUntransitioned =
        this._attemptTranstionToNextUntransitionedConversation(conversation);
    }
    this.removeConversations([conversation]);

    if (this.count > 0) {
      this.decrementProperty('count');
    }

    if (isCurrentConversation && isCurrentInbox && !transitionedToNextUntransitioned) {
      this.inboxService.transitionToRoot();
    }
  }),

  // Fetch a page of items
  fetchPageTask: task(function* (force) {
    if (!force && this.hasLoadedAll) {
      return;
    }
    if (isEmpty(this.conversations)) {
      let params = this.conversationSearchParams;
      params.from_user_interaction = true;
      let results = yield this.conversationLoader.doSearch(params);

      this.addConversations(results.conversations);

      this.set('count', results.totalCount);
      this._setScheduledSync(results.validFor);
    } else {
      yield this.sync.perform(ENV.APP.conversations_per_page, true);
    }
    this.inboxService.updateFavicon();
    if (this.inboxService.inbox === this) {
      this.inboxService.autoTransitionIfNoCurrentConversation();
    }
  }).drop(),

  _setScheduledSync(timeToNextSync) {
    if (this.isInboxView && timeToNextSync) {
      this._cancelScheduledSync();
      if (!this.isDestroying) {
        this.set(
          'scheduledSync',
          later(null, () => this.sync.perform(undefined, false), timeToNextSync * 1000),
        );
      }
    }
  },

  _cancelScheduledSync() {
    cancel(this.scheduledSync);
  },

  nearestUntransitionedConversation(conversation) {
    return this.conversationList.nearestUntransitionedConversation(conversation);
  },

  addConversations(items, force = false) {
    this.conversationList.addItems(items, force);
    this.realtimeUpdater.trackUpdatesToConversations(items);
  },

  forceAddConversations(items) {
    this.addConversations(items, true);
  },

  removeConversations(items) {
    this.conversationList.removeItems(items);
    this.realtimeUpdater.stopTrackingUpdatesToConversations(items);
  },

  clearConversations() {
    this.realtimeUpdater.stopTrackingUpdatesToConversations(this.get('conversationList.items'));
    this.conversationList.clear();
  },

  clearTransitionedConversations() {
    this.removeConversations(this.conversationList.transitionedConversations);
  },

  clearTransitionedConversationsExcluding(conversation) {
    this.removeConversations(
      this.conversationList.transitionedConversationsExcluding(conversation),
    );
  },

  findConversationById(id) {
    return this.conversationList.findById(id);
  },

  cancelAllTasks() {
    this.fetchPageTask.cancelAll();
    this.sync.cancelAll();
    this.removeConversationTask.cancelAll();
  },

  conversationWasUpdated(conversation, removeWhenOutOfContextEvenIfCurrent = false) {
    if (this.isMentionsInbox) {
      this.conversationList.sort();
      return;
    }

    conversation.set('userIsTyping', false);

    if (!this.isInboxView) {
      let isInList = this.conversationList.includes(conversation);
      let isInContext = this.isConversationInContext(conversation);
      let isCurrentConversation = conversation === this.inboxService.currentConversation;

      if (isInList && !isInContext) {
        if (this._shouldTransitionConversation(conversation)) {
          this.conversationList.markConversationAsTransitioned(conversation);
        }
        if (removeWhenOutOfContextEvenIfCurrent || !isCurrentConversation) {
          this.removeConversationTask.perform(conversation);
        }
      } else if (isInContext) {
        this.conversationList.markConversationAsNotTransitioned(conversation);
        if (!isInList) {
          this.forceAddConversations([conversation]);
        }
      }
    }
    scheduleOnce('afterRender', this.conversationList, this.conversationList.sort);
  },

  isConversationInContext(conversation) {
    return (
      this._isConversationStatusInContext(conversation) &&
      this._isConversationAssigneeInContext(conversation)
    );
  },

  _isConversationStatusInContext(conversation) {
    if (this.status === 'opened') {
      return conversation.get('isOpen');
    } else if (this.status === 'snoozed') {
      return conversation.get('isSnoozed');
    } else {
      // See https://github.com/intercom/intercom/issues/28384 for more details
      if (conversation.get('lastPart.isExplicitAssignment')) {
        return true;
      }
      return !conversation.get('isOpen');
    }
  },

  _isConversationAssigneeInContext(conversation) {
    let assigneeInbox = this.assignee_identifier;
    let assigneeIdentifier = conversation.get('assigneeIdentifier');
    if (assigneeInbox === 'all') {
      return true;
    }
    return assigneeInbox === assigneeIdentifier;
  },

  _shouldTransitionConversation(conversation) {
    let inboxStatus = CONVERSATION_STATES[this.status];
    let conversationState = conversation.get('state');
    let inboxAssignee = this.assigneeId;
    let conversationAssignee = Number(conversation.get('assignee.id') || 0);
    let isAllInbox = this.isAllInbox;
    return (
      inboxStatus !== conversationState || (!isAllInbox && inboxAssignee !== conversationAssignee)
    );
  },

  _updateInboxCount(count) {
    this.set('count', count);
  },

  _attemptTranstionToNextUntransitionedConversation(conversation) {
    let nextUntransitionedConversation;
    try {
      nextUntransitionedConversation = this.nearestUntransitionedConversation(conversation);
    } catch (e) {
      captureException(e, {
        fingerprint: ['respond-service', 'nearestUntransitionedConversation'],
      });
    }

    if (nextUntransitionedConversation && nextUntransitionedConversation !== conversation) {
      this.inboxService.transitionToConversation(nextUntransitionedConversation);
      return true;
    }
    return false;
  },

  conversationSearchParams: computed(
    'app_id',
    'assignee_identifier',
    'sortKey',
    'sortDirection',
    'status',
    function () {
      let params = {
        app_id: this.app_id,
        assignee_identifier: this.assignee_identifier,
        sort_key: decamelize(this.sortKey),
        sort_direction: decamelize(this.sortDirection),
        per_page: ENV.APP.conversations_per_page,
        include_total_count: true,
      };
      let status = this.status;
      if (status) {
        params.status = status;
      }
      return params;
    },
  ),
});
