/* RESPONSIBLE TEAM: team-help-desk-experience */
export interface AdminVisibilitySettingConstructorFormat {
  visible: boolean;
}

export interface AdminVisibilitySettingWireFormat {
  visible: boolean;
}

export default class AdminVisibilitySetting {
  visible: boolean;

  constructor({ visible }: AdminVisibilitySettingConstructorFormat) {
    this.visible = visible;
  }

  static deserialize(json: AdminVisibilitySettingWireFormat) {
    return new AdminVisibilitySetting({
      visible: json.visible,
    });
  }
}
