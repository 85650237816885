/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Model, { attr } from '@ember-data/model';
import type LineItem from './line-item';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import type SubscriptionDetails from './subscription-details';

export type DiscountAmount = {
  amount: number;
};

/**
 * Refer to the Stripe docs:
 * https://docs.stripe.com/api/invoices/object
 */
export default class Invoice extends Model {
  @attr declare object: 'invoice';
  @attr declare amountDue: number;
  @attr declare amountPaid: number;
  @attr declare amountRemaining: number;
  @attr declare currency: string;
  @attr declare total: number;
  @attr('array') declare totalDiscountAmounts: Array<DiscountAmount>;
  @fragment('billing/stripe/subscription-details') declare subscriptionDetails: SubscriptionDetails;
  @attr declare subtotal: number;
  @attr('number') declare periodEnd: number;
  @attr('number') declare periodStart: number;
  @fragmentArray('billing/stripe/line-item') declare lines: Array<LineItem>;

  get netAmount() {
    return this.total - this.totalDiscountAmount;
  }
  get totalDiscountAmount() {
    return this.totalDiscountAmounts.reduce((acc, discount) => acc + discount.amount, 0);
  }
}
