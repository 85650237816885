/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ParentAction } from 'embercom/objects/inbox/command-k/action';
import type ArticlesApi from 'embercom/services/articles-api';
import { inject as service } from '@ember/service';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type InboxState from 'embercom/services/inbox-state';

export default class InsertArticleAction extends ParentAction {
  @service declare articlesApi: ArticlesApi;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare inboxState: InboxState;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.InsertArticle];
  }

  id = 'insert-article';
  icon = 'article' as const;

  paneComponent = 'inbox2/command-k/insert-article/pane';
}
