/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';

let QueryBuilderDropdownItem = EmberObject.extend({
  isLabel: false,
  isFilterType: false,
  isLoadingSpinner: false,
  isSuggestion: false,
  isKeywordPrompt: false,
  isNoMatchForType: false,
  isKeyboardNavigable: false,
});

let DropdownLabel = QueryBuilderDropdownItem.extend({
  label: '',
  isLabel: true,
});

let DropdownFilterType = QueryBuilderDropdownItem.extend({
  isFilterType: true,
  isKeyboardNavigable: true,
  filterType: undefined,
  onClickAction: undefined,
});

let DropdownLoadingSpinner = QueryBuilderDropdownItem.extend({
  isLoading: true,
  type: undefined,
});

let DropdownSuggestion = QueryBuilderDropdownItem.extend({
  suggestion: undefined,
  isSuggestion: true,
  isKeyboardNavigable: true,
  onClickAction: undefined,
});

let DropdownKeywordPrompt = QueryBuilderDropdownItem.extend({
  isSearchByKeywordPrompt: true,
  isKeyboardNavigable: true,
  onClickAction: undefined,
});

let DropdownNoMatchForType = QueryBuilderDropdownItem.extend({
  isNoMatchForType: true,
  type: undefined,
});

export {
  DropdownLabel,
  DropdownFilterType,
  DropdownLoadingSpinner,
  DropdownSuggestion,
  DropdownKeywordPrompt,
  DropdownNoMatchForType,
};
