/* RESPONSIBLE TEAM: team-channels */
import type AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type BotComment from 'embercom/objects/inbox/renderable/bot-comment';
import type CustomBot from 'embercom/objects/inbox/renderable/custom-bot';

export type RenderableDataWithSendState = AdminComment | BotComment | CustomBot;

export interface SendStateWireFormat {
  send_state: number;
  info_message: string;
}

export default class SendState {
  readonly sendState: number;
  readonly infoMessage: string;

  constructor(sendState: number, infoMessage: string) {
    this.sendState = sendState;
    this.infoMessage = infoMessage;
  }

  static deserialize(json: SendStateWireFormat): SendState {
    return new SendState(json.send_state, json.info_message);
  }
}
