/* RESPONSIBLE TEAM: team-help-desk-experience */

import { TicketSystemState } from 'embercom/objects/inbox/conversation';

export type TicketTypeId = number;
export interface TicketCustomStateConstructorFormat {
  id: number;
  systemState: TicketSystemState;
  archived: boolean;
  userLabel: string;
  adminLabel: string;
  ticketTypeIds: Array<TicketTypeId>;
}

export function ticketSystemStatesList(): TicketSystemState[] {
  return [
    TicketSystemState.Submitted,
    TicketSystemState.InProgress,
    TicketSystemState.WaitingOnCustomer,
    TicketSystemState.Resolved,
  ];
}

export function groupCustomStatesBySystemStates(
  ticketCustomStates: TicketCustomState[],
): Record<`${TicketSystemState}`, TicketCustomState[]> {
  return ticketCustomStates.reduce(
    (acc: Record<`${TicketSystemState}`, TicketCustomState[]>, customState: TicketCustomState) => {
      let systemState = customState.systemState;
      acc[systemState] = acc[systemState] || [];
      acc[systemState].push(customState);
      return acc;
    },
    {} as Record<`${TicketSystemState}`, TicketCustomState[]>,
  );
}

export interface TicketCustomStateWireFormat {
  id: number;
  system_state: TicketSystemState;
  archived: boolean;
  user_label: string;
  admin_label: string;
  ticket_type_ids: Array<TicketTypeId>;
}

export default class TicketCustomState {
  readonly id: number;
  readonly systemState: TicketSystemState;
  readonly archived: boolean;
  userLabel: string;
  adminLabel: string;
  ticketTypeIds: Array<TicketTypeId>;

  constructor(inputs: TicketCustomStateConstructorFormat) {
    let { id, systemState, archived, userLabel, adminLabel, ticketTypeIds } = inputs;
    this.id = id;
    this.systemState = systemState;
    this.archived = archived;
    this.userLabel = userLabel;
    this.adminLabel = adminLabel;
    this.ticketTypeIds = ticketTypeIds;
  }

  static deserialize(json: TicketCustomStateWireFormat) {
    return new TicketCustomState({
      id: json.id,
      systemState: json.system_state,
      archived: json.archived,
      userLabel: json.user_label,
      adminLabel: json.admin_label,
      ticketTypeIds: json.ticket_type_ids,
    });
  }

  static serialize(object: TicketCustomState) {
    return {
      id: object.id,
      system_state: object.systemState,
      archived: object.archived,
      user_label: object.userLabel,
      admin_label: object.adminLabel,
      ticket_type_ids: object.ticketTypeIds,
    };
  }
}
