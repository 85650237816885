/* RESPONSIBLE TEAM: team-knowledge-interop */
import Model, {
  type AsyncHasMany,
  type SyncHasMany,
  attr,
  hasMany,
  belongsTo,
} from '@ember-data/model';
import { type SenderType } from 'embercom/models/data/outbound/types';
import { type EntityType } from 'embercom/models/data/entity-types';
import { type State } from 'embercom/models/data/matching-system/matching-constants';
import { type Status as ReviewStatus } from 'embercom/models/data/content-service/content-review-statuses';
import type ContentStatistic from '../outbound/content-statistic';
import type ContentWrapper from './content-wrapper';
import type Tag from 'embercom/models/tag';

export default class ContentFragment extends Model {
  @attr('string') declare appId: string;
  @attr() declare senderId?: number | string;
  @attr('number') declare senderType: SenderType;
  @attr('number') declare copilotState: number;
  @attr('number') declare chatbotState: number;
  @attr('number') declare contentId: number;
  @attr('number') declare contentType: EntityType;
  @attr('number') declare contentBytesize: number;
  @attr('number') declare rulesetLinkId?: number;
  @attr('boolean') declare insertable: boolean;
  @attr('boolean') declare showAutomatically: boolean;
  @attr('boolean') declare triggerable: boolean;
  @attr('number') declare state: State;
  @hasMany('outbound/content-statistic', { async: false })
  declare stats: SyncHasMany<ContentStatistic>;
  @belongsTo('content-service/content-wrapper', { async: false, inverse: 'contents' })
  declare contentWrapper: ContentWrapper;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('date') declare wentLiveAt: Date;
  @attr('number') declare createdById: number;
  @attr('number') declare lastUpdatedById: number;
  @attr('number') declare subscriptionTypeId: number;
  @attr('string') declare subtitle: string;
  @attr('string') declare locale?: string;
  @attr('string') declare title?: string;
  @attr({ defaultValue: () => {} }) declare contentData: any;
  @attr('number') declare contentReviewStatus: ReviewStatus;
  @attr('array', { defaultValue: () => [] }) declare aiContentSegmentIds: number[];
  @hasMany('tag', { async: true }) declare tags: AsyncHasMany<Tag>;
  @hasMany('tagging', { async: false }) declare taggings: SyncHasMany<any>;

  allContentIncludedIn(fragments: Set<ContentFragment>) {
    return fragments.has(this);
  }

  anyContentIncludedIn(fragments: Set<ContentFragment>) {
    return fragments.has(this);
  }

  get copilotStates() {
    return [this.copilotState];
  }

  get chatbotStates() {
    return [this.chatbotState];
  }
}
