/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { type SyncHasMany, attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type Charge from './charge';
import { PLAN_DATA } from 'embercom/lib/billing';

export default class Breakdown extends Fragment {
  @attr('string') declare name: string | null;
  @attr('string') declare base_plan_price: string | undefined;
  @attr('number') declare amount: number;
  @attr('number') declare base_plan_price_pre_discount: number | undefined;
  @attr declare plan_id: number | 'base_lite' | 'base_standard' | undefined;
  @attr('number') declare plan_starting_price: number | undefined;
  @attr('number') declare pre_discount_price: number | undefined;
  @attr() declare pricing_by_billing_period: any;

  @fragmentArray('billing/price/charge') declare charges: SyncHasMany<Charge>;

  get isDiscount() {
    return this.plan_id === undefined;
  }

  get isSharedBaseBreakdown() {
    if (this.isDiscount) {
      return false;
    }

    return ['base_standard', 'base_lite'].includes(this.plan_id!.toString());
  }

  get nameWithProduct() {
    if (this.plan_id && PLAN_DATA[this.plan_id]) {
      return PLAN_DATA[this.plan_id]['nameWithProduct'];
    }

    return this.name;
  }
}
