/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';

export enum AssignableEntity {
  UnassignedTeammate = -1,
  UnassignedTeam = -2,
  Teammate = EntityType.Admin, // 80
  Team = EntityType.Team, // 23
  Bot = EntityType.Bot, // 82
}

export enum AssigningEntity {
  Teammate = EntityType.Admin, // 80
  AssignmentRule = EntityType.AssignmentRule, // 79
  Workflow = EntityType.Workflow, // 78
  LegacyMessage = EntityType.LegacyMessage, // 67
  Bot = EntityType.Bot, // 82
  CustomBotSnapshot = EntityType.CustomBotSnapshot, // 77

  BulkReassignment = -1,
  AwayMode = -2,
  AssignmentStrategy = -3,
  DefaultAssignment = -4,
}

/*
 * This enum represents the valid combinations of assignees that we allow.
 * It is used to quickly pick out the right translation key for the UI.
 * If an unexpected combination is serialized down to Embercom, the "unknown"
 * key is chosen. If this occurs, we should either add translations for the
 * new combination, or fix the bug which is causing an unexpected combination
 * of assignees.
 */
export enum CombinedAssigneeType {
  UnassignedTeammateAndTeam, // 0
  UnassignedTeam, // 1
  UnassignedTeammate, // 2
  AssignedTeammate, // 3
  AssignedTeam, // 4
  AssignedTeammateAndTeam, // 5
  AssignedBot, // 6
  UnassignedTeammateAndAssignedTeam, // 7
  Unknown, // 8
  UnassignedTeamAndAssignedTeammate, // 9
}
