/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ChildAction, ParentAction } from './action';
import Tag from 'embercom/objects/inbox/tag';
import { type SearchByTypeResponse } from 'embercom/services/quick-search';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { isEmpty } from '@ember/utils';
import { DisplayContext } from 'embercom/services/command-k';
import { includesTag } from '../taggable-part';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Inbox2TagsSearch from 'embercom/services/inbox2-tags-search';
import { SEARCH_RESULTS_SIZE } from 'embercom/components/inbox2/command-k/home';

enum TaggableItemType {
  ConversationPart = 'conversation-part',
  User = 'user',
  Company = 'company',
}

export enum CommandKTagActionId {
  TagLastUserConversationPart = 'tag-last-user-conversation-part',
  TagConversationPart = 'tag-conversation-part',
  TagFirstUserConversationPart = 'tag-first-user-conversation-part',
  TagUser = 'tag-user',
  TagLead = 'tag-lead',
  TagCompany = 'tag-company',
}

export abstract class TagItemBaseAction extends ParentAction {
  abstract id: string;
  abstract itemType: TaggableItemType;
  icon = 'tag' as const;
  paneComponent = 'inbox2/command-k/tag-item/pane';

  hideOnSelect = false;

  @tracked private pendingTags: Set<string> = new Set();
  @tracked private initialTags: Tag[] = this.tags ?? [];

  @service declare inbox2TagsSearch: Inbox2TagsSearch;

  beforeOnSelect = ({ value: tag }: ChildAction) => {
    if (!tag.isNew) {
      return;
    }

    this.pendingTags.add(tag.name);
    this.pendingTags = this.pendingTags;
  };

  buildResults(
    query: string,
    searchResults: SearchByTypeResponse,
    options = { showCreateTagOption: true },
  ): Array<ChildAction> {
    let quickSearchTagResults = searchResults ?? { results: [], total: 0 };
    let quickSearchTags = quickSearchTagResults.results.map((result) => ({
      name: result.data.name,
      id: result.data.id,
      highlightedLabel: result.highlightedLabel,
    }));

    let filteredLocalTags = this.inbox2TagsSearch
      .filterAndHighlightInitialTags(query, this.initialTags.concat(this.createdTags))
      .map((result) => ({
        name: result.data.name,
        id: result.data.id,
        highlightedLabel: result.highlightedLabel,
      }));

    let tagActions = [...filteredLocalTags, ...quickSearchTags].uniqBy('id').map((result) => {
      return new ChildAction({
        parent: this,
        id: result.id.toString(),
        label: result.name,
        value: result,
        optionComponent: 'inbox2/command-k/tag-item/option',
        beforeOnSelect: this.beforeOnSelect,
        highlightedLabel: result.highlightedLabel,
      });
    });

    let queryMatchesExistingTag = tagActions.any((item) => item.label === query);
    if (
      !isEmpty(query) &&
      !queryMatchesExistingTag &&
      this.commandK.session.teammate.permissions.canManageTags &&
      options.showCreateTagOption
    ) {
      tagActions.push(this.createTagAction(query));
    }

    return tagActions;
  }

  async buildTopLevelItems(query: string): Promise<Array<ChildAction>> {
    if (
      this.itemType === TaggableItemType.ConversationPart &&
      this.commandK.currentActivationContext !== DisplayContext.MessageActions
    ) {
      return [];
    }

    let results = await this.inbox2TagsSearch.filterTags(query, undefined, SEARCH_RESULTS_SIZE);

    return this.buildResults(query, results, {
      // No need to show create tag option in top level result in other contexts. But we need to show it in MessageActions context as top level in that context is just displaying all tags.
      showCreateTagOption: this.commandK.currentActivationContext === DisplayContext.MessageActions,
    });
  }

  createTagAction(tagName: string): ChildAction {
    let newTag: Tag = new Tag('', tagName, this.commandK.session.teammate);

    return new ChildAction({
      parent: this,
      id: 'new',
      label: this.intl.t(`inbox.command-k.tag-item.create-tag`, {
        tagName: newTag.name,
      }),
      value: newTag,
      icon: 'add-tag',
      beforeOnSelect: this.beforeOnSelect,
      optionComponent: 'inbox2/command-k/tag-item/create',
    });
  }

  get visibleTags(): Array<Tag> {
    return this.initialTags.concat(this.createdTags);
  }

  resetState() {
    this.initialTags = this.context?.tags ?? [];
    this.pendingTags = new Set();
  }

  private get createdTags() {
    return this.tags.filter(
      (tag) => !includesTag(this.initialTags, tag) && this.pendingTags.has(tag.name),
    );
  }

  private get tags() {
    let tags = (this.context?.tags ?? []) as Tag[];
    return tags;
  }
}

export class TagLastUserConversationPartAction extends TagItemBaseAction {
  @service declare inboxHotkeys: InboxHotkeys;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.TagLastUserComment];
  }

  id = CommandKTagActionId.TagLastUserConversationPart;
  itemType = TaggableItemType.ConversationPart;
}

export class TagFirstUserConversationPartAction extends TagItemBaseAction {
  id = CommandKTagActionId.TagFirstUserConversationPart;
  itemType = TaggableItemType.ConversationPart;
}

export class TagConversationPartAction extends TagItemBaseAction {
  id = CommandKTagActionId.TagConversationPart;
  itemType = TaggableItemType.ConversationPart;
}

export class TagUser extends TagItemBaseAction {
  @service declare inboxHotkeys: InboxHotkeys;

  id = CommandKTagActionId.TagUser;
  itemType = TaggableItemType.User;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.TagUser];
  }
}

export class TagLead extends TagItemBaseAction {
  @service declare inboxHotkeys: InboxHotkeys;

  id = CommandKTagActionId.TagLead;
  itemType = TaggableItemType.User;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.TagUser];
  }
}

export class TagCompany extends TagItemBaseAction {
  id = CommandKTagActionId.TagCompany;
  itemType = TaggableItemType.Company;
}
