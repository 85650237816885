/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  name: attr(),
  locale_id: attr(),

  predicate: computed('locale_id', function () {
    return {
      comparison: 'eq',
      value: this.locale_id,
      attribute: 'browser_locales',
      type: 'browser_locale',
    };
  }),
});
