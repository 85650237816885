/* RESPONSIBLE TEAM: team-channels */

import Model, { attr } from '@ember-data/model';

export default class EmailSignature extends Model {
  @attr('string') appId;
  @attr('string', { defaultValue: 'left' }) logoPosition;
  @attr('string') logoUrl;
  @attr('number') height;
  @attr('number') width;
  @attr('array') blocks;
  @attr('string') logoUrl;
  @attr('number') width;
  @attr('number') height;
  @attr('number') brandId;
}
