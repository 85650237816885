/* RESPONSIBLE TEAM: team-frontend-tech */
let urlAppend = function (url, params) {
  let _url = new URL(url);

  for (let param of params) {
    _url.searchParams.append(param.key, param.value);
  }

  return _url.href;
};

export default urlAppend;
