/* RESPONSIBLE TEAM: team-frontend-tech */
let HoursOfDayMap = [
  { secondsFromMidnight: 0, text: '12:00AM', icon: 'clock' }, // Midnight, start of day
  { secondsFromMidnight: 1 * 3600, text: '1:00AM', icon: 'clock' },
  { secondsFromMidnight: 2 * 3600, text: '2:00AM', icon: 'clock' },
  { secondsFromMidnight: 3 * 3600, text: '3:00AM', icon: 'clock' },
  { secondsFromMidnight: 4 * 3600, text: '4:00AM', icon: 'clock' },
  { secondsFromMidnight: 5 * 3600, text: '5:00AM', icon: 'clock' },
  { secondsFromMidnight: 6 * 3600, text: '6:00AM', icon: 'clock' },
  { secondsFromMidnight: 7 * 3600, text: '7:00AM', icon: 'clock' },
  { secondsFromMidnight: 8 * 3600, text: '8:00AM', icon: 'clock' },
  { secondsFromMidnight: 9 * 3600, text: '9:00AM', icon: 'clock' },
  { secondsFromMidnight: 10 * 3600, text: '10:00AM', icon: 'clock' },
  { secondsFromMidnight: 11 * 3600, text: '11:00AM', icon: 'clock' },
  { secondsFromMidnight: 12 * 3600, text: '12:00PM', icon: 'clock' },
  { secondsFromMidnight: 13 * 3600, text: '1:00PM', icon: 'clock' },
  { secondsFromMidnight: 14 * 3600, text: '2:00PM', icon: 'clock' },
  { secondsFromMidnight: 15 * 3600, text: '3:00PM', icon: 'clock' },
  { secondsFromMidnight: 16 * 3600, text: '4:00PM', icon: 'clock' },
  { secondsFromMidnight: 17 * 3600, text: '5:00PM', icon: 'clock' },
  { secondsFromMidnight: 18 * 3600, text: '6:00PM', icon: 'clock' },
  { secondsFromMidnight: 19 * 3600, text: '7:00PM', icon: 'clock' },
  { secondsFromMidnight: 20 * 3600, text: '8:00PM', icon: 'clock' },
  { secondsFromMidnight: 21 * 3600, text: '9:00PM', icon: 'clock' },
  { secondsFromMidnight: 22 * 3600, text: '10:00PM', icon: 'clock' },
  { secondsFromMidnight: 23 * 3600, text: '11:00PM', icon: 'clock' },
  { secondsFromMidnight: 24 * 3600, text: '12:00AM', icon: 'clock' }, // Midnight, end of day
];

export default HoursOfDayMap;
