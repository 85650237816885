/* RESPONSIBLE TEAM: team-frontend-tech */
import { BaseConfig, EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';

export default class ComposerConfig extends BaseConfig {
  placeholder = '';
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = true;
  inserters = {
    emojiInserter: 'composer/inserters/emoji-inserter',
  };
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  singleBlockMode = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  typeaheads = [EMOJI_TYPEAHEAD];

  constructor(app, includeAppAttributes) {
    super(app);

    this.attributes = app.allowedAttributes;

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes,
      }),
    };
  }
}
