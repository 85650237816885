/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ParentAction, ChildAction } from './action';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type AdminSummary from '../admin-summary';
import type TeamSummary from '../team-summary';
import type AdminWithPermissions from '../admin-with-permissions';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import type ViewSummary from '../view-summary';
import { NamedIcon, ProfilePictureIcon } from './icons';
import type Router from '@ember/routing/router-service';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import { type SearchMultipleTypesResponse } from 'embercom/services/quick-search';

type JumpToAdminInbox = {
  category: InboxCategory.Admin;
  id: string;
  adminSummary: AdminSummary | AdminWithPermissions;
};
type JumpToTeamInbox = {
  category: InboxCategory.Team;
  id: string;
  teamSummary: TeamSummary;
};
type JumpToViewInbox = {
  category: InboxCategory.View;
  id: string;
  viewSummary: ViewSummary;
};
type JumpToSharedInbox = {
  category: InboxCategory.Shared;
  id: string;
};
type JumpToDashboard = {
  category: InboxCategory.Dashboard;
  id: string;
};

export type JumpToValue =
  | JumpToAdminInbox
  | JumpToTeamInbox
  | JumpToViewInbox
  | JumpToSharedInbox
  | JumpToDashboard;

export default class JumpTo extends ParentAction {
  @service declare session: Session;
  @service declare router: Router;
  @service declare inboxHotkeys: InboxHotkeys;

  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.JumpToAction];
  }

  id = 'jump-to';
  icon = 'arrow' as const;
  searchableTypes = [SearchableType.Admin, SearchableType.Team, SearchableType.View];
  paneComponent = 'inbox2/command-k/jump-to/pane';

  readonly dashboardRoute = 'inbox.workspace.dashboard';
  readonly inboxRoute = 'inbox.workspace.inbox.inbox';

  childForAdmin(admin: AdminSummary | AdminWithPermissions): ChildAction {
    return new ChildAction({
      parent: this,
      id: `admin:${admin.id}`,
      label: admin.name,
      value: {
        category: InboxCategory.Admin,
        id: admin.id.toString(),
        adminSummary: admin,
      } as JumpToAdminInbox,
      icon: new ProfilePictureIcon(admin.imageURL),
    });
  }

  childForTeam(team: TeamSummary): ChildAction {
    return new ChildAction({
      parent: this,
      id: `team:${team.id}`,
      label: team.name,
      value: {
        category: InboxCategory.Team,
        id: team.id.toString(),
        teamSummary: team,
      } as JumpToTeamInbox,
      icon: team.icon,
    });
  }

  childForView(view: ViewSummary): ChildAction {
    return new ChildAction({
      parent: this,
      id: `view:${view.id}`,
      label: view.name,
      value: {
        category: InboxCategory.View,
        id: view.id.toString(),
        viewSummary: view,
      } as JumpToViewInbox,
      icon: view.icon,
    });
  }

  buildTopLevelItems(
    query: string,
    quickSearchResults: SearchMultipleTypesResponse,
  ): Array<ChildAction> {
    return this.buildResults(query, quickSearchResults);
  }

  buildResults(query: string, quickSearchResults: SearchMultipleTypesResponse): Array<ChildAction> {
    let upperQuery = query.trim().toLocaleUpperCase();

    let defaults = [
      new ChildAction({
        parent: this,
        id: 'your-inbox',
        label: this.intl.t('inbox.command-k.jump-to.your-inbox'),
        value: {
          category: InboxCategory.Admin,
          id: this.session.teammate.id.toString(),
          adminSummary: this.session.teammate,
        } as JumpToAdminInbox,
        icon: new ProfilePictureIcon(this.session.teammate.imageURL),
      }),

      new ChildAction({
        parent: this,
        id: 'mentions',
        label: this.intl.t('inbox.command-k.jump-to.mentions'),
        value: {
          category: InboxCategory.Shared,
          id: 'mentions',
        } as JumpToSharedInbox,
        icon: new NamedIcon('email'),
      }),

      new ChildAction({
        parent: this,
        id: 'unassigned',
        label: this.intl.t('inbox.command-k.jump-to.unassigned'),
        value: {
          category: InboxCategory.Shared,
          id: 'unassigned',
        } as JumpToSharedInbox,
        icon: new NamedIcon('unassigned'),
      }),

      new ChildAction({
        parent: this,
        id: 'all',
        label: this.intl.t('inbox.command-k.jump-to.all'),
        value: {
          category: InboxCategory.Shared,
          id: 'all',
        } as JumpToSharedInbox,
        icon: new NamedIcon('multiple-people'),
      }),
    ];

    if (this.session.workspace.isFeatureEnabled('realtime_monitoring')) {
      defaults.push(
        new ChildAction({
          parent: this,
          id: 'dashboard',
          label: this.intl.t('inbox.command-k.jump-to.dashboard'),
          value: {
            category: InboxCategory.Dashboard,
            id: 'dashboard',
          } as JumpToDashboard,
          icon: new NamedIcon('bar-charts'),
        }),
      );
    }

    defaults = defaults.filter((action) => action.label.toLocaleUpperCase().includes(upperQuery));

    let admins =
      quickSearchResults[SearchableType.Admin]?.results?.map((r) =>
        this.childForAdmin(r.data as AdminSummary),
      ) || [];

    let teams =
      quickSearchResults[SearchableType.Team]?.results?.map((r) =>
        this.childForTeam(r.data as TeamSummary),
      ) || [];

    let views =
      quickSearchResults[SearchableType.View]?.results?.map((r) =>
        this.childForView(r.data as ViewSummary),
      ) || [];

    return [...defaults, ...admins, ...teams, ...views].filter((inbox) => !this.skipAction(inbox));
  }

  private skipAction(action: ChildAction): boolean {
    if (action.value.category === InboxCategory.Dashboard) {
      return this.router.isActive(this.dashboardRoute);
    } else {
      return this.router.isActive(this.inboxRoute, action.value.category, action.value.id);
    }
  }
}
