/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { hasMany } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default Model.extend({
  archived_attributes: hasMany('attribute', {
    defaultValue: () => [],
  }),
  archivedAttributes: alias('archived_attributes'),
});
