/* RESPONSIBLE TEAM: team-ai-agent */
import { modifier as buildModifier } from 'ember-modifier';

type Args = {
  isActive: boolean;
  onDisplayEntryPoint: (selectedText: string, selectionBoundingRect: DOMRect) => void;
  onHideEntryPoint: () => void;
};

function isSelectionActionable(range?: Range): boolean {
  if (!range || range.collapsed) {
    return false;
  }

  // Ensure the selection starts in a user content part
  let closestStartingPart =
    range.startContainer.parentElement?.closest('[data-conversation-stream-user-content-blocks]') ||
    range.startContainer.parentElement?.closest(
      '[data-conversation-stream-user-email-content-blocks]',
    );
  return !!closestStartingPart;
}

function fn(element: HTMLElement, _params: [], named: Args) {
  function handleSelection() {
    let selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      named.onHideEntryPoint();
      return;
    }

    if (selection.rangeCount === 0) {
      return;
    }

    let range = selection?.getRangeAt(0);
    if (!isSelectionActionable(range)) {
      return;
    }

    named.onDisplayEntryPoint(selection.toString().trim(), range.getBoundingClientRect());
  }

  if (named.isActive) {
    document.addEventListener('selectionchange', handleSelection);
    element.addEventListener('scroll', handleSelection);
  }

  return function cleanup() {
    if (named.isActive) {
      document.removeEventListener('selectionchange', handleSelection);
      element.removeEventListener('scroll', handleSelection);
    }
  };
}

let modifier = buildModifier(fn, { eager: false });
export default modifier;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'copilot-entry-on-select': typeof modifier;
  }
}
