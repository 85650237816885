/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr } from '@ember-data/model';
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';

export default class RealtimeDashboardSettings extends Model {
  @attr() admin_id;
  @attr() inbox_ids;
  @attr() team_ids;
  @attr() selectedInboxOverviewColumns;
  @attr() selectedTeammateActivityColumns;
  @attr() selectedInboxOverviewTimeframe;
  @attr() selectedTeammateActivityTimeframe;
  @attr() aggregationType;
  @attr({
    defaultValue: 30 * 60,
  })
  idleThreshold;
  @attr({ defaultValue: false }) excludeBotTime;
  @attr() selectedTeammateStatuses;

  get idleThresholdText() {
    return this.formatTime(this.idleThreshold);
  }

  getTimeFormatter() {
    if (!this.timeFormatter) {
      this.timeFormatter = new Formatters[units.seconds]();
    }
    return this.timeFormatter;
  }

  formatTime(value) {
    return value ? this.getTimeFormatter().formatCounter(value) : null;
  }
}
