/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, or } from '@ember/object/computed';

export default Model.extend({
  name: attr(),
  isJavascript: equal('name', 'javascript'),
  isIos: equal('name', 'ios'),
  isAndroid: equal('name', 'android'),
  isCordova: equal('name', 'cordova'),
  isMobile: or('isAndroid', 'isIos', 'isCordova'),
  isRails: equal('name', 'rails'),
  isSegment: equal('name', 'segment'),
  isStripe: equal('name', 'stripe'),
  isWordPress: equal('name', 'wordpress'),
  isSinglePageApp: equal('name', 'single_page'),
  isEmailForwarding: equal('name', 'forward_support_email'),

  formattedName: computed('name', function () {
    if (this.isJavascript) {
      return 'Javascript';
    } else if (this.isRails) {
      return 'Rails';
    } else if (this.isSegment) {
      return 'Segment';
    } else if (this.isIos) {
      return 'iOS';
    } else if (this.isAndroid) {
      return 'Android';
    } else if (this.isCordova) {
      return 'Cordova';
    } else if (this.isSinglePageApp) {
      return 'Single page app';
    } else if (this.isEmailForwarding) {
      return 'Forward support email';
    } else if (this.isWordPress) {
      return 'WordPress';
    } else {
      return 'Javascript';
    }
  }),
});
