/* RESPONSIBLE TEAM: team-tickets-1 */
import { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type BenchmarkAvatarUrls from './benchmark-avatar-urls';

export default class BenchmarkAvatar extends Fragment {
  @attr declare color: string;
  @attr declare initials: string;
  @fragment('benchmark-avatar-urls') declare image_urls: BenchmarkAvatarUrls;
}
