/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import diffCalculator from 'embercom/lib/developer-hub/diff-calculator';
import { readOnly } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations(
  {
    initializeUrl: [
      validator('presence', { presence: true, message: 'This field is required' }),
      validator('format', { type: 'url' }),
      validator('https-except-in-development'),
    ],
    submitUrl: [
      validator('presence', { presence: true, message: 'This field is required' }),
      validator('format', { type: 'url' }),
      validator('https-except-in-development'),
    ],
  },
  {
    disabled: readOnly('model.validationsDisabled'),
  },
);

export default Fragment.extend(Validations, {
  submitUrl: attr('string', { humanisedName: 'Submit URL' }),
  initializeUrl: attr('string', { humanisedName: 'Initialize URL' }),
  enabled: attr('boolean', { humanisedName: 'Works with Inbox', defaultValue: false }),
  validationsDisabled: true,
  initializeUrlErrors: readOnly('validations.attrs.initializeUrl.errors'),
  submitUrlErrors: readOnly('validations.attrs.submitUrl.errors'),
  enabledErrors: readOnly('validations.attrs.enabled.errors'),

  compareWith(modelB) {
    return diffCalculator(this, modelB);
  },

  validate() {
    this.set('validationsDisabled', false);
    return this._super(...arguments);
  },

  rollbackAttributes() {
    this.set('validationsDisabled', true);
    return this._super(...arguments);
  },
});
