/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import ajax from 'embercom/lib/ajax';
import { fragment } from 'ember-data-model-fragments/attributes';
import {
  stampClasses,
  statusLabels,
  statusDescriptions,
} from 'embercom/models/data/whatsapp/whatsapp-constants';
import { capitalize } from '@ember/string';

export default class WhatsappIntegration extends Model {
  @service store;
  @service intl;

  @attr('string') wabacId;
  @attr('string') phoneNumber;
  @attr('string') whatsappAccountId;
  @attr('string') businessId;
  @attr('string') businessName;
  @attr('string') businessVerificationStatus;
  @attr('string') displayName;
  @attr('string') displayNameStatus;
  @attr('string') whatsappAccountStatus;
  @attr('string') wabacStatus;
  @attr('string') phoneNumberStatus;
  @attr('string') codeVerificationStatus;
  @attr('string') profilePictureUrl;
  @fragment('whatsapp/business-profile-settings') businessProfileSettings;
  @attr('string') testMessageQrCodeUrl;
  @attr('string') testMessageStatus;

  get isWhatsappApproved() {
    return (
      this.displayNameStatus?.toLowerCase() === 'approved' &&
      this.whatsappAccountStatus?.toLowerCase() === 'approved'
    );
  }

  get isPhoneNumberNotConnected() {
    if (this.wabacStatus) {
      return this.wabacStatus.toLowerCase() !== 'connected';
    } else {
      return (
        this.phoneNumberStatus &&
        ['deleted', 'unknown'].includes(this.phoneNumberStatus.toLowerCase())
      );
    }
  }

  get isPhoneNumberPending() {
    return (
      this.phoneNumberStatus &&
      ['pending', 'disconnected'].includes(this.phoneNumberStatus.toLowerCase())
    );
  }

  get isStatusUnknown() {
    return !this.phoneNumberStatus;
  }

  get isPhoneNumberConnected() {
    if (this.wabacStatus) {
      return this.wabacStatus.toLowerCase() === 'connected';
    } else {
      return this.phoneNumberStatus && ['connected'].includes(this.phoneNumberStatus.toLowerCase());
    }
  }

  get isBusinessVerified() {
    return this.businessVerificationStatus?.toLowerCase() === 'verified';
  }

  get isBusinessVerificationRequested() {
    return this.businessVerificationStatus?.toLowerCase() !== 'not_verified';
  }

  get isDisplayNamePending() {
    return ['pending_review', 'available_without_review'].includes(
      this.displayNameStatus?.toLowerCase(),
    );
  }

  get isDisplayNameRejected() {
    return this.displayNameStatus?.toLowerCase() === 'declined';
  }

  get isDisplayNameExpired() {
    return this.displayNameStatus?.toLowerCase() === 'expired';
  }

  get isDisplayNameNone() {
    return this.displayNameStatus?.toLowerCase() === 'none';
  }

  get isWhatsappAccountRejected() {
    return this.whatsappAccountStatus?.toLowerCase() === 'rejected';
  }

  get isWhatsappAccountPending() {
    return this.whatsappAccountStatus?.toLowerCase() === 'pending';
  }

  get displayString() {
    if (isPresent(this.displayName)) {
      return `${this.displayName} (${this.phoneNumber})`;
    } else {
      return this.phoneNumber;
    }
  }

  get invalidWabacStatus() {
    ['disconnected', 'unregistered', 'uninitialized'].includes(this.wabacStatus);
  }

  get whatsappStatus() {
    let displayNameStatus = this.displayNameStatus?.toLowerCase();
    if (this.isDisplayNameRejected || this.isDisplayNameExpired || this.isDisplayNameNone) {
      return displayNameStatus;
    } else if (this.isWhatsappAccountRejected) {
      return 'rejected';
    } else if (this.isPhoneNumberNotConnected) {
      return 'disconnected';
    } else if (this.isPhoneNumberPending) {
      return 'pending';
    } else if (this.isPhoneNumberConnected) {
      return 'connected';
    } else {
      return this.isPhoneNumberConnected;
    }
  }

  get stampClass() {
    return this.getStampClass(this.whatsappStatus);
  }

  get statusLabel() {
    return this.getStatusLabel(this.whatsappStatus);
  }

  get statusDescription() {
    if (
      !this.whatsappStatus ||
      this.isDisplayNameRejected ||
      this.isDisplayNameExpired ||
      this.isDisplayNameNone ||
      !statusDescriptions[this.whatsappStatus?.toLowerCase()]
    ) {
      return;
    }
    return this.intl.t(statusDescriptions[this.whatsappStatus?.toLowerCase()]);
  }

  get businessStampClass() {
    return this.getStampClass(this.businessVerificationStatus);
  }

  get businessStatusLabel() {
    return this.getStatusLabel(this.businessVerificationStatus);
  }

  get sender() {
    return {
      id: this.id,
      phoneNumber: this.phoneNumber,
      displayName: this.displayName,
      displayString: this.displayString,
    };
  }

  getStampClass(status) {
    let stampClass = stampClasses[status?.toLowerCase()];
    return stampClass || 'o__light-blue';
  }

  getStatusLabel(status) {
    if (!status) {
      return this.intl.t(statusLabels['unknown']);
    }
    if (statusLabels[status?.toLowerCase()]) {
      return this.intl.t(statusLabels[status?.toLowerCase()]);
    }
    return capitalize(status?.toLowerCase() ?? '');
  }

  async uploadProfilePicture(params) {
    let { app, image } = params;
    let formData = new FormData();
    formData.append('integration_id', this.id);
    formData.append('app_id', app.id);
    formData.append('profile_picture', image);
    let response = await ajax({
      type: 'POST',
      url: '/ember/whatsapp/integrations/update_picture',
      data: formData,
      contentType: false,
      processData: false,
    });
    this.store.pushPayload({
      'whatsapp/integration': { id: this.id, profile_picture_url: response?.url },
    });
  }

  async updateBusinessProfileSettings(params) {
    let { app } = params;
    let response = await ajax({
      type: 'POST',
      url: '/ember/whatsapp/integrations/update_business_profile_settings',
      data: JSON.stringify({
        integration_id: this.id,
        app_id: app.id,
        business_profile_settings: {
          about: this.businessProfileSettings.about,
          address: this.businessProfileSettings.address,
          description: this.businessProfileSettings.description,
          email: this.businessProfileSettings.email,
          vertical: this.businessProfileSettings.vertical,
          websites: this.businessProfileSettings.websites,
        },
      }),
    });
    this.store.pushPayload({
      'whatsapp/integration': {
        id: this.id,
        businessProfileSettings: response?.business_profile_settings,
      },
    });
  }

  get analyticsData() {
    return {
      object: 'whatsapp_integration',
      whatsapp_integration_id: this.id,
    };
  }
}
