/* RESPONSIBLE TEAM: team-frontend-tech */
import { copy } from 'ember-copy';
let Json = {
  serialize(hash) {
    return hash;
  },
  deserialize(hash) {
    return copy(hash, true);
  },
};

export default Json;
