/* RESPONSIBLE TEAM: team-knowledge-interop */
import Model, { attr } from '@ember-data/model';

export default class ContentReviewRequest extends Model {
  @attr('number') declare reviewerId: number;
  @attr('number') declare status: number;
  @attr('number') declare entityId: number;
  @attr('number') declare entityType: number;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
}
