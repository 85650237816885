/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';

export default Model.extend({
  appId: attr('string'),
  capacityLimit: attr('number'),
  sortingCriteria: attr('array'),
  pullConversationEnabled: attr('boolean'),
  ticketsAssignmentDisabled: attr('boolean'),
});
