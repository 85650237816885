/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { ALL_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  durationType: [
    validator('inclusion', {
      in: ['later_today', 'tomorrow', 'monday', 'next_month', 'custom'],
      messageKey: 'operator.workflows.visual-builder.validations.snooze.duration-type',
    }),
  ],
  customDuration: [
    validator('number', {
      allowNone: true,
      integer: true,
      gte: 60,
      lte: 180 * 24 * 60 * 60, // 180 days in seconds
      messageKey: 'operator.workflows.visual-builder.validations.snooze.custom-duration',
    }),
  ],
});

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export default class Snooze extends Step.extend(Validations) {
  @attr('number') declare customDuration?: number;
  @attr('string') declare durationType: string;

  supportedChannels = ALL_CHANNELS;

  static get supportedContexts() {
    return [
      TargetContext.Conversation,
      TargetContext.CustomerTicket,
      TargetContext.BackOfficeTicket,
      TargetContext.TrackerTicket,
    ];
  }

  static get requiredBillingFeature() {
    return 'inbox_rules';
  }

  static get isStepGroupEnding() {
    return false;
  }

  static createNewStep(store: Store): Snooze {
    return store.createRecord('operator/visual-builder/step/snooze', {
      type: 'operator/visual-builder/step/snooze',
      durationType: 'custom',
      customDuration: 60,
    });
  }

  get days() {
    return Math.floor((this.customDuration || 0) / DAY);
  }

  get hours() {
    return Math.floor(((this.customDuration || 0) % DAY) / HOUR);
  }

  get minutes() {
    return Math.floor(((this.customDuration || 0) % HOUR) / MINUTE);
  }

  get minDuration() {
    return 60;
  }

  get maxDuration() {
    return 180 * 24 * 60 * 60; // 180 days in seconds
  }
}
