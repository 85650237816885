/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { getOwner } from '@ember/application';
import taggerEditorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/tagger';
import { buildValidations } from 'ember-cp-validations';

// When used as a RulesetLink object, the matchable object must implement the validations library.
// For this model, we don't have any specific validations so we can build an empty validation set.
const TaggerValidations = buildValidations({});

type TagId = number | string;

export default class Tagger extends Model.extend(TaggerValidations) {
  @attr('array') declare tagIds: TagId[];
  @attr('array') declare untagIds: TagId[];

  addTag(tagId: TagId) {
    let _tagId = Number(tagId);

    if (this.tagIds.includes(_tagId)) {
      return;
    }

    this.tagIds.pushObject(_tagId);
  }

  removeTag(tagId: TagId) {
    this.tagIds.removeObject(Number(tagId));
  }

  addUntag(tagId: TagId) {
    let _tagId = Number(tagId);

    if (this.untagIds.includes(_tagId)) {
      return;
    }

    this.untagIds.pushObject(_tagId);
  }

  removeUntag(tagId: TagId) {
    this.untagIds.removeObject(Number(tagId));
  }

  editorConfig() {
    let container = getOwner(this);
    return taggerEditorConfigGenerator({ container });
  }
}
