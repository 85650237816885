/* RESPONSIBLE TEAM: team-frontend-tech */
import Block from './block';
import { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';

export default Block.extend({
  style: fragment('common/blocks/style'),
  text: attr('string', {
    defaultValue: '___',
  }),
});
