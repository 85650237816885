/* RESPONSIBLE TEAM: team-proactive-support */
import { seriesEdgeTypes } from 'embercom/models/data/matching-system/matching-constants';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';
import ComponentConfiguration from 'embercom/objects/component-configuration';

export default class ConnectorConfiguration extends ComponentConfiguration {
  edgeType = undefined;
  customLabel = undefined;

  constructor(inputs) {
    super(inputs);
    assert('You must provide an edge type for a connector', isPresent(inputs.edgeType));
    assert(
      'The provided edge type is unknown',
      Object.values(seriesEdgeTypes).includes(inputs.edgeType),
    );

    this.edgeType = inputs.edgeType;
    this.customLabel = inputs.label;
  }

  get label() {
    return this.customLabel;
  }

  get canConnect() {
    return true;
  }
}
