/* RESPONSIBLE TEAM: team-channels */
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import type Store from '@ember-data/store';
import { ALL_CHANNELS_EXCEPT_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  ticketTypeId: [validator('presence', true)],

  hasArchivedTicketType: {
    descriptionKey: 'operator.workflows.visual-builder.archived-ticket',
    validators: [
      validator('convert-to-ticket-has-valid-ticket-type', {
        dependentKeys: ['model.ticketTypeId'],
      }),
    ],
  },
});

export default class ConvertToTicket extends Step.extend(Validations) {
  @attr('number') declare ticketTypeId?: number;
  supportedChannels = ALL_CHANNELS_EXCEPT_PHONE_CALL;

  static get supportedContexts() {
    return [TargetContext.Conversation];
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  static createNewStep(store: Store): ConvertToTicket {
    return store.createRecord('operator/visual-builder/step/convert-to-ticket', {
      type: 'operator/visual-builder/step/convert-to-ticket',
      justAdded: true,
    });
  }
}
