/* RESPONSIBLE TEAM: team-workflows */

import EditorConfig, {
  type PathConfigGenerationParams,
  type StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import { AiAgentProfilePathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { ProfileHandoverReplyButtonsConfiguration } from 'embercom/objects/visual-builder/configuration/step/reply-buttons';
import type MutableArray from '@ember/array/mutable';
import type Step from 'embercom/models/operator/visual-builder/step';

export default class AiAgentProfileEditorConfig extends EditorConfig {
  public targetChannels: MutableArray<string>;

  constructor({ targetChannels }: { targetChannels: MutableArray<string> }) {
    super({ areStepPaywallsActive: false });
    this.targetChannels = targetChannels;
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new AiAgentProfilePathConfig({ path, editorState });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.replyButtons:
        return new ProfileHandoverReplyButtonsConfiguration(stepConfigArgs);
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }
}
