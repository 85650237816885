/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
// eslint-disable-next-line @intercom/intercom/max-file-length
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { and, equal, gt, not, notEmpty, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isAny } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import { copy } from 'ember-copy';
import { fragment } from 'ember-data-model-fragments/attributes';
import { post, put, get } from 'embercom/lib/ajax';
import nonconcurrentAjax from 'embercom/lib/nonconcurrent-ajax';
import {
  matchBehaviors,
  states,
  objectTypes,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { contentWrapperTypes, emailTemplateTypes } from 'embercom/models/data/outbound/constants';
import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import RulesetValidations from 'embercom/validations/ruleset';
import Predicate from 'predicates/models/predicate';
import ImplicitPredicatesGenerator from 'embercom/helpers/matching-system/implicit-predicates-generator';
import { isNone } from '@ember/utils';
import { objectWithVersions } from 'embercom/models/data/matching-system/matching-constants';
import { matchingLocations } from 'embercom/models/data/matching-system/matching-constants';
import md5 from 'blueimp-md5';
import ContentDependency from 'embercom/models/matching-system/content-dependency';
import { dasherize } from '@ember/string';

const REQUIRED_USERS_FOR_UI = 10;
const MAX_TESTS_ALLOWED = 2;

export default Model.extend(RulesetValidations, TaggingMixin, {
  entityType: objectTypes.ruleset,
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  notificationsService: service(),
  lastSavedAt: null,
  state: attr('number'),
  isLive: equal('state', states.live),
  isDraft: equal('state', states.draft),
  isScheduled: equal('state', states.scheduled),
  isPaused: equal('state', states.paused),
  matchBehavior: attr('number'),
  isMatchBehaviorSingle: equal('matchBehavior', matchBehaviors.single),
  isMatchBehaviorStatic: equal('matchBehavior', matchBehaviors.static),
  isMatchBehaviorMulti: equal('matchBehavior', matchBehaviors.multi),
  predicateGroup: fragment('predicates/predicate-group'),
  clientPredicateGroup: fragment('predicates/predicate-group'),
  rolePredicateGroup: fragment('predicates/predicate-group'),
  defaultPredicateGroup: fragment('predicates/predicate-group'),
  rulesetLinks: hasMany('matching-system/ruleset-link', {
    polymorphic: true,
    async: false,
  }),
  rulesetTriggers: hasMany('matching-system/ruleset-trigger'),
  matchingTimetable: fragment('matching-system/matching-timetable'),
  recurringSchedule: fragment('matching-system/recurring-schedule'),
  scheduledActivation: fragment('matching-system/scheduled-state-change'),
  scheduledDeactivation: fragment('matching-system/scheduled-state-change'),
  goal: fragment('stats-system/goal'),
  utmSettings: fragment('content-service/utm-settings'),
  currentVersion: belongsTo('matching-system/ruleset-version'),
  clientData: belongsTo('matching-system/client-data', {
    async: false,
  }),
  node: computed(function () {
    return this.store
      .peekAll('series/node')
      .find((node) => Number(node.rulesetId) === Number(this.id));
  }),
  rulesetErrors: belongsTo('matching-system/ruleset-errors', { async: false }),
  isNewRuleset: false,
  isNotNewRuleset: not('isNewRuleset'),
  seriesRelation: attr(),
  isMemberOfSeries: notEmpty('seriesRelation'),
  createdAt: attr('date'),
  matchSpacingPeriod: attr('number', { defaultValue: -1 }),
  maxMatchCount: attr('number', { defaultValue: -1 }),
  audienceValid: and(
    'validations.attrs.predicateGroup.isValid',
    'validations.attrs.clientPredicateGroup.isValid',
    'validations.attrs.userRolePredicate.isValid',
  ),
  isUsingWebOnlyPredicates: computed(
    'clientPredicateGroup.predicates.@each.attribute',
    function () {
      return [...Predicate.walkPredicates(this.clientPredicateGroup.predicates)].any(
        (predicate) => predicate.isTimeOnPage || predicate.isPageTarget,
      );
    },
  ),

  hasMatchingTimetable: notEmpty('matchingTimetable'),
  hasRecurringSchedule: notEmpty('recurringSchedule'),
  hasRulesetTriggers: gt('rulesetTriggers.length', 0),
  hasGoal: notEmpty('goal'),
  hasNoGoal: not('hasGoal'),
  userRolePredicate: computed(
    'rolePredicateGroup.userPredicate',
    'predicateGroup.userPredicate',
    function () {
      let predicateFromPredicateGroup = this.get('predicateGroup.userPredicate');
      let predicateFromRolePredicateGroup = this.get('rolePredicateGroup.userPredicate');
      if (predicateFromPredicateGroup && predicateFromRolePredicateGroup) {
        throw new Error(
          `Ruleset with id=${this.id} has user role predicates from both predicate groups`,
        );
      }
      return predicateFromPredicateGroup || predicateFromRolePredicateGroup;
    },
  ),
  userType: or('rolePredicateGroup.userType', 'predicateGroup.userType'),
  tags: hasMany('tag', { async: true }),
  taggings: hasMany('tagging'),
  updateTaggingUrl: '/ember/content_service/contents/update_tags',

  canCreateTest: computed('rulesetLinks.length', 'rulesetLinks.@each.objectType', function () {
    return (
      this.rulesetLinks.length < MAX_TESTS_ALLOWED &&
      this.rulesetLinks.any((link) => !link.isObjectTypeWithoutAbTesting)
    );
  }),
  hasTests: gt('rulesetLinks.length', 1),
  hasControlGroup: isAny('rulesetLinks', 'isControlGroup', true),
  hasDifferentRulesetLinkTypes: computed(
    'rulesetLinks.length',
    'rulesetLinks.@each.objectType',
    function () {
      let objectTypes = this.rulesetLinks.map((link) => link.objectType);
      return new Set(objectTypes).size === this.rulesetLinks.length;
    },
  ),
  hasContentWithVersions: computed(
    'rulesetLinks.length',
    'rulesetLinks.@each.objectType',
    function () {
      let objectTypes = this.rulesetLinks.map((link) => link.objectType);
      return objectWithVersions.some((objectType) => objectTypes.indexOf(objectType) >= 0);
    },
  ),
  hasRulesetLinkInDraftState: isAny('rulesetLinks', 'isDraft'),

  hasUnsavedChanges: computed(
    'hasDirtyAttributes',
    'rulesetLinks.@each.hasUnsavedChanges',
    'rulesetTriggers.@each.hasUnsavedChanges',
    'clientData.hasUnsavedChanges',
    function () {
      return (
        this.hasDirtyAttributes ||
        this.rulesetLinks.any((link) => link?.hasUnsavedChanges) ||
        this.rulesetTriggers.any((trigger) => trigger.hasUnsavedChanges) ||
        this.clientData?.hasUnsavedChanges
      );
    },
  ),

  isTriggeredByCode: computed('rulesetLinks.@each.matchingLocations', function () {
    return this.rulesetLinks.any((rulesetLink) =>
      rulesetLink.matchingLocations.includes(matchingLocations.TRIGGER_FROM_CODE),
    );
  }),

  isTriggeredByOperator: computed('rulesetLinks.@each.matchingLocations', function () {
    return this.rulesetLinks.any((rulesetLink) =>
      rulesetLink.matchingLocations.includes(matchingLocations.TRIGGER_FROM_OPERATOR),
    );
  }),

  analyticsData: computed('id', function () {
    return {
      object: 'ruleset',
      id: this.id,
    };
  }),

  rollbackAttributes() {
    this._super(...arguments);
    this.rulesetLinks.forEach((link) => link.rollbackAttributes());
    this.rulesetTriggers.forEach((trigger) => trigger.rollbackAttributes());
    this.clientData?.rollbackAttributes();
  },

  hash() {
    return md5(JSON.stringify(this.serialize()));
  },

  isMultiMatchAndEventTriggered: computed('matchBehavior', 'rulesetTriggers', function () {
    let hasEventTriggers = this.rulesetTriggers.length > 0;
    return this.isMatchBehaviorMulti && hasEventTriggers;
  }),

  beforeEdit() {
    this.rulesetLinks.forEach((link) => link.beforeEdit());
  },

  async save() {
    this.rulesetLinks.forEach((link) => {
      if (link.isMigratingEmailTemplate) {
        this.clientData.templateMigrationAccepted.pushObject(link.id);
        this.intercomEventService.trackAnalyticsEvent({
          object: 'email',
          action: 'template_migrated',
          ruleset: this.id,
          ruleset_link: link.id,
        });
      }
      link.beforeSave();
    });
    this.set('isNewRuleset', false);
    await this._super(...arguments);
    this.set('lastSavedAt', new Date());
    this.rulesetLinks.forEach((link) => link.afterSave());
    this._clearLocallyCreatedRulesetTriggers();
  },

  _clearLocallyCreatedRulesetTriggers() {
    let locallyCreatedRulesetTriggers = this.rulesetTriggers.filter((trigger) =>
      isNone(trigger.id),
    );
    locallyCreatedRulesetTriggers.forEach((trigger) => this.store.unloadRecord(trigger));
  },

  async activate(rulesetLinkId) {
    let params = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
    };
    if (rulesetLinkId) {
      params.ruleset_link_id = rulesetLinkId;
    }
    await put(`/ember/matching_system/rulesets/${this.id}/activate`, params);

    let activatedObjectName = objectNames[this.rulesetLinks.firstObject.objectType];
    if (activatedObjectName) {
      this.intercomEventService.trackEvent(`set-live-${dasherize(activatedObjectName)}`);

      if (this._isCustomTemplateEmail(this.rulesetLinks.firstObject)) {
        let localizedEmailContent = this._getLocalizedEmailContent(this.rulesetLinks.firstObject);
        if (localizedEmailContent.emailTemplate.isBlockTemplate) {
          this._trackEmailSetLive('block');
        } else {
          this._trackEmailSetLive('html');
        }
      }
    }

    await this.reload();
  },

  async deactivate(rulesetLinkId) {
    let params = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
    };
    if (rulesetLinkId) {
      params.ruleset_link_id = rulesetLinkId;
    }
    await put(`/ember/matching_system/rulesets/${this.id}/deactivate`, params);
    await this.reload();
  },

  async schedule() {
    let params = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
    };
    await put(`/ember/matching_system/rulesets/${this.id}/schedule`, params);
    await this.reload();
  },

  async unschedule() {
    let params = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
    };
    await put(`/ember/matching_system/rulesets/${this.id}/unschedule`, params);
    await this.reload();
  },

  async reorder(previousContentWrapper, nextContentWrapper) {
    let params = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
      previous_ruleset_id: previousContentWrapper?.contentWrapperId,
      next_ruleset_id: nextContentWrapper?.contentWrapperId,
    };

    let reorderResponse = await put(`/ember/matching_system/rulesets/${this.id}/reorder`, params);
    await this.reload();
    return reorderResponse;
  },

  // @returns Array<ContentDependency>
  async loadDependentObjects() {
    let dependencies = await Promise.all([
      this.fetchRulesetDependencies(),
      ...this.rulesetLinks.map((link) => link.loadDependentObjects()),
    ]);
    return dependencies.flat(Infinity).uniq();
  },

  async fetchRulesetDependencies() {
    let dependencies = await get('/ember/content_service/contents/list_dependents', {
      app_id: this.app.id,
      target_entity_id: this.id,
      target_entity_type: objectTypes.ruleset,
    });
    return dependencies.map((json) => new ContentDependency(json));
  },

  async loadDependentSeries() {
    return await Promise.all(this._findAllSeriesToFetch(this.predicateGroup.predicates));
  },

  _findAllSeriesToFetch(predicates, seriesToFetch = []) {
    predicates.forEach((predicate) => {
      if (predicate.isContentEventPredicate && predicate.isSeriesEvent) {
        seriesToFetch.push(this.store.findRecord('series/series', predicate.value));
      } else if (predicate.isLogicalType) {
        this._findAllSeriesToFetch(predicate.predicates, seriesToFetch);
      }
    });

    return seriesToFetch;
  },

  taggable: computed('taggings', 'updateTaggingsTask', function () {
    return {
      tags: [],
      taggings: this.taggings,
      type: 'content',
      updateTaggings: (admin, addedTags, removedTags, initialTags) => {
        return this.updateTaggingsTask.perform(addedTags, removedTags);
      },
    };
  }),

  updateTaggingsTask: task(function* (addedTags, removedTags) {
    yield put(this.updateTaggingUrl, {
      app_id: this.app.id,
      added_tag_ids: addedTags.map((tag) => tag.id),
      removed_tag_ids: removedTags.map((tag) => tag.id),
      content_wrapper_id: this.id,
      content_wrapper_type: contentWrapperTypes.ruleset,
    });
  }),

  fetchAudiencePreviewTask: task(function* (ignoredAttributeTypesForPreview) {
    yield this.fetchAudiencePreview(ignoredAttributeTypesForPreview);
  }).restartable(),

  _post(url, additionalData = {}) {
    return this._request(url, 'POST', additionalData);
  },

  _request(url, requestType = 'POST', additionalData = {}) {
    let data = Object.assign(additionalData, {
      id: this.id,
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
    });
    if (requestType === 'POST') {
      data = JSON.stringify(data);
    }
    return nonconcurrentAjax(this, {
      url,
      type: requestType,
      data,
    });
  },

  _isCustomTemplateEmail(rulesetLink) {
    if (rulesetLink.isEmail) {
      let localizedEmailContent = this._getLocalizedEmailContent(rulesetLink);
      if (
        localizedEmailContent &&
        localizedEmailContent.emailTemplateType === emailTemplateTypes.custom
      ) {
        return true;
      }
    }

    return false;
  },

  _getLocalizedEmailContent(rulesetLink) {
    return this.rulesetLinks.firstObject?.object?.localizedEmailContents?.firstObject;
  },

  _trackEmailSetLive(custom_template_type) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'email',
      action: 'set_live',
      custom_template_type,
    });
  },

  fetchAudiencePreview(ignoredAttributeTypesForPreview) {
    let predicateGroup = copy(this.predicateGroup);
    let rolePredicateGroup = copy(this.rolePredicateGroup);
    let defaultPredicateGroup = copy(this.defaultPredicateGroup);

    if (!this.predicateGroup.isValid) {
      this.set('audiencePreview', {});
      return Promise.resolve();
    }
    return this.fetchAudiencePreviewRequest(ignoredAttributeTypesForPreview).then(
      (response) => {
        let store = this.store;
        let data = store.normalize('messages/audience-preview', {
          users: response.users,
          count: response.total_count,
          limited: response.limited,
        }).data.attributes;
        data = Object.assign(data, { predicateGroup, rolePredicateGroup, defaultPredicateGroup });
        let audiencePreview = store.createFragment('messages/audience-preview', data);
        this.set('audiencePreview', audiencePreview);
      },
      (err) => {
        this.notificationsService.notifyResponseError(err);
      },
    );
  },

  duplicate(params) {
    return this._post(`/ember/matching_system/rulesets/${this.id}/duplicate`, params);
  },

  exportData(params) {
    return this._post(`/ember/matching_system/rulesets/${this.id}/export`, params);
  },

  filterOutIgnoredPredicates(predicates, ignoredAttributeTypesForPreview) {
    return predicates
      .filter(
        (predicate) =>
          predicate.predicates ||
          !ignoredAttributeTypesForPreview.any((ignoredAttributeType) =>
            predicate.attribute.startsWith(ignoredAttributeType),
          ),
      )
      .map((predicate) => {
        if (predicate.predicates) {
          predicate.predicates = this.filterOutIgnoredPredicates(
            predicate.predicates,
            ignoredAttributeTypesForPreview,
          );
        }

        return predicate;
      });
  },

  predicatesForAudiencePreview(ignoredAttributeTypesForPreview = []) {
    let predicates = this.get('predicateGroup.predicates').serialize();

    let filteredPredicates = this.filterOutIgnoredPredicates(
      predicates,
      ignoredAttributeTypesForPreview,
    );
    let rolePredicates = this.get('rolePredicateGroup.predicates').serialize();
    let defaultPredicates = this.get('defaultPredicateGroup.predicates').serialize();
    let implicitPredicates = this.implicitPredicates || [];
    let additionalPredicates = this.additionalPredicates;
    return filteredPredicates
      .concat(rolePredicates)
      .concat(defaultPredicates)
      .concat(implicitPredicates)
      .concat(additionalPredicates);
  },

  additionalPredicates: computed('node.series.exitPredicateGroup.predicates.[]', function () {
    if (this.isMemberOfSeries && this.node.series?.hasExitRules) {
      return this.negatePredicates(this.node.series.exitPredicateGroup.predicates.serialize());
    }
    return [];
  }),

  negatePredicates(predicates) {
    return [
      {
        type: 'not',
        predicates,
      },
    ];
  },

  fetchAudiencePreviewRequest(ignoredAttributeTypesForPreview) {
    let allPredicates = this.predicatesForAudiencePreview(ignoredAttributeTypesForPreview);
    let params = {
      app_id: this.get('app.id'),
      predicates: allPredicates,
      page: 1,
      per_page: REQUIRED_USERS_FOR_UI,
      include_count: true,
    };
    return this._post('/ember/users/search.json', params);
  },

  trackCreationEvent() {
    let objectName = objectNames[this.rulesetLinks.firstObject.objectType];
    if (objectName) {
      this.intercomEventService.trackEvent(`created-draft-${dasherize(objectName)}`);
    }
  },

  implicitPredicates: computed('rulesetLinks.@each.implicitPredicates', function () {
    return ImplicitPredicatesGenerator.generate(this.rulesetLinks);
  }),
}).reopenClass({
  async createForType(store, params) {
    let response = await post('/ember/matching_system/rulesets', params);
    store.pushPayload('matching-system/ruleset', { 'matching-system/rulesets': [response] });
    let ruleset = store.peekRecord('matching-system/ruleset', response.id);
    ruleset.set('isNewRuleset', true);
    ruleset.trackCreationEvent();
    return ruleset;
  },
});
