/* RESPONSIBLE TEAM: team-phone */
import moment from 'moment-timezone';
import { type Call } from '@twilio/voice-sdk';
import type Conversation from 'embercom/objects/inbox/conversation';

export default class PhoneCall extends EventTarget {
  phoneCall: Call;
  conversation: Conversation;
  duration: number | null;
  hangedUpByAdmin: boolean;

  private currentType: string | null;
  private _startTime: moment.Moment | null;
  private _endTime: moment.Moment | null;

  get startTime() {
    return this._startTime?.valueOf();
  }

  constructor(initializedCall: Call, conversation: Conversation) {
    super();

    this.phoneCall = initializedCall;
    this.currentType = null;
    this.conversation = conversation;
    this._startTime = null;
    this._endTime = null;
    this.duration = null;
    this.hangedUpByAdmin = false;

    this.setupCallEvent('accept');
    this.setupCallEvent('ringing');
    this.setupCallEvent('disconnect');
    this.setupCallEvent('cancel');
    this.setupCallEvent('error');
  }

  setupCallEvent(type: string) {
    let eventPayload: { detail: { type: string; oldType: string | null } } = {
      detail: { type, oldType: null },
    };
    let newEvent = new CustomEvent('stateChange', eventPayload);
    this.phoneCall.on(type, () => {
      if (type === 'accept') {
        this.handleAccepted();
      } else if (type === 'disconnect') {
        this.handleDisconnected();
      }

      newEvent.detail.oldType = this.currentType;
      this.currentType = type;

      this.dispatchEvent(newEvent);
    });
  }

  handleAccepted() {
    this._startTime = moment();
  }

  handleDisconnected() {
    if (!this._startTime) {
      this.duration = 0;
    } else {
      this._endTime = moment();
      this.duration = moment
        .duration(this._endTime.valueOf() - this._startTime.valueOf(), 'milliseconds')
        .asSeconds();
    }
  }

  onStateChange(fn: (event: CustomEvent) => void) {
    this.addEventListener('stateChange', fn);
  }

  isMuted() {
    return this.phoneCall.isMuted();
  }

  hangUp() {
    this.hangedUpByAdmin = true;
    this.phoneCall.disconnect();
  }

  pressDigit(digit: string) {
    this.phoneCall.sendDigits(digit);
  }

  mute() {
    let isMuted = this.isMuted();

    this.phoneCall.mute(!isMuted);
    return !isMuted;
  }
}
