/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import { alias } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  index: attr('number'),
  unicodeEmoticon: attr('string'),

  //Temporary while we work with supporting both JSON and DS.Model based reactions
  unicode_emoticon: alias('unicodeEmoticon'),
});
