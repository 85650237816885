/* RESPONSIBLE TEAM: team-frontend-tech */
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';

const isFirefox = UserAgentDetector.isFirefox();

let replaceAsterisk = function (url) {
  return url.replace(/\*/g, '%2A');
};

let urlWithHttpPrefix = function (url) {
  if (isFirefox) {
    url = replaceAsterisk(url); //https://github.com/intercom/intercom/issues/190568
  }
  if (url.match(/^http(?:s?):\/\//)) {
    return url;
  }
  return `http://${url}`;
};

let parseUrl = function (url) {
  let isWildCard = url.includes('*');
  let shouldReplaceUrl = isFirefox && isWildCard;
  let parser = document.createElement('a');
  parser.href = urlWithHttpPrefix(url);

  return {
    original: url,
    pathname: shouldReplaceUrl ? '/' : parser.pathname,
    hostname: shouldReplaceUrl ? url : parser.hostname?.replace(/\%2A/g, '*'),
    protocol: parser.protocol,
    port: parser.port,
    search: parser.search,
    hash: parser.hash,
    host: shouldReplaceUrl ? url : parser.host,
  };
};

export default parseUrl;
