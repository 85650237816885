/* RESPONSIBLE TEAM: team-workflows */

import Model, { attr } from '@ember-data/model';

export default class CustomBotsSettings extends Model {
  @attr('boolean') declare autoClosingForUsers: boolean;
  @attr('boolean') declare autoClosingForVisitors: boolean;
  @attr('boolean') declare autoClosingFromButton: boolean;
  @attr('boolean') declare autoClosingFromNewConversationUsers: boolean;
  @attr('boolean') declare autoClosingFromNewConversationVisitors: boolean;
  @attr('boolean') declare autoClosingConversationStarted: boolean;
  @attr('boolean') declare autoClosingNewComment: boolean;
  @attr('boolean') declare autoClosingUserInactivity: boolean;
  @attr('boolean') declare autoClosingAdminInactivity: boolean;
  @attr('boolean') declare autoClosingConversationStatusChanged: boolean;
  @attr('number') declare autoClosingTimerMinutes: number;
}
