/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
import { A, isArray } from '@ember/array';
import EmberObject, { computed, get, observer } from '@ember/object';
import { equal, gt, mapBy, none, not, notEmpty, or, reads } from '@ember/object/computed';
import { isEmpty, isPresent } from '@ember/utils';
import {
  applyFunction,
  subtractProperties,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import PredicateValidator from 'embercom/models/mixins/predicate-validator';

let SelectionState = EmberObject.extend(PredicateValidator, {
  isSelectAllMode: false,
  selected: null,
  unselected: null,
  predicates: null,
  totalCount: null,
  search: '',
  isNotSelectAllMode: not('isSelectAllMode'),
  hasCount: not('hasNoCount'),
  hasNoCount: none('count'),
  hasPredicates: notEmpty('predicates'),
  hasNoPredicates: not('hasPredicates'),
  hasUserAddedPredicates: notEmpty('activePredicatesWithoutAnonymous'),
  isTargetingLeads: reads('hasAnonymousPredicate'),
  hasIncludedIds: notEmpty('included_ids'),
  hasExcludedIds: notEmpty('excluded_ids'),
  isSelectionBased: or('hasIncludedIds', 'hasExcludedIds'),
  isNotSelectionBased: not('isSelectionBased'),
  isUserMessage: not('isCompanyMessage'),
  hasMultipleRecipients: gt('count', 1),
  countIsZero: equal('count', 0),
  countIsZeroOrNone: or('countIsZero', 'hasNoCount'),
  lastUpdatedAt: computed('predicatesUpdated', 'selected.[]', 'unselected.[]', function () {
    return new Date().getTime();
  }),
  nestedPredicates: [],
  nullifyCountWhenPredicatesChange: observer({
    dependentKeys: ['predicatesUpdated'],

    fn() {
      this.set('totalCount', null);
    },

    sync: false,
  }),
  hasTotalCount: applyFunction(isPresent, 'totalCount'),
  hasNoTotalCount: not('hasTotalCount'),
  checkIsCompanyPredicate(predicate, regex) {
    let self = this;
    if (get(predicate, 'type') === 'or') {
      return predicate.predicates.reduce(function (accumulator, orPredicate) {
        return accumulator && self.checkIsCompanyPredicate(orPredicate, regex);
      }, !isEmpty(predicate.predicates));
    } else if (get(predicate, 'type') === 'and') {
      return predicate.predicates.reduce(function (accumulator, andPredicate) {
        return accumulator && self.checkIsCompanyPredicate(andPredicate, regex);
      }, !isEmpty(predicate.predicates));
    } else {
      return get(predicate, 'attribute').match(regex) !== null;
    }
  },
  isCompanyMessage: computed('predicates', 'selected', 'unselected', 'search', function () {
    let self = this;
    let predicates = this.predicates;
    return (
      isEmpty(this.selected) &&
      isEmpty(this.unselected) &&
      isEmpty(this.search) &&
      isArray(predicates) &&
      predicates.reduce(function (accumulator, predicate) {
        return accumulator && self.checkIsCompanyPredicate(predicate, /^company/);
      }, !isEmpty(predicates))
    ); //If predicates are empty this resolves to false
  }),
  deselected: computed(function () {
    return this.isSelectAllMode ? this.unselected : A();
  }),
  countFromDeselected: subtractProperties('totalCount', 'deselected.length'),
  count: ternaryToProperty('isSelectAllMode', 'countFromDeselected', 'selected.length'),
  isSingleSelection: equal('count', 1),
  selectedIDs: mapBy('selected', 'id'),
  deselectedIDs: mapBy('deselected', 'id'),
  // nolaneo - Due to a discrepancy between how we use included_ids vs selectedIDs
  // I've added a one way binding so we can fall through to selectedIDs if included_ids isn't set
  included_ids: reads('selectedIDs'),
  excluded_ids: reads('deselectedIDs'),
  toApi() {
    let isSelectAllMode = this.isSelectAllMode;
    return {
      included_ids: isSelectAllMode ? [] : this.selectedIDs,
      excluded_ids: this.deselectedIDs,
      predicates: isSelectAllMode ? this.predicates : [],
      count: this.count,
    };
  },
  toSelectAllSelection() {
    return SelectionState.create({
      isSelectAllMode: true,
      predicates: this.predicates,
      selected: [],
      unselected: [],
      count: this.totalCount,
    });
  },
  toJSON: computed(
    'selected',
    'unselected',
    'predicates',
    'totalCount',
    'search',
    'isSelectAllMode',
    function () {
      let properties = [
        'selected',
        'unselected',
        'predicates',
        'totalCount',
        'search',
        'isSelectAllMode',
      ];
      return JSON.stringify(this.getProperties(properties));
    },
  ),
}).reopenClass({
  createFromId(id) {
    return this.create({
      isSelectAllMode: false,
      selected: [{ id }],
      unselected: [],
      predicates: [],
      count: 1,
      search: '',
    });
  },
});

export default SelectionState;
