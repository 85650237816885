/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { senderTypes } from 'embercom/models/data/outbound/constants';

export default class CustomEmailAddress extends Model {
  @attr('string') declare appId: string;
  @attr('boolean') declare customBounceValid: boolean;
  @attr('boolean') declare dmarcValid: boolean;
  @attr('string') declare email: string;
  @attr('string') declare name: string;
  @attr('boolean') declare verified: boolean;
  @attr('string') declare status: string;
  @attr('number') declare senderEmailAddressSettingsId: number;
  @attr('string') declare brandName: string;
  @attr('number') declare brandId: number;
  @attr('boolean') declare forwardingEnabled: boolean;
  @attr('date') declare forwardedEmailLastReceivedAt: Date;

  get nameOrEmail() {
    return this.name || this.email;
  }

  get displayAs() {
    return `${this.name} <${this.email}>`;
  }

  get isCustomEmailAddress() {
    return true;
  }

  get outboundType() {
    return senderTypes.customEmailAddress;
  }
}
