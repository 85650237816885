/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
import { tracked } from 'tracked-built-ins';

const Validations = buildValidations({
  label: {
    description: 'Lead category',
    validators: [
      validator('presence', true),
      validator('length', {
        min: 1,
        max: 34,
      }),
    ],
  },
  followUpRules: {
    validators: [validator('has-many')],
  },
});

export default class BranchingQualificationAnswer extends Fragment.extend(Validations) {
  @attr('string') declare label: string;
  @fragmentArray('qualification/attribute') declare qualificationAttributes: $TSFixMe; // SyncHasMany<app/models/qualification/attribute>
  @fragmentArray('rules/rule') declare followUpRules: $TSFixMe; // SyncHasMany<app/models/rules/rule>

  @tracked justAdded = false;

  // for some reason we have to do this or the model validations tests fail
  //@ts-ignore
  get isValid() {
    return this.validations.isValid;
  }
}
