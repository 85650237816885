/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

export default class CancellationReasonModel extends Model {
  @attr('string') reason;
  @attr('boolean') freeTextResponse;
  @attr('array') followUpQuestionIds;
  @tracked isChecked = false;
  @belongsTo('cancellation-question', { inverse: 'cancellationReasons' }) cancellationQuestion;

  reset() {
    this.isChecked = false;
  }
}
