/* RESPONSIBLE TEAM: team-ai-agent */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { EntityType } from 'embercom/models/data/entity-types';
import {
  humanReadableObjectNames,
  objectIcons,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
export default class RelatedContentSource extends Fragment {
  @attr('number') declare entityId: number;
  @attr('number') declare entityType: EntityType;
  @attr('string') declare title: string | null;
  @attr('date') declare lastUpdatedAt: Date | null;
  @attr('number') declare frequency: number;
  @attr('boolean') declare deleted: boolean;

  get icon(): InterfaceIconName {
    return objectIcons[this.entityType];
  }

  get contentTypeForDisplay(): string {
    let humanReadableObjectName = humanReadableObjectNames[this.entityType];
    return capitalize(humanReadableObjectName);
  }

  get contentTypeForUrl(): string {
    return objectNames[this.entityType];
  }

  get isCustomAnswer(): boolean {
    return this.entityType === EntityType.Answer;
  }

  get isCustomAction(): boolean {
    return this.entityType === EntityType.WorkflowConnectorAction;
  }
}
