/* RESPONSIBLE TEAM: team-product-setup */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const FF_ICONS = [
  'document-check',
  'document-arrow-up',
  'clipboard-document-check',
  'folder-open',
  'document-magnifying-glass',
  'clipboard',
  'archive-box-arrow-down',
  'document-chart-bar',
  'newspaper',
  'document-arrow-down',
  'clipboard-document',
  'document',
  'archive-box',
  'document-text',
  'clipboard-document-list',
  'document-duplicate',
  'folder',
  'folder-arrow-down',
];

const ffCommonKeywords = ['files', 'document', 'windows', 'mac', 'computer', 'archive'];

export const FF_KEYWORDS = {
  [FF_ICONS[0]]: [...ffCommonKeywords, 'check', 'verify', 'approve'],
  [FF_ICONS[1]]: [...ffCommonKeywords, 'send', 'upload'],
  [FF_ICONS[2]]: [...ffCommonKeywords, 'check', 'verify', 'approve', 'clipboard', 'copy'],
  [FF_ICONS[3]]: [...ffCommonKeywords, 'folder'],
  [FF_ICONS[4]]: [...ffCommonKeywords, 'search', 'magnifying', 'glass'],
  [FF_ICONS[5]]: [...ffCommonKeywords, 'clipboard', 'copy'],
  [FF_ICONS[6]]: [...ffCommonKeywords, 'archive', 'arrow', 'down'],
  [FF_ICONS[7]]: [...ffCommonKeywords, 'data', 'bar', 'chart'],
  [FF_ICONS[8]]: [...ffCommonKeywords, 'newspaper', 'feed'],
  [FF_ICONS[9]]: [...ffCommonKeywords, 'download', 'save'],
  [FF_ICONS[10]]: [...ffCommonKeywords, 'clipboard', 'copy'],
  [FF_ICONS[11]]: [...ffCommonKeywords, 'new', 'create'],
  [FF_ICONS[12]]: [...ffCommonKeywords, 'box'],
  [FF_ICONS[13]]: [...ffCommonKeywords, 'text'],
  [FF_ICONS[14]]: [...ffCommonKeywords, 'clipboard', 'text', 'copy'],
  [FF_ICONS[15]]: [...ffCommonKeywords, 'duplicate', 'multiple'],
  [FF_ICONS[16]]: [...ffCommonKeywords, 'open', 'folder'],
  [FF_ICONS[17]]: [...ffCommonKeywords, 'download', 'folder', 'save'],
};
