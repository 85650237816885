/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ComponentConfiguration from 'embercom/objects/component-configuration';
import {
  seriesEdgeTypes,
  humanReadableObjectNames,
  rulesetCopyTitleSufix,
} from 'embercom/models/data/matching-system/matching-constants';
import { isPresent } from '@ember/utils';
import { dependentKeyCompat } from '@ember/object/compat';
import escapeRegExp from 'embercom/lib/escape-regular-expression';

export default class GraphConfiguration {
  node = undefined;
  componentConfig = new ComponentConfiguration({ component: 'path/to/component/file' });
  get connectors() {
    return [{ edgeType: seriesEdgeTypes.primary }];
  }
  objectType = undefined;

  constructor(node) {
    this.node = node;
  }

  get inferredTitle() {
    return '';
  }

  get defaultTitle() {
    return `Empty ${humanReadableObjectNames[this.objectType]}`;
  }

  get primaryPredecessorLabel() {
    return '';
  }

  get alternativePredecessorLabel() {
    return '';
  }

  @dependentKeyCompat
  get title() {
    if (isPresent(this.node.rulesetClientData.title) && !this.isUntitledCopy) {
      return this.node.rulesetClientData.title;
    }

    if (!this.node.rulesetClientData.openedSeriesEditor || !this.inferredTitle) {
      return this.defaultTitle + this.titleSuffix;
    }

    return this.inferredTitle + this.titleSuffix;
  }

  get isUntitledCopy() {
    let title = this.node.rulesetClientData.title || '';
    let copySufixRegexString = `^(${escapeRegExp(rulesetCopyTitleSufix)})+$`;
    let copySufixRegex = new RegExp(copySufixRegexString);
    return title.match(copySufixRegex);
  }

  get titleSuffix() {
    let rulesetTitle = this.node.rulesetClientData.title;
    if (this.isUntitledCopy) {
      return rulesetTitle;
    } else {
      return '';
    }
  }

  get checkpointStatsOverviewValue() {
    return this.checkpointStats.map((stat) => stat.value).reduce((a, b) => a + b, 0);
  }

  get checkpointStatsOverviewLabel() {
    return 'people in total';
  }

  get checkpointStats() {
    return [];
  }
}
