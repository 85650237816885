/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class Metadata extends Fragment {
  @attr('array') declare goodFor: string[];
  @attr('string') declare icon: InterfaceIconName;
  @attr('string') declare recommendationBadgeKey: string;
  @attr('string') declare cssSelector: string;
}
