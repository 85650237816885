/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { not, or, readOnly, gt } from '@ember/object/computed';
import diffCalculator, { COMPARISON_FUNCTIONS } from 'embercom/lib/developer-hub/diff-calculator';
import { validator, buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';

const Validations = buildValidations(
  {
    redirectUrls: {
      disabled: or('model.validationsDisabled', 'model.oauthDisabled'),
      validators: [validator('presence', true), validator('has-many')],
    },
    'scopes.length': {
      validators: [
        validator('number', {
          gt: 0,
          message: 'Please select at least one permision',
        }),
        validator('inline', {
          validate(value, options, model) {
            if (!model.developerHub.hasInvalidOauthScopesWithoutCanvasKitCapabilities) {
              return true;
            }
            return 'Invalid Permissions for App Without Canvas Kit Capabilities (Read data when entered into the app)';
          },
        }),
      ],
    },
  },
  {
    disabled: readOnly('model.validationsDisabled'),
  },
);

const redirectUrlComparison = (a, b) => {
  return COMPARISON_FUNCTIONS.array(a.mapBy('url'), b.mapBy('url'));
};

export default Fragment.extend(Validations, {
  developerHub: service(),
  callbackUrl: attr('string'),
  clientId: attr('string', { noDiff: true }),
  clientSecret: attr('string', { noDiff: true }),
  redirectUrls: fragmentArray('developer-hub/redirect-url', {
    diffFunction: redirectUrlComparison,
    humanisedName: 'Redirect URLs',
    renderingComponentPath: 'developer-hub/app-package/review/review-changes/oauth-redirect-urls',
  }),
  scopes: attr('array', {
    humanisedName: 'Oauth Scopes',
    renderingComponentPath: 'developer-hub/app-package/review/review-changes/oauth-scopes',
  }),

  validationsDisabled: true,
  scopesErrors: readOnly('validations.attrs.scopes.length.errors'),
  useOAuthFlow: gt('redirectUrls.length', 0),
  oauthDisabled: not('useOAuthFlow'),

  compareWith(modelB) {
    return diffCalculator(this, modelB);
  },

  validate() {
    this.set('validationsDisabled', false);
    this.redirectUrls.forEach(function (redirectUrl) {
      redirectUrl.set('validationsDisabled', false);
    });
    return this._super(...arguments);
  },

  rollbackAttributes() {
    this.set('validationsDisabled', true);
    this.redirectUrls.forEach(function (redirectUrl) {
      redirectUrl.set('validationsDisabled', true);
    });
    return this._super(...arguments);
  },
});
