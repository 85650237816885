/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type SeriesSplitter from 'embercom/models/series/splitter';

export default class extends RulesetLink {
  @belongsTo('series/splitter', { async: false }) declare object: SeriesSplitter;

  get objectType() {
    return objectTypes.splitter;
  }
}
