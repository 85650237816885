/* RESPONSIBLE TEAM: team-help-desk-experience */
import { default as RuleAction } from 'embercom/models/rules/rule-action';

export default class MacroAction extends RuleAction {
  get uniquenessKey() {
    if (this.type === 'close-conversation' || this.type === 'snooze-conversation') {
      return 'close-or-snooze-conversation';
    } else if (this.type.startsWith('assign-conversation')) {
      return 'assign-conversation';
    } else if (this.type === 'add-tag-to-conversation') {
      return `${this.type}-${this.actionData.tag_id}`;
    } else if (this.type === 'set-conversation-data-attribute') {
      return `${this.type}-${this.actionData.attribute_identifier}`;
    } else if (this.type === 'add-conversation-topic') {
      return `${this.type}-${this.actionData.topic_id}`;
    } else {
      return `${this.type}`;
    }
  }
}
