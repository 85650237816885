/* RESPONSIBLE TEAM: team-workflows */
import type TagUser from 'embercom/models/operator/visual-builder/step/tag-user';
import StepConfig, { type StepConfigParams } from '../step';

export default class TagUserConfiguration extends StepConfig {
  declare step: TagUser;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/tag-user',
      ...params,
    });
  }
}
