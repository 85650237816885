/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ParentAction, ChildAction } from 'embercom/objects/inbox/command-k/action';
import { inject as service } from '@ember/service';
import type AttributesApi from 'embercom/services/attributes-api';

export default class InsertAttribute extends ParentAction {
  @service declare attributesApi: AttributesApi;

  id = 'insert-attribute';
  icon = 'insert-data' as const;
  paneComponent = 'inbox2/command-k/insert-attribute/pane';

  buildResults(query: string): Array<ChildAction> {
    return this.attributesApi.templatableAttributes
      .filter((attribute) => attribute.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
      .map((result: any) => {
        return new ChildAction({
          parent: this,
          id: result.key.toString(),
          label: result.name,
          optionComponent: 'inbox2/command-k/insert-attribute/option',
        });
      });
  }
}
