/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { computed, observer } from '@ember/object';
import {
  alias,
  and,
  bool,
  empty,
  equal,
  filter,
  filterBy,
  mapBy,
  none,
  not,
  notEmpty,
  or,
  reads,
  uniqBy,
} from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { isEmpty } from '@ember/utils';
import Em from 'ember';
import {
  applyFunction,
  equalToProperty,
  findByProperty,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import ajax from 'embercom/lib/ajax';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import emoji from 'embercom/lib/emoji';
import truncateUrl from 'embercom/lib/url-truncator';
import Admin from 'embercom/models/admin';
import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import Participant from 'embercom/models/participant';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { capitalize } from '@ember/string';

export const ADMIN_PART_TYPES_WITH_SEEN_METADATA = [
  'article',
  'comment',
  'link',
  'message',
  'multi-link',
  'notification-channels',
  'sticker',
];

export default Model.extend(TaggingMixin, {
  appService: service(),

  app_id: attr(),

  conversation_id: attr(),
  conversation: computed('conversation_id', function () {
    if (this.conversation_id) {
      return this.store.peekRecord('conversation', this.conversation_id);
    }
  }),

  subject: attr(),
  blocks: attr(),
  summary: attr(),
  admin_id: attr(),
  admin: computed('admin_id', function () {
    if (this.admin_id) {
      return Admin.peekAndMaybeLoad(getEmberDataStore(), this.admin_id);
    }
  }),

  created_by: attr(),
  sender: computed('created_by', function () {
    if (this.created_by) {
      return Admin.peekAndMaybeLoad(getEmberDataStore(), this.created_by);
    }
  }),

  participant_id: attr(),
  participant: computed('participant_id', function () {
    if (this.participant_id) {
      return Participant.peekAndMaybeLoad(this.store, this.participant_id);
    }
  }),

  assignee_id: attr(),
  admin_assignee_id: attr(),
  team_assignee_id: attr(),

  assignee: computed('assignee_id', function () {
    if (this.assignee_id) {
      return Admin.peekAndMaybeLoad(getEmberDataStore(), this.assignee_id);
    }
  }),

  taggings: hasMany('tagging'),
  uploads: hasMany('upload'),

  unloadRecord() {
    this.taggings && this.taggings.compact().forEach((t) => t.unloadRecord());
    this.uploads && this.uploads.compact().forEach((u) => u.unloadRecord());
    this._super(...arguments);
  },
  // this indirection is needed as we currently use rule as a boolean in places, and a belongsTo relationship is considered truthy regardless of presence. - @beegan
  _rule: belongsTo('inbox/conversation-rules/rule', { inverse: null }),
  rule: computed('_rule', function () {
    if (this._rule.content) {
      return this._rule;
    }
  }),
  type: attr(),
  sub_type: attr(),
  channel_type: attr(),
  user_agent: attr(),
  url: attr(),
  inbound_email_id: attr(),
  original_message_url: attr(),
  message_style: attr(),
  message_id: attr(),
  lightweight_reply_type: attr(),
  lightweight_reply_response: attr(),
  lightweight_reply_created_at: attr(),
  reactions_reply: attr(),
  created_from_quick_reply: attr(),
  is_after_last_user_comment: attr(),
  is_initial_part: attr(),
  passive: attr(),
  nonPassive: not('passive'),
  deleted: attr(),
  from_address: attr(),
  fromAddress: alias('from_address'),
  updated_at: attr(),
  created_at: attr(),
  seen_by_current_admin: attr(),
  github_metadata_name: attr(),
  text_direction: attr('string', { defaultValue: 'ltr' }),
  unauthenticated: attr(),
  delivery_state: attr(),
  client_assigned_uuid: attr(),
  is_event: attr(),
  event_data: attr(),
  snoozed_until: attr(),
  priority: attr(),
  custom_snoozed_until_time: attr(),
  author_timezone: attr(),
  redacted_at: attr(),
  admin_only: attr(),
  isAnswerRatingEnabled: attr(),
  answerRating: attr(),
  answerId: attr(),
  answerTitle: attr(),
  title: attr(),

  participant_reply_to_address: attr(),
  participantReplyToAddressDiffers: computed(
    'participant_reply_to_address',
    'participant.email',
    function () {
      if (this.participant_reply_to_address && this.participant?.email) {
        return (
          this.participant_reply_to_address.toLowerCase() !== this.participant.email.toLowerCase()
        );
      } else {
        return false;
      }
    },
  ),

  // Nullable dict in this format: {send_state: -1/0/1, info_message: "..."}
  external_reply_send_state: attr(),

  messageShowPageRoute: computed('conversation.initiator.showPageRoute', function () {
    return this.get('conversation.initiator.showPageRoute') || 'apps.app.outbound.message';
  }),

  messageShowPageId: or('conversation.initiator.showPageId', 'message_id'),

  fromAddressModel: computed('from_address', function () {
    let from_address = this.from_address;
    return {
      avatarData: {
        url: from_address['avatar'],
        isTeammate: true,
        isCompany: false,
        isCustomer: false,
      },
    };
  }),

  partId: computed('id', function () {
    let id = this.id;
    if (id) {
      let idParts = id.split('-');
      return this.isInitialMessage ? idParts[3] : idParts[2];
    }
  }),

  analyticsData: computed(
    'id',
    'partType',
    'isNote',
    'hasArticle',
    'hasUploads',
    'hasImageBlock',
    'hasGifBlock',
    'hasApp',
    'appIDs',
    function () {
      return {
        object: 'conversation-part',
        conversation_part_id: this.id,
        part_type: this.partType,
        is_note: this.isNote,
        has_article: this.hasArticle,
        has_attachments: this.hasUploads,
        has_image: this.hasImageBlock,
        has_gif: this.hasGifBlock,
        has_app: this.hasApp,
        messenger_app_ids: this.appIDs,
      };
    },
  ),

  isFirstCompactedPart: false,
  compact: false,
  isLastCompactedPart: false,

  isNotCompactable: or(
    'hasNoBodyOrUploads',
    'isAssignment',
    'isReopener',
    'isNote',
    'hasLightweightResponse',
    'isEventWithoutBody',
  ),

  setCompactProperties(isFirstCompactedPart, compact, isLastCompactedPart) {
    this.setProperties({
      isFirstCompactedPart,
      compact,
      isLastCompactedPart,
    });
  },

  shouldHideMeta: computed('compact', 'isFirstCompactedPart', 'isLastCompactedPart', function () {
    return this.isFirstCompactedPart || (this.compact && !this.isLastCompactedPart);
  }),

  shouldHideAvatar: computed('compact', 'isFirstCompactedPart', function () {
    return this.compact && !this.isFirstCompactedPart;
  }),

  SEEN: 'seen',

  isSeenByCurrentAdmin: computed('seen_by_current_admin', function () {
    return this.seen_by_current_admin === this.SEEN;
  }),

  setSeen() {
    this.set('seen_by_current_admin', this.SEEN);
  },

  isReply: or(
    'isComment',
    'isSnoozerWithBody',
    'isUnsnoozerWithBody',
    'isCloserWithBody',
    'isReopenerWithBody',
    'isHybridAssignment',
  ),

  isInitialMessage: notEmpty('message_id'),
  isPostStyleAutoMessage: equal('message_style', 'announcement'),
  isNoteStyleAutoMessage: equal('message_style', 'small-announcement'),

  isNotificationChannels: computed('blocks.[]', function () {
    return (
      this.get('blocks.length') === 1 &&
      this.get('blocks.firstObject.type') === 'notificationChannelsCard'
    );
  }),

  isLink: computed('blocks.[]', 'blocks.firstObject.type', function () {
    return this.get('blocks.length') === 1 && this.get('blocks.firstObject.type') === 'link';
  }),

  hasArticle: computed('blocks.[]', function () {
    return (
      this.blocks &&
      this.blocks.any((block) => block.type === 'link' && block.linkType === 'educate.article')
    );
  }),

  isLinkContainer: computed('blocks.[]', 'blocks.firstObject.type', function () {
    let hasAllLinks = true;
    let blocks = this.blocks;
    if (!blocks) {
      return false;
    }
    blocks.forEach(function (block) {
      if (block && block.type !== 'link') {
        hasAllLinks = false;
      }
    });
    return hasAllLinks && blocks.length > 1;
  }),

  isRatingRequest: computed('blocks.[]', function () {
    return (
      this.get('blocks.length') === 1 &&
      this.get('blocks.firstObject.type') === 'conversationRating'
    );
  }),

  isUserPart: none('admin_id'),
  isAdminPart: not('isUserPart'),
  isHumanAdminPart: and('isAdminPart', 'isNotOperatorPart'),
  isInitialPartByUser: and('is_initial_part', 'isUserPart'),
  isInitialPartByAdmin: and('is_initial_part', 'isAdminPart'),
  isInitialPartByHumanAdmin: and('is_initial_part', 'isHumanAdminPart', 'isNotStartedFromArticle'),
  isInitialPartStartedFromArticle: and('is_initial_part', 'conversation.started_from_article'),
  isNotStartedFromArticle: not('conversation.started_from_article'),
  isInitialPartAndStartedByCustomBot: and('is_initial_part', 'conversation.started_by_custom_bot'),
  isGithubBotPart: reads('admin.isGithubBot'),
  isOperatorPart: reads('admin.isOperator'),
  isNotOperatorPart: not('isOperatorPart'),
  isOperatorCommentPart: and('isOperatorPart', 'displaysAsAdminComment'),
  isOperatorNotePart: and('isOperatorPart', 'isNote'),
  isTwitterBotPart: and('admin.isTwitterBot', 'isChannelTwitter'),
  isAnswerRatingPart: bool('isAnswerRatingEnabled'),
  isPending: equal('delivery_state', 'pending'),
  isStopped: equal('delivery_state', 'stopped'),
  isBotPart: reads('admin.isBot'),
  isNotBotPart: not('isBotPart'),
  hasSubject: notEmpty('subject'),
  hasNoBody: empty('blocks'),
  hasBody: not('hasNoBody'),
  hasBodyOrSubject: or('hasBody', 'hasSubject'),
  hasSummary: notEmpty('summary'),
  hasAnyContent: or('hasBodyOrSubject', 'hasSummary'),

  blocksHaveUploads: applyFunction(function (blocks) {
    if (!blocks) {
      return false;
    }
    return !!blocks.find((block) => {
      return block.type === 'image' || block.type === 'attachmentList';
    });
  }, 'blocks'),

  blocksHaveNoUploads: not('blocksHaveUploads'),
  uploadsIsEmpty: empty('uploads'),
  hasNoUploads: and('uploadsIsEmpty', 'blocksHaveNoUploads'),
  hasUploads: not('hasNoUploads'),
  hasNoBodyOrUploads: and('hasNoBody', 'hasNoUploads'),
  hasBodyOrUploads: not('hasNoBodyOrUploads'),
  hasTaggings: notEmpty('taggings'),
  shouldOverrideAdminAvatar: notEmpty('from_address'),
  isNote: and('admin_only', 'hasBodyOrUploads'),
  isDuplicateConversationNote: equal('sub_type', 'duplicate_conversation_note'),
  isUnknownParticipantFromEmailConversationNote: equal(
    'sub_type',
    'unknown_participant_for_email_conversation_note',
  ),
  isComment: equal('sub_type', 'comment'),
  isRuleAssignment: notEmpty('rule'),
  hasUserAgent: notEmpty('user_agent'),
  hasNoUserAgent: not('hasUserAgent'),
  hasReaction: notEmpty('reactions_reply.reaction_index'),

  selectedReaction: findByProperty(
    'reactions_reply.reaction_set',
    'index',
    'reactions_reply.reaction_index',
  ),

  selectedReactionEmoji: alias('selectedReaction.unicode_emoticon'),

  stickerUri: computed('blocks.[]', function () {
    return emoji.stickerUri(this.get('blocks.firstObject.text'));
  }),

  isSticker: computed('blocks.[]', 'isNote', 'hasNoUploads', function () {
    let blocks = this.blocks;
    return (
      blocks &&
      blocks.get('length') === 1 &&
      emoji.isSticker(blocks.get('firstObject.text')) &&
      !this.isNote &&
      this.hasNoUploads
    );
  }),

  hasOneImageUpload: computed('uploads', function () {
    if (this.get('uploads.length') === 1) {
      return this.get('uploads.firstObject.isImage');
    }
    return false;
  }),

  hasOneAudioUpload: computed('uploads', function () {
    if (this.get('uploads.length') === 1) {
      return this.get('uploads.firstObject.name')?.endsWith('.oga');
    }
    return false;
  }),

  hasImageBlock: computed('blocks.[]', function () {
    return !!this.blocks?.any((block) => block.type === 'image');
  }),

  hasGifBlock: computed('blocks.[]', function () {
    return !!this.blocks?.any((block) => block.type === 'image' && block.url?.endsWith('.gif'));
  }),

  appIDs: computed('blocks.[]', function () {
    return this.blocks
      ?.filter((block) => block.type === 'messengerCard')
      .map((block) => block.messenger_app_id);
  }),
  hasApp: notEmpty('appIDs'),
  hasMention: computed('blocks.[]', function () {
    return !!this.blocks?.any(
      (block) => block.text && block.text.indexOf('class="entity_mention"') >= 0,
    );
  }),

  isAssignment: or('isExplicitAssignment', 'isImplicitAssignment', 'isAwayModeAssignment'),

  isExplicitAssignment: computed('sub_type', function () {
    let subType = this.sub_type;
    return ['assignment', 'assign_and_reopen', 'assign_and_unsnooze'].includes(subType);
  }),

  isNotExplicitAssignment: not('isExplicitAssignment'),
  isImplicitAssignment: and('isNotExplicitAssignment', 'hasAssignee'),
  isExplicitAssignmentWithBody: and('hasBody', 'isExplicitAssignment'),
  isHybridAssignment: or('isImplicitAssignment', 'isExplicitAssignmentWithBody'),

  hasAssignee: computed('assignee', function () {
    return !!parseInt(this.get('assignee.id'), 10);
  }),

  hasHumanAssignee: reads('assignee.isHuman'),
  hasNoAssignee: not('hasAssignee'),
  isAssignToUnassigned: and('isExplicitAssignment', 'hasNoAssignee'),
  adminIsAssignee: equalToProperty('admin.id', 'assignee.id'),
  isReflexiveAssignment: or('isHybridAssignment', 'adminIsAssignee'),
  isBulkReassignmentSubType: equal('sub_type', 'bulk_reassignment'),
  isReassignOnAdminRemovalSubType: equal('sub_type', 'reassign_on_admin_removal'),
  isBulkReassignment: or('isBulkReassignmentSubType', 'isReassignOnAdminRemovalSubType'),

  isAction: or(
    'isOpener',
    'isCloser',
    'isReopener',
    'isAssignment',
    'isSnoozer',
    'isUnsnoozer',
    'isTimerUnsnoozer',
    'isNoteUnsnoozer',
    'isAssignmentUnsnoozer',
    'isPriorityChanger',
    'isTitleChanger',
  ),

  isStateChange: or(
    'isOpener',
    'isCloser',
    'isReopener',
    'isSnoozer',
    'isUnsnoozer',
    'isTimerUnsnoozer',
    'isNoteUnsnoozer',
    'isAssignmentUnsnoozer',
  ),

  isOpener: not('isNoteOrCloserOrEventOrConversationRatingOrExplicitAssignment'),
  isCloser: equal('sub_type', 'close'),
  isReopener: equal('sub_type', 'open'),
  isSnoozer: equal('sub_type', 'snoozed'),
  isUnsnoozer: equal('sub_type', 'unsnoozed'),
  isTimerUnsnoozer: equal('sub_type', 'timer_unsnooze'),
  isNoteUnsnoozer: equal('sub_type', 'note_and_unsnooze'),
  isAssignmentUnsnoozer: equal('sub_type', 'assign_and_unsnooze'),
  isPriorityChanger: equal('sub_type', 'priority_changed'),
  isTitleChanger: equal('sub_type', 'title_changed'),
  isPriorityRuleEvent: equal('sub_type', 'priority_changed_by_rule'),
  isConversationSlaApplier: equal('sub_type', 'conversation_sla_applied_by_rule'),
  isConversationSlaRemover: equal('sub_type', 'conversation_sla_removed'),
  isConversationSlaTargetMisser: equal('sub_type', 'conversation_sla_target_missed'),
  isConversationSlaPaused: equal('sub_type', 'conversation_sla_paused'),
  isConversationSlaUnpaused: equal('sub_type', 'conversation_sla_unpaused'),
  isTagEvent: equal('sub_type', 'conversation_tags_updated'),
  isConversationAttributeUpdatedByAdmin: equal(
    'sub_type',
    'conversation_attribute_updated_by_admin',
  ),
  isTicketStateUpdatedByAdmin: equal('sub_type', 'ticket_state_updated_by_admin'),
  isConversationAttributeUpdatedByUser: equal('sub_type', 'conversation_attribute_updated_by_user'),
  isConversationAttributeUpdatedByWorkflow: equal(
    'sub_type',
    'conversation_attribute_updated_by_workflow',
  ),
  isSideConversationStartedEvent: equal('sub_type', 'side_conversation_started'),
  isSideConversationRepliedEvent: equal('sub_type', 'side_conversation_reply'),

  isNoteOrCloserOrEventOrConversationRatingOrExplicitAssignment: or(
    'isCloser',
    'isNote',
    'is_event',
    'isRatingRequest',
    'isExplicitAssignment',
  ),

  isCloserWithBody: and('isCloser', 'hasBody'),
  isReopenerWithBody: and('isReopener', 'hasBody'),
  isAdminReopenerWithBody: and('isReopenerWithBody', 'isAdminPart'),
  isUserReopenerWithBody: and('isReopenerWithBody', 'isUserPart'),
  isSnoozerWithBody: and('isSnoozer', 'hasBody'),
  isUnsnoozerWithBody: and('isUnsnoozer', 'hasBody'),
  isNotPersisted: reads('isNew'),
  isPersisted: not('isNotPersisted'),
  isParticipantAdded: equal('sub_type', 'participant_added'),
  isParticipantRemoved: equal('sub_type', 'participant_removed'),
  isLightweightReply: not('isNotLightweightReply'),

  isNotLightweightReply: computed('lightweight_reply_type', function () {
    return this.lightweight_reply_type === 'text' || this.lightweight_reply_type === null;
  }),

  hasLightweightResponse: notEmpty('lightweight_reply_response'),

  lightweightReplyResponseIcon: computed('lightweight_reply_response', function () {
    if (this.lightweight_reply_response) {
      return `lwr-${this.lightweight_reply_response.replace(/\_/, '-')}`;
    }
    return '';
  }),

  isThumbsLightweightReply: equal('lightweight_reply_type', 'thumbs'),
  isEmotionsLightweightReply: equal('lightweight_reply_type', 'emotions'),

  lightweightReplyStatement: computed('lightweight_reply_type', function () {
    if (this.lightweight_reply_type) {
      return capitalize(this.lightweight_reply_type);
    }
    return '';
  }),

  lightweightReplyResponse: computed('lightweight_reply_response', function () {
    return capitalize(this.lightweight_reply_response.replace(/\_/, ' '));
  }),

  isAwayModeAssignment: equal('sub_type', 'away_mode_assignment'),
  isNotNote: not('isNote'),
  isAdminComment: and('isAdminPart', 'isComment'),
  isAdminPartWithBody: and('isAdminPart', 'hasBody'),
  isAdminCommentOrMessage: or('isInitialPartByHumanAdmin', 'isAdminComment'),
  isUserComment: and('isUserPart', 'isComment'),
  isEventWithoutBody: and('is_event', 'hasNoBody'),

  displaysAsAdminComment: or(
    'isInitialPartByHumanAdmin',
    'isAdminComment',
    'isAdminPartWithBody',
    'isCloserWithBody',
    'isAdminReopenerWithBody',
  ),

  displaysAsUserComment: or('isInitialPartByUser', 'isUserComment', 'isUserReopenerWithBody'),
  isToolable: or('isReply', 'isUserPart', 'isNote'),
  hasTools: and('isToolable', 'isNotBotPart'),
  shouldDisplayTools: and('hasTools', 'isPersisted', 'isNotRedacted'),
  shouldDisplayInitialPartTools: and('isInitialPartByAdmin', 'isPersisted', 'isNotRedacted'),

  shouldDisplaySeenState: and(
    'isAdminPart',
    'isNotNote',
    'hasBodyOrSubject',
    'is_after_last_user_comment',
  ),

  isPartSameOrAfterConversationReadDate: computed(
    'conversation.read_at',
    'created_at',
    function () {
      if (this.conversation) {
        let conversationReadAtDate = moment(this.conversation.read_at);
        let createdAtDate = moment(this.created_at);
        return conversationReadAtDate >= createdAtDate;
      }
      return false;
    },
  ),

  hasBeenSeen: and('shouldDisplaySeenState', 'isPartSameOrAfterConversationReadDate'),
  isWaitingSinceInfluencingPart: or('isReopener', 'isCloser', 'isReply'),
  messengerCardBlocks: filterBy('blocks', 'type', 'messengerCard'),
  messengerCardUris: mapBy('messengerCardBlocks', 'uri'),
  hasMessengerCards: notEmpty('messengerCardBlocks'),
  hasOnlyMessengerCard: computed('hasMessengerCards', 'messengerCardBlocks', 'blocks', function () {
    return this.hasMessengerCards && this.messengerCardBlocks.length === this.blocks.length;
  }),

  hasMessengerCard(uri) {
    return this.messengerCardUris.includes(uri);
  },

  timestamp: computed('created_at', 'message_id', function () {
    let timestamp = new Date(this.created_at).getTime();
    if (this.isInitialMessage) {
      timestamp--;
    }
    return timestamp;
  }),

  hasChannelType: computed('channel_type', function () {
    let channel_type = this.channel_type;
    if (channel_type === 'Email' && this.get('original_message_url.length') <= 0) {
      return false;
    }
    return channel_type !== 'Unknown' && this.get('channel_type.length') > 0;
  }),

  lowerCaseChannelType: computed('channel_type', function () {
    let channelType = this.channel_type;
    if (channelType) {
      return channelType.toLowerCase();
    }
  }),

  isChannelInApp: not('isChannelOther'),
  isChannelOther: or('isChannelMobile', 'isChannelSocial', 'isChannelEmail'),
  isChannelMobile: or('isChanneliOS', 'isChannelAndroid'),
  isChannelSms: equal('lowerCaseChannelType', 'sms'),
  isChannelEmail: equal('lowerCaseChannelType', 'email'),
  isChannelSocial: or(
    'isChannelFacebookOrFacebookMessenger',
    'isChannelTwitter',
    'isChannelWhatsapp',
    'isChannelInstagram',
    'isChannelSms',
  ),
  isChanneliOS: equal('lowerCaseChannelType', 'ios app'),
  isChannelAndroid: equal('lowerCaseChannelType', 'android app'),
  isChannelFacebookMessenger: equal('lowerCaseChannelType', 'facebook messenger'),
  isChannelFacebook: equal('lowerCaseChannelType', 'facebook'),
  isChannelFacebookOrFacebookMessenger: or('isChannelFacebook', 'isChannelFacebookMessenger'),
  isChannelTwitter: equal('lowerCaseChannelType', 'twitter'),
  isChannelWhatsapp: equal('lowerCaseChannelType', 'whatsapp'),
  isChannelInstagram: equal('lowerCaseChannelType', 'instagram'),
  isChannelPhoneCall: equal('lowerCaseChannelType', 'phone_call'),

  channelIcon: computed(
    'isChanneliOS',
    'isChannelAndroid',
    'isChannelEmail',
    'isChannelSms',
    'isChannelTwitter',
    'isChannelFacebookOrFacebookMessenger',
    'isChannelWhatsapp',
    'isChannelInstagram',
    function () {
      if (this.isChanneliOS) {
        return 'ios';
      } else if (this.isChannelAndroid) {
        return 'android';
      } else if (this.isChannelEmail) {
        return 'email';
      } else if (this.isChannelSms) {
        return 'ios';
      } else if (this.isChannelTwitter) {
        return 'twitter';
      } else if (this.isChannelFacebookOrFacebookMessenger) {
        return 'facebook';
      } else if (this.isChannelWhatsapp) {
        return 'whatsapp';
      } else if (this.isChannelInstagram) {
        return 'instagram';
      } else {
        return 'messenger';
      }
    },
  ),

  hasUrl: notEmpty('url'),
  creator: ternaryToProperty('isUserPart', 'participant', 'admin'),
  hasNoInboundEmail: empty('inbound_email_id'),
  shouldHideViewRawEmailLink: or('isRedacted', 'hasNoInboundEmail'),

  twitterUsername: computed('url', 'isChannelTwitter', function () {
    if (!this.url || !this.isChannelTwitter) {
      return null;
    }

    let matches = this.url.match(/^https?:\/\/twitter.com\/(.+)/i, '');
    if (isEmpty(matches)) {
      return '[Unknown User]';
    }
    return matches[1];
  }),

  actionIcon: computed('sub_type', function () {
    if (this.isCloser) {
      return 'check';
    } else if (this.isAssignment) {
      return 'assignment';
    } else if (this.sub_type === 'open' || this.isUnsnoozer) {
      return 'check';
    } else if (this.isNote) {
      return 'note';
    } else if (this.isComment && this.isUserPart) {
      return 'reply';
    }
  }),

  actionStatement: computed(
    'isCloserWithBody',
    'isCloser',
    'isReopenerWithBody',
    'isReopener',
    'isExplicitAssignment',
    'hasAssignee',
    'isAssignToUnassigned',
    'isHybridAssignment',
    'isComment',
    'isUserPart',
    'isBotPart',
    'isAwayModeAssignment',
    'isSnoozer',
    'isUnsnoozer',
    'isTimerUnsnoozer',
    function () {
      let action;
      if (this.isCloserWithBody) {
        action = 'replied and closed this conversation';
      } else if (this.isCloser) {
        if (this.event_data?.reason === 'conversation-soft-deleted') {
          action = `closed this conversation because this person was archived`;
        } else {
          action = `closed this conversation`;
        }
      } else if (this.isReopenerWithBody) {
        action = 'replied and reopened this conversation';
      } else if (this.isReopener) {
        action = 'reopened this conversation';
      } else if (this.isAwayModeAssignment) {
        action = 'Reassigned by away mode';
      } else if (this.isSnoozer) {
        action = 'snoozed the conversation';
      } else if (this.isUnsnoozer) {
        action = 'reopened this conversation';
      } else if (this.isTimerUnsnoozer) {
        action = 'Snooze ended and this conversation was reopened';
      } else if (this.isRuleAssignment) {
        action = 'auto assigned to';
      } else if (this.isExplicitAssignment && this.hasAssignee) {
        action = 'assigned this conversation to';
      } else if (this.isAssignToUnassigned) {
        action = 'assigned this conversation to Unassigned';
      } else if (this.isHybridAssignment) {
        action = 'replied and auto-assigned to';
      } else if (this.isComment && this.isUserPart) {
        action = `${this.get('participant.display_as')} replied and this conversation was reopened`;
      }
      return action;
    },
  ),

  actionStatementTarget: computed('isAssignment', 'hasAssignee', function () {
    if (this.isAssignment && this.hasAssignee) {
      return this.assignee;
    }
    return null;
  }),

  uniqueTaggings: uniqBy('taggings', 'tag.id'),
  nonEmptyTaggings: filter('uniqueTaggings', function (tagging) {
    return !!tagging.tag.name;
  }),
  hasNonEmptyTaggings: notEmpty('nonEmptyTaggings'),

  parsedSummary: computed('summary', function () {
    let summary = this.summary;
    if (summary) {
      summary = Em.Handlebars.Utils.escapeExpression(summary);
      summary = emoji.emojify(summary);
      return sanitizeHtml(summary);
    }
    return '';
  }),

  isNonEventPartAction: or('isCloser', 'isReopener', 'isAssignment', 'isPriorityChanger'),
  isAdminAction: and('isNonEventPartAction', 'isAdminPart'),

  isAdminActionWithoutBody: computed('isAdminAction', 'hasNoBody', 'is_event', function () {
    return this.isAdminAction && this.hasNoBody && !this.is_event;
  }),

  partType: computed(
    'isUserPart',
    'isSticker',
    'isAdminActionWithoutBody',
    'isEventWithoutBody',
    'isLink',
    'isInitialPartStartedFromArticle',
    'isLinkContainer',
    'isNotificationChannels',
    'isRatingRequest',
    'isInitialMessage',
    'isNote',
    'isDuplicateConversationNote',
    'isUnknownParticipantFromEmailConversationNote',
    function () {
      if (this.isEventWithoutBody) {
        return 'eventWithoutBody';
      }

      if (this.isUserPart) {
        if (this.isSticker) {
          return 'sticker';
        } else {
          return 'comment';
        }
      }

      if (this.isAdminActionWithoutBody) {
        return 'action';
      } else if (this.isSticker) {
        return 'sticker';
      } else if (this.isLink) {
        return 'link';
      } else if (this.isInitialPartStartedFromArticle) {
        return 'article';
      } else if (this.isLinkContainer) {
        return 'multi-link';
      } else if (this.isNotificationChannels) {
        return 'notification-channels';
      } else if (this.isRatingRequest) {
        return 'rating-request';
      } else if (this.isInitialMessage) {
        return 'message';
      } else if (this.isDuplicateConversationNote) {
        return 'duplicate-conversation-note';
      } else if (this.isUnknownParticipantFromEmailConversationNote) {
        return 'unknown-participant-for-email-conversation-note';
      } else if (this.isNote) {
        return 'note';
      } else {
        return 'comment';
      }
    },
  ),

  hasSeenMetadata: computed('isAdminPart', 'partType', 'isEventWithoutBody', function () {
    return (
      this.isAdminPart &&
      ADMIN_PART_TYPES_WITH_SEEN_METADATA.includes(this.partType) &&
      !this.isEventWithoutBody
    );
  }),

  // Fade in admin action string after immediate send & prevent the animation from
  // running twice when the thread re-renders after the part has been persisted
  // prettier-ignore
  isNewChanged: on(
    'init',
      observer({
      dependentKeys: ['isNew'],

      fn() {
        if (this.isImmediateSend) {
          this.set('shouldFadeInAction', true);
        }
        this.set('isImmediateSend', false);
      },

      sync: false
    }),
  ),

  updateTaggingUrl: '/ember/conversation_parts/update_tags.json',

  sourceOfMessageLabel: ternaryToProperty(
    'creatorIsAnonymous',
    'shortenedMessageSourcePathForAnonymousUsers',
    'sourceOfMessageLabelForUsers',
  ),

  creatorIsAnonymous: reads('creator.is_anonymous'),
  sourceForUsers: 'App',

  sourceOfMessageLabelForUsers: ternaryToProperty(
    'unauthenticated',
    'shortenedMessageSourcePathForAnonymousUsers',
    'sourceForUsers',
  ),

  shortenedMessageSourcePathForAnonymousUsers: computed('url', function () {
    return truncateUrl(this.url, 300);
  }),

  updateError(error = {}) {
    this.set('hasErrored', true);
    this.set('isRetriable', this._failedWithRetriableError(error));
    this.set(
      'errorMessage',
      `Failed. ${this.isRetriable ? 'Click to try again' : error.responseText}`,
    );
  },

  _failedWithRetriableError(error) {
    if (!error.responseText) {
      return true;
    }
    // error messages that shouldn't be retried from
    let expectedErrors = ['message is too long'];
    let receivedError = error.responseText.toLowerCase();
    return expectedErrors.every((expectedError) => !receivedError.startsWith(expectedError));
  },

  isUserPartOrReply: or('isUserPart', 'isReply'),

  canBeRedactedForUserPartOrReply: and('hasBodyOrUploads', 'isUserPartOrReply', 'isNotRedacted'),

  canBeRedactedForNote: and('isNote', 'isNotRedacted'),
  canBeRedacted: or('canBeRedactedForUserPartOrReply', 'canBeRedactedForNote'),
  isRedacted: notEmpty('redacted_at'),
  isNotRedacted: not('isRedacted'),
  redacted_by_id: attr(),

  redactedBy: computed('redacted_by_id', function () {
    if (this.redacted_by_id) {
      return Admin.peekAndMaybeLoad(getEmberDataStore(), this.redacted_by_id);
    }
  }),

  redact() {
    return ajax({
      url: `/ember/conversation_parts/${this.id}/redact`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.app_id,
        conversation_id: this.conversation_id,
      }),
    });
  },
});
