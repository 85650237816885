/* RESPONSIBLE TEAM: team-reporting */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';

const SUPPORTS_LINE_TARGET = [
  VISUALIZATION_TYPES.LINE,
  VISUALIZATION_TYPES.AREA,
  VISUALIZATION_TYPES.BAR,
];
const SUPPORTS_HIT_MISS_COLORS = [VISUALIZATION_TYPES.COUNTER, VISUALIZATION_TYPES.TABLE];

export default class Target extends Fragment {
  @attr('number') declare value: number;
  @attr('string') declare lineColor: string | undefined;
  @attr('string') declare hitColor: string | undefined;
  @attr('string') declare missColor: string | undefined;
  @attr('number') declare timeUnit: number | undefined;

  ensureOptionsAreCompatible(visualizationType: string) {
    if (!SUPPORTS_LINE_TARGET.includes(visualizationType)) {
      this.lineColor = undefined;
    }
    if (!SUPPORTS_HIT_MISS_COLORS.includes(visualizationType)) {
      this.hitColor = undefined;
      this.missColor = undefined;
    }
  }
}
