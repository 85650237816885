/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  ticketTypeId: {
    descriptionKey: 'messenger.add-apps.ticket-links.ticket-type-dropdown-label',
    validators: [
      validator('presence', {
        presence: true,
        messageKey: 'validations.messenger-settings.errors.required',
      }),
    ],
  },
  icon: {
    descriptionKey: 'messenger.add-apps.ticket-links.icon-dropdown-label',
    validators: [
      validator('presence', {
        presence: true,
        messageKey: 'validations.messenger-settings.errors.required',
      }),
    ],
  },
});

export default class TicketLink extends Model.extend(Validations) {
  @belongsTo('messenger-home/slot') slot;

  @attr('number') ticketTypeId;
  @attr('string') ticketTypeName;
  @attr('string') icon;
  @attr('number') displayOrder;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }
}
