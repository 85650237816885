/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type ReportingViews from 'embercom/services/reporting-views';
import { type ReportItem, type FolderReport } from 'embercom/services/reporting-views';
import type Router from '@ember/routing/router-service';
import { flatten, pairs, without } from 'underscore';
import type Report from 'embercom/models/reporting/custom/report';
import { put } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';

const STORAGE_KEY = 'folder-state';

interface StorageState {
  expanded: boolean;
  lastOpened: ReportItem | undefined;
}
export default class NavigationFolder extends Model {
  @service appService: $TSFixMe;
  @service declare reportingViews: ReportingViews;
  @service declare router: Router;

  @attr('string') declare name: string;
  @attr('number') declare position: number;
  @attr('boolean', { defaultValue: true }) declare pinned: boolean;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('array', { defaultValue: () => [] }) declare reports: string[];

  @tracked expanded: boolean = this.expandedState;
  @tracked declare lastOpened: ReportItem | undefined;

  get app() {
    return this.appService.app;
  }

  async unpin() {
    this.pinned = false;
    this.collapseFolder();
    this.resetLastOpened();
    this.save();
  }

  async pin() {
    this.pinned = true;
    this.save();
  }

  async togglePin() {
    if (this.pinned) {
      await this.unpin();
    } else {
      await this.pin();
    }
  }

  private get defaultLocalStorageState() {
    return { expanded: false, lastOpened: undefined };
  }

  private get localStorageState(): Record<string, StorageState> {
    let storage = localStorage.getItem(STORAGE_KEY);
    if (storage) {
      return JSON.parse(storage);
    }
    return { [this.id]: this.defaultLocalStorageState };
  }

  toggledExpanded() {
    this.expanded = !this.expanded;
    this.updateStorageState();
  }

  updateLastOpened(content: ReportItem) {
    this.lastOpened = content;
    this.updateStorageState();
  }

  resetLastOpened() {
    this.lastOpened = undefined;
    this.updateStorageState();
  }

  collapseFolder() {
    this.expanded = false;
    this.updateStorageState();
  }

  private updateStorageState() {
    let currentState = this.localStorageState;
    currentState[this.id] = { expanded: this.expanded, lastOpened: this.lastOpened };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(currentState));
  }

  private get expandedState(): boolean {
    let state: StorageState = this.localStorageState[this.id] ?? this.defaultLocalStorageState;
    return state.expanded;
  }

  async pinReportToFolder(reportId: string) {
    this.reports = flatten([this.reports, reportId]);
    this.save();
  }

  async unpinReportFromFolder(reportId: string) {
    this.reports = without(this.reports, reportId);
    this.save();
  }

  isReportPinned(id: string) {
    return this.reports.includes(id);
  }

  async updateReportOrder(reportIds: string[]) {
    this.reports = reportIds;
    this.save();
  }

  get folderReports(): FolderReport[] {
    let folderReportForStaticReport: FolderReport[] = pairs(
      this.reportingViews.staticReportsWithoutTopics,
    ).map(([key, value]) => ({
      id: key,
      title: value.name,
    }));

    let folderReportForNoneStaticReport = this.store
      .peekAll('reporting/custom/report')
      .filter((report) => !report.isNew)
      .map((report: Report) => ({
        id: report.id,
        title: report.titleForDisplay,
      }));

    return flatten([folderReportForStaticReport, folderReportForNoneStaticReport]);
  }

  reorder(newPosition: number) {
    return put(`/ember/reporting/reporting_navigation_folder/${this.id}/reorder`, {
      position: newPosition,
      app_id: this.appService.app.id,
    });
  }

  //On Destroy record delete local storage items
}
