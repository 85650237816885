/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import BannerContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/banner/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import SeriesSchedulePanelConfiguration from 'embercom/objects/content-editor/panels/schedule/series-schedule-panel-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';

export default function generateEditModeConfig(options) {
  let container = options.container;

  return EditModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesModeHeaderConfiguration.create({ container }),
    headerComponentConfigs: [StandardEditModeHeaderConfiguration.create({ container })],
    editorPanelConfigs: [
      BannerContentPanelConfiguration.create({ container }),
      StandardAudiencePanelConfiguration.create({
        hideMatchBehavior: true,
        showEventRules: true,
        showDateEvents: true,
        container,
      }),
      StandardSchedulePanelConfiguration.create({
        showFrequencyEditor: true,
        container,
      }),
      SeriesSchedulePanelConfiguration.create({ container }),
      StandardGoalPanelConfiguration.create({
        introText: 'Understand how your banner drives people to take action in your product.',
        container,
      }),
    ],
    footerComponentConfigs: [],
  });
}
