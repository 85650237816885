/* RESPONSIBLE TEAM: team-workflows */
import type SetConversationDataAttribute from 'embercom/models/operator/visual-builder/step/set-conversation-data-attribute';
import StepConfig, { type StepConfigParams } from '../step';

export default class SetConversationDataAttributeConfiguration extends StepConfig {
  declare step: SetConversationDataAttribute;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/set-conversation-data-attribute',
      ...params,
    });
  }
}
