/* RESPONSIBLE TEAM: team-proactive-support */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import CustomBotContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/custom-bot/panel-configuration';
import { StandardSchedulePanelConfiguration } from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import { timezoneTypes } from 'embercom/models/data/matching-system/matching-constants';

export default function generateEditModeConfig({ container, customBot }) {
  return EditModeConfig.create({
    editorPanelConfigs: [
      CustomBotContentPanelConfiguration.create({ container, customBot }),
      StandardSchedulePanelConfiguration.create({
        timezoneType: timezoneTypes.basedOnUserTimezone,
        container,
      }),
    ],
  });
}
