/* RESPONSIBLE TEAM: team-help-desk-experience */

import { type IDBPDatabase } from 'idb';
import { type Span } from '@opentelemetry/api';

import { EntityType } from 'embercom/models/data/entity-types';
import { prepareStringForFuzzySearch } from 'embercom/services/fuzzy-search';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { type DB } from 'embercom/services/inbox2-core-data';
import { type TagResponse } from 'embercom/services/inbox2-tags-search';
import ObjectStore from 'embercom/objects/inbox/core-data/store';
import SearchableDocument from 'embercom/objects/inbox/searchable-document';
import Tag from 'embercom/objects/inbox/tag';

export type TagSchema = {
  id: string;
  name: string;
  fuzzy: Fuzzysort.Prepared;
};

export default class TagsStore extends ObjectStore<Tag> {
  static upgradeDB(db: IDBPDatabase<DB>, _oldVersion: number, _newVersion: number | null) {
    // for now nuke all data on update, in future we can do something nicer
    if (db.objectStoreNames.contains('tags')) {
      db.deleteObjectStore('tags');
    }

    if (!db.objectStoreNames.contains('tags')) {
      db.createObjectStore('tags', { keyPath: 'id', autoIncrement: false });
    }
  }

  async boot() {
    let versionData = await this.db.get('versions', 'tags');
    let json = await this.fetchData<TagResponse>('/ember/inbox/tags', versionData?.version);
    if (!json) {
      return;
    }

    let tags = json.tags.results;
    let version = json.tags.version;

    return await this.tracing.inSpan(
      { name: 'boot', resource: 'inbox2-core-data.tags' },
      async (span?: Span) => {
        let existing = await this.db.getAll('tags');
        let existingById = Object.fromEntries(existing.map((tag) => [tag.id, tag]));
        let fetchedIds: Set<DB['tags']['key']> = new Set();
        let toUpdate: TagSchema[] = [];
        let toDelete: DB['tags']['key'][] = [];

        // figure out which are new / changed
        tags.forEach((tag) => {
          fetchedIds.add(tag.id.toString());
          let existingItem = existingById[tag.id.toString()];
          if (!existingItem || (existingItem && existingItem.name !== tag.name)) {
            toUpdate.push({
              id: tag.id.toString(),
              name: tag.name,
              fuzzy: prepareStringForFuzzySearch(tag.name),
            });
          }
        });

        // figure out which have been deleted
        Object.keys(existingById).forEach((id) => {
          if (!fetchedIds.has(id)) {
            toDelete.push(id);
          }
        });

        span?.setAttributes({
          'items.fetched': fetchedIds.size,
          'items.existing': existing.length,
          'items.updated': toUpdate.length,
          'items.deleted': toDelete.length,
        });

        // stick the data in indexeddb
        let tx = this.db.transaction(['tags', 'versions'], 'readwrite');
        let itemStore = tx.objectStore('tags');
        let versionStore = tx.objectStore('versions');
        await Promise.all([
          ...toUpdate.map((tag) => itemStore.put(tag)),
          ...toDelete.map((id) => itemStore.delete(id)),
          versionStore.put({ id: 'tags', version }),
          tx.done,
        ]);
      },
    );
  }

  async fetchOne(id: Tag['id']): Promise<Tag | null> {
    let item = await this.db.get('tags', id);
    if (!item) {
      // TODO: fetch from server
      return null;
    }

    return this.getOrCreateFromIdentityMap(item);
  }

  async fetchAll(): Promise<Tag[]> {
    return await this.tracing.inSpan(
      { name: 'fetchAll', resource: 'inbox2-core-data.tags' },
      async () => {
        let dbTags = await this.db.getAll('tags');

        return dbTags.map((tagData) => this.getOrCreateFromIdentityMap(tagData));
      },
    );
  }

  async fetchAllSearchable(): Promise<SearchableDocument[]> {
    return await this.tracing.inSpan(
      { name: 'fetchAllSearchable', resource: 'inbox2-core-data.tags' },
      async () => {
        let dbTags = await this.db.getAll('tags');

        let searchable = dbTags.map((tagData) => {
          let tag = this.getOrCreateFromIdentityMap(tagData);

          return new SearchableDocument(
            EntityType.Tag,
            SearchableType.Tag,
            new Date(),
            new Date(),
            tag,
            undefined,
            undefined,
            tagData.fuzzy,
          );
        });

        return searchable;
      },
    );
  }

  private getOrCreateFromIdentityMap(tagData: TagSchema) {
    let tag = this.identityMap.get(tagData.id);
    if (!tag) {
      tag = Tag.deserialize(tagData);
      this.identityMap.set(tagData.id, tag);
    }
    return tag;
  }
}
