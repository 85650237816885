/* RESPONSIBLE TEAM: team-frontend-tech */
let ShareUrls = {
  facebook(url) {
    return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  },
  twitter(url, text) {
    return `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
  },
  linkedin(url, text) {
    return `https://www.linkedin.com/shareArticle?url=${url}&title=${text.replace(/\s/g, '+')}`;
  },
};

export default ShareUrls;
