/* RESPONSIBLE TEAM: team-workflows */
import HeaderConfiguration from 'embercom/objects/content-editor/header-configuration';

export default HeaderConfiguration.extend({
  component: undefined,

  init(inputs) {
    inputs.component = 'content-editor/headers/edit-mode/workflow-header';
    this._super(...arguments);
  },
});
