/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import { ChildAction, ParentAction } from './action';
import type Session from 'embercom/services/session';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type TwilioService from 'embercom/services/twilio-service';

export enum ComposeNewAction {
  newConversation = 'new-conversation',
  newTicket = 'new-ticket',
  newPhoneCall = 'phone-call',
}

export default class ComposeNew extends ParentAction {
  @service declare session: Session;
  @service declare intercomCallService: IntercomCallService;
  @service declare twilioService: TwilioService;

  id = 'compose-new';
  paneComponent = 'inbox2/command-k/compose-new/pane';

  buildTopLevelItems(query: string): Array<ChildAction> {
    return this.buildResults(query);
  }

  buildResults(query: string): Array<ChildAction> {
    let upperQuery = query.trim().toLocaleUpperCase();
    return this.allOptions.filter((action) =>
      action.label.toLocaleUpperCase().includes(upperQuery),
    );
  }

  private get allOptions(): Array<ChildAction> {
    let options = [
      new ChildAction({
        parent: this,
        id: ComposeNewAction.newConversation,
        label: this.intl.t('inbox.command-k.actions.create-new-conversation'),
        icon: 'chat-filled',
      }),
      new ChildAction({
        parent: this,
        id: ComposeNewAction.newTicket,
        label: this.intl.t('inbox.command-k.actions.create-new-ticket'),
        icon: 'ticket',
      }),
    ];

    if (this.intercomCallService.hasActiveOutboundPhoneNumber && !this.twilioService.isActiveCall) {
      options.push(
        new ChildAction({
          parent: this,
          id: ComposeNewAction.newPhoneCall,
          label: this.intl.t('calling.phone-call'),
          icon: 'phone',
        }),
      );
    }
    return options;
  }
}
