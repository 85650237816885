/* RESPONSIBLE TEAM: team-data-interop */
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

export interface WorkflowConnectorActionDetailsWireFormat {
  description: string;
  request_attribute_identifiers: Array<string>;
  response_attribute_identifiers: Array<string>;
}

export interface WorkflowConnectorActionWireFormat {
  id: number;
  name: string;
  details: WorkflowConnectorActionDetailsWireFormat;
  created_at: string;
  created_by?: AdminSummaryWireFormat;
}

export class WorkflowConnectorActionDetails {
  description: string;
  request_attribute_identifiers: Array<string>;
  response_attribute_identifiers: Array<string>;

  constructor(
    description: string,
    request_attribute_identifiers: Array<string>,
    response_attribute_identifiers: Array<string>,
  ) {
    this.description = description;
    this.request_attribute_identifiers = request_attribute_identifiers;
    this.response_attribute_identifiers = response_attribute_identifiers;
  }
}

export default class WorkflowConnectorAction {
  readonly id: number;
  readonly name: string;
  readonly createdAt: Date;
  readonly details: WorkflowConnectorActionDetails;
  createdBy?: AdminSummary;

  constructor(
    id: number,
    name: string,
    details: WorkflowConnectorActionDetails,
    createdBy: AdminSummary | undefined,
    createdAt: Date | undefined = new Date(),
  ) {
    this.id = id;
    this.name = name;
    this.details = details;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
  }

  static deserialize(json: WorkflowConnectorActionWireFormat): WorkflowConnectorAction {
    let createdBy = json.created_by ? AdminSummary.deserialize(json.created_by) : undefined;
    let createdAt = json.created_at ? new Date(json.created_at) : undefined;
    return new WorkflowConnectorAction(json.id, json.name, json.details, createdBy, createdAt);
  }

  static serialize(
    action: WorkflowConnectorAction,
  ): Pick<WorkflowConnectorActionWireFormat, 'id' | 'name'> {
    return { id: action.id, name: action.name };
  }
}
