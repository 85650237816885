/* RESPONSIBLE TEAM: team-proactive-support */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';

export default function generateEditModeConfig(options) {
  return EditModeConfig.create({
    headerComponentConfigs: [],
    editorPanelConfigs: [],
    footerComponentConfigs: [],
  });
}
