/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { gt, not, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import StateProperties from 'embercom/models/mixins/common/states/state-properties';
import ControlGroupValidations from 'embercom/validations/messages/control-group';

export default Model.extend(ControlGroupValidations, StateProperties, {
  appService: service(),
  app: readOnly('appService.app'),

  message: belongsTo('message'),
  stats: belongsTo('messages/control-group-stats'),

  state: attr('string'),
  wentLiveAt: attr('date'),
  createdAt: attr('date'),
  stoppedAt: attr('date'),

  label: 'control',

  isControlGroup: true,

  typeIcon: 'person',

  hasBeenSent: gt('stats.sentCount', 0),
  hasNotBeenSent: not('hasBeenSent'),

  analyticsData: computed('id', 'state', function () {
    return {
      id: this.id,
      object: 'control-group',
      state: this.state,
    };
  }),
});
