/* RESPONSIBLE TEAM: team-tickets-1 */
export interface GithubLinkSummaryConstructorFormat {
  id: string;
  title: string | null;
  conversationId: string;
  rawLink: string;
  issueId: string;
}

export interface GithubLinkSummaryWireFormat {
  id: string;
  title: string | null;
  conversation_id: string;
  raw_link: string;
  issue_id: string;
}

export default class GithubLinkSummary {
  readonly id: string;
  readonly title: string | null;
  readonly conversationId: string;
  readonly rawLink: string;
  readonly issueId: string;

  constructor(inputs: GithubLinkSummaryConstructorFormat) {
    let { id, title, conversationId, rawLink, issueId } = inputs;
    this.id = id;
    this.title = title;
    this.conversationId = conversationId;
    this.rawLink = rawLink;
    this.issueId = issueId;
  }

  static deserialize(json: GithubLinkSummaryWireFormat) {
    return new GithubLinkSummary({
      id: json.id,
      title: json.title,
      conversationId: json.conversation_id,
      rawLink: json.raw_link,
      issueId: json.issue_id,
    });
  }

  get tableViewName() {
    return `#${this.issueId}: ${this.title ? this.title : this.rawLink}`;
  }
}
