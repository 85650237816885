/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr, belongsTo } from '@ember-data/model';

export default class PushCredential extends Model {
  @attr('string') mobileAppName;
  @attr('string') bundleId;
  @attr('string') keyId;
  @attr('string') teamId;
  @attr('string') tokenFileName;
  @attr('string') fcmServerKey;
  @attr('boolean') isDefault;

  @belongsTo('sdk-app') sdkApp;
}
