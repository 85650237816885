/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import { applyFunction } from '@intercom/pulse/lib/computed-properties';
import moment from 'moment-timezone';

let formatDate = function (datestring) {
  return isEmpty(datestring) ? 'Never' : moment(datestring).fromNow();
};

export default Model.extend({
  opened_at: attr(),
  sent_at: attr(),
  replied_at: attr(),
  clicked_at: attr(),
  responded_with: attr(),
  responded_at: attr(),
  unsubscribed_at: attr(),
  bounced_at: attr(),
  complained_at: attr(),
  hit_goal_at: attr(),
  user: belongsTo('user'),
  displayOpenedAt: applyFunction(formatDate, 'opened_at'),
  displaySentAt: applyFunction(formatDate, 'sent_at'),
  displayRepliedAt: applyFunction(formatDate, 'replied_at'),
  displayClickedAt: applyFunction(formatDate, 'clicked_at'),
  displayUnsubscribedAt: applyFunction(formatDate, 'unsubscribed_at'),
  displayHitGoalAt: applyFunction(formatDate, 'hit_goal_at'),
  displayBouncedAt: applyFunction(formatDate, 'bounced_at'),
  displayComplainedAt: applyFunction(formatDate, 'complained_at'),
  displayRespondedAt: applyFunction(formatDate, 'responded_at'),

  displayResponseType: computed('responded_with', function () {
    let responded_with = this.responded_with;
    if (isPresent(responded_with)) {
      return this.responded_with.replace('_', ' '); // TODO remove this replace once LWRs are no more --LC
    } else {
      return `\u2014`; //em dash
    }
  }),
});
