/* RESPONSIBLE TEAM: team-workflows */
import Model, { attr } from '@ember-data/model';

export default class DuplicateConversationDetection extends Model {
  @attr('boolean') enabled;

  isValid = true;

  get isNotDirty() {
    return !this.hasDirtyAttributes;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
