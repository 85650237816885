/* RESPONSIBLE TEAM: team-phone */

import Model, { attr } from '@ember-data/model';

export enum PhoneNumberType {
  TollFree = 0,
  Local = 1,
  Mobile = 2,
}

export const PhoneNumberTypeToTwilioString = new Map<PhoneNumberType, string>([
  [PhoneNumberType.TollFree, 'toll-free'],
  [PhoneNumberType.Local, 'local'],
  [PhoneNumberType.Mobile, 'mobile'],
]);

export const TwilioStringToPhoneNumberType = new Map<string, PhoneNumberType>([
  ['toll-free', PhoneNumberType.TollFree],
  ['local', PhoneNumberType.Local],
  ['mobile', PhoneNumberType.Mobile],
]);

export interface PhoneNumberCountryDetails {
  flag: string;
  prefix: string;
  supportedNumberTypes: Array<PhoneNumberType>;
  privateOfferingTypes: Array<PhoneNumberType>;
  requiringBundleTypes: Array<PhoneNumberType>;
  requiringAddressTypes: Array<PhoneNumberType>;
  requiringLocalAddressTypes: Array<PhoneNumberType>;
  bundleProvidesAddressFor: Array<PhoneNumberType>;
}

export const PHONE_NUMBER_COUNTRY_DETAILS = new Map<string, PhoneNumberCountryDetails>([
  [
    'AU',
    {
      flag: '🇦🇺',
      prefix: '+61',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [],
      requiringBundleTypes: [PhoneNumberType.Mobile],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
      bundleProvidesAddressFor: [PhoneNumberType.Mobile],
    },
  ],
  [
    'AT',
    {
      flag: '🇦🇹',
      prefix: '+43',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [],
      requiringBundleTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      bundleProvidesAddressFor: [PhoneNumberType.Local, PhoneNumberType.Mobile],
    },
  ],
  [
    'BE',
    {
      flag: '🇧🇪',
      prefix: '+32',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.Mobile],
      requiringBundleTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      bundleProvidesAddressFor: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
    },
  ],
  [
    'CA',
    {
      flag: '🇨🇦',
      prefix: '+1',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [],
      requiringBundleTypes: [],
      requiringAddressTypes: [],
      requiringLocalAddressTypes: [],
      bundleProvidesAddressFor: [],
    },
  ],
  [
    'CH',
    {
      flag: '🇨🇭',
      prefix: '+41',
      supportedNumberTypes: [PhoneNumberType.Local],
      privateOfferingTypes: [],
      requiringBundleTypes: [PhoneNumberType.Local],
      requiringAddressTypes: [PhoneNumberType.Local],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
      bundleProvidesAddressFor: [PhoneNumberType.Local],
    },
  ],
  [
    'DE',
    {
      flag: '🇩🇪',
      prefix: '+49',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [PhoneNumberType.TollFree, PhoneNumberType.Mobile],
      requiringBundleTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      bundleProvidesAddressFor: [PhoneNumberType.Local, PhoneNumberType.Mobile],
    },
  ],
  [
    'DK',
    {
      flag: '🇩🇰',
      prefix: '+45',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      bundleProvidesAddressFor: [PhoneNumberType.Local],
    },
  ],
  [
    'FI',
    {
      flag: '🇫🇮',
      prefix: '+358',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [PhoneNumberType.Local],
      requiringBundleTypes: [],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
      bundleProvidesAddressFor: [],
    },
  ],
  [
    'FR',
    {
      flag: '🇫🇷',
      prefix: '+33',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      bundleProvidesAddressFor: [PhoneNumberType.Local, PhoneNumberType.Mobile],
    },
  ],
  [
    'GB',
    {
      flag: '🇬🇧',
      prefix: '+44',
      supportedNumberTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      privateOfferingTypes: [],
      requiringBundleTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringAddressTypes: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
      requiringLocalAddressTypes: [],
      bundleProvidesAddressFor: [
        PhoneNumberType.Local,
        PhoneNumberType.TollFree,
        PhoneNumberType.Mobile,
      ],
    },
  ],
  [
    'IE',
    {
      flag: '🇮🇪',
      prefix: '+353',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      bundleProvidesAddressFor: [PhoneNumberType.TollFree],
    },
  ],
  [
    'LU',
    {
      flag: '🇱🇺',
      prefix: '+352',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      bundleProvidesAddressFor: [PhoneNumberType.Local, PhoneNumberType.TollFree],
    },
  ],
  [
    'NL',
    {
      flag: '🇳🇱',
      prefix: '+31',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.Mobile],
      privateOfferingTypes: [PhoneNumberType.Local],
      requiringBundleTypes: [PhoneNumberType.Local],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.Mobile],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
      bundleProvidesAddressFor: [PhoneNumberType.Local],
    },
  ],
  [
    'NO',
    {
      flag: '🇳🇴',
      prefix: '+47',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      bundleProvidesAddressFor: [PhoneNumberType.TollFree, PhoneNumberType.Mobile],
    },
  ],
  [
    'NZ',
    {
      flag: '🇳🇿',
      prefix: '+64',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [],
      requiringBundleTypes: [PhoneNumberType.Local],
      requiringAddressTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
      bundleProvidesAddressFor: [PhoneNumberType.Local],
    },
  ],
  [
    'SE',
    {
      flag: '🇸🇪',
      prefix: '+46',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      requiringBundleTypes: [PhoneNumberType.Local],
      requiringAddressTypes: [PhoneNumberType.Local],
      requiringLocalAddressTypes: [PhoneNumberType.Local],
      bundleProvidesAddressFor: [PhoneNumberType.Local],
    },
  ],
  [
    'US',
    {
      flag: '🇺🇸',
      prefix: '+1',
      supportedNumberTypes: [PhoneNumberType.Local, PhoneNumberType.TollFree],
      privateOfferingTypes: [],
      requiringBundleTypes: [],
      requiringAddressTypes: [],
      requiringLocalAddressTypes: [],
      bundleProvidesAddressFor: [],
    },
  ],
]);

export const COUNTRIES_LIST = Array.from(PHONE_NUMBER_COUNTRY_DETAILS.keys());

function isSupportedByCountryForNumberType(
  key: keyof Omit<PhoneNumberCountryDetails, 'flag' | 'prefix'>,
  country: string,
  numberType: PhoneNumberType,
) {
  return PHONE_NUMBER_COUNTRY_DETAILS.get(country)?.[key].includes(numberType);
}

export function countrySupportsNumberType(country: string, numberType: PhoneNumberType) {
  return isSupportedByCountryForNumberType('supportedNumberTypes', country, numberType);
}

export function countryNeedsBundleForNumberType(country: string, numberType: PhoneNumberType) {
  return isSupportedByCountryForNumberType('requiringBundleTypes', country, numberType);
}

export function countryNeedAddressForNumberType(country: string, numberType: PhoneNumberType) {
  return (
    isSupportedByCountryForNumberType('requiringAddressTypes', country, numberType) &&
    !isSupportedByCountryForNumberType('bundleProvidesAddressFor', country, numberType)
  );
}

export function countryNeedsLocalAddressForNumberType(
  country: string,
  numberType: PhoneNumberType,
) {
  return isSupportedByCountryForNumberType('requiringLocalAddressTypes', country, numberType);
}

export function countryNeedsPrivateOfferingForNumberType(
  country: string,
  numberType: PhoneNumberType,
) {
  return isSupportedByCountryForNumberType('privateOfferingTypes', country, numberType);
}

export type PhoneNumber = {
  country_iso_code: string;
  phone_number: string;
  twilio_sid: string;
  status: string;
};

export const TRIAL_CREDIT_FULL_AMOUNT = 10;

export default class Calling extends Model {
  @attr('array') declare phoneNumbers: PhoneNumber[];
  @attr('boolean') declare recordingEnabled: boolean;
  @attr('boolean') declare recordingEnabledOutbound: boolean;
  @attr('boolean') declare recordingEnabledMessenger: boolean;
  @attr('string') declare recordingWarningMessage: string;
  @attr('string') declare recordingWarningMessageOutbound: string;
  @attr('string') declare recordingWarningMessageMessenger: string;
  @attr('boolean') declare inboundEnabled: boolean;
  @attr('boolean') declare outboundEnabled: boolean;
  @attr('boolean') declare wrapUpTimeEnabled: boolean;
  @attr('string') declare wrapUpTimeUnit: string;
  @attr('number') declare wrapUpTimeAmount: number;
  @attr('boolean') declare messengerCallingEnabled: boolean;
  @attr('boolean') declare deleteRecordingAndTranscriptionEnabled: boolean;
  @attr('number') declare recordingAndTranscriptionRetentionPeriodInDays: number;
  @attr('number') declare trialCreditBalance: number;
  @attr('boolean') declare callingAllowed: boolean;
  @attr('boolean') declare unpaidCustomerExceededCallingLimit: boolean;
  @attr('boolean') declare callListeningEnabled: boolean;
  // metadata for attribution metrics
  @attr('string') declare currentPricingPlan: string;
  @attr('boolean') declare paidPhoneNumbersPresent: boolean;
  @attr('boolean') declare trialPhoneNumbersPresent: boolean;
  @attr('boolean') declare freeUsageTrialOngoing: boolean;
  @attr('date') declare freeUsageExpiredAt: Date;
  @attr('string') declare freeUsageExperimentGroup: string;
  @attr('boolean') declare legacyCustomerHasFreeUsageTrial: boolean;

  get isTrialCreditBalancePositive() {
    return this.trialCreditBalance > 0;
  }

  get canSeeFreeUsageTreatment() {
    return this.freeUsageExperimentGroup === 'variant' || this.legacyCustomerHasFreeUsageTrial;
  }

  get isFreeUsageAvailable() {
    if (!this.freeUsageExpiredAt) {
      return true;
    }

    return this.freeUsageExpiredAt > new Date();
  }

  get isFullTrialCredit(): boolean {
    return this.trialCreditBalance >= TRIAL_CREDIT_FULL_AMOUNT;
  }

  get isTrialCreditFinished(): boolean {
    return this.trialCreditBalance <= 0;
  }
}
