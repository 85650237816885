/* RESPONSIBLE TEAM: team-purchase-experience */
import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import {
  ARTICLES_ID,
  INBOX_ID,
  MESSAGES_ID,
  OPERATOR_ID,
  PRODUCTS,
  PRO_TIER_ID,
  SMS_BASE_ID,
} from 'embercom/lib/billing';
import productKeyFromId from 'embercom/lib/product-key-from-id';
import usageFormatter from 'embercom/lib/usage-formatter';
import moment from 'moment-timezone';
import { dasherize } from '@ember/string';
import type Plan from './plan';

const GROUPED_PRODUCTS = [SMS_BASE_ID];

export default class Product extends Model {
  @attr('string') declare name: string;
  @attr('string') declare pricingMetric: string;
  @attr('boolean') declare addon: boolean;
  @attr('boolean') declare trialable: boolean;
  @attr('date') declare activeTrialStartedAt: Date;
  @attr('date') declare activeTrialEndedAt: Date;
  @attr('date') declare latestTrialEndedAt: Date;
  @hasMany('plan', { async: false }) declare plans: SyncHasMany<Plan>;

  get idAsNumber(): number {
    return parseInt(this.id, 10);
  }

  get slug(): string {
    return dasherize(this.name);
  }

  get notTrialable(): boolean {
    return !this.trialable;
  }

  get active(): boolean {
    return isPresent(this.activePlan);
  }

  get inactive(): boolean {
    return !this.active;
  }

  get hasGroupedPricingMetrics(): boolean {
    return this.activePlan !== undefined && GROUPED_PRODUCTS.includes(this.activePlan.id);
  }

  get activePlan(): Plan | undefined {
    return this.plans.findBy('active', true);
  }

  get billableCustomerPlan(): Plan | undefined {
    return this.plans.findBy('billableCustomerPlan', true);
  }

  get hasOnePlan(): boolean {
    return this.plans.length === 1;
  }

  get isArticles(): boolean {
    return this.id === ARTICLES_ID;
  }

  get isMessages(): boolean {
    return this.id === MESSAGES_ID;
  }

  get isInbox(): boolean {
    return this.id === INBOX_ID;
  }

  get isOperator(): boolean {
    return this.id === OPERATOR_ID;
  }

  get hasProPlan(): boolean {
    return this.activePlan?.tierId === PRO_TIER_ID;
  }

  get selfServe(): boolean {
    return this.plans.any((plan) => plan.selfServe);
  }

  get hasQuarterlyActivePeoplePricingTable(): boolean {
    return this.pricingMetric === 'quarterly_active_people';
  }

  get hasMonthlyActivePeoplePricingTable(): boolean {
    return this.pricingMetric === 'monthly_active_people';
  }

  get hasPeopleRecordsPricingTable(): boolean {
    return this.pricingMetric === 'people_count';
  }

  hasPlan(planId: number): boolean {
    return this.plans.any((plan) => plan.idAsNumber === planId);
  }

  // // Only use for URLs/routes, otherwise use ID
  get key() {
    return productKeyFromId(this.id);
  }

  get activePlanName() {
    if (this.hasOnePlan) {
      return '';
    }
    return this.activePlan?.name;
  }

  get shortDescription(): any {
    return (PRODUCTS as any)[this.id].shortDescription;
  }

  get longDescription() {
    return (PRODUCTS as any)[this.id].longDescription;
  }

  get pricingMetricDisplayName() {
    if (this.hasGroupedPricingMetrics) {
      return '';
    }
    return `${usageFormatter.getDisplayName(this.pricingMetric)}`;
  }

  get videoId() {
    return (PRODUCTS as any)[this.id].videoId;
  }

  get route() {
    return (PRODUCTS as any)[this.id].route;
  }

  get optionClass() {
    return (PRODUCTS as any)[this.id].optionClass;
  }

  get analyticsData() {
    return {
      object: 'product',
      product_active: this.active,
    };
  }

  get isOnTrial(): boolean {
    return isPresent(this.activeTrialEndedAt);
  }

  get daysToTrialExpiration(): number {
    if (this.isPostTrial) {
      return 0;
    }

    let today = moment().startOf('day');
    let expirationDay = moment(this.activeTrialEndedAt).startOf('day');
    let diff = expirationDay.diff(today);
    return Math.round(moment.duration(diff).asDays());
  }

  get isPostTrial(): boolean {
    return !this.isOnTrial && this.trialLatelyExpired;
  }

  get trialLatelyExpired(): boolean {
    if (!isPresent(this.latestTrialEndedAt)) {
      return false;
    }
    let expirationDay = moment(this.latestTrialEndedAt).startOf('day');
    let today = moment().startOf('day');
    let diff = today.diff(expirationDay);
    return Math.round(moment.duration(diff).asDays()) <= 14;
  }
}
