/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, hasMany } from '@ember-data/model';

export default class NewLabelModel extends Model {
  @attr('string') name;
  @attr() label_assignments;
  @attr() created_at;
  @hasMany('news/item', { inverse: 'labels' }) newsItems;

  get news_item_count() {
    return this.label_assignments ? this.label_assignments.length : 0;
  }
}
