/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr, belongsTo } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Admin from 'embercom/models/admin';
import { defaultBlockWithModelKey } from 'embercom/models/data/outbound/constants';
import LocalizedNewsItemContentValidations from 'embercom/validations/localized-news-item-content';

export default class LocalizedNewsItemContentModel extends Model.extend(
  LocalizedNewsItemContentValidations,
) {
  @service store;
  @belongsTo('news/item', { inverse: 'localizedNewsItemContents' }) item;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [defaultBlockWithModelKey],
  })
  blocks;
  @attr('string') title;
  @attr('string') locale;
  @attr('string') senderId;
  @attr('number') senderType;
  @attr('string') coverImageUrl;
  @attr('number') replyType;
  @attr('boolean') deliverSilently;
  @fragmentArray('messages/reaction') reactionSet;

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.blocks.any((block) => block.hasDirtyAttributes);
  }

  get contentHash() {
    return this.serialize();
  }

  get sender() {
    return Admin.peekAndMaybeLoad(this.store, this.senderId);
  }

  get senderName() {
    return this.sender.firstNameOrNameOrEmail || 'Unknown';
  }
}
