/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { type SyncHasMany, attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { dependentKeyCompat } from '@ember/object/compat';
import seriesSplitterEditorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/series/splitter';
import SplitterValidations from 'embercom/validations/splitter';
import { isNone, isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import type EdgeSplit from './edge-split';
import { type edgeSplitIndexName } from '../data/matching-system/matching-constants';

export default class SeriesSplitter extends Model.extend(SplitterValidations) {
  @service declare store: Store;
  @service declare seriesEditorService: $TSFixMe;
  @hasMany('series/edge-split', { async: false }) declare edgeSplits: SyncHasMany<EdgeSplit>;
  @attr('number') declare winningEdgeSplitId: number;

  // @ts-expect-error - SplitterValidations not TS'd yet
  get isValid() {
    return this.validations.isValid;
  }

  get hasWinner() {
    return isPresent(this.winningEdgeSplitId);
  }

  get hasControlGroup() {
    return isPresent(this.edgeSplitControl);
  }

  get edgeSplitTests() {
    return this.edgeSplits.filter((edgeSplit) => edgeSplit.isTest);
  }

  get edgeSplitControl() {
    return this.edgeSplits.find((edgeSplit) => edgeSplit.isControl);
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.edgeSplits.any((edgeSplit) => Boolean(edgeSplit.hasDirtyAttributes))
    );
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this._clearLocallyCreatedEdgeSplits();
    this.edgeSplits.forEach((edgeSplit) => edgeSplit.rollbackAttributes());
    this.setEdgeSplitIndex();
  }

  ready() {
    this.setEdgeSplitIndex();
  }

  afterSave() {
    this._clearLocallyCreatedEdgeSplits();
    this.seriesEditorService.activeSeriesNode.edgeSplits = this.edgeSplits;
    this.setEdgeSplitIndex();
  }

  editorConfig() {
    let container = getOwner(this);
    return seriesSplitterEditorConfigGenerator({ container, splitter: this });
  }

  setEdgeSplitIndex() {
    this.edgeSplitTests.forEach((edgeSplit, index: keyof typeof edgeSplitIndexName) => {
      edgeSplit.index = index;
    });
  }

  _clearLocallyCreatedEdgeSplits() {
    this.edgeSplits
      .filter((edgeSplit) => isNone(edgeSplit.id))
      .forEach((edgeSplit) => {
        this.store.unloadRecord(edgeSplit);
      });
  }
}
