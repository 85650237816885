/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr } from '@ember-data/model';

export default class ReplyExpectations extends Model {
  @attr('boolean') showOfficeHoursAndReplyTimes;
  @attr('boolean') showOfficeHoursAndReplyTimesOnlyWhenAssignedToTeam;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
