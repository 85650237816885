/* RESPONSIBLE TEAM: team-workflows */
import EditorConfig, {
  type PathConfigGenerationParams,
  type StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { OutboundPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';

import { OutboundBotChatMessageConfiguration } from 'embercom/objects/visual-builder/configuration/step/chat-message';
import { OutboundBotReplyButtonsConfiguration } from '../step/reply-buttons';
import type Step from 'embercom/models/operator/visual-builder/step';

export default class OutboundBotEditorConfig extends EditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new OutboundPathConfig({ path, editorState });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.chatMessage:
        return new OutboundBotChatMessageConfiguration({
          ...stepConfigArgs,
          attributeService: this.attributeService,
        });
      case stepTypes.replyButtons:
        return new OutboundBotReplyButtonsConfiguration(stepConfigArgs);
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }
}
