/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import TourStep from '@intercom/intersection-shared-models/models/tour-step';

export default class TourStepModel extends TourStep {
  @attr('date') updatedAt;

  get hasCompanyAttributes() {
    return this.blocks.any((block) => block.text?.includes('{{company.'));
  }
}
