/* RESPONSIBLE TEAM: team-workflows */

import WorkflowDetails, {
  type WorkflowDetailsWireFormat,
} from 'embercom/objects/inbox/workflow-details';

export interface TriggerableCustomBotWireFormat {
  id: number;
  name: string;
  details: WorkflowDetailsWireFormat;
}

export default class TriggerableCustomBot {
  readonly id: number;
  readonly name: string;
  readonly details: WorkflowDetails;

  constructor(id: number, name: string, details: WorkflowDetails) {
    this.id = id;
    this.name = name;
    this.details = details;
  }

  static deserialize(json: TriggerableCustomBotWireFormat): TriggerableCustomBot {
    return new TriggerableCustomBot(json.id, json.name, WorkflowDetails.deserialize(json.details));
  }
}
