/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { equal, notEmpty } from '@ember/object/computed';

export default Model.extend({
  appId: attr('string'),
  identifier: attr('string'),
  value: attr(),
  isTwitter: equal('identifier', 'twitter'),
  isFacebook: equal('identifier', 'facebook'),
  isAddress: equal('identifier', 'address'),
  isLogo: equal('identifier', 'logo'),
  hasValue: notEmpty('value'),
});
