/* RESPONSIBLE TEAM: team-help-desk-experience */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { CONVERSATION_STATES } from 'embercom/lib/inbox/constants';
export default class DraftConversation extends Model {
  @service appService;

  @attr('string') initial_channel;
  @attr('array') blocks;
  @attr('string') title;
  @attr('array') recipient_ids;
  @attr('string') sender_id;
  @attr('number') sender_type;
  @attr('string') admin_assignee_id;
  @attr('string') team_assignee_id;
  @attr('array') actions;
  @attr('array') new_users;

  state = CONVERSATION_STATES.open;
  isDraft = true;
  isNotSocial = true;
  isChannelMessengerOrEmail = true;
  isOpen = true;

  get app() {
    return this.appService.app;
  }

  get adminToDisplay() {
    return this.app.humanAdmins.findBy('id', this.sender_id);
  }

  get firstRecipientId() {
    if (this.recipient_ids?.length > 0) {
      return this.recipient_ids[0];
    }
  }

  get recipientIdsExcludingNewUsers() {
    return this.recipient_ids.filter((id) => !id.startsWith('new-user-'));
  }
}
