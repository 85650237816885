/* RESPONSIBLE TEAM: team-proactive-support */
import { not, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import TabConfiguration from '../tab-configuration';

export default TabConfiguration.extend({
  contentEditorService: service(),

  isNotDraft: not('contentEditorService.ruleset.isDraft'),
  isVisible: readOnly('isNotDraft'),

  label: undefined,
  icon: 'path-analysis',
  view: undefined,

  init(inputs) {
    inputs.component = 'content-editor/tabs/custom-bot/path-analysis-tab';
    inputs.view = 'pathAnalysis';
    this._super(...arguments);
    this.setProperties({
      label: inputs.label,
      view: inputs.view,
    });
  },
});
