/* RESPONSIBLE TEAM: team-frontend-tech */
export function anonymizeQueryParams(queryParams, depth = 1) {
  if (!queryParams) {
    return queryParams;
  }

  if (depth >= 4) {
    // We don't want to spend a lot of time recursing params, or possibly go into infinite recursion.
    return typeof queryParams;
  }

  if (typeof queryParams === 'object') {
    let anonymize = {};
    for (let [key, value] of Object.entries(queryParams)) {
      anonymize[key] = anonymizeQueryParams(value, depth + 1);
    }
    return anonymize;
  } else if (typeof queryParams === 'string') {
    return `string(${queryParams.length})`;
  } else {
    return typeof queryParams;
  }
}
