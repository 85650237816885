/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { REQUIRED_CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES } from 'embercom/models/custom-objects/constants/standard-attribute-names';
import type ActionObjectMapping from './action-object-mapping';

export default class ActionAttributeMapping extends Fragment {
  @service declare attributeService: any;

  @attr('string') declare responseAttributePath: string;
  @attr('string') declare intercomAttributeIdentifier: string;
  @attr('string') declare mappingType: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  @tracked declare objectMapping: ActionObjectMapping;

  get required() {
    return REQUIRED_CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES.includes(
      this.intercomAttributeIdentifier,
    );
  }

  get apiMappableAttribute() {
    if (isEmpty(this.objectMapping)) {
      return null;
    }

    if (!this.objectMapping.apiMappableObjectPresent) {
      return null;
    }

    return this.objectMapping.apiMappableObject?.apiMappableAttributes.find(
      (e: any) => e.fullPath === this.responseAttributePath,
    );
  }

  get apiMappableAttributePresent() {
    return isPresent(this.apiMappableAttribute);
  }

  get intercomMappableAttribute() {
    if (isEmpty(this.objectMapping)) {
      return null;
    }

    if (!this.objectMapping.intercomMappableObjectPresent) {
      return null;
    }

    if (this.objectMapping.isCustomObjectMapping) {
      return this.objectMapping.intercomMappableObject.attributesInclStandard.findBy(
        'identifier',
        `custom_objects.${this.objectMapping.intercomObjectType}.${this.intercomAttributeIdentifier}`,
      );
    } else {
      return this.attributeService.findByIdentifier(this.intercomAttributeIdentifier);
    }
  }

  get intercomMappableAttributePresent() {
    return isPresent(this.intercomMappableAttribute);
  }

  get intercomAttributeDataType() {
    if (!this.intercomMappableAttributePresent) {
      return null;
    }

    let dataType = this.intercomMappableAttribute.type;

    if (dataType === 'float') {
      dataType = 'decimal';
    } else if (dataType === 'date') {
      dataType = 'datetime';
    } else if (dataType === 'conversation_attribute_list') {
      dataType = 'list';
    } else if (dataType === 'attribute_descriptor_list') {
      dataType = 'list';
    } else if (this.intercomMappableAttribute.hasOptions) {
      dataType = 'list';
    }

    return dataType;
  }

  get validationErrors() {
    if (this.mappingType === 'context_mapping') {
      return [];
    }

    if (!this.apiMappableAttributePresent) {
      return ['api_attribute_missing'];
    }

    if (!this.intercomMappableAttributePresent) {
      return ['intercom_attribute_missing'];
    }

    if (this.dataTypesIncompatible) {
      return ['data_type_incompatible'];
    }

    return [];
  }

  get dataTypesIncompatible() {
    if (!this.apiMappableAttributePresent) {
      return false;
    }

    if (!this.intercomMappableAttributePresent) {
      return false;
    }

    // intercom_data_type: [api_attribute_data_types]
    // https://docs.google.com/spreadsheets/d/16WXUdtKnUODIU7RzOVCmaYBz88bXg0hn97ZlbVQKNS8/edit#gid=789323593
    // make sure to update this on the backend in app/lib/workflow_connector/response_processor/object_writer.rb
    let intercomToApiAllowedDataTypeCombinations: any = {
      string: ['string', 'integer', 'decimal', 'datetime', 'boolean', 'unknown'],
      integer: ['integer'],
      decimal: ['integer', 'decimal'],
      datetime: ['datetime'],
      boolean: ['boolean'],
      list: [],
      relationship: ['string', 'integer'],
    };

    let apiAttributeDataType = this.apiMappableAttribute?.dataType;
    let allowedApiAttributeDataTypes =
      intercomToApiAllowedDataTypeCombinations[this.intercomAttributeDataType];
    if (isEmpty(allowedApiAttributeDataTypes)) {
      return true;
    }
    return !allowedApiAttributeDataTypes.includes(apiAttributeDataType);
  }

  hydrateParent(objectMapping: any) {
    this.objectMapping = objectMapping;
  }
}
