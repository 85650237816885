/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from '../component-configuration';
import { assert } from '@ember/debug';
import { readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default ComponentConfiguration.extend({
  contentEditorService: service(),

  component: undefined,
  title: undefined,
  errors: undefined,
  isComplete: undefined,

  _openedPanels: readOnly('contentEditorService.ruleset.clientData.openedPanels'),
  hasBeenOpened: computed('_openedPanels.[]', 'parentConfiguration.name', function () {
    if (this._openedPanels && this.parentConfiguration) {
      return this._openedPanels.includes(this.parentConfiguration.name);
    }
  }),

  init(inputs) {
    this._super(...arguments);
    assert(`The panel header configuration must be provided with a title`, inputs.title);
    assert(
      `The component path must be within the content-editor/panels directory. You passed '${inputs.component}'`,
      inputs.component.startsWith('content-editor/panels/'),
    );

    this.setProperties(inputs);

    assert(`A value or computed property must be defined for 'errors'`, this.errors !== undefined);

    assert(
      `A value or computed property must be defined for 'isComplete'`,
      this.isComplete !== undefined,
    );
  },
});
