/* RESPONSIBLE TEAM: team-ml */
import { ParentAction, ChildAction, type ParentActionConfig } from './action';
import { NamedIcon } from 'embercom/objects/inbox/command-k/icons';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import Conversation, { type NewConversation } from '../conversation';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import type AiAssistSettings from 'embercom/services/ai-assist-settings';
import { SUPPORTED_LANGUAGES } from 'embercom/lib/ai-assist';

export default class AIAssist extends ParentAction {
  @service declare session: Session;
  @service declare aiAssistSettings: AiAssistSettings;
  @service declare inboxHotkeys: InboxHotkeys;

  static id = 'ai-assist';

  static someFeatureEnabled(
    session: Session,
    aiAssistSettings: AiAssistSettings,
    conversation?: Conversation | NewConversation,
  ): boolean {
    return (
      this.insertingSummaryEnabled(aiAssistSettings, conversation) ||
      this.rewriteFeaturesEnabled(aiAssistSettings) ||
      this.askFinEnabled(session, conversation)
    );
  }

  static rewriteFeaturesEnabled(aiAssistSettings: AiAssistSettings): boolean {
    return aiAssistSettings.textTransformations ?? false;
  }

  static askFinEnabled(session: Session, conversation?: Conversation | NewConversation): boolean {
    return !!conversation && session.isCopilotEnabled;
  }

  static insertingSummaryEnabled(
    aiAssistSettings: AiAssistSettings,
    conversation?: Conversation | NewConversation,
  ): boolean {
    return (
      !!conversation &&
      conversation instanceof Conversation &&
      conversation.commentOrNoteParts.length > 0 &&
      (aiAssistSettings.conversationSummarization ?? false)
    );
  }

  id = AIAssist.id;
  icon = new NamedIcon('ai');
  paneComponent = 'inbox2/command-k/ai-assist/pane';

  constructor(args: ParentActionConfig) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.AiAssist];
  }

  buildTopLevelItems(query: string): Array<ChildAction> {
    // Translate is filtered out because it should open a second stage with different language options,
    // but this type of behaviour is not currently supported in Command K.
    return this.buildResults(query).filter((action) => action.id !== 'ai-assist-translate');
  }

  buildResults(query: string): Array<ChildAction> {
    let upperQuery = query.trim().toLocaleUpperCase();
    return this.allOptions.filter((action) =>
      action.label.toLocaleUpperCase().includes(upperQuery),
    );
  }

  buildTranslationOptions(query: string): Array<ChildAction> {
    let actions: Array<ChildAction> = [];

    SUPPORTED_LANGUAGES.forEach((lang) => {
      actions.push(
        new ChildAction({
          parent: this,
          id: `translate-${lang}`,
          label: this.intl.t(`inbox.command-k.ai-assist.translate-${lang}`),
          isUnselectable: this.composerIsEmpty,
          isUnselectableReason: this.composerIsEmpty
            ? this.intl.t('inbox.command-k.ai-assist.unselectable-empty-composer')
            : undefined,
        }),
      );
    });

    let upperQuery = query.trim().toLocaleUpperCase();
    return actions.filter((action) => action.label.toLocaleUpperCase().includes(upperQuery));
  }

  get composerIsEmpty() {
    return !!this.context.composerIsEmpty;
  }

  private get allOptions(): Array<ChildAction> {
    let actions: Array<ChildAction> = [];
    if (AIAssist.insertingSummaryEnabled(this.aiAssistSettings, this.context.conversation)) {
      actions.push(
        new ChildAction({
          parent: this,
          id: 'insert-summary',
          label: this.context.conversation.isTicket
            ? this.intl.t('inbox.command-k.ai-assist.insert-summary-ticket')
            : this.intl.t('inbox.command-k.ai-assist.insert-summary-conversation'),
          trackingData: { action: 'trigger', object: 'insert_summary' },
        }),
      );
      actions.push(
        new ChildAction({
          parent: this,
          id: 'add-summary',
          label: this.context.conversation.isTicket
            ? this.intl.t('inbox.command-k.ai-assist.add-summary-ticket')
            : this.intl.t('inbox.command-k.ai-assist.add-summary-conversation'),
          trackingData: { action: 'trigger', object: 'add_summary' },
        }),
      );
    }

    if (AIAssist.rewriteFeaturesEnabled(this.aiAssistSettings)) {
      actions = actions.concat([
        new ChildAction({
          parent: this,
          id: 'tone',
          label: this.intl.t('inbox.command-k.ai-assist.openai-demo-tone'),
          isUnselectable: this.composerIsEmpty,
          isUnselectableReason: this.composerIsEmpty
            ? this.intl.t('inbox.command-k.ai-assist.unselectable-empty-composer')
            : undefined,
        }),
        new ChildAction({
          parent: this,
          id: 'friendly',
          label: this.intl.t('inbox.command-k.ai-assist.openai-demo-friendly'),
          isUnselectable: this.composerIsEmpty,
          isUnselectableReason: this.composerIsEmpty
            ? this.intl.t('inbox.command-k.ai-assist.unselectable-empty-composer')
            : undefined,
        }),
        new ChildAction({
          parent: this,
          id: 'shorten',
          label: this.intl.t('inbox.command-k.ai-assist.openai-demo-shorten'),
          isUnselectable: this.composerIsEmpty,
          isUnselectableReason: this.composerIsEmpty
            ? this.intl.t('inbox.command-k.ai-assist.unselectable-empty-composer')
            : undefined,
        }),
        new ChildAction({
          parent: this,
          id: 'formal',
          label: this.intl.t('inbox.command-k.ai-assist.openai-demo-formal'),
          isUnselectable: this.composerIsEmpty,
          isUnselectableReason: this.composerIsEmpty
            ? this.intl.t('inbox.command-k.ai-assist.unselectable-empty-composer')
            : undefined,
        }),
        new ChildAction({
          parent: this,
          id: 'rephrase',
          label: this.intl.t('inbox.command-k.ai-assist.openai-demo-rephrase'),
          isUnselectable: this.composerIsEmpty,
          isUnselectableReason: this.composerIsEmpty
            ? this.intl.t('inbox.command-k.ai-assist.unselectable-empty-composer')
            : undefined,
        }),
        new ChildAction({
          parent: this,
          id: 'expand',
          label: this.intl.t('inbox.command-k.ai-assist.openai-demo-expand'),
          isUnselectable: this.composerIsEmpty,
          isUnselectableReason: this.composerIsEmpty
            ? this.intl.t('inbox.command-k.ai-assist.unselectable-empty-composer')
            : undefined,
        }),
      ]);

      actions.push(
        new ChildAction({
          parent: this,
          id: 'translate',
          label: this.intl.t('inbox.command-k.ai-assist.translate'),
          isUnselectable: this.composerIsEmpty,
          isUnselectableReason: this.composerIsEmpty
            ? this.intl.t('inbox.command-k.ai-assist.unselectable-empty-composer')
            : undefined,
        }),
      );

      actions.push(
        new ChildAction({
          parent: this,
          id: 'auto-correct',
          label: this.intl.t('inbox.command-k.ai-assist.autocorrect'),
          isUnselectable: this.composerIsEmpty,
          isUnselectableReason: this.composerIsEmpty
            ? this.intl.t('inbox.command-k.ai-assist.unselectable-empty-composer')
            : undefined,
        }),
      );
    }

    return actions;
  }
}
