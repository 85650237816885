/* RESPONSIBLE TEAM: team-reporting */
import { type MetricDefinition, NO_DATASET } from './types';

const metrics: MetricDefinition[] = [
  {
    id: 'series.receipts',
    type: 'field',
    name: 'outbound.series.aggregated-stats.performance.started.header',
    description: 'reporting.metrics.outbound.description',
    valueDescription: 'reporting.outbound.value-description.series.started',
    dataPointFormat: 'outbound.series.aggregated-stats.performance.started.chart-tooltip',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'event.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_receipt'],
      },
    },
  },
  {
    id: 'series.receipts.series_disengagement',
    type: 'field',
    name: 'outbound.series.aggregated-stats.performance.disengaged.header',
    description: 'reporting.outbound.stat-chart',
    dataPointFormat: 'outbound.series.aggregated-stats.performance.disengaged.chart-tooltip',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'event.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_series_disengagement'],
      },
    },
  },
  {
    id: 'series.receipts.series_disengagement.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.series.disengaged',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'series.receipts.series_disengagement',
    denominatorMetricId: 'series.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'series.receipts.series_completion',
    type: 'field',
    name: 'outbound.series.aggregated-stats.performance.finished.header',
    description: 'outbound.series.aggregated-stats.performance.finished.header',
    dataPointFormat: 'outbound.series.aggregated-stats.performance.finished.chart-tooltip',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'event.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_series_completion'],
      },
    },
  },
  {
    id: 'series.receipts.series_completion.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.series.completion',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'series.receipts.series_completion',
    denominatorMetricId: 'series.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'series.receipts.series_exit',
    type: 'field',
    name: 'outbound.series.aggregated-stats.performance.exited.header',
    description: 'reporting.outbound.stat-chart',
    valueDescription: 'reporting.outbound.value-description.series.exited',
    dataPointFormat: 'outbound.series.aggregated-stats.performance.exited.chart-tooltip',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'event.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_series_exit'],
      },
    },
  },
  {
    id: 'series.receipts.series_exit.percent',
    type: 'percentage',
    name: 'outbound.series.aggregated-stats.performance.exited.header',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.series.exited',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'series.receipts.series_exit',
    denominatorMetricId: 'series.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'series.receipts.goal_success',
    type: 'field',
    name: 'outbound.series.aggregated-stats.performance.goal.header',
    description: 'reporting.outbound.stat-chart',
    valueDescription: 'reporting.outbound.value-description.series.goal',
    dataPointFormat: 'outbound.series.aggregated-stats.performance.goal.chart-tooltip',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'event.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_goal_success'],
      },
    },
  },
  {
    id: 'series.receipts.goal_success.percent',
    type: 'percentage',
    name: 'outbound.series.aggregated-stats.performance.goal.header',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.series.goal',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'series.receipts.goal_success',
    denominatorMetricId: 'series.receipts',
    datasetId: NO_DATASET,
  },
];

export { metrics };
