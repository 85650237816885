/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable promise/prefer-await-to-then */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, sort, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Admin from 'embercom/models/admin';
import ajax from 'embercom/lib/ajax';
import { senderTypes } from 'embercom/models/data/outbound/constants';

let Team = Admin.extend({
  appService: service(),
  app: readOnly('appService.app'),
  member_ids: attr(),
  member_ids_with_priority: attr(),
  teamMembers: computed('member_ids', function () {
    let member_ids = this.member_ids || [];
    return member_ids.map((id) => {
      return Admin.peekAndMaybeLoad(this.store, String(id));
    });
  }),
  teamAvatar: attr(),
  assigneeId: attr(),
  outboundType: senderTypes.admin,

  assignee_id: alias('assigneeId'),
  sortKey: ['name'],
  sortedTeamMembers: sort('teamMembers', 'sortKey'),
  primaryMembers: computed('member_ids_with_priority', function () {
    if (!this.member_ids_with_priority) {
      return [];
    }
    return this.member_ids_with_priority['1'] ?? [];
  }),
  secondaryMembers: computed('member_ids_with_priority', function () {
    if (!this.member_ids_with_priority) {
      return [];
    }
    return this.member_ids_with_priority['2'] ?? [];
  }),
  deleteAndReassign(adminIdToReassignTo) {
    let url = `/ember/teams/${this.id}`;
    return ajax({
      url,
      type: 'DELETE',
      data: JSON.stringify({ app_id: this.app.id, assignee_id: adminIdToReassignTo }),
    }).then(() => this.deleteRecord());
  },
});

export default Team;
