/* RESPONSIBLE TEAM: team-tickets-1 */
export interface ConversationTopicWireFormat {
  id: number | string;
  name: string;
}

export default class ConversationTopic {
  readonly id: number;
  readonly name: string;

  constructor(id: number | string, name: string) {
    this.id = parseInt(`${id}`, 10);
    this.name = name;
  }

  static deserialize(json: ConversationTopicWireFormat): ConversationTopic {
    return new ConversationTopic(json.id, json.name);
  }
}
