/* RESPONSIBLE TEAM: team-proactive-support */
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';

export default StandardSummaryConfiguration.extend({
  init() {
    this._super({
      // Null title as it is handle in summary.hbs
      title: null,
      component: 'content-editor/summaries/content/email/summary',
      isVisible: true,
    });
  },
});
