/* RESPONSIBLE TEAM: team-help-desk-experience */
import { modifier } from 'ember-modifier';
import scrollIntoViewIfNotVisible from 'embercom/lib/scroll-into-view-if-not-visible';

type scrollIntoViewOptions = {
  behavior: 'smooth' | 'instant' | 'auto';
  block: 'start' | 'center' | 'end' | 'nearest';
};

const scrollToElement = modifier<{
  Args: {
    Positional: [boolean?, ({ alignToTop?: boolean } | scrollIntoViewOptions)?];
  };
}>(
  (element, [active, options]: [boolean, { alignToTop: boolean }]) => {
    // alignTop will default to true in scrollIntoViewIfNotVisible
    if (active === false) {
      return;
    }

    scrollIntoViewIfNotVisible(element, options);
  },
  { eager: false },
);

export default scrollToElement;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'scroll-to-element': typeof scrollToElement;
  }
}
