/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import Admin from 'embercom/models/admin';

export default Model.extend({
  provider: attr(),
  profileUrl: attr(),
  adminId: attr(),
  admin: computed('adminId', function () {
    return Admin.peekAndMaybeLoad(this.store, this.adminId);
  }),
  imageUrl: attr(),
  username: attr(),
});
