/* RESPONSIBLE TEAM: team-help-desk-experience */
/* eslint-disable no-use-before-define */

import { type SearchableType } from 'embercom/models/data/inbox/searchable-types';
import { getOwner, setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type ActionRegistration } from 'embercom/services/command-k';
import type CommandKService from 'embercom/services/command-k';
import { type Icon } from './icons';
import { type Hotkey, type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { type HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type QuickSearchService from 'embercom/services/quick-search';
import { type SearchMultipleTypesResponse } from 'embercom/services/quick-search';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export type Action = SimpleAction | ParentAction | ChildAction;

export interface SimpleActionConfig {
  owner: unknown;
  registration?: ActionRegistration;
  id: string;
  icon?: InterfaceIconName | Icon;
  label?: string;
  shortcutId?: HotkeyID;
  hidden?: boolean;
  secret?: boolean;
}

export class SimpleAction {
  @service declare intl: IntlService;
  @service declare commandK: CommandKService;
  @service declare inboxHotkeys: InboxHotkeys;

  id: string;
  icon?: InterfaceIconName | Icon;

  private _label?: string;

  readonly shortcuts: Hotkey | object;
  readonly hotkeys: HotkeysMap;
  hidden: boolean;
  secret = false;
  hideOnSelect = true;

  constructor(args: SimpleActionConfig) {
    setOwner(this, args.owner);
    this.id = args.id;
    this.icon = args.icon;
    this._label = args.label;
    this.hotkeys = this.inboxHotkeys.hotkeysMap;
    this.shortcuts = args.shortcutId !== undefined ? this.hotkeys[args.shortcutId] : {};
    this.hidden = args.hidden || false;
    this.secret = args.secret || false;
  }

  get label() {
    return this._label || this.intl.t(`inbox.command-k.actions.${this.id}`);
  }

  get context() {
    return this.registration?.context;
  }

  get isPaywalled(): boolean {
    return this.paywall?.isActive ?? false;
  }

  get paywall() {
    return this.registration?.paywall;
  }

  get registration() {
    return this.commandK.registrationForAction(this);
  }

  onHover() {}
  resetState() {}
}

export interface ChildActionConfig {
  parent: ParentAction;
  id: string;
  label: string;
  value?: any;
  optionComponent?: string;
  icon?: InterfaceIconName | Icon;
  iconComponent?: 'inbox2/ticket-state-dot-icon';
  hint?: string;
  beforeOnSelect?: (selected: ChildAction) => unknown;
  onHover?: Function;
  shortcutId?: HotkeyID;
  trackingData?: any;
  highlightedLabel?: any;
  isUnselectable?: boolean;
  isUnselectableReason?: string;
}

export class ChildAction<T = any> {
  @service declare inboxHotkeys: InboxHotkeys;
  private _id: string;

  label: string;
  icon?: InterfaceIconName | Icon;
  iconComponent?: 'inbox2/ticket-state-dot-icon';
  hint?: string;

  parent: ParentAction;
  value: T;
  trackingData?: any;
  highlightedLabel?: any;

  // override the component when this option appears in the list
  optionComponent?: string;

  beforeOnSelect?: (selected: ChildAction) => unknown = () => {};
  onHover?: Function = () => {};

  readonly shortcuts: Hotkey | { display: [] };
  readonly isUnselectable: boolean = false;
  readonly isUnselectableReason: string | undefined;

  constructor(args: ChildActionConfig) {
    setOwner(this, getOwner(args.parent));
    this._id = args.id;
    this.label = args.label;
    this.parent = args.parent;
    this.value = args.value || args.id;
    this.optionComponent = args.optionComponent;
    this.icon = args.icon;
    this.iconComponent = args.iconComponent;
    this.hint = args.hint;
    this.beforeOnSelect = args.beforeOnSelect;
    this.shortcuts =
      args.shortcutId !== undefined
        ? this.inboxHotkeys.hotkeysMap[args.shortcutId]
        : { display: [] };
    this.trackingData = args.trackingData;
    this.highlightedLabel = args.highlightedLabel;
    this.isUnselectable = !!args.isUnselectable;
    this.isUnselectableReason = args.isUnselectableReason;

    if (args.onHover !== undefined) {
      this.onHover = args.onHover;
    }
  }

  get id(): string {
    return `${this.parent.id}-${this._id}`;
  }

  get paywall() {
    return this.parent.paywall;
  }

  get isPaywalled(): boolean {
    return this.parent.isPaywalled;
  }
}

export interface ParentActionConfig {
  owner: unknown;
  registration?: ActionRegistration;
}

export abstract class ParentAction {
  @service declare intl: IntlService;
  @service declare commandK: CommandKService;
  @service declare quickSearch: QuickSearchService;

  abstract id: string;

  icon?: InterfaceIconName | Icon;
  searchableTypes?: Array<SearchableType>;
  shortcuts: Hotkey | {} = {};
  hidden = false;
  secret = false;
  cacheLatest = false;
  hideOnSelect = true;

  // the component to be displayed when this action is selected
  abstract paneComponent: string;

  constructor(args: ParentActionConfig) {
    setOwner(this, args.owner);
  }

  get label() {
    return this.intl.t(`inbox.command-k.actions.${this.id}`);
  }

  buildTopLevelItems(
    _query: string,
    _quickSearchResults: SearchMultipleTypesResponse,
  ): Array<Action> | Promise<Array<Action>> {
    return [];
  }

  get context() {
    return this.registration?.context;
  }

  get searchableContext(): Array<any> {
    return [];
  }

  get placeholder(): string {
    return this.label;
  }

  get paywall() {
    return this.registration?.paywall;
  }

  get isPaywalled(): boolean {
    return this.paywall?.isActive ?? false;
  }

  get registration() {
    return this.commandK.registrationForAction(this);
  }

  onHover() {}
  resetState() {}
}
