/* RESPONSIBLE TEAM: team-help-desk-experience */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Modifier from 'ember-modifier';
import { assert } from '@ember/debug';
import { registerDestructor } from '@ember/destroyable';

class Timer {
  constructor() {
    this.reset();
  }

  start() {
    if (!this.running()) {
      this.startTime = performance.now();
    }
  }

  stop() {
    if (this.running()) {
      this.totalTime += performance.now() - this.startTime;
      this.startTime = null;
    }
    return this.totalTime;
  }

  reset() {
    this.totalTime = 0;
    this.startTime = null;
  }

  running() {
    return this.startTime !== null;
  }
}

export default class TrackMouseTime extends Modifier {
  @service mouseTrackingService;
  @service activityTrackingSamplingService;
  @service intercomEventService;

  element = null;
  didSetup = false;

  constructor(_owner, args) {
    super(...arguments);
    // validate tracker name exists
    assert('Expected name argument', typeof args.positional[0] === 'string');
    this.accumulatorKey = args.positional[0];
    this.timer = new Timer();
    this.paused = false;

    registerDestructor(this, () => {
      this.cleanup(this.element);
    });
  }

  @action mouseEnter() {
    this.timer.start();
    this.paused = false;
    if (this.activityTrackingSamplingService.shouldSampleActivity) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'mouseenter',
        object: this.accumulatorKey,
      });
    }
  }

  @action mouseLeave() {
    this.timer.stop();
    this.paused = false;
    if (this.activityTrackingSamplingService.shouldSampleActivity) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'mouseleave',
        object: this.accumulatorKey,
      });
    }
  }

  getAndResetAccumulatedTime() {
    let running = this.timer.running();
    let accumulatedTime = this.timer.stop();
    this.timer.reset();
    if (running) {
      this.timer.start();
    }
    return accumulatedTime;
  }

  pause() {
    this.paused = this.timer.running();
    this.timer.stop();
  }

  resume() {
    if (this.paused) {
      this.timer.start();
      this.paused = false;
    }
  }

  modify(element) {
    if (!this.didSetup) {
      this.mouseTrackingService.registerTracker(this);
      element.addEventListener('mouseenter', this.mouseEnter);
      element.addEventListener('mouseleave', this.mouseLeave);

      this.element = element;
      this.didSetup = true;
    }
  }

  cleanup = (element) => {
    element?.removeEventListener('mouseenter', this.mouseEnter);
    element?.removeEventListener('mouseleave', this.mouseLeave);
    this.mouseTrackingService.unregisterTracker(this);
  };
}
