/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Model, { attr, belongsTo } from '@ember-data/model';
import type ArticleContentReviewEntry from './article-content-review-entry';

export default class ArticleContentReview extends Model {
  @attr declare currentStatus: string;
  @attr('date') declare nextReviewRequiredAt: Date;
  @attr('date') declare createdAt: Date;
  @belongsTo('articles/article-content-review-entry')
  declare latestSignificantReviewEntry?: ArticleContentReviewEntry;
}
