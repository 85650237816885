/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import { EntityType } from 'embercom/models/data/entity-types';

export type RemovingEntity = AdminSummary;
export type RemovingEntityType = EntityType.Admin;
interface ParticipantRemovedWireFormat {
  renderable_type: RenderableType;
  participant_summary: UserSummaryWireFormat;
  admin_summary: AdminSummaryWireFormat;
}

export default class ParticipantRemoved implements RenderableData {
  readonly renderableType = RenderableType.ParticipantRemoved;
  readonly participantSummary: UserSummary;
  readonly adminSummary: AdminSummary;

  get creatingEntity() {
    return { type: EntityType.Admin, id: this.adminSummary.id, object: this.adminSummary };
  }

  constructor(participantSummary: UserSummary, adminSummary: AdminSummary) {
    this.participantSummary = participantSummary;
    this.adminSummary = adminSummary;
  }

  static deserialize(json: ParticipantRemovedWireFormat): ParticipantRemoved {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);

    let participantSummary = UserSummary.deserialize(json.participant_summary);

    return new ParticipantRemoved(participantSummary, adminSummary);
  }
}
