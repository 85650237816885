/* RESPONSIBLE TEAM: team-product-setup */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const CHARTS_ICONS = [
  'chart-bar',
  'presentation-chart-line',
  'chart-pie',
  'presentation-chart-bar',
  'chart-bar-square',
];

const chartCommonKeywords = [
  'chart',
  'data',
  'analytics',
  'business',
  'performance',
  'metrics',
  'report',
  'visualization',
  'statistics',
];

export const CHARTS_KEYWORDS = {
  [CHARTS_ICONS[0]]: [
    ...chartCommonKeywords,
    'bar',
    'trends',
    'measurements',
    'dashboard',
    'graph',
  ],
  [CHARTS_ICONS[1]]: [
    ...chartCommonKeywords,
    'line',
    'growth',
    'presentation',
    'dashboard',
    'graph',
    'trend',
  ],
  [CHARTS_ICONS[2]]: [...chartCommonKeywords, 'pie', 'segments', 'distribution'],
  [CHARTS_ICONS[3]]: [...chartCommonKeywords, 'bar', 'graph', 'data', 'analysis', 'presentation'],
  [CHARTS_ICONS[4]]: [...chartCommonKeywords, 'bar', 'graph', 'data', 'analysis'],
};
