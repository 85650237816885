/* RESPONSIBLE TEAM: team-pricing-and-packaging */
export enum TierPriceFormat {
  FlatFee = 'FlatFee',
  PerUnit = 'PerUnit',
}

export interface Tier {
  id: number;
  ending_unit: number;
  price: number;
  price_format: TierPriceFormat;
  starting_unit: number;
}

export interface Block extends Tier {
  cumulative_price: number;
}
