/* RESPONSIBLE TEAM: team-proactive-support */

import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import type AiContentSegment from 'embercom/models/ai-content-segment';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class AiContentSegmentRulesetLink extends RulesetLink {
  @belongsTo('ai-content-segment', { async: false }) declare object: AiContentSegment;
  readonly objectType = objectTypes.aiContentSegment;
}
