/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { readOnly } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations(
  {
    websiteUrl: [
      validator('presence', { presence: true, message: 'This field is required' }),
      validator('format', { type: 'url' }),
    ],
    securityCertificatesPageUrl: [validator('format', { allowBlank: true, type: 'url' })],
    productUptimeDescription: [validator('length', { max: 1000 })],
    productKeyValueDescription: [validator('length', { max: 1000 })],
    topCustomersDescription: [validator('length', { max: 1000 })],
    technicalPocEmail: [
      validator('format', { allowBlank: true, type: 'email' }),
      validator('presence', { presence: true, message: 'This field is required' }),
    ],
    marketingPocEmail: [validator('format', { allowBlank: true, type: 'email' })],
    partnershipPocEmail: [validator('format', { allowBlank: true, type: 'email' })],
    technicalPocName: [
      validator('length', { max: 100 }),
      validator('presence', { presence: true, message: 'This field is required' }),
    ],
    marketingPocName: [validator('length', { max: 100 })],
    partnershipPocName: [validator('length', { max: 100 })],
    technicalPocTitle: [
      validator('length', { max: 100 }),
      validator('presence', { presence: true, message: 'This field is required' }),
    ],
    marketingPocTile: [validator('length', { max: 100 })],
    partnershipTile: [validator('length', { max: 100 })],
    customersSize: [validator('presence', { presence: true, message: 'Please select an option' })],
    salesTeamSize: [validator('presence', { presence: true, message: 'Please select an option' })],
    marketingTeamSize: [
      validator('presence', { presence: true, message: 'Please select an option' }),
    ],
  },
  {
    disabled: readOnly('model.validationsDisabled'),
  },
);

export default Fragment.extend(Validations, {
  tier: attr('string'), //enum
  websiteUrl: attr('string', { humanisedName: 'Website URL' }),
  customersSize: attr('string'), //enum
  customersPrimarilyServed: attr('array'), //JSON
  topCustomersDescription: attr('string'), //text
  securityCertificates: attr('array'), //JSON
  securityCertificatesPageUrl: attr('string', { humanisedName: 'Security Certificates Page URL' }),
  productUptimeDescription: attr('string'), //text
  productKeyValueDescription: attr('string'), //text
  technicalPocName: attr('string'),
  technicalPocEmail: attr('string'),
  technicalPocTitle: attr('string'),
  marketingPocName: attr('string'),
  marketingPocEmail: attr('string'),
  marketingPocTitle: attr('string'),
  partnershipPocName: attr('string'),
  partnershipPocEmail: attr('string'),
  partnershipPocTitle: attr('string'),
  salesTeamSize: attr('string'), //enum
  marketingTeamSize: attr('string'), //enum

  validationsDisabled: true,

  websiteUrlErrors: readOnly('validations.attrs.websiteUrl.errors'),
  securityCertificatesPageUrlErrors: readOnly(
    'validations.attrs.securityCertificatesPageUrl.errors',
  ),
  productUptimeDescriptionErrors: readOnly('validations.attrs.productUptimeDescription.errors'),
  productKeyValueDescriptionErrors: readOnly('validations.attrs.productKeyValueDescription.errors'),
  topCustomersDescriptionErrors: readOnly('validations.attrs.topCustomersDescription.errors'),
  technicalPocEmailErrors: readOnly('validations.attrs.technicalPocEmail.errors'),
  marketingPocEmailErrors: readOnly('validations.attrs.marketingPocEmail.errors'),
  technicalPocNameErrors: readOnly('validations.attrs.technicalPocName.errors'),
  marketingPocNameErrors: readOnly('validations.attrs.marketingPocName.errors'),
  technicalPocTitleErrors: readOnly('validations.attrs.technicalPocTitle.errors'),
  marketingPocTitleErrors: readOnly('validations.attrs.marketingPocTitle.errors'),
  partnershipPocEmailErrors: readOnly('validations.attrs.partnershipPocEmail.errors'),
  partnershipPocNameErrors: readOnly('validations.attrs.partnershipPocName.errors'),
  partnershipPocTitleErrors: readOnly('validations.attrs.partnershipPocTitle.errors'),
  customersSizeErrors: readOnly('validations.attrs.customersSize.errors'),
  salesTeamSizeErrors: readOnly('validations.attrs.salesTeamSize.errors'),
  marketingTeamSizeErrors: readOnly('validations.attrs.marketingTeamSize.errors'),

  validate() {
    this.set('validationsDisabled', false);
    return this._super(...arguments);
  },

  rollbackAttributes() {
    this.set('validationsDisabled', true);
    return this._super(...arguments);
  },
});
