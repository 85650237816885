/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';
import { inject as service } from '@ember/service';
import { readOnly, empty } from '@ember/object/computed';
import { computed } from '@ember/object';

export default StandardPanelHeaderConfiguration.extend({
  contentEditorService: service(),

  rulesetLinkErrors: readOnly('contentEditorService.ruleset.validations.attrs.rulesetLinks.errors'),
  errors: computed('rulesetLinkErrors.@each.attribute', function () {
    return this.rulesetLinkErrors.filter((error) => error.attribute !== 'insertableUrl');
  }),
  isComplete: empty('errors'),

  init(inputs) {
    inputs.component = 'content-editor/panels/content/tour/header';
    inputs.title = inputs.title || 'Create your tour';
    this._super(...arguments);
  },
});
