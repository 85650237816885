/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';
import { applyFunction, isAny, isEvery } from '@intercom/pulse/lib/computed-properties';
export default Model.extend({
  idAsNumber: applyFunction(parseInt, 'id'),
  name: attr('string'),
  key: attr('string'),
  description: attr('string'),
  activeTrialStartedAt: attr('date'),
  products: hasMany('product', { async: false }),

  active: isEvery('products', 'active'),
  partiallyActive: isAny('products', 'active'),
  inactive: not('active'),

  trialable: isEvery('products', 'trialable'),
  notTrialable: not('trialable'),
  trialing: computed('products.@each.activeTrialStartedAt', function () {
    return this.products.every((product) => product.get('activeTrialStartedAt'));
  }),

  analyticsData: computed('active', function () {
    return {
      object: 'solution',
      solution_active: this.active,
    };
  }),
});
