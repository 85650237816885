/* RESPONSIBLE TEAM: team-proactive-support */
import { assert } from '@ember/debug';
import TabConfiguration from 'embercom/objects/content-editor/tabs/tab-configuration';

export default class SeriesOverviewTabConfiguration extends TabConfiguration {
  label;
  subtitle;

  init(inputs) {
    inputs.component = 'series/editor/tabs/overview-tab';
    inputs.view = 'overview';

    super.init(...arguments);

    assert('A label must be passed for the series overview tab configuration', inputs.label);
    assert('A subtitle must be passed for the series overview tab configuration', inputs.subtitle);

    this.setProperties({
      label: inputs.label,
      subtitle: inputs.subtitle,
    });
  }
}
