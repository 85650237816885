/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import UserSummary, { type UserSummaryWireFormat } from '../user-summary';

interface ConversationRatingChangedWireFormat {
  renderable_type: RenderableType;
  user_summary: UserSummaryWireFormat;
  conversation_rating: string;
}

export default class ConversationRatingChanged {
  readonly renderableType: RenderableType = RenderableType.TitleChanged;
  readonly userSummary: UserSummary;
  readonly conversationRating: string;

  get creatingEntity() {
    return { type: EntityType.User, id: this.userSummary.id, object: this.userSummary };
  }

  constructor(userSummary: UserSummary, conversationRating: string) {
    this.conversationRating = conversationRating;
    this.userSummary = userSummary;
  }

  static deserialize(json: ConversationRatingChangedWireFormat): ConversationRatingChanged {
    let userSummary = UserSummary.deserialize(json.user_summary);
    return new ConversationRatingChanged(userSummary, json.conversation_rating);
  }
}
