/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import defaultTo from '@intercom/pulse/lib/default-to';

export default Model.extend({
  name: attr(),
  description: attr(),
  icon_url: attr(),
  configure_url: attr(),
  params: attr(),
  installed: attr(),
  app_package_id: attr(),
  installed_at: attr(),
  available_in_operator: attr(),
  available_in_messenger_home: attr(),
  available_in_conversations: attr(),
  available_in_messages: attr(),
  notifiable: attr('boolean'),
  availableInOperator: readOnly('available_in_operator'),
  paywalledInOperator: attr('boolean'),
  showInInserter: attr('boolean'),
  availableAreaNames: defaultTo({
    available_in_operator: 'Operator',
    available_in_messenger_home: 'Messenger Home',
    available_in_conversations: 'Inbox',
    available_in_messages: 'Messages',
  }),

  hasConfiguration: computed('params', 'configure_url', function () {
    return Boolean((this.params && this.get('params.length')) || this.configure_url);
  }),

  availableAreasToText: computed(
    'available_in_operator',
    'available_in_messenger_home',
    'available_in_conversations',
    'available_in_messages',
    function () {
      return [
        'available_in_operator',
        'available_in_messenger_home',
        'available_in_conversations',
        'available_in_messages',
      ]
        .filter((prop) => this.get(prop))
        .map((prop) => this.availableAreaNames[prop]);
    },
  ),
});
