/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';

export default class SenderDomain extends Model {
  @attr('string') declare domain: string;
  @attr('boolean') declare validDkimRecordExists: boolean;
  @attr('boolean') declare validCustomBounceDomainRecordExists: boolean;
  @attr('boolean') declare hasVerifiedEmail: boolean;
  @attr('boolean') declare hasCreatedAnEmail: boolean;

  get isAuthenticated(): boolean {
    return (
      this.validDkimRecordExists &&
      this.validCustomBounceDomainRecordExists &&
      this.hasVerifiedEmail
    );
  }
}
