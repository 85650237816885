/* RESPONSIBLE TEAM: team-messenger */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
const CHAR_LIMIT = 160;

export default class MessengerLocale extends Fragment {
  @attr('string') declare localeId: string;
  @attr('string') declare name: string;
  // TODO: Rename this as welcome messages are now Team Intros
  @attr('string') declare userWelcomeMessage: string;
  @attr('boolean') declare isPermitted: boolean;
  @attr('string', { defaultValue: '' }) declare greeting: string;
  @attr('string', { defaultValue: '' }) declare greetingSubtitle: string;
  @attr('string', { defaultValue: '' }) declare mobileGreeting: string;
  @attr('string', { defaultValue: '' }) declare mobileGreetingSubtitle: string;

  @attr('string', { defaultValue: '' }) declare conversationalGreeting: string;

  get welcomeMessageTooLong() {
    return this.userWelcomeMessage.length > CHAR_LIMIT;
  }
}
