/* RESPONSIBLE TEAM: team-data-interop */
/* eslint-disable no-restricted-imports */
import {
  TRIGGERABLE_BOT_TYPE,
  AI_AGENT_WORKFLOW_TARGET,
  AI_AGENT_WORKFLOW_TRIGGER,
  DURING_CONVERSATION,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import AiAgentWorkflowEditorConfig from 'embercom/objects/visual-builder/configuration/editor/ai-agent-workflow';

import CustomBotConfig from '../custom-bot';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class AiAgentWorkflowCustomBotConfig extends CustomBotConfig {
  constructor(public customBot?: any) {
    super();
    this.customBot = customBot;
  }

  get target(): BotConfigTarget {
    return AI_AGENT_WORKFLOW_TARGET;
  }

  get botType() {
    return TRIGGERABLE_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.triggerableCustomBot;
  }

  get trigger() {
    return AI_AGENT_WORKFLOW_TRIGGER;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.ai-agent-workflow.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.ai-agent-workflow.description';
  }

  get icon(): InterfaceIconName {
    return 'fin';
  }

  get EditorConfigClass() {
    return AiAgentWorkflowEditorConfig;
  }

  get editorConfig(): AiAgentWorkflowEditorConfig {
    return new this.EditorConfigClass({
      areStepPaywallsActive: this.areStepPaywallsActive,
      actions: this.customBot?.visualBuilderObject?.actionSteps
        .filter((actionStep: any) => actionStep.action !== null)
        .map((actionStep: any) => {
          return actionStep.action;
        }),
      workflow: this.customBot?.visualBuilderObject,
      targetChannels: this.customBot?.targetChannels,
    });
  }

  visibleToApp(app: any) {
    return app.canUseAiAgentWorkflows as boolean;
  }

  get selectableCustomBotType() {
    return true;
  }

  get requiredBillingFeature() {
    return 'ai_answers_billing_feature';
  }

  get triggerContextType() {
    return DURING_CONVERSATION;
  }
}
