/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';
import { type ConversationSlaObject } from 'embercom/objects/inbox/interfaces/conversation_sla';

interface ConversationSlaRemovedWireFormat {
  renderable_type: RenderableType;
  admin_summary: AdminSummaryWireFormat;
  conversation_sla: ConversationSlaObject;
}

export default class ConversationSlaRemoved {
  readonly renderableType: RenderableType = RenderableType.ConversationSlaRemoved;
  readonly adminSummary: AdminSummary;
  readonly conversationSla: ConversationSlaObject;

  get creatingEntity() {
    return { type: EntityType.Admin, id: this.adminSummary.id, object: this.adminSummary };
  }

  constructor(adminSummary: AdminSummary, conversationSla: ConversationSlaObject) {
    this.adminSummary = adminSummary;
    this.conversationSla = conversationSla;
  }

  static deserialize(json: ConversationSlaRemovedWireFormat): ConversationSlaRemoved {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    return new ConversationSlaRemoved(adminSummary, json.conversation_sla);
  }
}
