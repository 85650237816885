/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';

export const PRIORITY_TRUE = 64;
export const PRIORITY_FALSE = 0;

interface PriorityChangedWireFormat {
  renderable_type: RenderableType;
  admin_summary: AdminSummaryWireFormat;
  priority: number;
  previous_priority: number;
}

export default class PriorityChanged {
  readonly renderableType: RenderableType = RenderableType.PriorityChanged;
  readonly adminSummary: AdminSummary;
  readonly priority: number;
  readonly previousPriority: number;

  get creatingEntity() {
    return { type: EntityType.Admin, id: this.adminSummary.id, object: this.adminSummary };
  }

  get isPriority() {
    return this.priority === PRIORITY_TRUE;
  }

  constructor(adminSummary: AdminSummary, priority: number, previousPriority: number) {
    this.adminSummary = adminSummary;
    this.priority = priority;
    this.previousPriority = previousPriority;
  }

  static deserialize(json: PriorityChangedWireFormat): PriorityChanged {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    return new PriorityChanged(adminSummary, json.priority, json.previous_priority);
  }
}
