/* RESPONSIBLE TEAM: team-proactive-support */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/tooltip-group/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/tooltip-group/edit-mode-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import {
  objectTypes,
  objectFeatures,
} from 'embercom/models/data/matching-system/matching-constants';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.tooltip;
  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    notificationMessages: generateNotificationMessages(options),
    requiredFeature: objectFeatures[objectTypes.tooltip],
  });
}
