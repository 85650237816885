/* RESPONSIBLE TEAM: team-tickets-1 */

export interface DescriptorConditionWireFormat {
  id: number;
  controlling_descriptor_id: number;
  descriptor_id: number;
  controlling_list_option_id: string;
  descriptor_list_option_ids: string[];
}

export class DescriptorCondition {
  readonly id: number;
  readonly controllingDescriptorId: number;
  readonly descriptorId: number;
  readonly controllingListOptionId: string;
  readonly descriptorListOptionIds: string[];

  constructor(
    id: number,
    controllingDescriptorId: number,
    descriptorId: number,
    controllingListOptionId: string,
    descriptorListOptionIds: string[],
  ) {
    this.id = id;
    this.controllingDescriptorId = controllingDescriptorId;
    this.descriptorId = descriptorId;
    this.controllingListOptionId = controllingListOptionId;
    this.descriptorListOptionIds = descriptorListOptionIds;
  }

  static deserialize(json: DescriptorConditionWireFormat) {
    return new DescriptorCondition(
      json.id,
      json.controlling_descriptor_id,
      json.descriptor_id,
      json.controlling_list_option_id,
      json.descriptor_list_option_ids,
    );
  }
}
