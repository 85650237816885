/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr } from '@ember-data/model';
import {
  humanReadableObjectNames,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import {
  SELECTION_SET_ROUTES,
  OUTBOUND_EDITOR_ROUTES,
} from 'embercom/models/outbound/content-wrapper';
import { capitalize } from '@ember/string';

export default class Initiator extends Model {
  @attr('number') contentType;
  @attr('number') rulesetId;
  @attr('number') selectionSetId;
  @attr('string') title;
  @attr('boolean', { defaultValue: false }) deleted;

  get label() {
    let readableContentType = capitalize(humanReadableObjectNames[this.contentType]);
    return this.deleted ? `Deleted ${readableContentType}` : readableContentType;
  }

  get showPageId() {
    return this.rulesetId || this.selectionSetId;
  }

  get showPageRoute() {
    if (this.rulesetId) {
      return OUTBOUND_EDITOR_ROUTES[objectNames[this.contentType]];
    } else if (this.selectionSetId) {
      return SELECTION_SET_ROUTES['all'];
    }
  }
}
