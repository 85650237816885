/* RESPONSIBLE TEAM: team-proactive-support */

export enum ChecklistStatus {
  Active = 0,
  Complete = 1,
  New = 2,
}

interface TextBlock {
  type: string;
  text: string;
}

interface TaskWireFormat {
  id: string;
  order: number;
  is_complete: boolean;
  title_blocks: Array<TextBlock>;
}

interface TaskConstructorFormat {
  id: string;
  order: number;
  isComplete: boolean;
  titleBlocks: Array<TextBlock>;
}

export class Task {
  readonly id: string;
  readonly order: number;
  readonly isComplete: boolean;
  readonly titleBlocks: Array<TextBlock>;

  constructor(inputs: TaskConstructorFormat) {
    this.id = inputs.id;
    this.order = inputs.order;
    this.isComplete = inputs.isComplete;
    this.titleBlocks = inputs.titleBlocks;
  }

  get title() {
    return this.titleBlocks[0].text;
  }

  static deserialize(json: TaskWireFormat) {
    return new Task({
      id: json.id,
      order: json.order,
      isComplete: json.is_complete,
      titleBlocks: json.title_blocks,
    });
  }
}

interface TaskGroupWireFormat {
  id: string;
  tasks: Array<TaskWireFormat>;
}

interface TaskGroupConstructorFormat {
  id: string;
  tasks: Array<Task>;
}

class TaskGroup {
  readonly id: string;
  readonly tasks: Array<Task>;

  constructor(inputs: TaskGroupConstructorFormat) {
    this.id = inputs.id;
    this.tasks = inputs.tasks;
  }

  static deserialize(json: TaskGroupWireFormat) {
    return new TaskGroup({
      id: json.id,
      tasks: json.tasks.map(Task.deserialize),
    });
  }
}

export interface ChecklistWireFormat {
  id: string;
  title_blocks: Array<TextBlock>;
  status: ChecklistStatus;
  published_at: string;
  task_groups: Array<TaskGroupWireFormat>;
  ruleset_id: number;
  shareable_url: string;
}

export interface ChecklistConstructorFormat {
  id: string;
  titleBlocks: Array<TextBlock>;
  status: ChecklistStatus;
  publishedAt: Date;
  taskGroups: Array<TaskGroup>;
  rulesetId: number;
  shareableUrl: string;
}

export class Checklist {
  readonly id: string;
  readonly rulesetId: number;
  readonly titleBlocks: Array<TextBlock>;
  readonly status: ChecklistStatus;
  readonly publishedAt: Date;
  readonly taskGroups: Array<TaskGroup>;
  readonly shareableUrl: string;

  constructor(inputs: ChecklistConstructorFormat) {
    this.id = inputs.id;
    this.titleBlocks = inputs.titleBlocks;
    this.status = inputs.status;
    this.publishedAt = inputs.publishedAt;
    this.taskGroups = inputs.taskGroups;
    this.rulesetId = inputs.rulesetId;
    this.shareableUrl = inputs.shareableUrl;
  }

  get title() {
    return this.titleBlocks[0].text;
  }

  get tasks() {
    return this.taskGroups.flatMap((tg) => tg.tasks);
  }

  get completedTasks() {
    return this.tasks.filter((task) => task.isComplete);
  }

  get pendingTasks() {
    return this.tasks.filter((task) => !task.isComplete);
  }

  get nextTask() {
    return this.pendingTasks.sort((a, b) => a.order - b.order)[0];
  }

  static deserialize(json: ChecklistWireFormat) {
    return new Checklist({
      id: json.id,
      titleBlocks: json.title_blocks,
      status: json.status,
      publishedAt: new Date(json.published_at),
      taskGroups: json.task_groups.map(TaskGroup.deserialize),
      rulesetId: json.ruleset_id,
      shareableUrl: json.shareable_url,
    });
  }
}
