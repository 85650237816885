/* RESPONSIBLE TEAM: team-proactive-support */
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/resolution-bot-behavior/edit-mode-generator';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/resolution-bot-behavior/view-mode-generator';
import generateFinNotificationMessages from 'embercom/objects/content-editor/configuration-generators/resolution-bot-behavior/notification-messages-generator';
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import { resolutionBotBillingFeature } from 'embercom/components/operator/answer-bot/billing-helper';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.resolutionBotBehavior;
  let app = options.container.lookup('service:appService')?.app;

  let notificationMessages = generateFinNotificationMessages(options);
  let feature = app ? resolutionBotBillingFeature(app) : 'answer_workflows';
  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    notificationMessages,
    requiredFeature: feature,
  });
}
