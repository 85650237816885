/* RESPONSIBLE TEAM: team-proactive-support */
import { DataType } from './constants/data-types';
import SavedViewData from './saved-view-data';

interface Args {
  query: {};
  columns: string[];
}

export default class OutboundSavedViewData extends SavedViewData implements Args {
  constructor(args: Args) {
    super({ savedViewDataType: DataType.OUTBOUND });
    this.query = args.query || {};
    this.columns = args.columns || null;
  }

  public query;
  public columns;

  get data() {
    return {
      ...super.data,
      query: this.query,
      columns: this.columns,
    };
  }

  equals(other: OutboundSavedViewData) {
    return this._queryEqual(other.query) && this.columnsEqual(other.columns);
  }

  columnsEqual(otherColumns: string[]) {
    if (this.columns === otherColumns) {
      return true;
    } else if (!this.columns || !otherColumns) {
      return false;
    }
    return this._arraysEqual(this.columns, otherColumns);
  }

  private _queryEqual(otherQuery: object) {
    if (Object.keys(this.query).length !== Object.keys(otherQuery).length) {
      return false;
    }
    for (let key in this.query) {
      //@ts-ignore
      if (JSON.stringify(this.query[key]) !== JSON.stringify(otherQuery[key])) {
        return false;
      }
    }
    return true;
  }

  private _arraysEqual(a: string[], b: string[]) {
    if (a.length !== b.length) {
      return false;
    }
    let arrayA = a.slice();
    let arrayB = b.slice();
    return JSON.stringify(arrayA.sort()) === JSON.stringify(arrayB.sort());
  }
}
