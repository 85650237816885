/* RESPONSIBLE TEAM: team-proactive-support */
import { computed } from '@ember/object';
export function mapAdminToId(adminsKey, adminIdKey) {
  return computed(`${adminsKey}.@each.id`, adminIdKey, {
    get() {
      return this.get(adminsKey).findBy('id', this.get(adminIdKey));
    },
    set(key, value) {
      this.set(adminIdKey, value.get('id'));
      return value;
    },
  });
}
