/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';

export const OTHER_ID = 99;
export const IS_SWITCHING_ID = 1;

export default Model.extend({
  reasonId: attr('number'),
  secondaryReasonId: attr('number'),
  tertiaryReasonId: attr('number'),
  switchingAnswerId: attr('number'),
  alternativeProductId: attr('number'),
  alternativeProductComment: attr('string'),
  comment: attr('string'),
  cancellationQuestionResponses: attr('array'),

  secondaryReason: attr('number'),
  alternativeProduct: attr('number'),

  shouldHaveComment: equal('secondaryReasonId', OTHER_ID),
  shouldHaveAlternativeProductId: equal('switchingAnswerId', IS_SWITCHING_ID),
  shouldHaveAlternativeProductComment: equal('alternativeProductId', OTHER_ID),
});
