/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { readOnly, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { findBy } from '@intercom/pulse/lib/computed-properties';

export default Model.extend({
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),

  channel: belongsTo('slack-channel'),
  notificationTypes: hasMany('slack-notification-type'),
  assigneeId: attr('string'),
  intercomAdminId: attr('string'),
  createdAt: attr('date'),

  assignee: computed('assigneeId', function () {
    let assigneeId = this.assigneeId;

    if (assigneeId) {
      return this._getAdminById(`${assigneeId}`);
    }
  }),

  owner: computed('intercomAdminId', function () {
    let intercomAdminId = this.intercomAdminId;

    if (intercomAdminId) {
      return this._getAdminById(`${intercomAdminId}`);
    }
  }),

  conversationNotificationType: findBy('notificationTypes', 'isConversationType', true),
  hasConversationNotificationType: notEmpty('conversationNotificationType'),

  _getAdminById(adminId) {
    let admin = this.get('app.teams').findBy('id', adminId);

    if (admin === undefined) {
      admin = this.get('app.admins').findBy('id', adminId);
    }

    return admin;
  },

  trackEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'slack_notification_rule',
      place: 'slack_integration',
      notification_rule_id: this.id,
      assignee_id: this.get('assignee.id'),
      channel: this.get('channel.id'),
      notification_types: this.notificationTypes.mapBy('id'),
    });
  },
});
