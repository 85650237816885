/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-new-mixins */
import { A } from '@ember/array';
import { assert } from '@ember/debug';
import EmberError from '@ember/error';
import { expandProperties, notEmpty } from '@ember/object/computed';
import { compare } from '@ember/utils';
import { removeObserver, addObserver } from '@ember/object/observers';
import Mixin from '@ember/object/mixin';
import { observer, computed, get } from '@ember/object';
//from https://github.com/emberjs/ember-legacy-controllers/blob/master/addon/utils/sortable-mixin.js

const { MutableEnumerable } = Ember;

function beforeObserver(...args) {
  let func = args.slice(-1)[0];
  let paths;

  let addWatchedProperty = function (path) {
    paths.push(path);
  };

  let _paths = args.slice(0, -1);

  if (typeof func !== 'function') {
    // revert to old, soft-deprecated argument ordering

    func = args[0];
    _paths = args.slice(1);
  }

  paths = [];

  for (let i = 0; i < _paths.length; ++i) {
    expandProperties(_paths[i], addWatchedProperty);
  }

  if (typeof func !== 'function') {
    throw new EmberError('Ember.beforeObserver called without a function');
  }

  func.__ember_observesBefore__ = paths;
  return func;
}

/**
  `Ember.SortableMixin` provides a standard interface for array proxies
  to specify a sort order and maintain this sorting when objects are added,
  removed, or updated without changing the implicit order of their underlying
  model array:
  ```javascript
  songs = [
    {trackNumber: 4, title: 'Ob-La-Di, Ob-La-Da'},
    {trackNumber: 2, title: 'Back in the U.S.S.R.'},
    {trackNumber: 3, title: 'Glass Onion'},
  ];
  songsController = Ember.ArrayController.create({
    model: songs,
    sortProperties: ['trackNumber'],
    sortAscending: true
  });
  songsController.get('firstObject');  // {trackNumber: 2, title: 'Back in the U.S.S.R.'}
  songsController.addObject({trackNumber: 1, title: 'Dear Prudence'});
  songsController.get('firstObject');  // {trackNumber: 1, title: 'Dear Prudence'}
  ```
  If you add or remove the properties to sort by or change the sort direction the model
  sort order will be automatically updated.
  ```javascript
  songsController.set('sortProperties', ['title']);
  songsController.get('firstObject'); // {trackNumber: 2, title: 'Back in the U.S.S.R.'}
  songsController.toggleProperty('sortAscending');
  songsController.get('firstObject'); // {trackNumber: 4, title: 'Ob-La-Di, Ob-La-Da'}
  ```
  `SortableMixin` works by sorting the `arrangedContent` array, which is the array that
  `ArrayProxy` displays. Due to the fact that the underlying 'content' array is not changed, that
  array will not display the sorted list:
   ```javascript
  songsController.get('content').get('firstObject'); // Returns the unsorted original content
  songsController.get('firstObject'); // Returns the sorted content.
  ```
  Although the sorted content can also be accessed through the `arrangedContent` property,
  it is preferable to use the proxied class and not the `arrangedContent` array directly.
  @class SortableMixin
  @namespace Ember
  @uses Ember.MutableEnumerable
  @private
*/
export default Mixin.create(MutableEnumerable, {
  /**
    Specifies which properties dictate the `arrangedContent`'s sort order.
    When specifying multiple properties the sorting will use properties
    from the `sortProperties` array prioritized from first to last.
    @property {Array} sortProperties
    @private
  */
  sortProperties: null,

  /**
    Specifies the `arrangedContent`'s sort direction.
    Sorts the content in ascending order by default. Set to `false` to
    use descending order.
    @property {Boolean} sortAscending
    @default true
    @private
  */
  sortAscending: true,

  /**
    The function used to compare two values. You can override this if you
    want to do custom comparisons. Functions must be of the type expected by
    Array#sort, i.e.,
    *  return 0 if the two parameters are equal,
    *  return a negative value if the first parameter is smaller than the second or
    *  return a positive value otherwise:
    ```javascript
    function(x, y) { // These are assumed to be integers
      if (x === y)
        return 0;
      return x < y ? -1 : 1;
    }
    ```
    @property sortFunction
    @type {Function}
    @default Ember.compare
    @private
  */
  sortFunction: compare,

  orderBy(item1, item2) {
    let result = 0;
    let sortProperties = get(this, 'sortProperties');
    let sortAscending = get(this, 'sortAscending');
    let sortFunction = get(this, 'sortFunction');

    assert('you need to define `sortProperties`', !!sortProperties);

    sortProperties.forEach((propertyName) => {
      if (result === 0) {
        result = sortFunction.call(this, get(item1, propertyName), get(item2, propertyName));
        if (result !== 0 && !sortAscending) {
          result *= -1;
        }
      }
    });

    return result;
  },

  destroy() {
    let content = get(this, 'content');
    let sortProperties = get(this, 'sortProperties');

    if (content && sortProperties) {
      content.forEach((item) => {
        sortProperties.forEach((sortProperty) => {
          removeObserver(item, sortProperty, this, 'contentItemSortPropertyDidChange');
        });
      });
    }

    return this._super(...arguments);
  },

  isSorted: notEmpty('sortProperties'),

  /**
    Overrides the default `arrangedContent` from `ArrayProxy` in order to sort by `sortFunction`.
    Also sets up observers for each `sortProperty` on each item in the content Array.
    @property arrangedContent
    @private
  */
  arrangedContent: computed('content', 'sortProperties.[]', {
    get() {
      let content = get(this, 'content');
      let isSorted = get(this, 'isSorted');
      let sortProperties = get(this, 'sortProperties');

      if (content && isSorted) {
        content = content.slice();
        content.sort((item1, item2) => this.orderBy(item1, item2));

        content.forEach((item) => {
          sortProperties.forEach((sortProperty) => {
            addObserver(item, sortProperty, this, 'contentItemSortPropertyDidChange');
          });
        });

        return A(content);
      }

      return content;
    },
  }),

  _contentWillChange: beforeObserver('content', function () {
    let content = get(this, 'content');
    let sortProperties = get(this, 'sortProperties');

    if (content && sortProperties) {
      content.forEach((item) => {
        sortProperties.forEach((sortProperty) => {
          removeObserver(item, sortProperty, this, 'contentItemSortPropertyDidChange');
        });
      });
    }

    this._super(...arguments);
  }),

  sortPropertiesWillChange: beforeObserver('sortProperties', function () {
    this._lastSortAscending = undefined;
  }),

  sortPropertiesDidChange: observer({
    dependentKeys: ['sortProperties'],

    fn() {
      this._lastSortAscending = undefined;
    },

    sync: true,
  }),

  sortAscendingWillChange: beforeObserver('sortAscending', function () {
    this._lastSortAscending = get(this, 'sortAscending');
  }),

  sortAscendingDidChange: observer({
    dependentKeys: ['sortAscending'],

    fn() {
      if (
        this._lastSortAscending !== undefined &&
        get(this, 'sortAscending') !== this._lastSortAscending
      ) {
        let arrangedContent = get(this, 'arrangedContent');
        arrangedContent.reverseObjects();
      }
    },

    sync: true,
  }),

  contentArrayWillChange(array, idx, removedCount, addedCount) {
    let isSorted = get(this, 'isSorted');

    if (isSorted) {
      let arrangedContent = get(this, 'arrangedContent');
      let removedObjects = array.slice(idx, idx + removedCount);
      let sortProperties = get(this, 'sortProperties');

      removedObjects.forEach((item) => {
        arrangedContent.removeObject(item);

        sortProperties.forEach((sortProperty) => {
          removeObserver(item, sortProperty, this, 'contentItemSortPropertyDidChange');
        }, this);
      }, this);
    }

    return this._super(array, idx, removedCount, addedCount);
  },

  contentArrayDidChange(array, idx, removedCount, addedCount) {
    let isSorted = get(this, 'isSorted');
    let sortProperties = get(this, 'sortProperties');

    if (isSorted) {
      let addedObjects = array.slice(idx, idx + addedCount);

      addedObjects.forEach((item) => {
        this.insertItemSorted(item);

        sortProperties.forEach((sortProperty) => {
          addObserver(item, sortProperty, this, 'contentItemSortPropertyDidChange');
        });
      });
    }

    return this._super(array, idx, removedCount, addedCount);
  },

  insertItemSorted(item) {
    let arrangedContent = get(this, 'arrangedContent');
    let length = get(arrangedContent, 'length');

    let idx = this._binarySearch(item, 0, length);
    arrangedContent.insertAt(idx, item);
  },

  contentItemSortPropertyDidChange(item) {
    let arrangedContent = get(this, 'arrangedContent');
    let oldIndex = arrangedContent.indexOf(item);
    let leftItem = arrangedContent.objectAt(oldIndex - 1);
    let rightItem = arrangedContent.objectAt(oldIndex + 1);
    let leftResult = leftItem && this.orderBy(item, leftItem);
    let rightResult = rightItem && this.orderBy(item, rightItem);

    if (leftResult < 0 || rightResult > 0) {
      arrangedContent.removeObject(item);
      this.insertItemSorted(item);
    }
  },

  _binarySearch(item, low, high) {
    let mid;
    let midItem;
    let res;
    let arrangedContent;

    if (low === high) {
      return low;
    }

    arrangedContent = get(this, 'arrangedContent');

    mid = low + Math.floor((high - low) / 2);
    midItem = arrangedContent.objectAt(mid);

    res = this.orderBy(midItem, item);

    if (res < 0) {
      return this._binarySearch(item, mid + 1, high);
    } else if (res > 0) {
      return this._binarySearch(item, low, mid);
    }

    return mid;
  },
});
