/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Block from './block';
import { fragment } from 'ember-data-model-fragments/attributes';

export default Block.extend({
  responsive: attr('boolean'),
  container: attr('boolean'),
  sectionType: attr('string'),
  rows: attr('array'),
  style: fragment('common/blocks/style'),
});
