/* RESPONSIBLE TEAM: team-channels */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { type Block } from '@intercom/interblocks.ts';

interface MergedConversationReplyWireFormat {
  blocks: Array<object>;
  admin_summary: AdminSummaryWireFormat;
  user_id: string;
  user_display_name: string;
  merged_conversation_id: number;
}

export default class MergedConversationReply implements RenderableData {
  renderableType: RenderableType = RenderableType.MergedConversationReply;
  readonly blocks: Array<Block>;
  readonly adminSummary: AdminSummary;
  readonly userId: string;
  readonly userDisplayName: string;
  readonly mergedConversationId: number;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(
    blocks: Array<object>,
    adminSummary: AdminSummary,
    userId: string,
    userDisplayName: string,
    mergedConversationId: number,
  ) {
    this.blocks = blocks.map((block) => block as Block);
    this.adminSummary = adminSummary;
    this.userId = userId;
    this.userDisplayName = userDisplayName;
    this.mergedConversationId = mergedConversationId;
  }

  static deserialize(json: MergedConversationReplyWireFormat): MergedConversationReply {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);

    return new MergedConversationReply(
      json.blocks,
      adminSummary,
      json.user_id,
      json.user_display_name,
      json.merged_conversation_id,
    );
  }
}
