/* RESPONSIBLE TEAM: team-tickets-1 */

import Model, { attr } from '@ember-data/model';
import intermoji from '@intercom/intermoji';

export default class TicketTypeSummary extends Model {
  @attr('string') name;
  @attr('string') category;
  @attr('string') description;
  @attr('string') emoji;
  @attr('string') createdAt;
  @attr('boolean') archived;
  @attr('boolean') internal;
  @attr('boolean') disableAiAssist;
  @attr('string') category;
  @attr('array') ticketCustomStateIds;

  get emojiUri() {
    return intermoji.twemojiSVGUri(this.emoji ?? '');
  }

  get isConversationTicketType() {
    return this.name === 'Conversation';
  }

  get isTracker() {
    return this.category === 'tracker';
  }

  get categoryLabelIntlKey() {
    return `settings.ticket-data.categories.${this.category}`;
  }

  get categoryIconData() {
    if (this.category === 'request') {
      return {
        set: 'standard',
        name: 'ticket',
      };
    } else if (this.category === 'tracker') {
      return {
        set: 'standard',
        name: 'tracker',
      };
    }

    return {
      set: 'standard',
      name: 'post-filled',
    };
  }
}
