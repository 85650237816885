/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';

export default class EarlyStageStep extends Fragment {
  @attr('date') endsAt;
  @attr('number') graduationPath;
  @attr('boolean') isOnSteppedGraduation;
  @fragment('billing/early-stage-step-config') nextStepConfig;
  @attr('string') programName;
  @attr('date') startsAt;
  @fragment('billing/early-stage-step-config') stepConfig;
}
