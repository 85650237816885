/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import { gt } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';

export default Fragment.extend({
  users: fragmentArray('messages/recipient'),
  count: attr('number'),
  limited: attr('boolean'),
  hasAudience: gt('count', 0),
  predicateGroup: fragment('predicates/predicate-group'),
  rolePredicateGroup: fragment('predicates/predicate-group'),
});
