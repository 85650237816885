/* RESPONSIBLE TEAM: team-frontend-tech */
let PredefinedSegmentIconMap = {
  predefinedUserIconMap: {
    All: 'multiple-people',
    'All Users': 'multiple-people',
    'All Contacts': 'multiple-people',
    'All Leads': 'multiple-people',
    New: 'person',
    Active: 'active',
    'Slipping Away': 'slipping-away',
    'Card Expires Soon': 'creditcard',
    Delinquent: 'slipping-away',
    'Loyal Subscribers': 'pointed_star',
  },
  predefinedCompanyIconMap: {
    All: 'company',
    New: 'company',
    Active: 'active',
    'Slipping Away': 'slipping-away',
  },
};

export default PredefinedSegmentIconMap;
