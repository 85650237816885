/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, attr } from '@ember-data/model';
import { equal, notEmpty } from '@ember/object/computed';
import { equalsAny, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Model.extend({
  parentSectionList: belongsTo('inbox/admin-app-sidebar-section-list'),
  inboxAppId: attr('number'),
  intercomModuleIdentifier: attr('string'),
  name: attr('string'),
  notifiable: attr('boolean'),
  iconUrl: attr('string'),

  isIntercomModule: notEmpty('intercomModuleIdentifier'),
  isApp: notEmpty('inboxAppId'),
  isUserModule: equalsAny('intercomModuleIdentifier', [
    'series',
    'events',
    'external-profiles',
    'latest-conversations',
    'notes',
    'qualification',
    'segments',
    'tags',
    'user-details',
    'recent-events',
    'recent-page-views',
    'subscription-types',
    'domain-object',
  ]),
  isCompanyModule: equalsAny('intercomModuleIdentifier', [
    'company-details',
    'company-notes',
    'company-tags',
    'company-segments',
  ]),
  isConversationModule: equalsAny('intercomModuleIdentifier', ['similar-conversations']),
  isShopify: equal('intercomModuleIdentifier', 'shopify2'),

  uniqueIdentifier: ternaryToProperty('isIntercomModule', 'intercomModuleIdentifier', 'inboxAppId'),

  appCardIdentifier: 'app-card',
  componentIdentifier: ternaryToProperty(
    'isIntercomModule',
    'intercomModuleIdentifier',
    'appCardIdentifier',
  ),
});
