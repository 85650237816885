/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr } from '@ember-data/model';

export default class MessageDataExportSchedule extends Model {
  @attr('number') adminId;
  @attr('string') name;
  @attr('date') nextScheduledExport;
  @attr('string') columnsToInclude;
  @attr('string') filterPredicates;
  @attr('number') timeToExport;
  @attr('number') dayOfWeek;
  @attr('number') dayOfMonth;
  @attr('date') lastSuccessfulExport;
  @attr('string') rangeSetting;
}
