/* RESPONSIBLE TEAM: team-frontend-tech */
import Model, { attr } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class QualificationAttribute extends Model {
  @service declare appService: any;
  @service declare attributeService: any;
  @attr('string') declare identifier: string;

  get app() {
    return this.appService.app;
  }

  get appId() {
    return this.app.id;
  }

  get hasOptions(): boolean {
    return isPresent(this.options);
  }

  get name(): string | undefined {
    return this.dataAttribute?.name;
  }

  get isCompany() {
    return this.dataAttribute?.isCompany;
  }

  get identifierWithoutCompanyPrefix() {
    return this.dataAttribute?.identifierWithoutCompanyPrefix;
  }

  get options() {
    return this.dataAttribute?.options;
  }

  @computed('attributeService.attributes.@each.identifier', 'identifier')
  get dataAttribute(): Record<string, any> | undefined {
    return this.attributeService.attributes.find(
      (attribute: any) => attribute.identifier === this.identifier,
    );
  }
}
