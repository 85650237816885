/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { fragment } from 'ember-data-model-fragments/attributes';
import type OutboundContent from './outbound-content';
import type ButtonOptions from './button-options';
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import tinycolor, { type Instance as TinyColor, type WCAG2Options } from 'tinycolor2';

const PRIMARY_WHITE = '#ffffff';
const PRIMARY_BLACK = '#1a1a1a';
const WCAG_AASMALL: WCAG2Options = { level: 'AA', size: 'small' };

function isDark(color: TinyColor) {
  return tinycolor.isReadable(color, PRIMARY_WHITE, WCAG_AASMALL);
}

function calculateBodyTextPrimaryColor(color: TinyColor) {
  return isDark(color) ? PRIMARY_WHITE : PRIMARY_BLACK;
}

export const DEFAULT_CONTENT_BLOCK_OPTIONS = {
  blockStyle: {
    backgroundColor: '#ffffff',
    fontColor: '#000000',
  },
  buttonOptions: {
    borderRadius: 6,
    fontColor: '#ffffff',
    backgroundColor: '#334BFA',
  },
  isFullWidth: false,
};
export default class ContentBlockOptions extends Fragment {
  @fragment('customization-options/outbound-content') declare blockStyle: OutboundContent;
  @fragment('customization-options/button-options') declare buttonOptions: ButtonOptions;
  @attr('boolean') declare isFullWidth: boolean;

  initialize(bodyStyle: OutboundContent) {
    this.blockStyle.backgroundColor = this.blockStyle.backgroundColor || bodyStyle.backgroundColor;
    this.blockStyle.fontColor =
      this.blockStyle.fontColor ||
      calculateBodyTextPrimaryColor(tinycolor(bodyStyle.backgroundColor as string));
    this.buttonOptions.borderRadius =
      this.buttonOptions.borderRadius || DEFAULT_CONTENT_BLOCK_OPTIONS.buttonOptions.borderRadius;
    this.buttonOptions.fontColor =
      this.buttonOptions.fontColor || DEFAULT_CONTENT_BLOCK_OPTIONS.buttonOptions.fontColor;
    this.buttonOptions.backgroundColor =
      this.buttonOptions.backgroundColor ||
      DEFAULT_CONTENT_BLOCK_OPTIONS.buttonOptions.backgroundColor;
  }
}
