/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import { gt } from '@ember/object/computed';
import Block from './block';

const PLAIN_TEMPLATE_MAX_WIDTH = 600;

export default Block.extend({
  url: attr('string'),
  linkUrl: attr('string'),
  trackingLinkUrl: attr('string'),
  width: attr('number'),
  height: attr('number'),
  displayWidth: attr('number'),
  text: attr('string'),
  align: attr('string'),
  isWiderThanMaxPlainTemplateWidth: gt('width', PLAIN_TEMPLATE_MAX_WIDTH),
});
