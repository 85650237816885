/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import GraphConfiguration from 'embercom/objects/series/node/graph-configuration';
import StandardNodeComponentConfiguration from 'embercom/objects/series/node/standard-node-component-configuration';
import StandardConnectorConfiguration from 'embercom/objects/series/node/standard-connector-configuration';
import StatsConfiguration from 'embercom/objects/series/node/stats-configuration';
import {
  objectTypes,
  objectNames,
  seriesEdgeTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class TaggerGraphConfiguration extends GraphConfiguration {
  objectType = objectTypes['tagger'];

  constructor(seriesNode) {
    super(...arguments);

    this.componentConfig = new StandardNodeComponentConfiguration({
      icon: 'series/content/tag',
      objectType: this.objectType,
      title: seriesNode.get('rulesetClientData.title') || capitalize(objectNames[this.objectType]),
      statsSummaryConfigurationComponent: new StatsConfiguration({
        verb: 'tagged',
        component: 'series/node/standard-stats-summary',
      }),
    });
  }

  get connectors() {
    return [
      new StandardConnectorConfiguration({
        label: 'WHEN TAGGED',
        edgeType: seriesEdgeTypes.primary,
      }),
    ];
  }

  get inferredTitle() {
    let previewText = this.node.contentPreviews.firstObject?.contentPreviewData?.content;
    if (previewText) {
      return `${previewText}`;
    }
    return '';
  }

  get defaultTitle() {
    return `Empty tag`;
  }

  get primaryPredecessorLabel() {
    return `Applied '${this.title}'`;
  }

  get disableStatsPopover() {
    return true;
  }
}
