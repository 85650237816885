/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import numberFormatter from 'embercom/lib/number-formatter';
import pluralize from 'embercom/lib/inflector';
import { capitalize } from '@ember/string';
import NotificationMessages from 'embercom/objects/content-editor/notification-messages';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import { inject as service } from '@ember/service';
import { characterIndex } from 'embercom/helpers/character-index';
import {
  humanReadableObjectNames,
  objectNames,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import Predicate from 'predicates/models/predicate';

export default function generateNotificationMessages(options) {
  let container = options.container;
  let humanReadableObjectName = humanReadableObjectNames[options.objectType];
  let objectName = objectNames[options.objectType];

  return NotificationMessages.create({
    activate: NotificationMessage.extend({
      contentEditorService: service(),
      store: service(),
      humanReadableObjectName,
      async confirmation() {
        await this.contentEditorService.ruleset.fetchAudiencePreview();

        let ruleset = this.contentEditorService.ruleset;

        let audienceCount = ruleset.audiencePreview.count;
        let audience = `${numberFormatter(audienceCount)} ${pluralize('person', audienceCount)}`;

        let title = `Ready to set your ${this.humanReadableObjectName} live?`;
        let confirmButtonText = `Set live`;

        let body;
        let warning;
        if (ruleset.hasControlGroup) {
          body = `Part of your audience of ${audience} will be sent this ${this.humanReadableObjectName} and the rest will join the control group.`;
          if (!ruleset.isMatchBehaviorStatic) {
            body +=
              ' Going forward, anyone who matches your rules in the future will be split similarly.';
          }
        } else if (ruleset.rulesetTriggers.length > 0) {
          body = `Your ${this.humanReadableObjectName} will be sent to people who trigger the specified event and match the audience rules. ${audience} currently match your audience rules.`;
        } else {
          body = `Your ${this.humanReadableObjectName} will be sent to ${audience}`;

          if (options.objectType === objectTypes.post) {
            body = `Your ${this.humanReadableObjectName} will reach up to ${audience}`;
          }

          if (ruleset.isMatchBehaviorStatic) {
            body += '.';
            let flattenPredicates = [
              ...Predicate.walkPredicates(ruleset.predicateGroup.predicates),
            ];
            let maybeTagPredicate = flattenPredicates.findBy('type', 'manual_tag');
            if (maybeTagPredicate) {
              let tag = this.store.peekRecord('tag', maybeTagPredicate.value);
              if (tag.isProcessing) {
                warning = `Tag '${tag.name}' is still being updated. ${capitalize(
                  this.humanReadableObjectName,
                )} will only send to people who are added to the tag right now.`;
              }
            }
          } else {
            body += ' now and anyone who matches your rules in the future.';
          }
          if (!ruleset.isDraft) {
            body += ` Anyone who has already received this ${this.humanReadableObjectName} will not receive it again.`;
          }
        }

        return {
          title,
          warning,
          confirmButtonText,
          confirmContext: {
            body,
            objectType: options.objectType,
            isDynamicMessage: !ruleset.isMatchBehaviorStatic,
          },
          bodyComponentName: 'content-editor/set-live-confirmation-body',
          shouldTrackAnalyticsEvents: true,
          analyticsEventChannelName: objectName,
        };
      },
    }).create({
      success: `The ${humanReadableObjectName} was set live`,
      failure: `We couldn't set this ${humanReadableObjectName} live. Send us a message and we'll do our best to help out.`,
      container,
    }),
    deactivate: NotificationMessage.extend({
      contentEditorService: service(),
      async confirmation() {
        let dependencies = await this.contentEditorService.ruleset.loadDependentObjects();

        let title = `Pausing the ${humanReadableObjectName}`;
        let body = `You are about to pause this ${humanReadableObjectName}. Do you wish to continue?`;
        let confirmButtonText = `Pause this ${humanReadableObjectName}`;

        return {
          title,
          body,
          confirmButtonText,
          bodyComponentName: 'content-editor/dependent-content-list',
          confirmContext: {
            dependencies,
            body,
            humanReadableObjectName,
          },
        };
      },
    }).create({
      success: `The ${humanReadableObjectName} was paused`,
      failure: `We couldn't pause this ${humanReadableObjectName}. Send us a message and we'll do our best to help out.`,
      container,
    }),
    duplicate: NotificationMessage.create({
      success: `Your ${humanReadableObjectName} was successfully duplicated`,
      failure: `We couldn't duplicate your ${humanReadableObjectName}. Send us a message and we'll do our best to help out.`,
      container,
    }),
    cancel: NotificationMessage.create({
      success: null,
      failure: null,
      container,
    }),
    save: NotificationMessage.extend({
      contentEditorService: service(),
      confirmation() {
        let ruleset = this.contentEditorService.ruleset;

        if (ruleset.isLive) {
          let title;
          let confirmButtonText;
          let body;

          if (ruleset.isMemberOfSeries && ruleset.hasRulesetLinkInDraftState) {
            let rulesetLinkLabel;

            ruleset.rulesetLinks.forEach((rulesetLink, index) => {
              if (rulesetLink.isDraft) {
                rulesetLinkLabel = `Test ${characterIndex([index])}`;
              }
            });

            title = `Ready to set your changes live?`;
            body = `Your changes would activate '${rulesetLinkLabel}' and go live for anyone who matches your rules in the future.`;
            confirmButtonText = `Set changes live and activate ${rulesetLinkLabel}`;
          } else {
            title = `Ready to set your changes live?`;
            body = `Your changes will go live for anyone who matches your rules in the future.`;
            confirmButtonText = `Set changes live`;
          }
          return { title, body, confirmButtonText };
        }
        return null;
      },
    }).create({
      success: `Your ${humanReadableObjectName} has been updated`,
      failure: `We could not save your ${humanReadableObjectName}`,
      container,
    }),
    delete: NotificationMessage.extend({
      contentEditorService: service(),
      async confirmation() {
        let dependencies = await this.contentEditorService.ruleset.loadDependentObjects();

        let title = `Delete this ${humanReadableObjectName}?`;
        let confirmButtonText = `Delete`;
        let primaryButtonType = 'primary-destructive';
        let body = `Are you sure you want to delete this ${humanReadableObjectName}? This is permanent.`;
        if (
          this.contentEditorService.ruleset.isLive ||
          this.contentEditorService.ruleset.isPaused
        ) {
          body += ` This deletes the ${humanReadableObjectName} from Intercom and the Messenger. Any conversations it started will still be visible in the Inbox to teammates and included in reports.`;
        }

        return {
          title,
          body,
          confirmButtonText,
          primaryButtonType,
          bodyComponentName: 'content-editor/dependent-content-list',
          confirmContext: {
            dependencies,
            body,
            humanReadableObjectName,
          },
        };
      },
    }).create({
      success: `Your ${humanReadableObjectName} has been deleted`,
      failure: `We could not delete your ${humanReadableObjectName}`,
      container,
    }),
    activateRulesetLink: NotificationMessage.extend({
      contentEditorService: service(),
      confirmation() {
        let title = `Set test live`;
        let body = `You are about to set Test ${characterIndex([
          this.contentEditorService.activeRulesetLinkIndex,
        ])} live. Do you wish to continue?`;
        let confirmButtonText = `Set Test ${characterIndex([
          this.contentEditorService.activeRulesetLinkIndex,
        ])} live`;
        return { title, body, confirmButtonText };
      },
    }).create({
      success: `Test ${characterIndex([
        container.lookup('service:content-editor-service').activeRulesetLinkIndex,
      ])} of your message is now live.`,
      failure: `We couldn't set test ${characterIndex([
        container.lookup('service:content-editor-service').activeRulesetLinkIndex,
      ])} live. Send us a message and we'll do our best to help out.`,
      container,
    }),
    deactivateRulesetLink: NotificationMessage.create({
      success: `Test ${characterIndex([
        container.lookup('service:content-editor-service').activeRulesetLinkIndex,
      ])} of your message is now paused`,
      failure: `We couldn't pause Test ${characterIndex([
        container.lookup('service:content-editor-service').activeRulesetLinkIndex,
      ])}. Send us a message and we'll do our best to help out.`,
      container,
    }),
    endRulesetLink: NotificationMessage.create({
      success: `Control group testing of your ${humanReadableObjectName} is now ended. Going forward, your ${humanReadableObjectName} will send to 100% of your selected audience.`,
      failure:
        "We couldn't end the control group test. Send us a message and we'll do our best to help out.",
      container,
    }),
    duplicateRulesetLink: NotificationMessage.create({
      success: `The test version of your message was created`,
      failure: `We couldn't create the test version of your message. Send us a message and we'll do our best to help out.`,
      container,
    }),
    deleteControlGroup: NotificationMessage.create({
      humanReadableObjectName,
      confirmation() {
        let title = 'Delete control group';
        let body = `By deleting the control group you will also delete all the statistics associated with this test. Users who joined the control group will not get this ${this.humanReadableObjectName} again. Are you sure you want to delete the control group?`;
        let confirmButtonText = 'Delete';
        return { title, body, confirmButtonText };
      },
      success: 'Control group deleted',
      failure:
        "We could not delete the control group. Send us a message and we'll do our best to help out.",
      container,
    }),
    deleteRulesetLink: NotificationMessage.extend({
      contentEditorService: service(),
      confirmation() {
        let title = `Delete test ${characterIndex([
          this.contentEditorService.activeRulesetLinkIndex,
        ])}`;
        let body = `By deleting test ${characterIndex([
          this.contentEditorService.activeRulesetLinkIndex,
        ])} you will also delete all the statistics associated with this test. Users who received this test will not get this message again. Are you sure you want to delete test ${characterIndex(
          [this.contentEditorService.activeRulesetLinkIndex],
        )}?`;
        let confirmButtonText = `Delete`;
        return { title, body, confirmButtonText };
      },
    }).create({
      success: `Test ${characterIndex([
        container.lookup('service:content-editor-service').activeRulesetLinkIndex,
      ])} deleted`,
      failure: `We could not delete test ${characterIndex([
        container.lookup('service:content-editor-service').activeRulesetLinkIndex,
      ])}. Send us a message and we'll do our best to help out.`,
      container,
    }),
    schedule: NotificationMessage.extend({
      contentEditorService: service(),
      async confirmation() {
        await this.contentEditorService.ruleset.fetchAudiencePreview();
        let ruleset = this.contentEditorService.ruleset;
        let audienceCount = ruleset.audiencePreview.count;
        let title = `Ready to schedule your message?`;
        let scheduledActivationDate = ruleset.scheduledActivation.humanReadableChangeAt;
        let body = `Your message will be sent to ${numberFormatter(audienceCount)} ${pluralize(
          'person',
          audienceCount,
        )} when it goes live on ${scheduledActivationDate}`;
        if (ruleset.isMatchBehaviorStatic) {
          body += '.';
        } else {
          body += ', and anyone who matches your rules in the future.';
        }
        let confirmButtonText = 'Schedule';
        return { title, body, confirmButtonText };
      },
    }).create({
      success: 'The message was scheduled',
      failure:
        "We couldn't schedule this message. Send us a message and we'll do our best to help out.",
      container,
    }),
    unschedule: NotificationMessage.create({
      success: `The message was unscheduled`,
      failure: `We couldn't unschedule this message. Send us a message and we'll do our best to help out.`,
      confirmation() {
        let title = `Unscheduling the message`;
        let body = `You are about to unschedule this message. Do you wish to continue?`;
        let confirmButtonText = `Unschedule this message`;
        return { title, body, confirmButtonText };
      },
      container,
    }),
  });
}
