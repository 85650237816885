/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import { alias, readOnly, not } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { rejectBy } from '@intercom/pulse/lib/computed-properties';
import PredicatesService from 'embercom/services/predicates-service';
import { hasNestedPredicates } from 'embercom/lib/predicates-helper';

let PredicateValidator = Mixin.create({
  predicatesUpdated: computed(
    'predicates.@each.{comparison,value}',
    'nestedPredicates.@each.{comparison,value}',
    'nestedPredicates.length',
    'predicates.length',
    'predicates',
    'predicates.[]',
    'nestedPredicates.[]',
    function () {
      return new Date().getTime();
    },
  ),

  hasAnonymousPredicate: computed(
    'predicates.@each.{comparison,value}',
    'nestedPredicates.@each.{comparison,value}',
    'nestedPredicates.length',
    'predicates.length',
    'predicates',
    'predicates.[]',
    function () {
      let anonymousPredicates = this.predicates.filter((predicate) => {
        return this._isAnonymousTrue(predicate) || this._isNonUserRole(predicate);
      });
      return anonymousPredicates.length > 0;
    },
  ),

  _isAnonymousTrue(predicate) {
    return predicate.type === 'anonymous' && predicate.comparison === 'true';
  },

  _isNonUserRole(predicate) {
    if (predicate.type !== 'role') {
      return false;
    }
    return !(predicate.comparison === 'eq' && predicate.value === 'user_role');
  },

  hasUserPredicate: computed(
    'predicates.@each.{comparison,value}',
    'nestedPredicates.@each.{comparison,value}',
    'nestedPredicates.length',
    'predicates.length',
    'predicates',
    'predicates.[]',
    function () {
      let userPredicates = this.predicates.filter(function (predicate) {
        return (
          (predicate.type === 'anonymous' && predicate.comparison === 'false') ||
          (predicate.type === 'role' && predicate.value === 'user_role')
        );
      });
      return userPredicates.length > 0;
    },
  ),

  anonymousPredicates: computed(
    'predicates.@each.{comparison,value}',
    'nestedPredicates.@each.{comparison,value}',
    'nestedPredicates.length',
    'predicates.length',
    'predicates',
    'predicates.[]',
    function () {
      return this.predicates.filter((predicate) => {
        return predicate.type === 'anonymous' || predicate.type === 'role';
      });
    },
  ),

  anonymousPredicate: readOnly('anonymousPredicates.firstObject'),

  // I left `isOrGroup` as an alias to avoid modifying the other places it is called (LegacySelectionState).
  // I will remove it in subsequent PR after making sure it doesn't impact anything
  isOrGroup: alias('isNestedGroup'),
  isNestedGroup: computed('predicates', 'predicates.[]', 'predicates.@each.type', function () {
    if (this.predicates) {
      return this.predicates.isAny('type', 'or') || this.predicates.isAny('type', 'and');
    } else {
      return false;
    }
  }),
  // this is to store an array of nested predicates, it's a bit hacky but we're not picking
  // up changes to nested predicates as part of the predicates array
  // it means we have keep the nestedPredicates array up to date in the segment-filter/list-component
  nestedPredicates: [],
  editablePredicatesWithoutRole: computed('predicatesWithoutRole', function () {
    let flattenedPredicates = Array.from(this._flattenPredicates(this.predicatesWithoutRole));
    return flattenedPredicates.filter((pred) => pred.type !== 'or' && pred.type !== 'and');
  }),
  activePredicates: computed(
    'predicates',
    'predicates.[]',
    'nestedPredicates',
    'nestedPredicates.[]',
    'nestedPredicates.@each.{value,type,comparison}',
    'predicates.@each.{value,type,comparison}',
    function () {
      let cleanPredicates = this.cleanedPredicates(this.predicates);
      return cleanPredicates;
    },
  ),

  activePredicatesWithoutAnonymous: rejectBy('activePredicates', 'type', 'anonymous'),
  activePredicatesWithoutRole: rejectBy('activePredicates', 'type', 'role'),
  predicatesWithoutRole: rejectBy('predicates', 'type', 'role'),

  cleanedPredicates(predicates) {
    predicates = predicates || [];
    predicates = PredicatesService.removeAnonymousPredicateIfRolePresent(predicates);
    return predicates
      .filter(this.predicateIsFilterable.bind(this))
      .map(this.cleanNestedPredicates.bind(this));
  },

  cleanNestedPredicates(predicate) {
    if (predicate.type === 'or') {
      return { type: 'or', predicates: this.cleanedPredicates(predicate.predicates) };
    } else if (predicate.type === 'and') {
      return { type: 'and', predicates: this.cleanedPredicates(predicate.predicates) };
    } else {
      return predicate;
    }
  },
  predicateIsFilterable(predicate) {
    let isComparisonWithValue = predicate.comparison && predicate.value;
    let isBooleanWithComparison =
      (predicate.type === 'boolean' || predicate.type === 'anonymous') && predicate.comparison;
    let isKnownOrUnknown = predicate.comparison === 'known' || predicate.comparison === 'unknown';
    // A join/logical predicate is only valid if it has at least one predicate that is filterable
    let isValidJoinPredicate =
      (predicate.type === 'or' || predicate.type === 'and') &&
      predicate.predicates.any(this.predicateIsFilterable.bind(this));
    return (
      isComparisonWithValue || isBooleanWithComparison || isKnownOrUnknown || isValidJoinPredicate
    );
  },

  _flattenedValidPredicates: computed(
    'activePredicatesWithoutRole.[]',
    'isNestedGroup',
    function () {
      let activePredicates = this.activePredicatesWithoutRole;
      if (this.isNestedGroup) {
        // Remove the only top level logical predicate as we're comparing the
        // result to nestedPredicates that doesn't include the top level predicate.
        // This is not necessary for predicates with an implicit AND (i.e. isNestedGroup == false)
        activePredicates = activePredicates[0].predicates;
      }
      return Array.from(this._flattenPredicates(activePredicates));
    },
  ),

  *_flattenPredicates(predicates) {
    for (let predicate of predicates) {
      yield predicate;
      let children = predicate.predicates;
      if (children) {
        yield* this._flattenPredicates(children);
      }
    }
  },

  allPredicatesAreValid: computed(
    'activePredicates.[]',
    'predicates.@each.{type,value,comparison,isTracked}',
    'nestedPredicates.@each.{type,value,comparison,isTracked}',
    function () {
      if (this.isNestedGroup) {
        return (
          this.hasValidNestedOrNonNestedPredicates &&
          this.get('nestedPredicates.length') === this._flattenedValidPredicates.length
        );
      } else {
        return (
          this.hasValidNestedOrNonNestedPredicates &&
          this.get('predicates.length') === this.get('activePredicates.length')
        );
      }
    },
  ),

  hasValidNestedOrNonNestedPredicates: computed(
    'activePredicatesWithoutAnonymous.[]',
    'nestedPredicates.@each.{type,comparison,value}',
    'predicates.@each.{type,comparison,value}',
    function () {
      if (this.isNestedGroup) {
        return this._flattenedValidPredicates.length > 0;
      } else {
        return this.get('activePredicatesWithoutAnonymous.length') > 0;
      }
    },
  ),

  nestingLevelGreaterThanOne: computed(
    'activePredicates.[]',
    'predicates.@each.{type,value,comparison}',
    'nestedPredicates.@each.{type,value,comparison}',
    function () {
      return hasNestedPredicates(this.predicates);
    },
  ),

  hasSimplePredicates: not('nestingLevelGreaterThanOne'),
});

export default PredicateValidator;
