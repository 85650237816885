/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
import TabConfiguration from './tab-configuration';
import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { selectFromObject } from '@intercom/pulse/lib/computed-properties';
import { not, readOnly } from '@ember/object/computed';

export default TabConfiguration.extend({
  contentEditorService: service(),
  statisticKeys,

  isNotDraft: not('contentEditorService.ruleset.isDraft'),
  isVisible: readOnly('isNotDraft'),

  label: undefined,
  icon: undefined,
  view: undefined,

  statisticKey: selectFromObject('statisticKeys', 'view'),
  statistic: computed('contentEditorService.activeRulesetLink', function () {
    return this.contentEditorService.activeRulesetLink?.objectStats?.findBy(
      'key',
      this.statisticKey,
    );
  }),

  init(inputs) {
    inputs.component = inputs.tabComponent || 'content-editor/tabs/standard-stat-tab';

    this._super(...arguments);

    assert(
      'The view for a standard stats tab must be a known statistic.',
      Object.keys(statisticKeys).includes(inputs.view),
    );

    this.setProperties({
      label: inputs.label,
      view: inputs.view,
      tooltip: inputs.tooltip,
    });
  },
});
