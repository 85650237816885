/* RESPONSIBLE TEAM: team-workflows */
export default class GraphEditorHepler {
  static guardAllowedActions(app: any, actionKeys: string[]): string[] {
    let allowedActions = [
      'assign-conversation',
      'tag-user',
      'add-tag-to-conversation',
      'remove-tag-from-conversation',
      'remove-tag-from-user',
      'set-language-override',
    ];

    if (app.hasSalesforceIntegration) {
      allowedActions.push('create-salesforce-case');
      allowedActions.push('send-to-salesforce');
    }

    if (app.hasHubSpotIntegration) {
      allowedActions.push('send-to-hubspot');
    }

    if (app.hasMarketoEnrichmentIntegration) {
      allowedActions.push('send-to-marketo');
      allowedActions.push('trigger-marketo-campaign');
    }

    if (app.canUsePriorityRules) {
      allowedActions.push('change-conversation-priority');
    }

    if (app.canUseInboxSlas) {
      allowedActions.push('apply-conversation-sla');
    }

    return allowedActions.filter((action) => actionKeys.includes(action));
  }
}
