/* RESPONSIBLE TEAM: team-proactive-support */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/push/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/push/edit-mode-generator';
import generateSeriesModeConfig from 'embercom/objects/series/ruleset-editor/push/editor-configuration-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.push;
  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    seriesMode: generateSeriesModeConfig(options),
    editMode: generateEditModeConfig(options),
    notificationMessages: generateNotificationMessages(options),
    requiredFeature: 'push_only',
  });
}
