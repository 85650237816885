/* RESPONSIBLE TEAM: team-proactive-support */

import TooltipGroup from '@intercom/intersection-shared-models/models/tooltip-group';
import tooltipGroupEditorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/tooltip-group';
import { getOwner } from '@ember/application';
import TooltipGroupValidations from 'embercom/validations/tooltip-group';
import { fragment } from 'ember-data-model-fragments/attributes';

export default class TooltipGroupModel extends TooltipGroup.extend(TooltipGroupValidations) {
  @fragment('customization-options/outbound-content') customizationOptions;

  get implicitPredicates() {
    return [];
  }

  get hasLinks() {
    return this.tooltips.filter((tooltip) => tooltip.hasLinks).length > 0;
  }

  editorConfig() {
    let container = getOwner(this);
    return tooltipGroupEditorConfigGenerator({ container, tooltipGroup: this });
  }
}
