/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { type SyncHasMany, attr } from '@ember-data/model';
import {
  humanReadableObjectNames,
  objectInterfaceIcons,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { fragment } from 'ember-data-model-fragments/attributes';
import type SeriesEdge from './edge';
import type Metadata from './template/metadata';

export default class Template extends Model {
  @attr('string') declare title: string;
  @attr('string') declare description: string;
  @attr('array') declare nodes: $TSFixMe[];
  @attr('array') declare edges: SyncHasMany<SeriesEdge>;
  @attr('string') declare thumbnail: string;
  @attr('string') declare preview: string;
  @attr('number') declare objective: number;
  @attr('boolean') declare requiresMobileSdk: boolean;
  @fragment('series/template/metadata') declare metadata: Metadata;

  @service declare appService: $TSFixMe;

  get objectType() {
    return objectTypes.series;
  }

  get contentObjectIcon() {
    return objectInterfaceIcons[this.objectType];
  }

  get contentObjectName() {
    return humanReadableObjectNames[this.objectType];
  }

  get isAvailable() {
    if (this.requiresMobileSdk) {
      return this.appService.app.hasIOSSdk || this.appService.app.hasAndroidSdk;
    } else {
      return true;
    }
  }
}
