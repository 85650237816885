/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import UserSummary, { type UserSummaryWireFormat } from '../user-summary';

interface ConversationRatingRemarkAddedWireFormat {
  renderable_type: RenderableType;
  user_summary: UserSummaryWireFormat;
  remark: string;
}

export default class ConversationRatingRemarkAdded {
  readonly renderableType: RenderableType = RenderableType.TitleChanged;
  readonly userSummary: UserSummary;
  readonly remark: string;

  get creatingEntity() {
    return { type: EntityType.User, id: this.userSummary.id, object: this.userSummary };
  }

  constructor(userSummary: UserSummary, remark: string) {
    this.remark = remark;
    this.userSummary = userSummary;
  }

  static deserialize(json: ConversationRatingRemarkAddedWireFormat): ConversationRatingRemarkAdded {
    let userSummary = UserSummary.deserialize(json.user_summary);
    return new ConversationRatingRemarkAdded(userSummary, json.remark);
  }
}
