/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import Admin from 'embercom/models/admin';

export default class RulesetVersion extends Model {
  @service store;

  @attr('date') createdAt;
  @attr('number') createdBy;
  @attr() contentStats;
  @attr('number') uiIndex;
  @attr('number') lastStateChangeAdminId;
  @belongsTo('matching-system/ruleset', { async: false, inverse: null }) snapshot;

  get creator() {
    if (this.createdBy) {
      return Admin.peekAndMaybeLoad(this.store, this.createdBy);
    }
    return null;
  }

  get lastStateChangeAdmin() {
    if (this.lastStateChangeAdminId) {
      return Admin.peekAndMaybeLoad(this.store, this.lastStateChangeAdminId);
    }
    return null;
  }
}
