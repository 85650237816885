/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import {
  AssignableEntity,
  AssigningEntity,
  CombinedAssigneeType,
} from 'embercom/models/data/inbox/assignment-enums';
import { type WorkflowObject } from '../interfaces/workflow';
import { type AssignmentRuleObject } from '../interfaces/assignment-rule';
import type TeamSummary from '../team-summary';
import CustomBotSummary, {
  type CustomBotSummaryWireFormat,
} from 'embercom/objects/inbox/custom-bot-summary';
import MessageSummary, {
  type MessageSummaryWireFormat,
} from 'embercom/objects/inbox/message-summary';

type AssignmentListWireFormat = Array<{
  entity_type: AssignableEntity;
  entity: AdminSummaryWireFormat | undefined;
}>;

export type AssigningEntityData = { strategy?: { id: string } };
interface AssignmentWireFormat {
  renderable_type: RenderableType;
  assigned_from: AssignmentListWireFormat;
  assigned_to: AssignmentListWireFormat;
  assigning_entity_type: AssigningEntity;
  assigning_entity:
    | AdminSummaryWireFormat
    | WorkflowObject
    | AssignmentRuleObject
    | CustomBotSummaryWireFormat
    | MessageSummaryWireFormat;
  assigning_entity_data?: AssigningEntityData;
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

type AssigneeData = {
  entityType: AssignableEntity;
  entity: AdminSummary | TeamSummary | undefined;
};
export type AssignmentList = Array<AssigneeData>;

export default class Assignment implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.Assignment;
  readonly assigningEnitiyType: AssigningEntity;
  readonly assigningEntity:
    | AdminSummary
    | WorkflowObject
    | AssignmentRuleObject
    | CustomBotSummary
    | MessageSummary
    | undefined;
  readonly assignedFrom: AssignmentList;
  readonly assignedTo: AssignmentList;
  readonly assigningEntityData?: AssigningEntityData;

  readonly assigneeMap: Record<AssignableEntity, AssigneeData>;
  readonly combinedAssigneeType: CombinedAssigneeType;
  readonly customBotSummary?: CustomBotSummary;

  get creatingEntity() {
    return {
      type: this.assigningEnitiyType,
      id: this.assigningEntity?.id ?? -1,
      object: this.assigningEntity,
    };
  }

  constructor(
    assigningEntityType: AssigningEntity,
    assigningEntity:
      | AdminSummary
      | WorkflowObject
      | AssignmentRuleObject
      | CustomBotSummary
      | MessageSummary
      | undefined,
    assignedFrom: AssignmentList,
    assignedTo: AssignmentList,
    assigningEntityData: AssigningEntityData | undefined,
    customBotSummary?: CustomBotSummary,
  ) {
    this.assigningEnitiyType = assigningEntityType;
    this.assignedFrom = assignedFrom;
    this.assignedTo = assignedTo;
    this.assigningEntity = assigningEntity;
    this.assigningEntityData = assigningEntityData;
    this.customBotSummary = customBotSummary;

    this.assigneeMap = this.assignedTo.reduce((mapping: any, assignee) => {
      mapping[assignee.entityType] = assignee;
      return mapping;
    }, {});

    if (this.assignedTo.length === 2) {
      if (
        this.assigneeMap[AssignableEntity.UnassignedTeammate] &&
        this.assigneeMap[AssignableEntity.UnassignedTeam]
      ) {
        this.combinedAssigneeType = CombinedAssigneeType.UnassignedTeammateAndTeam;
      } else if (
        this.assigneeMap[AssignableEntity.Team] &&
        this.assigneeMap[AssignableEntity.Teammate]
      ) {
        this.combinedAssigneeType = CombinedAssigneeType.AssignedTeammateAndTeam;
      } else if (
        this.assigneeMap[AssignableEntity.UnassignedTeammate] &&
        this.assigneeMap[AssignableEntity.Team]
      ) {
        this.combinedAssigneeType = CombinedAssigneeType.UnassignedTeammateAndAssignedTeam;
      } else if (
        this.assigneeMap[AssignableEntity.Teammate] &&
        this.assigneeMap[AssignableEntity.UnassignedTeam]
      ) {
        this.combinedAssigneeType = CombinedAssigneeType.UnassignedTeamAndAssignedTeammate;
      } else {
        this.combinedAssigneeType = CombinedAssigneeType.Unknown;
      }
    } else {
      switch (this.assignedTo[0].entityType) {
        case AssignableEntity.Bot: {
          this.combinedAssigneeType = CombinedAssigneeType.AssignedBot;
          break;
        }
        case AssignableEntity.Team: {
          this.combinedAssigneeType = CombinedAssigneeType.AssignedTeam;
          break;
        }
        case AssignableEntity.Teammate: {
          this.combinedAssigneeType = CombinedAssigneeType.AssignedTeammate;
          break;
        }
        case AssignableEntity.UnassignedTeam: {
          this.combinedAssigneeType = CombinedAssigneeType.UnassignedTeam;
          break;
        }
        case AssignableEntity.UnassignedTeammate: {
          this.combinedAssigneeType = CombinedAssigneeType.UnassignedTeammate;
          break;
        }
        default: {
          this.combinedAssigneeType = CombinedAssigneeType.Unknown;
        }
      }
    }
  }

  private static parseAssignmentList(data: AssignmentListWireFormat): AssignmentList {
    return data.map((data) => {
      let entity = undefined;
      if (data.entity) {
        entity = AdminSummary.deserialize(data.entity);
      }
      return { entity, entityType: data.entity_type };
    });
  }

  static deserialize(json: AssignmentWireFormat): Assignment {
    let assignedTo = Assignment.parseAssignmentList(json.assigned_to);
    let assignedFrom = Assignment.parseAssignmentList(json.assigned_from);

    let assigningEntity = undefined;
    if (json.assigning_entity_type === AssigningEntity.Teammate) {
      assigningEntity = AdminSummary.deserialize(json.assigning_entity as AdminSummaryWireFormat);
    } else if (json.assigning_entity_type === AssigningEntity.Workflow) {
      assigningEntity = json.assigning_entity as WorkflowObject;
    } else if (json.assigning_entity_type === AssigningEntity.AssignmentRule) {
      assigningEntity = json.assigning_entity as AssignmentRuleObject;
    } else if (json.assigning_entity_type === AssigningEntity.Bot) {
      assigningEntity = AdminSummary.deserialize(json.assigning_entity as AdminSummaryWireFormat);
    } else if (json.assigning_entity_type === AssigningEntity.CustomBotSnapshot) {
      assigningEntity = CustomBotSummary.deserialize(
        json.assigning_entity as CustomBotSummaryWireFormat,
      );
    } else if (json.assigning_entity_type === AssigningEntity.LegacyMessage) {
      assigningEntity = MessageSummary.deserialize(
        json.assigning_entity as MessageSummaryWireFormat,
      );
    }

    return new Assignment(
      json.assigning_entity_type,
      assigningEntity,
      assignedFrom,
      assignedTo,
      json.assigning_entity_data,
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
