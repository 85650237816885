/* RESPONSIBLE TEAM: team-proactive-support */
import { not, and } from '@ember/object/computed';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import {
  StandardAudienceSummaryConfiguration,
  StandardGoalSummaryConfiguration,
  StandardScheduleSummaryConfiguration,
} from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/whatsapp/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';

export default function generateViewModeConfig(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');
  return ViewModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesViewModeHeaderConfiguration.create({ container }),
    headerComponentConfigs: [StandardViewModeHeaderConfiguration.create({ container })],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: intl.t(
            'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.view-mode-generator.whatsapp-label',
          ),
          icon: 'push-notification',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.sent'),
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.extend({
          _isNotDraft: not('contentEditorService.ruleset.isDraft'),
          isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.goal'),
          view: 'goals',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t(
            'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.view-mode-generator.issues-label',
          ),
          view: 'failures',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        ContentSummaryConfiguration.create({ container }),
        StandardAudienceSummaryConfiguration.create({
          showImplicitPredicates: false,
          container,
        }),
        StandardGoalSummaryConfiguration.create({ container }),
        StandardScheduleSummaryConfiguration.create({ container }),
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          noun: 'send',
          verb: 'received',
          emptyStateDescription: intl.t(
            'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.view-mode-generator.receipts-empty-state-description',
          ),
          container,
        }),
      ],
      goals: [
        StandardStatListConfiguration.create({
          filters: [
            {
              text: intl.t(
                'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.view-mode-generator.hit-goal',
              ),
              value: stats.open,
            },
          ],
          stat: 'goalSuccess',
          container,
        }),
      ],
      failures: [
        StandardStatListConfiguration.create({
          stat: 'failure',
          container,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });
}
