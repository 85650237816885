/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type Tagger from 'embercom/models/tagger';

export default class extends RulesetLink {
  @belongsTo('tagger', { async: false }) declare object: Tagger;

  get objectType() {
    return objectTypes.tagger;
  }
}
