/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type OutboundWebhook from 'embercom/models/outbound-webhook';

export default class extends RulesetLink {
  @belongsTo('outbound-webhook', { async: false }) declare object: OutboundWebhook;
  get objectType() {
    return objectTypes.outboundWebhook;
  }
}
