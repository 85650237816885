/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { inject as service } from '@ember/service';
import { recurringScheduleTypes } from 'embercom/models/data/matching-system/matching-constants';
export default class RecurringSchedule extends Fragment {
  @service appService;
  @service attributeService;

  @attr('string') dateAttribute;
  @attr('number') dayOffset;
  @attr('number') frequency;
  @attr('number') monthOffset;
  @attr('number') rulesetId;
  @attr('number') scheduleType;

  get attributeName() {
    let dateAttributes = this.attributeService.attributes.filter((attr) => attr.isDateFilter);
    let dateAttribute = dateAttributes.findBy('identifier', this.dateAttribute);

    return dateAttribute?.humanFriendlyName;
  }

  get offsetValue() {
    if (this.monthOffset) {
      return this.monthOffset;
    }

    return this.dayOffset;
  }

  get isBeforeDate() {
    return this.offsetValue < 0;
  }

  get isAfterDate() {
    return this.offsetValue > 0;
  }

  get isOnDate() {
    return this.offsetValue === 0;
  }

  get absoluteOffsetValue() {
    return Math.abs(this.offsetValue);
  }

  get dateOffsetLabel() {
    let text;

    if (this.isBeforeDate) {
      text = 'before the date';
    } else if (this.isAfterDate) {
      text = 'after the date';
    } else if (this.isOnDate) {
      text = 'on the date';
    }

    return text;
  }

  get attributeBased() {
    return this.scheduleType === recurringScheduleTypes.attributeBased;
  }

  get calendarBased() {
    return this.scheduleType === recurringScheduleTypes.calendarBased;
  }
}
