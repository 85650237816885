/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const MAX_NUMBER_OF_TASKS = 25;
export const DEFAULT_ESTIMATED_TASK_TIME_SECONDS = 60;

export const defaultCompletionScreenBlocks = [
  {
    type: 'heading',
    text: 'Success! 🎉',
    align: 'center',
    modelKey: 'common/blocks/heading',
  },
  {
    type: 'paragraph',
    text: 'You completed the checklist!',
    align: 'center',
    modelKey: 'common/blocks/paragraph',
  },
];
