/* RESPONSIBLE TEAM: team-help-desk-experience */
export class QueryBuilderDropdownItem {
  isLabel = false;
  isFilterType = false;
  isLoadingSpinner = false;
  isSuggestion = false;
  isKeywordPrompt = false;
  isNoMatchForType = false;
  isKeyboardNavigable = false;
  isUnselectable = false;
}

export class DropdownLabel extends QueryBuilderDropdownItem {
  label = '';
  isLabel = true;
}

export class DropdownFilterType extends QueryBuilderDropdownItem {
  isFilterType = true;
  isKeyboardNavigable = true;
  filterType = undefined;
  onClickAction = undefined;
}

export class DropdownLoadingSpinner extends QueryBuilderDropdownItem {
  isLoading = true;
  type = undefined;
}

export class DropdownSuggestion extends QueryBuilderDropdownItem {
  declare suggestion?: any;
  isSuggestion = true;
  isKeyboardNavigable = true;
  onClickAction = () => {};

  constructor(suggestion: any, onClickAction: any) {
    super();
    this.suggestion = suggestion;
    this.onClickAction = onClickAction;
  }
}

export class DropdownUnselectableSuggestion extends QueryBuilderDropdownItem {
  declare suggestion?: any;
  isSuggestion = true;
  isKeyboardNavigable = false;
  isUnselectable = true;
  onClickAction = () => {};

  constructor(suggestion: any) {
    super();
    this.suggestion = suggestion;
  }
}

export class DropdownKeywordPrompt extends QueryBuilderDropdownItem {
  isSearchByKeywordPrompt = true;
  isKeyboardNavigable = true;
  onClickAction = undefined;
}

export class DropdownNoMatchForType extends QueryBuilderDropdownItem {
  isNoMatchForType = true;
  type = undefined;
  onClickAction = () => {};

  constructor(onClickAction: any) {
    super();
    this.onClickAction = onClickAction;
  }
}
