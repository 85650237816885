/* RESPONSIBLE TEAM: team-customer-data-platform */

import Model, { attr, type SyncHasMany } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import type AttributeMapping from 'embercom/models/crm/attribute-mapping';
import type Attribute from 'embercom/models/crm/attribute';
import type IdentityMapping from 'embercom/models/crm/identity-mapping';

export default class ImportConfiguration extends Model {
  @attr('string') declare appId: string;
  @fragmentArray('crm/attribute-mapping') declare attributeMappings: SyncHasMany<AttributeMapping>;
  @fragmentArray('crm/identity-mapping') declare identityMappings: SyncHasMany<IdentityMapping>;
  @fragmentArray('crm/attribute') declare sourceAttributes: SyncHasMany<Attribute>;
  @attr('string') declare fallbackIntercomTeamId: string | null;
  @attr('string') declare fallbackIntercomAdminId: string | null;
  @attr('string') declare privateTicketTypeId: string | null;
  @attr('string') declare publicTicketTypeId: string | null;

  @attr('boolean', { defaultValue: true }) declare migrateAttachments: boolean;
  @attr('boolean', { defaultValue: true }) declare attachInlineImages: boolean;
}
