/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, belongsTo } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import type Admin from 'embercom/models/admin';
import { type SyncHasMany } from '@ember-data/model';
import { type Block } from 'embercom/models/common/blocks/block';

export default class SeriesAnnotation extends Model {
  @belongsTo('series/series', { inverse: 'annotations', async: false }) declare series: $TSFixMe;
  @belongsTo('admin', { async: false }) declare lastEditedBy: Admin;

  @attr('number') declare xPosition: number;
  @attr('number') declare yPosition: number;
  @attr('number') declare annotationType: number;
  @attr('date') declare updatedAt: Date;

  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
  })
  declare blocks: SyncHasMany<Block>;
}
