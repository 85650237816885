/* RESPONSIBLE TEAM: team-help-desk-experience */
import { modifier as buildModifier } from 'ember-modifier';

function onWidthChange(element: HTMLElement, [onWidthChangeFn]: [Function]) {
  function fn() {
    onWidthChangeFn(element);
  }

  window.addEventListener('resize', fn);
  fn();

  return function cleanupObserver() {
    window.removeEventListener('resize', fn);
  };
}

let modifier = buildModifier<{
  Args: {
    Positional: [Function];
  };
}>(onWidthChange, { eager: false });
export default modifier;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'on-window-width-change': typeof modifier;
  }
}
