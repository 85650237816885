/* RESPONSIBLE TEAM: team-proactive-support */
import ConnectorConfiguration from './connector-configuration';

export default class NotMatchedConnectorConfiguration extends ConnectorConfiguration {
  node = undefined;

  get label() {
    if (this.node.isDelayable) {
      return `IF NOT MATCHED AFTER ${this.node.delayDescription}`;
    } else {
      return `IF NOT MATCHED`;
    }
  }

  constructor(inputs) {
    inputs.component = 'series/node/connectors/standard-connector';
    super({ ...inputs });

    this.node = inputs.node;
  }
}
