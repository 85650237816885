/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
export default class ConversationAttributeValue extends Model {
  @service store;
  @service intl;
  @attr() value;
  @attr() dataType;

  get compositeIdParts() {
    let [conversationId, attributeId] = this.id.split('-');
    return {
      conversationId,
      attributeId,
    };
  }

  get conversationId() {
    return this.compositeIdParts.conversationId;
  }

  get attributeId() {
    return this.compositeIdParts.attributeId;
  }

  get isEmptyOnCreate() {
    return this.isEmpty({ dataType: this.dataType });
  }

  isEmpty(descriptor) {
    if (this.value === null || this.value === undefined) {
      return true;
    }

    switch (descriptor.dataType) {
      case 'integer':
      case 'decimal':
      case 'boolean':
        return this.value === '';
      case 'string':
        return this.value?.trim() === '';
      default:
        return this.value.length === 0;
    }
  }

  normalizeValue(descriptor) {
    switch (descriptor.dataType) {
      case 'integer':
        return this.normalizeNumber(true);
      case 'decimal':
        return this.normalizeNumber(false);
      case 'string':
        return this.normalizeString();
    }
  }

  isValueValid(descriptor, event = null) {
    switch (descriptor.dataType) {
      case 'decimal':
        return event?.target?.validity?.valid === true;
      default:
        return true;
    }
  }

  normalizeString() {
    this.value = this.value?.trim();
  }

  normalizeNumber(integer) {
    let casted = Number(this.value);
    if (this.value === '' || isNaN(casted)) {
      return;
    }
    this.value = integer ? Math.round(casted) : casted;
  }

  formatValue(descriptor) {
    switch (descriptor.dataType) {
      case 'list':
        return descriptor.listOptions.find((option) => option.id === this.value)?.label;
      case 'boolean':
        return this.value ? 'True' : 'False';
      case 'datetime':
        return this.intl.formatDate(this.value, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      default:
        return this.value;
    }
  }
}
