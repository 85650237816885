/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import { changeGate } from '@intercom/pulse/lib/computed-properties';
import { alias, gt } from '@ember/object/computed';

export default EmberObject.extend({
  queryParams: undefined,
  rawText: alias('queryParams.searchTerm'),
  tags: alias('queryParams.app.tags'),
  isCompany: alias('queryParams.isCompany'),
  text: changeGate(
    'rawText',
    function (value) {
      if (!value) {
        return '';
      }
      return value.trim();
    },
    { sync: true },
  ),
  hasValue: gt('text.length', 0),
  searchedTagName: computed('text', function () {
    let text = this.text;
    if (text) {
      let splitTagString = text.split('tag:');
      if (splitTagString[1]) {
        return splitTagString[1].trim();
      }
    }
  }),
  tag: computed('tags', 'searchedTagName', function () {
    if (!this.tags) {
      return null;
    }
    return this.tags.findBy('name', this.searchedTagName);
  }),
  predicate: computed('isCompany', 'tag', 'text', function () {
    let tag = this.tag;
    let text = this.text.trim();
    if (text === '') {
      return null;
    }
    if (this.isCompany) {
      return this.createCompanyPredicates(tag, text);
    } else {
      return this.createUserPredicates(tag, text);
    }
  }),
  createUserPredicates(tag, text) {
    let or = { type: 'or', predicates: [] };
    if (tag) {
      or.predicates.push({
        attribute: 'manual_tag_ids',
        comparison: 'eq',
        value: tag.get('id'),
        type: 'manual_tag',
      });
    } else {
      or.predicates.push({
        attribute: 'name',
        comparison: 'contains',
        value: text,
        type: 'string',
      });
      or.predicates.push({
        attribute: 'email',
        comparison: 'contains',
        value: text,
        type: 'string',
      });
    }

    return or;
  },
  createCompanyPredicates(tag, text) {
    if (tag) {
      return {
        attribute: 'company.manual_tag_ids',
        comparison: 'eq',
        value: tag.get('id'),
        type: 'manual_tag',
      };
    } else {
      return { attribute: 'company.name', comparison: 'contains', value: text, type: 'string' };
    }
  },
  reset() {
    this.set('rawText', '');
  },
});
