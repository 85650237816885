/* RESPONSIBLE TEAM: team-channels */
import SenderEmailAddressSummary, {
  type SenderEmailAddressSummaryWireFormat,
} from 'embercom/objects/inbox/sender-email-address-summary';

export interface EmailSenderSettingsWireFormat {
  use_admin_app_email: boolean;
  admin_reply_default_address?: SenderEmailAddressSummaryWireFormat;
}

export default class EmailSenderSettings {
  readonly useAdminAppEmail: boolean;
  readonly adminReplyDefaultAddress?: SenderEmailAddressSummary;

  constructor(useAdminAppEmail: boolean, adminReplyDefaultAddress?: SenderEmailAddressSummary) {
    this.useAdminAppEmail = useAdminAppEmail;
    this.adminReplyDefaultAddress = adminReplyDefaultAddress;
  }

  static deserialize(json: EmailSenderSettingsWireFormat): EmailSenderSettings {
    return new EmailSenderSettings(
      json.use_admin_app_email,
      json.admin_reply_default_address
        ? SenderEmailAddressSummary.deserialize(json.admin_reply_default_address)
        : undefined,
    );
  }

  static serialize(emailSenderSettings: EmailSenderSettings): EmailSenderSettingsWireFormat {
    return {
      use_admin_app_email: emailSenderSettings.useAdminAppEmail,
      admin_reply_default_address: emailSenderSettings.adminReplyDefaultAddress
        ? SenderEmailAddressSummary.serialize(emailSenderSettings.adminReplyDefaultAddress)
        : undefined,
    };
  }
}
