/* RESPONSIBLE TEAM: team-workflows */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
// @ts-expect-error for some reason, fragmentOwner does not seem to be typed
import { fragmentArray, fragmentOwner } from 'ember-data-model-fragments/attributes';
import Validations from 'embercom/validations/operator/bot-only-message/localization';
import type BotOnlyMessagePart from 'embercom/models/operator/bot-only-message/part';
import { type SyncHasMany } from '@ember-data/model';
import type Profile from 'embercom/models/ai-agent/profile';

export default class Localization extends Fragment.extend(Validations) {
  @attr('string') declare locale: string;
  @attr('string') declare name: string;
  @fragmentArray('operator/bot-only-message/part') declare parts: SyncHasMany<BotOnlyMessagePart>;
  @fragmentOwner() declare behavior: Profile;

  get hasChanges() {
    return this.hasDirtyAttributes || this.parts.hasDirtyAttributes;
  }
}
