/* RESPONSIBLE TEAM: team-channels */
import type ConvertToTicket from 'embercom/models/operator/visual-builder/step/convert-to-ticket';
import StepConfig, { type StepConfigParams } from '../step';

export default class ConvertToTicketConfiguration extends StepConfig {
  declare step: ConvertToTicket;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/convert-to-ticket',
      ...params,
    });
  }
}
