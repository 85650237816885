/* RESPONSIBLE TEAM: team-frontend-tech */
import { timeInputToHrTime } from '@opentelemetry/core';
import * as api from '@opentelemetry/api';
import { runInDebug } from '@ember/debug';

export const MIN_LONGTASK_DURATION_MS = 250;
export const EXCLUDE_FROM_HEIRARCHY_ATTR_KEY = '_internal.exclude_from_heirarchy';

export function registerPerformanceObserver(tracingService) {
  try {
    let longTaskPerformanceObserver = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        try {
          if (tracingService.getActiveRootSpan() && entry.duration >= MIN_LONGTASK_DURATION_MS) {
            let hrStartTime = timeInputToHrTime(entry.startTime);
            let bestParent = tracingService.spanHierarchy.bestParentForStartTime(hrStartTime);
            if (bestParent) {
              let parentContext = api.trace.setSpan(api.context.active(), bestParent.span);
              let span = tracingService.tracer.startSpan(
                'performanceEntry',
                {
                  attributes: {
                    resource: 'longTask',
                  },
                  startTime: entry.startTime,
                },
                parentContext,
                bestParent,
              );
              tracingService.endChildSpan({ span, endTime: entry.startTime + entry.duration });
              bestParent.bumpActivity();
              tracingService.addRollupField('longtask_total_count', 1);
              tracingService.addRollupField('longtask_total_duration_ms', entry.duration);
            } else {
              tracingService.tagRootSpan({ '_internal.contains_orphaned_long_task': true });
            }
          } else {
            tracingService.addRollupField('longtask_total_discarded_count', 1);
            tracingService.addRollupField('longtask_total_discarded_duration_ms', entry.duration);
          }
        } catch (e) {
          // We are happy to discard errors when we are unable to register / process
          // performance observer entries - we do not want side effects from this
          // logic breaking the rest of the tracing service - so we fail silently
          runInDebug(() => console.error(e));
        }
      });
    });

    longTaskPerformanceObserver.observe({ entryTypes: ['longtask'] });
  } catch (e) {
    // There's a chance certain browsers don't support PerformanceLongTaskTiming
    // we're happy to fallback to no observer
    runInDebug(() => console.error(e));
  }
}
