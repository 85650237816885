/* RESPONSIBLE TEAM: team-messenger */
import { attr } from '@ember-data/model';
import Model from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class Space extends Model {
  @attr('string') type;
  @attr('boolean') enabled;

  @service intl;
  @service appService;
  @service messengerSettingsTargetUserProvider;

  get name() {
    let translationKey = this.type === 'home' ? 'hub' : this.type; // TODO(sebchlan): Remove after updating translations
    return this.intl.t(`messenger.settings-layout.spaces.names.${translationKey}`);
  }

  get messengerTitleKey() {
    return `${this.type}_space_title`;
  }

  get description() {
    let translationKey = this.type === 'home' ? 'hub' : this.type; // TODO(sebchlan): Remove after updating translations
    return this.intl.t(`messenger.settings-layout.spaces.descriptions.${translationKey}`);
  }

  get icon() {
    switch (this.type) {
      case 'news':
        return 'news-filled';
      case 'help':
        return 'help-space';
      case 'tasks':
        return 'checklist-filled';
      case 'home':
        return 'home-space';
      case 'messages':
        return 'messages-space';
      case 'tickets':
        return 'ticket';
    }
  }

  previewIconPath(isActive = false) {
    switch (this.type) {
      case 'news':
        return `spaces-tab-bar/news${isActive ? '-active' : ''}.svg`;
      case 'help':
        return `spaces-tab-bar/help${isActive ? '-active' : ''}.svg`;
      case 'tasks':
        return `spaces-tab-bar/tasks${isActive ? '-active' : ''}.svg`;
      case 'home':
        return `spaces-tab-bar/home${isActive ? '-active' : ''}.svg`;
      case 'messages':
        return `spaces-tab-bar/messages${isActive ? '-active' : ''}.svg`;
      case 'tickets':
        return `spaces-tab-bar/tickets${isActive ? '-active' : ''}.svg`;
    }
  }

  previewCompactIconPath() {
    switch (this.type) {
      case 'news':
        return `spaces-compact/news-active.svg`;
      case 'help':
        return `spaces-compact/help-active.svg`;
      case 'tasks':
        return `spaces-compact/tasks-active.svg`;
      case 'home':
        return `spaces-compact/home-active.svg`;
      case 'messages':
        return `spaces-compact/messages-active.svg`;
      case 'tickets':
        return `spaces-compact/tickets-active.svg`;
    }
  }

  get isHub() {
    return this.type === 'home';
  }

  get isMessages() {
    return this.type === 'messages';
  }

  get isNews() {
    return this.type === 'news';
  }

  get isHelp() {
    return this.type === 'help';
  }

  get isTasks() {
    return this.type === 'tasks';
  }

  get isTickets() {
    return this.type === 'tickets';
  }

  get isReorderable() {
    return !this.isHub && !this.isPaywalled;
  }

  get isPaywalled() {
    if (this.billingFeatureKey) {
      return !this.appService.app.canUseFeature(this.billingFeatureKey);
    } else {
      return false;
    }
  }

  get isAvailableForMobile() {
    return this.type === 'help' || this.type === 'messages' || this.type === 'tickets';
  }

  get billingFeatureKey() {
    switch (this.type) {
      case 'news':
        return 'news';
      case 'help':
        return 'help_center';
      case 'tasks':
        return 'checklists';
    }
  }

  get hasChangedEnabledState() {
    return isPresent(this.changedAttributes().enabled);
  }

  get currentlyEnabledAndSetLive() {
    return this.enabled && !this.hasChangedEnabledState;
  }

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
