/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import { A } from '@ember/array';
import Fragment from 'ember-data-model-fragments/fragment';
import { includes } from '@intercom/pulse/lib/computed-properties';

export default Fragment.extend({
  openedSteps: attr('array', { defaultValue: () => A() }),
  completedSteps: attr('array'),
  explicitlyAddedTitle: attr('boolean'),
  lastEditedAdminId: attr('number'),
  examplePredicates: attr('array'),

  hasOpenedAudienceStep: includes('openedSteps', 'audience'),
  hasOpenedContentStep: includes('openedSteps', 'content'),
});
