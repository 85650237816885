/* RESPONSIBLE TEAM: team-workflows */
import type AddTagToConversation from 'embercom/models/operator/visual-builder/step/add-tag-to-conversation';
import StepConfig, { type StepConfigParams } from '../step';

export default class AddTagToConversationConfiguration extends StepConfig {
  declare step: AddTagToConversation;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/add-tag-to-conversation',
      ...params,
    });
  }
}
