/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from 'embercom/objects/component-configuration';
import { assert } from '@ember/debug';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';

export default class StandardNodeComponentConfiguration extends ComponentConfiguration {
  icon = undefined;
  objectType = undefined;
  objectName = undefined;
  delayConfigurationComponent = undefined;
  statsSummaryConfigurationComponent = undefined;

  get objectLabel() {
    return this.objectName;
  }

  // In the standard node component, the label in the bottom of the block
  // may be styled differently based on the object. E.g. starting rules has
  // a different treatment.
  get objectLabelClass() {
    return undefined;
  }

  constructor(params = {}) {
    super({ component: 'series/node/graph/standard' });
    this.icon = params.icon;
    this.paywallIcon = params.paywallIcon;
    this.featureFlagsWithAccess = params.featureFlagsWithAccess;
    this.objectType = params.objectType;
    this.readableObjectNames = humanReadableObjectNames[this.objectType];
    this.objectName = params.objectName || this.readableObjectNames;
    this.delayConfigurationComponent = params.delayConfigurationComponent;
    this.statsSummaryConfigurationComponent = params.statsSummaryConfigurationComponent;

    assert('You must specify the icon', this.icon);
  }

  // This getter can be used to eagerly show an error message instead of waiting for a response from the server
  get customErrorMessage() {
    return '';
  }

  hasAccessToRequiredFeature(app) {
    if (this.featureFlagsWithAccess) {
      return this.featureFlagsWithAccess.any((feature) => app.canUseFeature(feature));
    } else {
      return true;
    }
  }
}
