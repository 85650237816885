/* RESPONSIBLE TEAM: team-workflows */
import type TriggerMarketoCampaign from 'embercom/models/operator/visual-builder/step/trigger-marketo-campaign';
import StepConfig, { type StepConfigParams } from '../step';

export default class TriggerMarketoCampaignConfiguration extends StepConfig {
  declare step: TriggerMarketoCampaign;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/trigger-marketo-campaign',
      ...params,
    });
  }
}
