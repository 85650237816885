/* RESPONSIBLE TEAM: team-workflows */
import type AttributeCollector from 'embercom/models/operator/visual-builder/step/attribute-collector';
import StepConfig, { type StepConfigParams } from '../step';

export default class AttributeCollectorConfiguration extends StepConfig {
  declare step: AttributeCollector;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/attribute-collector',
      ...params,
    });
  }
}
