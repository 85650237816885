/* RESPONSIBLE TEAM: team-workflows */
import type AssignConversation from 'embercom/models/operator/visual-builder/step/assign-conversation';
import StepConfig, { type StepConfigParams } from '../step';

export default class AssignConversationToOwnerConfiguration extends StepConfig {
  declare step: AssignConversation;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/assign-conversation',
      ...params,
    });
  }
}
