/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import type ArticleList from '../blocks/article-list';
import type CollectionList from '../blocks/collection-list';
import type TicketsPortalLink from '../blocks/tickets-portal-link';
import type SupportedLocale from 'embercom/models/articles/site/supported-locale';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type ContentBlock from '../blocks/content-block';

export type HomePageBlock = CollectionList | ArticleList | TicketsPortalLink | ContentBlock;

export default class HomePageOptions extends Fragment {
  @fragmentArray('customization-options/layout-options/blocks/help-center-block', {
    polymorphic: true,
    typeKey: 'modelKey',
  })
  declare blocks?: HomePageBlock[];

  getBlockByType(
    type: 'collection-list' | 'article-list' | 'tickets-portal-link' | 'content-block',
  ) {
    let block = this.blocks?.find((block) => block.type === type);
    switch (block?.type) {
      case 'collection-list':
      case 'article-list':
      case 'tickets-portal-link':
      case 'content-block':
        return block;
      default:
        return undefined;
    }
  }

  get ticketsPortalLinkBlock() {
    return this.getBlockByType('tickets-portal-link') as TicketsPortalLink | undefined;
  }

  get collectionBlock() {
    return this.getBlockByType('collection-list') as CollectionList | undefined;
  }

  get articleBlock() {
    return this.getBlockByType('article-list') as ArticleList | undefined;
  }

  get contentBlock() {
    return this.getBlockByType('content-block') as ContentBlock | undefined;
  }

  get isArticleBlockEnabled() {
    return this.articleBlock?.enabled || false;
  }

  preview(locale: string) {
    let blocksPreview = this.blocks
      ?.map((block) => block.preview(locale))
      .filter((block) => !!block);
    return blocksPreview?.length === 0
      ? {}
      : {
          blocks: blocksPreview,
        };
  }

  refreshAvailableArticleLinks(site: HelpCenterSite) {
    if (!this.articleBlock || !this.articleBlock.enabled) {
      return;
    }
    let articleBlockContent = this.articleBlock.contentForLocale(site.locale);
    articleBlockContent?.refreshAvailableArticleLinks(site);
  }

  refreshTicketsPortalSection(site: HelpCenterSite) {
    if (this.ticketsPortalLinkBlock) {
      this.ticketsPortalLinkBlock.enabled = !!site.ticketsPortalTurnedOn;
    }
  }

  initialize({
    homeCollectionColumns = 1,
    selectedLocales,
  }: {
    homeCollectionColumns: number | undefined;
    selectedLocales: SupportedLocale[];
  }) {
    let selectedLocale = selectedLocales.find((locale) => locale.isSelected) as SupportedLocale;
    this.createDefaultValues({ homeCollectionColumns, selectedLocale });
    this.articleBlock?.addMissingLocalizedContent(selectedLocales);
  }

  createDefaultValues({
    homeCollectionColumns = 1,
    selectedLocale,
  }: {
    homeCollectionColumns: number | undefined;
    selectedLocale: SupportedLocale;
  }) {
    if (!this.collectionBlock) {
      this.blocks?.pushObject(
        this.store.createFragment('customization-options/layout-options/blocks/collection-list', {
          type: 'collection-list',
          columns: homeCollectionColumns,
        }),
      );
    } else if (!this.collectionBlock.columns) {
      this.collectionBlock.setColumns(homeCollectionColumns);
    }

    if (!this.articleBlock) {
      let articleListBlock: ArticleList = this.store.createFragment(
        'customization-options/layout-options/blocks/article-list',
        {
          type: 'article-list',
          columns: 2,
          enabled: false,
          localized_content: [],
        },
      );
      this.blocks?.pushObject(articleListBlock);
    }

    if (!this.contentBlock) {
      let contentBlock: ContentBlock = this.store.createFragment(
        'customization-options/layout-options/blocks/content-block',
        {
          type: 'content-block',
          columns: 1,
        },
      );
      selectedLocale?.initializeContentBlock();
      this.blocks?.pushObject(contentBlock);
    }
  }

  reorderBlocks(reorderedBlockTypes: string[]) {
    let indexes: Record<string, number> = {};
    reorderedBlockTypes.forEach((type, index) => {
      indexes[type] = index;
    });

    let orderedHomePageBlocks = Array<HomePageBlock>(reorderedBlockTypes.length);
    if (this.blocks) {
      this.blocks.forEach((block) => {
        orderedHomePageBlocks[indexes[block.type]] = block;
      });
      this.blocks.clear();
      this.blocks.addObjects(orderedHomePageBlocks);
    }
  }
}
