/* RESPONSIBLE TEAM: team-proactive-support */
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';

export default class ProductTourContentEditorAudienceEditorConfiguration extends StandardAudienceEditorConfiguration {
  get showAutomaticallyEnabled() {
    return !!this.ruleset.rulesetLinks.firstObject.object.clientData?.showAutomatically;
  }

  get isDisabled() {
    return !this.showAutomaticallyEnabled;
  }
}
