/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { not, and } from '@ember/object/computed';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import { StandardGoalSummaryConfiguration } from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/checklist/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import ChecklistStepAnalysisTabConfiguration from 'embercom/objects/content-editor/tabs/checklist/step-analysis-tab-configuration';
import ChecklistStepAnalysisConfiguration from 'embercom/objects/content-editor/stats/checklist/step-analysis-configuration';
import ChecklistSharingSummaryConfiguration from 'embercom/objects/content-editor/summaries/custom/checklist-sharing/summary-configuration';
import ChecklistAudienceSummaryConfiguration from 'embercom/objects/content-editor/summaries/audience/content/checklist/audience-summary-configuration';

export default function generateViewModeConfig(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');

  let contentConfigs = [
    ContentSummaryConfiguration.create({ container }),
    ChecklistAudienceSummaryConfiguration.create({ container }),
    ChecklistSharingSummaryConfiguration.create({ container }),
    StandardGoalSummaryConfiguration.create({ container }),
  ];

  return ViewModeConfig.create({
    headerComponentConfigs: [StandardViewModeHeaderConfiguration.create({ container })],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: 'Checklists',
          icon: 'chat-filled',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.sent'),
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: 'Viewed',
          view: 'opens',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.completed'),
          view: 'completions',
          container,
        }),
        StandardStatTabConfiguration.extend({
          _isNotDraft: not('contentEditorService.ruleset.isDraft'),
          isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.goal'),
          view: 'goals',
          container,
        }),
        ChecklistStepAnalysisTabConfiguration.create({ container }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: contentConfigs,
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: false,
          noun: 'send',
          verb: 'received',
          emptyStateTitle: 'No one has received your checklist yet',
          emptyStateDescription: ' ', // We need a non-empty value as override in order to hide description
          container,
        }),
      ],
      opens: [
        StandardStatListConfiguration.create({
          stat: 'open',
          hideCreatedVia: true,
          container,
          component: 'content-editor/view-mode/checklist/viewed-stat-list',
        }),
      ],
      completions: [
        StandardStatListConfiguration.create({
          stat: 'completion',
          component: 'content-editor/view-mode/checklist/completed-stat-list',
          container,
        }),
      ],
      goals: [
        StandardStatListConfiguration.create({
          stat: 'goalSuccess',
          container,
        }),
      ],
      stepAnalysis: [ChecklistStepAnalysisConfiguration.create({ container })],
    }),
    footerComponentConfigs: [],
  });
}
