/* RESPONSIBLE TEAM: team-proactive-support */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/whatsapp/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/whatsapp/edit-mode-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import {
  objectTypes,
  humanReadableObjectNames,
  objectFeatures,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import { inject as service } from '@ember/service';
import numberFormatter from 'embercom/lib/number-formatter';
import pluralize from 'embercom/lib/inflector';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.whatsapp;
  let humanReadableObjectName = humanReadableObjectNames[options.objectType];
  let notificationMessages = generateNotificationMessages(options);
  notificationMessages.activate = generateWhatsappNotificationMessages(
    options.container,
    humanReadableObjectName,
    options.objectType,
  );

  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    notificationMessages,
    requiredFeature: objectFeatures[objectTypes.whatsapp],
  });
}

function generateWhatsappNotificationMessages(container, humanReadableObjectName, objectType) {
  let intl = container.lookup('service:intl');
  return NotificationMessage.extend({
    contentEditorService: service(),
    intl: service(),
    humanReadableObjectName,
    async confirmation() {
      await this.contentEditorService.ruleset.fetchAudiencePreview();

      let ruleset = this.contentEditorService.ruleset;

      let audienceCount = ruleset.audiencePreview.count;
      let audience = `${numberFormatter(audienceCount)} ${pluralize('person', audienceCount)}`;

      let title = intl.t(
        'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.title',
        { humanReadableObjectName: this.humanReadableObjectName },
      );
      let confirmButtonText = intl.t(
        'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.confirm-button-text',
      );

      let body;
      if (ruleset.hasControlGroup) {
        body = intl.t(
          'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.has-control-group',
          { audience, humanReadableObjectName: this.humanReadableObjectName },
        );
        if (!ruleset.isMatchBehaviorStatic) {
          body += intl.t(
            'app.objects.content-editor.whatsapp.configuration-generators.control-group-dynamic',
          );
        }
      } else if (ruleset.rulesetTriggers.length > 0) {
        body = intl.t(
          'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.has-trigger',
          { audience, humanReadableObjectName: this.humanReadableObjectName },
        );
      } else {
        body = intl.t(
          'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.no-trigger',
          { audience, humanReadableObjectName: this.humanReadableObjectName },
        );
        if (ruleset.isMatchBehaviorStatic) {
          body += intl.t(
            'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.period',
          );
        } else {
          body += intl.t(
            'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.dynamic-control-group-conclusion',
          );
        }
        if (!ruleset.isDraft) {
          body += intl.t(
            'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.is-live',
            { humanReadableObjectName: this.humanReadableObjectName },
          );
        }
      }

      return {
        title,
        confirmButtonText,
        confirmContext: {
          body,
          objectType,
          isDynamicMessage: !ruleset.isMatchBehaviorStatic,
        },
        bodyComponentName: 'content-editor/set-live-confirmation-body',
        shouldTrackAnalyticsEvents: true,
        analyticsEventChannelName: objectNames[objectType],
      };
    },
  }).create({
    success: intl.t(
      'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.success',
      {
        humanReadableObjectName,
      },
    ),
    failure: intl.t(
      'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.failure',
      {
        humanReadableObjectName,
      },
    ),
    container,
  });
}
