/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Model, { attr } from '@ember-data/model';
import { A } from '@ember/array';
import ajax from 'embercom/lib/ajax';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import Admin from 'embercom/models/admin';
import { ALL_SEAT_LABELS } from 'embercom/lib/settings/seats/constants';
import { formatList } from 'embercom/helpers/format-list-helper';

export default class LogsSettingsModel extends Model {
  @attr() type;
  @attr() value;
  @attr() app_id;
  @attr() admin_id;
  @attr() admin_ip;
  @attr() created_at;
  @attr() impersonation_id;

  get admin() {
    return Admin.peekAndMaybeLoad(this.store, this.admin_id);
  }

  get humanType() {
    return this.type.replace('AppAdminEvents::', '').replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  get isAdminDeletion() {
    return this.type === 'AppAdminEvents::AdminDeletion';
  }

  get isAdminImpersonationEnd() {
    return this.type === 'AppAdminEvents::AdminImpersonationEnd';
  }

  get isAdminImpersonationStart() {
    return this.type === 'AppAdminEvents::AdminImpersonationStart';
  }

  get isAdminInviteChange() {
    return this.type === 'AppAdminEvents::AdminInviteChange';
  }

  get isAdminInviteCreation() {
    return this.type === 'AppAdminEvents::AdminInviteCreation';
  }

  get isAdminInviteDeletion() {
    return this.type === 'AppAdminEvents::AdminInviteDeletion';
  }

  get _isRoleChanged() {
    return this.value.before.role && this.value.after.role;
  }

  get _isRoleAssigned() {
    return !this.value.before.role && this.value.after.role;
  }

  get _isRoleRemoved() {
    return this.value.before.role && !this.value.after.role;
  }

  get isAdminInviteRoleChange() {
    return this.isAdminInviteChange && this._isRoleChanged;
  }

  get isAdminInviteRoleAssign() {
    return this.isAdminInviteChange && this._isRoleAssigned;
  }

  get isAdminInviteRoleRemove() {
    return this.isAdminInviteChange && this._isRoleRemoved;
  }

  get isAdminLoginFailure() {
    return this.type === 'AppAdminEvents::AdminLoginFailure';
  }

  get isAdminLoginSuccess() {
    return this.type === 'AppAdminEvents::AdminLoginSuccess';
  }

  get isAdminLogout() {
    return this.type === 'AppAdminEvents::AdminLogout';
  }

  get isInboxAccessRevoked() {
    return (
      this.type === 'AppAdminEvents::InboxAccessChange' &&
      this.value.before.has_inbox_access === true &&
      this.value.after.has_inbox_access === false
    );
  }

  get isInboxAccessGranted() {
    return (
      this.type === 'AppAdminEvents::InboxAccessChange' &&
      this.value.before.has_inbox_access === false &&
      this.value.after.has_inbox_access === true
    );
  }

  get isAdminPermissionChange() {
    return this.type === 'AppAdminEvents::AdminPermissionChange';
  }

  get isAdminRoleChange() {
    return this.isAdminPermissionChange && this._isRoleChanged;
  }

  get isAdminRoleAssign() {
    return this.isAdminPermissionChange && this._isRoleAssigned;
  }

  get isAdminRoleRemove() {
    return this.isAdminPermissionChange && this._isRoleRemoved;
  }

  get isAdminAwayModeChange() {
    return (
      this.type === 'AppAdminEvents::AdminAwayModeChange' &&
      (this.value.auto_changed === false || !this.value.source.includes('auto away status worker'))
    );
  }

  get isAdminAwayModeAutoChange() {
    return (
      this.type === 'AppAdminEvents::AdminAwayModeChange' &&
      (this.value.auto_changed || this.value.source.includes('auto away status worker'))
    );
  }

  get isAppAdminJoin() {
    return this.type === 'AppAdminEvents::AppAdminJoin';
  }

  get isAppAuthenticationMethodChange() {
    return this.type === 'AppAdminEvents::AppAuthenticationMethodChange';
  }

  get isAppDataDeletion() {
    return this.type === 'AppAdminEvents::AppDataDeletion';
  }

  get isAppGoogleSsoDomainChange() {
    return this.type === 'AppAdminEvents::AppGoogleSsoDomainChange';
  }

  get isAppIdentityVerificationChange() {
    return this.type === 'AppAdminEvents::AppIdentityVerificationChange';
  }

  get isAppNameChange() {
    return this.type === 'AppAdminEvents::AppNameChange';
  }

  get isAppOutboundAddressChange() {
    return this.type === 'AppAdminEvents::AppOutboundAddressChange';
  }

  get isAppPackageTokenRegeneration() {
    return this.type === 'AppAdminEvents::AppPackageTokenRegeneration';
  }

  get isAppTimezoneChange() {
    return this.type === 'AppAdminEvents::AppTimezoneChange';
  }

  get isAppWebhookCreation() {
    return this.type === 'AppAdminEvents::AppWebhookCreation';
  }

  get isAppWebhookDeletion() {
    return this.type === 'AppAdminEvents::AppWebhookDeletion';
  }

  get isBulkDataExportCreation() {
    return this.type === 'AppAdminEvents::BulkDataExportCreation';
  }

  get isBulkDataExportRun() {
    return this.type === 'AppAdminEvents::BulkDataExportRun';
  }

  get isBulkExport() {
    return this.type === 'AppAdminEvents::BulkExport';
  }

  get isBulkDelete() {
    return this.type === 'AppAdminEvents::BulkDelete';
  }

  get isMessageDeletion() {
    return this.type === 'AppAdminEvents::MessageDeletion';
  }

  get isRulesetActivation() {
    return this.type === 'AppAdminEvents::RulesetActivation';
  }

  get isRulesetDeletion() {
    return this.type === 'AppAdminEvents::RulesetDeletion';
  }

  get isRulesetCreation() {
    return this.type === 'AppAdminEvents::RulesetCreation';
  }

  get isArticleCreation() {
    return this.type === 'AppAdminEvents::ArticleCreation';
  }

  get isArticleContentUpdate() {
    return this.type === 'AppAdminEvents::ArticleContentUpdate';
  }

  get isArticleDeletion() {
    return this.type === 'AppAdminEvents::ArticleDeletion';
  }

  get isArticleContentCreation() {
    return this.type === 'AppAdminEvents::ArticleContentCreation';
  }

  get isArticleContentDeletion() {
    return this.type === 'AppAdminEvents::ArticleContentDeletion';
  }

  get isArticleContentPublished() {
    return this.type === 'AppAdminEvents::ArticleContentPublished';
  }

  get isArticleContentUnpublished() {
    return this.type === 'AppAdminEvents::ArticleContentUnpublished';
  }

  get isMessageStateChange() {
    return this.type === 'AppAdminEvents::MessageStateChange';
  }

  get isCampaignDeletion() {
    return this.type === 'AppAdminEvents::CampaignDeletion';
  }

  get isCampaignStateChange() {
    return this.type === 'AppAdminEvents::CampaignStateChange';
  }

  get isAppTeamCreation() {
    return this.type === 'AppAdminEvents::AppTeamCreation';
  }

  get isAppTeamDeletion() {
    return this.type === 'AppAdminEvents::AppTeamDeletion';
  }

  get isAppTeamMembershipModification() {
    return this.type === 'AppAdminEvents::AppTeamMembershipModification';
  }

  get isAppPackageInstallation() {
    return this.type === 'AppAdminEvents::AppPackageInstallation';
  }

  get isAppPackageUninstallation() {
    return this.type === 'AppAdminEvents::AppPackageUninstallation';
  }

  get isAppDataExport() {
    return this.type === 'AppAdminEvents::AppDataExport';
  }

  get isHelpCenterSettingsChange() {
    return this.type === 'AppAdminEvents::HelpCenterSettingsChange';
  }

  get isRoleChange() {
    return this.type === 'AppAdminEvents::RoleChange';
  }

  get isRoleCreation() {
    return this.type === 'AppAdminEvents::RoleCreation';
  }

  get isRoleDeletion() {
    return this.type === 'AppAdminEvents::RoleDeletion';
  }

  get isAdminProvisioned() {
    return this.type === 'AppAdminEvents::AdminProvisioned';
  }

  get isAdminDeprovisioned() {
    return this.type === 'AppAdminEvents::AdminDeprovisioned';
  }

  get isSeatAssigned() {
    return (
      this.type === 'AppAdminEvents::SeatChange' &&
      this.value.after.some((seat) => !this.value.before.includes(seat))
    );
  }

  get isSeatRevoked() {
    return (
      this.type === 'AppAdminEvents::SeatChange' &&
      this.value.before.some((seat) => !this.value.after.includes(seat))
    );
  }

  get isSeatChange() {
    return (
      this.type === 'AppAdminEvents::SeatChange' && !this.isSeatAssigned && !this.isSeatRevoked
    );
  }

  get assignedSeatLabel() {
    return this.formatSeatLabelChange(this.value.before, this.value.after);
  }

  get revokedSeatLabel() {
    return this.formatSeatLabelChange(this.value.after, this.value.before);
  }

  formatSeatLabelChange(originalSeats, updatedSeats) {
    let seatLabels = updatedSeats
      .filter((seat) => !originalSeats.includes(seat))
      .map((seatType) => ALL_SEAT_LABELS[seatType]);

    return formatList([seatLabels], { type: 'conjunction' });
  }

  get isOfficeHoursChange() {
    return this.type === 'AppAdminEvents::OfficeHoursChange';
  }

  get isTopicCreation() {
    return this.type === 'AppAdminEvents::ConversationTopicCreation';
  }

  get isTopicChange() {
    return this.type === 'AppAdminEvents::ConversationTopicChange';
  }

  get isTopicDeletion() {
    return this.type === 'AppAdminEvents::ConversationTopicDeletion';
  }

  get isConversationPartDeletion() {
    return this.type === 'AppAdminEvents::ConversationPartDeletion';
  }

  get isInboundConversationsChange() {
    return this.type === 'AppAdminEvents::InboundConversationsChange';
  }

  get isMessengerSpacesChange() {
    return this.type === 'AppAdminEvents::MessengerSpacesChange';
  }

  get isWelcomeMessageChange() {
    return this.type === 'AppAdminEvents::WelcomeMessageChange';
  }

  get isLookAndFeelChange() {
    return this.type === 'AppAdminEvents::MessengerLookAndFeelChange';
  }

  // When these events were originally created they were named SearchBrowse but changed to ArticlesInMessenger later
  // So to allow historical data to still display correctly check for both event type names
  get isSearchBrowseEnabledChange() {
    return (
      this.type === 'AppAdminEvents::SearchBrowseEnabledChange' ||
      this.type === 'AppAdminEvents::ArticlesInMessengerEnabledChange'
    );
  }

  get isSearchBrowseRequiredChange() {
    return (
      this.type === 'AppAdminEvents::SearchBrowseRequiredChange' ||
      this.type === 'AppAdminEvents::MessengerSearchRequiredChange'
    );
  }

  get isTemporaryExpectationChange() {
    return this.type === 'AppAdminEvents::TemporaryExpectationChange';
  }

  get isUpfrontEmailCollectionChange() {
    return this.type === 'AppAdminEvents::UpfrontEmailCollectionChange';
  }

  get isPrivacyPolicyNoticeChange() {
    return this.type === 'AppAdminEvents::PrivacyPolicyNoticeChange';
  }

  get isPrivacyPolicyNoticeContentsChange() {
    return this.type === 'AppAdminEvents::PrivacyPolicyNoticeContentsChange';
  }

  get isSecuritySettingChange() {
    return this.type === 'AppAdminEvents::SecuritySettingsChange';
  }

  get isTwoFactorAuthChange() {
    return this.type === 'AppAdminEvents::AdminTwoFactorAuthChange';
  }

  get isEmailSignatureChange() {
    return this.type === 'AppAdminEvents::EmailSignatureChange';
  }

  get isRecoveryCodeSent() {
    return this.type === 'AppAdminEvents::RecoveryCodeSent';
  }

  get isBillingAddresseesChange() {
    return this.type === 'AppAdminEvents::BillingAddresseesChange';
  }

  get isBillingAddresseesBounced() {
    return this.type === 'AppAdminEvents::BillingAddresseesBouncedAndRemoved';
  }

  get isReportCreatedEvent() {
    return this.type === 'AppAdminEvents::CustomReportCreate';
  }

  get isReportUpdateEvent() {
    return this.type === 'AppAdminEvents::CustomReportUpdate';
  }

  get isReportDeleteEvent() {
    return this.type === 'AppAdminEvents::CustomReportDelete';
  }

  get isReportingFolderCreateEvent() {
    return this.type === 'AppAdminEvents::ReportingFolderCreate';
  }

  get isReportingFolderDeleteEvent() {
    return this.type === 'AppAdminEvents::ReportingFolderDelete';
  }

  get isReportingFolderNameChange() {
    return this.type === 'AppAdminEvents::ReportingFolderNameChange';
  }

  get isReportingFolderPositionChange() {
    return this.type === 'AppAdminEvents::ReportingFolderPositionChange';
  }

  get isReportingFolderReportsChange() {
    return this.type === 'AppAdminEvents::ReportingFolderReportsChange';
  }

  get isReportingFolderPinChange() {
    return this.type === 'AppAdminEvents::ReportingFolderPinChange';
  }

  get isReportingCsvExport() {
    return this.type === 'AppAdminEvents::ReportingCsvExport';
  }

  get isReportAccessCreate() {
    return this.type === 'AppAdminEvents::ReportAccessCreate';
  }

  get isReportAccessDelete() {
    return this.type === 'AppAdminEvents::ReportAccessDelete';
  }

  get isReportAccessUpdate() {
    return this.type === 'AppAdminEvents::ReportAccessUpdate';
  }

  get hasPermissionChangeDetails() {
    return (
      this.isAdminProvisioned ||
      this.isRoleDeletion ||
      this.isRoleCreation ||
      this.isRoleChange ||
      this.isAdminInviteRoleChange ||
      this.isAdminInviteRoleAssign ||
      this.isAdminInviteRoleRemove ||
      this.isAdminInviteChange ||
      this.isAdminInviteCreation ||
      this.isAdminRoleChange ||
      this.isAdminRoleAssign ||
      this.isAdminRoleRemove ||
      this.isAdminPermissionChange
    );
  }

  get hasSeatChangeDetails() {
    return this.isSeatAssigned || this.isSeatRevoked || this.isSeatChange;
  }

  get hasTopicChangeDetails() {
    return this.isTopicCreation || this.isTopicChange || this.isTopicDeletion;
  }

  get isEmailSetLive() {
    return this.isRulesetActivation && this.value.content_type === 'email';
  }

  get isAdminUnauthorizedSignInMethod() {
    return this.type === 'AppAdminEvents::AdminUnauthorizedSignInMethod';
  }

  get isAdminPasswordResetRequest() {
    return this.type === 'AppAdminEvents::AdminPasswordResetRequest';
  }

  get isAdminPasswordResetSuccess() {
    return this.type === 'AppAdminEvents::AdminPasswordResetSuccess';
  }

  get isAdminAssignmentLimitChange() {
    return this.type === 'AppAdminEvents::AdminAssignmentLimitChange';
  }

  get isFinOptIn() {
    return this.type === 'AppAdminEvents::FinOptIn';
  }

  get isIdvReminderDisabled() {
    return this.type === 'AppAdminEvents::AppIdentityVerificationReminderDisabled';
  }

  get isMessengerPlatformAvailabilityChange() {
    return this.type === 'AppAdminEvents::MessengerPlatformAvailabilityChange';
  }

  get isMessengerPlatformInactivityDisableEvent() {
    return this.type === 'AppAdminEvents::MessengerPlatformInactivityDisableEvent';
  }

  get isIpAllowlistSettingChange() {
    return this.type === 'AppAdminEvents::IpAllowlistSettingChange';
  }

  get isAdminMobileLoginSuccess() {
    return this.type === 'AppAdminEvents::AdminMobileLoginSuccess';
  }

  get isAdminMobileLoginFailure() {
    return this.type === 'AppAdminEvents::AdminMobileLoginFailure';
  }

  get isAdminNameChange() {
    return this.type === 'AppAdminEvents::AdminNameChange';
  }

  get isAdminEmailChange() {
    return this.type === 'AppAdminEvents::AdminEmailChange';
  }

  get isAdminPasswordChange() {
    return this.type === 'AppAdminEvents::AdminPasswordChange';
  }

  get isSeatRevoke() {
    return this.type === 'AppAdminEvents::SeatRevoke';
  }

  get isAdminChannelChange() {
    return this.type === 'AppAdminEvents::AdminChannelChange';
  }

  get isFinPersistedDataContextAttributeChange() {
    return this.type === 'AppAdminEvents::FinPersistedDataContextAttributeChange';
  }

  get isAiAgentCustomizationSettingsChange() {
    return this.type === 'AppAdminEvents::AiAgentCustomizationSettingsChange';
  }

  get isAiAgentIdentitySettingsChange() {
    return this.type === 'AppAdminEvents::AiAgentIdentitySettingsChange';
  }

  static logSettingsUrl(appId, format) {
    return `/ember/app/${appId}/admin-events.${format || 'json'}`;
  }

  static logsSettingsBetweenDatesUrl(appId, format, from, to) {
    return `${this.logSettingsUrl(appId, format)}?from=${from}&to=${to}`;
  }

  static logsSettingsByImpersonationIdUrl(appId, format, impersonation_id) {
    return `${this.logSettingsUrl(appId, format)}?impersonation_id=${impersonation_id}`;
  }

  static getLogsSettingsBetweenDates(appId, from, to) {
    return ajax({
      url: this.logsSettingsBetweenDatesUrl(appId, 'json', from, to),
      type: 'GET',
    }).then(
      (response) => {
        let store = getEmberDataStore();
        store.pushPayload({ 'logs-settings': response });
        let objs = response.map(function (el) {
          try {
            return store.peekRecord('logs-settings', el.id);
          } catch (e) {
            console.error('app admin event item load failed', e, el);
          }
        });

        return A(objs);
      },
      function (e) {
        console.error(e, 'app admin event item load failed');
      },
    );
  }

  static getLogsSettingsByImpersonationId(appId, impersonation_id) {
    return ajax({
      url: this.logsSettingsByImpersonationIdUrl(appId, 'json', impersonation_id),
      type: 'GET',
    }).then(
      (response) => {
        let store = getEmberDataStore();
        store.pushPayload({ 'logs-settings': response });
        let objs = response.map(function (el) {
          try {
            return store.peekRecord('logs-settings', el.id);
          } catch (e) {
            console.error('app admin event item load failed', e, el);
          }
        });

        return A(objs);
      },
      function (e) {
        console.error(e, 'app admin event item load failed');
      },
    );
  }
}
