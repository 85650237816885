/* RESPONSIBLE TEAM: team-tickets-1 */
import {
  VALID_ACTION_TYPES,
  AWAY_MODE_KEY,
  REPORTS_DELETE_KEY,
} from 'embercom/lib/settings/permissions/constants';

export default function isActionPermission(permissionKey) {
  if (permissionKey === AWAY_MODE_KEY || permissionKey === REPORTS_DELETE_KEY) {
    return false;
  }

  return VALID_ACTION_TYPES.some((action) => permissionKey.includes(action));
}
