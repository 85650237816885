/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import Fragment from 'ember-data-model-fragments/fragment';

export default class ParamDescriptor extends Fragment {
  @service declare intl: IntlService;

  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('string') declare type: string;
  @attr('boolean') declare required: boolean;
  @attr('string') declare defaultValue: string;

  get typeLabel() {
    return this.intl.t(
      `workflow-connector.builder.body.request.fin-data.modal.form.format-types.${this.type}`,
    );
  }
}
