/* RESPONSIBLE TEAM: team-tickets-1 */
export interface PhoneNumberWireFormat {
  readonly id: number;
  readonly number: string;
  readonly country_code: string;
  readonly phone_type: number;
  readonly verification_status?: number;
}

export class PhoneNumber {
  readonly id: number;
  readonly number: string;
  readonly countryCode: string;
  readonly phoneType: number;
  readonly verificationStatus?: number;

  constructor(inputs: {
    id: number;
    number: string;
    countryCode: string;
    phoneType: number;
    verificationStatus?: number;
  }) {
    this.id = inputs.id;
    this.number = inputs.number;
    this.countryCode = inputs.countryCode;
    this.phoneType = inputs.phoneType;
    this.verificationStatus = inputs.verificationStatus;
  }

  static deserialize(json: PhoneNumberWireFormat) {
    return new PhoneNumber({
      id: json.id,
      number: json.number,
      countryCode: json.country_code,
      phoneType: json.phone_type,
      verificationStatus: json.verification_status,
    });
  }
}
