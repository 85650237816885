/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { and, not, or, readOnly, alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { fragmentArray, fragment } from 'ember-data-model-fragments/attributes';
import { isEvery } from '@intercom/pulse/lib/computed-properties';
import { userTypeComputedProperty } from './utils/userTypeComputedProperty';

export default Model.extend({
  appService: service(),
  app: readOnly('appService.app'),
  messengerSettingsTargetUserProvider: service(),

  isUserType: alias('messengerSettingsTargetUserProvider.isUserType'),
  targetUserType: alias('messengerSettingsTargetUserProvider.targetUserType'),

  hasUnsavedChanges: and('hasDirtyAttributes', 'hasValidLauncherPredicates'),

  hasValidLauncherPredicates: computed(
    'launcherPredicatesForUsers.[]',
    'launcherPredicatesForAnonymous.[]',
    'isFilterUsersSelected',
    'isFilterVisitorsSelected',
    'hasDirtyAttributes',
    function () {
      if (this.isFilterUsersSelected) {
        return !this.hasNoPredicatesSelected('launcherPredicatesForUsers');
      }
      if (this.isFilterVisitorsSelected) {
        return !this.hasNoPredicatesSelected('launcherPredicatesForAnonymous');
      }
      return true;
    },
  ),

  messengerEnabledForUsers: attr('boolean'),
  messengerEnabledForVisitors: attr('boolean'),
  messengerEnabledForMobileUsers: attr('boolean'),
  messengerEnabled: userTypeComputedProperty(
    'targetUserType',
    'messengerEnabledForUsers',
    'messengerEnabledForVisitors',
    'messengerEnabledForMobileUsers',
  ),

  allowAllUsers: attr('boolean'),
  allowAllVisitors: attr('boolean'),
  allowAllMobileUsers: attr('boolean'),
  isFilterUsersSelected: not('allowAllUsers'),
  isFilterVisitorsSelected: not('allowAllVisitors'),
  isFilterMobileUsersSelected: not('allowAllMobileUsers'),

  noContinuedConversationsEnabledForUsers: attr('boolean'),
  noContinuedConversationsEnabledForVisitors: attr('boolean'),

  noContinuedConversationsEnabled: userTypeComputedProperty(
    'targetUserType',
    'noContinuedConversationsEnabledForUsers',
    'noContinuedConversationsEnabledForVisitors',
  ),

  delayInDays: computed(
    'disallowConversationReplyAfterSecondsForUsers',
    'disallowConversationReplyAfterSecondsForVisitors',
    'isUserType',
    {
      get(key) {
        let property = this.isUserType
          ? this.disallowConversationReplyAfterSecondsForUsers
          : this.disallowConversationReplyAfterSecondsForVisitors;
        return property / (60 * 60 * 24);
      },
      set(key, value) {
        let days = Number.parseInt(value * 60 * 60 * 24, 10);
        if (this.isUserType) {
          this.disallowConversationReplyAfterSecondsForUsers = days;
        } else {
          this.disallowConversationReplyAfterSecondsForVisitors = days;
        }
        return value;
      },
    },
  ),

  disallowConversationReplyAfterSecondsForUsers: attr('number'),
  disallowConversationReplyAfterSecondsForVisitors: attr('number'),

  preventMultipleInboundConversationEnabledForUsers: attr('boolean'),
  preventMultipleInboundConversationEnabledForVisitors: attr('boolean'),

  preventMultipleInboundConversationEnabled: userTypeComputedProperty(
    'targetUserType',
    'preventMultipleInboundConversationEnabledForUsers',
    'preventMultipleInboundConversationEnabledForVisitors',
  ),

  preventMultipleInboundForUsersPredicateGroup: fragment('predicates/predicate-group'),
  preventMultipleInboundForVisitorsPredicateGroup: fragment('predicates/predicate-group'),

  preventMultipleInboundPredicateGroup: userTypeComputedProperty(
    'targetUserType',
    'preventMultipleInboundForUsersPredicateGroup',
    'preventMultipleInboundForVisitorsPredicateGroup',
  ),

  launcherPredicatesForUsers: fragmentArray('predicates/predicate', {
    polymorphic: true,
    typeKey: 'subclass',
    defaultValue: () => [],
  }),
  launcherPredicatesForAnonymous: fragmentArray('predicates/predicate', {
    polymorphic: true,
    typeKey: 'subclass',
    defaultValue: () => [],
  }),
  launcherPredicatesForMobileUsers: fragmentArray('predicates/predicate', {
    polymorphic: true,
    typeKey: 'subclass',
    defaultValue: () => [],
  }),

  launcherPredicates: userTypeComputedProperty(
    'targetUserType',
    'launcherPredicatesForUsers',
    'launcherPredicatesForAnonymous',
    'launcherPredicatesForMobileUsers',
  ),

  inboundConversationsForUsersPredicateGroup: fragment('predicates/predicate-group'),
  inboundConversationsForVisitorsPredicateGroup: fragment('predicates/predicate-group'),

  inboundConversationsPredicateGroup: userTypeComputedProperty(
    'targetUserType',
    'inboundConversationsForUsersPredicateGroup',
    'inboundConversationsForVisitorsPredicateGroup',
  ),

  inboundConversationsEnabledForUsers: attr('boolean'),
  inboundConversationsEnabledForVisitors: attr('boolean'),

  inboundConversationsEnabled: userTypeComputedProperty(
    'targetUserType',
    'inboundConversationsEnabledForUsers',
    'inboundConversationsEnabledForVisitors',
  ),

  contentSuggestionsForUsersPredicateGroup: fragment('predicates/predicate-group'),
  contentSuggestionsForVisitorsPredicateGroup: fragment('predicates/predicate-group'),
  contentSuggestionsEnabledForUsers: attr('boolean'),
  contentSuggestionsEnabledForVisitors: attr('boolean'),

  validUserLauncherPredicates: isEvery('launcherPredicatesForUsers', 'isValid'),
  validVisitorLauncherPredicates: isEvery('launcherPredicatesForAnonymous', 'isValid'),
  validLauncherPredicates: and('validUserLauncherPredicates', 'validVisitorLauncherPredicates'),

  validUserInboundConversationsPredicates: isEvery(
    'inboundConversationsForUsersPredicateGroup.predicates',
    'isValid',
  ),
  validVisitorInboundConversationsPredicates: isEvery(
    'inboundConversationsForVisitorsPredicateGroup.predicates',
    'isValid',
  ),
  validInboundConversationsPredicates: and(
    'validUserInboundConversationsPredicates',
    'validVisitorInboundConversationsPredicates',
  ),

  validUserContentSuggestionsPredicates: isEvery(
    'contentSuggestionsForUsersPredicateGroup.predicates',
    'isValid',
  ),
  validVisitorContentSuggestionsPredicates: isEvery(
    'contentSuggestionsForVisitorsPredicateGroup.predicates',
    'isValid',
  ),
  validContentSuggestionsPredicates: and(
    'validUserContentSuggestionsPredicates',
    'validVisitorContentSuggestionsPredicates',
  ),

  validPredicates: and(
    'validLauncherPredicates',
    'validInboundConversationsPredicates',
    'validContentSuggestionsPredicates',
  ),
  hasInvalidPredicates: not('validPredicates'),

  showPaywallForConditionalDisplay: not('app.canUseConditionalDisplayForVisitors'),
  isNotValidPaywallSaveState: and('isFilterVisitorsSelected', 'showPaywallForConditionalDisplay'),

  isNotValidSaveState: or('hasInvalidPredicates', 'isNotValidPaywallSaveState'),
  isValid: not('isNotValidSaveState'),

  hasNoPredicatesSelected(type) {
    if (this.get(`${type}.length`) === 0) {
      return true;
    }
    //If "Match Any" is selected, need to check if the sublist of predicates is empty
    return this.get(`${type}.firstObject.predicates.length`) === 0;
  },

  refresh() {
    this.rollbackAttributes();
  },

  updateLauncherPredicates() {
    if (
      this.messengerEnabledForVisitors &&
      this.isFilterVisitorsSelected &&
      this.hasNoPredicatesSelected('launcherPredicatesForAnonymous')
    ) {
      this.set('allowAllVisitors', true);
    }
    if (
      this.messengerEnabledForUsers &&
      this.isFilterUsersSelected &&
      this.hasNoPredicatesSelected('launcherPredicatesForUsers')
    ) {
      this.set('allowAllUsers', true);
    }
    if (
      this.messengerEnabledForMobileUsers &&
      this.isFilterMobileUsersSelected &&
      this.hasNoPredicatesSelected('launcherPredicatesForMobileUsers')
    ) {
      this.set('allowAllMobileUsers', true);
    }
    if (!this.messengerEnabledForVisitors || this.allowAllVisitors) {
      this.set('launcherPredicatesForAnonymous', []);
    }
    if (!this.messengerEnabledForUsers || this.allowAllUsers) {
      this.set('launcherPredicatesForUsers', []);
    }
    if (!this.messengerEnabledForMobileUsers || this.allowAllMobileUsers) {
      this.set('launcherPredicatesForMobileUsers', []);
    }
  },
});
