/* RESPONSIBLE TEAM: team-help-desk-experience */
import ViewSummary, { type ViewSummaryWireFormat } from '../view-summary';
import type Inbox from './inbox';
import { INBOX_DEFAULT_SORT_ORDER } from './inbox';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

export type ViewInboxWireFormat = {
  inbox_type: InboxType.View;
  count: number;
  sort_order: number;
  view_summary: ViewSummaryWireFormat;
};

export default class View implements Inbox {
  readonly category = InboxCategory.View;
  readonly type = InboxType.View;
  readonly sortOrder: number;
  readonly count?: number;

  viewSummary: ViewSummary;

  constructor(count: number, viewSummary: ViewSummary, sortOrder?: number) {
    this.count = count;
    this.viewSummary = viewSummary;
    this.sortOrder = sortOrder ?? INBOX_DEFAULT_SORT_ORDER;
  }

  get id() {
    return this.viewSummary.id.toString();
  }

  get icon() {
    return this.viewSummary.icon;
  }

  get name() {
    return this.viewSummary.name;
  }

  get translationKey() {
    return this.viewSummary.isSystemView
      ? `inbox.inbox-list.system-view-names.${this.viewSummary.systemType}`
      : undefined;
  }

  static deserialize(json: ViewInboxWireFormat): View {
    return new View(json.count, ViewSummary.deserialize(json.view_summary), json.sort_order);
  }
}
