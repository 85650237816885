/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { ALL_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

export default class CreateSalesforceCase extends Step {
  supportedChannels = ALL_CHANNELS;

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store): CreateSalesforceCase {
    return store.createRecord('operator/visual-builder/step/create-salesforce-case', {
      type: 'operator/visual-builder/step/create-salesforce-case',
    });
  }
}
