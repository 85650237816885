/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import GraphConfiguration from 'embercom/objects/series/node/graph-configuration';
import StandardNodeComponentConfiguration from 'embercom/objects/series/node/standard-node-component-configuration';
import ConnectorConfiguration from 'embercom/objects/series/node/connector-configuration';
import NotOnlineConnectorConfiguration from 'embercom/objects/series/node/not-online-connector-configuration';
import ComponentConfiguration from 'embercom/objects/component-configuration';
import StatsConfiguration from 'embercom/objects/series/node/stats-configuration';
import {
  objectTypes,
  seriesEdgeTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import renderPreview from '@intercom/embercom-composer/lib/render-preview';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { defaultDelayDescription } from 'embercom/lib/series/constants';

export default class PostGraphConfiguration extends GraphConfiguration {
  objectType = objectTypes['post'];

  constructor(seriesNode) {
    super(...arguments);
    this.componentConfig = new StandardNodeComponentConfiguration({
      icon: 'series/content/post',
      objectType: this.objectType,
      delayConfigurationComponent: new ComponentConfiguration({
        component: 'series/node/in-app-delay-editor',
      }),
      statsSummaryConfigurationComponent: new StatsConfiguration({
        verb: 'sent',
        component: 'series/node/standard-stats-summary',
      }),
    });
  }

  get connectors() {
    return [
      new ConnectorConfiguration({
        label: 'WHEN DELIVERED',
        edgeType: seriesEdgeTypes.primary,
        component: 'series/node/connectors/content-shortcuts-connector',
      }),
      new NotOnlineConnectorConfiguration({
        node: this.node,
        edgeType: seriesEdgeTypes.alternative,
      }),
    ];
  }

  get inferredTitle() {
    let contentPreview = this.node.contentPreviews.firstObject?.contentPreviewData?.content;
    if (contentPreview) {
      let previewHtml = renderPreview(contentPreview, {});
      return htmlToTextContent(previewHtml);
    }
    return '';
  }

  get primaryPredecessorLabel() {
    return `Received '${this.title}'`;
  }

  get alternativePredecessorLabel() {
    return `Did not come online to receive '${this.title}'`;
  }

  get checkpointStats() {
    return [
      { label: 'received', value: this.node.completedCheckpointCount },
      { label: 'pending', value: this.node.activeCheckpointCount },
      {
        label: [
          `didn't come online within ${this.node.delayDescription || defaultDelayDescription}`,
        ],
        value: this.node.expiredCheckpointCount,
      },
      { label: 'left path', value: this.node.exitedCheckpointCount },
    ];
  }
}
