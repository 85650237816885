/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { not, readOnly } from '@ember/object/computed';
import TrustedListUrlValidator from 'embercom/lib/trusted-list-url-validator';

export default Model.extend({
  parsedAllowedDomains: attr('array'),
  isValid: not('domainValidator.isNotValid'),
  hasUnsavedChanges: readOnly('hasDirtyAttributes'),

  allowedDomains: computed('parsedAllowedDomains.[]', function () {
    return this.parsedAllowedDomains.join(',');
  }),

  domainValidator: computed('allowedDomains', function () {
    return TrustedListUrlValidator.create({ urlString: this.allowedDomains });
  }),

  updateParsedAllowedDomains() {
    this.set('parsedAllowedDomains', this.get('domainValidator.domainHostNames'));
  },

  refresh() {
    this.rollbackAttributes();
  },
});
