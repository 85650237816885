/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const useCaseTypes = {
  marketing: 0,
  operational: 1,
};

export const keywordResponseTypes = {
  default: 0,
  stop: 1,
  start: 2,
  info: 3,
  custom: 4,
  carrierStop: 5,
};

export const keywordTypes = {
  0: 'Unrecognised Keyword',
  1: 'Unsubscribe',
  2: 'Resubscribe',
  3: 'Help',
  4: 'Custom',
};

export const defaultQuietHours = {
  startMinute: 40 * 30,
  endMinute: 22 * 30,
};

export const responseProcessingBehaviours = {
  deflectToInbox: 0,
  keywordResponse: 1,
};

export const keywordResponseActionTranslationKeys = {
  [keywordResponseTypes.default]: 'create-inbox-conversation',
  [keywordResponseTypes.stop]: 'unsubscribe-marketing',
  [keywordResponseTypes.start]: 'resubscribe-marketing',
  [keywordResponseTypes.info]: null,
  [keywordResponseTypes.custom]: null,
  [keywordResponseTypes.carrierStop]: 'carrier-stop',
};

export const estimatedMonthlyVolumes = {
  lessThan1K: 0,
  from1KTo5K: 2,
  from5KTo50K: 3,
  from50KTo500K: 4,
  moreThan500K: 5,
};

export const mainUseCases = {
  // active
  marketingAndPromotional: 8,
  otherNonPromotional: 9,
  // inactive
  promotional: 0,
  operational: 1,
  accountNotifications: 2,
  reminders: 3,
  deliveryNotifications: 4,
  transactional: 5,
  customerSupport: 6,
  proactiveSupport: 7,
};

export const mainUseCaseCategories = {
  twoFactorAuthentication: 0,
  accountNotifications: 1,
  customerCare: 2,
  charityNonprofit: 3,
  deliveryNotifications: 4,
  fraudAlertMessaging: 5,
  events: 6,
  higherEducation: 7,
  k12: 8,
  marketing: 9,
  pollingAndVotingNonPolitical: 10,
  politicalElectionCampaigns: 11,
  publicServiceAnnouncement: 12,
  securityAlert: 13,
};

export const mainUseCaseCategoryNames = {
  [mainUseCaseCategories.twoFactorAuthentication]: 'twoFactorAuthentication',
  [mainUseCaseCategories.accountNotifications]: 'accountNotifications',
  [mainUseCaseCategories.customerCare]: 'customerCare',
  [mainUseCaseCategories.charityNonprofit]: 'charityNonprofit',
  [mainUseCaseCategories.deliveryNotifications]: 'deliveryNotifications',
  [mainUseCaseCategories.fraudAlertMessaging]: 'fraudAlertMessaging',
  [mainUseCaseCategories.events]: 'events',
  [mainUseCaseCategories.higherEducation]: 'higherEducation',
  [mainUseCaseCategories.k12]: 'k12',
  [mainUseCaseCategories.marketing]: 'marketing',
  [mainUseCaseCategories.pollingAndVotingNonPolitical]: 'pollingAndVotingNonPolitical',
  [mainUseCaseCategories.politicalElectionCampaigns]: 'politicalElectionCampaigns',
  [mainUseCaseCategories.publicServiceAnnouncement]: 'publicServiceAnnouncement',
  [mainUseCaseCategories.securityAlert]: 'securityAlert',
};

export const optInTypes = {
  verbal: 0,
  webForm: 1,
  paperForm: 2,
  viaText: 3,
  mobileQrCode: 4,
};

export const optInTypeNames = {
  [optInTypes.verbal]: 'verbal',
  [optInTypes.webForm]: 'webForm',
  [optInTypes.paperForm]: 'paperForm',
  [optInTypes.viaText]: 'viaText',
  [optInTypes.mobileQrCode]: 'mobileQrCode',
};

// onboarding
export const ONBOARDING_STEP_KEYS = {
  GET_STARTED: 'GET_STARTED',
  SELECT_COUNTRY: 'SELECT_COUNTRY',
  SELECT_NUMBER: 'SELECT_NUMBER',
  CONFIGURE_SMS: 'CONFIGURE_SMS',
  PREVIEW_SMS: 'PREVIEW_SMS',
};

export const ONBOARDING_PROGRESS_STEP_LIST = {
  [ONBOARDING_STEP_KEYS.SELECT_COUNTRY]: 'Select country',
  [ONBOARDING_STEP_KEYS.SELECT_NUMBER]: 'Choose number',
  [ONBOARDING_STEP_KEYS.CONFIGURE_SMS]: 'Configure SMS',
};

export const phoneTypes = {
  tollFree: 0,
  local: 1,
  mobile: 2,
};

export const phoneTypesNames = {
  [phoneTypes.tollFree]: 'toll-free',
  [phoneTypes.local]: 'local',
  [phoneTypes.mobile]: 'mobile',
};

export const VERIFICATION_STATUS = {
  DRAFT: 'Draft',
  PENDING: 'Pending',
  SUBMITTED: 'Submitted',
  VERIFIED: 'Verified',
  REJECTED: 'Rejected',
  IN_REVIEW: 'In review',
};

export const SENDER_ID_OPTIONS = {
  LONGCODE: 'longcode',
  ALPHANUMERIC_SENDER_ID: 'alphanumericSenderId',
};

// hardcoded survey Id for requesting countries, only for production
export const REQUEST_COUNTRY_SURVEY_ID = 25446105;

export const USAGE_LIMIT_STATUS = {
  SENDING_PERMITTED: 0,
  OUTBOUND_SENDING_BLOCKED: 1,
  ALL_SENDING_BLOCKED: 2,
};

export const REJECTION_REASONS = {
  CONTENT_VIOLATION: 30441,
  CAMPAIGN_VIOLATION: 30442,
  DISALLOWED_CONTENT: 30443,
  HIGH_RISK: 30444,
  UNKNOWN_ERROR: 30440,
  INVALID_INFORMATION: 30445,
  OPT_IN_ERROR: 30446,
  PHONE_NUMBER_ERROR: 30447,
  AGE_GATE: 30448,
  INVALID_SAMPLE_MESSAGE_URL: 30449,
};
