/* RESPONSIBLE TEAM: team-workflows */
import type ChangeConversationPriority from 'embercom/models/operator/visual-builder/step/change-conversation-priority';
import StepConfig, { type StepConfigParams } from '../step';

export default class ChangeConversationPriorityConfiguration extends StepConfig {
  declare step: ChangeConversationPriority;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/change-conversation-priority',
      ...params,
    });
  }
}
