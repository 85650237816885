/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { and, equal, match, none, not, notEmpty, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { typeOf } from '@ember/utils';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import {
  applyFunction,
  findByProperty,
  readsAndFmtWithProperty,
} from '@intercom/pulse/lib/computed-properties';
import { DataManagers } from 'embercom/lib/common/predicates/data-managers';
import PredicatesService from 'embercom/services/predicates-service';
import PredicateValidations from 'embercom/validations/common/predicate';

export default Fragment.extend(PredicateValidations, {
  attributeService: service(),
  predicatesService: service(),
  attribute: attr('string'),
  comparison: attr('string'),
  type: attr('string'),
  value: attr(),
  valueDescription: attr('string'),
  predicates: fragmentArray('common/predicate'),

  isOrPredicate: equal('type', 'or'),
  isUserEventDateType: equal('type', 'user_event_date'),

  isUserEventPredicate: match('attribute', /^user_event/),
  isTimeOnPage: equal('attribute', 'client_attributes.time_on_page'),
  isPageTarget: equal('attribute', 'client_attributes.last_visited_url'),

  isUserPredicate: or('isAnonymousType', 'isRoleType'),
  isNotEditable: or('isLogicalType', 'isUserPredicate', 'isEmailDisengagementType'),
  isEditable: not('isNotEditable'),

  hasAttribute: notEmpty('attribute'),
  hasComparison: notEmpty('comparison'),
  hasNoComparison: not('hasComparison'),
  hasType: notEmpty('type'),
  hasValue: notEmpty('value'),
  hasNoValue: not('hasValue'),
  hasAttributeComparisonAndType: and('hasAttribute', 'hasComparison', 'hasType'),
  hasAttributeValueAndType: and('hasValue', 'hasType'),

  isKnownComparison: equal('comparison', 'known'),
  isUnknownComparison: equal('comparison', 'unknown'),
  isKnownOrUnknownComparison: or('isKnownComparison', 'isUnknownComparison'),
  isIncreaseComparison: equal('comparison', 'increase'),
  isDecreaseComparison: equal('comparison', 'decrease'),
  isIncreaseOrDecreaseComparison: or('isIncreaseComparison', 'isDecreaseComparison'),
  isTriggeredComparison: equal('comparison', 'triggered'),
  isNotValueBasedComparison: or(
    'isKnownOrUnknownComparison',
    'isIncreaseOrDecreaseComparison',
    'isTriggeredComparison',
  ),
  isValueBaseComparison: not('isNotValueBasedComparison'),
  isTrueComparison: equal('comparison', 'true'),
  isFalseComparison: equal('comparison', 'false'),

  valueIsNull: none('value'),
  typeOfValue: applyFunction(typeOf, 'value'),
  valueIsObject: equal('typeOfValue', 'object'),
  valueIsString: equal('typeOfValue', 'string'),

  isAbsoluteDate: and('valueIsObject', 'isDateType'),
  isRelativeDate: and('valueIsString', 'isDateType'),
  isTargetingUsers: and('isAnonymousType', 'isFalseComparison'),

  comparisonOptionValue: computed('comparison', 'isAbsoluteDate', 'isRelativeDate', function () {
    let comparison = this.comparison;
    if (this.isRelativeDate) {
      return `${comparison}:relative`;
    } else if (this.isAbsoluteDate) {
      return `${comparison}:absolute`;
    } else {
      return comparison;
    }
  }),

  attributeIdentifier: computed('attribute', 'isUserEventPredicate', function () {
    let attribute = this.attribute;
    if (this.isUserEventPredicate) {
      return attribute.replace(/\.(count|first|last)$/, '');
    }
    return attribute;
  }),

  userEventAttributeType: computed('attribute', 'isUserEventPredicate', function () {
    if (this.isUserEventPredicate) {
      return this.attribute.split('.').pop();
    }
  }),

  info: findByProperty('attributeService.allAttributes', 'identifier', 'attributeIdentifier'),
  settings: findByProperty('attributeService.attributeSettings', 'attribute', 'attribute'),

  isTagType: equal('genericType', 'tag'),
  isNumericTagType: equal('genericType', 'numericTag'),
  isOptionalTagType: equal('genericType', 'optionalTag'),
  isSelectType: equal('genericType', 'select'),
  isStringType: equal('genericType', 'string'),
  isNumericType: equal('genericType', 'numeric'),
  isDateType: equal('genericType', 'date'),
  isBooleanType: equal('genericType', 'boolean'),
  isAnonymousType: equal('genericType', 'anonymous'),
  isRoleType: equal('genericType', 'role'),
  isLogicalType: equal('genericType', 'logical'),
  isEmailDisengagementType: equal('genericType', 'emailDisengagement'),

  genericType: computed('type', function () {
    return PredicatesService.genericTypes.find((genericType) =>
      PredicatesService[`${genericType}Types`].includes(this.type),
    );
  }),
  comparisonOptions: computed('type', 'genericType', function () {
    return PredicatesService.comparisonOptionsForType(this.type, this.genericType);
  }),
  camelizedType: applyFunction(camelize, 'type'),
  optionsForType: readsAndFmtWithProperty('predicatesService.%@Options', 'camelizedType'),

  dataManager: computed('genericType', function () {
    let managerForType = DataManagers[this.genericType];
    return managerForType.create({ predicate: this });
  }),

  isTracked: readOnly('info.tracked'),
  isUntracked: not('isTracked'),
  isValid: readOnly('dataManager.isValid'),
  isInvalid: not('isValid'),

  getUserType: computed('genericType', 'value', function () {
    return this.dataManager.getUserType();
  }),

  getDefaultComparison(context = {}) {
    return this.dataManager.getDefaultComparison(context);
  },

  getDefaultValue(context = {}) {
    return this.dataManager.getDefaultValue(context);
  },

  getDescription(context) {
    return this.dataManager.getDescription(context || {});
  },

  //DEPRECATED IN FAVOUR OF ONE WAY BINDINGS
  initializeDefaultValue(context = {}) {
    this.dataManager.initializeDefaultValue(context);
  },

  changeComparison(comparison) {
    this.dataManager.changeComparison(comparison);
  },

  changeValue(value) {
    this.dataManager.changeValue(value);
  },

  changeUserEventType(eventType) {
    let predicateType = eventType === 'count' ? 'user_event_integer' : 'user_event_date';
    this.setProperties({
      attribute: `${this.attributeIdentifier}.${eventType}`,
      type: predicateType,
    });
  },

  toJSON() {
    if (this.isOrPredicate) {
      let jsonPredicates = this.predicates.map((predicate) => predicate.toJSON());
      return { type: this.type, predicates: jsonPredicates };
    } else {
      return this.getProperties(['attribute', 'comparison', 'type', 'value']);
    }
  },

  description: computed(
    'attribute',
    'comparison',
    'type',
    'value',
    'predicates.@each.description',
    function () {
      return this.getDescription();
    },
  ),

  goalDescription: computed(
    'attribute',
    'comparison',
    'type',
    'value',
    'predicates.@each.description',
    function () {
      return this.dataManager.getGoalDescription();
    },
  ),
});
