/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { notEmpty, and, gt, not, equal, or, filterBy, readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { buildEmbeddableVideoUrl } from 'embercom/lib/video-embed-url';

const APP_PACKAGE_IDS_WITH_TEAMMATE_SETTINGS = [
  'google-meet',
  'google-calendar-meetings',
  'outlook-calendar-meetings',
];
const APP_PACKAGE_EXTRA_INSTALL_TYPES = {
  'salesforce-by-intercom': [
    {
      id: 'salesforce-sandbox',
      label: 'Install using sandbox',
      paywall: 'hasSalesforceSandboxFeatureInPlan',
      params: { sandbox: true },
    },
  ],
};

export const APP_PACKAGE_INSTALL_STATES = {
  unknown: 'UNKNOWN',
  ok: 'OK',
  unhealthy: 'UNHEALTHY',
  notFound: 'NOT_FOUND',
  invalidAuth: 'INVALID_AUTH',
  incompleteConfiguration: 'INCOMPLETE_CONFIGURATION',
};

export default Model.extend({
  init() {
    this._super(...arguments);
    this.set('appPackageExtraInstallTypes', APP_PACKAGE_EXTRA_INSTALL_TYPES);
  },

  appService: service(),
  name: attr('string'),
  implementationIdentifier: attr('string'),
  description: attr('string'),
  longDescription: attr('string'),
  longDescriptionBlocks: attr('array'),
  startingGuideBlocks: attr('array'),
  categories: hasMany('appstore/category'),
  publicCategories: filterBy('categories', 'publiclyVisible', true),
  capabilities: attr(),
  appPackageInstallId: attr('string'),
  installState: attr('string'),
  installStateMessage: attr('string'),
  installStateCtaUrl: attr('string'),
  installStateCtaLabel: attr('string'),
  installStateCtaType: attr('string'),
  inboxAppId: attr(),
  installingAdminName: attr('string'),
  iconUrl: attr('string'),
  installUrl: attr('string'),
  isInstalled: attr('boolean'),
  isNotInstalled: not('isInstalled'),
  isLegacyVersionInstalled: attr('boolean'),
  learnMoreUrl: attr('string'),
  installType: attr('string'),
  hasInstallUrl: notEmpty('installUrl'),
  hasNoInstallUrl: not('installUrl'),
  hasLearnMoreUrl: notEmpty('learnMoreUrl'),
  isRequiresOauthInstallType: equal('installType', 'requires_oauth'),
  isOAuthInstall: and('isRequiresOauthInstallType', 'hasInstallUrl'),
  isCustomInstall: equal('installType', 'skips_oauth'),
  isThirdPartyAppStoreInstall: and('isRequiresOauthInstallType', 'hasNoInstallUrl'),
  isIntercomAppStoreInstall: or('isOAuthInstall', 'isCustomInstall'),
  messengerApp: belongsTo('messenger-app'),
  isMessengerApp: notEmpty('messengerApp.content'),
  imageUrls: attr('array'),
  installDate: attr('string'),
  paywallFlag: attr('string'),
  hasTeammateSettings: computed('id', function () {
    return APP_PACKAGE_IDS_WITH_TEAMMATE_SETTINGS.includes(this.id);
  }),
  analyticsData: computed('id', function () {
    return {
      object: 'app_package',
      app_package_code: this.id,
    };
  }),
  builtBy: attr('string'),
  termsOfServiceUrl: attr('string'),
  privacyPolicyUrl: attr('string'),
  pricingModel: attr('string'),
  pricingPageUrl: attr('string'),
  documentationUrl: attr('string'),
  avatarWorkspaceIdCode: attr('string'),
  supportEmail: attr('string'),
  adminAvatars: attr('array'),
  developerHomepageUrl: attr('string'),
  extraInstallTypes: computed('appService.app', function () {
    let appPackageExtraInstallTypes = this.appPackageExtraInstallTypes[this.id];
    if (appPackageExtraInstallTypes === undefined) {
      return [];
    }

    return appPackageExtraInstallTypes.filter((appPackage) => {
      return appPackage.paywall === undefined || this.get('appService.app').get(appPackage.paywall);
    });
  }),
  hasExtraInstallTypesEnabled: gt('extraInstallTypes.length', 0),

  videoId: attr('string'),
  videoProvider: attr('string'),
  videoEmbedUrl: computed('videoId', 'videoProvider', function () {
    return buildEmbeddableVideoUrl(this.videoId, this.videoProvider);
  }),

  hasInstallStateUnknown: equal('installState', APP_PACKAGE_INSTALL_STATES.unknown),
  hasInstallStateUnhealthy: equal('installState', APP_PACKAGE_INSTALL_STATES.unhealthy),
  hasInstallStateOk: equal('installState', APP_PACKAGE_INSTALL_STATES.ok),
  hasInstallStateNotFound: equal('installState', APP_PACKAGE_INSTALL_STATES.notFound),
  hasInstallStateInvalidAuth: equal('installState', APP_PACKAGE_INSTALL_STATES.invalidAuth),
  hasInstallStateIncompleteConfig: equal(
    'installState',
    APP_PACKAGE_INSTALL_STATES.incompleteConfiguration,
  ),
  isIntercomApp: equal('builtBy', 'Intercom'),
  hasStartingGuide: notEmpty('startingGuideBlocks'),
  hasCapabilities: or(
    'worksWithInboxConversations',
    'worksWithMessages',
    'worksWithMessenger',
    'worksWithOperator',
    'worksWithInboxSidebar',
  ),
  worksWithInboxSidebar: readOnly('capabilities.works_with_inbox_sidebar'),
  worksWithMessenger: readOnly('capabilities.works_with_messenger'),
  worksWithInboxConversations: readOnly('capabilities.works_with_inbox_conversations'),
  worksWithOperator: readOnly('capabilities.works_with_operator'),
  worksWithMessages: readOnly('capabilities.works_with_messages'),
  worksWithInbox: or('worksWithInboxSidebar', 'worksWithInboxConversations'),
  worksWithInboxSidebarAndConversations: and(
    'worksWithInboxSidebar',
    'worksWithInboxConversations',
  ),
});
