/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export type FORM_TYPES = 'default' | 'compact' | 'conversational';
export default class Form extends Model {
  @attr('string') formForUsers?: FORM_TYPES;
  @attr('string') formForVisitors?: FORM_TYPES;

  @service messengerSettingsTargetUserProvider: $TSFixMe;

  get selectedForm() {
    return this.messengerSettingsTargetUserProvider.isUserType
      ? this.formForUsers
      : this.formForVisitors;
  }
  // We're setting both to the same value because the backend does distinguish between
  // users and visitors, but we do not want to in settings.
  set selectedForm(value) {
    this.formForUsers = value;
    this.formForVisitors = value;
  }

  get isCompact() {
    return this.selectedForm === 'compact';
  }

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
