/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import { type AttributeCollectorType } from 'embercom/models/data/inbox/attribute-collector-enums';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';
import CustomBotSummary, { type CustomBotSummaryWireFormat } from '../custom-bot-summary';

export type Attribute = {
  identifier: string;
  name: string;
  type: string;
  options?: Array<string>;
  custom_bot_control_id?: number;
  value?: string | number | boolean | undefined;
};

interface AttributeCollectorWireFormat {
  renderable_type: RenderableType;
  attribute_collector_type: AttributeCollectorType;
  attributes: Array<Attribute>;
  entity: AdminSummaryWireFormat;
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

export default class AttributeCollector implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.AttributeCollector;
  readonly attributes: Array<Attribute>;
  readonly attributeCollectorType: AttributeCollectorType;
  readonly entity: AdminSummary;
  readonly customBotSummary?: CustomBotSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: this.entity.id, object: this.entity };
  }

  constructor(
    attributes: Array<Attribute>,
    attributeCollectorType: AttributeCollectorType,
    entity: AdminSummary,
    customBotSummary?: CustomBotSummary,
  ) {
    this.attributes = attributes;
    this.attributeCollectorType = attributeCollectorType;
    this.entity = entity;
    this.customBotSummary = customBotSummary;
  }

  static deserialize(json: AttributeCollectorWireFormat): AttributeCollector {
    return new AttributeCollector(
      json.attributes,
      json.attribute_collector_type,
      AdminSummary.deserialize(json.entity),
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
