/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';
import type ActionObjectMapping from './action-object-mapping';

export default class ActionReferenceMapping extends Fragment {
  @attr('string') declare intercomObjectType: string;
  @attr('string') declare intercomAttributeIdentifier: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  @tracked declare objectMapping: ActionObjectMapping;

  get validationErrors() {
    if (isBlank(this.intercomObjectType) || isBlank(this.intercomAttributeIdentifier)) {
      return ['intercom_attribute_missing'];
    }

    return [];
  }

  hydrateParent(objectMapping: ActionObjectMapping) {
    this.objectMapping = objectMapping;
  }
}
