/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import type AdminSummary from '../admin-summary';
import CustomBotSummary, { type CustomBotSummaryWireFormat } from '../custom-bot-summary';

export enum CreatingEntityType {
  Bot = EntityType.Bot,
  LegacyRule = EntityType.LegacyRule,
}

interface PriorityChangedByRuleWireFormat {
  renderable_type: RenderableType;
  entity_type: CreatingEntityType;
  entity?: AdminSummary;
  bot_id?: number;
  rule_id?: number;
  rule_name?: string;
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

export default class PriorityChangedByRule implements RenderableData {
  readonly renderableType = RenderableType.PriorityChangedByRule;
  readonly entityType: CreatingEntityType;
  readonly entity?: AdminSummary;
  readonly botId?: number;
  readonly ruleId?: number;
  readonly ruleName?: string;
  readonly customBotSummary?: CustomBotSummary;

  get creatingEntity() {
    let id;
    let type;
    let object;
    if (this.entityType === CreatingEntityType.Bot) {
      id = this.botId;
      type = EntityType.Bot;
      object = this.entity;
    } else if (this.entityType === CreatingEntityType.LegacyRule) {
      id = this.ruleId;
      type = EntityType.LegacyRule;
    }

    return { type, id: id || -1, object };
  }

  constructor(
    entityType: CreatingEntityType,
    entity?: AdminSummary,
    botId?: number,
    ruleId?: number,
    ruleName?: string,
    customBotSummary?: CustomBotSummary,
  ) {
    this.entityType = entityType;
    this.entity = entity;
    this.botId = botId;
    this.ruleId = ruleId;
    this.ruleName = ruleName;
    this.customBotSummary = customBotSummary;
  }

  static deserialize(json: PriorityChangedByRuleWireFormat): PriorityChangedByRule {
    return new PriorityChangedByRule(
      json.entity_type,
      json.entity,
      json.bot_id,
      json.rule_id,
      json.rule_name,
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
