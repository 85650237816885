/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class BillingFeature extends Fragment {
  @attr('string') declare key: string;
  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr() declare breakdown: Array<string>;
  @attr() declare breakdownKeys: Array<string>;
  @attr('string') declare subtext: string;
  @attr('string') declare subtextKey: string;
  @attr() declare helpCenterArticleLinks: Array<string>;
}
