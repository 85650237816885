/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import ENV from 'embercom/config/environment';

function _B64UrlToUint8Array(base64String) {
  let padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  let base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  let rawData = atob(base64);
  let outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function _vapidTokenString() {
  if (ENV.environment === 'production') {
    return $('meta[name="vapid_public_key"]').attr('content');
  } else {
    return ENV.applicationServerKeyString;
  }
}

export function encodedVapidToken() {
  return _B64UrlToUint8Array(_vapidTokenString());
}
