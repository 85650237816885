/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';
import StandardInserterComponentConfiguration from 'embercom/objects/series/node/standard-inserter-component-configuration';
import StandardLoadingComponentConfiguration from 'embercom/objects/series/node/standard-loading-component-configuration';
import {
  objectTypes,
  objectFeatures,
} from 'embercom/models/data/matching-system/matching-constants';

export default class WhatsappInserterConfiguration extends InserterConfiguration {
  objectType = objectTypes.whatsapp;
  objectData = {};
  requiredFeatures = [objectFeatures[objectTypes.whatsapp]];

  componentConfig = new StandardInserterComponentConfiguration({
    icon: 'series/content/whatsapp',
    paywallIcon: 'series/content/paywalled-whatsapp',
    featureFlagsWithAccess: [objectFeatures[objectTypes.whatsapp]],
    title: 'WhatsApp',
  });

  loadingComponentConfig = new StandardLoadingComponentConfiguration({
    icon: 'series/content/whatsapp',
    paywallIcon: 'series/content/paywalled-whatsapp',
    title: `Empty WhatsApp message`,
    objectType: this.objectType,
  });

  isTooltipDisabled(app) {
    return app.canUseFeature('whatsapp_integration');
  }

  disabledTooltipContent() {
    return 'You need to purchase the WhatsApp addon to use WhatsApp in Series';
  }

  canInsertNode({ app, _dataObject }) {
    return app.canUseWhatsappOutbound;
  }
}
