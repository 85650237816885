/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

import { userTypeComputedProperty } from './utils/userTypeComputedProperty';

export default class StartConversationButtonText extends Model {
  @attr('string') teammateStartConversationButtonTextForUsers?: string;
  @attr('string') teammateStartConversationButtonTextForVisitors?: string;
  @attr('string') finStartConversationButtonTextForUsers?: string;
  @attr('string') finStartConversationButtonTextForVisitors?: string;
  @attr('string') setExpectationsForUsers?: string;
  @attr('string') setExpectationsForVisitors?: string;

  @service messengerSettingsTargetUserProvider: any;

  // @ts-ignore
  @userTypeComputedProperty(
    'messengerSettingsTargetUserProvider.targetUserType',
    'teammateStartConversationButtonTextForUsers',
    'teammateStartConversationButtonTextForVisitors',
  )
  teammateStartConversationButtonText?: string;

  // @ts-ignore
  @userTypeComputedProperty(
    'messengerSettingsTargetUserProvider.targetUserType',
    'finStartConversationButtonTextForUsers',
    'finStartConversationButtonTextForVisitors',
  )
  finStartConversationButtonText?: string;

  // @ts-ignore
  @userTypeComputedProperty(
    'messengerSettingsTargetUserProvider.targetUserType',
    'setExpectationsForUsers',
    'setExpectationsForVisitors',
  )
  setExpectations?: string;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
