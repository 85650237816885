/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr } from '@ember-data/model';

export default class AutoAwaySettingModel extends Model {
  @attr('number') appId;
  @attr('boolean') isActive;
  @attr('boolean') initialInactivityThresholdActive;
  @attr('number') initialInactivityThreshold;
  @attr('boolean') followingInactivityThresholdActive;
  @attr('number') followingInactivityThreshold;
  @attr('date') createdAt;
  @attr('date') updatedAt;
}
