/* RESPONSIBLE TEAM: team-data-interop */
import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { TRIGGER_PREDICATE_PREFIX } from 'embercom/lib/workflows/constants/webhook_received';
import type InboundTrigger from './inbound-trigger';

export default class AttributeDescriptor extends Model {
  @service declare appService: any;

  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('string') declare dataType: string;
  @attr('string') declare inputOptions: string;
  @attr('boolean') declare required: boolean;
  @attr('string') declare webhookPayloadPath: string;

  @belongsTo('workflow-connector/inbound-trigger', { async: false })
  declare inboundTrigger: InboundTrigger;

  get attributeIdentifier() {
    return `${TRIGGER_PREDICATE_PREFIX}.data.${this.id}`;
  }
}
