/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  name: attr(),
  human_friendly_name: attr(),
  created_at: attr(),
  description: attr(),
  selector: attr(),
  sourceUrl: attr(),
  eventTrigger: attr(),
  urlRegex: attr(),
  hasDynamicSegment: computed('urlRegex', function () {
    return this.urlRegex.includes('(.*)');
  }),
});
