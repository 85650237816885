/* RESPONSIBLE TEAM: team-product-setup */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const NATURE_ICONS = [
  'sparkles',
  'fire',
  'bug-ant',
  'bolt',
  'star',
  'sun',
  'cloud',
  'moon',
  'heart',
];

const natureCommonKeywords = ['nature', 'symbolism'];

export const NATURE_KEYWORDS = {
  [NATURE_ICONS[0]]: [
    ...natureCommonKeywords,
    'sparkles',
    'glitter',
    'shine',
    'magic',
    'celebration',
  ],
  [NATURE_ICONS[1]]: [...natureCommonKeywords, 'fire', 'flame', 'heat', 'burn', 'ignite'],
  [NATURE_ICONS[2]]: [...natureCommonKeywords, 'bug', 'ant', 'insect', 'creature', 'pest'],
  [NATURE_ICONS[3]]: [
    ...natureCommonKeywords,
    'bolt',
    'lightning',
    'electricity',
    'storm',
    'power',
  ],
  [NATURE_ICONS[4]]: [...natureCommonKeywords, 'star', 'twinkle', 'celebration', 'shine'],
  [NATURE_ICONS[5]]: [...natureCommonKeywords, 'sun', 'solar', 'brightness', 'daylight', 'heat'],
  [NATURE_ICONS[6]]: [...natureCommonKeywords, 'cloud', 'weather', 'sky', 'forecast'],
  [NATURE_ICONS[7]]: [...natureCommonKeywords, 'moon', 'lunar', 'night'],
  [NATURE_ICONS[8]]: [...natureCommonKeywords, 'heart', 'love', 'affection', 'emotion', 'romance'],
};
