/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { selectFromObject } from '@intercom/pulse/lib/computed-properties';

export default Mixin.create({
  _inAppIconMap: {
    chat: 'chat-bubble',
    post: 'inapp-post',
    note: 'inapp-note',
    video: 'video',
    pointer: 'inapp-pointer',
  },
  _iconForInApp: selectFromObject('_inAppIconMap', 'messageStyleName'),

  typeIcon: computed('message.type', '_iconForInApp', function () {
    if (this.isEmail) {
      return 'email';
    } else if (this.isMobilePush) {
      return 'push-notification';
    } else {
      return this._iconForInApp;
    }
  }),
});
