/* RESPONSIBLE TEAM: team-help-desk-experience */
import { modifier as buildModifier } from 'ember-modifier';
import { run } from '@ember/runloop';

function scrollIntoView(el: Element) {
  if (el === undefined) {
    return;
  }

  run(() => {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  });
}

let modifier = buildModifier<{
  Element: HTMLElement;
  Args: {
    Positional: [boolean];
  };
}>(
  (element, [active]) => {
    if (active === false) {
      return;
    }
    scrollIntoView(element);
  },
  { eager: false },
);

export default modifier;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'scroll-to-element-nearest': typeof modifier;
  }
}
