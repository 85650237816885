/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';

export default class GroupedStat extends Model {
  @hasMany('stats-system/base-stat', {
    polymorphic: true,
    async: false,
  })
  stats;
  @fragment('messages/recipient') user;
  @belongsTo('user') userModel;
  @belongsTo('company') company;
  @attr('date') createdAt;
}
