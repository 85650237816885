/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';

interface TitleChangedWireFormat {
  renderable_type: RenderableType;
  admin_summary: AdminSummaryWireFormat;
  title: string;
  previous_title: string;
}

export default class TitleChanged {
  readonly renderableType: RenderableType = RenderableType.TitleChanged;
  readonly adminSummary: AdminSummary;
  readonly title: string;
  readonly previousTitle: string;

  get creatingEntity() {
    return { type: EntityType.Admin, id: this.adminSummary.id, object: this.adminSummary };
  }

  constructor(adminSummary: AdminSummary, title: string, previousTitle: string) {
    this.title = title;
    this.adminSummary = adminSummary;
    this.previousTitle = previousTitle;
  }

  static deserialize(json: TitleChangedWireFormat): TitleChanged {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    return new TitleChanged(adminSummary, json.title, json.previous_title);
  }
}
