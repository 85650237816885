/* RESPONSIBLE TEAM: team-customer-data-platform */
import { tracked } from '@glimmer/tracking';
import Model from '@ember-data/model';
import { EntityType } from 'embercom/models/data/entity-types';

export default class FinPersistedDataContextAttribute extends Model {
  @tracked declare aiContentSegmentIds: number[];
  @tracked declare entityId: number;
  @tracked declare isFinLive: boolean;
  readonly entityType: EntityType = EntityType.FinPersistedDataAttribute;

  constructor(entityId: number, isFinLive: boolean, aiContentSegmentIds?: number[]) {
    super();
    this.entityId = entityId;
    this.isFinLive = isFinLive;
    this.aiContentSegmentIds = aiContentSegmentIds ?? [];
  }
}
