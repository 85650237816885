/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
/* eslint-disable jquery-unsafe/no-html */
/* RESPONSIBLE TEAM: team-frontend-tech */

import $ from 'jquery';
const TRUNCATED_SUFFIX = '…';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default function (string, length, notHtml) {
  if (!notHtml) {
    string = $('<div />').html(sanitizeHtml(string).string).text();
  }
  string = string.trim();
  if (string.length <= length) {
    return string;
  }
  return string.substring(0, length).trim() + TRUNCATED_SUFFIX;
}
