/* RESPONSIBLE TEAM: team-help-desk-experience */
import { run } from '@ember/runloop';
import { elementIsVisibleOnScreen } from 'embercom/lib/screen-awareness';

export default function (el, options = { alignToTop: true }) {
  if (el === undefined) {
    return;
  }
  el = el.jquery === undefined ? el : el.get(0);
  if (!elementIsVisibleOnScreen(el)) {
    run(() => {
      if (options['alignToTop'] !== undefined) {
        el.scrollIntoView(options.alignToTop);
      } else {
        el.scrollIntoView(options);
      }
    });
  }
}
