/* RESPONSIBLE TEAM: team-frontend-tech */
const DEFAULT_TTL = 1000;

export default class TTLCache {
  constructor() {
    this._keymap = {};
    this._timeoutMap = {};
  }

  remove(key) {
    delete this._keymap[key];
    if (this._timeoutMap[key]) {
      clearTimeout(this._timeoutMap[key]);
      delete this._timeoutMap[key];
    }
  }

  set(key, value, ttl = DEFAULT_TTL) {
    this.remove(key);
    let entry = { key, value };
    this._keymap[key] = entry;

    this._timeoutMap[key] = setTimeout(() => {
      delete this._keymap[key];
      delete this._timeoutMap[key];
    }, ttl);
  }

  get(key, returnEntry) {
    let entry = this._keymap[key];

    if (entry === undefined) {
      return undefined;
    }
    return returnEntry ? entry : entry.value;
  }

  removeAll() {
    Object.keys(this._keymap).forEach((key) => {
      this.remove(key);
    });
  }

  keys() {
    return Object.keys(this._keymap);
  }

  has(key) {
    return this._keymap[key] !== undefined;
  }
}
