/* RESPONSIBLE TEAM: team-knowledge-interop */

import { inject as service } from '@ember/service';
import { type Block, type UnsupportedElement } from '@intercom/interblocks.ts';
import Model, { attr, belongsTo } from '@ember-data/model';
import type Admin from 'embercom/models/admin';
import type IntlService from 'embercom/services/intl';
import { type LocalizedKnowledgeContentVersion } from 'embercom/objects/knowledge-hub/localized-knowledge-content-version';
import type InternalArticle from './internal-article';

export default class InternalArticleVersion
  extends Model
  implements LocalizedKnowledgeContentVersion
{
  @service appService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  @attr('string') declare authorId: string;
  @attr('string') declare title: string;
  @attr('array')
  declare jsonBlocks: Array<Block>;
  @attr('array')
  declare jsonBlocksForEditor: Array<Block>;
  @belongsTo('content-service/internal-article', { inverse: 'currentVersion', async: false })
  declare article: InternalArticle;

  get author() {
    return this.app.humanAdmins.findBy('id', this.authorId);
  }

  set author(newAuthor: Admin) {
    this.authorId = newAuthor.id;
  }

  get localisedJsonBlocks() {
    if (this.appService.app.canUseFeature('group-ps-handle-unsupported-elements')) {
      return this.jsonBlocksForEditor.map((block) => {
        if (block.type === 'unsupportedElement') {
          block = block as UnsupportedElement;
          block.text = this.intl.t(
            `blocks.unsupported-element.${block.category || 'unsupported'}`,
            {
              blockTypeName: block.unsupportedTypeName,
            },
          );
        }
        return block;
      });
    }
    return this.jsonBlocks;
  }

  get needsReview() {
    return this.jsonBlocksForEditor.some((block) => block.type === 'unsupportedElement');
  }

  get localization() {
    return this.article;
  }

  get versionTitle(): string | undefined {
    return this.title;
  }

  set versionTitle(value: string | undefined) {
    this.title = value ?? '';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'content-service/internal-article-version': InternalArticleVersion;
  }
}
