/* RESPONSIBLE TEAM: team-proactive-support */
export const defaultEmailPredicates = {
  predicates: [
    {
      type: 'and',
      predicates: [
        {
          attribute: 'last_contacted_at',
          type: 'date',
          comparison: 'lt',
          value: '2',
        },
        {
          attribute: 'last_request_at',
          type: 'date',
          comparison: 'gt',
          value: '90',
        },
      ],
    },
  ],
};
