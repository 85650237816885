/* RESPONSIBLE TEAM: team-phone */
import type PhoneHoldAndAssign from 'embercom/models/operator/visual-builder/step/phone-hold-and-assign';
import StepConfig, { type StepConfigParams } from '../step';

export default class PhoneHoldAndAssignConfiguration extends StepConfig {
  declare step: PhoneHoldAndAssign;

  constructor(params: Omit<StepConfigParams, 'component'> & { component?: string }) {
    super({
      component: 'workflows/graph-editor/node-items/steps/phone-hold-and-assign',
      ...params,
    });
  }

  get alignment() {
    return 'left' as const;
  }
}
