/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { or } from '@ember/object/computed';
import UserAvatarData from 'embercom/models/mixins/user-avatar-data';
import ajax from 'embercom/lib/ajax';
import { computed } from '@ember/object';

const REJECTION_REASON_VALUES = {
  spam: 'Junk',
  auto_responder: 'Auto-responder',
};

export default Model.extend(UserAvatarData, {
  appId: attr('string'),
  name: attr('string'),
  email: attr('string'),
  emailSubject: attr('string'),
  rejectedReason: attr('string'),
  avatar: attr(),
  isProcessing: false,
  isDeleted: false,
  createdAt: attr('date'),
  nameOrEmail: or('name', 'email'),
  rejectionReasonLabel: computed('rejectedReason', function () {
    return REJECTION_REASON_VALUES[this.rejectedReason] || 'N/A';
  }),
  processSpamMessage() {
    return ajax({
      url: '/ember/spam_messages/process_email',
      data: JSON.stringify({
        id: this.id,
        app_id: this.appId,
      }),
      type: 'POST',
      contentType: 'application/json',
      dataType: 'JSON',
    });
  },
});
