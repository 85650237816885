/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import UserSummary, { type UserSummaryWireFormat } from '../user-summary';
import CustomBotSummary, { type CustomBotSummaryWireFormat } from '../custom-bot-summary';

export type AttributeSummary = {
  identifier: string;
  name: string;
};

interface AttributeCollectedWireFormat {
  renderable_type: RenderableType;
  user_summary: UserSummaryWireFormat;
  attribute_summary: AttributeSummary;
  value: any;
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

export default class AttributeCollected implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.AttributeCollected;
  readonly attributeSummary: AttributeSummary;
  readonly value: any;
  readonly userSummary: UserSummary;
  readonly customBotSummary?: CustomBotSummary;

  get creatingEntity() {
    return { type: EntityType.User, id: this.userSummary.id, object: this.userSummary };
  }

  constructor(
    userSummary: UserSummary,
    attributeSummary: AttributeSummary,
    value: any,
    customBotSummary?: CustomBotSummary,
  ) {
    this.userSummary = userSummary;
    this.attributeSummary = attributeSummary;
    this.value = value;
    this.customBotSummary = customBotSummary;
  }

  static deserialize(json: AttributeCollectedWireFormat): AttributeCollected {
    let userSummary = UserSummary.deserialize(json.user_summary);
    return new AttributeCollected(
      userSummary,
      json.attribute_summary,
      json.value,
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
