/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import StepConfig, { type StepConfigParams } from '../step';
import containerLookup from 'embercom/lib/container-lookup';
import { tracked } from '@glimmer/tracking';
import { type SyncHasMany } from '@ember-data/model';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type ReplyButtons from 'embercom/models/operator/visual-builder/step/reply-buttons';

export default class ReplyButtonsConfiguration extends StepConfig {
  declare step: ReplyButtons;

  @tracked buttons: SyncHasMany<ConnectionPoint>;

  isDisableComposerSupported: boolean;
  intercomEventService: any;
  appService: any;
  isStartOverButtonSupported: boolean;
  allowEmptyBlocks: boolean;

  constructor(params: Omit<StepConfigParams, 'component'> & { component?: string }) {
    super({
      component: 'workflows/graph-editor/node-items/steps/reply-buttons',
      ...params,
    });

    this.buttons = this.step.outwardConnectionPoints;
    this.isDisableComposerSupported = false;
    this.allowEmptyBlocks = true;
    this.intercomEventService = containerLookup('service:intercomEventService');
    this.appService = containerLookup('service:appService');
    this.isStartOverButtonSupported = !params.pathConfig.path.isStart;
  }

  get stepMenuConfigOptions() {
    // reply buttons do not have a step-level config
    return null;
  }

  get attributeCollectorMenuConfigOptions() {
    return [this.deleteAttributeCollectorOption];
  }

  get disabledComposerMenuConfigOptions() {
    return [] as {
      icon: string;
      text: string;
      onSelectItem: () => void;
    }[];
  }

  get buttonConfigMenuOptions() {
    return [this.deleteButtonOption];
  }

  // attributeCollectorMenuConfig - Delete Option
  get deleteAttributeCollectorOption() {
    return {
      icon: 'trash',
      text: this.intl.t('operator.workflows.visual-builder.step-option-menu.delete'),
      onSelectItem: () => {
        this.step.attributeIdentifier = undefined;
        this.step.shouldCollectAttribute = false;
      },
    };
  }

  // buttonMenuConfig - Delete Option
  get deleteButtonOption() {
    return {
      icon: 'trash',
      text: this.intl.t('operator.workflows.visual-builder.step-option-menu.delete'),
      isDisabled: !this.canDeleteButton,
      tooltipText: this.deleteTooltipText,
      onSelectItem: ({ button }: { button: ConnectionPoint }) => {
        return this.deleteButton(button);
      },
    };
  }

  get canDeleteButton() {
    if ((this.buttons.length as number) > 1) {
      return true;
    }

    return this.canDeleteStep;
  }

  deleteButton(button: ConnectionPoint) {
    if (button.type === 'capture_composer_input') {
      this.intercomEventService.trackAnalyticsEvent({ object: button, action: 'removed' });

      if (button.edge?.toGroup.isPlaceholder) {
        this.editorState.deleteGroup(button.edge.toGroup);
      }
      this.buttons.removeObject(button);
      this.editorState.markAsEdited();
      this.editorState.logInteraction(`Disabled composer`);

      return;
    }

    if ((this.buttons.length as number) > 1) {
      this.intercomEventService.trackAnalyticsEvent({ object: button, action: 'removed' });
      this.buttons.removeObject(button);
      this.editorState.markAsEdited();
      this.editorState.logInteraction(`Deleted reply button`);
    } else {
      this.editorState.logInteraction(`Deleting last reply button`);
      this.deleteStep();
    }
  }
}

export class InboundBotReplyButtonsConfiguration extends ReplyButtonsConfiguration {
  constructor(params: Omit<StepConfigParams, 'component'>) {
    super(params);
    this.isDisableComposerSupported = params.pathConfig.path.isStart;
  }

  get disabledComposerMenuConfigOptions() {
    return [this.deleteDisabledComposerOption];
  }

  // disabledComposerMenuConfig - Delete Option
  get deleteDisabledComposerOption() {
    return {
      icon: 'trash',
      text: this.intl.t('operator.workflows.visual-builder.step-option-menu.delete'),
      onSelectItem: () => {
        this.step.isComposerDisabled = false;
      },
    };
  }

  get deleteTooltipText() {
    return !this.canDeleteButton
      ? this.intl.t('operator.workflows.visual-builder.step-option-menu.delete-disabled-tooltip')
      : undefined;
  }

  get canDeleteButton() {
    if (this.pathConfig.path.isStart && this.buttons.length === 1) {
      return false;
    }

    return super.canDeleteButton;
  }
}

export class OutboundBotReplyButtonsConfiguration extends ReplyButtonsConfiguration {
  constructor(args: any) {
    super(args);
  }

  get deleteTooltipText() {
    return !this.canDeleteButton
      ? this.intl.t('operator.workflows.visual-builder.step-option-menu.delete-disabled-tooltip')
      : undefined;
  }
}

export class CustomAnswerReplyButtonsConfiguration extends ReplyButtonsConfiguration {
  constructor(params: Omit<StepConfigParams, 'component'>) {
    super(params);
    this.isStartOverButtonSupported = false;
  }
}

export class ProfileHandoverReplyButtonsConfiguration extends ReplyButtonsConfiguration {
  constructor(params: Omit<StepConfigParams, 'component'>) {
    super(params);
    this.isStartOverButtonSupported = false;
  }
}

export class ReusableBotReplyButtonsConfiguration extends ReplyButtonsConfiguration {
  constructor(params: Omit<StepConfigParams, 'component'>) {
    super(params);
    this.isStartOverButtonSupported = true;
  }
}

export class PhoneTriggerReplyButtonsConfiguration extends ReplyButtonsConfiguration {
  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      ...params,
      component: 'workflows/graph-editor/node-items/steps/phone-reply-buttons',
    });
    this.allowEmptyBlocks = false;
  }
}
