/* RESPONSIBLE TEAM: team-phone */

import Model, { attr } from '@ember-data/model';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export enum PhoneNumberStatus {
  Active = 'active',
  Porting = 'porting',
  MissingBundle = 'missing_bundle',
}

export const COUNTRIES_WITH_STATE = 'US,DE,AU,AT,BR,IN,MY,MX,FM,MM,NZ,NG,PW,SS';

export default class PhoneNumber extends Model {
  @service declare appService: any;

  @attr('string') declare name: string;
  @attr('string') declare phoneNumber: string;
  @attr('string') declare countryIsoCode: string;
  @attr('number') declare phoneType: number;
  @attr('string') declare status: PhoneNumberStatus;
  @attr('boolean') declare inboundEnabled: boolean;
  @attr('boolean') declare outboundEnabled: boolean;
  @attr('boolean') declare inboundCapable: boolean;
  @attr('boolean') declare outboundCapable: boolean;
  @attr('string') declare welcomeMessage: string;
  @attr('string') declare routingMethod: string;
  @attr('string') declare assignTeamId: string;
  @attr('string') declare assignAdminId: string;
  @attr('boolean') declare voicemailDeflectionEnabled: boolean;
  @attr('string') declare voicemailDeflectionGreetingMessage: string;
  @attr('boolean') declare voicemailOooTriggerEnabled: boolean;
  @attr('boolean') declare voicemailNoTeammatesAvailableTriggerEnabled: boolean;
  @attr('boolean') declare voicemailMaxQueueSizeTriggerEnabled: boolean;
  @attr('number') declare voicemailMaxQueueSize: number;
  @attr('number') declare portingTicketId: number;
  @attr('string') declare regulatoryBundleSid: string;
  @attr('date') declare freeUsageExpiredAt: Date;

  get formattedPhoneNumber() {
    return parsePhoneNumberFromString(this.phoneNumber)?.formatInternational();
  }

  get assignTeam() {
    let teams = new Map(
      this.appService.app.assignableTeams.map((team: any) => [team.get('id'), team]),
    );
    return teams.get(this.assignTeamId);
  }

  get assignAdmin() {
    return this.appService.app.admins.findBy('id', this.assignAdminId);
  }

  get isActive() {
    return [PhoneNumberStatus.MissingBundle, PhoneNumberStatus.Active].includes(this.status);
  }

  get isPorting() {
    return this.status === PhoneNumberStatus.Porting;
  }

  get isActiveFreeUsageNumber() {
    return this.isActive && this.freeUsageExpiredAt && this.freeUsageExpiredAt > new Date();
  }

  get freeUsageDaysLeft() {
    if (this.freeUsageExpiredAt) {
      let daysLeft = Math.ceil(moment(this.freeUsageExpiredAt).diff(moment(), 'days', true));
      return daysLeft;
    }
    return 0;
  }
}
