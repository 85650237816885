/* RESPONSIBLE TEAM: team-tickets-1 */

import { attr } from '@ember-data/model';
import HelpCenterBlock from './help-center-block';

export default class TicketsPortalLink extends HelpCenterBlock {
  @attr('string') declare type: 'tickets-portal-link';
  @attr('boolean') declare enabled: boolean;

  get title(): string {
    return this.intl.t('articles.settings.stylings.layout-and-sections.tickets-portal.header');
  }

  get editable(): boolean {
    return false;
  }

  preview(_locale: string) {
    if (!this.enabled) {
      return null;
    }

    return {
      type: this.type,
      enabled: this.enabled,
    };
  }
}
