/* RESPONSIBLE TEAM: team-phone */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import Fragment from 'ember-data-model-fragments/fragment';

export default Fragment.extend({
  providerName: attr('string'),
  listId: attr('string'),
  lists: attr('array'),
  title: attr('string'),
  description: attr('string'),
  confirmationTitle: attr('string'),
  confirmationDescription: attr('string'),
  rewardType: attr('string'),
  rewardText: attr('string'),
  installationStatus: attr('string'),
  analyticsData: computed('id', function () {
    return {
      object: 'mailchimp_settings',
      mailchimp_settings_id: this.id,
    };
  }),
});
