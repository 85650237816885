/* RESPONSIBLE TEAM: team-workflows */

import { type SyncHasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type ApplyRules from 'embercom/models/operator/visual-builder/step/apply-rules';
import StepConfig, { type StepConfigParams } from '../step';

export default class ApplyRulesConfiguration extends StepConfig {
  declare step: ApplyRules;

  @tracked rules: SyncHasMany<any>;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/apply-rules',
      ...params,
    });
    this.rules = this.step.followUpRules;
  }

  get ruleConfigMenuOptions() {
    return [this.deleteRuleOption];
  }

  get deleteRuleOption() {
    return {
      icon: 'trash',
      text: this.intl.t('operator.workflows.visual-builder.step-option-menu.delete'),
      isDisabled: false,
      onSelectItem: (item: { rule: any }) => {
        this.editorState.markAsEdited();
        return this.deleteRule(item.rule);
      },
    };
  }

  deleteRule(rule: any) {
    this.rules.removeObject(rule);
  }
}
