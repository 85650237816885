/* RESPONSIBLE TEAM: team-customer-data-platform */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class AttributeMapping extends Fragment {
  @attr('string') _id;
  @attr('string') appIdCode;
  @attr('string') destinationAttributePath;
  @attr('string') destinationObjectType;
  @attr('boolean') editable;
  @attr('string') integrationCode;
  @attr('number', { defaultValue: 0 }) direction;
  @attr('string') sourceAttributePath;
  @attr('string') sourceObjectType;
  @attr('string') sourceType;
  @attr('boolean') disableEmptySourceSync;
  @attr('boolean') disableNonEmptyDestinationSync;
  @attr('date') updatedAt;

  @attr('string') status;
  @attr('string', { defaultValue: 'public' }) mappingVisibility;
  @attr('string', { defaultValue: 'default' }) sourceAttributeType;

  isEqual(other) {
    return (
      other.destinationAttributePath === this.destinationAttributePath &&
      other.sourceAttributePath === this.sourceAttributePath &&
      other.direction === this.direction
    );
  }
}
