/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import { dependentKeyCompat } from '@ember/object/compat';

const emptyPredicateGroup = { predicates: [] };
export default class ContentServicePath extends Model {
  @attr('number') contentType;
  @attr('string') contentId;
  @attr('number') stepType;
  @attr('string') stepId;
  @attr('string') nextStepId;
  @attr('number') order;
  @fragment('predicates/predicate-group', {
    defaultValue: () => emptyPredicateGroup,
  })
  predicateGroup;

  // Used to link together steps that haven't been persisted yet and therefore don't have a database ID
  @attr('string') stepUuid;
  @attr('string') nextStepUuid;

  @dependentKeyCompat
  get hasValidPredicates() {
    return this.predicateGroup.isValid;
  }

  @dependentKeyCompat
  get hasPredicates() {
    return this.predicateGroup.hasPredicates;
  }
}
