/* RESPONSIBLE TEAM: team-tickets-1 */
import type { TriggerPredicates } from './custom-bot';
import { TargetContext } from './custom-bot';
import { TRIGGER_PREDICATE_PREFIX } from 'embercom/lib/workflows/constants/webhook_received';

const TARGET_CONTEXT_VALUES = {
  conversation: 'conversation',
  customerTicket: 'customer_ticket',
  backOfficeTicket: 'back_office_ticket',
  trackerTicket: 'tracker_ticket',
} as const;

const TRIGGER_PREDICATE_OPTIONS = {
  conversation: {
    icon: 'chat-filled',
    iconSet: 'standard',
    value: TARGET_CONTEXT_VALUES.conversation,
    translationKey: 'operator.custom-bot.trigger.conversations',
    predicate: {
      attribute: `${TRIGGER_PREDICATE_PREFIX}.target_context`,
      type: 'string',
      comparison: 'eq',
      value: TARGET_CONTEXT_VALUES.conversation,
    },
  },
  customerTicket: {
    icon: 'ticket',
    iconSet: 'standard',
    value: TARGET_CONTEXT_VALUES.customerTicket,
    translationKey: 'operator.custom-bot.trigger.ticket-types.customer-facing',
    tooltipTranslationKey:
      'operator.custom-bot.trigger.ticket-types.info-description.customer-ticket',
    predicate: {
      attribute: `${TRIGGER_PREDICATE_PREFIX}.target_context`,
      type: 'string',
      comparison: 'eq',
      value: TARGET_CONTEXT_VALUES.customerTicket,
    },
  },
  backOfficeTicket: {
    icon: 'back-office',
    iconSet: 'standard',
    value: TARGET_CONTEXT_VALUES.backOfficeTicket,
    translationKey: 'operator.custom-bot.trigger.ticket-types.back-office',
    tooltipTranslationKey: 'operator.custom-bot.trigger.ticket-types.info-description.back-office',
    predicate: {
      attribute: `${TRIGGER_PREDICATE_PREFIX}.target_context`,
      type: 'string',
      comparison: 'eq',
      value: TARGET_CONTEXT_VALUES.backOfficeTicket,
    },
  },
  trackerTicket: {
    icon: 'tracker',
    iconSet: 'standard',
    value: TARGET_CONTEXT_VALUES.trackerTicket,
    translationKey: 'operator.custom-bot.trigger.ticket-types.tracker',
    tooltipTranslationKey: 'operator.custom-bot.trigger.ticket-types.info-description.tracker',
    predicate: {
      attribute: `${TRIGGER_PREDICATE_PREFIX}.target_context`,
      type: 'string',
      comparison: 'eq',
      value: TARGET_CONTEXT_VALUES.trackerTicket,
    },
  },
} as const;

export const ADMIN_ADDS_NOTE_PREDICATES: TriggerPredicates = {
  type: 'or',
  options: [
    TRIGGER_PREDICATE_OPTIONS.conversation,
    TRIGGER_PREDICATE_OPTIONS.customerTicket,
    TRIGGER_PREDICATE_OPTIONS.backOfficeTicket,
    TRIGGER_PREDICATE_OPTIONS.trackerTicket,
  ],
};

export const TICKET_TRIGGER_PREDICATES: TriggerPredicates = {
  type: 'or',
  options: [
    TRIGGER_PREDICATE_OPTIONS.customerTicket,
    TRIGGER_PREDICATE_OPTIONS.backOfficeTicket,
    TRIGGER_PREDICATE_OPTIONS.trackerTicket,
  ],
};

const TARGET_CONTEXT_MAP = new Map<string, TargetContext>([
  [TARGET_CONTEXT_VALUES.conversation, TargetContext.Conversation],
  [TARGET_CONTEXT_VALUES.customerTicket, TargetContext.CustomerTicket],
  [TARGET_CONTEXT_VALUES.backOfficeTicket, TargetContext.BackOfficeTicket],
  [TARGET_CONTEXT_VALUES.trackerTicket, TargetContext.TrackerTicket],
]);

export function computeSelectedTargetContexts(triggerPredicateGroup: any): TargetContext[] {
  let selectedContexts: TargetContext[] = [];

  let selectedPredicates = triggerPredicateGroup.predicates?.firstObject?.predicates || [];

  selectedPredicates.forEach((predicate: any) => {
    let value = predicate.value;
    let targetContext = TARGET_CONTEXT_MAP.get(value);
    if (targetContext !== undefined) {
      selectedContexts.push(targetContext);
    }
  });

  return selectedContexts;
}
