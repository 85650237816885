/* RESPONSIBLE TEAM: team-data-interop */

import EditorConfig, {
  type AttributeGroupList,
} from 'embercom/objects/visual-builder/configuration/editor';
import type {
  AttributeGroup,
  PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import { AiAgentWorkflowPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type InsertableAction from 'embercom/models/workflow-connector/insertable-action';
import type InsertableActionField from 'embercom/models/workflow-connector/insertable-action-field';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type MutableArray from '@ember/array/mutable';

export default class AiAgentWorkflowEditorConfig extends EditorConfig {
  @service declare intl: IntlService;
  public actions: any;
  public workflow: Workflow;
  public targetChannels: MutableArray<string>;

  constructor({
    areStepPaywallsActive,
    actions,
    workflow,
    targetChannels,
  }: {
    areStepPaywallsActive?: boolean;
    actions?: any;
    workflow: Workflow;
    targetChannels: MutableArray<string>;
  }) {
    super({ areStepPaywallsActive });
    this.actions = actions;
    this.workflow = workflow;
    this.targetChannels = targetChannels;
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new AiAgentWorkflowPathConfig({ path, editorState });
  }

  get showGoalsPanel(): boolean {
    return false;
  }

  get actionResponseFieldAttributes(): AttributeGroupList {
    return this.actions?.map((action: InsertableAction) => {
      return {
        heading: action.name,
        attributes: action.responseFields.map((field: InsertableActionField) => {
          return {
            identifier: `operator_workflow_trigger.data.action_id_${action.id}.${field.identifier}`,
            templatable_identifier: `operator_workflow_trigger.data.action_id_${action.id}.${field.identifier}`,
            composer_name: `${action.name} > ${field.name}`,
            name: field.name,
            type: field.type,
            icon: field.icon,
          };
        }),
      };
    });
  }

  get localAttributes(): Array<any> {
    return this.workflow.attributeDescriptors.toArray();
  }

  get localAttributeGroupList(): AttributeGroupList {
    return [
      {
        heading: this.intl.t(
          'operator.workflows.visual-builder.attribute-collector.local-variables',
        ),
        attributes: this.localAttributes,
      },
      ...this.actionResponseFieldAttributes.map((attributeGroup: AttributeGroup) => {
        return {
          heading: attributeGroup.heading,
          attributes: attributeGroup.attributes.map((attribute: any) => {
            return {
              ...attribute,
              referenceOnly: false,
            };
          }),
        };
      }),
    ];
  }

  get runtimeMatchingTargetingAttributes(): AttributeGroupList {
    let groups = [
      ...this.localAttributeGroupList,
      ...this.attributeService.visualBotBuilderConditionalAttributesGroupList,
    ];

    return groups.sort((a, b) => {
      if (!a.heading && b.heading) {
        return -1;
      }
      if (a.heading && !b.heading) {
        return 1;
      }
      return 0;
    });
  }
}
