/* RESPONSIBLE TEAM: team-phone */
import EmberObject from '@ember/object';
import Evented from '@ember/object/evented';
import moment from 'moment-timezone';

const PHONE_CALL_STATE_CHANGE = 'phone_call_state_change';
const PHONE_NUMBER_CONNECTION_TYPE = 'phone_number';

export enum AmazonCtiPhoneCallState {
  None = 'none',
  Started = 'started',
  Accepted = 'accepted',
  Finished = 'finished',
}

class AmazonCtiPhoneCallData {
  constructor(public conversationId: string) {}
}

export class AmazonCtiPhoneCall extends EmberObject.extend(Evented) {
  amazonContact: any;
  amazonConnection: any;
  amazonEndpoint: any;
  phoneNumber: string;
  callState: AmazonCtiPhoneCallState = AmazonCtiPhoneCallState.None;
  callData: AmazonCtiPhoneCallData | object;
  startTime: moment.Moment | null = null;
  endTime: moment.Moment | null = null;

  get duration() {
    if (this.startTime && this.endTime) {
      return moment
        .duration(this.endTime.valueOf() - this.startTime.valueOf(), 'milliseconds')
        .seconds();
    }

    return null;
  }

  constructor(
    contact: any,
    state: AmazonCtiPhoneCallState = AmazonCtiPhoneCallState.None,
    data: AmazonCtiPhoneCallData | null = null,
  ) {
    super(...arguments);

    this.callState = state;
    this.amazonContact = contact;
    this.callData = data || {};

    // when phone call happens, there are two connections - one with type 'agent' and another with type 'phone_call'
    // we need to grab the second one because it has call information like the phone number
    let phoneCallData = this.findPhoneCallData(contact.getConnections());
    if (!phoneCallData) {
      throw new Error(`No phone call found for contact ${contact.getContactId()}`);
    }

    this.amazonConnection = phoneCallData.connection;
    this.amazonEndpoint = phoneCallData.endpoint;

    // there should be a phone call connection because we trust the caller to create the phone call state
    // when the phone call starts. I.e. the check whether amazonConnection exists is not needed.

    this.phoneNumber = this.amazonEndpoint.phoneNumber;

    // ------- handling events --------
    this.amazonContact.onConnected(() => {
      let newState = AmazonCtiPhoneCallState.Accepted;

      this.startTime = moment.utc();
      this.trigger(PHONE_CALL_STATE_CHANGE, this.callState, newState);
      this.callState = newState;
    });

    this.amazonContact.onAccepted(() => {
      // Leaving this as a stub in case we'll need handling this event in the future.
      // Experiments showed that amazon-connect-streams library calls 'onConnected'
      // handler for outbound and inbound cases, but 'onAccepted' only for inbound phone calls.
      // Therefore, it makes sense to treat 'onConnected' as 'onAccepted'
    });

    this.amazonContact.onEnded(() => {
      let newState = AmazonCtiPhoneCallState.Finished;

      this.endTime = moment.utc();
      this.trigger(PHONE_CALL_STATE_CHANGE, this.callState, newState);
      this.callState = newState;
    });
  }

  onStateChange(fn: (oldState: string, newState: string) => void) {
    this.on(PHONE_CALL_STATE_CHANGE, fn);
  }

  private findPhoneCallData(connections: any[]) {
    return connections
      .map((c) => ({ endpoint: c.getAddress(), connection: c }))
      .find((c: any) => c.endpoint.type === PHONE_NUMBER_CONNECTION_TYPE);
  }
}
