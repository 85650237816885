/* RESPONSIBLE TEAM: team-ai-agent */
import Fragment from 'ember-data-model-fragments/fragment';
import { type SyncHasMany, attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { action } from '@ember/object';
import Validations from 'embercom/validations/operator/bot-intro/localization';
import type BotIntroPart from 'embercom/models/operator/bot-intro/part';

export default class Localization extends Fragment.extend(Validations) {
  @attr('boolean') declare default: boolean;
  @attr('boolean') declare usingDefault: boolean;
  @attr('boolean') declare enabled: boolean;
  @attr('string') declare locale: string;
  @attr('string') declare name: string;
  @fragmentArray('operator/bot-intro/part') declare parts: SyncHasMany<BotIntroPart>;
  @fragmentArray('operator/bot-intro/part') declare defaultParts: SyncHasMany<BotIntroPart>;

  @action
  resetToDefault() {
    this.parts = this.defaultParts.copy();
    this.usingDefault = true;
  }

  get hasChanges() {
    return this.hasDirtyAttributes || this.parts.hasDirtyAttributes;
  }
}
