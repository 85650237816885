/* RESPONSIBLE TEAM: team-knowledge-interop */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import type Store from '@ember-data/store';

export default class ArticleSyncSetting extends Model {
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  @attr('string') declare provider: string;
  @attr('string') declare status: string;
  @attr('number') declare period: number;
  @attr('date') declare lastSuccessfulSyncedAt: Date;
  @attr('string') declare error: string;
  @attr('string') declare siteUrl: string;

  get app() {
    return this.appService.app;
  }

  async triggerManualSync(): Promise<void> {
    let response = await post(`/ember/article_sync_settings/${this.id}/trigger_sync`, {
      app_id: this.app.id,
    });
    this.store.pushPayload({ 'articles/article-sync-setting': response });
  }

  async pause(): Promise<void> {
    let response = await post(`/ember/article_sync_settings/${this.id}/pause`, {
      app_id: this.app.id,
    });
    this.store.pushPayload({ 'articles/article-sync-setting': response });
  }
}
