/* RESPONSIBLE TEAM: team-help-desk-experience */
import TeamSummary, { type TeamSummaryWireFormat } from '../team-summary';
import type Inbox from './inbox';
import { INBOX_DEFAULT_SORT_ORDER } from './inbox';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

export type TeamInboxWireFormat = {
  inbox_type: InboxType.Team;
  count: number;
  sort_order: number;
  team_summary: TeamSummaryWireFormat;
};

export default class Team implements Inbox {
  readonly category = InboxCategory.Team;
  readonly type = InboxType.Team;
  readonly sortOrder?: number;
  readonly count?: number;

  teamSummary: TeamSummary;

  constructor(count: number, teamSummary: TeamSummary, sortOrder?: number) {
    this.count = count;
    this.teamSummary = teamSummary;
    this.sortOrder = sortOrder ?? INBOX_DEFAULT_SORT_ORDER;
  }

  get name() {
    return this.teamSummary.name;
  }

  get id() {
    return this.teamSummary.id.toString();
  }

  get icon() {
    return this.teamSummary.icon;
  }

  static deserialize(json: TeamInboxWireFormat): Team {
    return new Team(json.count, TeamSummary.deserialize(json.team_summary), json.sort_order);
  }
}
