/* RESPONSIBLE TEAM: team-product-guidance */

import { tracked } from '@glimmer/tracking';
import STEPS from 'embercom/lib/home/steps';

interface StepWireFormat {
  identifier: string;
  state: string;
}

export const FLOATING_WIDGET_REMINDER_LOCALSTORAGE_KEY = 'great-guidance_widget-dismissal-timeline';
export const FLOATING_WIDGET_DISMISSAL_KEY = 'great-guidance-floating-widget-dismissal-key';

export class Step {
  readonly identifier: string;
  @tracked state: string;
  isExpanded: boolean;
  stepData: $TSFixMe;

  constructor(identifier: string, state: string) {
    this.identifier = identifier;
    this.state = state;
    this.isExpanded = false;
    this.stepData = STEPS[identifier as keyof typeof STEPS];
  }

  get completed(): boolean {
    return this.state === 'completed';
  }

  static deserialize(json: StepWireFormat): Step {
    return new Step(json.identifier, json.state);
  }
}
