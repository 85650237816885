/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { readOnly } from '@ember/object/computed';

export default EmberObject.extend({
  type: 'tag',
  value: null,
  tag: null,
  suggestionIcon: 'tag',
  suggestionText: readOnly('tag.name'),
  filterIcon: 'tag',
  filterText: readOnly('tag.name'),
}).reopenClass({
  type: 'tag',
  dropdownIcon: 'tag',
});
