/* RESPONSIBLE TEAM: team-frontend-tech */

import Modifier from 'ember-modifier';

export default class UpdateOpacity extends Modifier {
  updateOpacity(element, { fadeOffset }) {
    if (typeof fadeOffset === 'undefined') {
      return;
    }

    let height = element.clientHeight;
    let opacity = (height - fadeOffset * 1.5) / height;
    element.style.setProperty('--content-opacity', opacity);
  }

  modify(element, _positional, named) {
    this.updateOpacity(element, { fadeOffset: named.fadeOffset });
  }
}
