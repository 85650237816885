/* RESPONSIBLE TEAM: team-workflows */
import EditorConfig, {
  type PathConfigGenerationParams,
  type StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import { ReusablePathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { ReusableBotReplyButtonsConfiguration } from 'embercom/objects/visual-builder/configuration/step/reply-buttons';
import type Step from 'embercom/models/operator/visual-builder/step';

export default class ReusableBotEditorConfig extends EditorConfig {
  public readonly canBeTicketTrigger: boolean = true;

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new ReusablePathConfig({ path, editorState });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.replyButtons:
        return new ReusableBotReplyButtonsConfiguration(stepConfigArgs);
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }
}
