/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { ParentAction, ChildAction } from './action';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import { UNASSIGNED_ID } from 'embercom/objects/inbox/admin-summary';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type TeamSummary from '../team-summary';
import { ProfilePictureIcon } from './icons';
import { type SearchMultipleTypesResponse } from 'embercom/services/quick-search';
import { isEmpty } from '@ember/utils';
import { AdminSearchContexts } from '../macro';
import type Session from 'embercom/services/session';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';
import type AdminAwayService from 'embercom/services/admin-away-service';

export default class AssignToAction extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare session: Session;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service declare adminAwayService: AdminAwayService;

  id = 'assign-to';
  icon = 'assignment' as const;
  searchableTypes = [SearchableType.Admin, SearchableType.Team];
  paneComponent = 'inbox2/command-k/assign-to/pane';

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.AssignToAction];
  }

  childForAdmin(admin: AdminSummary | AdminWithPermissions): ChildAction {
    return new ChildAction({
      parent: this,
      id: admin.id.toString(),
      label: admin.name,
      value: admin,
      icon: new ProfilePictureIcon(admin.imageURL),
      optionComponent: 'inbox2/command-k/assign-to/option',
    });
  }

  childForCurrentTeammate(): ChildAction {
    let admin = this.commandK.session.teammate;
    admin.adminStatus = {
      awayModeEnabled: this.adminAwayService.awayModeEnabled,
      reassignConversations: this.adminAwayService.reassignConversations,
      isAutoChanged: this.adminAwayService.isAutoChanged,
      channelAvailability: this.adminAwayService.channelAvailability,
    };

    return new ChildAction({
      parent: this,
      id: admin.id.toString(),
      label: this.intl.t('inbox.command-k.assign-to-yourself'),
      value: admin,
      icon: new ProfilePictureIcon(admin.imageURL),
      optionComponent: 'inbox2/command-k/assign-to/option',
    });
  }

  childForTeam(team: TeamSummary): ChildAction {
    let icon = team.isUnassignedAssignee ? new ProfilePictureIcon() : team.icon;
    return new ChildAction({
      parent: this,
      id: team.id.toString(),
      label: team.name,
      value: team,
      icon,
      optionComponent: 'inbox2/command-k/assign-to/option',
    });
  }

  buildTopLevelItems(
    query: string,
    _quickSearchResults: SearchMultipleTypesResponse,
  ): Array<ChildAction> {
    return this.buildResults(query);
  }

  buildResults(query: string): Array<ChildAction> {
    let children = [];

    if (!this.context?.excludeAdmins) {
      let teammate = this.commandK.session.teammate;
      let adminResults = this.inbox2AssigneeSearch.filterAdmins(
        query,
        AdminSearchContexts.Assignments,
      );
      let resultsIncludeCurrentTeammate = adminResults.results?.any(
        (result) => result.data.id === teammate.id,
      );
      if (isEmpty(query) || resultsIncludeCurrentTeammate) {
        children.unshift(this.childForCurrentTeammate());
      }

      adminResults.results
        ?.filter((result) => result.data.id !== teammate.id)
        .forEach((result) => {
          children.push(this.childForAdmin(result.data as AdminSummary));
        });
    }

    if (!this.context?.excludeTeams) {
      let teamResults = this.inbox2AssigneeSearch.filterTeams(query);
      let results = teamResults.results;
      // Remove Unassigned Team when Admin Assignees are included in results
      if (!this.context?.excludeAdmins) {
        results = results?.filter((result) => result.data.id !== UNASSIGNED_ID);
      }
      results?.forEach((result) => {
        children.push(this.childForTeam(result.data as TeamSummary));
      });
    }

    return children;
  }
}
