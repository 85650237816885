/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import BaseStat from './base-stat';

export default BaseStat.extend({
  store: service(),
  failureCategory: attr(),
  subscriptionTypeId: attr(),
  subscriptionTypeName: computed('subscriptionTypeId', function () {
    return this.store.peekRecord(
      'outbound-subscriptions/subscription-type',
      this.subscriptionTypeId,
    ).translationForTeammate.name;
  }),
  messageStatus: attr(),
  errorMessage: attr(),
  errorCode: attr(),
  device: attr(),
});
