/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import ajax from 'embercom/lib/ajax';
import containerLookup, { getIntlService, getApp } from 'embercom/lib/container-lookup';

export default async function resendSenderEmailVerificationLink(senderEmailId) {
  let intl = getIntlService();
  let intercomEventService = containerLookup('service:intercomEventService');
  let notificationsService = containerLookup('service:notificationsService');
  let app = getApp();

  intercomEventService.trackAnalyticsEvent({
    action: 'clicked',
    object: 'resend_verification_link',
    context: 'create_custom_sender_address',
  });
  try {
    await ajax({
      url: `/ember/sender_emails/${senderEmailId}/resend_verification_email`,
      type: 'POST',
      data: JSON.stringify({
        app_id: app.id,
        admin_id: app.currentAdmin.id,
      }),
    });
    notificationsService.notifyConfirmation(intl.t('settings.resend-verification-email.success'));
  } catch (e) {
    notificationsService.notifyError(intl.t('settings.resend-verification-email.error'));
  }
}
