/* RESPONSIBLE TEAM: team-frontend-tech */
import ENV from '../../config/environment';
import { WebTracerProvider, StackContextManager } from '@opentelemetry/sdk-trace-web';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { TraceIdRatioBasedSampler } from '@opentelemetry/core';
import { BatchSpanProcessor, NoopSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http/build/esnext';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';

export function setupOTEL(exportBaseUrl) {
  let samplingRate = ENV.APP.tracing.sampleRate;
  let provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: `embercom-${ENV.environment}`,
    }),
    sampler: new TraceIdRatioBasedSampler(samplingRate),
  });

  if (ENV.environment === 'test') {
    // We don't want to send any data out of the test environment
    provider.addSpanProcessor(new NoopSpanProcessor());
  } else {
    provider.addSpanProcessor(
      new BatchSpanProcessor(
        new OTLPTraceExporter({
          url: exportUrl(exportBaseUrl),
          headers: {
            'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            'X-EMBERCOM-REVISION-ID': document.querySelector("meta[name='embercom_revision_id']")
              ?.content,
            'X-OTLP-Sample-Rate': 1 / samplingRate, // Honeycomb uses integer sample rate, e.g. 20 means 1 in 20 retained
          },
          concurrencyLimit: 10,
        }),
      ),
    );
  }

  provider.register({
    contextManager: new StackContextManager(),
  });

  return provider.getTracer('embercom-tracer');
}
export function registerOTELInstrumentations({ pageLoadRootSpan, getActiveRootSpan, onSpanEnd }) {
  // TODO: in certain scenarios (eg. before the browser "load" event has fired
  // but after the XMLHttpRequestInstrumentation has been initialized) we can
  // end up with duplicate spans for the same entity (eg. network request). we
  // should consider how we want to resolve this. the first idea that comes to
  // mind is that we could force the DocumentLoadInstrumentation to create all
  // its spans generated from performance navigation entries as soon as it can
  // instead of waiting for the "load" event, this way _it_ captures data about
  // resources that were fetched before we started javascript execution, and
  // we can assume it's safe to leave the XHR + fetch instrumentation to catch
  // any interesting requests after that point.
  //
  // * Caveat: there are resource loads that the performance navigation entries
  // will catch that the XHR + Fetch instrumentation can't (eg. an image added
  // to the DOM, this is loaded by the browser instead of being programatically
  // fetched by javascript and as a result only a performance navigation entry
  // will exist)
  //
  // * Caveat to the caveat: As Jack has shown with the img load instrumentation
  // in inbox2, we can (and should) manually instrument browser events that
  // we're interested in (eg. image loads) because performance navigation
  // entries only really help us for the initial page load, we don't use them
  // for dynamically created top level spans (there are memory limitations to
  // how large the entries can become, also they're not as configurable so can't
  // capture much metadata)

  // TODO: we probably actually want to count the number of occurrences
  // of the following ignored urls and add it as metadata on the root
  // level span instead of discarding this info entirely (eg. imagine
  // we have so many of these requests that they actually slow down the
  // app / cause HTTP/1 contention). but for now let's just ignore them,
  // we may instead eventually want to only ignore them completely when
  // thinking about autoEndOnIdle detection
  let ignoredURLs = [
    /\/ember\/otlp*/,
    '/frontend_stats',
    '/ember/interaction_metrics',
    '/ember/log',
    /ember\/user_presence/,
    '/ember/exceptions',
    /sentry\.io\/api/,
  ];

  registerInstrumentations({
    instrumentations: [
      new DocumentLoadInstrumentation({
        ignoreNetworkEvents: true,
        rootSpan: pageLoadRootSpan,
      }),
      new XMLHttpRequestInstrumentation({
        getActiveRootSpan,
        ignoreUrls: ignoredURLs,
        ignoreXhrEvents: true,
        applyCustomAttributesOnSpan: (span, xhr) => {
          let spanAttributes = {};
          let responseHeaders = xhr.getAllResponseHeaders();
          if (responseHeaders.indexOf('x-request-id') >= 0) {
            spanAttributes.request_id = xhr.getResponseHeader('x-request-id');
          }
          if (responseHeaders.indexOf('x-runtime') >= 0) {
            spanAttributes['http.response.headers.x-runtime'] = Math.round(
              Number(xhr.getResponseHeader('x-runtime')) * 1000,
            );
          }
          if (responseHeaders.indexOf('x-request-queueing') >= 0) {
            spanAttributes['network.backend_queueing_ms'] = Number(
              xhr.getResponseHeader('x-request-queueing'),
            );
          }
          if (responseHeaders.indexOf('x-client-port') >= 0) {
            spanAttributes['remote_port'] = xhr.getResponseHeader('x-client-port');
          }
          span.setAttributes(spanAttributes);
          onSpanEnd(span);
        },
      }),
      new FetchInstrumentation({
        getActiveRootSpan,
        ignoreUrls: ignoredURLs,
        applyCustomAttributesOnSpan: (span, request, response) => {
          span.setAttributes({
            request_id: response.headers.get('X-Request-Id'),
            'http.response.headers.x-runtime': Math.round(
              Number(response.headers.get('x-runtime')) * 1000,
            ),
            'network.backend_queueing_ms': Number(response.headers.get('X-Request-Queueing')),
            remote_port: response.headers.get('X-Client-Port'),
            'network.priority': request.priority ?? 'auto',
            'network.cache': request.cache,
          });
          onSpanEnd(span);
        },
      }),
    ],
  });
}

function exportUrl(exportBaseUrl) {
  let appIdCode = document.querySelector("meta[name='app_id_code']")?.content;
  let adminId = window.__embercom_boot_data?.current_admin_id;
  let queryParams = new URLSearchParams({
    ...(appIdCode && { app_id: appIdCode }),
    ...(adminId && { admin_id: adminId }),
  });
  return `${exportBaseUrl}/ember/otlp?${queryParams.toString()}`;
}
