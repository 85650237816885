/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import AdminAvatarData from 'embercom/models/mixins/admin-avatar-data';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';

export default Model.extend(AdminAvatarData, {
  appService: service(),
  operatorId: attr('string'),
  name: attr('string'),
  avatar: attr(),
  avatarType: attr('string'),
  avatarUpload: attr(),
  isOperator: true,
  avatar_shape: 'squircle',
  has_default_avatar: attr('boolean'),
  has_photo_avatar: attr('boolean'),

  save() {
    let formData = this._formData();
    let ajaxParams = {
      type: 'PUT',
      url: `/ember/operator_identities/${this.operatorId}.json`,
      data: formData,
      contentType: false,
      processData: false,
    };
    return ajax(ajaxParams)
      .then((req) => this._setPropertiesFromRequest(req))
      .then(() => this._reloadOperatorAdmin());
  },
  revert() {
    let formData = new FormData();
    formData.append('app_id', this.get('appService.app.id'));
    formData.append('id', this.id);
    return ajax({
      type: 'PUT',
      url: `/ember/operator_identities/${this.operatorId}/revert.json`,
      data: formData,
      contentType: false,
      processData: false,
    }).then((req) => this._setPropertiesFromRequest(req));
  },
  _formData() {
    let formData = new FormData();
    formData.append('app_id', this.get('appService.app.id'));
    formData.append('id', this.id);
    formData.append('name', this.name);
    formData.append('avatar_type', this.avatarType);

    if (this.avatarUpload) {
      formData.append('avatar_upload', this.avatarUpload, 'custom_avatar.png');
    } else {
      // This is form data so a value of null will convert to the 'null' string
      // to get an empty field, the value must be an empty string.
      formData.append('avatar_upload', '');
    }

    return formData;
  },
  _setPropertiesFromRequest(request) {
    request['avatar_upload'] = null;
    this.store.pushPayload({ 'operator-identities': request });
    this.set('avatarUpload', null);
    this.set('name', request.name);
    this.set('avatarType', request.avatar_type);
    return this;
  },
  _reloadOperatorAdmin() {
    this.get('appService.app.operatorBot').reload();
    return this;
  },
});
