/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from '../entity-types';

export enum SearchableType {
  Admin = EntityType.Admin,
  Macro = EntityType.SavedReply,
  Tag = EntityType.Tag,
  Team = EntityType.Team,
  View = EntityType.InboxView,
  GithubIssue = EntityType.GithubIssue,
  ConversationTopic = EntityType.ConversationTopic,
  WorkflowConnectorAction = EntityType.WorkflowConnectorAction,
  TriggerableCustomBot = EntityType.TriggerableCustomBot,
}

export type SearchableTypeString = `${SearchableType}`;
