/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';

export default class InboundEmail extends Model {
  @attr('string') declare app_id: string;
  @attr('string') declare to: string;
  @attr('string') declare reply_to: string;
  @attr('string') declare from: string;
  @attr('string') declare cc: string;
  @attr('string') declare bcc: string;
  @attr('string') declare accepted_for: string;
  @attr('string') declare subject: string;
  @attr('string') declare date: string;
  @attr('string') declare auto_responder: string;
  @attr('string') declare plain_text: string;
  @attr('string') declare html: string;
  @attr('string') declare email_message_id: string;
  @attr('string') declare references: string;
  @attr('string') declare in_reply_to: string;
}
