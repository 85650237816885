/* RESPONSIBLE TEAM: team-channels */
export const stampClasses = {
  approved: 'o__live',
  pending: 'o__review',
  rejected: 'o__unverified',
  declined: 'o__unverified',
  disabled: 'o__inactive',
  verified: 'o__live',
  unverified: 'o__light-blue',
  not_verified: 'o__unverified',
  connected: 'o__live',
  disconnected: 'o__unverified',
};

export const statusLabels = {
  approved: 'app.models.data.whatsapp.whatsapp-constants.status-label.approved',
  pending: 'app.models.data.whatsapp.whatsapp-constants.status-label.pending',
  pending_submission: 'app.models.data.whatsapp.whatsapp-constants.status-label.pending',
  pending_need_more_info:
    'app.models.data.whatsapp.whatsapp-constants.status-label.pending_need_more_info',
  rejected: 'app.models.data.whatsapp.whatsapp-constants.status-label.rejected',
  declined: 'app.models.data.whatsapp.whatsapp-constants.status-label.declined',
  disabled: 'app.models.data.whatsapp.whatsapp-constants.status-label.disabled',
  verified: 'app.models.data.whatsapp.whatsapp-constants.status-label.verified',
  unverified: 'app.models.data.whatsapp.whatsapp-constants.status-label.unverified',
  not_verified: 'app.models.data.whatsapp.whatsapp-constants.status-label.not_verified',
  connected: 'app.models.data.whatsapp.whatsapp-constants.status-label.connected',
  disconnected: 'app.models.data.whatsapp.whatsapp-constants.status-label.disconnected',
  unknown: 'app.models.data.whatsapp.whatsapp-constants.status-label.unknown',
  none: 'app.models.data.whatsapp.whatsapp-constants.status-label.none',
};

export const statusDescriptions = {
  connected: 'app.models.data.whatsapp.whatsapp-constants.status-description.connected',
  declined: 'app.models.data.whatsapp.whatsapp-constants.status-description.declined',
  rejected: 'app.models.data.whatsapp.whatsapp-constants.status-description.rejected',
  disconnected: 'app.models.data.whatsapp.whatsapp-constants.status-description.disconnected',
  pending: 'app.models.data.whatsapp.whatsapp-constants.status-description.pending',
  pending_need_more_info:
    'app.models.data.whatsapp.whatsapp-constants.status-description.pending_need_more_info',
};

export const allVerticals = [
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.automotive',
    value: 'AUTO',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.beauty_spa_and_salon',
    value: 'BEAUTY',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.clothing_and_apparel',
    value: 'APPAREL',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.education',
    value: 'EDU',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.entertainment',
    value: 'ENTERTAIN',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.event_planning_and_service',
    value: 'EVENT_PLAN',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.finance_and_banking',
    value: 'FINANCE',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.food_and_grocery',
    value: 'GROCERY',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.public_service',
    value: 'GOVT',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.hotel_and_lodging',
    value: 'HOTEL',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.medical_and_health',
    value: 'HEALTH',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.non_profit',
    value: 'NONPROFIT',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.professional_services',
    value: 'PROF_SERVICES',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.shopping_and_retail',
    value: 'RETAIL',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.travel_and_transportation',
    value: 'TRAVEL',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.restaurant',
    value: 'RESTAURANT',
  },
  {
    text: 'app.models.data.whatsapp.whatsapp-constants.vertical.other',
    value: 'OTHER',
  },
];
