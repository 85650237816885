/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class extends RulesetLink {
  @belongsTo('operator/custom-bot', { async: false }) object;

  objectType = objectTypes.customBot;
  shouldReloadRecord = true;

  get contentHash() {
    return this.serialize();
  }

  ready() {
    this.object.beforeEdit();
  }
}
