/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { SimpleAction } from 'embercom/objects/inbox/command-k/action';

export default class SetSystemTheme extends SimpleAction {
  @service declare session: Session;

  id = 'set-system-theme';
  icon = 'theme' as const;

  get label() {
    return this.intl.t('inbox.switch-system-mode');
  }
}
