/* RESPONSIBLE TEAM: team-ai-agent */

export interface ResolutionBotAnswerSummaryWireFormat {
  id: string;
  title: string;
}

export default class ResolutionBotAnswerSummary {
  readonly id: string;
  readonly title: string;

  constructor(id: string, title: string) {
    this.id = id;
    this.title = title;
  }

  static deserialize(json: ResolutionBotAnswerSummaryWireFormat): ResolutionBotAnswerSummary {
    return new ResolutionBotAnswerSummary(json.id, json.title);
  }
}
