/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import { ParentAction } from './action';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { NamedIcon } from 'embercom/objects/inbox/command-k/icons';

export default class MergeInto extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.MergeInto];
  }

  id = 'merge-into';
  icon = new NamedIcon('merge');
  paneComponent = 'inbox2/command-k/merge-into/pane';
}
