/* RESPONSIBLE TEAM: team-proactive-support */
import { DataType } from './constants/data-types';
import SavedViewData from './saved-view-data';

interface Args {
  conditions: object;
  columns: string[];
  tags: string[];
}

export default class ArticleSavedViewData extends SavedViewData implements Args {
  constructor(args: Args) {
    super({ savedViewDataType: DataType.ARTICLES });
    this.conditions = args.conditions || {};
    this.columns = args.columns || [];
    this.tags = args.tags || [];
  }

  public conditions;
  public columns;
  public tags;

  get data() {
    return {
      ...super.data,
      conditions: this.conditions,
      columns: this.columns,
      tags: this.tags,
    };
  }

  equals(other: ArticleSavedViewData) {
    return (
      this._conditionsEqual(other.conditions) &&
      this.columnsEqual(other.columns) &&
      this.tagsEqual(other.tags)
    );
  }

  columnsEqual(otherColumns: string[]) {
    return this._arraysEqual(this.columns, otherColumns);
  }

  tagsEqual(otherTags: string[]) {
    return this._arraysEqual(this.tags, otherTags);
  }

  private _conditionsEqual(otherConditions: {}) {
    if (Object.keys(this.conditions).length !== Object.keys(otherConditions).length) {
      return false;
    }
    for (let key in this.conditions) {
      //@ts-ignore
      if (JSON.stringify(this.conditions[key]) !== JSON.stringify(otherConditions[key])) {
        return false;
      }
    }
    return true;
  }

  private _arraysEqual(a: string[], b: string[]) {
    if (a.length !== b.length) {
      return false;
    }
    let arrayA = a.slice();
    let arrayB = b.slice();
    return JSON.stringify(arrayA.sort()) === JSON.stringify(arrayB.sort());
  }
}
