/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import {
  responseTypes,
  stepTypes,
  ratingScaleResponseTypes,
  branchingQuestionIdentifier,
} from 'embercom/models/data/survey/constants';

export const generateAttributes = (steps, questionNameTranslation) => {
  let notAllowedQuestionTypes = [responseTypes.input, responseTypes.text];
  let questionTypeMapper = {
    [responseTypes.dropdown]: 'survey_dropdown_question',
    [responseTypes.multiSelect]: 'string',
    [ratingScaleResponseTypes.numeric]: 'integer',
    [ratingScaleResponseTypes.emoji]: 'integer',
    [ratingScaleResponseTypes.stars]: 'integer',
    [ratingScaleResponseTypes.nps]: 'integer',
  };

  let { questionAttributes, attributesOverrides } = steps.reduce(
    (acc, step) => {
      let stepOrder = step.order + 1;

      step.orderedQuestions
        .filter(({ questionType }) => !notAllowedQuestionTypes.includes(questionType))
        .map((question) => {
          let questionId = branchingQuestionIdentifier + question.uuid;
          let questionOrder = question.order + 1;
          let serializedQuestionTitle = question.blocks.map((block) => block.text).join('');
          let questionName = questionNameTranslation(
            stepOrder,
            questionOrder,
            serializedQuestionTitle,
          );

          let attributesOverrides = {
            name: questionName,
            identifier: questionId,
            type: questionTypeMapper[question.granularQuestionType],
            description: questionName,
            human_friendly_name: questionName,
          };

          let questionToAttribute = {
            noFallback: true,
            id: questionId,
            identifier: questionId,
            human_friendly_name: questionName,
            name: questionName,
            type: questionTypeMapper[question.granularQuestionType],
            displayable: false,
            filterable: false,
            templatable: false,
            templatable_identifier: questionId,
            sortable: false,
            tracked: false,
            description: 'Branch on this question',
          };

          acc.attributesOverrides.push(attributesOverrides);
          acc.questionAttributes.push(questionToAttribute);
        });
      return acc;
    },
    {
      attributesOverrides: [],
      questionAttributes: [],
    },
  );

  return { questionAttributes, attributesOverrides };
};

export const sortStepsByOrder = (stepsArray) => {
  let baseSort = stepsArray.sort((step, nextStep) => step.order - nextStep.order);
  let positiveOrderedSteps = baseSort.filter((step) => step.order >= 0);
  let negativeOrderedSteps = baseSort
    .filter((step) => step.order < 0)
    .sortBy('order')
    .reverse();

  return [...positiveOrderedSteps, ...negativeOrderedSteps];
};

export const availableStepToBranchTo = (currentStep, steps) => {
  let availableStepsActions = sortStepsByOrder(
    steps.filter((step) => {
      let withoutIntroStep = step.stepType !== stepTypes.intro;
      let withoutCurrentStep = step.id !== currentStep.id || step.uuid !== currentStep.uuid;
      let withoutPreviousSteps = step.order > currentStep.order || step.order < 0;

      return withoutIntroStep && withoutCurrentStep && withoutPreviousSteps;
    }),
  )
    .map((step) => ({
      text: step.readableName,
      value: step.order,
    }))
    .reduce((acc, current) => {
      let duplicatedOption = acc.find(
        (option) => option.text === current.text && option.value === current.value,
      );

      if (!duplicatedOption) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

  return availableStepsActions;
};

export const pathBasedUniqueStepsIds = (paths) => {
  return paths
    .map(({ stepId, stepUuid, nextStepId, nextStepUuid }) => [
      {
        id: stepId,
        uuid: stepUuid,
      },
      {
        id: nextStepId,
        uuid: nextStepUuid,
      },
    ])
    .reduce((acc, val) => [...acc, ...val], [])
    .reduce((acc, current) => {
      let duplicatedStep = acc.find((item) => item.id === current.id && item.uuid === current.uuid);
      let emptyStepObject = current.id === undefined && current.uuid === undefined;

      if (!duplicatedStep && !emptyStepObject) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
};

export const stepForPath = (path, steps) => {
  return steps.find((step) => {
    if (path.stepId) {
      return path.stepId === step.id;
    } else {
      return path.stepUuid === step.uuid;
    }
  });
};

export const nextStepForPath = (path, steps) => {
  return steps.find((step) => {
    if (path.nextStepId) {
      return path.nextStepId === step.id;
    } else {
      return path.nextStepUuid === step.uuid;
    }
  });
};
