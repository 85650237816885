/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from '../component-configuration';
import { assert } from '@ember/debug';
import { stats } from 'embercom/models/data/stats-system/stats-constants';

export default ComponentConfiguration.extend({
  filters: [],
  stat: undefined,

  init(inputs) {
    inputs.component = inputs.component || 'content-editor/view-mode/standard-stat-list';
    this._super(...arguments);
    assert(
      `The standard stats-list must be passed a known stats system statistic key. You passed [${inputs.stat}]`,
      Object.keys(stats).includes(inputs.stat),
    );

    this.setProperties(inputs);

    this.filters.forEach((filter) => {
      assert(`Expected a text attribute for the filter [${filter}]`, filter.text);
      assert(
        `Expected a known stats system statistic value for the filter value. You passed [${filter.value}]`,
        Object.values(stats).includes(filter.value),
      );
    });
  },
});
