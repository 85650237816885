/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import SmsContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/sms/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import SeriesSchedulePanelConfiguration from 'embercom/objects/content-editor/panels/schedule/series-schedule-panel-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import { timezoneTypes } from 'embercom/models/data/matching-system/matching-constants';
import AudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import StandardAudienceEditorConfiguration from 'embercom/objects/audience-editor/standard-audience-editor-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';

export default function generateEditModeConfig(options) {
  let container = options.container;

  return EditModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesModeHeaderConfiguration.create({ container }),
    headerComponentConfigs: [StandardEditModeHeaderConfiguration.create({ container })],
    editorPanelConfigs: [
      SmsContentPanelConfiguration.create({ container }),
      StandardAudiencePanelConfiguration.create({
        audienceHeaderConfiguration: AudienceHeaderConfiguration.create({
          showImplicitPredicates: true,
          container,
        }),
        audienceEditorConfiguration: new StandardAudienceEditorConfiguration({
          showMatchBehavior: true,
          showClientPredicates: false,
          showEventRules: true,
          allowedUserRoles: ['user', 'lead'],
          useRolePredicateGroup: true,
          showImplicitPredicates: true,
        }),
        container,
      }),
      StandardSchedulePanelConfiguration.create({
        showFrequencyEditor: true,
        timezoneType: timezoneTypes.basedOnUserTimezone,
        container,
      }),
      StandardGoalPanelConfiguration.create({
        introText: 'Understand how your SMS drives people to take action in your product.',
        container,
      }),
      SeriesSchedulePanelConfiguration.create({
        timezoneType: timezoneTypes.basedOnUserTimezone,
        container,
      }),
    ],
    footerComponentConfigs: [],
  });
}
