/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { not } from '@ember/object/computed';
import { containsByProperty } from '@intercom/pulse/lib/computed-properties';

export default Model.extend({
  name: attr(),
  allConversationNotificationTypeIds: ['conversation.all', 'conversation.new'],

  isConversationType: containsByProperty('allConversationNotificationTypeIds', 'id'),
  isNonConversationType: not('isConversationType'),
});
