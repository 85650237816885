/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import Fragment from 'ember-data-model-fragments/fragment';

export const MONDAY = 0;
const TUESDAY = 1;
const WEDNESDAY = 2;
const THURSDAY = 3;
export const FRIDAY = 4;
export const SATURDAY = 5;
export const SUNDAY = 6;

export const DAYS = {
  [MONDAY]: 'monday',
  [TUESDAY]: 'tuesday',
  [WEDNESDAY]: 'wednesday',
  [THURSDAY]: 'thursday',
  [FRIDAY]: 'friday',
  [SATURDAY]: 'saturday',
  [SUNDAY]: 'sunday',
};

export default Fragment.extend({
  startTime: attr('number', { defaultValue: 9 * 60 }),
  endTime: attr('number', { defaultValue: 17 * 60 }),
  startDay: attr('number', { defaultValue: 0 }),
  endDay: attr('number', { defaultValue: 6 }),
  period: computed('startDay', 'endDay', function () {
    let startDay = this.startDay;
    let endDay = this.endDay;

    if (startDay === endDay && startDay >= MONDAY && startDay <= SUNDAY) {
      return DAYS[startDay];
    } else if (startDay === SATURDAY && endDay === SUNDAY) {
      return 'weekends';
    } else if (startDay === MONDAY && endDay === FRIDAY) {
      return 'weekdays';
    } else {
      return 'everyday';
    }
  }),
  isInvalid: computed('startTime', 'endTime', function () {
    let startTime = this.startTime;
    let endTime = this.endTime;
    return startTime >= endTime;
  }),
}).reopenClass({
  periodValueMapping: {
    everyday: { startDay: MONDAY, endDay: SUNDAY },
    weekdays: { startDay: MONDAY, endDay: FRIDAY },
    weekends: { startDay: SATURDAY, endDay: SUNDAY },
    monday: { startDay: MONDAY, endDay: MONDAY },
    tuesday: { startDay: TUESDAY, endDay: TUESDAY },
    wednesday: { startDay: WEDNESDAY, endDay: WEDNESDAY },
    thursday: { startDay: THURSDAY, endDay: THURSDAY },
    friday: { startDay: FRIDAY, endDay: FRIDAY },
    saturday: { startDay: SATURDAY, endDay: SATURDAY },
    sunday: { startDay: SUNDAY, endDay: SUNDAY },
  },
});
