/* RESPONSIBLE TEAM: team-customer-data-platform */
const CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_ID = 'external_id';
const CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_CREATED_AT = 'external_created_at';
const CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_UPDATED_AT = 'external_updated_at';

const CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES = [
  CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_ID,
  CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_CREATED_AT,
  CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_UPDATED_AT,
];

const REQUIRED_CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES = [
  CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_ID,
];

export {
  CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_ID,
  CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_CREATED_AT,
  CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAME_EXTERNAL_UPDATED_AT,
  CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES,
  REQUIRED_CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES,
};
