/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { getApp } from 'embercom/lib/container-lookup';
import { mapObject } from 'underscore';

const ISO_FORMAT_WITHOUT_TZ = 'YYYY-MM-DDTHH:mm:ss';
const INTERVAL_THRESHOLDS = {
  1: 'hour',
  14: 'day',
  91: 'week',
};

function getCurrentDateOverride(timezone) {
  try {
    let currentDateOverride = getApp()?.reportingCurrentDateOverride;
    if (currentDateOverride) {
      return moment.tz(currentDateOverride, timezone);
    }
  } catch {
    return null;
  }
}

function getCurrentDate(timezone) {
  return getCurrentDateOverride(timezone) || moment.tz(timezone);
}

export default class Range {
  @tracked start = null;
  @tracked end = null;
  @tracked selectedRange;
  @tracked extraRanges = {};

  heatmapAggregations = [
    { grouping: 'day_of_week', interval: 1 },
    { grouping: 'hour_of_day', interval: 1 },
  ];
  teammateAggregation = { grouping: 'teammate_id', interval: 1 };
  conversationRatingsAggregation = { grouping: 'rating_index', interval: 1 };
  answerEffectivenessAggregation = { grouping: 'effectiveness', interval: 1 };

  static createFromParamsWithAddedRanges(rangeStart, rangeEnd, timezone, extraRanges) {
    let start = rangeStart || getCurrentDate(timezone).subtract(6, 'days').startOf('day').format();
    let end = rangeEnd || getCurrentDate(timezone).endOf('day').format();
    return new Range(start, end, timezone, null, extraRanges);
  }

  static createFromParams(rangeStart, rangeEnd, timezone) {
    let start = rangeStart || getCurrentDate(timezone).subtract(6, 'days').startOf('day').format();
    let end = rangeEnd || getCurrentDate(timezone).endOf('day').format();
    return new Range(start, end, timezone, null);
  }

  static createFromPreset(preset, timezone) {
    return new Range(null, null, timezone, preset);
  }

  static createFromModel(dateRange, timezone) {
    if (isEmpty(dateRange)) {
      return null;
    }

    if (dateRange.selection === 'custom') {
      return Range.createFromParams(dateRange.start, dateRange.end, timezone);
    } else {
      return Range.createFromPreset(dateRange.selection, timezone);
    }
  }

  static copy(range) {
    return Range.createFromParamsWithAddedRanges(
      range.start,
      range.end,
      range.timezone,
      range.extraRanges,
    );
  }

  constructor(start = null, end = null, timezone = null, preset = null, extraRanges = {}) {
    this.timezone = timezone;
    this.extraRanges = extraRanges;
    if (preset) {
      this.setDefaultRange(preset);
    } else {
      this.start = start;
      this.end = end;
      this.setSelectedRange();
    }
  }

  setSelectedRange() {
    this.selectedRange = this.getSelectedRangeFromCurrentTime() || 'custom';
  }

  getSelectedRangeFromCurrentTime() {
    if (this.timezone) {
      let start =
        this.currentDateInTimezone.clone().startOf('day').diff(this.startMoment, 'days') + 1;
      let end = this.currentDateInTimezone.clone().endOf('day').diff(this.endMoment, 'days') + 1;
      return Object.keys(this.defaultRanges).find(
        (key) => this.defaultRanges[key][0] === start && this.defaultRanges[key][1] === end,
      );
    } else {
      return null;
    }
  }

  monthsAgo(n) {
    return this.currentDateInTimezone.clone().subtract(n, 'months');
  }

  daysAgo(date) {
    return this.currentDateInTimezone.clone().diff(date, 'days') + 1;
  }

  get newDefaultRanges() {
    if (!getApp()?.canUseFeature('new-default-date-ranges')) {
      return {};
    }

    return {
      year_to_date: [this.daysAgo(this.currentDateInTimezone.clone().startOf('year')), 1],
      past_6_months: [this.daysAgo(this.monthsAgo(6)), 1],
      past_12_months: [this.daysAgo(this.monthsAgo(12)), 1],
    };
  }

  get defaultRanges() {
    let params = {
      today: [1, 1],
      yesterday: [2, 2],
      past_week: [7, 1],
      month_to_date: [this.daysAgo(this.currentDateInTimezone.clone().startOf('month')), 1],
      past_4_weeks: [28, 1],
      past_12_weeks: [84, 1],
      ...this.newDefaultRanges,
      ...this.extraRanges,
    };
    return params;
  }

  get presetToTranslationKey() {
    let extraRangesEntries = mapObject(this.extraRanges, (_, key) => key);
    return {
      today: 'reporting.range.today',
      yesterday: 'reporting.range.yesterday',
      past_week: 'reporting.range.past-week',
      month_to_date: 'reporting.range.month-to-date',
      past_4_weeks: 'reporting.range.past-4-weeks',
      past_12_weeks: 'reporting.range.past-12-weeks',
      ...mapObject(this.newDefaultRanges, (_, key) => `reporting.range.${key.replace(/_/g, '-')}`),
      ...extraRangesEntries,
    };
  }
  get currentDateInTimezone() {
    return this._currentDate().tz(this.timezone);
  }
  get startMoment() {
    return moment.tz(this.start, this.timezone).startOf('day');
  }
  get endMoment() {
    return moment.tz(this.end, this.timezone).endOf('day');
  }
  get endMomentButNotInTheFuture() {
    // this is a bit of a hack

    // the "today" range preset is from 00:00 -> 23:59:59
    // this causes inconsistencies in reports because data is always being ingested, so
    // a query for "today"'s data performed at time A can end up with different results than the same query performed seconds later
    // an easy way to minimize this type of inconsistency is to clamp the time to Now. Ideally this Now value would
    // be consistent, but an even easier approach (although less effective), is just to use now and round to the nearest second

    let now = moment.tz(this.timezone);
    return moment.min(now.subtract(5, 'minutes').startOf('minute'), this.endMoment);
  }
  get comparisonStartMoment() {
    return moment.tz(this.comparisonStart, this.timezone).startOf('day');
  }
  get comparisonEndMoment() {
    return moment.tz(this.comparisonEnd, this.timezone).endOf('day');
  }
  get inDays() {
    return Math.round(moment.duration(this.endMoment - this.startMoment).asDays());
  }
  get isSingleDay() {
    return this.inDays <= 1;
  }
  get comparisonStart() {
    return this._createComparison(this.startMoment);
  }
  get comparisonEnd() {
    return this._createComparison(this.endMoment);
  }
  get comparisonStartIsMoreThanTwoYearsAgo() {
    return this.comparisonStartMoment.isBefore(this._currentDate().subtract(2, 'years'));
  }
  get comparisonSpansMultipleYears() {
    return this.comparisonStartMoment.year() !== this.comparisonEndMoment.year();
  }
  get baseAggregations() {
    if (this.isSingleDay) {
      return [{ grouping: 'hour_of_day', interval: 1 }];
    } else {
      return [{ grouping: 'date', interval: this.interval }];
    }
  }
  get tagAggregations() {
    return [
      {
        grouping: 'conversation_tag_ids',
        interval: 1,
        unique_count: 'conversation_id',
      },
    ].concat(this.baseAggregations);
  }
  get interval() {
    for (let threshold in INTERVAL_THRESHOLDS) {
      if (this.inDays <= threshold) {
        return INTERVAL_THRESHOLDS[threshold];
      }
    }
    return 'month';
  }
  get hasDefaultEnd() {
    return this.endMoment.isSame(this.currentDateInTimezone, 'day');
  }

  updateRange(start, end) {
    this.start = this._convertToTimezone(start).startOf('day').format();
    this.end = this._convertToTimezone(end).endOf('day').format();
    this.selectedRange = 'custom';
  }
  setDefaultRange(selectedRange) {
    let selectedInterval = this.defaultRanges[selectedRange];
    let currentDate = this.currentDateInTimezone.endOf('day');
    let startDate = currentDate
      .clone()
      .subtract(selectedInterval[0] - 1, 'days')
      .startOf('day');
    let endDate = currentDate
      .clone()
      .subtract(selectedInterval[1] - 1, 'days')
      .endOf('day');

    this.start = startDate.format();
    this.end = endDate.format();
    this.selectedRange = selectedRange;
  }
  _currentDate() {
    return getCurrentDate(this.timezone);
  }
  get _currentDateOverride() {
    return getCurrentDateOverride(this.timezone);
  }
  _createComparison(date) {
    return date.clone().subtract(this.inDays, 'days').format();
  }
  // stripping the computer timezone from the datestring avoids double accounting for timezones
  // if the computer time does not match the app zone, and allows us to reliably use
  // the date and time numeric values from the datestamp returned from the date picker
  _convertToTimezone(date) {
    return moment.tz(date.format(ISO_FORMAT_WITHOUT_TZ), this.timezone);
  }
}
