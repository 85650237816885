/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import type AttributesApi from 'embercom/services/attributes-api';

export default class AttributesResolver {
  @service declare attributesApi: AttributesApi;

  get attributes() {
    return this.attributesApi.templatableAttributes;
  }

  humanReadable(path: string) {
    let attribute = this.findAttributeByPath(path);
    if (!attribute) {
      return path;
    }

    return attribute.humanFriendlyName;
  }

  isValid(path: string) {
    return !!this.findAttributeByPath(path);
  }

  requiresFallback(path: string, fallback: string) {
    if (fallback) {
      return false;
    }

    let attribute = this.findAttributeByPath(path);
    if (!attribute) {
      return false;
    }

    return attribute.requiresFallback;
  }

  isFallbackAllowed(path: string) {
    return this.isValid(path) && this.findAttributeByPath(path)!.requiresFallback;
  }

  fallbackInfoText(path: string) {
    let name = this.humanReadable(path);
    let attribute = this.findAttributeByPath(path);

    if (attribute?.category === 'user') {
      name = `Recipient's ${name.toLocaleLowerCase()}`;
    }

    return `${name} will be inserted here`;
  }

  private findAttributeByPath(path: string) {
    return this.attributes.findBy('templateIdentifier', path);
  }
}
