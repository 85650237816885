/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';

export interface RulesetErrorMessage {
  id: string;
  messages: string[];
}

export default class RulesetErrors extends Model {
  @attr('array', { defaultValue: () => [] }) declare messages: RulesetErrorMessage[];
}
