/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { stateChangeDescriptions } from 'embercom/models/data/inbox/state-change-descriptions';

import {
  type StateChangeReason,
  type StateChangeType,
  StateChangingEntity,
} from 'embercom/models/data/inbox/state-change-enums';

import AdminSummary, { type AdminSummaryWireFormat } from '../../admin-summary';
import { type DurationObject } from '../../duration';

import { type StateChangePart, type StateChangeWireFormat } from '../state-change';

export interface TeammateStateChangeContext {
  duration?: DurationObject;
  priority?: boolean;
}
export interface TeammateStateChangeWireFormat extends StateChangeWireFormat {
  changed_by_entity: AdminSummaryWireFormat;
  change_context: TeammateStateChangeContext;
}
export default class TeammateStateChange implements StateChangePart {
  readonly renderableType: RenderableType = RenderableType.StateChange;
  readonly changedByEntity: AdminSummary;
  readonly changeContext: TeammateStateChangeContext;
  readonly changedByEntityType = StateChangingEntity.Teammate;
  readonly change: StateChangeType;
  readonly reason: StateChangeReason;

  get teammate() {
    return this.changedByEntity;
  }

  get creatingEntity() {
    return { type: EntityType.Admin, id: this.teammate.id, object: this.teammate };
  }

  get eventDescription(): string {
    return stateChangeDescriptions[this.change][this.reason];
  }

  constructor(
    change: StateChangeType,
    reason: StateChangeReason,
    changedByEntity: AdminSummary,
    changeContext?: TeammateStateChangeContext,
  ) {
    this.change = change;
    this.reason = reason;
    this.changedByEntity = changedByEntity;

    if (changeContext) {
      this.changeContext = changeContext;
    } else {
      this.changeContext = {};
    }
  }

  static deserialize(json: TeammateStateChangeWireFormat): TeammateStateChange {
    if (json.changed_by_entity_type !== StateChangingEntity.Teammate) {
      throw new Error(
        `Attempted to create a TeammateStateChange from a response with changed entity type of '${json.changed_by_entity_type}'`,
      );
    }

    let adminSummary = AdminSummary.deserialize(json.changed_by_entity);
    return new TeammateStateChange(json.change, json.reason, adminSummary, json.change_context);
  }
}
