/* RESPONSIBLE TEAM: team-reporting */
import { next } from '@ember/runloop';
import { modifier } from 'ember-modifier';

export default modifier((element, [fn]) => {
  next(this, () => {
    if (element) {
      let overflowsHorizontally = element.scrollWidth > element.clientWidth;
      let overflowsVertically = element.scrollHeight > element.clientHeight;
      fn(overflowsHorizontally || overflowsVertically);
    }
  });
});
