/* RESPONSIBLE TEAM: team-help-desk-experience */
// @ts-ignore
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import { type InterfaceIconSet, type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export class ProfilePictureIcon {
  type = 'image' as const;
  url: string;

  constructor(url?: string) {
    this.url = url || DefaultAvatars.admins[128];
  }
}

export class EmojiIcon {
  type = 'emoji' as const;
  emoji: string | undefined;
  url: string | undefined;

  constructor(emoji: string | undefined) {
    this.emoji = emoji;

    if (emoji) {
      this.url = intermoji.twemojiSVGUri(emoji);
    }
  }
}

export class NamedIcon {
  type = 'icon' as const;
  name: InterfaceIconName;
  set: InterfaceIconSet;

  constructor(name: InterfaceIconName, set: InterfaceIconSet = 'standard') {
    this.name = name;
    this.set = set;
  }
}

export type Icon = ProfilePictureIcon | EmojiIcon | NamedIcon;
