/* RESPONSIBLE TEAM: team-workflows */
import { captureException } from 'embercom/lib/sentry';
import type TagModel from 'embercom/models/tag';
import { isPresent } from '@ember/utils';
// eslint-disable-next-line no-restricted-imports
import { getApp } from 'embercom/lib/container-lookup';

class Tag {
  declare app: any;
  declare actionType: string;

  constructor(actionType: string) {
    this.app = getApp();
    this.actionType = actionType;
  }

  getActionTagName(tagId: string): string {
    if (!tagId) {
      return '';
    }

    let tag = this.app.tags.findBy('id', tagId);

    if (!tag) {
      captureException(new Error('Unable to find corresponding tag for tag conversation action'));
      return '';
    }
    return tag.name;
  }

  getTagItems(selectableTags: TagModel[]) {
    return selectableTags.map((tag: TagModel) => {
      return {
        text: tag.name,
        value: tag.id,
      };
    });
  }

  getSelectableTags(allRuleActions: $TSFixMe[], action: $TSFixMe): TagModel[] {
    let allOtherTagActions = allRuleActions.filter(
      (otherAction) =>
        otherAction !== action &&
        otherAction.type === this.actionType &&
        isPresent(otherAction.get('actionData.tag_id')),
    );
    let allUsedTagsInActions = allOtherTagActions.map(
      (otherAction) => otherAction.actionData.tag_id,
    );
    return this.app.tags.reject((tag: TagModel) => {
      return (
        tag.id !== action.actionData.tag_id &&
        (tag.archived || allUsedTagsInActions.includes(tag.id))
      );
    });
  }
}

export default Tag;
