/* RESPONSIBLE TEAM: team-ai-agent */
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { type Block } from 'embercom/models/common/blocks/block';
import { buildValidations, validator } from 'ember-cp-validations';

const Validations = buildValidations({
  blocks: {
    validators: [
      validator('blocks-not-empty', {
        message: 'operator.workflows.visual-builder.validations.fin.blank-message-error',
        messageTranslated: true,
      }),
    ],
  },
});

export default class Part extends Fragment.extend(Validations) {
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: Block;

  get jsonBlocks() {
    return this.blocks.serialize();
  }
}
