/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type RenderableData } from '../renderable-data';

interface ChannelSwitchedRemovedWireFormat {
  renderable_type: RenderableType;
  channel: string;
}

export default class ChannelSwitched implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.ChannelSwitched;
  readonly channel: string;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(channel: string) {
    this.channel = channel;
  }

  static deserialize(json: ChannelSwitchedRemovedWireFormat): ChannelSwitched {
    return new ChannelSwitched(json.channel);
  }
}
