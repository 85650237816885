/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { ParentAction } from './action';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
export default class ConvertToCustomerTicket extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.ConvertToTicket];
  }

  id = 'convert-to-customer-ticket';
  icon = 'ticket' as const;
  paneComponent = 'inbox2/command-k/convert-to-ticket/pane';
}
