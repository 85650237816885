/* RESPONSIBLE TEAM: team-ai-agent */
import { modifier as buildModifier } from 'ember-modifier';
import { type EntityType } from 'embercom/models/data/entity-types';

function copilotInlineSources(
  element: HTMLElement,
  [onSourceHover, onSourceDismissHover, onSourceClick, onAnswerContentRender]: [
    (entityId: number, entityType: EntityType, event: PointerEvent, target: Element) => void,
    (event: PointerEvent) => void,
    (entityId: number, entityType: EntityType, passageId: string, event: PointerEvent) => void,
    ((firstCitationRect: DOMRect, answerCitationRect: DOMRect) => void)?,
  ],
) {
  let citationElements = element.querySelectorAll('.inline-citation');
  if (!citationElements) {
    return;
  }

  let onHover = (event: PointerEvent) => {
    if (!event.target) {
      return;
    }

    let target = event.target as Element & {
      attributes: {
        'data-entity-type': { value: string };
        'data-entity-id': { value: string };
      };
    };
    let entityType = target.attributes['data-entity-type'].value;
    let entityId = target.attributes['data-entity-id'].value;

    onSourceHover(Number(entityId), Number(entityType), event, target);
  };

  let onDismissHover = (event: PointerEvent) => {
    onSourceDismissHover(event);
  };

  let onClick = (event: PointerEvent) => {
    if (!event.currentTarget) {
      return;
    }

    let target = event.currentTarget as Element & {
      attributes: {
        'data-entity-type': { value: string };
        'data-entity-id': { value: string };
        'data-passage-id': { value: string };
      };
    };
    let entityType = target.attributes['data-entity-type'].value;
    let entityId = target.attributes['data-entity-id'].value;
    let passageId = target.attributes['data-passage-id']?.value;

    onSourceClick(Number(entityId), Number(entityType), passageId, event);
  };

  let onElementRender = () => {
    if (citationElements.length === 0 || !onAnswerContentRender) {
      return;
    }
    let firstCitationElementRect = citationElements[0].getBoundingClientRect();
    let answerContentElementRect = element.getBoundingClientRect();
    onAnswerContentRender(firstCitationElementRect, answerContentElementRect);
  };

  onElementRender();

  citationElements.forEach((citationElement) => {
    citationElement.addEventListener('mouseenter', onHover);
    citationElement.addEventListener('mouseleave', onDismissHover);
    citationElement.addEventListener('click', onClick);
  });

  return function cleanupObserver() {
    citationElements.forEach((citationElement) => {
      citationElement.removeEventListener('mouseenter', onHover);
      citationElement.removeEventListener('mouseleave', onDismissHover);
      citationElement.removeEventListener('click', onClick);
    });
  };
}

let modifier = buildModifier(copilotInlineSources, { eager: false });

export default modifier;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'copilot-inline-sources': typeof modifier;
  }
}
