/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { difference } from 'underscore';

export default function teamsThatWillBeEmpty(app, deletedTeammates) {
  // the teammate ids in the team model's member_ids list are numeric
  let teammateIds = deletedTeammates.map((teammate) => Number(teammate.id));

  return app.teams
    .filter((team) => team.teamMemberCount > 0 && team.teamMemberCount <= teammateIds.length)
    .filter((team) => difference(team.member_ids, teammateIds).length === 0);
}
