/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardSummaryConfiguration from '../../standard-summary-configuration';
import { not } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default StandardSummaryConfiguration.extend({
  init(inputs) {
    let title = inputs.title || 'Channel';
    this._super({
      title,
      component: 'content-editor/summaries/channel/custom-bot/summary',
    });
  },
  contentEditorService: service(),
  isVisible: not('contentEditorService.isSeriesMode').readOnly(),
});
