/* RESPONSIBLE TEAM: team-channels */
import Model, { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { put } from 'embercom/lib/ajax';

export default class EmailAutoReplySettings extends Model {
  @service appService;

  @attr('string') appId;
  @attr('boolean') autoReplyEnabled;
  @hasMany('email/auto-reply-localized-content', { async: false }) emailLanguages;

  get app() {
    return this.appService.app;
  }

  get hasUnsavedChanges() {
    return this.emailLanguages.any((language) => language.hasUnsavedChanges);
  }

  async updateSetting() {
    let params = {
      app_id: this.app.id,
      auto_reply_enabled: this.autoReplyEnabled,
    };
    let response = await put(`/ember/email_auto_reply_settings/update_setting`, params);
    this.store.pushPayload({ 'email-auto-reply-settings': response });
    return this.store.peekRecord('email-auto-reply-settings', this.id);
  }
}
