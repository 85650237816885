/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';
import { post } from 'embercom/lib/ajax';
import Em from 'ember';

const UNSUPPORTED_HEADER_FORMAT = ['image', 'document', 'video'];

export default class MessageTemplate extends Model {
  @attr('string') name;
  @attr('string') language;
  @attr('string') status;
  @attr('string') category;
  @attr('array') components;

  get locale() {
    return this.language?.replace('_', '-');
  }

  get body() {
    return this.components.find((component) => component.type.toLowerCase() === 'body');
  }

  get body_text() {
    return this.body?.text;
  }

  get bodyTextWithParameters() {
    return this.textWithParameters(this.body_text, this.body);
  }

  get header() {
    return this.components.find((component) => component.type.toLowerCase() === 'header');
  }

  get unsupportedHeader() {
    return this.components.find((component) => {
      return (
        component.type.toLowerCase() === 'header' &&
        UNSUPPORTED_HEADER_FORMAT.includes(component?.format?.toLowerCase())
      );
    });
  }

  get header_text() {
    return this.header?.text;
  }

  get headerTextWithParameters() {
    return this.textWithParameters(this.header_text, this.header);
  }

  get footer() {
    return this.components.find((component) => component.type.toLowerCase() === 'footer');
  }

  get footer_text() {
    return this.footer?.text;
  }

  get footerTextWithParameters() {
    return this.textWithParameters(this.footer_text, this.footer);
  }

  get buttons() {
    return this.components.find((component) => component.type.toLowerCase() === 'buttons')?.buttons;
  }

  get buttons_text() {
    return this.buttons?.map((button) => `[ ${button.text} ]`)?.join(' ');
  }

  get unsupportedButtons() {
    return Boolean(
      this.components.find((component) => {
        return (
          component.type.toLowerCase() === 'buttons' &&
          component.buttons?.some((button) => button.type.toLowerCase() === 'flow')
        );
      }),
    );
  }

  createBlock(type, text, className = '') {
    if (text) {
      return {
        type,
        text: this.textToHtml(text),
        ...(className && { class: className }), // Spread operator will add the class property only if className is truthy
      };
    }
    return null;
  }

  get blocksWithParameters() {
    return [
      this.createBlock('subheading', this.headerTextWithParameters),
      this.createBlock('paragraph', this.bodyTextWithParameters, 'no-margin'),
      this.createBlock('paragraph', this.footerTextWithParameters, 'no-margin'),
      this.createBlock('paragraph', this.buttons_text, 'no-margin'),
    ].filter(Boolean); // remove null entries
  }

  textWithParameters(text, component) {
    if (!text || !component) {
      return;
    }

    let parameters = component.parameters;
    if (parameters === undefined) {
      return text;
    }

    return this.replacePlaceholders(text, parameters);
  }

  replacePlaceholders(text, parameters) {
    return text.replace(/{{(\d+)}}/g, (_, number) => {
      let index = parseInt(number, 10) - 1;
      if (index < 0 || index >= parameters.length) {
        console.warn(`No replacement found for placeholder {{${number}}}`);
        return `{{${number}}}`;
      }
      if (!parameters[index].hasOwnProperty('text')) {
        console.warn(`Object at index ${index} does not have a 'text' property`);
        return `{{${number}}}`;
      }
      return parameters[index].text;
    });
  }

  get blocks() {
    let blocks = [];
    if (this.header_text) {
      blocks.push({ type: 'subheading', text: this.header_text });
    }
    if (this.body_text) {
      blocks.push({ type: 'paragraph', text: this.body_text, class: 'no-margin' });
    }
    if (this.footer_text) {
      blocks.push({ type: 'paragraph', text: this.footer_text, class: 'no-margin' });
    }
    if (this.buttons_text) {
      blocks.push({ type: 'paragraph', text: this.buttons_text, class: 'no-margin' });
    }
    return blocks;
  }

  get previewBlocks() {
    // TODO: extract repeated code from this and blocks
    let blocks = [];
    if (this.header_text) {
      blocks.push({
        type: 'paragraph',
        text: this.textToHtml(Em.Handlebars.Utils.escapeExpression(this.header_text)),
        class: 'font-bold header',
      });
    }
    if (this.body_text) {
      blocks.push({
        type: 'paragraph',
        text: this.textToHtml(Em.Handlebars.Utils.escapeExpression(this.body_text)),
        class: 'no-margin body',
      });
    }
    if (this.footer_text) {
      blocks.push({
        type: 'paragraph',
        text: this.textToHtml(Em.Handlebars.Utils.escapeExpression(this.footer_text)),
        class: 'no-margin footer',
      });
    }
    if (this.buttons_text) {
      blocks.push({
        type: 'paragraph',
        text: this.textToHtml(Em.Handlebars.Utils.escapeExpression(this.buttons_text)),
        class: 'no-margin buttons',
      });
    }
    return blocks;
  }

  textToHtml(text) {
    return text.replace(/\n/g, '<br />');
  }

  escapeParameters(text) {
    return text.replace(/{{/g, '[[').replace(/}}/g, ']]');
  }

  get analyticsData() {
    return {
      object: 'message_template',
      message_template_id: this.id,
    };
  }

  get isInvalid() {
    return !!this.unsupportedHeader || this.hasParameters();
  }

  hasParameters() {
    let parameterRegex = /{{.*?}}/g;
    return (
      !!this.header?.text.match(parameterRegex) ||
      !!this.body?.text.match(parameterRegex) ||
      !!this.footer?.text.match(parameterRegex) ||
      !!this.buttons_text?.match(parameterRegex)
    );
  }

  updateParameters(componentsWithParameters) {
    this.components.forEach((component) => {
      let updatedComponent = componentsWithParameters.find(
        (c) => c.type?.toLowerCase() === component.type?.toLowerCase(),
      );
      if (updatedComponent) {
        component.parameters = updatedComponent.parameters;
      }
    });
  }

  async send(params) {
    let { app, conversation } = params;
    let payload = {
      app_id: app.id,
      admin_id: app.currentAdmin.id,
      conversation_id: conversation.id,
      template_name: this.name,
      language_code: this.language,
    };
    await post(`/ember/whatsapp/message_templates/send_message_template`, payload);
  }
}
