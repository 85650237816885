/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { CONVERSATION_RATINGS } from 'predicates/models/data/conversation-ratings';
import { IOS_DEVICES, IOS_VERSIONS } from 'predicates/models/data/ios';

const stringComparisons = {
  comparison: [
    { label: 'is', value: 'eq', selected: false },
    { label: 'is not', value: 'ne', selected: false },
    { label: 'starts with', value: 'starts_with', selected: false },
    { label: 'ends with', value: 'ends_with', selected: false },
    { label: 'contains', value: 'contains', selected: false },
    { label: 'does not contain', value: 'not_contains', selected: false },
    { label: 'is unknown', value: 'unknown', selected: false, hideInput: true },
    { label: 'has any value', value: 'known', selected: false, hideInput: true },
  ],
};

const numericComparisons = {
  comparison: [
    { label: 'greater than', value: 'gt', selected: false },
    { label: 'less than', value: 'lt', selected: false },
    { label: 'is', value: 'eq', selected: false },
    { label: 'is not', value: 'ne', selected: false },
    { label: 'is unknown', value: 'unknown', selected: false, hideInput: true },
    { label: 'has any value', value: 'known', selected: false, hideInput: true },
  ],
};

const tagComparisons = {
  comparison: [
    { label: 'is', value: 'in', selected: false },
    { label: 'is not', value: 'nin', selected: false },
  ],
};

const numericTagComparisons = {
  comparison: [
    { label: 'is', value: 'eq', selected: false },
    { label: 'greater than', value: 'gt', selected: false },
    { label: 'less than', value: 'lt', selected: false },
  ],
};

const optionalComparisons = {
  comparison: [
    { label: 'is', value: 'eq', selected: false },
    { label: 'is not', value: 'ne', selected: false },
    { label: 'is unknown', value: 'unknown', selected: false, hideInput: true },
    { label: 'has any value', value: 'known', selected: false, hideInput: true },
  ],
};

const optionalTagComparisons = {
  comparison: [
    { label: 'is', value: 'eq', selected: false },
    { label: 'is not', value: 'ne', selected: false },
    { label: 'is unknown', value: 'unknown', selected: false, hideInput: true },
    { label: 'has any value', value: 'known', selected: false, hideInput: true },
  ],
};

const rangeComparisons = {
  comparison: [
    { label: 'is', value: 'eq', selected: false },
    { label: 'is more than', value: 'gt', selected: false },
    { label: 'is less than', value: 'lt', selected: false },
  ],
};

const urlComparisons = {
  comparison: [
    { value: 'eq', text: 'URL is', isSelected: true },
    { value: 'ne', text: 'URL is not' },
    { value: 'starts_with', text: 'URL starts with' },
    { value: 'ends_with', text: 'URL ends with' },
    { value: 'contains', text: 'URL contains' },
    { value: 'not_contains', text: 'URL does not contain' },
    {
      value: 'regex',
      text: 'URL matches regular expression',
      tooltipText:
        'Use this to handle complex URLs. We recommend rubular.com to create your regular expressions.',
    },
  ],
};

let PredicateFilterMap = {
  anonymous: {
    comparison: [
      { label: 'is', value: 'false' },
      { label: 'is', value: 'true' },
    ],
  },
  anonymousWithLabels: {
    comparison: [
      { label: 'is User', value: 'false' },
      { label: 'is Lead', value: 'true' },
    ],
  },
  integer: numericComparisons,
  float: numericComparisons,
  numeric: numericComparisons,
  date: {
    comparison: [
      {
        label: 'more than',
        value: 'lt:relative',
        selected: false,
        type: 'relative',
        comparison: 'lt',
      },
      {
        label: 'exactly',
        value: 'eq:relative',
        selected: false,
        type: 'relative',
        comparison: 'eq',
      },
      {
        label: 'less than',
        value: 'gt:relative',
        selected: false,
        type: 'relative',
        comparison: 'gt',
      },

      { label: 'after', value: 'gt:absolute', selected: false, type: 'absolute', comparison: 'gt' },
      { label: 'on', value: 'eq:absolute', selected: false, type: 'absolute', comparison: 'eq' },
      {
        label: 'before',
        value: 'lt:absolute',
        selected: false,
        type: 'absolute',
        comparison: 'lt',
      },

      {
        label: 'is unknown',
        value: 'unknown',
        selected: false,
        hideInput: true,
        comparison: 'unknown',
      },
      {
        label: 'has any value',
        value: 'known',
        selected: false,
        hideInput: true,
        comparison: 'known',
      },
    ],
    months: [
      { label: 'Jan', days: 31, value: 1 },
      { label: 'Feb', days: 29, value: 2 },
      { label: 'Mar', days: 31, value: 3 },
      { label: 'Apr', days: 30, value: 4 },
      { label: 'May', days: 31, value: 5 },
      { label: 'Jun', days: 30, value: 6 },
      { label: 'Jul', days: 31, value: 7 },
      { label: 'Aug', days: 31, value: 8 },
      { label: 'Sep', days: 30, value: 9 },
      { label: 'Oct', days: 31, value: 10 },
      { label: 'Nov', days: 30, value: 11 },
      { label: 'Dec', days: 31, value: 12 },
    ],
  },
  string: stringComparisons,
  downcased_string: stringComparisons,
  manual_tag: {
    comparison: [
      { label: 'is', value: 'eq', selected: false },
      { label: 'is not', value: 'ne', selected: false },
    ],
  },
  subscription_type: {
    comparison: [
      { label: 'is', value: 'in', selected: false },
      { label: 'is not', value: 'nin', selected: false },
    ],
  },
  numericTag: numericTagComparisons,
  browser_locale: optionalTagComparisons,
  boolean: {
    comparison: [
      { label: 'is true', value: 'true', selected: true, hideInput: true },
      { label: 'is false', value: 'false', selected: false, hideInput: true },
      { label: 'is unknown', value: 'unknown', selected: false, hideInput: true },
      { label: 'has any value', value: 'known', selected: false, hideInput: true },
    ],
  },
  plan: optionalTagComparisons,
  optionalTag: optionalTagComparisons,
  select: optionalTagComparisons,
  tag: tagComparisons,
  company_tag: tagComparisons,
  vip_query: {
    comparison: [
      { label: 'is true', value: 'known', selected: false },
      { label: 'is false', value: 'unknown', selected: false },
    ],
  },
  account_id: optionalComparisons,
  owner_id: optionalComparisons,
  ios_device: {
    comparison: [
      { label: 'is', value: 'in', selected: false },
      { label: 'is not', value: 'nin', selected: false },
    ],
    data: IOS_DEVICES,
  },
  last_conversation_rating_given: {
    comparison: [
      { label: 'is', value: 'eq', selected: false },
      { label: 'greater than', value: 'gt', selected: false },
      { label: 'less than', value: 'lt', selected: false },
    ],
    data: CONVERSATION_RATINGS,
  },
  ios_version: {
    comparison: [
      { label: 'is', value: 'eq', selected: false },
      { label: 'greater than', value: 'gt', selected: false },
      { label: 'less than', value: 'lt', selected: false },
    ],
    data: IOS_VERSIONS,
  },
  android_app_version: numericTagComparisons,
  android_device: tagComparisons,
  android_version: numericTagComparisons,
  duration_integer: {
    comparison: [{ label: 'is more than', value: 'eq', selected: false, suffix: 'second' }],
  },
  url: urlComparisons,
  url_visit_integer: rangeComparisons,
  url_visit: numericTagComparisons,
  over_messaging_protection: {
    comparison: [
      { label: 'is on', value: 'true', selected: true, hideInput: true },
      { label: 'is off', value: 'false', selected: false, hideInput: true },
    ],
    value: '', //Over-messaging protection should not display a value in it's description
  },
};

export default PredicateFilterMap;
