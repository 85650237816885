/* RESPONSIBLE TEAM: team-workflows */
import type SendTicket from 'embercom/models/operator/visual-builder/step/send-ticket';
import StepConfig, { type StepConfigParams } from '../step';

export default class SendTicketConfiguration extends StepConfig {
  declare step: SendTicket;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/send-ticket',
      ...params,
    });
  }

  get disabledComposerMenuConfigOptions() {
    return [this.deleteDisabledComposerOption];
  }

  get deleteDisabledComposerOption() {
    return {
      icon: 'trash',
      text: this.intl.t('operator.workflows.visual-builder.step-option-menu.delete'),
      onSelectItem: () => {
        this.step.disableComposer = false;
      },
    };
  }
}
