/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { readOnly } from '@ember/object/computed';

export default Model.extend({
  meetingDescription: attr(),
  meetingLink: attr(),
  connectedAccount: attr(),
  schedulingCalendarId: attr(),
  timezone: attr(),
  availabilities: fragmentArray('settings/time-period'),
  teammateCalendarUrl: attr('string'),
  accountEmail: readOnly('connectedAccount'),

  hasUnsavedChanges: readOnly('hasDirtyAttributes'),
});
