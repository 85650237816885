/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type Admin from 'embercom/models/admin';
import { type AccessType } from 'embercom/services/report-access-service';
import { isEmpty, isPresent } from '@ember/utils';
import type Role from 'embercom/models/role';

export default class ReportAccessControlList extends Model {
  @service appService: $TSFixMe;

  @attr('string') declare adminId?: string;
  @attr('string') declare reportId: string;
  @attr('string') declare accessType: AccessType;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('string') declare roleId?: string;

  get app() {
    return this.appService.app;
  }

  get admin(): Admin | null {
    if (this.adminId) {
      return this.store.peekRecord('admin', this.adminId);
    }
    return null;
  }

  get role(): Role | null {
    if (this.roleId) {
      return this.store.peekRecord('role', this.roleId);
    }
    return null;
  }

  get isWorkspaceAccessControl() {
    return isEmpty(this.adminId) && isEmpty(this.roleId);
  }

  get isAdminAccessControl() {
    return isPresent(this.adminId) && isEmpty(this.roleId);
  }

  get isRoleAccessControl() {
    return isEmpty(this.adminId) && isPresent(this.roleId);
  }

  isRoleAccessForAdminRole(admin: Admin): boolean {
    // we check isRoleAccessControl because `role_id` could be null. `null === null` is true
    return this.isRoleAccessControl && this.roleId === admin.currentAppPermissions?.role_id;
  }

  belongsToAdmin(admin: Admin): boolean {
    return this.isAdminAccessControl && this.adminId === admin.id;
  }
}
