/* RESPONSIBLE TEAM: team-customer-data-platform */
import Model, { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class SyncHealthData extends Model {
  @attr('string') sectionId;
  @fragmentArray('crm/log') logs;
  @attr('number') accountAttributeMappingCount;
  @attr('number') accountIdentityMappingCount;
  @attr('number') accountIdentityMappingVersion;
  @attr('string') accountIdentityMappingField;
  @attr('number') lastAccountTriggerEncounteredAt;
  @attr('boolean') throttled;
}
