/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import ModelWithPermissions from 'embercom/models/model-with-permissions';
import Validations from 'embercom/validations/role';
import { getApp } from 'embercom/lib/container-lookup';
import { fragment } from 'ember-data-model-fragments/attributes';
import { isPresent } from '@ember/utils';

export default class Role extends ModelWithPermissions.extend(Validations) {
  @service appService;
  @service intl;

  @attr('string', { defaultValue: '' }) name;
  @attr('string', { defaultValue: '' }) description;
  @attr('string', { defaultValue: 'all' }) accessType;
  @attr('boolean', { defaultValue: false }) includeUnassigned;
  @attr('number') conversationCapacity;
  @fragment('predicates/predicate-group') defaultPredicatesGroup;
  @attr('boolean', { defaultValue: true }) defaultPredicatesModifiable;

  @attr({ defaultValue: () => [] }) assigneeBlockedList;

  get admins() {
    let id = Number.parseInt(this.id, 10);
    return getApp().humanAdmins.filter(
      (admin) => Number.parseInt(admin.currentAppPermissions?.role_id, 10) === id,
    );
  }

  get conversationAccessHasChanged() {
    let changes = this.changedAttributes();
    return (
      'accessType' in changes ||
      (this.accessType === 'all_except' && 'assigneeBlockedList' in changes) ||
      (['only_them', 'only_them_or_their_teams'].includes(this.accessType) &&
        'includeUnassigned' in changes)
    );
  }

  get copilotUsageAccessHasChanged() {
    let changes = this.changedAttributes();
    return 'can_inbox__access_copilot' in changes;
  }

  get app() {
    return this.appService.app;
  }

  validate() {
    if (this.accessType === 'all_except' && !this.assigneeBlockedList?.length > 0) {
      throw new Error(this.intl.t('app.models.role.notifications.error'));
    }
  }

  // as Record<string, number>
  static adminPerRole() {
    return getApp()
      .humanAdmins.filter((admin) => isPresent(admin?.currentAppPermissions?.role_id))
      .reduce((byRoleIds, admin) => {
        let prevCount = byRoleIds[admin.currentAppPermissions.role_id] ?? 0;
        byRoleIds[admin.currentAppPermissions.role_id] = prevCount + 1;
        return byRoleIds;
      }, {});
  }
}
