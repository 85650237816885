/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type ConversationSlaObject } from 'embercom/objects/inbox/interfaces/conversation_sla';
import { type RuleObject } from 'embercom/objects/inbox/interfaces/rule';
import CustomBotSummary, { type CustomBotSummaryWireFormat } from '../custom-bot-summary';
interface ConversationSlaAppliedByRuleWireFormat {
  renderable_type: RenderableType;
  rule: RuleObject;
  conversation_sla: ConversationSlaObject;
  custom_bot_summary?: CustomBotSummaryWireFormat;
}

export default class ConversationSlaAppliedByRule {
  readonly renderableType: RenderableType = RenderableType.ConversationSlaAppliedByRule;
  readonly rule: RuleObject;
  readonly conversationSla: ConversationSlaObject;
  readonly customBotSummary?: CustomBotSummary;

  constructor(
    rule: RuleObject,
    conversationSla: ConversationSlaObject,
    customBotSummary?: CustomBotSummary,
  ) {
    this.rule = rule;
    this.conversationSla = conversationSla;
    this.customBotSummary = customBotSummary;
  }

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  static deserialize(json: ConversationSlaAppliedByRuleWireFormat): ConversationSlaAppliedByRule {
    return new ConversationSlaAppliedByRule(
      json.rule,
      json.conversation_sla,
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
    );
  }
}
