/* RESPONSIBLE TEAM: team-help-desk-experience */
import moment from 'moment-timezone';

export enum DurationType {
  LaterToday = 1,
  Tomorrow = 2,
  Monday = 3,
  NextWeek = 4,
  NextMonth = 5,
  CustomTime = 6,
}

export class DurationObject {
  constructor(type: DurationType, time?: string, timezone?: string) {
    this.type = type;

    if (time) {
      this.time = time;
    } else {
      this.time = calculateUnsnoozeTime(type).toISOString();
    }

    if (timezone) {
      this.timezone = timezone;
    } else {
      //@ts-ignore
      this.timezone = moment.tz.guess();
    }
  }

  type: DurationType;
  time: string;
  timezone: string;
}

export function getDurationType(duration: string) {
  switch (duration) {
    case 'later_today':
      return DurationType.LaterToday;
    case 'tomorrow':
      return DurationType.Tomorrow;
    case 'monday':
      return DurationType.Monday;
    case 'next_week':
      return DurationType.NextWeek;
    case 'next_month':
      return DurationType.NextMonth;
    case 'custom_timer':
      return DurationType.CustomTime;
    default:
      throw new Error(`unknown duration type: ${duration}`);
  }
}

export function calculateUnsnoozeTime(
  type: DurationType,
  date?: moment.Moment,
  secondsFromMidnight?: number,
): moment.Moment {
  switch (type) {
    case DurationType.LaterToday:
      return moment().add(3, 'hours');

    case DurationType.Tomorrow:
      return moment().startOf('day').add(1, 'day').add(9, 'hours');

    case DurationType.Monday:
      return moment().startOf('isoWeek').add(1, 'week').add(9, 'hours');

    case DurationType.NextWeek:
      return moment().add(1, 'week');

    case DurationType.NextMonth:
      return moment().startOf('day').add(1, 'month').add(9, 'hours');

    case DurationType.CustomTime:
      if (!date || typeof secondsFromMidnight !== 'number') {
        throw new Error('Custom snooze must have a date and secondsFromMidnight value');
      }
      return moment(date).startOf('day').add(secondsFromMidnight, 'seconds');

    default:
      throw new Error(
        `Cannot automatically calculate the unsnooze time for a duration of type '${type}'`,
      );
  }
}
