/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { attr } from '@ember-data/model';
import HelpCenterBlock from './help-center-block';

export enum CollectionListTemplate {
  Classic = 0,
  Visual = 1,
  Compact = 2,
}

export default class CollectionList extends HelpCenterBlock {
  @attr('string') declare type: 'collection-list';
  @attr('number', { defaultValue: 0 }) declare template: CollectionListTemplate;

  get enabled() {
    return true;
  }

  get disableable(): boolean {
    return false;
  }

  get title(): string {
    return this.intl.t('articles.settings.stylings.layout-and-sections.collections.header');
  }

  preview(_locale: string) {
    return {
      type: this.type,
      columns: this.columns,
      template: this.template,
    };
  }
}
