/* RESPONSIBLE TEAM: team-frontend-tech */
const EMAIL_REGEXP =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0 -9])?/;

export function redactEmailFromUrl(url) {
  let parts = url.split('?');
  let urlParts = parts[0].split('/');
  let queryParts = parts[1] ? parts[1].split('&') : [];
  let queryKeyValues = queryParts.map((part) => part.split('='));

  let redactedUrl = redactFromStrings(urlParts).join('/');
  let redactedQueryParts = queryKeyValues.map((keyValue) => redactFromStrings(keyValue).join('='));
  let redactedQuery = redactFromStrings(redactedQueryParts).join('&');

  return `${redactedUrl}${redactedQuery ? `?${redactedQuery}` : ''}`;
}

// Recursively mutates array of strings by redacting matches of redactRegExp
function redactFromStrings(strings, redactRegExp = EMAIL_REGEXP) {
  for (let i = 0; i < strings.length; i++) {
    if (Array.isArray(strings[i])) {
      redactFromStrings(strings[i]);

      // eslint-disable-next-line no-continue
      continue;
    }
    if (redactRegExp.test(strings[i])) {
      strings.splice(i, 1, '[FILTERED]');
    }
  }

  return strings;
}
