/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';

export default class WhatsappSettings extends Model {
  @attr('string') appId;
  @attr('boolean') messengerIntegration;
  @attr('string') messengerIntegrationId;
  @attr('boolean') phoneBasedUserAssociation;
  @attr('number') delineationThresholdInSeconds;
}
