/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import GraphConfiguration from 'embercom/objects/series/node/graph-configuration';
import ConditionNodeComponentConfiguration from 'embercom/objects/series/node/condition-node-component-configuration';
import StandardConnectorConfiguration from 'embercom/objects/series/node/standard-connector-configuration';
import NotMatchedConnectorConfiguration from 'embercom/objects/series/node/not-matched-connector-configuration';
import ComponentConfiguration from 'embercom/objects/component-configuration';
import StatsConfiguration from 'embercom/objects/series/node/stats-configuration';
import { isPresent } from '@ember/utils';
import {
  objectTypes,
  seriesEdgeTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { dependentKeyCompat } from '@ember/object/compat';
import { defaultDelayDescription } from 'embercom/lib/series/constants';
import { capitalize } from '@ember/string';

export default class ConditionGraphConfiguration extends GraphConfiguration {
  objectType = objectTypes['condition'];

  constructor(seriesNode) {
    super(...arguments);
    this.componentConfig = new ConditionNodeComponentConfiguration({
      node: seriesNode,
      icon: 'series/content/condition',
      objectType: this.objectType,
      delayConfigurationComponent: new ComponentConfiguration({
        component: 'series/node/condition-delay-editor',
      }),
      statsSummaryConfigurationComponent: new StatsConfiguration({
        verb: 'matched',
        component: 'series/node/standard-stats-summary',
      }),
    });
  }

  @dependentKeyCompat
  get title() {
    if (isPresent(this.node.rulesetClientData.title) && !this.isUntitledCopy) {
      return this.node.rulesetClientData.title;
    }

    if (this.inferredTitle) {
      return this.inferredTitle + this.titleSuffix;
    }

    return this.defaultTitle + this.titleSuffix;
  }

  get defaultTitle() {
    return 'Empty rules';
  }

  get inferredTitle() {
    let predicateGroup = this.node.contentPreviews.firstObject?.predicateGroup;
    let descriptions = [];

    if (predicateGroup) {
      // If we have a starting node, we want to show all predicates including the role predicate
      if (
        this.node.isStarting &&
        this.node.rulesetClientData.openedSeriesEditor &&
        isPresent(predicateGroup.predicates)
      ) {
        this._generateSummary(predicateGroup.predicates, descriptions);
        return capitalize(descriptions.join(', '));
      }

      // Otherwise summarise only the 'editable' predicates. This prevents "Type User, Visitor, Lead"
      // being prepended to every title.
      if (isPresent(predicateGroup.editablePredicates)) {
        this._generateSummary(predicateGroup.editablePredicates, descriptions);
        return capitalize(descriptions.join(', '));
      }
    }
  }

  _generateSummary(predicates, descriptions) {
    predicates.forEach((predicate) => {
      if (predicate.isLogicalType) {
        this._generateSummary(predicate.predicates, descriptions);
      } else {
        descriptions.push(predicate.descriptionWithName);
      }
    });
  }

  get connectors() {
    let connectors = [
      new StandardConnectorConfiguration({
        label: 'WHEN MATCHED',
        edgeType: seriesEdgeTypes.primary,
      }),
    ];

    // In cases where have have an internal condition with a false path and we delete the preceeding
    // Node to make it a starting node, we don't want to hide the connector.
    if (!this.node.isStarting || this.node.outwardEdges.any((edge) => edge.isAlternative)) {
      connectors.push(
        new NotMatchedConnectorConfiguration({
          node: this.node,
          edgeType: seriesEdgeTypes.alternative,
        }),
      );
    }

    return connectors;
  }

  get primaryPredecessorLabel() {
    return `Matched '${this.title}'`;
  }

  get alternativePredecessorLabel() {
    return `Did not match '${this.title}'`;
  }

  get checkpointStats() {
    return [
      { label: 'matched', value: this.node.completedCheckpointCount },
      { label: "didn't match", value: this.node.completedTraversedAlternativeEdgesCheckpointCount },
      { label: "didn't match and left path", value: this.node.completedEjectedCheckpointCount },
      { label: 'trying to match', value: this.node.activeCheckpointCount },
      {
        label: [`didn't match within ${this.node.delayDescription || defaultDelayDescription}`],
        value: this.node.expiredCheckpointCount,
      },
      { label: 'left path', value: this.node.exitedCheckpointCount },
    ];
  }
}
