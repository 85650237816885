/* RESPONSIBLE TEAM: team-purchase-experience */

export interface IntershopPlanCardData {
  nameWithProduct: string;
  description: string;
  planSvg?: string;
  features?: Array<string>;
  prefix?: string;
}

export default {};
