/* RESPONSIBLE TEAM: team-help-desk-experience */
import { StateChangeReason, StateChangeType } from 'embercom/models/data/inbox/state-change-enums';

const openChangeDescriptions: Record<StateChangeReason, string> = {
  [StateChangeReason.StandardStateChange]: 'inbox.opened-conversation',
  [StateChangeReason.ChangedDueToReply]: 'inbox.replied-and-opened-conversation',
  [StateChangeReason.ChangedDueToUserArchival]: 'inbox.unexpected-event',
  [StateChangeReason.TimerFinished]: 'inbox.unexpected-event',
};

const closeChangeDescriptions: Record<StateChangeReason, string> = {
  [StateChangeReason.StandardStateChange]: 'inbox.closed-conversation',
  [StateChangeReason.ChangedDueToUserArchival]: 'inbox.closed-due-to-archival',
  [StateChangeReason.ChangedDueToReply]: 'inbox.replied-and-closed-conversation',
  [StateChangeReason.TimerFinished]: 'inbox.unexpected-event',
};

const snoozeChangeDescriptions: Record<StateChangeReason, string> = {
  [StateChangeReason.StandardStateChange]: 'inbox.snoozed-conversation',
  [StateChangeReason.ChangedDueToReply]: 'inbox.replied-and-snoozed-conversation',
  [StateChangeReason.ChangedDueToUserArchival]: 'inbox.unexpected-event',
  [StateChangeReason.TimerFinished]: 'inbox.unexpected-event',
};

const priorityDescriptions: Record<StateChangeReason, string> = {
  [StateChangeReason.StandardStateChange]: 'inbox.marked-as-priority-conversation',
  [StateChangeReason.ChangedDueToReply]: 'inbox.replied-and-marked-as-priority-conversation',
  [StateChangeReason.ChangedDueToUserArchival]: 'inbox.unexpected-event',
  [StateChangeReason.TimerFinished]: 'inbox.unexpected-event',
};

const notPriorityDescriptions: Record<StateChangeReason, string> = {
  [StateChangeReason.StandardStateChange]: 'inbox.marked-as-not-priority-conversation',
  [StateChangeReason.ChangedDueToReply]: 'inbox.replied-and-marked-as-not-priority-conversation',
  [StateChangeReason.ChangedDueToUserArchival]: 'inbox.unexpected-event',
  [StateChangeReason.TimerFinished]: 'inbox.unexpected-event',
};

const unsnoozeChangeDescriptions: Record<StateChangeReason, string> = {
  [StateChangeReason.StandardStateChange]: 'inbox.unsnoozed-conversation',
  [StateChangeReason.ChangedDueToReply]: 'inbox.replied-and-unsnoozed-conversation',
  [StateChangeReason.TimerFinished]: 'inbox.unsnoozed-conversation-automatically',
  [StateChangeReason.ChangedDueToUserArchival]: 'inbox.unexpected-event',
};

export const stateChangeDescriptions: Record<StateChangeType, Record<StateChangeReason, string>> = {
  [StateChangeType.Opened]: openChangeDescriptions,
  [StateChangeType.Closed]: closeChangeDescriptions,
  [StateChangeType.Snoozed]: snoozeChangeDescriptions,
  [StateChangeType.Unsnoozed]: unsnoozeChangeDescriptions,
  [StateChangeType.Priority]: priorityDescriptions,
  [StateChangeType.NotPriority]: notPriorityDescriptions,
};
