/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import MESSAGE_CONSTANTS from 'embercom/models/data/messages/message-constants';

const DEFAULT_VARIATION_BLOCK = {
  text: 'Hi there,',
  type: 'paragraph',
};

const VARIATION_DEFAULTS = {
  messageStyle: MESSAGE_CONSTANTS.noStyle,
  subject: '',
  deliveryOption: 'full',
  pointerSelector: null,
  replyType: MESSAGE_CONSTANTS.textReply,
  blocks: [
    {
      text: 'Hi {{first_name|fallback:"there"}},',
      type: 'paragraph',
    },
  ],
  pushOnlyURIs: null,
  reactions: [],
};

const DEFAULT_CUSTOM_DELIVERY_WINDOW = {
  deliveryPeriod: 'custom',
  mondayDelivery: true,
  tuesdayDelivery: true,
  wednesdayDelivery: true,
  thursdayDelivery: true,
  fridayDelivery: true,
  saturdayDelivery: false,
  sundayDelivery: false,
  deliverySecondsSinceMidnightStart: 32400, //9AM
  deliverySecondsSinceMidnightEnd: 61200, //5PM
};

const DEFAULT_OFFICE_HOURS_DELIVERY_WINDOW = {
  deliveryPeriod: 'during_office_hours',
  mondayDelivery: true,
  tuesdayDelivery: true,
  wednesdayDelivery: true,
  thursdayDelivery: true,
  fridayDelivery: true,
  saturdayDelivery: false,
  sundayDelivery: false,
  deliverySecondsSinceMidnightStart: 32400, //9AM
  deliverySecondsSinceMidnightEnd: 61200, //5PM
};

const INITIAL_PUSH_DEFAULTS = {
  pushBlocks: [
    {
      type: 'heading',
      text: '',
    },
    {
      type: 'paragraph',
      text: '',
    },
  ],
  pushURIs: {
    ios: [],
    android: [],
  },
};

const INITIAL_POINTER_DEFAULTS = {
  deliveryOption: 'full',
  deliveryChannel: MESSAGE_CONSTANTS.webChannel,
};

const INITIAL_STORY_DEFAULTS = {
  messageStyle: MESSAGE_CONSTANTS.video,
  deliveryOption: 'full',
  replyType: MESSAGE_CONSTANTS.textReply,
  videoFileBlock: {
    type: 'videoFile',
  },
  reactions: [],
};

const DEFAULT_TIME_ON_PAGE_PREDICATE = {
  attribute: 'client_attributes.time_on_page',
  type: 'duration_integer',
  comparison: 'eq',
  value: null,
};

const DEFAULT_PAGE_TARGET_PREDICATE = {
  attribute: 'client_attributes.last_visited_url',
  type: 'string',
  comparison: 'eq',
  value: '',
};

const EXCLUDE_DISENGAGED_USERS_PREDICATE = {
  type: 'email_disengagement',
  attribute: 'consecutive_unread_email_count',
  value: 20,
  comparison: 'lt',
};

export default {
  variationA: VARIATION_DEFAULTS,
  variationB: VARIATION_DEFAULTS,
  deliveryWindow: null,
  pageTarget: { pageTargetType: 0, pageTargetString: null },
  sendPushNotification: false,
  deliveryChannel: MESSAGE_CONSTANTS.noChannel,
};

export {
  DEFAULT_CUSTOM_DELIVERY_WINDOW,
  DEFAULT_OFFICE_HOURS_DELIVERY_WINDOW,
  INITIAL_PUSH_DEFAULTS,
  DEFAULT_VARIATION_BLOCK,
  INITIAL_STORY_DEFAULTS,
  VARIATION_DEFAULTS,
  INITIAL_POINTER_DEFAULTS,
  DEFAULT_TIME_ON_PAGE_PREDICATE,
  EXCLUDE_DISENGAGED_USERS_PREDICATE,
  DEFAULT_PAGE_TARGET_PREDICATE,
};
