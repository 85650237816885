/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ParentAction } from './action';
import { inject as service } from '@ember/service';
import type WhatsappTemplates from 'embercom/services/whatsapp-templates';
import type InboxState from 'embercom/services/inbox-state';

export default class InsertWhatsappTemplateAction extends ParentAction {
  @service declare whatsappTemplates: WhatsappTemplates;
  @service declare inboxState: InboxState;

  id = 'insert-whatsapp-template';
  icon = 'whatsapp' as const;
  paneComponent = 'inbox2/command-k/whatsapp/insert-template/pane';
}
