/* RESPONSIBLE TEAM: team-knowledge-interop */
// from app/lib/content_service/models/content_import_run.rb

export enum TriggerSources {
  MarketingSite = 0,
  AdminInitiated = 1,
  AdminTool = 2,
  LoadTest = 3,
  OnboardingHome = 7,
}
