/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const defaultParagraph = {
  type: 'paragraph',
  text: 'Use short body text, images (including gifs), and images with text to add detail. Be as short and simple as possible. Focus on one key thing that will make your user successful per screen.',
};

export const defaultTitle = {
  type: 'subheading',
  text: 'Titles make the message clear',
};
