/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { fragment } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type SubheaderStyle from '../styles/subheader';
import { attr } from '@ember-data/model';

export default class Subheader extends Fragment {
  @attr('boolean') declare enabled: boolean;
  @fragment('customization-options/styles/subheader') declare style: SubheaderStyle;

  setDefaultValues() {
    if (!this.style) {
      this.style = this.store.createFragment('customization-options/styles/subheader');
    }

    if (!this.style.backgroundColor) {
      this.style.backgroundColor = '#000000';
    }

    if (!this.style.fontColor) {
      this.style.fontColor = '#FFFFFF';
    }
  }
}
