/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import ModelWithPermissions from 'embercom/models/model-with-permissions';
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import ajax from 'embercom/lib/ajax';

export default class InvitedAdmin extends ModelWithPermissions {
  @attr() email;
  @attr() app_id;
  @attr() active;
  @attr() role_id;
  @attr('date') expired_at;
  @attr('boolean', { defaultValue: false }) has_inbox_access;
  @attr('string', { defaultValue: 'all' }) accessType;
  @attr('boolean', { defaultValue: false }) includeUnassigned;
  @attr({ defaultValue: () => [] }) assigneeBlockedList;
  @attr('array') seatTypes;
  @attr('string') locale;
  @attr('string') token;

  get hasInboxAccess() {
    return this.has_inbox_access;
  }

  get role() {
    return this.role_id ? this.store.peekRecord('role', this.role_id) : null;
  }

  avatarData = {
    url: DefaultAvatars.admins[128],
    showAsActive: false,
    showAsAway: false,
    isCustomer: false,
    isTeammate: true,
    isCompany: false,
    deleted: false,
    shouldAnimateUpdate: false,
  };

  resend() {
    return ajax({
      url: `/ember/invited_admins/resend`,
      type: 'POST',
      data: JSON.stringify({ id: this.id, app_id: this.app_id }),
    });
  }
}
