/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';

export type ReplyOption = {
  id: string;
  text: string;
};

interface QuickReplyOptionsWireFormat {
  renderable_type: RenderableType;
  reply_options: Array<ReplyOption>;
}

export default class QuickReplyOptions implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.QuickReplyOptions;
  readonly replyOptions: Array<object>;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(replyOptions: Array<ReplyOption>) {
    this.replyOptions = replyOptions;
  }

  static deserialize(json: QuickReplyOptionsWireFormat): QuickReplyOptions {
    return new QuickReplyOptions(json.reply_options);
  }
}
