/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import PanelConfiguration from 'embercom/objects/content-editor/panel-configuration';
import ScheduleHeaderConfiguration from 'embercom/objects/content-editor/panels/schedule/standard-schedule-header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import { assert } from '@ember/debug';
import { timezoneTypes } from 'embercom/models/data/matching-system/matching-constants';

export default PanelConfiguration.extend({
  init(inputs) {
    // The container gives us access to Services in these objects. Pass `container: getOwner(this)` as an
    // input when generating these configuration objects.
    assert('You must provide the application container in your panel inputs', inputs.container);
    let frequencyEditorTitle = inputs.showFrequencyEditor ? `Frequency and scheduling` : undefined;
    let header = ScheduleHeaderConfiguration.create(
      {
        title: frequencyEditorTitle || inputs.title,
        verb: inputs.verb,
      },
      { container: inputs.container },
    );
    let body = ComponentConfiguration.create({
      component: 'content-editor/panels/schedule/body',
      showFrequencyEditor: inputs.showFrequencyEditor,
      timezoneType: inputs.timezoneType || timezoneTypes.basedOnAppTimezone,
      scheduleEditorConfiguration: inputs.scheduleEditorConfiguration,
      verb: inputs.verb,
    });
    this._super({
      name: 'schedule',
      header,
      body,
    });
  },
});
