/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';

export default Model.extend({
  url: attr('string'),
  hubSecret: attr('string'),
  updatedAt: attr('date'),
  topics: hasMany('developer-hub/webhook-topic'),
  appId: attr('string'),
  eventName: attr('string'),
  stats: attr(),

  ping() {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);
    let url = `${adapter.buildURL(modelName, this.id)}/ping`;
    return adapter.ajax(url, 'POST');
  },
});
