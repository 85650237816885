/* RESPONSIBLE TEAM: team-proactive-support */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';

export default class TrackedChanges extends Fragment {
  @attr('array') declare newNodeIds: string[] | null;

  get hasNewChanges() {
    return isPresent(this.newNodeIds);
  }
}
