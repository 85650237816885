/* RESPONSIBLE TEAM: team-tickets-1 */
import { tracked } from '@glimmer/tracking';
import { ChannelData, type ChannelDataWireFormat } from 'embercom/models/data/inbox/channels';
import RenderablePart, {
  type RenderablePartWireFormat,
} from 'embercom/objects/inbox/renderable-part';
import UserSummary, { type UserSummaryWireFormat } from './user-summary';
import Conversation, { type ConversationWireFormat } from './conversation';
import ParticipantUserSummary, { firstConversationParticipant } from './participant-user-summary';

export interface SideConversationContructorFormat {
  id: number;
  title: string;
  lastRenderablePart: RenderablePart;
  renderableParts?: Array<RenderablePart>;
  userSummary: UserSummary;
  channel: ChannelData;
  participantSummaries: ParticipantUserSummary[];
  lastSeenByAdminAt?: Date;
  latestAdminVisibleCommentAt?: Date;
}

export interface SideConversationWireFormat {
  id: number | string;
  title: string;
  last_renderable_part: RenderablePartWireFormat;
  renderable_parts?: Array<RenderablePartWireFormat>;
  user_summary: UserSummaryWireFormat;
  channel: ChannelDataWireFormat;
  participant_summaries: UserSummaryWireFormat[];
  last_seen_by_admin_at?: string;
  latest_admin_visible_comment_at?: string;
}

export default class SideConversation {
  readonly id: number;
  readonly title?: string;
  @tracked lastRenderablePart: RenderablePart;
  @tracked renderableParts?: Array<RenderablePart>;
  @tracked userSummary: UserSummary;
  @tracked channel: ChannelData;
  @tracked participantSummaries: Array<ParticipantUserSummary>;
  @tracked lastSeenByAdminAt?: Date;
  @tracked latestAdminVisibleCommentAt?: Date;
  @tracked isRead = false;

  constructor(inputs: SideConversationContructorFormat) {
    let {
      id,
      title,
      lastRenderablePart,
      renderableParts,
      userSummary,
      channel,
      participantSummaries,
      lastSeenByAdminAt,
      latestAdminVisibleCommentAt,
    } = inputs;

    this.id = id;
    this.title = title;
    this.lastRenderablePart = lastRenderablePart;
    this.renderableParts = renderableParts;
    this.userSummary = userSummary;
    this.channel = channel;
    this.participantSummaries = participantSummaries;
    this.lastSeenByAdminAt = lastSeenByAdminAt;
    this.latestAdminVisibleCommentAt = latestAdminVisibleCommentAt;
    this.isRead = this.hasBeenRead;
  }

  static deserialize(json: SideConversationWireFormat) {
    let lastRenderablePart = RenderablePart.deserialize(json.last_renderable_part);
    let renderableParts = json.renderable_parts
      ? json.renderable_parts.map(RenderablePart.deserialize)
      : undefined;
    let userSummary = UserSummary.deserialize(json.user_summary);
    let channel = ChannelData.deserialize(json.channel);
    let participantSummaries = json.participant_summaries
      .compact()
      .map(ParticipantUserSummary.deserialize);
    let lastSeenByAdminAt = json.last_seen_by_admin_at
      ? new Date(json.last_seen_by_admin_at)
      : undefined;
    let latestAdminVisibleCommentAt = json.latest_admin_visible_comment_at
      ? new Date(json.latest_admin_visible_comment_at)
      : undefined;

    return new SideConversation({
      id: Number(json.id),
      title: json.title,
      lastRenderablePart,
      renderableParts,
      userSummary,
      channel,
      participantSummaries,
      lastSeenByAdminAt,
      latestAdminVisibleCommentAt,
    });
  }

  /**
   * Components needed to handle side conversations deal with Conversation objects.
   * Deserializing a side conversation to conversation makes it easier for us to re-use existing components.
   */
  static deserializeToConversation(json: ConversationWireFormat) {
    let sideConversation = Conversation.deserialize(json);
    sideConversation.setIsSideConversation(true);
    return sideConversation;
  }

  get hasBeenRead() {
    if (this.lastSeenByAdminAt && this.latestAdminVisibleCommentAt) {
      if (this.lastRenderablePart) {
        return this.lastSeenByAdminAt >= this.lastRenderablePart.createdAt;
      } else {
        return this.lastSeenByAdminAt >= this.latestAdminVisibleCommentAt;
      }
    }
    return this.isRead;
  }

  get participantsCount() {
    return this.participantSummaries.length;
  }

  get hasMultipleParticipants() {
    return this.participantSummaries.length > 1;
  }

  get otherParticipantsCount() {
    return this.participantsCount - 1;
  }

  get firstParticipant(): ParticipantUserSummary {
    return firstConversationParticipant(this.participantSummaries) ?? this.userSummary;
  }
}
