/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default [
  {
    key: 'javascript',
    displayName: 'Basic JavaScript',
    instructions:
      'Start sending your attributes to Intercom, just copy the code below and add it to the ${code} object in your JavaScript snippet.',
    code: 'intercomSettings',
  },
  {
    key: 'singlePage',
    displayName: 'Single-page web app',
    instructions:
      'Start sending your attributes to Intercom, just copy the code below and add the values when you call ${code}.',
    code: "Intercom('boot')",
  },
  {
    key: 'rails',
    displayName: 'Rails',
    instructions:
      'Start sending your attributes to Intercom, just copy the code below and add the values to the config file at ${code}.',
    code: 'config/initializers/intercom.rb',
  },
  {
    key: 'ios',
    displayName: 'iOS',
    instructions:
      'Start sending your attributes to Intercom, just copy the code below and pass the ${code} dictionary.',
    code: 'custom_attributes',
  },
  {
    key: 'android',
    displayName: 'Android',
    instructions:
      'Start sending your attributes to Intercom, just copy the code below and pass the ${code} dictionary.',
    code: 'custom_attributes',
  },
  {
    key: 'cordova',
    displayName: 'Cordova/PhoneGap',
    instructions:
      'Start sending your attributes to Intercom, just copy the code below and pass the ${code} dictionary.',
    code: 'custom_attributes',
  },
];
