/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';

// update routes below for each offer once they are created
const OFFER_STATIC_DATA = {
  extend_trial: {
    name: 'Extend your trial',
    icon: 'conversation-rating',
    description: 'Still not sure? Get another 14 days for free.',
    route: 'extend-trial',
    redeemOnClick: true,
  },
  get_help: {
    name: 'Get help setting up Intercom',
    icon: 'messenger',
    description: "You don't have to figure it out alone. Our team can help!",
    route: 'get-help',
    redeemOnClick: true,
  },
  downsize_products: {
    name: 'Reduce the cost of Intercom',
    icon: 'acquire',
    description: 'Lower your monthly bill by downgrading from products you aren’t using.',
    route: 'downsize',
    redeemOnClick: false,
  },
  pause_subscription: {
    name: 'Pause your subscription',
    icon: 'schedule',
    description: 'Put your account on hold but keep your setup and data.',
    route: 'pause-subscription',
    redeemOnClick: false,
  },
};

export default Model.extend({
  valid: attr('boolean'),
  type: attr('string'),
  accepted: attr('boolean'),
  metadata: attr(),
  name: computed('id', function () {
    return OFFER_STATIC_DATA[this.id].name;
  }),
  icon: computed('id', function () {
    return OFFER_STATIC_DATA[this.id].icon;
  }),
  description: computed('id', function () {
    return OFFER_STATIC_DATA[this.id].description;
  }),
  route: computed('id', function () {
    return OFFER_STATIC_DATA[this.id].route;
  }),
  redeemOnClick: computed('id', function () {
    return OFFER_STATIC_DATA[this.id].redeemOnClick;
  }),
});
