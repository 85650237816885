/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { not } from '@ember/object/computed';

export default Model.extend({
  enabled: attr('boolean'),
  enabledForLeads: attr('boolean'),
  enabledForUsers: attr('boolean'),

  isValid: true,
  isNotDirty: not('hasDirtyAttributes'),

  refresh() {
    this.rollbackAttributes();
  },
});
