/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { post, put, get } from 'embercom/lib/ajax';
import {
  humanReadableObjectNames,
  objectNames,
  objectTypes,
  states,
  seriesOnlyObjectTypes,
  objectTypesWithoutStatistics,
  objectWithoutAbTesting,
  statsSystemObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import RulesetLinkValidations from 'embercom/validations/ruleset-link';
import ContentDependency from 'embercom/models/matching-system/content-dependency';

export default Model.extend(RulesetLinkValidations, {
  appService: service(),
  app: readOnly('appService.app'),
  lastStateChange: belongsTo('matching-system/state-change'),
  contentObject: belongsTo('content-object', { async: false }),
  contentPreview: belongsTo('matching-system/content-preview', { async: false }),
  objectStats: hasMany('outbound/content-statistic'),
  ruleset: belongsTo('matching-system/ruleset'),
  state: attr('number'),
  type: attr('string'),
  matchingLocations: attr('array'),

  isDraft: equal('state', states.draft),

  isLive: equal('state', states.live),
  isPaused: equal('state', states.paused),

  isEnded: equal('state', states.ended),

  isValid: readOnly('object.isValid'),

  isMigratingEmailTemplate: false,

  contentHash: readOnly('object.contentHash'),
  implicitPredicates: readOnly('object.implicitPredicates'),
  preferredDevices: readOnly('object.preferredDevices'),

  objectName: computed('objectType', function () {
    return objectNames[this.objectType];
  }),

  parameterizedObjectName: computed('objectName', function () {
    return this.objectName.replace('_', '-');
  }),

  objectId: computed('object.id', function () {
    return this.object && this.object.id.split('-').lastObject;
  }),

  databaseId: computed('id', function () {
    return this.id.split('-').lastObject;
  }),

  humanReadableObjectName: computed('objectType', function () {
    return humanReadableObjectNames[this.objectType];
  }),

  statsSystemObjectName: computed('objectType', function () {
    return statsSystemObjectNames[this.objectType];
  }),

  isSeriesOnlyObjectType: computed('objectType', function () {
    return seriesOnlyObjectTypes.includes(this.objectType);
  }),

  isObjectTypeWithoutStatistics: computed('objectType', function () {
    return objectTypesWithoutStatistics.includes(this.objectType);
  }),

  isObjectTypeWithoutAbTesting: computed('objectType', function () {
    return objectWithoutAbTesting.includes(this.objectType);
  }),

  isControlGroup: equal('objectType', objectTypes.controlGroup),

  isBanner: equal('objectType', objectTypes.banner),

  isEmail: equal('objectType', objectTypes.email),

  isOutboundCustomBot: equal('objectType', objectTypes.customBot),

  isSms: equal('objectType', objectTypes.sms),

  isWhatsapp: equal('objectType', objectTypes.whatsapp),

  hasUnsavedChanges: computed('hasDirtyAttributes', 'object.hasUnsavedChanges', function () {
    return this.hasDirtyAttributes || (this.object && this.object.hasUnsavedChanges);
  }),

  wentLiveAt: attr('date'),

  rollbackAttributes() {
    this._super(...arguments);
    if (this.object) {
      this.object.rollbackAttributes();
    }
  },

  beforeEdit() {
    this.object?.beforeEdit?.();
  },

  beforeSave() {
    this.object?.beforeSave?.();
  },

  afterSave() {
    this.set('isMigratingEmailTemplate', false);
    this.object?.afterSave?.();
  },

  duplicate() {
    let params = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
    };
    return post(`/ember/matching_system/ruleset_links/${this.id}/duplicate`, params);
  },

  end() {
    let params = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
    };
    return put(`/ember/matching_system/ruleset_links/${this.id}/end`, params);
  },

  async loadDependentObjects() {
    let dependencies = await get('/ember/content_service/contents/list_dependents', {
      app_id: this.app.id,
      target_entity_id: this.objectId,
      target_entity_type: this.objectType,
    });
    return dependencies.map((json) => new ContentDependency(json));
  },
});
