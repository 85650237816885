/* RESPONSIBLE TEAM: team-frontend-tech */
import Model, { attr } from '@ember-data/model';

export default class AppPackageInstallUsageModel extends Model {
  @attr('array') adminsUsingAppInInboxSidebar;
  @attr('array') answers;
  @attr('boolean') conversations;
  @attr('array') customBots;
  @attr('array') messages;
  @attr('boolean') messengerHome;
  @attr('boolean') operatorQualification;
}
