/* RESPONSIBLE TEAM: team-proactive-support */
import SeriesModeConfig from 'embercom/objects/content-editor/series-mode-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';

export default function generateEditorConfig(options) {
  let container = options.container;

  return new SeriesModeConfig({
    headerComponentConfig: StandardSeriesModeHeaderConfiguration.create({ container }),
    editorComponentConfigs: [
      ComponentConfiguration.create({
        component: 'content-editor/panels/content/push/body',
      }),
    ],
  });
}
