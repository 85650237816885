/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type Canvas } from '@intercom/interblocks.ts';

const LOADING_IMAGE =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYwIiBoZWlnaHQ9IjYwIiB2aWV3Qm94PSIwIDAgMTYwIDYwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cmVjdCB4PSIyIiB5PSI2IiB3aWR0aD0iMTU2IiBoZWlnaHQ9IjEyIiByeD0iNCIgZmlsbD0iI0U4RThFOCIvPgo8cmVjdCB4PSIyIiB5PSIyOCIgd2lkdGg9IjYwIiBoZWlnaHQ9IjgiIHJ4PSI0IiBmaWxsPSIjRThFOEU4Ii8+CjxyZWN0IHg9IjIiIHk9IjQ2IiB3aWR0aD0iNjAiIGhlaWdodD0iOCIgcng9IjQiIGZpbGw9IiNFOEU4RTgiLz4KPC9zdmc+Cg==';

export const LOADING_CANVAS: Canvas = {
  content: {
    components: [
      {
        align: 'left',
        height: 60,
        width: 160,
        id: 'intercom_loading_image',
        type: 'image',
        url: LOADING_IMAGE,
      },
    ],
    version: '0.1',
  },
};
