/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { matchingLocations } from 'embercom/models/data/matching-system/matching-constants';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

export default class extends RulesetLink {
  @belongsTo('operator/custom-bot', { async: false }) object;

  objectType = objectTypes.triggerableCustomBot;
  shouldReloadRecord = true;

  get contentHash() {
    return this.serialize();
  }

  get matchingLocations() {
    return [matchingLocations.TRIGGER_FROM_OPERATOR];
  }

  // The ruleset-has-valid-predicates validation depends on this property.
  //
  // We need a computed property for this because of a limitation in ember-cp-validations [1].
  //
  // [1] https://github.com/ember-cli/eslint-plugin-ember/blob/master/docs/rules/no-deeply-nested-dependent-keys-with-each.md
  @computed('object.visualBuilderObject.editorConfig')
  get editorConfig() {
    return this.object.visualBuilderObject?.editorConfig;
  }

  ready() {
    this.object.beforeEdit();
  }
}
