/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import EmailContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/email/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import SeriesSchedulePanelConfiguration from 'embercom/objects/content-editor/panels/schedule/series-schedule-panel-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';
import { timezoneTypes } from 'embercom/models/data/matching-system/matching-constants';
import AudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';

export default function generateEditModeConfig(options) {
  let container = options.container;

  return EditModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesModeHeaderConfiguration.create({
      container,
      previewConfig: ComponentConfiguration.create({
        container,
        component: 'content-editor/headers/email/preview-button',
      }),
    }),
    headerComponentConfigs: [
      StandardEditModeHeaderConfiguration.create({
        container,
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/email/preview-button',
        }),
      }),
    ],
    editorPanelConfigs: [
      EmailContentPanelConfiguration.create({ container }),
      StandardAudiencePanelConfiguration.create({
        audienceHeaderConfiguration: AudienceHeaderConfiguration.create({
          showImplicitPredicates: true,
          container,
        }),
        audienceEditorConfiguration: new StandardAudienceEditorConfiguration({
          showSendToUnsubscribedToggle: true,
          showEventRules: true,
          showDateEvents: true,
          container,
        }),
        container,
      }),
      StandardSchedulePanelConfiguration.create({
        showFrequencyEditor: true,
        timezoneType: timezoneTypes.basedOnUserTimezone,
        container,
      }),
      SeriesSchedulePanelConfiguration.create({
        timezoneType: timezoneTypes.basedOnUserTimezone,
        container,
      }),
      StandardGoalPanelConfiguration.create({
        title: 'Goal and UTM tracking',
        introText: 'Understand how your email drives people to take action in your product.',
        showUtmTracking: true,
        container,
      }),
    ],
    footerComponentConfigs: [],
  });
}
