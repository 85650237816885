/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Model, { attr, belongsTo, type SyncHasMany } from '@ember-data/model';
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type AdminSeatMappingFragment from 'embercom/models/billing/migration-admin-seat-mapping';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class MigrationSeatConfiguration extends Model {
  @belongsTo('billing/stripe-migration', { async: false })
  declare stripeMigration: StripeMigration;
  @fragmentArray('billing/migration-admin-seat-mapping')
  declare adminSeatMappings: SyncHasMany<AdminSeatMappingFragment>;

  @attr('boolean') declare isCompleted: boolean;
  @attr('boolean') declare hasCustomerSaved: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'billing/migration-seat-configuration': MigrationSeatConfiguration;
  }
}
