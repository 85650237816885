/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { stateChangeDescriptions } from 'embercom/models/data/inbox/state-change-descriptions';

import {
  type StateChangeReason,
  type StateChangeType,
  StateChangingEntity,
} from 'embercom/models/data/inbox/state-change-enums';
import { type WorkflowObject } from '../../interfaces/workflow';
import { type StateChangePart, type StateChangeWireFormat } from '../state-change';

export interface WorkflowStateChangeWireFormat extends StateChangeWireFormat {
  changed_by_entity: WorkflowObject;
}

export default class WorkflowStateChange implements StateChangePart {
  readonly renderableType: RenderableType = RenderableType.StateChange;
  readonly changedByEntity: WorkflowObject;
  readonly changedByEntityType: StateChangingEntity = StateChangingEntity.Workflow;
  readonly change: StateChangeType;
  readonly reason: StateChangeReason;

  get workflow() {
    return this.changedByEntity;
  }

  get creatingEntity() {
    return { type: EntityType.Workflow, id: this.workflow.id, object: this.workflow };
  }

  get eventDescription(): string {
    return stateChangeDescriptions[this.change][this.reason];
  }

  constructor(change: StateChangeType, reason: StateChangeReason, changedByEntity: WorkflowObject) {
    this.change = change;
    this.reason = reason;
    this.changedByEntity = changedByEntity;
  }

  static deserialize(json: WorkflowStateChangeWireFormat): WorkflowStateChange {
    if (json.changed_by_entity_type !== StateChangingEntity.Workflow) {
      throw new Error(
        `Attempted to create a WorkflowStateChange from a response with changed entity type of '${json.changed_by_entity_type}'`,
      );
    }

    return new WorkflowStateChange(json.change, json.reason, json.changed_by_entity);
  }
}
