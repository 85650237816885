/* RESPONSIBLE TEAM: team-ai-agent */
import Model, { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';

export default class Settings extends Model {
  @attr('boolean') declare contentSuggestionsEnabledForUsers: boolean;
  @attr('boolean') declare contentSuggestionsEnabledForVisitors: boolean;
  @fragment('predicates/predicate-group')
  declare contentSuggestionsForUsersPredicateGroup: $TSFixMe; // app/models/predicates/predicate-group
  @fragment('predicates/predicate-group')
  declare contentSuggestionsForVisitorsPredicateGroup: $TSFixMe; // app/models/predicates/predicate-group

  @attr('boolean') declare predictiveAnswersEnabledForUsers: boolean;
  @attr('boolean') declare predictiveAnswersEnabledForVisitors: boolean;
  @fragment('predicates/predicate-group') declare predictiveAnswersForUsersPredicateGroup: $TSFixMe; // app/models/predicates/predicate-group
  @fragment('predicates/predicate-group')
  declare predictiveAnswersForVisitorsPredicateGroup: $TSFixMe; // app/models/predicates/predicate-group
  @attr('boolean') declare hasGeneratedPredictiveAnswers: boolean;
}
