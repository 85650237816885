/* RESPONSIBLE TEAM: team-proactive-support */

import EmberObject from '@ember/object';
import { tracked } from '@glimmer/tracking';

export class UploadHelper extends EmberObject {
  get hasUpdates() {
    return this.mediaTypes.some((mediaType) => {
      return Array.isArray(this[mediaType])
        ? this[mediaType].some((innerMediaType) => innerMediaType.hasUpdates)
        : this[mediaType].hasUpdates;
    });
  }

  reset() {
    this.mediaTypes.forEach((mediaType) => {
      Array.isArray(this[mediaType])
        ? this[mediaType].forEach((innerMediaType) => innerMediaType.reset())
        : this[mediaType].reset();
    });
  }

  getMediaHelper(mediaType) {
    return this[mediaType];
  }
}

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsDataURL(file);
  });
};

export class MediaHelper extends EmberObject {
  @tracked url = '';
  @tracked file = '';
  @tracked type = '';
  @tracked siteSettingsName = '';
  @tracked isReset = false;
  @tracked isSaved = false;
  @tracked collectionCardType = '';
  @tracked validators = null;

  get hasUpdates() {
    return (this.url || this.isReset) && !this.isSaved;
  }

  get hasFile() {
    return !!this.file;
  }

  async readFile(file) {
    let fileBlob = await readFile(file);
    this.setProperties({
      file,
      isSaved: false,
      url: fileBlob,
    });
  }

  removeExistingFile() {
    this.setProperties({
      url: '',
      file: '',
      isReset: true,
      isSaved: false,
    });
  }

  resetIfHasFile() {
    if (this.hasFile) {
      this.reset();
    }
  }

  clone() {
    return MediaHelper.create({
      url: this.url,
      file: this.file,
      type: this.type,
      siteSettingsName: this.siteSettingsName,
      isReset: this.isReset,
      isSaved: this.isSaved,
      collectionCardType: this.collectionCardType,
    });
  }

  reset() {
    this.setProperties({
      url: '',
      file: '',
      isReset: false,
      isSaved: false,
    });
  }
}
