/* RESPONSIBLE TEAM: team-phone */

import Model, { attr } from '@ember-data/model';

export type Message = {
  content: string;
  end_time: string;
  speaker: string;
  start_time: string;
};

export default class CallingDetails extends Model {
  @attr('boolean') declare recordingEnabled: boolean;
  @attr('string') declare type: string;
  @attr('string') declare state: string;
  @attr('string') declare direction: string;
  @attr('string') declare endedReason: string;
  @attr('string') declare answeredAt: string;
  @attr('string') declare createdAt: string;
  @attr('string') declare endedAt: string;
  @attr('string') declare routingTime: string;
  @attr('string') declare userId: string;
  @attr('string') declare adminId: string;
  @attr('string') declare humanTranscript: string;
  @attr('array') declare jsonTranscript: Message[];
  @attr('string') declare recordingUrl: string;
  @attr('number') declare conversationId: number;
  @attr('string') declare transcriptUrl: string;
  @attr('string') declare transcriptStatus: string;
  @attr('string') declare recordingName: string;
  @attr('string') declare forwardedTo: string;
  @attr('string') declare detailedState: string;
  @attr('boolean') declare isRecorded: boolean;
  @attr('string') declare workspacePhoneNumber: string;
  @attr('string') declare csatRating: string;
}
