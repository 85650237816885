/* RESPONSIBLE TEAM: team-workflows */

import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import CustomBotSummary, { type CustomBotSummaryWireFormat } from '../custom-bot-summary';
import ResolutionBotAnswerSummary, {
  type ResolutionBotAnswerSummaryWireFormat,
} from '../resolution-bot-answer-summary';
import ResolutionBotBehaviorVersionSummary, {
  type ResolutionBotBehaviorVersionSummaryWireFormat,
} from '../resolution-bot-behavior-version-summary';
import ResolutionBotGenericTriageSummary, {
  type ResolutionBotGenericTriageSummaryWireFormat,
} from '../resolution-bot-generic-triage-summary';
import OperatorWorkflowEventData, {
  type OperatorWorkflowEventDataWireFormat,
} from '../operator-workflow-event-data';

interface OperatorWorkflowEventWireFormat {
  operator_workflow_event_type: string;
  operator_workflow_event_data: OperatorWorkflowEventDataWireFormat;
  custom_bot_summary?: CustomBotSummaryWireFormat;
  resolution_bot_answer_summary?: ResolutionBotAnswerSummaryWireFormat;
  resolution_bot_behavior_version_summary?: ResolutionBotBehaviorVersionSummaryWireFormat;
  resolution_bot_generic_triage_summary?: ResolutionBotGenericTriageSummaryWireFormat;
}

export default class OperatorWorkflowEvent implements RenderableData {
  readonly renderableType = RenderableType.OperatorWorkflowEvent;
  readonly eventType: string;
  readonly eventData: OperatorWorkflowEventData;
  readonly customBotSummary?: CustomBotSummary;
  readonly resolutionBotAnswerSummary?: ResolutionBotAnswerSummary;
  readonly resolutionBotBehaviorVersionSummary?: ResolutionBotBehaviorVersionSummary;
  readonly resolutionBotGenericTriageSummary?: ResolutionBotGenericTriageSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(
    eventType: string,
    eventData: OperatorWorkflowEventData,
    customBotSummary?: CustomBotSummary,
    resolutionBotAnswerSummary?: ResolutionBotAnswerSummary,
    resolutionBotBehaviorVersionSummary?: ResolutionBotBehaviorVersionSummary,
    resolutionBotGenericTriageSummary?: ResolutionBotGenericTriageSummary,
  ) {
    this.eventType = eventType;
    this.eventData = eventData;
    this.customBotSummary = customBotSummary;
    this.resolutionBotAnswerSummary = resolutionBotAnswerSummary;
    this.resolutionBotBehaviorVersionSummary = resolutionBotBehaviorVersionSummary;
    this.resolutionBotGenericTriageSummary = resolutionBotGenericTriageSummary;
  }

  static deserialize(json: OperatorWorkflowEventWireFormat): OperatorWorkflowEvent {
    return new OperatorWorkflowEvent(
      json.operator_workflow_event_type,
      OperatorWorkflowEventData.deserialize(json.operator_workflow_event_data),
      json.custom_bot_summary ? CustomBotSummary.deserialize(json.custom_bot_summary) : undefined,
      json.resolution_bot_answer_summary
        ? ResolutionBotAnswerSummary.deserialize(json.resolution_bot_answer_summary)
        : undefined,
      json.resolution_bot_behavior_version_summary
        ? ResolutionBotBehaviorVersionSummary.deserialize(
            json.resolution_bot_behavior_version_summary,
          )
        : undefined,
      json.resolution_bot_generic_triage_summary
        ? ResolutionBotGenericTriageSummary.deserialize(json.resolution_bot_generic_triage_summary)
        : undefined,
    );
  }
}
