/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
const YOUTUBE_REGEX = /\/watch\?v\=([a-zA-Z0-9_\-]+)\/?/;
const VIMEO_REGEX = /\/([a-zA-Z0-9_\-]+)\/?/;
const WISTIA_REGEX = /\/(embed\/iframe|medias)\/([a-zA-Z0-9_\-]+)\/?/;
const LOOM_REGEX = /\/embed\/([a-zA-Z0-9_\-]+)\/?/;

export const INVALID_YOUTUBE_LINK_MESSAGE = `Invalid YouTube link. Must be in the form 'https://youtube.com/watch?v=[VIDEO_ID]'`;
export const INVALID_VIMEO_LINK_MESSAGE = `Invalid Vimeo link. Must be in the form 'https://vimeo.com/[VIDEO_ID]'`;
export const INVALID_WISTIA_LINK_MESSAGE = `Invalid Wistia link. Must be in the form 'https://fast.wistia.net/embed/iframe/[VIDEO_ID]'`;
export const INVALID_LOOM_LINK_MESSAGE = `Invalid Loom link. Must be in the form 'https://www.useloom.com/embed/[VIDEO_ID]'`;
export const UNKNOWN_VIDEO_PROVIDER_MESSAGE =
  'Must be a link from one of the supported video hosts';

const PROVIDERS = {
  youtube: 'youtube',
  vimeo: 'vimeo',
  wistia: 'wistia',
  loom: 'loom',
};

export function buildVideoUrl(id, provider) {
  if (!id) {
    return null;
  }

  switch (provider) {
    case PROVIDERS.youtube:
      return `https://www.youtube.com/watch?v=${id}`;
    case PROVIDERS.vimeo:
      return `https://vimeo.com/${id}`;
    case PROVIDERS.wistia:
      return `https://fast.wistia.net/embed/iframe/${id}`;
    case PROVIDERS.loom:
      return `https://www.useloom.com/embed/${id}`;
    default:
      return null;
  }
}

export function buildEmbeddableVideoUrl(id, provider) {
  if (!id) {
    return null;
  }

  switch (provider) {
    case PROVIDERS.youtube:
      return `https://www.youtube.com/embed/${id}?rel=0&hd=1&autoplay=0`;
    case PROVIDERS.vimeo:
      return `https://player.vimeo.com/video/${id}?autoplay=0`;
    case PROVIDERS.wistia:
      return `https://fast.wistia.net/embed/iframe/${id}?autoPlay=false&muted=false`;
    case PROVIDERS.loom:
      return `https://www.useloom.com/embed/${id}`;
    default:
      return null;
  }
}

export function parseVideoUrl(url) {
  try {
    let parsedUrl = new URL(url);
    let provider = providerForUrl(parsedUrl);

    switch (provider) {
      case PROVIDERS.youtube:
        return { provider, id: parseId(`${parsedUrl.pathname}${parsedUrl.search}`, YOUTUBE_REGEX) };
      case PROVIDERS.vimeo:
        return { provider, id: parseId(parsedUrl.pathname, VIMEO_REGEX) };
      case PROVIDERS.wistia:
        return { provider, id: parseId(parsedUrl.pathname, WISTIA_REGEX) };
      case PROVIDERS.loom:
        return { provider, id: parseId(parsedUrl.pathname, LOOM_REGEX) };
      default:
        return { id: null, provider: null };
    }
  } catch (err) {
    return { id: null, provider: null };
  }
}

export function validateVideoDetails(id, provider) {
  if (provider === null) {
    return UNKNOWN_VIDEO_PROVIDER_MESSAGE;
  }

  if (id === null) {
    switch (provider) {
      case PROVIDERS.youtube:
        return INVALID_YOUTUBE_LINK_MESSAGE;
      case PROVIDERS.vimeo:
        return INVALID_VIMEO_LINK_MESSAGE;
      case PROVIDERS.wistia:
        return INVALID_WISTIA_LINK_MESSAGE;
      case PROVIDERS.loom:
        return INVALID_LOOM_LINK_MESSAGE;
      default:
        return UNKNOWN_VIDEO_PROVIDER_MESSAGE;
    }
  }

  return true;
}

function providerForUrl({ hostname }) {
  switch (hostname) {
    case 'youtube.com':
    case 'www.youtube.com':
      return PROVIDERS.youtube;
    case 'vimeo.com':
    case 'www.vimeo.com':
      return PROVIDERS.vimeo;
    case 'wistia.net':
    case 'fast.wistia.net':
      return PROVIDERS.wistia;
    case 'useloom.com':
    case 'www.useloom.com':
      return PROVIDERS.loom;
    default:
      if (isWistiaCustomDomain(hostname)) {
        return PROVIDERS.wistia;
      }

      return null;
  }
}

function isWistiaCustomDomain(hostname) {
  let domains = hostname.split('.');
  let onlySubdomain = domains.length === 3;
  let wistiaDomain = domains[1] === 'wistia' && domains[2] === 'net';
  let subDomainRegex = /^[a-z0-9][a-z0-9\-]*$/;
  let validCustomDomain = subDomainRegex.test(domains[0]);
  return onlySubdomain && wistiaDomain && validCustomDomain;
}

function parseId(path, regex) {
  let match = path.match(regex);
  if (!match) {
    return null;
  }

  return match[match.length - 1];
}
