/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import Admin from 'embercom/models/admin';

export default class MockResponse extends Fragment {
  @attr('number') declare adminId: number;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('string') declare body: string;

  get admin() {
    return Admin.peekAndMaybeLoad(this.store, this.adminId?.toString());
  }
}
