/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EmberObject from '@ember/object';
import parseUrl from 'embercom/lib/url-parser';

const SIMPLE_VALIDATION_REGEX = /(.*\..+)/;
const NOT_SEPARATED_BY_COMMAS_REGEX = /^([^ ]+)(,\s*[^ ]+)*$/;

export default class TrustedListUrlValidator extends EmberObject {
  urlString = null;
  get isEmptyUrlString() {
    return !this.urlString;
  }
  get isSeparatedByCommas() {
    return this.urlString.match(NOT_SEPARATED_BY_COMMAS_REGEX);
  }
  get isNotSeparatedByCommas() {
    return !this.isSeparatedByCommas;
  }
  get hasNoFormatErrors() {
    return this.isSeparatedByCommas && this.domainsCorrectlyFormatted;
  }
  get isValid() {
    return this.isEmptyUrlString || this.hasNoFormatErrors;
  }
  get isNotValid() {
    return !this.isValid;
  }
  get domainsCorrectlyFormatted() {
    return !this.domainsNotFormatted;
  }
  get domainUrlObjects() {
    return this.rawDomains.map(parseUrl);
  }
  get domainHostNames() {
    return this.domainUrlObjects.map((duo) => duo.hostname);
  }
  get domainsNotFormatted() {
    return this.domainUrlObjects.some((urlObject) => {
      return this.urlIsInvalid(urlObject);
    });
  }

  urlIsInvalid(urlObject) {
    return urlObject.hostname.match(SIMPLE_VALIDATION_REGEX) === null || urlObject.pathname !== '/';
  }

  get rawDomains() {
    return this.urlString.replace(/\s+/g, '').split(',').filter(Boolean);
  }
  get errorMessage() {
    if (this.isEmptyUrlString) {
      return null;
    } else if (this.isNotSeparatedByCommas) {
      return 'Separate your domains with commas';
    } else if (this.domainsNotFormatted) {
      return 'Add complete domain(s) without path';
    }
    return null;
  }
}
