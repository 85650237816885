/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Model, { attr } from '@ember-data/model';
import { action } from '@ember/object';

export type HistoricalConversationImportStatus =
  | 'in_progress'
  | 'finished'
  | 'failed'
  | 'cancelled';

export default class ConversationExtractionSettings extends Model {
  @attr('boolean') declare enabled: boolean;
  @attr('string') declare latestImportStatus: HistoricalConversationImportStatus | null;
  @attr('array') declare teamIds: number[];
  @attr('array') declare adminIds: number[];
  @attr('boolean') declare reviewRequired: boolean;
  @attr('boolean') declare defaultChatbotAvailability: boolean;
  @attr('boolean') declare defaultCopilotAvailability: boolean;
  @attr('number') declare folderID: number;

  get isLatestImportInProgress() {
    return this.latestImportStatus === 'in_progress';
  }

  get hasRunAnyImport() {
    return this.latestImportStatus !== null;
  }

  @action setAdminIds(adminIds: number[]) {
    this.adminIds = adminIds.map((id) => Number(id));
  }

  @action clearAdminIds() {
    this.adminIds = [];
  }

  @action setTeamIds(teamIds: number[]) {
    this.teamIds = teamIds.map((id) => Number(id));
  }

  @action clearTeamIds() {
    this.teamIds = [];
  }
}
