/* RESPONSIBLE TEAM: team-help-desk-experience */

import { type InboxWireFormat } from 'embercom/objects/inbox/inboxes/inbox';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import { deserialize as deserializeInbox } from 'embercom/objects/inbox/inboxes/inbox';

export enum InboxFolderObjectType {
  Team = 'team',
  Admin = 'admin',
  View = 'view',
  AiChatbot = 'ai_chatbot',
}

export interface InboxFolderObjectWireFormat {
  object_type: InboxFolderObjectType;
  object_id: number;
  position: number;
  inbox: InboxWireFormat;
}

export interface InboxFolderWireFormat {
  id: number;
  name: string;
  objects: InboxFolderObjectWireFormat[];
}

export interface InboxFoldersWireFormat {
  inbox_folders: InboxFolderWireFormat[];
}

export class InboxFolderObject {
  readonly objectType: string;
  readonly objectId: number;
  readonly position: number;
  readonly inbox: Inbox;

  constructor(objectType: string, objectId: number, position: number, inbox: Inbox) {
    this.objectType = objectType;
    this.objectId = objectId;
    this.position = position;
    this.inbox = inbox;
  }

  static deserialize(json: InboxFolderObjectWireFormat): InboxFolderObject {
    let inbox = deserializeInbox(json.inbox);
    return new InboxFolderObject(json.object_type, json.object_id, json.position, inbox);
  }
}

export class InboxFolder {
  readonly id: number;
  readonly name: string;
  readonly objects: InboxFolderObject[];

  constructor(id: number, name: string, objects: InboxFolderObject[]) {
    this.id = id;
    this.name = name;
    this.objects = objects;
  }

  static deserialize(json: InboxFolderWireFormat): InboxFolder {
    let objects = json.objects.flatMap((object) => {
      try {
        // if a view (and possibly team/admin) is deleted, we don't remove the folder object.
        // We need to filter out any objects that fail to deserialize.
        return [InboxFolderObject.deserialize(object)];
      } catch (e) {
        return [];
      }
    });
    return new InboxFolder(json.id, json.name, objects);
  }
}
