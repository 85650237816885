/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Block from './block';
import { computed } from '@ember/object';
import { isBlank } from '@ember/utils';

export default Block.extend({
  type: attr('string'),
  url: attr('string'),
  hasContent: computed('url', function () {
    return !isBlank(this.url);
  }),
});
