/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';

export default EmberObject.extend({
  activate: undefined,
  deactivate: undefined,
  delete: undefined,
  pause: undefined,
  duplicate: undefined,
  schedule: undefined,
  unschedule: undefined,
  save: undefined,
  autoSave: undefined,

  init(inputs) {
    this._super(...arguments);
    this.setProperties({
      activate: inputs.activate,
      deactivate: inputs.deactivate,
      delete: inputs.delete,
      pause: inputs.pause,
      duplicate: inputs.duplicate,
      schedule: inputs.schedule,
      unschedule: inputs.unschedule,
      save: inputs.save,
      autoSave: inputs.autoSave,
    });
  },
});
