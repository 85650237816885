/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type WorkflowObject } from 'embercom/objects/inbox/interfaces/workflow';

interface PriorityChangedByWorkflowWireFormat {
  renderable_type: RenderableType;
  workflow: WorkflowObject;
  priority: number;
}

export default class PriorityChangedByWorkflow {
  readonly renderableType: RenderableType = RenderableType.ConversationSlaAppliedByWorkflow;
  readonly workflow: WorkflowObject;
  readonly priority: number;

  constructor(workflow: WorkflowObject, priority: number) {
    this.workflow = workflow;
    this.priority = priority;
  }

  get creatingEntity() {
    return { type: EntityType.Workflow, id: this.workflow.id || -1, object: this.workflow };
  }

  static deserialize(json: PriorityChangedByWorkflowWireFormat): PriorityChangedByWorkflow {
    return new PriorityChangedByWorkflow(json.workflow, json.priority);
  }
}
