/* RESPONSIBLE TEAM: team-workflows */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/custom-bot/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/custom-bot/edit-mode-generator';
import generateSeriesModeConfig from 'embercom/objects/series/ruleset-editor/bot/editor-configuration-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.customBot;
  let customBot = options.customBot;
  let intl = options.container.lookup('service:intl');
  let paywallUpgradeHoverCopy = 'operator.custom-bot.header.set-live-paywall-text-with-workflows';

  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    seriesMode: generateSeriesModeConfig(options),
    notificationMessages: generateNotificationMessages(options),
    requiredFeature: customBot.config.requiredBillingFeature,
    paywallHoverParams: {
      customFeatureText: intl.t(paywallUpgradeHoverCopy),
    },
  });
}
