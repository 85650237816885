/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, belongsTo } from '@ember-data/model';
import { checkpointStatuses } from 'embercom/models/data/matching-system/matching-constants';

export default class SeriesCheckpoint extends Model {
  @attr('date') declare backgroundProcessableAt: Date;
  @attr('date') declare foregroundProcessableAt: Date;
  @attr('date') declare expiresAt: Date;
  @attr('number') declare status: number;
  @attr('number') declare timesProcessed: number;
  @attr('number') declare matchingLocationType: number;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @belongsTo('user', { async: false }) declare user: $TSFixMe;
  @belongsTo('company', { async: false }) company: $TSFixMe;

  get isActive() {
    return this.status === checkpointStatuses.active;
  }
}
