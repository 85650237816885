/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { TicketType, type TicketTypeWireFormat } from 'embercom/objects/inbox/ticket';

interface LinkedTicketSharedWireFormat {
  renderable_type: number;
  admin_summary: AdminSummaryWireFormat;
  ticket_id: number;
  id?: number;
  ticket_type: TicketTypeWireFormat;
}

export default class LinkedTicketShared implements RenderableData {
  renderableType: RenderableType = RenderableType.LinkedTicketShared;
  readonly adminSummary: AdminSummary;
  readonly ticketId: number;
  readonly ticketType: TicketType;
  readonly id?: number;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(adminSummary: AdminSummary, ticketId: number, ticketType: TicketType, id?: number) {
    this.adminSummary = adminSummary;
    this.ticketId = ticketId;
    this.id = id;
    this.ticketType = ticketType;
  }

  get adminOnly() {
    return true;
  }

  get styles() {
    return {
      backgroundColor: 'bg-sky dark:bg-dm-blue-dark',
      textColor: 'text-blue-darkest dark:text-dm-white',
      backgroundColorHover: 'bg-sky-dark dark:bg-blue-darkest',
    };
  }

  static deserialize(json: LinkedTicketSharedWireFormat): LinkedTicketShared {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);
    let ticketType = TicketType.deserialize(json.ticket_type);

    return new LinkedTicketShared(adminSummary, json.ticket_id, ticketType, json?.id);
  }
}
