/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { assert } from '@ember/debug';

export default EmberObject.extend({
  success: undefined,
  failure: undefined,
  confirm: undefined,

  init(inputs) {
    this._super(...arguments);

    assert(
      'A key must be provided for the "success" notification The value may be blank.',
      Object.keys(inputs).includes('success'),
    );

    assert(
      'A key must be provided for the "failure" notification. The value may be blank.',
      Object.keys(inputs).includes('failure'),
    );

    this.setProperties(inputs);
  },
});
