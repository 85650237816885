/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import LocalizedPushContentValidations from 'embercom/validations/localized-push-content';

export default Model.extend(LocalizedPushContentValidations, {
  push: belongsTo('mobile-push/push', { inverse: 'localizedPushContents' }),
  titleBlocks: fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [{ text: '', type: 'paragraph', modelKey: 'common/blocks/paragraph' }],
  }),
  bodyBlocks: fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [{ text: '', type: 'paragraph', modelKey: 'common/blocks/paragraph' }],
  }),
  uris: fragment('mobile-push/uris'),
  locale: attr('string'),
  contentImageUrl: attr('string'),

  contentHash: computed(
    'titleBlocks.@each.text',
    'bodyBlocks.@each.text',
    'uris.ios',
    'uris.android',
    'contentImageUrl',
    function () {
      return {
        titleBlocks: this.titleBlocks.serialize(),
        bodyBlocks: this.bodyBlocks.serialize(),
        uris: this.uris.serialize(),
        contentImageUrl: this.contentImageUrl ? this.contentImageUrl.split('/').pop() : '',
      };
    },
  ),

  hasUnsavedChanges: computed(
    'hasDirtyAttributes',
    'titleBlocks.@each.hasDirtyAttributes',
    'bodyBlocks.@each.hasDirtyAttributes',
    function () {
      return (
        this.hasDirtyAttributes ||
        this.titleBlocks.any((block) => block.hasDirtyAttributes) ||
        this.bodyBlocks.any((block) => block.hasDirtyAttributes)
      );
    },
  ),
});
