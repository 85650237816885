/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr } from '@ember-data/model';

export default class MessagingSettings extends Model {
  @attr('string') declare commentPlaceholder: string;
  @attr('string') declare defaultAssigneeId: string;
  @attr('boolean') declare hideAllUnsubscribeLinks: boolean;
  @attr('boolean') declare userEmailFollowUps: boolean;
  @attr('boolean') declare userTicketEmailFollowUps: boolean;
  @attr('string') declare emailNotificationDestinationUrl: string;
}
