/* RESPONSIBLE TEAM: team-frontend-tech */
import { type CanvasContent, type CanvasNetworkClient } from '@intercom/interblocks.ts';
import $ from 'jquery';

export class EmbercomCanvasNetworkClient implements CanvasNetworkClient {
  workspaceId;

  constructor(workspaceId: string) {
    this.workspaceId = workspaceId;
  }

  async get(url: string, done: (content: CanvasContent) => void) {
    // eslint-disable-next-line ember/no-jquery
    $.get(`/ember${url}?app_id=${this.workspaceId}`).done((response) => {
      done(response.content);
    });
  }

  post(url: string, done: (content: CanvasContent) => void): void {
    // eslint-disable-next-line ember/no-jquery
    $.post(`/ember/${url}`).done(done as any);
  }
}
