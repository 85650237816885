/* RESPONSIBLE TEAM: team-ai-agent */
import PanelConfiguration from 'embercom/objects/content-editor/panel-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';
import { assert } from '@ember/debug';
import { contentEditors } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class AnswersPanelConfiguration extends PanelConfiguration {
  @service contentEditorService;
  @service intl;

  init(inputs) {
    // The container gives us access to Services in these objects. Pass `container: getOwner(this)` as an
    // input when generating these configuration objects.
    assert('You must provide the application container in your panel inputs', inputs.container);
    let header = StandardPanelHeaderConfiguration.create({
      container: inputs.container,
      title: this.intl.t('ai-agent.profiles.config-generator.answers'),
      component: 'content-editor/panels/custom/ai-agent-profile/answers/header',
      errors: [],
      isComplete: true,
    });
    let body = ComponentConfiguration.create({
      component: 'content-editor/panels/custom/ai-agent-profile/answers/body',
    });
    super.init({
      name: 'custom/ai-agent-profile/answers',
      header,
      body,
      supportedEditors: [contentEditors.standalone],
    });
  }
}
