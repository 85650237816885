/* RESPONSIBLE TEAM: team-help-desk-experience */

import { isNaN } from 'underscore';
import { type DataType } from './conversation-attribute-descriptor';

export interface ConversationAttributeDescriptorSummaryWireFormat {
  id: number | string;
  name: string;
  type: DataType;
  editable: boolean;
}

export default class ConversationAttributeDescriptorSummary {
  readonly id: number | string;
  readonly name: string;
  readonly type: DataType;
  readonly editable: boolean;

  constructor(id: number | string, name: string, type: DataType, editable: boolean) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.editable = editable;
  }

  static deserialize(
    json: ConversationAttributeDescriptorSummaryWireFormat,
  ): ConversationAttributeDescriptorSummary {
    let id: number | string = Number(json.id);
    if (isNaN(id)) {
      id = json.id;
    }

    return new ConversationAttributeDescriptorSummary(id, json.name, json.type, json.editable);
  }
}
