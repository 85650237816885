/* RESPONSIBLE TEAM: team-workflows */
import EditorConfig, {
  type PathConfigGenerationParams,
  type StepConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { ButtonPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';

import { ButtonBotChatMessageConfiguration } from 'embercom/objects/visual-builder/configuration/step/chat-message';
import type Step from 'embercom/models/operator/visual-builder/step';
export default class ButtonBotEditorConfig extends EditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new ButtonPathConfig({ path, editorState });
  }

  generateStepConfig<S extends Step>({ step, pathConfig }: StepConfigGenerationParams<S>) {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.chatMessage:
        return new ButtonBotChatMessageConfiguration({
          ...stepConfigArgs,
          attributeService: this.attributeService,
        });
      default:
        return super.generateStepConfig(stepConfigArgs);
    }
  }
}
