/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, belongsTo } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import ActionValidations from 'embercom/validations/action';
import isValidUrlWithProtocol from 'embercom/lib/url-validator';

export default class Action extends Model.extend(ActionValidations) {
  @service store;
  @attr('number') actionType;
  @attr('string') webUrl;
  @attr('string') actionTitle;
  @attr('string') androidUri;
  @attr('string') iosUri;
  @attr('number') actionContentId;
  @attr('number') actionContentType;
  @belongsTo('surveys/step', { inverse: 'actions' }) step;

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  get webUrlHasValidFormat() {
    return isValidUrlWithProtocol(this.webUrl);
  }
}
