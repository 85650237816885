/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model from '@ember-data/model';
import { attr } from '@ember-data/model';
import Workflowable from 'embercom/models/mixins/operator/workflowable';

export default Model.extend(Workflowable, {
  createdAt: attr('date'),
});
