/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Block from './block';
import { notEmpty } from '@ember/object/computed';

export default Block.extend({
  text: attr('string'),
  language: attr('string'),

  hasContent: notEmpty('text'),
});
