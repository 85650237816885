/* RESPONSIBLE TEAM: team-phone */
import type Callback from 'embercom/models/operator/visual-builder/step/callback';
import StepConfig, { type StepConfigParams } from '../step';

export default class CallbackConfiguration extends StepConfig {
  declare step: Callback;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/callback',
      ...params,
    });
  }

  get alignment() {
    return 'left' as const;
  }
}
