/* RESPONSIBLE TEAM: team-ai-agent */
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import BotIntroContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/custom/resolution-bot-behavior/bot-intro/summary-configuration';
import AnswersSummaryConfiguration from 'embercom/objects/content-editor/summaries/custom/resolution-bot-behavior/answers/summary-configuration';
import BotModeContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/custom/resolution-bot-behavior/bot-mode/summary-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import GenericTriageContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/custom/resolution-bot-behavior/generic-triage/summary-configuration';
import PreHandoverSummaryConfiguration from 'embercom/objects/content-editor/summaries/custom/resolution-bot-behavior/pre-handover/summary-configuration';
import StateChangeSummaryConfiguration from 'embercom/objects/content-editor/summaries/custom/resolution-bot-behavior/state-change-summary-configuration';
import {
  StandardAudienceSummaryConfiguration,
  StandardScheduleSummaryConfiguration,
} from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';

export default function generateViewModeConfig({ container, customBot }) {
  let intl = container.lookup('service:intl');
  let finOptInService = container.lookup('service:finOptInService');
  let appService = container.lookup('service:appService');
  let contentEditorService = container.lookup('service:contentEditorService');
  let finTrialService = container.lookup('service:finTrialService');

  let audienceSummaryConfiguration = StandardAudienceSummaryConfiguration.create({
    title: intl.t('ai-agent.profiles.config-generator.rules'),
    hideMatchBehavior: true,
    container,
  });

  audienceSummaryConfiguration.header = ComponentConfiguration.create({
    component: 'content-editor/summaries/custom/triggerable-custom-bot/channel-targeting/summary',
    config: {},
  });

  let configurationContent = [
    StateChangeSummaryConfiguration.create({ container }),
    AnswersSummaryConfiguration.create({
      container,
      title: intl.t('ai-agent.profiles.config-generator.answers'),
    }),
    audienceSummaryConfiguration,
    BotModeContentSummaryConfiguration.create({
      title: intl.t('ai-agent.profiles.config-generator.bot-mode'),
      container,
    }),
    BotIntroContentSummaryConfiguration.create({
      title: intl.t('ai-agent.profiles.config-generator.bot-intro'),
      container,
    }),
    PreHandoverSummaryConfiguration.create({
      title: intl.t('operator.fin.setup.setup-and-go-live.pre-handover.title'),
      container,
    }),
    GenericTriageContentSummaryConfiguration.create({
      title: intl.t('ai-agent.profiles.config-generator.handover'),
      container,
    }),
    StandardScheduleSummaryConfiguration.create({
      title: intl.t('ai-agent.profiles.config-generator.schedule'),
      container,
    }),
  ];

  function finOptInCheck(postAcceptCallback) {
    if (contentEditorService.activeObject?.useAiAnswers && !finOptInService.hasOptedInToFin) {
      finOptInService.postAcceptCallback = postAcceptCallback;
      finOptInService.openFinOptInModal();
    } else {
      postAcceptCallback.perform();
    }
  }

  let viewModeConfig = ViewModeConfig.create({
    headerComponentConfigs: [
      StandardViewModeHeaderConfiguration.create({
        container,
        preActivationCheck: finOptInCheck,
        moreDropdownConfig: MoreDropdownConfiguration.create({
          hideCsvExportOption: true,
          hideTagOption: true,
          hideAddProfileOption: false,
          container,
        }),
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/workflows/preview-button',
          analyticEvent: {
            place: 'resolution_bot_behavior',
          },
        }),
        ctaConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/fin/learn-about-fin-dropdown',
          analyticEvent: {
            place: 'resolution_bot_behavior',
          },
        }),
        ...(!appService.app.hasFinFreeUsageWindowRetrials && {
          bannerConfig: ComponentConfiguration.create({
            container,
            component: 'content-editor/headers/fin/free-trial-banner',
            hideBeforeTrialStarted: true,
            usingAiAnswers: contentEditorService.activeObject?.useAiAnswers,
          }),
        }),
        ...(appService.app.hasFinFreeUsageWindowRetrials && {
          trialButtonConfig: ComponentConfiguration.create({
            container,
            component: 'content-editor/headers/fin/free-trial-button',
          }),
        }),
      }),
      ComponentConfiguration.create({
        container,
        component: 'content-editor/headers/fin/header',
        classes: finTrialService.isTrialCustomer ? 'mt-4' : '',
      }),
    ],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: intl.t('ai-agent.profiles.config-generator.behavior'),
          icon: 'operator',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('ai-agent.profiles.config-generator.matched'),
          view: 'receipts',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: configurationContent,
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          noun: 'match',
          verb: 'matched',
          container,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });
  return viewModeConfig;
}
