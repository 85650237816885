/* RESPONSIBLE TEAM: team-data-interop */
import type GithubCreateIssue from 'embercom/models/operator/visual-builder/step/github-create-issue';
import StepConfig, { type StepConfigParams } from '../step';

export default class GithubCreateIssueConfiguration extends StepConfig {
  declare step: GithubCreateIssue;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/integration-action',
      ...params,
    });
  }
}
