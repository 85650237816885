/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
const HEADING_BLOCK = {
  type: 'subheading',
  text: 'Welcome to Podcasts',
};

const PARAGRAPH_BLOCK = {
  type: 'paragraph',
  text: 'Be as short and simple as possible. Focus on one key thing per screen.',
};

const COVER_IMAGE_BLOCK = {
  type: 'image',
  url: 'https://downloads.intercomcdn.com/i/o/195702666/e2cae946f062f0f795e7d0dc/placeholder-image-full-width.png',
  width: 350,
  height: 240,
  align: 'center',
};

const ROUNDED_IMAGE_BLOCK = {
  type: 'image',
  url: 'https://downloads.intercomcdn.com/i/o/195704481/fde13be5b749bdb5975b8004/placeholder-image-centered.png',
  align: 'center',
};

const IMAGE_TEXT_BLOCK = {
  type: 'imageText',
  imageUrl:
    'https://downloads.intercomcdn.com/i/o/195704481/fde13be5b749bdb5975b8004/placeholder-image-centered.png',
  text: 'Discover free audio stories that entertain and inform <br>',
  title: '',
};

const titleTextImage = {
  blocks: [
    { ...HEADING_BLOCK, text: 'Title, text, and image' },
    PARAGRAPH_BLOCK,
    COVER_IMAGE_BLOCK,
  ],
};

const imageTitleText = {
  blocks: [
    ROUNDED_IMAGE_BLOCK,
    { ...HEADING_BLOCK, text: 'Image, title and text', align: 'center' },
    { ...PARAGRAPH_BLOCK, align: 'center' },
  ],
};

const listWithIcons = {
  blocks: [
    { ...HEADING_BLOCK, text: 'List with icons' },
    { ...IMAGE_TEXT_BLOCK, title: 'First', text: 'Use images with text to add detail.' },
    { ...IMAGE_TEXT_BLOCK, title: 'Second', text: 'Be as short and simple as possible.' },
    {
      ...IMAGE_TEXT_BLOCK,
      title: 'Third',
      text: 'Focus on the key things your user should understand.',
    },
  ],
};

const permissionsRequest = {
  blocks: [
    { ...HEADING_BLOCK, text: 'Permissions request', align: 'center' },
    {
      ...PARAGRAPH_BLOCK,
      text: 'Help your user to understand why the app needs to use a permission.',
      align: 'center',
    },
  ],
  actions: [
    { actionType: 'pushNotificationPermission', order: 'primary' },
    { actionType: 'skip', order: 'secondary' },
  ],
};

export const screenTemplates = {
  titleTextImage,
  imageTitleText,
  listWithIcons,
  permissionsRequest,
};
