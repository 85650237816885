/* RESPONSIBLE TEAM: team-help-desk-experience */
export interface MessageSummaryWireFormat {
  id: string;
  title: string;
}

export default class MessageSummary {
  readonly id: string;
  readonly title: string;

  constructor(id: string, title: string) {
    this.id = id;
    this.title = title;
  }

  static deserialize(json: MessageSummaryWireFormat): MessageSummary {
    return new MessageSummary(json.id, json.title);
  }
}
