/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { ALL_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  outwardConnectionPoints: [validator('has-many')],
});

export default class ConditionalBranches extends Step.extend(Validations) {
  @service intercomEventService: any;
  @service declare store: Store;

  supportedChannels = ALL_CHANNELS;

  static get supportedContexts() {
    return [
      TargetContext.Conversation,
      TargetContext.CustomerTicket,
      TargetContext.BackOfficeTicket,
      TargetContext.TrackerTicket,
    ];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static get isStepGroupEnding() {
    return true;
  }

  addBranch() {
    let predicateGroup = this.store.createFragment('predicates/predicate-group');
    let newBranch = this.store.createRecord('operator/visual-builder/connection-point', {
      label: null,
      type: 'conditional_branch',
      predicateGroup,
    });

    let elseBranch = this.outwardConnectionPoints.lastObject;
    if (elseBranch) {
      // Typedef of removeObject has the wrong return type
      let existingBranches = this.outwardConnectionPoints.removeObjects([elseBranch]);

      this.outwardConnectionPoints = existingBranches;
      this.outwardConnectionPoints.pushObjects([newBranch, elseBranch]);

      this.intercomEventService.trackAnalyticsEvent({ object: newBranch, action: 'added' });
    } else {
      throw new Error('Every conditional branch step must have an else branch.');
    }
  }

  static createNewStep(store: Store): ConditionalBranches {
    let predicateGroup = store.createFragment('predicates/predicate-group');
    let condition = store.createRecord('operator/visual-builder/connection-point', {
      label: null,
      type: 'conditional_branch',
      predicateGroup,
    });

    let elseBranch = store.createRecord('operator/visual-builder/connection-point', {
      label: null,
      type: null,
    });

    return store.createRecord('operator/visual-builder/step/conditional-branches', {
      outwardConnectionPoints: [condition, elseBranch],
      type: 'operator/visual-builder/step/conditional-branches',
    });
  }
}
