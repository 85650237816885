/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
import { attr } from '@ember-data/model';
import Block from './block';
import JsonType from 'embercom/models/types/json';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';

export default Block.extend({
  canvas: attr(JsonType),
  messenger_app_id: attr('number'),
  text: attr('string'),
  uri: attr('string'),

  isStaticCanvas: notEmpty('canvas.content'),
  isTourCard: computed('canvas', 'isStaticCanvas', function () {
    return (
      this.isStaticCanvas &&
      this.get('canvas.content.components').any((component) => component.id === 'tour')
    );
  }),
});
