/* RESPONSIBLE TEAM: team-proactive-support */
import PanelConfiguration from 'embercom/objects/content-editor/panel-configuration';
import ChecklistSharingHeaderConfiguration from 'embercom/objects/content-editor/panels/custom/checklist-sharing/header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import { assert } from '@ember/debug';
import { contentEditors } from 'embercom/models/data/matching-system/matching-constants';

export default class ChecklistSharingPanelConfiguration extends PanelConfiguration {
  init(inputs) {
    // The container gives us access to Services in these objects. Pass `container: getOwner(this)` as an
    // input when generating these configuration objects.
    assert('You must provide the application container in your panel inputs', inputs.container);

    let header = ChecklistSharingHeaderConfiguration.create({ container: inputs.container });
    let body = ComponentConfiguration.create({
      component: 'content-editor/panels/custom/checklist-sharing/body',
    });
    super.init({
      name: 'custom/checklist-sharing',
      header,
      body,
      supportedEditors: [contentEditors.standalone, contentEditors.series],
    });
  }
}
