/* RESPONSIBLE TEAM: team-proactive-support */
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment } from 'ember-data-model-fragments/attributes';

export default Fragment.extend({
  receipt: fragment('stats-system/stat-count'),
  completion: fragment('stats-system/stat-count'),
  goalSuccess: fragment('stats-system/stat-count'),
  tourStepFailure: fragment('stats-system/stat-count'),
});
