/* RESPONSIBLE TEAM: team-proactive-support */
import Model from '@ember-data/model';
import { getOwner } from '@ember/application';
import conditionEditorConfigGenerator from 'embercom/objects/content-editor/configuration-generators/series/condition';
import { buildValidations } from 'ember-cp-validations';

// When used as a RulesetLink object, the matchable object must implement the validations library.
// For this model, we don't have any specific validations so we can build an empty validation set.
const ConditionValidations = buildValidations({});
export default class SeriesCondition extends Model.extend(ConditionValidations) {
  editorConfig() {
    let container = getOwner(this);
    return conditionEditorConfigGenerator({ container });
  }
}
