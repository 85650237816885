/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import SendState, { type SendStateWireFormat } from 'embercom/objects/inbox/send-state';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import CustomBotSummary, {
  type CustomBotSummaryWireFormat,
} from 'embercom/objects/inbox/custom-bot-summary';
import { tracked } from '@glimmer/tracking';
import Tag, { type TagWireFormat } from 'embercom/objects/inbox/tag';

interface CustomBotWireFormat {
  renderable_type: RenderableType;
  blocks: Array<object>;
  custom_bot_summary: CustomBotSummaryWireFormat;
  entity: AdminSummaryWireFormat;
  ruleset_id?: number;
  selection_set_id?: number;
  send_state?: SendStateWireFormat;
  tags: Array<TagWireFormat>;
}

export default class CustomBot implements RenderableData {
  readonly renderableType = RenderableType.CustomBot;
  readonly blocks: Array<object>;
  readonly customBotSummary: CustomBotSummary;
  readonly entity: AdminSummary;
  readonly rulesetId?: number;
  readonly selectionSetId?: number;
  @tracked sendState?: SendState;
  @tracked tags: Array<Tag>;

  get creatingEntity() {
    return { type: EntityType.Bot, id: this.entity.id, object: this.entity };
  }

  get styles() {
    return this.hasError
      ? {
          backgroundColor: 'bg-sky dark:bg-dm-blue-dark dark:bg-opacity-50',
          textColor: 'text-gray dark:text-dm-gray',
        }
      : {
          backgroundColor: 'bg-sky dark:bg-dm-blue-dark',
          textColor: 'text-blue-darkest dark:text-dm-white',
          backgroundColorHover: 'bg-sky-dark dark:bg-blue-darkest',
        };
  }

  get ticketStyles() {
    return {
      backgroundColor: 'bg-white dark:bg-black',
      textColor: 'text-black dark:text-dm-white',
      backgroundColorHover: 'bg-gray-lightest dark:bg-dm-gray-darker',
    };
  }

  get hasError() {
    return this.sendState?.sendState === 0;
  }

  updateSendState(newSendState: SendState) {
    this.sendState = newSendState;
  }

  constructor(
    blocks: Array<object>,
    customBotSummary: CustomBotSummary,
    entity: AdminSummary,
    rulesetId?: number,
    selectionSetId?: number,
    sendState?: SendState,
    tags?: Array<Tag>,
  ) {
    this.blocks = blocks;
    this.customBotSummary = customBotSummary;
    this.entity = entity;
    this.rulesetId = rulesetId;
    this.selectionSetId = selectionSetId;
    this.sendState = sendState;
    this.tags = tags || [];
  }

  static deserialize(json: CustomBotWireFormat): CustomBot {
    return new CustomBot(
      json.blocks,
      CustomBotSummary.deserialize(json.custom_bot_summary),
      AdminSummary.deserialize(json.entity),
      json.ruleset_id,
      json.selection_set_id,
      json.send_state && SendState.deserialize(json.send_state),
      json.tags && json.tags.map(Tag.deserialize),
    );
  }
}
