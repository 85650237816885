/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';
import { not } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default StandardSummaryConfiguration.extend({
  init(_inputs) {
    this._super({
      title: 'Trigger your survey automatically with rules',
      component: 'content-editor/summaries/audience/content/audience-summary/summary',
    });
  },
  contentEditorService: service(),
  isVisible: not('contentEditorService.isSeriesMode').readOnly(),
});
