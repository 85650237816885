/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import Countries from 'embercom/lib/countries';

export default class Address extends Fragment {
  @attr('string') streetAddress;
  @attr('string') city;
  @attr('string') postcode;
  @attr('string') country;
  @attr('string') state;
  get summary() {
    let summaryElements = [this.city, this.state, this.country];
    return summaryElements.filter((obj) => obj).join(', ');
  }
  get inEu() {
    if (!this.country) {
      return false;
    }
    let country = Countries.allCountries.find(
      (country) => country.code.toUpperCase() === this.country.toUpperCase(),
    );
    return country.inEu;
  }
}
