/* RESPONSIBLE TEAM: team-help-desk-experience */
const DEFAULT_AVATAR_URL = 'static.intercomassets.com/assets/default-avatars';

// Our default avatar URLs have dark and light mode options. If dark mode is
// enabled and it's a default URL, return the dark mode variation, otherwise
// return the original URL.
export function transformAvatarUrl(url: string, dark = false) {
  let isDefaultImage = url.includes(DEFAULT_AVATAR_URL);
  if (isDefaultImage && dark) {
    return url.replace(/\.png$/, '-dark.png');
  }

  return url;
}
