/* RESPONSIBLE TEAM: team-workflows */
export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;
export const MAX_DELAY_IN_SECONDS = 14 * SECONDS_IN_DAY;
export const MIN_DELAY_IN_SECONDS = 30;
export const DEFAULT_DELAY = 300;

export default class Delays {
  getDelayUnit(delayInSeconds) {
    if (delayInSeconds % SECONDS_IN_DAY === 0) {
      return 'day';
    }
    if (delayInSeconds % SECONDS_IN_HOUR === 0) {
      return 'hour';
    }
    if (delayInSeconds % SECONDS_IN_MINUTE === 0) {
      return 'minute';
    }
    return 'second';
  }

  getDelayValue(delayInSeconds) {
    if (delayInSeconds % SECONDS_IN_DAY === 0) {
      return delayInSeconds / SECONDS_IN_DAY;
    }
    if (delayInSeconds % SECONDS_IN_HOUR === 0) {
      return delayInSeconds / SECONDS_IN_HOUR;
    }
    if (delayInSeconds % SECONDS_IN_MINUTE === 0) {
      return delayInSeconds / SECONDS_IN_MINUTE;
    }
    return delayInSeconds;
  }

  getDelayInSeconds(value, unit) {
    switch (unit) {
      case 'second':
        return value;
      case 'minute':
        return value * SECONDS_IN_MINUTE;
      case 'hour':
        return value * SECONDS_IN_HOUR;
      case 'day':
        return value * SECONDS_IN_DAY;
      default:
        throw new Error('Invalid unit type');
    }
  }
}
