/* RESPONSIBLE TEAM: team-proactive-support */
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';

export default StandardSummaryConfiguration.extend({
  init(inputs) {
    this._super({
      title: '',
      component: 'content-editor/summaries/custom/resolution-bot-behavior/state-change-summary',
      isVisible: true,
    });
  },
});
