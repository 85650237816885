/* RESPONSIBLE TEAM: team-workflows */
import { attr } from '@ember-data/model';
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { validator, buildValidations } from 'ember-cp-validations';
import type Store from '@ember-data/store';
import { ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  attributeIdentifier: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
    validator('attribute-collector-step-supported-by-channels'),
  ],
  attributeLabel: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
  ],
  attributeCollectionOverwritable: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
  ],
  attributeCollectionDisabled: [
    validator('presence', {
      presence: true,
      messageKey: 'operator.workflows.visual-builder.validations.content-is-missing',
    }),
  ],
});

export default class AttributeCollector extends Step.extend(Validations) {
  @attr('string') declare attributeIdentifier?: string;
  @attr('string') declare attributeLabel?: string;
  @attr('boolean') declare attributeCollectionOverwritable?: boolean;
  @attr('boolean') declare attributeCollectionDisabled?: boolean;
  @attr('string') declare attributeDataType?: string;

  supportedChannels = ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL;

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'bot_workflows';
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  static createNewStep(store: Store): AttributeCollector {
    return store.createRecord('operator/visual-builder/step/attribute-collector', {
      type: 'operator/visual-builder/step/attribute-collector',
    });
  }
}
