/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { type RoleLabel } from '../user-summary';
import { ChildAction, ParentAction } from './action';
import type InboxState from 'embercom/services/inbox-state';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';

export default class JumpToAppDetailsAction extends ParentAction {
  @service declare inboxState: InboxState;
  @service declare inboxSidebarService: InboxSidebarService;

  id = 'jump-to-app-details';
  icon = 'app-store' as const;
  paneComponent = 'inbox2/command-k/jump-to-app-details/pane';

  buildTopLevelItems(query: string): Array<ChildAction> {
    return this.buildResults(query);
  }

  buildResults(query: string): Array<ChildAction> {
    let roleLabel: RoleLabel | undefined;
    if (this.context?.conversation?.userSummary?.typeLabel) {
      roleLabel = this.context?.conversation?.userSummary?.typeLabel;
    }

    let sections: SidebarSection[] = [];

    if (this.inboxState.activeConversation) {
      sections = this.inboxSidebarService.pinnedSidebarSections(this.inboxState.activeConversation);
    }

    let c = sections.map((section) => {
      return new ChildAction({
        parent: this,
        id: section.id?.toString() || section.intercomModuleIdentifier || section.name,
        label:
          this.inboxSidebarService.sidebarSectionDetails(roleLabel)[
            section.intercomModuleIdentifier as SupportedSidebarSection
          ]?.label || section.name,
        value: section,
        icon: 'app-store',
      });
    });
    let upperQuery = query.trim().toLocaleUpperCase();
    return c.filter((action) => action.label.toLocaleUpperCase().includes(upperQuery));
  }
}
