/* RESPONSIBLE TEAM: team-workflows */
import { timezoneTypes } from 'embercom/models/data/matching-system/matching-constants';
import AudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';
import TriggerableCustomBotContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/triggerable-custom-bot/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';

export default function generateEditModeConfig({ container, customBot }) {
  let intl = container.lookup('service:intl');
  let attributeService = container.lookup('service:attributeService');
  let hasTrigger = !customBot.isNoTrigger;
  let shouldShowChannelTargeting = customBot.canUseOmnichannel;

  let audienceEditorConfiguration = new StandardAudienceEditorConfiguration({
    showMatchBehavior: false,
    allowedUserRoles: customBot.allowedUserRoles,
    showClientPredicates: false,
    showAudiencePreview: false,
    additionalAttributes: attributeService.visualBotBuilderConditionalAttributes,
    includeTicketAttributeGroups: true,
    container,
  });

  if (shouldShowChannelTargeting) {
    audienceEditorConfiguration.header = ComponentConfiguration.create({
      component: 'content-editor/panels/custom/triggerable-custom-bot/channel-targeting/body',
      config: {},
    });
    audienceEditorConfiguration.audienceRulesEditor = ComponentConfiguration.create({
      component:
        'content-editor/audience-wrappers/content/triggerable-custom-bots/audience-rules-editor',
      config: audienceEditorConfiguration.inputs,
    });
  }

  let contentPanel = TriggerableCustomBotContentPanelConfiguration.create({
    container,
    customBot,
  });

  let audiencePanel = StandardAudiencePanelConfiguration.create({
    hideMatchBehavior: true,
    audienceEditorConfiguration,
    container,
    audienceHeaderConfiguration: AudienceHeaderConfiguration.create(
      {
        showChannelTargetingSummary: shouldShowChannelTargeting,
        component: 'content-editor/panels/audience/content/triggerable-custom-bot/header',
      },
      { container },
    ),
  });

  let schedulePanel = StandardSchedulePanelConfiguration.create({
    timezoneType: timezoneTypes.basedOnAppTimezone,
    container,
  });

  let goalPanel = StandardGoalPanelConfiguration.create({
    introText: intl.t('operator.custom-bot.editor.goal-panel.description'),
    container,
  });

  let panelConfiguration = hasTrigger
    ? [audiencePanel, contentPanel, schedulePanel, goalPanel]
    : [contentPanel];

  return EditModeConfig.create({
    headerComponentConfigs: [
      StandardEditModeHeaderConfiguration.create({
        container,
        moreDropdownConfig: MoreDropdownConfiguration.create({
          duplicateModalComponent: 'custom-bots/duplicate-modal',
          hideCsvExportOption: true,
          hideTagOption: false,
          container,
        }),
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/workflows/preview-button',
          analyticEvent: {
            place: 'custom_bots',
          },
        }),
        afterSaveRedirectRoute: 'apps.app.automation.workflows-overview',
      }),
    ],
    editorPanelConfigs: panelConfiguration,
    footerComponentConfigs: [],
  });
}
