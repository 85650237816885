/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { or, alias, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isAny } from '@intercom/pulse/lib/computed-properties';
import { fragment } from 'ember-data-model-fragments/attributes';
import { userTypeComputedProperty } from './utils/userTypeComputedProperty';

export default Model.extend({
  messengerSettingsTargetUserProvider: service(),
  isUserType: alias('messengerSettingsTargetUserProvider.isUserType'),
  targetUserType: alias('messengerSettingsTargetUserProvider.targetUserType'),

  slotGroups: hasMany('messenger-home/slot-group', { async: false }),

  slotGroup: computed('targetUserType', 'slotGroups', {
    get(_) {
      return this.slotGroups.find(({ targetUserType }) => targetUserType === this.targetUserType);
    },
  }),

  hasEmptySlotGroup: isAny('slotGroups', 'isEmpty'),
  hasUnsavedSlotGroups: isAny('slotGroups', 'hasUnsavedChanges'),
  hasUnsavedChanges: or('hasUnsavedSlotGroups', 'hasDirtyAttributes'),

  requireSearchBrowseForUsers: attr('boolean'),
  requireSearchBrowseForVisitors: attr('boolean'),

  requireSearchBrowse: userTypeComputedProperty(
    'targetUserType',
    'requireSearchBrowseForUsers',
    'requireSearchBrowseForVisitors',
  ),

  toggleRequireSearchBrowse() {
    this.requireSearchBrowse = !this.requireSearchBrowse;
    if (this.requireSearchBrowse && !this.slotGroup.hasSlot('help_center')) {
      this.slotGroup.addSlot({ slotType: 'help_center', displayOrder: 0 });
    }
  },

  disableRequireSearchBrowse() {
    if (this.requireSearchBrowse) {
      this.toggleRequireSearchBrowse();
    }
  },

  requireSearchBrowseForUsersPredicates: fragment('predicates/predicate-group'),
  requireSearchBrowseForVisitorsPredicates: fragment('predicates/predicate-group'),

  requireSearchBrowsePredicates: userTypeComputedProperty(
    'targetUserType',
    'requireSearchBrowseForUsersPredicates',
    'requireSearchBrowseForVisitorsPredicates',
  ),

  homeScreenSuggestionsEnabledForUsers: attr('boolean'),
  homeScreenSuggestionsEnabledForVisitors: attr('boolean'),

  homeScreenSuggestionsEnabled: userTypeComputedProperty(
    'targetUserType',
    'homeScreenSuggestionsEnabledForUsers',
    'homeScreenSuggestionsEnabledForVisitors',
  ),

  hasValidPredicates: computed(
    'requireSearchBrowseForUsers',
    'requireSearchBrowseForUsersPredicates.isValid',
    'requireSearchBrowseForVisitors',
    'requireSearchBrowseForVisitorsPredicates.isValid',
    function () {
      if (this.requireSearchBrowseForUsers && !this.requireSearchBrowseForUsersPredicates.isValid) {
        return false;
      }

      if (
        this.requireSearchBrowseForVisitors &&
        !this.requireSearchBrowseForVisitorsPredicates.isValid
      ) {
        return false;
      }

      return true;
    },
  ),

  isValid: reads('hasValidPredicates'),

  refresh() {
    this.store.unloadAll('messenger-home/slot-group');
    this.store.unloadAll('messenger-home/slot');
    this.rollbackAttributes();
    return this.reload();
  },
});
