/* RESPONSIBLE TEAM: team-customer-data-platform */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment } from 'ember-data-model-fragments/attributes';
import type LogContext from 'embercom/models/crm/log-context';

export default class Log extends Fragment {
  @attr('string') declare _id: string;
  @attr('string') declare status: string;
  @attr('string') declare direction: string;
  @attr('string') declare triggerType: string;
  @attr('string') declare sourceObjectType: string;
  @attr('string') declare sourceObjectId: string;
  @attr('string') declare sourceObjectUrl: string;
  @attr('string') declare destinationObjectType: string;
  @attr('string') declare destinationObjectId: string;
  @attr('string') declare destinationObjectUrl: string;
  @fragment('crm/log-context') declare context: LogContext;
  @attr('date') declare updatedAt: Date;
  @attr('string') declare dataImportId: string;

  get isManualBulkSync(): boolean {
    return !!this.dataImportId;
  }
}
