/* RESPONSIBLE TEAM: team-customer-data-platform */
import Model, { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';

export default class LeadAccountOwnershipData extends Model {
  @attr('string') sectionId;
  @attr('array') salesforceUsers;
  @attr('array') salesforceAccountLists;
  @fragment('integrations/salesforce/settings') settings;
  @fragmentArray('integrations/salesforce/account-list') accountLists;
}
