/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type OutboundContent from './outbound-content';

export default class CollectionIdToOutboundContent extends Fragment {
  @attr('number') declare collectionId: number;
  @fragment('customization-options/outbound-content') declare outboundContent: OutboundContent;
}
