/* RESPONSIBLE TEAM: team-workflows */
import Model, { belongsTo } from '@ember-data/model';
import type ConnectionPoint from './connection-point';
import type Group from './group';

export default class Edge extends Model {
  @belongsTo('operator/visual-builder/connection-point', { async: false })
  declare outwardConnectionPoint: ConnectionPoint;
  @belongsTo('operator/visual-builder/group', { async: false }) declare toGroup: Group;

  get analyticsData() {
    return {
      ...(this.toGroup?.workflow?.analyticsData ?? {}),
      object: 'visual_builder_edge',
      edge_id: this.id,
      from_step_id: this.outwardConnectionPoint?.step?.id,
      from_step_type: this.outwardConnectionPoint?.step?.type,
    };
  }

  get fromGroup() {
    return this.outwardConnectionPoint.step?.group || this.outwardConnectionPoint.group;
  }

  get isStatic() {
    return false;
  }
}
