/* RESPONSIBLE TEAM: team-phone */
export class CtiMessage {
  static SEND_OUTBOUND_CALL = 'send_outbound_call';
  static NOTIFY_READY = 'notify_ready';
  static NOTIFY_NOT_READY = 'notify_not_ready';
  static NOTIFY_NEW_CALL = 'notify_new_call';
  static NOTIFY_ACCEPTED_CALL = 'notify_accepted_call';
  static NOTIFY_ENDED_CALL = 'notify_ended_call';
  static NOTIFY_INIT_FAILURE = 'notify_init_failure';
}

export type IntercomCtiData = {
  type: string;
  payload: any;
};

export type IntercomCtiMessage = {
  intercomCti: IntercomCtiData;
};

export type EventHandler = (IntercomCtiMessage: any) => void;

/**
 * **Inbound** is started when a call comes in
 *
 * **Outbound** can be started from places like the user profile and will create a new conversation
 *
 * **Escalation** can only be started from an existing conversation
 */
export enum CallType {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
  Escalation = 'ESCALATION',
}
