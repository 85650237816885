/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class EventMetadata extends Fragment {
  @attr('string') declare name: string;
  @attr('string') declare type: string;
  @attr('string') declare humanFriendlyName: string;
  @attr('string') declare identifier: string;
  @attr('string') declare templatableIdentifier: string;
}
