/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class Settings extends Fragment {
  @attr('boolean') automaticTaskCreation;
  @attr('boolean') createCaseOnConversationClose;
  @attr('boolean') createCaseOnConversationCreate;
  @attr('string', { defaultValue: 'Amount' }) opportunityValueField;
  @attr('array', { defaultValue: () => [] }) caseFields;
  @attr('boolean', { defaultValue: false }) opportunitySync;
  @attr('number', { defaultValue: 0 }) apiLimit;
  @attr('boolean', { defaultValue: true }) autoAssign;
  @attr('boolean', { defaultValue: true }) caseAutoAssign;
  @attr('string') intercomIdentityMappingAttribute;
  @attr('string') salesforceIdentityMappingField;
  @attr('string') salesforceAccountIdentityMappingField;
  @attr('boolean') identityMappingEmailFallbackEnabled;
  @attr('boolean') caseRecordTypesEnabled;
  @attr('string') automaticCaseCreationRecordType;
}
