/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
let HoursWith30MinsInterval = [
  { label: '12:00 am', secondsFromMidnight: 0 },
  { label: '12:30 am', secondsFromMidnight: 1 * 1800 },
  { label: '1:00 am', secondsFromMidnight: 2 * 1800 },
  { label: '1:30 am', secondsFromMidnight: 3 * 1800 },
  { label: '2:00 am', secondsFromMidnight: 4 * 1800 },
  { label: '2:30 am', secondsFromMidnight: 5 * 1800 },
  { label: '3:00 am', secondsFromMidnight: 6 * 1800 },
  { label: '3:30 am', secondsFromMidnight: 7 * 1800 },
  { label: '4:00 am', secondsFromMidnight: 8 * 1800 },
  { label: '4:30 am', secondsFromMidnight: 9 * 1800 },
  { label: '5:00 am', secondsFromMidnight: 10 * 1800 },
  { label: '5:30 am', secondsFromMidnight: 11 * 1800 },
  { label: '6:00 am', secondsFromMidnight: 12 * 1800 },
  { label: '6:30 am', secondsFromMidnight: 13 * 1800 },
  { label: '7:00 am', secondsFromMidnight: 14 * 1800 },
  { label: '7:30 am', secondsFromMidnight: 15 * 1800 },
  { label: '8:00 am', secondsFromMidnight: 16 * 1800 },
  { label: '8:30 am', secondsFromMidnight: 17 * 1800 },
  { label: '9:00 am', secondsFromMidnight: 18 * 1800 },
  { label: '9:30 am', secondsFromMidnight: 19 * 1800 },
  { label: '10:00 am', secondsFromMidnight: 20 * 1800 },
  { label: '10:30 am', secondsFromMidnight: 21 * 1800 },
  { label: '11:00 am', secondsFromMidnight: 22 * 1800 },
  { label: '11:30 am', secondsFromMidnight: 23 * 1800 },
  { label: '12:00 pm', secondsFromMidnight: 24 * 1800 },
  { label: '12:30 pm', secondsFromMidnight: 25 * 1800 },
  { label: '1:00 pm', secondsFromMidnight: 26 * 1800 },
  { label: '1:30 pm', secondsFromMidnight: 27 * 1800 },
  { label: '2:00 pm', secondsFromMidnight: 28 * 1800 },
  { label: '2:30 pm', secondsFromMidnight: 29 * 1800 },
  { label: '3:00 pm', secondsFromMidnight: 30 * 1800 },
  { label: '3:30 pm', secondsFromMidnight: 31 * 1800 },
  { label: '4:00 pm', secondsFromMidnight: 32 * 1800 },
  { label: '4:30 pm', secondsFromMidnight: 33 * 1800 },
  { label: '5:00 pm', secondsFromMidnight: 34 * 1800 },
  { label: '5:30 pm', secondsFromMidnight: 35 * 1800 },
  { label: '6:00 pm', secondsFromMidnight: 36 * 1800 },
  { label: '6:30 pm', secondsFromMidnight: 37 * 1800 },
  { label: '7:00 pm', secondsFromMidnight: 38 * 1800 },
  { label: '7:30 pm', secondsFromMidnight: 39 * 1800 },
  { label: '8:00 pm', secondsFromMidnight: 40 * 1800 },
  { label: '8:30 pm', secondsFromMidnight: 41 * 1800 },
  { label: '9:00 pm', secondsFromMidnight: 42 * 1800 },
  { label: '9:30 pm', secondsFromMidnight: 43 * 1800 },
  { label: '10:00 pm', secondsFromMidnight: 44 * 1800 },
  { label: '10:30 pm', secondsFromMidnight: 45 * 1800 },
  { label: '11:00 pm', secondsFromMidnight: 46 * 1800 },
  { label: '11:30 pm', secondsFromMidnight: 47 * 1800 },
];

export default HoursWith30MinsInterval;
