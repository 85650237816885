/* RESPONSIBLE TEAM: team-channels */
export interface ArticleSummaryWireFormat {
  id: number;
  title: string;
}

export default class ArticleSummary {
  readonly id: number;
  readonly title: string;

  constructor(id: number, title: string) {
    this.id = id;
    this.title = title;
  }

  static deserialize(json: ArticleSummaryWireFormat): ArticleSummary {
    return new ArticleSummary(json.id, json.title);
  }
}
