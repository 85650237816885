/* RESPONSIBLE TEAM: team-workflows */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

const Validations = buildValidations({
  compatibleChannels: [
    validator('trigger-workflow-channel-compatibility', {
      dependentKeys: ['model.targetChannels.[]'],
    }),
  ],
  triggerableWorkflowId: [
    validator('presence', {
      presence: true,
      messageKey:
        'operator.workflows.visual-builder.validations.fin.hard-resolution-workflow.no-workflow',
    }),
  ],
});

export default class HardResolutionWorkflow extends Fragment.extend(Validations) {
  @service declare contentEditorService: $TSFixMe;

  @attr('number') declare triggerableWorkflowId: number;
  @attr('boolean', { defaultValue: true }) declare continueWithFin: boolean;
  @attr('array') declare compatibleChannels?: string[];

  @computed('contentEditorService.activeObject.targetChannels.[]')
  get targetChannels() {
    return this.contentEditorService.activeObject?.targetChannels;
  }
}
