/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ParentAction, ChildAction } from './action';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { type SearchMultipleTypesResponse } from 'embercom/services/quick-search';
import { inject as service } from '@ember/service';

export abstract class TriggerWorkflowConnectorBaseAction extends ParentAction {
  @service declare inboxHotkeys: InboxHotkeys;

  abstract id: string;
  icon = 'webhook' as const;
  searchableTypes = [SearchableType.WorkflowConnectorAction];
  paneComponent = 'inbox2/command-k/trigger-workflow-connector-action/pane';

  buildTopLevelItems(
    _query: string,
    quickSearchResults: SearchMultipleTypesResponse,
  ): Array<ChildAction> {
    return this.buildResults(quickSearchResults);
  }

  buildResults(quickSearchResults: SearchMultipleTypesResponse): Array<ChildAction> {
    let results = quickSearchResults[SearchableType.WorkflowConnectorAction]?.results;
    if (!results) {
      return [];
    }

    return results.map((result) => {
      return new ChildAction({
        parent: this,
        id: result.data.id.toString(),
        label: result.data.name,
        value: result.data,
        optionComponent: 'inbox2/command-k/trigger-workflow-connector-action/option',
      });
    });
  }
}

export class TriggerWorkflowConnectorAction extends TriggerWorkflowConnectorBaseAction {
  id = 'trigger-workflow-connector-action';
  shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.TriggerWorkflowConnectorAction];
}

export class TriggerWorkflowConnectorActionPart extends TriggerWorkflowConnectorBaseAction {
  id = 'trigger-workflow-connector-action-part';
}
