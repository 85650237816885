/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { BaseConfig, EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';

export default class AnnotationsComposerConfig extends BaseConfig {
  placeholder = 'Start writing ...';
  textDirection = 'ltr';
  autoFocus = false;
  allowImplicitMarginParagraphs = true;
  typeaheads = [EMOJI_TYPEAHEAD];
  tools = [{ name: 'text-formatter' }];
  allowedBlocks = ['paragraph'];
  allowedInline = ['bold', 'italic'];
}
