/* RESPONSIBLE TEAM: team-workflows */
import Model, { attr } from '@ember-data/model';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';

export default class CustomBotReportableBot extends Model {
  @attr('string') title;
  @attr('string') type;
  @attr('string') target;

  get customBotConfig() {
    return CUSTOM_BOT_CONFIG[this.target];
  }
}
