/* RESPONSIBLE TEAM: team-phone */
import { tracked } from '@glimmer/tracking';

export default class PhoneDetailsAudioElement {
  @tracked duration = 0;
  @tracked volume = 1;
  @tracked muted = false;
  @tracked playbackRate = 1;
  @tracked loadedMetaData = false;
  @tracked isPlaying = false;
  @tracked currentTime = 0;

  isSeeking = false;
  wasPlaying = false;

  constructor(
    private _audioElement: HTMLAudioElement,
    private _onPlay?: () => void,
  ) {
    this.addEventListeners();
  }

  addEventListeners() {
    this._audioElement.addEventListener('loadedmetadata', this.onLoadedmetadata.bind(this));
    this._audioElement.addEventListener('play', this.onAudioPlaying.bind(this));
    this._audioElement.addEventListener('pause', this.onAudioPaused.bind(this));
    this._audioElement.addEventListener('timeupdate', this.onTimeUpdate.bind(this));
    this._audioElement.addEventListener('volumechange', this.onVolumeUpdated.bind(this));
    this._audioElement.addEventListener('error', this.onError.bind(this));
  }

  removeEventListeners() {
    this._audioElement.removeEventListener('loadedmetadata', this.onLoadedmetadata.bind(this));
    this._audioElement.removeEventListener('play', this.onAudioPlaying.bind(this));
    this._audioElement.removeEventListener('pause', this.onAudioPaused.bind(this));
    this._audioElement.removeEventListener('timeupdate', this.onTimeUpdate.bind(this));
    this._audioElement.removeEventListener('volumechange', this.onVolumeUpdated.bind(this));
    this._audioElement.removeEventListener('error', this.onError.bind(this));
  }

  onLoadedmetadata() {
    this.duration = this._audioElement.duration;
    this.volume = this._audioElement.volume;
    this.muted = this._audioElement.muted;
    this.playbackRate = this._audioElement.playbackRate;
    this.loadedMetaData = true;
  }

  onAudioPlaying() {
    this.isPlaying = true;
    this._onPlay?.();
  }

  onAudioPaused() {
    this.isPlaying = false;
  }

  onTimeUpdate() {
    this.currentTime = this._audioElement.currentTime;
  }

  onVolumeUpdated() {
    this.volume = this._audioElement.volume;
    this.muted = this._audioElement.muted;
  }

  onError(event: ErrorEvent) {
    console.error(event);
  }

  togglePlaying() {
    this.isPlaying = !this.isPlaying;
    this.isPlaying ? this._audioElement.play() : this._audioElement.pause();
  }

  toggleMute() {
    this.muted = !this.muted;
    this._audioElement.muted = this.muted;
  }

  onVolumeChange(volume: number) {
    this.volume = volume;
    this._audioElement.volume = this.volume;

    if (this.volume > 0) {
      this.muted = false;
      this._audioElement.muted = false;
    }
  }

  onPlaybackRateSelected(playbackRate: number) {
    this.playbackRate = playbackRate;
    this._audioElement.playbackRate = this.playbackRate;
  }

  startSeeking() {
    this.wasPlaying = this.isPlaying;
    this.isSeeking = true;

    if (this.wasPlaying) {
      this._audioElement.pause();
    }
  }

  stopSeeking(duration: string) {
    this.currentTime = parseInt(duration, 10);
    this.isSeeking = false;

    this._audioElement.currentTime = this.currentTime;

    if (this.wasPlaying) {
      this._audioElement.play();
      this.wasPlaying = false;
    }
  }
}
