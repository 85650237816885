/* RESPONSIBLE TEAM: team-frontend-tech */
export default {
  startCase(str) {
    return str
      .replace(/_/g, ' ')
      .replace(/quarterly /gi, '')
      .replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  },
  getDisplayName(str) {
    let pricingMetric = str;
    if (pricingMetric === 'monthly_active_people_beta') {
      pricingMetric = 'monthly_active_people';
    }
    if (pricingMetric === 'latest_daily_admin_count') {
      pricingMetric = 'teammates';
    }
    return `${this.startCase(pricingMetric)}`;
  },
};
