/* RESPONSIBLE TEAM: team-workflows */
import { hasMany } from '@ember-data/model';
import TicketTypeSummary from './ticket-type-summary';
import { getEmberDataStore } from '../../lib/container-lookup';
import { inject as service } from '@ember/service';
export default class TicketType extends TicketTypeSummary {
  @service store;

  @hasMany('conversation-attributes/descriptor', { async: false }) descriptors;

  get userVisibleDescriptors() {
    return this.descriptors.filter((x) => x.visibleToUsers && !x.archived);
  }

  static peekAllAndMaybeLoad() {
    let store = getEmberDataStore();
    let records = store.peekAll('inbox/ticket-type');

    if (!records.length) {
      records = store.findAll('inbox/ticket-type');
    }
    return records;
  }

  static ticketTypesByCategory(ticketTypes) {
    if (!ticketTypes) {
      return [];
    }

    return ticketTypes.reduce((groupedTickets, ticket) => {
      if (!groupedTickets[ticket.category]) {
        groupedTickets[ticket.category] = [];
      }

      groupedTickets[ticket.category].push(ticket);

      return groupedTickets;
    }, {});
  }
}
