/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default {
  noChannel: -1,
  anyChannel: 0,
  webChannel: 2,
  iosChannel: 3,
  androidChannel: 4,
  emailType: 'Email',
  inAppType: 'In-App',
  pushType: 'Push',
  customBotType: 'Custom Bot',
  reactionsReply: 'reactions_reply',
  textReply: 'text_reply',
  attributeCollectorReply: 'attribute_collector_reply',
  repliesDisabled: 'disabled_reply',
  noStyle: -1,
  chat: 0,
  post: 1,
  note: 2,
  video: 3,
  pointer: 4,
  emailStyle: 5,
  noPageTarget: 0,
  roundRobin: 'round_robin',
};

const COMPOSER_UPLOAD_WARNING = `Your content's still uploading. Try again in a second.`;

const VALID_STATES = ['draft', 'live', 'scheduled', 'stopped', 'ended'];

export { COMPOSER_UPLOAD_WARNING, VALID_STATES };
