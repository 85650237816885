/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from '../component-configuration';
import { assert } from '@ember/debug';

export default ComponentConfiguration.extend({
  component: undefined,
  title: undefined,
  isVisible: undefined,

  init(inputs) {
    this._super(...arguments);
    assert(
      `The component path must be within the content-editor/summaries or series/summaries directory. You passed '${inputs.component}'`,
      inputs.component.startsWith('content-editor/summaries/') ||
        inputs.component.startsWith('series/summaries/'),
    );

    this.setProperties(inputs);

    assert('A value or computed property must be provided for title', this.title !== undefined);
    assert(
      'A value or computed property must be provided for isVisible',
      this.isVisible !== undefined,
    );
  },
});
