/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type Profile from 'embercom/models/ai-agent/profile';

export default class extends RulesetLink {
  @belongsTo('ai-agent/profile', { async: false })
  declare object: Profile;
  objectType = objectTypes.resolutionBotBehavior;
  shouldReloadRecord = true;

  ready() {
    this.object.beforeEdit();
  }
}
