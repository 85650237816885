/* RESPONSIBLE TEAM: team-frontend-tech */
// This file contains code migrated from the deprecated
// `ember-overlays` internal overlay library. If you'd
// like to use or change anything here please come chat
// to us in the #ember_js channel on Slack

const NULL_BOX_EDGES = {
  topEdge: 0,
  rightEdge: 0,
  bottomEdge: 0,
  leftEdge: 0,
};

const SCREEN_MARGIN_OF_ZERO = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const EDGE_NAMES = ['topEdge', 'rightEdge', 'bottomEdge', 'leftEdge'];

function getScreenWidth() {
  // alternative to $(window).width()
  return document.documentElement.clientWidth;
}

function getScreenHeight() {
  // alternative to $(window).height()
  return document.documentElement.clientHeight;
}

function heightOfBoxEdges(boxEdges) {
  return boxEdges.bottomEdge - boxEdges.topEdge;
}

function widthOfBoxEdges(boxEdges) {
  return boxEdges.rightEdge - boxEdges.leftEdge;
}

function screenBoxSize(screenMargin) {
  return screenBoxSizeAccommodatingMargins(screenMargin, getScreenWidth(), getScreenHeight());
}

function screenBoxSizeAccommodatingMargins(screenMargin, screenWidth, screenHeight) {
  return {
    width: screenWidth - (screenMargin.left + screenMargin.right),
    height: screenHeight - (screenMargin.top + screenMargin.bottom),
  };
}

function screenEdgesForScreenSizeHash(screenMargin, screenSize) {
  let screenBoxPosition = {
    top: screenMargin.top,
    left: screenMargin.left,
  };

  return boundingBoxEdges(screenBoxPosition, screenSize);
}

function screenEdges(screenMargin) {
  if (typeof screenMargin === 'undefined') {
    screenMargin = SCREEN_MARGIN_OF_ZERO;
  }

  return screenEdgesForScreenSizeHash(screenMargin, screenBoxSize(screenMargin));
}

function boundingBoxEdges(boundingBoxPosition, boundingBoxSize) {
  let topEdge = boundingBoxPosition.top;
  let rightEdge = boundingBoxPosition.left + boundingBoxSize.width;
  let bottomEdge = boundingBoxPosition.top + boundingBoxSize.height;
  let leftEdge = boundingBoxPosition.left;

  return {
    topEdge,
    rightEdge,
    bottomEdge,
    leftEdge,
  };
}

function boundingBoxOnScreen(boundingBoxPosition, boundingBoxSize, screenMargin) {
  let s = screenEdges(screenMargin);
  let b = boundingBoxEdges(boundingBoxPosition, boundingBoxSize);

  return boundingBoxInContainer(b, s);
}

function boundingBoxInContainer(hashOfBoundingBoxEdges, containerEdges) {
  return (
    hashOfBoundingBoxEdges.topEdge >= containerEdges.topEdge &&
    hashOfBoundingBoxEdges.rightEdge <= containerEdges.rightEdge &&
    hashOfBoundingBoxEdges.bottomEdge <= containerEdges.bottomEdge &&
    hashOfBoundingBoxEdges.leftEdge >= containerEdges.leftEdge
  );
}

function isValidBoundingBoxEdges(boxEdges) {
  return heightOfBoxEdges(boxEdges) >= 0 && widthOfBoxEdges(boxEdges) >= 0;
}

function intersectingBoundingBoxEdges(boxEdgesOne, boxEdgesTwo) {
  let returnedBoxEdges = {};

  EDGE_NAMES.forEach((side) => {
    let fn = ['topEdge', 'leftEdge'].includes(side) ? Math.max : Math.min;
    returnedBoxEdges[side] = fn(boxEdgesOne[side], boxEdgesTwo[side]);
  });

  return isValidBoundingBoxEdges(returnedBoxEdges) ? returnedBoxEdges : NULL_BOX_EDGES;
}

function elementBoundingBox(el) {
  return elementAndChildrenBoundingBox(el, false);
}

function elementAndChildrenBoundingBox(el, includeChildren = true) {
  if (el === undefined || el === null) {
    return {
      top: 0,
      left: 0,
      topEdge: 0,
      rightEdge: 0,
      bottomEdge: 0,
      leftEdge: 0,
      width: 0,
      height: 0,
    };
  }

  // Just in case someone passes in a jQuery object
  if (typeof el.jquery === 'string') {
    el = el.get(0);
  }

  // Get boundingClientRect and convert to editable object
  let boundingClientRect = el.getBoundingClientRect();
  boundingClientRect = {
    left: boundingClientRect.left,
    right: boundingClientRect.right,
    top: boundingClientRect.top,
    bottom: boundingClientRect.bottom,
  };

  if (includeChildren) {
    let currentEl;
    let newBoundingClientRect;

    for (let i = 0, l = el.childNodes.length; i < l; i++) {
      currentEl = el.childNodes[i];
      if (typeof currentEl.getBoundingClientRect === 'function') {
        newBoundingClientRect = currentEl.getBoundingClientRect();
        if (newBoundingClientRect.height > 0 || newBoundingClientRect.width > 0) {
          boundingClientRect.top = Math.min(boundingClientRect.top, newBoundingClientRect.top);
          boundingClientRect.right = Math.max(
            boundingClientRect.right,
            newBoundingClientRect.right,
          );
          boundingClientRect.bottom = Math.max(
            boundingClientRect.bottom,
            newBoundingClientRect.bottom,
          );
          boundingClientRect.left = Math.min(boundingClientRect.left, newBoundingClientRect.left);
        }
      }
    }
  }

  return {
    left: boundingClientRect.left,
    top: boundingClientRect.top,
    topEdge: boundingClientRect.top,
    rightEdge: boundingClientRect.right,
    bottomEdge: boundingClientRect.bottom,
    leftEdge: boundingClientRect.left,
    width: boundingClientRect.right - boundingClientRect.left,
    height: boundingClientRect.bottom - boundingClientRect.top,
  };
}

function elementIsWithinScreen(el) {
  let boundingBox = elementAndChildrenBoundingBox(el);

  let boundingBoxPosition = {
    left: boundingBox.left,
    top: boundingBox.top,
  };
  let boundingBoxSize = {
    width: boundingBox.width,
    height: boundingBox.height,
  };

  return boundingBoxOnScreen(boundingBoxPosition, boundingBoxSize, SCREEN_MARGIN_OF_ZERO);
}

function allAncestorNodes(el, fn) {
  let parents = [];
  let parent = el.parentNode;
  while (parent !== null) {
    if (typeof fn !== 'function' || fn(parent)) {
      parents.push(parent);
    }
    parent = parent.parentNode;
  }
  return parents;
}

function intersectingBoundingBoxEdgesOfListOfElements(list) {
  let listWithoutFirst = list.slice(1);
  return listWithoutFirst.reduce((boundingBoxEdges, currentEl) => {
    return intersectingBoundingBoxEdges(elementBoundingBox(currentEl), boundingBoxEdges);
  }, elementBoundingBox(list[0]));
}

function elementCanScroll(el) {
  return el.clientHeight !== el.scrollHeight || el.clientWidth !== el.scrollWidth;
}

function intersectingBoundingBoxEdgesOfAllScrollableParents(el) {
  let scrollableParents = allAncestorNodes(el, elementCanScroll);
  if (scrollableParents.length === 0) {
    return undefined;
  }
  return intersectingBoundingBoxEdgesOfListOfElements(scrollableParents);
}

function elementIsFullyContainedWithinBoundingBoxEdges(el, containingBoundingBoxEdges) {
  let elementBoundingBoxIncludingChildren = elementAndChildrenBoundingBox(el);
  let returnable = true;
  EDGE_NAMES.forEach((side) => {
    if (['topEdge', 'leftEdge'].includes(side)) {
      if (containingBoundingBoxEdges[side] > elementBoundingBoxIncludingChildren[side]) {
        returnable = false;
      }
    } else if (containingBoundingBoxEdges[side] < elementBoundingBoxIncludingChildren[side]) {
      returnable = false;
    }
  });
  return returnable;
}

function elementIsNotOccludedByScrolling(el) {
  let containingBoundingBox = intersectingBoundingBoxEdgesOfAllScrollableParents(el);
  if (containingBoundingBox === undefined) {
    return true;
  }
  return elementIsFullyContainedWithinBoundingBoxEdges(el, containingBoundingBox);
}

export function elementIsVisibleOnScreen(element) {
  return elementIsWithinScreen(element) && elementIsNotOccludedByScrolling(element);
}

// This evaulates to true if the current user agent supports touch, in which
// case we should allow click events to open mouseenter Popovers (otherwise
// they would be inaccessible), etc.
// Implementation source:  https://stackoverflow.com/a/13470899/6945740
export const BROWSER_SUPPORTS_TOUCH = Boolean('ontouchstart' in window || navigator.maxTouchPoints);
