/* RESPONSIBLE TEAM: team-proactive-support */
import PanelConfiguration from 'embercom/objects/content-editor/panel-configuration';
import AudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import { assert } from '@ember/debug';
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';

export default PanelConfiguration.extend({
  init(inputs) {
    // The container gives us access to Services in these objects. Pass `container: getOwner(this)` as an
    // input when generating these configuration objects.
    assert('You must provide the application container in your panel inputs', inputs.container);

    let hideMatchBehavior =
      inputs.hideMatchBehavior ??
      (inputs.showMatchBehavior === undefined ? false : !inputs.showMatchBehavior);

    let showSendToUnsubscribedToggle =
      inputs.showSendToUnsubscribedToggle ??
      (inputs.showSendToUnsubscribedToggle === undefined
        ? false
        : inputs.showSendToUnsubscribedToggle);

    let showEventRules =
      inputs.showEventRules ??
      (inputs.showEventRules === undefined ? false : inputs.showEventRules);

    let showDateEvents =
      inputs.showDateEvents ??
      (inputs.showDateEvents === undefined ? false : inputs.showDateEvents);

    let header =
      inputs.audienceHeaderConfiguration ||
      AudienceHeaderConfiguration.create(
        {
          title: inputs.title,
          hideMatchBehavior,
        },
        { container: inputs.container },
      );
    let body = ComponentConfiguration.create({
      allowedUserRoles: inputs.allowedUserRoles,
      component: 'content-editor/panels/audience/body',
      showMatchBehavior: !hideMatchBehavior,
      audienceEditorConfiguration:
        inputs.audienceEditorConfiguration ||
        new StandardAudienceEditorConfiguration({
          container: inputs.container,
          showMatchBehavior: !hideMatchBehavior,
          showSendToUnsubscribedToggle,
          showAudiencePreview: true,
          showEventRules,
          showDateEvents,
        }),
    });
    this._super({
      name: 'audience',
      header,
      body,
    });
  },
});
