/* RESPONSIBLE TEAM: team-knowledge-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';

export default Model.extend({
  appId: attr(),
  createdAt: attr(),
  collectionsCreated: attr(),
  validUrlsProcessed: attr(),
  importCompletedAt: attr(),
  importFailedAt: attr(),
  importStartedAt: attr(),
  invalidUrlsProcessed: attr(),
  siteProvider: attr(),
  givenSiteProvider: attr(),
  totalUrlCount: attr(),
  missingContentErrorCount: attr(),
  siteUrl: attr(),
  titleSelector: attr(),
  bodySelector: attr(),
  categoriesSelector: attr(),
  entryType: attr(),
  isCustomTemplateImport: attr(),
  isBuildingCustomTemplate: attr(),
  isDoneCreatingArticles: attr(),
});
