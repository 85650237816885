/* RESPONSIBLE TEAM: team-phone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr } from '@ember-data/model';
import { ONLY_PHONE_CALL_CHANNEL } from 'embercom/lib/operator/custom-bots/constants';
import { buildValidations, validator } from 'ember-cp-validations';
import type ConnectionPoint from '../connection-point';
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';

const Validations = buildValidations({
  outwardConnectionPoints: [validator('has-many')],
  teamId: [validator('presence', true)],
  queueThreshold: [
    validator('number', {
      allowString: true,
      gte: 1,
      lte: 999,
      disabled: not('model.deflectWhenQueueThresholdBreached'),
    }),
  ],
  waitTimeMinutesThreshold: [
    validator('number', {
      allowString: true,
      gte: 1,
      lte: 999,
      disabled: not('model.deflectWhenWaitTimeThresholdBreached'),
    }),
  ],
});

export default class PhoneHoldAndAssign extends Step.extend(Validations) {
  @attr('string') declare teamId: string;
  @attr('boolean') declare deflectWhenTeamOutOfOfficeHours: boolean;
  @attr('boolean') declare deflectWhenQueueThresholdBreached: boolean;
  @attr('boolean') declare deflectWhenWaitTimeThresholdBreached: boolean;
  @attr('boolean') declare deflectWhenNoTeammatesAvailable: boolean;
  @attr('number') declare queueThreshold: number;
  @attr('number') declare waitTimeMinutesThreshold: number;

  supportedChannels = ONLY_PHONE_CALL_CHANNEL;

  @computed('hasDirtyAttributes', 'outwardConnectionPoints.@each.hasUnsavedChanges')
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.outwardConnectionPoints.isAny('hasUnsavedChanges');
  }

  get assignee() {
    return this.appService.app.teams.findBy('id', this.teamId);
  }

  get areSettingsValid() {
    return (
      this.validations.attrs.teamId.isValid &&
      this.validations.attrs.queueThreshold.isValid &&
      this.validations.attrs.waitTimeMinutesThreshold.isValid
    );
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  static createNewStep(store: Store): PhoneHoldAndAssign {
    let outwardConnectionPoints = [
      store.createRecord('operator/visual-builder/connection-point', { type: 'fallback' }),
    ] as ConnectionPoint[];

    return store.createRecord('operator/visual-builder/step/phone-hold-and-assign', {
      outwardConnectionPoints,
      type: 'operator/visual-builder/step/phone-hold-and-assign',
    });
  }
}
