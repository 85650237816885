/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Model from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default Model.extend({
  subscriptions: fragmentArray('appstore/app-package/stripe/subscription'),

  deleteSubscription(subscription) {
    return this.deleteAndUpdate(`subscription/${subscription.stripeAccountId}`);
  },

  deleteAndUpdate(path) {
    return this._delete(path).then((res) => this._updateModel(res));
  },

  _delete(path) {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);

    let url = `${adapter.buildURL(modelName, this.id)}/${path}`;
    return adapter.ajax(url, 'DELETE', { data: this.serialize() });
  },

  _updateModel(response) {
    let normalizedModel = this.store.normalize(this.constructor.modelName, response);
    return this.store.push(normalizedModel);
  },
});
