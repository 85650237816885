/* RESPONSIBLE TEAM: team-knowledge-interop */
import Model, { attr } from '@ember-data/model';
import {
  CONTENT_CREATION_RUN_STATUSES,
  RUN_STATUSES,
} from 'embercom/lib/external-content/constants';

export enum FailureReasonTypes {
  FAILED_TO_ENQUEUE_RUN = 'failed_to_enqueue_run',
  NO_IMPORTED_CONTENT = 'no_imported_content',
  APIFY_RUN_FAILED = 'apify_run_failed',
  CONTENT_IMPORT_FAILED = 'content_import_failed',
  CONTENT_INGESTION_FAILURE = 'content_ingestion_failure',
}
export default class ContentImportRun extends Model {
  @attr('string') declare contentType: string;
  @attr('string') declare triggerSource: string;
  @attr('string') declare status: string;
  @attr('string') declare url: string;
  @attr('string') declare adminId: string;
  @attr('number') declare enqueuedVersionId: number;
  @attr('boolean') declare isIngested: boolean;
  @attr('string') declare failureReason: FailureReasonTypes;

  get ingestionInProgress() {
    return this.enqueuedVersionId && this.isInProgress;
  }

  get isInProgress(): boolean {
    return CONTENT_CREATION_RUN_STATUSES.includes(this.status) && !this.isIngested;
  }

  get isCompleted(): boolean {
    return this.status === RUN_STATUSES.completed;
  }

  get isFullyIngested(): boolean {
    return this.isIngested || this.status === RUN_STATUSES.ingested;
  }

  get isFailed(): boolean {
    return this.status === RUN_STATUSES.failed;
  }
}
