/* RESPONSIBLE TEAM: team-help-desk-experience */

import Modifier from 'ember-modifier';
import scrollIntoViewIfNotVisible from 'embercom/lib/scroll-into-view-if-not-visible';

interface Args {
  named: {
    alignToTop: boolean;
    onScroll?: (id: string) => any;
  };
  positional: [string, string];
}

export default class ScrollIfSelected extends Modifier<Args> {
  private previousSelectedId?: string;

  modify(
    element: HTMLElement,
    [id, selectedId]: Args['positional'],
    { alignToTop, onScroll }: Args['named'],
  ) {
    if (this.previousSelectedId === selectedId) {
      return;
    }

    this.previousSelectedId = selectedId;
    if (id === selectedId) {
      scrollIntoViewIfNotVisible(element, { alignToTop });
      onScroll?.(id);
    }
  }
}
