/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  variation: belongsTo('message-variation'),
  url: attr('string'),
  clicks: attr('number'),
  linkType: attr('string'),
  analyticsData: computed('id', function () {
    return {
      object: 'click_tracking_link',
      click_tracking_link_id: this.id,
    };
  }),
});
