/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
let browserScrollbarWidth;

export default function () {
  if (browserScrollbarWidth !== undefined) {
    return browserScrollbarWidth;
  }

  let $scrollDiv = $('<div>');

  $scrollDiv.css({
    width: 100,
    height: 100,
    overflow: 'scroll',
    position: 'fixed',
    top: -9999,
  });

  // @pat - It is okay to skip sanitization here as there is no user input. We are just adding a div with CSS.
  /* eslint-disable jquery-unsafe/no-appendTo */
  $scrollDiv.appendTo('body');

  browserScrollbarWidth = $scrollDiv[0].offsetWidth - $scrollDiv[0].clientWidth;

  $scrollDiv.remove();

  return browserScrollbarWidth;
}
