/* RESPONSIBLE TEAM: team-reporting */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { inject as service } from '@ember/service';
// @ts-ignore
import { fragmentOwner } from 'ember-data-model-fragments/attributes';
import { isPresent } from '@ember/utils';
import { changeFilter } from 'embercom/lib/reporting/custom/filter-helpers';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import {
  type LogicalFilter,
  type LogicalFilterOperator,
} from 'embercom/components/reporting/custom/filters';
import type {
  Metric,
  Aggregation,
  FieldMetric,
} from 'embercom/objects/reporting/unified/metrics/types';
import type Chart from './chart';
import { humanizeFiltersForMetric } from 'embercom/lib/reporting/custom/filter-helpers';

export const DEFAULT_PERCENTILE_VALUE = 90;

export default class ChartSeries extends Fragment {
  @service declare reportingMetrics: ReportingMetrics;
  @service declare intl: any;

  @attr('string') declare metricId: string;
  @attr('string') declare color: string;
  @attr('string') declare aggregation: Aggregation | null;
  @attr('number', { defaultValue: undefined }) declare percentileValue: number | null;
  @attr('boolean', { defaultValue: false }) declare showDataLabels: boolean;
  @attr({ defaultValue: () => ({ type: 'and', filters: [] }) }) declare filters: LogicalFilter;
  @attr('string') declare label: string | null;
  @fragmentOwner() declare chart: Chart;

  get metric() {
    return this.reportingMetrics.getMetricById(this.metricId);
  }

  cleanupPercentile() {
    if (this.aggregation !== 'percentile' && isPresent(this.percentileValue)) {
      this.percentileValue = null;
    }
  }

  updateMetric(metricId: Metric['id']) {
    this.metricId = metricId;

    if (this.metric.type === 'field') {
      this.aggregation =
        this.aggregation &&
        (this.metric as FieldMetric).supportedAggregations.includes(this.aggregation)
          ? this.aggregation
          : (this.metric as FieldMetric).defaultAggregation;
    } else {
      this.aggregation = null;
    }

    if (this.aggregation === 'percentile') {
      this.percentileValue = this.percentileValue || DEFAULT_PERCENTILE_VALUE;
    } else {
      this.percentileValue = null;
    }

    this.ensureFiltersAreCompatibleWithCurrentMetric();
    this.ensureMetricsAreInSameUnit();
    this.resetLabel();
  }

  ensureMetricsAreInSameUnit() {
    this.chart.removeMetricsWithDifferentUnits(this.metric.unit);
  }

  ensureFiltersAreCompatibleWithCurrentMetric() {
    // TODO remove this once all tests pass
    let filtersMissingAttributes = this.filters.filters.filter((f) => !f.data.attribute);
    if (filtersMissingAttributes.length > 0) {
      throw new Error('Filters must have an attribute to be compatible with the current metric');
    }

    let supportedAttributeIds = this.metric.supportedFilters.map((a) => a.id);
    this.filters = {
      ...this.filters,
      filters: this.filters.filters.filter(
        (f) => f.data.attribute && supportedAttributeIds.includes(f.data.attribute),
      ),
    };
  }

  resetLabel() {
    this.label = null;
  }

  applyLogicalFilterOperator(operator: LogicalFilterOperator) {
    this.filters = { ...this.filters, type: operator };
  }

  changeFilter(
    index: number,
    attribute: { id: string; field: string },
    values: string[],
    operator: string | null,
  ) {
    this.filters = changeFilter(this.filters, index, attribute, values, operator);
  }

  clearFilters() {
    this.filters = { type: 'and', filters: [] };
  }

  get humanizedFilterText() {
    return this.intl.formatList(humanizeFiltersForMetric(this.filters, this.metric), {
      type: 'unit',
    });
  }

  get aggregationName() {
    let aggregation = this.aggregation ?? '';
    let percentileValue = this.percentileValue ?? 0;

    switch (aggregation) {
      case 'percentile':
        return this.intl.t(`reporting.aggregations.percentile-${Math.round(percentileValue)}`);
      case 'mean':
      case 'median':
      case 'min':
      case 'max':
      case 'sum':
        return this.intl.t(`reporting.aggregations.${aggregation}`);
      default:
        return '';
    }
  }

  get metricDisplayName() {
    return this.label ? this.label : this.defaultMetricDisplayName;
  }

  get defaultMetricDisplayName() {
    if (this.aggregationName) {
      return `${this.aggregationName} ${this.metric.name}`;
    }

    return this.metric.name;
  }
}
