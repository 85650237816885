/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const targetUserGroup = {
  new: {
    label: 'New mobile app users',
    value: 0,
  },
  existing: {
    label: 'Existing mobile app users',
    value: 1,
  },
  all: {
    label: 'All mobile app users',
    value: 2,
  },
};
