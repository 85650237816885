/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, not } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import HoursOfDayMap from 'embercom/models/data/hours-of-day-map';
import DeliveryWindowValidations from 'embercom/validations/messages/delivery-window';

export default Fragment.extend(DeliveryWindowValidations, {
  deliveryPeriod: attr('string'),
  mondayDelivery: attr('boolean'),
  tuesdayDelivery: attr('boolean'),
  wednesdayDelivery: attr('boolean'),
  thursdayDelivery: attr('boolean'),
  fridayDelivery: attr('boolean'),
  saturdayDelivery: attr('boolean'),
  sundayDelivery: attr('boolean'),
  deliverySecondsSinceMidnightStart: attr('number'),
  deliverySecondsSinceMidnightEnd: attr('number'),

  customDeliveryPeriod: equal('deliveryPeriod', 'custom'),
  notCustomDeliveryPeriod: not('customDeliveryPeriod'),

  enabledDays: computed(
    'mondayDelivery',
    'tuesdayDelivery',
    'wednesdayDelivery',
    'thursdayDelivery',
    'fridayDelivery',
    'saturdayDelivery',
    'sundayDelivery',
    function () {
      let days = [];
      if (this.mondayDelivery) {
        days.push('Mon');
      }
      if (this.tuesdayDelivery) {
        days.push('Tue');
      }
      if (this.wednesdayDelivery) {
        days.push('Wed');
      }
      if (this.thursdayDelivery) {
        days.push('Thu');
      }
      if (this.fridayDelivery) {
        days.push('Fri');
      }
      if (this.saturdayDelivery) {
        days.push('Sat');
      }
      if (this.sundayDelivery) {
        days.push('Sun');
      }
      return days;
    },
  ),

  daysText: computed('enabledDays.[]', function () {
    let days = this.enabledDays;
    if (days.length === 7) {
      return 'Every day';
    } else if (
      days.length === 5 &&
      days.includes('Mon') &&
      days.includes('Tue') &&
      days.includes('Wed') &&
      days.includes('Thu') &&
      days.includes('Fri')
    ) {
      return 'Weekdays';
    } else if (days.length === 2 && days.includes('Sat') && days.includes('Sun')) {
      return 'Weekends';
    }
    return days.join(', ');
  }),

  secondsFromMidnightToText(secondsFromMidnight) {
    let mapping = HoursOfDayMap.find(function (windowTime) {
      return windowTime.secondsFromMidnight === secondsFromMidnight;
    });
    return mapping ? mapping.text : 'Unknown';
  },
  startTimeText: computed('deliverySecondsSinceMidnightStart', function () {
    return this.secondsFromMidnightToText(this.deliverySecondsSinceMidnightStart);
  }),
  endTimeText: computed('deliverySecondsSinceMidnightEnd', function () {
    return this.secondsFromMidnightToText(this.deliverySecondsSinceMidnightEnd);
  }),
  summaryText: computed('deliveryPeriod', 'daysText', 'startTimeText', 'endTimeText', function () {
    switch (this.deliveryPeriod) {
      case 'custom':
        return `${this.daysText}, ${this.startTimeText} - ${this.endTimeText}`;
      case 'during_office_hours':
        return 'During office hours';
      case 'outside_office_hours':
        return 'Outside office hours';
    }
  }),
});
