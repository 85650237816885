/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type BlockList } from '@intercom/interblocks.ts';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

export interface LocaleDetail {
  id: string;
  name: string;
}

export interface ArticleContentWireFormat {
  id: string;
  title: string;
  summary: string;
  locale: LocaleDetail;
  last_edited_at: string;
  blocks: BlockList;
  author: AdminSummaryWireFormat;
  article_id?: number;
  public_url?: string;
  article_preview: BlockList;
}

interface ArticleWireFormat {
  id: string;
  contents: ArticleContentWireFormat[];
}

export class ArticleContent {
  readonly id: string;
  readonly title: string;
  readonly summary: string;
  readonly locale: LocaleDetail;
  readonly lastEditedAt: string;
  readonly author: AdminSummary;
  readonly blocks: BlockList;

  constructor(
    id: string,
    title: string,
    summary: string,
    locale: LocaleDetail,
    lastEditedAt: string,
    blocks: BlockList,
    author: AdminSummary,
  ) {
    this.id = id;
    this.title = title;
    this.summary = summary;
    this.locale = locale;
    this.lastEditedAt = lastEditedAt;
    this.blocks = blocks;
    this.author = author;
  }

  static deserialize(json: ArticleContentWireFormat): ArticleContent {
    return new ArticleContent(
      json.id,
      json.title,
      json.summary,
      json.locale,
      json.last_edited_at,
      json.blocks,
      AdminSummary.deserialize(json.author),
    );
  }
}

export class Article {
  readonly id: string;
  readonly contents: ArticleContent[];

  constructor(id: string, contents: ArticleContent[]) {
    this.id = id;
    this.contents = contents;
  }

  static deserialize(json: ArticleWireFormat): Article {
    return new Article(json.id, json.contents.map(ArticleContent.deserialize));
  }
}
