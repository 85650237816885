/* RESPONSIBLE TEAM: team-help-desk-experience */

export enum PinnedFolderType {
  Inbox = 'inbox',
  Team = 'team',
  Admin = 'admin',
  View = 'view',
  Custom = 'custom',
  AiChatbot = 'ai_chatbot',
}

export interface PinnedFoldersWireFormat {
  type: PinnedFolderType;
  custom_folder_id: number | null;
  sort_order: number;
}

export class PinnedFolder {
  readonly type: PinnedFolderType;
  readonly customFolderId: number | null;
  readonly sortOrder: number;

  constructor(type: PinnedFolderType, customFolderId: number | null, sortOrder: number) {
    this.type = type;
    this.customFolderId = customFolderId;
    this.sortOrder = sortOrder;
  }

  get id(): string | number {
    if (this.type === PinnedFolderType.Custom) {
      return this.customFolderId as number;
    } else {
      return this.type;
    }
  }
}

export function deserialize(json: PinnedFoldersWireFormat): PinnedFolder {
  return new PinnedFolder(json.type, json.custom_folder_id, json.sort_order);
}
