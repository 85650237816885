/* RESPONSIBLE TEAM: team-proactive-support */
import TabConfiguration from './tab-configuration';
import { assert } from '@ember/debug';

export default TabConfiguration.extend({
  label: undefined,
  icon: undefined,

  init(inputs) {
    inputs.component = 'content-editor/tabs/standard-content-tab';
    inputs.view = 'content';

    this._super(...arguments);

    assert('A label must be passed for the standard content tab configuration', inputs.label);
    assert('An icon must be passed for the standard content tab configuration', inputs.icon);

    this.setProperties({
      label: inputs.label,
      icon: inputs.icon,
    });
  },
});
