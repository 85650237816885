/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import UserSummary, { type UserSummaryWireFormat } from 'embercom/objects/inbox/user-summary';
import { EntityType } from 'embercom/models/data/entity-types';

interface DeletedWireFormat {
  renderable_type: RenderableType;
  admin_summary?: AdminSummaryWireFormat;
  user_summary?: UserSummaryWireFormat;
  deleted_by: AdminSummaryWireFormat;
  deleted_at: Date;
}

export default class Deleted implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.Deleted;
  readonly adminSummary?: AdminSummary;
  readonly userSummary?: UserSummary;
  readonly deletedBy?: AdminSummary;
  readonly deletedAt: Date;

  get isDeleted() {
    return true;
  }

  get teammate() {
    return this.adminSummary;
  }

  get creatingEntity() {
    if (this.userSummary) {
      return { type: EntityType.User, id: this.userSummary.id, object: this.userSummary };
    } else if (this.adminSummary) {
      return { type: EntityType.Admin, id: this.adminSummary.id, object: this.adminSummary };
    } else if (this.deletedBy) {
      // handles bad data which doesn't have an admin or user but better than blowing up for now
      return { type: EntityType.Admin, id: this.deletedBy.id, object: this.deletedBy };
    } else {
      throw new Error('Unknown creating entity');
    }
  }

  constructor(
    deletedBy: AdminSummary,
    deletedAt: Date,
    adminSummary?: AdminSummary,
    userSummary?: UserSummary,
  ) {
    this.deletedBy = deletedBy;
    this.deletedAt = deletedAt;
    this.adminSummary = adminSummary;
    this.userSummary = userSummary;
  }

  static deserialize(json: DeletedWireFormat): Deleted {
    let adminSummary = json.admin_summary && AdminSummary.deserialize(json.admin_summary);
    let deletedBy = AdminSummary.deserialize(json.deleted_by);
    let userSummary = json.user_summary && UserSummary.deserialize(json.user_summary);
    let deletedAt = new Date(json.deleted_at);
    return new Deleted(deletedBy, deletedAt, adminSummary, userSummary);
  }
}
