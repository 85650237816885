/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import { notEmpty, and } from '@ember/object/computed';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import PredicateGroup from 'predicates/models/predicate-group';
import { isEvery } from '@intercom/pulse/lib/computed-properties';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  predicates: {
    validators: [
      validator('has-many'),
      validator('length', {
        min: 1,
        message: 'You must have at least one data attribute.',
        dependentKeys: ['model.predicates'],
      }),
    ],
  },
  actions: {
    validators: [
      validator('has-many'),
      validator('length', {
        min: 1,
        message: 'You must have at least one action.',
        dependentKeys: ['model.actions'],
      }),
    ],
  },
  validPredicatesAndActions: {
    validators: [
      validator('rule-data', {
        dependentKeys: ['model.isRuleValid'],
      }),
    ],
  },
});

export default PredicateGroup.extend(Validations, {
  _id: attr('string'),
  actions: fragmentArray('rules/rule-action'),

  hasActions: notEmpty('actions'),
  actionsAreValid: isEvery('actions', 'isValid', true),

  hasPredicates: notEmpty('predicates'),
  hasNonLogicalPredicates: notEmpty('basePredicates'),
  predicatesAreValid: isEvery('predicates', 'isValid', true),

  isRuleValid: and('isValid', 'actionsAreValid', 'predicatesAreValid'),
});
