/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { fragment } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type HomePageOptions from './layout-options/home-page/home-page-options';
import type CollectionsPageOptions from './layout-options/collections-page';
import type ArticlePageOptions from './layout-options/article-page';
import type SearchPageOptions from './layout-options/search-page';

export default class LayoutOptions extends Fragment {
  @fragment('customization-options/layout-options/home-page/home-page-options')
  declare homePage: HomePageOptions;

  @fragment('customization-options/layout-options/collections-page')
  declare collectionsPage: CollectionsPageOptions;

  @fragment('customization-options/layout-options/article-page')
  declare articlePage: ArticlePageOptions;

  @fragment('customization-options/layout-options/search-page')
  declare searchPage: SearchPageOptions;
}
