/* RESPONSIBLE TEAM: team-customer-data-platform */
export const segmentSortFunction = (segmentA, segmentB) => {
  let segmentAIsCustom = segmentA.get('is_editable');
  let segmentBIsCustom = segmentB.get('is_editable');
  let bothSegmentsAreNotCustom = !segmentAIsCustom && !segmentBIsCustom;
  let exactlyOneSegmentIsCustom = segmentAIsCustom ? !segmentBIsCustom : segmentBIsCustom;

  if (bothSegmentsAreNotCustom) {
    return 0;
  } else if (exactlyOneSegmentIsCustom) {
    return segmentAIsCustom > segmentBIsCustom ? 1 : -1;
  } else {
    return segmentA
      .get('name')
      .localeCompare(segmentB.get('name'), undefined, { sensitivity: 'accent' });
  }
};
