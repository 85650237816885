/* RESPONSIBLE TEAM: team-proactive-support */
import { assert } from '@ember/debug';
import { isArray } from '@ember/array';

export default class SeriesModeConfiguration {
  headerComponentConfig = undefined;
  editorComponentConfigs = undefined;
  viewModeSummaryConfig = undefined;
  viewModeHeaderConfig = undefined;

  constructor({
    headerComponentConfig,
    editorComponentConfigs,
    viewModeHeaderConfig,
    viewModeSummaryConfig,
  }) {
    assert('The header component config must be provided', headerComponentConfig !== undefined);
    assert(
      'An array must be passed for the editor component configs',
      isArray(editorComponentConfigs),
    );

    editorComponentConfigs.setEach('parentConfiguration', this);
    Object.assign(this, {
      headerComponentConfig,
      editorComponentConfigs,
      viewModeSummaryConfig,
      viewModeHeaderConfig,
    });
  }
}
