/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
/* eslint-disable-next-line ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { DAY_IN_MINUTES } from 'embercom/components/matching-system/matching-timetable-editor';

export default class TimeInterval extends Fragment {
  @attr() declare startMinute: number;
  @attr() declare endMinute: number;

  @computed('startDay')
  get startMinuteOffset() {
    return this.startDay * DAY_IN_MINUTES;
  }

  @computed('endDay')
  get endMinuteOffset() {
    return this.endDay * DAY_IN_MINUTES;
  }

  @computed('startMinute', 'startMinuteOffset')
  get adjustedStartMinute() {
    return this.startMinute - this.startMinuteOffset;
  }

  @computed('endMinute', 'endMinuteOffset')
  get adjustedEndMinute() {
    return this.endMinute - this.endMinuteOffset;
  }

  @computed('startMinute')
  get startDay() {
    return Math.floor(this.startMinute / DAY_IN_MINUTES) % 7;
  }

  @computed('endMinute')
  get endDay() {
    return Math.floor((this.endMinute - 1) / DAY_IN_MINUTES) % 7;
  }

  @computed('startMinute', 'endMinute')
  get isInvalid() {
    return this.startMinute >= this.endMinute;
  }
}
