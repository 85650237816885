/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { or, bool, equal } from '@ember/object/computed';

import { isAny } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

export default Model.extend({
  store: service(),
  appService: service(),
  appId: attr('string'),
  slots: hasMany('messenger-home/slot'),
  targetUserType: attr('string'),

  hasUnsavedChanges: or('hasDirtyAttributes', 'slotsHaveUnsavedChanges', 'slotsWereDeleted'),
  slotsHaveUnsavedChanges: isAny('slots', 'hasUnsavedChanges'),
  hasNewConversationCardSlot: isAny('slots', 'isNewConversationCardSlot'),
  hasNewsSlot: isAny('slots', 'isNewsSlot'),
  hasTasksSlot: isAny('slots', 'isTasksSlot'),
  hasExternalLinksSlot: isAny('slots', 'isExternalLinksSlot'),
  hasTicketLinksSlot: isAny('slots', 'isTicketLinksSlot'),
  hasHelpCenterSlot: isAny('slots', 'isHelpCenterSlot'),
  slotsWereDeleted: false,
  isEmpty: equal('slots.length', 0),

  isNewConversationCardFirstSlot: bool('slots.firstObject.isNewConversationCardSlot'),

  addSlot({ slotType, canvas, messengerApp, displayOrder = this.slots.length }) {
    let slot = this.store.createRecord('messenger-home/slot', {
      slotGroup: this,
      appID: this.appService.app,
      canvas,
      messengerApp,
      displayOrder,
      hasConfiguration: !!messengerApp?.hasConfiguration,
      messengerAppId: messengerApp?.id,
      slotType,
      predicateGroup: this.store.createFragment('predicates/predicate-group'),
    });
    this.slots.pushObject(slot);
    return slot;
  },

  addNewConversationCard() {
    if (!this.hasNewConversationCardSlot) {
      //for M5, we want to reset display order before adding new slots
      this.resetSlotDisplayOrder(this, this.slots);
      return this.addSlot({ slotType: 'new_conversation_card' });
    }
  },

  addNewsSlot() {
    if (!this.hasNewsSlot) {
      //for M5, we want to reset display order before adding new slots
      this.resetSlotDisplayOrder(this, this.slots);
      return this.addSlot({ slotType: 'news_items_card' });
    }
  },

  addTasksSlot() {
    if (!this.hasTasksSlot) {
      //for M5, we want to reset display order before adding new slots
      this.resetSlotDisplayOrder(this, this.slots);
      return this.addSlot({ slotType: 'checklists_card' });
    }
  },

  removeTasksSlot() {
    this.findAndRemoveSlot('isTasksSlot');
  },

  removeNewsSlot() {
    this.findAndRemoveSlot('isNewsSlot');
  },

  addExternalLinksSlot() {
    //for M5, we want to reset display order before adding new slots
    this.resetSlotDisplayOrder(this, this.slots);
    return this.addSlot({ slotType: 'external_links_card' });
  },

  addTicketLinksSlot() {
    //for M5, we want to reset display order before adding new slots
    this.resetSlotDisplayOrder(this, this.slots);
    return this.addSlot({ slotType: 'ticket_links_card' });
  },

  removeExternalLinksSlot() {
    this.findAndRemoveSlot('isExternalLinksSlot');
  },

  removeTicketLinksSlot() {
    this.findAndRemoveSlot('isTicketLinksSlot');
  },

  addHelpCenterSlot() {
    if (!this.hasHelpCenterSlot) {
      //for M5, we want to reset display order before adding new slots
      this.resetSlotDisplayOrder(this, this.slots);
      return this.addSlot({ slotType: 'help_center' });
    }
  },

  removeHelpCenterSlot() {
    this.findAndRemoveSlot('isHelpCenterSlot');
  },

  findAndRemoveSlot(slotProperty) {
    let slot = this.slots.find((slot) => slot[slotProperty]);
    if (!slot) {
      return;
    }
    this.removeSlot(slot);
  },

  removeSlot(deletingSlot) {
    this.slots.removeObject(deletingSlot);
    deletingSlot.deleteRecord();
    this.set('slotsWereDeleted', true);
    this.resetSlotDisplayOrder(this, this.slots);
  },

  resetSlotDisplayOrder(slotGroup, reorderedSlots) {
    reorderedSlots.forEach((slot, index) => slot.set('displayOrder', index));
    slotGroup.set('slots', reorderedSlots);
  },

  hasSlot(targetType) {
    return this.slots.find(({ slotType }) => slotType === targetType);
  },

  removeExternalLinksWithoutIds() {
    this.slots
      .filterBy('isExternalLinksSlot', true)
      .forEach((slot) => slot.removeExternalLinksWithoutIds());
  },

  removeTicketLinksWithoutIds() {
    this.slots
      .filterBy('isTicketLinksSlot', true)
      .forEach((slot) => slot.removeTicketLinksWithoutIds());
  },

  resetExternalLinksWereDeleted() {
    this.slots
      .filterBy('isExternalLinksSlot', true)
      .forEach((slot) => slot.set('externalLinksWereDeleted', false));
  },

  resetTicketLinksWereDeleted() {
    this.slots
      .filterBy('isTicketLinksSlot', true)
      .forEach((slot) => slot.set('ticketLinksWereDeleted', false));
  },
});
