/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';

export default class AdminSeatMappingFragment extends Fragment {
  @attr('number') declare adminId: number;
  @attr('array') declare seatTypes: string[];
  @attr('boolean') declare isActive: boolean;

  get admin() {
    return this.store.peekRecord('admin', this.adminId);
  }
}
