/* RESPONSIBLE TEAM: team-channels */
export interface SenderEmailAddressSummaryWireFormat {
  id: number | string;
  name: string;
  email: string;
  status: string;
  verified: boolean;
  custom_bounce_valid: boolean;
  dmarc_valid: boolean;
}

export default class SenderEmailAddressSummary {
  readonly id: number | string;
  readonly name: string;
  readonly email: string;
  readonly status: string;
  readonly verified: boolean;
  readonly customBounceValid: boolean;
  readonly dmarcValid: boolean;

  constructor(
    id: number | string,
    name: string,
    email: string,
    status: string,
    verified: boolean,
    customBounceValid: boolean,
    dmarcValid: boolean,
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.status = status;
    this.verified = verified;
    this.customBounceValid = customBounceValid;
    this.dmarcValid = dmarcValid;
  }

  static deserialize(json: SenderEmailAddressSummaryWireFormat): SenderEmailAddressSummary {
    return new SenderEmailAddressSummary(
      json.id.toString(),
      json.name,
      json.email,
      json.status,
      json.verified,
      json.custom_bounce_valid,
      json.dmarc_valid,
    );
  }

  static serialize(address: SenderEmailAddressSummary): SenderEmailAddressSummaryWireFormat {
    return {
      id: address.id,
      name: address.name,
      email: address.email,
      status: address.status,
      verified: address.verified,
      custom_bounce_valid: address.customBounceValid,
      dmarc_valid: address.dmarcValid,
    };
  }
}
