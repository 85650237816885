/* RESPONSIBLE TEAM: team-help-desk-experience */
import { ParentAction, ChildAction } from './action';
import {
  calculateUnsnoozeTime,
  DurationObject,
  DurationType,
} from 'embercom/objects/inbox/duration';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type InboxVersion from 'embercom/services/inbox-version';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type CommandKService from 'embercom/services/command-k';
import { formatTime } from 'embercom/objects/inbox/snooze';
import type SnoozeConfig from 'embercom/services/snooze-config';

const maxIntegerDigits = 3;
const boundedPositiveIntegerPattern = new RegExp(
  `^(?<number>[1-9]\\d{0,${maxIntegerDigits - 1}}).*`,
);

enum RelativeSnoozeIncrement {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
}

export default class SnoozeAction extends ParentAction {
  @service declare session: Session;
  @service declare inboxVersion: InboxVersion;
  @service declare commandK: CommandKService;
  @service declare snoozeConfig: SnoozeConfig;
  @service declare inboxHotkeys: InboxHotkeys;

  // ChangeState action has SnoozeAction as a child action, so we need to pass the parent to get the registration
  parent?: ParentAction;

  constructor(args: any) {
    super(args);
    this.shortcuts = this.inboxHotkeys.hotkeysMap[HotkeyID.SnoozeAction];
    this.parent = args.parent;
  }

  id = 'snooze';
  icon = 'snooze' as const;
  paneComponent = 'inbox2/command-k/snooze/pane';
  cacheLatest = true;

  buildTopLevelItems(query: string) {
    return this.buildResults(query);
  }

  get customSnoozeAction() {
    return new ChildAction({
      parent: this,
      id: 'custom',
      label: this.intl.t('inbox.command-k.snooze.custom'),
      value: null, // this will get populated on select by snooze/pane.ts
    });
  }

  buildResults(query: string): Array<ChildAction> {
    let c = [
      new ChildAction({
        parent: this,
        id: 'later-today',
        label: this.intl.t('inbox.command-k.snooze.later'),
        value: new DurationObject(DurationType.LaterToday),
        hint: formatTime(this.intl, calculateUnsnoozeTime(DurationType.LaterToday)),
      }),
      new ChildAction({
        parent: this,
        id: 'tomorrow',
        label: this.intl.t('inbox.command-k.snooze.tomorrow'),
        value: new DurationObject(DurationType.Tomorrow),
        hint: formatTime(this.intl, calculateUnsnoozeTime(DurationType.Tomorrow)),
      }),
      new ChildAction({
        parent: this,
        id: 'monday',
        label: this.intl.t('inbox.command-k.snooze.monday'),
        value: new DurationObject(DurationType.Monday),
        hint: formatTime(this.intl, calculateUnsnoozeTime(DurationType.Monday)),
      }),
      new ChildAction({
        parent: this,
        id: 'one-week',
        label: this.intl.t('inbox.command-k.snooze.one-week'),
        value: new DurationObject(DurationType.NextWeek),
        hint: formatTime(this.intl, calculateUnsnoozeTime(DurationType.NextWeek)),
      }),
      new ChildAction({
        parent: this,
        id: 'one-month',
        label: this.intl.t('inbox.command-k.snooze.one-month'),
        value: new DurationObject(DurationType.NextMonth),
        hint: formatTime(this.intl, calculateUnsnoozeTime(DurationType.NextMonth)),
      }),
    ];

    c.push(this.customSnoozeAction);

    let upperQuery = query.trim().toLocaleUpperCase();

    let integerInput = extractNumber(upperQuery);
    if (integerInput !== undefined) {
      c.push(...this.relativeSnoozeActions(integerInput));
    }

    c = this.boostLatestAction(c);

    return c.filter((action) => {
      // To be fixed by this issue: https://github.com/intercom/intercom/issues/235932
      if (
        !this.snoozeConfig.snoozePaneShown &&
        action.label === this.intl.t('inbox.command-k.snooze.custom')
      ) {
        return false;
      } else {
        return action.label.toLocaleUpperCase().includes(upperQuery);
      }
    });
  }

  private boostLatestAction(actions: ChildAction[]): ChildAction[] {
    let lastActionId = this.commandK.fetchLastAction(this.id);
    if (!lastActionId) {
      return actions;
    }
    let lastAction =
      actions.find((action) => action.id === lastActionId) ||
      this.relativeSnoozeActionFromId(lastActionId);
    if (!lastAction) {
      return actions;
    }
    return [lastAction, ...actions.filter((action) => action.id !== lastActionId)];
  }

  private relativeSnoozeActionFromId(id: string): ChildAction | undefined {
    // Expects a relative snooze ChildAction id string of format "snooze-<number>-<RelativeSnoozeIncrement>"
    let [, delta, increment] = id.split('-');
    if (!delta || !increment) {
      return;
    }
    if (
      !Object.values(RelativeSnoozeIncrement).includes(increment as RelativeSnoozeIncrement) ||
      isNaN(Number(delta))
    ) {
      return;
    }
    return this.relativeSnoozeAction(Number(delta), increment as RelativeSnoozeIncrement);
  }

  private relativeSnoozeAction(delta: number, increment: RelativeSnoozeIncrement): ChildAction {
    return new ChildAction({
      parent: this,
      id: `${delta}-${increment}`,
      label: this.intl.t(`inbox.command-k.snooze.relative.${increment}`, {
        count: delta,
      }),
      value: new DurationObject(
        DurationType.CustomTime,
        moment().add(delta, increment).toISOString(),
      ),
      hint: formatTime(this.intl, moment().add(delta, increment)),
    });
  }

  private relativeSnoozeActions(delta: number): ChildAction[] {
    return [
      this.relativeSnoozeAction(delta, RelativeSnoozeIncrement.Minutes),
      this.relativeSnoozeAction(delta, RelativeSnoozeIncrement.Hours),
      this.relativeSnoozeAction(delta, RelativeSnoozeIncrement.Days),
      this.relativeSnoozeAction(delta, RelativeSnoozeIncrement.Weeks),
    ];
  }

  get placeholder() {
    return this.snoozeConfig.placeholder;
  }

  get registration() {
    if (this.parent) {
      return this.parent.registration;
    } else {
      return super.registration;
    }
  }
}

function extractNumber(input: string): number | undefined {
  let match = boundedPositiveIntegerPattern.exec(input);
  if (match && match.groups && 'number' in match.groups) {
    return Number(match.groups.number);
  }
  return undefined;
}
