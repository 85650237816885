/* RESPONSIBLE TEAM: team-proactive-support */
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';
import { inject as service } from '@ember/service';

export default StandardSummaryConfiguration.extend({
  init(_inputs) {
    this._super({
      title: this.intl.t('outbound.content-editor.panels.checklist-sharing.shareable-panel-header'),
      component: 'content-editor/summaries/custom/checklist-sharing/summary',
    });
  },
  intl: service(),

  contentEditorService: service(),
  isVisible: true,
});
