/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import { empty, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';

export default StandardPanelHeaderConfiguration.extend({
  contentEditorService: service(),
  intl: service(),

  ignoredValidators: ['custom-bot-bot-data-selector-present'],

  rulesetLinkErrors: computed(
    'contentEditorService.ruleset.validations.attrs.rulesetLinks.errors',
    function () {
      return this.contentEditorService.ruleset.validations.attrs.rulesetLinks.errors.filter(
        (e) => !this.ignoredValidators.includes(e.type),
      );
    },
  ),

  isValid: empty('rulesetLinkErrors'),
  errors: computed(
    'isValid',
    'contentEditorService.ruleset.rulesetLinks.firstObject.object.paths',
    'intl.locale',
    function () {
      if (!this.isValid) {
        let contentErrorMessages = this.rulesetLinkErrors.mapBy('message');
        let uniqueContentErrorMessages = [...new Set(contentErrorMessages)];

        if (uniqueContentErrorMessages.length === 1) {
          return [{ message: uniqueContentErrorMessages.firstObject }];
        }

        let paths = this.contentEditorService.ruleset.rulesetLinks.firstObject.object.paths;

        if (paths.length === 0) {
          return [
            {
              message: this.intl.t(
                'operator.workflows.visual-builder.validations.content-or-connections-missing',
              ),
            },
          ];
        } else {
          return [{ message: 'Finish designing your bot' }];
        }
      }
      return [];
    },
  ),
  isComplete: readOnly('isValid'),

  init(inputs) {
    inputs.component = 'content-editor/panels/content/custom-bot/header';
    inputs.title = inputs.title || this.intl.t('operator.custom-bot.editor.content-panel.title');
    this._super(...arguments);
  },
});
