/* RESPONSIBLE TEAM: team-workflows */
import Model, { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class UpfrontCollection extends Model {
  @attr('boolean') enabledForUsers;
  @attr('array') attributes;
  @fragmentArray('rules/rule') followUpRules;

  isValid = true;
  paywallFeature = 'upfront_collection_bot';

  get isNotDirty() {
    return !this.hasDirtyAttributes;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
