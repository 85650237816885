/* RESPONSIBLE TEAM: team-frontend-tech */
import {
  BaseConfig,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  INPUT_RULE_MARKDOWN_ANCHOR,
} from '@intercom/embercom-prosemirror-composer';
import { EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { EmbercomCanvasNetworkClient } from 'embercom/objects/composer/embercom-canvas-network-client';

export default class ComposerConfig extends BaseConfig {
  autoFocus = false;
  allowedBlocks = [
    'paragraph',
    'button',
    'codeBlock',
    'image',
    'messengerCard',
    'orderedList',
    'unorderedList',
    'video',
    'videoFile',
  ];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = false;
  canvasNetworkClient;
  inserters = {
    appInserter: 'composer/inserters/app-inserter',
    emojiInserter: 'composer/inserters/emoji-inserter',
    tourInserter: 'composer/inserters/tour-inserter',
  };
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];
  placeholder = '';
  textDirection = 'ltr';
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'button-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'media-inserter' },
    { name: 'image-editor', options: { supportAltAttributeEditing: false } },
    { name: 'video-editor' },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedVideoFileTypes: ['video/mp4'],
    allowedAttachmentTypes: [],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };

  constructor({ app, eventService, resolver }) {
    super();

    this.analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'chat',
          place: 'composer',
          ...attrs,
        });
      },
    };

    this.canvasNetworkClient = new EmbercomCanvasNetworkClient(app.id);
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
  }
}
