/* RESPONSIBLE TEAM: team-tickets-1 */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

interface SideConversationStartedWireFormat {
  admin_summary: AdminSummaryWireFormat;
  first_participant_email: string;
  participants_count: number;
  side_conversation_id?: number;
}

export default class SideConversationStarted implements RenderableData {
  renderableType: RenderableType = RenderableType.SideConversationStarted;
  readonly adminSummary: AdminSummary;
  readonly firstParticipantEmail: string;
  readonly participantsCount: number;
  // sideConversationId is optional because older started event parts are not backfilled with side conversation id
  readonly sideConversationId?: number;

  get creatingEntity() {
    return {
      type: EntityType.Admin,
      id: this.adminSummary.id,
      object: this.adminSummary,
    };
  }

  constructor(
    adminSummary: AdminSummary,
    firstParticipantEmail: string,
    participantsCount: number,
    sideConversationId?: number,
  ) {
    this.adminSummary = adminSummary;
    this.firstParticipantEmail = firstParticipantEmail;
    this.participantsCount = participantsCount;
    this.sideConversationId = sideConversationId;
  }

  static deserialize(json: SideConversationStartedWireFormat): SideConversationStarted {
    let adminSummary = AdminSummary.deserialize(json.admin_summary);

    return new SideConversationStarted(
      adminSummary,
      json.first_participant_email,
      json.participants_count,
      json.side_conversation_id,
    );
  }
}
