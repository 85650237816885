/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { stateChangeDescriptions } from 'embercom/models/data/inbox/state-change-descriptions';
import {
  type StateChangeReason,
  type StateChangeType,
  StateChangingEntity,
} from 'embercom/models/data/inbox/state-change-enums';

import UserSummary, { type UserSummaryWireFormat } from '../../user-summary';
import { type StateChangePart, type StateChangeWireFormat } from '../state-change';

export interface UserStateChangeWireFormat extends StateChangeWireFormat {
  changed_by_entity: UserSummaryWireFormat;
}

export default class UserStateChange implements StateChangePart {
  readonly renderableType: RenderableType = RenderableType.StateChange;
  readonly changedByEntity: UserSummary;
  readonly changedByEntityType: StateChangingEntity = StateChangingEntity.User;
  readonly change: StateChangeType;
  readonly reason: StateChangeReason;

  get user() {
    return this.changedByEntity;
  }

  get creatingEntity() {
    return { type: EntityType.User, id: this.user.id, object: this.user };
  }

  get eventDescription(): string {
    return stateChangeDescriptions[this.change][this.reason];
  }

  constructor(change: StateChangeType, reason: StateChangeReason, changedByEntity: UserSummary) {
    this.change = change;
    this.reason = reason;
    this.changedByEntity = changedByEntity;
  }

  static deserialize(json: UserStateChangeWireFormat): UserStateChange {
    if (json.changed_by_entity_type !== StateChangingEntity.User) {
      throw new Error(
        `Attempted to create a UserStateChange from a response with changed entity type of '${json.changed_by_entity_type}'`,
      );
    }

    let userSummary = UserSummary.deserialize(json.changed_by_entity);
    return new UserStateChange(json.change, json.reason, userSummary);
  }
}
