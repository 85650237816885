/* RESPONSIBLE TEAM: team-frontend-tech */
import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';

export default class Style extends Fragment {
  @attr('string') fontFamily;
  @attr('string') fontColor;
  @attr('string') backgroundColor;
  @attr('string') paddingTop;
  @attr('string') paddingBottom;
  @attr('string') paddingLeft;
  @attr('string') paddingRight;
  @attr('string') borderRadius;
}
