/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Block from './block';

export default Block.extend({
  type: attr('string'),
  url: attr('string'),
  thumbnailUrl: attr('string'),
  captions: attr('string'),
  duration: attr('number'),
});
