/* RESPONSIBLE TEAM: team-proactive-support */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';
import StandardInserterComponentConfiguration from 'embercom/objects/series/node/standard-inserter-component-configuration';
import StandardLoadingComponentConfiguration from 'embercom/objects/series/node/standard-loading-component-configuration';
import {
  objectTypes,
  objectNames,
  objectFeatures,
} from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class SplitterInserterConfiguration extends InserterConfiguration {
  objectType = objectTypes['splitter'];
  objectData = {};
  requiredFeatures = [objectFeatures[objectTypes.splitter]];
  componentConfig = new StandardInserterComponentConfiguration({
    icon: 'series/content/splitter',
    paywallIcon: 'series/content/paywalled-splitter',
    featureFlagsWithAccess: [objectFeatures[objectTypes.splitter]],
    title: capitalize(objectNames[this.objectType]),
  });
  loadingComponentConfig = new StandardLoadingComponentConfiguration({
    icon: 'series/content/splitter',
    title: capitalize(objectNames[this.objectType]),
    objectType: this.objectType,
  });

  canInsertNode() {
    return true;
  }
}
