/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Model, { attr } from '@ember-data/model';
import { type Metric } from 'embercom/models/data/pricing/metric-types';

export enum UsageType {
  resolutions = 'resolutions',
  calling = 'calling',
  emails_sent = 'emails_sent',
  messages_sent = 'messages_sent',
  sms_segments_sent_received_au = 'sms_segments_sent_received_au',
  sms_segments_sent_received_ca = 'sms_segments_sent_received_ca',
  sms_segments_sent_received_uk = 'sms_segments_sent_received_uk',
  sms_segments_sent_received_us = 'sms_segments_sent_received_us',
  whatsapp_inbound = 'whatsapp_inbound',
  whatsapp_outbound = 'whatsapp_outbound',
}

export default class UsageReminderModel extends Model {
  @attr('string') declare appId: string;
  @attr('number') declare threshold: number;
  @attr('string') declare usageType: Metric;
  @attr('boolean') declare willStartNextBillingCycle: boolean;
  @attr('boolean') declare tiered: boolean;
  @attr('boolean') declare deleted: boolean;
}
