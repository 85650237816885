/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr } from '@ember-data/model';
import {
  PARTICIPANT_TYPES,
  PARTICIPANT_TYPE_VALUES,
} from 'embercom/lib/reporting/flexible/constants';

export default class ConversationTopicModel extends Model {
  @attr('array', { defaultValue: () => [] }) keyphrases;
  @attr('array', { defaultValue: () => [] }) excludedKeyphrases;
  @attr('array', { defaultValue: () => PARTICIPANT_TYPE_VALUES }) authorTypes;
  @attr('array', { defaultValue: () => [] }) conversationCustomData;
  @attr('string') name;
  @attr('date') fullyPropagatedFrom;

  get keyphrasesText() {
    return this.joinKeyphrases(this.keyphrases);
  }

  get excludedKeyphrasesText() {
    return this.joinKeyphrases(this.excludedKeyphrases);
  }

  joinKeyphrases(keyphrases) {
    return (keyphrases || []).join(', ');
  }

  get authorTypeIsAny() {
    return this.authorTypes.length === PARTICIPANT_TYPE_VALUES.length;
  }

  get authorTypeNames() {
    return this.authorTypes.map((participantTypeValue) => {
      return PARTICIPANT_TYPES.find((type) => type.value === participantTypeValue).text;
    });
  }

  isPropagatingInRange(range) {
    return range.comparisonStartMoment.diff(this.fullyPropagatedFrom) <= 0;
  }
}
