/* RESPONSIBLE TEAM: team-proactive-support */
import ComponentConfiguration from 'embercom/objects/component-configuration';
import { assert } from '@ember/debug';
import { stats } from 'embercom/models/data/stats-system/stats-constants';

export default class StandardStatListConfiguration extends ComponentConfiguration {
  filters = [];
  stat = undefined;

  constructor(inputs) {
    inputs.component = 'series/editor/standard-stats-list';
    super(...arguments);

    assert(
      `The standard stats list must be passed a known stats system statistic key. You passed [${inputs.stat}].`,
      Object.keys(stats).includes(inputs.stat),
    );

    Object.keys(inputs).forEach((key) => {
      this[key] = inputs[key];
    });

    this.filters.forEach((filter) => {
      assert(`Expected a text attribute for the filter [${filter}]`, filter.text);
      assert(
        `Expected a known stats system statistic value for the filter value. You passed [${filter.value}].`,
        Object.values(stats).includes(filter.value),
      );
    });
  }
}
