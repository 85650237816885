/* RESPONSIBLE TEAM: team-reporting */

type MetricPropertyId = string;

type MetricPropertyDefinition = {
  id: string;
  alias_id?: string | null;
  name: string;
  groupTooltip?: string | null;
  filterTooltip: string | null;
  filterLabelPrefix?: string | null;
  filterSelectAllLabel?: string | null;
  headerName?: string | null;
  icon: string;
  type: string | null;
  supportsBreakdown?: boolean;
  supportsFiltering?: boolean;
  field: string;
  variantOf?: MetricPropertyId;
  disableMultiple?: boolean;
  group?: string | null;
};

export default class MetricProperty implements MetricPropertyDefinition {
  readonly id!: string;
  readonly alias_id!: string;
  readonly name!: string;
  readonly groupTooltip!: string | null;
  readonly filterTooltip!: string | null;
  readonly filterLabelPrefix!: string | null;
  readonly filterSelectAllLabel!: string | null;
  readonly headerName!: string | null;
  readonly icon!: string;
  readonly type!: string | null;
  readonly supportsBreakdown!: boolean;
  readonly supportsFiltering!: boolean;
  readonly field!: string;
  readonly variantOf!: MetricPropertyId;
  readonly disableMultiple!: boolean;
  readonly group!: string | null;

  constructor(propertyDefinition: MetricPropertyDefinition) {
    Object.assign(this, propertyDefinition);
    this.supportsBreakdown = propertyDefinition.supportsBreakdown ?? true;
    this.supportsFiltering = propertyDefinition.supportsFiltering ?? true;
    this.disableMultiple = propertyDefinition.disableMultiple ?? false;
  }

  get filterLabelPrefixWithOverride(): string | null {
    return this.filterLabelPrefix;
  }

  get identifier(): string {
    return this.field;
  }

  get groupTooltipKey(): string | null {
    return this.groupTooltip;
  }

  get isVariant(): boolean {
    return !!this.variantOf;
  }
}

export { MetricProperty, MetricPropertyId, MetricPropertyDefinition };
