/* RESPONSIBLE TEAM: team-tickets-1 */
import AdminVisibilitySetting, {
  type AdminVisibilitySettingWireFormat,
} from './admin-visibility-setting';

export enum DataType {
  String = 'string',
  Integer = 'integer',
  Float = 'float',
  Boolean = 'boolean',
  Date = 'date',
  Relationship = 'relationship',
}

interface AttributeOption {
  id: number;
  value: string;
}

interface AttributeOptionWireFormat {
  id: number;
  value: string;
}

export interface AttributeConstructorFormat {
  name: string;
  key: string;
  type: string;
  humanFriendlyName: string;
  description?: string;
  adminVisibilitySetting: AdminVisibilitySetting;
  templateIdentifier?: string;
  options?: AttributeOption[];
}

export interface AttributeWireFormat {
  name: string;
  key: string;
  type: string;
  human_friendly_name: string;
  description?: string;
  template_identifier?: string;
  admin_visibility_setting: AdminVisibilitySettingWireFormat;
  options?: AttributeOptionWireFormat[];
}

export default class Attribute {
  readonly name: string;
  readonly key: string;
  readonly type: string;
  readonly humanFriendlyName: string;
  readonly description?: string;
  readonly templateIdentifier?: string;
  readonly adminVisibilitySetting: AdminVisibilitySetting;
  readonly options: AttributeOption[] = [];

  value?: string;
  requiresFallback = true;

  constructor({
    name,
    key,
    type,
    humanFriendlyName,
    description,
    templateIdentifier,
    adminVisibilitySetting,
    options,
  }: AttributeConstructorFormat) {
    this.name = name;
    this.key = key;
    this.type = type;
    this.humanFriendlyName = humanFriendlyName;
    this.description = description;
    this.templateIdentifier = templateIdentifier;
    this.adminVisibilitySetting = adminVisibilitySetting;
    this.options = options ?? [];
  }

  get isCompany() {
    return this.key.startsWith('company.');
  }

  get category() {
    if (this.type === 'content_event') {
      return 'content_event';
    }

    if (this.key.startsWith('company.')) {
      return 'company';
    }

    if (this.key.startsWith('behavior.')) {
      return 'behavior';
    }

    return 'user';
  }

  get isRelationshipDataType() {
    return this.type === DataType.Relationship;
  }

  static deserialize(json: AttributeWireFormat) {
    return new Attribute({
      name: json.name,
      key: json.key,
      type: json.type,
      humanFriendlyName: json.human_friendly_name,
      description: json.description,
      templateIdentifier: json.template_identifier,
      adminVisibilitySetting: AdminVisibilitySetting.deserialize(json.admin_visibility_setting),
      options: json.options,
    });
  }
}
