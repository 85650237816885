/* RESPONSIBLE TEAM: team-messenger */
import Model, { belongsTo, attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import fmtStyle from '@intercom/pulse/lib/fmt-style';
import CarouselActionValidations from 'embercom/validations/carousel-action';

export default class CarouselAction extends Model.extend(CarouselActionValidations) {
  @service store;
  @belongsTo('carousels/carousel-screen', { inverse: 'carouselActions' })
  carouselScreen;
  @attr('string') title;
  @attr('string') type;
  @attr('number') order;
  @attr('string') backgroundColor;
  @attr('string') textColor;
  @attr('string') androidUri;
  @attr('string') iosUri;

  get isPrimary() {
    return this.order === 0;
  }

  get isSecondary() {
    return this.order === 1;
  }

  get style() {
    let template = 'background-color: %@; color: %@;';
    return fmtStyle(template, this.backgroundColor, this.textColor, this.textColor);
  }
}
