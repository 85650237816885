/* RESPONSIBLE TEAM: team-workflows */
import type SetExpectations from 'embercom/models/operator/visual-builder/step/set-expectations';
import StepConfig, { type StepConfigParams } from '../step';

export default class SetExpectationsConfiguration extends StepConfig {
  declare step: SetExpectations;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/set-expectations',
      ...params,
    });
  }
}
