/* RESPONSIBLE TEAM: team-help-desk-experience */
import moment from 'moment-timezone';
import type IntlService from 'embercom/services/intl';

const timeFmt = {
  hour: 'numeric',
  minute: 'numeric',
} as const;
const dowTimeFmt = {
  hour: 'numeric',
  minute: 'numeric',
  weekday: 'short',
} as const;
const dowMonthDayTimeFmt = {
  hour: 'numeric',
  minute: 'numeric',
  weekday: 'short',
  day: 'numeric',
  month: 'short',
} as const;
const dowYearMonthDayTimeFmt = {
  hour: 'numeric',
  minute: 'numeric',
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
} as const;

export function getDateFormat(unsnoozeTime: moment.Moment) {
  let now = moment();
  let fmt = undefined;

  if (unsnoozeTime.diff(now, 'years') > 0) {
    fmt = dowYearMonthDayTimeFmt;
  } else if (unsnoozeTime.diff(now, 'months') > 0) {
    fmt = dowMonthDayTimeFmt;
  } else if (unsnoozeTime.diff(now, 'weeks') > 0) {
    fmt = dowMonthDayTimeFmt;
  } else if (unsnoozeTime.diff(now, 'days') > 0) {
    fmt = dowTimeFmt;
  } else if (!unsnoozeTime.isSame(now, 'day')) {
    fmt = dowTimeFmt;
  } else {
    fmt = timeFmt;
  }

  return fmt;
}

export function formatTime(intlService: IntlService, unsnoozeTime: moment.Moment): string {
  return intlService.formatDate(unsnoozeTime.toDate(), getDateFormat(unsnoozeTime));
}
