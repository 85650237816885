/* RESPONSIBLE TEAM: team-frontend-tech */
import {
  BaseConfig,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
} from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';

export default class ComposerConfig extends BaseConfig {
  placeholder = '';
  autoFocus = false;
  textDirection = 'ltr';

  allowedBlocks = [
    'paragraph',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
    'button',
    'html',
  ];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = true;
  inserters = {
    emojiInserter: 'composer/inserters/emoji-inserter',
  };
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];
  singleBlockMode = false;
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'button-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'media-inserter' },
    { name: 'html/edit-button' },
    { name: 'image-editor', options: { supportAltAttributeEditing: true } },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  updateImages = true;
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedAttachmentTypes: [],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };
  customStylesConfig = {
    allowCustomStyles: true,
    allowFontFamilyInTextFormatter: true,
    colorPickerComponent: 'composer/if-else-statement-block-editor/color-picker',
  };

  constructor(app, includeAppAttributes) {
    super(app);

    this.attributes = app.allowedAttributes;

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes,
      }),
    };

    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
  }
}
