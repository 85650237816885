/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import DateFormatter from 'embercom/vendor/intercom/date-formatter';
import moment from 'moment-timezone';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { set } from '@ember/object';

export default Mixin.create({
  userPresenceService: service(),
  lastUserPresenceUpdate: undefined,
  lastUserPresenceAt: null,

  mostRecentlySeenAt: computed(
    'lastUserPresenceAt',
    'last_request_at',
    'last_request_at_android',
    'last_request_at_ios',
    function () {
      let timestamps = [
        this.lastUserPresenceAt,
        this.last_request_at,
        this.last_request_at_ios,
        this.last_request_at_android,
      ].compact();

      // return early as calling moments.max on an empty array returns the current timestamp
      if (isEmpty(timestamps)) {
        return null;
      }

      let moments = timestamps.map((timestamp) => moment(timestamp));
      return moment.max(moments).toISOString();
    },
  ),

  isActive: computed('mostRecentlySeenAt', 'lastUserPresenceUpdate', function () {
    return DateFormatter.isJustNow(this.mostRecentlySeenAt);
  }),

  updatePresenceStatus(latestTime, _platform, lastVisitedUrl) {
    // update this property so that isActive will be recalculated (since it's dependent on the current time)
    this.set('lastUserPresenceUpdate', Date.now());

    if (lastVisitedUrl) {
      this._setAndNotifyChange('last_visited_url', lastVisitedUrl);
    }

    if (
      isEmpty(latestTime) ||
      (isPresent(this.lastUserPresenceAt) && moment(latestTime) <= moment(this.lastUserPresenceAt))
    ) {
      return;
    }
    set(this, 'lastUserPresenceAt', latestTime);
  },

  // this is an event triggered by Ember Data.
  didLoad() {
    if (this.userPresenceService && this.userPresenceService.isPollingStarted) {
      this.userPresenceService.updateUserPresenceForLoadedObjects(this);
    }
  },
});
