/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { gt, reads } from '@ember/object/computed';
import { isEmpty, isPresent } from '@ember/utils';
import { applyFunction } from '@intercom/pulse/lib/computed-properties';
import countFormatter from 'embercom/lib/count-formatter';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import { toPercentString } from 'embercom/lib/percentage-formatter';
import moment from 'moment-timezone';
import StatsTranslator from 'embercom/lib/messages/real-time-stats-translations';

export default Model.extend({
  variation: belongsTo('message-variation'),
  clickTrackingLinks: attr(),
  message: reads('variation.message'),
  sentCount: attr('number'),
  openCount: attr('number'),
  openPercentage: attr('string'),
  replyCount: attr('number'),
  replyPercentage: attr('string'),
  responseCount: attr('number'),
  responsePercentage: attr('string'),
  clickCount: attr('number'),
  clickPercentage: attr('string'),
  unsubscribeCount: attr('number'),
  unsubscribePercentage: attr('string'),
  bounceCount: attr('number'),
  bouncePercentage: attr('string'),
  complainedCount: attr('number'),
  complainedPercentage: attr('string'),
  failedCount: attr('number'),
  failedPercentage: attr('string'),
  thumbsUpCount: attr('number'),
  thumbsUpPercentage: attr('string'),
  thumbsDownCount: attr('number'),
  thumbsDownPercentage: attr('string'),
  happyCount: attr('number'),
  happyPercentage: attr('string'),
  neutralCount: attr('number'),
  neutralPercentage: attr('string'),
  sadCount: attr('number'),
  sadPercentage: attr('string'),
  reaction0Count: attr('number'),
  reaction0Percentage: attr('string'),
  reaction1Count: attr('number'),
  reaction1Percentage: attr('string'),
  reaction2Count: attr('number'),
  reaction2Percentage: attr('string'),
  reaction3Count: attr('number'),
  reaction3Percentage: attr('string'),
  reaction4Count: attr('number'),
  reaction4Percentage: attr('string'),
  lastSentOn: attr('date'),
  goalCount: attr('number'),
  goalSuccessPercentage: attr('string'),
  attributeCollectorReplyCount: attr('number'),
  attributeCollectorReplyPercentage: attr('string'),
  lastLoadedAt: attr('date'),

  displayClickPercentage: applyFunction(toPercentString, 'clickPercentage'),
  displayFailedPercentage: applyFunction(toPercentString, 'failedPercentage'),
  displayGoalSuccessPercentage: applyFunction(toPercentString, 'goalSuccessPercentage'),
  displayOpenPercentage: applyFunction(toPercentString, 'openPercentage'),
  displayReplyPercentage: applyFunction(toPercentString, 'replyPercentage'),
  displayResponsePercentage: applyFunction(toPercentString, 'responsePercentage'),
  displaySentCount: applyFunction(countFormatter, 'sentCount'),

  hasReplies: gt('replyCount', 0),
  hasClickTrackingLinks: gt('clickTrackingLinks.length', 0),

  displayLastSentDate: computed('lastSentOn', function () {
    let lastSent = this.lastSentOn;
    return isEmpty(lastSent) ? 'Never' : moment(lastSent).fromNow();
  }),
  localeFormattedLastSentDate: computed('lastSentOn', function () {
    let lastSent = this.lastSentOn;
    return isEmpty(lastSent)
      ? 'Never'
      : moment(lastSent).format(dateAndTimeFormats.fullDateAndTimeWithoutTimezone);
  }),
}).reopenClass({
  translateFromRealTimeNotification(data) {
    let variationIds = Object.keys(data);
    if (variationIds.length) {
      return variationIds.map((variationId) => {
        let realtimeStatsForVariation = data[variationId];
        let translatedStats = StatsTranslator.translateRealTimeStats(realtimeStatsForVariation);
        Object.assign(translatedStats, {
          id: variationId,
          last_loaded_at: new Date().toISOString(),
          click_tracking_links: realtimeStatsForVariation['click_tracking_links'],
        });

        // If the reply type of the associated variation is a legacy LWR type
        // we will transpose these stats into reactions
        if (isPresent(realtimeStatsForVariation.reply_type)) {
          if (realtimeStatsForVariation.reply_type === 'thumbs_reply') {
            translatedStats['reaction_0_count'] = translatedStats['thumbs_up_count'];
            translatedStats['reaction_1_count'] = translatedStats['thumbs_down_count'];
            translatedStats['reaction_0_percentage'] = translatedStats['thumbs_up_percentage'];
            translatedStats['reaction_1_percentage'] = translatedStats['thumbs_down_percentage'];
          } else if (realtimeStatsForVariation.reply_type === 'emotions_reply') {
            translatedStats['reaction_0_count'] = translatedStats['happy_count'];
            translatedStats['reaction_1_count'] = translatedStats['neutral_count'];
            translatedStats['reaction_2_count'] = translatedStats['sad_count'];
            translatedStats['reaction_0_percentage'] = translatedStats['happy_percentage'];
            translatedStats['reaction_1_percentage'] = translatedStats['neutral_percentage'];
            translatedStats['reaction_2_percentage'] = translatedStats['sad_percentage'];
          }
        }
        return translatedStats;
      });
    }
  },
});
