/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  BILLING_PERIODS,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  findSolution,
} from 'embercom/lib/billing';
import Price from 'embercom/models/price';
import type { RequestParams } from 'embercom/services/quote-service';

import type Breakdown from 'embercom/models/billing/price/breakdown';
import type Charge from 'embercom/models/billing/price/charge';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type { Tier } from './data/pricing/tier-types';

export interface MetricRange {
  from: string;
  to: string;
}

export type PricingMetricRange = MetricRange | undefined;

export class Metadata {
  plan_ids: Array<number> = [];
  pricing_model_identifier?: string;
  source?: string;
  include_tiers?: number;
  include_pricing_by_billing_period?: boolean;
  bypass_current_cycle_usage?: boolean;
  include_plan_combination_validation?: boolean;
  core_seat_count?: number;

  constructor(params: RequestParams) {
    this.plan_ids = params.planIds;
    this.pricing_model_identifier = params.pricingModelIdentifier;
    this.source = params.source;
    this.include_tiers = params.includeTiers;
    this.include_pricing_by_billing_period = params.includePricingByBillingPeriod;
    this.include_plan_combination_validation = params.includePlanCombinationValidation;
    this.bypass_current_cycle_usage = params.bypassCurrentCycleUsage;
    this.core_seat_count = params.coreSeatCount;
  }
}

export default class Quote extends Price {
  @tracked breakdowns: Breakdown = this.breakdown;

  metaData: Metadata = {
    plan_ids: [],
  };

  @action
  setMetaData(params: Metadata) {
    this.metaData = params;
  }

  planQuoteBreakdown(planId: number) {
    return this.breakdown.find((quoteBreakdown: Breakdown) => quoteBreakdown.plan_id === planId);
  }

  breakdownPricingMetric(planId: number, pricingMetric: string) {
    return this.planQuoteBreakdown(Number(planId))?.charges.find(
      (charge: Charge) => charge.pricing_metric === pricingMetric,
    );
  }

  fullSeatPrice(planId: number, metric: Metric | string = Metric.core_seat_count) {
    return this.convertCentsToDollars(
      this.breakdownPricingMetric(Number(planId), metric)?.per_unit_price,
    );
  }

  fullSeatFlatFeePrice(planId: number, metric: Metric = Metric.core_seat_count) {
    return this.metricFlatFeePrice(Number(planId), metric);
  }

  discountedAnnualSeatPrice(planId: number, metric: Metric | string = Metric.core_seat_count) {
    return this.convertCentsToDollars(
      this.breakdownPricingMetric(Number(planId), metric)?.per_unit_pricing_by_billing_period[
        BILLING_PERIODS.Annual
      ]?.monthly_equivalent_when_billed_by_billing_period,
    );
  }

  fullBreakdownAmount(planId: number) {
    return this.convertCentsToDollars(this.planQuoteBreakdown(Number(planId))?.amount);
  }

  fullAmountForQuantity(planId: number, quantity: number, metric: Metric) {
    let pricingMetric = this.breakdownPricingMetric(Number(planId), metric);
    return this.convertCentsToDollars(pricingMetric?.per_unit_price * quantity || 0);
  }

  discountedBreakdownAmount(planId: number) {
    return this.convertCentsToDollars(
      this.planQuoteBreakdown(Number(planId))?.pricing_by_billing_period[BILLING_PERIODS.Annual]
        ?.monthly_equivalent_when_billed_by_billing_period,
    );
  }

  metricBaseUsage(planId: number, metric: Metric) {
    return this.breakdownPricingMetric(Number(planId), metric)?.base_usage;
  }

  metricPerUnitPrice(planId: number, metric: Metric) {
    let pricingMetric = this.breakdownPricingMetric(Number(planId), metric);
    return this.convertCentsToDollars(pricingMetric.per_unit_price);
  }

  metricPlanLimitMinimum(planId: number, metric: Metric) {
    return this.breakdownPricingMetric(Number(planId), metric)?.plan_limit.minimum;
  }

  metricFlatFeePrice(planId: number, metric: Metric) {
    let pricingMetric = this.breakdownPricingMetric(Number(planId), metric);
    let flatFeeTier = pricingMetric.tiers.find((tier: Tier) => tier.price_format === 'FlatFee');
    return flatFeeTier?.price || 0;
  }

  metricPriceRanges(planId: number, metric: Metric) {
    let pricingMetric = this.breakdownPricingMetric(Number(planId), metric);

    // Filter out the first tier of Messages Sent as it has the included usage
    let tiers = pricingMetric.tiers.filter((tier: Tier) => tier.price_format !== 'FlatFee');

    // If we are showing SMS we need to get the lowest and highest value out of all of the regions
    if (metric === Metric.sms_segments_sent_received_in_shifted_billing_cycle_us) {
      tiers = this.smsAllTiers(planId);
    }

    // If we are showing Whatsapp we need to get the lowest and highest value out of inbound and outbound
    if (metric === Metric.whatsapp_outbound) {
      tiers = this.whatsAppAllTiers(planId);
    }
    return {
      from: tiers[tiers.length - 1].price,
      to: tiers[0].price,
    };
  }

  private whatsAppAllTiers(planId: number) {
    let inbound = this.breakdownPricingMetric(Number(planId), Metric.whatsapp_inbound);
    let outbound = this.breakdownPricingMetric(Number(planId), Metric.whatsapp_outbound);

    return this.flatMapSortTiers([inbound, outbound]);
  }

  private smsAllTiers(planId: number) {
    let us = this.breakdownPricingMetric(
      Number(planId),
      Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
    );
    let uk = this.breakdownPricingMetric(
      Number(planId),
      Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk,
    );
    let ca = this.breakdownPricingMetric(
      Number(planId),
      Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca,
    );
    let au = this.breakdownPricingMetric(
      Number(planId),
      Metric.sms_segments_sent_received_in_shifted_billing_cycle_au,
    );

    return this.flatMapSortTiers([us, uk, ca, au]);
  }

  private flatMapSortTiers(array: Array<Charge>) {
    return array
      .map((pricingMetric) => pricingMetric?.tiers)
      .flat()
      .sort((a, b) => b.price - a.price);
  }

  convertCentsToDollars(cents: number) {
    return cents / 100;
  }

  finMetricPrice(planId: number) {
    let price = this.metricPerUnitPrice(planId, Metric.resolutions_with_custom_answers) || 0;
    return this.formatCurrency(price);
  }

  emailMetricPrice(planId: number) {
    return this.getMetricRangePrice(planId, Metric.emails_sent);
  }

  smsMetricPrice(planId: number) {
    return this.getMetricRangePrice(
      planId,
      Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
    );
  }

  whatsAppMetricPrice(planId: number) {
    return this.getMetricRangePrice(planId, Metric.whatsapp_outbound);
  }

  get proactiveSupportMetricPrice() {
    return this.getMetricRangePrice(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID), Metric.messages_sent);
  }

  phoneMetricPrice(solutionId: number) {
    return this.formatCurrency(findSolution(solutionId.toString())?.phoneMetricFromPrice);
  }

  get proactiveSupportBaseUsage() {
    let baseUsage =
      this.metricBaseUsage(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID), Metric.messages_sent) || 0;
    return this.intl.formatNumber(baseUsage, {
      minimumFractionDigits: 0,
    });
  }

  private getMetricRangePrice(planId: number, metric: Metric): MetricRange {
    let priceRange = this.metricPriceRanges(planId, metric);
    return {
      from: this.formatCurrency(priceRange.from),
      to: this.formatCurrency(priceRange.to),
    };
  }

  private formatCurrency(number: number) {
    return this.intl.formatNumber(number, {
      style: 'currency',
      currency: 'USD',
      maximumSignificantDigits: 5,
      minimumSignificantDigits: 1,
    });
  }
}
