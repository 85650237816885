/* RESPONSIBLE TEAM: team-proactive-support */
import TabConfiguration from 'embercom/objects/content-editor/tabs/tab-configuration';
import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';

export default class SeriesStatTabConfiguration extends TabConfiguration {
  @service appService;
  @service seriesEditorService;
  statisticKeys = statisticKeys;
  label;
  view;

  get app() {
    return this.appService.app;
  }

  get isVisible() {
    return true;
  }

  get statisticKey() {
    return this.statisticKeys[this.view];
  }

  get statistic() {
    return this.seriesEditorService.series.stats.findBy('key', this.statisticKey);
  }

  init(inputs) {
    inputs.component = inputs.tabComponent || 'series/editor/tabs/stat-tab';

    super.init(...arguments);

    assert(
      'The view for a series stat tab must be a known statistic.',
      Object.keys(statisticKeys).includes(inputs.view),
    );

    this.setProperties({
      label: inputs.label,
      view: inputs.view,
    });
  }
}
