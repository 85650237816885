/* RESPONSIBLE TEAM: team-product-exploration */

import { registerDestructor } from '@ember/destroyable';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Modifier from 'ember-modifier';

export interface HighlighterSignature {
  Element: HTMLElement;
  Args: Args;
}

interface Args {
  Positional: [];
  Named: { identifier: string | undefined | null };
}

/**
 * This modifier is responsible for highlighting the element with the identifier
 * passed in the query params.
 *
 * For the query params to work, the controller should have the query params defined.
 * And declare this modifier in the template where the element you want to highlight
 * is present.
 *
 * The modifier will add a ring-4 and ring-yellow-light class to the element and
 * scroll it into view.
 * The interactionObserver will remove the classes when the element is clicked.
 *
 * This does not work for text. It only works for elements.
 *
 * Example in: app/components/new-settings/common/card-setting-container.hbs
 */

export default class Highlighter extends Modifier<HighlighterSignature> {
  @service declare router: RouterService;

  private interactionObserver = (element: HTMLElement) => {
    if (element.classList.contains('ring-4')) {
      element.classList.remove('ring-4');
      element.classList.remove('ring-yellow-light');
    }
  };

  modify(element: HTMLElement, _postional: never[], { identifier }: Args['Named']) {
    if (
      identifier &&
      this.router.currentRoute?.queryParams?.highlight === identifier &&
      !element.classList.contains('ring-4')
    ) {
      element.classList.add('ring-4');
      element.classList.add('ring-yellow-light');
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (element.classList.contains('ring-4')) {
      element.classList.remove('ring-4');
      element.classList.remove('ring-yellow-light');
    }
    element.addEventListener('click', () => this.interactionObserver(element));
    registerDestructor(this, () => {
      element.removeEventListener('click', () => this.interactionObserver(element));
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    highlighter: typeof Highlighter;
  }
}
