/* RESPONSIBLE TEAM: team-proactive-support */
import ConnectorConfiguration from './connector-configuration';
import { seriesEdgeTypes } from 'embercom/models/data/matching-system/matching-constants';
import { isNone } from '@ember/utils';

export default class SplitTestConnectorConfiguration extends ConnectorConfiguration {
  edgeSplit = undefined;

  get label() {
    return `${this.edgeSplit.name} (${this.edgeSplit.percentage}%)`;
  }

  constructor(inputs) {
    inputs.component = 'series/node/connectors/standard-connector';
    inputs.edgeType = seriesEdgeTypes.split;
    super({ ...inputs });

    this.edgeSplit = inputs.edgeSplit;
  }

  get canConnect() {
    return isNone(this.edgeSplit.edge);
  }
}
