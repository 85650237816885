/* RESPONSIBLE TEAM: team-phone */
import Model, { attr } from '@ember-data/model';
import { PHONE_NUMBER_COUNTRY_DETAILS } from '../settings/calling';

export default class PhoneNumber extends Model {
  @attr('string') declare name: string;
  @attr('string') declare phoneNumber: string;
  @attr('string') declare countryIsoCode: string;
  @attr('string') declare status: string;

  get flag() {
    return PHONE_NUMBER_COUNTRY_DETAILS.get(this.countryIsoCode)?.flag;
  }

  get isActive() {
    return ['active', 'missing_bundle'].includes(this.status);
  }
}
