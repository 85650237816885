/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import QualificationAttribute from 'embercom/models/qualification/attribute';
import { validator, buildValidations } from 'ember-cp-validations';
import { readOnly } from '@ember/object/computed';

const Validations = buildValidations({
  followUpRules: {
    validators: [validator('has-many')],
  },
});

export default Model.extend(Validations, {
  paywallFeature: 'operator_qualification',
  qualificationAttributes: fragmentArray('qualification/attribute'),
  followUpRules: fragmentArray('rules/rule'),

  isValid: readOnly('validations.isValid'),

  sanitizeAttributes() {
    this.set(
      'qualificationAttributes',
      QualificationAttribute.removeInvalidFragments(this.qualificationAttributes),
    );
  },
});
