/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class EarlyStageStepConfig extends Fragment {
  @attr('number') couponPercentOff;
  @attr('string') couponPrefix;
  @attr('string') nextProgramName;
  @attr('number') yearNumber;
}
