/* RESPONSIBLE TEAM: team-workflows */
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { buildValidations, validator } from 'ember-cp-validations';
import { ALL_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';
import { fragment } from 'ember-data-model-fragments/attributes';

const Validations = buildValidations({
  action: [
    validator('presence', true),
    validator('rule-action-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
});

export default class RemoveTagFromUser extends Step.extend(Validations) {
  @fragment('rules/rule-action') declare action: any;
  supportedChannels = ALL_CHANNELS;

  static get isStepGroupEnding() {
    return false;
  }

  static createNewStep(store: Store): RemoveTagFromUser {
    return store.createRecord('operator/visual-builder/step/remove-tag-from-user', {
      action: store.createFragment('rules/rule-action', {
        type: 'remove-tag-from-user',
        justAdded: true,
        actionData: {},
      }),
      type: 'operator/visual-builder/step/remove-tag-from-user',
    });
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.No;
  }
}
