/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import { filterBy, mapBy } from '@ember/object/computed';
import TeammateAssigneeFilter from 'embercom/models/inbox/search/teammate-assignee-filter';
import TeamAssigneeFilter from 'embercom/models/inbox/search/team-assignee-filter';
import AssigneeFilter from 'embercom/models/inbox/search/assignee-filter';
import DateFilter from 'embercom/models/inbox/search/date-filter';
import TagFilter from 'embercom/models/inbox/search/tag-filter';
import UserFilter from 'embercom/models/inbox/search/user-filter';

const DATE_FORMAT = 'YYYY-MM-DD';

export default EmberObject.extend({
  init() {
    this._super(...arguments);
    this.set('filters', []);
  },

  currentTextFilter: '',

  _teammateAssigneeFilter: computed('filters.[]', function () {
    return this.filters.find((f) => f.type === TeammateAssigneeFilter.type);
  }),
  _teamAssigneeFilter: computed('filters.[]', function () {
    return this.filters.find((f) => f.type === TeamAssigneeFilter.type);
  }),
  _assigneeFilter: computed('filters.[]', function () {
    return this.filters.find((f) => f.type === AssigneeFilter.type);
  }),
  _dateFilter: computed('filters.[]', function () {
    return this.filters.find((f) => f.type === DateFilter.type);
  }),
  _tagFilters: filterBy('filters', 'type', TagFilter.type),
  _userFilters: filterBy('filters', 'type', UserFilter.type),

  tags: mapBy('_tagFilters', 'tag'),
  users: mapBy('_userFilters', 'user'),

  queryParams: computed(
    '_tagFilters.[]',
    '_userFilters.[]',
    '_dateFilter',
    '_assigneeFilter',
    '_teammateAssigneeFilter',
    '_teamAssigneeFilter',
    'currentTextFilter',
    function () {
      let tagIds = this._tagFilters.map((f) => f.value);
      let userIds = this._userFilters.map((f) => f.value);
      let q = this.currentTextFilter.length > 0 ? this.currentTextFilter : null;
      let adminDateRangeStart = this._dateFilter
        ? this._dateFilter.range.start.format(DATE_FORMAT)
        : null;
      let adminDateRangeEnd = this._dateFilter
        ? this._dateFilter.range.end.format(DATE_FORMAT)
        : null;

      let params = {
        q,
        userIds,
        tagIds,
        adminDateRangeStart,
        adminDateRangeEnd,
      };

      if (this.app?.canAssignToTeamAndTeammate) {
        let teammateAssigneeIdentifier = this._teammateAssigneeFilter
          ? this._teammateAssigneeFilter.value
          : 'all';
        let teamAssigneeIdentifier = this._teamAssigneeFilter
          ? this._teamAssigneeFilter.value
          : 'all';

        return { ...params, teammateAssigneeIdentifier, teamAssigneeIdentifier };
      } else {
        let assigneeIdentifier = this._assigneeFilter ? this._assigneeFilter.value : 'all';
        return { ...params, assigneeIdentifier };
      }
    },
  ),

  state: computed('currentTextFilter', 'focusedStateType', function () {
    if (this.focusedStateType) {
      return 'focused';
    } else if (this.currentTextFilter.endsWith(' ') || this.currentTextFilter.length === 0) {
      return 'start';
    } else {
      return 'scattered';
    }
  }),

  focusedStateType: computed('currentTextFilter', 'validFilterTypes', function () {
    let words = this.currentTextFilter.split(/\s+/).reverse();
    let validFilterTypeSyntaxHash = {};
    this.validFilterTypes.forEach((filterType) => {
      validFilterTypeSyntaxHash[`${filterType.type}:`] = filterType.type;
    });
    let syntaxSymbols = Object.keys(validFilterTypeSyntaxHash);
    for (let word of words) {
      if (word) {
        for (let syntaxSymbol of syntaxSymbols) {
          if (word.toLowerCase().startsWith(syntaxSymbol)) {
            return validFilterTypeSyntaxHash[syntaxSymbol];
          }
        }
      }
    }
  }),

  suggestionsQuery: computed('currentTextFilter', 'focusedStateType', function () {
    if (this.focusedStateType) {
      let filterTypeSyntax = `${this.focusedStateType}:`;
      let downcasedTextFilter = this.currentTextFilter.toLowerCase();
      let indexOfSyntax = downcasedTextFilter.indexOf(filterTypeSyntax);

      return this.currentTextFilter.substring(indexOfSyntax + filterTypeSyntax.length).trimStart();
    }

    return this.currentTextFilter;
  }),

  validFilterTypes: computed(
    '_assigneeFilter',
    '_teammateAssigneeFilter',
    '_teamAssigneeFilter',
    '_dateFilter',
    'app.canAssignToTeamAndTeammate',
    function () {
      let filterTypes = [TagFilter, UserFilter];

      if (this.app?.canAssignToTeamAndTeammate) {
        if (!this._teammateAssigneeFilter) {
          filterTypes.push(TeammateAssigneeFilter);
        }
        if (!this._teamAssigneeFilter) {
          filterTypes.push(TeamAssigneeFilter);
        }
      } else if (!this._assigneeFilter) {
        filterTypes.push(AssigneeFilter);
      }

      if (!this._dateFilter) {
        filterTypes.push(DateFilter);
      }

      return filterTypes;
    },
  ),

  validSuggestionTypes: computed(
    '_assigneeFilter',
    '_teammateAssigneeFilter',
    '_teamAssigneeFilter',
    'app.canAssignToTeamAndTeammate',
    function () {
      let suggestionTypes = [TagFilter.type, UserFilter.type];

      if (this.app?.canAssignToTeamAndTeammate) {
        if (!this._teammateAssigneeFilter) {
          suggestionTypes.push(TeammateAssigneeFilter.type);
        }
        if (!this._teamAssigneeFilter) {
          suggestionTypes.push(TeamAssigneeFilter.type);
        }
      } else if (!this._assigneeFilter) {
        suggestionTypes.push(AssigneeFilter.type);
      }

      return suggestionTypes;
    },
  ),

  addFilter(filter) {
    if (this._shouldAddFilter(filter)) {
      let remainingFilterText = this._textFilterWithoutTextProvidingCurrentSuggestion();
      this.filters.pushObject(filter);
      this.set('currentTextFilter', remainingFilterText);
    }
  },
  removeFilter(filter) {
    this.filters.removeObject(filter);
  },
  reset() {
    this.set('filters', []);
    this.set('currentTextFilter', '');
  },
  setCurrentFocusState(filterType) {
    this.set('currentTextFilter', `${this.currentTextFilter}${filterType}:`);
  },
  setCurrentTextFilter(currentTextFilter) {
    this.set('currentTextFilter', currentTextFilter);
  },
  _textFilterWithoutTextProvidingCurrentSuggestion() {
    let removeAfterPosition = 0;
    if (this.focusedStateType) {
      removeAfterPosition = this.currentTextFilter
        .toLowerCase()
        .lastIndexOf(`${this.focusedStateType}:`);
    } else {
      removeAfterPosition = this.currentTextFilter.length - this.suggestionsQuery.length;
    }
    return this.currentTextFilter.substring(0, removeAfterPosition);
  },
  _shouldAddFilter(filter) {
    return this.validFilterTypes.map((filterType) => filterType.type).includes(filter.type);
  },
});
