/* RESPONSIBLE TEAM: team-proactive-support */
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import EmailValidations from 'embercom/validations/email';
import emailEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/email';
import emailSelectionSetConfigGenerator from 'embercom/objects/selection-set/configuration-generators/email';
import { dependentKeyCompat } from '@ember/object/compat';
import { consentType } from 'embercom/models/outbound-subscriptions/subscription-type';

export const hasKnownEmailPredicate = {
  attribute: 'email',
  type: 'string',
  comparison: 'known',
  value: '',
};
export const notUnsubscribedFromEmailPredicate = {
  attribute: 'unsubscribed_from_emails',
  type: 'boolean',
  comparison: 'false',
  value: '',
};
export const unsubscribedFromEmailPredicate = {
  attribute: 'unsubscribed_from_emails',
  type: 'boolean',
  comparison: 'true',
  value: '',
};
export const notMarkedAsSpamPredicate = {
  attribute: 'marked_email_as_spam',
  type: 'string',
  comparison: 'ne',
  value: 'true',
};
export const notHardBouncedPredicate = {
  attribute: 'has_hard_bounced',
  type: 'string',
  comparison: 'ne',
  value: 'true',
};
export const engagedUsersPredicate = {
  attribute: 'last_request_at',
  type: 'date',
  comparison: 'gt',
  value: '90',
};

const implicitPredicatesForEmail = [
  hasKnownEmailPredicate,
  notMarkedAsSpamPredicate,
  notHardBouncedPredicate,
];

export default class EmailModel extends Model.extend(EmailValidations) {
  @service appService;
  @service store;

  @hasMany('emails/localized-email-content', { inverse: 'email' }) localizedEmailContents;
  @belongsTo('matching-system/ruleset-links/email', { inverse: 'object' }) rulesetLink;
  @attr('boolean') sendToUnsubscribed;
  @attr('boolean') showUnsubscribeLink;
  @attr('boolean') isLegallyMandated;
  @attr('string') subscriptionTypeId;
  @attr('string') linkFontWeight;
  @attr('string') linkFontColor;
  @attr('string') linkFontStyle;
  @attr('string') contentBackgroundColor;
  @attr('string') bodyBackgroundColor;

  @dependentKeyCompat
  get contentHash() {
    return this.localizedEmailContents.map(
      (localizedEmailContent) => localizedEmailContent.contentHash,
    );
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.localizedEmailContents.any((localizedContent) => localizedContent.hasUnsavedChanges)
    );
  }

  peekHasCustomEmailAssetsDomain() {
    return this.store.peekAll('settings/custom-email-assets-domain').length > 0;
  }

  get subscriptionType() {
    return (
      this.subscriptionTypeId &&
      this.store.peekRecord('outbound-subscriptions/subscription-type', this.subscriptionTypeId)
    );
  }

  get localizedContents() {
    return this.localizedEmailContents;
  }

  editorConfig() {
    let container = getOwner(this);
    return emailEditConfigGenerator({ container });
  }

  selectionSetConfig() {
    let container = getOwner(this);
    return emailSelectionSetConfigGenerator({ container });
  }

  @dependentKeyCompat
  get implicitPredicates() {
    // Any changes to this function should be made to the backend version too.
    let predicates = [...implicitPredicatesForEmail];

    if (this.isLegallyMandated) {
      predicates = [...predicates, ...this._legallyMandatedImplicitPredicates];
    } else {
      predicates = [...predicates, ...this._audienceRestrictingImplicitPredicates];
    }

    if (this.subscriptionTypeId) {
      predicates = [
        ...predicates,
        {
          attribute:
            this.subscriptionType?.consentType === consentType.optIn
              ? 'opted_in_subscription_type_ids'
              : 'opted_out_subscription_type_ids',
          type: 'subscription_type',
          comparison: this.subscriptionType?.consentType === consentType.optIn ? 'in' : 'nin',
          value: this.subscriptionTypeId,
        },
      ];
    }

    return predicates;
  }

  get _legallyMandatedImplicitPredicates() {
    let predicates = [];
    if (!this.sendToUnsubscribed) {
      predicates = [...predicates, notUnsubscribedFromEmailPredicate];
    }
    return predicates;
  }

  get _audienceRestrictingImplicitPredicates() {
    let predicates = [];

    if (this.sendToUnsubscribed) {
      if (this.showUnsubscribeLink) {
        predicates = [
          ...predicates,
          {
            type: 'or',
            predicates: [
              notUnsubscribedFromEmailPredicate,
              {
                type: 'and',
                predicates: [unsubscribedFromEmailPredicate, engagedUsersPredicate],
              },
            ],
          },
        ];
      }
    } else {
      predicates = [...predicates, notUnsubscribedFromEmailPredicate];
    }

    if (!this.showUnsubscribeLink) {
      predicates = [...predicates, engagedUsersPredicate];
    }

    return predicates;
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this.localizedEmailContents.forEach((content) => content.rollbackAttributes());
  }
}
