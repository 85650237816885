/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Block from './block';

export default Block.extend({
  videoId: attr('string'),
  provider: attr('string'),
  text: attr('string'),
  trackingLinkUrl: attr('string'),
});
