/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';

export default class CustomBounceSettings extends Model {
  @attr('string') appId;
  @attr('string') rootDomain;
  @attr('string') subdomain;
  @attr('string') host;
  @attr('string') value;
  @attr('boolean') validRecordExists;
}
