/* RESPONSIBLE TEAM: team-frontend-tech */
import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';

export default modifier((element, [active]) => {
  if (active === false) {
    return;
  }
  // running this in a `next` give enough time for elements with simple animations to become visible
  // eg. in our editor panels
  next(this, () => {
    element.select();
  });
});
