/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, belongsTo } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import type Admin from 'embercom/models/admin';
import type TrackedChanges from './changelog/tracked-changes';

export default class Changelog extends Model {
  @belongsTo('series/series', { async: false }) declare series: $TSFixMe;
  @belongsTo('admin', { async: false }) declare editedBy: Admin;
  @fragment('series/changelog/tracked-changes') declare trackedChanges: TrackedChanges;
  @attr('date') declare createdAt: Date;

  get hasNewChanges() {
    return this.trackedChanges.hasNewChanges;
  }
}
