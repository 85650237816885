/* RESPONSIBLE TEAM: team-phone */
export interface CallingDetailWireFormat {
  id: number;
  recording_enabled: boolean;
  type: string;
  state: string;
  direction: string;
  ended_reason: string | null;
  duration: number;
  answered_at: string;
  created_at: string;
  initiated_at: string;
  ended_at: string;
  routing_time: string | null;
  user_id: string;
  admin_id: number;
  phone: string;
  human_transcript: string | null;
  json_transcript: Message[] | null;
  recording_url: string | null;
  conversation_id: number;
  transcript_url: string | null;
  transcript_status: string | null;
  recording_name: string | null;
  forwarded_to: string | null;
  detailed_state: string;
  is_recorded: boolean;
  workspace_phone_number: string;
  csat_rating: string | null;
}

export type Message = {
  content: string;
  end_time: string;
  speaker: string;
  start_time: string;
};

export class CallingDetail {
  readonly id: number;
  readonly recordingEnabled: boolean;
  readonly type: string;
  readonly state: string;
  readonly direction: string;
  readonly endedReason: string | null;
  readonly duration: number;
  readonly answeredAt: string;
  readonly createdAt: string;
  readonly initiatedAt: string;
  readonly endedAt: string;
  readonly routingTime: string | null;
  readonly userId: string;
  readonly adminId: number;
  readonly phone: string;
  readonly humanTranscript: string | null;
  readonly jsonTranscript: Message[] | null;
  readonly recordingUrl: string | null;
  readonly conversationId: number;
  readonly transcriptUrl: string | null;
  readonly transcriptStatus: string | null;
  readonly recordingName: string | null;
  readonly forwardedTo: string | null;
  readonly detailedState: string;
  readonly isRecorded: boolean;
  readonly workspacePhoneNumber: string;
  readonly csatRating: string | null;

  constructor(
    id: number,
    recordingEnabled: boolean,
    type: string,
    state: string,
    direction: string,
    endedReason: string | null,
    duration: number,
    answeredAt: string,
    createdAt: string,
    initiatedAt: string,
    endedAt: string,
    routingTime: string | null,
    userId: string,
    adminId: number,
    phone: string,
    humanTranscript: string | null,
    jsonTranscript: Message[] | null,
    recordingUrl: string | null,
    conversationId: number,
    transcriptUrl: string | null,
    transcriptStatus: string | null,
    recordingName: string | null,
    forwardedTo: string | null,
    detailedState: string,
    isRecorded: boolean,
    workspacePhoneNumber: string,
    csatRating: string | null,
  ) {
    this.id = id;
    this.recordingEnabled = recordingEnabled;
    this.type = type;
    this.state = state;
    this.direction = direction;
    this.endedReason = endedReason;
    this.duration = duration;
    this.answeredAt = answeredAt;
    this.createdAt = createdAt;
    this.initiatedAt = initiatedAt;
    this.endedAt = endedAt;
    this.routingTime = routingTime;
    this.userId = userId;
    this.adminId = adminId;
    this.phone = phone;
    this.humanTranscript = humanTranscript;
    this.jsonTranscript = jsonTranscript;
    this.recordingUrl = recordingUrl;
    this.conversationId = conversationId;
    this.transcriptUrl = transcriptUrl;
    this.transcriptStatus = transcriptStatus;
    this.recordingName = recordingName;
    this.forwardedTo = forwardedTo;
    this.detailedState = detailedState;
    this.isRecorded = isRecorded;
    this.workspacePhoneNumber = workspacePhoneNumber;
    this.csatRating = csatRating;
  }

  static deserialize(json: CallingDetailWireFormat): CallingDetail {
    return new CallingDetail(
      json.id,
      json.recording_enabled,
      json.type,
      json.state,
      json.direction,
      json.ended_reason,
      json.duration,
      json.answered_at,
      json.created_at,
      json.initiated_at,
      json.ended_at,
      json.routing_time,
      json.user_id,
      json.admin_id,
      json.phone,
      json.human_transcript,
      json.json_transcript,
      json.recording_url,
      json.conversation_id,
      json.transcript_url,
      json.transcript_status,
      json.recording_name,
      json.forwarded_to,
      json.detailed_state,
      json.is_recorded,
      json.workspace_phone_number,
      json.csat_rating,
    );
  }

  static serialize(callDetail: CallingDetail): CallingDetailWireFormat {
    return {
      id: callDetail.id,
      recording_enabled: callDetail.recordingEnabled,
      type: callDetail.type,
      state: callDetail.state,
      direction: callDetail.direction,
      ended_reason: callDetail.endedReason,
      duration: callDetail.duration,
      answered_at: callDetail.answeredAt,
      created_at: callDetail.createdAt,
      initiated_at: callDetail.initiatedAt,
      ended_at: callDetail.endedAt,
      routing_time: callDetail.routingTime,
      user_id: callDetail.userId,
      admin_id: callDetail.adminId,
      phone: callDetail.phone,
      human_transcript: callDetail.humanTranscript,
      json_transcript: callDetail.jsonTranscript,
      recording_url: callDetail.recordingUrl,
      conversation_id: callDetail.conversationId,
      transcript_url: callDetail.transcriptUrl,
      transcript_status: callDetail.transcriptStatus,
      recording_name: callDetail.recordingName,
      forwarded_to: callDetail.forwardedTo,
      detailed_state: callDetail.detailedState,
      is_recorded: callDetail.isRecorded,
      workspace_phone_number: callDetail.workspacePhoneNumber,
      csat_rating: callDetail.csatRating,
    };
  }
}
