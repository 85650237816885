/* RESPONSIBLE TEAM: team-help-desk-experience */
let AttributeIconMap = {
  icons: {
    name: 'multiple-people',
    anonymous: 'person',
    user_id: 'transfer',
    last_request_at: 'calendar',
    last_contacted_at: 'calendar',
    'last_outbound_content_received_at_data.carousel': 'calendar',
    'last_outbound_content_received_at_data.chat': 'calendar',
    'last_outbound_content_received_at_data.custom_bot': 'calendar',
    'last_outbound_content_received_at_data.email': 'calendar',
    'last_outbound_content_received_at_data.mobile_push': 'calendar',
    'last_outbound_content_received_at_data.post': 'calendar',
    'last_outbound_content_received_at_data.sms_message': 'calendar',
    'last_outbound_content_received_at_data.survey': 'calendar',
    created_at: 'calendar',
    remote_created_at: 'calendar',
    last_heard_from_at: 'calendar',
    email: 'email',
    email_domain: 'globe',
    'social_accounts.job_title': 'account',
    session_count: 'sessions',
    'geoip_data.continent_code': 'globe',
    'geoip_data.country_name': 'globe',
    'geoip_data.country_code': 'globe',
    'geoip_data.region_name': 'globe',
    'geoip_data.city_name': 'city',
    'geoip_data.timezone': 'clock',
    'user_agent_data.name': 'computer',
    'user_agent_data.version': 'computer',
    ios_device: 'ios',
    ios_app_version: 'ios',
    visit_count: 'sessions',
    session_count_ios: 'ios',
    last_request_at_ios: 'ios',
    ios_os_version: 'ios',
    last_request_at_android: 'android',
    android_os_version: 'android',
    session_count_android: 'android',
    android_app_version: 'android',
    android_device: 'android',
    'user_agent_data.os': 'computer',
    'social_accounts.twitter.follower_count': 'twitter',
    'social_accounts.twitter.accounts.0.url': 'twitter',
    'social_accounts.linkedin.accounts.0.url': 'linkedin',
    'social_accounts.facebook.accounts.0.url': 'facebook',
    'social_accounts.klout.accounts.0.kscore': 'klout',
    tag_ids: 'segment',
    'user_event_summaries.segment-created.count': 'transfer',
    opted_out_subscription_type_ids: 'unsubscribed',
    opted_in_subscription_type_ids: 'rounded-check',
    unsubscribed_from_emails: 'unsubscribed',
    sms_consent: 'check',
    unsubscribed_from_sms: 'unsubscribed',
    marked_email_as_spam: 'bounced',
    has_hard_bounced: 'bounced',
    last_email_opened_at: 'calendar',
    last_email_clicked_at: 'calendar',
    last_visited_url: 'globe',
    referrer: 'globe',
    url_visits: 'sessions',
    total_url_visits: 'sessions',
    manual_tag_ids: 'tag',
    browser_locale: 'globe',
    language_override: 'globe',
    vip_timestamp: 'star',
    companies: 'company',
    'company.name': 'company',
    'company.last_request_at': 'calendar',
    'company.remote_created_at': 'calendar',
    'company.user_count': 'multiple-people',
    'company.session_count': 'sessions',
    'company.plan_id': 'account',
    'company.monthly_spend': 'creditcard',
    'company.manual_tag_ids': 'tag',
    'company.tag_ids': 'segment',
    'company.size': 'multiple-people',
    'company.website': 'link',
    'company.industry': 'company',
    'company.custom_data.id_code': 'transfer',
    'company.custom_data.admin_link': 'transfer',
    'company.custom_data.framework': 'transfer',
    'company.custom_data.installed_at': 'calendar',
    'company.custom_data.converted_at': 'calendar',
    'company.custom_data.tracked_users': 'transfer',
    'company.custom_data.signed_up_through_heroku': 'transfer',
    'company.custom_data.powered_by_intercom': 'transfer',
    'company.custom_data.custom_data_installed_at': 'calendar',
    'client_attributes.time_on_page': 'clock',
    role: 'person',
    user: 'person',
    phone: 'phone',
    phone_country: 'globe',
    whatsapp_number: 'whatsapp',
    last_conversation_rating_given: 'lwr-happy',
    utm_campaign: 'custom-data',
    utm_content: 'custom-data',
    utm_medium: 'custom-data',
    utm_source: 'custom-data',
    utm_term: 'custom-data',
    owner_id: 'owner',
    account_id: 'account',
    account_name: 'account',
    article_viewed: 'article',
    chat_viewed: 'opened-message',
    chat_received: 'receive',
    chat_opened: 'opened-message',
    chat_clicked: 'click-element',
    chat_goal_hit: 'goals',
    chat_replied: 'reply',
    chat_dismissal: 'dismiss',
    chat_collected_email: 'email',
    post_viewed: 'opened-message',
    post_received: 'receive',
    post_opened: 'opened-message',
    post_clicked: 'click-element',
    post_goal_hit: 'goals',
    post_replied: 'reply',
    post_dismissal: 'dismiss',
    post_reacted: 'lwr-happy',
    email_received: 'receive',
    email_opened: 'opened-message',
    email_clicked: 'click-element',
    email_goal_hit: 'goals',
    email_replied: 'reply',
    email_bounced: 'bounced',
    email_unsubscribed: 'unsubscribed',
    tour_viewed: 'opened-message',
    tour_completed: 'complete',
    tour_failed: 'bounced',
    tour_goal_hit: 'goals',
    push_received: 'receive',
    push_opened: 'opened-message',
    push_goal_hit: 'goals',
    carousel_received: 'receive',
    carousel_completed: 'complete',
    carousel_dismissal: 'dismiss',
    carousel_goal_hit: 'goals',
    custom_bot_received: 'receive',
    custom_bot_replied: 'reply',
    custom_bot_completed: 'complete',
    custom_bot_goal_hit: 'goals',
    series_started: 'receive',
    series_completed: 'complete',
    series_disengaged: 'clock',
    series_exited: 'arrow',
    series_goal_success: 'goals',
    banner_viewed: 'opened-message',
    banner_clicked: 'click-element',
    banner_reacted: 'lwr-happy',
    banner_goal_hit: 'goals',
    banner_collected_email: 'email',
    survey_received: 'receive',
    survey_completed: 'complete',
    survey_dismissal: 'dismiss',
    survey_goal_hit: 'goals',
    sms_received: 'receive',
    sms_replied: 'reply',
    sms_goal_hit: 'goals',
  },
} as const;

export default AttributeIconMap;
