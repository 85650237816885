/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import TooltipGroupContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/tooltip-group/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import AudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';

export default function generateEditModeConfig(options) {
  let container = options.container;

  return EditModeConfig.create({
    headerComponentConfigs: [
      StandardEditModeHeaderConfiguration.create({
        container,
      }),
    ],
    editorPanelConfigs: [
      TooltipGroupContentPanelConfiguration.create({ container }),
      StandardAudiencePanelConfiguration.create({
        audienceHeaderConfiguration: AudienceHeaderConfiguration.create({
          container,
        }),
        audienceEditorConfiguration: new StandardAudienceEditorConfiguration({
          showMatchBehavior: false,
          showClientPredicates: false,
          container,
        }),
        container,
      }),
      StandardSchedulePanelConfiguration.create({
        container,
      }),
      StandardGoalPanelConfiguration.create({
        introText: 'Understand how your tooltips drive people to take action in your product.',
        container,
      }),
    ],
    footerComponentConfigs: [],
  });
}
