/* RESPONSIBLE TEAM: team-channels */
import Model, { attr, belongsTo } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import LocalizedWhatsappContentValidations from 'embercom/validations/localized-whatsapp-message-content';

export default class localizedWhatsappMessageContents extends Model.extend(
  LocalizedWhatsappContentValidations,
) {
  @belongsTo('whatsapp/whatsapp', { inverse: 'localizedWhatsappMessageContents' }) whatsapp;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [{ text: '', type: 'paragraph', modelKey: 'common/blocks/paragraph' }],
  })
  jsonBlocks;
  @attr('string') locale;
  @attr('array') components;
  @attr('string') senderId;
  @attr('string') assignToId;
  @attr('number') senderType;
  @attr('number') assignToType;

  get isValid() {
    return true;
  }

  get contentHash() {
    return this.jsonBlocks.serialize();
  }
}
