/* RESPONSIBLE TEAM: team-workflows */
import type Fin from 'embercom/models/operator/visual-builder/step/fin';
import StepConfig, { type StepConfigParams } from '../step';

export default class FinConfiguration extends StepConfig {
  declare step: Fin;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/fin',
      ...params,
    });
  }
}
