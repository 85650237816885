/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import Subview from './subview';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import ContentBlock from 'embercom/models/common/subviews/content-block';

export default Subview.extend({
  distribution: attr('string', { defaultValue: 'fill_proportionally' }),
  axis: attr('string', { defaultValue: 'vertical' }),
  subviews: fragmentArray(
    'common/subviews/subview',
    { polymorphic: true, typeKey: 'modelKey' },
    { defaultValue: [] },
  ),
  type: attr('string', { defaultValue: 'content_layout' }),
  insertParagraph() {
    let contentBlock = ContentBlock.createDefaultParagraph(this.store);
    this.subviews.pushObject(contentBlock);
  },
});
