/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import StepConfig, { type StepConfigParams } from '../step';
import containerLookup from 'embercom/lib/container-lookup';
import { tracked } from '@glimmer/tracking';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import { type SyncHasMany } from '@ember-data/model';
import type ConditionalBranches from 'embercom/models/operator/visual-builder/step/conditional-branches';

export default class ConditionalBranchesConfiguration extends StepConfig {
  declare step: ConditionalBranches;

  @tracked branches: SyncHasMany<ConnectionPoint>;

  intercomEventService: any;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/conditional-branches',
      ...params,
    });

    this.branches = this.step.outwardConnectionPoints;
    this.intercomEventService = containerLookup('service:intercomEventService');
  }

  get alignment() {
    return 'left' as const;
  }

  get branchConfigMenuOptions() {
    return [this.deleteBranchOption];
  }

  // branchMenuConfig - Delete Option
  get deleteBranchOption() {
    return {
      icon: 'trash',
      text: this.intl.t('operator.workflows.visual-builder.step-option-menu.delete'),
      isDisabled: !this.canDeleteBranch,
      onSelectItem: ({ branch }: { branch: ConnectionPoint }) => {
        this.editorState.markAsEdited();
        return this.deleteBranch(branch);
      },
    };
  }

  // every conditional "if" branch is deletable since the "else" branch does not render the delete option
  get canDeleteBranch() {
    return true;
  }

  deleteBranch(branch: ConnectionPoint) {
    this.intercomEventService.trackAnalyticsEvent({ object: branch, action: 'removed' });
    this.branches.removeObject(branch);
    this.editorState.logInteraction('Deleted conditional branch');
  }
}
