/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr, belongsTo } from '@ember-data/model';
import type Admin from 'embercom/models/admin';

export default class ConversationSla extends Model {
  @attr('string') declare appId: string;
  @attr('date') declare createdAt: Date;
  @attr('string') declare name: string;
  @attr('number') declare firstReplyTimeInSeconds: number;
  @attr('number') declare nextReplyTimeInSeconds: number;
  @attr('number') declare resolutionTimeInSeconds: number;
  @attr('number') declare timeToCloseInSeconds: number;
  @attr('string') declare officeHoursScheduleId: string;
  @attr('boolean', { defaultValue: false }) declare archived: boolean;
  @attr('date') declare archivedAt?: Date;
  @attr('boolean') declare pauseOnSnooze: boolean;
  @attr('boolean') declare pauseOnWaitingOnCustomer: boolean;

  @belongsTo('admin', { async: false }) declare archivedBy?: Admin;
}
