/* RESPONSIBLE TEAM: team-knowledge-interop */
import { FinAvailability } from 'embercom/lib/ai-content-library/constants';
import { put } from 'embercom/lib/ajax';
import { type EntityType } from '../data/entity-types';

export interface AIConsumable {
  /** Return the entity id of the content item. */
  get entityId(): number;
  get entityType(): EntityType;
  /** Return the availability (not the state) of this content for CHATBOT (public) */
  get chatbotAvailabilityOption(): FinAvailability | undefined;
  /** Set the availability (not the state) of this content for CHATBOT (public) */
  set chatbotAvailabilityOption(availability: FinAvailability);
  /** Return what availability value (not state value) is used to indicate that a content item of this type is available to COPILOT */
  copilotAvailableValue: FinAvailability;
  /** Return what availability value (not state value) is used to indicate that a content item of this type is available to CHATBOT */
  chatbotAvailableValue: FinAvailability;
  /** Return the availability (not the state) of this content for COPILOT (internal) */
  get copilotAvailabilityOption(): FinAvailability;
  /** Set the availability (not the state) of this content for COPILOT (internal) */
  set copilotAvailabilityOption(availability: FinAvailability);
  /**
   * This returns whether the content item is available to COPILOT
   * Note that this could be true, even if the content item state is NOT_USED, if the copilotAvailableValue is DERIVED.
   */
  get availableToCopilot(): boolean;
  /**
   * This returns whether the content item is available to CHATBOT
   * Note that this could be true, even if the content item state is NOT_USED, if the chatbotAvailableValue is DERIVED.
   */
  get availableToChatbot(): boolean;
  /**
   * Inverts the availability of the content item and saves it
   * If the content item is unavailable it will be set to this.copilotAvailableValue
   * If the content item is available it will be set to FinAvailability.OFF
   */
  toggleCopilotAvailability: () => Promise<void>;
  /**
   * Inverts the availability of the content item and saves it
   * If the content item is unavailable it will be set to this.chatbotAvailableValue
   * If the content item is available it will be set to FinAvailability.OFF
   */
  toggleChatbotAvailability: () => Promise<void>;
  isEligibleForCopilot: () => Promise<boolean>;
  isEligibleForChatbot: () => Promise<boolean>;
}

export async function toggleCopilotAvailability(app_id: number, entity: AIConsumable) {
  await setCopilotAvailability(app_id, [entity], !isAvailableToCopilot(entity));
}

export async function toggleChatbotAvailability(app_id: number, entity: AIConsumable) {
  await setChatbotAvailability(app_id, [entity], !entity.availableToChatbot);
}

export async function setCopilotAvailability(
  app_id: number,
  entities: AIConsumable[],
  available: boolean,
) {
  if (available) {
    await enableCopilotAvailability(app_id, entities);
  } else {
    await disableCopilotAvailability(app_id, entities);
  }

  for (let entity of entities) {
    entity.copilotAvailabilityOption = available
      ? entity.copilotAvailableValue
      : FinAvailability.OFF;
  }
}

export async function setChatbotAvailability(
  app_id: number,
  entities: AIConsumable[],
  available: boolean,
) {
  if (available) {
    await enableChatbotAvailability(app_id, entities);
  } else {
    await disableChatbotAvailability(app_id, entities);
  }

  for (let entity of entities) {
    entity.chatbotAvailabilityOption = available
      ? entity.chatbotAvailableValue
      : FinAvailability.OFF;
  }
}

function enableCopilotAvailability(app_id: number, entities: AIConsumable[]) {
  return performUpdateRequest(
    '/ember/knowledge_base/bulk_actions/bulk_enable_copilot_availability',
    app_id,
    entities,
  );
}

function enableChatbotAvailability(app_id: number, entities: AIConsumable[]) {
  return performUpdateRequest(
    '/ember/knowledge_base/bulk_actions/bulk_enable_chatbot_availability',
    app_id,
    entities,
  );
}

function disableCopilotAvailability(app_id: number, entities: AIConsumable[]) {
  return performUpdateRequest(
    '/ember/knowledge_base/bulk_actions/bulk_disable_copilot_availability',
    app_id,
    entities,
  );
}

function disableChatbotAvailability(app_id: number, entities: AIConsumable[]) {
  return performUpdateRequest(
    '/ember/knowledge_base/bulk_actions/bulk_disable_chatbot_availability',
    app_id,
    entities,
  );
}

async function performUpdateRequest(
  updateApiPath: string,
  app_id: number,
  entities: AIConsumable[],
) {
  await put(updateApiPath, {
    app_id,
    contents_to_update: entities.map((entity) => ({
      entity_id: entity.entityId,
      entity_type: entity.entityType,
    })),
  });
}

export function isAvailableToCopilot(entity: AIConsumable) {
  return entity.copilotAvailabilityOption === entity.copilotAvailableValue;
}

export function isAvailableToChatbot(entity: AIConsumable) {
  return entity.chatbotAvailabilityOption === FinAvailability.ON;
}
