/* RESPONSIBLE TEAM: team-phone */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class BusinessContactAddress extends Fragment {
  @attr('string') addressLine1;
  @attr('string') addressLine2;
  @attr('string') city;
  @attr('string') country;
  @attr('string') state;
  @attr('string') zipCode;
}
