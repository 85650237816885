/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
import { readOnly } from '@ember/object/computed';
import QualificationAttribute from 'embercom/models/qualification/attribute';

const Validations = buildValidations({
  answers: {
    validators: [
      validator('has-many'),
      validator('length', {
        min: 1,
        message: 'You must have at least one reply.',
      }),
    ],
  },
  collectsQualificationData: [
    validator('task-bots/branching-qualification-collects-qualification-data'),
  ],
});

export default Model.extend(Validations, {
  paywallFeature: 'branching_qualification',
  customQuestion: attr('string'),
  answers: fragmentArray('operator-settings/qualify-leads/branching-qualification/answer'),

  isValid: readOnly('validations.isValid'),

  sanitizeAttributes() {
    this.answers.forEach((answer) => {
      answer.set(
        'qualificationAttributes',
        QualificationAttribute.removeInvalidFragments(answer.get('qualificationAttributes')),
      );
    });
  },
});
