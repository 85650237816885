/* RESPONSIBLE TEAM: team-channels */
export default {
  UNKNOWN_SOURCE: 0,
  PING_SOURCE: 1,
  OPERATOR_SOURCE: 2,
  TEAMMATE_SOURCE: 3,
  PUBLIC_API_SOURCE: 4,
  ATTRIBUTE_COLLECTOR_SOURCE: 5,
  INBOUND_MAILING_LIST_SOURCE: 12,
  WHATSAPP_NEW_LEAD_SOURCE: 13,
  WHATSAPP_USER_MERGE_SOURCE: 14,
};
