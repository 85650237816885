/* RESPONSIBLE TEAM: team-product-setup */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const COMMUNICATION_ICONS = [
  'inbox',
  'inbox-arrow-down',
  'envelope',
  'paper-airplane',
  'phone',
];

const communicationCommonKeywords = ['inbox', 'message', 'communication', 'email'];

export const COMMUNICATION_KEYWORDS = {
  [COMMUNICATION_ICONS[0]]: [...communicationCommonKeywords],
  [COMMUNICATION_ICONS[1]]: [...communicationCommonKeywords, 'receive', 'download'],
  [COMMUNICATION_ICONS[2]]: [...communicationCommonKeywords, 'envelope', 'post', 'msg'],
  [COMMUNICATION_ICONS[3]]: [...communicationCommonKeywords, 'paper', 'airplane', 'send'],
  [COMMUNICATION_ICONS[4]]: [...communicationCommonKeywords, 'call', 'phone', 'telephone'],
};
