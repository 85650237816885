/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';

export default class UnknownPart implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.Unknown;

  get creatingEntity() {
    return { type: undefined, id: -1 };
  }

  readonly className?: string;
  readonly actualType?: number;

  constructor(className?: string, actualType?: number) {
    this.className = className;
    this.actualType = actualType;
  }

  static deserialize(json: { renderable_type?: number; class_name?: string }): UnknownPart {
    return new UnknownPart(json.class_name, json.renderable_type);
  }
}
