/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import { applyFunction } from '@intercom/pulse/lib/computed-properties';
import moment from 'moment-timezone';

export default Model.extend({
  startTime: attr('date'),
  duration: attr('number'),
  booked: attr('boolean'),

  notBookable: or('booked', 'startInPast'),

  start: applyFunction(moment, 'startTime'),
  date: computed('start', function () {
    return this.start.format('YYYY-MM-DD');
  }),
  startInPast: computed('start', function () {
    return this.start.diff(moment(new Date())) < 0;
  }),
});
