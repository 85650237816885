/* RESPONSIBLE TEAM: team-workflows */
import type Wait from 'embercom/models/operator/visual-builder/step/wait';
import StepConfig, { type StepConfigParams } from '../step';

export default class WaitConfiguration extends StepConfig {
  declare step: Wait;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/wait',
      ...params,
    });
  }
}
