/* RESPONSIBLE TEAM: team-proactive-support */

import { CAN_MANAGE_ARTICLES_SAVED_VIEWS } from 'embercom/lib/articles/constants';
import { CAN_SEND_MESSAGES } from 'embercom/lib/outbound/constants';

export enum Scope {
  ARTICLES = 0,
  OUTBOUND = 1,
}

export const PERMISSIONS = {
  [Scope.ARTICLES]: {
    edit: CAN_MANAGE_ARTICLES_SAVED_VIEWS,
  },
  [Scope.OUTBOUND]: {
    edit: CAN_SEND_MESSAGES,
  },
};
