/* RESPONSIBLE TEAM: team-frontend-tech */
import config from 'embercom/config/environment';
import * as Sentry from '@sentry/browser';
import { redactEmailFromUrl } from 'embercom/lib/url-redacter';
import ajax from 'embercom/lib/ajax';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';
import { responsibleTeamFromTransition } from 'embercom/lib/transition-responsible-team';

const FILTERED_ERRORS = [
  /TransitionAborted/i,
  /Internal Server Error/i,
  /Bad Gateway/i,
  /Too Many Requests/i,
  /Access is denied/i,
  /Forbidden/i,
  /Not Found/i,
  /Failed to fetch/i,
  /ResizeObserver loop limit exceeded/i,
  /deactivateKeyDown/i,
  /clientHeight/i,
];

let exceptionRecordingEnabled;

export default function initializeSentry() {
  // Don't send Sentry errors on experimental builds
  let emberRev = getEmberRevisionQueryParam();
  if (emberRev) {
    return;
  }

  exceptionRecordingEnabled = true;

  let initParams = {
    dsn: config.sentry.dsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.dedupeIntegration()],
    whitelistUrls: [/embercom/, /templates-raw/, /vendor/],
    release: getRelease(),
    environment: 'production',
    beforeSend(event, hint) {
      if (isFiltered(event, hint)) {
        return null;
      }
      if (exceptionRecordingEnabled) {
        recordExceptionMetric(event, hint);
      }
      return event;
    },

    beforeBreadcrumb(breadcrumb, hint) {
      try {
        let url = breadcrumb.data && breadcrumb.data.url;

        if (url && url.includes('@')) {
          breadcrumb.data.url = redactEmailFromUrl(url);
        }
      } catch (e) {
        console.error('Error filtering breadcrumb url', e);
      }

      try {
        if (breadcrumb.category === 'navigation') {
          breadcrumb.data.from = redactEmailFromUrl(breadcrumb.data.from);
          breadcrumb.data.to = redactEmailFromUrl(breadcrumb.data.to);
        }
      } catch (e) {
        console.error('Error filtering breadcrumb from/to', e);
      }

      return breadcrumb;
    },
  };
  Sentry.init(initParams);
  return initParams;
}

function getEmberRevisionQueryParam() {
  let currentURL = window.location.href;
  let url = new URL(currentURL);
  return url.searchParams.get('ember_rev');
}

export function setSentryTagsFromTransition(transition) {
  setResponsibleTeamFromTransition(transition);
  setRouteParamsFromTransition(transition);
  setTranslationParamsFromPage();
}

export function captureException(exception, options) {
  Sentry.withScope((scope) => {
    setupScope(scope, options);
    Sentry.captureException(exception);
  });
}

export function captureMessage(exception, options) {
  Sentry.withScope((scope) => {
    setupScope(scope, options);
    Sentry.captureMessage(exception);
  });
}

function setResponsibleTeamFromTransition(transition) {
  let responsibleTeam = responsibleTeamFromTransition(transition);

  let scope = Sentry.getCurrentScope();
  scope.setTags({ responsibleTeam, responsible_team: responsibleTeam });
}

function setRouteParamsFromTransition(transition) {
  let tags = {};
  let to = transition.to;

  if (!to) {
    return;
  }

  while (to) {
    tags = { ...tags, ...to.params };
    to = to.parent;
  }

  let scope = Sentry.getCurrentScope();
  scope.setTags({
    transaction: transition.to.name,
    route_name: transition.to.name,
    ...tags,
  });
}

function setTranslationParamsFromPage() {
  let isTranslated = document.documentElement.className.includes('translated');
  let translatedLanguage = isTranslated ? document.documentElement.getAttribute('lang') : null;

  let scope = Sentry.getCurrentScope();
  scope.setTags({
    is_translated: isTranslated,
    translated_language: translatedLanguage,
  });
}

function getRelease() {
  let meta = document.querySelector('meta[name="sentry:revision"]');
  return meta && meta.content;
}

function setupScope(scope, options = {}) {
  if (options.fingerprint) {
    scope.setFingerprint(options.fingerprint);
  }
  if (options.extra) {
    Object.keys(options.extra).forEach((k) => {
      scope.setExtra(k, options.extra[k]);
    });
  }
  if (options.tags) {
    Object.keys(options.tags).forEach((k) => {
      scope.setTag(k, options.tags[k]);
    });
  }
}

function isFiltered(event, hint) {
  return (
    isUnhandledPromiseRejection(event) ||
    isUndefinedNetworkError(hint) ||
    isFilteredErrorMessage(hint) ||
    isFromNodeModules(hint)
  );
}

function recordExceptionMetric(event, hint) {
  let tags = Sentry.getCurrentScope().getScopeData().tags || {};
  let data = {
    exception: getErrorMessageFromHint(hint),
    responsible_team: tags.responsibleTeam,
    route_name: tags.route_name,
    boot_error: tags.bootError,
  };
  ajax({
    url: `${EmbercomBootDataModule.getApiHost()}/ember/exceptions`,
    type: 'POST',
    data: JSON.stringify(data),
  }).catch((r) => {
    if (r.jqXHR.status === 429) {
      exceptionRecordingEnabled = false;
    }
  });
}

function isUnhandledPromiseRejection(event) {
  try {
    return event.exception.values[0].mechanism.type === 'onunhandledrejection';
  } catch (e) {
    return false;
  }
}

function isUndefinedNetworkError(hint) {
  try {
    return hint.originalException.jqXHR.status === 0;
  } catch (e) {
    return false;
  }
}

function isFilteredErrorMessage(hint) {
  let errorMessage = getErrorMessageFromHint(hint);
  if (!errorMessage) {
    return true;
  }
  for (let i = 0; i < FILTERED_ERRORS.length; i++) {
    if (errorMessage.match(FILTERED_ERRORS[i])) {
      return true;
    }
  }
  return false;
}

function isFromNodeModules(hint) {
  let error = hint.originalException;
  if (error && error.stack) {
    if (error.stack.includes('node_modules')) {
      return true;
    }
  }
  return false;
}

function getErrorMessageFromHint(hint) {
  let error = hint.originalException;
  return error && error.message;
}
