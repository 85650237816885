/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { getLocale } from 'embercom/lib/locale';
import SubscriptionTypeValidations from 'embercom/validations/subscription-type';

export const stateOptions = {
  draft: 0,
  live: 1,
  archived: 2,
};

export const translationType = {
  custom: 0,
  announcements: 1,
  newsletter: 2,
  best_practices: 3,
};

export const consentType = {
  optOut: 0,
  optIn: 1,
};

export const readableConsentType = {
  [consentType.optIn]: 'Opt-in',
  [consentType.optOut]: 'Opt-out',
};

export default class SubscriptionType extends Model.extend(SubscriptionTypeValidations) {
  @attr('number') consentType;
  @fragmentArray('outbound-subscriptions/translation', { async: false }) customTranslations;
  @attr('number') state;
  @attr('number') translationType;
  @attr('array') permittedContentTypes;

  get translationForTeammate() {
    let translation = this.customTranslations.find((t) => t.locale === getLocale());
    if (isPresent(translation?.name) && isPresent(translation?.description)) {
      return translation;
    } else {
      return this.fallbackTranslation;
    }
  }

  get defaultTranslationForEndUsers() {
    let settings = this.store.peekAll('outbound-subscriptions/translation-settings');
    let locale = settings.firstObject?.defaultTranslationLocaleId;
    return (
      this.customTranslations.find((t) => t.locale === locale) ||
      this.customTranslations.firstObject
    );
  }

  get fallbackTranslation() {
    let translation = this.defaultTranslationForEndUsers;
    if (!isPresent(translation?.name) || !isPresent(translation?.description)) {
      translation = this.customTranslations.find(
        (t) => isPresent(t.name) && isPresent(t.description),
      );
    }
    return translation;
  }

  get isMissingTranslation() {
    if (this.translationType !== translationType['custom']) {
      return false;
    } else {
      let missingTranslation = this.customTranslations.find(
        (t) => t.name === '' || t.description === '',
      );
      return isPresent(missingTranslation);
    }
  }

  get stateLabel() {
    return Object.keys(stateOptions).find((k) => stateOptions[k] === this.state);
  }

  get isDraft() {
    return this.state === stateOptions.draft;
  }

  get isLive() {
    return this.state === stateOptions.live;
  }

  get isArchived() {
    return this.state === stateOptions.archived;
  }

  get isOptIn() {
    return this.consentType === consentType.optIn;
  }

  get isOptOut() {
    return this.consentType === consentType.optOut;
  }
}
