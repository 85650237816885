/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { readOnly, not, equal, and } from '@ember/object/computed';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Model.extend({
  qualificationType: attr('string'),
  simpleQualification: belongsTo('operator-settings/qualify-leads/simple-qualification', {
    async: false,
  }),
  branchingQualification: belongsTo('operator-settings/qualify-leads/branching-qualification', {
    async: false,
  }),

  enabled: attr('boolean'),
  isValid: readOnly('currentQualificationType.isValid'),

  isNotDirty: and('currentQualificationTypeIsNotDirty', 'qualifyLeadsIsNotDirty'),
  currentQualificationTypeIsNotDirty: not('currentQualificationType.hasDirtyAttributes'),
  qualifyLeadsIsNotDirty: not('hasDirtyAttributes'),

  simpleQualificationSelected: equal('qualificationType', 'default_qualification'),
  currentQualificationType: ternaryToProperty(
    'simpleQualificationSelected',
    'simpleQualification',
    'branchingQualification',
  ),

  refresh() {
    this.currentQualificationType.rollbackAttributes();
  },
});
