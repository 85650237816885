/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { A } from '@ember/array';
import { computed } from '@ember/object';
import {
  and,
  bool,
  empty,
  equal,
  gt,
  not,
  notEmpty,
  or,
  readOnly,
  reads,
} from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { addProperties, isAny, valueOrDefault } from '@intercom/pulse/lib/computed-properties';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import nonconcurrentAjax from 'embercom/lib/nonconcurrent-ajax';
import { hasNestedPredicates } from 'embercom/lib/predicates-helper';
import MESSAGE_CONSTANTS from 'embercom/models/data/messages/message-constants';
import MESSAGE_DEFAULTS from 'embercom/models/data/messages/message-defaults';
import StateProperties from 'embercom/models/mixins/common/states/state-properties';
import MessageRollback from 'embercom/models/mixins/messages/message-rollback';
import MessageUIConcern from 'embercom/models/mixins/messages/message-ui-concern';
import TaggingMixin from 'embercom/models/mixins/tagging-mixin';
import MessageValidations from 'embercom/validations/message';

// The message state is inherited from its variations.
// The state of the message is based on the highest priority variation state.
// This is the prioritized list of states. ** DO NOT CHANGE WITHOUT REASON **
const PRIORITIZED_STATES = ['live', 'scheduled', 'stopped', 'draft'];
const REQUIRED_USERS_FOR_UI = 18;
const UNASSIGNED_ASSIGNEE_ID = 0;

export default Model.extend(
  MessageValidations,
  StateProperties,
  MessageUIConcern,
  MessageRollback,
  TaggingMixin,
  {
    appService: service(),
    app: readOnly('appService.app'),
    appId: readOnly('app.id'),

    rollbackRelationshipNamesTrustedlist: A(['variations']),

    state: attr('string'),
    type: attr('string'),
    created_via: attr('string'),
    created_via_version: attr('number'),
    classType: attr('string'),
    stoppedAt: attr('date'),
    scheduledToGoLiveAt: attr('date'),
    dueToStopAt: attr('date'),
    pageTarget: fragment('messages/page-target'),
    hasPageTargeting: readOnly('pageTarget.present'),
    sendPushNotification: attr('boolean'),
    firstWentLiveAt: attr('date'),
    hasNoPushNotifications: not('sendPushNotification'),

    tags: hasMany('tag', { async: true }),
    taggings: hasMany('tagging'),

    deliveryChannel: attr('number'),
    isIOSFirst: equal('deliveryChannel', MESSAGE_CONSTANTS.iosChannel),
    isAndroidFirst: equal('deliveryChannel', MESSAGE_CONSTANTS.androidChannel),

    showUnsubscribeLink: attr('boolean'),
    selectionState: fragment('messages/selection-state'),

    //@pat We need to separate the selection state validation due to an issue where existing predicates are in a weird state fail validation. See https://github.com/intercom/intercom/issues/59886 for more details.
    selectionStateValuesAreValid: readOnly('selectionState.allPredicatesAreValid'),
    goalPredicatesAreValid: or('hasNoGoal', 'goal.allPredicatesAreValid'),

    validationsSucceed: and(
      'validations.isValid',
      'selectionStateValuesAreValid',
      'goalPredicatesAreValid',
    ),
    validationsFail: not('validationsSucceed'),

    messageGroupId: attr('number'),
    messageGroupName: attr('string'),
    messageGroupOrder: attr('number', { defaultValue: 0 }),

    isAutoMessage: equal('classType', 'auto'),
    isVisitorAutoMessage: equal('classType', 'visitorauto'),
    isNotVisitorAutoMessage: not('isVisitorAutoMessage'),
    isManualMessage: equal('classType', 'manual'),
    isNotManualMessage: not('isManualMessage'),
    isAutoOrVisitorMessage: or('isAutoMessage', 'isVisitorAutoMessage'),

    sampleUser: null,

    title: attr('string'),

    controlGroup: belongsTo('messages/control-group', { async: false }),
    hasNoControlGroup: empty('controlGroup'),
    hasControlGroup: not('hasNoControlGroup'),
    variations: hasMany('message-variation'),
    hasMultipleVariations: gt('variations.length', 1),
    variationA: reads('variations.firstObject'),
    variationB: computed('hasMultipleVariations', 'variations.lastObject', function () {
      return this.hasMultipleVariations ? this.get('variations.lastObject') : null;
    }),
    hasStoppedVariation: or('variationA.isStopped', 'variationB.isStopped'),

    canBeSetLive: computed(
      'isManualMessage',
      'isDraft',
      'isStopped',
      'hasStoppedVariation',
      function () {
        if (this.isManualMessage) {
          return this.isDraft;
        } else {
          return this.isDraft || this.isStopped || this.hasStoppedVariation;
        }
      },
    ),

    canNotBeAutoSaved: or('isLive', 'isScheduled'),
    canBeAutoSaved: not('canNotBeAutoSaved'),

    operatorLeadQualificationEnabled: attr('boolean'),
    answerBotEnabled: attr('boolean'),
    isAttributeCollectorReply: isAny('variations', 'isAttributeCollectorReply'),
    hasFollowUpRules: readOnly('isAttributeCollectorReply'),
    followUpRules: fragmentArray('rules/rule'),

    goal: fragment('messages/goal'),
    hasGoal: notEmpty('goal'),
    hasNoGoal: not('hasGoal'),

    deliveryWindow: fragment('messages/delivery-window'),
    hasDeliveryWindow: notEmpty('deliveryWindow'),

    audiencePreview: fragment('messages/audience-preview'),

    isEmail: equal('type', MESSAGE_CONSTANTS.emailType),
    isInApp: equal('type', MESSAGE_CONSTANTS.inAppType),
    isMobilePush: equal('type', MESSAGE_CONSTANTS.pushType),
    isInAppAndDeliveryWindowsEnabled: and('isInApp', 'hasNoPushNotifications'),
    canEditDeliveryWindows: or('isEmail', 'isMobilePush', 'isInAppAndDeliveryWindowsEnabled'),

    clientData: fragment('messages/client-data'),
    recipients: fragmentArray('messages/recipient'),
    recipientCount: reads('selectionState.count'),
    isTargetingLeads: reads('selectionState.isTargetingLeads'),

    feature: computed('isVisitorAutoMessage', 'isAutoMessage', 'isManualMessage', function () {
      if (this.isEmail) {
        return 'email_messages';
      }

      if (this.isVisitorAutoMessage) {
        return 'visitor_auto_messaging';
      }

      if (this.isManualMessage) {
        return 'manual_messaging';
      }

      if (this.isAutoMessage) {
        return 'auto_messaging';
      }
    }),

    hasBeenSent: or('variationsHaveBeenSent', 'controlGroupHasBeenSent'),
    variationsHaveBeenSent: isAny('variations', 'hasBeenSent'),
    controlGroupHasBeenSent: readOnly('controlGroup.hasBeenSent'),
    variationASends: valueOrDefault('variationA.stats.sentCount', 0),
    variationBSends: valueOrDefault('variationB.stats.sentCount', 0),
    totalSends: addProperties('variationASends', 'variationBSends'),

    remainingSendCount: computed('recipientCount', 'totalSends', function () {
      return Math.max(this.recipientCount - this.totalSends, 0);
    }),

    isStoppableManualMessage: and('isLive', 'isManualMessage', 'isInApp', 'hasNoPushNotifications'),

    // Set by the editor during composer upload.
    // Exposed as a computed property for validation purposes
    isUploading: bool('_isUploading'),
    isNotUploading: not('isUploading'),

    updateTaggingUrl: '/ember/content_service/contents/update_tags',

    analyticsData: computed(
      'variations',
      'selectionState.userAddedPredicatesAsJSON',
      'selectionState.hasEmailDisengagementPredicate',
      'hasDeliveryWindow',
      'controlGroup',
      function () {
        return {
          id: this.id,
          object: 'message',
          has_message_delivery_window: this.hasDeliveryWindow,
          has_enabled_exclude_disengaged_users: this.get(
            'selectionState.hasEmailDisengagementPredicate',
          ),
          predicates: this.get('selectionState.userAddedPredicatesAsJSON'),
          variations: this.variations.map((variation) => variation.get('analyticsData')),
          has_control_group: this.hasControlGroup,
          control_group: this.hasControlGroup ? this.get('controlGroup.analyticsData') : null,
          class_type: this.classType,
          has_nested_logical_predicates: hasNestedPredicates(this.get('selectionState.predicates')),
        };
      },
    ),

    redirectRulesetId: attr('string'),
    redirectContentType: attr('string'),

    shouldRedirectToContentObject: and('redirectRulesetId', 'redirectContentType'),

    implicitPredicates() {
      return this.selectionState.implicitPredicatesForMessage(this);
    },

    getDefaultValues() {
      let defaults = MESSAGE_DEFAULTS;

      let template = this.store.peekRecord(
        'email-template',
        this.get('app.default_email_template_id'),
      );
      defaults.variationA.emailTemplate = template;
      defaults.variationB.emailTemplate = template;

      return defaults;
    },

    activate() {
      // Deprecated noop
    },

    deactivate() {
      // Deprecated noop
    },

    duplicate(options) {
      // Deprecated noop
    },

    isLocked() {
      throw new Error('isLocked message not yet implemented');
    },

    schedule(options) {
      // Deprecated noop
    },

    removeSchedule() {
      // Deprecated noop
    },

    move(options) {
      // Deprecated noop
    },

    startABTest() {
      // Deprecated noop
    },

    createControlGroup() {
      // Deprecated noop
    },

    changeSubclassType(params) {
      // Deprecated noop
    },

    fetchServerRecipientCount() {
      return this._get('/ember/messages/recipient_count').then((response) => {
        this.set('lastRecipientCount', response.count);
        return response.count;
      });
    },

    fetchAudiencePreview() {
      return this.fetchMessageAudiencePreviewRequest().then((response) => {
        let store = this.store;
        let normalized = store.normalize('messages/audience-preview', {
          users: response.users,
          count: response.total_count,
          limited: response.limited,
        }).data.attributes;
        let audiencePreview = store.createFragment('messages/audience-preview', normalized);
        this.set('audiencePreview', audiencePreview);
      });
    },

    fetchMessageAudiencePreviewRequest() {
      let params = {
        app_id: this.appId,
        predicates: this.implicitPredicates(),
        page: 1,
        per_page: REQUIRED_USERS_FOR_UI,
        include_count: true,
      };
      return this._post('/ember/users/search.json', params);
    },

    _post(url, additionalData = {}) {
      return this._request(url, 'POST', additionalData);
    },
    _get(url, additionalData = {}) {
      return this._request(url, 'GET', additionalData);
    },
    _request(url, requestType = 'POST', additionalData = {}) {
      let data = Object.assign(additionalData, {
        id: this.id,
        app_id: this.appId,
        admin_id: this.get('app.currentAdmin.id'),
      });
      if (requestType === 'POST') {
        data = JSON.stringify(data);
      }
      return nonconcurrentAjax(this, {
        url,
        type: requestType,
        data,
      });
    },
  },
).reopenClass({
  getAssignToId(app, admin) {
    if (app.get('inboxIsNotActive')) {
      return UNASSIGNED_ASSIGNEE_ID;
    }

    if (app.get('requiresInboxSeatAccess') && !admin.get('hasInboxAccess')) {
      return UNASSIGNED_ASSIGNEE_ID;
    }

    return admin.get('id');
  },
  CREATED_VIA_EDITOR: 'editor',
  CREATED_VIA_EDITOR_VERSION: 3,
  CREATED_VIA_SIMPLE_COMPOSER: 'simple_composer',
  CREATED_VIA_SIMPLE_COMPOSER_VERSION: 2,
  CREATED_VIA_SIMPLE_COMPOSER_WITH_ONE_RECIPIENT: 'simple_composer_with_single_recipient',
  CREATED_VIA_VISITOR_LIST: 'visitor_list',
});
export { PRIORITIZED_STATES };
