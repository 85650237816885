/* RESPONSIBLE TEAM: team-growth-opportunities */

import confetti from 'canvas-confetti';
import { type CreateTypes as CanvasConfettiObject } from 'canvas-confetti';

// The success animation will be triggered when the user has successfully
// completed the checkout flow. This animation will be triggered in either
// the old onboarding-home or the guide-library.

export default {
  simpleConfettiShoot(confettiObj: CanvasConfettiObject) {
    confettiObj({
      particleCount: 700,
      startVelocity: 50,
      ticks: 800,
      gravity: 1,
      scalar: 1.3,
      angle: 45,
      spread: 3600,
      origin: { y: 0.3 },
    });
  },

  fireConfettiAnimation(): void {
    let confettiCanvas: HTMLCanvasElement | null = document.querySelector(
      '.conversion-success-confetti-canvas',
    );

    if (!confettiCanvas) {
      return;
    }

    confettiCanvas.style.display = 'block';
    let confettiObj = confetti.create(confettiCanvas, {
      resize: true,
    });

    this.simpleConfettiShoot(confettiObj);

    setTimeout(() => {
      confetti.reset();
      if (confettiCanvas) {
        confettiCanvas.style.display = 'none';
      }
    }, 5000);
  },

  showSuccesAnimation(): void {
    setTimeout(() => {
      this.fireConfettiAnimation();
    }, 100);
  },
};
