/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Fragment from 'ember-data-model-fragments/fragment';
import { fragment } from 'ember-data-model-fragments/attributes';
import { attr } from '@ember-data/model';
import type OutboundContent from './outbound-content';
import type LayoutOptions from './layout-options';
import type CollectionCardOptions from './collection-card-options';
import { CollectionCardImageType } from './collection-card-options';
import { inject as service } from '@ember/service';
import type HelpCenterSite from '../help-center-site';
import type GlobalOptions from './global-options';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type ContentBlockOptions from './content-block-options';
import { DEFAULT_CONTENT_BLOCK_OPTIONS } from './content-block-options';

const DEFAULT_FOOTER_CUSTOMIZATION_OPTIONS = {
  backgroundColor: '#ffffff',
  fontColor: '#909aa5',
};
const DEFAULT_BODY_CUSTOMIZATION_OPTIONS = {
  backgroundColor: '#ffffff',
};
const IMAGE_PLACEHOLDER_URL = assetUrl('/assets/images/articles/no-image-placeholder.jpg');
export default class HelpCenterSiteCustomizationOptions extends Fragment {
  @service declare appService: any;

  @attr('number') declare customizationType: number;
  @fragment('customization-options/outbound-content') declare header: OutboundContent;
  @fragment('customization-options/outbound-content') declare body: OutboundContent;
  @fragment('customization-options/outbound-content') declare footer: OutboundContent;
  @fragment('customization-options/layout-options') declare layout: LayoutOptions;
  @fragment('customization-options/collection-card-options')
  declare collectionCard: CollectionCardOptions;
  @fragment('customization-options/global-options') declare global: GlobalOptions;
  @fragment('customization-options/content-block-options')
  declare contentBlock: ContentBlockOptions;

  cardOptionsSnapshots: Record<string, any> = {};

  setDefaultValues(site: HelpCenterSite) {
    if (!this.footer || !this.footer.backgroundColor || !this.footer.fontColor) {
      this.footer = this.store.createFragment(
        'customization-options/outbound-content',
        DEFAULT_FOOTER_CUSTOMIZATION_OPTIONS,
      );
    }

    if (!this.collectionCard?.global?.fontColor) {
      this.collectionCard = this.store.createFragment(
        'customization-options/collection-card-options',
        {
          global: {
            fontColor: site.themeColor,
          },
        },
      );
    }

    this.collectionCard?.setDefaultValues();

    if (!this.body || !this.body.backgroundColor) {
      this.body = this.store.createFragment(
        'customization-options/outbound-content',
        DEFAULT_BODY_CUSTOMIZATION_OPTIONS,
      );
    }

    if (!this.global) {
      this.global = this.store.createFragment('customization-options/global-options', {
        font: {
          primary: null,
          secondary: null,
        },
      });
    }

    this.global.setDefaultValues();

    if (!this.contentBlock) {
      this.contentBlock = this.store.createFragment('customization-options/content-block-options', {
        ...DEFAULT_CONTENT_BLOCK_OPTIONS,
        blockStyle: {
          backgroundColor: this.body.backgroundColor,
          fontColor: this.body.fontColor,
        },
      });
    } else {
      this.contentBlock.initialize(this.body);
    }
  }

  collectionCardPreview(customizationOptionsParser: (option?: OutboundContent) => object) {
    let globalOptions = this.collectionCard?.global;
    let showIcons = globalOptions?.showIcons;
    let collectionCardOptions = this.collectionCard?.collections ?? [];

    let defaultBackgroundImageUrl =
      this.collectionCard?.global.imageType === CollectionCardImageType.Images
        ? globalOptions?.backgroundImageUrl
        : null;

    return {
      global: {
        showIcons: showIcons === null ? true : showIcons,
        backgroundColor: globalOptions?.backgroundColor,
        backgroundGradient: globalOptions?.backgroundGradient?.serialize(),
        backgroundImageUrl: globalOptions?.backgroundImageUrl ?? this.imagePlaceholderUrl(),
        fontColor: globalOptions?.fontColor,
        fadeToEdge: globalOptions?.fadeToEdge,
      },
      collections: collectionCardOptions.map((collection) => ({
        collectionId: collection.collectionId,
        outboundContent: {
          ...customizationOptionsParser(collection.outboundContent),
          ...(!collection.outboundContent.backgroundImageUrl && {
            backgroundImageUrl: defaultBackgroundImageUrl ?? this.imagePlaceholderUrl(),
          }),
        },
      })),
    };
  }

  private imagePlaceholderUrl() {
    let globalCollectionImageType = this.collectionCard?.global.imageType;
    if (
      !globalCollectionImageType ||
      globalCollectionImageType !== CollectionCardImageType.Images
    ) {
      return null;
    }

    return IMAGE_PLACEHOLDER_URL;
  }
}
