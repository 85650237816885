/* RESPONSIBLE TEAM: team-help-desk-experience */
export interface ExternalPlanWireFormat {
  id: string;
  name: string;
}

export default class ExternalPlan {
  constructor(
    public id: string,
    public name: string,
  ) {}

  static deserialize(data: ExternalPlanWireFormat): ExternalPlan {
    return new ExternalPlan(data.id, data.name);
  }
}
