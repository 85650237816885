/* RESPONSIBLE TEAM: team-help-desk-experience */
export enum InboxType {
  All = 'all',
  Unassigned = 'unassigned',
  Mentions = 'mentions',
  CreatedByYou = 'created_by_you',
  Admin = 'admin',
  Team = 'team',
  View = 'view',
  Spam = 'spam',
}
