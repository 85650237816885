/* RESPONSIBLE TEAM: team-workflows */
import { BaseConfig } from '@intercom/embercom-prosemirror-composer';
import { type TextDirection } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';

export default class ComposerConfig extends BaseConfig {
  autoFocus = true;
  allowedBlocks = ['paragraph', 'audioFile'];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  textDirection: TextDirection = 'auto';
  tools = [{ name: 'text-formatter' }, { name: 'template-inserter' }, { name: 'fallback-editor' }];
  upload = {
    onUploadStart: null,
    onUploadFinish: null,
    allowedAttachmentTypes: ['*'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };
  templating: { picker: string; resolver: AttributeInfoResolver };
  attributes: any;

  constructor({ app, placeholder }: { app: any; placeholder: string }) {
    super();
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
    this.placeholder = placeholder;
    this.attributes = app.allowedAttributes;
    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes: false,
      }),
    };
  }
}
