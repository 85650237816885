/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardPanelHeaderConfiguration from '../standard-panel-header-configuration';
import { and, readOnly, reads, union, filter } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default StandardPanelHeaderConfiguration.extend({
  contentEditorService: service(),
  _validations: readOnly('contentEditorService.ruleset.validations'),
  _scheduledActivationErrors: readOnly('_validations.attrs.scheduledActivation.errors'),
  _scheduledDeactivationErrors: readOnly('_validations.attrs.scheduledDeactivation.errors'),
  _activationIsBeforeDeactivationErrors: readOnly(
    '_validations.attrs.activationIsBeforeDeactivation.errors',
  ),
  _recurringScheduleErrors: filter(
    '_validations.attrs.recurringSchedule.errors',
    (error) => error.type === 'calendar-based-schedule-offsets',
  ),

  _matchSpacingPeriodErrors: reads('_validations.attrs.matchSpacingPeriod.errors'),
  _maxMatchCountErrors: reads('_validations.attrs.maxMatchCount.errors'),

  errors: union(
    '_scheduledActivationErrors',
    '_scheduledDeactivationErrors',
    '_activationIsBeforeDeactivationErrors',
    '_recurringScheduleErrors',
    '_matchSpacingPeriodErrors',
    '_maxMatchCountErrors',
  ),
  isComplete: and(
    '_validations.attrs.scheduledActivation.isValid',
    '_validations.attrs.scheduledDeactivation.isValid',
    '_validations.attrs.activationIsBeforeDeactivation.isValid',
    '_validations.attrs.recurringSchedule.isValid',
    '_validations.attrs.matchSpacingPeriod.isValid',
    '_validations.attrs.maxMatchCount.isValid',
  ),

  ruleset: readOnly('contentEditorService.ruleset'),
  humanReadableObjectName: readOnly('ruleset.rulesetLinks.firstObject.humanReadableObjectName'),

  init(inputs) {
    inputs.component = 'content-editor/panels/schedule/header';
    inputs.title = inputs.title || `Scheduling`;
    inputs.verb = inputs.verb || `send`;
    this._super(...arguments);
  },
});
