/* RESPONSIBLE TEAM: team-help-desk-experience */
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';
import type Inbox from './inbox';
import { INBOX_DEFAULT_SORT_ORDER } from './inbox';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

export type AdminInboxWireFormat = {
  inbox_type: InboxType.Admin;
  count: number;
  sort_order: number;
  admin_summary: AdminSummaryWireFormat;
};

export default class Admin implements Inbox {
  readonly category = InboxCategory.Admin;
  readonly type = InboxType.Admin;
  readonly sortOrder?: number;
  readonly count?: number;

  adminSummary: AdminSummary;

  constructor(count: number, adminSummary: AdminSummary, sortOrder?: number) {
    this.count = count;
    this.adminSummary = adminSummary;
    this.sortOrder = sortOrder ?? INBOX_DEFAULT_SORT_ORDER;
  }

  get name() {
    return this.adminSummary.name;
  }

  get id() {
    return this.adminSummary.id.toString();
  }

  get avatar() {
    return this.adminSummary.imageURL;
  }

  get deleted() {
    return this.adminSummary.removed;
  }

  static deserialize(json: AdminInboxWireFormat): Admin {
    return new Admin(json.count, AdminSummary.deserialize(json.admin_summary), json.sort_order);
  }
}
