/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr } from '@ember-data/model';

export default class TemporrayExpectations extends Model {
  @attr('boolean') enabled;
  @attr('string') message;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  refresh() {
    this.rollbackAttributes();
  }
}
