/* RESPONSIBLE TEAM: team-data-interop */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class AppSettings extends Model {
  @service appService;
  @attr('boolean') isEnabled;
  @attr() intercomTicketTypes;
  @attr() jiraTicketTypes;
  @attr() intercomJiraTicketTypeMappings;
  @attr() jiraProjects;
  @attr('boolean') syncComments;
  @attr() automationType;

  get intercomTicketTypesArray() {
    return this.intercomTicketTypes.map((ticketType) => {
      return {
        value: ticketType.id,
        text: ticketType.name,
      };
    });
  }

  get jiraProjectsArray() {
    return this.jiraProjects.map((project) => {
      return {
        value: project.id,
        text: project.name,
      };
    });
  }
}
