/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

let UNITS = {
  k: 1000,
  m: 1000000,
};

export default Fragment.extend({
  intl: service(),

  startedAt: attr('date'),
  priceSet: attr('string'),
  isOnTprPricingModel: attr(),
  invoiceDayOfMonth: attr(),
  isSalesforceContracted: attr(),
  endDate: attr('date'),
  isAnnualContractPrimarySubscription: attr('boolean'),
  isAnnualContractSecondarySubscription: attr('boolean'),
  primarySubscriptionAppIdCode: attr(),
  primarySubscriptionAppName: attr(),
  numberOfSecondarySubscriptions: attr(),
  billingPeriodDurationInMonths: attr('number'),
  termStartDate: attr('date'),
  initialInvoiceMonthOfYear: attr(),

  formattedStartedAt: computed('startedAt', function () {
    let startedAt = this.startedAt;

    if (!startedAt) {
      return null;
    }

    return moment(startedAt).format('LL');
  }),

  priceSetLowerBound: computed('priceSet', function () {
    let priceSet = this.priceSet;

    if (/up-to-1k/.test(priceSet)) {
      return 0;
    }

    let priceSetParts = priceSet.split('--');

    if (!priceSetParts[1]) {
      return null;
    }

    let lowerBoundString = priceSetParts[0];
    let lowerBoundStringChars = lowerBoundString.split('');
    let unit = lowerBoundStringChars.splice(-1, 1)[0];
    let number = parseInt(lowerBoundStringChars.join(''), 10);

    return number * UNITS[unit];
  }),

  isBilledAnnually: equal('billingPeriodDurationInMonths', 12),
  isBilledMonthly: equal('billingPeriodDurationInMonths', 1),

  ordinalInvoiceDayOfMonth: computed('invoiceDayOfMonth', function () {
    return moment.localeData().ordinal(this.invoiceDayOfMonth);
  }),

  billingCadence: computed('intl.locale', 'billingPeriodDurationInMonths', function () {
    switch (this.billingPeriodDurationInMonths) {
      case 1:
        return this.intl.t('app.models.subscription.billing-cadences.monthly');
      case 3:
        return this.intl.t('app.models.subscription.billing-cadences.quarterly');
      case 12:
        return this.intl.t('app.models.subscription.billing-cadences.annually');
      default:
        return '';
    }
  }),

  nextMonthlyInvoiceDate: computed('invoiceDayOfMonth', function () {
    let todaysDate = moment();
    let lastDayOfMonth = todaysDate.clone().endOf('month').date();

    let nextInvoiceDayOfMonth =
      this.invoiceDayOfMonth > lastDayOfMonth ? lastDayOfMonth : this.invoiceDayOfMonth;

    let nextInvoiceDate = moment().set('date', nextInvoiceDayOfMonth);

    if (nextInvoiceDate.isBefore(todaysDate, 'day')) {
      nextInvoiceDate.add(1, 'month');
    }

    return nextInvoiceDate;
  }),

  currentCycleDate: computed(
    'invoiceDayOfMonth',
    'isBilledAnnually',
    'initialInvoiceMonthOfYear',
    function () {
      let billDate = moment().set('date', this.invoiceDayOfMonth);
      let toDate = moment().set('date', this.invoiceDayOfMonth);
      let fromDate = moment().set('date', this.invoiceDayOfMonth);
      let duration = 'month';
      if (this.isBilledAnnually) {
        // moment months are 0 indexed, but on the backend it is 1 indexed
        let monthOfYear = this.initialInvoiceMonthOfYear - 1;
        billDate.set('month', monthOfYear);
        toDate.set('month', monthOfYear);
        fromDate.set('month', monthOfYear);
        duration = 'year';
      }
      // If bill date is in the future, fromDate should be last year/month
      if (billDate > moment()) {
        fromDate.subtract(1, duration);
      } else {
        toDate.add(1, duration);
      }

      return {
        from: fromDate,
        to: toDate,
      };
    },
  ),

  formattedCurrentCycleDate: computed('currentCycleDate.{from,to}', function () {
    let dateFormatOptions = {
      format: 'D MMMM YYYY',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    return {
      from: this.intl.formatDate(this.currentCycleDate.from, dateFormatOptions),
      to: this.intl.formatDate(this.currentCycleDate.to, dateFormatOptions),
    };
  }),
});
