/* RESPONSIBLE TEAM: team-phone */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type RenderableData } from '../renderable-data';

interface TransferCallWireFormat {
  renderable_type: RenderableType;
  admin_name: string;
  action: string;
}

export default class TransferCall implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.TransferCall;
  readonly adminName: string;
  readonly action: string;

  get creatingEntity() {
    return { type: EntityType.Bot, id: -1 };
  }

  constructor(admin_name: string, action: string) {
    this.adminName = admin_name;
    this.action = action;
  }

  static deserialize(json: TransferCallWireFormat): TransferCall {
    return new TransferCall(json.admin_name, json.action);
  }
}
