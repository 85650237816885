/* RESPONSIBLE TEAM: team-help-desk-experience */
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import Admin, { type AdminInboxWireFormat } from './admin';
import All, { type AllInboxWireFormat } from './all';
import Mentions, { type MentionsInboxWireFormat } from './mentions';
import CreatedByYou, { type CreatedByYouInboxWireFormat } from './created-by-you';
import Spam, { type SpamInboxWireFormat } from './spam';
import Team, { type TeamInboxWireFormat } from './team';
import Unassigned, { type UnassignedInboxWireFormat } from './unassigned';
import View, { type ViewInboxWireFormat } from './view';
import type AdminSummary from '../admin-summary';
import type TeamSummary from '../team-summary';
import { type CountersUpdatedEvent } from 'embercom/services/nexus';
import { type Icon } from '../command-k/icons';
import { type PinnedFoldersWireFormat } from 'embercom/objects/inbox/pinned-folder';

export type InboxWireFormat =
  | AllInboxWireFormat
  | UnassignedInboxWireFormat
  | AdminInboxWireFormat
  | TeamInboxWireFormat
  | ViewInboxWireFormat
  | MentionsInboxWireFormat
  | CreatedByYouInboxWireFormat
  | SpamInboxWireFormat;

export interface InboxesMetaTotalsWireFormat {
  admins: number;
  views: number;
  teams: number;
}

export interface InboxesMetaWireFormat {
  totals: InboxesMetaTotalsWireFormat;
}

export interface InboxesWireFormat {
  folders: PinnedFoldersWireFormat[];
  inboxes: InboxWireFormat[];
  meta: InboxesMetaWireFormat;
}

// We want to push inboxes without a sort order (or those with a sort order of
// `null`) to the bottom so we set the default to Infinity.
export const INBOX_DEFAULT_SORT_ORDER = Infinity;

export default interface Inbox {
  id: string;
  count?: number;
  name: string;
  translationKey?: string;
  icon?: Icon;
  category: InboxCategory;
  type: InboxType;
  adminSummary?: AdminSummary;
  teamSummary?: TeamSummary;
}

export interface InboxIdentifier {
  id?: string;
  type: InboxType;
}

export function isSameInbox(a?: InboxIdentifier, b?: InboxIdentifier) {
  if (!a || !b) {
    return false;
  }

  return a.type === b.type && a.id === b.id;
}

export function deserialize(json: InboxWireFormat): Inbox {
  switch (json.inbox_type) {
    case InboxType.All:
      return All.deserialize(json);
    case InboxType.Unassigned:
      return Unassigned.deserialize(json);
    case InboxType.Mentions:
      return Mentions.deserialize(json);
    case InboxType.CreatedByYou:
      return CreatedByYou.deserialize(json);
    case InboxType.Admin:
      return Admin.deserialize(json);
    case InboxType.Team:
      return Team.deserialize(json);
    case InboxType.View:
      return View.deserialize(json);
    case InboxType.Spam:
      return Spam.deserialize(json);
    default: {
      let exhaustive: any = json;
      throw new Error(`Unknown inbox type ${exhaustive.inbox_type}`);
    }
  }
}

export function parseUpdatedCount(
  inboxes: Inbox[],
  data: CountersUpdatedEvent['eventData'],
): (number | undefined)[] {
  let counters = data.counters.reduce(
    (memo, currentValue) => {
      let [inboxID, count]: [string | number, number] = currentValue;
      memo[inboxID.toString()] = count;
      return memo;
    },
    {} as { [key: string]: number },
  );
  return inboxes.map((inbox) =>
    extractCount(inbox, counters, data.treat_missing_assignees_as_zero),
  );
}

function extractCount(
  inbox: Inbox,
  counters: { [key: string]: number },
  treatMissingAssigneesAsZero: boolean | null,
): number | undefined {
  if (inbox instanceof View) {
    let count = counters[`view:${inbox.id}`];
    if (count !== undefined) {
      return count;
    }
  } else if (inbox instanceof Admin || inbox instanceof Team) {
    let count = counters[inbox.id.toString()];
    if (count !== undefined) {
      return count;
    } else if (treatMissingAssigneesAsZero) {
      return 0;
    }
  } else {
    let count = counters[inbox.id.toString()];
    if (count !== undefined) {
      return count;
    }
  }
  return undefined;
}

export function getInboxType(category: InboxCategory, id: string) {
  switch (category) {
    case InboxCategory.Admin:
      return InboxType.Admin;

    case InboxCategory.Team:
      return InboxType.Team;

    case InboxCategory.View:
      return InboxType.View;

    case InboxCategory.Shared:
      switch (id) {
        case 'all':
          return InboxType.All;
        case 'unassigned':
          return InboxType.Unassigned;
        case 'mentions':
          return InboxType.Mentions;
        case 'created_by_you':
          return InboxType.CreatedByYou;
        case 'spam':
          return InboxType.Spam;
      }
  }

  return undefined;
}
