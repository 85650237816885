/* RESPONSIBLE TEAM: team-proactive-support */
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { filterBy } from '@ember/object/computed';

export default Fragment.extend({
  clearbitAttributes: fragmentArray('appstore/app-package/clearbit/attribute'),
  selectedAttributes: filterBy('clearbitAttributes', 'selected', true),
  availableAttributes: filterBy('clearbitAttributes', 'selected', false),
});
