/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class SwitchSettings extends Model {
  @attr('string') appId;
  @attr('string') phoneNumber;
  @attr('string') url;
  @attr('string') apiToken;
  @attr('boolean') phoneBasedUserAssociation;
  @fragmentArray('switch/sms-language') smsLanguages;
  @attr('string') thankYouMessageUrl;
}
