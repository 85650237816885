/* RESPONSIBLE TEAM: team-frontend-tech */
import Model, { attr } from '@ember-data/model';

export default class Brand extends Model {
  @attr('string') declare appId: string;
  @attr('string') declare name: string;
  @attr('number') declare deleted: number;
  @attr('number') declare logoId: number;
  @attr('string') declare logoUrl: string;
  @attr('number') declare senderEmailAddressSettingsId: number;
  @attr('number') declare helpCenterId: number;
  @attr('boolean') declare isDefault?: boolean;
}
