/* RESPONSIBLE TEAM: team-proactive-support */
import { and, not } from '@ember/object/computed';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';
import CustomBotPathAnalysisConfiguration from 'embercom/objects/content-editor/stats/custom-bot/path-analysis-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import CustomBotChannelSummaryConfiguration from 'embercom/objects/content-editor/summaries/channel/custom-bot/custom-bot-channel-summary-configuration';
import CustomBotContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/custom-bot/summary-configuration';
import {
  StandardAudienceSummaryConfiguration,
  StandardGoalSummaryConfiguration,
  StandardScheduleSummaryConfiguration,
} from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import CustomBotPathAnalysisTabConfiguration from 'embercom/objects/content-editor/tabs/custom-bot/path-analysis-tab-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';

export default function generateViewModeConfig({ container, customBot }) {
  let intl = container.lookup('service:intl');

  let viewModeConfig = ViewModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesViewModeHeaderConfiguration.create({
      container,
      moreDropdownConfig: MoreDropdownConfiguration.create({
        hideDuplicateOption: true,
        hideCsvExportOption: false,
        hideTagOption: true,
        hideDeleteOption: true,
        hideVersionsOption: true,
        container,
      }),
    }),
    headerComponentConfigs: [
      StandardViewModeHeaderConfiguration.create({
        container,
        moreDropdownConfig: MoreDropdownConfiguration.create({
          duplicateModalComponent: 'custom-bots/duplicate-modal',
          hideCsvExportOption: true,
          hideTagOption: false,
          container,
        }),
        previewConfig: ComponentConfiguration.create({
          container,
          component: 'content-editor/headers/workflows/preview-button',
          analyticEvent: {
            place: 'custom_bots',
          },
        }),
      }),
    ],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: 'Workflow',
          icon: 'operator',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('operator.custom-bot.stats.sent'),
          view: 'receipts',
          tooltip: {
            content: intl.t('operator.custom-bot.stats.sent-tooltip'),
          },
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('operator.custom-bot.stats.engaged'),
          view: 'replies',
          tooltip: {
            content: intl.t('operator.custom-bot.stats.engaged-tooltip'),
          },
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('operator.custom-bot.stats.completed'),
          view: 'completions',
          tooltip: {
            content: intl.t('operator.custom-bot.stats.completed-tooltip'),
          },
          container,
        }),
        CustomBotPathAnalysisTabConfiguration.create({
          label: intl.t('operator.custom-bot.path-analysis.tab-title'),
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        CustomBotContentSummaryConfiguration.create({ title: ' ', container }),
        StandardAudienceSummaryConfiguration.create({
          title: intl.t('operator.custom-bot.editor.rules-panel.title'),
          hideMatchBehavior: true,
          allowedUserRoles: customBot.allowedUserRoles,
          container,
        }),
        CustomBotChannelSummaryConfiguration.create({
          title: intl.t('operator.custom-bot.editor.channel-panel.title'),
          container,
        }),
        StandardScheduleSummaryConfiguration.create({
          title: intl.t('operator.custom-bot.frequency-scheduling'),
          container,
        }),
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          noun: intl.t('operator.custom-bot.stats.send-noun'),
          verb: intl.t('operator.custom-bot.stats.received-verb'),
          container,
        }),
      ],
      replies: [
        StandardStatListConfiguration.create({
          stat: 'reply',
          hideCreatedVia: true,
          noun: intl.t('operator.custom-bot.stats.engagements-noun'),
          verb: intl.t('operator.custom-bot.stats.engaged-verb'),
          container,
        }),
      ],
      completions: [
        StandardStatListConfiguration.create({
          stat: 'completion',
          hideCreatedVia: true,
          container,
        }),
      ],
      pathAnalysis: [
        CustomBotPathAnalysisConfiguration.create({
          container,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });

  viewModeConfig.tabsConfiguration.tabComponentConfigs.splice(
    4,
    0,
    StandardStatTabConfiguration.extend({
      _isNotDraft: not('contentEditorService.ruleset.isDraft'),
      isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
    }).create({
      label: intl.t('operator.custom-bot.goal-tab.label'),
      view: 'goals',
      container,
    }),
  );

  viewModeConfig.activeViewConfiguration.content.splice(
    3,
    0,
    StandardGoalSummaryConfiguration.create({ container }),
  );

  viewModeConfig.activeViewConfiguration.goals = [
    StandardStatListConfiguration.create({
      filters: [
        { text: intl.t('operator.custom-bot.goal-tab.hit-after-engaging'), value: stats.reply },
        {
          text: intl.t('operator.custom-bot.goal-tab.hit-after-completing'),
          value: stats.completion,
        },
      ],
      stat: 'goalSuccess',
      container,
    }),
  ];

  return viewModeConfig;
}
