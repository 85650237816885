/* RESPONSIBLE TEAM: team-workflows */
import { fragment } from 'ember-data-model-fragments/attributes';
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { ALL_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';
import { buildValidations, validator } from 'ember-cp-validations';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

const Validations = buildValidations({
  action: [
    validator('presence', true),
    validator('rule-action-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
    validator('inline', {
      validate(_value: unknown, _options: unknown, model: SetTicketState) {
        return model.intl.t(
          'operator.workflows.visual-builder.validations.set-ticket-category-not-supported',
        );
      },
      dependentKeys: ['model.appService.app.canUseTicketCustomStatesInWorkflowsAndViews'],
    }),
  ],
});

export default class SetTicketState extends Step.extend(Validations) {
  @fragment('rules/rule-action') declare action: any;
  @service declare intl: IntlService;

  supportedChannels = ALL_CHANNELS;

  static get supportedContexts() {
    return [
      TargetContext.CustomerTicket,
      TargetContext.BackOfficeTicket,
      TargetContext.TrackerTicket,
    ];
  }

  static createNewStep(store: Store): SetTicketState {
    return store.createRecord('operator/visual-builder/step/set-ticket-state', {
      action: store.createFragment('rules/rule-action', {
        type: 'set-ticket-state',
        justAdded: true,
        actionData: {},
      }),
      type: 'operator/visual-builder/step/set-ticket-state',
    });
  }
}
