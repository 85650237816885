/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Model, { attr } from '@ember-data/model';
import type Admin from '../admin';

export default class ArticleContentReviewEntry extends Model {
  @attr() declare authorId: number;
  @attr() declare entryType: string;
  @attr('date') declare createdAt: Date;
  @attr() declare comment: any;
  @attr('date') declare nextReviewTimeSetTo: Date;

  get author(): Admin | undefined {
    return this.store.peekRecord('admin', this.authorId);
  }
}
