/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { attr } from '@ember-data/model';
import { readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { findBy, isAny } from '@intercom/pulse/lib/computed-properties';
import { characterIndex } from 'embercom/helpers/character-index';
import Validations from 'embercom/validations/workflows/path';

export default Fragment.extend(Validations, {
  _id: attr('number'),
  title: attr({ defaultValue: 'New path' }),
  index: null, //position in the customBot paths array, initialized and updated by the workflow editor
  label: computed('index', function () {
    return characterIndex([this.index]);
  }),
  steps: fragmentArray('operator/workflows/step'),
  endStateStep: findBy('steps', 'isEndStateStep'),
  labelWithSeparator: computed('label', function () {
    return `${this.label}.`;
  }),
  titleWithLabel: computed('labelWithSeparator', 'title', function () {
    return `${this.labelWithSeparator} ${this.title}`;
  }),
  hasReachedAutoFollowSoftLimit: readOnly('validations.attrs.autoFollowSoftLimit.hasWarnings'),
  isUploading: isAny('steps', 'isUploading'),
  isStartOverPath: isAny('steps', 'type', 'start_over'),

  branchingSteps: computed('steps.[]', function () {
    return this.steps.filter((step) => step.canBranch);
  }),

  renderableSteps: computed('steps.[]', function () {
    return this.steps.reject((step) => step.isEndStateStep);
  }),

  allRuleActions: computed('steps.@each.firstSimpleAction', function () {
    return this.steps.mapBy('firstSimpleAction').reject((action) => !action);
  }),

  lastStep: readOnly('steps.lastObject'),

  init() {
    this._super(...arguments);
    this.set('incomingControls', []);
  },

  ensureStepsAreLinkedCorrectly() {
    this.steps.forEach((step, index) => {
      step.get('controls').forEach((control) => {
        if (step === this.steps.lastObject && !step.branchingControls.includes(control)) {
          // we remove the last steps control, if it's not a branching step
          // eg. if teammates add a "chat message" as last step, the editor will create a control for that step, because that step goes nowhere, we need to remove that useless control.
          step.controls.removeObject(control);
        } else if (step.branchingControls.includes(control)) {
          // we update the nextStepUuid of all controls that points to a next path
          // eg. that will update each control.nextStepUuid of a reply buttons step
          control.set('nextStepUuid', control.get('nextPath.steps.firstObject.uuid'));
        } else if (this.steps.objectAt(index + 1)) {
          // if the step is not branching and not final, then that means the control needs to point to the next step (of the current path)
          // eg. typically used to link chat_message steps between each others
          control.set('nextStepUuid', this.steps.objectAt(index + 1).get('uuid'));
        }
      });
    });
  },
});
