/* RESPONSIBLE TEAM: team-workflows */
import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { createIntervalGroupsFromIntervals } from 'embercom/lib/office-hours/interval-group';
import { isEmpty } from '@ember/utils';
import { defaultQuietHours } from 'embercom/models/data/sms/constants';
import { responseProcessingBehaviours } from 'embercom/models/data/sms/constants';

export default class SmsOutboundSettings extends Model {
  @attr('string') companyInfoText;
  @attr('string') alphanumericSenderId;
  @attr('number') responseProcessingBehaviour;
  @fragmentArray('matching-system/timetable/time-interval') quietHoursTimeIntervals;
  @tracked twoWaySmsEnabled = this.enabledToDeflectToInbox;

  get startAndEndInterval() {
    let intervalGroups = createIntervalGroupsFromIntervals(this.quietHoursTimeIntervals);
    if (isEmpty(intervalGroups)) {
      return defaultQuietHours;
    }
    let startMinute = intervalGroups[intervalGroups.length - 1].startMinute;
    let endMinute = intervalGroups[0].endMinute;
    return {
      startMinute,
      endMinute,
    };
  }

  get enabledToDeflectToInbox() {
    return this.responseProcessingBehaviour === responseProcessingBehaviours.deflectToInbox;
  }
}
