/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Model, { attr, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { dependentKeyCompat } from '@ember/object/compat';

const SHORT_COLOR_HEX_CODE_REGEX = /^#[A-Fa-f0-9]{3}$/;

export default class LookAndFeelModel extends Model {
  @service appService;
  @hasMany('brand-settings', { async: false }) brands;
  @hasMany('brand-settings', { async: false }) brandSettings;
  @hasMany('brand', { async: false }) newBrands;
  @attr('boolean') widgetAudioEnabled;
  @attr('string') primaryColor;
  @attr('string') secondaryColor;
  @attr('boolean') showPoweredBy;
  @attr('string') messengerBackground;
  @attr('string') logoUrl;
  @attr() logoId;
  @attr() launcherLogoUrl;
  @attr() launcherLogoId;
  @attr('string') alignment;
  @attr('string') horizontalPadding;
  @attr('string') verticalPadding;

  // M5 Settings
  @attr('string', { defaultValue: 'colors' }) backgroundType;
  @attr('string') gradientFirstColor;
  @attr('string') gradientSecondColor;
  @attr('string') gradientThirdColor;
  @attr('string', { defaultValue: 'dark' }) textColor;
  @attr('boolean', { defaultValue: true }) fadeToWhite;
  @attr('boolean', { defaultValue: true }) showAvatars;
  @attr('string') backgroundImageUrl;
  @attr('number') backgroundImageId;
  @attr('number') helpCenterId;

  //Conversational settings
  @attr('boolean', { defaultValue: true }) showConversationBackground;
  @attr('string', { defaultValue: 'colors' }) conversationalBackgroundType;
  @attr('string') backgroundSolidColor;
  @attr('number') brandIdentityLogoId;
  @attr('string') brandIdentityLogoUrl;
  @attr('string', { defaultValue: 'ai_agent' }) conversationalMessengerIdentity;

  get primaryColorHex() {
    return this.primaryColor.slice(1);
  }
  get secondaryColorHex() {
    return this.secondaryColor.slice(1);
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      (this.hasDirtyAttributes || this.brands.any((brand) => brand.hasUnsavedChanges)) &&
      this.validPaddings
    );
  }
  get defaultBrand() {
    return this.brands.findBy('isDefault');
  }
  get defaultMobileBrand() {
    return this.brands.findBy('defaultMobileBrand');
  }
  get mobileBrand() {
    return this.brands.findBy('isMobile');
  }
  get webBrands() {
    return this.brands.filterBy('isWeb');
  }

  @tracked activeBrand = this.defaultBrand;

  get backgroundId() {
    let messengerBackground = this.activeBrand.messengerWallpaper;
    return isPresent(messengerBackground) ? messengerBackground.replace('background-', '') : null;
  }

  get previewConfigurationParams() {
    return {
      logoURL: this.logoUrl,
      backgroundPatternNumber: this.backgroundId,
      appPrimaryColor: this.primaryColor,
      appSecondaryColor: this.secondaryColor,
      showWeRunOnIntercom: this.showPoweredBy,
    };
  }

  paddingMin = 20;
  paddingMax = 32767;
  paddingMinAndroid = 16;
  iosHorizontalPadding = 20;
  androidHorizontalPadding = 16;

  get validPaddings() {
    return (
      this.paddingWithinBoundaries(this.horizontalPadding) &&
      this.paddingWithinBoundaries(this.verticalPadding)
    );
  }

  paddingWithinBoundaries(padding) {
    return Number(padding) >= this.paddingMin && Number(padding) <= this.paddingMax;
  }
  paddingWithinBoundariesAndroid(padding) {
    return Number(padding) >= this.paddingMinAndroid && Number(padding) <= this.paddingMax;
  }

  expandShortColorCode(colorProperty) {
    let color = this[colorProperty];
    if (SHORT_COLOR_HEX_CODE_REGEX.exec(color) !== null) {
      let expandedColor = color.replace(/([A-Fa-f0-9])/g, '$1$1');
      this[colorProperty] = expandedColor;
    }
  }

  expandColors() {
    this.expandShortColorCode('primaryColor');
    this.expandShortColorCode('secondaryColor');
  }

  refresh() {
    this.rollbackAttributes();
    this.brands.forEach((brand) => {
      if (brand.hasDirtyAttributes) {
        brand.rollbackAttributes();
      }
    });
    this.activeBrand = this.defaultBrand;
  }

  save() {
    return super.save().then(() => {
      this.set('appService.app.base_color', this.primaryColor);
    });
  }
}
