/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { notEmpty, equal, alias, or } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import UserAvatarData from 'embercom/models/mixins/user-avatar-data';

export default Fragment.extend(UserAvatarData, {
  displayAs: attr('string'),
  avatar: attr(),
  userId: attr(),
  email: attr('string'),

  companies: attr('array'),
  hasCompanies: notEmpty('companies'),
  mostRecentCompany: alias('companies.firstObject'),
  hasDisplayableCompany: or('mostRecentCompany.notManuallyCreated', 'mostRecentCompany.name'),
  otherCompanies: computed('companies', 'mostRecentCompany', function () {
    let mostRecentCompany = this.mostRecentCompany;
    return this.companies.filter(function (company) {
      return company && company !== mostRecentCompany;
    });
  }),

  legacyUserData: attr(),
  user: computed('legacyUserData', 'legacyUserData.id', 'store', function () {
    this.store.pushPayload({ user: this.legacyUserData });
    return this.store.peekRecord('user', this.legacyUserData.id);
  }),

  role: attr(),
  isVisitor: equal('role', 'visitor_role'),
});
