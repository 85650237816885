/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import BrandDomainValidations from 'embercom/validations/brand-domain';

export default class BrandDomain extends Model.extend(BrandDomainValidations) {
  @service appService;

  @attr('string') domain;
  @belongsTo('brand-settings') brand;

  get isValid() {
    return this.validations.isValid;
  }

  get isEmpty() {
    return this.domain === '';
  }

  get domainFormatErrorMessage() {
    if (this.isEmpty) {
      return;
    }

    if (this.validations.messages.length > 0) {
      return this.validations.messages[0];
    }
  }
}
