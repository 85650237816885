/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';
import { type WorkflowObject } from 'embercom/objects/inbox/interfaces/workflow';
import type UserSummary from 'embercom/objects/inbox/user-summary';

interface TicketStateUpdatedWireFormat {
  renderable_type: RenderableType;
  entity_type: EntityType;
  entity: AdminSummaryWireFormat | WorkflowObject;
  ticket_state: string;
  admin_only: boolean;
  ticket_type_id?: number;
}

export default class TicketStateUpdated implements RenderableData {
  renderableType: RenderableType = RenderableType.TicketStateUpdated;
  readonly entityType: EntityType;
  readonly entity: UserSummary | AdminSummary | WorkflowObject | undefined;
  readonly ticketState: string;
  readonly adminOnly: boolean;
  readonly ticketTypeId?: number;

  get creatingEntity() {
    return { type: this.entityType, id: this.entity?.id || -1, object: this.entity };
  }

  constructor(
    entity: AdminSummary | WorkflowObject | undefined,
    entity_type: EntityType,
    ticketState: string,
    adminOnly = true,
    ticketTypeId?: number,
  ) {
    this.entity = entity;
    this.entityType = entity_type;
    this.ticketState = ticketState;
    this.adminOnly = adminOnly;
    this.ticketTypeId = ticketTypeId;
  }

  static deserialize(json: TicketStateUpdatedWireFormat): TicketStateUpdated {
    let entity = undefined;
    switch (json.entity_type) {
      case EntityType.Admin: {
        entity = AdminSummary.deserialize(json.entity as AdminSummaryWireFormat);
        break;
      }
      case EntityType.Workflow: {
        entity = json.entity as WorkflowObject;
        break;
      }
    }
    return new TicketStateUpdated(
      entity,
      json.entity_type,
      json.ticket_state,
      json?.admin_only,
      json?.ticket_type_id,
    );
  }

  get styles() {
    return {
      backgroundColor: 'bg-sky dark:bg-dm-blue-dark',
      textColor: 'text-blue-darkest dark:text-dm-white',
      backgroundColorHover: 'bg-sky-dark dark:bg-blue-darkest',
    };
  }
}
