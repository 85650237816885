/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import { defineProperty } from '@ember/object';
import { equal, not, or } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
import { classify } from '@ember/string';
import { selectFromObject, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { VALID_STATES } from 'embercom/models/data/messages/message-constants';

const STAMP_STYLES = {
  Live: 'green',
  Paused: 'gray',
  Scheduled: 'yellow-light',
  Draft: 'gray',
  Ended: 'gray',
  Emptying: 'yellow-light',
  Stopped: 'gray',
};

export default Mixin.create({
  initializeComputedProperties: on('init', function () {
    VALID_STATES.forEach((state) => {
      let pascalCaseStateName = classify(state);
      defineProperty(this, `is${pascalCaseStateName}`, equal('state', state));
      defineProperty(this, `isNot${pascalCaseStateName}`, not(`is${pascalCaseStateName}`));
    });
  }),

  _autoMessageStateNames: {
    live: 'Live',
    stopped: 'Paused',
    scheduled: 'Scheduled',
    draft: 'Draft',
    ended: 'Ended',
    draining: 'Emptying',
  },
  _manualMessageStateNames: {
    live: 'Live',
    stopped: 'Stopped',
    scheduled: 'Scheduled',
    draft: 'Draft',
    new: 'Draft',
  },
  _stateNames: ternaryToProperty(
    'isManualMessage',
    '_manualMessageStateNames',
    '_autoMessageStateNames',
  ),
  uiStateName: selectFromObject('_stateNames', 'state'),

  stampColor: computed('uiStateName', function () {
    return STAMP_STYLES[this.uiStateName];
  }),

  isLiveOrScheduled: or('isLive', 'isScheduled'),
  isLiveOrStopped: or('isLive', 'isStopped'),
});
