/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class NewsfeedSiteModel extends Model {
  @service appService;

  @attr('string') identifier;
  @attr('string') customDomain;
  @attr('string') locale;
  @attr('string') customDomainAndPathPrefix;
  @attr('boolean') customDomainUsesSsl;
  @attr('boolean') seoIndexingEnabled;
  @attr('boolean') turnedOn;
  @attr('string') themeColor;
  @attr('string') headerUrl;
  @attr('string') faviconUrl;
  @attr('string') logoUrl;
  @attr('string') url;
  @attr('string') defaultDomain;
  @attr('string') homeUrl;
  @attr('string') googleAnalyticsTrackingId;
  @attr('string') facebookHandle;
  @attr('string') twitterHandle;
  @attr('string') linkedinHandle;
  @fragmentArray('news/supported-locale') supportedLocales;

  get app() {
    return this.appService.app;
  }

  get turnedOff() {
    return !this.turnedOn;
  }

  get defaultLocale() {
    return this.supportedLocales.find((locale) => locale.localeId === this.locale);
  }

  get name() {
    return this.defaultLocale.title;
  }

  get headline() {
    return this.defaultLocale.headline;
  }
}
