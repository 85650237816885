/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { capitalize } from '@ember/string';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import type ValueTransformation from './value-transformation';

export default class ResponseField extends Fragment {
  @attr('string') declare responsePath: string;
  @attr('string') declare rawFieldName: string;
  @attr('boolean') declare redacted: boolean;
  @attr('string') declare fieldType: string;
  @attr('string') declare humanReadableFieldName?: string;
  @attr('string') declare description: string;
  @attr('string') declare exampleValue: string;
  @attr() declare valuesMap: { [key: string | number]: any } | null;
  @fragmentArray('workflow-connector/value-transformation')
  declare valueTransformations: ValueTransformation[];

  get tooltipText() {
    return this.responsePath.split('.').map(capitalize).join(' › ').replace(/_/g, ' ');
  }

  get displayablePath() {
    let textToDisplay = this.tooltipText;
    let nestedFieldsArray = textToDisplay.split(' › ');

    let index = 0;
    while (textToDisplay.length > 40 && index < nestedFieldsArray.length - 2) {
      nestedFieldsArray[nestedFieldsArray.length - (2 + index)] = '...';
      textToDisplay = nestedFieldsArray.join(' › ');
      index++;
    }

    if (
      this.humanReadableFieldName &&
      this.humanReadableFieldName !== '' &&
      this.humanReadableFieldName !== this.rawFieldName
    ) {
      textToDisplay = textToDisplay.slice(0, -this.rawFieldName.length);
    }

    return textToDisplay;
  }

  get displayableLastTwoLevelPath() {
    let nestedFieldsArray = this.tooltipText.split(' › ');
    if (nestedFieldsArray.length === 2) {
      return nestedFieldsArray.slice(-1);
    }

    return nestedFieldsArray.slice(-2).join(' › ');
  }
}
