/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr, belongsTo } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import {
  defaultNumericQuestionBlockWithModelKey,
  defaultNPSQuestionBlockWithModelKey,
  defaultEmptyTextBlockWithModelKey,
} from 'embercom/models/data/outbound/constants';
import {
  responseTypes,
  scaleTypes,
  validationTypes,
  ratingScaleResponseTypes,
} from 'embercom/models/data/survey/constants';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { isPresent, isBlank } from '@ember/utils';
import { A } from '@ember/array';
import QuestionValidations from 'embercom/validations/question';
import generateUUID from 'embercom/lib/uuid-generator';

export default class QuestionModel extends Model.extend(QuestionValidations) {
  @service store;
  @attr('boolean') saveResponseToAttribute;
  @attr('string') attributeIdentifier;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
    defaultValue: () => [defaultEmptyTextBlockWithModelKey],
  })
  blocks;
  @belongsTo('surveys/step', { inverse: 'questions' }) step;
  @belongsTo('surveys/survey', { inverse: 'deletedQuestions' }) survey;
  @attr('number') questionType;
  @attr('string', { defaultValue: () => generateUUID() }) uuid;
  @attr('number') order;

  @fragment('surveys/question-data') data;

  setupQuestionData(scaleType) {
    this.saveResponseToAttribute = this.isCloseEndedQuestion;
    this.attributeIdentifier = undefined;
    this.data._resetData();

    if (this.isDropdown) {
      this.data.placeholder = 'Select one...';
    } else if (this.isFreeTextEntry || this.isFormInput) {
      this.data.placeholder = 'Enter text...';
    }

    if (this.isRatingScale) {
      this._setRatingScaleQuestionType(scaleType);
      this.data._setValidationType(validationTypes.number);
    }

    if (this.isMultiSelect) {
      this.data.minimumSelection = 0;
      this.data.maximumSelection = 1;
      this.data.options = A(['', '', '']);
    }
  }

  _setRatingScaleQuestionType(scaleType) {
    if (scaleType === undefined) {
      return;
    }

    this.data.type = scaleType;
    switch (scaleType) {
      case scaleTypes.numeric:
        this._setDefaultNumericData();
        break;
      case scaleTypes.nps:
        this._setDefaultNPSData();
        break;
      case scaleTypes.stars:
        this._setDefaultStarsData();
        break;
      case scaleTypes.emoji:
        this._setDefaultEmojiData();
        break;
      default:
        return;
    }
  }

  _setDefaultNPSData() {
    this.blocks = [defaultNPSQuestionBlockWithModelKey];
    this.data._setDefaultNPSData();
  }

  _setDefaultNumericData() {
    this.blocks = [defaultNumericQuestionBlockWithModelKey];
    this.data._setDefaultNumericData();
  }

  _setDefaultStarsData() {
    this.blocks = [defaultNumericQuestionBlockWithModelKey];
    this.data._setDefaultStarsData();
  }

  _setDefaultEmojiData() {
    this.blocks = [defaultNumericQuestionBlockWithModelKey];
    this.data._setDefaultEmojiData();
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.blocks.any((block) => block.hasDirtyAttributes) ||
      this.data.hasDirtyAttributes
    );
  }

  get hasAttribute() {
    return this.saveResponseToAttribute && isPresent(this.attributeIdentifier);
  }

  get isRatingScale() {
    return this.questionType === responseTypes.ratingScale;
  }

  get isEmojiScale() {
    return this.granularQuestionType === ratingScaleResponseTypes.emoji;
  }

  get isStarScale() {
    return this.granularQuestionType === ratingScaleResponseTypes.stars;
  }

  get isFreeTextEntry() {
    return this.questionType === responseTypes.text;
  }

  get isFormInput() {
    return this.questionType === responseTypes.input;
  }

  get isDropdown() {
    return this.questionType === responseTypes.dropdown;
  }

  get isMultiSelect() {
    return this.questionType === responseTypes.multiSelect;
  }

  get supportsResponseOrdering() {
    return this.isMultiSelect || this.isDropdown;
  }

  get isCloseEndedQuestion() {
    return !(this.isFreeTextEntry || this.isFormInput);
  }

  get hasInvalidAttributeState() {
    return this.saveResponseToAttribute && isBlank(this.attributeIdentifier);
  }

  get questionTitle() {
    return htmlToTextContent(this.blocks.firstObject?.text);
  }

  get isSingleMultiSelect() {
    return this.isMultiSelect && this.data.maximumSelection === 1;
  }

  get questionOrder() {
    return this.step.get('nonDeletedQuestions').toArray().indexOf(this) + 1;
  }

  get serializedBlocks() {
    return this.blocks.serialize();
  }

  get contentHash() {
    return {
      questionType: this.questionType,
      blocks: this.serializedBlocks,
      data: this.data.serialize(),
    };
  }

  get sanitizedId() {
    return this.id.split('-').pop();
  }

  get granularQuestionType() {
    return this.questionType === responseTypes.ratingScale
      ? `${this.questionType}.${this.data.type}`
      : this.questionType;
  }

  get canBeEmbedded() {
    if (this.isRatingScale || this.isSingleMultiSelect) {
      return true;
    }

    return false;
  }
}
