/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment } from 'ember-data-model-fragments/attributes';
import type Price from './price';
import type { SubscriptionType } from './subscription-details';
import type { DiscountAmount } from './invoice';

/**
 * Not from Stripe, customised via price terraforming
 */
type LineItemMetadata = {
  type: SubscriptionType;
};

type Period = {
  end: number;
  start: number;
};

type CreditedItem = {
  invoice: string;
  invoiceLineItems: string;
};

type ProrationDetails = {
  creditedItems: Array<CreditedItem>;
};

/**
 * Refer to the Stripe docs:
 * https://docs.stripe.com/api/invoices/line_item
 *
 * Not all fields are included here, only the ones we need.
 */
export default class LineItem extends Fragment {
  @attr declare object: 'line_item';
  @attr declare amount: number;
  @attr declare currency: string;
  @attr declare invoice: string | null;
  @attr declare metadata: LineItemMetadata;
  @attr declare period: Period;
  @attr declare quantity: number;
  @attr declare type: 'invoiceitem' | 'subscription';
  @attr declare discountable: boolean;
  @attr declare discountAmounts: Array<DiscountAmount>;

  // Tiered pricing share the same subscriptionItem
  @attr declare subscriptionItem: string | null;
  @attr declare amountExcludingTax: number;
  @attr declare unitAmountExcludingTax: number;
  @attr declare proration: boolean;
  @attr declare prorationDetails: ProrationDetails;
  @fragment('billing/stripe/price') declare price: Price;
  @attr declare unitAmount: number;
  @attr declare unitAmountDecimal: string;

  get netAmount() {
    return this.amount - this.totalDiscountAmount;
  }

  get totalDiscountAmount() {
    return this.discountAmounts.reduce((acc, discount) => acc + discount.amount, 0);
  }
}
