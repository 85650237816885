/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import type IntlService from 'embercom/services/intl';

export function getAssignmentRulesMessageAttributes(intl: IntlService) {
  let translationPrefix = 'matching-system.attributes.assignment-rules-message-attributes';
  return [
    {
      identifier: 'message.detected_language',
      type: 'browser_locale',
      icon: 'bot-filled',
      name: intl.t(`${translationPrefix}.detected-language.name`),
      comparison: [
        { label: 'is', value: 'eq' },
        { label: 'is not', value: 'ne' },
        { label: 'is unknown', value: 'unknown' },
        { label: 'has any value', value: 'known' },
      ],
      description: intl.t(`${translationPrefix}.detected-language.description`),
    },
    {
      name: intl.t(`${translationPrefix}.current-sentiment.name`),
      identifier: 'message.current_sentiment',
      type: 'sentiment',
      icon: 'ai',
      comparison: [
        { label: 'is', value: 'eq' },
        { label: 'is not', value: 'ne' },
        { label: 'is unknown', value: 'unknown' },
        { label: 'has any value', value: 'known' },
      ],
      available_with_feature: ['ai-sentiment-analysis'],
      description: intl.t(`${translationPrefix}.current-sentiment.description`),
    },
    {
      identifier: 'message.content',
      type: 'string',
      name: intl.t(`${translationPrefix}.content.name`),
      icon: 'message',
      comparison: [{ label: 'contains', value: 'contains', selected: false }],
      description: intl.t(`${translationPrefix}.content.description`),
    },
    {
      identifier: 'message.has_attachments',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.has-attachments.name`),
      icon: 'attachment',
      description: intl.t(`${translationPrefix}.has-attachments.description`),
    },
    {
      identifier: 'message.url',
      type: 'string',
      name: intl.t(`${translationPrefix}.url.name`),
      icon: 'message',
      comparison: [
        { label: 'is', value: 'eq', selected: false },
        { label: 'contains', value: 'contains', selected: false },
      ],
      description: intl.t(`${translationPrefix}.url.description`),
    },
    {
      identifier: 'message.subject',
      type: 'string',
      name: intl.t(`${translationPrefix}.subject.name`),
      icon: 'message',
      comparison: [{ label: 'contains', value: 'contains', selected: false }],
      description: intl.t(`${translationPrefix}.subject.description`),
    },
    {
      identifier: 'message.recipient',
      type: 'downcased_string',
      name: intl.t(`${translationPrefix}.recipient.name`),
      icon: 'message',
      comparison: [{ label: 'is', value: 'eq', selected: false }],
      description: intl.t(`${translationPrefix}.recipient.description`),
    },
    {
      identifier: 'message.direct_recipient',
      type: 'downcased_string',
      name: intl.t(`${translationPrefix}.direct-recipient.name`),
      icon: 'message',
      comparison: [{ label: 'is', value: 'eq', selected: false }],
      description: intl.t(`${translationPrefix}.direct-recipient.description`),
    },
    {
      identifier: 'message.cc_recipient',
      type: 'downcased_string',
      name: intl.t(`${translationPrefix}.cc-recipient.name`),
      icon: 'message',
      comparison: [{ label: 'is', value: 'eq', selected: false }],
      description: intl.t(`${translationPrefix}.cc-recipient.description`),
    },
    {
      identifier: 'message.channel_is_android',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-android.name`),
      icon: 'android',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      description: intl.t(`${translationPrefix}.channel-is-android.description`),
    },
    {
      identifier: 'message.channel_is_ios',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-ios.name`),
      icon: 'ios',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      description: intl.t(`${translationPrefix}.channel-is-ios.description`),
    },
    {
      identifier: 'message.channel_is_email',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-email.name`),
      icon: 'email',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      description: intl.t(`${translationPrefix}.channel-is-email.description`),
    },
    {
      identifier: 'message.channel_is_facebook',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-facebook.name`),
      icon: 'facebook',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      description: intl.t(`${translationPrefix}.channel-is-facebook.description`),
    },
    {
      identifier: 'message.channel_is_whatsapp',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-whatsapp.name`),
      icon: 'whatsapp',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      available_with_feature: ['whatsapp_integration'],
      description: intl.t(`${translationPrefix}.channel-is-whatsapp.description`),
    },
    {
      identifier: 'message.channel_is_twitter',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-twitter.name`),
      icon: 'twitter',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      description: intl.t(`${translationPrefix}.channel-is-twitter.description`),
    },
    {
      identifier: 'message.channel_is_instagram',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-instagram.name`),
      icon: 'instagram',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      description: intl.t(`${translationPrefix}.channel-is-instagram.description`),
    },
    {
      identifier: 'message.channel_is_phone_call',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-phone-call.name`),
      icon: 'phone',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      available_with_feature: ['cti-app-prototype'],
    },
    {
      identifier: 'message.channel_is_phone_switch',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-phone-switch.name`),
      icon: 'phone',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      description: intl.t(`${translationPrefix}.channel-is-phone-switch.description`),
    },
    {
      identifier: 'message.channel_is_sms',
      type: 'boolean',
      name: intl.t(`${translationPrefix}.channel-is-sms.name`),
      icon: 'ios',
      comparison: [
        { label: 'is true', value: 'true', selected: true, hideInput: true },
        { label: 'is false', value: 'false', selected: false, hideInput: true },
      ],
      available_with_feature: ['messages-sms'],
      description: intl.t(`${translationPrefix}.channel-is-sms.description`),
    },
    {
      identifier: 'message.whatsapp_integration_id',
      type: 'whatsapp_integration_id',
      icon: 'whatsapp',
      name: intl.t(`${translationPrefix}.whatsapp-integration-id.name`),
      comparison: [
        { label: 'is', value: 'eq' },
        { label: 'is not', value: 'ne' },
        { label: 'is unknown', value: 'unknown' },
        { label: 'has any value', value: 'known' },
      ],
      description: intl.t(`${translationPrefix}.whatsapp-integration-id.description`),
    },
    {
      identifier: 'message.instagram_integration_id',
      type: 'instagram_integration_id',
      icon: 'instagram',
      name: intl.t(`${translationPrefix}.instagram-integration-id.name`),
      comparison: [
        { label: 'is', value: 'eq' },
        { label: 'is not', value: 'ne' },
        { label: 'is unknown', value: 'unknown' },
        { label: 'has any value', value: 'known' },
      ],
      description: intl.t(`${translationPrefix}.instagram-integration-id.description`),
    },
  ];
}
