/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';

const DATE_FORMAT = 'MMMM D, YYYY';

export default EmberObject.extend({
  type: 'date',
  range: null,
  suggestionIcon: 'calendar',
  suggestionText: null,
  filterIcon: 'calendar',
  filterText: computed('range', function () {
    if (this.range.start.isSame(this.range.end, 'day')) {
      return this.range.start.format(DATE_FORMAT);
    } else {
      return `${this.range.start.format(DATE_FORMAT)} - ${this.range.end.format(DATE_FORMAT)}`;
    }
  }),
}).reopenClass({
  type: 'date',
  dropdownIcon: 'calendar',
});
