/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
import { get, computed } from '@ember/object';
import { isEmpty, isNone } from '@ember/utils';
import { notEmpty, filterBy, not } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import {
  findBy,
  isEvery,
  ternary,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import PredicateGroupValidations from 'embercom/validations/common/predicate-group';
import { USER_ROLE_PREDICATE, LEAD_ROLE_PREDICATE } from 'embercom/lib/default-predicates';
import { EXCLUDE_DISENGAGED_USERS_PREDICATE } from 'embercom/models/data/messages/message-defaults';

export default Fragment.extend(PredicateGroupValidations, {
  predicates: fragmentArray('common/predicate', {
    defaultValue: () => [],
  }),
  allPredicatesAreValid: isEvery('predicates', 'isValid'),
  hasPredicates: notEmpty('predicates'),

  orPredicate: findBy('predicates', 'isOrPredicate'),
  userTypePredicate: findBy('predicates', 'isUserPredicate'),
  emailDisengagementPredicate: findBy('predicates', 'isEmailDisengagementType'),
  hasEmailDisengagementPredicate: notEmpty('emailDisengagementPredicate'),
  userAddedPredicates: filterBy('basePredicates', 'isEditable'),
  hasNoUserAddedPredicates: not('hasUserAddedPredicates'),
  predicatesForSummary: computed('basePredicates', function () {
    return this.basePredicates.filter((p) => p.isEditable || p.isLogicalType);
  }),
  hasUserAddedPredicates: computed('basePredicates', function () {
    return this.basePredicates.any((p) => p.isEditable || p.isLogicalType);
  }),
  isOrMode: notEmpty('orPredicate'),
  isAndMode: not('isOrMode'),
  basePredicates: ternaryToProperty(
    'logicalPredicate',
    'logicalPredicate.predicates',
    'predicates',
  ),
  logicalPredicate: findBy('predicates', 'isLogicalType', true),
  joinType: ternary('isAndMode', 'and', 'or'),
  alternateJoinType: ternary('isOrMode', 'and', 'or'),

  switchJoinType(type) {
    if (type === 'or') {
      this.switchToOrJoinType();
    } else if (type === 'and') {
      this.switchToAndJoinType();
    }
  },

  switchToOrJoinType() {
    this._switchJoinType('or', (userAddedPredicates, userTypePredicate) => {
      let orPredicate = this.store.createFragment('common/predicate', { type: 'or' });
      orPredicate.set('predicates', userAddedPredicates);
      return this._joinedPredicatesWithNonUserAddedPredicates([orPredicate], userTypePredicate);
    });
  },

  switchToAndJoinType() {
    this._switchJoinType('and', this._joinedPredicatesWithNonUserAddedPredicates);
  },

  _switchJoinType(type, switchFunction) {
    if (type === this.alternateJoinType) {
      let userTypePredicate = this.userTypePredicate;
      let userAddedPredicates = this._copyPredicates(this.userAddedPredicates);
      Em.changeProperties(() => {
        let joinedPredicates = switchFunction(userAddedPredicates, [userTypePredicate]);
        let predicates = this.predicates;
        predicates.clear();
        predicates.addObjects(joinedPredicates);
      });
    }
  },

  // NOTE - Fragments can only belong to a single owner.
  // When switching join type we must create new models
  // instead of directly inserting existing predicates.
  _copyPredicates(predicates) {
    return isEmpty(predicates)
      ? []
      : predicates.map((predicate) =>
          this.store.createFragment('common/predicate', predicate.toJSON()),
        );
  },

  _joinedPredicatesWithNonUserAddedPredicates(predicates, nonUserAddedPredicates) {
    nonUserAddedPredicates = nonUserAddedPredicates.filter((predicate) => !isNone(predicate));
    return nonUserAddedPredicates.concat(predicates);
  },

  addJSONPredicate(predicateJSON, options = { forceTopLevelInsertion: false }) {
    this.addPredicate(this.store.createFragment('common/predicate', predicateJSON), options);
  },

  addJSONPredicateWithDefaults(predicateJSON, options = { forceTopLevelInsertion: false }) {
    let predicate = this.store.createFragment('common/predicate', predicateJSON);
    predicate.changeComparison(predicate.getDefaultComparison(options));
    predicate.changeValue(predicate.getDefaultValue(options));
    this.addPredicate(predicate, options);
  },

  addPredicate(predicate, options = { forceTopLevelInsertion: false }) {
    if (get(predicate, 'constructor.modelName') !== 'common/predicate') {
      throw new Error(`Expected object of type 'common/predicate' to be passed to addPredicate().
        If you are using raw JSON predicates, use the addJSONPredicate() function.`);
    } else {
      let predicates = options.forceTopLevelInsertion ? this.predicates : this.basePredicates;
      predicates.addObject(predicate);
    }
  },

  addPredicateFromAttribute(attribute, options = { forceTopLevelInsertion: false }) {
    let identifier = attribute.get('identifier');
    let type = attribute.get('type');
    if (attribute.get('isUserEvent')) {
      identifier += '.count';
      type += '_integer';
    }
    this.addJSONPredicateWithDefaults(
      {
        attribute: identifier,
        type,
      },
      options,
    );
  },

  removePredicate(predicate, options = { forceTopLevelRemoval: false }) {
    let predicates = options.forceTopLevelRemoval ? this.predicates : this.basePredicates;
    predicates.removeObject(predicate);
  },

  predicatesAsJSON: computed(
    'predicates.@each.{value,comparison,predicates}',
    'basePredicates.@each.{value,comparison,predicates}',
    function () {
      return this.predicates.map((predicate) => predicate.toJSON());
    },
  ),

  userAddedPredicatesAsJSON: computed(
    'predicates.@each.{value,comparison,predicates}',
    function () {
      return this.userAddedPredicates.map((predicate) => predicate.toJSON());
    },
  ),

  predicateDescriptions: computed('predicates.@each.{value,comparison,predicates}', function () {
    return this.predicates.map((predicate) => predicate.get('description'));
  }),

  userAddedPredicateDescriptions: computed(
    'predicates.@each.{value,comparison,predicates}',
    function () {
      return this.predicates
        .rejectBy('isUserPredicate')
        .map((predicate) => predicate.get('description'));
    },
  ),

  baseUserAddedGoalPredicateDescriptions: computed(
    'predicates.@each.{value,comparison,predicates}',
    function () {
      return this.basePredicates
        .rejectBy('isUserPredicate')
        .map((predicate) => predicate.get('goalDescription'));
    },
  ),

  baseUserAddedPredicateDescriptions: computed(
    'basePredicates.@each.{value,comparison,predicates}',
    function () {
      return this.basePredicates
        .rejectBy('isUserPredicate')
        .map((predicate) => predicate.get('description'));
    },
  ),

  _generateJoinTypePredicate(joinType) {
    let userAddedPredicates = this._copyPredicates(this.userAddedPredicates);
    if (joinType === 'or') {
      let orPredicate = this.store.createFragment('common/predicate', { type: 'or' });
      orPredicate.set('predicates', userAddedPredicates);
      return [orPredicate];
    } else if (joinType === 'and') {
      return userAddedPredicates;
    }
  },

  _getLogicalPredicates() {
    return this.isOrMode ? [this.orPredicate] : this._copyPredicates(this.userAddedPredicates);
  },

  _generateUserTypePredicate(userType) {
    if (userType === 'user') {
      return this.store.createFragment('common/predicate', USER_ROLE_PREDICATE);
    } else if (userType === 'lead') {
      return this.store.createFragment('common/predicate', LEAD_ROLE_PREDICATE);
    }
    throw new Error('Unknown user type');
  },

  _generateDisengagedPredicate(includeEmailDisengagementPredicate) {
    return includeEmailDisengagementPredicate
      ? this.store.createFragment('common/predicate', EXCLUDE_DISENGAGED_USERS_PREDICATE)
      : null;
  },

  generatePredicatesWithNewUserType(userType) {
    return this._generatePredicates({ userType });
  },

  generatePredicatesWithNewJoinType(joinType) {
    return this._generatePredicates({ joinType });
  },

  generatePredicatesWithDisengagementPredicate() {
    return this._generatePredicates({ includeEmailDisengagementPredicate: true });
  },

  generatePredicatesWithoutDisengagementPredicate() {
    return this._generatePredicates({ includeEmailDisengagementPredicate: false });
  },

  _generatePredicates(options = {}) {
    let joinTypePredicate = isNone(options.joinType)
      ? this._getLogicalPredicates()
      : this._generateJoinTypePredicate(options.joinType);

    let userTypePredicate = isNone(options.userType)
      ? this.userTypePredicate
      : this._generateUserTypePredicate(options.userType);

    let emailDisengagementPredicate = isNone(options.includeEmailDisengagementPredicate)
      ? [this.emailDisengagementPredicate]
      : this._generateDisengagedPredicate(options.includeEmailDisengagementPredicate);

    let nonUserAddedPredicates = [userTypePredicate].concat(emailDisengagementPredicate);

    return this._joinedPredicatesWithNonUserAddedPredicates(
      joinTypePredicate,
      nonUserAddedPredicates,
    );
  },
});
