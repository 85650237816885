/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/survey/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/survey/edit-mode-generator';
import generateSeriesModeConfig from 'embercom/objects/series/ruleset-editor/survey/editor-configuration-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import {
  humanReadableObjectNames,
  objectFeatures,
  objectTypes,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import { inject as service } from '@ember/service';
import numberFormatter from 'embercom/lib/number-formatter';
import pluralize from 'embercom/lib/inflector';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.survey;
  let container = options.container;
  let humanReadableObjectName = humanReadableObjectNames[options.objectType];
  let notificationMessages = generateNotificationMessages(options);
  notificationMessages.activate = generateSurveyNotificationMessages(
    container,
    humanReadableObjectName,
    options.objectType,
  );
  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    seriesMode: generateSeriesModeConfig(options),
    notificationMessages,
    requiredFeature: objectFeatures[objectTypes.survey],
  });
}

function generateSurveyNotificationMessages(container, humanReadableObjectName, objectType) {
  return NotificationMessage.extend({
    contentEditorService: service(),
    humanReadableObjectName,
    async confirmation() {
      await this.contentEditorService.ruleset.fetchAudiencePreview();

      let ruleset = this.contentEditorService.ruleset;

      let audienceCount = ruleset.audiencePreview.count;
      let audience = `${numberFormatter(audienceCount)} ${pluralize('person', audienceCount)}`;

      let title = `Ready to set your ${this.humanReadableObjectName} live?`;
      let confirmButtonText = `Set live`;

      let body;

      if (ruleset.isTriggeredByCode) {
        body = `Your ${this.humanReadableObjectName} will not be sent automatically, but can be sent via its sharing URL or triggered inside your application with Intercom's Javascript API.`;
      } else if (ruleset.hasControlGroup) {
        body = `Part of your audience of ${audience} will be sent this ${this.humanReadableObjectName} and the rest will join the control group.`;
        if (!ruleset.isMatchBehaviorStatic) {
          body +=
            ' Going forward, anyone who matches your rules in the future will be split similarly.';
        }
      } else if (ruleset.rulesetTriggers.length > 0) {
        body = `Your ${this.humanReadableObjectName} will be sent to people who trigger the specified event and match the audience rules. ${audience} currently match your audience rules.`;
      } else {
        body = `Your ${this.humanReadableObjectName} will reach up to ${audience}`;
        if (ruleset.isMatchBehaviorStatic) {
          body += '.';
        } else {
          body += ' now and anyone who matches your rules in the future.';
        }
        if (!ruleset.isDraft && !ruleset.isMatchBehaviorMulti) {
          body += ` Anyone who has already received this ${this.humanReadableObjectName} will not receive it again.`;
        }
      }

      return {
        title,
        confirmButtonText,
        confirmContext: {
          body,
          objectType,
          isDynamicMessage: !ruleset.isMatchBehaviorStatic,
        },
        bodyComponentName: 'content-editor/set-live-confirmation-body',
        shouldTrackAnalyticsEvents: true,
        analyticsEventChannelName: objectNames[objectType],
      };
    },
  }).create({
    success: `The ${humanReadableObjectName} was set live`,
    failure: `We couldn't set this ${humanReadableObjectName} live. Send us a message and we'll do our best to help out.`,
    container,
  });
}
