/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import EmberObject, { computed } from '@ember/object';
import ajax from 'embercom/lib/ajax';

let EventArchiver = EmberObject.extend({
  event: null,
  appId: null,
  dependentObjectsUrl: computed('event.event_id', function () {
    return `/ember/user_events/${this.event.event_id}/dependent_objects`;
  }),
  type: 'event',
  onArchive: $.noop,
  getDependentObjects() {
    return ajax({
      url: this.dependentObjectsUrl,
      type: 'GET',
      data: {
        app_id: this.appId,
      },
    }).then((response) => response.table);
  },
  archive() {
    return ajax({
      type: 'PUT',
      url: '/ember/event_settings/archive',
      data: JSON.stringify({
        app_id: this.appId,
        id: this.get('event.event_id'),
      }),
    }).then(this.onArchive);
  },
  unarchive() {
    return ajax({
      type: 'PUT',
      url: '/ember/event_settings/unarchive',
      data: JSON.stringify({
        app_id: this.appId,
        id: this.get('event.event_id'),
      }),
    });
  },
  updateDescription() {
    return ajax({
      type: 'PUT',
      url: '/ember/event_settings/update_description',
      data: JSON.stringify({
        app_id: this.appId,
        id: this.get('event.event_id'),
        description: this.get('event.description'),
      }),
    });
  },
});

export default EventArchiver;
