/* RESPONSIBLE TEAM: team-proactive-support */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import NewsItemContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/news-item/panel-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';
import { StandardSchedulePanelConfiguration } from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import { timezoneTypes } from 'embercom/models/data/matching-system/matching-constants';

export default function generateEditModeConfig(options) {
  let { container } = options;

  return EditModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesModeHeaderConfiguration.create({ container }),
    headerComponentConfigs: [StandardEditModeHeaderConfiguration.create({ container })],
    editorPanelConfigs: [
      NewsItemContentPanelConfiguration.create({ container }),
      StandardSchedulePanelConfiguration.create({
        showFrequencyEditor: false,
        timezoneType: timezoneTypes.basedOnAppTimezone,
        container,
        verb: 'show',
      }),
    ],
    footerComponentConfigs: [],
  });
}
