/* RESPONSIBLE TEAM: team-phone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
// eslint-disable-next-line no-restricted-imports
import { type SyncHasMany } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { ONLY_PHONE_CALL_CHANNEL } from 'embercom/lib/operator/custom-bots/constants';
import { buildValidations, validator } from 'ember-cp-validations';

const Validations = buildValidations({
  followUpRules: [
    validator('follow-up-rule-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
    validator('follow-up-rule-is-complete', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
  simpleActionRule: [
    validator('simple-action-rule-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
});

export default class HangUp extends Step.extend(Validations) {
  @fragmentArray('rules/rule') declare followUpRules: SyncHasMany<any>;
  @fragment('rules/rule') declare simpleActionRule?: any;

  supportedChannels = ONLY_PHONE_CALL_CHANNEL;

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get hasSimpleActionRule() {
    return !!this.simpleActionRule;
  }

  static get isStepGroupEnding() {
    return true;
  }

  static createNewStep(store: Store): HangUp {
    return store.createRecord('operator/visual-builder/step/hang-up', {
      type: 'operator/visual-builder/step/hang-up',
    });
  }
}
