/* RESPONSIBLE TEAM: team-workflows */
import Step from 'embercom/models/operator/visual-builder/step';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';
import { type SyncHasMany } from '@ember-data/model';
import { type Block } from 'embercom/models/common/blocks/block';
import type Store from '@ember-data/store';
import { ALL_CHANNELS } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

export const noteStepBlockValidators = {
  blocks: [
    validator('blocks-not-empty', {
      message: 'operator.workflows.visual-builder.validations.blank-message-error',
      messageTranslated: true,
    }),
  ],
};

const Validations = buildValidations({
  ...noteStepBlockValidators,
});

export default class Note extends Step.extend(Validations) {
  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;

  supportedChannels = ALL_CHANNELS;

  static get supportedContexts() {
    return [
      TargetContext.Conversation,
      TargetContext.CustomerTicket,
      TargetContext.BackOfficeTicket,
      TargetContext.TrackerTicket,
    ];
  }

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get serializedBlocks() {
    // We only need to serialize the blocks if they are a FragmentArray (in tests they are already serialized)
    if (this.blocks.type === 'common/blocks/block') {
      return this.blocks.serialize();
    } else {
      return this.blocks;
    }
  }

  static createNewStep(store: Store): Note {
    let blocks = [
      store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: '', // let the component decide what the default or placeholder text should be
      }),
    ];

    return store.createRecord('operator/visual-builder/step/note', {
      blocks,
      type: 'operator/visual-builder/step/note',
    });
  }
}
