/* RESPONSIBLE TEAM: team-help-desk-experience */

import { type IDBPDatabase } from 'idb';
import { type Span } from '@opentelemetry/api';

import ObjectStore from 'embercom/objects/inbox/core-data/store';
import Macro from 'embercom/objects/inbox/macro';
import { type MacroWireFormat } from 'embercom/objects/inbox/macro';
import { type DB } from 'embercom/services/inbox2-core-data';
import { type MacroResponse } from 'embercom/services/inbox2-macros-search';
import { prepareStringForFuzzySearch } from 'embercom/services/fuzzy-search';

export type MacroSchema = MacroWireFormat & { fuzzy: Fuzzysort.Prepared };

export default class MacrosStore extends ObjectStore<Macro> {
  static upgradeDB(db: IDBPDatabase<DB>, _oldVersion: number, _newVersion: number | null) {
    // for now nuke all data on update, in future we can do something nicer
    if (db.objectStoreNames.contains('macros')) {
      db.deleteObjectStore('macros');
    }

    if (!db.objectStoreNames.contains('macros')) {
      db.createObjectStore('macros', { keyPath: 'id', autoIncrement: false });
    }
  }

  async fetchOne(id: Macro['id']): Promise<Macro | null> {
    let item = await this.db.get('macros', id);
    if (!item) {
      // TODO: fetch from server
      return null;
    }

    return this.getOrCreateFromIdentityMap(item);
  }

  async fetchAll(): Promise<Macro[]> {
    return await this.tracing.inSpan(
      { name: 'fetchAll', resource: 'inbox2-core-data.macros' },
      async () => {
        let items = await this.db.getAll('macros');

        return items.map((item) => this.getOrCreateFromIdentityMap(item));
      },
    );
  }

  private getOrCreateFromIdentityMap(item: MacroSchema) {
    let macro = this.identityMap.get(item.id.toString());
    if (!macro) {
      macro = Macro.deserialize(item);
      this.identityMap.set(item.id.toString(), macro);
    }
    return macro;
  }

  async boot() {
    let json = await this.fetchData<MacroResponse>('/ember/inbox/saved_replies');
    if (!json) {
      return;
    }

    let items = json.saved_replies.results;

    return await this.tracing.inSpan(
      { name: 'boot', resource: 'inbox2-core-data.macros' },
      async (span?: Span) => {
        let existing = await this.db.getAll('macros');
        let existingById = Object.fromEntries(existing.map((macro) => [macro.id, macro]));
        let fetchedIds: Set<DB['macros']['key']> = new Set();
        let toUpdate: MacroSchema[] = [];
        let toDelete: DB['macros']['key'][] = [];

        // figure out which are new / changed
        items.forEach((item) => {
          fetchedIds.add(item.id);
          let existingItem = existingById[item.id.toString()];
          if (!existingItem || (existingItem && existingItem.name !== item.name)) {
            toUpdate.push({
              ...item,
              fuzzy: prepareStringForFuzzySearch(item.name),
            });
          }
        });

        // figure out which have been deleted
        Object.keys(existingById).forEach((id) => {
          if (!fetchedIds.has(Number(id))) {
            toDelete.push(Number(id));
          }
        });

        span?.setAttributes({
          'items.fetched': fetchedIds.size,
          'items.existing': existing.length,
          'items.updated': toUpdate.length,
          'items.deleted': toDelete.length,
        });

        // stick the data in indexeddb
        let tx = this.db.transaction(['macros'], 'readwrite');
        let itemStore = tx.objectStore('macros');
        await Promise.all([
          ...toUpdate.map((item) => itemStore.put(item)),
          ...toDelete.map((id) => itemStore.delete(id)),
          tx.done,
        ]);
      },
    );
  }
}
