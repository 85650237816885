/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';

export default class Prioritization extends Fragment {
  @attr('number') entityId;
  @attr('number') entityType;
  @fragmentArray('matching-system/prioritization') prioritizations;
}
