/* RESPONSIBLE TEAM: team-app-security */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { notEmpty, not, and } from '@ember/object/computed';
import { lessThanProperty } from '@intercom/pulse/lib/computed-properties';

export default Model.extend({
  createdAt: attr('date'),
  revokedAt: attr('date'),
  expiredAt: attr('date'),

  get now() {
    return new Date();
  },

  isRevoked: notEmpty('revokedAt'),
  isNotRevoked: not('isRevoked'),
  isExpired: lessThanProperty('expiredAt', 'now'),
  isNotExpired: not('isExpired'),
  isNotNew: not('isNew'),
  isNotDeleted: not('isDeleted'),

  isActive: and('isNotRevoked', 'isNotExpired', 'isNotNew', 'isNotDeleted'),
});
