/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import { A } from '@ember/array';
import { computed, get } from '@ember/object';
import { and, equal, filterBy, not, notEmpty, or, reads, alias } from '@ember/object/computed';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { findBy, isAny, ternary, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import pluralise from 'embercom/lib/inflector';
import PredicateGroup from 'embercom/models/common/predicate-group';
import IMPLICIT_PREDICATES from 'embercom/models/data/messages/implicit-predicates';
import SelectionStateValidations from 'embercom/validations/messages/selection-state';
import { hasNestedPredicates } from 'embercom/lib/predicates-helper';

// NB - nolaneo - In the context of a selection state user type
// a `person` message is when the message is NOT targeting users.
// We should look to remove this if we decided to move away from
// predicates in the form `role ne user_role`.

export default PredicateGroup.extend(SelectionStateValidations, {
  includedIds: attr('array'),
  excludedIds: attr('array'),
  count: attr('number'),
  predicates: fragmentArray('common/predicate'),
  clientPredicates: fragmentArray('common/predicate'),

  clientPredicatesOrPredicate: findBy('clientPredicates', 'isOrPredicate'),
  isClientPredicatesOrMode: notEmpty('clientPredicatesOrPredicate'),
  clientPredicatesPageTargetJoinType: ternary('isClientPredicatesOrMode', 'or', 'and'),

  predicatesOrNestedClientPredicates: ternaryToProperty(
    'isClientPredicatesOrMode',
    'clientPredicatesOrPredicate.predicates',
    'clientPredicates',
  ),

  nestingLevelGreaterThanOne: computed('predicates.@each.{type,value,comparison}', function () {
    return hasNestedPredicates(this.predicates);
  }),

  hasSimplePredicates: not('hasComplexPredicates'),
  hasComplexPredicates: alias('nestingLevelGreaterThanOne'),

  // Time on page predicates are always top level AND predicates
  timeOnPagePredicates: filterBy('clientPredicates', 'isTimeOnPage'),
  hasTimeOnPagePredicates: notEmpty('timeOnPagePredicates'),
  // Page target predicates can be top level or nested OR predicates
  pageTargetPredicates: filterBy('predicatesOrNestedClientPredicates', 'isPageTarget'),
  hasPageTargetPredicates: notEmpty('pageTargetPredicates'),

  addJSONClientPredicate(predicateJSON, options = {}) {
    this.addClientPredicate(this.store.createFragment('common/predicate', predicateJSON), options);
  },

  addClientPredicate(predicate) {
    if (get(predicate, 'constructor.modelName') !== 'common/predicate') {
      throw new Error(`Expected object of type 'common/predicate' to be passed to addClientPredicate().
        If you are using raw JSON predicates, use the addJSONClientPredicate() function.`);
    } else {
      let shouldNestPredicateInOrGroup =
        predicate.get('isPageTarget') && this.isClientPredicatesOrMode;
      let predicates = shouldNestPredicateInOrGroup
        ? this.get('clientPredicatesOrPredicate.predicates')
        : this.clientPredicates;
      predicates.addObject(predicate);
    }
  },

  userAddedPredicatesAreTrackedAndValidOrEmpty: or(
    'allPredicatesAreTrackedAndValid',
    'hasNoUserAddedPredicates',
  ),
  allPredicatesAreTrackedAndValid: and('allPredicatesAreValid', 'allPredicatesAreTracked'),

  isTargetingUsers: equal('internalUserType', 'user'),
  isTargetingLeads: equal('internalUserType', 'lead'),
  isTargetingVisitors: equal('internalUserType', 'visitor'),
  isTargetingNonUsers: equal('internalUserType', 'non_user'),
  isTargetingNonVisitors: equal('internalUserType', 'non_visitor'),
  isTargetingNonLeads: equal('internalUserType', 'non_lead'),
  isTargetingEveryone: equal('internalUserType', 'everyone'),
  audienceIncludesVisitors: or(
    'isTargetingVisitors',
    'isTargetingNonUsers',
    'isTargetingNonLeads',
    'isTargetingEveryone',
  ),
  audienceIncludesLeads: or(
    'isTargetingLeads',
    'isTargetingNonUsers',
    'isTargetingNonVisitors',
    'isTargetingEveryone',
  ),

  isSelectionBased: notEmpty('includedIds'),
  isNotSelectionBased: not('isSelectionBased'),

  hasPredicates: notEmpty('predicates'),
  hasZeroCount: equal('count', 0),

  hasUntrackedPredicates: isAny('userAddedPredicates', 'isUntracked', true),
  allPredicatesAreTracked: not('hasUntrackedPredicates'),

  internalUserType: reads('userTypePredicate.getUserType'),
  externalTypeLabels: {
    user: ['user'],
    visitor: ['visitor'],
    lead: ['lead'],
    non_user: ['visitor', 'lead'],
    non_visitor: ['user', 'lead'],
    non_lead: ['user', 'visitor'],
    everyone: ['user', 'lead', 'visitor'],
  },
  userTypes: computed('externalTypeLabels', 'internalUserType', function () {
    if (this.externalTypeLabels[this.internalUserType]) {
      return this.externalTypeLabels[this.internalUserType];
    } else {
      return ['people'];
    }
  }),
  pluralUserTypes: computed('userTypes', function () {
    if (this.userTypes) {
      return this.userTypes.map((userType) => pluralise(userType));
    }
  }),
  implicitPredicatesForMessage(message) {
    let predicates = this.serialize().predicates;
    if (message.get('isEmail')) {
      predicates.addObjects(IMPLICIT_PREDICATES.emailPredicates);
    } else if (message.get('isInApp')) {
      predicates.addObjects(this._implicitPredicatesForInApp(message));
    } else if (message.get('isMobilePush')) {
      predicates.addObjects(this._implicitPredicatesForPush(message));
    } else {
      throw new Error('Cannot generate implicit predicates for unknown message type');
    }
    return predicates;
  },

  _implicitPredicatesForInApp(message) {
    if (message.get('isAttributeCollectorReply')) {
      return IMPLICIT_PREDICATES.emailCollectorPredicates;
    } else if (message.get('isAndroidFirst')) {
      return IMPLICIT_PREDICATES.androidPredicates;
    } else if (message.get('isIOSFirst')) {
      return IMPLICIT_PREDICATES.iosPredicates;
    } else {
      return A();
    }
  },

  _implicitPredicatesForPush(message) {
    if (message.get('isAndroidFirst')) {
      return IMPLICIT_PREDICATES.androidPushOnlyPredicates;
    } else if (message.get('isIOSFirst')) {
      return IMPLICIT_PREDICATES.iosPushOnlyPredicates;
    } else {
      return IMPLICIT_PREDICATES.pushOnlyPredicates;
    }
  },
});
