/* RESPONSIBLE TEAM: team-proactive-support */
import HeaderConfiguration from 'embercom/objects/content-editor/header-configuration';

export default HeaderConfiguration.extend({
  component: undefined,

  init(inputs) {
    inputs.component = 'content-editor/headers/view-mode/standard-header';
    this._super(...arguments);
  },
});
