/* RESPONSIBLE TEAM: team-workflows */
import {
  INBOUND_BOT_TYPE,
  NEW_CONVERSATION_TARGET,
  AT_CONVERSATION_START,
} from 'embercom/lib/operator/custom-bots/constants';
import {
  matchBehaviors,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import InboundBotEditorConfig from 'embercom/objects/visual-builder/configuration/editor/inbound-bot';
import type { BotConfigTarget } from '../configuration';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

import CustomBotConfig from '../custom-bot';

export default class InboundCustomBotConfig extends CustomBotConfig {
  get target(): BotConfigTarget {
    return NEW_CONVERSATION_TARGET;
  }

  get botType() {
    return INBOUND_BOT_TYPE;
  }

  get objectType() {
    return objectTypes.inboundCustomBot;
  }

  get matchBehavior() {
    return matchBehaviors.transient;
  }

  get titleTranslationKey() {
    return 'operator.custom-bot.trigger.inbound.title';
  }

  get descriptionTranslationKey() {
    return 'operator.custom-bot.trigger.inbound.description';
  }

  get icon(): InterfaceIconName {
    return 'wave';
  }

  get EditorConfigClass() {
    return InboundBotEditorConfig;
  }

  get requiredBillingFeature() {
    return 'bot_workflows';
  }

  get triggerContextType() {
    return AT_CONVERSATION_START;
  }
}
