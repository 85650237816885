/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/outbound-webhook/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';

export default function generateViewModeConfig(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');
  return ViewModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesViewModeHeaderConfiguration.create({
      container,
      moreDropdownConfig: MoreDropdownConfiguration.create({
        hideDuplicateOption: true,
        hideCsvExportOption: false,
        hideTagOption: true,
        hideDeleteOption: true,
        hideVersionsOption: true,
        container,
      }),
    }),
    headerComponentConfigs: [StandardViewModeHeaderConfiguration.create({ container })],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: 'Webhook',
          icon: 'alignment-reference',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.sent'),
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: 'Failed',
          view: 'webhookFailures',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [ContentSummaryConfiguration.create({ container })],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          noun: 'send',
          verb: 'triggered',
          emptyStateTitle: 'Your webhook has not been triggered yet',
          emptyStateDescription: ' ', // We need a non-empty value as override in order to hide description
          container,
        }),
      ],
      webhookFailures: [
        StandardStatListConfiguration.create({
          stat: 'webhookFailure',
          hideCreatedVia: true,
          noun: 'failure',
          verb: 'failed',
          emptyStateTitle: 'Your webhook has not failed yet',
          emptyStateDescription: ' ', // We need a non-empty value as override in order to hide description
          container,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });
}
