/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';

export default Model.extend({
  oauthClientId: attr('string'),
  appPackageName: attr('string'),
  appPackageId: attr('number'),
  appPackageIconUrl: attr('string'),
  appPackagePrivacyPolicyUrl: attr('string'),
  appPackageTermsOfServiceUrl: attr('string'),
  appPackageOwnerApp: fragment('oauth/app'),
  appPackageOauthScopeCategories: fragmentArray('oauth/oauth-category'),
  initiallySelectedAppIdCode: attr('string'),
  allApps: fragmentArray('oauth/app'),
  adminId: attr('string'),
  adminEmailVerified: attr('boolean'),
  adminEmailPendingVerification: attr('boolean'),
});
