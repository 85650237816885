/* RESPONSIBLE TEAM: team-proactive-support */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/checklist/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/checklist/edit-mode-generator';
import generateSeriesModeConfig from 'embercom/objects/series/ruleset-editor/checklist/editor-configuration-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import {
  humanReadableObjectNames,
  objectFeatures,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import { inject as service } from '@ember/service';
import numberFormatter from 'embercom/lib/number-formatter';
import pluralize from 'embercom/lib/inflector';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.checklist;
  let container = options.container;
  let humanReadableObjectName = humanReadableObjectNames[options.objectType];
  let notificationMessages = generateNotificationMessages(options);
  notificationMessages.deactivate = generateChecklistDeactivateNotificationMessage(
    container,
    humanReadableObjectName,
  );
  notificationMessages.delete = generateChecklistDeleteNotificationMessage(
    container,
    humanReadableObjectName,
  );
  notificationMessages.activate = generateChecklistActivateNotifcationMessages(
    container,
    humanReadableObjectName,
  );
  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    seriesMode: generateSeriesModeConfig(options),
    notificationMessages,
    requiredFeature: objectFeatures[objectTypes.checklist],
  });
}

function generateChecklistDeactivateNotificationMessage(container, humanReadableObjectName) {
  let intl = container.lookup('service:intl');
  return NotificationMessage.extend({
    contentEditorService: service(),
    async confirmation() {
      let dependencies = await this.contentEditorService.ruleset.loadDependentObjects();

      let title = intl.t('outbound.checklists.notification-messages.deactivate.title');
      let body = intl.t('outbound.checklists.notification-messages.deactivate.body');
      let confirmButtonText = intl.t(
        'outbound.checklists.notification-messages.deactivate.confirm-button-text',
      );

      return {
        title,
        body,
        confirmButtonText,
        bodyComponentName: 'content-editor/dependent-content-list',
        confirmContext: {
          dependencies,
          body,
          humanReadableObjectName,
        },
      };
    },
  }).create({
    success: intl.t('outbound.checklists.notification-messages.deactivate.success'),
    failure: intl.t('outbound.checklists.notification-messages.deactivate.failure'),
    container,
  });
}

function generateChecklistDeleteNotificationMessage(container, humanReadableObjectName) {
  let intl = container.lookup('service:intl');
  return NotificationMessage.extend({
    contentEditorService: service(),
    async confirmation() {
      let dependencies = await this.contentEditorService.ruleset.loadDependentObjects();

      let title = intl.t('outbound.checklists.notification-messages.delete.title');
      let body = intl.t('outbound.checklists.notification-messages.delete.body');
      let primaryButtonType = 'primary-destructive';
      let confirmButtonText = intl.t(
        'outbound.checklists.notification-messages.delete.confirm-button-text',
      );

      return {
        title,
        body,
        confirmButtonText,
        primaryButtonType,
        bodyComponentName: 'content-editor/dependent-content-list',
        confirmContext: {
          dependencies,
          body,
          humanReadableObjectName,
        },
      };
    },
  }).create({
    success: intl.t('outbound.checklists.notification-messages.delete.success'),
    failure: intl.t('outbound.checklists.notification-messages.delete.failure'),
    container,
  });
}

function generateChecklistActivateNotifcationMessages(container, humanReadableObjectName) {
  let intl = container.lookup('service:intl');
  return NotificationMessage.extend({
    contentEditorService: service(),
    humanReadableObjectName,
    async confirmation() {
      await this.contentEditorService.ruleset.fetchAudiencePreview();
      let ruleset = this.contentEditorService.ruleset;
      let audienceCount = ruleset.audiencePreview.count;
      let audience = `${numberFormatter(audienceCount)} ${pluralize('person', audienceCount)}`;

      let title = intl.t('outbound.checklists.notification-messages.activate.title');
      let confirmButtonText = intl.t(
        'outbound.checklists.notification-messages.activate.confirm-button-text',
      );

      let body;

      if (ruleset.isTriggeredByCode) {
        body = intl.t('outbound.checklists.notification-messages.activate.only-shareable');
      } else if (ruleset.hasControlGroup) {
        body = intl.t('outbound.checklists.notification-messages.activate.control-group', {
          audience,
        });
        if (!ruleset.isMatchBehaviorStatic) {
          body = `${body} ${intl.t(
            'outbound.checklists.notification-messages.activate.control-group-with-dynamic-audience',
          )}`;
        }
      } else if (!ruleset.isDraft) {
        body = intl.t('outbound.checklists.notification-messages.activate.paused-ruleset');
      } else {
        body = intl.t('outbound.checklists.notification-messages.activate.general-body', {
          audience,
        });
        if (ruleset.isMatchBehaviorStatic) {
          body += '.';
        } else {
          body = `${body} ${intl.t(
            'outbound.checklists.notification-messages.activate.general-body-with-dynamic-audience',
          )}`;
        }
      }

      return { title, body, confirmButtonText };
    },
  }).create({
    success: intl.t('outbound.checklists.notification-messages.activate.success'),
    failure: intl.t('outbound.checklists.notification-messages.activate.failure'),
    container,
  });
}
