/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { readOnly, not } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Model.extend({
  developerHub: service(),
  approvedScopeIds: readOnly('developerHub.appPackage.oauthClient.scopes'),

  name: attr('string'),
  requiredScopes: hasMany('developer-hub/oauth-scope'),

  hasAtLeastOneRequiredScope: computed('requiredScopes.[]', 'approvedScopeIds.[]', function () {
    return this.requiredScopes.any((scope) => this.approvedScopeIds.includes(scope.id));
  }),
  missingRequiredScopes: not('hasAtLeastOneRequiredScope'),
});
