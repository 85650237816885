/* RESPONSIBLE TEAM: team-proactive-support */
import {
  objectTypes,
  objectNames,
  humanReadableObjectNames,
  objectIcons,
} from 'embercom/models/data/matching-system/matching-constants';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';

export const ContentDependencyType = {
  smartLink: 0,
  checkListTask: 1,
  reusableWorkflow: 2,
};

interface ContentDependencyJson {
  entity_id: number;
  entity_type: number;
  title: string;
  state: number;
  ruleset_id: number;
}

export default class ContentDependency {
  entityId;
  entityType;
  title;
  state;
  rulesetId;

  get entityIcon() {
    return objectIcons[this.entityType];
  }

  get humanReadableEntityName() {
    return humanReadableObjectNames[this.entityType];
  }

  get route() {
    let routes = OUTBOUND_EDITOR_ROUTES as Record<string, string>;
    let objectName = objectNames[this.entityType];
    let outboundRoute = routes[objectName];
    if (outboundRoute) {
      return outboundRoute;
    } else if (this.entityType === objectTypes.article) {
      // Articles aren't in the outbound system so let's just manually handle it here
      return 'apps.app.articles.articles.article.show';
    }
    return undefined;
  }

  get routeId() {
    if (this.entityType === objectTypes.article) {
      return this.entityId;
    } else {
      return this.rulesetId;
    }
  }

  constructor(json: ContentDependencyJson) {
    this.entityId = json.entity_id;
    this.entityType = json.entity_type;
    this.title = json.title;
    this.state = json.state;
    this.rulesetId = json.ruleset_id;
  }
}
