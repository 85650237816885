/* RESPONSIBLE TEAM: team-help-desk-experience */
import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';
import TeamSettings, { type TeamSettingsWireFormat } from './team-settings';
import { EmojiIcon } from './command-k/icons';

export interface TeamSummaryWireFormat {
  id: number;
  name: string;
  icon?: string;
  count?: number;
  admin_summaries?: AdminSummaryWireFormat[];
  team_settings?: TeamSettingsWireFormat;
  is_member?: boolean;
}

export const UNASSIGNED_ID = 0;

export default class TeamSummary {
  readonly id: number;
  readonly name: string;
  readonly icon?: EmojiIcon;
  readonly count?: number;
  readonly adminSummaries: AdminSummary[];
  readonly teamSettings?: TeamSettings;
  readonly isMember?: boolean;

  constructor(
    id: number,
    name: string,
    icon: string | EmojiIcon | undefined = undefined,
    count: number | undefined = 0,
    adminSummaries?: AdminSummary[],
    teamSettings?: TeamSettings,
    isMember?: boolean,
  ) {
    this.id = id;
    this.name = name;
    if (icon instanceof EmojiIcon) {
      this.icon = icon;
    } else if (icon) {
      this.icon = new EmojiIcon(icon);
    }
    this.count = count;
    this.adminSummaries = adminSummaries ?? [];
    this.teamSettings = teamSettings;
    this.isMember = isMember;
  }

  get translationKey(): string | void {
    if (this.isUnassignedAssignee) {
      return 'inbox.unassigned';
    }
  }

  get isUnassignedAssignee(): boolean {
    return this.id === UNASSIGNED_ID;
  }

  static deserialize(json: TeamSummaryWireFormat): TeamSummary {
    return new TeamSummary(
      json.id,
      json.name,
      json.icon,
      json.count,
      json.admin_summaries?.map(AdminSummary.deserialize),
      json.team_settings ? TeamSettings.deserialize(json.team_settings) : undefined,
      json.is_member,
    );
  }

  static get unassigned(): TeamSummary {
    return new TeamSummary(UNASSIGNED_ID, '');
  }

  clone() {
    return new TeamSummary(
      this.id,
      this.name,
      this.icon,
      this.count,
      this.adminSummaries,
      this.teamSettings,
      this.isMember,
    );
  }
}
