/* RESPONSIBLE TEAM: team-proactive-support */
import StandardAudienceEditorConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-editor-configuration';
import { matchingLocations } from 'embercom/models/data/matching-system/matching-constants';

export default class CarouselContentEditorAudienceEditorConfiguration extends StandardAudienceEditorConfiguration {
  get isDisabled() {
    return this.ruleset.rulesetLinks.any((rulesetLink) =>
      rulesetLink.matchingLocations.includes(matchingLocations.TRIGGER_FROM_CODE),
    );
  }
}
