/* RESPONSIBLE TEAM: team-help-desk-experience */
import TeamSummary, { type TeamSummaryWireFormat } from './team-summary';
import AdminSummary, { type AdminSummaryWireFormat } from './admin-summary';
export interface DuplicateConversationSummaryWireFormat {
  id: number;
  last_updated: string;
  title?: string;
  admin_assignee?: AdminSummaryWireFormat;
  team_assignee?: TeamSummaryWireFormat;
}

export default class DuplicateConversationSummary {
  readonly id: number;
  readonly lastUpdated: Date;
  readonly title?: string;
  readonly adminAssignee?: AdminSummary;
  readonly teamAssignee?: TeamSummary;

  constructor(
    id: number,
    lastUpdated: Date,
    title?: string,
    adminAssignee?: AdminSummary,
    teamAssignee?: TeamSummary,
  ) {
    this.id = id;
    this.lastUpdated = lastUpdated;
    this.title = title;
    this.adminAssignee = adminAssignee;
    this.teamAssignee = teamAssignee;
  }

  static deserialize(json: DuplicateConversationSummaryWireFormat): DuplicateConversationSummary {
    let adminAssignee = json.admin_assignee
      ? AdminSummary.deserialize(json.admin_assignee)
      : undefined;
    let teamAssignee = json.team_assignee ? TeamSummary.deserialize(json.team_assignee) : undefined;
    return new DuplicateConversationSummary(
      json.id,
      new Date(json.last_updated),
      json.title,
      adminAssignee,
      teamAssignee,
    );
  }
}
