/* RESPONSIBLE TEAM: team-proactive-support */
import EditModeConfig from 'embercom/objects/content-editor/edit-mode-configuration';
import WhatsappContentPanelConfiguration from 'embercom/objects/content-editor/panels/content/whatsapp/panel-configuration';
import {
  StandardAudiencePanelConfiguration,
  StandardGoalPanelConfiguration,
  StandardSchedulePanelConfiguration,
} from 'embercom/objects/content-editor/panels/standard-panel-configurations';
import SeriesSchedulePanelConfiguration from 'embercom/objects/content-editor/panels/schedule/series-schedule-panel-configuration';
import StandardEditModeHeaderConfiguration from 'embercom/objects/content-editor/headers/edit-mode/standard-header-configuration';
import { timezoneTypes } from 'embercom/models/data/matching-system/matching-constants';
import AudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import StandardAudienceEditorConfiguration from 'embercom/objects/audience-editor/standard-audience-editor-configuration';
import StandardSeriesModeHeaderConfiguration from 'embercom/objects/content-editor/headers/series-mode/standard-header-configuration';

export default function generateEditModeConfig(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');

  return EditModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesModeHeaderConfiguration.create({ container }),
    headerComponentConfigs: [StandardEditModeHeaderConfiguration.create({ container })],
    editorPanelConfigs: [
      WhatsappContentPanelConfiguration.create({ container }),
      StandardAudiencePanelConfiguration.create({
        audienceHeaderConfiguration: AudienceHeaderConfiguration.create({
          showImplicitPredicates: false,
          container,
        }),
        audienceEditorConfiguration: new StandardAudienceEditorConfiguration({
          showMatchBehavior: true,
          showClientPredicates: false,
          showEventRules: true,
          allowedUserRoles: ['user', 'lead'],
          useRolePredicateGroup: true,
        }),
        container,
      }),
      StandardSchedulePanelConfiguration.create({
        showFrequencyEditor: true,
        timezoneType: timezoneTypes.basedOnUserTimezone,
        container,
      }),
      StandardGoalPanelConfiguration.create({
        introText: intl.t(
          'app.objects.content-editor.whatsapp.configuration-generators.whatsapp.edit-mode-generator.intro-text',
        ),
        container,
      }),
      SeriesSchedulePanelConfiguration.create({
        timezoneType: timezoneTypes.basedOnUserTimezone,
        container,
      }),
    ],
    footerComponentConfigs: [],
  });
}
