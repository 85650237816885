/* RESPONSIBLE TEAM: team-workflows */
import { attr } from '@ember-data/model';
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { buildValidations, validator } from 'ember-cp-validations';
import type Store from '@ember-data/store';
import { ALL_CHANNELS_EXCEPT_PHONE_CALL } from 'embercom/lib/operator/custom-bots/constants';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  cardCreationParams: [validator('visual-builder/messenger-app-is-valid')],
});

export default class MessengerApp extends Step.extend(Validations) {
  @attr() declare previewCanvas?: object;
  @attr() declare cardCreationParams?: object;
  @attr('string') declare previewMessengerCardUri?: string;

  supportedChannels = ALL_CHANNELS_EXCEPT_PHONE_CALL;

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'bot_workflows';
  }

  static createNewStep(store: Store): MessengerApp {
    return store.createRecord('operator/visual-builder/step/messenger-app', {
      type: 'operator/visual-builder/step/messenger-app',
    });
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }
}
