/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import AdminAvatarData from 'embercom/models/mixins/admin-avatar-data';

export default Fragment.extend(AdminAvatarData, {
  firstName: attr('string'),
  jobTitle: attr('string'),
  city: attr('string'),
  avatar: attr(),
});
