/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import Validations from 'embercom/validations/custom-authentication/refresh-token-configuration';

export default class RefreshTokenConfiguration extends Fragment.extend(Validations) {
  @attr('string', { defaultValue: 'get' }) httpMethod;
  @attr('string') url;
  @attr('string', { defaultValue: '' }) body;
  @attr('string') tokenResponsePath;
  @fragmentArray('custom-authentication/refresh-token-configuration-header', {
    defaultValue: () => [],
  })
  headers;
}
