/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { and, gt, mapBy, not, readOnly, equal } from '@ember/object/computed';
import { rejectBy, selectFromObject } from '@intercom/pulse/lib/computed-properties';
import { contentType } from 'embercom/helpers/matching-system/content-type';
import {
  objectCustomReportRoute,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { contentWrapperTypes } from 'embercom/models/data/outbound/constants';

export const OUTBOUND_EDITOR_ROUTES = {
  'in-app': 'apps.app.outbound.in-apps.in-app',
  tour: 'apps.app.outbound.tour.edit',
  banner: 'apps.app.outbound.banners.edit',
  push: 'apps.app.outbound.push.edit',
  sms: 'apps.app.outbound.sms.edit',
  post: 'apps.app.outbound.post.edit',
  chat: 'apps.app.outbound.chat.edit',
  email: 'apps.app.outbound.email.edit',
  carousel: 'apps.app.outbound.carousel.edit',
  survey: 'apps.app.outbound.survey.edit',
  custom_bot: 'apps.app.outbound.custom-bot.edit',
  inbound_custom_bot: 'apps.app.automation.workflows.edit',
  button_custom_bot: 'apps.app.automation.workflows.edit',
  triggerable_custom_bot: 'apps.app.automation.workflows.edit',
  resolution_bot_behavior: 'apps.app.automation.resolution-bot.behaviors.edit',
  tooltip_group: 'apps.app.outbound.tooltips.edit',
  news_item: 'apps.app.outbound.news.news-items.edit',
  newsfeed: 'apps.app.settings.proactive-support.newsfeeds',
  checklist: 'apps.app.outbound.checklists.edit',
  whatsapp: 'apps.app.outbound.whatsapp.edit',
  rules: 'apps.app.outbound.series.series',
};

export const OUTBOUND_LIST_ROUTES = {
  custom_bot: 'apps.app.automation.workflows-overview',
  inbound_custom_bot: 'apps.app.automation.workflows-overview',
  button_custom_bot: 'apps.app.automation.workflows-overview',
  triggerable_custom_bot: 'apps.app.automation.workflows-overview',
  resolution_bot_behavior: 'apps.app.automation.resolution-bot.behaviors.index',
  news_item: 'apps.app.outbound.news.news-items.index',
  newsfeed: 'apps.app.settings.proactive-support.newsfeeds',
};

export const OUTBOUND_INDEX_ROUTES = {
  'in-app': 'apps.app.outbound.in-apps.in-app',
  tour: 'apps.app.outbound.tour',
  banner: 'apps.app.outbound.banners',
  push: 'apps.app.outbound.push',
  sms: 'apps.app.outbound.sms',
  post: 'apps.app.outbound.post',
  chat: 'apps.app.outbound.chat',
  email: 'apps.app.outbound.email',
  carousel: 'apps.app.outbound.carousel',
  survey: 'apps.app.outbound.survey',
  custom_bot: 'apps.app.outbound.custom-bot',
  inbound_custom_bot: 'apps.app.automation.workflows-overview',
  button_custom_bot: 'apps.app.automation.workflows-overview',
  triggerable_custom_bot: 'apps.app.automation.workflows-overview',
  resolution_bot_behavior: 'apps.app.automation.resolution-bot.behaviors',
  tooltip_group: 'apps.app.outbound.tooltips',
  news_item: 'apps.app.outbound.news.news-items',
  newsfeed: 'apps.app.outbound.news.newsfeeds.index',
  checklist: 'apps.app.outbound.checklists',
  whatsapp: 'apps.app.outbound.whatsapp',
};

const LEGACY_EDITOR_ROUTES = {
  all: 'apps.app.outbound.message',
};

export const SELECTION_SET_ROUTES = {
  all: 'apps.app.outbound.selection-set.show',
};

const SERIES_EDITOR_ROUTES = {
  series: 'apps.app.outbound.series.series',
};

const EDITOR_ROUTES = {
  [contentWrapperTypes.message]: LEGACY_EDITOR_ROUTES,
  [contentWrapperTypes.ruleset]: OUTBOUND_EDITOR_ROUTES,
  [contentWrapperTypes.selectionSet]: SELECTION_SET_ROUTES,
  [contentWrapperTypes.series]: SERIES_EDITOR_ROUTES,
};

export default Model.extend({
  contentWrapperType: attr('number'),
  contentWrapperId: attr('number'),
  appId: attr('string'),
  title: attr('string'),
  state: attr('number'),
  audienceTypes: attr(),
  matchBehavior: attr('number'),
  contents: hasMany('outbound/content-fragment'),
  relations: attr(),
  tags: hasMany('tag'),
  priority: attr('number'),
  isSelected: attr('boolean'),

  _contentsWithoutLegacyControlGroups: rejectBy(
    'contents',
    'contentType',
    objectTypes.legacyControlGroup,
  ),
  contentsWithoutControlGroups: rejectBy(
    '_contentsWithoutLegacyControlGroups',
    'contentType',
    objectTypes.controlGroup,
  ),

  hasMultipleContentFragments: gt('contents.length', 1),
  hasOneContentFragment: equal('contents.length', 1),
  versionA: readOnly('contents.firstObject'),
  versionB: computed('hasMultipleContentFragments', 'contents.lastObject', function () {
    return this.hasMultipleContentFragments ? this.get('contents.lastObject') : null;
  }),

  hasControlGroup: computed('contentTypes', function () {
    return (
      this.contentTypes.includes(objectTypes.legacyControlGroup) ||
      this.contentTypes.includes(objectTypes.controlGroup)
    );
  }),
  hasNoControlGroup: not('hasControlGroup'),
  contentTypes: mapBy('contents', 'contentType'),

  editorRoutes: EDITOR_ROUTES,
  routes: selectFromObject('editorRoutes', 'contentWrapperType'),
  routeForShowPage: selectFromObject('routes', 'routeKey'),

  routeKey: computed('contentWrapperType', function () {
    if (
      this.contentWrapperType === contentWrapperTypes.message ||
      this.contentWrapperType === contentWrapperTypes.selectionSet
    ) {
      return 'all';
    } else {
      return contentType(this.contentsWithoutControlGroups.firstObject.contentType);
    }
  }),

  contentTypeCustomReportRoute: computed('contentTypes', function () {
    return objectCustomReportRoute[
      contentType(this.contentsWithoutControlGroups.firstObject.contentType)
    ];
  }),

  hasTestsWithNoControlGroup: and('hasMultipleContentFragments', 'hasNoControlGroup'),
});
