/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import avatarUtils from '@intercom/pulse/lib/avatar-utils';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

let AdminAvatarDataMixin = Mixin.create({
  avatarData: computed(
    'avatar',
    'isUnknown',
    'isTeam',
    'isUnassignedAdmin',
    'away_mode_enabled',
    'avatar_shape',
    function () {
      let data = this.getProperties(
        'avatar.image_urls.square_128',
        'avatar.initials',
        'isUnknown',
        'isTeam',
        'isUnassignedAdmin',
        'away_mode_enabled',
        'avatar_shape',
      );
      let url = data.isUnassignedAdmin
        ? assetUrl('/assets/svgs/unassigned-avatar.svg')
        : data['avatar.image_urls.square_128'];
      if (typeof url !== 'string') {
        url = DefaultAvatars.admins[128];
      }
      return {
        url,
        showAsActive: !data['away_mode_enabled'],
        showAsAway: data['away_mode_enabled'],
        initials: avatarUtils.formatInitials(data['avatar.initials']),
        isCustomer: false,
        isTeammate: !data['isTeam'],
        isCompany: false,
        isTeam: data['isTeam'],
        deleted: data['isUnknown'],
        shouldAnimateUpdate: false,
        avatarShape: data['avatar_shape'],
      };
    },
  ),
});

export default AdminAvatarDataMixin;
