/* RESPONSIBLE TEAM: team-reporting */
import { type MetricDefinition, NO_DATASET } from './types';

const metrics: MetricDefinition[] = [
  {
    id: 'outbound.receipts',
    type: 'field',
    name: 'reporting.metrics.outbound.receipts.name',
    description: 'reporting.metrics.outbound.description',
    dataPointFormat: 'reporting.metrics.outbound.receipts.value-with-unit',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_receipt'],
      },
    },
  },
  {
    id: 'receipts.article_content_view',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_article_content_view'],
      },
    },
  },
  {
    id: 'outbound.receipts.answer',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'stat_receipt.id',
    supportedAggregations: ['cardinality'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_answer'],
      },
    },
  },
  {
    id: 'outbound.receipts.opened',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_open'],
      },
    },
  },
  {
    id: 'outbound.receipts.reply',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_reply'],
      },
    },
  },
  {
    id: 'outbound.receipts.click',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_click'],
      },
    },
  },
  {
    id: 'outbound.receipts.click.cardinality.stat_receipt_id',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'stat_receipt.id',
    supportedAggregations: ['cardinality'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_click'],
      },
    },
  },
  {
    id: 'outbound.receipts.click.cardinality.stat_receipt_id.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.clicks',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'outbound.receipts.click.cardinality.stat_receipt_id',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.completion',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_completion'],
      },
    },
  },
  {
    id: 'outbound.receipts.goal_success',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_goal_success'],
      },
    },
  },
  {
    id: 'outbound.receipts.reaction',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_reaction'],
      },
    },
  },
  {
    id: 'outbound.receipts.keyword_reply',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_keyword_reply'],
      },
    },
  },
  {
    id: 'outbound.receipts.keyword_reply.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.keyword-replies',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'outbound.receipts.keyword_reply',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.email_failure',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'decreasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_email_failure'],
      },
    },
  },
  {
    id: 'outbound.receipts.tour_step_failure',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'decreasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_tour_step_failure'],
      },
    },
  },
  {
    id: 'outbound.receipts.webhook_failure',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'decreasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_webhook_failure'],
      },
    },
  },
  {
    id: 'outbound.receipts.collected_email',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_collected_email'],
      },
    },
  },
  {
    id: 'outbound.receipts.dismissal',
    type: 'field',
    name: 'reporting.outbound.stat-chart',
    description: 'reporting.outbound.stat-chart',
    property: 'event.type',
    supportedAggregations: ['count'],
    unit: 'value',
    timeProperty: 'stat_receipt.created_at',
    improvementDirection: 'increasing',
    source: 'events',
    datasetId: NO_DATASET,
    filter: {
      type: 'category',
      data: {
        property: 'event.type',
        values: ['stats_dismissal'],
      },
    },
  },
  {
    id: 'outbound.receipts.opened.percent',
    type: 'percentage',
    name: 'reporting.metrics.outbound.receipts.opened.name',
    description: 'reporting.metrics.outbound.receipts.opened.percent.description',
    dataPointFormat: 'reporting.metrics.outbound.receipts.opened.percent.value-with-unit',
    valueDescription: 'reporting.outbound.value-description.opens',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'outbound.receipts.opened',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.reply.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.replies',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'outbound.receipts.reply',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.click.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.clicks',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'outbound.receipts.click',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.completion.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.completion',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'outbound.receipts.completion',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.reaction.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.reaction',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'outbound.receipts.reaction',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.email_failure.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.failure',
    unit: 'percent',
    improvementDirection: 'decreasing',
    numeratorMetricId: 'outbound.receipts.email_failure',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.email_failure.bounced.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.bounced',
    unit: 'percent',
    improvementDirection: 'decreasing',
    numeratorMetricId: 'outbound.receipts.email_failure',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.email_failure.unsubscribed.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.unsubscribe',
    unit: 'percent',
    improvementDirection: 'decreasing',
    numeratorMetricId: 'outbound.receipts.email_failure',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.webhook_failure.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.failure',
    unit: 'percent',
    improvementDirection: 'decreasing',
    numeratorMetricId: 'outbound.receipts.webhook_failure',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.collected_email.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.collected',
    unit: 'percent',
    improvementDirection: 'increasing',
    numeratorMetricId: 'outbound.receipts.collected_email',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.dismissal.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.dismissal',
    unit: 'percent',
    improvementDirection: 'decreasing',
    numeratorMetricId: 'outbound.receipts.dismissal',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
  {
    id: 'outbound.receipts.tour_step_failure.percent',
    type: 'percentage',
    name: 'reporting.outbound.percentage-stat-chart',
    description: 'reporting.outbound.percentage-stat-chart',
    valueDescription: 'reporting.outbound.value-description.failure',
    unit: 'percent',
    improvementDirection: 'decreasing',
    numeratorMetricId: 'outbound.receipts.tour_step_failure',
    denominatorMetricId: 'outbound.receipts',
    datasetId: NO_DATASET,
  },
];

export { metrics };
