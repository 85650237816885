/* RESPONSIBLE TEAM: team-frontend-tech */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import Block from './block';

export default Block.extend({
  header: attr('string'),
  content: attr('string'),
  footer: attr('string'),
  combinedHtml: computed('header', 'content', 'footer', function () {
    return `${this.header}${this.content}${this.footer}`;
  }),
});
