/* RESPONSIBLE TEAM: team-help-desk-experience */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import UserAvatarData from 'embercom/models/mixins/user-avatar-data';

export default Fragment.extend(UserAvatarData, {
  type: attr('string'),
  appId: attr('string'),
  name: attr('string'),
  displayName: attr('string'),
  avatar: attr(),
  isAnonymous: attr('boolean'),
  isCompany: false,
});
