/* RESPONSIBLE TEAM: team-proactive-support */
const IN_APP_WEB = {
  chat: 'inAppChat',
  note: 'inAppAnnouncement',
  post: 'inAppBigAnnouncement',
  pointer: 'inAppPointer',
};

const IN_APP_MOBILE = {
  chat: 'inAppChatMobile',
  note: 'inAppAnnouncementMobile',
  post: 'inAppBigAnnouncementMobile',
};

const READ_ONLY_IN_APP_WEB = {
  chat: 'readOnlyChat',
  note: 'readOnly',
  post: 'readOnly',
  pointer: 'readOnly',
};

const READ_ONLY_IN_APP_MOBILE = {
  chat: 'readOnlyChat',
  note: 'readOnly',
  post: 'readOnly',
};

const EMAIL = {
  plain: 'emailPlain',
  default: 'email',
};

const READ_ONLY_EMAIL = {
  plain: 'readOnlyEmail',
  default: 'readOnlyEmail',
};

const PUSH = {
  default: 'pushOnly',
};

const READ_ONLY_PUSH = {
  default: 'pushOnlyReadOnly',
};

let inAppWebPreset = function (variation, isReadOnly) {
  let presetHash = isReadOnly ? READ_ONLY_IN_APP_WEB : IN_APP_WEB;
  let messageStyleName = variation.get('messageStyleName');
  switch (messageStyleName) {
    case 'chat':
      return 'inAppChatVideo';
    case 'note':
      return 'inAppAnnouncementVideo';
    case 'post':
      return 'inAppBigAnnouncementVideo';
  }
  return presetHash[messageStyleName];
};

let inAppMobilePreset = function (variation, isReadOnly) {
  let presetHash = isReadOnly ? READ_ONLY_IN_APP_MOBILE : IN_APP_MOBILE;
  return presetHash[variation.get('messageStyleName')];
};

let emailPreset = function (variation, isReadOnly) {
  let presetHash = isReadOnly ? READ_ONLY_EMAIL : EMAIL;
  if (variation.get('emailTemplate.isPlain')) {
    return presetHash.plain;
  } else {
    return presetHash.default;
  }
};

let pushOnlyPreset = function (variation, isReadOnly) {
  let presetHash = isReadOnly ? READ_ONLY_PUSH : PUSH;
  return presetHash.default;
};

export default function (variation, options) {
  if (variation.get('isInApp') && options.isMobile) {
    return inAppMobilePreset(variation, options.isReadOnly);
  } else if (variation.get('isInApp')) {
    return inAppWebPreset(variation, options.isReadOnly);
  } else if (variation.get('isEmail')) {
    return emailPreset(variation, options.isReadOnly);
  } else if (variation.get('isMobilePush')) {
    return pushOnlyPreset(variation, options.isReadOnly);
  } else {
    return 'default';
  }
}
