/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { not, and } from '@ember/object/computed';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import {
  StandardAudienceSummaryConfiguration,
  StandardGoalSummaryConfiguration,
} from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/tooltip-group/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';

export default function generateViewModeConfig(options) {
  let container = options.container;

  let intl = container.lookup('service:intl');
  return ViewModeConfig.create({
    headerComponentConfigs: [StandardViewModeHeaderConfiguration.create({ container })],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: 'Tooltips',
          icon: 'product-tours',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: 'Engaged',
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.extend({
          isVisible: and('contentEditorService.activeObject.hasLinks', 'isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.clicked'),
          view: 'clicks',
          container,
        }),
        StandardStatTabConfiguration.extend({
          _isNotDraft: not('contentEditorService.ruleset.isDraft'),
          isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.goal'),
          view: 'goals',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        ContentSummaryConfiguration.create({ container }),
        StandardAudienceSummaryConfiguration.create({
          container,
          showImplicitPredicates: false,
        }),
        StandardGoalSummaryConfiguration.create({ container }),
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          hideCreatedVia: true,
          noun: 'send',
          verb: 'received',
          emptyStateTitle: "No one's engaged with your tooltips yet",
          emptyStateDescription: ' ',
          component: 'content-editor/view-mode/tooltip-group/reached-stat-list',
          container,
        }),
      ],
      clicks: [
        StandardStatListConfiguration.create({
          stat: 'click',
          component: 'content-editor/view-mode/tooltip-group/clicked-stat-list',
          container,
          emptyStateTitle: "No one's clicked a link in your tooltip yet",
        }),
      ],
      goals: [
        StandardStatListConfiguration.create({
          stat: 'goalSuccess',
          container,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });
}
