/* RESPONSIBLE TEAM: team-phone */
import { type Message } from 'embercom/models/calling-details';
import type CallSummary from './renderable/call-summary';
import { tracked } from '@glimmer/tracking';
import { type CallingDetail } from './call-detail';

type SidebarCallDetailsInterface = Pick<
  CallSummary,
  | 'id'
  | 'recordingUrl'
  | 'transcriptUrl'
  | 'csatRating'
  | 'recordingEnabled'
  | 'detailedState'
  | 'type'
  | 'direction'
  | 'duration'
  | 'initiatedAt'
> & {
  additionalCallDetailsLoaded: boolean;
  recordingDeleted: boolean;
  transcript: Message[] | null;
  transcriptStatus: string | null;
  isVideo: boolean;
  hasRecordingError: boolean;
  forwardedTo: string | null;
  workspacePhoneNumber: string | null;
};

export default class SidebarCallDetails implements SidebarCallDetailsInterface {
  @tracked id: string;
  // Voicemails are also stored in recordings
  @tracked recordingUrl: string | null;
  @tracked transcriptUrl: string | null;
  @tracked direction: string;
  @tracked csatRating: string | null;
  @tracked recordingEnabled: boolean;
  @tracked detailedState: string | null;
  @tracked type: string;
  @tracked initiatedAt: Date;
  @tracked duration: number | null;
  @tracked additionalCallDetailsLoaded = false;
  @tracked recordingDeleted = false;
  @tracked transcript: Message[] | null = null;
  @tracked transcriptStatus: string | null = null;
  @tracked isVideo = false;
  @tracked hasRecordingError = false;
  @tracked forwardedTo: string | null = null;
  @tracked workspacePhoneNumber: string | null = null;

  constructor(callSummary: CallSummary) {
    this.id = callSummary.id;
    this.recordingUrl = callSummary.recordingUrl;
    this.transcriptUrl = callSummary.transcriptUrl;
    this.direction = callSummary.direction;
    this.csatRating = callSummary.csatRating;
    this.recordingEnabled = callSummary.recordingEnabled;
    this.detailedState = callSummary.detailedState;
    this.type = callSummary.type;
    this.direction = callSummary.direction;
    this.initiatedAt = callSummary.initiatedAt;
    this.duration = callSummary.duration;
  }

  async addCallDetails(callDetails: CallingDetail) {
    let isVideo = false;
    let hasRecordingError = false;

    this.recordingDeleted = callDetails.recordingName === 'deleted';

    if (this.recordingUrl && !this.recordingDeleted) {
      ({ isVideo, hasRecordingError } = await this.fileHasVideo(this.recordingUrl));
    }

    this.isVideo = isVideo;
    this.hasRecordingError = hasRecordingError;
    this.forwardedTo = callDetails.forwardedTo;
    this.workspacePhoneNumber = callDetails.workspacePhoneNumber;
    this.transcript = this.formatTranscript(callDetails);
    this.transcriptStatus = callDetails.transcriptStatus;
    this.additionalCallDetailsLoaded = true;
  }

  private formatTranscript(callSummary: CallingDetail): Message[] {
    let originalTranscript = callSummary?.jsonTranscript;

    if (originalTranscript?.length && this.transcriptHasContent(originalTranscript)) {
      let newTranscript = structuredClone(originalTranscript);
      newTranscript.map((message: Message) => {
        let totalSeconds: number = parseFloat(message.start_time);
        let minutes: number = Math.floor(totalSeconds / 60);
        let seconds: number = Math.floor(totalSeconds % 60);

        message.start_time = `${minutes}:${seconds.toString().padStart(2, '0')}`;

        return message;
      });
      return newTranscript;
    }
    return [];
  }

  private transcriptHasContent(transcript: Message[]) {
    return Object.keys(transcript[0]).length > 0;
  }

  private async fileHasVideo(
    path: string,
  ): Promise<{ isVideo: boolean; hasRecordingError: boolean }> {
    return new Promise<{ isVideo: boolean; hasRecordingError: boolean }>((resolve) => {
      let video = document.createElement('video');
      video.src = path;

      let errorHandler: ((error: ErrorEvent) => void) | null = null;
      let loadHandler: (() => void) | null = null;

      let clearHandlers = () => {
        if (errorHandler) {
          video.removeEventListener('error', errorHandler);
        }
        if (loadHandler) {
          video.removeEventListener('loadedmetadata', loadHandler);
        }
      };

      errorHandler = (error) => {
        clearHandlers();

        console.error('Failed to load recording', error.message);
        resolve({ hasRecordingError: true, isVideo: false });
      };

      loadHandler = () => {
        clearHandlers();
        if (video.videoWidth > 0 && video.videoHeight > 0) {
          resolve({ hasRecordingError: false, isVideo: true });
        } else {
          resolve({ hasRecordingError: false, isVideo: false });
        }
      };

      video.addEventListener('error', errorHandler);
      video.addEventListener('loadedmetadata', loadHandler);
    });
  }
}
