/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { assert } from '@ember/debug';
import { isArray, A } from '@ember/array';
import { isPresent, isEmpty } from '@ember/utils';
import { copy } from 'ember-copy';
import { notEmpty, and, equal, readOnly, gt } from '@ember/object/computed';
import percent from 'embercom/lib/percentage-formatter';
import * as SIGNAL_QUERIES from 'embercom/lib/reporting/queries';

import EmberObject, { computed } from '@ember/object';
import moment from 'moment-timezone';
import ajax from 'embercom/lib/ajax';

const SECONDS_PER_MINUTE = 60;
const ISO_FORMAT_WITHOUT_TIME = 'YYYY-MM-DD';

export const SIGNAL_CONFIGS = {
  CONVERSATIONS_PARTICIPATED_IN: { name: 'conversations_participated_in' },
  LEADS_CREATED_BY_SOURCE: { name: 'leads_created_by_source' },
  EMAIL_CAPTURED: { name: 'email_captured' },
  OPPORTUNITY_CREATED: { name: 'opportunity_created' },
  OPPORTUNITY_CREATED_AMOUNT: { name: 'opportunity_created_amount' },
  OPPORTUNITY_INFLUENCED: { name: 'opportunity_influenced' },
  CLOSED_WON: { name: 'closed_won' },
  CLOSED_WON_AMOUNT: { name: 'closed_won_amount' },
  CLOSED_WON_INFLUENCED_AMOUNT: { name: 'closed_won_influenced_amount' },
  LEAD_DISQUALIFIED: { name: 'lead_disqualified' },
  OPPORTUNITY_INFLUENCED_AMOUNT: { name: 'opportunity_influenced_amount' },
  MESSAGE_ROI: { name: 'message_roi' },
  TEAMMATE_PERFORMANCE: { name: 'teammate_performance' },
  DEALS_INFLUENCED: { name: 'deals_influenced' },
  MEDIAN_TIME_TO_CLOSE: { name: 'opportunity_median_time_to_close' },
  EMAILS_SENT: { name: 'emails_sent' },
  EMAILS_OPENED: { name: 'emails_opened' },
  EMAILS_BOUNCED: { name: 'emails_bounced' },
  EMAILS_WITH_COMPLAINTS: { name: 'emails_with_complaints' },
  EMAIL_UNSUBSCRIBES: { name: 'email_unsubscribes' },
  MESSAGES_USER_VOLUME: { name: 'messages_user_volume' },
};

let Signal = EmberObject.extend({
  name: null,
  valueUnit: null,
  timezone: null,
  aggregations: null,
  originalValue: null,
  originalContext: [],
  previousValue: null,
  previousContext: null,
  keysAreDates: true,
  nestedKeysAreDates: true,
  datePickerRangeStart: null,
  datePickerRangeEnd: null,
  interval: null,

  hasPreviousValue: notEmpty('previousValue'),
  hasPreviousContext: notEmpty('previousContext'),
  comparison: and('hasPreviousValue', 'hasPreviousContext'),
  valueUnitIsMinute: equal('valueUnit', 'minute'),

  value: computed('originalValue', function () {
    let numberValue = isNaN(this.originalValue) ? 0 : this.originalValue;
    let value = typeof this.originalValue === 'object' ? this.originalValue : numberValue;
    return this._convertValue(value);
  }),
  context: computed(
    'originalContext',
    'previousContext',
    'keysAreDates',
    'nestedKeysAreDates',
    function () {
      return this.originalContext.map((bucket, i) => this._computedContext(bucket, i));
    },
  ),
  _computedContext(bucket, i) {
    bucket = this._replaceNaNsWithZeros(bucket);
    bucket = this._convertValues(bucket);

    if (this.keysAreDates) {
      bucket = this._convertKeysToAppsTimezone(bucket, this.nestedKeysAreDates);
    }
    if (isPresent(this.previousContext)) {
      bucket = this._addPreviousValue(bucket, i);
    }

    return bucket;
  },
  _addPreviousValue(bucket, index) {
    let previousBucket = this.keysAreDates
      ? this.previousContext[index]
      : this.previousContext.find((element) => element.key === bucket.key);
    if (isPresent(previousBucket)) {
      return {
        ...bucket,
        previousValue: previousBucket.value,
        previousKey: previousBucket.key,
      };
    } else {
      return bucket;
    }
  },
  maxValue: computed('values', function () {
    let values = this.values;
    if (isEmpty(values)) {
      return 0;
    }
    return Math.max(...values);
  }),
  isEmpty: equal('maxValue', 0),
  maxBucket: readOnly('maxBuckets.firstObject'),
  hasMultipleMaxBuckets: gt('maxBuckets.length', 1),
  maxBuckets: computed('context', 'maxValue', function () {
    return this._findMaxBuckets(this.context, this.maxValue);
  }),
  values: computed('context', function () {
    let values = [];
    this.context.forEach((outerBucket) => {
      if (isArray(outerBucket.value)) {
        outerBucket.value.forEach((bucket) => values.push(bucket.value));
      } else {
        values.push(outerBucket.value);
      }
    });
    return values;
  }),
  comparisons: computed('context', 'previousContext', function () {
    let previousContext = this.previousContext || A();
    return this.context.map((contextElement) => {
      let previousContextElement = previousContext.find(
        (element) => element.key === contextElement.key,
      );
      let comparison = {
        key: contextElement.key,
        label: contextElement.label,
        icon: contextElement.icon,
      };
      if (contextElement.url) {
        comparison.url = contextElement.url;
      }

      if (contextElement.unique_count) {
        comparison.value = contextElement.unique_count.value;
        comparison.previousValue = previousContextElement
          ? previousContextElement.unique_count.value
          : 0;
      } else {
        // We could use count here but this is more correct.
        comparison.value = contextElement.value.reduce((prev, current) => prev + current.value, 0);
        comparison.previousValue = previousContextElement
          ? previousContextElement.value.reduce((prev, current) => prev + current.value, 0)
          : 0;
      }
      return comparison;
    });
  }),
  analyticsContext: computed('aggregations.[]', function () {
    let aggregations = this.aggregations;

    if (
      aggregations &&
      aggregations.find((aggregation) => aggregation.grouping === 'conversation_tag_ids')
    ) {
      return 'by_tag';
    }

    return null;
  }),
  signalAsStackableData(asPercentages = false, previousContext = false) {
    let contextKey = previousContext ? 'previousContext' : 'originalContext';
    let context = this.get(contextKey) || [];

    return context.map(({ value: contextValue, key: date }) => {
      let total = contextValue.reduce((sum, element) => sum + element.value, 0);

      let totalsPerRating = contextValue.reduce(
        (acc, element) => ({ ...acc, [element.key]: element.value }),
        {},
      );

      let valuePerRating = contextValue.reduce(
        (acc, element) => ({
          ...acc,
          [element.key]: asPercentages ? percent(total, element.value) : element.value,
        }),
        {},
      );

      let result = {
        ...valuePerRating,
        totalsPerRating,
        totalConversations: total,
        date: (this.keysAreDates ? this._convertKeysToAppsTimezone({ key: date }) : { key: date })
          .key,
      };

      if (asPercentages) {
        result['roundedPercentagesPerRating'] = valuePerRating;
      }

      return result;
    });
  },
  aggregateSignalAsStackablePercentageData(previousContext = false) {
    let contextKey = previousContext ? 'previousContext' : 'context';
    let valueKey = previousContext ? 'previousValue' : 'value';
    let context = this.get(contextKey) || [];
    let total = this.get(valueKey) || 0;

    let totalsPerKey = context.reduce(
      (totals, contextItem) => ({
        ...totals,
        [contextItem.key]: contextItem.value,
      }),
      {},
    );

    let percentagesByKey = context.reduce(
      (stackableData, contextItem) => ({
        ...stackableData,
        [contextItem.key]: percent(total, contextItem.value),
      }),
      {},
    );

    return {
      percentagesByKey,
      totalsPerKey,
      total,
    };
  },
  signalAsStackablePercentageData: computed('context', function () {
    return this.signalAsStackableData(true);
  }),
  sumOfAllBuckets(usePreviousContext) {
    let data = this.signalAsStackableData(false, usePreviousContext);
    return data.reduce((sum, bucket) => sum + bucket.totalConversations, 0);
  },
  sumForBucketIndex(bucketIndex, usePreviousContext) {
    let data = this.signalAsStackableData(false, usePreviousContext);
    return data.reduce((sum, bucket) => {
      return sum + (bucket[bucketIndex] || 0); // Default to 0 if bucket doesn't exist
    }, 0);
  },
  percentForBucketIndex(bucketIndex, usePreviousContext) {
    let total = this.sumOfAllBuckets(usePreviousContext);
    let count = this.sumForBucketIndex(bucketIndex, usePreviousContext);
    return total > 0 ? percent(total, count) : 0;
  },
  roundedPercentForBucketIndex(bucketIndex, usePreviousContext) {
    let indexes = this.bucketIndexes(usePreviousContext);
    let percentagesByKey = indexes.reduce(
      (acc, index) => ({ ...acc, [index]: this.percentForBucketIndex(index, usePreviousContext) }),
      {},
    );
    return percentagesByKey[bucketIndex] || 0;
  },
  bucketIndexes(usePreviousContext) {
    let contextKey = usePreviousContext ? 'previousContext' : 'context';
    let context = this.get(contextKey);
    if (!context) {
      return [];
    }
    return context.reduce((acc, series) => acc.concat(series.value.map((v) => v.key)), []).uniq();
  },
  setComparison(previousSignal) {
    this.setProperties({
      previousValue: copy(previousSignal.get('value')),
      previousContext: copy(previousSignal.get('context')),
    });
  },
  _replaceNaNsWithZeros(bucket) {
    if (isArray(bucket.value)) {
      return {
        ...bucket,
        value: bucket.value.map((value) => this._replaceNaNsWithZeros(value)),
      };
    } else if (isNaN(bucket.value)) {
      return {
        ...bucket,
        value: 0,
      };
    }
    return bucket;
  },
  _convertValues(bucket) {
    if (isArray(bucket.value)) {
      return {
        ...bucket,
        value: bucket.value.map((value) => this._convertValues(value)),
      };
    } else {
      return {
        ...bucket,
        value: this._convertValue(bucket.value),
      };
    }
  },
  _convertValue(value) {
    if (this.valueUnitIsMinute) {
      return value / SECONDS_PER_MINUTE;
    }
    return value;
  },
  _convertKeysToAppsTimezone(bucket, nestedKeysAreDates) {
    if (isArray(bucket.value) && nestedKeysAreDates) {
      return {
        ...bucket,
        value: bucket.value.map((value) => this._convertKeysToAppsTimezone(value)),
      };
    } else if (Number.isInteger(bucket.key)) {
      //If the key is an integer we're getting hourly granularity and so we should construct an ISO format key
      return {
        ...bucket,
        key: moment(this.datePickerRangeStart)
          .tz(this.timezone)
          .startOf('day')
          .add(bucket.key, 'hours')
          .format(),
      };
    } else {
      return {
        ...bucket,
        key: moment(bucket.key).tz(this.timezone).format(),
      };
    }
  },
  _findMaxBuckets(buckets, maxValue, outerKey) {
    let maxBuckets = [];
    buckets.forEach((bucket) => {
      if (isArray(bucket.value)) {
        maxBuckets = maxBuckets.concat(this._findMaxBuckets(bucket.value, maxValue, bucket.key));
      } else if (bucket.value === maxValue) {
        bucket.outerKey = isEmpty(outerKey) ? bucket.key : outerKey;
        maxBuckets.push(bucket);
      }
    });
    return maxBuckets;
  },

  /**
   * Transforms internal originalContext and previousContext with given transformation functions
   * It is possible to pass multiple functions which will be chained
   *
   * See lib/reporting/signal-transformations.js for common transformation functions
   *
   * @return {Signal} New copy of signal model with transformed originalContext and previousContext
   */
  transform(...transformFns) {
    let transformFn = (state) => transformFns.reduce((acc, fn) => fn(acc), state);
    let originalTransformed = transformFn(this.contextAndValuePair({ previous: false }));

    if (this.hasPreviousContext || this.hasPreviousValue) {
      let previousTransformed = transformFn(this.contextAndValuePair({ previous: true }));
      return Signal.create({
        ...this,
        originalContext: originalTransformed.context,
        originalValue: originalTransformed.value,
        previousContext: previousTransformed.context,
        previousValue: previousTransformed.value,
      });
    } else {
      return Signal.create({
        ...this,
        originalContext: originalTransformed.context,
        originalValue: originalTransformed.value,
      });
    }
  },

  contextAndValuePair({ previous }) {
    return {
      context: previous ? this.previousContext : this.originalContext,
      value: previous ? this.previousValue : this.originalValue,
      previous,
    };
  },
}).reopenClass({
  fetchTabularSignal(app, signalConfig, rangeStart, rangeEnd, filters = {}) {
    assert(
      `Unknown signal ${signalConfig.name}, you will need to add it to Signal.SIGNAL_CONFIGS`,
      this.isAllowed(signalConfig.name),
    );
    let params = {
      app_id: app.get('id'),
      signal_name: signalConfig.name,
      range_start: rangeStart,
      range_end: rangeEnd,
      filters,
    };

    let _debug_name = signalConfig.debug_name || signalConfig.name;
    let _debug_start = moment(rangeStart).format(ISO_FORMAT_WITHOUT_TIME);
    params = Object.assign(params, { _debug_name, _debug_start });

    return ajax({
      type: 'POST',
      url: `/ember/reporting/signal.json?_debug_name=${_debug_name}&_debug_start=${_debug_start}`,
      data: JSON.stringify(params),
    }).then((response) => {
      return response;
    });
  },
  fetchSignal(
    app,
    signalConfig,
    valueUnit,
    rangeStart,
    rangeEnd,
    aggregations = [],
    filters = {},
    name,
    interval,
  ) {
    assert(
      `Unknown signal ${signalConfig.name}, you will need to add it to Signal.SIGNAL_CONFIGS`,
      this.isAllowed(signalConfig.name),
    );
    let params = {
      app_id: app.get('id'),
      signal_name: signalConfig.name,
      document_type: signalConfig.document_type,
      range_field: signalConfig.range_field,
      aggregation_type: signalConfig.aggregation_type,
      aggregation_field: signalConfig.aggregation_field,
      additional_aggregation_parameters: signalConfig.additional_aggregation_parameters,
      aggregations: aggregations || signalConfig.aggregations || [],
      range_start: rangeStart,
      range_end: rangeEnd,
      filters,
    };

    let _debug_name = signalConfig.debug_name || signalConfig.name;
    let _debug_start = moment(rangeStart).format(ISO_FORMAT_WITHOUT_TIME);
    params = Object.assign(params, { _debug_name, _debug_start });

    return ajax({
      type: 'POST',
      url: `/ember/reporting/signal.json?_debug_name=${_debug_name}&_debug_start=${_debug_start}`,
      data: JSON.stringify(params),
    }).then((response) => {
      return Signal.create({
        valueUnit,
        aggregations,
        timezone: app.get('timezone'),
        name: _debug_name || name || response.name,
        originalValue: response.value,
        originalContext: response.context,
        datePickerRangeStart: rangeStart,
        datePickerRangeEnd: rangeEnd,
        ...('keys_are_dates' in signalConfig ? { keysAreDates: signalConfig.keys_are_dates } : {}),
        ...('nested_keys_are_dates' in signalConfig
          ? { nestedKeysAreDates: signalConfig.nested_keys_are_dates }
          : {}),
        interval,
      });
    });
  },
  isAllowed(signalName) {
    let allowedNames = Object.values(SIGNAL_CONFIGS)
      .mapBy('name')
      .concat(Object.values(SIGNAL_QUERIES).mapBy('name'));
    return allowedNames.includes(signalName);
  },
});

export default Signal;
