/* RESPONSIBLE TEAM: team-reporting */
import { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';
import type Target from 'embercom/models/reporting/custom/target';
import { inject as service } from '@ember/service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export const VISUALIZATION_TYPES = {
  BAR: 'bar',
  COUNTER: 'counter',
  TABLE: 'tabular',
  LINE: 'line',
  BESPOKE: 'bespoke',
  INVISIBLE: 'invisible',
  HORIZONTAL_BAR_WITH_COUNTER: 'horizontal-bar-with-counter',
  HORIZONTAL_BAR: 'horizontal-bar',
  AREA: 'area',
  DONUT: 'donut',
  HEATMAP: 'heatmap',
  COLUMN_LINE: 'column-line',
  BANNER: 'banner',
};

export const VIZ_TYPE_TO_ICON_MAPPING: { [key: string]: InterfaceIconName } = {
  [VISUALIZATION_TYPES.COUNTER]: 'count',
  [VISUALIZATION_TYPES.BAR]: 'bar-charts',
  [VISUALIZATION_TYPES.LINE]: 'line-chart',
  [VISUALIZATION_TYPES.AREA]: 'area-chart',
  [VISUALIZATION_TYPES.DONUT]: 'segment',
  [VISUALIZATION_TYPES.TABLE]: 'table',
  [VISUALIZATION_TYPES.HEATMAP]: 'heatmap',
  [VISUALIZATION_TYPES.HORIZONTAL_BAR]: 'ab-test',
  [VISUALIZATION_TYPES.COLUMN_LINE]: 'combo-chart',
};

export const HORIZONTAL_BAR_TYPES = [
  VISUALIZATION_TYPES.HORIZONTAL_BAR,
  VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER,
];

export const SEGMENTABLE_VISUALIZATION_TYPES = [
  VISUALIZATION_TYPES.BAR,
  VISUALIZATION_TYPES.LINE,
  VISUALIZATION_TYPES.AREA,
  VISUALIZATION_TYPES.TABLE,
  VISUALIZATION_TYPES.HORIZONTAL_BAR,
];

export const STACKED = 'stacked';
export const SHOW_DATA_LABELS = 'showDataLabels';
export const SHOW_RELATIVE_VALUES = 'showRelativeValues';
export const TARGET = 'target';
export const SHOW_TIME_COMPARISON = 'showTimeComparison';
export const SHOW_SUMMARY_ROW = 'showSummaryRow';
export const VIEW_BY = 'viewBy';
export const SEGMENT_BY = 'segmentBy';
export const MULTI_METRICS = 'multimetrics';
export const ADD_METRICS = 'addMetrics';
export const MULTI_UNITS = 'multiUnits'; // visualizations displaying different metric Units (Tables)

export const UNLIMITED_RESULTS = 'unlimitedResults'; // we allow setting the viewByLimit until 10000 rows

export const TEAM_TEAMMATE_GROUPING_MULTIMETRIC = 'teamTeammateGrouping'; // we allow to viewBy Team and Teammate

export const TERMS_GROUPING_MULTIMETRIC = 'termsGrouping'; // we allow to viewBy for common terms between dataset, including CDAs

export interface VisualizationTraits {
  [STACKED]: boolean;
  [TARGET]: Target;
  [SHOW_TIME_COMPARISON]: boolean;
  [SHOW_RELATIVE_VALUES]: boolean;
  [SHOW_DATA_LABELS]: boolean;
  [SHOW_SUMMARY_ROW]: boolean;
}

type BooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

export type ToggleableVisualizationTraitNames = BooleanKeys<VisualizationTraits>;

const VISUALIZATION_TRAITS = {
  STACKABLE: [STACKED, SHOW_RELATIVE_VALUES],
  TARGETABLE_GRAPH: [SHOW_DATA_LABELS, TARGET],
  FULLY_GROUPABLE: [VIEW_BY, SEGMENT_BY],
  MANAGE_MULTI_METRICS: [MULTI_METRICS, ADD_METRICS],
};

export const VISUALIZATION_FEATURE_SUPPORT = {
  [VISUALIZATION_TYPES.BAR]: [
    ...VISUALIZATION_TRAITS.STACKABLE,
    ...VISUALIZATION_TRAITS.TARGETABLE_GRAPH,
    SHOW_TIME_COMPARISON,
    ...VISUALIZATION_TRAITS.FULLY_GROUPABLE,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    MULTI_UNITS,
  ],
  [VISUALIZATION_TYPES.AREA]: [
    ...VISUALIZATION_TRAITS.STACKABLE,
    ...VISUALIZATION_TRAITS.TARGETABLE_GRAPH,
    ...VISUALIZATION_TRAITS.FULLY_GROUPABLE,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    MULTI_UNITS,
  ],
  [VISUALIZATION_TYPES.LINE]: [
    ...VISUALIZATION_TRAITS.TARGETABLE_GRAPH,
    SHOW_TIME_COMPARISON,
    ...VISUALIZATION_TRAITS.FULLY_GROUPABLE,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    MULTI_UNITS,
  ],
  [VISUALIZATION_TYPES.DONUT]: [SHOW_DATA_LABELS, VIEW_BY, MULTI_UNITS],
  [VISUALIZATION_TYPES.COUNTER]: [TARGET, SHOW_TIME_COMPARISON, MULTI_UNITS],
  [VISUALIZATION_TYPES.TABLE]: [
    TARGET,
    SHOW_TIME_COMPARISON,
    SHOW_SUMMARY_ROW,
    ...VISUALIZATION_TRAITS.FULLY_GROUPABLE,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    MULTI_UNITS,
  ],
  [VISUALIZATION_TYPES.HEATMAP]: [SHOW_DATA_LABELS, MULTI_UNITS],
  [VISUALIZATION_TYPES.HORIZONTAL_BAR]: [
    ...VISUALIZATION_TRAITS.STACKABLE,
    SHOW_DATA_LABELS,
    ...VISUALIZATION_TRAITS.FULLY_GROUPABLE,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    MULTI_UNITS,
  ],
  [VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER]: [
    ...VISUALIZATION_TRAITS.STACKABLE,
    ...VISUALIZATION_TRAITS.FULLY_GROUPABLE,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    MULTI_UNITS,
  ],
  [VISUALIZATION_TYPES.COLUMN_LINE]: [SHOW_DATA_LABELS, VIEW_BY, MULTI_UNITS, MULTI_METRICS],
};

export const MULTIMETRICS_VISUALIZATION_FEATURE_SUPPORT = {
  [VISUALIZATION_TYPES.BAR]: [
    SHOW_DATA_LABELS,
    SHOW_TIME_COMPARISON,
    VIEW_BY,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    TEAM_TEAMMATE_GROUPING_MULTIMETRIC,
    TERMS_GROUPING_MULTIMETRIC,
  ],
  [VISUALIZATION_TYPES.AREA]: [
    SHOW_DATA_LABELS,
    VIEW_BY,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    TEAM_TEAMMATE_GROUPING_MULTIMETRIC,
    TERMS_GROUPING_MULTIMETRIC,
  ],
  [VISUALIZATION_TYPES.LINE]: [
    SHOW_DATA_LABELS,
    SHOW_TIME_COMPARISON,
    VIEW_BY,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    TEAM_TEAMMATE_GROUPING_MULTIMETRIC,
    TERMS_GROUPING_MULTIMETRIC,
  ],
  [VISUALIZATION_TYPES.DONUT]: [],
  [VISUALIZATION_TYPES.COUNTER]: [],
  [VISUALIZATION_TYPES.TABLE]: [
    SHOW_SUMMARY_ROW,
    VIEW_BY,
    MULTI_UNITS,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    UNLIMITED_RESULTS,
    SHOW_TIME_COMPARISON,
    TEAM_TEAMMATE_GROUPING_MULTIMETRIC,
    TERMS_GROUPING_MULTIMETRIC,
  ],
  [VISUALIZATION_TYPES.HEATMAP]: [],
  [VISUALIZATION_TYPES.HORIZONTAL_BAR]: [
    SHOW_DATA_LABELS,
    VIEW_BY,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
    TEAM_TEAMMATE_GROUPING_MULTIMETRIC,
    TERMS_GROUPING_MULTIMETRIC,
  ],
  [VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER]: [
    SHOW_DATA_LABELS,
    VIEW_BY,
    ...VISUALIZATION_TRAITS.MANAGE_MULTI_METRICS,
  ],
  [VISUALIZATION_TYPES.COLUMN_LINE]: [
    SHOW_DATA_LABELS,
    VIEW_BY,
    MULTI_UNITS,
    MULTI_METRICS,
    SHOW_TIME_COMPARISON,
    TEAM_TEAMMATE_GROUPING_MULTIMETRIC,
    TERMS_GROUPING_MULTIMETRIC,
  ],
};

export function visualizationSupportsFeature(
  visualizationType: string,
  feature: string,
  isMultimetric: boolean,
) {
  if (visualizationType === VISUALIZATION_TYPES.BESPOKE) {
    return true;
  }

  if (isMultimetric) {
    return MULTIMETRICS_VISUALIZATION_FEATURE_SUPPORT[visualizationType]?.includes(feature);
  }

  return VISUALIZATION_FEATURE_SUPPORT[visualizationType]?.includes(feature);
}

export function visualizationSupportOptions(visualizationType: string, isMultimetric: boolean) {
  if (isMultimetric) {
    return MULTIMETRICS_VISUALIZATION_FEATURE_SUPPORT[visualizationType];
  }

  return VISUALIZATION_FEATURE_SUPPORT[visualizationType];
}

export default class VisualizationOptions extends Fragment {
  @attr('boolean') declare showTimeComparison: boolean;
  @attr('boolean') declare showSummaryRow: boolean;
  @attr('boolean') declare showRelativeValues: boolean;
  @fragment('reporting/custom/target') declare target: Target | null;

  @service appService: any;

  get app() {
    return this.appService.app;
  }

  ensureOptionsAreCompatible(visualizationType: string) {
    if (!visualizationSupportsFeature(visualizationType, SHOW_TIME_COMPARISON, false)) {
      this.showTimeComparison = false;
    }
    if (!visualizationSupportsFeature(visualizationType, SHOW_SUMMARY_ROW, false)) {
      this.showSummaryRow = false;
    }
    if (!visualizationSupportsFeature(visualizationType, SHOW_RELATIVE_VALUES, false)) {
      this.showRelativeValues = false;
    }

    if (this.target?.value && visualizationSupportsFeature(visualizationType, TARGET, false)) {
      this.target.ensureOptionsAreCompatible(visualizationType);
    } else {
      this.target = null;
    }
  }
}
