/* RESPONSIBLE TEAM: team-workflows */
import {
  CONVERSATION_STARTED_TARGET,
  EVERYONE_TARGET,
  NEW_CONVERSATION_TARGET,
  NEW_COMMENT_TARGET,
  NEW_ADMIN_COMMENT_TARGET,
  NO_TRIGGER_TARGET,
  OUTBOUND_TARGET,
  CONVERSATION_STATUS_CHANGED_TARGET,
  CONVERSATION_ATTRIBUTE_CHANGED_TARGET,
  USER_INACTIVITY_TARGET,
  ADMIN_INACTIVITY_TARGET,
  ADMIN_NOTE_CREATED_TARGET,
  NEW_PHONE_CALL_TARGET,
  TICKET_CREATED_TARGET,
  TICKET_STATE_UPDATED_TARGET,
  WEBHOOK_RECEIVED_TARGET,
  CONVERSATION_ASSIGNMENT_CHANGED_TARGET,
  AI_AGENT_WORKFLOW_TARGET,
  NEW_ZENDESK_TICKET_TARGET,
  NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET,
  NEW_SALESFORCE_CASE_TARGET,
} from 'embercom/lib/operator/custom-bots/constants';

import ButtonCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/button-bot';
import ConversationStartedCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/conversation-started-bot';
import NewCommentCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/new-comment-bot';
import NewAdminCommentCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/new-admin-comment-bot';
import InboundCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/inbound-bot';
import NoTriggerCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/no-trigger-bot';
import OutboundCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/outbound-bot';
import ConversationStatusChangedCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/conversation-status-changed-bot';
import ConversationAttributeChangedCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/conversation-attribute-changed-bot';
import UserInactivityCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/user-inactivity-bot';
import AdminInactivityCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/admin-inactivity-bot';
import AdminNoteCreatedCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/admin-note-created-bot';
import TicketCreatedCustomBotConfig from './custom-bots/ticket-created-bot';
import TicketStateUpdatedCustomBotConfig from './custom-bots/ticket-state-updated-bot';
import type CustomBotConfig from './custom-bot';
import NewPhoneCallBotConfig from 'embercom/objects/operator/configuration/custom-bots/new-phone-call-bot';
import WebhookReceivedCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/webhook-received-bot';
import ConversationAssignmentChangedCustomBotConfig from './custom-bots/conversation-assignment-changed-bot';
import AiAgentWorkflowCustomBotConfig from 'embercom/objects/operator/configuration/custom-bots/ai-agent-workflow-bot';
import NewZendeskTicketBotConfig from './custom-bots/new-zendesk-ticket';
import NewZendeskSunshineConversationBotConfig from './custom-bots/new-zendesk-sunshine-conversation';
import NewSalesforceCaseBotConfig from './custom-bots/new-salesforce-case';

export const CUSTOM_BOT_CONFIG_CLASSES: Record<string, typeof CustomBotConfig> = {
  [OUTBOUND_TARGET]: OutboundCustomBotConfig,
  [EVERYONE_TARGET]: ButtonCustomBotConfig,
  [NEW_CONVERSATION_TARGET]: InboundCustomBotConfig,
  [NO_TRIGGER_TARGET]: NoTriggerCustomBotConfig,
  [CONVERSATION_STARTED_TARGET]: ConversationStartedCustomBotConfig,
  [NEW_COMMENT_TARGET]: NewCommentCustomBotConfig,
  [NEW_ADMIN_COMMENT_TARGET]: NewAdminCommentCustomBotConfig,
  [CONVERSATION_STATUS_CHANGED_TARGET]: ConversationStatusChangedCustomBotConfig,
  [CONVERSATION_ATTRIBUTE_CHANGED_TARGET]: ConversationAttributeChangedCustomBotConfig,
  [CONVERSATION_ASSIGNMENT_CHANGED_TARGET]: ConversationAssignmentChangedCustomBotConfig,
  [USER_INACTIVITY_TARGET]: UserInactivityCustomBotConfig,
  [ADMIN_INACTIVITY_TARGET]: AdminInactivityCustomBotConfig,
  [ADMIN_NOTE_CREATED_TARGET]: AdminNoteCreatedCustomBotConfig,
  [NEW_PHONE_CALL_TARGET]: NewPhoneCallBotConfig,
  [TICKET_CREATED_TARGET]: TicketCreatedCustomBotConfig,
  [TICKET_STATE_UPDATED_TARGET]: TicketStateUpdatedCustomBotConfig,
  [WEBHOOK_RECEIVED_TARGET]: WebhookReceivedCustomBotConfig,
  [AI_AGENT_WORKFLOW_TARGET]: AiAgentWorkflowCustomBotConfig,
  [NEW_ZENDESK_TICKET_TARGET]: NewZendeskTicketBotConfig,
  [NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET]: NewZendeskSunshineConversationBotConfig,
  [NEW_SALESFORCE_CASE_TARGET]: NewSalesforceCaseBotConfig,
};

export const CUSTOM_BOT_CONFIG = {
  [OUTBOUND_TARGET]: new OutboundCustomBotConfig(),
  [EVERYONE_TARGET]: new ButtonCustomBotConfig(),
  [NEW_CONVERSATION_TARGET]: new InboundCustomBotConfig(),
  [NO_TRIGGER_TARGET]: new NoTriggerCustomBotConfig(),
  [CONVERSATION_STARTED_TARGET]: new ConversationStartedCustomBotConfig(),
  [NEW_COMMENT_TARGET]: new NewCommentCustomBotConfig(),
  [NEW_ADMIN_COMMENT_TARGET]: new NewAdminCommentCustomBotConfig(),
  [CONVERSATION_STATUS_CHANGED_TARGET]: new ConversationStatusChangedCustomBotConfig(),
  [CONVERSATION_ATTRIBUTE_CHANGED_TARGET]: new ConversationAttributeChangedCustomBotConfig(),
  [CONVERSATION_ASSIGNMENT_CHANGED_TARGET]: new ConversationAssignmentChangedCustomBotConfig(),
  [USER_INACTIVITY_TARGET]: new UserInactivityCustomBotConfig(),
  [ADMIN_INACTIVITY_TARGET]: new AdminInactivityCustomBotConfig(),
  [ADMIN_NOTE_CREATED_TARGET]: new AdminNoteCreatedCustomBotConfig(),
  [NEW_PHONE_CALL_TARGET]: new NewPhoneCallBotConfig(),
  [TICKET_CREATED_TARGET]: new TicketCreatedCustomBotConfig(),
  [TICKET_STATE_UPDATED_TARGET]: new TicketStateUpdatedCustomBotConfig(),
  [WEBHOOK_RECEIVED_TARGET]: new WebhookReceivedCustomBotConfig(),
  [AI_AGENT_WORKFLOW_TARGET]: new AiAgentWorkflowCustomBotConfig(),
  [NEW_ZENDESK_TICKET_TARGET]: new NewZendeskTicketBotConfig(),
  [NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET]: new NewZendeskSunshineConversationBotConfig(),
  [NEW_SALESFORCE_CASE_TARGET]: new NewSalesforceCaseBotConfig(),
} as const;

export type BotConfigTarget = keyof typeof CUSTOM_BOT_CONFIG;
