/* RESPONSIBLE TEAM: team-reporting */
import Model, { attr } from '@ember-data/model';

export default class ReportSubscription extends Model {
  @attr('string') adminId;
  @attr('array') teammateIds;
  @attr('string', { defaultValue: 'dashboard' }) reportType;
  @attr('string') cadence;
  @attr('number') cadenceTimeOfDay;
  @attr('string') cadenceDayOfWeek;
  @attr('number') cadenceDateOfMonth;
  @attr() jsonBlob;

  unsubscribe() {
    let adapter = this.store.adapterFor('report-subscription');
    return adapter.unsubscribe(this);
  }
}
