/* RESPONSIBLE TEAM: team-standalone */
import EditorConfig, {
  type PathConfigGenerationParams,
} from 'embercom/objects/visual-builder/configuration/editor';
import { NewZendeskSunshineConversationPathConfig } from 'embercom/objects/visual-builder/configuration/path';

export default class NewZendeskSunshineConversationBotConfig extends EditorConfig {
  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new NewZendeskSunshineConversationPathConfig({ path, editorState });
  }

  get supportsConversationChannelTargeting(): boolean {
    return false;
  }

  get supportsSideSheet(): boolean {
    return false;
  }
}
