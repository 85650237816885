/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { availableUiSeatTypes } from 'embercom/lib/settings/seats/constants';
import { tracked } from '@glimmer/tracking';

class SeatSelection {
  @tracked engage;
  @tracked support;
  @tracked proactive_support;
  @tracked marketing;
  @tracked collaborator;
}

export default function calculateCommonSeats(teammates) {
  let seatsSelections = new SeatSelection();

  availableUiSeatTypes().forEach((seat) => {
    let allTeammatesHasSeat = teammates.every((teammate) => teammate.seats.includes(seat));
    let someTeammatesHasSeat = teammates.some((teammate) => teammate.seats.includes(seat));

    if (allTeammatesHasSeat) {
      seatsSelections[seat] = true;
    } else if (someTeammatesHasSeat) {
      seatsSelections[seat] = undefined;
    } else {
      seatsSelections[seat] = false;
    }
  });

  return seatsSelections;
}

export function calculateCommonSeatTypes(seatsSelections) {
  let seatTypes = [];
  let availableSeatTypes = availableUiSeatTypes();
  availableSeatTypes.forEach((seat) => {
    if (seatsSelections[seat] !== false) {
      seatTypes.push(seat);
    }
  });

  return seatTypes;
}

export function seatsByTeammates(teammates, seatTypes, seatsSelection) {
  let seatsAssignment = {};

  teammates.forEach((teammate) => {
    seatsAssignment[teammate.id] = [];

    seatTypes.forEach((seat) => {
      if (changeButtonPresentForSeatType(seatsSelection[seat]) && teammate.seats.includes(seat)) {
        seatsAssignment[teammate.id].push(seat);
      } else if (!changeButtonPresentForSeatType(seatsSelection[seat])) {
        seatsAssignment[teammate.id].push(seat);
      }
    });
  });

  return seatsAssignment;
}

export function countAmountOfSeatModified(teammates, seatTypeByUsers) {
  let availableSeatTypes = availableUiSeatTypes();
  let seatsByTeammates = availableSeatTypes.reduce((accumulator, seat) => {
    return { ...accumulator, [seat]: 0 };
  }, {});

  teammates.forEach((teammate) => {
    teammate.seats.forEach((seat) => seatsByTeammates[seat]--);
    seatTypeByUsers[teammate.id].forEach((seat) => seatsByTeammates[seat]++);
  });

  return seatsByTeammates;
}

export function initialSeatCountForSeatsWithDifferentSelection(
  seatsTypesByTeammates,
  seatsSelections,
) {
  let initialSeatCount = {};
  Object.values(seatsTypesByTeammates).forEach((seats) => {
    seats.forEach((seat) => {
      initialSeatCount[seat] = initialSeatCount[seat] ? initialSeatCount[seat] + 1 : 1;
    });
  });
  return initialSeatCount;
}

function changeButtonPresentForSeatType(seat) {
  return seat === undefined;
}
