/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import ModelWithPermissions from 'embercom/models/model-with-permissions';

export default class JitSettingsPermissions extends ModelWithPermissions {
  @service appService;
  @service intl;

  @attr('string', { defaultValue: 'all' }) accessType;
  @attr('boolean', { defaultValue: false }) includeUnassigned;
  @attr({ defaultValue: () => [] }) assigneeBlockedList;

  get conversationAccess() {
    return this.getProperties(['accessType', 'includeUnassigned', 'assigneeBlockedList']);
  }

  get app() {
    return this.appService.app;
  }

  validate() {
    if (this.accessType === 'all_except' && !this.assigneeBlockedList?.length > 0) {
      throw new Error(this.intl.t('app.models.role.notifications.error'));
    }
  }
}
