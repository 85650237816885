/* RESPONSIBLE TEAM: team-proactive-support */
import { EntityType } from 'embercom/models/data/entity-types';

export enum OutboundInitialPartSenderType {
  Unknown = -1,
  Admin = EntityType.Admin,
  Team = EntityType.Team,
  Bot = EntityType.Bot,
  CustomEmailAddress = EntityType.SenderEmailAddress,
}
