/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo } from '@ember-data/model';
import RulesetLink from '../ruleset-link';
import type Banner from 'embercom/models/banners/banner';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class extends RulesetLink {
  @belongsTo('banners/banner', { async: false }) declare object: Banner;

  get objectType() {
    return objectTypes.banner;
  }
}
