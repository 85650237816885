/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class ApiMappableObject extends Fragment {
  @attr('string') declare name: string;
  @attr('string') declare fullPath: string;
  @attr('string') declare exampleValue: string;
  @attr('string') declare dataType: string;
}
