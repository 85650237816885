/* RESPONSIBLE TEAM: team-workflows */
import type SendToHubspot from 'embercom/models/operator/visual-builder/step/send-to-hubspot';
import StepConfig, { type StepConfigParams } from '../step';

export default class SendToHubspotConfiguration extends StepConfig {
  declare step: SendToHubspot;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/send-to-hubspot',
      ...params,
    });
  }
}
