/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr } from '@ember-data/model';

const IS_VALID_EXTENSION_REGEX = /^\.[A-Za-z0-9]+$/;

export default class AttachmentSettings extends Model {
  @attr()
  declare appId: string;

  @attr()
  declare attachUploadsInline: boolean;

  @attr('boolean', { defaultValue: false })
  declare allowedAttachmentFiletypesEnabled: boolean;

  @attr('string')
  declare allowedAttachmentFiletypesList: string;

  @attr('boolean', { defaultValue: false })
  declare allowedAttachmentFiletypesAgreedToRisk: boolean;

  @attr('boolean', { defaultValue: false })
  declare userConversationAttachmentsEnabled: boolean;

  @attr('boolean', { defaultValue: false })
  declare userConversationCameraEnabled: boolean;

  @attr('boolean', { defaultValue: false })
  declare userConversationMediaEnabled: boolean;

  @attr('boolean', { defaultValue: false })
  declare userConversationGifsEnabled: boolean;

  @attr('boolean', { defaultValue: false })
  declare userConversationFilesEnabled: boolean;

  get isFiletypeListValid() {
    if (this.allowedAttachmentFiletypesList) {
      let items = this.allowedAttachmentFiletypesList
        .split(',')
        .map((i) => i.trim())
        .filter(Boolean);

      return items.every((i) => IS_VALID_EXTENSION_REGEX.test(i));
    } else {
      return true;
    }
  }
}
