/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr, belongsTo, type SyncHasMany } from '@ember-data/model';
import { fragmentArray, fragment } from 'ember-data-model-fragments/attributes';
import { dependentKeyCompat } from '@ember/object/compat';
import { isPresent, isBlank } from '@ember/utils';
import { type Paragraph } from 'embercom/models/common/blocks/paragraph';
import { type Block } from 'embercom/models/common/blocks/block';
import type TaskGroup from './task-group';
import { type EmberCPValidationsCompat } from 'embercom/validations/typescript/ember-cp-validations-compat';
import { validateTask } from 'embercom/validations/typescript/checklists/task';
import generateUUID from 'embercom/lib/uuid-generator';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export enum LogicalOperator {
  AND = 0,
  OR = 1,
}

export enum ActionType {
  ReadOnly = 0,
  OpenUrl = 1,
  ProductTour = 2,
  Article = 3,
}

export enum EventType {
  UserEvent = 0,
}

export default class Task extends Model implements EmberCPValidationsCompat {
  @service declare intl: IntlService;
  @attr('number') declare order: number;
  @attr('number') declare actionContentId: number | null;
  @attr('string') declare actionContentUrl: string | null;
  @attr('number') declare actionType: ActionType;
  @attr('string') declare actionUrl: string | null;
  @attr('boolean', { defaultValue: false }) declare openActionUrlInNewTab: boolean;
  @attr('boolean', { defaultValue: true }) declare canBeManuallyResolved: boolean;
  @attr('number') declare eventType: EventType | null;
  @attr('number') declare eventId: number | null;
  @attr('string') declare customActionButtonText: string | null;
  @attr('number') declare estimatedTime: number;
  @attr('number', { defaultValue: LogicalOperator.AND })
  declare resolveRulesLogicalOperator: LogicalOperator;
  @fragment('predicates/predicate-group')
  declare predicateGroup: any;
  @fragment('predicates/predicate-group')
  declare eventPredicateGroup: any;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
  })
  declare titleBlocks: SyncHasMany<Paragraph>;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
  })
  declare subtitleBlocks: SyncHasMany<Paragraph>;
  @fragmentArray('common/blocks/block', {
    polymorphic: true,
    typeKey: 'modelKey',
  })
  declare bodyBlocks: SyncHasMany<Block>;
  @belongsTo('checklists/task-group', { inverse: 'tasks' }) declare taskGroup: TaskGroup;
  accordionSectionId: string = generateUUID();
  isActiveTask = false;

  @dependentKeyCompat
  get validations() {
    return validateTask(this);
  }

  get hasTitle() {
    return isPresent(this.humanReadableTitle);
  }

  get estimatedMinutes() {
    return this.estimatedTime / 60;
  }

  get hasBody() {
    return this.serializedBodyBlocks.any((block: Block) => !isBlank(block.text));
  }

  get hasButtonAction() {
    return this.actionType !== ActionType.ReadOnly;
  }

  get serializedTitleBlocks() {
    return this.titleBlocks.serialize();
  }

  get serializedSubtitleBlocks() {
    return this.subtitleBlocks.serialize();
  }

  get serializedBodyBlocks() {
    return this.bodyBlocks.serialize();
  }

  get hasUrl() {
    return isPresent(this.actionUrl);
  }

  get humanReadableOrder() {
    return this.order + 1;
  }

  get humanReadableTitle() {
    return this.serializedTitleBlocks.firstObject?.text;
  }

  get openUrlAction() {
    return this.actionType === ActionType.OpenUrl;
  }

  get productTourAction() {
    return this.actionType === ActionType.ProductTour;
  }

  get articleAction() {
    return this.actionType === ActionType.Article;
  }

  get canBeAutoResolved() {
    return isPresent(this.eventId) || this.predicateGroup.hasPredicates;
  }

  get canBeResolved() {
    return this.canBeManuallyResolved || this.canBeAutoResolved;
  }

  get resolveRulesLogicalOperatorString() {
    return this.resolveRulesLogicalOperator === LogicalOperator.AND
      ? this.intl.t('outbound.checklists.tasks.and')
      : this.intl.t('outbound.checklists.tasks.or');
  }

  get hasCompanyRule() {
    return Boolean(this.predicateGroup._findPredicate((pred: any) => pred.isCompanyPredicate));
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.titleBlocks.any((block) => block.hasDirtyAttributes) ||
      this.subtitleBlocks.any((block) => block.hasDirtyAttributes) ||
      this.bodyBlocks.any((block) => block.hasDirtyAttributes)
    );
  }

  @dependentKeyCompat
  get contentHash() {
    return {
      titleBlocks: this.serializedTitleBlocks,
      subtitleBlocks: this.serializedSubtitleBlocks,
      bodyBlocks: this.serializedBodyBlocks,
      canBeManuallyResolved: this.canBeManuallyResolved,
      actionUrl: this.actionUrl,
      eventType: this.eventType,
      eventId: this.eventId,
      eventPredicateGroup: this.eventPredicateGroup,
      predicateGroup: this.predicateGroup,
    };
  }
}
