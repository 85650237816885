/* RESPONSIBLE TEAM: team-proactive-support */
import PushNodeConfiguration from 'embercom/objects/series/push-node-configuration';
import TourNodeConfiguration from 'embercom/objects/series/tour-node-configuration';
import ConditionNodeConfiguration from 'embercom/objects/series/condition-node-configuration';
import ChatNodeConfiguration from 'embercom/objects/series/chat-node-configuration';
import CarouselNodeConfiguration from 'embercom/objects/series/carousel-node-configuration';
import PostNodeConfiguration from 'embercom/objects/series/post-node-configuration';
import WaitNodeConfiguration from 'embercom/objects/series/wait-node-configuration';
import TaggerNodeConfiguration from 'embercom/objects/series/tagger-node-configuration';
import EmailNodeConfiguration from 'embercom/objects/series/email-node-configuration';
import BotNodeConfiguration from 'embercom/objects/series/bot-node-configuration';
import BannerNodeConfiguration from 'embercom/objects/series/banner-node-configuration';
import SplitterNodeConfiguration from 'embercom/objects/series/splitter-node-configuration';
import OutboundWebhookNodeConfiguration from 'embercom/objects/series/outbound-webhook-node-configuration';
import SMSNodeConfiguration from 'embercom/objects/series/sms-node-configuration';
import SurveyNodeConfiguration from 'embercom/objects/series/survey-node-configuration';
import WhatsappNodeConfiguration from 'embercom/objects/series/whatsapp-node-configuration';
import ChecklistNodeConfiguration from 'embercom/objects/series/checklist-node-configuration';

import PushGraphConfiguration from 'embercom/objects/series/node/push-graph-configuration';
import TourGraphConfiguration from 'embercom/objects/series/node/tour-graph-configuration';
import ChatGraphConfiguration from 'embercom/objects/series/node/chat-graph-configuration';
import PostGraphConfiguration from 'embercom/objects/series/node/post-graph-configuration';
import CarouselGraphConfiguration from 'embercom/objects/series/node/carousel-graph-configuration';
import WaitGraphConfiguration from 'embercom/objects/series/node/wait-graph-configuration';
import ConditionGraphConfiguration from 'embercom/objects/series/node/condition-graph-configuration';
import TaggerGraphConfiguration from 'embercom/objects/series/node/tagger-graph-configuration';
import EmailGraphConfiguration from 'embercom/objects/series/node/email-graph-configuration';
import BotGraphConfiguration from 'embercom/objects/series/node/bot-graph-configuration';
import BannerGraphConfiguration from 'embercom/objects/series/node/banner-graph-configuration';
import SplitterGraphConfiguration from 'embercom/objects/series/node/splitter-graph-configuration';
import OutboundWebhookGraphConfiguration from 'embercom/objects/series/node/outbound-webhook-graph-configuration';
import SMSGraphConfiguration from 'embercom/objects/series/node/sms-graph-configuration';
import SurveyGraphConfiguration from 'embercom/objects/series/node/survey-graph-configuration';
import WhatsappGraphConfiguration from 'embercom/objects/series/node/whatsapp-graph-configuration';
import ChecklistGraphConfiguration from 'embercom/objects/series/node/checklist-graph-configuration';

import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export const allNodeConfigurations = [
  new ChatNodeConfiguration(),
  new PostNodeConfiguration(),
  new EmailNodeConfiguration(),
  new BotNodeConfiguration(),
  new TourNodeConfiguration(),
  new BannerNodeConfiguration(),
  new PushNodeConfiguration(),
  new CarouselNodeConfiguration(),
  new ConditionNodeConfiguration(),
  new WaitNodeConfiguration(),
  new TaggerNodeConfiguration(),
  new SplitterNodeConfiguration(),
  new OutboundWebhookNodeConfiguration(),
  new SMSNodeConfiguration(),
  new SurveyNodeConfiguration(),
  new WhatsappNodeConfiguration(),
  new ChecklistNodeConfiguration(),
];

export const nodeGraphConfigurationClasses = {
  [objectTypes.chat]: ChatGraphConfiguration,
  [objectTypes.post]: PostGraphConfiguration,
  [objectTypes.email]: EmailGraphConfiguration,
  [objectTypes.customBot]: BotGraphConfiguration,
  [objectTypes.tour]: TourGraphConfiguration,
  [objectTypes.banner]: BannerGraphConfiguration,
  [objectTypes.push]: PushGraphConfiguration,
  [objectTypes.carousel]: CarouselGraphConfiguration,
  [objectTypes.condition]: ConditionGraphConfiguration,
  [objectTypes.wait]: WaitGraphConfiguration,
  [objectTypes.tagger]: TaggerGraphConfiguration,
  [objectTypes.splitter]: SplitterGraphConfiguration,
  [objectTypes.outboundWebhook]: OutboundWebhookGraphConfiguration,
  [objectTypes.sms]: SMSGraphConfiguration,
  [objectTypes.survey]: SurveyGraphConfiguration,
  [objectTypes.whatsapp]: WhatsappGraphConfiguration,
  [objectTypes.checklist]: ChecklistGraphConfiguration,
};
