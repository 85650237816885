/* RESPONSIBLE TEAM: team-help-desk-experience */
import { isEmpty } from 'underscore';

type RichLink = {
  value: string;
  url: string;
};

type Metadata = Record<string, string | RichLink>;

export interface EventWireFormat {
  id: string;
  created_at: string;
  event_name: string;
  metadata: Metadata;
  subject: Record<string, unknown>;
  type: string;
}

export default class Event {
  readonly id: string;
  readonly createdAt: string;
  readonly name: string;
  readonly metadata: Metadata;
  readonly subject: Record<string, unknown>;
  readonly type: string;

  constructor(
    id: string,
    name: string,
    createdAt: string,
    metadata: Metadata,
    subject: Record<string, unknown>,
    type: string,
  ) {
    this.id = id;
    this.name = name;
    this.createdAt = createdAt;
    this.metadata = metadata;
    this.subject = subject;
    this.type = type;
  }

  static deserialize(json: EventWireFormat) {
    return new Event(
      json.id,
      json.event_name,
      json.created_at,
      json.metadata,
      json.subject,
      json.type,
    );
  }

  get hasMetadata() {
    return !isEmpty(this.metadata);
  }
}
