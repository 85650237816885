/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { A } from '@ember/array';
import { assign } from '@ember/polyfills';
import EmberObject, { computed } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import ActivityFeedQuery from 'embercom/lib/users/activity-feed-query';
import { notEmpty } from '@ember/object/computed';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import { fragment } from 'ember-data-model-fragments/attributes';
import Admin from 'embercom/models/admin';

export default Model.extend({
  new: false,
  type: attr(),
  created_at: attr(),
  event_name: attr(),
  metadata: attr(),
  admin_id: attr(),
  admin: computed('admin_id', function () {
    if (this.admin_id) {
      return Admin.peekAndMaybeLoad(this.store, this.admin_id);
    }
  }),
  author_id: attr(),
  author: computed('author_id', function () {
    if (this.author_id) {
      return Admin.peekAndMaybeLoad(this.store, this.author_id);
    }
  }),
  conversation: belongsTo('conversation'),
  note: belongsTo('note'),
  segment: belongsTo('segment'),
  tag: belongsTo('tag', { async: true }),
  subject: fragment('activity-subject'),
  hasSubject: notEmpty('subject'),
  isExpanded: false,
  hasTag: notEmpty('tag'),
  hasSegment: notEmpty('segment'),
  hasEvent: notEmpty('event_name'),
}).reopenClass({
  query(app, user, options, realTimeEventService) {
    let feedQueryOptions = assign(
      { app, user, realTimeEventService, profileType: 'user' },
      options,
    );
    return ActivityFeedQuery.create(feedQueryOptions);
  },
  adminQuery(app, admin, options) {
    let feedQueryOptions = assign(
      { app, admin_id: admin.get('id'), profileType: 'admin' },
      options,
    );
    return ActivityFeedQuery.create(feedQueryOptions);
  },
  feedUrl() {
    return '/ember/events.json';
  },
  getFeedItems(options) {
    let store = getEmberDataStore();

    return ajax({
      url: this.feedUrl(),
      type: 'POST',
      data: JSON.stringify(options),
    }).then(
      function (response) {
        let feedItems = A();
        store.pushPayload({ 'activity-feed-items': response.events });

        if (response.events) {
          response.events.forEach(function (feedItem) {
            try {
              store.pushPayload({ 'activity-feed-items': [feedItem] });
              let item = store.peekRecord('activity-feed-item', feedItem.id);
              item.set('profileType', options.profileType);

              feedItems.push(item);
            } catch (e) {
              console.error('activity feed item load failed', e, feedItems, feedItem);
            }
          });
        }
        return EmberObject.create({
          items: feedItems,
          totalCount: feedItems.length,
          pageSize: response.page_size,
        });
      },
      function (e) {
        console.error(e, 'feed item load failed');
      },
    );
  },
});
