/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';

const Translations = EmberObject.extend({
  message: EmberObject.create({
    unsubscribe: 'Unsubscribe',
    dont_want_to_get_emails_like_these: "Don't want to get emails like this?",
    powered_by_intercom: 'Powered by Intercom',
  }),
  dontWantToGetEmailsLikeThese: readOnly('message.dont_want_to_get_emails_like_these'),
  poweredByLink: computed('message.powered_by_intercom', function () {
    return this.get('message.powered_by_intercom').replace(
      'Intercom',
      '<a href="https://www.intercom.io">Intercom</a>',
    );
  }),
  unsubscribeLink: readOnly('message.unsubscribe'),
});

Translations.reopenClass({
  async fetch(appId) {
    let res = await ajax({
      url: `/ember/i18n.json`,
      type: 'GET',
      data: { app_id: appId },
    });

    return res.map((responseObject) => Translations.create(responseObject));
  },
});

export default Translations;
