/* RESPONSIBLE TEAM: team-product-guidance */
// When adding roles to these, you will need to add them also to
// https://github.com/intercom/intercom/blob/master/app/models/teammate_role_data.rb
export const useCases = {
  use_case_support: 'use-case-support', // 'Support customers'
  use_case_engage: 'use-case-engage', // 'Engage with customers'
  use_case_lead_gen: 'use-case-lead-gen', // 'Generate, acquire, or convert leads'
  use_case_other: 'other', // 'Other'
};

export const experiences = {
  exp_novice: 'exp-novice', // 'It's my first time setting up a support solution'
  exp_intermediate: 'exp-intermediate', // 'I've used other support solutions but not Intercom'
  exp_pro: 'exp-pro', // 'I'm familiar with Intercom'
};

export const intentLevels = {
  intent_not_ready: 'intent-not-ready', // 'Not ready at all, were just browsing.'
  intent_somewhat_ready: 'intent-somewhat-ready', // 'Somewhat ready, we're considering options.'
  intent_very_ready: 'intent-very-ready', // 'Absolutely ready, we urgently need a new customer service tool'
};

export const tools = {
  tool_freshdesk: 'tool-freshdesk', // 'Freshdesk'
  tool_front: 'tool-front', // 'Front'
  tool_gorgias: 'tool-gorgias', // 'Gorgias'
  tool_help_scout: 'tool-help-scout', // 'Helpscout'
  tool_hubspot: 'tool-hubspot', // 'Hubspot'
  tool_internal_solution: 'tool-internal-solution', // 'Internal solution'
  tool_salesforce: 'tool-salesforce', // 'Salesforce'
  tool_zendesk: 'tool-zendesk', // 'Zendesk'
  tool_email: 'tool-email', // 'Email'
  tool_other: 'other', // 'Other'
};

const supportRoles = {
  support_exec: 'support-exec', // 'Support Executive/Director'
  support_manage: 'support-manage', // 'Support Manager'
  support_support_rep: 'support-support-rep', // 'Support Rep'
  support_support_ops: 'support-support-ops', // 'Support Operations/Engineering'
  support_other: 'other', // 'Other'
};

const marketingRoles = {
  marketing_exec: 'marketing-exec', // 'Marketing Executive/Director'
  marketing_manage: 'marketing-manage', // 'Marketing Manager'
  marketing_demand_gen: 'marketing-demand-gen', // 'Demand Generation
  marketing_lifecycle: 'marketing-lifecycle', // 'Lifecycle Marketing
  marketing_product: 'marketing-product', // 'Product Marketing
  marketing_marketing_ops: 'marketing-marketing-ops', // 'Marketing Operations/Engineering
  marketing_other: 'other', // 'Other'
};

const salesRoles = {
  sales_exec: 'sales-exec', // 'Sales Executive/Director'
  sales_manage: 'sales-manage', // 'Sales Manager'
  sales_new: 'sales-new', // 'Sales Rep - New Business'
  sales_existing: 'sales-existing', // 'Sales Rep - Existing Business'
  sales_operations: 'sales-operations', // 'Sales Operations/Engineer'
  sales_cust_success: 'sales-cust-success', // 'Customer Success'
  sales_other: 'other', // 'Other'
};

const productRoles = {
  prod_exec: 'prod-exec', // 'Product/Engineering Executive or Director'
  prod_manage: 'prod-manage', // 'Product Manager'
  prod_design: 'prod-design', // 'Designer'
  prod_research: 'prod-research', // 'Researcher'
  prod_eng: 'prod-eng', // 'Engineer'
  prod_other: 'other', // 'Other'
};

const otherRoles = {
  other_exec: 'other-exec', // 'Executive/Director'
  other_finance: 'other-finance', // 'Finance'
  other_legal: 'other-legal', // 'Legal'
  other_analytics: 'other-analytics', // 'Data'
  other_it: 'other-it', // 'IT'
  other_other: 'other', // 'Other'
};

export const departmentsAndRoles = {
  dept_support: {
    name: 'dept-support', // 'Customer Support/Service'
    roles: supportRoles,
  },
  dept_marketing: {
    name: 'dept-marketing', // 'Marketing'
    roles: marketingRoles,
  },
  dept_sales: {
    name: 'dept-sales', // 'Sales & Success'
    roles: salesRoles,
  },
  dept_product: {
    name: 'dept-product', // 'Product/Engineering'
    roles: productRoles,
  },
  dept_other: {
    name: 'other', // 'Other'
    roles: otherRoles,
  },
};

export const newDepartments = {
  dept_support: 'new-dept-support', // 'Support'
  dept_success: 'new-dept-success', // 'Customer Success'
  dept_marketing: 'new-dept-marketing', // 'Marketing'
  dept_sales: 'new-dept-sales', // 'Sales'
  dept_product: 'new-dept-product', // 'Product'
  dept_ceo: 'new-dept-ceo', // 'CEO/Founder/Owner'
  dept_other: 'other', // 'Other'
};
