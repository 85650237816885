/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';
import NewsfeedsValidations from 'embercom/validations/newsfeeds';
import type NewsItemModel from './item';

export default class NewsfeedModel extends Model.extend(NewsfeedsValidations) {
  @attr('string') declare name: string;
  @attr('string') declare platform: string;
  @hasMany('news/item', { inverse: 'newsfeeds' }) declare newsItems: SyncHasMany<NewsItemModel>;

  get nameForDisplay() {
    return this.name || 'Untitled';
  }
}
