/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import ModelWithPermissions from 'embercom/models/model-with-permissions';
import type Admin from 'embercom/models/admin';
import type IntlService from 'embercom/services/intl';

export type AccessType = 'all' | 'all_except' | 'only_them' | 'only_them_or_their_teams';

export default class Permission extends ModelWithPermissions {
  @service declare appService: any;
  @service declare intl: IntlService;
  @attr() declare app_id: string;
  @attr() declare admin_id: string;
  @attr('string') declare role_id: string;
  @attr('boolean', { defaultValue: false }) declare has_inbox_access: boolean;
  @attr('string', { defaultValue: 'all' }) declare accessType: AccessType;
  @attr('boolean', { defaultValue: false }) declare includeUnassigned: boolean;
  @attr({ defaultValue: () => [] }) declare assigneeBlockedList?: Array<string>;

  set reassignments(value: any) {
    if (value) {
      this.setProperties(Permission.transformReassignments(value));
    }
  }

  get seatTypes() {
    if (this.app.hasMultipleSeatTypes) {
      let admin = this.store.peekRecord('admin', this.admin_id) as Admin | undefined;

      return admin?.seats || [];
    } else {
      return [];
    }
  }

  get role() {
    if (!this.role_id) {
      return null;
    }

    let role = this.store.peekRecord('role', this.role_id);
    if (role) {
      return role;
    }

    return this.store.findRecord('role', this.role_id);
  }

  get conversationAccessHasChanged() {
    let changes = this.changedAttributes();
    return (
      'accessType' in changes ||
      (this.accessType === 'all_except' && 'assigneeBlockedList' in changes) ||
      ['only_them', 'only_them_or_their_teams'].includes(this.accessType)
    );
  }

  get copilotUsageAccessHasChanged() {
    let changes = this.changedAttributes();
    return 'can_inbox__access_copilot' in changes;
  }

  hasPermissionChanged(permissionName: string) {
    let changes = this.changedAttributes();
    return permissionName in changes;
  }

  get app() {
    return this.appService.app;
  }

  validate() {
    let blockedList = this.assigneeBlockedList ?? [];
    if (this.accessType === 'all_except' && blockedList.length < 1) {
      throw new Error(this.intl.t('app.models.role.notifications.error'));
    }
  }

  static transformReassignments(reassignments: any) {
    return {
      reassign_auto_messages_admin_id: reassignments.messages,
      reassign_replies_admin_id: reassignments.conversations,
      reassign_replies_teams: Object.entries(reassignments.teams).map(([team_id, assignee_id]) => ({
        team_id,
        assignee_id,
      })),
      reassign_owner_admin_id: reassignments.ownedUsersLeadsAndAccounts,
      reassign_articles_author_id: reassignments.articles,
      reassign_report_owner_admin_id: reassignments.reportOwner,
    };
  }

  @attr() declare reassign_auto_messages_admin_id: boolean;
  @attr() declare reassign_replies_admin_id: boolean;
  @attr() declare reassign_owner_admin_id: boolean;
  @attr() declare reassign_articles_author_id: boolean;
  @attr() declare reassign_report_owner_admin_id: boolean;
  @attr({ defaultValue: () => [] }) declare reassign_replies_teams: Array<string>;
}
