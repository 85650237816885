/* RESPONSIBLE TEAM: team-proactive-support */
import type { statisticKeys } from './constants';

export enum SenderType {
  admin = 0,
  owner = 1,
  team = 2,
  customEmailAddress = 3,
  noSender = 4,
  ownerWithCustomEmailAddressFallback = 5,
}

export type StatisticKeyName = keyof typeof statisticKeys;
