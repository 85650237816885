/* RESPONSIBLE TEAM: team-proactive-support */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';

export default class ScheduledStateChange extends Fragment {
  @service appService;
  @attr('number') changeStatus;
  @attr('number') changeType;
  @attr('date') changeAt;
  @attr('number') timezoneType;

  get appTimezone() {
    return this.appService.app.timezone;
  }

  get humanReadableChangeAt() {
    return moment(this.changeAt).tz(this.appTimezone).format(dateAndTimeFormats.dateHumanized);
  }
}
