/* RESPONSIBLE TEAM: team-frontend-tech */
import { modifier } from 'ember-modifier';
import { cancel, throttle } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { isPresent } from '@ember/utils';

export default modifier(
  (_element, [onScroll], { scrollSelector, throttleTime, triggerImmediately }) => {
    if (scrollSelector) {
      let throttleObj;
      let eventHandler = function (event) {
        let immediately = isPresent(triggerImmediately) ? triggerImmediately : true;
        throttleObj = throttle(
          onScroll,
          event.target.scrollTop,
          throttleTime || ENV.APP._15MS,
          immediately,
        );
      };

      let scrollContainerElement = document.querySelector(scrollSelector);

      if (scrollContainerElement) {
        scrollContainerElement.addEventListener('scroll', eventHandler);

        // clean up when the element is removed
        return () => {
          cancel(throttleObj);
          scrollContainerElement.removeEventListener('scroll', eventHandler);
        };
      } else {
        console.warn(`The scroll container doesn't exist (selector "${scrollSelector}" was used)`);
      }
    }
  },
);
