/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { not, and } from '@ember/object/computed';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import {
  StandardGoalSummaryConfiguration,
  StandardScheduleSummaryConfiguration,
} from 'embercom/objects/content-editor/summaries/standard-summary-configurations';
import CarouselAudienceSummaryConfiguration from 'embercom/objects/content-editor/summaries/audience/content/carousel/carousel-audience-summary-configuration';
import CarouselTriggerProgrammaticallySummaryConfiguration from 'embercom/objects/content-editor/summaries/trigger-programmatically/carousel/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/carousel/summary-configuration';
import StandardSeriesViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-series-header-configuration';
import MoreDropdownConfiguration from 'embercom/objects/content-editor/headers/more-dropdown-configuration';

export default function generateViewModeConfig(options) {
  let container = options.container;
  let intl = container.lookup('service:intl');
  return ViewModeConfig.create({
    seriesHeaderComponentConfig: StandardSeriesViewModeHeaderConfiguration.create({
      container,
      moreDropdownConfig: MoreDropdownConfiguration.create({
        hideDuplicateOption: true,
        hideCsvExportOption: false,
        hideTagOption: true,
        hideDeleteOption: true,
        hideVersionsOption: true,
        container,
      }),
    }),
    headerComponentConfigs: [StandardViewModeHeaderConfiguration.create({ container })],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs: [
        StandardContentTabConfiguration.create({
          label: 'Carousel',
          icon: 'ios',
          view: 'content',
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.sent'),
          view: 'receipts',
          container,
        }),
        StandardStatTabConfiguration.create({
          label: intl.t('outbound.stats-system.tabs.completed'),
          view: 'completions',
          container,
        }),
        StandardStatTabConfiguration.extend({
          _isNotDraft: not('contentEditorService.ruleset.isDraft'),
          isVisible: and('contentEditorService.ruleset.hasGoal', '_isNotDraft'),
        }).create({
          label: intl.t('outbound.stats-system.tabs.goal'),
          view: 'goals',
          container,
        }),
      ],
    }),
    activeViewConfiguration: ActiveViewConfiguration.create({
      content: [
        ContentSummaryConfiguration.create({ container }),
        CarouselAudienceSummaryConfiguration.create({ container }),
        CarouselTriggerProgrammaticallySummaryConfiguration.create({ container }),
        StandardScheduleSummaryConfiguration.create({
          title: 'Frequency and scheduling',
          container,
        }),
        StandardGoalSummaryConfiguration.create({ container, title: 'Set your goal' }),
      ],
      receipts: [
        StandardStatListConfiguration.create({
          stat: 'receipt',
          noun: 'send',
          verb: 'received',
          emptyStateTitle: "No one's been sent your carousel yet",
          emptyStateDescription: ' ', // We need a non-empty value as override in order to hide description
          container,
        }),
      ],
      completions: [StandardStatListConfiguration.create({ stat: 'completion', container })],
      goals: [
        StandardStatListConfiguration.create({
          filters: [{ text: 'Hit the goal after opening', value: stats.open }],
          stat: 'goalSuccess',
          container,
        }),
      ],
    }),
    footerComponentConfigs: [],
  });
}
