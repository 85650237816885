/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import type BenchmarkAvatar from './benchmark-avatar';

export default class BenchmarkAdmin extends Model {
  @attr declare name: string;
  @attr declare first_name: string;
  @attr declare last_name: string;
  @attr declare email: string;
  @fragment('benchmark-avatar') declare avatar: BenchmarkAvatar;
  @attr declare created_at: string;
  @attr declare is_me: boolean;
  @attr declare is_github_bot: boolean;
  @attr declare is_operator: boolean;
  @attr declare is_facebook_bot: boolean;
  @attr declare has_photo_avatar: boolean;
  @attr declare has_default_avatar: boolean;
  @attr declare assign_conversations_on_reply: boolean;
  @attr declare has_enabled_2fa: boolean;
  @attr declare current_app_id: string;
  @attr declare is_team: boolean;
  @attr declare is_app_team: boolean;
  @attr declare is_bot: boolean;
  @attr declare member_ids: string[];
  @attr declare member_ids_with_priority: Record<string, unknown>;
  @attr declare localized_sender_name: string;
  @attr declare avatar_emoji: string;
  @attr declare role: string;
  @attr declare seats: string[];
  @attr declare status: string;
  @attr declare department: string;
  @attr declare has_password: boolean;
  @attr declare advanced_shortcuts_enabled: boolean;
  @attr declare away_mode_enabled: boolean;
  @attr declare reassign_conversations: boolean;
  @attr declare away_status_reason: string;
  @attr declare is_unknown: boolean;
  @attr declare locale: string;
  @attr declare avatar_shape: string;
  @attr declare taggings: string[];
  @attr declare enforced_email_verification_required: boolean;
}
