/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import { inject as service } from '@ember/service';

export default function generateBotNotifcationMessages(container, humanReadableObjectName) {
  return NotificationMessage.extend({
    contentEditorService: service(),
    humanReadableObjectName,
    async confirmation() {
      // Adding 'team_capacity' predicate to default ignore attributes list
      await this.contentEditorService.ruleset.fetchAudiencePreview(['team_capacity']);

      let ruleset = this.contentEditorService.ruleset;

      let title = `Ready to set your ${this.humanReadableObjectName} live?`;
      let confirmButtonText = `Set live`;

      let body;
      if (ruleset.hasControlGroup) {
        body = `Part of your audience will be sent this ${this.humanReadableObjectName} and the rest will join the control group.`;
        if (!ruleset.isMatchBehaviorStatic) {
          body +=
            ' Going forward, anyone who matches your rules in the future will be split similarly.';
        }
      } else {
        body = `Your ${this.humanReadableObjectName} will be sent to users who match your rules, and anyone who matches your rules in the future.`;
      }
      if (!ruleset.isDraft) {
        body += ` Anyone who has already received this ${this.humanReadableObjectName} will not receive it again.`;
      }

      return { title, body, confirmButtonText };
    },
  }).create({
    success: `The ${humanReadableObjectName} was set live`,
    failure: `We couldn't set this ${humanReadableObjectName} live. Send us a message and we'll do our best to help out.`,
    container,
  });
}
