/* RESPONSIBLE TEAM: team-tickets-1 */
import Model, { attr, belongsTo } from '@ember-data/model';
import type ConversationAttributeDescriptor from './descriptor';

export default class ListOption extends Model {
  @attr('string') declare label: string;
  @attr('string') declare description: string | null;
  @attr('boolean') declare archived: boolean;

  @belongsTo('conversation-attributes/descriptor', { async: false, inverse: 'listOptions' })
  declare descriptor: ConversationAttributeDescriptor;

  get trimmedLabel() {
    return this.label.trim();
  }
}
