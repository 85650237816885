/* RESPONSIBLE TEAM: team-help-desk-experience */
import { modifier as buildModifier } from 'ember-modifier';
import { toggleMessengerVisibility } from 'embercom/lib/intercom-widget-helper';

function hideIntercomMessenger() {
  toggleMessengerVisibility({ hide: true });

  return function cleanupObserver() {
    toggleMessengerVisibility({ hide: false });
  };
}

let modifier = buildModifier(hideIntercomMessenger, { eager: false });
export default modifier;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'hide-intercom-messenger': typeof modifier;
  }
}
