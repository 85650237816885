/* RESPONSIBLE TEAM: team-proactive-support */
import { belongsTo, attr } from '@ember-data/model';
import RulesetLink from 'embercom/models/matching-system/ruleset-link';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default RulesetLink.extend({
  object: belongsTo('matching-system/control-group', { async: false }),
  objectType: objectTypes.controlGroup,
  rulesetId: attr('number'),
});
