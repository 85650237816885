/* RESPONSIBLE TEAM: team-workflows */
import type RemoveTagFromConversation from 'embercom/models/operator/visual-builder/step/remove-tag-from-conversation';
import StepConfig, { type StepConfigParams } from '../step';

export default class RemoveTagFromConversationConfiguration extends StepConfig {
  declare step: RemoveTagFromConversation;

  constructor(params: Omit<StepConfigParams, 'component'>) {
    super({
      component: 'workflows/graph-editor/node-items/steps/remove-tag-from-conversation',
      ...params,
    });
  }
}
