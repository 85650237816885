/* RESPONSIBLE TEAM: team-help-desk-experience */
import type Session from 'embercom/services/session';
import { ParentAction, ChildAction } from './action';
import { inject as service } from '@ember/service';
import { SUPPORTED_LANGUAGES } from 'embercom/services/intl';

export default class SwitchLanguageAction extends ParentAction {
  @service declare session: Session;

  id = 'switch-language';
  icon = 'globe' as const;
  paneComponent = 'inbox2/command-k/switch-language/pane';

  buildTopLevelItems(query: string): Array<ChildAction> {
    return this.buildResults(query);
  }

  buildResults(query: string): Array<ChildAction> {
    let upperQuery = query.trim().toLocaleUpperCase();
    return this.allOptions.filter((action) =>
      action.label.toLocaleUpperCase().includes(upperQuery),
    );
  }

  private get allOptions(): Array<ChildAction> {
    return SUPPORTED_LANGUAGES.map(({ locale: id, language: label }) => {
      return new ChildAction({
        parent: this,
        id,
        label,
      });
    });
  }
}
