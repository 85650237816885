/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type RenderableData } from 'embercom/objects/inbox/renderable-data';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { EntityType } from 'embercom/models/data/entity-types';
import DuplicateConversationSummary, {
  type DuplicateConversationSummaryWireFormat,
} from 'embercom/objects/inbox/duplicate-conversation-summary';
import AdminSummary, { type AdminSummaryWireFormat } from 'embercom/objects/inbox/admin-summary';

interface DuplicateConversationNoteWireFormat {
  renderable_type: RenderableType;
  duplicate_conversation_summaries: DuplicateConversationSummaryWireFormat[];
  entity: AdminSummaryWireFormat;
}

export default class DuplicateConversationNote implements RenderableData {
  readonly renderableType: RenderableType = RenderableType.DuplicateConversationNote;
  readonly duplicateConversationSummaries: DuplicateConversationSummary[];
  readonly entity: AdminSummary;

  get creatingEntity() {
    return { type: EntityType.Bot, id: this.entity.id, object: this.entity };
  }

  get styles() {
    return {
      backgroundColor: 'bg-yellow-light dark:bg-dm-yellow-dark',
      textColor: 'text-yellow-dark dark:text-dm-white',
      backgroundColorHover: 'inbox2__light-and-dark-yellow-hover-background-color',
    };
  }

  get ticketStyles() {
    return {
      backgroundColor: 'bg-white dark:bg-black',
      textColor: 'text-black dark:text-dm-white',
      backgroundColorHover: 'bg-gray-lightest dark:bg-dm-gray-darker',
    };
  }

  constructor(
    duplicateConversationSummaries: DuplicateConversationSummary[],
    entity: AdminSummary,
  ) {
    this.duplicateConversationSummaries = duplicateConversationSummaries;
    this.entity = entity;
  }

  static deserialize(json: DuplicateConversationNoteWireFormat): DuplicateConversationNote {
    let duplicateConversationSummaries = json.duplicate_conversation_summaries.map(
      (duplicate_conversation_summary: DuplicateConversationSummaryWireFormat) =>
        DuplicateConversationSummary.deserialize(duplicate_conversation_summary),
    );
    return new DuplicateConversationNote(
      duplicateConversationSummaries,
      AdminSummary.deserialize(json.entity),
    );
  }
}
