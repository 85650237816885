/* RESPONSIBLE TEAM: team-customer-data-platform */
import Model, { attr } from '@ember-data/model';
import { REFERENCES_MANY, REFERENCES_ONE } from 'embercom/models/objects/constants/reference-types';

export default class ObjectsReference extends Model {
  @attr('string') declare attributeDescriptorId: string;
  @attr('string') declare objectTypeIdentifier: string;
  @attr('string') declare referenceType: string;
  @attr('string') declare referencedObjectTypeIdentifier: string;

  get referencesOne() {
    return this.referenceType === REFERENCES_ONE;
  }

  get referencesMany() {
    return this.referenceType === REFERENCES_MANY;
  }
}
