/* RESPONSIBLE TEAM: team-proactive-support */
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import NewsItemValidations from 'embercom/validations/news-item';
import newsItemEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/news-item';
import { dependentKeyCompat } from '@ember/object/compat';
import { sanitizeUrl } from '@intercom/pulse/lib/sanitize';

export const replyTypeOptions = {
  text: 0,
  reaction: 1,
  disabled: 2,
};

export const defaultReactions = [
  {
    index: 0,
    unicodeEmoticon: '💜',
  },
  {
    index: 1,
    unicodeEmoticon: '😴',
  },
  {
    index: 2,
    unicodeEmoticon: '👎',
  },
  {
    index: 3,
    unicodeEmoticon: '🎉',
  },
];

export default class NewsItemModel extends Model.extend(NewsItemValidations) {
  @service store;
  @service appService;

  @attr('number') sentAs;
  @belongsTo('matching-system/ruleset-links/news-item', { inverse: 'object' }) rulesetLink;
  @hasMany('news/localized-news-item-content', { inverse: 'item' }) localizedNewsItemContents;
  @hasMany('news/newsfeed', { inverse: 'newsItems' }) newsfeeds;
  @hasMany('news/label', { inverse: 'newsItems' }) labels;
  @attr('array') newsfeedIds;
  @attr('array') labelIds;

  @dependentKeyCompat
  get contentHash() {
    return this.localizedNewsItemContents.map((localizedContent) => localizedContent.contentHash);
  }

  @dependentKeyCompat
  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.localizedNewsItemContents.any((localizedContent) => localizedContent.hasUnsavedChanges)
    );
  }

  get app() {
    return this.appService.app;
  }

  get localizedContents() {
    return this.localizedNewsItemContents;
  }

  get localizedContent() {
    return this.localizedNewsItemContents.firstObject;
  }

  editorConfig() {
    let container = getOwner(this);
    let app = this.app;
    return newsItemEditConfigGenerator({ container, app });
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this.localizedNewsItemContents.forEach((content) => content.rollbackAttributes());
  }

  get publicUrlPath() {
    return `news/${this.id}-${this.titleSlug}`;
  }

  get title() {
    return this.localizedNewsItemContents.firstObject.title;
  }

  get titleSlug() {
    let truncatedTitle = this.title ? this.title.split(' ').slice(0, 5).join(' ') : '';
    return sanitizeUrl(
      truncatedTitle
        .toLowerCase()
        .replace(/\p{Emoji}/gu, '')
        .replace(/\s{2,}/g, ' ')
        .replaceAll(/[^a-z0-9]/gi, '-'),
    );
  }

  get isReactionsReply() {
    return this.localizedContent.replyType === replyTypeOptions.reaction;
  }

  get reactionSet() {
    return this.localizedContent.reactionSet;
  }
}
