/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';
import { fragment } from 'ember-data-model-fragments/attributes';
import intermoji from '@intercom/intermoji';

export default class InboxViewModel extends Model {
  @attr('string') name;
  @fragment('predicates/predicate-group') predicateGroup;
  @attr('string') avatar_emoji;
  @attr('number') other_admins_with_view_visible_count;

  get analyticsData() {
    return {
      object: 'inbox-view',
      id: this.id,
    };
  }

  get emojiUri() {
    return intermoji.twemojiSVGUri(this.avatar_emoji);
  }
}
