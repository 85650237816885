/* RESPONSIBLE TEAM: team-channels */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';

interface ForwardedWireFormat {
  renderable_type: RenderableType;
  new_conversation_id: number;
  admin_summary: AdminSummaryWireFormat;
  forwarded_message_id: string;
}

export default class Forwarded {
  readonly renderableType: RenderableType = RenderableType.Forwarded;
  readonly newConversationId: number;
  readonly adminSummary: AdminSummary;
  readonly forwardedMessageId: string;

  constructor(newConversationId: number, adminSummary: AdminSummary, forwardedMessageId: string) {
    this.newConversationId = newConversationId;
    this.adminSummary = adminSummary;
    this.forwardedMessageId = forwardedMessageId;
  }

  get creatingEntity() {
    return { type: EntityType.Admin, id: this.adminSummary.id, object: this.adminSummary };
  }

  static deserialize(json: ForwardedWireFormat): Forwarded {
    let admin = AdminSummary.deserialize(json.admin_summary);
    return new Forwarded(json.new_conversation_id, admin, json.forwarded_message_id);
  }
}
