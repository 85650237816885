/* RESPONSIBLE TEAM: team-messenger */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty, isNone } from '@ember/utils';
import LocalizedCarouselContentValidations from 'embercom/validations/localized-carousel-content';
import {
  lastScreenOptionsValues,
  permissionTypeValues,
} from 'embercom/models/data/carousel/action-constants';
import { tracked } from '@glimmer/tracking';

export default class localizedCarouselContent extends Model.extend(
  LocalizedCarouselContentValidations,
) {
  @service store;
  @service appService;
  @tracked screenWhoseButtonActionHasBeenChanged = null;

  @hasMany('carousels/carousel-screen', { inverse: 'localizedCarouselContent', async: false })
  carouselScreens;
  @belongsTo('carousels/carousel', { inverse: 'localizedCarouselContents', async: false }) carousel;
  @attr('string') locale;

  get hasUnsavedChanges() {
    return this.hasDirtyAttributes;
  }

  deletedScreens = [];

  init() {
    super.init(...arguments);
    if (isEmpty(this.carouselScreens)) {
      this._setupDefaultScreens();
    }
  }

  getLastScreen() {
    return this.carouselScreens.findBy('order', this.carouselScreens.length - 1);
  }

  @action hideButtonAutoArrangeHelperText() {
    this.screenWhoseButtonActionHasBeenChanged = null;
  }

  get showButtonOrderingHelperText() {
    return this.screenWhoseButtonActionHasBeenChanged;
  }

  get contentHash() {
    let hash = this.serialize();
    delete hash['id'];
    delete hash['carousel'];
    hash.carousel_screens.forEach((screen) => {
      delete screen['id'];
      delete screen['localized_carousel_content'];
      screen.carousel_actions.forEach((action) => {
        delete action['id'];
        delete action['carousel_screen'];
      });
    });
    return hash;
  }

  async _setupDefaultScreens() {
    let appId = this.appService.app.id;
    let lookAndFeel = await this.store.findRecord('messenger-settings/look-and-feel', appId);
    this.setTransientAppearanceProperties(lookAndFeel);
    let firstScreen = this._createScreen(lookAndFeel, 0);
    firstScreen.clearActions();
    firstScreen.createPrimaryAction('continue');

    this.carouselScreens.pushObject(firstScreen);

    let lastScreen = this._createScreen(lookAndFeel, 1);
    this.carouselScreens.pushObject(lastScreen);
    this.carousel.save();
  }

  _createScreen(lookAndFeel, order) {
    return this.store.createRecord('carousels/carousel-screen', {
      order,
      backgroundColor: '#ffffff',
      textColor: '#222222',
      actionsColor: lookAndFeel.get('defaultBrand.messengerActionColor'),
      localizedCarouselContent: this,
    });
  }

  beforeSave() {
    this.carouselScreens.forEach((screen) => screen.beforeSave());
  }

  afterSave() {
    this._clearUnsavedCarouselScreens();
    this.carouselScreens.forEach((screen) => screen.afterSave());
  }

  rollbackAttributes() {
    this._clearUnsavedCarouselScreens();
    this.deletedScreens.forEach((screen) => this.carouselScreens.pushObject(screen));
    this.carouselScreens.forEach((screen) => screen.rollbackAttributes());
    this.carouselScreens = this.carouselScreens.sortBy('order');
    super.rollbackAttributes();
    this.deletedScreens = [];
  }

  setTransientAppearanceProperties(lookAndFeel) {
    this.carousel.set('backgroundColor', '#ffffff');
    this.carousel.set('textColor', '#222222');
    this.carousel.set('actionsColor', lookAndFeel.get('defaultBrand.messengerActionColor'));
    this.carousel.set('verticalAlignment', 'top');
  }

  addScreen() {
    let lastScreen = this.getLastScreen();
    if (lastScreenOptionsValues.includes(lastScreen.primaryAction.type)) {
      this._showBannerIfRequired(lastScreen);
      lastScreen.clearActions();
      lastScreen.createPrimaryAction('continue');
    }
    // add a new screen
    this.store.createRecord('carousels/carousel-screen', {
      backgroundColor: this.carousel.get('backgroundColor'),
      textColor: this.carousel.get('textColor'),
      localizedCarouselContent: this,
      verticalAlignment: this.carousel.get('verticalAlignment'),
    });
    this.resetOrderOfCarouselScreens();
  }

  reorderScreens(screens) {
    let lastScreen = screens.lastObject;
    let lastScreenActionType = lastScreen.primaryAction.type;
    if (
      !lastScreenOptionsValues.includes(lastScreenActionType) &&
      !permissionTypeValues.includes(lastScreenActionType)
    ) {
      lastScreen.clearActions();
      lastScreen.createPrimaryAction('dismiss');
    }
    screens.map((screen) => {
      if (screen.order !== lastScreen.order) {
        if (lastScreenOptionsValues.includes(screen.primaryAction.type)) {
          this._showBannerIfRequired(screen);
          screen.clearActions();
          screen.createPrimaryAction('continue');
        }
      }
      return screen;
    });
    this.carouselScreens = screens;
  }

  removeScreen(index) {
    this.deletedScreens.push(this.carouselScreens.objectAt(index));
    this.carouselScreens.removeAt(index);
    this.resetOrderOfCarouselScreens();
    let lastScreen = this.carouselScreens.lastObject;
    let lastScreenActionType = lastScreen.primaryAction.type;
    if (
      !lastScreenOptionsValues.includes(lastScreenActionType) &&
      !permissionTypeValues.includes(lastScreenActionType)
    ) {
      lastScreen.clearActions();
      lastScreen.createPrimaryAction('dismiss');
    }
  }

  resetOrderOfCarouselScreens() {
    this.carouselScreens.forEach((cs, index) => (cs.order = index));
  }

  _clearUnsavedCarouselScreens() {
    this.carouselScreens
      .filter((carouselScreen) => isNone(carouselScreen.id))
      .forEach((carouselScreen) => {
        this.store.unloadRecord(carouselScreen);
      });
  }

  _showBannerIfRequired(screen) {
    if (screen.primaryAction.type !== 'dismiss') {
      this.screenWhoseButtonActionHasBeenChanged = screen.order + 1;
    }
  }
}
