/* RESPONSIBLE TEAM: team-phone */
import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  friendlyName: validator('presence', { presence: true }),
  city: validator('presence', { presence: true }),
  customerName: validator('presence', { presence: true }),
  isoCountry: validator('presence', { presence: true }),
  postalCode: validator('presence', { presence: true }),
  region: validator('presence', { presence: true }),
  street: validator('presence', { presence: true }),
});

export default class RegulatoryAddress extends Model.extend(Validations) {
  @attr('string') declare sid: string;
  @attr('string') declare friendlyName: string;
  @attr('string') declare customerName: string;
  @attr('string') declare isoCountry: string;
  @attr('string') declare street: string;
  @attr('string') declare streetSecondary: string;
  @attr('string') declare city: string;
  @attr('string') declare region: string;
  @attr('string') declare postalCode: string;
}
