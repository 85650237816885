/* RESPONSIBLE TEAM: team-proactive-support */
import StandardSummaryConfiguration from 'embercom/objects/content-editor/summaries/standard-summary-configuration';

export default StandardSummaryConfiguration.extend({
  init(inputs) {
    let title = '';
    this._super({
      title,
      component: 'content-editor/summaries/content/webhook/summary',
      isVisible: true,
    });
  },
});
