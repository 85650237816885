/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import StripeInvoice from './invoice';

export default class UpcomingStripeInvoice extends StripeInvoice {}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'billing/stripe/upcoming-invoice': UpcomingStripeInvoice;
  }
}
