/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import ViewModeConfig from 'embercom/objects/content-editor/view-mode-configuration';
import TabsConfiguration from 'embercom/objects/content-editor/tabs-configuration';
import ActiveViewConfiguration from 'embercom/objects/content-editor/active-view-configuration';
import StandardContentTabConfiguration from 'embercom/objects/content-editor/tabs/standard-content-tab-configuration';
import StandardStatTabConfiguration from 'embercom/objects/content-editor/tabs/standard-stat-tab-configuration';
import StandardStatListConfiguration from 'embercom/objects/content-editor/stats/standard-stat-list-configuration';
import ContentSummaryConfiguration from 'embercom/objects/content-editor/summaries/content/news-item/summary-configuration';
import StandardViewModeHeaderConfiguration from 'embercom/objects/content-editor/headers/view-mode/standard-header-configuration';
import { StandardScheduleSummaryConfiguration } from 'embercom/objects/content-editor/summaries/standard-summary-configurations';

export default function generateViewModeConfig(options) {
  let { container } = options;

  let intl = container.lookup('service:intl');
  let tabComponentConfigs = [
    StandardContentTabConfiguration.create({
      label: 'News',
      icon: 'inapp-post',
      view: 'content',
    }),
    StandardStatTabConfiguration.create({
      label: intl.t('outbound.stats-system.tabs.opened'),
      view: 'receipts',
      container,
    }),
    StandardStatTabConfiguration.create({
      label: intl.t('outbound.stats-system.tabs.clicked'),
      view: 'clicks',
      container,
    }),
    StandardStatTabConfiguration.create({
      label: 'Reacted',
      view: 'reactions',
      tabComponent: 'content-editor/tabs/reactions/stat-tab',
      container,
    }),
  ];

  let activeViewConfigurationParams = {
    content: [
      ContentSummaryConfiguration.create({ container }),
      StandardScheduleSummaryConfiguration.create({
        container,
        verb: 'show',
      }),
    ],
    receipts: [
      StandardStatListConfiguration.create({
        stat: 'receipt',
        hideCreatedVia: true,
        noun: 'read',
        verb: 'opened',
        emptyStateTitle: 'No one have seen your news item yet',
        emptyStateDescription: ' ', // We need a non-empty value as override in order to hide description
        container,
      }),
    ],
    clicks: [StandardStatListConfiguration.create({ stat: 'click', container })],
    reactions: [StandardStatListConfiguration.create({ stat: 'reaction', container })],
  };

  return ViewModeConfig.create({
    headerComponentConfigs: [StandardViewModeHeaderConfiguration.create({ container })],
    tabsConfiguration: TabsConfiguration.create({
      container,
      tabComponentConfigs,
    }),
    activeViewConfiguration: ActiveViewConfiguration.create(activeViewConfigurationParams),
    footerComponentConfigs: [],
  });
}
