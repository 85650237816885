/* RESPONSIBLE TEAM: team-channels */
import Model, { hasMany, attr } from '@ember-data/model';
import WhatsappValidations from 'embercom/validations/whatsapp';
import { getOwner } from '@ember/application';
import whatsappEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/whatsapp';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';

export default class WhatsappMessage extends Model.extend(WhatsappValidations) {
  @service appService;

  @hasMany('whatsapp/localized-whatsapp-message-content', { inverse: 'whatsapp' })
  localizedWhatsappMessageContents;
  @attr('number') whatsappIntegrationId;
  @attr('string') templateName;

  @dependentKeyCompat
  get contentHash() {
    return this.localizedWhatsappMessageContents.map(
      (localizedWhatsappContent) => localizedWhatsappContent.contentHash,
    );
  }

  get locale() {
    return this.localizedWhatsappMessageContents.firstObject?.locale;
  }

  set locale(value) {
    if (this.localizedWhatsappMessageContents.firstObject) {
      this.localizedWhatsappMessageContents.firstObject.locale = value;
    }
  }

  editorConfig() {
    let container = getOwner(this);
    return whatsappEditConfigGenerator({ container, whatsapp: this });
  }

  rollbackAttributes() {
    super.rollbackAttributes();
    this.localizedWhatsappMessageContents.forEach((content) => content.rollbackAttributes());
  }
}
