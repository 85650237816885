/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { filterBy, notEmpty, readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import { findBy, flatMap } from '@intercom/pulse/lib/computed-properties';

export default Model.extend({
  identifier: attr('string'),
  levels: hasMany('onboarding/home/level'),
  forceAllGuide: attr('boolean'),
  migratedFromGuideId: attr('number'),
  hasAnyCompletedSteps: attr('boolean'),

  lockedLevels: filterBy('levels', 'locked'),
  incompleteLevels: filterBy('levels', 'notCompleted'),
  latestAvailableLevel: findBy('levels', 'available'),

  allSteps: flatMap('levels', 'steps'),

  latestAvailableStep: readOnly('latestAvailableLevel.availableSteps.firstObject'),
  hasAvailableSteps: notEmpty('latestAvailableStep'),

  latestAvailableOrPendingCompletedStep: findBy(
    'latestAvailableLevel.steps',
    'availableOrPendingCompleted',
  ),
  latestPendingCompletedStep: findBy('latestAvailableLevel.steps', 'pendingCompleted'),

  manageCustomerSupportChannelsStep: findBy('allSteps', 'identifier', 'customer_support_channels'),
  customizeMessengerStep: findBy('allSteps', 'identifier', 'customize_messenger'),

  findStepByIdentifier(stepIdentifier) {
    return this.allSteps.findBy('identifier', stepIdentifier);
  },
  installMessengerStep: computed('allSteps.@each.identifier', function () {
    return this.allSteps.find((step) => step.identifier.startsWith('install_messenger'));
  }),
  installVisitorsMessengerStep: findBy('allSteps', 'identifier', 'set_up_messenger_visitors'),
  installLoggedInMessengerStep: findBy('allSteps', 'identifier', 'set_up_messenger_logged_in'),

  async loadSteps() {
    let levels = this.levels.map((level) => level.loadSteps());
    return await Promise.all(levels);
  },
});
