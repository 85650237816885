/* RESPONSIBLE TEAM: team-ai-agent */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class QuestionGroupStats extends Fragment {
  @attr('number') declare totalCount: number;
  @attr('number') declare unansweredCount: number;
  @attr('number') declare routedToTeamCount: number;
  @attr('number') declare abandonedCount: number;

  get unansweredPercentage(): number {
    if (this.totalCount === 0) {
      return 0;
    }
    return Math.round((this.unansweredCount / this.totalCount) * 100);
  }

  get routedToTeamPercentage(): number {
    if (this.totalCount === 0) {
      return 0;
    }
    return Math.round((this.routedToTeamCount / this.totalCount) * 100);
  }

  get abandonedPercentage(): number {
    if (this.totalCount === 0) {
      return 0;
    }
    return Math.round((this.abandonedCount / this.totalCount) * 100);
  }
}
