/* RESPONSIBLE TEAM: team-help-desk-experience */
import lunr from 'lunr';

class SearchClient {
  index;
  config;

  constructor(config = {}) {
    if (typeof config.fields === 'undefined') {
      throw Error('config.fields should be defined');
    }
    this.config = config;
  }

  build(documents = []) {
    if (documents.length === 0) {
      return false;
    }
    let client = this;

    lunr.tokenizer.separator = /[\s\-\(\)_]+/;

    this.index = lunr(function () {
      // field based config
      this.ref('id');
      if (client.config.disableGlobalStopWords) {
        this.pipeline.remove(lunr.stopWordFilter);
      }

      client.config.fields.map((field) => {
        this.field(field.name, { boost: field.boost || 1 });
        if (field.stopWords?.length > 0) {
          this.use(client._generateFieldStopWordPipeline(field.stopWords, field.name));
        }
      });

      if (window?.localStorage?.getItem('search-debug')) {
        this.metadataWhitelist = ['position'];
      }

      // we always key by id
      client.config.fields.push({ name: 'id' });
      // iterate documents, extract fields, add to index
      documents.map((document) => {
        if (typeof document.id === 'undefined') {
          throw Error("All documents should have 'id' field");
        }
        let documentFields = client.config.fields.reduce(
          (obj, field) => ({ ...obj, [field.name]: document[field.name] }),
          {},
        );
        this.add(documentFields);
      });
    });
    return true;
  }

  // https://github.com/olivernn/lunr.js/issues/342#issuecomment-395191855
  search(queryString) {
    queryString = queryString.trim();
    let results = this.index.query(function (q) {
      q.term(lunr.tokenizer(queryString), {
        boost: 100,
        usePipeline: false,
        wildcard: lunr.Query.wildcard.TRAILING,
      });
      q.term(lunr.tokenizer(queryString), { boost: 50, usePipeline: true });
      if (queryString.length >= 4) {
        q.term(lunr.tokenizer(queryString), { boost: 1, editDistance: 1 });
      }
    });

    // Get all the unique ids
    let resultIds = [...new Set(results.map((result) => result.ref))];

    // Deduplicate the three pass search above
    let filteredResults = results.filter((result) => resultIds.includes(result.ref));
    if (window?.localStorage?.getItem('search-debug')) {
      console.debug(`Raw Search Results for ${queryString} ============`);
      for (let i = 0; i < 15; i++) {
        if (filteredResults[i]?.matchData?.metadata) {
          console.debug(`${i + 1}: `, Object.keys(filteredResults[i].matchData.metadata));
        }
      }
    }

    return filteredResults;
  }

  _generateFieldStopWordPipeline(stopWords, fieldName) {
    return (builder) => {
      let filter = this._generateStopWordFilter(stopWords, fieldName);
      lunr.Pipeline.registerFunction(filter, `fieldStopWorldFilter${fieldName}`);
      builder.pipeline.before(lunr.stemmer, filter);
    };
  }

  _generateStopWordFilter(stopWords, fieldName) {
    let words = stopWords.reduce(function (memo, stopWord) {
      memo[stopWord] = stopWord;
      return memo;
    }, {});
    return function (token) {
      if (token && token.metadata['fields'].indexOf(fieldName) === -1) {
        return token;
      } else if (token && words[token.toString()] !== token.toString()) {
        return token;
      }
    };
  }
}

export default SearchClient;
