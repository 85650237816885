/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import nonconcurrentAjax from 'embercom/lib/nonconcurrent-ajax';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';

export default Model.extend({
  appId: attr('string'),
  accessToken: attr('string'),
  installState: attr('boolean'),
  hasOutdatedScopes: attr('boolean'),

  appService: service(),
  apps: readOnly('appService.app.currentAdmin.apps'),
  app: findByProperty('apps', 'id', 'appId'),
  appPackage: belongsTo('developer-hub/app-package'),

  install() {
    return this.postAndUpdate('install');
  },
  uninstall() {
    return this.postAndUpdate('uninstall');
  },
  regenerateToken() {
    return this.postAndUpdate('regenerate_token');
  },
  postAndUpdate(path) {
    return this._post(path).then((res) => this._setProperties(res));
  },
  async regenerateScimToken() {
    let res = await nonconcurrentAjax(this, {
      url: `/ember/scim_settings/regenerate_token`,
      type: 'POST',
      data: JSON.stringify({
        app_id: this.appService.app.id,
      }),
    });

    this._setProperties(res);
  },
  _post(path) {
    let url = `/ember/developer_hub/app_package_installs/${path}`;
    return nonconcurrentAjax(this, {
      url,
      type: 'POST',
      data: JSON.stringify({
        app_id: this.appId,
        app_package_id: this.get('appPackage.id'),
      }),
    });
  },
  _setProperties(properties) {
    let normalizedProperties = this.store.normalize(this.constructor.modelName, properties).data
      .attributes;
    this.setProperties(normalizedProperties);
  },
});
