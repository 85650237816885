/* RESPONSIBLE TEAM: team-frontend-tech */

import Modifier from 'ember-modifier';
import { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';

export default class InteractionTracer extends Modifier {
  @service tracing;

  targetName;

  modify(element, _positional, named) {
    this.targetName = named.targetName;
    element.addEventListener('click', this.startInteraction);
    registerDestructor(this, () => {
      element.removeEventListener('click', this.startInteraction);
    });
  }

  @action
  startInteraction() {
    this.tracing.startTrace({
      name: 'manuallyTracedInteraction',
      attributes: { resource: this.targetName },
      startTime: performance.now(),
      autoEndOnIdle: true,
    });
  }
}
