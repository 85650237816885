/* RESPONSIBLE TEAM: team-data-interop */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import type ApiMappableAttribute from './api-mappable-attribute';

export default class ApiMappableObject extends Fragment {
  @attr('string') declare name: string;
  @attr('string') declare fullPath: string;
  @attr('boolean') declare isArray: boolean;
  @fragmentArray('workflow-connector/api-mappable-attribute')
  declare apiMappableAttributes: ApiMappableAttribute[];
}
