/* RESPONSIBLE TEAM: team-channels */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import AdminSummary, { type AdminSummaryWireFormat } from '../admin-summary';

interface CreatedByForwardingWireFormat {
  renderable_type: RenderableType;
  original_conversation_id: number;
  admin_summary: AdminSummaryWireFormat;
  forwarded_message_id: string;
}

export default class CreatedByForwarding {
  readonly renderableType: RenderableType = RenderableType.CreatedByForwarding;
  readonly originalConversationId: number;
  readonly adminSummary: AdminSummary;
  readonly forwardedMessageId: string;

  constructor(
    originalConversationId: number,
    adminSummary: AdminSummary,
    forwardedMessageId: string,
  ) {
    this.originalConversationId = originalConversationId;
    this.adminSummary = adminSummary;
    this.forwardedMessageId = forwardedMessageId;
  }

  get creatingEntity() {
    return { type: EntityType.Admin, id: this.adminSummary.id, object: this.adminSummary };
  }

  static deserialize(json: CreatedByForwardingWireFormat): CreatedByForwarding {
    let admin_summary = AdminSummary.deserialize(json.admin_summary);
    return new CreatedByForwarding(
      json.original_conversation_id,
      admin_summary,
      json.forwarded_message_id,
    );
  }
}
