/* RESPONSIBLE TEAM: team-proactive-support */
import InserterConfiguration from 'embercom/objects/series/node/inserter-configuration';
import StandardInserterComponentConfiguration from 'embercom/objects/series/node/standard-inserter-component-configuration';
import StandardLoadingComponentConfiguration from 'embercom/objects/series/node/standard-loading-component-configuration';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class PostInserterConfiguration extends InserterConfiguration {
  objectType = objectTypes['wait'];
  objectData = {};
  componentConfig = new StandardInserterComponentConfiguration({
    icon: 'series/content/wait',
    title: capitalize(objectNames[this.objectType]),
  });
  loadingComponentConfig = new StandardLoadingComponentConfiguration({
    icon: 'series/content/wait',
    title: capitalize(objectNames[this.objectType]),
    objectType: this.objectType,
  });

  canInsertNode() {
    return super.canInsertNode(...arguments);
  }
}
