/* RESPONSIBLE TEAM: team-help-desk-experience */
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import type GeoipData from 'embercom/objects/inbox/geoip-data';

import UserSummary, { type Role, type UserSummaryWireFormat } from './user-summary';

export interface ParticipantUserSummaryWireFormat extends UserSummaryWireFormat {
  added_at?: string;
}

export default class ParticipantUserSummary extends UserSummary {
  readonly addedAt?: Date;

  constructor(
    id: string,
    email?: string,
    name?: string,
    companyIds?: Array<string>,
    company?: CompanySummary,
    companies?: Array<CompanySummary>,
    geoipData?: GeoipData,
    mergedIntoId?: string,
    role?: Role,
    imageURL?: string,
    userId?: string,
    pseudonym?: string,
    phone?: string,
    phoneCountry?: string,
    addedAt?: Date,
  ) {
    super(
      id,
      email,
      name,
      companyIds,
      company,
      companies,
      geoipData,
      mergedIntoId,
      role,
      imageURL,
      userId,
      pseudonym,
      phone,
      phoneCountry,
    );

    this.addedAt = addedAt;
  }

  static deserialize(json: ParticipantUserSummaryWireFormat): ParticipantUserSummary {
    let userSummary = super.deserialize(json);

    return new ParticipantUserSummary(
      userSummary.id,
      userSummary.email,
      userSummary.name,
      userSummary.companyIds,
      userSummary.company,
      userSummary.companies,
      userSummary.geoipData,
      userSummary.mergedIntoId,
      userSummary.role,
      userSummary.imageURL,
      userSummary.userId,
      userSummary.pseudonym,
      userSummary.phone,
      userSummary.phoneCountry,
      json.added_at ? new Date(json.added_at) : undefined,
    );
  }
}

export function firstConversationParticipant(
  participantSummaries: ParticipantUserSummary[],
): ParticipantUserSummary | undefined {
  if (!participantSummaries.length) {
    return undefined;
  }

  let [firstParticipant] = participantSummaries.sortBy('addedAt');
  return firstParticipant;
}
