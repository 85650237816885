/* RESPONSIBLE TEAM: team-help-desk-experience */
import { EntityType } from 'embercom/models/data/entity-types';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type ConversationSlaObject } from 'embercom/objects/inbox/interfaces/conversation_sla';

interface ConversationSlaTargetMissedWireFormat {
  renderable_type: RenderableType;
  conversation_sla: ConversationSlaObject;
  sla_target_type: string;
}

export default class ConversationSlaTargetMissed {
  readonly renderableType: RenderableType = RenderableType.ConversationSlaRemoved;
  readonly conversationSla: ConversationSlaObject;
  readonly slaTargetType: string;

  get creatingEntity() {
    return {
      type: EntityType.ConversationSla,
      id: this.conversationSla.id,
      object: this.conversationSla,
    };
  }

  constructor(conversationSla: ConversationSlaObject, slaTargetType: string) {
    this.conversationSla = conversationSla;
    this.slaTargetType = slaTargetType;
  }

  static deserialize(json: ConversationSlaTargetMissedWireFormat): ConversationSlaTargetMissed {
    return new ConversationSlaTargetMissed(json.conversation_sla, json.sla_target_type);
  }
}
