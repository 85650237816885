/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { equal, notEmpty, or, reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import moment from 'moment-timezone';

export default Model.extend({
  address: attr(),
  annualContractTerms: attr(),
  appIdCode: attr(),
  appName: attr(),
  billAheadCouponLineItems: hasMany('invoice-line-item'),
  billAheadFixedPriceDiscountLineItems: hasMany('invoice-line-item'),
  billAheadMultiProductDiscountLineItems: hasMany('invoice-line-item'),
  billAheadPeriodEnd: attr(),
  billAheadPeriodStart: attr(),
  billAheadPermanentDiscountLineItems: hasMany('invoice-line-item', { async: false }),
  billAheadPreSubtotalLineItems: hasMany('invoice-line-item', { async: false }),
  billAheadSubtotal: attr(),
  billAheadTotal: attr(),
  billAheadVatLineItems: hasMany('invoice-line-item', { async: false }),
  cardAmountInCents: attr(),
  cardLastFour: attr(),
  contactEmail: attr(),
  entityName: attr(),
  extraBillingDetails: attr(),
  nonIrishVatNumber: attr(),
  manuallyInvoiced: attr(),
  outOfPeriodCouponLineItems: hasMany('invoice-line-item', { async: false }),
  outOfPeriodPermanentDiscountLineItems: hasMany('invoice-line-item', { async: false }),
  outOfPeriodVatLineItems: hasMany('invoice-line-item', { async: false }),
  outOfPeriodCreditLineItems: hasMany('invoice-line-item', { async: false }),
  paid: attr(),
  periodEnd: attr(),
  periodStart: attr(),
  pricedPerProduct: attr(),
  refundedAmount: attr(),
  retrospectiveCouponLineItems: hasMany('invoice-line-item', { async: false }),
  retrospectiveMultiProductDiscountLineItems: hasMany('invoice-line-item', { async: false }),
  retrospectiveFixedPriceDiscountLineItems: hasMany('invoice-line-item', { async: false }),
  retrospectivePeriodEnd: attr(),
  retrospectivePeriodStart: attr(),
  retrospectivePermanentDiscountLineItems: hasMany('invoice-line-item', { async: false }),
  retrospectivePreSubtotalLineItems: hasMany('invoice-line-item', { async: false }),
  retrospectivePrepaidCreditLineItems: hasMany('invoice-line-item', { async: false }),
  retrospectiveSubtotal: attr(),
  retrospectiveTotal: attr(),
  retrospectiveVatLineItems: hasMany('invoice-line-item', { async: false }),
  usageLimitAgreement: attr(),
  subtotal: attr(),
  total: attr(),
  trueUp: attr(),
  vatNumber: attr(),

  //zuora fields
  status: attr(), // stripe field
  number: attr(), // stripe field
  dueDate: attr(), // stripe field
  amount: attr('number'),
  balance: attr('number'),
  url: attr(),
  includesOneTimeOnly: attr('boolean'),

  //Stripe fields
  amountDue: attr('number'),
  amountRemaining: attr('number'),
  hostedInvoiceUrl: attr('string'),
  invoicePdf: attr('string'),
  created: attr(),

  isUpcoming: equal('id', 'upcoming'),

  parsedPeriodEnd: computed('periodEnd', function () {
    return moment(this.periodEnd, 'MMM D, YYYY');
  }),

  parsedBillAheadPeriodStart: computed('billAheadPeriodStart', function () {
    return moment(this.billAheadPeriodStart, 'MMM D, YYYY');
  }),

  unixBillAheadPeriodStart: computed('billAheadPeriodStart', function () {
    return moment(this.billAheadPeriodStart).unix();
  }),

  parsedDueDate: computed('isStripe', 'isZuora', 'dueDate', 'billAheadPeriodStart', function () {
    if (this.isStripe) {
      if (this.dueDate) {
        return moment.unix(this.dueDate);
      }

      return this.created && moment.unix(this.created);
    }

    if (this.isZuora) {
      return this.dueDate && moment(this.dueDate);
    }

    return this.billAheadPeriodStart && this.parsedBillAheadPeriodStart;
  }),

  displayIdentifier: computed('isStripe', 'isZuora', 'id', 'number', function () {
    if (this.isStripe || this.isZuora) {
      return this.number;
    }
    return this.id;
  }),

  isStripe: computed('id', function () {
    return this.id.startsWith('in_');
  }),

  isZuora: computed('status', 'isStripe', function () {
    if (this.isStripe) {
      return false;
    }
    return typeof this.status !== 'undefined';
  }),

  isUncollectible: computed('isStripe', 'status', function () {
    return this.isStripe && this.status === 'uncollectible';
  }),

  isPaid: computed('isStripe', 'isZuora', 'balance', 'paid', function () {
    if (this.isStripe) {
      return this.paid;
    }
    if (this.isZuora) {
      return this.balance <= 0.0;
    }
    return this.paid;
  }),

  isOpen: computed('isStripe', 'status', 'isPaid', function () {
    if (this.isStripe) {
      return this.status === 'open';
    }
    return !this.isPaid;
  }),

  displayedTotal: computed('isStripe', 'isZuora', 'total', 'amount', 'amountDue', function () {
    if (this.isStripe) {
      return this.amountDue;
    }
    if (this.isZuora) {
      return this.amount * 100;
    }
    return this.total;
  }),

  displayedOustanding: computed(
    'isStripe',
    'isZuora',
    'total',
    'balance',
    'amountRemaining',
    function () {
      if (this.isStripe) {
        return this.amountRemaining;
      }
      if (this.isZuora) {
        return this.balance * 100;
      }
      return this.total;
    },
  ),

  hideBillAhead: computed(
    'billAheadCouponLineItems',
    'billAheadFixedPriceDiscountLineItems',
    'billAheadMultiProductDiscountLineItems',
    'billAheadPermanentDiscountLineItems',
    'billAheadPreSubtotalLineItems',
    'billAheadVatLineItems',
    function () {
      return (
        this.get('billAheadCouponLineItems.length') === 0 &&
        this.get('billAheadFixedPriceDiscountLineItems.length') === 0 &&
        this.get('billAheadMultiProductDiscountLineItems.length') === 0 &&
        this.get('billAheadPermanentDiscountLineItems.length') === 0 &&
        this.get('billAheadPreSubtotalLineItems.length') === 0 &&
        this.get('billAheadVatLineItems.length') === 0
      );
    },
  ),

  hasRetrospectiveVatLineItems: notEmpty('retrospectiveVatLineItems'),
  hasBillAheadVatLineItems: notEmpty('billAheadVatLineItems'),
  hasVatLineItems: or('hasRetrospectiveVatLineItems', 'hasBillAheadVatLineItems'),

  hideRetrospective: computed(
    'retrospectiveCouponLineItems',
    'retrospectiveFixedPriceDiscountLineItems',
    'retrospectiveMultiProductDiscountLineItems',
    'retrospectivePermanentDiscountLineItems',
    'retrospectivePreSubtotalLineItems',
    'retrospectiveVatLineItems',
    function () {
      return (
        this.get('retrospectiveCouponLineItems.length') === 0 &&
        this.get('retrospectiveFixedPriceDiscountLineItems.length') === 0 &&
        this.get('retrospectiveMultiProductDiscountLineItems.length') === 0 &&
        this.get('retrospectivePermanentDiscountLineItems.length') === 0 &&
        this.get('retrospectivePreSubtotalLineItems.length') === 0 &&
        this.get('retrospectiveVatLineItems.length') === 0
      );
    },
  ),

  isTrueUpWithNoExpansion: computed('billAheadPreSubtotalLineItems', 'trueUp', function () {
    return this.get('billAheadPreSubtotalLineItems.length') === 0 && this.trueUp === true;
  }),

  trueUpExpansion: reads('billAheadPreSubtotalLineItems.firstObject'),

  products: computed('retrospectivePreSubtotalLineItems', function () {
    let products = [
      { hasItem: false, name: 'Engage', newName: 'Messages', items: [] },
      { hasItem: false, name: 'Respond', newName: 'Inbox', items: [] },
      { hasItem: false, name: 'Educate', newName: 'Articles', items: [] },
      { hasItem: false, name: 'Business', items: [] },
    ];

    let sortedRetrospectiveLineItems = this.retrospectivePreSubtotalLineItems.sortBy('start');

    sortedRetrospectiveLineItems.forEach((lineItem) => {
      let lineItemHasBeenAddedToProduct = false;
      products.forEach((product) => {
        let lineItemProductAndPlan = lineItem.get('productAndPlanNames');
        let lineItemCountForProduct = product.items.length;
        let matchesProductName =
          lineItemProductAndPlan.match(product.name) ||
          lineItemProductAndPlan.match(product.newName);
        if (matchesProductName && !lineItemHasBeenAddedToProduct) {
          if (
            lineItemCountForProduct === 0 ||
            product.items[lineItemCountForProduct - 1].productAndPlan !== lineItemProductAndPlan
          ) {
            product.items.push({
              subsequentLineItemForProduct: false,
              productAndPlan: lineItemProductAndPlan,
              lineItem,
            });
          } else {
            product.items.push({
              subsequentLineItemForProduct: true,
              productAndPlan: lineItemProductAndPlan,
              lineItem,
            });
          }
          lineItemHasBeenAddedToProduct = true;
          product.hasItem = true;
        }
      });
    });

    return products;
  }),

  hasUsageLimitAgreement: computed(
    'freeAgentCount',
    'minimumAgentCount',
    'minimumActivePeopleCount',
    function () {
      return this.freeAgentCount + this.minimumAgentCount + this.minimumActivePeopleCount > 0;
    },
  ),

  freeAgentCount: computed('usageLimitAgreement', function () {
    if (!this.usageLimitAgreement) {
      return 0;
    }
    return this.get('usageLimitAgreement.free_agent_count');
  }),

  minimumAgentCount: computed('usageLimitAgreement', function () {
    if (!this.usageLimitAgreement) {
      return 0;
    }
    return this.get('usageLimitAgreement.minimum_agent_count');
  }),

  minimumActivePeopleCount: computed('usageLimitAgreement', function () {
    if (!this.usageLimitAgreement) {
      return 0;
    }
    return this.get('usageLimitAgreement.minimum_active_people_count');
  }),
});
