/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import EditorConfig from 'embercom/objects/content-editor/content-editor-configuration';
import generateViewModeConfig from 'embercom/objects/content-editor/configuration-generators/carousel/view-mode-generator';
import generateEditModeConfig from 'embercom/objects/content-editor/configuration-generators/carousel/edit-mode-generator';
import generateRequiredFeature from 'embercom/objects/content-editor/configuration-generators/carousel/feature-generator';
import generateSeriesModeConfig from 'embercom/objects/series/ruleset-editor/carousel/editor-configuration-generator';
import generateNotificationMessages from 'embercom/objects/content-editor/configuration-generators/standard-notification-messages-generator';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import NotificationMessage from 'embercom/objects/content-editor/notification-message';
import { inject as service } from '@ember/service';
import {
  humanReadableObjectNames,
  objectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import numberFormatter from 'embercom/lib/number-formatter';
import pluralize from 'embercom/lib/inflector';
import { targetUserGroup } from 'embercom/models/data/carousel/target-user-group';

export default function generateEditorConfig(options) {
  options.objectType = objectTypes.carousel;
  let container = options.container;
  let humanReadableObjectName = humanReadableObjectNames[options.objectType];
  let notificationMessages = generateNotificationMessages(options);
  notificationMessages.activate = generateCarouselNotificationMessages(
    container,
    humanReadableObjectName,
    options.objectType,
  );
  notificationMessages.delete = generateCarouselDeletionMessage(container, humanReadableObjectName);
  return EditorConfig.create({
    viewMode: generateViewModeConfig(options),
    editMode: generateEditModeConfig(options),
    seriesMode: generateSeriesModeConfig(options),
    notificationMessages,
    requiredFeature: generateRequiredFeature(options),
  });
}

function generateCarouselNotificationMessages(container, humanReadableObjectName, objectType) {
  return NotificationMessage.extend({
    contentEditorService: service(),
    humanReadableObjectName,
    async confirmation() {
      await this.contentEditorService.ruleset.fetchAudiencePreview();

      let ruleset = this.contentEditorService.ruleset;

      let audienceCount = ruleset.audiencePreview.count;
      let audience = `${numberFormatter(audienceCount)} ${pluralize('person', audienceCount)}`;

      let title = `Ready to set your ${this.humanReadableObjectName} live?`;
      let confirmButtonText = `Set live`;

      let body;
      if (ruleset.isTriggeredByCode) {
        body =
          'Your mobile carousel will not be sent automatically and can only be triggered using code.';
      } else if (ruleset.hasControlGroup) {
        body = `Part of your audience of ${audience} will be sent this ${this.humanReadableObjectName} and the rest will join the control group.`;
        if (!ruleset.isMatchBehaviorStatic) {
          body +=
            ' Going forward, anyone who matches your rules in the future will be split similarly.';
        }
      } else {
        let object = this.contentEditorService.ruleset.rulesetLinks.firstObject.object;
        if (object.targetUserGroup === targetUserGroup.new.value) {
          body = `Your ${this.humanReadableObjectName} will be sent to new users who match your rules.`;
        } else {
          body = `Your ${this.humanReadableObjectName} will reach up to ${audience}`;
          if (ruleset.isMatchBehaviorStatic) {
            body += '.';
          } else {
            body += ' now and anyone who matches your rules in the future.';
          }
          if (!ruleset.isDraft) {
            body += ` Anyone who has already received this ${this.humanReadableObjectName} will not receive it again.`;
          }
        }
      }

      return {
        title,
        confirmButtonText,
        confirmContext: {
          body,
          objectType,
          isDynamicMessage: !ruleset.isMatchBehaviorStatic,
        },
        bodyComponentName: 'content-editor/set-live-confirmation-body',
        shouldTrackAnalyticsEvents: true,
        analyticsEventChannelName: objectNames[objectType],
      };
    },
  }).create({
    success: `The ${humanReadableObjectName} was set live`,
    failure: `We couldn't set this ${humanReadableObjectName} live. Send us a message and we'll do our best to help out.`,
    container,
  });
}

function generateCarouselDeletionMessage(container, humanReadableObjectName) {
  return NotificationMessage.extend({
    contentEditorService: service(),
    confirmation() {
      let title = `Delete this ${humanReadableObjectName}?`;
      let confirmButtonText = `Delete`;
      let primaryButtonType = 'primary-destructive';
      let body = `Are you sure you want to delete this ${humanReadableObjectName}? This is permanent.`;
      if (this.contentEditorService.ruleset.isLive || this.contentEditorService.ruleset.isPaused) {
        body += ` This deletes the ${humanReadableObjectName} from Intercom and the Messenger. Any conversations it started will still be visible in the Inbox to teammates and included in reports. If the carousel can be triggered using code in the SDK, deleting it would break the SDK integration`;
      }
      return { title, body, confirmButtonText, primaryButtonType };
    },
  }).create({
    success: `Your ${humanReadableObjectName} has been deleted`,
    failure: `We could not delete your ${humanReadableObjectName}`,
    container,
  });
}
