/* RESPONSIBLE TEAM: team-help-desk-experience */
import md5 from 'blueimp-md5';

export const AVATAR_COLORS = [
  'EC4662',
  'E08181',
  'F98600',
  'EFBB01',
  'BBAB02',
  '86BF40',
  '14B354',
  '14C0A6',
  '14C0A6',
  '8C99FF',
  '9673E8',
  'C97AB8',
];

export const DARK_AVATAR_COLORS = [
  'D77081',
  'F5C1C1',
  'EDB87A',
  'E4D49A',
  'B8B169',
  '95AF74',
  '7EC79A',
  'B4DDD7',
  '7FC1CE',
  '9EA4CF',
  'AB97DB',
  'C38FB8',
];

// Picks a colour based on a string (email / ID etc)
//
// Uses the same approach as the server [1] by taking a hash of the string
// and converting that to an integer which modulos agains the list of
// colours in order to generate the same 'random' colour for the same string.
//
// Unlike admin IDs company IDs aren't guaranteed to be numbers, so we can't
// simply modulo the company ID like we do for AdminSummary#color
//
// [1] - https://github.com/intercom/intercom/blob/ff2f6a24474c67a0ae0c338c18a2aa9615e86a42/app/services/user_service/models/avatar_color.rb#L56
function stringToAvatarColor(string: string | undefined, colors: Array<string>): string {
  if (!string) {
    return colors[0];
  }

  let chars = md5(string).slice(-8);
  let num = parseInt(chars, 16) || 0;
  let index = num % colors.length;
  let color = colors[index];

  return color;
}

export interface CompanySummaryWireFormat {
  id: string;
  name?: string;
  remote_company_id?: string;
}

export default class CompanySummary {
  readonly id: string;
  readonly name?: string;
  readonly remoteCompanyId?: string;

  constructor(summary: CompanySummaryWireFormat) {
    this.id = summary.id;
    this.name = summary.name;
    this.remoteCompanyId = summary.remote_company_id;
  }

  static deserialize(json: CompanySummaryWireFormat): CompanySummary {
    return new CompanySummary(json);
  }

  private get avatarKey() {
    return this.id;
  }

  get color(): string {
    return stringToAvatarColor(this.avatarKey, AVATAR_COLORS);
  }

  get darkColor(): string {
    return stringToAvatarColor(this.avatarKey, DARK_AVATAR_COLORS);
  }

  get displayAs(): string {
    return this.name || this.remoteCompanyId || this.id;
  }
}
