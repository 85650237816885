/* RESPONSIBLE TEAM: team-messenger */
import { computed } from '@ember/object';
import {
  USER_TARGET_TYPE,
  LEADS_AND_VISITORS_TARGET_TYPE,
  MOBILE_TARGET_TYPE,
} from 'embercom/lib/messenger-settings/target-types';

export function userTypeComputedProperty(
  userType,
  userProperty,
  visitorProperty,
  mobileProperty = undefined,
) {
  mobileProperty = mobileProperty || userProperty;
  return computed(userType, userProperty, visitorProperty, mobileProperty, {
    get(_) {
      switch (this.get(userType)) {
        case USER_TARGET_TYPE:
          return this.get(userProperty);
        case LEADS_AND_VISITORS_TARGET_TYPE:
          return this.get(visitorProperty);
        case MOBILE_TARGET_TYPE:
          return this.get(mobileProperty);
      }
    },
    set(_, value) {
      switch (this.get(userType)) {
        case USER_TARGET_TYPE:
          this.set(userProperty, value);
          break;
        case LEADS_AND_VISITORS_TARGET_TYPE:
          this.set(visitorProperty, value);
          break;
        case MOBILE_TARGET_TYPE:
          this.set(mobileProperty, value);
          break;
      }
      return value;
    },
  });
}
