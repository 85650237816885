/* RESPONSIBLE TEAM: team-phone */
import Step from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { type SyncHasMany } from '@ember-data/model';
import { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';
import { ONLY_PHONE_CALL_CHANNEL } from 'embercom/lib/operator/custom-bots/constants';
import { type Block } from 'embercom/models/common/blocks/block';

const Validations = buildValidations({
  blocks: [
    validator('blocks-not-empty', {
      message: 'operator.workflows.visual-builder.validations.blank-message-error',
      messageTranslated: true,
    }),
    validator('workflow-step-blocks-audio-snippets-have-no-meaningful-content', {
      message: 'operator.workflows.visual-builder.validations.audio-files-invalid',
      messageTranslated: true,
    }),
  ],
  followUpRules: [
    validator('follow-up-rule-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
    validator('follow-up-rule-is-complete', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
  simpleActionRule: [
    validator('simple-action-rule-is-valid', {
      message: 'operator.workflows.visual-builder.validations.empty-action-error',
    }),
  ],
  ttsMode: [validator('presence', true)],
});

export default class Voicemail extends Step.extend(Validations) {
  @fragmentArray('rules/rule') declare followUpRules: SyncHasMany<any>;
  @fragment('rules/rule') declare simpleActionRule?: any;

  @fragmentArray('common/blocks/block', { polymorphic: true, typeKey: 'modelKey' })
  declare blocks: SyncHasMany<Block>;
  @attr('string', { defaultValue: 'en-US' }) declare ttsLanguage: string;
  // eslint-disable-next-line @intercom/intercom/no-bare-strings
  @attr('string', { defaultValue: 'Text to Speech' }) declare ttsMode: string;
  supportedChannels = ONLY_PHONE_CALL_CHANNEL;

  static get requiredBillingFeature() {
    return 'workflows_core_billing_feature';
  }

  get hasSimpleActionRule() {
    return !!this.simpleActionRule;
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get isStepTerminal() {
    return true;
  }

  static createNewStep(store: Store): Voicemail {
    return store.createRecord('operator/visual-builder/step/voicemail', {
      type: 'operator/visual-builder/step/voicemail',
    });
  }
}
