/* RESPONSIBLE TEAM: team-ai-agent */
import Model, { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { fragment } from 'ember-data-model-fragments/attributes';
import type ExtractedQuestion from './extracted-question';
import type QuestionGroupStats from './question-group-stats';
import type RelatedContentSource from './related-content-source';

export default class QuestionGroup extends Model {
  @attr('string') declare title: string;
  @attr('string') declare locale: string;
  @fragment('ai-agent/question-group-stats') declare stats: QuestionGroupStats;
  @fragmentArray('ai-agent/extracted-question')
  declare extractedQuestions: ExtractedQuestion[];
  @fragmentArray('ai-agent/related-content-source')
  declare contentSourcesUsed: RelatedContentSource[];
}
