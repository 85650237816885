/* RESPONSIBLE TEAM: team-proactive-support */
import { stateName } from 'embercom/helpers/state-name';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

class AnalyticAction {
  private _actionName: string;

  constructor(actionName: string) {
    this._actionName = actionName;
  }

  get actionName() {
    return this._actionName;
  }
}

class AnalyticObject {
  private _name: string;

  constructor(name: string) {
    this._name = name;
  }

  get name() {
    return this._name;
  }
}

export abstract class SeriesAnalyticObjects {
  public static get series() {
    return new AnalyticObject('series');
  }
  public static get changesBanner() {
    return new AnalyticObject('series-has-new-changes-banner');
  }
  public static get overviewReport() {
    return new AnalyticObject('series_overview_list');
  }
  public static tab(view: string) {
    return new AnalyticObject(`${view}_tab`);
  }
  public static get saveButton() {
    return new AnalyticObject('save');
  }
  public static get messageOpen() {
    return new AnalyticObject('open_message');
  }
}

export abstract class SeriesAnalyticActions {
  public static get viewed() {
    return new AnalyticAction('viewed');
  }
  public static get clicked() {
    return new AnalyticAction('clicked');
  }
  public static get changeTab() {
    return new AnalyticAction('change_tab');
  }
  public static get download() {
    return new AnalyticAction('download');
  }
}

type SeriesAnalyticParameters = {
  intercomEventService: any;
  seriesEditorService?: any; //app/services/series-editor-service.js
};

type TrackParameters = {
  action: AnalyticAction;
  object?: AnalyticObject;
  additionalAttributes?: object;
};

export class SeriesAnalyticTracker {
  intercomEventService: any;
  series?: any; //app/models/series/series.js
  seriesEditorService?: any; //app/services/series-editor-service.js

  constructor({ intercomEventService, seriesEditorService }: SeriesAnalyticParameters) {
    this.series = seriesEditorService?.series;
    this.intercomEventService = intercomEventService;
    this.seriesEditorService = seriesEditorService;
  }

  track({ action, object, additionalAttributes }: TrackParameters) {
    this.intercomEventService.trackAnalyticsEvent({
      action: action.actionName,
      object: object ? object.name : SeriesAnalyticObjects.series.name,
      series_state: this.series ? this._seriesStateName : null,
      series_id: this.series ? this.series.id : null,
      active_node: !!this.seriesEditorService?.activeSeriesNode,
      owner: 'engage',
      ...additionalAttributes,
    });
  }

  private get _seriesStateName() {
    return stateName(
      {},
      {
        state: this.series.state,
        objectType: objectTypes.series,
      },
    );
  }
}
