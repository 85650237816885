/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import Fragment from 'ember-data-model-fragments/fragment';

export type SupportedNetworksType =
  | 'facebook'
  | 'linkedin'
  | 'twitter'
  | 'x'
  | 'instagram'
  | 'youtube'
  | 'pinterest'
  | 'github'
  | 'dribbble'
  | 'reddit'
  | 'discord'
  | 'tiktok'
  | 'tumblr'
  | 'telegram'
  | 'spotify'
  | 'twitch'
  | 'vk';
export const NetworkMap: Record<SupportedNetworksType, string> = {
  facebook: 'facebook.com',
  linkedin: 'linkedin.com',
  twitter: 'twitter.com',
  x: 'x.com',
  instagram: 'instagram.com',
  youtube: 'youtube.com',
  pinterest: 'pinterest.com',
  github: 'github.com',
  dribbble: 'dribbble.com',
  reddit: 'reddit.com',
  discord: 'discord.com',
  tiktok: 'tiktok.com',
  tumblr: 'tumblr.com',
  telegram: 't.me',
  spotify: 'spotify.com',
  twitch: 'twitch.tv',
  vk: 'vk.com',
};

const Validations = buildValidations({
  handle: [
    validator('presence', {
      presence: true,
      messageKey: 'articles.settings.redesign.content.footer.social-links.invalid',
    }),
    validator('format', {
      regex: /^\S*$/,
      messageKey: 'articles.settings.redesign.content.footer.social-links.invalid',
    }),
  ],
});
export default class HelpCenterSocialLink extends Fragment.extend(Validations) {
  @attr('string') declare handle: string;
  @attr('string') declare socialNetwork: string;

  get prefix(): string {
    if (!(this.socialNetwork in NetworkMap)) {
      return '';
    }

    return `${NetworkMap[this.socialNetwork as SupportedNetworksType]}/`;
  }
}
