/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { gt, empty, and, not } from '@ember/object/computed';
import Fragment from 'ember-data-model-fragments/fragment';
import { fragment } from 'ember-data-model-fragments/attributes';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';

const CHAR_LIMIT = 160;

const Validations = buildValidations({
  contentBlock: validator('belongsTo'),
  title: validator('presence', {
    presence: true,
    disabled: not('model.isSelected'),
    messageKey: 'articles.settings.redesign.validations.locale-title',
  }),
});

export default Fragment.extend(Validations, {
  intl: service(),
  localeId: attr('string'),
  name: attr('string'),
  title: attr('string'),
  headline: attr('string'),
  isSelected: attr('boolean'),

  invalidTitle: and('isSelected', 'missingTitle'),
  missingTitle: empty('title'),

  invalidHeadline: and('isSelected', 'headlineTooLong'),
  headlineTooLong: gt('headline.length', CHAR_LIMIT),
  contentBlock: fragment('customization-options/layout-options/home-page/localized-content-block'),
  initializeContentBlock() {
    this.contentBlock = this.store.createFragment(
      'customization-options/layout-options/home-page/localized-content-block',
      {
        locale: this.localeId,
        title: this.intl.t(
          'articles.settings.stylings.layout-and-sections.content-block.title-placeholder',
        ),
        withButton: false,
        description: '',
        buttonTitle: this.intl.t(
          'articles.settings.stylings.layout-and-sections.content-block.button-title-placeholder',
        ),
        buttonUrl: '',
      },
    );
  },
  dirtyAttributes: computed('title', function () {
    return Object.keys(this.changedAttributes());
  }),
});
