/* RESPONSIBLE TEAM: team-proactive-support */
import StandardPanelHeaderConfiguration from 'embercom/objects/content-editor/panels/standard-panel-header-configuration';
import { inject as service } from '@ember/service';
import { readOnly, empty } from '@ember/object/computed';

export default StandardPanelHeaderConfiguration.extend({
  contentEditorService: service(),
  intl: service(),

  errors: readOnly('contentEditorService.ruleset.validations.attrs.rulesetLinks.errors'),
  isComplete: empty('errors'),

  init(inputs) {
    inputs.component = 'content-editor/panels/content/whatsapp/header';
    inputs.title = inputs.title || this.intl.t('app.objects.content-editor.whatsapp.content');
    this._super(...arguments);
  },
});
