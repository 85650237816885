/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import StandardAudienceEditorConfiguration from 'embercom/objects/audience-editor/standard-audience-editor-configuration';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import toSentence from 'embercom/lib/to-sentence';
import { audienceNames } from 'embercom/models/data/matching-system/matching-constants';

export default class ContentEditorStandardAudienceEditorConfiguration extends StandardAudienceEditorConfiguration {
  container = null;
  @service contentEditorService;

  contentEditorDefaults = {
    showMatchBehavior: true,
    showClientPredicates: true,
    showAudiencePreview: true,
    showEventRules: false,
    showDateEvents: false,

    useRolePredicateGroup: true,
    allowedUserRoles: ['user', 'lead', 'visitor'],
  };

  constructor(inputs) {
    super(inputs);
    this.container = inputs.container;
    this.inputs = { ...this.contentEditorDefaults, ...inputs };
    this._setDefaultPreviewConfigs();
    this.createDefaults();
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get isDisabled() {
    return false;
  }

  get _tooltipText() {
    if (this.ruleset.isDraft || this.ruleset.isScheduled) {
      return 'The people below currently match the rules you added.';
    } else if (this.ruleset.isLive) {
      return 'The people below currently match the rules you added. Because this message is live, some or all of them may have received it.';
    } else if (this.ruleset.isPaused) {
      return 'The people below currently match the rules you added. Some or all of them may have received your message when it was live.';
    }
  }

  get _humanReadableObjectName() {
    return this.contentEditorService.configRulesetLink.humanReadableObjectName;
  }

  get _audienceString() {
    let audienceRole = this._getAudienceRole();

    if (!Array.isArray(audienceRole)) {
      audienceRole = [audienceRole];
    }

    let audience = toSentence(
      audienceRole.reverse().map((role) => {
        return pluralize(audienceNames[role]);
      }),
    );
    return audience;
  }

  get _audiencePreviewText() {
    if (this.inputs.audiencePreviewText) {
      return this.inputs.audiencePreviewText;
    } else if (this.ruleset.isMemberOfSeries) {
      return 'If you set this Series live, they would enter it, as well as new people that start to match your rules.';
    } else {
      let matchBehaviorText = this.ruleset.isMatchBehaviorStatic
        ? 'match your rules.'
        : 'start to match your rules.';
      return `If you set this ${this._humanReadableObjectName} live, we’ll send it to ${this._audienceString} that ${matchBehaviorText}`;
    }
  }

  _getAudienceRole() {
    return this.ruleset.userRolePredicate ? this.ruleset.userRolePredicate.value : ['user_role'];
  }

  _setDefaultPreviewConfigs() {
    this.inputs = {
      ...this.inputs,
      ...{
        tooltipText: this._tooltipText,
        audiencePreviewText: this._audiencePreviewText,
        emptyStateDescription: '',
        useLegacyList: false,
      },
    };
  }
}
